/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const statisticsItemsSv = {
  NUMBER_OF_APPOINTMENTS: "Bokning utförd",
  APPOINTMENT_USAGE: "Mötesanvändning",
  NUMBER_OF_MEDICAL_RECORDS: "Journal skapad",
  MEDICAL_RECORD_USAGE: "Journalanvändning",
  NUMBER_OF_USED_ITEMS: "Inventeringsartiklar använda",
  PRICE_OF_USED_ITEMS: "Kostnad av inventeringsartiklar använda",
  MAX_NUMBER_OF_EMPLOYEES: "Maximalt antal anställda under en månad",
  MAX_NUMBER_OF_VETS: "Maximalt antal veterinärer under månaden",
  MAX_NUMBER_OF_ASSISTANTS: "Maximalt antal assistenter under månaden",
  MAX_NUMBER_OF_RECEPTIONISTS: "Maximalt antal receptionister under månaden",
  MAX_NUMBER_OF_ORGANIZATION_MANAGERS: "Maximalt antal chefer under en månad",
  INVENTORY_USAGE: "Lageranvändning",
  SMS_USAGE: "SMS-användning",
  NUMBER_OF_PRESCRIPTIONS: "Recept skapade",
};
