/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import Button from "../Button";
import { Cross } from "../../common/Icons/Cross";
import { strings } from "../../common/Strings/Strings";

export interface Props {
  handleClose?(): void;
  className?: string;
  children?: string | ReactElement | ReactElement[];
  closeButton?: boolean;
  extraClassName?: string;
  title?: string;
  titleClassName?: string;
  titleExtraClassName?: string;
}

const ModalHeader: React.FC<Props> = ({
  handleClose,
  className,
  children,
  closeButton = true,
  extraClassName,
  title,
  titleClassName,
  titleExtraClassName,
}: Props) => (
  <div
    className={className || `tw-modal-header ${extraClassName || ""}`}
    id="modal-header"
  >
    {children}
    <h3
      className={
        titleClassName ||
        `truncate overflow-hidden xl:text-xl font-bold leading-tight text-zinc-800 dark:text-white ${
          titleExtraClassName || ""
        } `
      }
    >
      {title}
    </h3>
    {closeButton && (
      <>
        <Button
          className="hidden xl:flex"
          fullWidth={false}
          onClick={handleClose}
          variant="icon"
        >
          <span className="sr-only">{strings.closeModal}</span>
          <Cross />
        </Button>
        <Button
          className="inline-flex xl:hidden items-center p-2 text-sm text-zinc-800 dark:text-white focus:outline-none dark:text-white"
          fullWidth={false}
          onClick={handleClose}
          variant="basic"
        >
          <span className="sr-only">{strings.closeModal}</span>
          <Cross />
        </Button>
      </>
    )}
  </div>
);

ModalHeader.displayName = "ModalHeader";

export default ModalHeader;
