/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../common/Strings/Strings";
import { getGeneralError } from "../../util/helperFunctions";
import UserApi from "../../api/UserApi";
import { useUser } from "../../contexts/UserContext";
import CodeInputsForm from "../../components/ReactHookFormFields/Specific/CodeInputsForm";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";

interface Props {
  clear(): void;
  onSuccessFunction(): void;
  returnErrorMessage(m: string | null | undefined): void;
}

const PinCheckSection: React.FC<Props> = ({
  clear,
  onSuccessFunction,
  returnErrorMessage,
}: Props) => {
  const { user } = useUser();

  const checkPin = async (pin: string) => {
    try {
      await UserApi.validatePinCode({ pinCode: pin });
      onSuccessFunction();
    } catch (e) {
      returnErrorMessage(await getGeneralError(e));
    }
  };

  const pinChange = (pinCode: string) => {
    clear();
    void checkPin(pinCode);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div style={{ width: "75px", height: "75px" }}>
          <UserProfilePicture loggedIn />
        </div>
        <div className="my-1">{strings.signature}</div>
        <div className="text-xl font-bold mb-4">{user?.details.fullName}</div>
      </div>
      <div className="text-center border-t">
        <div className="my-4 text-base">{strings.enterPin}</div>
        <div className="flex justify-center mb-3">
          <CodeInputsForm submit={pinChange} size={4} hideCharacters />
        </div>
      </div>
    </>
  );
};

export default PinCheckSection;
