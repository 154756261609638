/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { strings } from "../../../common/Strings/Strings";
import PharmacyForm from "../sections/pharmacy/PharmacyForm";
import { Pharmacy } from "../../../models/pharmacy/Pharmacy";
import { GeoPharmacy } from "../../../models/pharmacy/GeoPharmacy";

interface Props {
  isOpen: boolean;
  close(): void;
  geoPharmacies: GeoPharmacy[];
  returnPharmacy(p: string): void;
}

const PharmacySearchModal: React.FC<Props> = ({
  isOpen,
  close,
  geoPharmacies,
  returnPharmacy
}: Props) => {
  const [pharmacyId, setPharmacyId] = useState<string>();

  const choosePharmacy = () => {
    if (pharmacyId) {
      returnPharmacy(pharmacyId);
    }
    close();
  };

  return (
    <Modal show={isOpen} onHide={close} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{strings.chooseAPharmacy}</Modal.Title>
      </Modal.Header>
      <Modal.Body bsPrefix="m-3 p-0">
        <PharmacyForm
          geoPharmacies={geoPharmacies}
          selectPharmacy={(p: Pharmacy) => {
            setPharmacyId(p?.glnCode);
          }}
          visibleDetails={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={close}>
          {strings.close}
        </Button>
        <Button
          variant="primary ml-auto"
          type="button"
          onClick={choosePharmacy}
        >
          {strings.continue}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PharmacySearchModal;
