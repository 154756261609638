/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import CloseButton from "../../../components/CloseButton";
import GDPRApi from "../../../api/GDPRApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { PolicyResponse } from "../../../models/management/PolicyResponse";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";
import AlertBox from "../../../components/AlertBox";

interface Props {
  onHide: () => void;
  onPolicyChanged: (policy: PolicyResponse) => void;
  policyName: string;
  policyToDelete?: PolicyResponse;
  setError: (errorMessage: string | null) => void;
  show: boolean;
}

const DeletePolicyModal: React.FC<Props> = ({
  onHide,
  onPolicyChanged,
  policyName,
  policyToDelete,
  setError,
  show,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const deletePolicy = async () => {
    if (!policyToDelete) {
      return;
    }

    setLoading(true);

    try {
      await GDPRApi.deletePolicy(
        policyToDelete.type,
        policyToDelete.countryCode,
        policyToDelete.languageCode
      );
      onPolicyChanged(policyToDelete);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
      onHide();
    }
  };

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={`${strings.deletePolicy}: ${policyName}`} />
      <Modal.Body>
        <p className="text-center">{strings.deletePolicyConfirmation}</p>
        <AlertBox message={strings.warningDeletePolicy} closeAble={false} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          fullWidth={false}
          loading={loading}
          onClick={deletePolicy}
          variant="danger"
        >
          {strings.delete}
        </Button>
        <CloseButton onClick={onHide} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePolicyModal;
