/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import PetOwnerContactsApi from "../../../api/PetOwnerContactsApi";
import AlertBox from "../../AlertBox";
import { ContactType } from "../../../models/contact/ContactType";
import BeforeDeleteModal from "../../Modals/BeforeDeleteModal";
import { EmailResponse } from "../../../models/contact/EmailResponse";
import AddEmailModal from "../../Modals/Email/AddEmailModal";
import Button from "../../Button";
import { Pencil } from "../../../common/Icons/Pencil";
import { Trash } from "../../../common/Icons/Trash";
import UnconfirmedEmailTag from "../user/email/UnconfirmedEmailTag";
import PromoteEmailModal from "../../Modals/Email/PromoteEmailModal";

interface Props {
  owner?: PetOwnerResponse;
}

const PetOwnerEmails: React.FC<Props> = ({ owner }: Props) => {
  const [emails, setEmails] = useState<EmailResponse[]>(owner?.emails || []);

  const [emailToPromote, setEmailToPromote] = useState<EmailResponse | null>(
    null
  );
  const [emailToDelete, setEmailToDelete] = useState<EmailResponse | null>(
    null
  );

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showPromoteModal, setShowPromoteModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [deletionLoading, setDeletionLoading] = useState<boolean>(false);
  const [deletionError, setDeletionError] = useState<string | null>(null);

  useEffect(() => {
    if (owner) {
      setEmails(owner.emails);
    }
  }, [owner]);

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openPromoteModal = (email: EmailResponse) => {
    if (email.contactType === ContactType.PRIMARY) {
      return;
    }

    setEmailToPromote(email);
    setShowPromoteModal(true);
  };

  const closePromoteModal = () => {
    setShowPromoteModal(false);
  };

  const deleteEmail = async (email: EmailResponse) => {
    if (!owner || !owner?.petOwnerDetails.id) {
      return;
    }

    setDeletionLoading(true);

    try {
      const response = await PetOwnerContactsApi.removePetOwnerEmail(
        owner.petOwnerDetails.id,
        email.id
      );
      setEmails(response.data.emails);
      setDeletionError(null);
    } catch (err) {
      setDeletionError(await getGeneralError(err));
    } finally {
      setDeletionLoading(false);
    }
  };

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 font-bold">
          <p className="uppercase">{strings.email.toUpperCase()}</p>
        </div>
        {emails.map((email, index) => (
          <div
            className="p-4 flex justify-between items-center space-x-4"
            key={index}
          >
            <div className="flex flex-wrap items-center">
              <p>{email.value}</p>
              <UnconfirmedEmailTag email={email} />
              <button
                className={`tw-btn-pill-tercier-small ${
                  email.confirmed ? "mx-3" : ""
                }`}
                disabled
                hidden={email.contactType !== ContactType.PRIMARY}
                type="button"
              >
                {strings.primary}
              </button>
            </div>
            <div className="flex">
              <Button
                aria-label={strings.edit}
                hidden={email.contactType === ContactType.PRIMARY}
                onClick={() => {
                  openPromoteModal(email);
                }}
                variant="icon"
              >
                <span className="sr-only">{strings.edit}</span>
                <Pencil />
              </Button>
              <Button
                aria-label={strings.delete}
                className="tw-link ml-3"
                loading={deletionLoading && email === emailToDelete}
                onClick={() => {
                  setShowDeleteModal(true);
                  setEmailToDelete(email);
                }}
                showTextWhileLoading={false}
                variant="icon"
              >
                <span className="sr-only">{strings.delete}</span>
                <Trash />
              </Button>
            </div>
          </div>
        ))}
        <div className="p-4">
          <Button
            aria-label={strings.addEmail}
            onClick={openAddModal}
            variant="link"
          >
            <span className="sr-only">{strings.addEmail}</span>+{" "}
            {strings.addEmail}
          </Button>
        </div>
        <AlertBox message={deletionError} className="m-3" />
      </div>
      <AddEmailModal
        onHide={closeAddModal}
        petOwnerId={owner?.petOwnerDetails.id}
        setEmails={setEmails}
        show={showAddModal}
      />
      <PromoteEmailModal
        email={emailToPromote}
        onHide={closePromoteModal}
        petOwnerId={owner?.petOwnerDetails.id}
        setEmails={setEmails}
        show={showPromoteModal}
      />
      <BeforeDeleteModal
        open={showDeleteModal}
        returnYes={() => {
          if (emailToDelete) {
            void deleteEmail(emailToDelete);
          }
          setShowDeleteModal(false);
        }}
        onHide={() => {
          setShowDeleteModal(false);
        }}
      />
    </>
  );
};

export default PetOwnerEmails;
