/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import Picture from "../Picture";
import { PetSpecies } from "../../../models/pet/PetSpeciesEnum";

type Props = {
  border?: boolean;
  borderWidth?: string;
  petId?: string;
  profilePictureId?: string;
  species?: PetSpecies;
};

const PetProfilePicture: React.FC<Props> = ({
  border = true,
  borderWidth,
  petId,
  profilePictureId,
  species,
}: Props) => (
  <Picture
    ownerType="pet"
    border={border}
    borderWidth={borderWidth}
    ownerId={petId}
    pictureId={profilePictureId}
    species={species}
  />
);

export default PetProfilePicture;
