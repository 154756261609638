/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const BiochemistryParams = [
  "ALP",
  "Amylase",
  "Bilirubin",
  "Bilirubin",
  "Cholesterol",
  "Cholesterol",
  "Creatinine",
  "Creatinine",
  "Creatine Kinase",
  "Glucose",
  "Glucose",
  "GGT",
  "GOT(AST)",
  "GPT(ALT) male",
  "GPT(ALT) female",
  "Hemoglobin",
  "Hemoglobin",
  "Potassium",
  "Potassium",
  "Triglyceride",
  "Triglyceride",
  "Urea",
  "Urea",
  "BUN",
  "BUN"
];

export const BiochemistryUnits = [
  "U/I",
  "U/I",
  "mg/dl",
  "µmol/l",
  "mg/dl",
  "mmol/l",
  "mg/dl",
  "µmol/l",
  "U/I",
  "mg/dl",
  "mmol/l",
  "U/I",
  "U/I",
  "U/I",
  "U/I",
  "g/dl",
  "mmol/l",
  "mval/l",
  "mmol/l",
  "mg/dl",
  "mmol/l",
  "mg/dl",
  "mmol/l",
  "mg/dl",
  "mmol/l"
];

interface SpeciesRef {
  [key: string]: Array<{ start: number | null; end: number | null }>;
}

export const BiochemistryReferenceRanges: SpeciesRef = {
  DOG: [
    { start: null, end: 68 },
    { start: null, end: 1289 },
    { start: null, end: 0.5 },
    { start: null, end: 8.6 },
    { start: 110, end: 300 },
    { start: 2.85, end: 7.77 },
    { start: null, end: 1.8 },
    { start: null, end: 159 },
    { start: null, end: 261 },
    { start: 74, end: 126 },
    { start: 4.1, end: 7 },
    { start: null, end: 20 },
    { start: null, end: 43 },
    { start: null, end: 89 },
    { start: null, end: 74 },
    { start: 15, end: 19 },
    { start: 9.3, end: 11.8 },
    { start: 3.5, end: 5.1 },
    { start: 3.5, end: 5.1 },
    { start: null, end: 98 },
    { start: null, end: 1.12 },
    { start: null, end: 54 },
    { start: null, end: 9 },
    { start: null, end: 25.2 },
    { start: null, end: 9 }
  ],
  CAT: [
    { start: null, end: 89 },
    { start: null, end: 1445 },
    { start: null, end: 0.5 },
    { start: null, end: 8.6 },
    { start: 70, end: 150 },
    { start: 1.81, end: 3.88 },
    { start: null, end: 1.8 },
    { start: null, end: 159 },
    { start: null, end: 244 },
    { start: 71, end: 136 },
    { start: 3.9, end: 7.5 },
    { start: null, end: 10 },
    { start: null, end: 78 },
    { start: null, end: 83 },
    { start: null, end: 83 },
    { start: 8, end: 17 },
    { start: 5, end: 10.5 },
    { start: 3, end: 4.8 },
    { start: 3, end: 4.8 },
    { start: null, end: 134 },
    { start: null, end: 1.53 },
    { start: null, end: 82 },
    { start: null, end: 13.7 },
    { start: null, end: 38.3 },
    { start: null, end: 13.7 }
  ],
  HORSE: [
    { start: null, end: 285 },
    { start: null, end: null },
    { start: null, end: 2.8 },
    { start: null, end: 47.9 },
    { start: 70, end: 180 },
    { start: 1.81, end: 4.66 },
    { start: null, end: 2 },
    { start: null, end: 177 },
    { start: null, end: 151 },
    { start: 77, end: 132 },
    { start: 4.3, end: 7.3 },
    { start: null, end: 23 },
    { start: null, end: 333 },
    { start: null, end: 17 },
    { start: null, end: 17 },
    { start: 11, end: 18 },
    { start: 6.8, end: 11.2 },
    { start: 2.8, end: 4.5 },
    { start: 2.8, end: 4.5 },
    { start: null, end: 50 },
    { start: null, end: 0.57 },
    { start: null, end: 49 },
    { start: null, end: 8.2 },
    { start: null, end: 22.9 },
    { start: null, end: 8.2 }
  ],
  RABBIT: [
    { start: null, end: null },
    { start: null, end: null },
    { start: null, end: 0.5 },
    { start: null, end: 8.6 },
    { start: null, end: null },
    { start: null, end: null },
    { start: 0.8, end: 2.9 },
    { start: 61, end: 211 },
    { start: null, end: null },
    { start: 120, end: 170 },
    { start: 6.7, end: 9.4 },
    { start: 12, end: 61 },
    { start: 18, end: 56 },
    { start: 34, end: 123 },
    { start: null, end: null },
    { start: 8, end: 14 },
    { start: 5, end: 8.7 },
    { start: null, end: null },
    { start: null, end: null },
    { start: 80, end: 250 },
    { start: 0.91, end: 2.85 },
    { start: 20, end: 60 },
    { start: 3.3, end: 10 },
    { start: 9.4, end: 28 },
    { start: 3.3, end: 10 }
  ]
};

export default {
  BiochemistryParams,
  BiochemistryUnits,
  BiochemistryReferenceRanges
};
