/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { ExtraCost } from "../../../models/medical/ExtraCost";
import { MedicalTreatmentResponse } from "../../../models/medical/MedicalTreatmentResponse";
import { ReservationAccessScope } from "../../../models/reservation/ReservationAccessScope";
import { PrescriptionDocument } from "../../../models/medical/PrescriptionDocument";
import ItemsRow from "./ItemsRow";
import { InventoryRelatedTypes } from "../../../models/medical/local/InventoryRelatedTypes";
import CostCalculator, { InvoiceItem } from "../../../util/CostCalculator";
import { UsedInventoryItemResponse } from "../../../models/medical/UsedInventoryItemResponse";
import NumberFormatter from "../../../util/NumberFormatter";
import { ReservationState } from "../../../models/reservation/ReservationState";
import { MedicalRecordResponse } from "../../../models/medical/MedicalRecordResponse";
import { UsedFeeActions } from "../Reducers/FeeReducer";
import { UsedOtherActions } from "../Reducers/OtherReducer";
import { useClinic } from "../../../contexts/ClinicContext";
import { ClinicFeeType } from "../../../models/clinic/ClinicFeeType";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";

export interface BillingSectionProps {
  accessScope: ReservationAccessScope;
  countryDetails?: CountryDetailsResponse;
  deleteCurrent: (id: string, where: InventoryRelatedTypes) => void;
  deleteTreatment: (treatmentId: string) => void;
  invoiceId?: string;
  medicines: UsedInventoryItemResponse[];
  medicalRecordId?: string;
  otherInventoryItems: UsedInventoryItemResponse[];
  prescriptions: PrescriptionDocument[];
  readonly?: boolean;
  reservationId?: string;
  reservationState?: ReservationState;
  signed: boolean;
  treatments: MedicalTreatmentResponse[];
  update: (
    medicines: UsedInventoryItemResponse[],
    vaccines: UsedInventoryItemResponse[],
    treatments: MedicalTreatmentResponse[],
    otherInventoryItems: UsedInventoryItemResponse[]
  ) => void;
  updateFees: (a: UsedFeeActions) => void;
  updateOthers: (a: UsedOtherActions) => void;
  usedFees: ExtraCost[];
  usedOthers: ExtraCost[];
  updateMedicalRecord(
    reservationState: ReservationState,
    medicalRecord?: MedicalRecordResponse
  ): void;
  vaccines: UsedInventoryItemResponse[];
}

export default function BillingSection(
  props: BillingSectionProps
): ReactElement {
  const {
    accessScope,
    countryDetails,
    deleteCurrent,
    deleteTreatment,
    prescriptions,
    readonly,
    usedFees,
    usedOthers,
    updateOthers,
    updateFees,
  } = props;

  const [signed, setSigned] = useState<boolean>(props.signed);
  const [medicines, setMedicines] = useState<UsedInventoryItemResponse[]>(
    props.medicines
  );
  const [vaccines, setVaccines] = useState<UsedInventoryItemResponse[]>(
    props.vaccines
  );
  const [otherInventoryItems, setOtherInventoryItems] = useState<
    UsedInventoryItemResponse[]
  >(props.otherInventoryItems);
  const [treatments, setTreatments] = useState<MedicalTreatmentResponse[]>(
    props.treatments
  );
  const [clinicPrescriptionFeeVat, setClinicPrescriptionFeeVat] =
    useState<number>(0);
  const { clinic } = useClinic();

  useEffect(() => {
    if (clinic) {
      setClinicPrescriptionFeeVat(
        clinic?.fees?.find((f) => f.type === ClinicFeeType.PRESCRIPTION_FEE)
          ?.vat || 0
      );
    }
  }, [clinic]);

  useEffect(() => setSigned(props.signed), [props.signed]);

  useEffect(() => {
    setMedicines(props.medicines);
    setVaccines(props.vaccines);
    setOtherInventoryItems(props.otherInventoryItems);
    setTreatments(props.treatments);
  }, [props]);

  useEffect(() => {
    props.update(medicines, vaccines, treatments, otherInventoryItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicines, vaccines, treatments, otherInventoryItems]);

  const canEdit = (scope: ReservationAccessScope) =>
    scope === ReservationAccessScope.SIGN ||
    scope === ReservationAccessScope.WRITE;

  const usedItemsToInvoiceItem = (
    input: UsedInventoryItemResponse
  ): InvoiceItem => ({
    price: input.price || 0,
    quantity: input.quantity || 0,
    vat: input.vat,
  });

  const getAllItems = (): InvoiceItem[] => {
    const items: InvoiceItem[] = [];

    medicines.forEach((m: UsedInventoryItemResponse) => {
      items.push(usedItemsToInvoiceItem(m));
    });

    vaccines.forEach((v: UsedInventoryItemResponse) => {
      items.push(usedItemsToInvoiceItem(v));
    });

    otherInventoryItems.forEach((o: UsedInventoryItemResponse) => {
      items.push(usedItemsToInvoiceItem(o));
    });

    treatments.forEach((t: MedicalTreatmentResponse) => {
      const price = t.price || 0;
      const quantity = t.quantity || 0;

      items.push({
        ...t,
        quantity,
        price,
      });
    });

    usedFees.forEach((i) => items.push(i));

    usedOthers.forEach((i) => items.push(i));

    prescriptions.forEach((p: PrescriptionDocument) => {
      items.push({
        price: p.price,
        quantity: 1,
        vat: clinicPrescriptionFeeVat || 0,
      });
    });

    return items;
  };

  const getNewList = (prevState: any[], newValue: any, idx: number) => {
    const list: any[] = [];
    prevState.forEach((state) => {
      if (prevState.indexOf(state) === idx) {
        const posValue = parseFloat(newValue !== "" ? newValue : 0);
        if (posValue >= 0) {
          state.price = posValue;
        }
      }
      list.push(state);
    });
    return list;
  };

  const handleInventoryItemPriceChange = (
    newValue: any,
    idx: number,
    type: InventoryRelatedTypes
  ) => {
    switch (type) {
      case InventoryRelatedTypes.medicines:
        setMedicines(getNewList(medicines, newValue, idx));
        break;
      case InventoryRelatedTypes.vaccines:
        setVaccines(getNewList(vaccines, newValue, idx));
        break;
      case InventoryRelatedTypes.treatments:
        setTreatments(getNewList(treatments, newValue, idx));
        break;
      case InventoryRelatedTypes.otherInventoryItems:
        setOtherInventoryItems(getNewList(otherInventoryItems, newValue, idx));
        break;
      default:
        break;
    }
  };

  const handleMedQuantityChange = (value: any, idx: number) => {
    setMedicines((prevState) => {
      const list: UsedInventoryItemResponse[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const i of prevState) {
        if (prevState.indexOf(i) === idx) {
          i.quantity = value;
        }
        list.push(i);
      }
      return list;
    });
  };

  const handleVacQuantityChange = (value: any, idx: number) => {
    setVaccines((prevState) => {
      const list: UsedInventoryItemResponse[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const i of prevState) {
        if (prevState.indexOf(i) === idx) {
          i.quantity = value;
        }
        list.push(i);
      }
      return list;
    });
  };

  const handleOtherItemQuantityChange = (value: any, idx: number) => {
    setOtherInventoryItems((prevState) => {
      const list: UsedInventoryItemResponse[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const i of prevState) {
        if (prevState.indexOf(i) === idx) {
          i.quantity = value;
        }
        list.push(i);
      }
      return list;
    });
  };

  const handleTreatmentQuantityChange = (quantity: number, idx: number) => {
    setTreatments((prevState) => {
      const list: MedicalTreatmentResponse[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const i of prevState) {
        if (prevState.indexOf(i) === idx) {
          i.quantity = quantity;
        }
        list.push(i);
      }
      return list;
    });
  };

  const editAvailable = !signed && canEdit(accessScope);

  return (
    <>
      <div className="space-y-2">
        <div>
          <div className="flex border-b">
            <p className="uppercase">{strings.treatments}</p>
          </div>
          <div className="divide-y dark:divide-gray-700">
            {treatments.map((treatment, idx) => (
              <div className="py-2" key={`${treatment.id}-${idx}`}>
                <ItemsRow
                  accessScope={accessScope}
                  canEdit={editAvailable}
                  clinic={clinic || undefined}
                  countryDetails={countryDetails}
                  deleteCurrent={() => {
                    deleteTreatment(treatment.id);
                  }}
                  item={treatment}
                  quantityChange={(value) => {
                    handleTreatmentQuantityChange(value, idx);
                  }}
                  priceChange={(value) => {
                    handleInventoryItemPriceChange(
                      value,
                      idx,
                      InventoryRelatedTypes.treatments
                    );
                  }}
                  readonly={readonly}
                />
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="flex border-b">
            <p className="uppercase">{strings.inventoryItems}</p>
          </div>
          <div className="divide-y dark:divide-gray-700">
            {medicines.map((med, idx) => (
              <div className="py-2" key={`${med.name}:${idx}`}>
                <ItemsRow
                  accessScope={accessScope}
                  canEdit={editAvailable}
                  clinic={clinic || undefined}
                  countryDetails={countryDetails}
                  item={med}
                  deleteCurrent={() => {
                    deleteCurrent(med.id, InventoryRelatedTypes.medicines);
                  }}
                  quantityChange={(value) => {
                    handleMedQuantityChange(value, idx);
                  }}
                  priceChange={(value) => {
                    handleInventoryItemPriceChange(
                      value,
                      idx,
                      InventoryRelatedTypes.medicines
                    );
                  }}
                  readonly={readonly}
                />
              </div>
            ))}
            {vaccines.map((vac, idx) => (
              <div className="py-2" key={`${vac.name}:${idx}`}>
                <ItemsRow
                  accessScope={accessScope}
                  canEdit={editAvailable}
                  clinic={clinic || undefined}
                  countryDetails={countryDetails}
                  item={vac}
                  deleteCurrent={() => {
                    deleteCurrent(vac.id, InventoryRelatedTypes.vaccines);
                  }}
                  quantityChange={(value) => {
                    handleVacQuantityChange(value, idx);
                  }}
                  priceChange={(value) => {
                    handleInventoryItemPriceChange(
                      value,
                      idx,
                      InventoryRelatedTypes.vaccines
                    );
                  }}
                  readonly={readonly}
                />
              </div>
            ))}
            {otherInventoryItems.map((inv, idx) => (
              <div className="py-2" key={`${inv.name}:${idx}`}>
                <ItemsRow
                  accessScope={accessScope}
                  canEdit={editAvailable}
                  clinic={clinic || undefined}
                  countryDetails={countryDetails}
                  item={inv}
                  deleteCurrent={() => {
                    deleteCurrent(
                      inv.id,
                      InventoryRelatedTypes.otherInventoryItems
                    );
                  }}
                  quantityChange={(value) => {
                    handleOtherItemQuantityChange(value, idx);
                  }}
                  priceChange={(value) => {
                    handleInventoryItemPriceChange(
                      value,
                      idx,
                      InventoryRelatedTypes.otherInventoryItems
                    );
                  }}
                  readonly={readonly}
                />
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="flex border-b">
            <p className="uppercase">{strings.fees}</p>
          </div>
          <div className="divide-y dark:divide-gray-700">
            {usedFees.map((extra: ExtraCost, idx: number) => (
              <div className="py-2" key={`${extra.name}-${idx}`}>
                <ItemsRow
                  accessScope={accessScope}
                  canEdit={editAvailable}
                  clinic={clinic || undefined}
                  countryDetails={countryDetails}
                  item={extra}
                  deleteCurrent={() => {
                    updateFees({
                      type: "remove",
                      itemToRemove: extra,
                    });
                  }}
                  quantityChange={(value) => {
                    updateFees({
                      type: "changeQuantity",
                      itemToChange: extra,
                      newQuantity: value,
                    });
                  }}
                  priceChange={(value) => {
                    updateFees({
                      type: "changePrice",
                      itemToChange: extra,
                      newPrice: value,
                    });
                  }}
                  readonly={readonly}
                />
              </div>
            ))}
            {prescriptions.map((p, idx) => (
              <div className="py-2" key={`${p.prescriptionId}_${idx}`}>
                <div className="flex items-center">
                  <div className="font-bold mr-3">{p.description}</div>
                </div>
                <div className="flex items-center text-gray-500">
                  <div>
                    {strings.subtotal} ({strings.gross})
                  </div>
                  <div className="ml-auto">
                    {NumberFormatter.formatPrice(
                      CostCalculator.getGrossPrice(
                        p.price,
                        clinicPrescriptionFeeVat,
                        countryDetails,
                        clinic || undefined
                      ),
                      countryDetails?.currency
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="flex border-b">
            <p className="uppercase">{strings.other}</p>
          </div>
          <div className="divide-y dark:divide-gray-700">
            {usedOthers.map((extra: ExtraCost, idx: number) => (
              <div className="py-2" key={`${extra.name}-${idx}`}>
                <ItemsRow
                  accessScope={accessScope}
                  canEdit={editAvailable}
                  clinic={clinic || undefined}
                  countryDetails={countryDetails}
                  item={extra}
                  deleteCurrent={() => {
                    updateOthers({
                      type: "remove",
                      itemToRemove: extra,
                    });
                  }}
                  quantityChange={(value) => {
                    updateOthers({
                      type: "changeQuantity",
                      itemToChange: extra,
                      newQuantity: value,
                    });
                  }}
                  priceChange={(value) => {
                    updateOthers({
                      type: "changePrice",
                      itemToChange: extra,
                      newPrice: value,
                    });
                  }}
                  readonly={readonly}
                />
              </div>
            ))}
          </div>
        </div>
        <div hidden={accessScope === ReservationAccessScope.PEEK}>
          <div className="flex">
            <div className="font-bold uppercase">
              {strings.total} ({strings.gross})
            </div>
            <div className="ml-auto">
              <div className="font-bold">
                {NumberFormatter.formatPrice(
                  CostCalculator.getTotalGrossPriceOfInvoiceItems(
                    getAllItems(),
                    countryDetails,
                    clinic || undefined
                  ),
                  countryDetails?.currency
                )}
              </div>
              <div className="text-xs text-gray-500">
                {NumberFormatter.formatPrice(
                  CostCalculator.getTotalNetPriceOfInvoiceItems(
                    getAllItems(),
                    countryDetails,
                    clinic || undefined
                  ),
                  countryDetails?.currency
                )}{" "}
                {strings.plusVat}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

BillingSection.defaultProps = {
  currency: undefined,
};
