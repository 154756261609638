/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useRef } from "react";
import { strings } from "../../../../common/Strings/Strings";
import CodeInputsForm, {
  CodeInputsFormRef,
} from "../../../ReactHookFormFields/Specific/CodeInputsForm";
import { PhoneResponse } from "../../../../models/contact/PhoneResponse";
import { Phone } from "../../../../common/Icons/Phone";
import AlertBox, { AlertType } from "../../../AlertBox";
import Button from "../../../Button";

interface Props {
  alert?: string | null;
  alertType?: AlertType;
  loading: boolean;
  onResendCode?(): void;
  onSubmitCode(smsCode: string): void;
  phoneToConfirm: PhoneResponse | null;
  resendVisible?: boolean;
}

const VerifyPhone: React.FC<Props> = ({
  alert,
  alertType,
  loading,
  onResendCode,
  onSubmitCode,
  phoneToConfirm,
  resendVisible = true,
}: Props) => {
  const codeInputRef = useRef<CodeInputsFormRef>(null);
  return (
    <>
      <div className="flex items-center space-x-2">
        <Phone />
        <div className="font-semibold">{strings.enterVerificationCode}</div>
      </div>
      <div>
        <p>
          {strings.formatString(
            strings.verificationCodeSentTo,
            <strong>{phoneToConfirm?.value}</strong>
          )}
        </p>
      </div>
      <CodeInputsForm
        disableNotNumberCharacters={false}
        label={strings.verificationCode}
        readOnly={loading}
        size={6}
        submit={onSubmitCode}
        ref={codeInputRef}
      />
      <AlertBox className="my-5" message={alert} type={alertType} />
      <div className="flex items-center justify-between">
        <Button
          hidden={!resendVisible}
          onClick={() => {
            onResendCode && onResendCode();
            codeInputRef.current && codeInputRef.current.resetForm();
          }}
          variant="link"
        >
          {strings.resendVerificationCode}
        </Button>
      </div>
    </>
  );
};

export default VerifyPhone;
