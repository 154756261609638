/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, ReactNode, useState } from "react";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import { strings } from "../../../../common/Strings/Strings";
import { useClinic } from "../../../../contexts/ClinicContext";
import PetOwnerApi from "../../../../api/PetOwnerApi";
import { NewPetOwnerRequest } from "../../../../models/pet/NewPetOwnerRequest";
import { getGeneralError } from "../../../../util/helperFunctions";
import { PetOwnerResponse } from "../../../../models/pet/PetOwnerResponse";
import ClinicPetOwnerForm1, {
  ClinicPetOwnerForm1Parts,
} from "./ClinicPetOwnerForm1";
import Button from "../../../../components/Button";
import ClinicPetOwnerForm2, {
  ClinicPetOwnerForm2Parts,
} from "./ClinicPetOwnerForm2";

export type PetOwnerFullForm = ClinicPetOwnerForm1Parts &
  ClinicPetOwnerForm2Parts;

const CreatePetOwner = (): ReactElement => {
  const [updateResponse, setUpdateResponse] = useState<{
    message: string | null;
    type: AlertType;
  } | null>(null);
  const [isUpdateSubmitting, setIsUpdateSubmitting] = useState<boolean>(false);
  const [step, setStep] = useState<1 | 2>(1);
  const { clinic } = useClinic();

  const petOwnerFormMethods = useForm<PetOwnerFullForm>({
    mode: "onChange",
  });
  const { handleSubmit, trigger } = petOwnerFormMethods;

  const navigate = useNavigate();

  const submitNewOwner = async ({
    city,
    country,
    ownerDateOfBirth,
    email,
    firstName,
    houseDetails,
    lastName,
    middleName,
    mobilePhone,
    ownerComment,
    personalId,
    prefix,
    street,
    zip,
  }: PetOwnerFullForm) => {
    if (clinic) {
      const request: NewPetOwnerRequest = {
        address: {
          city,
          countryCode: country[0].countryCode,
          street,
          houseDetails,
          zip,
        },
        comment: ownerComment,
        dateOfBirth: ownerDateOfBirth ? moment(ownerDateOfBirth).format("yyyy-MM-DD") : undefined,
        email: email?.length === 0 ? undefined : email,
        firstName,
        lastName,
        middleName: middleName?.length === 0 ? undefined : middleName,
        mobilePhone,
        personalId,
        prefix: prefix?.length === 0 ? undefined : prefix,
      };

      try {
        setIsUpdateSubmitting(true);
        const response: AxiosResponse<PetOwnerResponse> =
          await PetOwnerApi.createPetOwner(clinic.id, request);
        navigate(`/pet-owner-profile/${response.data.petOwnerDetails.id}`, {
          replace: true,
        });
      } catch (e) {
        setUpdateResponse({
          message: await getGeneralError(e),
          type: AlertType.ERROR,
        });
      } finally {
        setIsUpdateSubmitting(false);
      }
    }
  };

  const submitSection = (): ReactNode => {
    if (step === 1) {
      return (
        <div className="flex flex-col space-y-4">
          <div className="w-full">
            <Button
              onClick={() => {
                const response = trigger([
                  "firstName",
                  "lastName",
                  "prefix",
                  "middleName",
                  "city",
                  "zip",
                  "country",
                  "street",
                  "houseDetails",
                ]);

                response.then((isValid: boolean) => {
                  if (isValid) {
                    setStep(2);
                  }
                });
              }}
            >
              {strings.saveAndContinue}
            </Button>
          </div>
          <Button
            variant="link"
            onClick={() => {
              navigate(-1);
            }}
          >
            {strings.back}
          </Button>
        </div>
      );
    } 
      return (
        <div className="flex flex-col space-y-4">
          <div className="w-full">
            <Button
              onClick={handleSubmit(submitNewOwner)}
              loading={isUpdateSubmitting}
            >
              {strings.saveAndCreate}
            </Button>
          </div>
          <Button
            variant="link"
            onClick={() => {
              setStep(1);
            }}
          >
            {strings.back}
          </Button>
        </div>
      );
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <div className="w-full md:max-w-md md:tw-card space-y-6 md:p-8">
            <div className="flex justify-between space-x-4">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.createPetOwner}
              </h1>
              <span className="text-gray-500 dark:text-gray-400">{step}/2</span>
            </div>
            <FormProvider {...petOwnerFormMethods}>
              <form>
                <div hidden={!(step === 1)}>
                  <ClinicPetOwnerForm1/>
                </div>
                <div hidden={!(step === 2)}>
                  <ClinicPetOwnerForm2 />
                </div>
                <AlertBox
                  message={updateResponse?.message}
                  type={updateResponse?.type}
                  className="mt-6"
                />
              </form>
            </FormProvider>
            {submitSection()}
          </div>
        </div>
      </section>
    </main>
  );
};

export default CreatePetOwner;
