/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment } from "react";
import { MedicinalProduct } from "../../../../models/ePrescription/VARA/MedicinalProduct";
import ProductDetailSection from "../../productDetails/ProductDetailSection";
import { PrescriptionItemFrontend } from "../../../../models/ePrescription/local/PrescriptionItemFrontend";
import { PackageType } from "../../../../models/ePrescription/VARA/PackageType";
import VaraProductDetailSection from "../../../Inventory/CreateItem/VARA/VaraProductDetailSection";
import { VaraItem } from "../../../../models/inventory/item/VaraItem";

interface Props {
  addPrescriptionItem?(p: PrescriptionItemFrontend): void;
  addPackageToInventory?(p: VaraItem): void;
  inventory: boolean;
  isOnlyLicensed: boolean;
  medicine: MedicinalProduct;
  packageTypes: PackageType[];
}

const SelectedProduct: React.FC<Props> = ({
  addPrescriptionItem,
  addPackageToInventory,
  inventory,
  isOnlyLicensed,
  medicine,
  packageTypes,
}: Props) => {
  const addToPrescription = (newItem: PrescriptionItemFrontend) => {
    if (addPrescriptionItem) {
      setTimeout(() => {
        addPrescriptionItem(newItem);
      }, 100);
    }
  };

  return (
    <Fragment>
      {inventory ? (
        <VaraProductDetailSection
          medicalProduct={medicine}
          packageTypes={packageTypes}
          addPackageToInventory={addPackageToInventory}
        />
      ) : (
        <ProductDetailSection
          medicalProduct={medicine}
          addPrescription={addToPrescription}
          packageTypes={packageTypes}
          isVaraLicensed={isOnlyLicensed}
          productType={medicine.productType}
        />
      )}
    </Fragment>
  );
};

export default SelectedProduct;
