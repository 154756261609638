/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { getGeneralError } from "../../../../util/helperFunctions";
import VerifyPhone from "./VerifyPhone";
import { PhoneResponse } from "../../../../models/contact/PhoneResponse";
import { useUser } from "../../../../contexts/UserContext";
import { Card } from "../../../Cards/Card";
import { strings } from "../../../../common/Strings/Strings";
import UserContactsApi from "../../../../api/UserContactsApi";
import { AlertType } from "../../../AlertBox";

interface Props {
  onNextStep: (skip: boolean) => void;
  phoneResponse: PhoneResponse | null;
  skippable?: boolean;
}

const UserPhoneVerification = ({
  onNextStep,
  phoneResponse,
  skippable,
}: Props): ReactElement => {
  const { fetchUser } = useUser();

  const [resendVisible, setResendVisible] = useState<boolean>(true);

  const [alert, setAlert] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.ERROR);
  const [loading, setLoading] = useState<boolean>(false);

  const submitVerificationCode = async (smsCode: string) => {
    if (!phoneResponse) return;
    setLoading(true);
    setAlert(null);

    try {
      await UserContactsApi.confirmUserPhoneInstantly(phoneResponse.id, {
        smsCode,
      });
      await fetchUser();
      onNextStep(false);
    } catch (err) {
      setAlert(await getGeneralError(err));
      setAlertType(AlertType.ERROR);
      setResendVisible(true);
    } finally {
      setLoading(false);
    }
  };

  const resendVerificationCode = async () => {
    if (phoneResponse) {
      setLoading(true);
      setAlert(null);

      try {
        await UserContactsApi.sendUserPhoneConfirmation(phoneResponse.id);
        setAlert(`${strings.phoneCodeSent} ${phoneResponse.value}.`);
        setAlertType(AlertType.SUCCESS);
      } catch (err) {
        setAlert(await getGeneralError(err));
        setAlertType(AlertType.ERROR);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card
      onAction={skippable ? () => onNextStep(true) : undefined}
      title={strings.verifyYourPhone}
    >
      <VerifyPhone
        alert={alert}
        alertType={alertType}
        loading={loading}
        onResendCode={resendVerificationCode}
        onSubmitCode={submitVerificationCode}
        phoneToConfirm={phoneResponse}
        resendVisible={resendVisible}
      />
    </Card>
  );
};

export default UserPhoneVerification;
