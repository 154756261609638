/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { CustomFlowbiteTheme } from "flowbite-react";

export const customTheme: CustomFlowbiteTheme = {
  navbar: {
    root: {
      base: "fixed z-30 top-0 left-0 w-full h-14 px-4 lg:px-6 flex items-center bg-slate-500 dark:bg-gray-800 dark:border-b dark:border-gray-700",
      rounded: {
        on: "rounded",
        off: "",
      },
      bordered: {
        on: "border",
        off: "",
      },
      inner: {
        base: "relative container flex flex-wrap items-center justify-between mx-auto px-0",
        fluid: {
          on: "",
          off: "container",
        },
      },
    },
    brand: {
      base: "h-14 flex items-center no-underline",
    },
    collapse: {
      base: "w-full lg:block lg:w-auto",
      list: "lg:relative flex lg:items-center flex-col lg:flex-row lg:space-x-3 lg:space-x-6 lg:text-md lg:font-medium h-full",
      hidden: {
        on: "hidden",
        off: "fixed z-40 top-14 left-0 w-full h-screen pb-14 pt-4 px-4 lg:px-6 flex flex-col justify-between bg-slate-500 dark:bg-slate-800",
      },
    },
    link: {
      base: "",
      active: {
        on: "relative flex items-center flex-row space-x-3 lg:space-x-6 lg:text-md lg:font-medium",
        off: "block py-3 text-white transition-all duration-300 link",
      },
      disabled: {
        on: "text-gray-400 hover:cursor-not-allowed dark:text-gray-600",
        off: "",
      },
    },
    toggle: {
      base: "rounded-lg inline-flex lg:hidden items-center p-2 text-sm text-white hover:bg-slate-600 focus:bg-slate-600 focus:outline-none dark:text-white dark:hover:bg-slate-700 dark:focus:bg-slate-700 transition-all duration-300",
      icon: "h-7 w-7",
    },
  },
  dropdown: {
    arrowIcon: "hidden",
    content: "py-2 text-md text-gray-700 dark:text-gray-400",
    floating: {
      animation: "transition-opacity",
      arrow: {
        base: "absolute z-10 h-2 w-2 rotate-45",
        style: {
          dark: "bg-gray-900 dark:bg-gray-700",
          light: "bg-white",
          auto: "bg-white dark:bg-gray-700",
        },
        placement: "-4px",
      },
      base: "z-30 shadow rounded-lg w-44 bg-white font-normal divide-y divide-gray-100 dark:bg-gray-700 dark:divide-gray-600 block",
      content: "py-1 text-sm text-gray-700 dark:text-gray-200",
      hidden: "invisible opacity-0",
      item: {
        container: "",
        base: "w-full active flex justify-between items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white",
        icon: "mr-2 h-4 w-4",
      },
      style: {
        dark: "bg-gray-900 text-white dark:bg-gray-700",
        light: "border border-gray-200 bg-white text-gray-900",
        auto: "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white",
      },
      target: "w-fit",
    },
    inlineWrapper:
      "flex items-center space-x-1 w-full lg:w-auto lg:py-2 lg:pl-3 lg:pr-4 lg:p-0 text-gray-100 hover:text-white focus:text-white dark:text-gray-300 dark:hover:text-white dark:focus:text-white transition-all duration-300",
  },
};

export const NavMenuLinkClasses = {
  normal: {
    active:
      "active border-b-2 pt-0.5 h-14 flex items-center text-sky-500 dark:text-sky-400 transition-all duration-300",
    simple:
      "border-b-2 pt-0.5 border-transparent hover:border-gray-200 h-14 flex items-center text-gray-600 hover:text-gray-700 dark:hover:border-gray-700 dark:text-gray-300 dark:hover:text-gray-200 transition-all duration-300",
  },
  mobile: {
    active: "pt-px text-sky-500 dark:text-white mb-6",
    simple: "pt-px text-gray-500 dark:text-gray-400 mb-6",
  },
  mobileMore: {
    active: "space-x-4 text-sky-500 dark:text-white",
    simple: "space-x-4 text-gray-500 dark:text-gray-400",
  },
};
