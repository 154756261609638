/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { strings } from "../../../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import { useDebouncedState } from "../../../../../hooks/hooks";
import LoaderInline from "../../../../../components/LoaderInline";
import ServiceFeeApi from "../../../../../api/ServiceFeeApi";
import { ServiceFeeResponse } from "../../../../../models/servicefee/ServiceFeeResponse";
import FeeResultRow from "./FeeResultRow";
import { useClinic } from "../../../../../contexts/ClinicContext";
import { ClinicFeeType } from "../../../../../models/clinic/ClinicFeeType";
import { getGeneralError } from "../../../../../util/helperFunctions";
import AlertBox from "../../../../../components/AlertBox";
import { CartItem } from "../../../../../util/InvoiceCartUtils";
import EmptyListText from "../../../../../components/EmptyListText";
import { CountryDetailsResponse } from "../../../../../models/management/CountryDetailsResponse";

interface Props {
  addCustomCost(): void;
  addToCart(item: CartItem): void;
  countryDetails?: CountryDetailsResponse;
}

const AddFees = ({
  addCustomCost,
  addToCart,
  countryDetails,
}: Props): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceFees, setServiceFees] = useState<ServiceFeeResponse[]>([]);
  const [filteredServiceFees, setFilteredServiceFees] = useState<
    ServiceFeeResponse[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const { clinic } = useClinic();

  const productSearchParams = useDebouncedState<string>("", 600);

  const getServiceFees = async () => {
    if (clinic) {
      try {
        const response = await ServiceFeeApi.getServiceFeesOfClinic(clinic.id);
        const fees = response.data.elements;

        if (clinic) {
          const prescriptionFee = clinic.fees.find(
            (pf) => pf.type === ClinicFeeType.PRESCRIPTION_FEE
          );

          if (prescriptionFee) {
            fees.push({
              name: strings.prescription,
              price: prescriptionFee.amount,
              vat: prescriptionFee.vat || 0,
              accountingCode: prescriptionFee.accountingCode,
              id: moment().valueOf().toString(),
            });
          }
        }

        setServiceFees(fees);
        setFilteredServiceFees(
          fees.sort((a, b) => a.name.localeCompare(b.name))
        );
      } catch (err) {
        setError(await getGeneralError(err));
      }
    }
  };

  useEffect(() => {
    void getServiceFees();

    /* eslint-disable-next-line */
  }, [clinic]);

  useEffect(() => {
    setFilteredServiceFees(
      serviceFees.filter((fee) =>
        fee.name.toLowerCase().includes(productSearchParams.value)
      )
    );
    setLoading(false);
  }, [productSearchParams.value, serviceFees]);

  return (
    <>
      <div className="py-4">
        <div className="w-64">
          <div className="flex justify-between">
            <label htmlFor="search">{strings.search}</label>
            <div
              aria-hidden
              role="button"
              className="text-sky-500"
              onClick={() => {
                addCustomCost();
              }}
              style={{ cursor: "pointer", fontSize: "12px" }}
            >
              <b>{strings.addNew}</b>
            </div>
          </div>
          <input
            autoComplete={AutoCompleteOptions.off}
            className="tw-input"
            name="query"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              productSearchParams.setValue(e.target.value);
              setLoading(true);
            }}
            placeholder={strings.search}
            type="text"
          />
        </div>
      </div>
      <div>
        <AlertBox message={error} className="mb-3" />
        <div className="tw-table-container">
          <table className="tw-table">
            <thead className="tw-thead">
              <tr>
                <th className="tw-th">{strings.serviceFees}</th>
                <th className="tw-th">
                  {strings.price} ({strings.net})
                </th>
                <th className="tw-th">{strings.vat}</th>
                <th className="tw-th">
                  {strings.price} ({strings.gross})
                </th>
                <th className="tw-th">{strings.quantity}</th>
                <th className="tw-th" />
              </tr>
            </thead>
            <tbody>
              {" "}
              {loading ? (
                <tr>
                  <td
                    colSpan={6}
                    className="px-4 py-4 text-sm font-medium whitespace-nowrap"
                  >
                    <LoaderInline />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {!loading && filteredServiceFees.length === 0 ? (
                <tr>
                  <td
                    colSpan={6}
                    className="px-4 py-4 text-sm font-medium whitespace-nowrap"
                  >
                    <EmptyListText />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {!loading &&
                filteredServiceFees.map(
                  (result: ServiceFeeResponse, index: number) => (
                    <FeeResultRow
                      addToCart={addToCart}
                      key={result.id}
                      index={index}
                      result={result}
                      clinic={clinic || undefined}
                      country={countryDetails}
                    />
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AddFees;
