/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { MedicalRecordVersionResponse } from "../../../models/medical/MedicalRecordVersionResponse";
import { MedicalRecordResponse } from "../../../models/medical/MedicalRecordResponse";
import { downloadMedicalRecordPdfForReservation } from "../../../util/FileUtils";
import { MedicalRecordsMailResponse } from "../../../models/medical/mail/MedicalRecordsMailResponse";
import { MedicalRecordsMailRecipientResponse } from "../../../models/medical/mail/MedicalRecordsMailRecipientResponse";
import Button from "../../../components/Button";
import { Assignment as AssignmentIcon } from "../../../common/Icons/Assignment";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";

interface Props {
  medicalRecord?: MedicalRecordResponse;
  showLink?: boolean;
  title: string;
  version?: MedicalRecordVersionResponse;
}

const Signed = ({
  medicalRecord,
  showLink = false,
  title,
  version,
}: Props): ReactElement => (
  <>
    {version?.sentMails?.reverse().map((mail: MedicalRecordsMailResponse) => (
      <li className="flex flex-col space-y-4">
        <div className="flex justify-between">
          <div className="text-base font-bold text-zinc-800 dark:text-gray-100">
            {strings.mailedTo}
          </div>
          <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {mail.dateTime &&
              moment(mail.dateTime).format(dateAndTime.momentDateTimeFormat)}
          </div>
        </div>
        <div className="flex flex-col">
          {mail?.recipients?.map(
            (r: MedicalRecordsMailRecipientResponse, index) => (
              <span className="font-medium text-base text-zinc-800 dark:text-white">
                {r.email}
                {r.insuranceCompany ? ` (${r.insuranceCompany.name})` : ""}
                {mail?.recipients[index + 1] ? ", " : ""}
              </span>
            )
          )}
        </div>
        <hr className="border-gray-200 dark:border-gray-600" />
      </li>
    ))}
    <li className="flex flex-col space-y-4">
      <div className="flex justify-between">
        <div className="text-base font-bold text-zinc-800 dark:text-gray-100">
          {title}
        </div>
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {version?.signDate &&
            moment(version?.signDate).format(dateAndTime.momentDateTimeFormat)}
        </div>
      </div>
      <div className="flex space-x-2 items-center">
        <div className="relative rounded-full border-2 border-gray-100 bg-white w-8 h-8 dark:border-gray-600">
          <UserProfilePicture userId={version?.signedBy?.userId} />
        </div>
        <span className="font-medium text-base text-zinc-800 dark:text-white">
          {version?.signedBy?.fullName}
        </span>
      </div>
      {showLink && (
        <div className="flex space-x-2 items-center">
          <Link to={`/medical-record/${version?.id}`}>
            <Button variant="link-tertiary">{strings.openMedicalRecord}</Button>
          </Link>
        </div>
      )}
      <div className="flex space-x-2 items-center">
        <AssignmentIcon />
        <Button
          variant="link-tertiary"
          onClick={() => {
            if (medicalRecord && version) {
              void downloadMedicalRecordPdfForReservation(
                medicalRecord.referenceId,
                version.id
              );
            }
          }}
        >
          {strings.downloadPDF}
        </Button>
      </div>
    </li>
    <hr className="border-gray-200 dark:border-gray-600" />
  </>
);

export default Signed;
