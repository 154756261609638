/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../AlertBox";
import AddressForm, {
  CreateSimpleAddressForm,
} from "../../contact/address/AddressForm";
import { AddressRequest } from "../../../models/contact/AddressRequest";
import Button from "../../Button";
import Modal from "../../Modal/Modal";

interface Props {
  close(): void;
  error: string | null;
  isOpen: boolean;
  loading: boolean;
  onSubmit(values: AddressRequest): void;
}

const AddAddressModal: React.FC<Props> = ({
  close,
  error,
  isOpen,
  loading,
  onSubmit,
}: Props) => {
  const addressFormMethods = useForm<CreateSimpleAddressForm>({
    mode: "onChange",
  });

  const {
    getValues,
    formState: { isValid },
    reset,
  } = addressFormMethods;

  const addNewAddress = () => {
    const { city, countries, houseDetails, street, zip } = getValues();
    onSubmit({
      city,
      countryCode: countries[0].countryCode,
      houseDetails,
      street,
      zip,
    });
    reset();
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.addAddress} />
      <Modal.Body>
        <FormProvider {...addressFormMethods}>
          <AddressForm />
        </FormProvider>
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || loading}
          loading={loading}
          onClick={addNewAddress}
        >
          {strings.save}
        </Button>
        <Button
          className="modal-sub-button"
          disabled={loading}
          onClick={close}
          variant="secondary"
        >
          {strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAddressModal;
