/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { FieldError } from "react-hook-form";
import { Tooltip } from "../Tooltip";
import { Colors } from "../../models/Colors";
import { strings } from "../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import ClickOutsideDetector from "../ClickOutsideDetector";
import Tag from "../Tag";
import LoaderInline from "../LoaderInline";
import { XMark } from "../../common/Icons/XMark";
import { InfoFilled } from "../../common/Icons/InfoFilled";
import { arrowDownIcon } from "../../assets/AssetConfig";
import Button from "../Button";
import { useModal } from "../../contexts/ModalContext";
import { combineClassNames } from "../../util/HtmlUtils";

interface AnyMap {
  [key: string]: any;
}

type T = AnyMap | string | number;
type LabelKeyFunction = (item: T) => string;

export interface GroupedOption<Z> {
  groupTitle?: string;
  groupOptions: Z[];
}

export interface CombinedSelectInputProps {
  error?: FieldError;
  onChange(e: T[] | null): void;
  value: T[] | null;
}

interface DynamicSearchProps {
  isDynamic?: boolean;
  loadingOptions?: boolean;
}

export interface CombinedSelectComponentProps {
  allowNew?: boolean;
  allowNewPropName?: string;
  name: string;
  classLabel?: string;
  className?: string;
  clearButton?: boolean;
  fixOptions?: T[];
  groupedByOptions?: Array<GroupedOption<T>>;
  isTypeahead?: boolean;
  label?: string;
  labelIcon?: string;
  labelKey?: string | LabelKeyFunction;
  labelOff?: boolean;
  maxResults?: number;
  multiple?: boolean;
  onClear?(): void;
  onInputValueChange?: (e: string) => void;
  optional?: boolean;
  options?: T[];
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  scrollToListInModal?: boolean;
  selectedKey?: LabelKeyFunction;
  showRequired?: boolean;
  toolTipText?: string | ReactElement;
  width?: string;
}

const CombinedSelectComponent = ({
  allowNew = true,
  allowNewPropName,
  name,
  options,
  classLabel,
  className,
  clearButton = true,
  error,
  fixOptions,
  groupedByOptions,
  isDynamic = false,
  isTypeahead = true,
  label,
  labelIcon,
  labelKey,
  labelOff,
  loadingOptions = false,
  maxResults: mr = 50,
  multiple,
  onChange,
  onClear,
  onInputValueChange,
  optional,
  placeholder = isTypeahead ? strings.search : label,
  readOnly,
  required,
  scrollToListInModal = true,
  selectedKey,
  showRequired,
  toolTipText,
  value = null,
  width,
}: CombinedSelectComponentProps &
  CombinedSelectInputProps &
  DynamicSearchProps): ReactElement => {
  const [isListOpen, setIsListOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<T[]>([]);
  const [filteredGroupedOptions, setFilteredGroupedOptions] = useState<
    GroupedOption<T>[]
  >([]);
  const [maxResults, setMaxResults] = useState<number>(mr);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { openModalIds } = useModal();

  const scrollToTarget = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getDisplayValue = (item: T): string => {
    if (typeof item === "string") {
      return item;
    }
    if (typeof item === "number") {
      return item.toString();
    }
    if (labelKey) {
      if (typeof labelKey === "string") {
        return item[labelKey];
      }
      return labelKey(item);
    }
    return JSON.stringify(item);
  };

  const getCustomValue = (customValue: string): T[] => {
    if (allowNewPropName) {
      return [{ [allowNewPropName]: customValue }];
    }
    if (labelKey) {
      if (typeof labelKey === "string") {
        return [{ [labelKey]: customValue }];
      }
      return [{ customValue }];
    }
    return [customValue];
  };

  useEffect(() => {
    if (!multiple && value && value[0]) {
      setInputValue(
        selectedKey ? selectedKey(value[0]) : getDisplayValue(value[0])
      );
    } else {
      setInputValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (options) {
      if (isDynamic) {
        setFilteredOptions(options);
      } else {
        setFilteredOptions(
          options
            .filter(
              (o: T) =>
                getDisplayValue(o)
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                inputValue === "" ||
                !isTypeahead
            )
            .slice(0, maxResults)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxResults, inputValue, isTypeahead, options]);

  useEffect(() => {
    if (groupedByOptions) {
      const newList: GroupedOption<T>[] = [];
      let rowCnt = 0;

      groupedByOptions.forEach((go: GroupedOption<T>) => {
        rowCnt += go.groupOptions.length;

        newList.push({
          ...go,
          groupOptions: go.groupOptions
            .filter(
              (o) =>
                getDisplayValue(o)
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                inputValue === "" ||
                !isTypeahead
            )
            .slice(
              0,
              rowCnt >= maxResults
                ? go.groupOptions.length - (rowCnt - maxResults)
                : go.groupOptions.length
            ),
        });
      });

      setFilteredGroupedOptions(newList.slice(0, maxResults));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxResults, inputValue, isTypeahead, groupedByOptions]);

  const listLength = (): number => {
    if (groupedByOptions) {
      let result = 0;
      filteredGroupedOptions.forEach((go) => {
        result += go.groupOptions.length;
      });

      return result;
    }
    return filteredOptions.length;
  };

  return (
    <ClickOutsideDetector
      listen
      onClickOutside={() => {
        setIsListOpen(false);
        if (!value) {
          setInputValue("");

          if (onClear) {
            onClear();
          }
        }
      }}
    >
      <div
        className="dropdown"
        onClick={() => {
          // Auto scroll to component on click (only in modals)
          if (scrollToListInModal && openModalIds.length !== 0) {
            setTimeout(() => scrollToTarget(wrapperRef), 50);
          }
        }}
        role="button"
        tabIndex={0}
        ref={wrapperRef}
        style={{ width }}
      >
        <div className={error ? "has-error" : ""}>
          {labelOff ? (
            <></>
          ) : (
            <label className={classLabel}>
              {labelIcon ? <i className={labelIcon} /> : ""} {label}
              {required && showRequired ? " *" : ""}
              {optional ? (
                <span style={{ color: Colors.INPUTBORDER }}>
                  {" "}
                  ({strings.optional})
                </span>
              ) : (
                <></>
              )}
            </label>
          )}
          <div
            className={combineClassNames("relative w-full", className)}
            role="button"
            tabIndex={0}
          >
            {multiple && value && value?.length > 0 && (
              <div className="tw-input flex flex-wrap border-b-0 rounded-b-none">
                {value.map((v: T, index) => (
                  <div className="flex mr-2 mb-3" key={index}>
                    <Tag
                      closeButtonAction={() => {
                        onChange([
                          ...value.slice(0, index),
                          ...value.slice(index + 1, value?.length),
                        ]);
                      }}
                      text={getDisplayValue(v)}
                    />
                  </div>
                ))}
              </div>
            )}
            <input
              autoComplete={AutoCompleteOptions.off}
              className={`tw-input pr-10 ${
                multiple && value && value?.length > 0
                  ? "border-t-0 rounded-t-none"
                  : ""
              }`}
              disabled={readOnly}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
                if (onInputValueChange) {
                  onInputValueChange(e.target.value);
                }

                if (value !== null && !multiple) {
                  onChange(null);
                }
              }}
              name={name}
              onClick={() => {
                setIsListOpen(true);
              }}
              placeholder={placeholder}
              readOnly={!isTypeahead || readOnly}
              type="text"
              value={inputValue}
            />

            <div className="absolute inset-y-0 right-0 flex items-center pr-2 ml-auto space-x-2">
              {toolTipText ? (
                <div>
                  <Tooltip content={toolTipText} placement="top">
                    <span>
                      <InfoFilled className="text-sky-500" />
                    </span>
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
              <div>
                {clearButton && !!value && inputValue !== "" && !multiple ? (
                  <Button
                    className="p-0 m-0"
                    onClick={() => {
                      onChange(null);
                      setInputValue("");
                      if (onClear) {
                        onClear();
                      }
                    }}
                    variant="basic"
                  >
                    <XMark className="w-5 h-5" strokeWidth={2} />
                  </Button>
                ) : (
                  <div hidden={isTypeahead}>
                    <div
                      className="flex items-center"
                      onClick={() => {
                        setIsListOpen(!isListOpen);
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <img
                        src={arrowDownIcon}
                        alt="dropdown icon"
                        width="15px"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex w-full">
            <div className="ml-auto">{error && error.message}</div>
          </div>
        </div>
        <ul
          className={`mt-1 py-2 z-30 w-full max-h-96 shadow-md rounded-lg bg-white font-normal dark:bg-gray-700 overflow-y-auto absolute ${
            isListOpen ? "block" : "hidden"
          }`}
        >
          <div
            hidden={
              !fixOptions ||
              (inputValue.length > 0 &&
                filteredGroupedOptions.length > 0 &&
                !!groupedByOptions) ||
              (inputValue.length > 0 && filteredOptions.length > 0 && !!options)
            }
            className="border-b dark:border-gray-700"
            style={{ paddingBottom: "6px" }}
          >
            {fixOptions?.map((item: T, index) => (
              <li
                className="px-3 py-1 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer"
                key={index}
                onClick={() => {
                  if (multiple) {
                    onChange(value ? [...value, item] : [item]);
                  } else {
                    onChange([item]);
                  }

                  setInputValue("");
                  setIsListOpen(false);
                }}
              >
                {getDisplayValue(item)}
              </li>
            ))}
          </div>
          {loadingOptions ? (
            <div className="py-1 px-3 text-gray-400">
              <LoaderInline />
            </div>
          ) : (
            <>
              {groupedByOptions ? (
                <>
                  {filteredGroupedOptions.map(
                    ({ groupTitle, groupOptions }, index) => (
                      <div key={index} hidden={groupOptions.length === 0}>
                        <div
                          className="color-light-grey "
                          style={{
                            padding: groupTitle ? "16px 16px 6px 16px" : "0",
                            textTransform: "uppercase",
                            fontSize: "12px",
                          }}
                        >
                          {groupTitle}
                        </div>
                        <div
                          className="border-b dark:border-gray-700"
                          style={{ paddingBottom: "6px" }}
                        >
                          {groupOptions.map((item: T, groupIndex) => (
                            <li
                              className="px-3 py-1 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer"
                              key={groupIndex}
                              onClick={() => {
                                if (multiple) {
                                  onChange(value ? [...value, item] : [item]);
                                } else {
                                  onChange([item]);
                                }

                                setInputValue("");
                                setIsListOpen(false);
                              }}
                            >
                              {getDisplayValue(item)}
                            </li>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                </>
              ) : (
                <>
                  {filteredOptions.map((item: T, index) => (
                    <li
                      className="px-3 py-1 hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer"
                      key={index}
                      onClick={() => {
                        if (multiple) {
                          onChange(value ? [...value, item] : [item]);
                        } else {
                          onChange([item]);
                        }

                        setInputValue("");
                        setIsListOpen(false);
                      }}
                    >
                      {getDisplayValue(item)}
                    </li>
                  ))}
                </>
              )}
              {listLength() === 0 && !allowNew ? (
                <li className="px-3 py-1 text-gray-400">{strings.noResult}</li>
              ) : (
                <></>
              )}
              {listLength() === 0 && allowNew ? (
                <li className="px-3 py-1 text-gray-400">
                  {strings.noResultTypeCustomValue}
                </li>
              ) : (
                <></>
              )}
              {listLength() >= maxResults ? (
                <li className="px-3 py-1">
                  <Button
                    variant="link-tertiary"
                    onClick={() => {
                      setMaxResults(maxResults + 20);
                    }}
                  >
                    {strings.showMore}
                  </Button>
                </li>
              ) : (
                <></>
              )}
              {allowNew && inputValue.length > 0 ? (
                <li className="px-3 py-1">
                  <Button
                    onClick={() => {
                      const customValue = getCustomValue(inputValue);
                      onChange(customValue);
                      if (
                        multiple &&
                        (!value || !value.includes(customValue[0]))
                      ) {
                        onChange(
                          value ? [...value, customValue[0]] : customValue
                        );
                      }
                      setInputValue("");
                      setIsListOpen(false);
                    }}
                    variant="link"
                  >{`${strings.useCustomValue} ${inputValue}`}</Button>
                </li>
              ) : (
                <></>
              )}
            </>
          )}
        </ul>
      </div>
    </ClickOutsideDetector>
  );
};

export default CombinedSelectComponent;
