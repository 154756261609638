/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { AxiosResponse } from "axios";
import { strings } from "../../../common/Strings/Strings";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import {
  getGeneralError,
  localDateFormat,
  mergeTimeAndDate,
} from "../../../util/helperFunctions";
import { TodayShift } from "../../../models/calendar/TodayShift";
import ReservationTypeApi from "../../../api/ReservationTypeApi";
import { NewVetReservationRequest } from "../../../models/reservation/NewVetReservationRequest";
import { ReservationTypeResponse } from "../../../models/reservationtype/ReservationTypeResponse";
import PetSearchSection from "./PetSearchSection";
import { NewReservationPetOwnerRequest } from "../../../models/pet/NewReservationPetOwnerRequest";
import {
  AppointmentFormData,
  AppointmentFormDataNewOwnerAndPet,
} from "../../../models/calendar/AppointmentFormData";
import {
  appointmentToRescheduleKey,
  deleteFromLocalStorage,
  getFromLocalStorage,
  newReservationFormDataKey,
  setInLocalStorage,
} from "../../../util/LocalStorageVariables";
import { CancellableReservationResponse } from "../../../models/reservation/CancellableReservationResponse";
import { NewReservationPetType } from "../../../models/calendar/NewReservationPetType";
import LoaderInline from "../../../components/LoaderInline";
import { EmployeeResponse } from "../../../models/user/EmployeeResponse";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { ShiftForConsultationPeriodsResponse } from "../../../models/shift/ShiftForConsultationPeriodsResponse";
import NewPetOwnerForm from "./NewPetOwnerForm";
import { PetState } from "../../../models/calendar/PetState";
import { SavedReservationProgress } from "../../../models/calendar/SavedReservationProgress";
import DatePickerInput from "../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import { SymptomButtons } from "./SymptomButtons";
import logger from "../../../util/logger";
import { ChronicDiseaseOption } from "../../../common/ChronicDiseases/ChronicDiseases";
import { useClinic } from "../../../contexts/ClinicContext";
import { NewPetRequest } from "../../../models/pet/NewPetRequest";
import ReservationCreationApi from "../../../api/ReservationCreationApi";
import { ClinicPetAndOwnerResponse } from "../../../models/pet/ClinicPetAndOwnerResponse";
import ClinicPetApi from "../../../api/ClinicPetApi";
import {
  ClinicPetResponse,
  publicPetToClinicPet,
} from "../../../models/pet/ClinicPetResponse";
import PetForm, { PetFormProps } from "../../Pet/CreateNewPet/PetForm";
import TimeSelect from "../../../components/ReactHookFormFields/General/Select/TimeSelect";
import SelectedPetOrOwnerCard from "../../Todo/SelectedPetOrOwnerCard";
import {
  personDetailsToPetOwner,
  PetOwnerResponse,
} from "../../../models/pet/PetOwnerResponse";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { calendarTimeGapsMin } from "../calendarConfig";
import Button from "../../../components/Button";
import { Cross } from "../../../common/Icons/Cross";
import Modal from "../../../components/Modal/Modal";
import CloseButton from "../../../components/CloseButton";
import VideoConsultation from "./VideoConsultation";

interface Props {
  close(): void;
  dateAndTime: Date;
  isEmergency?: boolean;
  open: boolean;
  reload(): void;
  refreshReservationInProgress?(): void;
  selectedShift?: TodayShift;
}

interface Reservation extends CancellableReservationResponse {
  petClass?: string;
  rowSpan?: number;
}

const CalendarDayNewReservationModal: React.FC<Props> = ({
  close,
  isEmergency = false,
  dateAndTime,
  open,
  reload,
  refreshReservationInProgress,
  selectedShift: defaultShift,
}: Props) => {
  const navigate = useNavigate();
  const { clinic } = useClinic();
  const [reservationTypes, setReservationTypes] = useState<
    ReservationTypeResponse[]
  >([]);
  const [reservationPeriodStart, setReservationPeriodStart] = useState<Date>();
  const [reservationPeriodEnd, setReservationPeriodEnd] = useState<Date>();
  const [error, setErr] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [availableShiftsLoading, setAvailableShiftsLoading] =
    useState<boolean>(false);
  const [petState, setPetState] = useState<PetState>({
    state: NewReservationPetType.SEARCH,
  });
  const [petStatePermanent, setPetStatePermanent] = useState<boolean>(false);
  const selectedPet = petState.pet;

  const [rescheduling, setRescheduling] = useState(false);
  const [reservationProgress, setReservationProgress] =
    useState<SavedReservationProgress>();
  const [loadedReservationData, setLoadedReservationData] =
    useState<Reservation>();
  const [noShiftAtThisTime, setNoShiftAtThisTime] = useState<boolean>(false);
  const [availableShifts, setAvailableShifts] = useState<
    ShiftForConsultationPeriodsResponse[]
  >([]);
  const [availableEmergencyCollaborators, setAvailableEmergencyCollaborators] =
    useState<EmployeeResponse[]>([]);

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    setValue,
    getValues,
    reset,
    trigger,
    watch
  } = useForm<AppointmentFormData>({ mode: "onChange", defaultValues: { isVideoConsultation: false } });

  const {
    control: controlNewOwnerForm,
    register: registerNewOwnerForm,
    formState: { errors: errorsNewOwnerForm, isValid: isValidNewOwnerForm },
    setValue: setValueNewOwnerForm,
    getValues: getValuesNewOwnerForm,
    reset: resetNewOwnerForm,
  } = useForm<AppointmentFormDataNewOwnerAndPet>({ mode: "onChange" });

  const petFormMethods = useForm<PetFormProps>({ mode: "onChange" });
  const {
    formState: { isValid: isValidNewPetForm },
    setValue: setValueNewPetForm,
    getValues: getValuesNewPetForm,
    reset: resetNewPetForm,
  } = petFormMethods;

  const symptomsFormMethods = useForm<{
    description?: string;
    symptoms: string;
    symptomsList: string[] | null;
  }>({ defaultValues: { description: undefined, symptoms: "", symptomsList: []}});

  const getEmergencyShifts = async () => {
    if (clinic) {
      try {
        const resp =
          await ReservationCreationApi.getAvailableEmergencyCollaborators(
            clinic?.id
          );
        setAvailableEmergencyCollaborators(resp.data);
      } catch (e) {
        setErr(await getGeneralError(e));
      }
    }
  };

  useEffect(() => {
    if (isEmergency && clinic) {
      void getEmergencyShifts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmergency, clinic]);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (!clinic) {
        return;
      }

      try {
        const resp = await ReservationTypeApi.getReservationTypesOfClinic(
          clinic.id
        );
        setReservationTypes(resp.data);
      } catch (err) {
        if (isMounted) {
          setErr(await getGeneralError(err));
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [clinic]);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (reservationPeriodStart && !isEmergency) {
        const end: Date =
          reservationPeriodEnd ||
          moment(reservationPeriodStart).add(10, "minutes").toDate();

        const rescheduledResId = rescheduling
          ? loadedReservationData?.id
          : undefined;

        setAvailableShiftsLoading(true);

        if (!clinic) {
          return;
        }

        try {
          const selectedReservationType: ReservationTypeResponse[] | undefined =
            getValues("reservationType");
          const resp =
            await ReservationCreationApi.getAvailableConsultationPeriods(
              clinic.id,
              reservationPeriodStart,
              end,
              selectedReservationType
                ? selectedReservationType[0].id
                : undefined,
              rescheduledResId
            );
          if (isMounted) {
            if (resp.data.length === 0) {
              setNoShiftAtThisTime(!isEmergency);
              setValue("shift", [], { shouldValidate: true });
            } else if (resp.data.length === 1) {
              setNoShiftAtThisTime(false);
              setAvailableShifts(resp.data);
              setValue("shift", resp.data, { shouldValidate: true });
            } else {
              setNoShiftAtThisTime(false);
              setAvailableShifts(resp.data);
            }
          }
        } catch (err) {
          logger.error(err);
          if (isMounted) {
            setErr(await getGeneralError(err));
          }
        } finally {
          if (isMounted) {
            setAvailableShiftsLoading(false);
          }
        }
      }
    })();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationPeriodEnd, reservationPeriodStart]);

  useEffect(() => {
    if (defaultShift) {
      const preSelectedShift: ShiftForConsultationPeriodsResponse[] =
        availableShifts.filter(
          (s: ShiftForConsultationPeriodsResponse) => s.id === defaultShift.id
        );

      setValue("shift", preSelectedShift, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultShift, availableShifts]);

  // switch off permanent pet state if the user explicitly deletes the pet from the reservation
  useEffect(() => {
    if (!petState.pet) {
      setPetStatePermanent(false);
    }
    trigger("videoConsultationRoomId");
  }, [petState]);

  const closeNewModal = ({ save = true } = {}): void => {
    // store pet state if it is set to permanent e.g. reservation page was opened from nav bar search
    // do not overwrite if it was already saved by clicking "reschedule" button
    if (save && petState.pet && petStatePermanent) {
      setInLocalStorage(
        newReservationFormDataKey,
        JSON.stringify({
          petState,
          petStatePermanent: true,
        })
      );
    }

    setPetState({ state: NewReservationPetType.SEARCH });
    setPetStatePermanent(false);
    setReservationProgress(undefined);
    symptomsFormMethods.reset();

    if (error) {
      setErr(null);
    }
    setAvailableShifts([]);
    setReservationPeriodStart(undefined);
    setReservationPeriodEnd(undefined);

    setLoadedReservationData(undefined);
    setNoShiftAtThisTime(false);
    setRescheduling(false);

    reset();
    symptomsFormMethods.setValue("description", undefined);
    resetNewOwnerForm();
    resetNewPetForm();

    refreshReservationInProgress?.();
    close();
  };
  
  const onClickNewPetOwner = () => {
    setPetState({ state: NewReservationPetType.PET_OWNER_FORM });
  };

  const onClickNewPet = (petOwner: PetOwnerResponse) => {
    setPetState({ state: NewReservationPetType.PET_FORM, petOwner });
  };

  const onSelectPet = (petAndOwner: ClinicPetAndOwnerResponse): void => {
    setPetState({
      state: NewReservationPetType.SELECTED,
      pet: petAndOwner.pet,
      petOwner: petAndOwner.petOwner,
    });
  };

  const saveReservationData = (f: AppointmentFormData): void => {
    const savedReservation: SavedReservationProgress = {
      formData: f,
      formDataNewPet: getValuesNewPetForm(),
      formDataNewOwnerAndPet: getValuesNewOwnerForm(),
      petState,
      petStatePermanent,
      symptoms: symptomsFormMethods.getValues("symptomsList"),
      reservationType: f?.reservationType && f.reservationType[0],
    };

    setInLocalStorage(
      newReservationFormDataKey,
      JSON.stringify(savedReservation)
    );
    deleteFromLocalStorage(appointmentToRescheduleKey);
    refreshReservationInProgress?.();
    closeNewModal({ save: false });
  };

  const restoreReservationData = (): void => {
    if (isEmergency) {
      return;
    }

    const savedDataString = getFromLocalStorage(newReservationFormDataKey);
    const appointmentToRescheduleString = getFromLocalStorage(
      appointmentToRescheduleKey
    );

    /* Restore a started but not finished appointment */
    if (savedDataString) {
      const savedData = JSON.parse(savedDataString) as SavedReservationProgress;
      setReservationProgress(savedData);
      setPetState(savedData.petState);
      setPetStatePermanent(savedData.petStatePermanent || false);
      symptomsFormMethods.setValue("symptomsList", savedData.symptoms || []);
      deleteFromLocalStorage(newReservationFormDataKey);
    } else if (appointmentToRescheduleString) {
      /* Restore a submitted appointment */
      const loadedAppointmentData: Reservation = JSON.parse(
        appointmentToRescheduleString
      );

      setLoadedReservationData(loadedAppointmentData);
      setRescheduling(true);

      if (loadedAppointmentData.petOfNewOwner) {
        setPetState({ state: NewReservationPetType.PET_OWNER_PREFILLED });
      } else {
        setPetState({ state: NewReservationPetType.SELECTED });
      }
    } else {
      setReservationProgress(undefined);
      setLoadedReservationData(undefined);
    }
  };

  const setStartDateTime = (newStartDateTime: Date) => {
    setValue("startDate", newStartDateTime);
    setValue("startTime", moment(newStartDateTime).format("HH:mm"));
    setAvailableShifts([]);
    setReservationPeriodStart(newStartDateTime);
  };

  const setEndDateTime = (newEndDateTime: Date) => {
    setValue("endDate", newEndDateTime);
    setValue("endTime", moment(newEndDateTime).format("HH:mm"));
    setAvailableShifts([]);
    setReservationPeriodEnd(newEndDateTime);
  };

  useEffect(() => {
    setStartDateTime(dateAndTime);
    setEndDateTime(
      moment(dateAndTime).add(calendarTimeGapsMin, "minutes").toDate()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateAndTime]);

  useEffect(() => {
    if (open) {
      restoreReservationData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isEmergency]);

  const postReservation = async (
    formData: AppointmentFormData,
    rescheduledReservationId?: string,
    newPetOwner?: NewReservationPetOwnerRequest
  ) => {
    setLoading(true);

    const {
      shift,
      startDate,
      endDate,
      startTime,
      endTime,
      petId,
      reservationType,
      emergencyCollaborator,
      isVideoConsultation,
    } = formData;
    const { description, symptoms } = symptomsFormMethods.getValues();

    if (startDate && endDate) {
      const reservation: NewVetReservationRequest = {
        petId,
        newPetOwner,
        timePeriod: {
          startTime: moment(
            mergeTimeAndDate(startTime, new Date(startDate))
          ).format(localDateFormat()),
          endTime: moment(mergeTimeAndDate(endTime, new Date(endDate))).format(
            localDateFormat()
          ),
        },
        reservationTypeId:
          reservationType?.length === 1 ? reservationType[0].id : undefined,
        description: `${symptoms}${description ? ` ${  description}` : ""}`,
        isVideoConsultation,
      };

      if (clinic) {
        try {
          const collaboratorUserId =
            isEmergency && emergencyCollaborator.length === 1
              ? emergencyCollaborator[0].userId
              : undefined;
          const resp = await ReservationCreationApi.createVetReservation({
            clinicId: clinic.id,
            shiftId:
              shift && shift.length === 1 && collaboratorUserId === undefined
                ? shift[0].id
                : undefined,
            reservation,
            collaboratorUserId,
            rescheduledReservationId,
          });
          deleteFromLocalStorage(appointmentToRescheduleKey);
          reload();
          closeNewModal({ save: false });
          if (isEmergency) {
            navigate(`/reservation/${resp.data.id}/medical-record`);
          }
        } catch (err) {
          const generalError = getGeneralError(err);
          if (generalError) {
            setErr(await generalError);
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const submit = async (f: AppointmentFormData) => {
    const rescheduledResId = rescheduling
      ? loadedReservationData?.id
      : undefined;

    if (petState.state === NewReservationPetType.SELECTED) {
      const petId =
        selectedPet?.clinicPetDetails.id || loadedReservationData?.pet?.id;
      await postReservation({ petId, ...f }, rescheduledResId);
      return;
    }

    if (
      petState.state === NewReservationPetType.PET_FORM &&
      petState.petOwner &&
      petState.petOwner.petOwnerDetails.id &&
      isValidNewPetForm
    ) {
      const petFormData = getValuesNewPetForm();
      resetNewPetForm();

      const requestBody: NewPetRequest = {
        name: petFormData.name,
        gender: petFormData.gender,
        color: petFormData?.color && petFormData.color[0],
        species: petFormData.species[0].value,
        breed: petFormData.breed[0].name,
        dateOfBirth: petFormData.dateOfBirth,
        chipNumber: petFormData.chipNumber,
        tattooNumber: petFormData.tattooNumber,
        passportNumber: petFormData.passportNumber,
        insuranceCompanyId:
          petFormData.insuranceCompany &&
          petFormData.insuranceCompany[0] &&
          petFormData.insuranceCompany[0].id,
        insuranceNumber: petFormData.insuranceNumber,
        comment: petFormData.comment,
        deceased: !!petFormData?.deceased,
        chronicDiseases:
          petFormData?.chronicDiseases?.map(
            (disease: ChronicDiseaseOption) => disease.id
          ) || [],
      };

      if (clinic) {
        try {
          const resp: AxiosResponse<ClinicPetResponse> =
            await ClinicPetApi.createClinicPet(
              petState.petOwner.petOwnerDetails.id,
              requestBody
            );

          await postReservation(
            { petId: resp.data.clinicPetDetails.id, ...f },
            rescheduledResId
          );
        } catch (err) {
          setErr(await getGeneralError(err));
        }
      }

      return;
    }

    if (
      (petState.state === NewReservationPetType.PET_OWNER_FORM ||
        petState.state === NewReservationPetType.PET_OWNER_PREFILLED) &&
      isValidNewOwnerForm
    ) {
      const petAndOwnerFormData: AppointmentFormDataNewOwnerAndPet =
        getValuesNewOwnerForm();
      resetNewOwnerForm();

      const {
        name,
        prefix,
        firstName,
        middleName,
        lastName,
        mobilePhone,
      }: AppointmentFormDataNewOwnerAndPet = petAndOwnerFormData;
      await postReservation(f, rescheduledResId, {
        petName: name,
        prefix,
        firstName,
        middleName,
        lastName,
        mobilePhone,
      });
    }
  };

  useEffect(() => {
    if (reservationProgress?.formData) {
      const {
        reservationType,
        description,
        endTime,
        startDate,
        startTime,
        endDate,
        videoConsultationRoomId,
      } = reservationProgress?.formData || {};

      /* Reservation details */
      if (reservationType && reservationType.length === 1) {
        setValue("reservationType", reservationType);
      }

      if (endDate && endTime && startDate && startTime) {
        const reservationTimeLength = moment(
          mergeTimeAndDate(endTime, endDate)
        ).diff(mergeTimeAndDate(startTime, startDate), "minutes");
        if (reservationTimeLength > 0) {
          const newEndDateTime: Date = moment(dateAndTime)
            .add(reservationTimeLength, "minutes")
            .toDate();
          setEndDateTime(newEndDateTime);
        }
      }
      symptomsFormMethods.setValue("description", description);
      setValue("videoConsultationRoomId", videoConsultationRoomId);
    }

    if (reservationProgress?.petState) {
      setPetState(reservationProgress.petState);
    }

    if (reservationProgress?.formDataNewPet) {
      const {
        breed,
        chipNumber,
        color,
        comment,
        dateOfBirth,
        deceased,
        gender,
        insuranceCompany,
        insuranceNumber,
        name: newPetName,
        passportNumber,
        species,
        tattooNumber,
      } = reservationProgress?.formDataNewPet || {};

      /* Pet details */
      setValueNewPetForm("breed", breed);
      setValueNewPetForm("dateOfBirth", dateOfBirth);
      setValueNewPetForm("deceased", deceased);
      setValueNewPetForm("chipNumber", chipNumber);
      setValueNewPetForm("color", color);
      setValueNewPetForm("comment", comment);
      setValueNewPetForm("gender", gender);
      setValueNewPetForm("insuranceCompany", insuranceCompany);
      setValueNewPetForm("insuranceNumber", insuranceNumber);
      setValueNewPetForm("name", newPetName);
      setValueNewPetForm("passportNumber", passportNumber);
      setValueNewPetForm("species", species);
      setValueNewPetForm("tattooNumber", tattooNumber);
    }

    if (reservationProgress?.formDataNewOwnerAndPet) {
      const {
        firstName,
        lastName,
        middleName,
        mobilePhone,
        name: nameNewOwnerAndPet,
        noPersonalId,
        prefix,
        userInformedAboutGdpr,
      } = reservationProgress?.formDataNewOwnerAndPet || {};

      /* Owner details */
      setValueNewOwnerForm("firstName", firstName);
      setValueNewOwnerForm("lastName", lastName);
      setValueNewOwnerForm("middleName", middleName);
      setValueNewOwnerForm("mobilePhone", mobilePhone);
      setValueNewOwnerForm("noPersonalId", noPersonalId);
      setValueNewOwnerForm("prefix", prefix);
      setValueNewOwnerForm("userInformedAboutGdpr", userInformedAboutGdpr);
      setValueNewOwnerForm("name", nameNewOwnerAndPet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationProgress, dateAndTime]);

  useEffect(() => {
    if (loadedReservationData) {
      const {
        description,
        reservationType,
        startDateTime,
        endDateTime,
        petOwner,
        petOfNewOwner,
        pet,
        videoConsultationRoomId,
      } = loadedReservationData;

      setValueNewOwnerForm("userInformedAboutGdpr", true, {shouldValidate: true});

      if (petOwner.phones.length > 0) {
        setValueNewOwnerForm("mobilePhone", petOwner.phones[0].value, {shouldValidate: true});
      }

      if (petOfNewOwner?.name) {
        setValueNewOwnerForm("name", petOfNewOwner.name, {shouldValidate: true});
      }

      if (reservationType) {
        setValue("reservationType", [reservationType]);
      }

      symptomsFormMethods.setValue("description", description);
      setValue("videoConsultationRoomId", videoConsultationRoomId);

      const reservationTimeLength = moment(endDateTime).diff(
        startDateTime,
        "minutes"
      );
      if (reservationTimeLength > 0) {
        const newEndDateTime: Date = moment(dateAndTime)
          .add(reservationTimeLength, "minutes")
          .toDate();
        setEndDateTime(newEndDateTime);
      }

      const newPetState = { ...petState };

      if (pet) {
        newPetState.pet = publicPetToClinicPet(pet);
      }

      if (petOwner) {
        newPetState.petOwner = personDetailsToPetOwner(petOwner);
      }

      setPetState(newPetState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedReservationData, dateAndTime]);

  const setPeriodEnd = (reservationTypeMinutes?: number) => {
    const startDate = getValues("startDate");
    const startTime = getValues("startTime");
    const startDateTime =
      startDate && startTime && mergeTimeAndDate(startTime, startDate);

    if (reservationTypeMinutes && startDateTime) {
      const newEndDateTime = moment(startDateTime)
        .add(reservationTypeMinutes, "minutes")
        .toDate();

      setEndDateTime(newEndDateTime);
    }
  };

  const onReservationTypeChange = (
    values: ReservationTypeResponse[] | null
  ) => {
    if (values === null) {
      return;
    }

    const reservationType = values[0];

    if (reservationType) {
      setPeriodEnd(reservationType?.minutes);
    }
  };

  const onEndDateTimeChange = () => {
    const endDate: Date | undefined = getValues("endDate");
    const endTime: string = getValues("endTime");
    const endDateTime: Date | undefined =
      endDate && endTime ? mergeTimeAndDate(endTime, endDate) : undefined;

    setAvailableShifts([]);
    setReservationPeriodEnd(endDateTime);
  };

  const onStartDateTimeChange = () => {
    const startDate: Date | undefined = getValues("startDate");
    const startTime: string = getValues("startTime");
    const startDateTime: Date | undefined =
      startDate && startTime
        ? mergeTimeAndDate(startTime, startDate)
        : undefined;

    setAvailableShifts([]);
    setReservationPeriodStart(startDateTime);
  };

  const isAllFormsValid = (): boolean => {
    let result = false;

    switch (petState.state) {
      case NewReservationPetType.PET_FORM:
        result = isValid && isValidNewPetForm;
        break;
      case NewReservationPetType.PET_OWNER_FORM:
        result = isValid && isValidNewOwnerForm;
        break;
      case NewReservationPetType.SELECTED:
        result =
          isValid &&
          (!!petState?.pet?.clinicPetDetails.id ||
            !!loadedReservationData?.pet?.id);
        break;
      case NewReservationPetType.PET_OWNER_PREFILLED:
        result = isValid && isValidNewOwnerForm;
        break;
      default:
        break;
    }

    return result;
  };

  return (
    <Modal handleClose={closeNewModal} show={open} size="modal-lg">
      <Modal.Header
        title={
          isEmergency ? strings.emergencyAppointment : strings.newAppointment
        }
      />
      <Modal.Body className="px-4 xl:px-5 pt-4 xl:pt-8 pb-4 xl:pb-8 space-y-6">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
          <div className="col-span-1">
            <form className="space-y-4 md:space-y-6">
              {!isEmergency && (
                <>
                  <div className="relative w-full">
                    <label htmlFor="search">{strings.reservationType} *</label>
                    <div hidden={reservationTypes.length !== 0}>
                      <Link
                        to="/clinic-settings/reservationTypes"
                        className="absolute right-0 top-0 tw-link text-sm"
                      >
                        {strings.createNewReservationType}
                      </Link>
                    </div>
                    <div className="relative w-full">
                      <CombinedSelect
                        allowNew={false}
                        control={control}
                        labelKey="name"
                        labelOff
                        multiple={false}
                        name="reservationType"
                        onChange={onReservationTypeChange}
                        options={reservationTypes}
                        placeholder={strings.reservationType}
                        readOnly={availableShiftsLoading}
                        required
                      />
                    </div>
                  </div>
                  <div hidden={!watch("reservationType")?.[0]?.isVideoConsultationAllowed}>
                    <VideoConsultation control={control} petState={petState} />
                  </div>
                </>
              )}
              <div className="relative w-full">
                <label>{strings.startOfAppointment} *</label>
                <div
                  className="absolute right-0 top-0"
                  hidden={!refreshReservationInProgress}
                >
                  <Button
                    variant="link"
                    className="text-sm"
                    onClick={() => {
                      saveReservationData(getValues());
                    }}
                  >
                    {strings.reSchedule}
                  </Button>
                </div>
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-8 relative">
                    <DatePickerInput
                      control={control}
                      name="startDate"
                      labelOff
                      onChange={onStartDateTimeChange}
                      required
                      showRequired
                    />
                  </div>
                  <div className="col-span-4 relative">
                    <TimeSelect
                      control={control}
                      name="startTime"
                      labelOff
                      onChange={onStartDateTimeChange}
                      required
                      timeSuggestionStep={10}
                    />
                  </div>
                </div>
              </div>
              <div className="relative w-full">
                <label>{strings.endOfAppointment} *</label>
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-8 relative">
                    <DatePickerInput
                      control={control}
                      name="endDate"
                      labelOff
                      onChange={onEndDateTimeChange}
                      required
                      showRequired
                    />
                  </div>
                  <div className="col-span-4 relative">
                    <TimeSelect
                      control={control}
                      name="endTime"
                      labelOff
                      onChange={onEndDateTimeChange}
                      required
                      timeSuggestionStep={10}
                    />
                  </div>
                </div>
              </div>
              <AlertBox
                message={strings.startLaterThanEnd}
                hidden={
                  !reservationPeriodStart ||
                  !reservationPeriodEnd ||
                  (reservationPeriodStart &&
                    reservationPeriodEnd &&
                    moment(reservationPeriodStart).isBefore(
                      reservationPeriodEnd
                    ))
                }
                className="mb-3"
              />

              {isEmergency ? (
                <CombinedSelect
                  allowNew={false}
                  control={control}
                  name="emergencyCollaborator"
                  label={strings.employee}
                  labelKey="fullName"
                  multiple={false}
                  options={availableEmergencyCollaborators}
                  required
                  showRequired
                />
              ) : (
                <>
                  <CombinedSelect
                    allowNew={false}
                    control={control}
                    name="shift"
                    label={strings.collaborator}
                    labelKey={(
                      option: ShiftForConsultationPeriodsResponse | any
                    ) => option.collaborator.fullName}
                    multiple={false}
                    options={availableShifts}
                    readOnly={availableShiftsLoading}
                    required
                    showRequired
                  />
                  {availableShiftsLoading ? (
                    <LoaderInline />
                  ) : (
                    <AlertBox
                      message={strings.noShiftAtThisTime}
                      type={AlertType.WARNING}
                      className="mb-3"
                      hidden={!noShiftAtThisTime}
                    />
                  )}
                </>
              )}
              <AlertBox message={error} className="my-3" />
            </form>
          </div>
          <div className="col-span-1 space-y-4 md:space-y-6">
            {petState.state === "SEARCH" ? (
              <PetSearchSection
                onClickNewPetOwner={onClickNewPetOwner}
                onError={async (err) => setErr(await getGeneralError(err))}
                onSelectPet={onSelectPet}
                onClickNewPet={onClickNewPet}
              />
            ) : null}
            {petState.state === NewReservationPetType.SELECTED && (
              <SelectedPetOrOwnerCard
                disableClose={!!loadedReservationData}
                handleClose={() => {
                  setPetState({ state: NewReservationPetType.SEARCH });
                }}
                selectedPet={selectedPet}
                selectedPetOwner={petState?.petOwner}
              />
            )}
            {petState.state === NewReservationPetType.PET_FORM && (
              <div className="tw-card mb-3 divide-y divide-gray-200 dark:divide-gray-700">
                <div className="p-3 flex justify-between items-center">
                  <span>{strings.addNewPet}</span>
                  <Button
                    variant="icon"
                    onClick={() => {
                      setPetState({ state: NewReservationPetType.SEARCH });
                    }}
                  >
                    <Cross />
                  </Button>
                </div>
                <FormProvider {...petFormMethods}>
                  <div className="divide-y divide-gray-200 dark:divide-gray-700">
                    {petState.petOwner ? (
                      <div className="p-3">
                        <Link
                          to={`/pet-owner-profile/${petState.petOwner.petOwnerDetails.id}`}
                        >
                          {petState.petOwner.petOwnerDetails.fullName}
                        </Link>
                        <div>{petState.petOwner.phones[0]?.value}</div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="p-3">
                      <form>
                        <PetForm showOnlyRequired />
                      </form>
                    </div>
                  </div>
                </FormProvider>
              </div>
            )}
            {(petState.state === NewReservationPetType.PET_OWNER_FORM ||
              petState.state === NewReservationPetType.PET_OWNER_PREFILLED) && (
              <form>
                <NewPetOwnerForm
                  closable={!loadedReservationData}
                  control={controlNewOwnerForm}
                  register={registerNewOwnerForm}
                  errors={errorsNewOwnerForm}
                  onCancel={() => {
                    setPetState({ state: NewReservationPetType.SEARCH });
                  }}
                />
              </form>
            )}
            <FormProvider {...symptomsFormMethods}>
              <form className=" space-y-4 md:space-y-6">
                <SymptomButtons />
                <Textarea
                  autoComplete={AutoCompleteOptions.off}
                  error={symptomsFormMethods.formState.errors.description}
                  label={strings.comment}
                  maxLength={500}
                  minRows={1}
                  name="description"
                  register={symptomsFormMethods.register}
                />
              </form>
            </FormProvider>
          </div>
        </div>
        <AlertBox
          hidden={!errors.videoConsultationRoomId}
          message={
            <>
              {strings.needPetOwnerEmailError}{" "}
              {petState.state === NewReservationPetType.PET_OWNER_FORM ? (
                <Link to="/create-pet-owner">{strings.createPetOwner}</Link>
              ) : (
                <Link
                  to={`/edit-pet-owner-profile/${petState?.petOwner?.petOwnerDetails?.id}`}
                >
                  {strings.setPetOwnerEmailLink}
                </Link>
              )}
            </>
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isAllFormsValid() || loading}
          loading={loading}
          onClick={handleSubmit(submit)}
        >
          {isEmergency
            ? strings.createEmergencyAppointment
            : rescheduling
            ? strings.confirmReSchedule
            : strings.reserveAppointment}
        </Button>
        <CloseButton onClick={closeNewModal} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarDayNewReservationModal;

CalendarDayNewReservationModal.defaultProps = {
  isEmergency: false,
  selectedShift: undefined,
};
