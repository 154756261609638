/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import logger from "../../../../../util/logger";
import UserPetApi from "../../../../../api/UserPetApi";
import { UserPetResponse } from "../../../../../models/pet/UserPetResponse";
import PendingPets from "./PendingPets";

const PendingPetsPage = (): ReactElement => {
  const [myPets, setMyPets] = useState<UserPetResponse[]>([]);
  const [reloadMyPets, setReloadMyPets] = useState<boolean>(false);

  useEffect(() => {
    const getMyPets = async () => {
      try {
        const response = await UserPetApi.getUserPets();
        setMyPets(response.data);
      } catch (e) {
        logger.error(e);
      }
    };

    void getMyPets();
  }, [reloadMyPets]);

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <PendingPets
            myPets={myPets}
            reloadMyPets={() => {
              setReloadMyPets((prevstate) => !prevstate);
            }}
          />
        </div>
      </section>
    </main>
  );
};

export default PendingPetsPage;
