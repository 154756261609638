/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { axiosCommon } from "../util/axiosSetup";
import { TestInvoiceRequest } from "../models/invoice/TestInvoiceRequest";
import { PaymentRequest } from "../models/costaccounting/PaymentRequest";
import { GlobalVetInvoiceResponse } from "../models/management/GlobalVetInvoiceResponse";

export default class TestInvoiceApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getInvoices(
    countryCode: string
  ): ApiPromise<GlobalVetInvoiceResponse[]> {
    const url = `${this.baseUrl}/managements/countries/${countryCode}/test-invoices`;
    return axiosCommon.get(url);
  }

  static createInvoice(
    countryCode: string,
    request: TestInvoiceRequest
  ): ApiPromise<GlobalVetInvoiceResponse> {
    const url = `${this.baseUrl}/managements/countries/${countryCode}/test-invoices`;
    return axiosCommon.post(url, request);
  }

  static payInvoice(
    countryCode: string,
    invoiceId: string,
    request: PaymentRequest
  ): ApiPromise<GlobalVetInvoiceResponse> {
    const url = `${this.baseUrl}/managements/countries/${countryCode}/test-invoices/${invoiceId}/pay`;
    return axiosCommon.post(url, request);
  }

  static getInvoicePdf(
    countryCode: string,
    invoiceId: string
  ): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/managements/countries/${countryCode}/test-invoices/${invoiceId}/pdf`;
    return axiosCommon.get(url, {
      headers: { Accept: "*/*" },
      responseType: "arraybuffer",
    });
  }

  static deleteInvoice(
    countryCode: string,
    invoiceId: string
  ): ApiPromise<GlobalVetInvoiceResponse> {
    const url = `${this.baseUrl}/managements/countries/${countryCode}/test-invoices/${invoiceId}`;
    return axiosCommon.delete(url);
  }
}
