/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { strings } from "../../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { PetOwnerFullForm } from "./CreatePetOwner";
import { CountryResponse } from "../../../../models/management/CountryResponse";
import CountryApi from "../../../../api/CountryApi";
import logger from "../../../../util/logger";
import { fullYearSwedishPersonalId } from "../../../../util/Validations";
import BirthDatePicker from "../../../../components/ReactHookFormFields/Specific/BirthDatePicker";
import PhoneNumber from "../../../../components/ReactHookFormFields/Specific/PhoneNumber";
import Textarea from "../../../../components/ReactHookFormFields/General/Textarea";

export interface ClinicPetOwnerForm2Parts {
  ownerComment?: string;
  ownerDateOfBirth?: Date;
  personalId?: string;
  email?: string;
  mobilePhone?: string;
}

interface Props {
  primaryAddressCountryCode?: string;
}

const ClinicPetOwnerForm2: React.FC<Props> = ({
  primaryAddressCountryCode,
}: Props) => {
  const [countries, setCountries] = useState<CountryResponse[]>();

  useEffect(() => {
    const getCountries = async () => {
      try {
        const resp = await CountryApi.getCountries();
        setCountries(resp.data);
      } catch (e) {
        logger.error(e);
      }
    };

    if (primaryAddressCountryCode) {
      void getCountries();
    }
  }, [primaryAddressCountryCode]);

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext<PetOwnerFullForm>();

  const changedCountry = watch("country");

  useEffect(() => {
    if (!countries) return;

    setValue(
      "country",
      countries.filter((c) => c.countryCode === primaryAddressCountryCode)
    );
  }, [countries, primaryAddressCountryCode, setValue]);

  const onPersonalIdChange = (value: string) => {
    const isLengthValid = value && value.length >= 8;

    if (isLengthValid && changedCountry) {
      const countryCode = changedCountry[0]?.countryCode;
      if (countryCode === "SE") {
        const dateOfBirth = moment(value, "YYYYMMDD");
        if (dateOfBirth.isValid()) {
          setValue("ownerDateOfBirth", dateOfBirth.toDate(), {
            shouldValidate: true,
          });
        }
      }
    }
  };

  return (
    <>
      <div className="space-y-4">
        <Field
          autoComplete={AutoCompleteOptions.off}
          name="personalId"
          error={errors.personalId}
          fieldOptions={{
            pattern: {
              value: fullYearSwedishPersonalId.matchRegexp,
              message: strings.formatString(
                strings.invalidFormat,
                strings.personalIdFormatPlaceholder
              ),
            },
            validate: {
              req: (v: string) => {
                const formCountry = getValues("country")
                  ? getValues("country")[0]
                  : undefined;

                if (formCountry?.isPersonalIdUsed) {
                  return v.length > 0;
                }
                return true;
              },
            },
          }}
          label={`${strings.personalId} (${changedCountry?.[0]?.displayName})`}
          maxLength={12}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onPersonalIdChange(e.target.value);
          }}
          placeholder={strings.personalIdFormatPlaceholder}
          register={register}
          showRequired={changedCountry?.[0]?.isPersonalIdUsed}
        />
        <BirthDatePicker
          control={control}
          name="ownerDateOfBirth"
        />
        <PhoneNumber
          control={control}
          id="mobilePhone"
          name="mobilePhone"
          label={strings.mobilePhone}
          required
          showRequired
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.email}
          name="email"
          label={strings.emailAddress}
          register={register}
          type="email"
        />
        <Textarea
          name="ownerComment"
          minRows={2}
          register={register}
          label={strings.comment}
          toolTipText={strings.theOwnerWillNotSee}
        />
      </div>
    </>
  );
};

export default ClinicPetOwnerForm2;
