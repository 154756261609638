/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";

interface Props {
  label: string;
  content: string;
}

const StaticStatus: React.FC<Props> = ({ label, content }: Props) => {
  return (
    <div className="form-group form-row p-1">
      <label className="font-weight-bold" htmlFor="language">
        {label}
      </label>
      <div
        className="form-control custom-form-control-select input-field-label"
        style={{ display: "flex", alignItems: "center" }}
      >
        {content}
      </div>
    </div>
  );
};

export default StaticStatus;
