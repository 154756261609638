/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PageProps } from "../../models/PageProps";
import { strings } from "../../common/Strings/Strings";
import Tabs from "../../components/ReactHookFormFields/General/Tabs";
import { TodoState } from "../../models/todo/TodoState";
import Field from "../../components/ReactHookFormFields/General/Field";
import EmployeeSelect, {
  SimpleEmployee,
} from "../../components/ReactHookFormFields/Specific/EmployeeSelect";
import { TodoResponse } from "../../models/todo/TodoResponse";
import TodoCard from "./TodoCard";
import TodoApi from "../../api/TodoApi";
import { Loader } from "../../components/Loader";
import LoaderInline from "../../components/LoaderInline";
import AlertBox from "../../components/AlertBox";
import { getGeneralError } from "../../util/helperFunctions";
import Paging from "../../components/Paging";
import { useDebouncedState } from "../../hooks/hooks";
import TodoModal from "./TodoModal";
import { useClinic } from "../../contexts/ClinicContext";
import EmptyListText from "../../components/EmptyListText";
import Button from "../../components/Button";

interface FilterForm {
  employee?: SimpleEmployee[];
  pageNumber: number;
  state: TodoState;
}

const Todos: React.FC<PageProps> = ({ setPageLoading }: PageProps) => {
  const { control, register, getValues, setValue } = useForm<FilterForm>({
    defaultValues: { pageNumber: 0, state: TodoState.CREATED },
  });
  const query = useDebouncedState<string>("", 600);
  const [todos, setTodos] = useState<TodoResponse[]>([]);
  const [newTodo, setNewTodo] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showPager, setShowPager] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const { clinic } = useClinic();

  useEffect(() => {
    const getTodos = async (id: string) => {
      const { state, pageNumber, employee } = getValues();
      setLoading(true);

      try {
        const response = await TodoApi.getTodosOfClinic(
          id,
          state === TodoState.COMPLETED,
          query.value.length === 0 ? undefined : query.value,
          employee ? employee[0].userId : undefined,
          state === TodoState.COMPLETED ? 12 : undefined,
          state === TodoState.COMPLETED ? pageNumber : undefined
        );
        setTodos(response.data.elements);
        setTotalPages(response.data.totalPages);

        if (state === TodoState.COMPLETED) {
          setShowPager(true);
        } else {
          setShowPager(false);
        }
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
        setPageLoading(false);
      }
    };

    if (clinic?.id) {
      void getTodos(clinic?.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, query.value]);

  const triggerReload = () => {
    setReload(!reload);
  };

  const returnResult = (): ReactElement => {
    if (error) {
      return <AlertBox message={error} />;
    }
    if (!error && todos.length === 0) {
      return (
        <div className="tw-card p-4">
          <EmptyListText text={strings.noResult} />
        </div>
      );
    }
    return (
      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {todos.map((todo: TodoResponse) => (
          <div
            key={todo.id}
          >
            <TodoCard
              onError={setError}
              reload={() => {
                triggerReload();
              }}
              todo={todo}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <div className="flex justify-between">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.tasks}
              </h1>
              <div className="ml-auto">
                <Button
                  onClick={() => {
                    setNewTodo(true);
                  }}
                >
                  {strings.newTask}
                </Button>
              </div>
            </div>
            <div>
              <form className="space-y-4">
                <Tabs
                  control={control}
                  defaultValue={TodoState.CREATED}
                  name="state"
                  onChange={triggerReload}
                  options={[
                    { title: strings.onGoing, value: TodoState.CREATED },
                    { title: strings.completed, value: TodoState.COMPLETED },
                  ]}
                />
                <div className="flex flex-wrap">
                  <div className="mr-3">
                    <Field
                      label={strings.search}
                      name="nameSearch"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        query.setValue(e.target.value);
                      }}
                      placeholder="Search by title..."
                      readOnly={loading}
                      register={register}
                      width="320px"
                    />
                  </div>
                  <div>
                    <EmployeeSelect
                      allowNew={false}
                      className="mb-0"
                      control={control}
                      label={strings.employee}
                      name="employee"
                      onChange={triggerReload}
                      placeholder={strings.searchEmployee}
                      readOnly={loading}
                      required
                      width="320px"
                    />
                  </div>
                </div>
                <Field
                  className="d-none"
                  name="pageNumber"
                  register={register}
                  type="number"
                />
              </form>
            </div>
            {loading ? <LoaderInline /> : <>{returnResult()}</>}
            <div hidden={!showPager}>
              <div className="d-flex justify-content-end flex-fill py-3">
                <Paging
                  currentPage={getValues("pageNumber") || 0}
                  totalPages={totalPages}
                  selectPage={(newPageNumber: number) => {
                    setValue("pageNumber", newPageNumber);
                    triggerReload();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <TodoModal
        onHide={(isSuccess?: boolean) => {
          setNewTodo(false);
          if (isSuccess) {
            triggerReload();
          }
        }}
        show={newTodo}
      />
    </main>
  );
};

export default Loader(Todos);
