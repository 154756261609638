/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { quantityUnits, strings } from "../../../../common/Strings/Strings";
import RedemptionInputs from "../../productDetails/RepeatDispensingBox";
import WaitingPeriodsBox from "../../productDetails/WaitingPeriodsBox";
import { WaitingPeriods } from "../../../../models/ePrescription/local/WaitingPeriods";
import { LicensedPrescriptionItemFrontend } from "../../../../models/ePrescription/local/LicensedPrescriptionItemFrontend";
import { FreeTextLicense } from "../../../../models/ePrescription/sending/FreeTextLicense";
import { Package } from "../../../../models/ePrescription/sending/Package";
import hardcodedStrings from "../../fixValues/hardcodedStrings";
import { GeoPharmacy } from "../../../../models/pharmacy/GeoPharmacy";
import LicensedForm from "./LicensedForm";
import LatestDispensingTime from "../../productDetails/LatestDispensingTime";
import { RepeatDispensing } from "../../../../models/ePrescription/sending/RepeatDispensing";
import Instructions from "../../productDetails/Instructions";
import { QuantityUnit } from "../../../../models/ePrescription/QuantityUnit";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import OtherSettings, { OtherForm } from "../../productDetails/OtherSettings";
import { Antimicrobial } from "../../../../models/ePrescription/sending/Antimicrobial";
import { DosageTexts } from "../../../../models/ePrescription/local/DosageTexts";
import { LatestRequestedTimeForDispensing } from "../../../../models/ePrescription/local/LatestRequestedTimeForDispensing";
import { IntervalUnitEnum } from "../../../../models/ePrescription/sending/IntervalUnitEnum";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import AddToPrescriptionButtonContent from "../../productDetails/AddToPrescriptionButtonContent";
import Button from "../../../../components/Button";

interface Props {
  geoPharmacies: GeoPharmacy[];
  addPrescription?(newItem: LicensedPrescriptionItemFrontend): void;
  forEdit?: LicensedPrescriptionItemFrontend;
}

const ProductDetailSection: React.FC<Props> = ({
  geoPharmacies,
  addPrescription,
  forEdit,
}: Props) => {
  const [otherSettings, setOtherSettings] = useState<OtherForm>();
  const [isOtherSettingsValid, setIsOtherSettingsValid] =
    useState<boolean>(true);

  const newLicensedFormMethods = useForm<FreeTextLicense>({
    mode: "onChange",
    defaultValues: {
      quantityUnit: QuantityUnit.PIECES,
    },
  });

  const {
    formState: { isValid: isLicensedValid },
    getValues: getLicensedValues,
    reset: setLicensedForm,
  } = newLicensedFormMethods;

  const {
    getValues: getNumberOfPackages,
    register,
    formState: { errors, isValid: isNumberOfPackagesValid },
    setValue: setNumberOfPackages,
  } = useForm<{ numberOfPackages: number }>({
    mode: "onChange",
    defaultValues: { numberOfPackages: 1 },
  });

  const instructionMethods = useForm<DosageTexts>({
    mode: "onChange",
    defaultValues: {
      intervals: forEdit?.dosageText?.intervals || [
        {
          dose: undefined,
          unit: quantityUnits[QuantityUnit.PIECES],
          period: undefined,
          days: undefined,
          method: [],
        },
      ],
      freeText: forEdit?.dosageText?.freeText,
      totalAmount: forEdit?.dosageText?.totalAmount,
    },
  });

  const {
    getValues,
    formState: { isValid },
  } = instructionMethods;

  const otherBoxSettingsMethods = useForm<
    LatestRequestedTimeForDispensing & RepeatDispensing & WaitingPeriods
  >({
    mode: "onChange",
    defaultValues: {
      numberOfTimes: 1,
      interval: undefined,
      intervalUnit: IntervalUnitEnum.DAYS,
    },
  });

  const {
    formState: { isValid: isOtherFormValid },
    getValues: getOthers,
    setValue: setOther,
  } = otherBoxSettingsMethods;

  useEffect(() => {
    if (forEdit) {
      setOther("numberOfTimes", forEdit.repeatDispensing?.numberOfTimes || 1);
      setOther("interval", forEdit.repeatDispensing?.interval);
      setOther("intervalUnit", forEdit.repeatDispensing?.intervalUnit);

      setOther("egg", forEdit.waitingPeriods?.egg);
      setOther("honey", forEdit.waitingPeriods?.honey);
      setOther("milk", forEdit.waitingPeriods?.milk);
      setOther("slaughter", forEdit.waitingPeriods?.slaughter);

      setOther(
        "latestRequestedTimeForDispensing",
        forEdit.latestRequestedTimeForDispensing
      );

      setNumberOfPackages(
        "numberOfPackages",
        forEdit.package[0].numberOfPackages
      );

      setLicensedForm(forEdit.freeTextLicense);
    }
  }, [forEdit, setOther, setNumberOfPackages, setLicensedForm]);

  const addToPrescription = () => {
    if (isLicensedValid) {
      const licensePackage: Package = {
        numberOfPackages: getNumberOfPackages("numberOfPackages"),
        nplPackId: hardcodedStrings.licensedNplPackId,
      };

      const newLicensedPrescriptionItem: LicensedPrescriptionItemFrontend = {
        antimicrobial:
          otherSettings?.antimicrobial === Antimicrobial.NOTANTIMICROBIAL
            ? undefined
            : otherSettings?.antimicrobial,
        dosageText: getValues(),
        id: -1,
        latestRequestedTimeForDispensing: getOthers(
          "latestRequestedTimeForDispensing"
        ),
        nplId: hardcodedStrings.licensedNplId,
        freeTextLicense: getLicensedValues(),
        package: [licensePackage],
        repeatDispensing: {
          interval: getOthers("interval"),
          intervalUnit: getOthers("intervalUnit"),
          numberOfTimes: getOthers("numberOfTimes"),
        },
        validUntil: otherSettings?.validUntil,
        waitingPeriods: {
          egg: getOthers("egg"),
          honey: getOthers("honey"),
          milk: getOthers("milk"),
          slaughter: getOthers("slaughter"),
        },
      };

      if (forEdit) {
        newLicensedPrescriptionItem.id = forEdit.id;
      }

      if (addPrescription) {
        addPrescription(newLicensedPrescriptionItem);
      }
    }
  };

  const checkRequired = () => {
    return (
      !isLicensedValid ||
      !isNumberOfPackagesValid ||
      !isValid ||
      !isOtherFormValid ||
      !isOtherSettingsValid
    );
  };

  return (
    <div className="space-y-4">
      <div className="p-4 tw-card mt-4">
        <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {strings.createLicensedMedication}
        </h2>
        <div>(Licensläkemedel e-förskrivning)</div>
      </div>
      <div className="space-y-4 divide-y dark:divide-gray-700">
        <FormProvider {...newLicensedFormMethods}>
          <LicensedForm geoPharmacies={geoPharmacies} />
        </FormProvider>
        <div className="space-y-3 pt-3">
          <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
            {strings.packages}
          </h2>
          <div>
            <form>
              <label>{strings.packagingQuantity}</label>
              <Field
                autoComplete={AutoCompleteOptions.off}
                error={errors.numberOfPackages}
                fieldOptions={{
                  max: {
                    value: 9999,
                    message: strings.formatString(
                      strings.mustBeBetween,
                      1,
                      10000
                    ),
                  },
                  min: {
                    value: 1,
                    message: strings.formatString(
                      strings.mustBeBetween,
                      1,
                      10000
                    ),
                  },
                }}
                name="numberOfPackages"
                register={register}
                required
                type="number"
                width="60px"
              />
            </form>
          </div>
        </div>
        <div className="space-y-3 pt-3">
          <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
            {strings.dosage}
          </h2>
          <FormProvider {...instructionMethods}>
            <form>
              <Instructions
                defaultUnit={
                  getLicensedValues("quantityUnit") ||
                  quantityUnits[QuantityUnit.PIECES]
                }
              />
            </form>
          </FormProvider>
        </div>
        <div className="space-y-3 py-4">
          <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
            {strings.otherSettings}
          </h2>
          <OtherSettings
            forEdit={{
              antimicrobial: forEdit?.antimicrobial,
              validUntil: forEdit?.validUntil,
            }}
            returnIsValid={(valid: boolean) => {
              setIsOtherSettingsValid(valid);
            }}
            returnValues={(values: OtherForm) => {
              setOtherSettings(values);
            }}
          />

          <FormProvider {...otherBoxSettingsMethods}>
            <form className="space-y-3">
              <RedemptionInputs />
              <LatestDispensingTime />
              <WaitingPeriodsBox />
            </form>
          </FormProvider>
        </div>
        <Button onClick={addToPrescription} disabled={checkRequired()} minW>
          {forEdit ? strings.save : <AddToPrescriptionButtonContent />}
        </Button>
      </div>
    </div>
  );
};

export default ProductDetailSection;

ProductDetailSection.defaultProps = {
  forEdit: undefined,
};
