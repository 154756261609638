/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import ReservationsForm from "./ReservationsForm";
import SubstitutionTimeForm, { SubstitutionForm } from "./SubstitutionTimeForm";
import Button from "../../../../components/Button";
import {
  localDateFormat,
  mergeTimeAndDate,
} from "../../../../util/helperFunctions";
import Modal from "../../../../components/Modal/Modal";

interface Props {
  close(): void;
  end?: Date;
  open: boolean;
  reload(): void;
  start?: Date;
}

const DeleteMultipleShiftsModal: React.FC<Props> = ({
  end,
  open,
  reload,
  close,
  start,
}: Props) => {
  const [substitutionTimeSet, setSubstitutionTimeSet] =
    useState<boolean>(false);
  const methods = useForm<SubstitutionForm>({
    mode: "onChange",
    defaultValues: {
      absentEmployeeUser: [],
      endDate: moment().toDate(),
      endTime: "23:59:00",
      startDate: moment().toDate(),
      startTime: "00:00:00",
    },
  });

  const { getValues, handleSubmit, setValue } = methods;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (end) {
      setValue("endDate", end);
    }
  }, [end, setValue]);

  useEffect(() => {
    if (start) {
      setValue("startDate", start);
    }
  }, [setValue, start]);

  const submitTimeForm = () => {
    setSubstitutionTimeSet(true);
  };

  const closeModal = (): void => {
    setSubstitutionTimeSet(false);
    setError(null);
    close();
  };

  return (
    <Modal handleClose={closeModal} show={open}>
      <Modal.Header title={strings.deleteShifts} />
      <Modal.Body>
        {substitutionTimeSet ? (
          <ReservationsForm
            employeeUserId={getValues("absentEmployeeUser")?.[0]?.userId}
            end={moment(
              mergeTimeAndDate(getValues("endTime"), getValues("endDate"))
            ).format(localDateFormat())}
            onHide={closeModal}
            reload={reload}
            setLoading={setLoading}
            start={moment(
              mergeTimeAndDate(getValues("startTime"), getValues("startDate"))
            ).format(localDateFormat())}
          />
        ) : (
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(submitTimeForm)}
              id="substitutionTimeForm"
              className="space-y-4"
            >
              <SubstitutionTimeForm
                error={error}
                loading={loading}
                onHide={closeModal}
                submitTimeForm={submitTimeForm}
              />
            </form>
          </FormProvider>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading}
          form={
            substitutionTimeSet ? "reservationsForm" : "substitutionTimeForm"
          }
          loading={loading}
          type="submit"
          variant="primary"
        >
          {substitutionTimeSet ? strings.finish : strings.next}
        </Button>
        <Button
          className="modal-sub-button"
          onClick={() => {
            if (substitutionTimeSet) {
              setSubstitutionTimeSet(false);
            } else {
              closeModal();
            }
          }}
          variant="secondary"
        >
          {substitutionTimeSet ? strings.back : strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteMultipleShiftsModal;
