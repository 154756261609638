/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { quantityUnits, strings } from "../../../../common/Strings/Strings";
import { QuantityUnit } from "../../../../models/ePrescription/QuantityUnit";
import { GeoPharmacy } from "../../../../models/pharmacy/GeoPharmacy";
import PharmacySearchModal from "../../modals/PharmacySearchModal";
import { Option } from "../../../../models/Option";
import { prescriptionRegexp } from "../../../../util/Validations";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import Select from "../../../../components/ReactHookFormFields/General/Select/Select";
import Button from "../../../../components/Button";

interface Props {
  geoPharmacies: GeoPharmacy[];
}

const LicensedForm: React.FC<Props> = ({ geoPharmacies }: Props) => {
  const [pharmacySearchModal, setPharmacySearchModal] =
    useState<boolean>(false);
  const {
    getValues,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const generateQuantityUnits = (): Option[] => {
    const options: Option[] = [];

    Object.keys(QuantityUnit).forEach((unit) => {
      options.push({ title: quantityUnits[unit], value: unit });
    });

    return options;
  };

  return (
    <>
      <form>
        <div className="grid grid-cols-12 gap-4 pt-2">
          <div className="col-span-6 md:col-span-4">
            <Field
              autoComplete={AutoCompleteOptions.off}
              error={errors.supplementaryInformation}
              fieldOptions={{
                maxLength: 50,
                pattern: {
                  value: prescriptionRegexp,
                  message: strings.notAllowedCharacter,
                },
              }}
              label={strings.supplementaryInformation}
              maxLength={50}
              name="supplementaryInformation"
              placeholder={strings.supplementaryInformation}
              register={register}
              required
            />
          </div>
          <div className="col-span-6 md:col-span-4">
            <Field
              autoComplete={AutoCompleteOptions.off}
              error={errors.dosageForm}
              fieldOptions={{
                maxLength: 20,
                pattern: {
                  value: prescriptionRegexp,
                  message: strings.notAllowedCharacter,
                },
              }}
              label={strings.dosageForm}
              maxLength={20}
              name="dosageForm"
              placeholder={strings.dosageForm}
              register={register}
              required
            />
          </div>
          <div className="col-span-6 md:col-span-4">
            <Field
              autoComplete={AutoCompleteOptions.off}
              error={errors.strength}
              fieldOptions={{
                maxLength: 20,
                pattern: {
                  value: prescriptionRegexp,
                  message: strings.notAllowedCharacter,
                },
              }}
              label={strings.strength}
              name="strength"
              maxLength={20}
              placeholder={strings.strength}
              register={register}
              required
            />
          </div>
          <div className="col-span-6 md:col-span-4">
            <Field
              autoComplete={AutoCompleteOptions.off}
              name="quantity"
              error={errors.quantity}
              fieldOptions={{
                max: {
                  value: 10000,
                  message: strings.formatString(
                    strings.mustBeBetween,
                    0.01,
                    9999.99
                  ),
                },
                min: {
                  value: 0.01,
                  message: strings.formatString(
                    strings.mustBeBetween,
                    0.01,
                    9999.99
                  ),
                },
              }}
              label={strings.quantity}
              placeholder={strings.quantity}
              register={register}
              required
              type="number"
            />
          </div>
          <div className="col-span-6 md:col-span-4">
            <Select
              autoComplete={AutoCompleteOptions.off}
              error={errors.quantityUnit}
              label={strings.quantityUnit}
              name="quantityUnit"
              isChooseOption={false}
              options={generateQuantityUnits()}
              register={register}
              required
            />
          </div>
          <div className="col-span-6 md:col-span-4">
            <Field
              autoComplete={AutoCompleteOptions.off}
              error={errors.packageContent}
              fieldOptions={{
                max: {
                  value: 10000,
                  message: strings.formatString(
                    strings.mustBeBetween,
                    0.01,
                    9999.99
                  ),
                },
                min: {
                  value: 0.01,
                  message: strings.formatString(
                    strings.mustBeBetween,
                    0.01,
                    9999.99
                  ),
                },
              }}
              label={strings.packageContent}
              name="packageContent"
              placeholder={strings.packageContent}
              type="number"
              register={register}
              required
            />
          </div>
          <div className="col-span-6 md:col-span-4">
            <Field
              autoComplete={AutoCompleteOptions.off}
              error={errors.licenseId}
              fieldOptions={{
                maxLength: 20,
                pattern: {
                  value: prescriptionRegexp,
                  message: strings.notAllowedCharacter,
                },
              }}
              label={strings.licenseId}
              name="licenseId"
              maxLength={20}
              optional
              placeholder={strings.licenseId}
              register={register}
            />
          </div>
          <div className="col-span-6 md:col-span-4">
            <div className="relative w-full">
              <label htmlFor="search">{strings.pharmacyGLN}</label>
              <div className="absolute right-0 top-0">
                <Button
                  className="text-sm"
                  onClick={() => {
                    setPharmacySearchModal(true);
                  }}
                  variant="link"
                >
                  {strings.search}
                </Button>
              </div>
              <Field
                autoComplete={AutoCompleteOptions.off}
                error={errors.pharmacyId}
                fieldOptions={{
                  maxLength: 13,
                  pattern: {
                    value: prescriptionRegexp,
                    message: strings.notAllowedCharacter,
                  },
                  validate: {
                    isPharmacyIdNeedsToFill: (v: string) => {
                      const values = getValues();
                      return (
                        !(values.licenseId && !v) ||
                        strings.mandatoryIfLicenseIdProvided
                      );
                    },
                  },
                }}
                labelOff
                maxLength={13}
                name="pharmacyId"
                optional
                placeholder={strings.pharmacyGLN}
                register={register}
              />
            </div>
          </div>
        </div>
        <Field name="triggerChange" className="d-none" register={register} />
      </form>
      <PharmacySearchModal
        isOpen={pharmacySearchModal}
        close={() => {
          setPharmacySearchModal(false);
        }}
        geoPharmacies={geoPharmacies}
        returnPharmacy={(p: string) => {
          setValue("pharmacyId", p);
        }}
      />
    </>
  );
};

export default LicensedForm;
