/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import { Pharmacy } from "../../../../models/pharmacy/Pharmacy";
import { ClinicPharmacyResponse } from "../../../../models/clinic/ClinicPharmacyResponse";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import CombinedSelectComponent from "../../../../components/InputFieldsSimple/CombinedSelectComponent";
import SelectedPharmacyDetails from "./SelectedPharmacyDetails";

interface Props {
  selectPharmacy(p: Pharmacy | undefined): void;
  availableFeePharmacies?: ClinicPharmacyResponse[];
  visibleDetails?: boolean;
}

const PartnerPharmacyForm: React.FC<Props> = ({
  selectPharmacy,
  availableFeePharmacies,
  visibleDetails = true,
}: Props) => {
  const [selectedPharmacy, setSelectedPharmacy] = useState<Pharmacy[]>([]);
  const [partnerPharmacies, setPartnerPharmacies] = useState<
    ClinicPharmacyResponse[]
  >([]);

  useEffect(() => {
    if (availableFeePharmacies) {
      setPartnerPharmacies(availableFeePharmacies);
    }
  }, [availableFeePharmacies]);

  useEffect(() => {
    if (selectedPharmacy && selectedPharmacy[0]) {
      selectPharmacy(selectedPharmacy[0]);
    } else if (selectedPharmacy.length === 0) {
      selectPharmacy(undefined);
    }
  }, [selectPharmacy, selectedPharmacy]);

  return (
    <>
      <AlertBox
        type={AlertType.INFO}
        message={strings.clinicPharmacyTooltip}
        className="mb-3"
      />
      <CombinedSelectComponent
        label={strings.partnerPharmacies}
        labelKey={(option: Pharmacy | any) => {
          if (option.shortName) {
            return option.shortName;
          }
          if (option.longName) {
            return option.longName;
          }
          return option.glnCode;
        }}
        name="partnerPharmacyTypeahead"
        onChange={(p: Pharmacy[] | null) => {
          setSelectedPharmacy(p || []);
        }}
        options={partnerPharmacies}
        placeholder={strings.pharmacy}
        value={selectedPharmacy}
      />
      {selectedPharmacy.length === 1 && visibleDetails ? (
        <SelectedPharmacyDetails pharmacy={selectedPharmacy[0]} />
      ) : (
        <></>
      )}
    </>
  );
};

export default PartnerPharmacyForm;
