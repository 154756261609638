/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import {
  dateAndTime,
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import moment from "moment";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { Link } from "react-router-dom";
import { ReservationResponse } from "../../../models/reservation/ReservationResponse";
import Button from "../../../components/Button";

interface Props {
  hr?: boolean;
  invoice?: InvoiceResponse;
  reservation?: ReservationResponse;
}

const Payment = ({ hr = false, invoice, reservation }: Props): ReactElement => {
  return (
    <li className="flex flex-col space-y-4">
      <div className="flex justify-between">
        <div className="text-base font-bold text-zinc-800 dark:text-gray-100">
          {getStringFromEnumsOrReturn(reservation?.state)}
        </div>
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {invoice?.creationDate &&
            moment(invoice?.creationDate).format(
              dateAndTime.momentDateTimeFormat,
            )}
        </div>
      </div>
      <div className="flex space-x-2 items-center">
        <Link to={`/invoices/clinic/${invoice?.id || reservation?.invoiceId}`}>
          <Button variant="link-tertiary">{strings.invoice}</Button>
        </Link>
      </div>
      {hr && <hr className="border-gray-200 dark:border-gray-600" />}
    </li>
  );
};

export default Payment;
