/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getGeneralError, isCancellable } from "../../util/helperFunctions";
import { petSpecies, strings } from "../../common/Strings/Strings";
import { MedicalRecordStatus } from "../../models/medical/MedicalRecordStatus";
import { ReservationAccessScope } from "../../models/reservation/ReservationAccessScope";
import MedicalRecordApi from "../../api/MedicalRecordApi";
import { MedicalTreatmentResponse } from "../../models/medical/MedicalTreatmentResponse";
import { WeightMeasurement } from "../../models/medical/WeightMeasurement";
import { Medication } from "../../models/medical/Medication";
import InventoryItemApi from "../../api/InventoryItemApi";
import { UpdateMedicalRecordRequest } from "../../models/medical/UpdateMedicalRecordRequest";
import { ReservationResponse } from "../../models/reservation/ReservationResponse";
import logger from "../../util/logger";
import PrescriptionSection from "./PrescriptionsSection";
import { PrescriptionDocument } from "../../models/medical/PrescriptionDocument";
import CountryApi from "../../api/CountryApi";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import { UsedInventoryItemResponse } from "../../models/medical/UsedInventoryItemResponse";
import { UsedInventoryItemType } from "../../models/medical/local/UsedInventoryItemType";
import { InventoryRelatedTypes } from "../../models/medical/local/InventoryRelatedTypes";
import LoaderInline from "../../components/LoaderInline";
import NumberFormatter from "../../util/NumberFormatter";
import AlertBox from "../../components/AlertBox";
import { useDebouncedState } from "../../hooks/hooks";
import SignModal from "./SignModal";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../util/LocalStorageVariables";
import ReservationSection from "./ReservationSection";
import PetAndOwnerSection from "./PetAndOwnerSection";
import { AdditionalFieldsProps } from "./AdditionalFields";
import TodoSection from "./TodoSection";
import { MedicalRecordVersionResponse } from "../../models/medical/MedicalRecordVersionResponse";
import { MedicalRecordResponse } from "../../models/medical/MedicalRecordResponse";
import { ReservationState } from "../../models/reservation/ReservationState";
import FilesModal from "./Files/FilesModal";
import { MedicalRecordFileResponse } from "../../models/medical/MedicalRecordFileResponse";
import { MedicalRecordFileType } from "../../models/medical/MedicalRecordFileType";
import VersionSection from "./VersionHistory/VersionSection";
import { personDetailsToPetOwner } from "../../models/pet/PetOwnerResponse";
import { TreatmentResponse } from "../../models/treatment/TreatmentResponse";
import { TreatmentItemResponse } from "../../models/treatment/TreatmentItemResponse";
import { TreatmentFeeResponse } from "../../models/treatment/TreatmentFeeResponse";
import TreatmentApi from "../../api/TreatmentApi";
import DeleteTreatmentModal from "./Treatment/DeleteTreatmentModal";
import { feeReducer, UsedFeeActions } from "./Reducers/FeeReducer";
import { otherReducer, UsedOtherActions } from "./Reducers/OtherReducer";
import NegativeStockModal from "./Modals/NegativeStockModal";
import MedicalHistorySection from "./HistoryMedical/MedicalHistorySection";
import { SubsectionTemplate as MainFormSection } from "./MainSection/Templates/SubsectionTemplate";
import Button from "../../components/Button";
import WeightSection from "./MainSection/WeightSection";
import AnamnesisSection from "./MainSection/AnamnesisSection";
import ExaminationSection from "./MainSection/ExaminationSection";
import DiagnosisSection from "./MainSection/DiagnosisSection";
import TreatmentSectionMain from "./MainSection/TreatmentSection";
import { MainSectionContext } from "./MainSection/MainSectionContext";
import FollowUpSection from "./MainSection/FollowUpSection";
import { useClinic } from "../../contexts/ClinicContext";
import { ClinicFeature } from "../../models/clinic/ClinicFeature";
import CostsSection from "./Costs/CostsSection";

interface ArrayMap {
  [key: string]: Array<any>;
}

export interface NewlyAddedData extends ArrayMap {
  vaccines: UsedInventoryItemResponse[];
  medicines: UsedInventoryItemResponse[];
  treatments: MedicalTreatmentResponse[];
  otherInventoryItems: UsedInventoryItemResponse[];
  diagnoses: string[];
  differentialDiagnoses: string[];
}

interface Weight extends WeightMeasurement {
  name: string;
}

export interface Lists {
  weights: Weight[];
  vaccines: Medication[];
  medicines: Medication[];
  treatments: Medication[];
  diagnoses: Medication[];
  differentialDiagnoses: string[];
  prescriptions: PrescriptionDocument[];
}

interface Props {
  isPage: boolean;
  isUserNotOwner: boolean;
  resId?: string;
  recordId?: string;
  universalId?: string;
  reloadReservations?(): void;
  refreshReservationInProgress?(): void;
  returnReservation?(reservation: ReservationResponse): void;
  setPageLoading: (isLoading: boolean) => void;
}

function MedicalRecordComponent({
  isPage,
  isUserNotOwner,
  recordId,
  resId,
  universalId,
  reloadReservations,
  refreshReservationInProgress: _refreshReservationInProgress,
  returnReservation,
  setPageLoading,
}: Props): ReactElement {
  const navigate = useNavigate();
  const role: UserRole | null = getValueOfActiveUser("role");
  const { isFeatureAvailable } = useClinic();

  const [recordIdS, setRecordIdS] = useState<string | undefined>(recordId);
  const [reservationData, setReservationData] = useState<ReservationResponse>();
  const [resIdS, setResIdS] = useState<string | undefined>(resId);
  const [cancellable, setCancellable] = useState<boolean>(false);
  const changed = useDebouncedState<string | number>(0, 600);
  const [loading, setLoading] = useState<boolean>(true);

  const [getData, setGetData] = useState<MedicalRecordResponse>();
  const [medicalRecordVersions, setMedicalRecordVersions] = useState<
    MedicalRecordVersionResponse[]
  >([]);

  const [usedFees, setUsedFees] = useReducer(feeReducer, []);
  const [usedOthers, setUsedOthers] = useReducer(otherReducer, []);

  const [newlyAdded, setNewlyAdded] = useState<NewlyAddedData>({
    vaccines: [],
    medicines: [],
    treatments: [],
    otherInventoryItems: [],
    diagnoses: [],
    differentialDiagnoses: [],
  });
  const [lists, setLists] = useState<Lists>({
    weights: [],
    vaccines: [],
    medicines: [],
    treatments: [],
    diagnoses: [],
    differentialDiagnoses: [],
    prescriptions: [],
  });

  const [filesModalOpen, setFilesModalOpen] = useState<
    MedicalRecordFileType | undefined
  >();
  const [files, setFiles] = useState<MedicalRecordFileResponse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [_treatmentsError, setTreatmentsError] = useState<string | null>(null);
  const [signModalOpened, setSignModalOpened] = useState<boolean>(false);
  const [signed, setSigned] = useState<boolean>(false);
  const [debounce, setDebounce] = useState<boolean>(false);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [accessScope, setAccessScope] = useState<ReservationAccessScope>(
    ReservationAccessScope.NONE
  );
  const [clinicTreatmentsByPetSpecies, setClinicTreatmentsByPetSpecies] =
    useState<TreatmentResponse[]>([]);
  const [treatmentToDelete, setTreatmentToDelete] = useState<
    TreatmentResponse & { contentNotAvailable?: boolean }
  >();

  const [anamnesis, setAnamnesis] = useState<string>("");
  const [physicalExamination, setPhysicalExamination] = useState<string>("");
  const [diagnosticsText, setDiagnosticsText] = useState<string>("");
  const [treatmentText, setTreatmentText] = useState<string>("");
  const [recommendationText, setRecommendationText] = useState<string>("");
  const [weight, setWeight] = useState<number>();
  const [chipNumber, setChipNumber] = useState<string>();
  const [tattooNumber, setTattooNumber] = useState<string>();
  const [medicalRecordCorrected, setMedicalRecordCorrected] =
    useState<boolean>(false);

  const additionalFieldMethods = useForm<AdditionalFieldsProps>({
    defaultValues: { certificate: "", referral: "", prognosis: "" },
  });
  const { getValues: getAdditionalFields, setValue: setAdditionalFields } =
    additionalFieldMethods;

  const [warningModal, setWarningModal] = useState<boolean>(false);

  const openSignModal = () => setSignModalOpened(true);
  const closeSignModal = () => setSignModalOpened(false);

  const canEdit = (scope: ReservationAccessScope): boolean =>
    scope === ReservationAccessScope.WRITE ||
    scope === ReservationAccessScope.SIGN;

  const triggerChange = (e?: string) => {
    if (error) {
      setError(null);
    }

    changed.setValue(e || Math.random());
  };

  useEffect(() => {
    const getTreatments = async () => {
      if (getData) {
        try {
          const response = await TreatmentApi.getTreatmentsOfClinic(
            getData.clinicId,
            {
              species: getData?.pet.species
                ? [
                    {
                      value: getData.pet.species,
                      name: petSpecies[getData.pet.species],
                    },
                  ]
                : undefined,
            }
          );
          setClinicTreatmentsByPetSpecies(response.data.elements);
        } catch (err) {
          setTreatmentsError(await getGeneralError(err));
        }
      }
    };

    if (debounce && !!getData && countryDetails?.currency) {
      void getTreatments();
    }
  }, [debounce, getData, countryDetails]);

  useEffect(() => {
    if (getData) {
      setAnamnesis(getData.anamnesis || "");
      setChipNumber(getData.chipNumber || undefined);
      setTattooNumber(getData.tattooNumber || undefined);
      setPhysicalExamination(getData.physicalExamination || "");
      setTreatmentText(getData.treatment || "");
      setRecommendationText(getData.recommendation || "");
      setDiagnosticsText(getData.diagnostics || "");
      setWeight(getData.weight || undefined);

      setAdditionalFields("comment", getData.comment || "");
      setAdditionalFields("certificate", getData.certificate || "");
      setAdditionalFields("prognosis", getData.prognosis || "");
      setAdditionalFields("referral", getData.referral || "");
    }
  }, [getData, setAdditionalFields]);

  const getCountryDetails = async (clinicId: string) => {
    try {
      const response = await CountryApi.getCountryDetailsOfClinic(clinicId);
      setCountryDetails(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  };

  useEffect(() => {
    if (!getData?.clinicId || countryDetails) {
      return;
    }

    void getCountryDetails(getData.clinicId);

    /* eslint-disable-next-line */
  }, [countryDetails, getData]);

  const BillingSectionUpdateCallback = (
    medicines: UsedInventoryItemResponse[],
    vaccines: UsedInventoryItemResponse[],
    treatments: MedicalTreatmentResponse[],
    otherInventoryItems: UsedInventoryItemResponse[]
  ) => {
    if (debounce && !signed && getData !== undefined && canEdit(accessScope)) {
      logger.debug("Updating newly added items changed in billing section");
      setNewlyAdded((prevState) => ({
        ...prevState,
        vaccines,
        medicines,
        otherInventoryItems,
        treatments,
      }));

      triggerChange();
    }
  };

  const getMedicalRecord = async () => {
    setLoading(true);
    try {
      let response;
      if (universalId) {
        response = await MedicalRecordApi.getLatestMedicalRecord(universalId);
      } else if (resId) {
        response = await MedicalRecordApi.getMedicalRecordOfReservation(resId);
      } else if (recordId) {
        response = await MedicalRecordApi.getMedicalRecord(recordId);
      } else {
        return;
      }

      const { medicalRecord, medicalHistory, versions, reservation } =
        response.data;

      setGetData(medicalRecord);
      setMedicalRecordVersions(versions);
      setPageLoading(false);
      setResIdS(medicalRecord.reservationId);

      setUsedFees({
        type: "fill",
        itemsToAdd: medicalRecord.extraCosts.filter((ei) => !!ei.referenceId),
      });
      setUsedOthers({
        type: "fill",
        itemsToAdd: medicalRecord.extraCosts.filter((ei) => !ei.referenceId),
      });

      setFiles(medicalRecord.files);

      setNewlyAdded((prevState) => ({
        ...prevState,
        vaccines: medicalRecord.vaccines,
        medicines: medicalRecord.medicines,
        treatments: medicalRecord.treatments,
        otherInventoryItems: medicalRecord.otherInventoryItems,
        diagnoses: medicalRecord.diagnoses,
        differentialDiagnoses: medicalRecord.differentialDiagnoses,
      }));

      const compatibleWeightList: Weight[] = medicalHistory.weights.map(
        (item) => ({
          medicalRecordId: item.medicalRecordId,
          weight: item.weight,
          date: item.date,
          name: `${NumberFormatter.format(item.weight)} kg`,
        })
      );

      setLists((prevState) => ({
        ...prevState,
        weights: compatibleWeightList,
        vaccines: medicalHistory.vaccines,
        medicines: medicalHistory.medicines,
        treatments: medicalHistory.treatments,
        diagnoses: medicalHistory.diagnoses,
        prescriptions: medicalRecord.prescriptions,
      }));

      setSigned(medicalRecord.signedBy !== null);
      setAccessScope(response.data.accessScope);

      setReservationData(reservation);
      if (returnReservation) {
        returnReservation(reservation);
      }
      setCancellable(isCancellable(reservation, role || undefined));

      setDebounce(true);
    } catch (e) {
      const err: any = e;
      if (
        err.response?.data.id ===
        "PET_AND_OWNER_IS_NOT_SPECIFIED_YET_FOR_MEDICAL_RECORD"
      ) {
        navigate(`/reservation/${resId}/medical-record/pet-and-owner`, {
          replace: true,
        });
      } else {
        setError(await getGeneralError(err));
        setPageLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getMedicalRecord();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resId, medicalRecordCorrected]);

  const updateMedicalRecord = async (
    reservationId: string,
    request: UpdateMedicalRecordRequest
  ) => {
    try {
      const response = await MedicalRecordApi.updateMedicalRecord(
        reservationId,
        request
      );

      if (recordIdS === undefined && response.data.id) {
        setRecordIdS(response.data.id);
      }
      setFiles(response.data.files);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  };

  /* Save automatically changed data, after debounce (tick sign indicates successful saves) */
  useEffect(() => {
    const putData: UpdateMedicalRecordRequest = {
      anamnesis,
      physicalExamination,
      diagnostics: diagnosticsText,
      differentialDiagnoses: newlyAdded.differentialDiagnoses,
      treatment: treatmentText,
      recommendation: recommendationText,
      prognosis: getAdditionalFields("prognosis") || "",
      certificate: getAdditionalFields("certificate") || "",
      referral: getAdditionalFields("referral") || "",
      comment: getAdditionalFields("comment") || "",
      weight,
      vaccines: newlyAdded.vaccines,
      medicines: newlyAdded.medicines,
      treatments: newlyAdded.treatments,
      otherInventoryItems: newlyAdded.otherInventoryItems,
      diagnoses: newlyAdded.diagnoses,
      extraCosts: [...usedFees, ...usedOthers],
      chipNumber,
      tattooNumber,
    };

    if (debounce && resIdS && putData && !signed) {
      void updateMedicalRecord(resIdS, putData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed.value]);

  const setDiagnoses = (input: string) => {
    if (input === "") {
      return;
    }
    logger.debug("Diagnoses is added");
    const newArray = [...newlyAdded.diagnoses];
    newArray.push(input);
    setNewlyAdded((prevState) => ({ ...prevState, diagnoses: newArray }));
    triggerChange();
  };

  const setDifferentialDiagnoses = (input: string) => {
    if (input === "") {
      return;
    }
    logger.debug("Differential diagnoses is added");
    const newArray = [...newlyAdded.differentialDiagnoses];
    newArray.push(input);
    setNewlyAdded((prevState) => ({
      ...prevState,
      differentialDiagnoses: newArray,
    }));
    triggerChange();
  };

  const handleInventoryItemChange = (
    newInventoryItem: UsedInventoryItemResponse,
    type: UsedInventoryItemType
  ) => {
    let alreadyAdded;
    switch (type) {
      case UsedInventoryItemType.Medicines:
        alreadyAdded = newlyAdded.medicines.find(
          (m) => m.id === newInventoryItem.id
        );
        if (alreadyAdded) {
          alreadyAdded.quantity += newInventoryItem.quantity;
          setNewlyAdded((prevState) => ({
            ...prevState,
            medicines: [...newlyAdded.medicines],
          }));
          return;
        }

        logger.debug("Medicine is added");
        setNewlyAdded((prevState) => ({
          ...prevState,
          medicines: [...newlyAdded.medicines, newInventoryItem],
        }));
        break;
      case UsedInventoryItemType.Vaccines:
        alreadyAdded = newlyAdded.vaccines.find(
          (m) => m.id === newInventoryItem.id
        );
        if (alreadyAdded) {
          alreadyAdded.quantity += newInventoryItem.quantity;
          setNewlyAdded((prevState) => ({
            ...prevState,
            vaccines: [...newlyAdded.vaccines],
          }));
          return;
        }

        logger.debug("Vaccine is added");
        setNewlyAdded((prevState) => ({
          ...prevState,
          vaccines: [...newlyAdded.vaccines, newInventoryItem],
        }));
        break;
      case UsedInventoryItemType.Other:
        alreadyAdded = newlyAdded.otherInventoryItems.find(
          (m) => m.id === newInventoryItem.id
        );
        if (alreadyAdded) {
          alreadyAdded.quantity += newInventoryItem.quantity;
          setNewlyAdded((prevState) => ({
            ...prevState,
            otherInventoryItems: [...newlyAdded.otherInventoryItems],
          }));
          return;
        }

        logger.debug("Other inventory item is added");
        setNewlyAdded((prevState) => ({
          ...prevState,
          otherInventoryItems: [
            ...newlyAdded.otherInventoryItems,
            newInventoryItem,
          ],
        }));
        break;
      default:
        break;
    }
    triggerChange();
  };

  const createTreatmentText = (t: TreatmentResponse) =>
    t?.description ? `${t?.name}: ${t?.description} \n` : "";

  const createAdviceText = (t: TreatmentResponse) =>
    t?.advices ? `${t?.name}: ${t?.advices} \n` : "";

  const triggerResetTreatmentDescriptions = (
    list: MedicalTreatmentResponse[]
  ): void => {
    let newTreatmentText = "";
    let newRecommendationText = "";

    // Create the description text from the treatment list
    list.forEach((tr: MedicalTreatmentResponse) => {
      const filteredItems = clinicTreatmentsByPetSpecies?.filter(
        (t: TreatmentResponse) => t.id === tr.id
      );

      newTreatmentText += createTreatmentText(filteredItems[0]);
      newRecommendationText += createAdviceText(filteredItems[0]);
    });

    setTreatmentText(newTreatmentText);
    setRecommendationText(newRecommendationText);
  };

  const updateTreatmentDescriptions = (
    newTreatment: TreatmentResponse
  ): void => {
    setTreatmentText(`${treatmentText}${createTreatmentText(newTreatment)}`);
    setRecommendationText(
      `${recommendationText}${createAdviceText(newTreatment)}`
    );
  };

  const handleTreatmentsChange = (
    newTreatments: TreatmentResponse[],
    treatmentQuantity: number
  ) => {
    if (newTreatments.length > 1 || newTreatments.length === 0) {
      return;
    }

    const newTreatment = newTreatments[0];

    if (
      newlyAdded.treatments.find((t) => t.id === newTreatment.id) !== undefined
    ) {
      return;
    }

    updateTreatmentDescriptions(newTreatment);

    let newMedicines: UsedInventoryItemResponse[] = [...newlyAdded.medicines];
    let newVaccines: UsedInventoryItemResponse[] = [...newlyAdded.vaccines];
    let newOtherItems: UsedInventoryItemResponse[] = [
      ...newlyAdded.otherInventoryItems,
    ];

    newTreatment.usedItems.forEach((usedItem: TreatmentItemResponse) => {
      const item: UsedInventoryItemResponse = {
        accountingCode: usedItem.item.accountingCode,
        categoryName: usedItem.categoryName,
        id: usedItem.item.id,
        name: usedItem.item.name,
        price: usedItem.item.sellingPrice,
        quantity: usedItem.quantity,
        unit: usedItem?.item.acquisitionUnit,
        vat: usedItem.item.sellingVat,
      };

      let alreadyAdded;
      let { quantity } = item;

      switch (item.categoryName) {
        case "Medicines":
          alreadyAdded = newMedicines.find((i) => i.id === item.id);
          if (alreadyAdded) {
            newMedicines = newMedicines.filter((i) => i.id !== item.id);
            quantity += alreadyAdded.quantity;
          }
          newMedicines.push({ ...item, quantity });
          break;
        case "Vaccines":
          alreadyAdded = newVaccines.find((i) => i.id === item.id);
          if (alreadyAdded) {
            newVaccines = newVaccines.filter((i) => i.id !== item.id);
            quantity += alreadyAdded.quantity;
          }
          newVaccines.push({ ...item, quantity });
          break;
        default:
          alreadyAdded = newOtherItems.find((i) => i.id === item.id);
          if (alreadyAdded) {
            newOtherItems = newOtherItems.filter((i) => i.id !== item.id);
            quantity += alreadyAdded.quantity;
          }
          newOtherItems.push({ ...item, quantity });
          break;
      }
    });

    newTreatment.fees.forEach((feeItem: TreatmentFeeResponse) => {
      setUsedFees({ type: "addFromTreatment", itemToAdd: feeItem });
    });

    logger.debug("MedicalTreatmentResponse is added");
    setNewlyAdded((prevState) => ({
      ...prevState,
      medicines: newMedicines,
      vaccines: newVaccines,
      otherInventoryItems: newOtherItems,
      treatments: [
        ...newlyAdded.treatments,
        {
          accountingCode: newTreatment.accountingCode,
          id: newTreatment.id,
          name: newTreatment.name,
          price: newTreatment.price,
          quantity: treatmentQuantity,
          vat: newTreatment.vat,
        },
      ],
    }));

    triggerChange();
  };

  const deleteTreatment = (
    deleteItems: boolean,
    resetTrDescriptions: boolean
  ) => {
    if (!treatmentToDelete) {
      return;
    }

    const newTreatments = newlyAdded.treatments.filter(
      (i) => i.id !== treatmentToDelete.id
    );

    if (!deleteItems) {
      // Delete the treatment without deleting corresponding items
      setNewlyAdded((prevState) => ({
        ...prevState,
        treatments: newTreatments,
      }));
    } else {
      const newMedicines: UsedInventoryItemResponse[] = [];
      const newVaccines: UsedInventoryItemResponse[] = [];
      const newOtherItems: UsedInventoryItemResponse[] = [];

      newlyAdded.medicines.forEach((item) => {
        const treatmentItem = treatmentToDelete.usedItems.find(
          (i) => i.id === item.id
        );

        if (treatmentItem) {
          item.quantity -= treatmentItem.quantity || 0;
        }

        if (!treatmentItem || item.quantity > 0) {
          newMedicines.push(item);
        }
      });

      newlyAdded.vaccines.forEach((item) => {
        const treatmentItem = treatmentToDelete.usedItems.find(
          (i) => i.id === item.id
        );

        if (treatmentItem) {
          item.quantity -= treatmentItem.quantity || 0;
        }

        if (!treatmentItem || item.quantity > 0) {
          newVaccines.push(item);
        }
      });

      newlyAdded.otherInventoryItems.forEach((item) => {
        const treatmentItem = treatmentToDelete.usedItems.find(
          (i) => i.id === item.id
        );

        if (treatmentItem) {
          item.quantity -= treatmentItem.quantity || 0;
        }

        if (!treatmentItem || item.quantity > 0) {
          newOtherItems.push(item);
        }
      });

      treatmentToDelete.fees.forEach((treatmentFee: TreatmentFeeResponse) => {
        setUsedFees({ type: "reduceQuantity", itemToChange: treatmentFee });
      });

      // Delete the treatment and the corresponding items
      setNewlyAdded((prevState) => ({
        ...prevState,
        treatments: newTreatments,
        medicines: newMedicines,
        vaccines: newVaccines,
        otherInventoryItems: newOtherItems,
      }));
    }

    if (resetTrDescriptions) {
      triggerResetTreatmentDescriptions(newTreatments);
    }

    setTreatmentToDelete(undefined);
    triggerChange();
  };

  const deleteDiagnose = (what: string) => {
    const newArray: string[] = newlyAdded.diagnoses.filter(
      (i: string) => i !== what
    );

    setNewlyAdded((prevState) => ({ ...prevState, diagnoses: newArray }));

    triggerChange();
  };

  const deleteDifferentialDiagnoses = (what: string) => {
    const newArray: string[] = newlyAdded.differentialDiagnoses.filter(
      (i: string) => i !== what
    );

    setNewlyAdded((prevState) => ({
      ...prevState,
      differentialDiagnoses: newArray,
    }));

    triggerChange();
  };

  const deleteCurrent = (id: string, where: InventoryRelatedTypes) => {
    const newArray: any[] = [];

    newlyAdded[where].forEach(
      (i: UsedInventoryItemResponse | MedicalTreatmentResponse) => {
        if (i.id !== id) {
          newArray.push(i);
        }
      }
    );

    setNewlyAdded((prevState) => ({ ...prevState, [where]: newArray }));

    triggerChange();
  };

  const sign = async () => {
    if (resIdS === undefined || getData === undefined) {
      return;
    }

    try {
      closeSignModal();
      setPageLoading(true);
      const response = await MedicalRecordApi.signMedicalRecord(resIdS);
      setSigned(true);
      setGetData({ ...response.data });

      const versions = [...medicalRecordVersions];
      const i = versions.findIndex((v) => v.id === response.data.id);
      if (i >= 0) {
        versions[i].status = response.data.status;
        versions[i].signedBy = response.data.signedBy;
        versions[i].signDate = response.data.signDate;
      }

      setMedicalRecordVersions(versions);

      const resp = await InventoryItemApi.updateStockWithMedicalRecord(
        getData.clinicId,
        response.data.id,
        true
      );

      if (!resp.data.valid) {
        setWarningModal(true);
      }
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPageLoading(false);
    }
  };

  const gotoCorrected = (id: string) => {
    if (isPage) {
      navigate(`/medical-record/${id}`);
      window.location.reload();
    } else {
      setMedicalRecordCorrected((prevState) => !prevState);
    }
  };

  const addCorrection = async () => {
    if (resIdS === undefined) return;

    try {
      const response = await MedicalRecordApi.createCorrectiveMedicalRecord(
        resIdS
      );
      gotoCorrected(response.data.id);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  };

  const handleClose = () => setWarningModal(false);

  const submitButton = () => {
    if (
      getData?.status === MedicalRecordStatus.IN_PROGRESS &&
      accessScope === ReservationAccessScope.SIGN
    ) {
      return (
        <Button
          variant="primary"
          fullWidth
          className="py-4"
          onClick={openSignModal}
        >
          {getData?.previousVersionId ? strings.signCorrection : strings.sign}
        </Button>
      );
    }
    if (
      getData?.status === MedicalRecordStatus.SIGNED &&
      !getData.nextVersionId &&
      accessScope === ReservationAccessScope.SIGN
    ) {
      return (
        <Button
          variant="tertiary"
          fullWidth
          className="py-4"
          onClick={addCorrection}
        >
          {strings.openForCorrection}
        </Button>
      );
    }

    return null;
  };

  const markTreatmentToDelete = (treatmentId: string) => {
    const clinicTreatment = clinicTreatmentsByPetSpecies?.find(
      (tr: TreatmentResponse) => tr.id === treatmentId
    );

    if (clinicTreatment) {
      setTreatmentToDelete(clinicTreatment);
    } else {
      /* If the clinic treatment has been deleted since using it */
      const medicalRecordTreatment = getData?.treatments?.find(
        (tr) => tr.id === treatmentId
      );
      if (medicalRecordTreatment) {
        setTreatmentToDelete({
          name: medicalRecordTreatment.name,
          id: treatmentId,
          price: medicalRecordTreatment.price,
          usedItems: [],
          fees: [],
          species: [],
          contentNotAvailable: true,
        });
      }
    }
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const mainSectionContextValue = {
    medicalRecord: getData,
    signed,
    isUserNotOwner,
    triggerChange,
    accessScope,
    setFilesModalOpen,
    files,
    resIdS,
    anamnesis,
    setAnamnesis,
    physicalExamination,
    setPhysicalExamination,
    differentialDiagnoses: newlyAdded.differentialDiagnoses,
    deleteDifferentialDiagnoses,
    setDifferentialDiagnoses,
    diagnostics: diagnosticsText,
    setDiagnostics: setDiagnosticsText,
    treatmentText,
    setTreatmentText,
    recommendationText,
    setRecommendationText,
    weight,
    setWeight,
    chipNumber,
    setChipNumber,
    tattooNumber,
    setTattooNumber,
    deleteDiagnose,
    setDiagnoses,
    diagnoses: newlyAdded.diagnoses,
    countryDetails,
    newlyAdded,
    error,
    handleTreatmentsChange,
    clinicTreatmentsByPetSpecies,
    markTreatmentToDelete,
    usedFees,
    setUsedFees,
    setUsedOthers,
    handleInventoryItemChange,
    additionalFieldMethods,
    medicalRecordVersions,
    setMedicalRecordVersions,
    deleteCurrent,
  };

  if (!getData && error) {
    return (
      <div className="container">
        <AlertBox message={error} closeAble={false} className="m-3" />
      </div>
    );
  }

  if (!getData || loading) {
    return <LoaderInline />;
  }

  return (
    <>
      <MainSectionContext.Provider value={mainSectionContextValue}>
        <ReservationSection
          addCorrection={addCorrection}
          cancellable={cancellable}
          reload={reloadReservations}
          reservationData={reservationData}
        />
        <AlertBox className="my-3" message={error} />
        {isUserNotOwner && (
          <PetAndOwnerSection pet={getData.pet} petOwner={getData.petOwner} />
        )}
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 lg:col-span-8 order-2 lg:order-1">
            <div className="lg:tw-card flex flex-col space-y-8 p-4 lg:p-6">
              <WeightSection />
              <AnamnesisSection />
              <ExaminationSection />
              <DiagnosisSection />
              <TreatmentSectionMain />
              {isFeatureAvailable(ClinicFeature.PRESCRIPTION) ? (
                <MainFormSection title={strings.prescriptions}>
                  <PrescriptionSection
                    medicalRecordId={getData.universalId}
                    clinicId={getData?.clinicId}
                    petId={getData.pet.id}
                    state={{
                      weight: Number(weight),
                      medicalRecordId: getData.universalId,
                    }}
                    signed={signed}
                  />
                </MainFormSection>
              ) : (
                <></>
              )}
              <FollowUpSection />
              <VersionSection
                dropUp
                medicalRecordVersions={medicalRecordVersions}
                medicalRecordData={getData}
                reservationData={reservationData}
              />
              {submitButton()}
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4 order-1 lg:order-2">
            <div className="flex flex-col space-y-6">
              {isUserNotOwner && (
                <TodoSection
                  medicalRecord={{
                    universalMedicalRecordId: getData.universalId,
                    medicalRecordId: getData.id,
                    pet: {
                      clinicPetDetails: {
                        ...getData.pet,
                        chronicDiseases: getData.pet.chronicDiseases || [],
                      },
                    },
                    petOwner: personDetailsToPetOwner(getData.petOwner),
                    medicalRecordDateTime: getData.creationDate,
                    reservationType: reservationData?.reservationType,
                  }}
                />
              )}
              {isUserNotOwner && <MedicalHistorySection lists={lists} />}
              {debounce ? (
                <CostsSection
                  accessScope={accessScope}
                  deleteCurrent={deleteCurrent}
                  deleteTreatment={markTreatmentToDelete}
                  invoiceId={getData?.invoiceId}
                  medicines={newlyAdded.medicines}
                  medicalRecordId={getData?.id}
                  otherInventoryItems={newlyAdded.otherInventoryItems}
                  prescriptions={lists.prescriptions}
                  reservationId={reservationData?.id}
                  reservationState={reservationData?.state}
                  signed={signed}
                  treatments={newlyAdded.treatments}
                  update={BillingSectionUpdateCallback}
                  updateFees={(a: UsedFeeActions) => {
                    setUsedFees(a);
                    triggerChange();
                  }}
                  updateOthers={(a: UsedOtherActions) => {
                    setUsedOthers(a);
                    triggerChange();
                  }}
                  updateMedicalRecord={(
                    reservationState: ReservationState,
                    mr?: MedicalRecordResponse
                  ) => {
                    if (mr) {
                      setGetData(mr);
                    }
                    if (reservationData) {
                      setReservationData({
                        ...reservationData,
                        state: reservationState,
                      });
                    }
                  }}
                  usedFees={usedFees}
                  usedOthers={usedOthers}
                  vaccines={newlyAdded.vaccines}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </MainSectionContext.Provider>
      <SignModal
        closeSignModal={closeSignModal}
        petName={debounce ? getData.pet.name : ""}
        sign={sign}
        signModalOpened={signModalOpened}
        petOwnerEmail={getData?.petOwner?.emails[0]?.value}
      />
      <FilesModal
        openType={filesModalOpen}
        close={() => setFilesModalOpen(undefined)}
        medicalRecord={getData}
        files={files}
        onChange={triggerChange}
      />
      <NegativeStockModal isOpen={warningModal} close={handleClose} />
      <DeleteTreatmentModal
        close={() => {
          setTreatmentToDelete(undefined);
        }}
        deleteTreatment={deleteTreatment}
        isOpen={!!treatmentToDelete}
        treatmentToDelete={treatmentToDelete}
      />
    </>
  );
}

export default MedicalRecordComponent;
