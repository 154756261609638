/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { strings } from "../../../common/Strings/Strings";
import { vetDocumentIcon } from "../../../assets/AssetConfig";
import { Certificate as CertificateIcon } from "../../../common/Icons/Certificate";
import Button from "../../../components/Button";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import { XMark } from "../../../common/Icons/XMark";

interface Props {
  documents: File[];
  onChoose(): void;
  setDocuments: (documents: File[]) => void;
}

const DocumentsUploadPageHeader = ({
  documents,
  onChoose,
  setDocuments,
}: Props): ReactElement => (
  <>
    <div className="flex items-center space-x-2 text-zinc-800 dark:text-white">
      <CertificateIcon />
      <div className="font-semibold leading-tight">
        {strings.submitMedicinalCertificate}
      </div>
    </div>
    <div>{strings.vetProfessionNotValidated}</div>
    <div className="flex items-center justify-center">
      <img src={vetDocumentIcon} alt="medicinal certificate" />
    </div>
    <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
      {documents.map((file: File) => (
        <div className="flex items-center justify-between p-4" key={file.name}>
          <div className="flex items-center space-x-1.5">
            <div className="text-green-600 dark:text-green-600">
              <CheckCircle className="h-6 w-6 flex-shrink-0" />
            </div>
          </div>
          <p className="pl-2">{file.name}</p>
          <Button
            variant="icon"
            onClick={() => {
              setDocuments([]);
            }}
          >
            <XMark />
          </Button>
        </div>
      ))}
      <div hidden={documents.length > 0} className="p-4">
        <Button variant="link" className="p-0" onClick={onChoose}>
          + {strings.addFile}
        </Button>
      </div>
    </div>
  </>
);

export default DocumentsUploadPageHeader;
