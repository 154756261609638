/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ExtraCost } from "../../../models/medical/ExtraCost";

export type UsedOtherActions =
  | { type: "fill"; itemsToAdd: ExtraCost[] }
  | { type: "add"; itemToAdd: ExtraCost }
  | { type: "changeQuantity"; itemToChange: ExtraCost; newQuantity: number }
  | { type: "changePrice"; itemToChange: ExtraCost; newPrice: number }
  | { type: "remove"; itemToRemove: ExtraCost };

export const otherReducer = (
  state: Array<ExtraCost>,
  action: UsedOtherActions
): ExtraCost[] => {
  switch (action.type) {
    case "fill": {
      return action.itemsToAdd;
    }
    case "add": {
      const addActionId = action.itemToAdd.id;
      const alreadyAddedItemIndex = state.findIndex(
        (item: ExtraCost) => item.id === addActionId
      );

      if (alreadyAddedItemIndex >= 0) {
        state[alreadyAddedItemIndex].quantity += action.itemToAdd.quantity;
        return [...state];
      }

      return [...state, action.itemToAdd];
    }
    case "changeQuantity": {
      const changeActionId = action.itemToChange.id;
      const changeIndex = state.findIndex(
        (item: ExtraCost) => item.id === changeActionId
      );

      if (changeIndex >= 0) {
        state[changeIndex].quantity = action.newQuantity;
      }
      return [...state];
    }
    case "changePrice": {
      const changePriceActionId = action.itemToChange.id;
      const changePriceIndex = state.findIndex(
        (item: ExtraCost) => item.id === changePriceActionId
      );

      if (changePriceIndex >= 0) {
        state[changePriceIndex].price = action.newPrice;
      }
      return [...state];
    }
    case "remove": {
      const removeActionId = action.itemToRemove.id;

      return [...state.filter((item: ExtraCost) => item.id !== removeActionId)];
    }
    default: {
      return [];
    }
  }
};
