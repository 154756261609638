/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { InventoryItemStockCountingResponse } from "../../../../models/inventory/item/Response/InventoryItemStockCountingResponse";
import { InventoryItemUnit } from "../../../../models/inventory/item/InventoryItemUnit";
import { quantityUnits, strings } from "../../../../common/Strings/Strings";
import CountingViewModal from "../../Counting/CountingViewModal";
import InventoryItemApi from "../../../../api/InventoryItemApi";
import { InventoryItemStockItemResponse } from "../../../../models/inventory/item/Response/InventoryItemStockItemResponse";
import { getGeneralError } from "../../../../util/helperFunctions";
import Button from "../../../../components/Button";
import { Trash } from "../../../../common/Icons/Trash";
import { Eye } from "../../../../common/Icons/Eye";
import { InventoryItemDetailsResponse } from "../../../../models/inventory/item/Response/InventoryItemDetailsResponse";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import NumberFormatter from "../../../../util/NumberFormatter";

interface Props {
  acquisitionUnit?: InventoryItemUnit;
  countryDetails?: CountryDetailsResponse;
  counting: InventoryItemStockCountingResponse | any;
  inventoryItem: InventoryItemDetailsResponse;
  reloadItem(successMessage?: string): void;
  setError(message: string | null): void;
}

const CountingRow: React.FC<Props> = ({
  acquisitionUnit,
  counting,
  countryDetails,
  inventoryItem,
  reloadItem,
  setError
}) => {
  const [openCountingViewModal, setOpenCountingViewModal] =
    useState<InventoryItemStockCountingResponse>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const deleteStockCounting = async (stockCountingId: string) => {
    if (inventoryItem) {
      setDeleteLoading(true);

      try {
        await InventoryItemApi.deleteItemCounting(
          inventoryItem.id,
          stockCountingId
        );
        reloadItem(strings.countingDeletedSuccessfully);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setDeleteLoading(false);
      }
    }
  };

  const getSum = (stocks: InventoryItemStockItemResponse[]) => {
    let sum = 0;
    stocks.forEach((stock) => {
      sum += stock.quantity;
    });
    return sum;
  };

  return (
    <div className="p-3 grow grid xl:grid-cols-4 gap-4 bg-slate-50 dark:bg-slate-700" key={`counting-${counting.id}`}>
      <div>
        <div className="uppercase text-sm">{strings.countingHistoryUnit}</div>
        <div className="font-bold">
          {moment(counting.dateTime).format("LL")}
        </div>
      </div>
      <div className="xl:col-span-2 flex items-center">{counting.comment}</div>
      <div className="flex items-center">
        <div className="font-bold ">{NumberFormatter.format(getSum(counting.stocks))}{" "}
          {quantityUnits[acquisitionUnit || ""]}
          </div>
          <div className="ml-auto">
          <Button
            askAreYouSure={{
              enable: true,
              content: strings.formatString(
                strings.areYouSureToDeleteItemStock,
                <b>{inventoryItem.name}</b>
              ),
              title: strings.deleteStock,
            }}
            className="mr-3"
            disabled={deleteLoading}
            loading={deleteLoading}
            onClick={() => {
              void deleteStockCounting(counting.id);
            }}
            variant="icon"
          >
            <Trash />
          </Button>
          <Button
            variant="icon"
            onClick={() => {
              setOpenCountingViewModal(counting);
            }}
          >
            <Eye />
          </Button>
        </div>
      </div>
      <CountingViewModal
        acquisitionUnit={inventoryItem?.acquisitionUnit}
        close={() => {
          setOpenCountingViewModal(undefined);
        }}
        countryDetails={countryDetails}
        isOpen={!!openCountingViewModal}
        stocks={openCountingViewModal?.stocks}
      />
    </div>
  );
};

export default CountingRow;
