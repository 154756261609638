/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import IconBubble from "../../../components/IconBubble";
import { InventoryItemDetailsResponse } from "../../../models/inventory/item/Response/InventoryItemDetailsResponse";
import Button from "../../../components/Button";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { InventoryItemStockCountingResponse } from "../../../models/inventory/item/Response/InventoryItemStockCountingResponse";
import NumberFormatter from "../../../util/NumberFormatter";
import LowLevelIndicator from "../LowLevelIndicator";
import EmptyListText from "../../../components/EmptyListText";
import { InventoryItemStockResponse } from "../../../models/inventory/item/Response/InventoryItemStockResponse";
import { InventoryItemUsageResponse } from "../../../models/inventory/item/Response/InventoryItemUsageResponse";
import StockRow from "./ItemQuantityHistoryRows/StockRow";
import UsageRow from "./ItemQuantityHistoryRows/UsageRow";
import CountingRow from "./ItemQuantityHistoryRows/CountingRow";
import Filters from "../../../components/ReactHookFormFields/General/Filters";
import ItemStockModal from "../Stock/ItemStockModal";

interface Props {
  countryDetails?: CountryDetailsResponse;
  inventoryItem: InventoryItemDetailsResponse;
  reloadItem(successMessage?: string): void;
}

type MergedItemHistory = Array<{
  date: string;
  type: "usage" | "stock" | "counting";
  data:
    | InventoryItemStockResponse
    | InventoryItemUsageResponse
    | InventoryItemStockCountingResponse;
}>;

const ItemQuantityHistory: React.FC<Props> = ({
  countryDetails,
  inventoryItem,
  reloadItem,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [list, setList] = useState<MergedItemHistory>([]);
  const [openStockModal, setOpenStockModal] = useState<boolean>(false);
  const [itemStockToEdit, setItemStockToEdit] =
    useState<InventoryItemStockResponse>();

  const { control, watch } = useForm<{
    historyFilters: Array<"usage" | "stock" | "counting" | "all">;
  }>({ defaultValues: { historyFilters: ["all"] } });
  const filters = watch("historyFilters") || [];

  useEffect(() => {
    const temp: MergedItemHistory = [];
    if (filters.includes("all") || filters.includes("stock")) {
      inventoryItem.stocks.forEach((stock) => {
        temp.push({
          date: stock.dateTime,
          type: "stock",
          data: stock,
        });
      });
    }

    if (filters.includes("all") || filters.includes("usage")) {
      inventoryItem.usages.forEach((usage) => {
        temp.push({
          date: usage.dateTime,
          type: "usage",
          data: usage,
        });
      });
    }

    if (filters.includes("all") || filters.includes("counting")) {
      inventoryItem.stockCountings.forEach((stockCounting) => {
        temp.push({
          date: stockCounting.dateTime,
          type: "counting",
          data: stockCounting,
        });
      });
    }
    setList(
      temp.sort((a, b) => {
        if (moment(a.date).isAfter(b.date)) {
          return -1;
        } if (moment(b.date).isAfter(a.date)) {
          return 1;
        }
        return 0;
      })
    );
  }, [inventoryItem, filters]);

  const reloadList = (successMessage?: string) => {
    if (successMessage) {
      setSuccess(successMessage);
    }
    reloadItem();
  };

  return (
    <div className="w-full md:tw-card space-y-6 md:p-8 my-6">
      <div className="lg:flex items-center space-y-4 ">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
            {inventoryItem?.name}
          </h2>
          <div className="ml-3">
            <IconBubble>
              {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
            </IconBubble>
          </div>
        </div>
        <div className="lg:ml-auto space-x-3">
          <Button
            minW
            onClick={() => {
              setOpenStockModal(true);
            }}
          >
            {strings.refillStock}
          </Button>
          <Link to={`/inventory/item/${inventoryItem?.id}/counting-stock`}>
            <Button minW>{strings.countInventory}</Button>
          </Link>
        </div>
      </div>
      <AlertBox message={error} />
      <AlertBox message={success} type={AlertType.SUCCESS} />
      <div className="flex items-center">
        <div className="mr-2">
          {strings.stock}: {NumberFormatter.format(inventoryItem?.stock || 0)}{" "}
          {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
        </div>
        <LowLevelIndicator
          lowLevel={inventoryItem?.lowLevel}
          stock={inventoryItem?.stock}
        />
      </div>
      <Filters
        defaultFilters={["all"]}
        name="historyFilters"
        control={control}
        options={[
          {
            value: "all",
            title: strings.all,
          },
          {
            value: "stock",
            title: strings.orderHistory,
          },
          {
            value: "usage",
            title: strings.usageHistory,
          },
          {
            value: "counting",
            title: strings.countingHistory,
          },
        ]}
      />

      <div className="divide-y dark:divide-gray-700 tw-card">
        {list.length === 0 ? (
          <div className="p-3">
            <EmptyListText />
          </div>
        ) : (
          list.map((row) => {
            if (row.type === "stock") {
              return (
                <StockRow
                  acquisitionUnit={inventoryItem?.acquisitionUnit}
                  countryDetails={countryDetails}
                  inventoryItem={inventoryItem}
                  reloadItem={reloadList}
                  setError={setError}
                  setItemStockToEdit={setItemStockToEdit}
                  setOpenStockModal={setOpenStockModal}
                  stock={row.data}
                />
              );
            }
            if (row.type === "usage") {
              return (
                <UsageRow
                  acquisitionUnit={inventoryItem?.acquisitionUnit}
                  countryDetails={countryDetails}
                  usage={row.data}
                />
              );
            }
            if (row.type === "counting") {
              return (
                <CountingRow
                  acquisitionUnit={inventoryItem?.acquisitionUnit}
                  countryDetails={countryDetails}
                  counting={row.data}
                  inventoryItem={inventoryItem}
                  reloadItem={reloadList}
                  setError={setError}
                />
              );
            }
            return <></>;
          })
        )}
      </div>
      <ItemStockModal
        close={(shouldReloadItem: boolean, successMessage?: string) => {
          setOpenStockModal(false);
          setItemStockToEdit(undefined);
          if (shouldReloadItem) {
            reloadList(successMessage);
          }
        }}
        countryDetails={countryDetails}
        inventoryItem={inventoryItem}
        itemStockToEdit={itemStockToEdit}
        isOpen={openStockModal}
      />
    </div>
  );
};

export default ItemQuantityHistory;
