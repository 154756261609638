/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import CombinedSelectComponent, {
  CombinedSelectComponentProps,
  CombinedSelectInputProps,
} from "./CombinedSelectComponent";
import { useDebouncedState } from "../../hooks/hooks";

interface AnyMap {
  [key: string]: any;
}

type T = AnyMap | string | number;

export interface DynamicSelectComponentProps {
  defaultOptions?: T[];
}

const DynamicSelectComponent = ({
  defaultOptions,
  options,
  onInputValueChange,
  ...rest
}: CombinedSelectComponentProps &
  CombinedSelectInputProps &
  DynamicSelectComponentProps): ReactElement => {
  const inputText = useDebouncedState<string>("", 600);
  const [prevText, setPrevText] = useState<string>("");
  const [prevOptions, setPrevOptions] = useState<T[]>([]);
  const [localOptions, setLocalOptions] = useState<T[]>(defaultOptions || []);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLocalOptions(defaultOptions || []);
  }, [defaultOptions]);

  useEffect(() => {
    if (options) {
      setLocalOptions(options);
      setPrevOptions(options);
    }
    setLoading(false);
  }, [options]);

  useEffect(() => {
    setPrevText(inputText.value);

    if (inputText.value.length === 0) {
      setLocalOptions(defaultOptions || []);
      setLoading(false);
      return;
    }
    if (onInputValueChange) {
      onInputValueChange(inputText.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputText.value]);

  return (
    <CombinedSelectComponent
      isDynamic
      loadingOptions={loading}
      onClear={() => {
        setLocalOptions(defaultOptions || []);
      }}
      onInputValueChange={(value: string) => {
        if (value === prevText) {
          setLoading(false);
          setLocalOptions(prevOptions);
        } else {
          setLoading(true);
        }
        inputText.setValue(value);
      }}
      options={localOptions}
      {...rest}
    />
  );
};

export default DynamicSelectComponent;
