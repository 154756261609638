/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { SupportTopics } from "../Topics";

interface Props {
  isPreview?: boolean;
}

const StripeIntegration: React.FC<Props> = ({ isPreview }) => {
  return (
    <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3 flex flex-col tw-card p-4 space-y-4 ">
      {isPreview ? (
        <Link
          className="tw-link-tercier"
          to={`/help/topics/${SupportTopics.stripe}`}
        >
          {strings.stripeIntegration}
        </Link>
      ) : (
        <div className="font-bold">{strings.stripeIntegration}</div>
      )}

      <div className="space-y-4">
        <div className={isPreview ? "line-clamp-2" : ""}>
          {strings.formatString(
            strings.stripeIntegrationSupportContent,
            <a
              href="https://stripe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stripe
            </a>
          )}
        </div>
        <ul hidden={isPreview}>
          <li>
            1.{" "}
            <a
              href="https://dashboard.stripe.com/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              {strings.stripeIntegrationSupportStep1}
            </a>
          </li>
          <li>
            2.{" "}
            <a
              href="https://dashboard.stripe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {strings.stripeIntegrationSupportStep2}
            </a>{" "}
            (developers/API keys)
          </li>
          <li>
            3.{" "}
            <a
              href="/clinic-settings/bank "
              target="_blank"
              rel="noopener noreferrer"
            >
              {strings.stripeIntegrationSupportStep3}
            </a>{" "}
            ({strings.publicKey}, {strings.privateKey})
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StripeIntegration;
