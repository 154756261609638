/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../util/helperFunctions";
import VetApi from "../../../../api/VetApi";
import { vetLicence } from "../../../../assets/AssetConfig";
import AlertBox from "../../../../components/AlertBox";
import { UserResponse } from "../../../../models/user/UserResponse";
import VetRegistrationNumberForm, {
  VetRegistrationNumberProps,
} from "../../../UserManagement/ProfileSettings/ProfileSettingsTabs/VetTab/VetRegistrationNumber/VetRegistrationNumberForm";
import Button from "../../../../components/Button";
import LoaderInline from "../../../../components/LoaderInline";
import { License as LicenseIcon } from "../../../../common/Icons/License";
import { Card } from "../../../../components/Cards/Card";

interface Props {
  onSuccess?(response: UserResponse): void;
  onSkip: () => void;
}

const VetRegistrationNumberCard: React.FC<Props> = ({
  onSuccess,
  onSkip,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const methods = useForm<VetRegistrationNumberProps>({ mode: "onChange" });

  useEffect(() => {
    setError(null);
  }, []);

  const onSubmit = async (data: VetRegistrationNumberProps) => {
    setLoading(true);
    setError(null);

    try {
      const response = await VetApi.addVetRegistrationNumber({
        ...data,
        countryCode: data.countries[0].countryCode,
      });

      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      title={strings.registrationNumber}
      footerText={strings.addItLater}
      onAction={onSkip}
    >
      <div className="flex space-x-2 items-center text-zinc-800 dark:text-white">
        <LicenseIcon className="w-6 h-6 flex-shrink-0" />
        <div className="font-semibold leading-tight">
          {strings.addRegistrationNumber}
        </div>
      </div>
      <div>{strings.vetRegNumberInfo}</div>
      <div className="flex items-center justify-center">
        <img src={vetLicence} alt="vetLicence" />
      </div>
      <FormProvider {...methods}>
        <VetRegistrationNumberForm />
      </FormProvider>
      <AlertBox className="mb-3 w-100" message={error} />
      <div className="flex w-full">
        {loading ? (
          <LoaderInline className="mx-auto" />
        ) : (
          <Button
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(onSubmit)}
          >
            {strings.save}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default VetRegistrationNumberCard;
