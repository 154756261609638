/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { strings } from "../../../../../common/Strings/Strings";
import SocialLoginApi from "../../../../../api/SocialLoginApi";
import { setUpAuthToken } from "../../../../../util/axiosSetup";
import { getGeneralError } from "../../../../../util/helperFunctions";
import LoaderInline from "../../../../../components/LoaderInline";
import { useUser } from "../../../../../contexts/UserContext";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import { SocialLoginProvider } from "../../../../../models/user/SocialLoginProvider";
import EmailConfirmationInfoBase from "../../../../../components/contact/confirmation/EmailConfirmationInfoBase";
import UserApi from "../../../../../api/UserApi";

interface LocationState {
  socialProvider?: SocialLoginProvider;
  socialToken?: string;
  email: string;
}

/* Currently not available, this component is considered legacy */
/* TODO: Implement social registration & login */
export default function SocialRegistrationSuccessful(): ReactElement {
  const { fetchUser } = useUser();
  const { state } = useLocation();
  const { socialProvider, socialToken, email }: LocationState = state;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingResend, setLoadingResend] = useState<boolean>(false);
  const [resendSuccess, setResendSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const doSocialLogin = async (
    provider: SocialLoginProvider,
    token: string
  ) => {
    setLoading(true);
    setError(null);
    let response;

    try {
      if (provider === SocialLoginProvider.FACEBOOK) {
        response = await SocialLoginApi.loginWithFacebook(token);
      } else {
        response = await SocialLoginApi.loginWithGoogle(token);
      }

      const { data } = response;

      setUpAuthToken(data);
      fetchUser().then(() => {
        navigate("/");
        setLoading(false);
      });
    } catch (err) {
      setError(await getGeneralError(err));
      setLoading(false);
    }
  };

  const sendConfirmationMail = async () => {
    setLoadingResend(true);
    setResendSuccess(false);
    setError(null);

    try {
      await UserApi.sendRegistrationConfirmationEmail(email);
      setResendSuccess(true);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoadingResend(false);
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="mx-auto flex flex-col items-center justify-center space-y-6 px-4 py-10 lg:px-0 lg:py-14">
          <div className="w-full space-x-0 space-y-10 lg:w-auto lg:space-x-6 lg:space-y-0">
            <div className="w-full md:max-w-md md:tw-card md:p-8 space-y-4">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.registration}
              </h1>
              {socialProvider && socialToken ? (
                <>
                  <AlertBox
                    closeAble={false}
                    message={strings.registrationSuccessful}
                    type={AlertType.SUCCESS}
                  />
                  <AlertBox
                    closeAble={false}
                    message={error}
                    className="mt-3"
                  />
                  <LoaderInline hidden={!loading} className="p-3" />
                  <Button
                    variant="primary w-100 mt-3"
                    onClick={() => {
                      void doSocialLogin(socialProvider, socialToken);
                    }}
                  >
                    {strings.login}
                  </Button>
                </>
              ) : (
                <EmailConfirmationInfoBase
                  email={email}
                  loading={loadingResend}
                  resend={sendConfirmationMail}
                  success={resendSuccess}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
