/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { useDebouncedState } from "../../../hooks/hooks";
import { useClinic } from "../../../contexts/ClinicContext";
import { HighlightedText } from "../../../util/StringUtils";
import LoaderInline from "../../../components/LoaderInline";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import EmptyListText from "../../../components/EmptyListText";
import { Colors } from "../../../models/Colors";
import ClickOutsideDetector from "../../../components/ClickOutsideDetector";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { PageResponse } from "../../../models/PageResponse";
import PetOwnerApi from "../../../api/PetOwnerApi";
import { getGeneralError } from "../../../util/helperFunctions";

interface Props {
  onSelectOwner: (owner: PetOwnerResponse) => void;
  onError?: (err: string | null) => void;
}

const PetOwnerSearchSection: React.FC<Props> = ({
  onSelectOwner,
  onError,
}: Props) => {
  const { clinic } = useClinic();
  const [isDown, setIsDown] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<PetOwnerResponse[]>([]);
  const { value: searchQuery, setValue: setSearchQuery } =
    useDebouncedState<string>("", 400);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);

  const searchChange = ({ target: { value } }: any) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    let isMounted = true;

    const searchOwner = async (clinicId: string) => {
      try {
        const response: AxiosResponse<PageResponse<PetOwnerResponse>> =
          await PetOwnerApi.getPetOwners(clinicId, {
            pageSize: 100,
            pageNumber: 0,
            query: searchQuery,
          });
        setSearchResult(response.data.elements);
      } catch (e) {
        if (onError) {
          onError(await getGeneralError(e));
        }
      } finally {
        setIsSearchLoading(false);
      }
    };

    if (searchQuery.length >= 3 && clinic?.id) {
      setIsDown(true);
      setIsSearchLoading(true);
      void searchOwner(clinic.id);
    } else if (isMounted) {
      setSearchResult([]);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <ClickOutsideDetector
      className="w-100"
      listen
      onClickOutside={() => {
        setIsDown(false);
      }}
    >
      <div className="dropdown w-100">
        <div className="w-100">
          <div className="d-flex justify-content-between w-100">
            <label htmlFor="search">
              {strings.searchPetOwner}
            </label>
          </div>
          <input
            autoComplete={AutoCompleteOptions.off}
            className="form-control custom-form-control-input input-field-label typeahead"
            name="search"
            onChange={searchChange}
            onClick={() => {
              if (searchResult.length > 0 && !isDown) {
                setIsDown(true);
              }
            }}
            placeholder={strings.searchPetOwner}
            type="text"
          />
        </div>
        <ul
          className={`dropdown-menu w-100 ${isDown ? "show" : ""}`}
          style={{
            maxHeight: "300px",
            minWidth: 0,
            padding: 0,
            overflowY: "scroll",
          }}
        >
          {isSearchLoading ? (
            <div style={{ padding: "12px", color: Colors.BLOCKBORDER }}>
              <LoaderInline />
            </div>
          ) : (
            <div style={{ fontSize: "12px" }}>
              {searchResult.length > 0 ? (
                <>
                  {searchResult.map(
                    (petOwner: PetOwnerResponse, index: number) => (
                      <div
                        className={
                          index === searchResult.length - 1
                            ? ""
                            : "hr-on-bottom"
                        }
                        key={index}
                        style={{ padding: "12px" }}
                      >
                        <div>
                          <div
                            onClick={() => {
                              onSelectOwner(petOwner);
                              setIsDown(false);
                            }}
                            role="button"
                            style={{ cursor: "pointer" }}
                            tabIndex={0}
                          >
                            <HighlightedText
                              text={petOwner.petOwnerDetails.fullName}
                              highlights={searchQuery.split(" ")}
                            />
                          </div>
                          {petOwner.petOwnerDetails.personalId ? (
                            <div
                              onClick={() => {
                                onSelectOwner(petOwner);
                                setIsDown(false);
                              }}
                              role="button"
                              style={{ cursor: "pointer" }}
                              tabIndex={0}
                            >
                              <span>
                                <HighlightedText
                                  text={petOwner.petOwnerDetails.personalId}
                                  highlights={searchQuery.split(" ")}
                                />
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                          {petOwner.userDetails?.emails[0]?.value ? (
                            <div
                              onClick={() => {
                                onSelectOwner(petOwner);
                                setIsDown(false);
                              }}
                              role="button"
                              style={{ cursor: "pointer" }}
                              tabIndex={0}
                            >
                              <span>
                                <HighlightedText
                                  text={petOwner.userDetails?.emails[0]?.value}
                                  highlights={searchQuery.split(" ")}
                                />
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </>
              ) : (
                <div style={{ padding: "12px" }}>
                  <EmptyListText />
                </div>
              )}
              <div className="hr-on-top" style={{ padding: "12px" }}>
                <Link style={{ fontSize: "12px" }} to="/create-pet-owner">
                  {strings.createNew}
                </Link>
              </div>
            </div>
          )}
        </ul>
      </div>
    </ClickOutsideDetector>
  );
};

export default PetOwnerSearchSection;
