/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { strings } from "../../common/Strings/Strings";
import { PageProps } from "../../models/PageProps";
import { getAccessToken } from "../../util/LocalStorageVariables";
import { useUser } from "../../contexts/UserContext";
import { SiteManagerPage } from "./SiteManagerPage";
import { useChosenRole } from "../Main/Onboarding/ChooseRole";
import Menu from "./Dashboard/Menu";
import Health from "./Dashboard/Health";
import AppVersions from "./Dashboard/AppVersions";
import AnalyticsOverview from "./Dashboard/AnalyticsOverview";

function Dashboard(props: PageProps) {
  const authorities: string[] = jwtDecode(getAccessToken())?.authorities || [];
  const { user } = useUser();
  const { setPageLoading } = props;

  useChosenRole("manager");

  useEffect(() => {
    setPageLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasAuthority = (authority: string): boolean => {
    return (
      authorities.includes("MANAGER_FULL") || authorities.includes(authority)
    );
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 mb-4 mb-lg-0">
            <Menu authorities={authorities} user={user} />
          </div>
          <div className="col-12 col-lg-6">
            <div className="mb-4">
              <AnalyticsOverview />
            </div>
            {hasAuthority("MANAGER_CONFIG") && (
              <div className="mb-4">
                <Health />
              </div>
            )}
            <AppVersions />
          </div>
        </div>
      </div>
    </>
  );
}

export default SiteManagerPage(Dashboard, `${strings.welcome} admin!`);
