/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { strings } from "../../../common/Strings/Strings";
import Button from "../../../components/Button";
import { ArrowUnturnLeft } from "../../../common/Icons/ArrowUnturnLeft";

interface Props {
  deleteInvoice(): void;
}

const CreateInvoiceActions = ({ deleteInvoice }: Props): ReactElement => {
  return (
    <Button onClick={deleteInvoice} variant="tertiary">
      <ArrowUnturnLeft className="w-5 h-5 mr-2" /> {strings.withdrawReadyForPayment}
    </Button>
  );
};

export default CreateInvoiceActions;
