import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { LocalizedPetSpeciesResponse } from "../models/pet/LocalizedPetSpeciesResponse";
import Params from "../common/Params";

export default class PetSpeciesApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getPetSpecies(
    countryCode = "sv"
  ): ApiPromise<LocalizedPetSpeciesResponse[]> {
    const url = `${this.baseUrl}/pet-species/${countryCode}`;
    return axiosCommon.get(url);
  }
}
