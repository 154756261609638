/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Option } from "../../models/Option";
import { LocalizedPetSpeciesResponse } from "../../models/pet/LocalizedPetSpeciesResponse";
import { petSpecies } from "../Strings/Strings";
import { PetSpecies } from "../../models/pet/PetSpeciesEnum";

export const getAnimalStringFromCode = (
  localisedSpeciesCodes: LocalizedPetSpeciesResponse[] | undefined,
  code: string | undefined
) => {
  if (!localisedSpeciesCodes || !code) {
    return "";
  }

  let result = "";

  localisedSpeciesCodes.forEach((lsc: LocalizedPetSpeciesResponse) => {
    if (lsc.code === code) {
      result = lsc.petSpecies;
    }
  });

  return petSpecies[result];
};

export const generateAnimalOptionsGeneral = (): Option[] => {
  const newOptions: Option[] = [];

  for (const value in PetSpecies) {
    const temp = { title: petSpecies[value], value };
    newOptions.push(temp);
  }

  return newOptions;
};

export const animalSpeciesOptions = (
  localisedSpeciesCodes: LocalizedPetSpeciesResponse[] | undefined
): Option[] => {
  interface StringMap {
    [key: string]: string;
  }

  let list: Option[] = [];
  let codes: StringMap = {};

  if (!localisedSpeciesCodes) {
    return list;
  }

  /* Check whether two enum types have the same code. If so, merge names. (duck and goose) */
  localisedSpeciesCodes.forEach((option: LocalizedPetSpeciesResponse) => {
    if (codes[option.code]) {
      codes[option.code] =
        codes[option.code] + "/" + petSpecies[option.petSpecies];
    } else {
      codes[option.code] = petSpecies[option.petSpecies];
    }
  });

  for (let k in codes) {
    const newOption: Option = { value: k, title: codes[k] };
    list.push(newOption);
  }

  return list;
};

export const animalSpeciesConverterToCode = (
  localisedSpeciesCodes: LocalizedPetSpeciesResponse[] | undefined,
  pet: PetSpecies | undefined
): string => {
  let code = "51";

  if (!localisedSpeciesCodes || !pet) {
    return code;
  }

  localisedSpeciesCodes.forEach((item: LocalizedPetSpeciesResponse) => {
    if (item.petSpecies === pet) {
      code = item.code;
    }
  });

  return code;
};

export default {
  getAnimalStringFromCode,
  generateAnimalOptionsGeneral,
  animalSpeciesOptions,
  animalSpeciesConverterToCode,
};
