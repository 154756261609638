/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Control, RegisterOptions } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import InsuranceApi from "../../../api/InsuranceApi";
import { InsuranceCompanyResponse } from "../../../models/insurance/InsuranceCompanyResponse";
import { PetInsuranceCompanyResponse } from "../../../models/pet/PetInsuranceCompany";
import logger from "../../../util/logger";
import CombinedSelect from "../General/Select/CombinedSelect";

interface FieldProps {
  allowNew?: boolean;
  className?: string;
  name: string;
  control: Control<any>;
  defaultValue?: PetInsuranceCompanyResponse | InsuranceCompanyResponse;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
    >;
  label?: string;
  optional?: boolean;
  required?: boolean;
  width?: string;
}

const SelectInsuranceCompany = ({
  className,
  name,
  control,
  defaultValue,
  fieldOptions,
  label,
  optional,
  required,
  width
}: FieldProps): ReactElement => {
  const [insuranceCompanies, setInsuranceCompanies] = useState<
    InsuranceCompanyResponse[] | PetInsuranceCompanyResponse[]
  >([]);

  useEffect(() => {
    const loadInsuranceCompanies = async () => {
      try {
        const result = await InsuranceApi.getInsuranceCompanies();
        setInsuranceCompanies(result.data);
      } catch (e) {
        logger.error(e);
      }
    };

    void loadInsuranceCompanies();
  }, []);

  return (
    <CombinedSelect
      allowNew={false}
      control={control}
      className={className}
      fieldOptions={fieldOptions}
      name={name}
      label={label || strings.insuranceCompany}
      labelKey="name"
      multiple={false}
      optional={optional}
      options={insuranceCompanies}
      required={required}
      width={width}
    />
  );
};

export default SelectInsuranceCompany;

SelectInsuranceCompany.defaultProps = {
  allowNew: undefined,
  defaultValue: undefined,
  fieldOptions: undefined,
  optional: false,
  required: false,
};
