/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import AlertBox, { AlertType } from "../../../../../../components/AlertBox";
import { PinState } from "./PinCodeSettings";
import { Card } from "../../../../../../components/Cards/Card";
import CodeInputsForm from "../../../../../../components/ReactHookFormFields/Specific/CodeInputsForm";
import { strings } from "../../../../../../common/Strings/Strings";
import Button from "../../../../../../components/Button";

interface Props {
  setPinState: (pinState: PinState) => void;
  asCard?: boolean;
}

const HasCode: React.FC<Props> = ({ setPinState, asCard = true }: Props) => {
  const content = (
    <>
      <div className="my-5">
        <CodeInputsForm
          fill
          focusFirstInput={false}
          hideCharacters
          label={strings.pin}
          readOnly
          size={4}
        />
      </div>
      <AlertBox
        className="my-5"
        closeAble={false}
        message={strings.userHasPinCode}
        type={AlertType.INFO}
      />
      <div className="flex">
        <Button
          onClick={() => {
            setPinState(PinState.NewCode);
          }}
        >
          {strings.addNewPin}
        </Button>
      </div>
    </>
  );

  if (!asCard) {
    return content;
  }

  return (
    <Card size="md" type="simple" title={strings.setPin}>
      {content}
    </Card>
  );
};

export default HasCode;
