/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { SiteManagerPage } from "../SiteManagerPage";
import { PageProps } from "../../../models/PageProps";
import { strings } from "../../../common/Strings/Strings";
import AnalyticsEventsTable from "./AnalyticsEventsTable";

const AnalyticsEventsPage: React.FC<PageProps> = (props: PageProps) => {
  const { setPageLoading } = props;

  return (
    <div className="px-4 lg:px-6 py-6">
      <AnalyticsEventsTable setPageLoading={setPageLoading} />
    </div>
  );
};

export default SiteManagerPage(AnalyticsEventsPage, strings.activityLogs);
