/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import { strings } from "../../../common/Strings/Strings";
import PrescriptionOnlyModeSection from "./UsageModes/PrecriptionOnlyModeSection";
import ClinicDeletionSection from "./DeleteClinic/ClinicDeletionSection";
import { isFeatureAvailable } from "../../../util/helperFunctions";
import { ClinicFeature } from "../../../models/clinic/ClinicFeature";
import { Card } from "../../../components/Cards/Card";

interface Props {
  clinic?: ClinicResponse;
  refresh: () => void;
}

const GlobalVetOtherSettings: React.FC<Props> = ({
  clinic,
  refresh,
}: Props) => (
  <Card size="md" title={strings.settings} type="simple">
    {clinic && isFeatureAvailable(clinic, ClinicFeature.FEATURE_SETTINGS) && (
      <PrescriptionOnlyModeSection clinic={clinic} refresh={refresh} />
    )}
    <ClinicDeletionSection clinic={clinic} refresh={refresh} />
  </Card>
);

export default GlobalVetOtherSettings;
