import React from "react";

export default () => {
  throw new Error("Hello, Sentry!");

  // prettier-ignore
  // eslint-disable-next-line no-unreachable
  return <div>
        Hello, Sentry!
    </div>
};
