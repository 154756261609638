/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { XMark } from "../common/Icons/XMark";
import Button from "./Button";

export enum TagType {
  primary,
  primaryOutlined,
  secondary,
  secondaryOutlined,
  danger,
  success,
  warning,
}

interface Props {
  closeButtonAction?(): void;
  size?: "sm" | "lg";
  text: string;
  type?: TagType;
}

const Tag = ({
  closeButtonAction,
  size = "sm",
  text,
  type = TagType.secondary,
}: Props): ReactElement => {
  const removeButton = () => (
    <Button
      className="ml-1"
      hidden={!closeButtonAction}
      onClick={(e) => {
        e.preventDefault();
        if (closeButtonAction) {
          closeButtonAction();
        }
      }}
      variant="basic"
    >
      <XMark className="w-3 h-3 align-sub" strokeWidth={2} />
    </Button>
  );

  switch (type) {
    case TagType.primary:
      return (
        <>
          <span className="tw-btn-pill-primary-small">
            {text}
            {removeButton()}
          </span>
        </>
      );
    case TagType.primaryOutlined:
      return (
        <>
          <span className="tw-btn-pill-primary-outlined-small">
            {text}
            {removeButton()}
          </span>
        </>
      );
    case TagType.secondary:
      return (
        <>
          <span
            className={
              size === "sm"
                ? "tw-btn-pill-tercier-small"
                : "tw-btn-pill-tercier"
            }
          >
            {text}
            {removeButton()}
          </span>
        </>
      );
    case TagType.secondaryOutlined:
      return (
        <>
          <span
            className={
              size === "sm"
                ? "tw-btn-pill-secondary-small"
                : "tw-btn-pill-secondary"
            }
          >
            {text}
            {removeButton()}
          </span>
        </>
      );
    case TagType.danger:
      return (
        <span className="tw-btn-pill-red-small">
          {text}
          {removeButton()}
        </span>
      );
    case TagType.success:
      return (
        <span className="tw-btn-pill-green-small">
          {text}
          {removeButton()}
        </span>
      );
    case TagType.warning:
      return (
        <span className="tw-btn-pill-orange-small">
          {text}
          {removeButton()}
        </span>
      );
    default:
      return <></>;
  }
};

export default Tag;
