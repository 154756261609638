/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../Strings/Strings";
import { Bell } from "../../Icons/Bell";

interface Props {
  notificationCount: number;
}

export const NotificationsButton: React.FC<Props> = ({
  notificationCount,
}: Props) => (
  <div
    aria-current="page"
    className="block relative text-gray-100 hover:text-white focus:text-white dark:text-gray-300 dark:hover:text-gray-200 dark:focus:text-gray-200 transition-all duration-300"
  >
    <Bell variant="outline" />
    <span className="sr-only">{strings.notifications}</span>
    {notificationCount !== 0 && (
      <div className="absolute rounded-full -top-3 -right-3 w-6 h-6 flex-shrink-0 inline-flex items-center justify-center bg-sky-500 text-xs font-semibold text-white">
        {notificationCount}
      </div>
    )}
  </div>
);

export default NotificationsButton;
