/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { getGeneralError } from "../../util/helperFunctions";
import AlertBox from "../AlertBox";
import Field from "../ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import Button from "../Button";

interface Props {
  titleText: string;
  placeHolderText?: string;
  buttonText: string;
  submitAnswer: (msg: string) => Promise<void>;
  isDisabled?: boolean;
}

interface ChatInputForm {
  answer: string;
}

export const ChatInput: React.FC<Props> = ({
  submitAnswer,
  titleText,
  placeHolderText,
  buttonText,
  isDisabled = false,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ChatInputForm>({
    mode: "onChange",
  });

  const submit = async (formData: ChatInputForm) => {
    setLoading(true);
    setError(null);

    try {
      await submitAnswer(formData.answer);
      alert(formData.answer);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
      reset();
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Field
        name="answer"
        register={register}
        autoComplete={AutoCompleteOptions.off}
        placeholder={placeHolderText || titleText}
        readOnly={isDisabled}
        error={errors.answer}
        required
      />
      <AlertBox message={error} className="my-4" />
      <div className="flex justify-end my-2">
        <Button
          disabled={loading || !isValid || isDisabled}
          loading={loading}
          type="submit"
          variant="primary"
        >
          {buttonText}
        </Button>
      </div>
    </form>
  );
};

export default ChatInput;
