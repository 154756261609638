/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import qs from "query-string";
import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import Params from "../common/Params";
import { UserDetailsResponse } from "../models/management/UserDetailsResponse";
import { UpdatePasswordRequest } from "../models/user/UpdatePasswordRequest";
import { PageResponse } from "../models/PageResponse";
import { UserManagementItemResponse } from "../models/management/UserManagementItemResponse";

export default class UserManagementApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static findUsers(queryParams: {
    query?: string;
    pageNumber?: number;
    pageSize?: number;
  }): ApiPromise<PageResponse<UserManagementItemResponse>> {
    const url = `${this.baseUrl}/management/users?${qs.stringify(queryParams)}`;
    return axiosCommon.get(url);
  }

  static getUserDetails(userId: string): ApiPromise<UserDetailsResponse> {
    const url = `${this.baseUrl}/management/users/${userId}`;
    return axiosCommon.get(url);
  }

  static sendGdprExtract(userId: string): ApiPromise<UserDetailsResponse> {
    const url = `${this.baseUrl}/management/users/${userId}/gdpr-extract`;
    return axiosCommon.post(url);
  }

  static changeUserPassword(
    userId: string,
    request: UpdatePasswordRequest
  ): ApiPromise<UserDetailsResponse> {
    const url = `${this.baseUrl}/management/users/${userId}/password`;
    return axiosCommon.put(url, request);
  }
}
