/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import React, { useEffect, useRef } from "react";
import { dateAndTime, strings } from "../../common/Strings/Strings";
import { TicketArticleResponse } from "../../models/support/TicketArticleResponse";

interface Props {
  articles: TicketArticleResponse[];
  userEmail: string;
}

export const TicketMessages: React.FC<Props> = props => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () =>
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    scrollToBottom();
  }, [props.articles.length]);

  const MessageList = props.articles.map((a, i) => {
    return <TicketMessage key={i} article={a} userEmail={props.userEmail} />;
  });

  return (
    <div className="Messages" style={{ maxHeight: "400px" }}>
      {MessageList}
      <div style={{ float: "left", clear: "both" }} ref={messagesEndRef} />
    </div>
  );
};

const TicketMessage = (props: {
  article: TicketArticleResponse;
  userEmail: string;
}) => {
  const isFromMeClass = props.article.isOwn ? "Message-fromMe" : "";

  // Ticket answers are encoded in html
  /* eslint-disable react/no-danger */
  return (
    <div className={`Message ${isFromMeClass}`}>
      <div className="Message-body py-2 px-3">
        <div className="Message-date">
          <div>{props.article.isOwn ? props.userEmail : "GlobalVet"}</div>
          <div>
            {moment(props.article.createdAt).format(
              `${dateAndTime.momentDateFormat} ${dateAndTime.timeFormat}`
            )}
          </div>
        </div>
        {props.article.isOwn ? (
          props.article.content
        ) : (
          <div dangerouslySetInnerHTML={{ __html: props.article.content }} />
        )}
      </div>
    </div>
  );
};
