/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { MedicalRecordFileResponse } from "../../../../models/medical/MedicalRecordFileResponse";
import LoaderInline from "../../../../components/LoaderInline";
import MedicalRecordApi from "../../../../api/MedicalRecordApi";
import { urlFromBlobPart } from "../../../../util/FileUtils";
import logger from "../../../../util/logger";

interface Props {
  file: MedicalRecordFileResponse;
  reservationId?: string;
  size?: "sm" | "full";
}

const supportedMedicalRecordFileTypes = [
  { extension: ".png", mimeType: "image/png" },
  { extension: ".jpg", mimeType: "image/jpeg" },
  { extension: ".pdf", mimeType: "application/pdf" },
  { extension: ".txt", mimeType: "text/plain" },
  { extension: ".doc", mimeType: "application/msword" },
  {
    extension: ".docx",
    mimeType:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  { extension: ".xls", mimeType: "application/vnd.ms-excel" },
  {
    extension: ".xlsx",
    mimeType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
];

export const getSupportedMedicalRecordFileExtensions = (): string => {
  let result = "";
  supportedMedicalRecordFileTypes.forEach((type, index) => {
    result += type.extension;
    if (index !== supportedMedicalRecordFileTypes.length - 1) {
      result += ",";
    }
  });
  return result;
};

const imagePreview = (
  src: string | null,
  alt: string,
  size?: string
): ReactElement => (
  <div
    className={`rounded-md border-4 border-gray-100 border-solid dark:border-gray-700 ${size}`}
  >
    {src ? (
      <img className="rounded-md" src={src} alt={`preview-of-${alt}`} />
    ) : (
      <LoaderInline className={size} />
    )}
  </div>
);

const fileFormatPreview = (title: string | ReactElement): ReactElement => (
  <div className="rounded-md border-4 border-gray-200 border-solid dark:border-gray-700 w-16 flex items-center justify-center font-bold text-gray-300 dark:text-gray-600">
    {title}
  </div>
);

const ocjectPreview = (fileName: string, src?: string): ReactElement => (
  <div className="rounded-md border-4 border-gray-100 border-solid dark:border-gray-700">
    <object
      aria-labelledby={fileName}
      data={src}
      type="application/pdf"
      width="100%"
      height="600"
    />
  </div>
);

const FilePreview: React.FC<Props> = ({ file, reservationId, size }: Props) => {
  const [previewData, setPrewviewData] = useState<string | null>(null);
  const [dataContentType, setDataContentType] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const downloadPicture = async (): Promise<string | null> => {
      if (!file || !reservationId) {
        return null;
      }

      try {
        setLoading(true);
        const resp = await MedicalRecordApi.getFile(reservationId, file.fileId);
        if (resp.status === 204) return null;

        const url = await urlFromBlobPart(resp.data);

        const contentType =
          resp.headers["content-type"] ?? "application/octet-stream";

        setDataContentType(contentType);

        return `data:${contentType};${url.split(";")[1]}`;
      } catch (error) {
        logger.error(error);
        return null;
      } finally {
        setLoading(false);
      }
    };

    downloadPicture().then(setPrewviewData);
  }, [file, reservationId]);

  if (loading) {
    return fileFormatPreview(<LoaderInline />);
  }

  switch (dataContentType) {
    /* image/png */
    case supportedMedicalRecordFileTypes[0].mimeType:
      return size === "full"
        ? imagePreview(
            previewData,
            file.fileName,
            size === "full" ? "w-full" : "w-16"
          )
        : fileFormatPreview("PNG");
    /* image/jpeg */
    case supportedMedicalRecordFileTypes[1].mimeType:
      return size === "full"
        ? imagePreview(
            previewData,
            file.fileName,
            size === "full" ? "w-full" : "w-16"
          )
        : fileFormatPreview("JPEG");
    /* application/pdf */
    case supportedMedicalRecordFileTypes[2].mimeType:
      return size === "full"
        ? ocjectPreview(file.fileName, previewData || undefined)
        : fileFormatPreview("PDF");
    /* text/plain */
    case supportedMedicalRecordFileTypes[3].mimeType:
      return size === "full"
        ? ocjectPreview(file.fileName, previewData || undefined)
        : fileFormatPreview("TXT");
    /* application/msword */
    case supportedMedicalRecordFileTypes[4].mimeType:
      return fileFormatPreview("DOC");
    /* application/vnd.openxmlformats-officedocument.wordprocessingml.document */
    case supportedMedicalRecordFileTypes[5].mimeType:
      return fileFormatPreview("DOCX");
    /* application/vnd.ms-excel */
    case supportedMedicalRecordFileTypes[6].mimeType:
      return fileFormatPreview("XSL");
    /* application/vnd.openxmlformats-officedocument.spreadsheetml.sheet */
    case supportedMedicalRecordFileTypes[7].mimeType:
      return fileFormatPreview("XSLX");
    default:
      return fileFormatPreview("FILE");
  }
};

export default FilePreview;
