/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { PetOwnerFullForm } from "./CreatePetOwner";
import SelectCountry from "../../../../components/ReactHookFormFields/Specific/SelectCountry";
import { CountryResponse } from "../../../../models/management/CountryResponse";

export interface ClinicPetOwnerForm1Parts {
  city: string;
  country: CountryResponse[];
  firstName: string;
  houseDetails?: string;
  lastName: string;
  middleName?: string;
  prefix?: string;
  street: string;
  zip: string;
}

interface Props {
  countryOptions?: CountryResponse[];
}

const ClinicPetOwnerForm1: React.FC<Props> = ({countryOptions}) => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<PetOwnerFullForm>();

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <Field
          autoComplete={AutoCompleteOptions.off}
          name="prefix"
          label={strings.prefix}
          error={errors.prefix}
          register={register}
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          name="firstName"
          register={register}
          label={strings.firstName}
          error={errors.firstName}
          required
          showRequired
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          name="middleName"
          register={register}
          label={strings.middleName}
          error={errors.middleName}
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          name="lastName"
          register={register}
          label={strings.lastName}
          error={errors.lastName}
          required
          showRequired
        />
        <div className="col-span-2">
          <SelectCountry
            control={control}
            options={countryOptions}
            required
            setValue={setValue}
            showRequired
          />
        </div>
        <div className="col-span-2">
          <Field
            name="street"
            register={register}
            autoComplete={AutoCompleteOptions.off}
            error={errors.street}
            label={strings.address}
            placeholder={strings.addressLine1}
            required
            showRequired
          />
        </div>
        <div className="col-span-2">
          <Field
            name="houseDetails"
            register={register}
            autoComplete={AutoCompleteOptions.off}
            error={errors.houseDetails}
            label={strings.addressLine2}
          />
        </div>
        <Field
          name="city"
          register={register}
          autoComplete={AutoCompleteOptions.off}
          error={errors.city}
          label={strings.city}
          required
          showRequired
        />
        <Field
          name="zip"
          register={register}
          autoComplete={AutoCompleteOptions.off}
          error={errors.zip}
          label={strings.zip}
          required
          showRequired
          type="number"
        />
      </div>
    </>
  );
};

export default ClinicPetOwnerForm1;
