/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { strings, symptoms } from "../../../common/Strings/Strings";
import { symptomsEn } from "../../../common/Strings/Symptoms/en";
import Button from "../../../components/Button";
import CombinedSelectComponent from "../../../components/InputFieldsSimple/CombinedSelectComponent";

const symptomSentence = (selectedSymptoms: string[] | null): string => {
  let sentence = "";

  if (selectedSymptoms) {
    selectedSymptoms?.forEach((word) => {
      sentence += `${word.toLowerCase()}, `;
    });
  } else {
    return "";
  }

  return sentence?.slice(0, -2);
};

export const SymptomButtons: React.FC = () => {
  const symptomList = Object.keys(symptomsEn).map((key) => symptoms[key]);
  const { control, setValue, watch } = useFormContext();

  return (
    <div>
      <Controller
        control={control}
        name="symptomsList"
        render={({ field: { onChange, value } }) => {
          const frequentSymptoms = symptomList.slice(0, 8);

          const updateValue = (newValue: string[] | null) => {
            onChange(newValue);
            setValue("symptoms", symptomSentence(newValue));
          }

          return (
            <div>
              <label>{strings.symptoms}</label>
              <div className="flex flex-wrap py-2">
                {frequentSymptoms.map((v) => {
                  if (value?.includes(v)) {
                    return (
                      <div className="mr-2 mb-2">
                        <Button
                          variant="pill-tertiary"
                          small
                          onClick={() => {
                            if (value) {
                              if (value.includes(v)) {
                                updateValue([
                                  ...value.filter((i: string) => i !== v),
                                ]);
                              } else {
                                updateValue([...value, v]);
                              }
                            } else {
                              updateValue([v]);
                            }
                          }}
                        >
                          {v}
                        </Button>
                      </div>
                    );
                  }
                  return (
                    <div className="mr-2 mb-2">
                      <Button
                        variant="pill-secondary"
                        small
                        onClick={() => {
                          if (value) {
                            if (value.includes(v)) {
                              updateValue([
                                ...value.filter((i: string) => i !== v),
                              ]);
                            } else {
                              updateValue([...value, v]);
                            }
                          } else {
                            updateValue([v]);
                          }
                        }}
                      >
                        {v}
                      </Button>
                    </div>
                  );
                })}
                {value?.map((v: string | null) => {
                  if (v && !frequentSymptoms.includes(v)) {
                    return (
                      <div className="mr-2 mb-2">
                        <Button
                          variant="pill-tertiary"
                          small
                          onClick={() => {
                            if (value) {
                              updateValue([
                                ...value.filter((i: string) => i !== v),
                              ]);
                            } else {
                              updateValue([]);
                            }
                          }}
                        >
                          {v}
                        </Button>
                      </div>
                    );
                  }
                  return <></>;
                })}
              </div>
              <CombinedSelectComponent
                name="typeaheadForSymptoms"
                multiple
                onChange={(v: string[] | null) => {
                  updateValue(v);
                }}
                options={symptomList}
                placeholder={strings.searchOrCreateSymptom}
                value={value}
              />
            </div>
          );
        }}
      />
    </div>
  );
};
