/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import {
  postReservationStates,
  preReservationStates,
  ReservationState,
} from "../../../models/reservation/ReservationState";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import { Colors } from "../../../models/Colors";
import ReservationApi from "../../../api/ReservationApi";
import { getGeneralError } from "../../../util/helperFunctions";
import LoaderInline from "../../../components/LoaderInline";
import ClickOutsideDetector from "../../../components/ClickOutsideDetector";
import MedicalRecordApi from "../../../api/MedicalRecordApi";
import { ChevronDown } from "../../../common/Icons/ChevronDown";
import { CheckSimple } from "../../../common/Icons/CheckSimple";
import ReservationStateIcons from "./ReservationStateIcons";

interface Props {
  dropUp?: boolean;
  medicalRecordId?: string;
  reload(): void;
  reservationId?: string;
  reservationStatus?: ReservationState;
  setError(message: string | null): void;
  setInvoice(invoiceId?: string): void;
}

const ReservationStateSelect = ({
  dropUp = false,
  medicalRecordId,
  reload,
  reservationId,
  reservationStatus,
  setError,
  setInvoice,
}: Props): ReactElement => {
  const [isDown, setIsDown] = useState<boolean>(false);
  const [status, setStatus] = useState<ReservationState>();
  const [statuses, setStatuses] = useState<Array<ReservationState>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setStatus(reservationStatus);
  }, [reservationStatus]);

  useEffect(() => {
    if (status) {
      if (postReservationStates.includes(status)) {
        setStatuses(postReservationStates);
      } else if (preReservationStates.includes(status)) {
        setStatuses(preReservationStates);
      }
    } else {
      setStatuses(preReservationStates);
    }
  }, [status]);

  const createInvoice = async () => {
    if (medicalRecordId) {
      setLoading(true);
      setIsDown(false);
      try {
        const response = await MedicalRecordApi.createInvoice(medicalRecordId);
        setStatus(ReservationState.READY_FOR_PAYMENT);
        setInvoice(response.data.invoiceId);
        reload();
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  const updateReservationState = async (state: ReservationState) => {
    if (reservationId) {
      setLoading(true);
      setIsDown(false);

      try {
        const response = await ReservationApi.updateReservationState(
          reservationId,
          { state }
        );
        setStatus(response.data.state);
        reload();
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  const updateState = async (state: ReservationState) => {
    if (
      state === ReservationState.READY_FOR_PAYMENT &&
      reservationStatus &&
      preReservationStates.includes(reservationStatus)
    ) {
      void createInvoice();
    } else {
      void updateReservationState(state);
    }
  };

  return (
    <div className="flex space-x-3 w-full items-center">
      <ReservationStateIcons reservationState={status} />
      <div
        className={`dropdown flex-grow ${dropUp ? " dropup" : ""}`}
        style={{ zIndex: 1030 }}
      >
        <ClickOutsideDetector
          listen
          onClickOutside={() => {
            setIsDown(false);
          }}
        >
          <div
            className="tw-card cursor-pointer px-3 py-3 w-full"
            onClick={() => {
              setIsDown(!isDown);
            }}
            role="button"
            tabIndex={0}
          >
            <div className="flex items-center">
              <div className="font-semibold text-base text-zinc-800 dark:text-white">
                {loading ? (
                  <LoaderInline />
                ) : (
                  getStringFromEnumsOrReturn(status) || strings.notArrivedYet
                )}
              </div>
              <div className="ml-auto">
                <ChevronDown className="w-6 h-6 text-slate-500 dark:text-slate-400 shrink-0" />
              </div>
            </div>
          </div>
          <div
            className={`z-30 shadow-md rounded-lg w-72 lg:w-80 bg-white font-normal divide-y divide-gray-100 dark:bg-gray-700 dark:divide-gray-600 overflow-y-auto absolute ${
              isDown ? "block" : "hidden"
            }`}
          >
            <ul className="py-2 text-md text-gray-700 dark:text-gray-400">
              {statuses.map((st: ReservationState, index) => (
                <li
                  key={index}
                  className="px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {st === status ? (
                    <div className="flex font-bold">
                      {getStringFromEnumsOrReturn(st)}
                      <div className="ml-auto">
                        <CheckSimple />
                      </div>
                    </div>
                  ) : (
                    <button
                      className="cursor-pointer"
                      onClick={() => {
                        void updateState(st);
                      }}
                      type="button"
                    >
                      {getStringFromEnumsOrReturn(st)}
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </ClickOutsideDetector>
      </div>
    </div>
  );
};

export default ReservationStateSelect;
