/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { ClinicEmploymentRequestRequest } from "../models/employment/ClinicEmploymentRequestRequest";
import { ClinicEmploymentRequestResponse } from "../models/employment/ClinicEmploymentRequestResponse";
import { BasicUserWithClinicRequestResponse } from "../models/user/BasicUserWithClinicRequestResponse";
import { axiosCommon } from "../util/axiosSetup";

export default class ClinicEmploymentApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static addClinicEmployee(
    clinicId: string | undefined | null,
    request: ClinicEmploymentRequestRequest
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/employees`;
    return axiosCommon.post(url, request);
  }

  static getUserClinicEmploymentRequests(): ApiPromise<
    ClinicEmploymentRequestResponse[]
  > {
    const url = `${this.baseUrl}/user-clinic-employment-requests`;
    return axiosCommon.get(url);
  }

  static acceptClinicEmploymentRequest(requestId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/clinic-employment-requests/${requestId}/accept`;
    return axiosCommon.post(url);
  }

  static acceptClinicEmploymentRequestByCode(code: string): ApiPromise<void> {
    const url = `${this.baseUrl}/clinic-employment-requests/${code}`;
    return axiosCommon.post(url);
  }

  static rejectClinicEmploymentRequest(requestId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/clinic-employment-requests/${requestId}/reject`;
    return axiosCommon.post(url);
  }

  static deleteClinicEmploymentRequest(requestId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/clinic-employment-requests/${requestId}`;
    return axiosCommon.delete(url);
  }
}
