/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { NewVetReservationRequest } from "../models/reservation/NewVetReservationRequest";
import { NewUserReservationRequest } from "../models/reservation/NewUserReservationRequest";
import { ReservationResponse } from "../models/reservation/ReservationResponse";
import { ShiftForConsultationPeriodsResponse } from "../models/shift/ShiftForConsultationPeriodsResponse";
import { EmployeeResponse } from "../models/user/EmployeeResponse";
import { axiosCommon } from "../util/axiosSetup";
import { localDateTimeStringOf } from "../util/helperFunctions";
import qs from "query-string";

interface VetReservationCreation {
  clinicId: string;
  shiftId?: string;
  reservation: NewVetReservationRequest;
  collaboratorUserId?: string /* The id of the employee who does the emergency treatment. */;
  rescheduledReservationId?: string;
}

export default class ReservationApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getConsultationPeriods(
    clinicId: string,
    startTime: Date,
    endTime: Date,
    reservationTypeId?: string
  ): ApiPromise<ShiftForConsultationPeriodsResponse[]> {
    const query = qs.stringify({
      startTime: localDateTimeStringOf(startTime),
      endTime: localDateTimeStringOf(endTime),
      reservationTypeId,
    });
    const url = `${this.baseUrl}/clinics/${clinicId}/consultation-periods?${query}`;
    return axiosCommon.get(url);
  }

  static getAvailableConsultationPeriods(
    clinicId: string,
    startTime: Date,
    endTime: Date,
    reservationTypeId?: string,
    rescheduledReservationId?: string
  ): ApiPromise<ShiftForConsultationPeriodsResponse[]> {
    const query = qs.stringify({
      startTime: localDateTimeStringOf(startTime),
      endTime: localDateTimeStringOf(endTime),
      reservationTypeId,
      rescheduledReservationId,
    });
    const url = `${this.baseUrl}/clinics/${clinicId}/available-consultation-periods?${query}`;
    return axiosCommon.get(url);
  }

  static getAvailableEmergencyCollaborators(
    clinicId: string
  ): ApiPromise<EmployeeResponse[]> {
    const url = `${this.baseUrl}/clinics/${clinicId}/available-emergency-collaborators`;
    return axiosCommon.get(url);
  }

  static createUserReservation(
    clinicId: string,
    shiftId: string,
    request: NewUserReservationRequest
  ): ApiPromise<ReservationResponse> {
    const query = qs.stringify({ shiftId });
    const url = `${this.baseUrl}/clinics/${clinicId}/user-reservations?${query}`;
    return axiosCommon.post(url, request);
  }

  static createVetReservation({
    clinicId,
    shiftId,
    reservation,
    rescheduledReservationId,
    collaboratorUserId,
  }: VetReservationCreation): ApiPromise<ReservationResponse> {
    const query = qs.stringify({
      shiftId,
      rescheduledReservationId,
      collaboratorUserId,
    });
    const url = `${this.baseUrl}/clinics/${clinicId}/vet-reservations?${query}`;
    return axiosCommon.post(url, reservation);
  }
}
