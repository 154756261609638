/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import {
  getStringFromEnumsOrReturn,
  petGenders,
  petSpecies,
  strings,
} from "../../common/Strings/Strings";
import { ClinicPetResponse } from "../../models/pet/ClinicPetResponse";
import { PetProfileTabs } from "../Pet/PetProfile/ClinicPetProfile";
import PetProfilePicture from "../../components/Pictures/Pet/PetProfilePicture";
import { Heart } from "../../common/Icons/Heart";
import Tooltip from "../../components/Tooltip";

interface Props {
  pet: ClinicPetResponse;
  weight?: number;
}

const PetInfo = ({ pet, weight }: Props): ReactElement => (
  <div className="d-flex align-items-start">
    <div style={{ marginRight: "12px" }}>
      <div style={{ width: "40px", height: "40px" }}>
        <PetProfilePicture
          petId={pet?.userPetDetails?.id}
          species={pet?.clinicPetDetails?.species}
        />
      </div>
    </div>
    <div>
      <div className="flex items-center">
        <Link
          to={`/clinic-pet-profile/${pet?.clinicPetDetails?.id}/${PetProfileTabs.basic}`}
        >
          {pet?.clinicPetDetails?.name}
        </Link>
        {pet?.clinicPetDetails?.deceased && (
          <div>
            <Tooltip content={strings.deceased}>
              <Heart className="ml-2" variant="solid" />
            </Tooltip>
          </div>
        )}
      </div>
      <div>
        {pet?.clinicPetDetails?.species &&
          petSpecies[pet?.clinicPetDetails?.species]}
        ,{" "}
        {pet?.clinicPetDetails?.gender &&
          petGenders[pet?.clinicPetDetails?.gender]}
        {pet?.clinicPetDetails?.color && `, ${pet?.clinicPetDetails?.color}`},{" "}
        {pet?.clinicPetDetails?.breed}
        {weight || pet.clinicPetDetails?.lastMedicalRecordWeight
          ? `, ${weight || pet.clinicPetDetails?.lastMedicalRecordWeight} kg`
          : ""}
      </div>
      {pet?.clinicPetDetails?.chronicDiseases?.map((disease, index) => (
        <span className="badge badge-pill badge-danger mr-2 mb-2" key={index}>
          {getStringFromEnumsOrReturn(disease)}
        </span>
      ))}
    </div>
  </div>
);

export default PetInfo;
