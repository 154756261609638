/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ApiPromise } from "../models/ApiPromise";
import { axiosCommon } from "../util/axiosSetup";
import { PolicyUpdateRequest } from "../models/management/PolicyUpdateRequest";
import { Void } from "../models/Void";
import Params from "../common/Params";
import { PolicyType } from "../models/management/PolicyType";
import { PrimaryPolicyRequest } from "../models/management/PrimaryPolicyRequest";
import { PolicyResponse } from "../models/management/PolicyResponse";
import { NewPolicyRequest } from "../models/management/NewPolicyRequest";

export default class GDPRApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getPolicy(
    type: PolicyType,
    countryCode: string
  ): ApiPromise<PolicyResponse> {
    const url = `${this.baseUrl}/policies?type=${type}&country=${countryCode}`;
    return axiosCommon.get(url);
  }

  static getPolicies(): ApiPromise<PolicyResponse[]> {
    const url = `${this.baseUrl}/management/policies`;
    return axiosCommon.get(url);
  }

  static createPolicy(request: NewPolicyRequest): ApiPromise<PolicyResponse> {
    const url = `${this.baseUrl}/management/policies`;
    return axiosCommon.post(url, request);
  }

  static updatePolicy(
    request: PolicyUpdateRequest
  ): ApiPromise<PolicyResponse> {
    const url = `${this.baseUrl}/management/policies`;
    return axiosCommon.put(url, request);
  }

  static updatePrimaryPolicy(
    request: PrimaryPolicyRequest
  ): ApiPromise<PolicyResponse> {
    const url = `${this.baseUrl}/management/policies/primary`;
    return axiosCommon.put(url, request);
  }

  static deletePolicy(
    type: PolicyType,
    countryCode: string,
    languageCode: string
  ): ApiPromise<Void> {
    const url = `${this.baseUrl}/management/policies?type=${type}&country=${countryCode}&language=${languageCode}`;
    return axiosCommon.delete(url);
  }
}
