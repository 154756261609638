/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../../common/Strings/Strings";
import Button from "../../../../components/Button";
import { Pencil } from "../../../../common/Icons/Pencil";

interface Props {
  petId?: string;
}

const UserPetListRowActions = ({ petId }: Props): ReactElement => (
  <div className="flex items-center space-x-4">
    <Link to={`/edit-pet/${petId}`}>
      <Button className="w-10 h-10" variant="icon">
        <Pencil />
      </Button>
    </Link>
    <Link to={`/reservation/${petId}`}>
      <Button>{strings.newReservation}</Button>
    </Link>
  </div>
);

export default UserPetListRowActions;
