/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import moment from "moment";
import { PublicUserResponse } from "../../../models/user/PublicUserResponse";
import { dateAndTime } from "../../../common/Strings/Strings";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";

interface Props {
  creator?: PublicUserResponse;
  date?: string;
  hr?: boolean;
  title: string;
}

const Created = ({ creator, date, hr, title }: Props): ReactElement => (
  <li className="flex flex-col space-y-4">
    <div className="flex justify-between">
      <div className="text-base font-bold text-zinc-800 dark:text-gray-100">
        {title}
      </div>
      <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
        {date && moment(date).format(dateAndTime.momentDateTimeFormat)}
      </div>
    </div>
    <div className="flex space-x-2 items-center">
      <div className="relative rounded-full border-2 border-gray-100 bg-white w-8 h-8 dark:border-gray-600">
        <UserProfilePicture userId={creator?.userId} />
      </div>
      <span className="font-medium text-base text-zinc-800 dark:text-white">
        {creator?.fullName}
      </span>
    </div>
  </li>
);

export default Created;
