/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { axiosCommon } from "../util/axiosSetup";
import { TodoResponse } from "../models/todo/TodoResponse";
import { NewTodoRequest } from "../models/todo/NewTodoRequest";
import { UpdateTodoRequest } from "../models/todo/UpdateTodoRequest";
import { PageResponse } from "../models/PageResponse";

export default class TodoApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getTodosOfClinic(
    clinicId: string,
    completed: boolean,
    query?: string,
    assigneeId?: string,
    pageSize?: number,
    pageNumber?: number
  ): ApiPromise<PageResponse<TodoResponse>> {
    let url = `${this.baseUrl}/clinics/${clinicId}/todos?completed=${completed}`;
    if (query) {
      url += `&query=${query}`;
    }
    if (assigneeId !== undefined) {
      url += `&assigneeId=${assigneeId}`;
    }
    if (pageSize) {
      url += `&pageSize=${pageSize}&pageNumber=${pageNumber || 0}`;
    }
    return axiosCommon.get(url);
  }

  static addTodo(
    clinicId: string,
    request: NewTodoRequest
  ): ApiPromise<TodoResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/todos`;
    return axiosCommon.post(url, request);
  }

  static updateTodo(
    clinicId: string,
    todoId: string,
    request: UpdateTodoRequest
  ): ApiPromise<TodoResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/todos/${todoId}`;
    return axiosCommon.put(url, request);
  }

  static deleteTodo(clinicId: string, todoId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/todos/${todoId}`;
    return axiosCommon.delete(url);
  }

  static getTodosForMedicalRecord(
    universalMrId: string
  ): ApiPromise<TodoResponse[]> {
    const url = `${this.baseUrl}/medical-records/${universalMrId}/todos`;
    return axiosCommon.get(url);
  }

  static getTodosForPet(
    clinicPetId: string,
    completed: boolean
  ): ApiPromise<TodoResponse[]> {
    const url = `${this.baseUrl}/clinic-pets/${clinicPetId}/todos?completed=${completed}`;
    return axiosCommon.get(url);
  }

  static getTodosForPetOwner(
    ownerId: string,
    completed: boolean
  ): ApiPromise<TodoResponse[]> {
    const url = `${this.baseUrl}/pet-owners/${ownerId}/todos?completed=${completed}`;
    return axiosCommon.get(url);
  }
}
