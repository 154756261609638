/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const prescriptionMethodsEn = {
  AURI: "Ear",
  BIDI: "Beak",
  BU: "Dent",
  CUTN: "Skin",
  DENT: "Dental",
  DIPP: "Bath",
  DW: "Use in drinking water",
  GAEN: "Gastroenteral",
  GING: "Use on the gums",
  IH: "Inhalation",
  INAC: "Intra-articular",
  INBU: "Intrabursal",
  INCD: "Intracardiac",
  INDE: "Intradermal",
  INFE: "Use in food",
  MOUTH: "By mouth",
  NASA: "Nasal",
  OCUL: "Ocular",
  ORAL: "Oral",
  ORMU: "Oral cavity",
  PDON: "Periodontal",
  PO: "Per oral",
  POON: "Pour-on",
  PR: "Per rectal",
  RECT: "Rectal",
  SL: "Sublingual",
  SMUC: "Submucosal use",
  SPON: "Spot-on",
  SUCO: "Use in the conjunctival sac",
  SUCU: "Subcutan",
  TEAT: "Teats",
  TO: "Topical",
  TODR: "Sprinkled to the lining",
  VAGI: "Vaginal",
  WABO: "Treatment with water"
};