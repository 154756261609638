/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { ShiftResponse } from "../models/shift/ShiftResponse";
import { ShiftSchedulingRequest } from "../models/shift/ShiftSchedulingRequest";
import { TimePeriod } from "../models/shift/TimePeriod";
import Params from "../common/Params";
import { localDateTimeStringOf } from "../util/helperFunctions";
import qs from "query-string";

export default class EmploymentApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static addShift(
    clinicId: string,
    request: ShiftSchedulingRequest
  ): ApiPromise<ShiftResponse[]> {
    const url = `${this.baseUrl}/clinics/${clinicId}/shifts`;
    return axiosCommon.post(url, request);
  }

  static updateTimePeriod(
    clinicId: string,
    shiftId: string,
    period: TimePeriod
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/shifts/${shiftId}/timePeriod`;
    return axiosCommon.put(url, period);
  }

  static getShifts(
    clinicId: string,
    startTime: Date,
    endTime: Date
  ): ApiPromise<ShiftResponse[]> {
    const query = qs.stringify({
      startTime: localDateTimeStringOf(startTime),
      endTime: localDateTimeStringOf(endTime),
    });
    const url = `${this.baseUrl}/clinics/${clinicId}/shifts?${query}`;
    return axiosCommon.get(url);
  }

  static getMyShifts(
    clinicId: string,
    startTime: Date,
    endTime: Date
  ): ApiPromise<ShiftResponse[]> {
    const query = qs.stringify({
      startTime: localDateTimeStringOf(startTime),
      endTime: localDateTimeStringOf(endTime),
    });
    const url = `${this.baseUrl}/clinics/${clinicId}/shifts/my?${query}`;
    return axiosCommon.get(url);
  }

  static deleteShift(clinicId: string, shiftId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/shifts/${shiftId}`;
    return axiosCommon.delete(url);
  }
}
