/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { strings } from "../../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import Button from "../../../../components/Button";
import { facebookIcon, googleIcon } from "../../../../assets/AssetConfig";

interface Props {
  onNext(email: string): void;
  onSocialRegistration(): void;
  prefilledEmail?: string | null;
}

interface EmailForm {
  email: string;
}

const EmailAndSocials: React.FC<Props> = ({
  onNext,
  onSocialRegistration,
  prefilledEmail
}: Props) => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setValue
  } = useForm<EmailForm>({
    mode: "onChange",
  });

  useEffect(() => {
    if(prefilledEmail){
      setValue("email", prefilledEmail);
    }
  }, [prefilledEmail]);

  const submit = (formValues: EmailForm) => {
    onNext(formValues.email);
  };

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit(submit)} className="space-y-6">
        <Field
          autoComplete={AutoCompleteOptions.email}
          error={errors.email}
          label={strings.yourEmail}
          name="email"
          placeholder={strings.emailExampleName}
          register={register}
          required
          readOnly={!!prefilledEmail}
          showRequired
          type="email"
        />
        <Button disabled={!isValid} type="submit">
          {strings.continue}
        </Button>
      </form>
      <div id="todo-social-login" hidden>
        <div className="flex items-center justify-center">
          <span className="w-full border border-gray-200 dark:border-gray-700" />
          <span className="px-4 text-gray-500 dark:text-gray-400">
            {strings.or.toUpperCase()}
          </span>
          <span className="w-full border border-gray-200 dark:border-gray-700" />
        </div>
        <Button
          className="my-5"
          fullWidth
          onClick={onSocialRegistration}
          variant="social"
        >
          <span>
            <img src={googleIcon} className="w-4" alt="google" />
          </span>
          <span>{strings.loginWithGoogleText}</span>
        </Button>
        <Button
          className="my-5"
          fullWidth
          onClick={onSocialRegistration}
          variant="social"
        >
          <span>
            <img src={facebookIcon} className="w-4" alt="facebook" />
          </span>
          <span>{strings.loginWithFacebookText}</span>
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex justify-start">
          <span className="text-left text-sm font-medium text-gray-500 dark:text-gray-400">
            {strings.alreadyHaveAccount}
          </span>
        </div>
        <div className="flex justify-end">
          <Button
            className="text-sm"
            onClick={() => navigate("/login")}
            variant="link"
          >
            {strings.signInHere}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailAndSocials;
