/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import ClinicLogoSettings from "../../../../components/Pictures/Clinic/ClinicLogoSettings";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import { strings } from "../../../../common/Strings/Strings";
import BasicInformationForm from "./BasicInformationForm";

interface Props {
  clinic: ClinicResponse;
  refresh: () => void;
}

const BasicInformationTab: React.FC<Props> = ({ clinic, refresh }: Props) => (
  <section className="w-full md:tw-card md:max-w-md space-y-6 md:p-8">
    <div>
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
        {strings.basicInfo}
      </h2>
    </div>
    <ClinicLogoSettings />
    <BasicInformationForm clinic={clinic} getClinic={refresh} />
  </section>
);

export default BasicInformationTab;
