/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { getGeneralError } from "../../util/helperFunctions";
import { PageProps } from "../../models/PageProps";
import MedicalRecordApi from "../../api/MedicalRecordApi";
import { Loader } from "../../components/Loader";
import { MedicalRecordResponse } from "../../models/medical/MedicalRecordResponse";
import Params from "../../common/Params";
import AlertBox, { AlertType } from "../../components/AlertBox";
import { strings } from "../../common/Strings/Strings";
import { PageResponse } from "../../models/PageResponse";
import Button from "../../components/Button";

const OpenLastMedicalRecord: React.FC<PageProps> = ({
  setPageLoading,
}: PageProps) => {
  const { petId } = useParams<"petId">();

  const [error, setError] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getMedicalRecords = async (id: string) => {
      setPageLoading(true);
      try {
        const response: AxiosResponse<PageResponse<MedicalRecordResponse>> =
          await MedicalRecordApi.getMedicalRecordsOfClinicPet(id);

        if (response.data.totalElements === 0) {
          setInfo(strings.petHasNoMedicalRecords);
        } else {
          const id = response.data.elements[0].id;

          window.location.replace(
            `${Params.frontendBaseURL}/medical-record/${id}`
          );
        }
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setPageLoading(false);
      }
    };

    if (petId) {
      void getMedicalRecords(petId);
    }
  }, [petId]);

  return (
    <main className="main-signed-in">
      <section>
        <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <div className="md:tw-card w-full space-y-6 md:max-w-md md:p-8">
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 dark:text-white lg:text-2xl">
              {strings.openLastMedicalRecord}
            </h1>
            <AlertBox message={error} />
            <AlertBox message={info} type={AlertType.INFO} />
            <Button
              variant="link"
              onClick={() => {
                navigate(-1);
              }}
            >
              {strings.back}
            </Button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(OpenLastMedicalRecord);
