import * as process from "process";

// Custom interface that encapsulates process on the browser's window object
interface WindowWithProcess extends Window {
  process: typeof process;
  Buffer: unknown[];
}

const windowWithProcess = window as unknown as WindowWithProcess;

// Makes the global process object (from Node.JS environment) available on the window object in the browser
// This is a workaround for Node.js libraries where process is needed, and it also needs to run in the browser
const setupProcess = () => {
  windowWithProcess.process = process;
  windowWithProcess.Buffer = [];
};

export default setupProcess;
