/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import AlertBox from "../../../components/AlertBox";
import LoaderInline from "../../../components/LoaderInline";
import { BillingSystem } from "../../../models/management/BillingSystem";
import { BillingSystemConfigResponse } from "../../../models/management/BillingSystemConfigResponse";
import TestInvoices from "./TestInvoices";
import BillingSystemConfig from "./BillingSystemConfig";
import PaymentSystemConfig from "./PaymentSystemConfig";
import FortnoxIntegration from "./FortnoxIntegration";

interface Props {
  isActiveTab: boolean;
  countryCode: string;
}

export default function PaymentAndBillingSection({
  isActiveTab,
  countryCode,
}: Props): ReactElement {
  const [isPaymentConfigLoading, setPaymentConfigLoading] =
    useState<boolean>(true);
  const [isBillingConfigLoading, setBillingConfigLoading] =
    useState<boolean>(true);
  const [isTestInvoicesLoading, setTestInvoicesLoading] =
    useState<boolean>(true);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [billingSystemConfig, setBillingSystemConfig] =
    useState<BillingSystemConfigResponse>();

  useEffect(() => {
    setLoading(
      isBillingConfigLoading || isPaymentConfigLoading || isTestInvoicesLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBillingConfigLoading, isPaymentConfigLoading, isTestInvoicesLoading]);

  return (
    <>
      <LoaderInline className="my-3" hidden={!isLoading} />
      <div hidden={isLoading || error == null}>
        <AlertBox message={error} className="my-3" />
      </div>
      <div hidden={isLoading || error != null}>
        <div className="lg:grid lg:grid-cols-2 lg:gap-6 space-y-6 lg:space-y-0">
          <div className="space-y-6">
            <PaymentSystemConfig
              isActiveTab={isActiveTab}
              countryCode={countryCode}
              onLoadingChange={(loading) => setPaymentConfigLoading(loading)}
              onError={(err) => setError(err)}
            />
            <BillingSystemConfig
              isActiveTab={isActiveTab}
              countryCode={countryCode}
              onLoadingChange={(loading) => setBillingConfigLoading(loading)}
              onError={(err) => setError(err)}
              onConfigChange={(config) => setBillingSystemConfig(config)}
            />
            {billingSystemConfig?.system === BillingSystem.FORTNOX && (
              <FortnoxIntegration
                countryCode={countryCode}
                billingConfig={billingSystemConfig}
              />
            )}
          </div>
          <div>
            <TestInvoices
              isActiveTab={isActiveTab}
              countryCode={countryCode}
              onLoadingChange={(loading) => setTestInvoicesLoading(loading)}
              onError={(err) => setError(err)}
            />
          </div>
        </div>{" "}
      </div>
    </>
  );
}
