/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { TreatmentResponse } from "../models/treatment/TreatmentResponse";
import CostCalculator from "./CostCalculator";
import { TreatmentFeeResponse } from "../models/treatment/TreatmentFeeResponse";
import { TreatmentItemResponse } from "../models/treatment/TreatmentItemResponse";
import { CountryDetailsResponse } from "../models/management/CountryDetailsResponse";
import { ClinicResponse } from "../models/clinic/ClinicResponse";

export default class TreatmentCostCalculator {
  static getNetPriceOfItems(
    treatment: TreatmentResponse,
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    const items: InvoiceItem[] = treatment.usedItems.map((i) => ({
      ...i,
      vat: i.item.sellingVat,
      price: i.item.sellingPrice || 0,
    }));
    return CostCalculator.getTotalNetPriceOfInvoiceItems(
      items,
      country,
      clinic
    );
  }

  static getGrossPriceOfItems(
    treatment: TreatmentResponse,
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    return treatment.usedItems.reduce(
      (sum, current) =>
        sum +
        CostCalculator.getGrossPriceOfItem(
          {
            ...current,
            vat: current.item.sellingVat,
            price: current.item.sellingPrice || 0,
          },
          country,
          clinic
        ),
      0
    );
  }

  static getGrossPriceOfItems2(
    usedItems: TreatmentItemResponse[],
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    return usedItems.reduce(
      (sum, current) =>
        sum +
        CostCalculator.getGrossPriceOfItem(
          {
            ...current,
            vat: current.item.sellingVat,
            price: current.item.sellingPrice || 0,
          },
          country,
          clinic
        ),
      0
    );
  }

  static getNetPriceOfFees(
    treatment: TreatmentResponse,
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    const items: InvoiceItem[] = treatment.fees.map((i) => ({
      ...i,
      vat: i.fee.vat,
      price: i.fee.price || 0,
    }));
    return CostCalculator.getTotalNetPriceOfInvoiceItems(
      items,
      country,
      clinic
    );
  }

  static getGrossPriceOfFees(
    treatment: TreatmentResponse,
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    return treatment.fees.reduce(
      (sum, current) =>
        sum +
        CostCalculator.getGrossPriceOfItem(
          {
            ...current,
            vat: current.fee.vat,
            price: current.fee.price,
          },
          country,
          clinic
        ),
      0
    );
  }

  static getGrossPriceOfFees2(
    fees: TreatmentFeeResponse[],
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    return fees.reduce(
      (sum, current) =>
        sum +
        CostCalculator.getGrossPriceOfItem(
          {
            ...current,
            vat: current.fee.vat,
            price: current.fee.price,
          },
          country,
          clinic
        ),
      0
    );
  }

  static getNetPriceOfWorkCost(treatment: TreatmentResponse): number {
    return treatment.price || 0;
  }

  static getGrossPriceOfWorkCost(
    treatment: TreatmentResponse,
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    return CostCalculator.getGrossPriceOfItem(
      {
        ...treatment,
        quantity: 1,
        price: treatment.price || 0,
      },
      country,
      clinic
    );
  }

  static getNetTotalPrice(
    treatment: TreatmentResponse,
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    const items = TreatmentCostCalculator.getCostItems(treatment);
    return CostCalculator.getTotalNetPriceOfInvoiceItems(
      items,
      country,
      clinic
    );
  }

  static getGrossTotalPrice(
    treatment: TreatmentResponse,
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    return CostCalculator.getTotalGrossPriceOfInvoiceItems(
      TreatmentCostCalculator.getCostItems(treatment),
      country,
      clinic
    );
  }

  private static getCostItems(treatment: TreatmentResponse): InvoiceItem[] {
    const costItems: InvoiceItem[] = [];
    costItems.push({
      ...treatment,
      quantity: 1,
      price: treatment.price || 0,
    });
    treatment.usedItems.forEach((i) =>
      costItems.push({
        ...i,
        vat: i.item.sellingVat,
        price: i.item.sellingPrice || 0,
      })
    );
    treatment.fees.forEach((i) =>
      costItems.push({
        ...i,
        vat: i.fee.vat,
        price: i.fee.price,
      })
    );
    return costItems;
  }
}

export interface InvoiceItem {
  price: number;
  quantity: number;
  vat?: number;
  totalPriceExcludingVat?: number;
}
