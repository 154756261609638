/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import moment from "moment";
import { dateAndTime, strings } from "../../../../common/Strings/Strings";
import { FreeTextMedicalRecordResponse } from "../../../../models/medical/FreeTextMedicalRecordResponse";
import MedicalRecordApi from "../../../../api/MedicalRecordApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import { downloadExternalMedicalRecordPdf } from "../../../../util/FileUtils";
import Modal from "../../../../components/Modal/Modal";
import UserProfilePicture from "../../../../components/Pictures/User/UserProfilePicture";
import HorizontalLine from "../../../../components/HorizontalLine";
import Button from "../../../../components/Button";
import { DocumentText } from "../../../../common/Icons/DocumentText";
import { Tooltip } from "flowbite-react";
import { ArrowDownTray } from "../../../../common/Icons/ArrowDownTray";
import { Trash } from "../../../../common/Icons/Trash";

interface Props {
  isNextExist: boolean;
  externalMedicalRecord: FreeTextMedicalRecordResponse | any;
  reloadList(): void;
  returnError(message: string | null): void;
}

const ExternalMedicalRecordRow: React.FC<Props> = ({
  isNextExist,
  externalMedicalRecord,
  reloadList,
  returnError,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const onDelete = async () => {
    setIsDeleting(true);

    try {
      await MedicalRecordApi.deleteFreeTextMedicalRecord(
        externalMedicalRecord.id
      );
      reloadList();
    } catch (err) {
      returnError(await getGeneralError(err));
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <div
        className="flex flex-wrap py-3 space-y-3 lg:space-y-0"
        key={externalMedicalRecord.id}
      >
        <div className="col-12 col-lg-2 flex items-center">
          <div
            style={{
              marginRight: "8px",
              marginBottom: "11px",
              marginLeft: "30px",
            }}
          />
          <div className="flex flex-col justify-center">
            <div className="uppercase text-sm">
              {strings.externalMedicalRecord}
            </div>
            <div className="font-bold">
              {moment(
                externalMedicalRecord.startDateTime ||
                  externalMedicalRecord.creationDateTime
              ).format("LL")}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 flex flex-col justify-center">
          <b>{externalMedicalRecord?.title}</b>
          <div className="line-clamp-1" style={{ whiteSpace: "pre-line" }}>
            {externalMedicalRecord.description}
          </div>
        </div>
        <div className="col-12 col-lg-2 flex flex-col justify-center">
          {externalMedicalRecord.veterinarian.fullName}
        </div>
        <div className="col-12 col-lg-1 flex justify-end items-center space-x-3">
          <div>
            <Tooltip content={strings.download}>
              <Button
                disabled={pdfLoading}
                loading={pdfLoading}
                onClick={() => {
                  setPdfLoading(true);
                  downloadExternalMedicalRecordPdf(externalMedicalRecord.id)
                    .catch(() => {
                      returnError(strings.simpleError);
                    })
                    .finally(() => {
                      setPdfLoading(false);
                    });
                }}
                variant="icon"
              >
                <ArrowDownTray />
              </Button>
            </Tooltip>
          </div>
          <div>
            <Button
              disabled={isDeleting}
              loading={isDeleting}
              onClick={onDelete}
              variant="icon"
            >
              <Trash />
            </Button>
          </div>
          <div>
            <Button
              variant="icon"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <DocumentText />
            </Button>
          </div>
        </div>
        <Modal
          handleClose={() => {
            setIsModalOpen(false);
          }}
          show={isModalOpen}
          size="modal-lg"
        >
          <Modal.Header
            title={externalMedicalRecord.title || strings.externalMedicalRecord}
          />
          <Modal.Body>
            <div className="grid grid-cols-2">
              <div>
                <div hidden={!externalMedicalRecord?.startDateTime}>
                  <b>{strings.startDate}:</b>&nbsp;
                  {externalMedicalRecord?.startDateTime &&
                    moment(externalMedicalRecord.startDateTime).format(
                      dateAndTime.momentDateFormat
                    )}
                </div>
              </div>
              <div>
                <div hidden={!externalMedicalRecord?.endDateTime}>
                  <b>{strings.endDate}:</b>&nbsp;
                  {externalMedicalRecord?.endDateTime &&
                    moment(externalMedicalRecord.endDateTime).format(
                      dateAndTime.momentDateFormat
                    )}
                </div>
              </div>
            </div>
            <div style={{ whiteSpace: "pre-line" }}>
              {externalMedicalRecord.description}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex items-center">
              <div
                className="mr-3"
                style={{
                  width: "32px",
                  height: "32px",
                }}
              >
                <UserProfilePicture
                  userId={externalMedicalRecord.veterinarian.userId}
                />
              </div>
              <div>
                <div>{externalMedicalRecord.veterinarian.fullName}</div>
                <div>{externalMedicalRecord.clinic.name}</div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
      {isNextExist && <HorizontalLine />}
    </>
  );
};

export default ExternalMedicalRecordRow;
