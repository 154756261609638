/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { strings } from "../../../common/Strings/Strings";

import DatePickerInput from "../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import { PlusInverseIcon } from "../../../common/Icons/PlusInverseIcon";
import { MinusInverseIcon } from "../../../common/Icons/MinusInverseIcon";

const LatestDispensingTime: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { control } = useFormContext();

  return (
    <div className="tw-card">
      <div
        role="button"
        tabIndex={0}
        className="px-3 py-2 flex items-center"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyDown={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="font-semibold">
          {strings.latestRequestedTimeForDispensing}
        </div>
        <div className="ml-auto">
          <button type="button">
            {isOpen ? (
              <MinusInverseIcon className="text-gray-500" />
            ) : (
              <PlusInverseIcon className="text-gray-500" />
            )}
          </button>
        </div>
      </div>
      {isOpen ? (
        <div className="grid grid-cols-12 gap-3 px-3 py-2">
          <div className="col-span-4">
            <DatePickerInput
              control={control}
              fieldOptions={{
                validate: {
                  isInAYearRange: (v: Date | null) => {
                    if (!v) {
                      return true;
                    }

                    return (
                      moment(v).isBetween(
                        moment().add(1, "days"),
                        moment().add(1, "years"),
                        "days",
                        "[]"
                      ) || strings.oneYearInterval
                    );
                  },
                },
              }}
              label={strings.latestRequestedTimeForDispensingDate}
              name="latestRequestedTimeForDispensing"
              optional
            />
          </div>
          <div className="col-span-8">
            <i>{strings.latestRequestedTimeForDispensingDescription}</i>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LatestDispensingTime;
