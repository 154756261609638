/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import { useClinic } from "../../../contexts/ClinicContext";
import { useChosenRole } from "./ChooseRole";
import AlertBox from "../../../components/AlertBox";
import Clinics from "./ClinicSide/Clinics";
import MainCalendar from "../../Calendar/MainCalendar";
import { Feature } from "../../../models/clinic/Feature";

interface Props {
  setPageLoading: (isLoading: boolean) => void;
}

const MainDoctor: React.FC<Props> = ({ setPageLoading }: Props) => {
  const navigate = useNavigate();

  const {
    clinic,
    clinics,
    clinicLoading,
    clinicsLoading,
    fetchClinics,
    fetchClinicError,
    fetchClinicsError,
  } = useClinic();

  useChosenRole("doctor");

  useEffect(() => {
    fetchClinics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageLoading(clinicLoading || clinicsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicLoading, clinicsLoading]);

  useEffect(() => {
    if (
      clinic &&
      clinic.features.includes(Feature.PRESCRIPTION) &&
      !clinic.features.includes(Feature.RESERVATION)
    ) {
      navigate(`/prescriptions`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic]);

  if (fetchClinicError || fetchClinicsError) {
    return (
      <main className={clinic ? "main-signed-in" : "main-signed-in-no-clinic"}>
        <section>
          <AlertBox
            className="m-5"
            message={fetchClinicError || fetchClinicsError}
          />
        </section>
      </main>
    );
  }

  if (clinic) {
    return <MainCalendar />;
  }

  return <Clinics myClinics={clinics} />;
};

export default Loader(MainDoctor);
