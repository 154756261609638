/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { strings } from "../../../common/Strings/Strings";
import StripeSection from "../../Stripe/StripeSection";
import OnlinePaymentForm, {
  OnlinePaymentFormRef,
} from "../../Stripe/OnlinePaymentForm";
import { getGeneralError } from "../../../util/helperFunctions";
import { PaymentRequest } from "../../../models/costaccounting/PaymentRequest";
import LoaderInline from "../../../components/LoaderInline";
import NumberFormatter from "../../../util/NumberFormatter";
import StripeApi from "../../../api/StripeApi";
import TestInvoiceApi from "../../../api/TestInvoiceApi";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { GlobalVetInvoiceResponse } from "../../../models/management/GlobalVetInvoiceResponse";

interface Props {
  isOpen: boolean;
  close: () => void;
  countryCode: string;
  invoice: GlobalVetInvoiceResponse;
}

const TestInvoicePaymentModal: React.FC<Props> = (props: Props) => {
  const { isOpen, close, countryCode, invoice } = props;

  const [stripeApiKey, setStripeApiKey] = useState<string>();
  const [error, setError] = useState<string | null>(null);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const onlinePaymentRef = useRef<OnlinePaymentFormRef>(null);

  const closeModal = () => {
    if (!loading) {
      close();
    }
  };

  const getStripeApiKey = async () => {
    try {
      setLoading(true);
      const resp = await StripeApi.getStripePublicKeyForGlobalVet(countryCode);
      setStripeApiKey(resp.data);
      setLoading(false);
    } catch (err) {
      setError(await getGeneralError(error));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    setError(null);
    void getStripeApiKey();
    /* eslint-disable-next-line */
  }, [isOpen]);

  const payInvoice = async (request: PaymentRequest) => {
    setLoading(true);

    try {
      await TestInvoiceApi.payInvoice(countryCode, invoice.id, request);
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      const err: any = e;
      if (
        err?.response?.data?.id === "ACTION_REQUIRED" &&
        err?.response?.data?.clientSecret
      ) {
        // eslint-disable-next-line no-use-before-define
        confirmPayment(err.response.data.clientSecret);
      } else {
        setError(await getGeneralError(err));
        setLoading(false);
      }
    }
  };

  const submitPayment = (request: PaymentRequest) => {
    setError(null);
    setSuccess(false);
    void payInvoice(request);
  };

  const startPayment = (ev: any) => {
    ev.preventDefault();
    setLoading(true);

    const startPaymentCall = onlinePaymentRef?.current?.createPaymentMethod();

    if (!startPaymentCall) {
      return;
    }

    startPaymentCall
      .then((response) => {
        if (response.error) {
          setError(response?.error?.message ?? strings.simpleError);
          setLoading(false);
        } else {
          const request = {
            paymentMethodId: response?.paymentMethod?.id,
            cardHolder: onlinePaymentRef?.current?.getCardHolder() || "",
          };
          submitPayment(request);
        }
      })
      .catch((err) => {
        setError(err ?? strings.simpleError);
        setLoading(false);
      });
  };

  const confirmPayment = (clientSecret: string) => {
    setLoading(true);

    const confirmPaymentCall =
      onlinePaymentRef?.current?.confirmPayment(clientSecret);

    if (!confirmPaymentCall) {
      return;
    }

    confirmPaymentCall
      .then((response) => {
        if (response.error) {
          setError(response?.error?.message ?? strings.simpleError);
          setLoading(false);
        } else {
          const request = {
            paymentIntentId: response?.paymentIntent?.id,
            cardHolder: onlinePaymentRef?.current?.getCardHolder() || "",
          };
          submitPayment(request);
        }
      })
      .catch((err) => {
        setError(err ?? strings.simpleError);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal show={isOpen} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{strings.onlinePayment}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0 p-0">
          <div hidden={!loading} className="p-3 align-middle ">
            <LoaderInline />
          </div>
          <div hidden={loading}>
            <div className="d-flex p-3 hr-on-bottom">
              <div className="font-weight-bold">{strings.amountToPay}:</div>
              <div className="ml-auto">
                {NumberFormatter.formatPrice(
                  invoice.totalCost,
                  invoice.currency
                )}
              </div>
            </div>
            <div hidden={success}>
              <div>
                <div className="m-3 font-weight-bold">
                  {strings.creditCardDetails}:
                </div>
              </div>
              <div className="mb-3">
                {stripeApiKey && (
                  <StripeSection apiKey={stripeApiKey}>
                    <OnlinePaymentForm
                      ref={onlinePaymentRef}
                      onValidation={(isValid) => setFormValid(isValid)}
                    />
                  </StripeSection>
                )}
              </div>
            </div>
            <AlertBox message={error} className="m-3" />
            <AlertBox
              message={strings.paymentSuccessful}
              hidden={!success}
              type={AlertType.SUCCESS}
              className="m-3"
              closeAble={false}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary mr-auto"
            onClick={closeModal}
            hidden={success}
          >
            {strings.cancel}
          </Button>
          <div>
            <Button
              variant="primary"
              hidden={success}
              onClick={(e: any) => startPayment(e)}
              disabled={!formValid || loading}
            >
              {strings.pay}
            </Button>
            <Button
              variant="primary"
              hidden={!success}
              onClick={closeModal}
              disabled={loading}
            >
              {strings.close}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestInvoicePaymentModal;
