/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import {
  getAddressString,
  getGeneralError,
} from "../../../../../util/helperFunctions";
import { ApiPromise } from "../../../../../models/ApiPromise";
import Button from "../../../../../components/Button";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import { AddressResponse } from "../../../../../models/contact/AddressResponse";
import { ContactOwner } from "./AddressOwnershipList";
import Modal from "../../../../../components/Modal/Modal";
import { ContactType } from "../../../../../models/contact/ContactType";

interface Props {
  address?: AddressResponse;
  onHide: () => void;
  owner: ContactOwner;
  removeAddress: (
    ownerId: string,
    ownershipId: string
  ) => ApiPromise<ContactOwner>;
  setAddresses: (addresses: AddressResponse[]) => void;
  show: boolean;
}

const DeleteAddressModal: React.FC<Props> = ({
  address,
  onHide,
  owner,
  removeAddress,
  setAddresses,
  show,
}: Props) => {
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect((): void => {
    setError(null);
  }, [show]);

  const deleteAddress = async (address: AddressResponse) => {
    setLoading(true);

    try {
      const response = await removeAddress(owner.id, address.id);
      setAddresses(response.data.addresses);
      setError(null);
      onHide();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.deleteAddress} />
      <Modal.Body>
        {address?.contactType === ContactType.PRIMARY ? (
          <AlertBox
            closeAble={false}
            message={strings.primaryContactNotDeletable}
            type={AlertType.WARNING}
          />
        ) : (
          <p>
            {strings.deleteAddressConfirmation}
            <br />
            <strong>{address && getAddressString(address)}</strong>
          </p>
        )}
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading}
          hidden={address?.contactType === ContactType.PRIMARY}
          fullWidth={false}
          loading={loading}
          onClick={() => {
            if (address) {
              void deleteAddress(address);
            }
          }}
          variant="danger"
        >
          {strings.deleteAddress}
        </Button>
        <Button
          className="modal-sub-button"
          disabled={loading}
          onClick={onHide}
          variant="secondary"
        >
          {strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAddressModal;
