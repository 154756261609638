/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ApiPromise } from "../models/ApiPromise";
import { axiosCommon } from "../util/axiosSetup";
import { Void } from "../models/Void";
import Params from "../common/Params";
import { InsuranceConfigResponse } from "../models/management/InsuranceConfigResponse";
import { InsuranceConfigRequest } from "../models/management/InsuranceConfigRequest";

export default class InsuranceConfigApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getConfig(countryCode: string): ApiPromise<InsuranceConfigResponse> {
    const url = `${this.baseUrl}/managements/countries/${countryCode}/insurance`;
    return axiosCommon.get(url);
  }

  static updateConfig(
    countryCode: string,
    request: InsuranceConfigRequest
  ): ApiPromise<InsuranceConfigResponse> {
    const url = `${this.baseUrl}/managements/countries/${countryCode}/insurance`;
    return axiosCommon.put(url, request);
  }

  static deleteConfig(countryCode: string): ApiPromise<Void> {
    const url = `${this.baseUrl}/managements/countries/${countryCode}/insurance`;
    return axiosCommon.delete(url);
  }
}
