/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { FeeType } from "../../../models/ePrescription/local/FeeType";
import DefaultFeeModal from "../modals/DefaultFeeModal";
import { ClinicFeeType } from "../../../models/clinic/ClinicFeeType";
import { useClinic } from "../../../contexts/ClinicContext";
import { ClinicFeeResponse } from "../../../models/clinic/ClinicFeeResponse";
import Button from "../../../components/Button";
import { Pencil } from "../../../common/Icons/Pencil";
import { RadioButtonOption } from "../../../common/Icons/RadioButtonOption";

interface Props {
  defaultPaymentType: FeeType;
  paymentResult(payment: FeeType): void;
  amountResult(amount: number): void;
}

const PaymentSection: React.FC<Props> = ({
  defaultPaymentType,
  paymentResult,
  amountResult,
}: Props) => {
  const { clinic } = useClinic();
  const [defaultPrescriptionFee, setDefaultPrescriptionFee] =
    useState<ClinicFeeResponse>();
  const [selectedFeeType, setSelectedFeeType] =
    useState<FeeType>(defaultPaymentType);
  const [defaultFeeModalOpen, setDefaultFeeModalOpen] =
    useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);

  const openDefaultFeeModal = () => {
    setDefaultFeeModalOpen(true);
  };

  const closeDefaultFeeModal = () => {
    setDefaultFeeModalOpen(false);
  };

  useEffect(() => {
    let defaultAmount = 0;
    clinic?.fees.forEach((fee) => {
      if (fee.type === ClinicFeeType.PRESCRIPTION_FEE) {
        setDefaultPrescriptionFee(fee);
        defaultAmount = fee.amount;
      }
    });

    amountResult(defaultAmount);
    setAmount(defaultAmount);
  }, [amountResult, clinic]);

  const submitNewAmount = (newAmount: number) => {
    setAmount(newAmount);
    amountResult(newAmount);
  };

  const pharmacyChange = (value: FeeType): void => {
    setSelectedFeeType(value);
    paymentResult(value);
  };

  const isFeeAvailable = (): boolean => {
    return clinic ? clinic?.affiliatedSwedishPharmacies?.length > 0 : false;
  };

  const isFeeSectionValid = (): boolean => {
    let result = false;
    if (
      (selectedFeeType === FeeType.FEE && amount > 0 && amount < 1000) ||
      selectedFeeType === FeeType.AUTOMATIC
    ) {
      result = true;
    }
    return result;
  };

  return (
    <div className="tw-card p-4 space-y-4">
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
        {strings.fees}
        <Button variant="icon" onClick={openDefaultFeeModal}>
          <Pencil />
        </Button>
      </h2>
      <div className="divide-y dark:divide-gray-700">
        <div className="pb-1">
          <Button
            onClick={() => {
              pharmacyChange(FeeType.AUTOMATIC);
            }}
            variant="basic"
          >
            {selectedFeeType === FeeType.AUTOMATIC ? (
              <RadioButtonOption className="text-sky-500" checked />
            ) : (
              <RadioButtonOption className="text-gray-500" checked={false} />
            )}
            <div className="ml-2">{strings.chargedAutomatically}</div>
          </Button>
        </div>
        <div className="pt-1">
          <Button
            disabled={!isFeeAvailable()}
            onClick={() => {
              pharmacyChange(FeeType.FEE);
            }}
            variant="basic"
          >
            {selectedFeeType === FeeType.FEE ? (
              <RadioButtonOption className="text-sky-500" checked />
            ) : (
              <RadioButtonOption className="text-gray-500" checked={false} />
            )}
            <div className="ml-2">{strings.chargedLater}</div>
          </Button>
        </div>
      </div>
      <DefaultFeeModal
        open={defaultFeeModalOpen}
        close={closeDefaultFeeModal}
        defaultFee={defaultPrescriptionFee}
        feeType={selectedFeeType}
        returnNewAmount={submitNewAmount}
      />
    </div>
  );
};

export default PaymentSection;
