/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";

// Retrieves a child of a React Component by name, returns undefined if it's not found
export const findChildByName = (
  childrenArray: React.ReactNode[],
  componentName: string
): React.ReactNode | undefined =>
  childrenArray.find(
    (child) =>
      React.isValidElement(child) &&
      (child.type as any).displayName === componentName
  );

/**
 * Gets the height of an element by its ID
 * @param {string} elementId - The ID of the element
 * @returns {number} - The height of the element in pixels (including vertical padding and borders), or 0 if the element is not found
 */
export const getElementHeightById = (elementId: string) => {
  const element = document.getElementById(elementId);
  return element ? element.offsetHeight : 0;
};

// Retrieves the height of a <div> element without padding, border and margin
export const getElementHeight = (ref: React.RefObject<HTMLDivElement>) => {
  const element = ref.current;
  if (!element) return 0;

  const styles = window.getComputedStyle(element);

  const pt = parseFloat(styles.paddingTop);
  const pb = parseFloat(styles.paddingBottom);

  return element.clientHeight - pt - pb;
};

// Creates a class name list from classnames given as a parameter
// This function concatenates classnames, filters out falsy values and removes double spaces
export const combineClassNames = (
  ...classNames: (string | null | undefined)[]
) => {
  const filteredClassNames = classNames.filter(Boolean);
  return filteredClassNames.join(" ").replace(/\s+/g, " ");
};

// Inserts a ReactElement into a string
export const insertReactElement = (
  string: string,
  element: ReactElement
): ReactElement => {
  // Split the string into parts before and after the placeholder
  const parts = string.split("{0}");

  // Return the parts joined together with the React element in between
  return (
    <div className="whitespace-pre-wrap">
      {parts[0]}
      {element}
      {parts[1]}
    </div>
  );
};

// Inserts an array of ReactElements into a string
export const insertReactElements = (
  string: string,
  elements: React.ReactElement[]
): React.ReactElement => {
  // Split the string into parts using placeholders like "{0}", "{1}", etc.
  const parts = string.split(/\{\d+}/);

  // Map each part to a React fragment containing the part and the corresponding element
  const mergedElements = parts.reduce((acc: React.ReactNode[], part, index) => {
    acc.push(
      React.createElement(React.Fragment, { key: `part-${index}` }, part)
    );
    if (index < elements.length) {
      acc.push(elements[index]);
    }
    return acc;
  }, []);

  // Return the merged elements wrapped in a div
  return <div className="">{mergedElements}</div>;
};

/**
 * Clones the given React element `n` times and wraps them in a `div`.
 *
 * @param {React.ReactElement} element - The React element to be cloned.
 * @param {number} n - The number of times to clone the element.
 * @returns {JSX.Element} A `div` containing the cloned elements.
 */
export const repeatElement = (element: ReactElement, n: number) => {
  // Create an array of n elements where each element is a clone of the given element
  const elementsArray = Array.from({ length: n }, (_, index) =>
    React.cloneElement(element, { key: index })
  );

  // Return the array wrapped in a div
  return <>{elementsArray}</>;
};

/**
 * Generates a random RGB color.
 *
 * @returns {string} - The random RGB color code.
 */
export const generateRandomRgbColor = (): string => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r}, ${g}, ${b})`;
};

/* Test first names */
const firstNames = [
  "Alice",
  "Bob",
  "Charlie",
  "Diana",
  "Eve",
  "Frank",
  "Grace",
  "Hank",
  "Ivy",
  "Jack",
];

/* Test last names */
const lastNames = [
  "Smith",
  "Johnson",
  "Williams",
  "Jones",
  "Brown",
  "Davis",
  "Miller",
  "Wilson",
  "Moore",
  "Taylor",
];

/**
 * Generates a random full name using predefined lists of first and last names.
 *
 * @returns {string} - The random full name.
 */
export const generateRandomName = (): string => {
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  return `${firstName} ${lastName}`;
};
