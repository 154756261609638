/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";

interface Props {
  listen: boolean;
  onClickOutside: () => void;
  ignore?: HTMLElement | null; // This prop is used mostly for the buttons/links that toggle menus and drawers
}

const ClickOutsideDetector: React.FC<
  Props & React.HTMLProps<HTMLDivElement>
> = ({ listen, onClickOutside, ignore, ...props }) => {
  const container = useRef<any>(null);

  const [enabled, setEnabled] = useState<boolean>(true);

  const handleEvent = (e: MouseEvent | TouchEvent) => {
    if (!enabled) {
      // Enable click handler after the resize event's click
      setEnabled(true);
      return;
    }

    // Ignore clicks inside the container
    if (container.current?.contains(e.target)) return;

    // Ignore the specified element (given as the ignore prop)
    if (container.current && !container.current.contains(e.target as Node)) {
      if (ignore instanceof Node && ignore.contains(e.target as Node)) {
        return;
      }
    }

    onClickOutside();
  };

  const onKeyUp = (e: any) => {
    // If the user hits ESC, it's considered a click outside!
    if (e.keyCode === 27) {
      handleEvent(e);
    }
  };

  const handleResize = () => {
    // Disable click handler during resize
    setEnabled(false);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (listen) {
      // Add listeners
      document.addEventListener("mousedown", handleEvent, false);
      document.addEventListener("touchend", handleEvent, false);
      document.addEventListener("keyup", onKeyUp);

      return () => {
        // Remove listeners
        document.removeEventListener("mousedown", handleEvent, false);
        document.removeEventListener("touchend", handleEvent, false);
        document.removeEventListener("keyup", onKeyUp);
      };
    }
    return undefined;
  });

  return <div id="click-outside-detector" ref={container} {...props} />;
};

export default ClickOutsideDetector;
