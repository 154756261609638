/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { SetupIntent } from "@stripe/stripe-js";
import { strings } from "../../common/Strings/Strings";
import { ClinicCreateRequest } from "../../models/clinic/ClinicCreateRequest";
import { PhoneType } from "../../models/contact/PhoneType";
import { LocatedAddressRequest } from "../../models/contact/LocatedAddressRequest";
import { useClinic } from "../../contexts/ClinicContext";
import BasicInformationTab from "./CreateClinicSteps/BasicInformationTab";
import AddressTab from "./CreateClinicSteps/AddressTab";
import ContactsTab from "./CreateClinicSteps/ContactsTab";
import LocationTab from "./CreateClinicSteps/LocationTab";
import { CountryResponse } from "../../models/management/CountryResponse";
import ClinicApi from "../../api/ClinicApi";
import { BusinessStructure } from "../../models/clinic/BusinessStructure";
import CountryApi from "../../api/CountryApi";
import CreditCardTab from "./CreateClinicSteps/CreditCardTab";
import { getGeneralError } from "../../util/helperFunctions";
import AlertBox from "../../components/AlertBox";
import SuccessTab from "./CreateClinicSteps/SuccessTab";
import { PageProps } from "../../models/PageProps";
import { Loader } from "../../components/Loader";
import { Feature } from "../../models/clinic/Feature";

export interface CreateClinicBasicInfoForm {
  name: string;
  email: string;
  phoneNumber: string;
  phoneType?: PhoneType;
  address?: LocatedAddressRequest;
  countryCode: string;
  registrationNumber: string;
  businessStructure: string;
  businessName: string;
  vat: string;
  stripeSetupIntentId: string;
}

const defaultFormValues: CreateClinicBasicInfoForm = {
  name: "",
  email: "",
  phoneNumber: "",
  phoneType: PhoneType.MOBILE,
  address: undefined,
  countryCode: "",
  registrationNumber: "",
  businessStructure: BusinessStructure.COMPANY,
  businessName: "",
  vat: "",
  stripeSetupIntentId: "",
};

enum Tabs {
  BASIC_INFO = strings.basicInfo,
  CONTACTS = strings.contacts,
  ADDRESS = strings.address,
  LOCATION = strings.location,
  BANK_CARD = strings.creditCardDetails,
  SUCCESS = strings.success,
}

const tabsInOrder = [
  Tabs.BASIC_INFO,
  Tabs.CONTACTS,
  Tabs.ADDRESS,
  Tabs.LOCATION,
  Tabs.BANK_CARD,
  Tabs.SUCCESS,
];

const CreateClinic: React.FC<PageProps> = (props) => {
  const { setPageLoading } = props;
  const navigate = useNavigate();
  const { fetchClinics } = useClinic();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.BASIC_INFO);

  const [countryOptions, setCountryOptions] = useState<CountryResponse[]>([]);

  const createClinicFormMethods = useForm<CreateClinicBasicInfoForm>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });
  const { getValues, watch, setValue } = createClinicFormMethods;
  const address = watch("address");
  const countryCode = watch("countryCode");

  const nextStep = () => {
    if (tabIndex === tabsInOrder.length - 1) {
      return;
    }
    setActiveTab(tabsInOrder[tabIndex + 1]);
    setTabIndex(tabIndex + 1);
  };

  const previousStep = () => {
    if (tabIndex === 0) {
      navigate(-1);
    }
    setActiveTab(tabsInOrder[tabIndex - 1]);
    setTabIndex(tabIndex - 1);
  };

  const createClinic = async (stripe: SetupIntent) => {
    const data = getValues();

    if (!data.address) {
      return;
    }

    const clinic: ClinicCreateRequest = {
      name: data.name,
      features: Object.values(Feature),
      address: data.address,
      zoneId: data.address?.zoneId,
      email: data.email,
      phoneNumber: data.phoneNumber,
      phoneType: data.phoneType ?? PhoneType.MOBILE,
      businessName: data.businessName,
      businessStructure: data.businessStructure,
      registrationNumber: data.registrationNumber,
      countryCode: data.countryCode,
      vat: data.registrationNumber,
      stripeSetupIntentId: stripe.id,
    };

    setPageLoading(true);

    try {
      await ClinicApi.createClinic(clinic);
      fetchClinics();
      nextStep();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      setPageLoading(true);
      try {
        const response = await CountryApi.getCountries();
        setCountryOptions(response.data);
      } catch (err) {
        setError(strings.simpleError);
        setPageLoading(false);
      } finally {
        setPageLoading(false);
      }
    };

    void getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="main-default">
      <section>
        <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <div className="w-full md:max-w-md md:tw-card space-y-6 md:p-8">
            <div className="flex justify-between space-x-4">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.createNewClinic}
              </h1>
              <span
                className="text-gray-500 dark:text-gray-400"
                hidden={activeTab === Tabs.SUCCESS}
              >
                {tabIndex + 1}/{tabsInOrder.length - 1}
              </span>
            </div>
            <div hidden={activeTab === Tabs.SUCCESS}>
              <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {activeTab}
              </h2>
            </div>
            <AlertBox message={error} />
            <div>
              <FormProvider {...createClinicFormMethods}>
                <form>
                  {activeTab === Tabs.BASIC_INFO && (
                    <BasicInformationTab
                      countries={countryOptions}
                      onNext={nextStep}
                    />
                  )}
                  {activeTab === Tabs.CONTACTS && (
                    <ContactsTab
                      defaultCountryCode={countryCode}
                      onNext={nextStep}
                    />
                  )}
                  {activeTab === Tabs.ADDRESS && (
                    <AddressTab onNext={nextStep} />
                  )}
                </form>
              </FormProvider>
              {activeTab === Tabs.LOCATION && (
                <LocationTab
                  address={address}
                  countryName={
                    countryOptions.find((c) => c.countryCode === countryCode)
                      ?.displayName
                  }
                  onPrevious={previousStep}
                  onSubmit={(locatedAddress: LocatedAddressRequest) => {
                    setValue("address.latitude", locatedAddress.latitude);
                    setValue("address.longitude", locatedAddress.longitude);
                    setValue("address.zoneId", locatedAddress.zoneId);
                    nextStep();
                  }}
                />
              )}
              {activeTab === Tabs.BANK_CARD && (
                <CreditCardTab
                  loading={loading}
                  setLoading={setLoading}
                  setAlert={setError}
                  addressData={address}
                  countryCode={countryCode}
                  onSubmit={(data: SetupIntent) => {
                    void createClinic(data);
                  }}
                />
              )}
              {activeTab === Tabs.SUCCESS && (
                <SuccessTab
                  onFinish={() => {
                    navigate(`/`, { replace: true });
                  }}
                />
              )}
            </div>
            <div className="flex justify-between">
              {activeTab === Tabs.BASIC_INFO ? (
                <Link to="/globalvet/clinics">{strings.back}</Link>
              ) : (
                <Button
                  className="text-left text-sm p-0"
                  hidden={activeTab === Tabs.SUCCESS}
                  onClick={previousStep}
                  variant="link"
                >
                  {tabIndex === 0 ? strings.cancel : strings.back}
                </Button>
              )}

              <div id="dev-only" hidden>
                <Button
                  className="text-right text-sm p-0"
                  hidden={activeTab === Tabs.SUCCESS}
                  onClick={nextStep}
                  variant="link"
                >
                  {strings.next}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(CreateClinic);
