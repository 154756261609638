/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContactsConfirmationApi from "../../../api/ContactsConfirmationApi";
import EmailConfirmation from "./EmailConfirmation";
import ClinicContactsApi from "../../../api/ClinicContactsApi";
import AlertBox from "../../../components/AlertBox";
import { strings } from "../../../common/Strings/Strings";

const ClinicEmailConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ emailId: string; token: string }>();
  const emailId: string | undefined = params.emailId;
  const { token } = params;

  const onRedirect = () => {
    navigate("/", { replace: true });
  };

  const confirmEmail = async () => {
    if (emailId && token) {
      await ContactsConfirmationApi.confirmEmail(emailId, token);
    }
  };

  if (emailId && token) {
    return (
      <EmailConfirmation
        emailId={emailId}
        token={token}
        onRedirect={onRedirect}
        confirm={confirmEmail}
        resendMail={(id) => ClinicContactsApi.sendClinicEmailConfirmation(id)}
      />
    );
  }

  return <AlertBox message={strings.errorDuringEmailVerification} />;
};

export default ClinicEmailConfirmation;
