/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { strings } from "../../common/Strings/Strings";
import { getGeneralError } from "../../util/helperFunctions";
import { axiosCommon } from "../../util/axiosSetup";
import logger from "../../util/logger";
import CountryApi from "../../api/CountryApi";
import { Option } from "../../models/Option";
import SimpleSelect from "../../components/InputFieldsSimple/SimpleSelect";
import { useUser } from "../../contexts/UserContext";
import AlertBox from "../../components/AlertBox";
import { PolicyType } from "../../models/management/PolicyType";
import GDPRApi from "../../api/GDPRApi";
import { PolicyResponse } from "../../models/management/PolicyResponse";
import LoaderInline from "../../components/LoaderInline";
import * as DOMPurify from "dompurify";

interface Props {
  allowCountryChange?: boolean;
  defaultCountryCode?: string;
  policyType: PolicyType;
  title: string;
}

const ManageCookies: React.FC<Props> = ({
  allowCountryChange = true,
  defaultCountryCode,
  policyType,
  title,
}: Props) => {
  const { user } = useUser();

  const [policy, setPolicy] = useState<PolicyResponse | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [countryCode, setCountryCode] = useState<string | undefined>(
    defaultCountryCode
  );
  const [country, setCountry] = useState<string>();

  const [countryOptions, setCountryOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!allowCountryChange && defaultCountryCode) return;
    if (!user || !user.addresses) return;

    setCountryCode(
      (user.addresses.length > 0 && user?.addresses[0].countryCode) || undefined
    );
  }, [allowCountryChange, defaultCountryCode, user]);

  useEffect(() => {
    const getCountryFromIp = (options: Option[]) => {
      if (!allowCountryChange && defaultCountryCode) return;

      axiosCommon
        .get("https://ipapi.co/json/")
        .then((response: any) => {
          const code = response.data.country_code;
          if (options.find((i) => i.value === code)) {
            setCountryCode(code);
          }
        })
        .catch((err: Error | AxiosError) => {
          logger.error(err);
        });
    };

    const getCountries = async () => {
      try {
        const response = await CountryApi.getCountries();
        const countries = response.data
          .filter((i) => i.availablePolicies.includes(policyType))
          .map((i) => ({ title: i.displayName, value: i.countryCode }));

        setCountryOptions(countries);
        getCountryFromIp(countries);
      } catch (err) {
        setError(await getGeneralError(err));
      }
    };

    void getCountries();
  }, [allowCountryChange, defaultCountryCode, policyType]);

  useEffect(() => {
    if (!countryCode) {
      return;
    }

    setCountry(countryOptions.find((c) => c.value === countryCode)?.title);
  }, [countryCode, countryOptions]);

  useEffect(() => {
    if (!countryCode) {
      return;
    }

    const getPolicy = async (code: string) => {
      setLoading(true);

      try {
        const response = await GDPRApi.getPolicy(policyType, code);
        setPolicy(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void getPolicy(countryCode);
  }, [countryCode, policyType]);

  return (
    <main
      className={user.userId ? "main-signed-in" : "main-signed-in-no-clinic"}
    >
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {title}
              {!allowCountryChange && country && ` - ${country}`}
            </h1>
            {allowCountryChange && (
              <div className="flex">
                <SimpleSelect
                  isChooseOption={false}
                  label={strings.country}
                  name="countryCode"
                  options={countryOptions}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setCountryCode(e.target.value);
                  }}
                  value={countryCode}
                />
              </div>
            )}
            <LoaderInline hidden={!loading} />
            <AlertBox message={error} />
            <div
              className="xl:w-1/2"
              dangerouslySetInnerHTML={
                policy?.statement
                  ? { __html: DOMPurify.sanitize(policy?.statement) }
                  : { __html: "" }
              }
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default ManageCookies;
