/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { strings } from "../../../../common/Strings/Strings";
import { ServiceFeeResponse } from "../../../../models/servicefee/ServiceFeeResponse";
import logger from "../../../../util/logger";
import { getGeneralError } from "../../../../util/helperFunctions";
import ServiceFeeApi from "../../../../api/ServiceFeeApi";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import DynamicSelect from "../../../../components/ReactHookFormFields/General/Select/DynamicSelect";
import Button from "../../../../components/Button";
import { useMainSectionContext } from "../../MainSection/MainSectionContext";
import { XMark } from "../../../../common/Icons/XMark";

interface Props {
  canEdit: boolean;
  clinicId: string;
}

interface FormData {
  fee: ServiceFeeResponse[];
  quantity: number;
}

const UseFees: React.FC<Props> = ({ canEdit, clinicId }: Props) => {
  const [allServiceFees, setAllServiceFees] = useState<ServiceFeeResponse[]>(
    []
  );
  const [options, setOptions] = useState<ServiceFeeResponse[]>([]);
  const [addedItem, setItem] = useState<ServiceFeeResponse[] | null>(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      quantity: 1,
    },
  });
  const { usedFees, setUsedFees, triggerChange } = useMainSectionContext();

  useEffect(() => {
    const getServiceFees = async () => {
      try {
        const response = await ServiceFeeApi.getServiceFeesOfClinic(
          clinicId,
          200,
          0
        );
        setAllServiceFees(response.data.elements);
        setOptions(response.data.elements);
      } catch (e) {
        logger.error(await getGeneralError(e));
      }
    };

    if (clinicId) {
      void getServiceFees();
    }
  }, [clinicId]);

  const findServiceFees = async (query: string) => {
    try {
      const response = await ServiceFeeApi.searchServiceFeesOfClinic(
        clinicId,
        query
      );
      setOptions(response.data.elements);
    } catch (e) {
      logger.error(await getGeneralError(e));
    }
  };

  const search = (query: string) => {
    if (query && query.length > 0) {
      void findServiceFees(query);
    }
  };

  const addToList = ({ fee, quantity }: FormData) => {
    setUsedFees({
      type: "add",
      itemToAdd: fee[0],
      quantity,
    });
    triggerChange();
    setItem(null);
    setOptions(allServiceFees);
    reset();
  };

  const handleChange = (input: Array<ServiceFeeResponse> | null) => {
    setItem(input);
  };

  return (
    <div className={canEdit && usedFees.length === 0 ? "hidden" : ""}>
      <form className={usedFees.length === 0 ? "" : "pb-4"} onSubmit={handleSubmit(addToList)}>
        <div className="flex flex-wrap gap-4 items-end">
          <div className="relative grow">
            <label htmlFor="fee">{strings.fees}</label>
            {canEdit ? (
              <></>
            ) : (
              <>
                <div className="absolute right-0 top-0">
                  <Link className="text-sm" to="/clinic-settings/service-fees">
                    {strings.createNewServiceFee}
                  </Link>
                </div>
                <DynamicSelect
                  allowNew={false}
                  control={control}
                  defaultOptions={allServiceFees}
                  labelKey="name"
                  name="fee"
                  onChange={handleChange}
                  onInputValueChange={search}
                  options={options}
                  placeholder={strings.searchFees}
                />
              </>
            )}
          </div>
          {addedItem && (
            <>
              <Field
                autoComplete={AutoCompleteOptions.off}
                error={errors.quantity}
                fieldOptions={{
                  validate: {
                    positive: (v) => v > 0,
                  },
                }}
                label={strings.quantity}
                name="quantity"
                register={register}
                required
                step="0.0001"
                type="number"
                width="100px"
              />
              <div>
                <Button
                  hidden={!addedItem}
                  variant="primary"
                  className="text-sm"
                  type="submit"
                >
                  {strings.add}
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
      <div className="space-y-2">
        {usedFees?.map((record, idx: number) => (
          <div key={idx} className="tw-card px-3 py-2">
            <div className="flex items-center">
              <div className="font-bold text-base flex space-x-3">
                <div>{record.quantity}x</div>
                <div>{record.name}</div>
              </div>
              <div className="ml-auto flex items-center">
                {canEdit ? null : (
                  <>
                    <Button
                      onClick={() => {
                        setUsedFees({ type: "remove", itemToRemove: record });
                      }}
                      variant="basic"
                    >
                      <XMark className="w-5 h-5" />
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UseFees;
