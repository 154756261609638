/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const petSpeciesEn = {
  ALPACA: "Alpaca",
  ASS: "Ass",
  BIRD: "Bird",
  CAMEL: "Camel",
  CAT: "Cat",
  CHICKEN: "Chicken",
  CHINCHILLA: "Chinchilla",
  CHINCHILLA_LIVESTOCK: "Chinchilla (livestock)",
  DEER: "Deer",
  DEGU: "Degu",
  DOG: "Dog",
  DONKEY: "Donkey",
  DUCK: "Duck",
  FERRET: "Ferret",
  FISH_FARMED: "Fish (farmed)",
  FISH_WILD: "Fish (wild)",
  FUR_ANIMAL: "Fur animal",
  GOAT: "Goat",
  GOOSE: "Goose",
  GUINEA_PIG: "Guinea pig",
  HAMSTER: "Hamster",
  HORSE: "Horse",
  INSECT: "Insect",
  MINK: "Mink",
  MOOSE: "Moose",
  MOUSE: "Mouse",
  MUSSEL_FARMED: "Mussel (farmed)",
  OSTRICH: "Ostrich",
  OTHER_LIVESTOCK: "Other livestock",
  OTHER_PRODUCTION: "Other production",
  OTHER_FENCED_GAME: "Other fenced game",
  OTHER_GAME: "Other game",
  OTHER: "Other pet",
  OTHER_POULTRY: "Other poultry",
  PIG: "Pig",
  PIG_LIVESTOCK: "Pig (livestock)",
  POLECAT: "Ferret",
  RABBIT: "Rabbit",
  RABBIT_LIVESTOCK: "Rabbit (livestock)",
  RAT: "Rat",
  REINDEER: "Reindeer",
  REPTILE: "Reptile",
  SHEEP: "Sheep",
  TURKEY: "Turkey",
  WILD_BOAR: "Wild boar",
  ZOO_ANIMAL: "Zoo animal"
};