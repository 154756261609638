/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import RecoveryApi from "../../../api/RecoveryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";

interface Props {
  smsCodeId: string;
  phoneNumber: string;
}

interface SmsCodeForm {
  smsCode: string;
}

const defaultFormValues = {
  smsCode: "",
};

const SmsCode: React.FC<Props> = ({ smsCodeId, phoneNumber }: Props) => {
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState<
    string | ReactElement | null
  >(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.INFO);
  const [loading, setLoading] = useState<boolean>(false);

  const submitForm = async (formData: SmsCodeForm) => {
    setLoading(true);
    try {
      const resp = await RecoveryApi.getRecoveryTokenBySmsCode(
        smsCodeId,
        formData.smsCode
      );
      setAlertMessage(null);
      navigate(`/reset-password/${resp.data.tokenId}/${resp.data.token}`);
    } catch (e) {
      setAlertMessage(await getGeneralError(e));
      setAlertType(AlertType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const setSmsInfoMessage = (phone: string) => {
      setAlertMessage(
        <>
          {strings.recoverySmsOk} <strong>{phone}</strong>
        </>
      );
      setAlertType(AlertType.INFO);
    };

    setSmsInfoMessage(phoneNumber);
  }, [phoneNumber]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SmsCodeForm>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  return (
    <form className="space-y-6" onSubmit={handleSubmit(submitForm)}>
      <Field
        name="smsCode"
        label={strings.confirmationCode}
        register={register}
        autoComplete={AutoCompleteOptions.off}
        fieldOptions={{
          required: true,
          validate: {
            lengthIsSix: (v) =>
              v.toString().length === 6 ||
              strings.confirmationCodeMustBeSixCharacters,
          },
        }}
        error={errors.smsCode}
      />
      <AlertBox message={alertMessage} type={alertType} />
      <Button disabled={loading || !isValid} loading={loading} type="submit">
        {strings.confirm}
      </Button>
    </form>
  );
};

export default SmsCode;
