/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { PetFormProps } from "./PetForm";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import SelectInsuranceCompany from "../../../components/ReactHookFormFields/Specific/SelectInsuranceCompany";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { chronicDiseasesList } from "../../../common/ChronicDiseases/ChronicDiseases";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";

const PetFormOptionalTab: React.FC = () => {
  const { control, register } = useFormContext<PetFormProps>();

  return (
    <>
      <Field
        autoComplete={AutoCompleteOptions.off}
        name="chipNumber"
        register={register}
        label={strings.chipNumber}
        optional
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        name="passportNumber"
        register={register}
        label={strings.passportNumber}
        optional
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        name="tattooNumber"
        register={register}
        label={strings.tattooNumber}
        optional
      />
      <SelectInsuranceCompany
        control={control}
        name="insuranceCompany"
        optional
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        name="insuranceNumber"
        register={register}
        label={strings.insuranceNumber}
        optional
      />
      <CombinedSelect
        allowNew={false}
        control={control}
        label={strings.chronicDiseases}
        labelKey="name"
        multiple
        name="chronicDiseases"
        options={chronicDiseasesList}
        optional
      />
      <Textarea
        autoComplete={AutoCompleteOptions.off}
        name="comment"
        register={register}
        label={strings.comment}
        minRows={2}
        optional
      />
      <CheckBox
        name="deceased"
        register={register}
        label={strings.deceased}
        defaultChecked={false}
      />
    </>
  );
};

export default PetFormOptionalTab;
