/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  petGenders,
  petSpecies,
  strings,
} from "../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import { PersonDetailsResponse } from "../../../../models/contact/PersonDetailsResponse";
import { PetDetailsResponse } from "../../../../models/pet/PetDetailsResponse";
import { PlusIcon } from "../../../../common/Icons/PlusIcon";
import CloseButton from "../../../../components/CloseButton";
import { UserPetTransferRequest } from "../../../../models/petTransfer/UserPetTransferRequest";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import Textarea from "../../../../components/ReactHookFormFields/General/Textarea";
import { validateInternationalEmail } from "../../../../util/Validations";
import PetTransferApi from "../../../../api/PetTransferApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import Modal from "../../../../components/Modal/Modal";
import Button from "../../../../components/Button";
import UserProfilePicture from "../../../../components/Pictures/User/UserProfilePicture";
import PetProfilePicture from "../../../../components/Pictures/Pet/PetProfilePicture";
import HorizontalLine from "../../../../components/HorizontalLine";

interface Props {
  close(): void;
  isOpen: boolean;
  pet?: PetDetailsResponse;
  petOwner?: PersonDetailsResponse;
}

const ChangeUserOwnerModal = ({
  close,
  isOpen,
  pet,
  petOwner,
}: Props): ReactElement => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [noteVisible, setNoteVisible] = useState<boolean>(false);
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<UserPetTransferRequest>({
    defaultValues: { newOwnerEmail: undefined, note: undefined },
  });
  const navigate = useNavigate();

  const onClose = () => {
    if (isSuccess) {
      navigate("/pet-owner");
    } else {
      setError(undefined);
      setNoteVisible(false);
      reset();
      close();
    }
  };

  const onSubmit = async (values: UserPetTransferRequest) => {
    if (!pet) {
      return;
    }
    setSubmitting(true);
    try {
      await PetTransferApi.transfer(pet.id, values);
      setIsSuccess(true);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal handleClose={onClose} show={isOpen}>
      <Modal.Header title={strings.changeOwner} />
      <Modal.Body>
        <div className="pb-3 space-y-3">
          <b>{strings.pet}</b>
          <div className="flex items-center">
            <div className="h-20 w-20 mr-3">
              <PetProfilePicture
                border
                borderWidth="3px"
                petId={pet?.id}
                species={pet?.species}
              />
            </div>
            <div>
              <b>{pet?.name}</b>
              <div>
                {pet?.species && petSpecies[pet?.species]},{" "}
                {pet?.gender && petGenders[pet?.gender]}
                {pet?.color && `, ${pet?.color}`}, {pet?.breed}
              </div>
            </div>
          </div>
        </div>
        <HorizontalLine />
        <div className="pb-3 space-y-3">
          <b>{strings.currentPetOwner}</b>
          <div className="flex items-center">
            <div className="h-20 w-20 mr-3">
              <UserProfilePicture
                border
                borderWidth="3px"
                userId={petOwner?.id}
              />
            </div>
            <div>
              <b>{petOwner?.fullName}</b>
              <div>{petOwner?.personalId}</div>
            </div>
          </div>
        </div>
        <HorizontalLine />
        <form>
          <div className="pb-3">
            <Field
              error={errors.newOwnerEmail}
              fieldOptions={{
                validate: {
                  isEmail: (v) => validateInternationalEmail(v),
                },
              }}
              label={strings.newOwnerEmail}
              name="newOwnerEmail"
              register={register}
              required
            />
          </div>
          <HorizontalLine />
          <div className="pb-3">
            {noteVisible ? (
              <Textarea
                error={errors.note}
                label={strings.note}
                name="note"
                minRows={3}
                maxLength={100}
                register={register}
              />
            ) : (
              <Button
                onClick={() => {
                  setNoteVisible(true);
                }}
                variant="link"
              >
                <div className="flex items-center">
                  <PlusIcon className="w-6 h-6" />
                  <div>{strings.addNote}</div>
                </div>
              </Button>
            )}
          </div>
        </form>
        <AlertBox className="my-5" message={error} />
        <AlertBox
          className="my-5"
          closeAble={false}
          hidden={!isSuccess}
          message={strings.savedSuccessfully}
          type={AlertType.SUCCESS}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={submitting}
          onClick={handleSubmit(onSubmit)}
        >
          {strings.changeOwner}
        </Button>
        <CloseButton
          disabled={submitting}
          onClick={() => {
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeUserOwnerModal;
