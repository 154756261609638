/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useFormContext } from "react-hook-form";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import { generateAnimalOptionsGeneral } from "../../../common/AnimalSpecies/SpeciesFunctions";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import { TreatmentResponse } from "../../../models/treatment/TreatmentResponse";
import Field from "../../../components/ReactHookFormFields/General/Field";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import Button from "../../../components/Button";
import { Pencil } from "../../../common/Icons/Pencil";
import { TreatmentFeeResponse } from "../../../models/treatment/TreatmentFeeResponse";
import { TreatmentItemResponse } from "../../../models/treatment/TreatmentItemResponse";
import ControlledTextarea from "../../../components/ReactHookFormFields/General/Controlled/ControlledTextarea";
import { PlusIcon } from "../../../common/Icons/PlusIcon";

interface Props {
  editedTreatment?: TreatmentResponse;
  openAddFeeModal(): void;
  openAddItemModal(): void;
  usedFees: Array<TreatmentFeeResponse>;
  usedItems: Array<TreatmentItemResponse>;
}

const BasicDetails: React.FC<Props> = ({
  editedTreatment,
  openAddFeeModal,
  openAddItemModal,
  usedFees,
  usedItems,
}: Props) => {
  const speciesOptions = generateAnimalOptionsGeneral();
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [applicableToSpecificSpecies, setApplicableToSpecificSpecies] =
    useState<boolean>(
      editedTreatment !== undefined && editedTreatment.species.length > 0
    );

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {strings.basicInfo}
        </h2>
      </div>
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.newName}
        label={strings.treatmentName}
        name="newName"
        register={register}
        required
      />
      <CheckBox
        register={register}
        name="applicableToSpecificSpecies"
        defaultChecked={applicableToSpecificSpecies}
        label={strings.applicableToSpecificSpecies}
        autoComplete={AutoCompleteOptions.off}
        onChange={(value) => {
          setApplicableToSpecificSpecies(value);
          if (!value) {
            setValue("species", []);
          }
        }}
      />
      {applicableToSpecificSpecies ? (
        <CombinedSelect
          allowNew={false}
          fieldOptions={{
            validate: {
              isNotEmpty: (species: string) =>
                !getValues("applicableToSpecificSpecies") || species.length > 0,
            },
          }}
          control={control}
          name="species"
          multiple
          options={speciesOptions}
          placeholder={strings.chooseSpecies}
          labelKey="title"
          clearButton
        />
      ) : (
        <></>
      )}
      <ControlledTextarea
        name="description"
        control={control}
        autoComplete={AutoCompleteOptions.off}
        label={strings.description}
        toolTipText={strings.generateIntoMedicalRecordToolTip}
      />
      <div>
        {usedItems.length === 0 ? (
          <Button variant="link" onClick={openAddItemModal}>
            <PlusIcon /> <span>{strings.addInventoryItems}</span>
          </Button>
        ) : (
          <div>
            <div className="flex">
              {strings.usedItems}
              <div className="ml-auto">
                <Button variant="icon" onClick={openAddItemModal}>
                  <Pencil />
                </Button>
              </div>
            </div>
            <ul>
              {usedItems.map((i) => (
                <li key={i.id}>
                  {i.quantity}
                  {quantityUnits[i.item.acquisitionUnit || "PIECES"]}{" "}
                  <b>{i.item.name}</b>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div>
        {usedFees.length === 0 ? (
          <Button variant="link" onClick={openAddFeeModal}>
            <PlusIcon /> <span>{strings.addServiceFees}</span>
          </Button>
        ) : (
          <div>
            <div className="flex">
              {strings.usedFees}
              <div className="ml-auto">
                <Button variant="icon" onClick={openAddFeeModal}>
                  <Pencil />
                </Button>
              </div>
            </div>
            <ul>
              {usedFees.map((f) => (
                <li key={f.id}>
                  {f.quantity} <b>{f.fee.name}</b>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <ControlledTextarea
        control={control}
        name="advices"
        autoComplete={AutoCompleteOptions.off}
        label={strings.advices}
        toolTipText={strings.generateIntoMedicalRecordToolTip}
      />
      <ControlledTextarea
        name="complications"
        control={control}
        autoComplete={AutoCompleteOptions.off}
        label={strings.complications}
      />
      <ControlledTextarea
        name="comment"
        control={control}
        autoComplete={AutoCompleteOptions.off}
        label={strings.comment}
      />
    </div>
  );
};

export default BasicDetails;
