/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../util/helperFunctions";
import { AccountingCodeResponse } from "../../../../models/accounting/AccountingCodeResponse";
import CloseButton from "../../../../components/CloseButton";
import { AccountingCodeRequest } from "../../../../models/accounting/AccountingCodeRequest";
import AccountingCodeApi from "../../../../api/AccountingCodeApi";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import AlertBox from "../../../../components/AlertBox";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal/Modal";

/* This form is used for adding new accounting code and for modifying already existing ones too. */

interface Props {
  clinicId: string;
  reload: () => void;
  isOpen: boolean;
  close: () => void;
  accountingCode?: AccountingCodeResponse;
}

const AccountingCodeModal: React.FC<Props> = (props: Props) => {
  const { clinicId, reload, isOpen, close, accountingCode } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [externalError, setExternalError] = useState<string | null>(null);

  const defaultFormValues = {
    code: accountingCode?.code,
    name: accountingCode?.name,
    sendToFortnox: true,
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AccountingCodeRequest>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  const resetFormAndErrors = () => {
    reset(defaultFormValues);
    setExternalError(null);
  };

  useEffect(() => {
    resetFormAndErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingCode]);

  const onSubmitForm = async (request: AccountingCodeRequest) => {
    setLoading(true);

    try {
      if (accountingCode?.id) {
        await AccountingCodeApi.updateAccountingCode(
          clinicId,
          accountingCode.id,
          request
        );
      } else {
        await AccountingCodeApi.addAccountingCode(clinicId, request);
      }
      resetFormAndErrors();
      reload();
      close();
    } catch (err) {
      setExternalError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.accountingCode} />
      <Modal.Body noSpacing>
        <form className="modal-body" id="accountingCodeForm">
          <Field
            name="code"
            label={strings.accountingCode}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            required
            readOnly={accountingCode !== undefined}
            error={errors.code}
          />
          <Field
            name="name"
            label={strings.name}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            required
            error={errors.name}
          />
          <AlertBox
            hidden={!externalError}
            className="mt-2"
            message={externalError}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || loading}
          form="accountingCodeForm"
          loading={loading}
          onClick={handleSubmit(onSubmitForm)}
          type="submit"
        >
          {strings.save}
        </Button>
        <CloseButton onClick={close} />
      </Modal.Footer>
    </Modal>
  );
};

export default AccountingCodeModal;

AccountingCodeModal.defaultProps = {
  accountingCode: undefined,
};
