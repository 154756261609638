/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Cross } from "../common/Icons/Cross";
import Button from "./Button";
import { strings } from "../common/Strings/Strings";

interface Props {
  id: string;
  positionClassName: string;
  closeable?: boolean;
  show?: boolean;
  text: string;
}

const TooltipHint: React.FC<Props> = ({
  id,
  positionClassName,
  closeable = true,
  show = true,
  text,
}: Props) => {
  const [mutedTooltips, setMutedTooltips] = useLocalStorage<string[]>(
    "mutedTooltips",
    []
  );

  const visible = show && !mutedTooltips.includes(id);

  const hideAndMute = () => {
    setMutedTooltips([...mutedTooltips, id]);
  };

  if (!visible) {
    return null;
  }

  return (
    <div
      className={`${positionClassName} w-screen max-w-[30vw] xl:w-96 z-50 flex justify-between rounded-lg text-white shadow-md bg-gray-600 dark:bg-gray-600 px-3 py-2 text-sm font-medium transition-opacity duration-300`}
    >
      <span>{text}</span>
      <Button
        aria-label={strings.close}
        className="text-white bg-gray-600 dark:bg-gray-600 hover:bg-gray-500 hover:dark:bg-gray-500"
        hidden={!closeable}
        onClick={hideAndMute}
        variant="icon"
      >
        <Cross className="w-5 h-5" strokeWidth={2} />
      </Button>
    </div>
  );
};

export default TooltipHint;
