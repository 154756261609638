/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { axiosCommon } from "../util/axiosSetup";
import { AccountingCodeRequest } from "../models/accounting/AccountingCodeRequest";
import { AccountingCodeResponse } from "../models/accounting/AccountingCodeResponse";

export default class AccountingCodeApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static addAccountingCode(
    clinicId: string,
    request: AccountingCodeRequest
  ): ApiPromise<AccountingCodeResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/accounting-codes`;
    return axiosCommon.post(url, request);
  }

  static getAccountingCodesOfClinic(
    clinicId: string
  ): ApiPromise<AccountingCodeResponse[]> {
    const url = `${this.baseUrl}/clinics/${clinicId}/accounting-codes`;
    return axiosCommon.get(url);
  }

  static updateAccountingCode(
    clinicId: string,
    accountingCodeId: string,
    request: AccountingCodeRequest
  ): ApiPromise<AccountingCodeResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/accounting-codes/${accountingCodeId}`;
    return axiosCommon.put(url, request);
  }

  static removeAccountingCode(
    clinicId: string,
    accountingCodeId: string
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/accounting-codes/${accountingCodeId}`;
    return axiosCommon.delete(url);
  }

  static syncAccountingCodesOfClinic(
    clinicId: string
  ): ApiPromise<AccountingCodeResponse[]> {
    const url = `${this.baseUrl}/clinics/${clinicId}/accounting-codes/sync`;
    return axiosCommon.post(url);
  }
}
