/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import ExaminationModal from "./ExaminationModal";
import { PetSpecies } from "../../../models/pet/PetSpeciesEnum";
import { strings } from "../../../common/Strings/Strings";
import {
  HematologyParams,
  HematologyReferenceRanges,
  HematologyUnits,
} from "../../../common/ExaminationReferences/Hematology";
import { ExaminationType } from "../../../models/medical/local/ExaminationType";
import {
  BiochemistryParams,
  BiochemistryReferenceRanges,
  BiochemistryUnits,
} from "../../../common/ExaminationReferences/Biochemistry";
import AddButtonIcon from "../../../components/AddButtonIcon";
import Button from "../../../components/Button";

interface Props {
  species: PetSpecies;
  returnDiagnosticsText(text: string): void;
  readOnly: boolean;
}

const Diagnostics: React.FC<Props> = ({
  species,
  returnDiagnosticsText,
  readOnly,
}: Props) => {
  const [hematologyModal, setHematologyModal] = useState<boolean>(false);
  const [biochemistryModal, setBiochemistryModal] = useState<boolean>(false);

  const openHematologyModal = () => {
    setHematologyModal(true);
  };

  const closeHematologyModal = () => {
    setHematologyModal(false);
  };

  const openBiochemistryModal = () => {
    setBiochemistryModal(true);
  };

  const closeBiochemistryModal = () => {
    setBiochemistryModal(false);
  };

  const rangesForBioChemistry = (
    rangeBeginning: number | null | undefined,
    rangeEnding: number | null | undefined
  ): string => {
    if (rangeBeginning !== null && rangeBeginning !== undefined) {
      if (rangeEnding !== null && rangeEnding !== undefined) {
        return `${rangeBeginning  } - ${  rangeEnding}`;
      }
    } else if (rangeBeginning === null) {
      if (rangeEnding !== null && rangeEnding !== undefined) {
        return `<= ${  rangeEnding}`;
      }
    }
    return "";
  };

  const rangesForHematology = (
    rangeBeginning: number | null | undefined,
    rangeEnding: number | null | undefined
  ): string => {
    if (rangeBeginning !== null && rangeBeginning !== undefined) {
      if (rangeEnding !== null && rangeEnding !== undefined) {
        return `${rangeBeginning  } - ${  rangeEnding}`;
      }
    }
    return "";
  };

  const hematologyResultProcessor = (results: Array<number>) => {
    let resultText = `${strings.hematology  }:\n`;

    results.forEach((r: number, index: number) => {
      const rangeBeginning =
        HematologyReferenceRanges[species] &&
        HematologyReferenceRanges[species][index].start;
      const rangeEnding =
        HematologyReferenceRanges[species] &&
        HematologyReferenceRanges[species][index].end;
      const range = rangesForHematology(rangeBeginning, rangeEnding);

      resultText += `${
        ((rangeBeginning && r >= rangeBeginning) || !rangeBeginning) &&
        rangeEnding &&
        r <= rangeEnding
          ? "  "
          : "* "
      }${HematologyParams[index]} ${r.toString()} ${
        HematologyUnits[index]
      } (${range})\n`;
    });

    returnDiagnosticsText(resultText);
  };

  const biochemistryResultProcessor = (results: Array<number>) => {
    let resultText = `${strings.biochemistry  }:\n`;

    results.forEach((r: number, index: number) => {
      const rangeBeginning =
        BiochemistryReferenceRanges[species] &&
        BiochemistryReferenceRanges[species][index].start;
      const rangeEnding =
        BiochemistryReferenceRanges[species] &&
        BiochemistryReferenceRanges[species][index].end;
      const range = rangesForBioChemistry(rangeBeginning, rangeEnding);

      resultText += `${
        ((rangeBeginning && r >= rangeBeginning) || !rangeBeginning) &&
        rangeEnding &&
        r <= rangeEnding
          ? "  "
          : "* "
      }${BiochemistryParams[index]} ${r.toString()} ${
        BiochemistryUnits[index]
      } (${range})\n`;
    });

    returnDiagnosticsText(resultText);
  };

  return (
    <>
      <div className="col-span-12 lg:col-span-6">
        <Button
          variant="primary-outline"
          fullWidth
          onClick={openHematologyModal}
        >
          <div className="flex items-center justify-center">
            <AddButtonIcon />
            {strings.hematology}
          </div>
        </Button>
        <ExaminationModal
          open={hematologyModal}
          close={closeHematologyModal}
          species={species}
          returnResult={hematologyResultProcessor}
          parameters={HematologyParams}
          title={strings.hematology}
          type={ExaminationType.Hematology}
        />
      </div>
      <div className="col-span-12 lg:col-span-6">
        <Button
          variant="primary-outline"
          fullWidth
          onClick={openBiochemistryModal}
        >
          <div className="flex justify-center items-center">
            <AddButtonIcon />
            {strings.biochemistry}
          </div>
        </Button>
        <ExaminationModal
          open={biochemistryModal}
          close={closeBiochemistryModal}
          species={species}
          returnResult={biochemistryResultProcessor}
          parameters={BiochemistryParams}
          title={strings.hematology}
          type={ExaminationType.Biochemistry}
        />
      </div>
    </>
  );
};

export default Diagnostics;
