/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import moment from "moment";
import { ReservationResponse } from "../../../../models/reservation/ReservationResponse";
import { Shift } from "../../../../models/calendar/Shift";
import CalendarDayReservationModal from "../../Reservation/CalendarDayReservationModal";
import { isCancellable } from "../../../../util/helperFunctions";
import { ChevronDown } from "../../../../common/Icons/ChevronDown";
import { ChevronUp } from "../../../../common/Icons/ChevronUp";
import ShiftModal from "../ShiftModal";
import UserProfilePicture from "../../../../components/Pictures/User/UserProfilePicture";
import { Pencil } from "../../../../common/Icons/Pencil";
import ReservationStateIcons from "../../Reservation/ReservationStateIcons";
import { medicalRecordStatusTags } from "../../Day/CalendarListView";

interface Props {
  reload(): void;
  shift: Shift;
}

const ShiftListViewRow: React.FC<Props> = ({ reload, shift }: Props) => {
  const [selectedShiftData, setSelectedShiftData] = useState<Shift>();
  const [activeReservation, setActiveReservation] =
    useState<ReservationResponse>();
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);

  return (
    <div className="flex flex-row items-center">
      <div className="tw-card p-3" key={shift.id}>
        <div
          className="flex flex-row items-center cursor-pointer"
          onClick={() => setOpenSchedule((prev) => !prev)}
          role="button"
          tabIndex={0}
        >
          <div className="flex flex-row space-x-3">
            {openSchedule ? <ChevronUp /> : <ChevronDown />}
            <div>
              {moment(shift.startDateTime).format("LT")} -{" "}
              {moment(shift.endDateTime).format("LT")}
            </div>
            <div className="w-6 h-6 hidden md:block">
              <UserProfilePicture userId={shift.collaborator.userId} />
            </div>
            <div>{shift.collaborator.fullName}</div>
          </div>
          <div className="flex flex-row space-x-1 ml-auto">
            {shift.reservations?.map((reservation: ReservationResponse) => (
              <div
                className="rounded-full w-4 h-4 flex-shrink-0}"
                style={{
                  backgroundColor: reservation.reservationType?.color,
                }}
              />
            ))}
          </div>
        </div>
        <div
          className={shift.reservations?.length || -1 > 0 ? "pt-3" : ""}
          hidden={!openSchedule}
        >
          {shift.reservations?.map((reservation: ReservationResponse) => (
            <div
              className="flex flex-row items-center space-x-3 py-1 rounded hover:bg-gray-50 dark:hover:bg-gray-900"
              role="button"
              tabIndex={0}
              onClick={() => {
                setActiveReservation(reservation);
              }}
            >
              <div className="w-6" />
              <div
                className="rounded-full w-4 h-4 flex-shrink-0}"
                style={{
                  backgroundColor: reservation.reservationType?.color,
                }}
              />
              <div>
                {moment(reservation.startDateTime).format("LT")} -{" "}
                {moment(reservation.endDateTime).format("LT")}
              </div>
              <div className="flex flex-row items-center flex-wrap space-x-3">
                <div className="font-bold">{reservation.pet?.name}</div>
                <div
                  hidden={reservation.description?.length === 0}
                  className="break-words"
                >
                  {reservation.description}
                </div>
                <ReservationStateIcons 
                  notArrivedVisible={false} 
                  reservationState={reservation.state} 
                  size="w-6 h-6"
                />
                {medicalRecordStatusTags(reservation)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        type="button"
        className="tw-icon tw-link ml-3"
        onClick={() => {
          setSelectedShiftData(shift);
        }}
      >
        <span className="sr-only">Edit</span>
        <Pencil />
      </button>
      {activeReservation !== undefined ? (
        <CalendarDayReservationModal
          modalIsOpen={!!activeReservation}
          closeModal={() => {
            setActiveReservation(undefined);
          }}
          activeReservation={{
            ...activeReservation,
            cancellable: isCancellable(activeReservation),
          }}
          reload={() => {
            reload();
          }}
        />
      ) : (
        <></>
      )}
      {selectedShiftData ? (
        <ShiftModal
          selectedShiftData={selectedShiftData}
          onHide={() => {
            setSelectedShiftData(undefined);
          }}
          reload={reload}
          show={!!selectedShiftData}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ShiftListViewRow;
