import React from "react";
import { Microphone } from "../../../common/Icons/Microphone";
import { MicrophoneOff } from "../../../common/Icons/MicrophoneOff";
import Tooltip from "../../../components/Tooltip";
import { ExclamationMark } from "../../../common/Icons/ExclamationMark";
import { VideoCamera } from "../../../common/Icons/VideoCamera";
import { VideoCameraOff } from "../../../common/Icons/VideoCameraOff";
import { strings } from "../../../common/Strings/Strings";
import { CheckCircle } from "../../../common/Icons/CheckCircle";

interface Props {
  camEnabled: boolean;
  camMissing: boolean;
  camError: boolean;
  micEnabled: boolean;
  micMissing: boolean;
  micError: boolean;
}

const getMicrophoneErrorString = (micMissing: boolean, micError: boolean) => {
  if (micMissing) return strings.micMissing;
  if (micError) return strings.micError;
  return strings.micWorking;
};

const getCameraErrorString = (camMissing: boolean, camError: boolean) => {
  if (camMissing) return strings.camMissing;
  if (camError) return strings.camError;
  return strings.camWorking;
};

const MediaDevicesState: React.FC<Props> = ({
  camEnabled,
  camMissing,
  camError,
  micEnabled,
  micMissing,
  micError,
}: Props) => (
  <div className="flex space-x-6">
    <div className="flex">
      <Tooltip
        content={micEnabled ? strings.micEnabled : strings.micDisabled}
        placement="top"
      >
        {micEnabled ? (
          <Microphone variant="outline" />
        ) : (
          <MicrophoneOff variant="outline" />
        )}
      </Tooltip>
      <Tooltip
        content={getMicrophoneErrorString(micMissing, micError)}
        placement="top"
      >
        <>
          {micMissing ? (
            <ExclamationMark className="ml-1 text-orange-700 dark:text-orange-400" />
          ) : null}
          {micError ? (
            <ExclamationMark className="ml-1 text-red-700 dark:text-red-400" />
          ) : null}
          {!micMissing && !micError ? (
            <CheckCircle className="ml-1 text-green-700 dark:text-green-400" />
          ) : null}
        </>
      </Tooltip>
    </div>
    <div className="flex">
      <Tooltip
        content={camEnabled ? strings.camEnabled : strings.camDisabled}
        placement="top"
      >
        {camEnabled ? (
          <VideoCamera variant="outline" />
        ) : (
          <VideoCameraOff variant="outline" />
        )}
      </Tooltip>
      <Tooltip
        content={getCameraErrorString(camMissing, camError)}
        placement="top"
      >
        <>
          {camMissing ? (
            <ExclamationMark className="ml-2 text-orange-700 dark:text-orange-400" />
          ) : null}
          {camError ? (
            <ExclamationMark className="ml-2 text-red-700 dark:text-red-400" />
          ) : null}
          {!camMissing && !camError ? (
            <CheckCircle className="ml-2 text-green-700 dark:text-green-400" />
          ) : null}
        </>
      </Tooltip>
    </div>
  </div>
);

export default MediaDevicesState;
