/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { ClinicResponse } from "../models/clinic/ClinicResponse";
import { LocatedAddressRequest } from "../models/contact/LocatedAddressRequest";
import { EmailRequest } from "../models/contact/EmailRequest";
import { PhoneConfirmationRequest } from "../models/contact/PhoneConfirmationRequest";
import { PhoneRequest } from "../models/contact/PhoneRequest";
import { axiosCommon } from "../util/axiosSetup";
import { SendConfirmationEmailResponse } from "../models/user/SendConfirmationEmailResponse";

export default class ClinicContactsApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static addClinicEmail(
    clinicId: string,
    emailRequest: EmailRequest
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/emails`;
    return axiosCommon.post(url, emailRequest);
  }

  static removeClinicEmail(
    clinicId: string,
    emailId: string
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/emails/${emailId}`;
    return axiosCommon.delete(url);
  }

  static addClinicPhone(
    clinicId: string,
    phoneRequest: PhoneRequest
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/phones`;
    return axiosCommon.post(url, phoneRequest);
  }

  static confirmClinicPhoneInstantly(
    clinicId: string,
    phoneId: string,
    confirmationRequest: PhoneConfirmationRequest
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/phones/${phoneId}/confirm-instantly`;
    return axiosCommon.post(url, confirmationRequest);
  }

  static removeClinicPhone(
    clinicId: string,
    phoneId: string
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/phones/${phoneId}`;
    return axiosCommon.delete(url);
  }

  static addClinicAddress(
    clinicId: string,
    addressRequest: LocatedAddressRequest
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/addresses`;
    return axiosCommon.post(url, addressRequest);
  }

  static removeClinicAddress(
    clinicId: string,
    addressId: string
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/addresses/${addressId}`;
    return axiosCommon.delete(url);
  }

  static setPrimaryClinicEmail(
    clinicId: string,
    emailId: string
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/primary-email`;
    return axiosCommon.put(url, { emailId });
  }

  static setPrimaryClinicPhone(
    clinicId: string,
    phoneId: string
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/primary-phone`;
    return axiosCommon.put(url, { phoneId });
  }

  static setPrimaryClinicAddress(
    clinicId: string,
    addressId: string
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/primary-address`;
    return axiosCommon.put(url, { addressId });
  }

  static sendClinicEmailConfirmation(
    emailId: string
  ): ApiPromise<SendConfirmationEmailResponse> {
    const url = `${this.baseUrl}/clinic-emails/${emailId}/send-confirmation-mail`;
    return axiosCommon.post(url);
  }

  static sendClinicPhoneConfirmation(
    clinicId: string,
    phoneId: string
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/phones/${phoneId}/send-confirmation-sms`;
    return axiosCommon.post(url);
  }
}
