/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { strings } from "../../../common/Strings/Strings";
import { InvoiceStatus } from "../../../models/invoice/InvoiceStatus";
import { downloadInvoicePdf, printInvoicePdf } from "../../../util/FileUtils";
import { getGeneralError } from "../../../util/helperFunctions";
import logger from "../../../util/logger";
import AddPayment from "./AddPayment";
import WithdrawInvoice from "./WithdrawInvoice";
import InvoiceApi from "../../../api/InvoiceApi";
import Button from "../../../components/Button";
import { ArrowDownTray } from "../../../common/Icons/ArrowDownTray";
import { Printer } from "../../../common/Icons/Printer";
import { ArrowUnturnLeft } from "../../../common/Icons/ArrowUnturnLeft";
import Tooltip from "../../../components/Tooltip";
import { Cog } from "../../../common/Icons/Cog";

interface Props {
  invoice: InvoiceResponse;
  isDropdown?: boolean;
  isPairInvoice?: boolean;
  reload(): void;
  setError(message: string | null): void;
}

const InvoiceActions = ({
  invoice,
  isDropdown = true,
  isPairInvoice = false,
  reload,
  setError,
}: Props): ReactElement => {
  const [isPrinting, setPrinting] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] =
    useState<boolean>(false);
  const [showCancelWarningModal, setShowCancelWarningModal] =
    useState<boolean>(false);

  const deleteInvoice = async () => {
    if (!invoice) {
      return;
    }

    setIsDeleting(true);

    try {
      await InvoiceApi.deletePendingInvoice(invoice.id);
      reload();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      {isDropdown ? (
        <Dropdown className="rounded-dropdown">
          <Dropdown.Toggle
            as="div"
            bsPrefix="toggle-kebab"
            id="dropdown-pets-more"
          >
            <span className="material-icons">more_vert</span>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end" className="mt-1 p-0">
            <Dropdown.Item
              bsPrefix="calendar-menu dropdown-item hr-on-bottom"
              as="button"
              onClick={() => {
                setIsAddPaymentModalOpen(true);
              }}
              hidden={
                invoice?.costAccounting?.status !== "UNRESOLVED" ||
                invoice?.valid === false
              }
            >
              {strings.markAsPaid}
            </Dropdown.Item>
            <Dropdown.Item
              hidden={
                invoice.status === InvoiceStatus.IN_PROGRESS ||
                invoice.valid === false
              }
              bsPrefix="calendar-menu dropdown-item hr-on-bottom"
              onClick={async () => {
                try {
                  await downloadInvoicePdf(invoice);
                } catch (err) {
                  setError(await getGeneralError(err));
                }
              }}
            >
              {strings.downloadInvoicePDF}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={isPrinting}
              hidden={
                invoice.status === InvoiceStatus.IN_PROGRESS ||
                invoice.valid === false
              }
              bsPrefix="calendar-menu dropdown-item hr-on-bottom"
              onClick={async () => {
                setPrinting(true);
                try {
                  if (invoice) {
                    await printInvoicePdf(invoice, () => setPrinting(false));
                  }
                } catch (err) {
                  logger.error(err);
                  setError(await getGeneralError(err));
                  setPrinting(false);
                }
              }}
            >
              {strings.print}
            </Dropdown.Item>
            <Dropdown.Item
              bsPrefix="calendar-menu dropdown-item hr-on-bottom"
              as="button"
              onClick={() => {
                setShowCancelWarningModal(true);
              }}
              hidden={
                invoice?.valid === false ||
                invoice?.costAccounting?.status === "WITHDRAWN" ||
                invoice.status === InvoiceStatus.IN_PROGRESS ||
                (invoice?.costAccounting?.historyOfCostAccounting?.length ||
                  0) > 0
              }
            >
              {strings.withdrawInvoice}
            </Dropdown.Item>
            <Dropdown.Item
              bsPrefix="calendar-menu dropdown-item hr-on-bottom"
              href={`/invoices/edit/${invoice?.id}/copy`}
            >
              {strings.copyAsNew}
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              bsPrefix="calendar-menu dropdown-item hr-on-bottom"
              disabled={isDeleting}
              hidden={invoice.status !== InvoiceStatus.IN_PROGRESS}
              onClick={deleteInvoice}
            >
              {strings.withdrawReadyForPayment}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div className="flex items-center space-x-3">
          <Button
            hidden={
              invoice?.costAccounting?.status !== "UNRESOLVED" ||
              invoice?.valid === false
            }
            variant="primary"
            fullWidth={false}
            onClick={() => {
              setIsAddPaymentModalOpen(true);
            }}
          >
            {strings.markAsPaid}
          </Button>
          <div
            hidden={
              invoice?.valid === false ||
              invoice?.costAccounting?.status === "WITHDRAWN" ||
              invoice.status === InvoiceStatus.IN_PROGRESS ||
              (invoice?.costAccounting?.historyOfCostAccounting?.length || 0) >
                0
            }
          >
            <Tooltip content={strings.withdrawInvoice} placement="top">
              <Button
                className="tw-link"
                fullWidth={false}
                onClick={() => {
                  setShowCancelWarningModal(true);
                }}
                variant="icon"
              >
                <ArrowUnturnLeft className="w-5 h-5" />
              </Button>
            </Tooltip>
          </div>
          <div
            hidden={
              invoice.status === InvoiceStatus.IN_PROGRESS ||
              invoice.valid === false
            }
          >
            <Tooltip content={strings.downloadInvoicePDF} placement="top">
              <Button
                className="tw-link"
                onClick={async () => {
                  try {
                    await downloadInvoicePdf(invoice);
                  } catch (err) {
                    setError(await getGeneralError(err));
                  }
                }}
                variant="icon"
              >
                <ArrowDownTray />
              </Button>
            </Tooltip>
          </div>
          <div
            hidden={
              invoice.status === InvoiceStatus.IN_PROGRESS ||
              invoice.valid === false
            }
          >
            <Tooltip content={strings.print} placement="top">
              <Button
                className="tw-link"
                disabled={isPrinting}
                onClick={async () => {
                  setPrinting(true);
                  try {
                    if (invoice) {
                      await printInvoicePdf(invoice, () => setPrinting(false));
                    }
                  } catch (err) {
                    logger.error(err);
                    setError(await getGeneralError(err));
                    setPrinting(false);
                  }
                }}
                variant="icon"
              >
                <Printer />
              </Button>
            </Tooltip>
          </div>
          <div hidden={!isPairInvoice}>
            <Tooltip content={strings.invoiceDetails} placement="top">
              <Link to={`/invoices/clinic/${invoice?.id}`}>
                <Button className="tw-link" variant="icon">
                  <Cog />
                </Button>
              </Link>
            </Tooltip>
          </div>
        </div>
      )}
      <AddPayment
        close={() => {
          setIsAddPaymentModalOpen(false);
        }}
        invoice={invoice}
        isOpen={isAddPaymentModalOpen}
        reload={() => {
          setIsAddPaymentModalOpen(false);
          reload();
        }}
      />
      <WithdrawInvoice
        close={() => {
          setShowCancelWarningModal(false);
        }}
        invoice={invoice}
        isOpen={showCancelWarningModal}
        reload={() => {
          setShowCancelWarningModal(false);
          reload();
        }}
      />
    </div>
  );
};

export default InvoiceActions;
