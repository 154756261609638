/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";

interface Props {
  backgroundColor?: string;
  children?: any;
  height?: string;
  icon?: string;
  iconColor?: string;
  iconFontSize?: string;
  iconType?: string;
  margin?: string;
  width?: string;
}

const IconBubble = ({
  backgroundColor,
  children,
  height = "h-10",
  width = "w-10",
}: Props): ReactElement => {
  return (
    <div
      className={`relative rounded-full ${
        backgroundColor ? "" : "bg-sky-50 dark:bg-sky-800"
      } ${width} ${height} flex flex-shrink-0 justify-center items-center text-gray-900 dark:text-white`}
      style={{ backgroundColor }}
    >
      {children}
    </div>
  );
};

export default IconBubble;
