/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { InventoryItemUnit } from "../../../models/inventory/item/InventoryItemUnit";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { VaraItem } from "../../../models/inventory/item/VaraItem";
import { Option } from "../../../models/Option";
import { AccountingCodeResponse } from "../../../models/accounting/AccountingCodeResponse";
import { InventoryItemStockRequest } from "../../../models/inventory/item/Request/InventoryItemStockRequest";
import InventoryItemBasicStep from "./CreationSteps/InventoryItemBasicStep";
import InventoryItemSellingStep from "./CreationSteps/InventoryItemSellingStep";
import InventoryItemFirstStockStep from "./CreationSteps/InventoryItemFirstStockStep";

interface NewInventoryItemBasicPart {
  accountingCode: AccountingCodeResponse[] | null;
  acquisitionUnit: InventoryItemUnit;
  applicableToSpecificSpecies: boolean;
  barcode?: string;
  categoryId?: string;
  lowLevel?: number;
  name?: string;
  species: Option[];
  varaNplPackId?: string;
  varaNvn?: string;
}

interface NewInventoryItemSellingPart {
  profitMargin?: number;
  sellingPrice: number;
  sellingVat: number;
}

export type CreateInventoryItemForm = NewInventoryItemBasicPart &
  InventoryItemStockRequest &
  NewInventoryItemSellingPart;

type NewInventoryItemCreationStep = "basic" | "stock" | "selling";

interface Props {
  clinicId?: string;
  countryDetails?: CountryDetailsResponse;
  isEdit: boolean;
  selectedVaraPackage?: VaraItem;
  setIsStockStepSkipped(newValue: boolean): void;
  setStepCount(newValue: number): void;
  submit(): void;
  submitLoading: boolean;
  useVara(): void;
}

const InventoryItemForm: React.FC<Props> = ({
  clinicId,
  countryDetails,
  isEdit,
  selectedVaraPackage,
  setIsStockStepSkipped,
  setStepCount,
  submit,
  submitLoading,
  useVara,
}: Props) => {
  const [step, setStep] = useState<NewInventoryItemCreationStep>("basic");
  const [stockStepSkipped, setStockStepSkipped] = useState<boolean>(false);

  const next = (isSkipped?: boolean) => {
    switch (step) {
      case "basic":
        if(isEdit){
          setStep("selling");
        } else {
          setStep("stock");
        }
        setStepCount(2);
        break;
      case "stock":
        setStep("selling");
        setStepCount(3);
        break;
      case "selling":
        submit();
        break;
      default:
        break;
    }
    
    if(isSkipped !== undefined ){
      setIsStockStepSkipped(isSkipped);
      setStockStepSkipped(isSkipped);
    }
  };

  const prev = () => {
    switch (step) {
      case "stock":
        setStep("basic");
        setStepCount(1);
        break;
      case "selling":
        if(isEdit){
          setStep("basic");
          setStepCount(1);
        } else {
          setStep("stock");
          setStepCount(2);
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="space-y-6" hidden={step !== "basic"}>
        <InventoryItemBasicStep
          clinicId={clinicId}
          countryDetails={countryDetails}
          selectedVaraPackage={selectedVaraPackage}
          next={next}
          useVara={useVara}
        />
      </div>
      <div className="space-y-6" hidden={step !== "stock"}>
        <InventoryItemFirstStockStep countryDetails={countryDetails} isEdit={isEdit} next={next} prev={prev} />
      </div>
      <div className="space-y-6" hidden={step !== "selling"}>
        <InventoryItemSellingStep
          countryDetails={countryDetails}
          isEdit={isEdit}
          isStockStepSkipped={stockStepSkipped}
          prev={prev}
          submit={submit}
          submitLoading={submitLoading}
        />
      </div>
    </>
  );
};

export default InventoryItemForm;
