/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import Paging from "../../../../components/Paging";
import PageSizeSelect from "../../../../components/PageSizeSelect";
import { ReservationResponse } from "../../../../models/reservation/ReservationResponse";
import AppointmentListRow from "./AppointmentListRow";

interface Props {
  reservations: ReservationResponse[];
}

const AppointmentList = ({ reservations }: Props): ReactElement => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  return (
    <>
      <div className="tw-card divide-y dark:divide-gray-700">
        {reservations
          .slice(page * pageSize, (page + 1) * pageSize)
          .map((r: ReservationResponse) => (
            <div key={r.id}>
              <AppointmentListRow appointment={r} />
            </div>
          ))}
      </div>

      <div className="flex">
        <div className="flex align-items-center">
          <PageSizeSelect
            pageSize={pageSize}
            setPageSize={(newPageSize: number) => {
              setPageSize(newPageSize);
            }}
            totalPages={Math.ceil(reservations.length / pageSize)}
            totalResults={reservations.length}
          />
        </div>
        <div className="ml-auto">
          <Paging
            totalPages={Math.ceil(reservations.length / pageSize)}
            currentPage={page}
            selectPage={(newPageNumber: number) => {
              setPage(newPageNumber);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AppointmentList;
