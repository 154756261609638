/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { businessStructures, strings } from "../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { CreateClinicBasicInfoForm } from "../CreateClinic";
import isSwedishOrganizationNumberValid from "../../../util/RegistrationNumberValidator";
import { isSwedishPersonalIdValid } from "../../../util/Validations";
import { BusinessStructure } from "../../../models/clinic/BusinessStructure";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { CountryResponse } from "../../../models/management/CountryResponse";
import { PolicyType } from "../../../models/management/PolicyType";
import AlertBox from "../../../components/AlertBox";

interface Props {
  countries: CountryResponse[];
  onNext(): void;
}

const BasicInformationTab: React.FC<Props> = ({ countries, onNext }: Props) => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    watch,
  } = useFormContext<CreateClinicBasicInfoForm>();
  const [regNumberPlaceholder, setRegNumberPlaceholder] = useState<string>(
    strings.businessRegistrationNumber
  );

  const businessStructure = watch("businessStructure");

  const isRegistrationNumberValid = (regNumber: string): boolean => {
    const values = getValues();
    if (values.countryCode === "SE") {
      if (values.businessStructure === BusinessStructure.COMPANY) {
        return isSwedishOrganizationNumberValid(regNumber);
      }
      if (values.businessStructure === BusinessStructure.SOLE_PROPRIETORSHIP) {
        return isSwedishPersonalIdValid(regNumber);
      }
    }
    return true;
  };

  const changeRegNumberPlaceholder = (bs: string) => {
    const values = getValues();
    if (values.countryCode === "SE") {
      if (bs === BusinessStructure.COMPANY) {
        return setRegNumberPlaceholder("012345-6789");
      }
      if (bs === BusinessStructure.SOLE_PROPRIETORSHIP) {
        return setRegNumberPlaceholder(strings.personalIdFormatPlaceholder);
      }
    }
    return setRegNumberPlaceholder(strings.businessRegistrationNumber);
  };

  return (
    <div className="space-y-6">
      <Select
        autoComplete={AutoCompleteOptions.country}
        disabledChooseOptionTitle={strings.chooseCountry}
        error={errors.countryCode}
        isChooseOption
        label={strings.country}
        name="countryCode"
        onChange={(v) => {
          setValue("address.countryCode", v.target.value);
          changeRegNumberPlaceholder(businessStructure);
        }}
        options={countries
          ?.filter(
            (i) =>
              i.availablePolicies.includes(
                PolicyType.GENERAL_TERMS_AND_CONDITIONS
              ) &&
              i.availablePolicies.includes(
                PolicyType.DATA_PROCESSING_TERMS_AND_CONDITIONS
              )
          )
          ?.map((i) => ({
            title: i.displayName,
            value: i.countryCode,
          }))}
        register={register}
        required
        showRequired
      />
      <Select
        name="businessStructure"
        label={strings.businessStructure}
        register={register}
        options={Object.keys(BusinessStructure).map((type) => ({
          title: businessStructures[type],
          value: type,
        }))}
        error={errors.businessStructure}
        onChange={(e) => changeRegNumberPlaceholder(e.target.value)}
        isChooseOption
        required
        showRequired
      />
      <Field
        name="businessName"
        label={strings.businessName}
        register={register}
        autoComplete={AutoCompleteOptions.organization}
        error={errors.businessName}
        required
        showRequired
      />
      <Field
        name="registrationNumber"
        label={`${
          businessStructure === BusinessStructure.COMPANY
            ? strings.organisationId
            : strings.personalId
        }`}
        register={register}
        fieldOptions={{
          validate: {
            registrationNumberIsValid: (orgNumber) =>
              isRegistrationNumberValid(orgNumber) ||
              strings.invalidRegistrationNumber,
          },
        }}
        error={errors.registrationNumber}
        required
        showRequired
      />
      <Field
        name="vat"
        label={strings.vatNumber}
        register={register}
        error={errors.vat}
        required
        showRequired
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.name}
        fieldOptions={{
          maxLength: {
            value: 255,
            message: strings.formatString(strings.maximumCharacters, 255),
          },
          minLength: {
            value: 3,
            message: strings.formatString(strings.minimumCharacters, 3),
          },
        }}
        label={strings.clinicName}
        name="name"
        register={register}
        required
        showRequired
      />
      <AlertBox
        hidden={
          errors.name?.type !== "required" &&
          errors.businessName?.type !== "required" &&
          errors.countryCode?.type !== "required" &&
          errors.registrationNumber?.type !== "required" &&
          errors.vat?.type !== "required"
        }
        message={strings.pleaseFillRequiredFields}
      />
      <Button
        onClick={() => {
          const response = trigger([
            "name",
            "businessName",
            "businessStructure",
            "countryCode",
            "registrationNumber",
            "vat",
          ]);

          response.then((isValid: boolean) => {
            if (isValid) {
              onNext();
            }
          });
        }}
      >
        {strings.saveAndContinue}
      </Button>
    </div>
  );
};

export default BasicInformationTab;
