/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import PharmacyApi from "../../../../api/PharmacyApi";
import { Pharmacy } from "../../../../models/pharmacy/Pharmacy";
import { City } from "../../../../models/pharmacy/City";
import { Municipality } from "../../../../models/pharmacy/Municipality";
import { County } from "../../../../models/pharmacy/County";
import MapModal from "../../modals/MapModal";
import logger from "../../../../util/logger";
import { GeoPharmacy } from "../../../../models/pharmacy/GeoPharmacy";
import { Colors } from "../../../../models/Colors";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import Params from "../../../../common/Params";
import { getGeneralError } from "../../../../util/helperFunctions";
import CombinedSelectComponent from "../../../../components/InputFieldsSimple/CombinedSelectComponent";
import { MapPin } from "../../../../common/Icons/MapPin";
import SelectedPharmacyDetails from "./SelectedPharmacyDetails";
import Button from "../../../../components/Button";

interface Props {
  selectPharmacy(p: Pharmacy | undefined): void;
  geoPharmacies: GeoPharmacy[];
  visibleDetails?: boolean;
}

const PharmacyForm: React.FC<Props> = ({
  selectPharmacy,
  geoPharmacies,
  visibleDetails = true,
}: Props) => {
  const [cities, setCities] = useState<City[]>([]);
  const [filteredCities, setFilteredCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState<City[]>();

  const [municipalities, setMunicipalities] = useState<Municipality[]>([]);
  const [filteredMunicipalities, setFilteredMunicipalities] = useState<
    Municipality[]
  >([]);
  const [selectedMunicipality, setSelectedMunicipality] =
    useState<Municipality[]>();

  const [counties, setCounties] = useState<County[]>([]);
  const [selectedCounty, setSelectedCounty] = useState<County[]>();

  const [pharmacies, setPharmacies] = useState<GeoPharmacy[]>([]);
  const [filteredPharmacies, setFilteredPharmacies] = useState<Pharmacy[]>([]);
  const [selectedPharmacy, setSelectedPharmacy] = useState<Pharmacy[]>();

  const [mapModal, setMapModal] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<string[]>(["", "", ""]);
  const [filter, setFilter] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const getPharmacies = async () => {
      try {
        const response = await PharmacyApi.getPharmacies();
        if (isMounted) {
          setPharmacies(response.data);
          setFilteredPharmacies(response.data);
        }
      } catch (e) {
        if (isMounted) {
          setError(await getGeneralError(e));
        }
        logger.error(e);
      }
    };

    if (geoPharmacies.length === 0) {
      void getPharmacies();
    }

    return () => {
      isMounted = false;
    };
  }, [geoPharmacies]);

  useEffect(() => {
    setPharmacies(geoPharmacies);
    setFilteredPharmacies(geoPharmacies);
  }, [geoPharmacies]);

  useEffect(() => {
    let isMounted = true;

    const loadCities = async () => {
      try {
        const result = await PharmacyApi.getCities();
        if (isMounted) {
          setCities(result.data);
          setFilteredCities(result.data);
        }
      } catch (e) {
        if (isMounted) {
          setError(await getGeneralError(e));
        }
        logger.error(e);
      }
    };

    const loadMunicipalities = async () => {
      try {
        const result = await PharmacyApi.getMunicipalities();
        if (isMounted) {
          setMunicipalities(result.data);
          setFilteredMunicipalities(result.data);
        }
      } catch (e) {
        if (isMounted) {
          setError(await getGeneralError(e));
        }
        logger.error(e);
      }
    };

    const loadCounties = async () => {
      try {
        const result = await PharmacyApi.getCounties();
        if (isMounted) {
          setCounties(result.data);
        }
      } catch (e) {
        if (isMounted) {
          setError(await getGeneralError(e));
        }
        logger.error(e);
      }
    };

    void loadCities();
    void loadMunicipalities();
    void loadCounties();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    /* County filters municipalities, cities, and pharmacies. */
    const filterByCounty = () => {
      if (filterParams[0] !== "" && selectedCounty && selectedCounty[0]) {
        setFilteredMunicipalities(
          municipalities.filter(
            (m: Municipality) => m.countyId === selectedCounty[0].id
          )
        );
        setFilteredCities(
          cities.filter((c: City) => c.county === selectedCounty[0].id)
        );
        setFilteredPharmacies(
          pharmacies.filter(
            (p: Pharmacy) => p.countyName === selectedCounty[0].name
          )
        );
      } else {
        setFilteredMunicipalities(municipalities);
        setFilteredCities(cities);
        setFilteredPharmacies(pharmacies);
      }
    };

    /* Municipality filters cities and pharmacies. */
    const filterByMunicipality = () => {
      if (
        filterParams[1] !== "" &&
        selectedMunicipality &&
        selectedMunicipality[0]
      ) {
        setFilteredCities(
          cities.filter(
            (c: City) =>
              c.municipality === selectedMunicipality[0].municipalityId
          )
        );
        setFilteredPharmacies(
          pharmacies.filter(
            (p: Pharmacy) => p.municipalityName === selectedMunicipality[0].name
          )
        );
      } else {
        filterByCounty();
      }
    };

    /* City filters pharmacies. */
    const filterByCity = () => {
      logger.debug(filterParams[1]);
      if (filterParams[2] !== "" && selectedCity && selectedCity[0]) {
        setFilteredPharmacies(
          pharmacies.filter((p: Pharmacy) => p.city === selectedCity[0].city)
        );
      } else {
        filterByMunicipality();
      }
    };

    /* Filter typeahead lists based on filter parameters */
    filterByCounty();
    filterByMunicipality();
    filterByCity();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (selectedCounty && selectedCounty[0]) {
      const county = selectedCounty[0].name;

      setFilterParams((prevState) => {
        prevState[0] = county;
        return prevState;
      });
    } else {
      setFilterParams((prevState) => {
        prevState[0] = "";
        return prevState;
      });
    }
    setFilter(!filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCounty]);

  useEffect(() => {
    if (selectedMunicipality && selectedMunicipality[0]) {
      const municipality = selectedMunicipality[0].name;

      setFilterParams((prevState) => {
        prevState[1] = municipality;
        return prevState;
      });
    } else {
      setFilterParams((prevState) => {
        prevState[1] = "";
        return prevState;
      });
    }
    setFilter(!filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMunicipality]);

  useEffect(() => {
    if (selectedCity && selectedCity[0]) {
      const { city } = selectedCity[0];

      setFilterParams((prevState) => {
        prevState[2] = city;
        return prevState;
      });
    } else {
      setFilterParams((prevState) => {
        prevState[2] = "";
        return prevState;
      });
    }
    setFilter(!filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity]);

  const closeMapModal = () => {
    setMapModal(false);
  };

  useEffect(() => {
    if (selectedPharmacy && selectedPharmacy[0]) {
      selectPharmacy(selectedPharmacy[0]);
    } else {
      selectPharmacy(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPharmacy]);

  const selectPharmacyFromMap = (p: Pharmacy) => {
    setSelectedPharmacy([p]);
    setSelectedCounty(undefined);
    setSelectedMunicipality(undefined);
    setSelectedCity(undefined);
  };

  return (
    <>
      {Params.prescriptionTestEnvironment === "on" ? (
        <AlertBox type={AlertType.INFO} message={strings.testPharmacyInfo} />
      ) : (
        <></>
      )}
      <AlertBox message={error} />
      <div className="space-y-4">
        <div>
          <div className="flex items-end">
            <label className="mb-0">
              {strings.county}{" "}
              <span style={{ color: Colors.INPUTBORDER }}>
                ({strings.optional})
              </span>
            </label>
            <Button
              className="tw-link ml-auto"
              disabled={geoPharmacies.length === 0}
              onClick={() => {
                setMapModal(true);
              }}
              variant="link"
            >
              <MapPin className="text-sky-500" />
            </Button>
          </div>
          <CombinedSelectComponent
            allowNew={false}
            labelKey="name"
            name="countySearch"
            onChange={(c: County[] | null) => {
              setSelectedCounty(c || undefined);
              setSelectedPharmacy(undefined);
              setSelectedMunicipality(undefined);
              setSelectedCity(undefined);
            }}
            options={counties}
            placeholder={strings.search}
            value={selectedCounty || null}
          />
        </div>
        <CombinedSelectComponent
          allowNew={false}
          label={strings.municipality}
          labelKey="name"
          name="municipality"
          onChange={(m: Municipality[] | null) => {
            setSelectedMunicipality(m || undefined);
            setSelectedPharmacy(undefined);
            setSelectedCity(undefined);
          }}
          optional
          options={filteredMunicipalities}
          placeholder={strings.search}
          value={selectedMunicipality || null}
        />
        <CombinedSelectComponent
          allowNew={false}
          label={strings.city}
          labelKey="city"
          name="citySearch"
          onChange={(c: City[] | null) => {
            setSelectedCity(c || undefined);
            setSelectedPharmacy(undefined);
          }}
          optional
          options={filteredCities}
          placeholder={strings.search}
          value={selectedCity || null}
        />
        <CombinedSelectComponent
          allowNew={false}
          label={strings.pharmacy}
          labelKey={(option: Pharmacy | any) => {
            if (option.shortName) {
              return option.shortName;
            }
            if (option.longName) {
              return option.longName;
            }
            return option.glnCode;
          }}
          name="pharmacySearch"
          onChange={(p: Pharmacy[] | null) => {
            setSelectedPharmacy(p || undefined);
          }}
          options={filteredPharmacies}
          placeholder={strings.pharmacy}
          value={selectedPharmacy || null}
        />
        {selectedPharmacy?.length === 1 && visibleDetails ? (
          <SelectedPharmacyDetails pharmacy={selectedPharmacy[0]} />
        ) : (
          <></>
        )}
        <MapModal
          isOpen={mapModal}
          close={closeMapModal}
          geoPharmacies={pharmacies}
          returnPharmacy={selectPharmacyFromMap}
        />
      </div>
    </>
  );
};

export default PharmacyForm;

PharmacyForm.defaultProps = {
  visibleDetails: undefined,
};
