/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { InvoiceResponse } from "../../../../models/invoice/InvoiceResponse";
import { strings } from "../../../../common/Strings/Strings";
import { InvoiceStatus } from "../../../../models/invoice/InvoiceStatus";
import { downloadInvoicePdf, printInvoicePdf } from "../../../../util/FileUtils";
import { getGeneralError } from "../../../../util/helperFunctions";
import logger from "../../../../util/logger";
import InvoicePaymentModal from "../../../Accounting/InvoicePaymentModal";
import Tooltip from "../../../../components/Tooltip";
import { ArrowDownTray } from "../../../../common/Icons/ArrowDownTray";
import { Printer } from "../../../../common/Icons/Printer";
import Button from "../../../../components/Button";
import { Cog } from "../../../../common/Icons/Cog";

interface Props {
  invoice: InvoiceResponse;
  reload?(): void;
  showDetails?: boolean;
  showPayButton?: boolean;
  setError(message: string | null): void;
  setInvoice?(invoice: InvoiceResponse): void;
}

const CustomerInvoiceActions = ({
  invoice,
  reload,
  showDetails = false,
  showPayButton = true,
  setError,
  setInvoice,
}: Props): ReactElement => {
  const [isPrinting, setPrinting] = useState<boolean>(false);
  const [showPayWithCardModal, setShowPayWithCardModal] =
    useState<boolean>(false);

  const openPayWithCardModal = () => {
    setShowPayWithCardModal(true);
  };
  const closePayWithCardModal = () => {
    setShowPayWithCardModal(false);
  };

  return (
    <div className="ml-auto flex items-center space-x-3">
      <div>
        <Button
          hidden={
            !invoice?.stripeApiKey ||
            invoice?.costAccounting?.status !== "UNRESOLVED" ||
            !showPayButton
          }
          onClick={openPayWithCardModal}
          variant="primary"
        >
          {strings.pay}
        </Button>
      </div>
      <div
        hidden={
          invoice.status === InvoiceStatus.IN_PROGRESS ||
          invoice.valid === false
        }
      >
        <Tooltip content={strings.downloadInvoicePDF} placement="top">
          <Button
            className="tw-link"
            onClick={async () => {
              try {
                await downloadInvoicePdf(invoice);
              } catch (err) {
                setError(await getGeneralError(err));
              }
            }}
            variant="icon"
          >
            <ArrowDownTray />
          </Button>
        </Tooltip>
      </div>
      <div
        hidden={
          invoice.status === InvoiceStatus.IN_PROGRESS ||
          invoice.valid === false
        }
      >
        <Tooltip content={strings.print} placement="top">
          <Button
            className="tw-link"
            disabled={isPrinting}
            onClick={async () => {
              setPrinting(true);
              try {
                if (invoice) {
                  await printInvoicePdf(invoice, () => setPrinting(false));
                }
              } catch (err) {
                logger.error(err);
                setError(await getGeneralError(err));
                setPrinting(false);
              }
            }}
            variant="icon"
          >
            <Printer />
          </Button>
        </Tooltip>
      </div>
      <div hidden={!showDetails}>
        <Tooltip content={strings.invoiceDetails} placement="top">
          <Link hidden={!showDetails} to={`/invoices/${invoice?.longId}`}>
            <Button className="tw-link" disabled={isPrinting} variant="icon">
              <Cog />
            </Button>
          </Link>
        </Tooltip>
      </div>
      {invoice?.stripeApiKey && (
        <InvoicePaymentModal
          isOpen={showPayWithCardModal}
          close={closePayWithCardModal}
          onInvoiceUpdated={(updatedInvoice) => {
            if (setInvoice) {
              setInvoice(updatedInvoice);
            } else if (reload) {
              reload();
            }
          }}
          invoice={invoice}
          stripeApiKey={invoice?.stripeApiKey}
        />
      )}
    </div>
  );
};

export default CustomerInvoiceActions;
