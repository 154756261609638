/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { strings } from "../../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { PrevStateSetter } from "./SzamlazzIntegration";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import szamlazzLogo from "../../../../../assets/Images/szamlazz_logo.png";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal/Modal";
import { SzamlazzIntegrationRequest } from "../../../../../models/clinic/SzamlazzIntegrationRequest";
import SzamlazzApi from "../../../../../api/SzamlazzApi";
import { QuestionMarkCircle } from "../../../../../common/Icons/QuestionMarkCircle";
import { SupportTopics } from "../../../../Support/Topics";

interface Props {
  clinic: ClinicResponse;
  setClinic: (setter: PrevStateSetter) => void;
  show: boolean;
  onHide: () => void;
}

const defaultFormValues = {
  apiKey: "",
};

const SzamlazzIntegrationModal: React.FC<Props> = ({
  clinic,
  setClinic,
  show,
  onHide,
}: Props) => {
  const [addIntegrationLoading, setAddIntegrationLoading] =
    useState<boolean>(false);
  const [deleteIntegrationLoading, setDeleteIntegrationLoading] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.ERROR);

  const addIntegration = async (request: SzamlazzIntegrationRequest) => {
    setAddIntegrationLoading(true);

    try {
      await SzamlazzApi.addIntegration(clinic.id, request);
      setAlertMessage(strings.savedSuccessfully);
      setAlertType(AlertType.SUCCESS);
      setClinic((prevState) => {
        const newState = prevState;
        newState.szamlazzEnabled = true;
        return newState;
      });
    } catch (error) {
      setAlertMessage(await getGeneralError(error));
      setAlertType(AlertType.ERROR);
    } finally {
      setAddIntegrationLoading(false);
    }
  };

  const deleteIntegration = async () => {
    setDeleteIntegrationLoading(true);

    try {
      await SzamlazzApi.deleteIntegration(clinic.id);
      setAlertMessage(strings.savedSuccessfully);
      setAlertType(AlertType.SUCCESS);
      setClinic((prevState) => {
        const newState = prevState;
        newState.szamlazzEnabled = false;
        return newState;
      });
    } catch (error) {
      setAlertMessage(await getGeneralError(error));
      setAlertType(AlertType.ERROR);
    } finally {
      setDeleteIntegrationLoading(false);
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SzamlazzIntegrationRequest>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    const resetFormAndErrors = () => {
      reset(defaultFormValues);
      setAlertMessage(null);
    };

    if (show) {
      resetFormAndErrors();
    }
  }, [reset, show]);

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.szamlazzIntegration} />
      <Modal.Body>
        <Link target="_blank" to="https://www.szamlazz.hu/szamla/main">
          <img width="120px" alt="szamlazzLogo" src={szamlazzLogo} />
        </Link>
        <p>
          {strings.formatString(
            strings.szamlazzIntegrationInfo1,
            <Link
              target="_blank"
              to="https://www.szamlazz.hu/egyedi-megoldasok/szamla-agent/"
            >
              {" "}
              {strings.here}
            </Link>
          )}
        </p>
        <AlertBox
          closeAble={false}
          type={AlertType.INFO}
          message={
            <>
              {strings.szamlazzIntegrationCostWarning}
              <div>
                <Link
                  target="_blank"
                  to="https://tudastar.szamlazz.hu/gyik/szamla-agent-automatikus-szamlazas-koltsege"
                >
                  {strings.costsOfSzamlazz}
                </Link>
              </div>
            </>
          }
        />
        <p>{strings.szamlazzIntegrationInfo2}</p>
        <form
          className="space-y-6"
          onSubmit={handleSubmit(addIntegration)}
          id="addIntegrationForm"
        >
          <Field
            name="apiKey"
            label={strings.szamlazzApiKey}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            required
            error={errors.apiKey}
          />
        </form>
        <div className="flex w-full">
          <Link
            to={`/help/topics/${SupportTopics.szamlazz}`}
            target="_blank"
            className="text-gray-600 hover:text-sky-500 dark:text-gray-300 dark:hover:text-gray-200 transition-all duration-300 flex space-x-1"
          >
            <QuestionMarkCircle className="w-6 h-6 flex-shrink-0" />
            <span>{strings.moreInformation}</span>
          </Link>
        </div>
        <AlertBox message={alertMessage} type={alertType} />
      </Modal.Body>
      <Modal.Footer>
        <div hidden={!clinic.szamlazzEnabled}>
          <Button
            className="modal-sub-button"
            disabled={!clinic.szamlazzEnabled}
            loading={deleteIntegrationLoading}
            onClick={deleteIntegration}
            variant="danger"
          >
            {strings.turnOff}
          </Button>
        </div>
        <Button
          className="modal-main-button ml-auto"
          disabled={!isValid || addIntegrationLoading}
          form="addIntegrationForm"
          loading={addIntegrationLoading}
          type="submit"
        >
          {strings.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SzamlazzIntegrationModal;
