/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { getAddressString } from "../../../util/helperFunctions";
import {
  getStringFromEnumsOrReturn,
  policyType,
  strings,
} from "../../../common/Strings/Strings";
import { Card } from "../../../components/Cards/Card";
import UnconfirmedEmailTag from "../../Clinic/Settings/Contacts/Emails/UnconfirmedEmailTag";
import { ContactType } from "../../../models/contact/ContactType";
import UnconfirmedPhoneTag from "../../Clinic/Settings/Contacts/Phones/UnconfirmedPhoneTag";
import { UserDetailsResponse } from "../../../models/management/UserDetailsResponse";
import { ListGroup } from "react-bootstrap";
import VetProfessionDocListItem from "../VetDocumentsPage/VetProfessionDocListItem";
import { Link } from "react-router-dom";
import ClinicLogo from "../../../components/Pictures/Clinic/ClinicLogo";
import PetProfilePicture from "../../../components/Pictures/Pet/PetProfilePicture";

interface Props {
  user: UserDetailsResponse;
}

const UserBasicInfoTab: React.FC<Props> = (props: Props) => {
  const { user } = props;

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <Card size="max" title={strings.basicInfo} type="simple">
            <div>
              <div hidden={!user.prefix}>
                <div>{strings.prefix}</div>
                <div className="bold">{user.prefix}</div>
              </div>
              <div className="mt-3" hidden={!user.firstName}>
                <div>{strings.firstName}</div>
                <div className="bold">{user.firstName}</div>
              </div>
              <div className="mt-3" hidden={!user.middleName}>
                <div>{strings.middleName}</div>
                <div className="bold">{user.middleName}</div>
              </div>
              <div className="mt-3" hidden={!user.lastName}>
                <div>{strings.lastName}</div>
                <div className="bold">{user.lastName}</div>
              </div>
              <div className="mt-3" hidden={!user.personalId}>
                <div>{strings.personalId}</div>
                <div className="bold">{user.personalId}</div>
              </div>
              <div className="mt-3" hidden={!user.locale}>
                <div>{strings.language}</div>
                <div className="bold">{user.locale}</div>
              </div>
              <div className="mt-3" hidden={!user.profilePicture}>
                <div>{strings.profilePicture}</div>
                <div className="bold">{user.profilePicture}</div>
              </div>
              <div className="mt-3" hidden={!user.dateOfBirth}>
                <div>{strings.dateOfBirth}</div>
                <div className="bold">{user.dateOfBirth}</div>
              </div>
              <div className="mt-3" hidden={!user.facebookId}>
                <div>{strings.facebookId}</div>
                <div className="bold">{user.facebookId}</div>
              </div>
              <div className="mt-3" hidden={!user?.facebookEmail}>
                <div>{strings.facebookEmail}</div>
                <div className="bold">{user.facebookEmail}</div>
              </div>
              <div className="mt-3" hidden={!user.googleId}>
                <div>{strings.googleId}</div>
                <div className="bold">{user.googleId}</div>
              </div>
              <div className="mt-3" hidden={!user?.googleEmail}>
                <div>{strings.googleEmail}</div>
                <div className="bold">{user.googleEmail}</div>
              </div>
              <div
                className="mt-3"
                hidden={!user?.vetRegistrationNumbers?.length}
              >
                <div>{strings.vetRegistrationNumbersTitle}</div>
                {user?.vetRegistrationNumbers?.map((vetNumber) => (
                  <div className="bold">
                    {vetNumber.registrationNumber} ({vetNumber.countryCode})
                  </div>
                ))}
              </div>
              <div className="mt-3" hidden={!user?.hasPinCode}>
                <div>{strings.pin}</div>
                <div className="bold">****</div>
              </div>
              <div className="mt-3" hidden={!user?.receiveSMSNotifications}>
                <div>{strings.receiveSMSNotifications}</div>
                <div className="bold">{user.receiveSMSNotifications}</div>
              </div>
            </div>
          </Card>
          <div
            className="mt-5"
            hidden={!user.vetProfession?.proofDocuments?.length}
          >
            <Card size="max" title={strings.documents} type="simple">
              <div>
                <ListGroup className="my-5">
                  {user?.vetProfession?.proofDocuments?.map((document) => (
                    <VetProfessionDocListItem
                      document={document}
                      onError={() => {}}
                    />
                  ))}
                </ListGroup>
              </div>
            </Card>
          </div>
          <div className="mt-5" hidden={!user?.acceptedPolicies?.length}>
            <Card size="max" title={strings.policies} type="simple">
              <div>
                {user.acceptedPolicies.map((policy) => (
                  <div className="mt-3">
                    <div>
                      <div className="bold">{policyType[policy.type]}</div>
                      <div className="mt-1">
                        {strings.version}: {policy.version} (
                        {policy.languageCode}-{policy.countryCode})
                      </div>
                      <div className="mt-1">
                        {strings.ipAddress}: {policy.ipAddress}
                      </div>
                      <div className="mt-1">
                        {strings.userAgent}: {policy.userAgent}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <Card size="max" title={strings.contacts} type="simple">
            <div>
              <div>
                <div>{strings.phones}</div>
                {user?.phones?.map((phone) => (
                  <div className="flex flex-wrap items-center mb-2">
                    <div className="bold">{phone.value}</div>
                    <UnconfirmedPhoneTag phone={phone} onClick={() => {}} />
                    <button
                      className={`tw-btn-pill-tercier-small ${
                        phone.confirmed ? "mx-3" : ""
                      }`}
                      disabled
                      hidden={phone.contactType !== ContactType.PRIMARY}
                      type="button"
                    >
                      {strings.primary}
                    </button>
                  </div>
                ))}
              </div>
              <div className="mt-3">
                <div>{strings.emails}</div>
                {user?.emails?.map((email) => (
                  <div className="flex flex-wrap items-center mb-2">
                    <div className="bold">{email.value}</div>
                    <UnconfirmedEmailTag email={email} onClick={() => {}} />
                    <button
                      className={`tw-btn-pill-tercier-small ${
                        email.confirmed ? "mx-3" : ""
                      }`}
                      disabled
                      hidden={email.contactType !== ContactType.PRIMARY}
                      type="button"
                    >
                      {strings.primary}
                    </button>
                  </div>
                ))}
              </div>
              <div className="mt-3">
                <div>{strings.addresses}</div>
                {user?.addresses?.map((address) => (
                  <div className="flex flex-wrap items-center mb-2">
                    <div className="bold mr-3">{getAddressString(address)}</div>
                    <button
                      className="tw-btn-pill-tercier-small"
                      disabled
                      hidden={address.contactType !== ContactType.PRIMARY}
                      type="button"
                    >
                      {strings.primary}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </Card>
          <div className="mt-5" hidden={!user.ownedClinics.length}>
            <Card size="max" title={strings.ownedClinics} type="simple">
              <div className="tw-table-container">
                <table className="tw-table">
                  <tbody className="tw-tbody">
                    {user.ownedClinics.map((clinic) => (
                      <tr key={clinic.id}>
                        <td className="px-4 py-4 text-sm">
                          <div className="flex items-center space-x-3">
                            <div className="w-10 h-10">
                              <ClinicLogo
                                border
                                borderWidth="2px"
                                clinicId={clinic?.id}
                                logoPictureId={clinic?.logoPictureId}
                              />
                            </div>
                            <div>
                              <Link to={`/management/clinics/${clinic.id}`}>
                                {clinic.name}
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
          <div className="mt-5" hidden={!user.employments.length}>
            <Card size="max" title={strings.workplaces} type="simple">
              <div className="tw-table-container">
                <table className="tw-table">
                  <tbody className="tw-tbody">
                    {user.employments.map((employment) => (
                      <tr key={employment.clinic.id}>
                        <td className="px-4 py-4 text-sm">
                          <div className="flex items-center space-x-3">
                            <div className="w-10 h-10">
                              <ClinicLogo
                                border
                                borderWidth="2px"
                                clinicId={employment.clinic?.id}
                                logoPictureId={employment.clinic?.logoPictureId}
                              />
                            </div>
                            <div>
                              <Link
                                to={`/management/clinics/${employment.clinic.id}`}
                              >
                                {employment.clinic.name}
                              </Link>
                              <div>
                                {employment.roles
                                  .map(getStringFromEnumsOrReturn)
                                  .join(", ")}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
          <div className="mt-5" hidden={!user.pets.length}>
            <Card size="max" title={strings.pets} type="simple">
              <div className="tw-table-container">
                <table className="tw-table">
                  <tbody className="tw-tbody">
                    {user.pets.map((pet) => (
                      <tr key={pet.userPetDetails.id}>
                        <td className="px-4 py-4 text-sm">
                          <div className="flex items-center space-x-3">
                            <div className="w-10 h-10">
                              <PetProfilePicture
                                border
                                borderWidth="2px"
                                petId={pet.userPetDetails.id}
                                profilePictureId={
                                  pet.userPetDetails.profilePicture
                                }
                              />
                            </div>
                            <div>
                              <strong>{pet.userPetDetails.name}</strong>
                              <div>
                                {pet.clinicPetDetails.length}{" "}
                                {strings.clinicsVisited}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBasicInfoTab;
