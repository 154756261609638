/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useUser } from "../../../../contexts/UserContext";
import UserEmailOwnershipList from "../../../../components/contact/user/email/UserEmailOwnershipList";
import UserPhoneOwnershipList from "../../../../components/contact/user/phone/UserPhoneOwnershipList";
import UserAddressOwnershipList from "../../../../components/contact/user/address/UserAddressOwnershipList";
import { strings } from "../../../../common/Strings/Strings";
import { Card } from "../../../../components/Cards/Card";

const ContactsTab: React.FC = () => {
  const { user } = useUser();

  return (
    <Card size="lg" title={strings.contacts} type="simple">
      <p className="mt-5">{strings.profileContactToolTip}</p>
      <UserEmailOwnershipList owner={user} />
      <UserPhoneOwnershipList owner={user} />
      <UserAddressOwnershipList owner={user} />
    </Card>
  );
};

export default ContactsTab;
