/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export enum AutoCompleteOptions {
  on = "on",
  off = "off",
  name = "name",
  honorificPrefix = "honorific-prefix",
  givenName = "given-name",
  additionalName = "additional-name",
  familyName = "family-name",
  honorificSuffix = "honorific-suffix",
  nickname = "nickname",
  email = "email",
  username = "username",
  newPassword = "new-password",
  currentPassword = "current-password",
  oneTimeCode = "one-time-code",
  organizationTitle = "organization-title",
  organization = "organization",
  streetAddress = "street-address",
  addressLine1 = "address-line1",
  addressLine2 = "address-line2",
  addressLine3 = "address-line3",
  addressLevel4 = "address-level4",
  addressLevel3 = "address-level3",
  addressLevel2 = "address-level2",
  addressLevel1 = "address-level1",
  country = "country",
  countryName = "country-name",
  postalCode = "postal-code",
  ccName = "cc-name",
  ccGivenName = "cc-given-name",
  ccAdditionalName = "cc-additional-name",
  ccFamilyName = "cc-family-name",
  ccNumber = "cc-number",
  ccExp = "cc-exp",
  ccExpMonth = "cc-exp-month",
  ccExpYear = "cc-exp-year",
  ccCsc = "cc-csc",
  ccType = "cc-type",
  transactionCurrency = "transaction-currency",
  transactionAmount = "transaction-amount",
  language = "language",
  bday = "bday",
  bdayDay = "bday-day",
  bdayMonth = "bday-month",
  bdayYear = "bday-year",
  sex = "sex",
  tel = "tel",
  telCountryCode = "tel-country-code",
  telNational = "tel-national",
  telAreaCode = "tel-area-code",
  telLocal = "tel-local",
  telExtension = "tel-extension",
  impp = "impp",
  url = "url",
  photo = "photo",
}
