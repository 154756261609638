/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import ClinicApi from "../../../../api/ClinicApi";
import logger from "../../../../util/logger";
import { getClinicUpdateDto } from "../../../Clinic/Settings/ClinicSettings";
import Button from "../../../../components/Button";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import AlertBox from "../../../../components/AlertBox";
import { getGeneralError } from "../../../../util/helperFunctions";
import { Pencil } from "../../../../common/Icons/Pencil";
import { CheckCircle } from "../../../../common/Icons/CheckCircle";
import RadioButtonGroup from "../../../../components/ReactHookFormFields/General/RadioButton/RadioButtonGroup";
import Modal from "../../../../components/Modal/Modal";
import { Feature } from "../../../../models/clinic/Feature";

interface Props {
  clinic?: ClinicResponse;
  refresh: () => void;
}

const PrescriptionOnlyModeSection: React.FC<Props> = ({
  clinic,
  refresh,
}: Props) => {
  const [switchModalShown, setSwitchModalShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const { control, setValue, watch } = useForm<{
    usageMode: "full" | "prescriptionOnly";
  }>();
  const [isFullModeUsed, setIsFullModeUsed] = useState<
    "full" | "prescriptionOnly"
  >("full");
  const [error, setError] = useState<string | null>(null);

  const changeModeValue = watch("usageMode");

  useEffect(() => {
    if (clinic) {
      const currentMode = clinic?.features.includes(Feature.RESERVATION)
        ? "full"
        : "prescriptionOnly";
      setIsFullModeUsed(currentMode);
      setValue("usageMode", currentMode);
    }
  }, [clinic, setValue]);

  const switchMode = async () => {
    if (clinic) {
      setLoading(true);
      try {
        const clinicUpdateDto = getClinicUpdateDto(clinic);
        clinicUpdateDto.features =
          changeModeValue === "prescriptionOnly"
            ? [Feature.PRESCRIPTION]
            : Object.values(Feature);

        await ClinicApi.updateClinic(clinic.id, clinicUpdateDto);
        refresh();
      } catch (e) {
        logger.error(e);
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
        setSwitchModalShown(false);
      }
    }
  };

  const closeConfirmModal = () => {
    setSwitchModalShown(false);
  };

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 flex items-center">
          <p className="uppercase">{strings.usageMode}</p>
          <div className="ml-auto">
            <Button
              onClick={() => {
                setSwitchModalShown(true);
              }}
              variant="icon"
            >
              <Pencil />
            </Button>
          </div>
        </div>
        <div className="p-4 flex">
          {strings.fullMode}
          <div className="ml-auto">
            <div hidden={isFullModeUsed === "prescriptionOnly"}>
              <CheckCircle className="text-green-600 dark:text-green-500" />
            </div>
          </div>
        </div>
        <div className="p-4 flex">
          {strings.prescriptionOnlyMode}
          <div className="ml-auto">
            <div hidden={isFullModeUsed === "full"}>
              <CheckCircle className="text-green-600 dark:text-green-500" />
            </div>
          </div>
        </div>
        <AlertBox message={error} />
      </div>
      <Modal handleClose={closeConfirmModal} show={switchModalShown}>
        <Modal.Header title={strings.usageMode} />
        <Modal.Body>
          <form>
            <RadioButtonGroup
              control={control}
              name="usageMode"
              options={[
                { label: strings.fullMode, value: "full" },
                {
                  label: strings.prescriptionOnlyMode,
                  helperText: strings.prescriptionOnlyModeTooltip,
                  value: "prescriptionOnly",
                },
              ]}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-main-button"
            disabled={loading || changeModeValue === isFullModeUsed}
            loading={loading}
            onClick={switchMode}
            type="submit"
          >
            {strings.switch}
          </Button>
          <Button
            className="modal-sub-button"
            disabled={loading}
            onClick={closeConfirmModal}
            variant="secondary"
          >
            {strings.cancel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrescriptionOnlyModeSection;
