/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import { PhoneResponse } from "../../../models/contact/PhoneResponse";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import PetOwnerContactsApi from "../../../api/PetOwnerContactsApi";
import AlertBox from "../../AlertBox";
import { ContactType } from "../../../models/contact/ContactType";
import AddPhoneModal, { NewPhoneForm } from "../../Modals/Phone/AddPhoneModal";
import BeforeDeleteModal from "../../Modals/BeforeDeleteModal";
import Button from "../../Button";
import UnconfirmedPhoneTag from "../user/phone/UnconfirmedPhoneTag";
import { Pencil } from "../../../common/Icons/Pencil";
import { Trash } from "../../../common/Icons/Trash";
import PromotePhoneModal from "../../Modals/Phone/PromotePhoneModal";

interface Props {
  owner?: PetOwnerResponse;
}

const PetOwnerPhones: React.FC<Props> = ({ owner }: Props) => {
  const [phones, setPhones] = useState<PhoneResponse[]>(owner?.phones || []);

  const [phoneToPromote, setPhoneToPromote] = useState<PhoneResponse | null>(
    null
  );
  const [phoneToDelete, setPhoneToDelete] = useState<PhoneResponse | null>();

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showPromoteModal, setShowPromoteModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [additionLoading, setAdditionLoading] = useState<boolean>(false);
  const [promotionLoading, setPromotionLoading] = useState<boolean>(false);
  const [deletionLoading, setDeletionLoading] = useState<boolean>(false);

  const [additionError, setAdditionError] = useState<string | null>(null);
  const [promotionError, setPromotionError] = useState<string | null>(null);
  const [deletionError, setDeletionError] = useState<string | null>(null);

  useEffect(() => {
    if (owner) {
      setPhones(owner.phones);
    }
  }, [owner]);

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openPromoteModal = (phone: PhoneResponse) => {
    if (phone.contactType === ContactType.PRIMARY) {
      return;
    }

    setPhoneToPromote(phone);
    setShowPromoteModal(true);
  };

  const closePromoteModal = () => {
    setShowPromoteModal(false);
  };

  const addNewPhoneNumber = async ({ phone, phoneType }: NewPhoneForm) => {
    if (!owner?.petOwnerDetails.id) {
      return;
    }

    setAdditionLoading(true);
    setAdditionError(null);

    try {
      const response = await PetOwnerContactsApi.addPetOwnerPhone(
        owner?.petOwnerDetails.id,
        {
          value: phone,
          phoneType,
        }
      );

      setPhones(response.data.phones);
      closeAddModal();
    } catch (err) {
      setAdditionError(await getGeneralError(err));
    } finally {
      setAdditionLoading(false);
    }
  };

  const promotePhone = async (phone: PhoneResponse) => {
    if (!owner?.petOwnerDetails.id) {
      return;
    }

    setPromotionLoading(true);
    setPromotionError(null);

    try {
      await PetOwnerContactsApi.setPrimaryPetOwnerPhone(
        owner?.petOwnerDetails.id,
        phone.id
      );

      const newPhones: PhoneResponse[] = [];

      phones.forEach((p: PhoneResponse) => {
        if (p.id === phone.id) {
          newPhones.push({ ...p, contactType: ContactType.PRIMARY });
        } else {
          newPhones.push({ ...p, contactType: ContactType.PRIVATE });
        }
      });

      setPhones(newPhones);
      closePromoteModal();
    } catch (err) {
      setPromotionError(await getGeneralError(err));
    } finally {
      setPromotionLoading(false);
    }
  };

  const deletePhone = async (phone: PhoneResponse) => {
    if (!owner?.petOwnerDetails.id) {
      return;
    }

    setPhoneToDelete(phone);
    setDeletionLoading(true);
    try {
      const response = await PetOwnerContactsApi.removePetOwnerPhone(
        owner?.petOwnerDetails.id,
        phone.id
      );

      setPhones(response.data.phones);
    } catch (err) {
      setDeletionError(await getGeneralError(err));
    } finally {
      setDeletionLoading(false);
    }
  };

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 font-bold">
          <p>{strings.phoneNumber.toUpperCase()}</p>
        </div>
        {phones.map((phone, index) => (
          <div
            className="p-4 flex justify-between items-center space-x-4"
            key={index}
          >
            <div>
              <div className="flex flex-wrap items-center">
                <p>{phone.value}</p>
                <UnconfirmedPhoneTag phone={phone} />
                <button
                  className={`tw-btn-pill-tercier-small ${
                    phone.confirmed ? "mx-3" : ""
                  }`}
                  disabled
                  hidden={phone.contactType !== ContactType.PRIMARY}
                  type="button"
                >
                  {strings.primary}
                </button>
              </div>
            </div>
            <div className="flex">
              <Button
                aria-label={strings.edit}
                hidden={phone.contactType === ContactType.PRIMARY}
                onClick={() => {
                  openPromoteModal(phone);
                }}
                variant="icon"
              >
                <span className="sr-only">{strings.edit}</span>
                <Pencil />
              </Button>
              <Button
                aria-label={strings.delete}
                className="tw-link ml-3"
                loading={deletionLoading && phone === phoneToDelete}
                onClick={() => {
                  setShowDeleteModal(true);
                  setPhoneToDelete(phone);
                }}
                showTextWhileLoading={false}
                variant="icon"
              >
                <span className="sr-only">{strings.delete}</span>
                <Trash />
              </Button>
            </div>
          </div>
        ))}
        <div className="p-4">
          <Button
            aria-label={strings.addPhoneNumber}
            onClick={openAddModal}
            variant="link"
          >
            <span className="sr-only">{strings.addPhoneNumber}</span>+{" "}
            {strings.addPhoneNumber}
          </Button>
        </div>
        <AlertBox className="m-3" message={deletionError} />
      </div>
      <AddPhoneModal
        close={closeAddModal}
        error={additionError}
        isOpen={showAddModal}
        loading={additionLoading}
        onSubmit={addNewPhoneNumber}
      />
      <PromotePhoneModal
        close={closePromoteModal}
        error={promotionError}
        isOpen={showPromoteModal}
        loading={promotionLoading}
        onSubmit={() => {
          if (phoneToPromote) {
            void promotePhone(phoneToPromote);
          }
        }}
        phoneNumber={phoneToPromote?.value}
      />
      <BeforeDeleteModal
        open={showDeleteModal}
        returnYes={() => {
          if (phoneToDelete) {
            void deletePhone(phoneToDelete);
          }
          setShowDeleteModal(false);
        }}
        onHide={() => {
          setShowDeleteModal(false);
        }}
      />
    </>
  );
};

export default PetOwnerPhones;
