/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TodoResponse } from "../../models/todo/TodoResponse";
import LoaderInline from "../../components/LoaderInline";
import TodoCard from "./TodoCard";
import AlertBox from "../../components/AlertBox";
import TodoModal from "./TodoModal";
import { strings } from "../../common/Strings/Strings";
import { ClinicPetAndOwnerResponse } from "../../models/pet/ClinicPetAndOwnerResponse";
import Filters from "../../components/ReactHookFormFields/General/Filters";
import { PetOwnerResponse } from "../../models/pet/PetOwnerResponse";
import Button from "../../components/Button";
import Modal from "../../components/Modal/Modal";
import CloseButton from "../../components/CloseButton";
import EmptyListText from "../../components/EmptyListText";

interface Props {
  error: string | null;
  filter: boolean;
  limitResults?: number;
  loading: boolean;
  petAndOwner?: ClinicPetAndOwnerResponse;
  petOwner?: PetOwnerResponse;
  reload(completed?: boolean): void;
  todos: TodoResponse[];
}

interface FilterForm {
  filters: boolean[];
}

export const PetOrOwnerTodos = ({
  error: e,
  filter,
  limitResults,
  loading,
  petAndOwner,
  petOwner,
  reload,
  todos,
}: Props): ReactElement => {
  const [newTodo, setNewTodo] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const { control, handleSubmit, setValue } = useForm<FilterForm>({
    defaultValues: { filters: [false] },
  });

  useEffect(() => {
    setValue("filters", [filter]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    setError(e);
  }, [e]);

  const returnResult = (): ReactElement => {
    if (error) {
      return <AlertBox className="my-3" message={error} />;
    }
    if (!error && todos.length === 0) {
      return (
        <div className="tw-card p-3 mt-6">
          <EmptyListText />
        </div>
      );
    }
    return (
      <div className="grid grid-cols-12 gap-6 my-4">
        {todos
          .slice(0, limitResults || todos.length)
          .map((todo: TodoResponse) => (
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
              key={todo.id}
            >
              <TodoCard onError={setError} reload={reload} todo={todo} />
            </div>
          ))}
      </div>
    );
  };

  const applyFilters = ({ filters }: FilterForm) => {
    reload(filters[0]);
    setFilterModalOpen(false);
  };

  return (
    <div>
      {loading ? (
        <LoaderInline className="mt-3" />
      ) : (
        <>
          <div className="d-flex">
            <div className="mt-3">
              <Button
                onClick={() => {
                  setFilterModalOpen(true);
                }}
                variant="tertiary"
              >
                {strings.filter}
              </Button>
            </div>
            <div className="ml-auto mt-3">
              <Button
                onClick={() => {
                  setNewTodo(true);
                }}
              >
                {strings.newTask}
              </Button>
            </div>
          </div>
          {returnResult()}
        </>
      )}
      <Modal
        handleClose={() => {
          setFilterModalOpen(false);
        }}
        show={isFilterModalOpen}
      >
        <Modal.Header title={strings.filter} />
        <Modal.Body>
          <div className="mb-2">
            <form>
              <Filters
                control={control}
                defaultFilters={[false]}
                multiple={false}
                name="filters"
                options={[
                  { value: false, title: strings.onGoing },
                  { value: true, title: strings.completed },
                ]}
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-main-button"
            onClick={handleSubmit(applyFilters)}
          >
            {strings.applyFilters}
          </Button>
          <CloseButton
            onClick={() => {
              setFilterModalOpen(false);
            }}
          />
        </Modal.Footer>
      </Modal>
      <TodoModal
        onHide={(isSuccess?: boolean) => {
          setNewTodo(false);
          if (isSuccess) {
            reload();
          }
        }}
        show={newTodo}
        todoForPet={petAndOwner}
        todoForPetOwner={petOwner}
      />
    </div>
  );
};
