/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Control, RegisterOptions, useController } from "react-hook-form";
import React, { ReactElement, useEffect, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import { InputModeEnum } from "../../../../models/InputModeEnum";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import SimpleInput, {
  FieldInputType,
} from "../../../InputFieldsSimple/SimpleInput";

interface FieldProps {
  control: Control<any>;
  name: string;
  autoComplete?: AutoCompleteOptions;
  classInput?: string;
  classLabel?: string;
  className?: string;
  defaultValue?: any;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  inputMode?: InputModeEnum;
  label?: string;
  labelIcon?: string;
  labelOff?: boolean;
  maxLength?: number;
  onChange?(e: any): void;
  optional?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  sm?: boolean;
  suffix?: string | null;
  toolTipText?: string;
  type?: FieldInputType;
  value?: any;
  width?: string;
}

const ControlledField = ({
  control,
  name,
  autoComplete,
  defaultValue,
  fieldOptions,
  maxLength,
  onChange,
  readOnly,
  required,
  type = "text",
  value: v,
  ...rest
}: FieldProps): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});
  const [value, setValue] = useState<string>("");

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules: registerOptions, defaultValue });

  useEffect(() => {
    if (v !== undefined) {
      setValue(v);

      if (type === "number") {
        field.onChange(Number(v));
      } else {
        field.onChange(v);
      }
    } else {
      setValue("");
      field.onChange("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [v]);

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  useEffect(() => {
    if (maxLength) {
      setRegisterOptions({
        ...registerOptions,
        maxLength: {
          value: maxLength,
          message: strings.tooManyCharactersEntered,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxLength]);

  return (
    <SimpleInput
      autoComplete={autoComplete || AutoCompleteOptions.on}
      error={error}
      name={field.name}
      type={type}
      maxLength={maxLength}
      readOnly={readOnly}
      onChange={(e: string) => {
        if (type === "number") {
          field.onChange(Number(e));
        } else {
          field.onChange(e);
        }

        setValue(e);

        if (onChange) {
          onChange(e);
        }
      }}
      value={value}
      {...rest}
    />
  );
};

export default ControlledField;

ControlledField.defaultProps = {
  autoComplete: AutoCompleteOptions.on,
  classInput: undefined,
  classLabel: undefined,
  className: undefined,
  defaultValue: undefined,
  fieldOptions: undefined,
  inputMode: undefined,
  label: undefined,
  labelIcon: undefined,
  labelOff: false,
  maxLength: undefined,
  onChange: undefined,
  optional: false,
  placeholder: undefined,
  readOnly: false,
  required: false,
  sm: false,
  suffix: undefined,
  toolTipText: undefined,
  type: "text",
  value: undefined,
  width: undefined,
};
