/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useCallback, useEffect, useState } from "react";
import VetApi from "../../../api/VetApi";
import { strings } from "../../../common/Strings/Strings";
import { VetProfessionWithUserResponse } from "../../../models/vet/VetProfessionWithUserResponse";
import logger from "../../../util/logger";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import { PageProps } from "../../../models/PageProps";
import { SiteManagerPage } from "../SiteManagerPage";
import VetProfessionsItemRow from "./VetProfessionsItemRow";
import { Card } from "../../../components/Cards/Card";

const VetDocumentsPage = ({ setPageLoading }: PageProps): ReactElement => {
  const [unapprovedProfessions, setUnapprovedProfessions] = useState<
    VetProfessionWithUserResponse[]
  >([]);
  const [error, setError] = useState<string | null>(null);

  const fetchUnapprovedVetProfessionDocs = useCallback(async () => {
    try {
      const resp = await VetApi.getAllVetProfessionDocuments();
      setUnapprovedProfessions(resp.data);
    } catch (e) {
      setError(await getGeneralError(e));
      logger.error(e);
    } finally {
      setPageLoading(false);
    }
  }, [setPageLoading]);

  useEffect(() => {
    void fetchUnapprovedVetProfessionDocs();
  }, [fetchUnapprovedVetProfessionDocs]);

  return (
    <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
      <Card size="lg" title={strings.approveVets} type="simple">
        <AlertBox message={error} />
        <table className="tw-table">
          <thead className="tw-thead">
            <tr>
              <th className="tw-th">{strings.name}</th>
              <th className="tw-th">{strings.fileOrFiles}</th>
              <th className="tw-th">{strings.actions}</th>
            </tr>
          </thead>
          <tbody className="tw-tbody">
            {unapprovedProfessions.map((p) => (
              <VetProfessionsItemRow
                key={p.user.userId}
                onError={setError}
                onUpdated={fetchUnapprovedVetProfessionDocs}
                vetProfessionWithUser={p}
              />
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

export default SiteManagerPage(VetDocumentsPage, strings.VET_DOCUMENTS);
