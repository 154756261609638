/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { strings } from "../../../../common/Strings/Strings";
import { CostAccountingStatus } from "../../../../models/costaccounting/CostAccountingStatus";
import { InvoiceResponse } from "../../../../models/invoice/InvoiceResponse";
import NumberFormatter from "../../../../util/NumberFormatter";
import CustomerInvoiceActions from "./CustomerInvoiceActions";
import SimpleCheckBox from "../../../../components/InputFieldsSimple/SimpleCheckBox";
import StripeApi from "../../../../api/StripeApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import CloseButton from "../../../../components/CloseButton";
import InvoicePaymentModal from "../../../Accounting/InvoicePaymentModal";
import { BasicClinicResponse } from "../../../../models/clinic/BasicClinicResponse";
import { getInvoiceState } from "../../../Accounting/InvoiceDetails/InvoiceInfos";

interface Props {
  clinic: BasicClinicResponse;
  invoices: InvoiceResponse[];
  refresh: () => void;
  setError(message: string | null): void;
}

interface CheckedInvoice {
  invoice: InvoiceResponse;
  checked: boolean;
}

const UnpaidInvoiceList = ({
  clinic,
  invoices,
  refresh,
  setError,
}: Props): ReactElement => {
  const [showPayWithCardModal, setShowPayWithCardModal] =
    useState<boolean>(false);
  const [stripePublicKeyOfClinic, setStripePublicKeyOfClinic] =
    useState<string>();
  const [checkedInvoices, setCheckedInvoices] = useState<CheckedInvoice[]>();
  const [stripeIntegrationErrorMessage, setStripeIntegrationErrorMessage] =
    useState<string | null>(null);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const openWarningModal = () => {
    setShowWarningModal(true);
  };
  const closeWarningModal = () => {
    setShowWarningModal(false);
  };

  const setUpCheckboxes = (invoicesToSetup: InvoiceResponse[]) => {
    const temp: CheckedInvoice[] = [];
    invoicesToSetup.forEach((invoice) => {
      if (invoice.costAccounting?.status === CostAccountingStatus.UNRESOLVED) {
        temp.push({ checked: false, invoice });
      }
    });
    setCheckedInvoices(temp);
  };

  useEffect(() => {
    setUpCheckboxes(invoices);
  }, [invoices]);

  const handleCheckChange = (invoice: InvoiceResponse) => {
    const any =
      checkedInvoices?.find(
        (i) => i.invoice.clinicId === invoice.clinicId && i.checked
      ) !== undefined;

    if (any) {
      setCheckedInvoices((prevState) => {
        if (prevState === undefined) return undefined;

        const newState = prevState;
        const idx = newState.findIndex((i) => i.invoice.id === invoice.id);
        newState[idx] = { checked: !newState[idx].checked, invoice };
        return [...newState];
      });
    } else {
      setCheckedInvoices((prevState) => {
        if (prevState === undefined) return undefined;

        const newState = prevState;
        newState.forEach((i, idx) => {
          newState[idx] = {
            checked: i.invoice.id === invoice.id,
            invoice: i.invoice,
          };
        });
        return [...newState];
      });
    }
  };

  const openPayInvoicesModal = async () => {
    if (checkedInvoices === undefined) return;

    const relevantList = checkedInvoices.filter((i) => i.checked);
    if (relevantList.length > 0) {
      const { clinicId } = relevantList[0].invoice;

      try {
        const response = await StripeApi.getStripePublicKeyOfClinic(clinicId);
        setStripePublicKeyOfClinic(response.data);
        setShowPayWithCardModal(true);
        setStripeIntegrationErrorMessage(null);
      } catch (e) {
        setStripeIntegrationErrorMessage(await getGeneralError(e));
        openWarningModal();
        setUpCheckboxes(invoices || []);
      }
    }
  };

  const closePayWithCardModal = () => {
    setShowPayWithCardModal(false);
  };

  return (
    <div className="w-full md:tw-card space-y-6 md:p-8 my-6">
      <div>
        <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
          {clinic.name}
        </h1>
      </div>
      <div>
        {clinic.canPayOnline ? (
          <Button
            disabled={
              !checkedInvoices || !checkedInvoices.find((i) => i.checked)
            }
            onClick={openPayInvoicesModal}
          >
            {strings.paySelected}
          </Button>
        ) : (
          strings.infoOnlinePaymentDisabled
        )}
      </div>

      <div className="tw-table-container">
        <table className="tw-table">
          <thead className="tw-thead">
            <tr>
              <th hidden={!clinic?.canPayOnline} />
              <th className="tw-th">{strings.invoice}</th>
              <th className="tw-th">{strings.invoiceNumber}</th>
              <th className="tw-th text-right">{strings.totalCost}</th>
              <th className="tw-th text-right">{strings.remainingCost}</th>
              <th className="tw-th text-right">{strings.status}</th>
              <th />
            </tr>
          </thead>
          <tbody className="tw-tbody">
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td
                  className="px-4 py-3 text-sm whitespace-nowrap"
                  hidden={!clinic?.canPayOnline}
                >
                  <div
                    hidden={
                      invoice.costAccounting?.status !==
                      CostAccountingStatus.UNRESOLVED
                    }
                  >
                    <SimpleCheckBox
                      checked={
                        checkedInvoices?.find(
                          (i) => invoice.id === i.invoice.id
                        )?.checked || false
                      }
                      name={`checked-invoice-${invoice.id}`}
                      onChange={() => {
                        handleCheckChange(invoice);
                      }}
                    />
                  </div>
                </td>
                <td className="px-4 py-3 text-sm whitespace-nowrap">
                  {moment(invoice.creationDate).format("LLL")}
                </td>
                <td className="px-4 py-3 text-sm whitespace-nowrap">
                  {invoice.invoiceNumber}
                </td>
                <td className="px-4 py-3 text-sm whitespace-nowrap text-right">
                  {NumberFormatter.formatPrice(
                    invoice.totalCost,
                    invoice.currency
                  )}
                </td>
                <td className="px-4 py-3 text-sm whitespace-nowrap text-right">
                  {NumberFormatter.formatPrice(
                    invoice.costAccounting?.remainingCost || 0,
                    invoice.costAccounting?.currency
                  )}
                </td>
                <td className="px-4 py-3 text-sm whitespace-nowrap text-right">
                  {getInvoiceState(invoice)}
                </td>
                <td className="px-4 py-3 text-sm whitespace-nowrap text-right">
                  <div className="flex items-center">
                    <div className="ml-auto">
                      <CustomerInvoiceActions
                        invoice={invoice}
                        showDetails
                        showPayButton={clinic?.canPayOnline}
                        setError={setError}
                        reload={refresh}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {stripePublicKeyOfClinic && (
        <InvoicePaymentModal
          invoiceGroup={(checkedInvoices || [])
            .filter((i) => i.checked)
            .map((i) => i.invoice)}
          stripeApiKey={stripePublicKeyOfClinic}
          close={closePayWithCardModal}
          isOpen={showPayWithCardModal}
          onInvoiceUpdated={() => refresh()}
          onInvoiceGroupUpdated={() => refresh()}
        />
      )}
      <Modal
        size="sm"
        show={showWarningModal}
        onHide={closeWarningModal}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{strings.warning}</Modal.Title>
        </Modal.Header>
        <div className="m-2 text-center">{stripeIntegrationErrorMessage}</div>
        <Modal.Footer>
          <CloseButton onClick={closeWarningModal} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UnpaidInvoiceList;
