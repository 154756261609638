/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Dispatch, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import logger from "../../../util/logger";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import DynamicSelect from "../../../components/ReactHookFormFields/General/Select/DynamicSelect";
import CloseButton from "../../../components/CloseButton";
import FeeSection from "./FeeSection";
import ServiceFeeApi from "../../../api/ServiceFeeApi";
import { ServiceFeeResponse } from "../../../models/servicefee/ServiceFeeResponse";
import { TreatmentFeeResponse } from "../../../models/treatment/TreatmentFeeResponse";
import { getGeneralError } from "../../../util/helperFunctions";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";
import EmptyListText from "../../../components/EmptyListText";
import Modal from "../../../components/Modal/Modal";

export type TreatmentFeeActions =
  | { type: "fill"; feesToAdd: TreatmentFeeResponse[] }
  | { type: "add"; feeToAdd: TreatmentFeeResponse }
  | { type: "changeQuantity"; feeToChange: TreatmentFeeResponse }
  | { type: "remove"; feeToRemove: TreatmentFeeResponse };

interface Props {
  clinicId?: string;
  close(): void;
  countryDetails?: CountryDetailsResponse;
  dispatchFee: Dispatch<TreatmentFeeActions>;
  usedFees: Array<TreatmentFeeResponse>;
  serviceFees: ServiceFeeResponse[];
  show: boolean;
}

export const treatmentFeeReducer = (
  state: Array<TreatmentFeeResponse>,
  action: TreatmentFeeActions
) => {
  switch (action.type) {
    case "fill": {
      return action.feesToAdd;
    }
    case "add": {
      const addActionId = action.feeToAdd.fee.id;
      const alreadyAddedItemIndex = state.findIndex(
        (fee: TreatmentFeeResponse) => fee.fee.id === addActionId
      );
      if (alreadyAddedItemIndex >= 0) {
        state[alreadyAddedItemIndex].quantity += action.feeToAdd.quantity;
        return [...state];
      }
      return [...state, action.feeToAdd];
    }
    case "changeQuantity": {
      const changeActionId = action.feeToChange.fee.id;
      const changeIndex = state.findIndex(
        (fee: TreatmentFeeResponse) => fee.fee.id === changeActionId
      );

      if (changeIndex >= 0) {
        state[changeIndex].quantity = action.feeToChange.quantity;
      }
      return [...state];
    }
    case "remove": {
      const removeActionId = action.feeToRemove.fee.id;

      return [
        ...state.filter(
          (fee: TreatmentFeeResponse) => fee.fee.id !== removeActionId
        ),
      ];
    }
    default: {
      return [];
    }
  }
};

interface FormData {
  feeItem: ServiceFeeResponse[];
  quantity: number;
}

const AddFeeModal: React.FC<Props> = ({
  clinicId,
  close,
  countryDetails,
  dispatchFee,
  usedFees,
  serviceFees: defaultOptions,
  show,
}: Props) => {
  const [serviceFees, setServiceFees] = useState<ServiceFeeResponse[]>([]);
  const [addedItem, setItem] = useState<ServiceFeeResponse[] | null>(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      quantity: 1,
    },
  });

  const findServiceFees = async (query: string) => {
    if (clinicId) {
      try {
        const response = await ServiceFeeApi.searchServiceFeesOfClinic(
          clinicId,
          query
        );
        setServiceFees(response.data.elements);
      } catch (e) {
        logger.error(await getGeneralError(e));
      }
    }
  };

  const search = (query: string) => {
    if (query && query.length > 0) {
      void findServiceFees(query);
    }
  };

  const addFee = ({ feeItem, quantity }: FormData) => {
    if (feeItem.length === 1) {
      const fee: TreatmentFeeResponse = {
        id: feeItem[0].id,
        fee: feeItem[0],
        quantity,
      };
      dispatchFee({ type: "add", feeToAdd: fee });
      setItem(null);
      reset();
    }
  };

  const deleteFee = (feeItem: TreatmentFeeResponse) => {
    dispatchFee({ type: "remove", feeToRemove: feeItem });
  };

  const changeQuantityOfFee = (feeItem: TreatmentFeeResponse) => {
    dispatchFee({ type: "changeQuantity", feeToChange: feeItem });
  };

  return (
    <Modal handleClose={close} show={show} size="modal-lg">
      <Modal.Header title={strings.serviceFees} />
      <Modal.Body>
        <div className="space-y-6">
          <form
            className="flex space-x-4 items-end"
            onSubmit={handleSubmit(addFee)}
          >
            <DynamicSelect
              allowNew={false}
              className="md:min-w-96"
              control={control}
              defaultOptions={defaultOptions}
              labelKey="name"
              label={strings.addFee}
              name="feeItem"
              onInputValueChange={search}
              onChange={(selected: Array<any> | null) => {
                setItem(selected);
              }}
              options={serviceFees}
              placeholder={strings.searchFees}
            />
            {addedItem && (
              <>
                <Field
                  autoComplete={AutoCompleteOptions.off}
                  error={errors.quantity}
                  fieldOptions={{
                    validate: {
                      positive: (v) => v > 0,
                    },
                  }}
                  label={strings.quantity}
                  name="quantity"
                  register={register}
                  required
                  step="0.0001"
                  type="number"
                  width="100px"
                />
                <div>
                  <Button hidden={!addedItem} variant="primary" type="submit">
                    {strings.add}
                  </Button>
                </div>
              </>
            )}
          </form>
          <div>
            {strings.usedFees}
            {usedFees.length === 0 ? (
              <div className="my-3">
                <EmptyListText text={strings.noServiceFeesAdded} />
              </div>
            ) : (
              <FeeSection
                changeQuantityOfFee={changeQuantityOfFee}
                countryDetails={countryDetails}
                deleteFee={deleteFee}
                fees={usedFees}
                showSum
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer extraClassName="justify-end">
        <CloseButton asMain onClick={close} />
      </Modal.Footer>
    </Modal>
  );
};

export default AddFeeModal;
