/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import Button from "../../../../components/Button";
import { CheckCircle } from "../../../../common/Icons/CheckCircle";
import { Pencil } from "../../../../common/Icons/Pencil";
import PetFormMandatoryTab from "../../../Pet/CreateNewPet/PetFormMandatoryTab";
import PetFormOptionalTab from "../../../Pet/CreateNewPet/PetFormOptionalTab";

const CreatePet: React.FC = () => {
  const [stepPet, setStepPet] = useState<number>(1);
  const { trigger } = useFormContext();

  return (
    <div className="md:tw-card space-y-6 md:p-8">
      <div className="flex justify-between space-x-4">
        <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
          {strings.pet}
        </h1>
        <span className="text-gray-500 dark:text-gray-400">
          {stepPet < 3 ? (
            `${stepPet  }/2`
          ) : (
            <div className="flex space-x-2">
              <CheckCircle className="text-green-500" />
              <Button
                variant="icon"
                onClick={() => {
                  setStepPet(1);
                }}
              >
                <Pencil />
              </Button>
            </div>
          )}
        </span>
      </div>
      <div className="space-y-4" hidden={stepPet === 3}>
        {stepPet === 1 ? (
          <>
            <PetFormMandatoryTab />
            <Button
              className="w-full"
              onClick={() => {
                const response = trigger([
                  "breed",
                  "color",
                  "dateOfBirth",
                  "gender",
                  "name",
                  "species",
                ]);

                response.then((isValid: boolean) => {
                  if (isValid) {
                    setStepPet(2);
                  }
                });
              }}
              variant="primary-outline"
            >
              {strings.saveAndContinue}
            </Button>
          </>
        ) : (
          <>
            <PetFormOptionalTab />
            <Button
              className="w-full"
              onClick={() => {
                const response = trigger([
                  "chipNumber",
                  "chronicDiseases",
                  "comment",
                  "deceased",
                  "insuranceCompany",
                  "insuranceNumber",
                  "passportNumber",
                  "tattooNumber",
                ]);

                response.then((isValid: boolean) => {
                  if (isValid) {
                    setStepPet(3);
                  }
                });
              }}
              variant="primary-outline"
            >
              {strings.saveAndContinue}
            </Button>
          </>
        )}
        <div hidden={stepPet === 1}>
          <Button
            variant="link"
            onClick={() => {
              setStepPet(1);
            }}
          >
            {strings.back}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreatePet;
