/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { SearchResponse } from "../models/ePrescription/VARA/SearchResponse";
import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { AtcCode } from "../models/ePrescription/VARA/AtcCode";
import { AdministrationWays } from "../models/ePrescription/VARA/AdministrationWays";
import { SearchParams } from "../models/ePrescription/local/SearchParams";
import { VaraVersion } from "../models/ePrescription/VARA/VaraVersion";
import { MedicinalProduct } from "../models/ePrescription/VARA/MedicinalProduct";
import { PackageType } from "../models/ePrescription/VARA/PackageType";
import { SearchResponseCommodity } from "../models/ePrescription/VARA/SearchResponseCommodity";
import Params from "../common/Params";
import { SearchParamsCommodity } from "../models/ePrescription/local/SearchParamsCommodity";

export default class VaraApi {
  private static baseUrl = Params.varaService;

  static getAtcCodes(): ApiPromise<AtcCode[]> {
    const url = `${this.baseUrl}/api/products/atcCodes`;
    return axiosCommon.get(url);
  }

  static getAdministrationWays(): ApiPromise<AdministrationWays[]> {
    const url = `${this.baseUrl}/api/products/administrationWays`;
    return axiosCommon.get(url);
  }

  static getPackageTypes(): ApiPromise<PackageType[]> {
    const url = `${this.baseUrl}/api/products/packageTypes`;
    return axiosCommon.get(url);
  }

  static getVersion(): ApiPromise<VaraVersion> {
    const url = `${this.baseUrl}/api/versions`;
    return axiosCommon.get(url);
  }

  static getProductById(nplId: string): ApiPromise<MedicinalProduct> {
    const url = `${this.baseUrl}/api/products/${nplId}`;
    return axiosCommon.get(url);
  }

  static searchProducts({
    approved,
    barcodeKeyword,
    onTheMarket,
    pageNumber,
    pageSize,
    productNameKeyword,
    productTypes,
    substanceKeyword,
    atcCode,
    varaNummerKeyword,
  }: SearchParams): ApiPromise<SearchResponse> {
    let url = `${this.baseUrl}/api/products?`;

    if (approved) {
      url = `${url}approved=${approved}&`;
    }
    if (barcodeKeyword) {
      url = `${url}barcodeKeyword=${barcodeKeyword}&`;
    }
    if (onTheMarket !== undefined) {
      url = `${url}onTheMarket=${onTheMarket}&`;
    }
    if (pageNumber) {
      url = `${url}pageNumber=${pageNumber}&`;
    }
    if (pageSize) {
      url = `${url}pageSize=${pageSize}&`;
    }
    if (productNameKeyword) {
      url = `${url}productNameKeyword=${productNameKeyword}&`;
    }
    if (substanceKeyword) {
      url = `${url}substanceKeyword=${substanceKeyword}&`;
    }
    if (atcCode && atcCode !== "-") {
      url = `${url}atcCode=${atcCode}&`;
    }
    if (varaNummerKeyword) {
      url = `${url}productNameKeyword=${varaNummerKeyword}&`;
    }
    if (productTypes && productTypes.length > 0) {
      let productTypeText = "";
      productTypes.forEach((type: string) => {
        productTypeText = `${productTypeText + type},`;
      });
      const finalText = productTypeText.slice(0, -1);
      url = `${url}productTypes=${finalText}&`;
    }

    const finalUrl = url.slice(0, -1);

    return axiosCommon.get(finalUrl);
  }

  static searchCommodityProducts({
    barcodeKeyword,
    pageNumber,
    pageSize,
    productNameKeyword,
    productType,
    onTheMarket,
  }: SearchParamsCommodity): ApiPromise<SearchResponseCommodity> {
    let url = `${this.baseUrl}/api/commodities?`;

    if (barcodeKeyword) {
      url = `${url}barcodeKeyword=${barcodeKeyword}&`;
    }
    if (pageNumber) {
      url = `${url}pageNumber=${pageNumber}&`;
    }
    if (pageSize) {
      url = `${url}pageSize=${pageSize}&`;
    }
    if (productNameKeyword) {
      url = `${url}productNameKeyword=${productNameKeyword}&`;
    }
    if (productType) {
      url = `${url}substanceKeyword=${productType}&`;
    }
    if (onTheMarket !== undefined) {
      url = `${url}onTheMarket=${onTheMarket}&`;
    }

    const finalUrl = url.slice(0, -1);

    return axiosCommon.get(finalUrl);
  }
}
