/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  RegisterOptions,
} from "react-hook-form";
import React, { ReactElement, useEffect, useState } from "react";
import { Tooltip } from "../../Tooltip";
import { Colors } from "../../../models/Colors";
import { strings } from "../../../common/Strings/Strings";
import { InputModeEnum } from "../../../models/InputModeEnum";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { RegisterReturnType } from "../../../models/RegisterReturnType";
import { validateInternationalEmail } from "../../../util/Validations";
import { InfoFilled } from "../../../common/Icons/InfoFilled";
import { FieldInputType } from "../../InputFieldsSimple/SimpleInput";

interface FieldProps {
  name: string;
  register(ref: string, registerOptions?: RegisterOptions): RegisterReturnType;
  autoComplete?: AutoCompleteOptions;
  classInput?: string;
  classLabel?: string;
  className?: string;
  disableNotNumberCharacters?: boolean;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  fieldOptions?: RegisterOptions;
  inputMode?: InputModeEnum;
  label?: string;
  labelIcon?: string;
  labelOff?: boolean;
  maxLength?: number;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onPaste?(e: React.ClipboardEvent<HTMLInputElement>): void;
  optional?: boolean;
  placeholder?: string;
  placeholderHighlight?: boolean;
  readOnly?: boolean;
  required?: boolean;
  setError?(
    name: string,
    error: FieldError | Merge<FieldError, FieldErrorsImpl<any>>,
    options?: { shouldFocus: boolean }
  ): void;
  showRequired?: boolean;
  sm?: boolean;
  step?: string;
  suffix?: string | null;
  toolTipText?: string | ReactElement;
  type?: FieldInputType;
  width?: string;
  helperText?: string;
}

const Field = ({
  name,
  register,
  autoComplete,
  classInput,
  classLabel,
  className,
  disableNotNumberCharacters,
  error,
  fieldOptions,
  label,
  labelIcon,
  labelOff,
  maxLength,
  onChange,
  onPaste,
  optional,
  placeholder = label,
  placeholderHighlight = false,
  readOnly = true,
  required,
  showRequired,
  step,
  setError,
  suffix,
  toolTipText,
  type = "text",
  width,
  helperText,
}: FieldProps): ReactElement => {
  const [space, setSpace] = useState<number>(0);
  const [registerOptions, setRegisterOptions] = useState<RegisterOptions>(
    fieldOptions || {}
  );

  useEffect(() => {
    if (suffix) {
      if (toolTipText) {
        setSpace(2);
      } else {
        setSpace(1);
      }
    } else if (toolTipText) {
      setSpace(1);
    }
  }, [suffix, toolTipText]);

  useEffect(() => {
    const prevState = { ...registerOptions };

    if (type === "email") {
      prevState.validate = {
        isInternationalEmail: (v) =>
          validateInternationalEmail(v) || strings.emailNotValid,
      };
    }

    if (type === "number") {
      prevState.valueAsNumber = true;
    }

    if (required) {
      prevState.required = true;
    }

    if (maxLength) {
      prevState.maxLength = {
        value: maxLength,
        message: strings.tooManyCharactersEntered,
      };
    }

    setRegisterOptions(prevState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required, type, maxLength]);

  const inputClass =
    classInput ||
    `tw-input ${type === "color" ? "h-14" : ""}${space === 1 ? " pr-10" : ""}${
      space === 2 ? " pr-20" : ""
    }`;

  return (
    <div
      className={`${className || ""} ${error ? "has-error" : ""}`}
      style={{ width }}
    >
      {labelOff ? (
        <></>
      ) : (
        <label htmlFor={name} className={classLabel}>
          {labelIcon ? <i className={labelIcon} /> : ""} {label}
          {showRequired ? " *" : ""}
          {optional ? (
            <span style={{ color: Colors.INPUTBORDER }}>
              {" "}
              ({strings.optional})
            </span>
          ) : (
            <></>
          )}
          {helperText ? (
            <span style={{ color: Colors.INPUTBORDER }}> ({helperText})</span>
          ) : (
            <></>
          )}
        </label>
      )}
      <div className="relative w-full">
        <input
          {...register(name, registerOptions)}
          autoComplete={autoComplete || AutoCompleteOptions.on}
          disabled={readOnly}
          name={name}
          type={type}
          placeholder={placeholder}
          className={`${inputClass} text-ellipsis ${
            placeholderHighlight
              ? "placeholder:text-[32px] h-10 placeholder:h-5 pt-4"
              : ""
          }`}
          maxLength={maxLength}
          readOnly={readOnly}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            void register(name).onChange(e);

            if (onChange) {
              onChange(e);
            }
          }}
          onInput={(evt: React.FormEvent<HTMLInputElement>) => {
            // Remove non-digit characters
            if (disableNotNumberCharacters) {
              const input = evt.target as HTMLInputElement;
              input.value = input.value.replace(/\D/g, "");
            }
          }}
          onKeyDown={(evt) => {
            if (type === "number") {
              if (
                !Number(evt.key) &&
                evt.key !== "." &&
                evt.key !== "Backspace" &&
                evt.key !== "-"
              ) {
                if (setError) {
                  setError(name, {
                    type: "custom",
                    message: strings.formatString(
                      strings.notAllowedCharacterSpecific,
                      evt.key
                    ),
                  });
                }
              }
            }
          }}
          onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
            onPaste && onPaste(e);
          }}
          step={step}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-2">
          {suffix ? (
            <div>
              <span>{suffix}</span>
            </div>
          ) : (
            <></>
          )}
          {toolTipText ? (
            <Tooltip content={toolTipText} placement="top">
              <span>
                <InfoFilled className="text-sky-500" />
              </span>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
      </div>
      {error?.types !== undefined ? (
        Object.values(error?.types).map((err) => <p>{err}</p>)
      ) : (
        <p>{error && error.message?.toString()}</p>
      )}
    </div>
  );
};

export default Field;

Field.defaultProps = {
  autoComplete: AutoCompleteOptions.on,
  classInput: undefined,
  classLabel: undefined,
  className: undefined,
  disableNotNumberCharacters: false,
  error: undefined,
  fieldOptions: undefined,
  inputMode: undefined,
  label: undefined,
  labelIcon: undefined,
  labelOff: false,
  maxLength: undefined,
  onChange: undefined,
  optional: false,
  placeholder: undefined,
  readOnly: false,
  required: false,
  step: undefined,
  suffix: undefined,
  toolTipText: undefined,
  type: "text",
  width: undefined,
};
