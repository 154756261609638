/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";

interface Props {
  currentPage?: number;
  firstPage?: number;
  lastPage?: number;
  maxVisiblePages?: number;
  selectPage(page: number): void;
  totalPages: number;
  updatePageRange?(first: number, last: number): void;
}

const Paging: React.FC<Props> = ({
  currentPage,
  firstPage: fp,
  lastPage: lp,
  maxVisiblePages = 5,
  selectPage,
  totalPages = 1,
  updatePageRange,
}: Props) => {
  const [actualPage, setActualPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [firstPage, setFirstPage] = useState<number>(1);

  useEffect(() => {
    const page = (currentPage || 0) + 1;
    setActualPage(page);
  }, [currentPage]);

  useEffect(() => {
    if (fp && fp !== firstPage) {
      setFirstPage(fp);
    }
    if (lp && lp !== lastPage) {
      setLastPage(lp);
    }
  }, [fp, lp]);

  useEffect(() => {
    if (updatePageRange) {
      updatePageRange(firstPage, lastPage);
    }
  }, [firstPage, lastPage]);

  useEffect(() => {
    setLastPage(totalPages > maxVisiblePages ? maxVisiblePages : totalPages);
    setFirstPage(1);
  }, [totalPages, maxVisiblePages]);

  const boxes = [];

  for (let i = firstPage; i <= lastPage; i += 1) {
    boxes.push(
      <button
        key={i}
        type="button"
        className={`btn px-2 py-1 m-1 ${
          actualPage === i ? "btn-primary min-w-0" : "btn-secondary"
        }`}
        onClick={() => {
          selectPage(i - 1);
        }}
      >
        {i}
      </button>
    );
  }

  return (
    <div className="d-inline-flex">
      {firstPage > 1 ? (
        <button
          type="button"
          className="btn btn-simple ml-1"
          onClick={() => {
            setFirstPage(firstPage - maxVisiblePages);
            const nextPageLength: number =
              totalPages - firstPage + 1 > maxVisiblePages
                ? maxVisiblePages
                : totalPages - firstPage + 1;
            setLastPage(lastPage - nextPageLength);
          }}
        >
          <div className="paragraph-small">
            <i className="fas fa-chevron-left mr-1" />
          </div>
        </button>
      ) : (
        <></>
      )}
      {boxes}
      {lastPage < totalPages ? (
        <button
          type="button"
          className="btn btn-simple ml-1"
          onClick={() => {
            setFirstPage(firstPage + maxVisiblePages);
            const nextPageLength: number =
              totalPages - lastPage > maxVisiblePages
                ? maxVisiblePages
                : totalPages - lastPage;
            setLastPage(lastPage + nextPageLength);
          }}
        >
          <div className="paragraph-small">
            <i className="fas fa-chevron-right ml-1" />
          </div>
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

Paging.defaultProps = {
  maxVisiblePages: 5,
  currentPage: 0,
};

export default Paging;
