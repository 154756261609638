/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  businessStructures,
  strings,
  SupportedLanguages,
} from "../../../../common/Strings/Strings";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import Select from "../../../../components/ReactHookFormFields/General/Select/Select";
import { BusinessStructure } from "../../../../models/clinic/BusinessStructure";
import AlertBox from "../../../../components/AlertBox";
import { BackendFieldError } from "../../../../models/BackendFieldError";
import isSwedishOrganizationNumberValid from "../../../../util/RegistrationNumberValidator";
import { isSwedishPersonalIdValid } from "../../../../util/Validations";
import {
  canManageClinic,
  getGeneralError,
} from "../../../../util/helperFunctions";
import Button from "../../../../components/Button";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import { ClinicUpdateRequest } from "../../../../models/clinic/ClinicUpdateRequest";
import ClinicApi from "../../../../api/ClinicApi";

interface Props {
  clinic: ClinicResponse;
  getClinic: () => void;
}

const defaultFormValues = (clinic: ClinicResponse) => ({
  name: clinic.name ?? "",
  businessStructure: clinic.businessStructure ?? BusinessStructure.COMPANY,
  businessName: clinic.businessName ?? "",
  vat: clinic.vat ?? "",
  registrationNumber: clinic.registrationNumber ?? "",
  language: clinic.language ?? "",
  features: clinic.features,
});

type EditClinicFormKeys = keyof ClinicUpdateRequest;

const BasicInformationForm: React.FC<Props> = ({
  clinic,
  getClinic,
}: Props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [externalError, setExternalError] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<
    Array<BackendFieldError<EditClinicFormKeys>>
  >([]);

  const refreshPage = (withReload: boolean) => {
    getClinic();
    withReload && navigate(0);
  };

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    watch,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = useForm<ClinicUpdateRequest>({
    mode: "onChange",
    defaultValues: defaultFormValues(clinic),
  });

  const businessStructure = watch("businessStructure");

  const isRegistrationNumberValid = (regNumber: string): boolean => {
    const values = getValues();
    if (clinic?.countryCode === "SE") {
      if (values.businessStructure === BusinessStructure.COMPANY) {
        return isSwedishOrganizationNumberValid(regNumber);
      }
      if (values.businessStructure === BusinessStructure.SOLE_PROPRIETORSHIP) {
        return isSwedishPersonalIdValid(regNumber);
      }
    }
    return true;
  };

  useEffect(() => {
    if (!Array.isArray(fieldErrors)) return;
    fieldErrors.forEach(
      ({ field, message }: BackendFieldError<EditClinicFormKeys>) => {
        const errs = errors[field]?.types;
        const errsCount = errs ? Object.keys(errs).length : 0;
        setError(field, {
          types: { ...errs, [errsCount]: message },
        });
      }
    );
  }, [errors, fieldErrors, setError]);

  const submit = async (clinicRequest: ClinicUpdateRequest) => {
    setLoading(true);

    try {
      await ClinicApi.updateClinic(clinic.id, clinicRequest);
      refreshPage(dirtyFields.name === true);
    } catch (error: any) {
      const data = error.response.data;
      if (Array.isArray(data)) {
        setFieldErrors(error.response?.data || []);
      } else {
        const generalError = await getGeneralError(error);
        if (generalError !== null) {
          setExternalError(generalError);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const readOnly = !canManageClinic(clinic);

  return (
    <form className="space-y-6" onSubmit={handleSubmit(submit)}>
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.name}
        helperText={strings.organizationNameHelperText}
        label={strings.clinicName}
        name="name"
        placeholder={strings.editOrganizationNamePlaceholder}
        register={register}
        required
        showRequired
        toolTipText={strings.basicInfoOrganizationNameToolTip}
        readOnly={readOnly}
      />
      <Select
        error={errors.businessStructure}
        fieldOptions={{ deps: ["registrationNumber"] }}
        isChooseOption
        label={strings.businessStructure}
        name="businessStructure"
        options={Object.keys(BusinessStructure).map((type) => ({
          title: businessStructures[type],
          value: type,
        }))}
        register={register}
        required
        readOnly={readOnly}
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.businessName}
        label={strings.businessName}
        name="businessName"
        placeholder={strings.editBusinessNamePlaceholder}
        register={register}
        required
        showRequired
        toolTipText={strings.basicInfoBusinessNameToolTip}
        readOnly={readOnly}
      />
      <Select
        isChooseOption={false}
        label={strings.officialLanguage}
        name="language"
        options={SupportedLanguages.map((lan) => ({
          title: lan.title,
          value: lan.code,
        }))}
        register={register}
        required
        readOnly={readOnly}
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.vat}
        label={strings.vatNumber}
        name="vat"
        placeholder={strings.editVATNumberPlaceholder}
        register={register}
        required
        readOnly={readOnly}
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.registrationNumber}
        fieldOptions={{
          validate: {
            registrationNumberIsValid: (orgNumber) =>
              isRegistrationNumberValid(orgNumber) ||
              strings.invalidRegistrationNumber,
          },
        }}
        label={`${
          businessStructure === BusinessStructure.COMPANY
            ? strings.organisationId
            : strings.personalId
        }`}
        name="registrationNumber"
        register={register}
        required
        readOnly={readOnly}
      />
      <AlertBox className="my-3" message={externalError} />
      <Button
        hidden={readOnly}
        disabled={loading || !isValid || !isDirty}
        loading={loading}
        type="submit"
      >
        {strings.saveChanges}
      </Button>
    </form>
  );
};

export default BasicInformationForm;
