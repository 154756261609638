/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import UrlTab from "../../components/Tabs/UrlTab";
import { strings } from "../../common/Strings/Strings";
import LocationIndicator from "../../components/Tabs/LocationIndicator";
import { ClinicResponse } from "../../models/clinic/ClinicResponse";
import { useClinic } from "../../contexts/ClinicContext";
import PaymentsTab from "./Payment/PaymentsTab";
import UsageStatisticsTab from "./Statistics/UsageStatistics";
import CostStatisticsTab from "./Statistics/CostStatistics";
import ClinicApi from "../../api/ClinicApi";
import {
  canManageClinic,
  getGeneralError,
  isFeatureAvailable,
} from "../../util/helperFunctions";
import logger from "../../util/logger";
import { Loader } from "../../components/Loader";
import GlobalVetOtherSettings from "./Settings/Settings";
import { ClinicFeature } from "../../models/clinic/ClinicFeature";

enum GlobalVetSettingsTabs {
  usageStats = "usage-statistics",
  costStats = "cost-statistics",
  payments = "payments",
  settings = "settings",
}

interface Props {
  setPageLoading: (isLoading: boolean) => void;
}

const GlobalVetSettings: React.FC<Props> = ({ setPageLoading }: Props) => {
  const [currentTab, setCurrentTab] = useState<GlobalVetSettingsTabs>(
    GlobalVetSettingsTabs.usageStats
  );
  const [activeClinic, setActiveClinic] = useState<ClinicResponse>();
  const [reloadClinic, setReloadClinic] = useState<boolean>(false);
  const params = useParams<"clinicId">();
  const clinicId = params.clinicId;
  const { fetchClinic } = useClinic();
  const navigate = useNavigate();

  useEffect(() => {
    const getClinic = async (cId: string) => {
      try {
        const response = await ClinicApi.getClinic(cId);
        setActiveClinic(response.data);
      } catch (e) {
        logger.error(getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    };
    if (clinicId) {
      void getClinic(clinicId);
    } else {
      setPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicId, reloadClinic]);

  const goToClinicPage = async () => {
    if (activeClinic?.id) {
      setPageLoading(true);
      await fetchClinic(activeClinic.id);
      navigate("/");
    }
  };

  useEffect(() => {
    if (activeClinic && !canManageClinic(activeClinic)) {
      navigate("/", { replace: true });
    }
  }, [activeClinic, navigate]);

  if (activeClinic && !canManageClinic(activeClinic)) {
    return <></>;
  }

  if (!activeClinic) {
    return <></>;
  }

  return (
    <main className="main-signed-in-no-clinic">
      <section>
        <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <div>
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white flex items-center">
              <span
                className="cursor-pointer hover:underline"
                onClick={goToClinicPage}
                role="button"
                tabIndex={0}
              >
                {" "}
                {activeClinic?.name}
              </span>
            </h1>
          </div>
          <div className="border border-gray-200 rounded-md bg-gray-50 !p-4 text-center text-md font-medium text-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300">
            <ul className="flex flex-wrap -mb-px">
              <UrlTab
                useBaseUrlOnly
                currentTabKey={currentTab}
                tabKey={GlobalVetSettingsTabs.usageStats}
                title={strings.clinicStatistics}
                visible={isFeatureAvailable(
                  activeClinic,
                  ClinicFeature.STATISTICS
                )}
              />
              <UrlTab
                currentTabKey={currentTab}
                tabKey={GlobalVetSettingsTabs.costStats}
                title={strings.costSummary}
                visible={isFeatureAvailable(
                  activeClinic,
                  ClinicFeature.MONTHLY_CHARGES
                )}
              />
              <UrlTab
                currentTabKey={currentTab}
                tabKey={GlobalVetSettingsTabs.payments}
                title={strings.payments}
                visible={isFeatureAvailable(
                  activeClinic,
                  ClinicFeature.MONTHLY_CHARGES
                )}
              />
              <UrlTab
                currentTabKey={currentTab}
                tabKey={GlobalVetSettingsTabs.settings}
                title={strings.settings}
              />
            </ul>
          </div>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <LocationIndicator
                    location={GlobalVetSettingsTabs.usageStats}
                    setLocation={(location: GlobalVetSettingsTabs) => {
                      setCurrentTab(location);
                    }}
                  />
                  <UsageStatisticsTab clinic={activeClinic} />
                </>
              }
            />
            <Route
              path={`${GlobalVetSettingsTabs.costStats}`}
              element={
                <>
                  <LocationIndicator
                    location={GlobalVetSettingsTabs.costStats}
                    setLocation={(location: GlobalVetSettingsTabs) => {
                      setCurrentTab(location);
                    }}
                  />
                  <CostStatisticsTab clinic={activeClinic} />
                </>
              }
            />
            <Route
              path={`${GlobalVetSettingsTabs.payments}`}
              element={
                <>
                  <LocationIndicator
                    location={GlobalVetSettingsTabs.payments}
                    setLocation={(location: GlobalVetSettingsTabs) => {
                      setCurrentTab(location);
                    }}
                  />
                  <div className="">
                    <PaymentsTab
                      isActiveTab={
                        currentTab === GlobalVetSettingsTabs.payments
                      }
                      clinicId={activeClinic?.id}
                    />
                  </div>
                </>
              }
            />
            <Route
              path={`${GlobalVetSettingsTabs.settings}`}
              element={
                <>
                  <LocationIndicator
                    location={GlobalVetSettingsTabs.settings}
                    setLocation={(location: GlobalVetSettingsTabs) => {
                      setCurrentTab(location);
                    }}
                  />
                  <GlobalVetOtherSettings
                    clinic={activeClinic}
                    refresh={() => {
                      setReloadClinic((prev) => !prev);
                    }}
                  />
                </>
              }
            />
          </Routes>
        </div>
      </section>
    </main>
  );
};

export default Loader(GlobalVetSettings);
