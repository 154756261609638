/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import {
  GiroAccountResponse,
  GiroAccountType,
} from "../../../../../models/clinic/GiroAccountResponse";
import EditGiroAccountsModal from "./EditGiroAccountsModal";
import InfoTextWithLabel from "../../../../../components/InfoTextWithLabel";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import { Pencil } from "../../../../../common/Icons/Pencil";
import Button from "../../../../../components/Button";
import { canManageClinic } from "../../../../../util/helperFunctions";

interface Props {
  clinic: ClinicResponse;
  setClinic: (org: ClinicResponse) => void;
}

const GiroAccounts: React.FC<Props> = ({ clinic, setClinic }: Props) => {
  const [showGiroAccountsModal, setShowGiroAccountsModal] =
    useState<boolean>(false);

  const bankGirotAccount = clinic?.giroAccounts?.find(
    (account: GiroAccountResponse) => account.type === GiroAccountType.BANKGIROT
  )?.account;

  const plusGirotAccount = clinic?.giroAccounts?.find(
    (i) => i.type === GiroAccountType.PLUSGIROT
  )?.account;

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 flex items-center">
          <p className="uppercase">{strings.bankAndPlusGiroAccount}</p>
          <div className="ml-auto">
            <Button
              variant="icon"
              onClick={() => setShowGiroAccountsModal(true)}
              hidden={!canManageClinic(clinic)}
            >
              <Pencil />
            </Button>
          </div>
        </div>
        <div className="p-4 space-y-4">
          <div>{strings.giroOrgToolTip}</div>
          <InfoTextWithLabel
            label="Bankgirot"
            text={bankGirotAccount}
            inputLike
            widthPercent={100}
          />
          <InfoTextWithLabel
            label="Plusgirot"
            text={plusGirotAccount}
            inputLike
            widthPercent={100}
          />
        </div>
        <EditGiroAccountsModal
          isOpen={showGiroAccountsModal}
          close={() => {
            setShowGiroAccountsModal(false);
          }}
          refresh={(data: GiroAccountResponse[]) =>
            setClinic({ ...clinic, giroAccounts: data })
          }
          clinicId={clinic.id}
          bankgirotAccount={bankGirotAccount}
          plusgirotAccount={plusGirotAccount}
        />
      </div>
    </>
  );
};

export default GiroAccounts;
