/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import SimpleInput from "./SimpleInput";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { InventoryItemUnit } from "../../models/inventory/item/InventoryItemUnit";
import NumberFormatter from "../../util/NumberFormatter";
import { quantityUnits } from "../../common/Strings/Strings";

interface Props {
  disabled?: boolean;
  error?: FieldError;
  label?: string;
  onChange: (price?: number) => void;
  readOnly?: boolean;
  required?: boolean;
  unit?: InventoryItemUnit;
  value?: number;
}

const MultiplicationFieldsComponent = ({
  disabled,
  error,
  label,
  onChange,
  readOnly,
  unit,
  value,
}: Props) => {
  const [multiplier1, setMultiplier1] = useState<number>(1);
  const [multiplier2, setMultiplier2] = useState<number>(1);
  const [multiplier3, setMultiplier3] = useState<number>(1);
  const [block, setBlock] = useState<boolean>(true);

  useEffect(() => {
    /* Fill multiplier fields properly when default values are used. */
    if (
      block &&
      value !== undefined &&
      value !== multiplier1 * multiplier2 * multiplier3
    ) {
      setMultiplier3(value);
      setMultiplier2(1);
      setMultiplier1(1);
    }
  }, [value]);

  const update = (m1: number, m2: number, m3: number) => {
    if (block) {
      setBlock(false);
    }

    onChange(m1 * m2 * m3);
  };

  return (
    <div className={error ? "has-error" : ""}>
      <label>{label}</label>
      <div className="flex space-x-4 items-center">
        <SimpleInput
          autoComplete={AutoCompleteOptions.off}
          disabled={disabled}
          labelOff
          name="multiplier1"
          onChange={(v: string) => {
            setMultiplier1(Number(v));
            update(Number(v), multiplier2, multiplier3);
          }}
          readOnly={readOnly}
          type="number"
          value={multiplier1}
          width="4rem"
        />
        <div>x</div>
        <SimpleInput
          autoComplete={AutoCompleteOptions.off}
          disabled={disabled}
          labelOff
          name="multiplier2"
          onChange={(v: string) => {
            setMultiplier2(Number(v));
            update(multiplier1, Number(v), multiplier3);
          }}
          readOnly={readOnly}
          type="number"
          value={multiplier2}
          width="4rem"
        />
        <div>x</div>
        <SimpleInput
          autoComplete={AutoCompleteOptions.off}
          disabled={disabled}
          labelOff
          name="multiplier3"
          onChange={(v: string) => {
            setMultiplier3(Number(v));
            update(multiplier1, multiplier2, Number(v));
          }}
          readOnly={readOnly}
          type="number"
          value={multiplier3}
          width="4rem"
        />
        <div>=</div>
        <div>
          {value !== undefined ? NumberFormatter.format(value) : 1}{" "}
          {quantityUnits[unit || ""]}
        </div>
      </div>{" "}
    </div>
  );
};

export default MultiplicationFieldsComponent;
