/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { getCalendarNavigationClass } from "../../Day/MonthCalendarNavigation";
import { WeekCollaborator } from "../WeekController";
import { Shift } from "../../../../models/calendar/Shift";
import ShiftListViewRow from "./ShiftListViewRow";

interface Props {
  collaborators: WeekCollaborator[];
  reload(): void;
  selectedDay: string;
  shifts: Shift[];
  weekEnd: boolean;
}

const ShiftListView: React.FC<Props> = ({
  collaborators,
  reload,
  selectedDay,
  shifts,
  weekEnd,
}: Props) => {
  const [weekDays, setWeekDays] = useState<moment.Moment[]>([]);
  const [collabList, setCollabList] = useState<string[]>([]);

  useEffect(() => {
    const generateWeekDays = () => {
      const tempWeekDays = [];
      let dayCounter = 0;

      while (dayCounter < 7) {
        const currentDay = moment(selectedDay).weekday(dayCounter);

        if (!weekEnd && (currentDay.day() === 0 || currentDay.day() === 6)) {
          // If weekends are excluded, and it's a weekend day, do nothing
        } else {
          tempWeekDays.push(currentDay);
        }

        dayCounter += 1;
      }

      return tempWeekDays;
    };

    const updatedWeekDays = generateWeekDays();
    setWeekDays(updatedWeekDays);
  }, [selectedDay, shifts, weekEnd]);

  useEffect(() => {
    setCollabList(
      collaborators
        .filter((collab) => collab.checked)
        .map((collab) => collab.userId)
    );
  }, [collaborators]);

  return (
    <section>
      {weekDays.map((day: moment.Moment) => (
        <div key={day.unix()}>
          <div className="flex py-3">
            <div className="flex flex-col space-y-1 items-center mr-auto">
              <div className="uppercase text-xs font-semibold text-gray-500 dark:text-gray-400">
                {moment(day).format("dd")}
              </div>
              <div
                className={
                  getCalendarNavigationClass(day, selectedDay)
                    .numberContainerClass
                }
              >
                <span
                  className={
                    getCalendarNavigationClass(day, selectedDay).numberClass
                  }
                >
                  {" "}
                  {moment(day).format("D")}
                </span>
              </div>
            </div>
          </div>
          <div className="space-y-3">
            {shifts
              .filter((shift) => collabList.includes(shift.collaborator.userId))
              .filter((shift) => moment(shift.startDateTime).isSame(day, "day"))
              .map((shift: Shift) => (
                <ShiftListViewRow shift={shift} reload={reload} />
              ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export default ShiftListView;
