/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useRef, useState } from "react";
import moment from "moment";
import { FieldError } from "react-hook-form";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import ClickOutsideDetector from "../ClickOutsideDetector";
import { Colors } from "../../models/Colors";
import { strings } from "../../common/Strings/Strings";

interface TimeSelectInputProps {
  error?: FieldError;
  onChange(v: string | null): void;
  value: string | null;
}

export interface TimeSelectProps {
  classLabel?: string;
  className?: string;
  label?: string;
  labelIcon?: string | ReactElement;
  labelOff?: boolean;
  name: string;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  showRequired?: boolean;
  timeSuggestionStep?: number /* minutes */;
  width?: string;
}

const TimeSelectComponent = ({
  classLabel,
  className,
  error: outSideError,
  label,
  labelIcon,
  labelOff,
  name,
  onChange,
  optional,
  readOnly,
  required,
  showRequired,
  timeSuggestionStep = 30,
  value,
  width = "110px",
}: TimeSelectInputProps & TimeSelectProps): ReactElement => {
  const [inputText, setInputText] = useState<string>("");
  const [timeSuggestions, setTimeSuggestions] = useState<string[]>([]);
  const [isDown, setIsDown] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const scrollRef = useRef<HTMLUListElement | null>(null);

  const updateTimeSuggestions = (startTime: string | null) => {
    let startHour = moment(startTime, "LT").hours();
    let startMinutes = moment(startTime, "LT").minutes();

    if (Number.isNaN(startHour)) {
      startHour = 7;
    }

    if (Number.isNaN(startMinutes)) {
      startMinutes = 0;
    } else {
      startMinutes =
        Math.round(startMinutes / timeSuggestionStep) * timeSuggestionStep;
    }

    const start: moment.Moment = moment()
      .hours(startHour)
      .minutes(startMinutes);
    start.subtract(30, "minutes");

    const end: number = moment(start).add(1, "day").valueOf();
    const prev: moment.Moment = moment(start);

    const list: string[] = [];
    while (prev.valueOf() < end) {
      list.push(prev.format("LT"));
      prev.add(timeSuggestionStep, "minutes");
    }

    setTimeSuggestions(list);
  };

  useEffect(() => {
    scrollRef?.current?.scrollTo(0, 0);
  }, [isDown, inputText]);

  useEffect(() => {
    /* setting value from outside */
    if (value) {
      setInputText(value);
    } else {
      setInputText("");
    }

    updateTimeSuggestions(value);

    setError(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setError(outSideError?.message);
  }, [outSideError]);

  return (
    <div
      className={`${className || ""} ${error ? "has-error" : ""}`}
      style={{ width }}
    >
      <ClickOutsideDetector
        listen
        onClickOutside={() => {
          setIsDown(false);

          const momentTime = moment(inputText, "LT");
          if (momentTime.isValid() && value !== momentTime.format("LT")) {
            const formattedTime: string = momentTime.format("LT");
            setInputText(formattedTime);
            onChange(formattedTime);
            setError(undefined);
          }
        }}
      >
        <div className="dropdown" style={{ width }}>
          <div className={className}>
            {labelOff ? (
              <></>
            ) : (
              <label htmlFor={name} className={classLabel}>
                {labelIcon ? (
                  typeof labelIcon === "string" ? (
                    <i className={labelIcon} />
                  ) : (
                    <span style={{ verticalAlign: "text-bottom" }}>
                      {labelIcon}
                    </span>
                  )
                ) : (
                  ""
                )}{" "}
                {label}
                {showRequired ? " *" : ""}
                {optional ? (
                  <span style={{ color: Colors.INPUTBORDER }}>
                    {" "}
                    ({strings.optional})
                  </span>
                ) : (
                  <></>
                )}
              </label>
            )}
            <div
              className="flex items-center tw-input"
              onClick={() => {
                setIsDown(true);
              }}
              role="button"
              tabIndex={0}
            >
              <input
                autoComplete={AutoCompleteOptions.off}
                className="tw-input p-0 border-0 rounded-none focus:ring-sky-500 dark:focus:ring-sky-800 focus:bg-white"
                maxLength={8}
                name={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const inputValue = e.target.value;

                  setInputText(inputValue);

                  if (!moment(inputValue, "LT").isValid()) {
                    setError(strings.invalidDate);
                  } else {
                    setError(undefined);

                    if (inputValue.length > 0) {
                      updateTimeSuggestions(
                        moment(inputValue, "LT").format("LT")
                      );
                    }
                  }
                }}
                readOnly={readOnly}
                type="text"
                value={inputText}
              />
            </div>
          </div>
          <ul
            hidden={readOnly}
            className={`dropdown-menu w-full ${isDown ? "show" : ""}`}
            style={{
              maxHeight: "300px",
              minWidth: 0,
              padding: 0,
              overflowY: "scroll",
            }}
            ref={scrollRef}
          >
            {timeSuggestions.map((suggestion: string, index) => (
              <li
                className="dropdown-item"
                key={index}
                onClick={() => {
                  setInputText(suggestion);
                  onChange(suggestion);
                  setError(undefined);
                  setIsDown(false);
                }}
                style={{
                  cursor: "pointer",
                  padding: "6px 16px",
                  backgroundColor:
                    suggestion === moment(inputText, "LT").format("LT")
                      ? "#f2f2f2"
                      : undefined,
                }}
              >
                <div className="input-field-label" style={{ fontWeight: 400 }}>
                  {suggestion}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </ClickOutsideDetector>
    </div>
  );
};

export default TimeSelectComponent;
