/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { axiosCommon } from "../util/axiosSetup";
import { ServiceFeeResponse } from "../models/servicefee/ServiceFeeResponse";
import { ServiceFeeRequest } from "../models/servicefee/ServiceFeeRequest";
import { PageResponse } from "../models/PageResponse";

export default class ServiceFeeApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static addServiceFee(
    clinicId: string,
    request: ServiceFeeRequest
  ): ApiPromise<ServiceFeeResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/service-fees`;
    return axiosCommon.post(url, request);
  }

  static getServiceFeesOfClinic(
    clinicId: string,
    pageSize?: number,
    pageNumber?: number
  ): ApiPromise<PageResponse<ServiceFeeResponse>> {
    let url = `${this.baseUrl}/clinics/${clinicId}/service-fees`;
    if (pageSize) {
      url += `?pageSize=${pageSize}&pageNumber=${pageNumber || 0}`;
    }
    return axiosCommon.get(url);
  }

  static updateServiceFee(
    clinicId: string,
    feeId: string,
    request: ServiceFeeRequest
  ): ApiPromise<ServiceFeeResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/service-fees/${feeId}`;
    return axiosCommon.put(url, request);
  }

  static removeServiceFee(clinicId: string, feeId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/service-fees/${feeId}`;
    return axiosCommon.delete(url);
  }

  static searchServiceFeesOfClinic(
    clinicId: string,
    query: string,
    pageSize?: number,
    pageNumber?: number
  ): ApiPromise<PageResponse<ServiceFeeResponse>> {
    let url = `${this.baseUrl}/clinics/${clinicId}/service-fees/search?query=${query}`;
    if (pageSize) {
      url += `&pageSize=${pageSize}&pageNumber=${pageNumber || 0}`;
    }
    return axiosCommon.get(url);
  }
}
