/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactNode, useEffect, useRef, useState } from "react";

interface Props {
  children: (isHovered: boolean, isRecentlyHovered: boolean) => ReactNode;
  className?: string;
  hoverTimeout?: number;
}

/**
 * This component manages hover and recent hover states for a DOM element.
 * It detects mouse enter and leave events, and manages a timeout to determine
 * if the mouse recently moved inside or out of the element.
 *
 * Props:
 * - children: A function that receives two boolean arguments (isHovered, isRecentlyHovered),
 *   and returns ReactNode. This function is called to render content based on the current
 *   hover states of the element.
 * - hoverTimeout: Optional. Specifies the timeout duration in milliseconds to consider the
 *   element recently hovered after mouse leave. Defaults to 1000 milliseconds.
 *
 * Example usage:
 * ```tsx
 * <HoverChecker hoverTimeout={500}>
 *   {(isHovered, isRecentlyHovered) => (
 *     <div className="relative">
 *       <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
 *         Hover Me
 *       </button>
 *       {isHovered && (
 *         <div className={`absolute top-0 left-0 bg-gray-200 p-2 rounded ${isRecentlyHovered ? 'opacity-100' : 'opacity-0'}`}>
 *           <p className="text-sm text-blue-700">Controls shown on hover</p>
 *         </div>
 *       )}
 *     </div>
 *   )}
 * </HoverChecker>
 * ```
 */
const HoverChecker: React.FC<Props> = ({
  children,
  className,
  hoverTimeout = 1000,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isRecentlyHovered, setIsRecentlyHovered] = useState(false);
  const hoverTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsRecentlyHovered(true);
    if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current);
      hoverTimerRef.current = null;
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current);
    }
    hoverTimerRef.current = setTimeout(() => {
      setIsRecentlyHovered(false);
    }, hoverTimeout);
  };

  const handleMouseMove = () => {
    if (!isHovered) {
      setIsHovered(true);
    }
    if (!isRecentlyHovered) {
      setIsRecentlyHovered(true);
    }
    if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current);
    }
    hoverTimerRef.current = setTimeout(() => {
      setIsRecentlyHovered(false);
    }, hoverTimeout);
  };

  // Cleanup
  useEffect(
    () => () => {
      if (hoverTimerRef.current) {
        clearTimeout(hoverTimerRef.current);
      }
    },
    []
  );

  return (
    <div
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {children(isHovered, isRecentlyHovered)}
    </div>
  );
};

export default HoverChecker;
