/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import {
  canManageClinic,
  getGeneralError,
} from "../../../../util/helperFunctions";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import { ClinicPharmacyResponse } from "../../../../models/clinic/ClinicPharmacyResponse";
import { Pharmacy } from "../../../../models/pharmacy/Pharmacy";
import PharmacyApi from "../../../../api/PharmacyApi";
import hardcodedStrings from "../../../ePrescription/fixValues/hardcodedStrings";
import GeoApi from "../../../../api/GeoApi";
import { GeoPharmacy } from "../../../../models/pharmacy/GeoPharmacy";
import PharmacyForm from "../../../ePrescription/sections/pharmacy/PharmacyForm";
import ClinicPharmacyApi from "../../../../api/ClinicPharmacyApi";
import { ClinicPharmacyRequest } from "../../../../models/clinic/ClinicPharmacyRequest";
import { ClinicPharmacyOrdinalRequest } from "../../../../models/clinic/ClinicPharmacyOrdinalRequest";
import LoaderInline from "../../../../components/LoaderInline";
import CloseButton from "../../../../components/CloseButton";
import { AddressRequest } from "../../../../models/contact/AddressRequest";
import Button from "../../../../components/Button";
import { Trash } from "../../../../common/Icons/Trash";
import { ChevronUp } from "../../../../common/Icons/ChevronUp";
import { ChevronDown } from "../../../../common/Icons/ChevronDown";
import { PlusIcon } from "../../../../common/Icons/PlusIcon";
import EmptyListText from "../../../../components/EmptyListText";
import AlertBox from "../../../../components/AlertBox";
import Modal from "../../../../components/Modal/Modal";

interface Props {
  clinicResponse: ClinicResponse;
}

const AffiliatedPharmaciesTab: React.FC<Props> = ({
  clinicResponse,
}: Props) => {
  const [clinicPharmacies, setClinicPharmacies] = useState<
    ClinicPharmacyResponse[]
  >(clinicResponse?.affiliatedSwedishPharmacies || []);
  const [geoPharmacies, setGeoPharmacies] = useState<GeoPharmacy[]>([]);
  const [allPharmacies, setAllPharmacies] = useState<Pharmacy[]>([]);
  const [pharmacy, setPharmacy] = useState<Pharmacy | undefined>();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadOrdinal, setLoadOrdinal] = useState<boolean>(false);
  const [loadDelete, setLoadDelete] = useState<boolean>(false);

  const openAddPharmacyModal = () => {
    setModalOpen(true);
  };

  const closeAddPharmacyModal = () => {
    setModalOpen(false);
    setError(null);
  };

  useEffect(() => {
    const getPharmacies = async () => {
      try {
        const response = await PharmacyApi.getPharmacies();
        setAllPharmacies(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);

    void getPharmacies();
  }, []);

  useEffect(() => {
    const getGeoFromAddresses = async (addresses: AddressRequest[]) => {
      try {
        const response = await GeoApi.getGeoFromAddresses(addresses);
        const resultPharmacies: GeoPharmacy[] = [];
        response.data.forEach((googlePharmacy: any, index: number) => {
          const temp: GeoPharmacy = allPharmacies[index];

          if (
            googlePharmacy[0] &&
            googlePharmacy[0].geometry &&
            googlePharmacy[0].geometry.location
          ) {
            temp.lat = googlePharmacy[0].geometry?.location.lat;
            temp.lng = googlePharmacy[0].geometry?.location.lng;
            resultPharmacies.push(temp);
          }
        });

        setGeoPharmacies(resultPharmacies);
      } catch (err) {
        setError(await getGeneralError(err));
      }
    };

    if (allPharmacies.length > 0) {
      const addresses: AddressRequest[] = [];

      allPharmacies.forEach((p: Pharmacy) => {
        const address: AddressRequest = {
          zip: p.zipCode || "",
          street: p.officeAddress || "",
          city: p.city || "",
          countryCode: hardcodedStrings.swedishCountryCode,
        };
        addresses.push(address);
      });

      void getGeoFromAddresses(addresses);
    }
  }, [allPharmacies]);

  const pharmacyFormResult = (p: Pharmacy | undefined): void => {
    setPharmacy(p);
  };

  const submit = async () => {
    if (pharmacy) {
      const {
        glnCode,
        shortName,
        zipCode,
        officeAddress,
        longName,
        countyName,
        city,
        municipalityName,
        prescriptionPhone,
        generalPhone,
        mailingAddress,
      } = pharmacy;

      const request: ClinicPharmacyRequest = {
        glnCode,
        shortName,
        longName,
        mailingAddress,
        officeAddress,
        zipCode,
        city,
        countyName,
        municipalityName,
        generalPhone,
        prescriptionPhone,
      };

      try {
        const response =
          await ClinicPharmacyApi.addAffiliatedSwedishPharmacyToClinic(
            clinicResponse.id,
            request
          );
        setClinicPharmacies(response.data.affiliatedSwedishPharmacies);
        closeAddPharmacyModal();
      } catch (err) {
        setError(await getGeneralError(err));
      }
    }
  };

  const changeOrder = async (pharmacyId: string, newOrder: number) => {
    if (newOrder < 0 || newOrder >= clinicPharmacies.length) {
      return;
    }

    setLoadOrdinal(true);

    const request: ClinicPharmacyOrdinalRequest = {
      ordinal: newOrder,
    };

    try {
      const response =
        await ClinicPharmacyApi.setAffiliatedSwedishPharmacyOrdinalInClinic(
          clinicResponse.id,
          pharmacyId,
          request
        );
      setClinicPharmacies(response.data.affiliatedSwedishPharmacies);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoadOrdinal(false);
    }
  };

  const deletePharmacy = async (pharmacyId: string) => {
    setLoadDelete(true);

    try {
      const response =
        await ClinicPharmacyApi.removeAffiliatedSwedishPharmacyFromClinic(
          clinicResponse.id,
          pharmacyId
        );
      setClinicPharmacies(response.data.affiliatedSwedishPharmacies);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoadDelete(false);
    }
  };

  const readOnly = !canManageClinic(clinicResponse);

  return (
    <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
      <div className="p-4">
        <p className="uppercase">{strings.affiliatedSwedishPharmacies}</p>
      </div>
      <div className="p-4 space-y-4">
        <div>{strings.clinicPharmacyTooltip}</div>
        <Button variant="link" onClick={openAddPharmacyModal} hidden={readOnly}>
          <div className="flex">
            <PlusIcon /> <span> {strings.addPharmacy}</span>
          </div>
        </Button>
        {loading ? (
          <div className="tw-table-container py-3.5 px-4">
            <LoaderInline />
          </div>
        ) : (
          <>
            {clinicPharmacies.length === 0 ? (
              <div className="tw-table-container py-3.5 px-4">
                <EmptyListText />
              </div>
            ) : (
              <div className="tw-table-container">
                <table className="tw-table" id="paymentMethods">
                  <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
                    <tr>
                      <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        {strings.name}
                      </th>
                      <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        {strings.address}
                      </th>
                      <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        {strings.phone}
                      </th>
                      <th
                        hidden={readOnly}
                        className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        {strings.order}
                      </th>
                      <th
                        hidden={readOnly}
                        className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                      >
                        {strings.delete}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                    {clinicPharmacies
                      ?.sort((p, p1) => p1.priorityOrdinal - p.priorityOrdinal)
                      .map((affiliatedPharmacy: ClinicPharmacyResponse) => (
                        <tr key={affiliatedPharmacy.glnCode}>
                          <td className="px-4 py-3 text-sm font-medium">
                            {affiliatedPharmacy.shortName}
                          </td>
                          <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                            {affiliatedPharmacy.mailingAddress}
                            <br />
                            {affiliatedPharmacy.zipCode}{" "}
                            {affiliatedPharmacy.city}
                          </td>
                          <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                            <div>{affiliatedPharmacy.generalPhone}</div>
                            <div>{affiliatedPharmacy.prescriptionPhone}</div>
                          </td>
                          <td
                            hidden={readOnly}
                            className="px-4 py-3 text-sm font-medium whitespace-nowrap"
                          >
                            <Button
                              variant="icon"
                              disabled={loadOrdinal}
                              loading={loadOrdinal}
                              onClick={() => {
                                void changeOrder(
                                  affiliatedPharmacy.id,
                                  affiliatedPharmacy.priorityOrdinal + 1
                                );
                              }}
                              showTextWhileLoading={false}
                              small
                            >
                              <ChevronUp />
                            </Button>
                            <div className="mt-1">
                              <Button
                                type="button"
                                variant="icon"
                                disabled={loadOrdinal}
                                onClick={() => {
                                  void changeOrder(
                                    affiliatedPharmacy.id,
                                    affiliatedPharmacy.priorityOrdinal - 1
                                  );
                                }}
                                showTextWhileLoading={false}
                                small
                              >
                                <ChevronDown />
                              </Button>
                            </div>
                          </td>
                          <td
                            hidden={readOnly}
                            className="px-4 py-3 text-sm font-medium whitespace-nowrap text-right"
                          >
                            <Button
                              showTextWhileLoading={false}
                              className="tw-link"
                              disabled={loadDelete}
                              loading={loadDelete}
                              onClick={() => {
                                void deletePharmacy(affiliatedPharmacy.id);
                              }}
                              variant="icon"
                            >
                              <Trash />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
        <Button variant="link" onClick={openAddPharmacyModal} hidden={readOnly}>
          <div className="flex">
            <PlusIcon /> <span> {strings.addPharmacy}</span>
          </div>
        </Button>
      </div>
      <Modal handleClose={closeAddPharmacyModal} show={isModalOpen}>
        <Modal.Header title={strings.affiliatedSwedishPharmacies} />
        <Modal.Body>
          <PharmacyForm
            selectPharmacy={pharmacyFormResult}
            geoPharmacies={geoPharmacies}
          />
          <AlertBox message={error} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-main-button"
            disabled={loading}
            loading={loading}
            onClick={submit}
          >
            {strings.save}
          </Button>
          <CloseButton onClick={closeAddPharmacyModal} text={strings.cancel} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AffiliatedPharmaciesTab;
