/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
  localDateFormat,
  roundToNearestTime,
} from "../../../../util/helperFunctions";
import { ReservationResponse } from "../../../../models/reservation/ReservationResponse";
import { TodayShift } from "../../../../models/calendar/TodayShift";
import LoaderInline from "../../../../components/LoaderInline";
import {
  CalendarTableRowUnit,
  TupleType,
  ZipType,
} from "../../../../models/calendar/Rendering/CalendarTableRowUnit";
import { CalendarReservation } from "../../../../models/calendar/CalendarReservation";
import {
  CalendarRowsByColumn,
  ShiftToRender,
} from "../../../../models/calendar/Rendering/CalendarRowsByColumn";
import NewCalendarDay from "./NewCalendarDay";
import { ViewTypes } from "../../MainCalendar";
import DoctorDashboard from "./DoctorDashboard";
import {
  calendarTimeGapsMin,
  minReservationLength,
  momentDateFormat,
  momentTimeFormat,
} from "../../calendarConfig";
import { useUser } from "../../../../contexts/UserContext";

interface Props {
  selectedDay: string;
  calendarStartTime: moment.Moment;
  calendarEndTime: moment.Moment;
  handleNewReservationModal(
    open: boolean,
    dateAndTime?: { time: string; date: string },
    selectedShift?: TodayShift
  ): void;
  refreshReservationInProgress(): void;
  reservations: CalendarReservation[];
  todayShifts: TodayShift[];
  reloadReservations(): void;
  viewType: ViewTypes;
}

interface CollaboratorMap {
  [key: string]: TodayShift[];
}

interface DisjointAndParallelShifts {
  disjoint: TodayShift[];
  parallel: TodayShift[];
}

interface OptimizedShifts {
  [key: string]: {
    shifts: DisjointAndParallelShifts;
  };
}

const DayScheduler: React.FC<Props> = ({
  selectedDay,
  calendarEndTime,
  calendarStartTime,
  handleNewReservationModal,
  refreshReservationInProgress,
  reservations: dayData,
  todayShifts,
  reloadReservations,
  viewType,
}: Props) => {
  const startTimeOfDay = moment(selectedDay, momentDateFormat).set({
    hour: calendarStartTime.hour(),
    minute: calendarStartTime.minute(),
    second: calendarStartTime.second(),
  });

  const endTimeOfDay = moment(selectedDay, momentDateFormat).set({
    hour: calendarEndTime.hour(),
    minute: calendarEndTime.minute(),
    second: calendarEndTime.second(),
  });
  const [schedule, setSchedule] = useState<CalendarRowsByColumn>();
  const { user } = useUser();

  const selectDisjointShifts = (
    shifts: TodayShift[]
  ): DisjointAndParallelShifts => {
    const result: DisjointAndParallelShifts = {
      disjoint: [],
      parallel: [],
    };

    /* if a shift has no overlap with the others, it goes to the disjoint, goes to the parallel otherwise */
    shifts.forEach((shift: TodayShift) => {
      const start = moment(shift.startTime);
      const end = moment(shift.endTime);
      const temp: TodayShift[] = [];
      let toPush = true;

      if (result.disjoint.length === 0) {
        result.disjoint.push(shift);
      } else {
        result.disjoint.forEach((otherShift: TodayShift) => {
          const otherStart = moment(otherShift.startTime);
          const otherEnd = moment(otherShift.endTime);

          if (
            start.isBetween(otherStart, otherEnd, "minutes", "[]") ||
            end.isBetween(otherStart, otherEnd, "minutes", "[]") ||
            otherStart.isBetween(start, end, "minutes", "[]") ||
            otherEnd.isBetween(start, end, "minutes", "[]")
          ) {
            result.parallel.push(shift);
            toPush = false;
          } else {
            temp.push(shift);
          }
        });
      }
      if (toPush) {
        result.disjoint = result.disjoint.concat(temp);
      }
    });

    /* remove duplicates */
    const d = new Set(result.disjoint);
    const p = new Set(result.parallel);

    result.disjoint = [...d].sort(
      (a: TodayShift, b: TodayShift) =>
        moment(a.startTime).valueOf() - moment(b.startTime).valueOf()
    );
    result.parallel = [...p].sort(
      (a: TodayShift, b: TodayShift) =>
        moment(a.startTime).valueOf() - moment(b.startTime).valueOf()
    );

    return result;
  };

  const sortShiftsAndOptimiseColumns = useCallback(
    (shifts: TodayShift[]): CollaboratorMap => {
      const collaborators: CollaboratorMap = {};

      /* 1. sort by collaborators */
      const shiftsByCollaborator: {
        [key: string]: {
          shifts: TodayShift[];
        };
      } = {};

      shifts.forEach((shift: TodayShift) => {
        if (shiftsByCollaborator[shift.collaborator.userId]) {
          shiftsByCollaborator[shift.collaborator.userId].shifts.push(shift);
        } else {
          shiftsByCollaborator[shift.collaborator.userId] = {
            shifts: [shift],
          };
        }
      });

      /* 2. select disjoint shifts */
      const sortedShiftsByCollaborator: OptimizedShifts = {};

      Object.keys(shiftsByCollaborator).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(shiftsByCollaborator, key)) {
          sortedShiftsByCollaborator[key] = {
            shifts: selectDisjointShifts(shiftsByCollaborator[key].shifts),
          };
        }
      });

      /* 3. create columns */
      /*  column1 -> disjoint shifts by XY
          column2 -> disjoint shifts by ZD
          ...
          columnN -> parallel shift By XY
          columnN+1 -> parallel shift By XY
          ...
          columnN+k -> parallel shift By ZD
          columnN+k+1 -> parallel shift By ZD
          ...
       */
      let columnIndex = 0;

      Object.keys(shiftsByCollaborator).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(shiftsByCollaborator, key)) {
          collaborators[`column${columnIndex}`] =
            sortedShiftsByCollaborator[key].shifts.disjoint;

          sortedShiftsByCollaborator[key].shifts.parallel.forEach(
            (parallelShift) => {
              columnIndex += 1;
              collaborators[`column${columnIndex}`] = [parallelShift];
            }
          );

          columnIndex += 1;
        }
      });

      return collaborators;
    },
    []
  );

  /* Generate times for the first column of the calendar table, based on the given time gap (default 15 min), start and end times. */
  const generateTimes = () => {
    const timeArray = [];
    let newTime = startTimeOfDay;

    while (moment(newTime).diff(endTimeOfDay, "minutes") <= 0) {
      timeArray.push(moment(newTime).format(momentTimeFormat));
      newTime = moment(newTime).add(minReservationLength, "m");
    }

    return timeArray;
  };

  /* Generate rows for the calendar table from reservations, by collaborator */
  const arrayFactory = (shift: TodayShift): Array<CalendarTableRowUnit> => {
    const timeArray = generateTimes();
    const tableRows: Array<CalendarTableRowUnit> = [];
    const unit = 60000 * minReservationLength;
    const collabReservations: CalendarReservation[] = shift.reservations || [];

    collabReservations.sort((a, b) =>
      a.startDateTime > b.startDateTime ? 1 : -1
    );

    const shiftStartTime = moment(shift.startTime, localDateFormat());
    const shiftEndTime = moment(shift.endTime, localDateFormat());

    let closestUnitShiftStartTime: number = roundToNearestTime(
      shift.startTime,
      unit
    );

    timeArray.forEach((i: string) => {
      let rowTuple: CalendarTableRowUnit = [
        undefined,
        TupleType.EMPTY,
        ZipType.NONE,
      ];

      /* If shift starts before the calendar but ends in the calendar */
      if (closestUnitShiftStartTime < startTimeOfDay.valueOf() && 
          shiftEndTime.isAfter(startTimeOfDay) && 
          shiftEndTime.isBefore(endTimeOfDay)) {
            closestUnitShiftStartTime = startTimeOfDay.valueOf();
      }

      const timeOfDay = moment(i, momentTimeFormat);
      const exactTimeOfDay = moment(selectedDay, momentDateFormat).set({
        hour: timeOfDay.hour(),
        minute: timeOfDay.minute(),
        second: timeOfDay.second(),
      });

      if (closestUnitShiftStartTime === exactTimeOfDay.valueOf()) {
        rowTuple[0] = { shift };
        rowTuple[1] = TupleType.SHIFT_START;
      } else if (
        exactTimeOfDay.isBetween(shiftStartTime, shiftEndTime, "minute", "()")
      ) {
        rowTuple[1] = TupleType.SHIFT;
      }

      collabReservations.forEach((j) => {
        let roundedReservationStartTime = roundToNearestTime(
          j.startDateTime,
          unit
        );

        if (closestUnitShiftStartTime > roundedReservationStartTime) {
          roundedReservationStartTime = closestUnitShiftStartTime;
        } 

        if (
          exactTimeOfDay.isBetween(
            moment(roundedReservationStartTime),
            moment(j.endDateTime, localDateFormat()),
            "minute",
            "[)"
          )
        ) { 
          let reservationLength = moment(j.endDateTime).diff(
            moment(roundedReservationStartTime),
            "minutes"
          );

          if (endTimeOfDay.isBefore(j.endDateTime)) {
            reservationLength = endTimeOfDay.diff(
              moment(roundedReservationStartTime),
              "minutes"
            );
          }

          j.rowSpan = Math.ceil(reservationLength);

          rowTuple = [
            { reservation: j, shift },
            TupleType.RESERVATION_START,
            ZipType.NONE,
          ];
        }
      });

      tableRows.push(rowTuple);
    });

    const rows = tableRows.length;

    for (let i = 0; i < rows; i += 1) {
      if (tableRows[i][1] !== undefined) {
        if (
          (tableRows[i] && tableRows[i][0]?.reservation?.id) ===
          ((tableRows[i - 1] && tableRows[i - 1][0]?.reservation?.id) || true)
        ) {
          tableRows[i][1] = TupleType.RESERVATION;
        }
      }
    }

    return tableRows;
  };

  /* param: shifts: disjoint shifts for the same column */
  const createColumns = (shifts: TodayShift[]): ShiftToRender => {
    const collection: Array<Array<CalendarTableRowUnit>> = [];

    /* 1. create table rows for each shift */
    shifts.forEach((shift: TodayShift) => {
      collection.push(arrayFactory(shift));
    });

    /* 2. merge rows */
    const mergedRows: Array<CalendarTableRowUnit> = [];
    const rowsCount = collection[0].length;

    const basicEmptyRow: CalendarTableRowUnit = [
      undefined,
      TupleType.EMPTY,
      ZipType.NONE,
    ];
    let areCellsTheSameType = true;

    for (let i = 0; i < rowsCount; i += 1) {
      let row: CalendarTableRowUnit = basicEmptyRow;

      collection.forEach((rows: Array<CalendarTableRowUnit>) => {
        if (
          rows[i][0] !== basicEmptyRow[0] ||
          rows[i][1] !== basicEmptyRow[1]
        ) {
          row = rows[i];
        }
      });

      mergedRows.push([...row]);

      /* "Zip" blocks if the cells are the same */
      if (i > 0 && i % calendarTimeGapsMin > 0) {
        areCellsTheSameType =
          areCellsTheSameType &&
          (mergedRows[i][1] === mergedRows[i - 1][1] ||
            mergedRows[i - 1][1] === TupleType.SHIFT_START);
        mergedRows[i][2] = areCellsTheSameType ? ZipType.ZIPPED : ZipType.NONE;
      }

      if (i % calendarTimeGapsMin === 0 && i >= calendarTimeGapsMin) {
        if (areCellsTheSameType) {
          mergedRows[i - calendarTimeGapsMin][2] = ZipType.ZIP_NEXT_BLOCK;
        }
        areCellsTheSameType = true;
      }
    }

    return {
      rows: mergedRows,
      columnShift: {
        firstStartTime: shifts[0].startTime,
        collaboratorName: shifts[0].collaborator.fullName,
        collaboratorUserId: shifts[0].collaborator.userId,
        lastEndTime: shifts[shifts.length - 1].endTime,
        mergedShifts: shifts,
      },
    };
  };

  const generateSchedule = (): CalendarRowsByColumn => {
    dayData
      .sort(
        (r1: CalendarReservation, r2: CalendarReservation) =>
          moment(r1.startDateTime).valueOf() -
          moment(r2.startDateTime).valueOf()
      )
      .forEach((reservation: CalendarReservation) => {
        todayShifts.forEach((shift: TodayShift) => {
          const isPushedBefore =
            shift.reservations?.filter(
              (r: ReservationResponse) => r.id === reservation.id
            ).length === 0;
          if (
            shift.id === reservation.shiftId &&
            shift.reservations &&
            isPushedBefore
          ) {
            shift.reservations.push(reservation);
          }
        });
      });

    const sortedShifts = sortShiftsAndOptimiseColumns(todayShifts);

    const formattedReservationsByCollaborators: CalendarRowsByColumn = {};

    Object.keys(sortedShifts).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(sortedShifts, key)) {
        formattedReservationsByCollaborators[key] = createColumns(
          sortedShifts[key]
        );
      }
    });

    return formattedReservationsByCollaborators;
  };

  useEffect(() => {
    if (todayShifts && dayData) {
      const s = generateSchedule();

      const myShifts: { [key: string]: any } = {};
      const otherShifts: { [key: string]: any } = {};

      Object.keys(s).forEach((key) => {
        if (s[key]?.columnShift?.collaboratorUserId === user.userId) {
          myShifts[key] = s[key];
        } else {
          otherShifts[key] = s[key];
        }
      });
      setSchedule({ ...myShifts, ...otherShifts });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todayShifts, dayData]);

  if (schedule) {
    switch (viewType) {
      case ViewTypes.DOCTOR_DAY:
        return (
          <DoctorDashboard
            day={selectedDay}
            handleNewReservationModal={handleNewReservationModal}
            refreshReservationInProgress={refreshReservationInProgress}
            reloadReservations={reloadReservations}
            schedule={schedule}
            times={generateTimes()}
          />
        );
      default:
        return (
          <NewCalendarDay
            calendarEndTime={calendarEndTime}
            calendarStartTime={calendarStartTime}
            day={selectedDay}
            handleNewReservationModal={handleNewReservationModal}
            refreshReservationInProgress={refreshReservationInProgress}
            reloadReservations={reloadReservations}
            schedule={schedule}
            times={generateTimes()}
          />
        );
    }
  } else return <LoaderInline className="mt-3" />;
};

export default DayScheduler;
