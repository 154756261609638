/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState, Fragment } from "react";
import { petSpecies, strings } from "../../../common/Strings/Strings";
import { PetSpecies } from "../../../models/pet/PetSpeciesEnum";
import HematologyRow from "./HematologyRow";
import { ExaminationType } from "../../../models/medical/local/ExaminationType";
import BiochemistryRow from "./BiochemistryRow";
import CloseButton from "../../../components/CloseButton";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";

interface Props {
  open: boolean;
  close(): void;
  species: PetSpecies;
  returnResult(results: Array<number>): void;
  parameters: Array<string>;
  title: string;
  type: ExaminationType;
}

const ExaminationModal: React.FC<Props> = ({
  open,
  close,
  species,
  returnResult,
  parameters,
  title,
  type,
}: Props) => {
  const [results, setResults] = useState<Array<number>>([]);

  const updateResults = (value: number, index: number) => {
    const newResult = results;
    newResult[index] = value;
    setResults(newResult);
  };

  const submit = () => {
    returnResult(results);
    close();
  };

  return (
    <Modal show={open} handleClose={close} size="modal-md">
      <Modal.Header title={`${title} - ${petSpecies[species]}`} />
      <Modal.Body>
        <table className="tw-table">
          <thead className="tw-thead">
            <tr>
              <th className="tw-th">{strings.parameters}</th>
              <th className="tw-th">{strings.conventionalUnits}</th>
              <th className="tw-th">{strings.referenceRange}</th>
              <th className="tw-th">{strings.result}</th>
              <th className="tw-th" />
            </tr>
          </thead>
          <tbody className="tw-tbody">
            {parameters.map((h: string, index: number) => {
              switch (type) {
                case ExaminationType.Hematology:
                  return (
                    <Fragment key={index}>
                      <HematologyRow
                        index={index}
                        species={species}
                        updateResults={updateResults}
                      />
                    </Fragment>
                  );
                case ExaminationType.Biochemistry:
                  return (
                    <Fragment key={index}>
                      <BiochemistryRow
                        index={index}
                        species={species}
                        updateResults={updateResults}
                      />
                    </Fragment>
                  );
                default:
                  return <></>;
              }
            })}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" onClick={submit}>
          {strings.add}
        </Button>
        <CloseButton onClick={close} />
      </Modal.Footer>
    </Modal>
  );
};

export default ExaminationModal;
