/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import jwtDecode from "jwt-decode";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Flowbite, Navbar } from "flowbite-react";
import { simpleLightText } from "../../../../assets/AssetConfig";
import { Environments } from "../../../../models/Environments";
import EnvironmentBadge from "../../EnvironmentBadge";
import { SiteManagerMenuItem } from "../../MenuItem/MenuItemTypes";
import UserMenu from "../../UserMenu/UserMenu";
import { getAccessToken } from "../../../../util/LocalStorageVariables";
import { customTheme } from "../../Themes/NavTheme";
import { SiteManagerAuthority } from "../../../../models/management/SiteManagerAuthority";
import SiteManagerMenuItems from "../../MenuItem/SiteManagerMenuItems";
import SiteManagerNavItem from "./SiteManagerNavItem";

interface Props {
  environment: Environments;
  activeMenu?: SiteManagerMenuItem;
}

const NavSiteManager: React.FC<Props> = ({
  environment,
  activeMenu,
}: Props) => {
  const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");

  const authorities: string[] = jwtDecode(getAccessToken())?.authorities || [];

  const hasAuthority = (authority?: SiteManagerAuthority) => {
    if (!authority) {
      return false;
    }

    return (
      authorities.includes("MANAGER_FULL") || authorities.includes(authority)
    );
  };

  const hasAnySettingsAuthority = (): boolean =>
    hasAuthority(SiteManagerAuthority.MANAGER_CONFIG) ||
    hasAuthority(SiteManagerAuthority.MANAGER_FEES) ||
    hasAuthority(SiteManagerAuthority.MANAGER_POLICIES) ||
    hasAuthority(SiteManagerAuthority.MANAGER_MANAGERS);

  return (
    <>
      <Flowbite theme={{ theme: customTheme, dark: darkMode }}>
        <Navbar fluid id="manager-navbar">
          <Navbar.Brand href="/">
            <img alt="GlobalVet" className="w-24" src={simpleLightText} />
            <EnvironmentBadge
              className="ml-5 no-underline hover:no-underline"
              environment={environment}
            />
          </Navbar.Brand>
          <UserMenu />
        </Navbar>
      </Flowbite>
      <nav
        className="fixed z-20 top-14 left-0 shadow w-full h-14 px-4 lg:px-6 hidden xl:flex items-center bg-white dark:bg-gray-800 dark:border-b dark:border-gray-700"
        id="manager-second-navbar"
      >
        <div className="container flex flex-wrap items-center justify-between xl:justify-start mx-auto px-0">
          <div className="flex items-center justify-between hidden w-full xl:flex xl:w-auto xl:order-1">
            <ul className="flex flex-row space-x-8 font-medium">
              {SiteManagerMenuItems.map((item, index) => (
                <li key={index}>
                  <SiteManagerNavItem
                    menu={item.type}
                    activeMenu={activeMenu}
                    href={item.href}
                    visible={
                      item.type === "SITE_MANAGER_SETTINGS"
                        ? hasAnySettingsAuthority()
                        : hasAuthority(item.authority)
                    }
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavSiteManager;
