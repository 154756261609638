/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import PhoneNumber from "../../../../components/ReactHookFormFields/Specific/PhoneNumber";
import { ProfileSetupPhoneRequest } from "../../../../models/user/ProfileSetupPhoneRequest";
import { getGeneralError } from "../../../../util/helperFunctions";
import AlertBox from "../../../../components/AlertBox";
import { PhoneResponse } from "../../../../models/contact/PhoneResponse";
import UserPhoneVerification from "../../../../components/contact/user/phone/UserPhoneVerification";
import { Card } from "../../../../components/Cards/Card";
import { Phone } from "../../../../common/Icons/Phone";
import Button from "../../../../components/Button";
import UserApi from "../../../../api/UserApi";
import { PhoneType } from "../../../../models/contact/PhoneType";

interface Props {
  onNextStep(skip: boolean): void;
  skippable?: boolean;
}

type PhoneSetupSteps = "input" | "verify";

const PhoneSetup = ({ onNextStep, skippable = false }: Props): ReactElement => {
  const { control, handleSubmit } = useForm<ProfileSetupPhoneRequest>();
  const [step, setStep] = useState<PhoneSetupSteps>("input");
  const [phoneResponse, setPhoneResponse] = useState<PhoneResponse | null>(
    null
  );

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (values: ProfileSetupPhoneRequest) => {
    setLoading(true);

    try {
      const resp = await UserApi.setupUserProfilePhone(values);
      const phone = resp.data.phones[0];
      setPhoneResponse(phone);

      if (phone.phoneType === PhoneType.MOBILE) {
        setStep("verify");
      } else {
        onNextStep(false);
      }
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  switch (step) {
    case "input":
      return (
        <Card
          onAction={skippable ? () => onNextStep(true) : undefined}
          title={strings.mobilePhoneNumber}
        >
          <div className="flex items-center space-x-2">
            <Phone />
            <div className="font-semibold">{strings.mobilePhoneNumber}</div>
          </div>
          <div>
            <p>
              {strings.formatString(
                strings.primaryPhoneNumberDetails,
                <strong>{strings.primaryPhoneNumberDetailsMobilePhone}</strong>
              )}
            </p>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <PhoneNumber
              control={control}
              id="userPhoneSetup"
              label={strings.mobilePhoneNumber}
              name="mobilePhone"
              showRequired
              required
            />
            <AlertBox message={error} />
            <Button type="submit" disabled={loading}>
              {strings.saveAndContinue}
            </Button>
          </form>
        </Card>
      );
    case "verify":
      return (
        <UserPhoneVerification
          onNextStep={onNextStep}
          phoneResponse={phoneResponse}
        />
      );
    default:
      return <></>;
  }
};

export default PhoneSetup;
