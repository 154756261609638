/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useLayoutEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { Option } from "../../../models/Option";
import CloseButton from "../../../components/CloseButton";
import { PolicyResponse } from "../../../models/management/PolicyResponse";
import { LocalLanguageResponse } from "../../../models/language/LocalLanguageResponse";
import { CountrySettingsResponse } from "../../../models/management/CountrySettingsResponse";
import { PolicyType } from "../../../models/management/PolicyType";
import AlertBox from "../../../components/AlertBox";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { getGeneralError } from "../../../util/helperFunctions";
import GDPRApi from "../../../api/GDPRApi";
import { NewPolicyRequest } from "../../../models/management/NewPolicyRequest";
import Button from "../../../components/Button";
import HtmlEditorTextarea from "../../../components/HtmlEditorTextarea";
import Modal from "../../../components/Modal/Modal";

interface Props {
  country: CountrySettingsResponse;
  languages: LocalLanguageResponse[];
  onHide: () => void;
  onPolicyChanged: (policy: PolicyResponse) => void;
  policies: PolicyResponse[];
  policyName: string;
  policyType: PolicyType;
  show: boolean;
}

interface AddPolicyForm {
  languageCode: string;
  statement: string;
}

const AddPolicyModal: React.FC<Props> = ({
  country,
  languages,
  onHide,
  onPolicyChanged,
  policies,
  policyName,
  policyType,
  show,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const languageOptions = useMemo(() => {
    const filterAlreadyExistingLanguages = () => {
      const existingLanguages = policies
        .filter((i) => i.countryCode === country.countryCode)
        .map((i) => i.languageCode);

      return languages
        .filter((l) => !existingLanguages.includes(l.code))
        .map((l) => {
          const option: Option = {
            title: l.displayLanguage,
            value: l.code,
          };
          return option;
        });
    };

    return filterAlreadyExistingLanguages();
  }, [country.countryCode, languages, policies]);

  const createPolicy = async (formData: AddPolicyForm) => {
    setLoading(true);

    const request: NewPolicyRequest = {
      languageCode: formData.languageCode,
      countryCode: country.countryCode,
      type: policyType,
      statement: formData.statement,
    };

    try {
      const response = await GDPRApi.createPolicy(request);
      onPolicyChanged(response.data);
      onHide();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  const {
    watch,
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<AddPolicyForm>({
    mode: "onChange",
  });

  useLayoutEffect(() => {
    if (!show) {
      reset();
      setError(null);
    }
  }, [reset, show]);

  const languageCode = watch("languageCode");

  return (
    <Modal handleClose={onHide} show={show} size="modal-lg">
      <Modal.Header title={`${strings.addPolicy}: ${policyName}`} />
      <Modal.Body>
        <form
          className="space-y-6"
          id="addPolicyForm"
          onSubmit={handleSubmit(createPolicy)}
        >
          <Select
            label={`${strings.language}:`}
            name="languageCode"
            options={languageOptions}
            register={register}
            error={errors.languageCode}
            required
          />
          {languageCode && (
            <HtmlEditorTextarea
              setValue={(value) => setValue("statement", value)}
              value={getValues("statement")}
            />
          )}
        </form>
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || !isDirty || loading}
          form="addPolicyForm"
          loading={loading}
          type="submit"
          variant="primary"
        >
          {strings.save}
        </Button>
        <CloseButton onClick={onHide} />
      </Modal.Footer>
    </Modal>
  );
};

export default AddPolicyModal;
