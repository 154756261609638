/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const prescriptionIntakeEn = {
  QD: "1 time/day",
  BID: "2 times/day",
  TID: "3 times/day",
  QID: "4 times/day",
  QOD: "5 times/day",
  SD: "6 times/day",
  SED: "7 times/day",
  OD: "8 times/day",
  NOD: "9 times/day",
  DD: "10 times/day",
  DQD: "11 times/day",
  DBID: "12 times/day",
  QW: "1 times/week",
  BIW: "2 times/week",
  q3D: "every 3 days",
  QM: "1 time/month",
  q3M: "every 3 months"
};