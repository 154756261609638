/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ReactNode } from "react";
import {
  DoctorMenuItem,
  PetOwnerMenuItem,
  SiteManagerMenuItem,
} from "./MenuItemTypes";
import { ClinicFeature } from "../../../models/clinic/ClinicFeature";
import {
  getScreenSizeCategory,
  TailwindResponsiveBreakpoints,
} from "../../../util/TailwindResponsiveBreakpoints";
import { SiteManagerAuthority } from "../../../models/management/SiteManagerAuthority";

export interface MenuItem {
  type: DoctorMenuItem | PetOwnerMenuItem | SiteManagerMenuItem;
  name: string;
  href: string;
  icon: ReactNode;
}

export interface MenuItemDoctor extends MenuItem {
  type: DoctorMenuItem;
  clinicFeature: ClinicFeature | null;
}

export interface MenuItemPetOwner extends MenuItem {
  type: PetOwnerMenuItem;
}

export interface MenuItemSiteManager extends MenuItem {
  type: SiteManagerMenuItem;
  authority?: SiteManagerAuthority;
}

/* The number of menu items that are visible by default (not visible by clicking the 'More' icon) */
export const getMenuItemsVisible = () => {
  const screenSize = getScreenSizeCategory();

  switch (screenSize) {
    case TailwindResponsiveBreakpoints["3xs"]:
      return 2;
    case TailwindResponsiveBreakpoints["2xs"]:
      return 3;
    case TailwindResponsiveBreakpoints.xs:
      return 4;
    case TailwindResponsiveBreakpoints.sm:
      return 5;
    case TailwindResponsiveBreakpoints.md:
      return 6;
    case TailwindResponsiveBreakpoints.lg:
      return 7;
    case TailwindResponsiveBreakpoints.xl:
      return 7;
    case TailwindResponsiveBreakpoints["2xl"]:
      return 7;
    default:
      return 4;
  }
};
