/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import moment from "moment";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import IconBubble from "../../../components/IconBubble";
import { InventoryItemDetailsResponse } from "../../../models/inventory/item/Response/InventoryItemDetailsResponse";
import { InventoryItemStockResponse } from "../../../models/inventory/item/Response/InventoryItemStockResponse";
import Button from "../../../components/Button";
import ItemStockModal from "../Stock/ItemStockModal";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { Pencil } from "../../../common/Icons/Pencil";
import { Trash } from "../../../common/Icons/Trash";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import NumberFormatter from "../../../util/NumberFormatter";
import CostCalculator from "../../../util/CostCalculator";
import LowLevelIndicator from "../LowLevelIndicator";
import EmptyListText from "../../../components/EmptyListText";
import { useClinic } from "../../../contexts/ClinicContext";

interface Props {
  countryDetails?: CountryDetailsResponse;
  inventoryItem: InventoryItemDetailsResponse;
  reloadItem(): void;
}

const StockHistory: React.FC<Props> = ({
  countryDetails,
  inventoryItem,
  reloadItem,
}) => {
  const [openStockModal, setOpenStockModal] = useState<boolean>(false);
  const [itemStockToEdit, setItemStockToEdit] =
    useState<InventoryItemStockResponse>();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const { clinic } = useClinic();

  const deleteStock = async (stockId: string) => {
    setSuccess(null);
    setError(null);

    if (inventoryItem) {
      setDeleteLoading(true);

      try {
        await InventoryItemApi.deleteItemStock(inventoryItem.id, stockId);
        setSuccess(strings.stockDeletedSuccessfully);
        reloadItem();
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setDeleteLoading(false);
      }
    }
  };

  return (
    <div className="w-full md:tw-card space-y-6 md:p-8 my-6">
      <div className="flex items-center">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
          {inventoryItem?.name}
        </h2>
        <div className="ml-3">
          <IconBubble>
            {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
          </IconBubble>
        </div>
        <div className="ml-auto">
          <Button
            minW
            onClick={() => {
              setOpenStockModal(true);
            }}
          >
            {strings.refillStock}
          </Button>
        </div>
      </div>
      <AlertBox message={error} />
      <AlertBox message={success} type={AlertType.SUCCESS} />
      <div className="flex items-center">
        <div className="mr-2">
          {strings.stock}: <span className="font-bold">{NumberFormatter.format(inventoryItem?.stock || 0)}{" "}
          {quantityUnits[inventoryItem?.acquisitionUnit || ""]}</span>
        </div>
        <LowLevelIndicator
          lowLevel={inventoryItem?.lowLevel}
          stock={inventoryItem?.stock}
        />
      </div>
      <div className="tw-table-container">
        <table className="tw-table">
          <thead className="tw-thead">
            <tr>
              <th className="tw-th">{strings.date}</th>
              <th className="tw-th">{strings.quantity}</th>
              <th className="tw-th">{strings.acquisitionVat}</th>
              <th className="tw-th">{strings.acquisitionPrice}</th>
              <th className="tw-th">{strings.expirationDate}</th>
              <th />
            </tr>
          </thead>
          <tbody className="tw-tbody">
            {inventoryItem?.stocks?.length === 0 ? (
              <tr>
                <td
                  colSpan={6}
                  className="py-3.5 px-4 text-sm font-medium whitespace-nowrap"
                >
                  <EmptyListText />
                </td>
              </tr>
            ) : (
              <></>
            )}
            {inventoryItem?.stocks?.map((stock: InventoryItemStockResponse) => (
              <tr key={stock.id}>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {stock.dateTime && moment(stock.dateTime).format("LL")}
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {NumberFormatter.format(stock.quantity || 0)}{" "}
                  {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {stock.acquisitionVat} %
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    CostCalculator.getGrossPrice(
                      stock.acquisitionPrice || 0,
                      stock.acquisitionVat,
                      countryDetails,
                      clinic || undefined
                    ),
                    countryDetails?.currency
                  )}
                  <div className="text-xs text-gray-500">
                    {NumberFormatter.formatPrice(
                      stock.acquisitionPrice || 0,
                      stock.acquisitionCurrency
                    )}{" "}
                    {strings.plusVat}
                  </div>
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {!!stock.expirationDate &&
                    moment(stock.expirationDate).format("LL")}
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap text-right">
                  <Button
                    askAreYouSure={{
                      enable: true,
                      content: strings.formatString(
                        strings.areYouSureToDeleteItemStock,
                        <div className="font-bold">{inventoryItem.name}</div>
                      ),
                      title: strings.deleteStock,
                    }}
                    className="mr-3"
                    disabled={deleteLoading}
                    loading={deleteLoading}
                    onClick={() => {
                      void deleteStock(stock.id);
                    }}
                    variant="icon"
                  >
                    <Trash />
                  </Button>
                  <Button
                    onClick={() => {
                      setItemStockToEdit(stock);
                      setOpenStockModal(true);
                    }}
                    variant="icon"
                  >
                    <Pencil />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ItemStockModal
        close={(shouldReloadItem: boolean, successMessage?: string) => {
          setOpenStockModal(false);
          setItemStockToEdit(undefined);

          if (shouldReloadItem) {
            setSuccess(null);
            setError(null);
            reloadItem();
          }
          if (successMessage) {
            setSuccess(successMessage);
          }
        }}
        countryDetails={countryDetails}
        inventoryItem={inventoryItem}
        itemStockToEdit={itemStockToEdit}
        isOpen={openStockModal}
      />
    </div>
  );
};

export default StockHistory;
