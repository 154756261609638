/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import { useClinic } from "../../../../contexts/ClinicContext";
import { AnimalCarerTypeEnum } from "../../../../models/ePrescription/local/AnimalCarerTypeEnum";
import { DeliverySelectionTypeEnum } from "../../../../models/ePrescription/local/DeliverySelectionTypeEnum";
import { PharmacySelectionTypeEnum } from "../../../../models/ePrescription/local/PharmacySelectionTypeEnum";
import { GeoPharmacy } from "../../../../models/pharmacy/GeoPharmacy";
import { Pharmacy } from "../../../../models/pharmacy/Pharmacy";
import StatusSignal from "../../StatusSignal";
import PartnerPharmacyForm from "./PartnerPharmacyForm";
import PharmacyForm from "./PharmacyForm";
import { RadioButtonOption } from "../../../../common/Icons/RadioButtonOption";
import Button from "../../../../components/Button";

interface Props {
  geoPharmacies: GeoPharmacy[];
  specificPharmacyResult(p: Pharmacy | undefined): void;
  pharmacyTypeChanged(type: PharmacySelectionTypeEnum): void;
  animalCarerType: AnimalCarerTypeEnum;
  deliveryType: DeliverySelectionTypeEnum;
  isValid(isValid: boolean): void;
}

const ReceiverPharmacy: React.FC<Props> = ({
  geoPharmacies,
  specificPharmacyResult,
  pharmacyTypeChanged,
  animalCarerType,
  deliveryType,
  isValid,
}: Props) => {
  const { clinic } = useClinic();
  const [selectedPharmacyType, setSelectedPharmacyType] = useState<
    PharmacySelectionTypeEnum
  >(PharmacySelectionTypeEnum.ALL);

  const [onlyPharmacySelectOption, setOnlyPharmacySelectOption] = useState<
    boolean
  >(false);
  const [pharmacy, setPharmacy] = useState<Pharmacy | undefined>();
  const [partnerPharmacy, setPartnerPharmacy] = useState<
    Pharmacy | undefined
  >();

  const isPharmacySelectionValid = (): boolean => {
    let result = true;

    if (selectedPharmacyType === PharmacySelectionTypeEnum.SPECIFIC) {
      if (!pharmacy) {
        result = false;
      }
    } else if (selectedPharmacyType === PharmacySelectionTypeEnum.PARTNER) {
      if (!partnerPharmacy) {
        result = false;
      }
    }

    return result;
  };

  useEffect(() => {
    switch (selectedPharmacyType) {
      case PharmacySelectionTypeEnum.SPECIFIC:
        specificPharmacyResult(pharmacy);
        isValid(isPharmacySelectionValid());
        break;
      case PharmacySelectionTypeEnum.PARTNER:
        specificPharmacyResult(partnerPharmacy);
        isValid(isPharmacySelectionValid());
        break;
      default:
        specificPharmacyResult(undefined);
        isValid(true);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPharmacyType, pharmacy, partnerPharmacy]);

  useEffect(() => {
    if (
      animalCarerType === AnimalCarerTypeEnum.DATEOFBIRTH ||
      deliveryType !== DeliverySelectionTypeEnum.NONE
    ) {
      setOnlyPharmacySelectOption(true);
      if (selectedPharmacyType === PharmacySelectionTypeEnum.ALL) {
        setSelectedPharmacyType(PharmacySelectionTypeEnum.SPECIFIC);
      }
    } else {
      setOnlyPharmacySelectOption(false);
    }
  }, [animalCarerType, deliveryType, selectedPharmacyType]);

  useEffect(() => {
    pharmacyTypeChanged(selectedPharmacyType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPharmacyType]);

  const pharmacyChange = (value: PharmacySelectionTypeEnum): void => {
    setSelectedPharmacyType(value);
  };

  const pharmacyFormResult = (p: Pharmacy | undefined): void => {
    setPharmacy(p);
  };

  return (
    <div className="tw-card p-4 space-y-4">
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
       {strings.receiverPharmacy}
      </h2>
      <div className="divide-y dark:divide-gray-700">
        <div className={onlyPharmacySelectOption ? "text-gray-400 pb-1" : "pb-1"}>
          <Button
            disabled={onlyPharmacySelectOption}
            onClick={() => {
              pharmacyChange(PharmacySelectionTypeEnum.ALL);
            }}
            variant="basic"
          >
            {selectedPharmacyType === PharmacySelectionTypeEnum.ALL ? (
              <RadioButtonOption className="text-sky-500" checked />
            ) : (
              <RadioButtonOption className="text-gray-500" checked={false} />
            )}
            <div className="ml-2">{strings.allPharmacies}</div>
          </Button>
        </div>
        <div className="py-1">
          <Button
            onClick={() => {
              pharmacyChange(PharmacySelectionTypeEnum.SPECIFIC);
            }}
            variant="basic"
          >
            {selectedPharmacyType === PharmacySelectionTypeEnum.SPECIFIC ? (
              <RadioButtonOption className="text-sky-500" checked />
            ) : (
              <RadioButtonOption className="text-gray-500" checked={false} />
            )}
            <div className="ml-2">{strings.specificPharmacy}</div>
          </Button>
          <div
            className="py-2 space-y-4"
            hidden={selectedPharmacyType !== PharmacySelectionTypeEnum.SPECIFIC}
          >
            <PharmacyForm
              selectPharmacy={pharmacyFormResult}
              geoPharmacies={geoPharmacies}
            />
          </div>
        </div>
        <div className="pt-1">
          <Button
            onClick={() => {
              pharmacyChange(PharmacySelectionTypeEnum.PARTNER);
            }}
            variant="basic"
          >
            {selectedPharmacyType === PharmacySelectionTypeEnum.PARTNER ? (
              <RadioButtonOption className="text-sky-500" checked />
            ) : (
              <RadioButtonOption className="text-gray-500" checked={false} />
            )}
            <div className="ml-2">{strings.partnerPharmacies}</div>
          </Button>
          <div
            className="pt-2 space-y-4"
            hidden={selectedPharmacyType !== PharmacySelectionTypeEnum.PARTNER}
          >
            <PartnerPharmacyForm
              selectPharmacy={setPartnerPharmacy}
              availableFeePharmacies={clinic?.affiliatedSwedishPharmacies}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiverPharmacy;
