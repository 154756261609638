/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { getStringFromEnumsOrReturn, strings } from "../../../Strings/Strings";
import { ClinicEmploymentRequestResponse } from "../../../../models/employment/ClinicEmploymentRequestResponse";
import ClinicEmploymentApi from "../../../../api/ClinicEmploymentApi";
import LoaderInline from "../../../../components/LoaderInline";
import { getGeneralError } from "../../../../util/helperFunctions";
import Button from "../../../../components/Button";
import AlertBox from "../../../../components/AlertBox";
import { useClinic } from "../../../../contexts/ClinicContext";

interface Props {
  refresh: () => void;
  request: ClinicEmploymentRequestResponse;
}

const ClinicRequestNotification: React.FC<Props> = ({
  request,
  refresh,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchClinics } = useClinic();

  const pendingHandling = async (isAccepted: boolean) => {
    setLoading(true);

    try {
      if (isAccepted) {
        await ClinicEmploymentApi.acceptClinicEmploymentRequest(request.id);
      } else {
        await ClinicEmploymentApi.rejectClinicEmploymentRequest(request.id);
      }

      refresh();
      await fetchClinics();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoaderInline className="p-3" hidden={!loading} />
      <div hidden={loading}>
        <div className="flex flex-col">
          <p className="text-sm text-left">
            {strings.formatString(
              strings.pending,
              request.requestingUser.fullName,
              request.clinic.name,
              getStringFromEnumsOrReturn(request.employmentType)
            )}
          </p>
          <AlertBox className="mt-3 text-left" message={error} />
          <div className="flex mt-5">
            <Button
              onClick={() => {
                void pendingHandling(false);
              }}
              variant="link"
            >
              <p className="text-sm text-slate-500 dark:text-slate-300">
                {strings.reject}
              </p>
            </Button>
            <Button
              className="ml-auto text-sm"
              onClick={() => {
                void pendingHandling(true);
              }}
              variant="link"
            >
              {strings.accept}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicRequestNotification;
