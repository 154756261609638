/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useArrayState = <T>(
  initial: T[]
): [T[], (i: number, e: T) => any, (a: T[]) => any] => {
  const [array, setArray] = useState<T[]>(initial);
  const setElement = (i: number, e: T) => {
    const newArray = array.slice();
    newArray[i] = e;
    setArray(newArray);
  };
  return [array, setElement, setArray];
};

export const useSearchParams = () => {
  const result = {} as any;
  // prettier-ignore
  // eslint-disable-next-line no-restricted-syntax
  for (const [k, v] of new URLSearchParams(useLocation().search)) {
      result[k] = v;
  }
  return result;
};

interface DebouncedState<T> {
  value: T;
  setValue: (v: T) => void;
  sync: () => void;
}

interface DebouncedEffect {
  sync: () => void;
}

export const useDebouncedEffect = (
  eff: () => any,
  delay: number,
  deps: any[]
): DebouncedEffect => {
  const [delaying, setDelaying] = useState(true);
  const [timeoutHandler, setTimeoutHandler] = useState<
    NodeJS.Timeout | undefined
  >();
  useEffect(() => {
    if (!delaying) {
      eff();
    }
  }, [delaying]);
  const clearCallback = () => {
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }
  };
  useEffect(() => {
    clearCallback();
    setDelaying(true);
    setTimeoutHandler(setTimeout(() => setDelaying(false), delay));
  }, deps);
  return {
    sync: () => {
      clearCallback();
      eff();
    },
  };
};

export const useDebouncedState = <T>(
  initial: T,
  delay: number
): DebouncedState<T> => {
  const [value, setValue] = useState<T>(initial);
  const [delayedValue, setDelayedValue] = useState<T>(initial);
  const { sync } = useDebouncedEffect(() => setDelayedValue(value), delay, [
    value,
  ]);
  return {
    value: delayedValue,
    setValue,
    sync,
  };
};

export interface PagerState {
  pageSize: number;
  setPageSize: (v: number) => void;
  pageNumber: number;
  setPageNumber: (v: number) => void;
}

export const usePager = (defaultPageSize: number): PagerState => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  return {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
  };
};
