/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import RedemptionInputs from "./RepeatDispensingBox";
import { RepeatDispensing } from "../../../models/ePrescription/sending/RepeatDispensing";
import { PackageType } from "../../../models/ePrescription/VARA/PackageType";
import { CommodityProduct } from "../../../models/ePrescription/VARA/CommodityProduct";
import { CommodityArticle } from "../../../models/ePrescription/VARA/CommodityArticle";
import { CommodityPrescriptionItemFrontend } from "../../../models/ePrescription/local/CommodityPrescriptionItemFrontend";
import Instructions from "./Instructions";
import PackageSelectionCommodity from "./PackageSelectionCommodity";
import hardcodedStrings from "../fixValues/hardcodedStrings";
import { ArticleId } from "../../../models/ePrescription/VARA/ArticleId";
import { DosageTexts } from "../../../models/ePrescription/local/DosageTexts";
import { LatestRequestedTimeForDispensing } from "../../../models/ePrescription/local/LatestRequestedTimeForDispensing";
import LatestDispensingTime from "./LatestDispensingTime";
import { IntervalUnitEnum } from "../../../models/ePrescription/sending/IntervalUnitEnum";
import AddToPrescriptionButtonContent from "./AddToPrescriptionButtonContent";
import ProductInfoCommodity from "./ProductInfoCommodity";
import Button from "../../../components/Button";
import { ExclamationMark } from "../../../common/Icons/ExclamationMark";

interface Props {
  commodityProduct?: CommodityProduct;
  addPrescription?(newItem: CommodityPrescriptionItemFrontend): void;
  forEdit?: CommodityPrescriptionItemFrontend;
  packageTypes: PackageType[];
}

const ProductDetailSectionCommodity: React.FC<Props> = ({
  commodityProduct,
  addPrescription,
  forEdit,
  packageTypes,
}: Props) => {
  const [medicine, setMedicine] = useState<CommodityProduct>();

  const packagesMethods = useForm<{
    selectedPackage: CommodityArticle[] | null;
    numberOfPackages: number;
  }>({
    mode: "onChange",
    defaultValues: {
      numberOfPackages: 1,
      selectedPackage: [],
    },
  });

  const {
    formState: { isValid: isPackageValid },
    getValues: getPackage,
    reset: setPackage,
  } = packagesMethods;

  const otherBoxSettingsMethods = useForm<
    LatestRequestedTimeForDispensing & RepeatDispensing
  >({
    mode: "onChange",
    defaultValues: {
      numberOfTimes: 1,
      interval: undefined,
      intervalUnit: IntervalUnitEnum.DAYS,
    },
  });

  const {
    formState: { isValid: isOtherFormValid },
    getValues: getOthers,
    setValue: setOther,
  } = otherBoxSettingsMethods;

  const defaultUnit =
    medicine &&
    medicine.articles &&
    medicine.articles[0] &&
    medicine.articles[0].quantityNumericUnit
      ? medicine.articles[0].quantityNumericUnit
      : "";

  const instructionMethods = useForm<DosageTexts>({
    mode: "onChange",
    defaultValues: {
      intervals: forEdit?.dosageText?.intervals || [
        {
          dose: undefined,
          unit: defaultUnit,
          period: undefined,
          days: undefined,
          method: [],
        },
      ],
      freeText: forEdit?.dosageText?.freeText,
      totalAmount: forEdit?.dosageText?.totalAmount,
    },
  });

  const {
    getValues,
    formState: { isValid },
    setValue,
  } = instructionMethods;

  useEffect(() => {
    if (defaultUnit && !forEdit) {
      setValue(`intervals.${0}.unit`, defaultUnit.toLowerCase());
    }
  }, [defaultUnit, setValue, forEdit]);

  const isTechnicalSpirit = (cp?: CommodityProduct): boolean => {
    if (!cp) {
      return false;
    }

    let result = false;

    if (cp.productType === hardcodedStrings.technicalSpiritType) {
      result = true;
    }

    cp.articles.forEach((cpa: CommodityArticle) => {
      if (cpa.itemNumber === hardcodedStrings.packageArticleIdIdNVN) {
        result = true;
      }
      if (cpa.articleIds) {
        cpa.articleIds.forEach((cpaId: ArticleId) => {
          if (
            cpaId.id === hardcodedStrings.packageArticleIdIdNVN &&
            cpaId.type === hardcodedStrings.packageArticleIdTypeNVN
          ) {
            result = true;
          }
        });
      }
    });

    return result;
  };

  useEffect(() => {
    if (forEdit) {
      setMedicine(forEdit.product);
      setOther("numberOfTimes", forEdit.repeatDispensing?.numberOfTimes || 1);
      setOther("interval", forEdit.repeatDispensing?.interval);
      setOther("intervalUnit", forEdit.repeatDispensing?.intervalUnit);
      setPackage({
        numberOfPackages: forEdit.package.numberOfPackages,
        selectedPackage:
          forEdit.product?.articles?.filter(
            (p: CommodityArticle) => p.itemNumber === forEdit.package.nvn
          ) || [],
      });
      setOther(
        "latestRequestedTimeForDispensing",
        forEdit.latestRequestedTimeForDispensing
      );
    } else if (commodityProduct) {
      setMedicine(commodityProduct);
    }
  }, [forEdit, commodityProduct, setOther, setPackage]);

  const mapPackingType = (packageType?: string) => {
    if (packageTypes) {
      packageTypes.forEach((pt: PackageType) => {
        if (pt.label === packageType) {
          packageType = pt.description;
        }
      });
    }

    return packageType;
  };

  const addToPrescription = () => {
    if (medicine && isPackageValid) {
      const { numberOfPackages, selectedPackage } = getPackage();

      if (selectedPackage && selectedPackage.length > 0) {
        const ca: CommodityArticle = selectedPackage[0];
        const interval = getOthers("interval");

        const newPrescriptionItem: CommodityPrescriptionItemFrontend = {
          product: medicine,
          package: {
            nvn: ca.itemNumber,
            numberOfPackages,
            title: ca.title,
            label: ca.label,
            manufacturer: ca?.stakeholder ? ca.stakeholder.name : "",
            innerPackageType: mapPackingType(ca.innerPackageType),
          },
          id: -1,
          dosageText: getValues(),
          repeatDispensing: {
            interval,
            intervalUnit: interval ? getOthers("intervalUnit") : undefined,
            numberOfTimes: getOthers("numberOfTimes"),
          },
          latestRequestedTimeForDispensing: getOthers(
            "latestRequestedTimeForDispensing"
          ),
        };

        if (forEdit) {
          newPrescriptionItem.id = forEdit.id;
        }
        if (addPrescription) {
          addPrescription(newPrescriptionItem);
        }
      }
    }
  };

  const isPrescriptionItemDisabled = () => {
    return (
      !isPackageValid ||
      (!isValid && isTechnicalSpirit(commodityProduct)) ||
      !isOtherFormValid
    );
  };

  return (
    <div className="space-y-4">
      <ProductInfoCommodity medicine={medicine} />
      <div className="space-y-4 divide-y dark:divide-gray-700">
        <div className="space-y-3 pt-3">
          <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
            {strings.packages}
          </h2>
          <FormProvider {...packagesMethods}>
            <form>
              <PackageSelectionCommodity commodityProduct={medicine} />
            </form>
          </FormProvider>
        </div>
        <div className="space-y-3 pt-3">
          <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
            <div className="flex items-center">
              {isTechnicalSpirit(medicine) ? (
                <span className="text-red-500 pr-1">
                  <ExclamationMark />
                </span>
              ) : (
                <></>
              )}
              {strings.dosage}
            </div>
          </h2>
          <FormProvider {...instructionMethods}>
            <form>
              <Instructions
                defaultUnit={defaultUnit}
                isCommodity={isTechnicalSpirit(medicine)}
              />
            </form>
          </FormProvider>
        </div>
        <div className="py-4 space-y-3">
          <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
            {strings.otherSettings}
          </h2>
          <FormProvider {...otherBoxSettingsMethods}>
            <form className="space-y-3">
              <RedemptionInputs />
              <LatestDispensingTime />
            </form>
          </FormProvider>
        </div>
        <Button
          onClick={() => {
            addToPrescription();
          }}
          disabled={isPrescriptionItemDisabled()}
          minW
        >
          {forEdit ? strings.save : <AddToPrescriptionButtonContent />}
        </Button>
      </div>
    </div>
  );
};

export default ProductDetailSectionCommodity;
