/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  dateAndTime,
  petGenders,
  petSpecies,
  strings,
} from "../../../common/Strings/Strings";
import NumberFormatter from "../../../util/NumberFormatter";
import InvoiceActions from "../InvoiceDetails/InvoiceActions";
import { invoiceType } from "./InvoiceListRow";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import Button from "../../../components/Button";
import PetProfilePicture from "../../../components/Pictures/Pet/PetProfilePicture";

interface Props {
  invoice: InvoiceResponse;
  setError(error: string): void;
  reload(): void;
}

const PendingInvoiceListRow: React.FC<Props> = ({
  invoice,
  setError,
  reload,
}: Props) => (
  <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 flex flex-col justify-between tw-card shadow divide-y dark:divide-gray-700">
    <div className="px-4 py-3 flex items-center font-bold">
      {invoice.customer.fullName || invoice.customer.organizationName}
      <div className="ml-auto">
        {NumberFormatter.formatPrice(invoice.totalCost, invoice.currency)}
      </div>
    </div>
    <div className="p-4 h-full">
      {invoice?.creationDate
        ? moment(invoice.creationDate).format(
            `${dateAndTime.momentDateFormat} HH:mm`
          )
        : ""}
    </div>
    {invoice?.pet ? (
      <div className="flex space-x-3 items-center p-4">
        <div className="w-10 h-10">
          <PetProfilePicture
            species={invoice?.pet?.species}
            petId={invoice?.pet?.id}
            profilePictureId={invoice?.pet?.profilePicture}
          />
        </div>
        <div className="flex flex-col -space-y-1">
          <div className="font-semibold text-base text-zinc-800 dark:text-white">
            {invoice?.pet?.name}
          </div>
          <span className="font-normal text-sm text-zinc-800 dark:text-white">
            {invoice?.pet?.species
              ? ` ${petSpecies[invoice?.pet?.species]}, ${invoice?.pet
                  ?.breed}, ${petGenders[invoice?.pet?.gender]}`
              : ""}
          </span>
        </div>
      </div>
    ) : (
      <></>
    )}
    <div className="p-4 flex items-center">
      {invoiceType(invoice)}
      <div className="ml-auto">
        <InvoiceActions
          invoice={invoice}
          reload={reload}
          setError={(err: string) => {
            if (setError) {
              setError(err);
            }
          }}
        />
      </div>
      <div className="ml-2">
        <Link
          to={`/invoices/edit/${invoice.id}/finish`}
          style={{ lineHeight: 0 }}
        >
          <Button fullWidth={false}>{strings.createInvoice}</Button>
        </Link>
      </div>
    </div>
  </div>
);

export default PendingInvoiceListRow;
