/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Control, RegisterOptions, useController } from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import {
  isSupportedCountry,
  isValidPhoneNumber,
} from "react-phone-number-input";
import sv from "react-phone-number-input/locale/sv.json";
import { Colors } from "../../../models/Colors";
import { strings } from "../../../common/Strings/Strings";
import { useClinic } from "../../../contexts/ClinicContext";
import { useUser } from "../../../contexts/UserContext";
import "react-phone-number-input/style.css";

interface FieldProps {
  control: Control<any>;
  id: string;
  name: string;
  autocomplete?: string;
  classLabel?: string;
  className?: string;
  country?: string;
  defaultValue?: string;
  disabled?: boolean;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  isLoading?: boolean;
  label?: string;
  labelIcon?: string;
  labelOff?: boolean;
  optional?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  showRequired?: boolean;
  width?: string;
}

const PhoneNumber = ({
  control,
  id,
  name: n,
  autocomplete,
  classLabel,
  className,
  country: defaultCountry,
  defaultValue,
  disabled,
  fieldOptions,
  isLoading,
  label,
  labelIcon,
  labelOff,
  optional,
  placeholder,
  readOnly,
  required,
  showRequired,
  width,
}: FieldProps): ReactElement => {
  const {
    fieldState: { error },
  } = useController({
    name: n,
    control,
    rules: {
      ...(fieldOptions || {}),
      required,
      validate: (value) => {
        if (!!value && value?.length > 0) {
          return (
            (value && isValidPhoneNumber(value.toString())) ||
            strings.phoneNotValid
          );
        }
        return true;
      },
    },
    defaultValue,
  });
  const { clinic } = useClinic();
  const { user } = useUser();

  // TODO if defaultCountry is provided but invalid, try clinic, etc.
  function getDefaultCountry() {
    const countryCodeStr = (
      defaultCountry ||
      clinic?.addresses[0]?.countryCode ||
      user?.primaryAddress?.countryCode
    )?.toUpperCase();

    return isSupportedCountry(countryCodeStr as any)
      ? (countryCodeStr as any)
      : undefined;
  }

  return (
    <div
      className={`${className || ""} ${error ? "has-error" : ""}`}
      style={{ width }}
    >
      {labelOff ? (
        <></>
      ) : (
        <label htmlFor={id} className={classLabel}>
          {labelIcon ? <i className={labelIcon} /> : ""} {label}
          {showRequired ? " *" : ""}
          {optional ? (
            <span style={{ color: Colors.INPUTBORDER }}>
              {" "}
              ({strings.optional})
            </span>
          ) : (
            <></>
          )}
        </label>
      )}
      <PhoneInputWithCountry
        name={n}
        control={control}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete={autocomplete}
        required={required}
        international
        defaultCountry={getDefaultCountry()}
        defaultValue={defaultValue}
        placeholder={isLoading ? strings.loading : placeholder}
        countrySelectProps={{
          disabled: disabled || readOnly, // this trick is needed because readonly prop does not prevent country select component from changing
        }}
        labels={user?.locale === "sv" ? sv : undefined}
      />
      <p>{error && error.message}</p>
    </div>
  );
};

export default PhoneNumber;

PhoneNumber.defaultProps = {
  autocomplete: undefined,
  classLabel: undefined,
  className: undefined,
  country: undefined,
  defaultValue: undefined,
  disabled: false,
  fieldOptions: undefined,
  isLoading: false,
  label: undefined,
  labelIcon: undefined,
  labelOff: false,
  optional: false,
  placeholder: undefined,
  readOnly: false,
  required: false,
  width: undefined,
};
