/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import SubsectionTemplate from "./Templates/SubsectionTemplate";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import SimpleInput from "../../../components/InputFieldsSimple/SimpleInput";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { QuantityUnit } from "../../../models/ePrescription/QuantityUnit";
import { useMainSectionContext } from "../MainSection/MainSectionContext";

const WeightSection: React.FC = () => {
  const { signed, weight, setWeight, triggerChange } = useMainSectionContext();

  return (
    <SubsectionTemplate title={strings.weight}>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-6 xl:col-span-3">
          {signed && weight && (
            <>
              <label>{strings.weight}</label>
              <p>
                <strong>
                  {weight} {quantityUnits[QuantityUnit.KILOGRAMS]}
                </strong>
              </p>
            </>
          )}
          {!signed && (
            <SimpleInput
              name="weight"
              autoComplete={AutoCompleteOptions.off}
              label={`${strings.weight} (${
                quantityUnits[QuantityUnit.KILOGRAMS]
              })`}
              onChange={(v: string) => {
                setWeight(v.length > 0 ? Number(v) : undefined);
                triggerChange(v);
              }}
              placeholder={strings.weight}
              readOnly={signed}
              type="number"
              value={weight}
              width="100px"
            />
          )}
        </div>
      </div>
    </SubsectionTemplate>
  );
};

export default WeightSection;
