/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ShiftApi from "../../../api/ShiftApi";
import { strings } from "../../../common/Strings/Strings";
import { useClinic } from "../../../contexts/ClinicContext";
import { DayOfWeek } from "../../../models/shift/DayOfWeek";
import { ShiftSchedulingRequest } from "../../../models/shift/ShiftSchedulingRequest";
import { EmployeeResponse } from "../../../models/user/EmployeeResponse";
import {
  getGeneralError,
  localDateFormat,
  mergeTimeAndDate,
} from "../../../util/helperFunctions";
import DatePickerInput from "../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import Switch from "../../../components/ReactHookFormFields/General/Switch";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import Field from "../../../components/ReactHookFormFields/General/Field";
import AlertBox from "../../../components/AlertBox";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import TimeSelect from "../../../components/ReactHookFormFields/General/Select/TimeSelect";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";
import CloseButton from "../../../components/CloseButton";

interface Props {
  open: boolean;
  closeNewModal(): void;
  reload(): void;
  selectedTimeAndDate: {
    time: string;
    date: string;
  };
}

type EndType = "count" | "date";

interface FormData {
  collaboratorUser: Array<Pick<EmployeeResponse, "userId" | "fullName">> | null;
  endDate: Date;
  endTime: string;
  frequencyOfRecurrence: number;
  repeat: boolean;
  repeatCount: number;
  repetitionEndDate?: Date | null;
  startTime: string;
  startDate: Date;
  SUNDAY: string;
  MONDAY: string;
  TUESDAY: string;
  WEDNESDAY: string;
  THURSDAY: string;
  FRIDAY: string;
  SATURDAY: string;
}

const CalendarWeekNewShiftModal: React.FC<Props> = ({
  reload,
  open,
  selectedTimeAndDate,
  closeNewModal,
}: Props) => {
  const { clinic } = useClinic();
  const [showRepetition, setShowRepetition] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [endType, setEndType] = useState<EndType>("count");
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    reset,
    setError: setFormError
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: { repeatCount: 0, collaboratorUser: null, repeat: false },
  });

  const isConfirmedEmployee = (e: EmployeeResponse) => !e.request;

  useEffect(() => {
    if (selectedTimeAndDate && open) {
      setValue("startDate", moment(selectedTimeAndDate.date).toDate());
      setValue("startTime", selectedTimeAndDate.time);

      const end = moment(
        mergeTimeAndDate(
          selectedTimeAndDate.time,
          new Date(selectedTimeAndDate.date)
        )
      ).add(8, "hours");
      setValue("endDate", end.toDate());
      setValue("endTime", end.format("HH:mm"));
    }

    setValue("collaboratorUser", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeAndDate, open]);

  useEffect(() => {
    if (clinic) {
      setOptions([
        {
          groupTitle: strings.assistants,
          groupOptions: [
            ...clinic.assistants
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName))
              .map(({ fullName, userId }: EmployeeResponse) => ({
                fullName,
                userId,
              })),
          ],
        },
        {
          groupTitle: strings.authorizedAssistants,
          groupOptions: [
            ...clinic.authorizedAssistants
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName))
              .map(({ fullName, userId }: EmployeeResponse) => ({
                fullName,
                userId,
              })),
          ],
        },
        {
          groupTitle: strings.receptionists,
          groupOptions: [
            ...clinic.receptionists
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName))
              .map(({ fullName, userId }: EmployeeResponse) => ({
                fullName,
                userId,
              })),
          ],
        },
        {
          groupTitle: strings.vets,
          groupOptions: [
            ...clinic.vets
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName))
              .map(({ fullName, userId }: EmployeeResponse) => ({
                fullName,
                userId,
              })),
          ],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic]);

  const closeModal = (): void => {
    if (error) {
      setError(null);
    }

    setEndType("count");
    setShowRepetition(false);
    reset();

    closeNewModal();
  };

  const getRepeatCount = (startDate: Date, endDate: Date) =>
    Math.ceil(moment(endDate).diff(startDate, "days") / 7);

  const submitAdd = async (data: FormData) => {
    if (!error) {
      setError(null);
    }

    setLoading(true);

    const daysForWeek: DayOfWeek[] = [];
    const d = new Date(data.startDate);

    if (data.MONDAY || d.getDay() === 1) {
      daysForWeek.push(DayOfWeek.MONDAY);
    }
    if (data.TUESDAY || d.getDay() === 2) {
      daysForWeek.push(DayOfWeek.TUESDAY);
    }
    if (data.WEDNESDAY || d.getDay() === 3) {
      daysForWeek.push(DayOfWeek.WEDNESDAY);
    }
    if (data.THURSDAY || d.getDay() === 4) {
      daysForWeek.push(DayOfWeek.THURSDAY);
    }
    if (data.FRIDAY || d.getDay() === 5) {
      daysForWeek.push(DayOfWeek.FRIDAY);
    }
    if (data.SATURDAY || d.getDay() === 6) {
      daysForWeek.push(DayOfWeek.SATURDAY);
    }
    if (data.SUNDAY || d.getDay() === 0) {
      daysForWeek.push(DayOfWeek.SUNDAY);
    }

    const startDateTime = mergeTimeAndDate(data.startTime, data.startDate);
    const endDateTime = mergeTimeAndDate(data.endTime, data.endDate);

    const postData: ShiftSchedulingRequest = {
      collaboratorUserId: data.collaboratorUser?.[0].userId ?? "",
      daysOfWeek: daysForWeek,
      endDate: data.repetitionEndDate
        ? moment(data.repetitionEndDate).format(localDateFormat())
        : undefined,
      minutes: moment(endDateTime).diff(startDateTime, "minutes"),
      repeatCount:
        endType === "date" && data.repetitionEndDate
          ? getRepeatCount(data.startDate, data.repetitionEndDate)
          : data.repeatCount,
      startDateTime: moment(startDateTime).format(localDateFormat()),
    };

    try {
      if (clinic) {
        await ShiftApi.addShift(clinic?.id, postData);
        reload();
        closeModal();
      }
    } catch (e) {
      const generalError = await getGeneralError(e);
      if (generalError) {
        setError(generalError);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={closeModal} show={open} size="modal-lg">
      <Modal.Header title={strings.newShift} />
      <Modal.Body>
        <form className="divide-y divide-gray-200 dark:divide-gray-700">
          <div className="flex flex-wrap items-center space-x-3 pb-5">
            <div className="flex items-center space-x-3">
              <DatePickerInput
                control={control}
                fieldOptions={{ deps: ["endDate"] }}
                label={strings.startDate}
                name="startDate"
                required
              />
              <TimeSelect
                control={control}
                label={strings.startTime}
                name="startTime"
                required
              />
              <div>-</div>
            </div>
            <div className="flex items-center space-x-3">
              <TimeSelect
                control={control}
                label={strings.endTime}
                name="endTime"
                required
              />
              <DatePickerInput
                control={control}
                fieldOptions={{
                  validate: {
                    isSameOrAfter: (v) =>
                      moment(v).isSameOrAfter(getValues("startDate"), "days"),
                  },
                }}
                label={strings.endDate}
                name="endDate"
                required
              />
            </div>
          </div>
          <div className="py-5 space-y-3">
            <div>{strings.repetition}</div>
            <div className="flex items-center">
              <Switch
                control={control}
                label={showRepetition ? strings.turnedOn : strings.turnedOff}
                name="repeat"
                onChange={(value: boolean) => {
                  setShowRepetition(value);
                }}
                order="IL"
              />
            </div>
            <div hidden={!showRepetition}>
              <div className="flex flex-wrap items-center space-x-3">
                <div>
                  <CheckBox
                    label={strings.monday}
                    name="MONDAY"
                    register={register}
                  />
                </div>
                <div>
                  <CheckBox
                    label={strings.tuesday}
                    name="TUESDAY"
                    register={register}
                  />
                </div>
                <div>
                  <CheckBox
                    label={strings.wednesday}
                    name="WEDNESDAY"
                    register={register}
                  />
                </div>
                <div>
                  <CheckBox
                    label={strings.thursday}
                    name="THURSDAY"
                    register={register}
                  />
                </div>
                <div>
                  <CheckBox
                    label={strings.friday}
                    name="FRIDAY"
                    register={register}
                  />
                </div>
                <div>
                  <CheckBox
                    label={strings.saturday}
                    name="SATURDAY"
                    register={register}
                  />
                </div>
                <div>
                  <CheckBox
                    label={strings.sunday}
                    name="SUNDAY"
                    register={register}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="py-5 space-y-3" hidden={!showRepetition}>
            <div>{strings.end}</div>
            <div
              tabIndex={0}
              role="button"
              className="flex items-center space-x-3 cursor-pointer"
              onClick={() => {
                setEndType("count");
              }}
            >
              <input
                className="tw-input cursor-pointer"
                type="radio"
                checked={endType === "count"}
              />
              <div>{strings.setRepforWeeks}</div>
              <Field
                error={errors.repeatCount}
                fieldOptions={{
                  validate: {
                    positive: (v) => parseInt(v, 10) > 0 || !v || strings.numberMustBePositive,
                    isInteger: (v) => Number.isInteger(v) || !v || strings.numberMustBeAnInteger,
                  },
                }}
                name="repeatCount"
                register={register}
                readOnly={endType === "date"}
                setError={setFormError}
                step="1"
                type="number"
                width="100px"
              />
            </div>
            <div
              role="button"
              className="flex items-center space-x-3 cursor-pointer"
              onClick={() => {
                setEndType("date");
              }}
              style={{ cursor: "pointer" }}
              tabIndex={0}
            >
              <input
                className="tw-input cursor-pointer"
                type="radio"
                checked={endType === "date"}
              />
              <div>{strings.onThisDate}</div>
              <DatePickerInput
                className="mb-0"
                control={control}
                name="repetitionEndDate"
                readOnly={endType === "count"}
              />
            </div>
          </div>
          <div className="py-5 flex items-center">
            <CombinedSelect
              allowNew={false}
              control={control}
              label={strings.createShiftFor}
              labelKey="fullName"
              name="collaboratorUser"
              groupedByOptions={options}
              placeholder={strings.searchEmployee}
              required
            />
          </div>
          <AlertBox className="mb-3" message={error} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading}
          loading={loading}
          onClick={handleSubmit(submitAdd)}
        >
          {strings.create}
        </Button>
        <CloseButton onClick={closeModal} />
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarWeekNewShiftModal;
