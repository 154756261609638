/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const diagnoseListSE = [
  { title: "Utan symtom på sjukdom (frisk)", value: "001" },
  { title: "Ej fastställd diagnos", value: "002" },
  { title: "Foderleda / inappetens", value: "010" },
  { title: "Avmagring", value: "011" },
  { title: "Uttorkning/vätskebrist", value: "012" },
  { title: "Symtom på utmärgling", value: "013" },
  { title: "Coprofagi", value: "014" },
  { title: "Symtom på förgiftning", value: "015" },
  { title: "Förlamning", value: "016" },
  { title: "Nedsatt allmäntillstånd", value: "017" },
  { title: "Symtom pågenerell stelhet", value: "018" },
  { title: "Symtom på tumörsjukdom", value: "019" },
  { title: "Feber", value: "020" },
  { title: "Hypertermi (värmeslag, malign hypertermi)", value: "021" },
  { title: "Beteendeförändring", value: "022" },
  { title: "Lynnesfel", value: "023" },
  { title: "Kannibalism", value: "024" },
  { title: "Svansbitning", value: "025" },
  { title: "Vulvabitning", value: "026" },
  { title: "Huvudhackning", value: "027" },
  { title: "Kloakhackning", value: "028" },
  { title: "Tvångsrörelser", value: "029" },
  { title: "Vävning", value: "030" },
  { title: "Krubbitning", value: "031" },
  { title: "Fjäderätning, äggpickning", value: "032" },
  { title: "Fjäderplockning", value: "033" },
  { title: "Endotoxinchock", value: "034" },
  { title: "Chock", value: "035" },
  { title: "Shivering", value: "036" },
  { title: "Hälta", value: "040" },
  { title: "Belastningshälta", value: "041" },
  { title: "Rörelsehälta", value: "042" },
  { title: "Distal hälta", value: "043" },
  { title: "Proximal hälta", value: "044" },
  { title: "Rörelsestörning", value: "045" },
  { title: "Avvikande rörelsemönster (dålig travaktion)", value: "046" },
  { title: "Svullnad i ben", value: "047" },
  { title: "Svullen has", value: "048" },
  { title: "Ryggbesvär", value: "049" },
  { title: "Arbetsinsufficiens", value: "050" },
  { title: "Symtom på vanvård", value: "060" },
  { title: "Nedsmutsat djur", value: "061" },
  { title: "Komplikation till operation/behandling", value: "080" },
  { title: "Komplikation till kastration", value: "081" },
  { title: "Komplikation till injektion", value: "082" },
  { title: "Komplikation till ledinjektion", value: "083" },
  { title: "Komplikation till rektalisering", value: "084" },
  { title: "Död", value: "090" },
  { title: "Död avlivad", value: "091" },
  { title: "Död under tävling", value: "092" },
  { title: "Massdöd (fläktavbrott)", value: "093" },
  { title: "Cirkulatorisk chock", value: "100" },
  { title: "Blåsljud u fastställd orsak", value: "101" },
  {
    title: "Kliniska tecken på hjärtsvikt (inkluderat komplikationer)",
    value: "102",
  },
  { title: "Arterit, akut", value: "103" },
  { title: "Tromboflebit, akut", value: "104" },
  { title: "Tromboflebit, kronisk", value: "105" },
  { title: "Lymfangit, akut", value: "106" },
  { title: "Lymfangit, kronisk", value: "107" },
  { title: "Blodbrist/anemi", value: "108" },
  { title: "Hosta", value: "120" },
  { title: "Andningssvårighet/dyspné", value: "121" },
  { title: "Näsblod/epistaxis, övre luftvägar", value: "122" },
  { title: "Övre luftvägsinflammation, akut", value: "123" },
  { title: "Övre luftvägsinflammation, kronisk", value: "124" },
  { title: "Serös rinit/snuva", value: "125" },
  { title: "Purulent rinit", value: "126" },
  { title: "Luftsäcksinflammation, akut", value: "127" },
  { title: "Luftsäcksinflammation, kronisk", value: "128" },
  { title: "Bihåleinflammation/sinuit", value: "130" },
  { title: "Struppipning", value: "131" },
  { title: "Lunginflammation, akut", value: "132" },
  { title: "Lunginflammation, kronisk", value: "133" },
  { title: "bronkit, akut", value: "134" },
  { title: "bronkit, kronisk", value: "135" },
  { title: "Kronisk obstruktiv lungsjukdom", value: "136" },
  { title: "Kvickdrag", value: "137" },
  { title: "Aspirationspneumoni", value: "138" },
  { title: "Transportsjuka/pleuropneumoni", value: "139" },
  { title: "Kronisk pleurit", value: "140" },

  {
    title: "Symtom på sjukdom u fastställd orsak, munhåla,tänder, tunga",
    value: "150",
  },
  { title: "Tuggningsvårigheter", value: "151" },
  { title: "Tandproblem", value: "152" },
  { title: "Bettfel", value: "153" },
  { title: "Glatt bett (senilt)", value: "154" },
  { title: "Akut tandköttsinflammation/gingivit", value: "155" },
  { title: "Tandfraktur", value: "156" },
  { title: "Vargtand som interfererar med betsling", value: "157" },
  { title: "Vassa emaljåsar (utan munsår)", value: "158" },
  { title: "Skarpbett (med munsår)", value: "159" },
  { title: "Hakbett", value: "160" },
  { title: "Tandnötningsanomali", value: "161" },
  { title: "Vågbett", value: "162" },
  { title: "Munsår", value: "163" },
  { title: "Betsel/bett-relaterade skador", value: "164" },
  { title: "Foderinpackning tänder", value: "165" },
  { title: "Foderstrupsförstoppning", value: "166" },
  { title: "Mag- och tarmstörning", value: "167" },
  { title: "Diarré", value: "168" },
  { title: "Dysenteriliknande diarré", value: "169" },
  { title: "Kolik", value: "170" },
  { title: "Symtom på förstoppning", value: "171" },
  { title: "Förstoppning/koprostas i tunntarm", value: "172" },
  { title: "Förstoppning/koprostas i blindtarm/cekum", value: "173" },
  { title: "Förstoppning/koprostas i tjocktarm/kolon", value: "174" },
  { title: "Sandkolik", value: "175" },
  { title: "Mekoniumförstoppning", value: "176" },
  { title: "Gaskolik", value: "177" },
  { title: "Krampkolik", value: "178" },
  { title: "Fölbrunstdiarré", value: "179" },
  { title: "Våmacidos", value: "180" },
  { title: "Trumsjuka", value: "181" },
  { title: "Löpmagsinpackning", value: "182" },
  { title: "Vänstersidig löpmagsförskjutning", value: "183" },
  { title: "Högersidig löpmagsförskjutning", value: "184" },
  { title: "Plingljud vänster sida", value: "185" },
  { title: "Plingljud höger sida", value: "186" },
  { title: "Löpmagsomvridning", value: "187" },
  { title: "Tarmlägesförändring", value: "188" },
  { title: "Upphängning avgrovtarm på mjält-njurbandet", value: "189" },
  { title: "Inklämt pungbråck", value: "190" },
  { title: "Tarmframfall", value: "191" },
  { title: "Traumatisk retikuloperitonit (vasst)", value: "192" },
  { title: 'Vagusindigestion (vagusskada vid "vasst")', value: "193" },
  { title: "Akut ma gkatarr/gastrit", value: "194" },
  { title: "Diarre-tarminflammation", value: "195" },
  { title: "Akut magtarmkatarr", value: "196" },
  { title: "Magsår/löpmagssår/ulcus ventriculi", value: "197" },
  { title: "Tarmflorerubbning", value: "198" },
  { title: "Våmstörning", value: "199" },
  { title: "Spädgrisdiarré", value: "200" },
  { title: "Treveckorsdiarré", value: "201" },
  { title: "Avvänjningsdiarré svin", value: "202" },
  { title: "Diarre- tillväxtgris", value: "203" },
  { title: "Krävdilatation (hängkräva)", value: "204" },
  { title: "Akut bukhinneinflammation/peritonit", value: "205" },
  { title: "Kronisk bukhinneinflammation/peritonit", value: "206" },
  { title: "Gulsot / Ikterus", value: "210" },
  { title: "Leverförfettning", value: "211" },

  { title: "Selen/vitamin-E brist (muskeldegeneration)", value: "212" },
  { title: "Symtom på muskelömhet", value: "213" },
  { title: "Symtom på fläkning", value: "214" },
  { title: "Korsförlamning", value: "216" },
  { title: "Myosit, akut", value: "217" },
  { title: "Myosit, kronisk", value: "218" },
  {
    title: "Utvecklingsrubbn, tillväxtrubbn, sena, senskida, bursa",
    value: "220",
  },
  { title: "Senkontraktur", value: "221" },
  { title: "Eftergift i sena (genomtrampning)", value: "222" },
  { title: "Bursit, akut", value: "223" },
  { title: "Bursit, kronisk", value: "224" },
  { title: "Galla i senskida", value: "225" },
  { title: "Piphas", value: "226" },
  { title: "Ligamentös spatt", value: "227" },
  { title: "Ruptur av sena", value: "228" },
  { title: "Bursit (sek. till trauma)", value: "229" },

  { title: "Slemsäcksinflammation, carpalled", value: "230" },
  { title: "Slemsäcksinflammation, hasled", value: "231" },
  { title: "Sårskada sena, senskida, bursa", value: "232" },
  { title: "Traumatiskt orsakad eftergift i ligament och senor", value: "233" },
  { title: "Symtom på sjukdom u fastställd orsak, skelett", value: "240" },
  { title: "Felaktig benställning", value: "241" },
  { title: "Björnfot", value: "242" },
  { title: "Osteokondros", value: "243" },
  { title: "Benhinneinflammation, akut", value: "244" },
  { title: "Kronisk periostit (överben)", value: "245" },
  { title: "Senbensinflammation", value: "246" },
  { title: "Fissur", value: "247" },
  { title: "Fraktur", value: "248" },
  { title: "Patellaupphakning", value: "249" },
  { title: "Ansvällning av led", value: "260" },
  { title: "Galla i led (ökad mängd vätska i led)", value: "261" },
  { title: "Spatt", value: "262" },
  { title: "Smärta från led", value: "263" },
  { title: "Stelhet i led", value: "264" },
  { title: "Ledinflammation", value: "270" },
  { title: "Vrickning/distorsion", value: "280" },
  { title: "Lägesförändring av led", value: "281" },
  { title: "Sårskada med ledaffektion", value: "282" },

  { title: "Symtom på infektionssjukdom", value: "300" },
  { title: "Virusinfektioner häst", value: "310" },
  { title: "Virusarterit (ekvin virus arterit, EVA)", value: "311" },
  { title: "Influensa, häst", value: "312" },
  { title: "Vesikulär stomatit (VS), häst", value: "313" },
  { title: "Infektiös anemi (häst)", value: "314" },
  { title: "Virusabort- fosterdöd/kastning", value: "315" },
  { title: "Rabies, häst", value: "316" },
  { title: "Virusinfektioner nöt/får/get", value: "320" },
  {
    title: "TSE- transmissible spongiform encefalopati (nöt, får, get)",
    value: "321",
  },
  {
    title: "BSE (Bovin spongiform encephalopati, Madcow disease)",
    value: "322",
  },
  { title: "Scrapie (får,get)", value: "323" },
  { title: "Nor98, får", value: "324" },
  { title: "Parainfluensavirus typ 3 infektion (PIV-3)", value: "325" },
  { title: "Boskapspest (Rinderpest)", value: "326" },
  { title: "Lumpy skin disease", value: "327" },
  { title: "Får- och getkoppor", value: "328" },
  { title: "Bluetongue", value: "329" },
  { title: "Leukos nöt", value: "330" },
  { title: "BRSV (Bovint respiratoriskt syncytialt virus)", value: "331" },
  { title: "Infektion med bovint herpesvirus 1 (IBR/IPV/IBP)", value: "332" },
  { title: "Elakartad katarralfeber", value: "333" },
  { title: "Coronavirusinfektion nöt/får/get", value: "334" },
  { title: "BVD Bovin virusdiarré", value: "335" },
  { title: "Kronisk BVD", value: "336" },
  { title: "BD Borderdisease", value: "337" },
  { title: "Hairy shaker - kronisk borderdisease", value: "338" },
  { title: "Maedi/Visna", value: "339" },
  { title: "CAE (Caprin Artrit och Encephalit, get)", value: "340" },
  {
    title: "Louping ill (akut encephalomyelit, flavivirus, får)",
    value: "341",
  },

  { title: "Rabies infektion nöt/får/get", value: "342" },
  { title: "Mul- och klövsjuka (MK)", value: "343" },
  { title: "Rift Valley fever", value: "344" },
  { title: "AD (Aujeszkys dis, pseudorabies, Herpesvirus), nöt", value: "345" },
  { title: "Vesiculär stomatit (VS), nöt/får/get", value: "346" },
  { title: "Rotavirusinfektion, nöt/får/get", value: "347" },
  { title: "Peste des petites ruminantes", value: "348" },
  { title: "Smittsamt muneksem (Orf, får/get)", value: "349" },
  { title: "Virusinfektioner svin", value: "360" },
  { title: "Am.svininfluensa", value: "361" },
  { title: "Rotavirusinfektion svin", value: "362" },
  {
    title: "TGE (Transmissiblegastroenterit, Coronavirus), svin",
    value: "363",
  },
  { title: "PED (Porcine epidemisk diarré, PED-virus)", value: "364" },
  { title: "Parvovirusinfektion, svin", value: "365" },
  { title: "PRRS (Porcint Repr. o Resp.Syndrom, togavirus)", value: "366" },
  { title: "SMEDI (Stillbirth and infert, enterovirus), svin", value: "367" },
  { title: "Afrikansk svinpest", value: "368" },
  { title: "Teschen/Talfan (sporadisk encephalomyelit,svin)", value: "369" },
  { title: "Aujeszkys sjukdom (AD), svin", value: "370" },
  { title: "Klassisk svinpest (Hog cholera)", value: "371" },
  { title: "Rabies infektion svin", value: "372" },
  { title: "Mul- och klövsjuka (MK), svin", value: "373" },
  { title: "SVD (Swine vesicular disease)", value: "374" },
  { title: "Vesikulär stomatit (VS), svin", value: "375" },
  { title: "Bluetongue, svin", value: "376" },
  { title: "Postweaning multisystemic wasting syndrome (PMWS)", value: "377" },
  { title: "Virusinfektioner fjäderfä", value: "380" },
  { title: "Mareks sjukdom (MD hönslamhet,herpesv.)", value: "381" },
  { title: "Aviär encephalomyelit (epid.tremor, Picornav.)", value: "382" },
  { title: "Infektiös bronkit (Coronav. fjäderfä)", value: "383" },
  { title: "Infektiös bursit (Gumborosjukan, Birnav.)", value: "384" },
  { title: "Blåvingesjuka (CAA Chicken anemia agent)", value: "385" },
  { title: "Inklusionskropps-hepatit (IBH Adenov. fjäderfä)", value: "386" },
  { title: "Newcastle disease (NDV)", value: "387" },
  { title: "Infektiös laryngotracheit (ILT,herpesv. fjäderfä)", value: "388" },
  {
    title: "TRT/SHS (Turkey rhinotracheitis/swollen head syndrome)",
    value: "389",
  },
  { title: "EDS (egg drop syndrome)", value: "390" },
  { title: "Fågelkoppor", value: "391" },
  { title: "Rotavirusinfektion hos höns", value: "392" },
  { title: "AIV, fjäderfä", value: "393" },
  { title: "Reovirus-infektion, fjäderfä", value: "394" },
  { title: "Paramyxovirus, ej NDV", value: "395" },
  { title: "Aviär leukos, ej Mareks sjukdom", value: "396" },
  { title: "Bakteriella infektioner", value: "400" },
  { title: "Sårinfektion", value: "401" },
  { title: "Böld (abscess)", value: "402" },
  { title: "Blodförgiftning", value: "403" },
  { title: "Salmonellainfektion", value: "404" },
  { title: "VTEC/EHEC", value: "405" },
  { title: "E.coli infektion (enterit, ödemsjuka svin mfl.)", value: "406" },
  { title: "Benaktinos", value: "407" },
  { title: "Mjukdelsaktinos/actinobacillos/strålsvamp", value: "408" },
  { title: "Botulism", value: "409" },
  { title: "Stelkramp", value: "410" },
  { title: "Borreliainfektion", value: "411" },

  { title: "Rhodococcus equi infektion (pneumoni / artrit mm)", value: "412" },
  { title: "CEM (Taylorella equigenitalis)", value: "413" },
  { title: "Fölsjuka/sepsis", value: "414" },
  { title: "Kvarka", value: "415" },
  { title: "Anaplasmos (fd granulocytär ehrlichios)", value: "416" },
  { title: "Mykoplasmahosta (får)", value: "417" },
  { title: "Clostridios", value: "418" },
  {
    title: "Clostridium perfr.typ A (enterotox.lamm,nekr.enterit höns)",
    value: "419",
  },
  { title: "Vaktelsjuka hos höns/nekrotiserande enterit", value: "419" },
  { title: "Clostridium perfr typ C (smitts tarmbrand,svin)", value: "420" },
  { title: "Frasbrand (Cl.chauvoei,black leg disease)", value: "421" },
  { title: "Pasteurellos", value: "422" },
  { title: "Tuberkulos", value: "423" },
  { title: "Q-fever (Queenstand fever, Coxiella burnetti)", value: "424" },
  { title: "Mjältbrand", value: "425" },
  { title: "Necrobacillos", value: "426" },
  { title: "Campylobacter fetus infektion", value: "427" },
  { title: "Transportsjuka svin", value: "428" },
  {
    title: "Atrofisk rinit (Bordetella br./Past.multocida,nyssjuka svin)",
    value: "429",
  },
  {
    title: "Elakartad lungsjuka (Actinobacillus pleuropneumoniae,svin)",
    value: "430",
  },
  { title: "Rödsjuka (erysipelas)", value: "431" },
  { title: "Dysenteri", value: "432" },
  {
    title: "PIA (Porcine intest.adenomatos,Campylobact.inf.svin.(höns)",
    value: "433",
  },
  {
    title: "Infektiös coryza (hönssnuva, Hemophilus paragallinarium)",
    value: "434",
  },
  { title: "Hönskolera (Pasteurella)", value: "435" },
  { title: "Hönspest (Salmonella pullorum)", value: "436" },
  { title: "Paratuberkulos", value: "437" },
  { title: "SEP (swine enzoot.pneumoni)", value: "438" },
  { title: "Brucellos/smittsam kastning", value: "439" },
  { title: "Listerios", value: "440" },
  { title: "Lawsonia infektion", value: "441" },
  { title: "Fotröta, Dichelobacter nodosus", value: "442" },
  { title: "Kutan streptotrikos", value: "443" },
  { title: "Svartskorv/svarteksem", value: "444" },
  { title: "Fjäderfollikulit", value: "445" },
  { title: "Svampinfektion", value: "447" },
  { title: "Ringorm/dermatofytos", value: "448" },
  { title: "Symtom på parasitär sjukdom", value: "450" },
  { title: "Parasitär sjukdom, magsäck tarm", value: "451" },
  { title: "Spolmask", value: "452" },
  { title: "Blodmaskar", value: "453" },
  { title: "Lungmaskinfektion", value: "454" },
  { title: "Parasitär sjukdom (ostertagios, teladorsagios)", value: "455" },
  { title: "Haemonchus (H.contortus får,get)", value: "456" },
  { title: "Nematodirus", value: "457" },
  { title: "Bandmaskar", value: "458" },
  { title: "Parasitär sjukdom - coccidios", value: "459" },
  { title: "Toxoplasma", value: "460" },
  { title: "Neospora", value: "461" },
  { title: "Parasitär sjukdom-giardiasis", value: "462" },
  { title: "Sommarsjuka (babesios)", value: "463" },
  { title: "Parasitär sjukdom - cryptosporidios", value: "464" },
  {
    title: "Stora leverflundran (Distomatos, Fasciola hepatica)",
    value: "465",
  },
  { title: "Lilla leverflundran (Dicrocoelium dentriticum)", value: "466" },
  { title: "Skabbangrepp", value: "470" },

  { title: "Lusangrepp", value: "471" },
  {
    title: "Päls-/fjäderät lus (Tricodectes,Bovicola,Damalinia, Menopon)",
    value: "472",
  },
  { title: "Fluglarvangrepp (Myiasis)", value: "473" },
  { title: "Fårstynglarv (Oestrus ovis)", value: "474" },
  { title: "Styngflugelarver (häst)", value: "475" },
  { title: "Knottangrepp", value: "476" },

  { title: "Symtom på metabolisk sjukdom", value: "500" },
  { title: "Gikt (hyperurikemi, fjäderfä)", value: "505" },
  { title: "Kalvningsförlamning", value: "510" },
  { title: "Förlamning - utfodringsbetingad pares", value: "511" },
  { title: "Hypocalcemi u fastställd orsak", value: "512" },
  { title: "Dräktighetsförlamning (hypocalcemi)", value: "513" },
  { title: "Beteskramp/stallkramp (Hypomagnesemi)", value: "515" },
  { title: "Proteinrubbningar", value: "516" },
  { title: "Hypogammaglobulinemi", value: "517" },
  { title: "Onormala blodlevervärden", value: "518" },
  { title: "Acetonemi", value: "520" },
  { title: "Nervös acetonemi", value: "521" },
  { title: "Dräktighetstoxikos (acidos, leverförfettning)", value: "522" },
  { title: "Avmagring pga underutfodring", value: "529" },
  { title: "Sjukdomar pga vitamin / mineralrubbningar", value: "530" },
  { title: "Vitamin D brist (Rakitis)", value: "531" },
  { title: "Järnbrist", value: "532" },
  { title: "Kopparbrist", value: "533" },
  { title: "Koboltbrist", value: "534" },
  { title: "Läkemedelsbiverkning", value: "535" },
  { title: "Växttoxinförgiftning", value: "536" },
  { title: "Ekollonförgiftning", value: "537" },
  { title: "Fodermedelsförgiftning", value: "538" },
  { title: "Kraftfoderförätning", value: "539" },
  { title: "Mineralförgiftning", value: "540" },
  { title: "Toxic fat (ödemsjuka, höns)", value: "541" },
  { title: "Blyförgiftning", value: "542" },
  { title: "Koksaltförgiftning", value: "543" },
  { title: "Kopparförgiftning", value: "544" },
  { title: "Gödselgasförgiftning", value: "545" },
  { title: "Ormbett", value: "546" },
  { title: "Insektsbett/stick", value: "547" },
  { title: "Symtom på allergisk sjukdom", value: "550" },
  { title: "Anasarka", value: "551" },
  { title: "Symtom på hormonell sjukdom", value: "560" },
  { title: "Nedsatt tillväxt", value: "570" },
  { title: "Pellesyndromet (nedsatt tillväxt, svin)", value: "571" },
  { title: 'Nedsatt tillväxt ("Pellekalv")', value: "572" },

  { title: "Brunstig", value: "600" },
  { title: "Utebliven brunst", value: "601" },
  { title: "Tyst brunst", value: "602" },
  { title: "Symtomlös omlöpning", value: "603" },
  { title: "Nymfomani", value: "604" },
  { title: "Parningsproblem, hondjur", value: "605" },
  { title: "Svag könsdrift", value: "606" },

  { title: "Betäckning- oplanerad", value: "607" },
  { title: "Tvillingdräktighet", value: "608" },
  { title: "Reproduktionsstörning", value: "610" },
  { title: "Äggstockscysta", value: "611" },
  { title: "Follikelcysta", value: "612" },
  { title: "Cystiskgulkropp", value: "613" },
  { title: "Cystisk endometriehyperplasi", value: "614" },
  { title: "Abort", value: "615" },
  { title: "Värpnöd", value: "616" },
  { title: "Stenfoster", value: "617" },
  { title: "Mjölkstockning", value: "618" },
  { title: "Agalakti", value: "619" },
  { title: "Juverinflammation, akut", value: "620" },
  { title: "Juverinflammation, kronisk", value: "621" },
  { title: "Juverinflammation, subklinisk (höga celltal)", value: "622" },
  { title: "Juverinflammation- återkommande", value: "623" },
  { title: "Juverinflammation med kallbrand", value: "624" },
  { title: "Juverinflammation- blodförgiftning/förgiftning", value: "625" },
  { title: "Juverinflammation- kronisk som akuteriseras", value: "626" },
  { title: "Juverböld", value: "627" },
  { title: "Juveraktinos (strålsvamp)", value: "628" },
  { title: "Spenskada utanjuverinflammation", value: "629" },
  { title: "Spenskada medjuverinflammation", value: "630" },
  { title: "Spenvårtor", value: "631" },
  { title: "Flytningar", value: "632" },
  { title: "Livmoderinflammation, akut", value: "633" },
  { title: "Livmoderinflammation, kronisk", value: "634" },
  { title: "MMA (Agalacti,grisningsfeber, svin)", value: "635" },
  { title: "Juverödem", value: "636" },
  { title: "Kvarbliven efterbörd", value: "637" },
  { title: "Äggledarinflammation", value: "638" },
  { title: "Förlossningshysteri", value: "639" },
  { title: "Primär värksvaghet", value: "640" },
  { title: "Sekundär värksvaghet", value: "641" },
  { title: "Felläge av foster", value: "642" },
  { title: "Stort foster", value: "643" },
  { title: "Trånga fostervägar", value: "644" },
  { title: "Bristande cervixdilatation/ringwomb", value: "645" },
  { title: "Slidframfall", value: "646" },
  { title: "Livmoderframfall", value: "647" },
  { title: "Livmoderomvridning", value: "648" },
  { title: "Skada förlossningsvägar", value: "649" },
  { title: "Sårskada, vulva", value: "650" },
  { title: "Livmoderruptur", value: "651" },
  { title: "Ligamentrupturjuver", value: "652" },
  { title: "Spenförträngning- förvärvad", value: "653" },
  { title: "Spensår/bitsår på spenarna", value: "654" },
  { title: "Juversår", value: "655" },
  { title: "Missbildning, könsorgan hondjur", value: "660" },
  { title: "Hermafroditism, hondjur", value: "661" },
  { title: "Freemartinism", value: "662" },
  { title: "Missbildad spene", value: "663" },
  { title: "Högt membran, spene/ar", value: "664" },
  { title: "Lågt membran, spene", value: "665" },
  { title: "Outvecklad spenkanal", value: "666" },

  { title: "Extraspenar", value: "667" },
  { title: "För litet antal spenar", value: "668" },
  { title: "Könsjukdom, hondjur", value: "670" },
  { title: "Betäckningsoförmåga", value: "680" },
  { title: "Kryptorkism / monorkism", value: "681" },
  { title: "Testikelinflammation", value: "682" },
  { title: "Akut förhudsinflammation/balanopostitis", value: "683" },
  { title: "Traumatiska/mekaniska skador, könsorgan handjur", value: "684" },
  { title: "Könsjukdom, handjur", value: "689" },
  { title: "För tidigt födda/svaga", value: "690" },
  { title: "Hypotermi, nyfödda", value: "691" },
  { title: "Fläkgris", value: "692" },
  { title: "Skakgris", value: "693" },
  { title: "Missbildad avkomma", value: "694" },
  { title: "Klumpfot", value: "695" },
  { title: "Navelblödning", value: "696" },
  { title: "Svartfoster", value: "697" },
  { title: "Navelinfektion", value: "698" },

  { title: "Ospecifika traumatiska / mekaniska skador", value: "700" },
  { title: "Trafikskada", value: "701" },
  { title: "Blixtnedslag", value: "702" },
  { title: "Skadad av inredning i box, spilta el bås", value: "703" },
  { title: "Skadad vid brand/eldsvåda", value: "704" },
  { title: "Köldskada", value: "705" },
  { title: "Skadad av djur", value: "706" },
  { title: "Skadad av tamdjur", value: "707" },
  { title: "Skadad av häst", value: "708" },
  { title: "Skadad av hund", value: "709" },
  { title: "Skadad av katt", value: "710" },
  { title: "Skadad av tjur/ko", value: "711" },
  { title: "Skadad av får/get", value: "712" },
  { title: "Skadad av svin", value: "713" },
  { title: "Skadad av vilda djur", value: "714" },
  { title: "Skadad av björn", value: "715" },
  { title: "Skadad av varg", value: "716" },
  { title: "Skadad av lo", value: "717" },
  { title: "Skadad av rovfågel", value: "718" },
  { title: "Skadad av vildsvin", value: "719" },
  { title: "Skadad av älg/älgskräck", value: "720" },
  { title: "Dödad av djur", value: "721" },
  { title: "Dödad av tamdjur", value: "722" },
  { title: "Dödad av häst", value: "723" },
  { title: "Dödad av hund", value: "724" },
  { title: "Dödad av katt", value: "725" },
  { title: "Dödad av tjur/ko", value: "726" },
  { title: "Dödad av får/get", value: "727" },
  { title: "Dödad av svin", value: "728" },
  { title: "Dödad av vilda djur", value: "729" },
  { title: "Dödad av björn", value: "730" },
  { title: "Dödad av varg", value: "731" },
  { title: "Dödad av lo", value: "732" },
  { title: "Dödad av rovfågel", value: "733" },
  { title: "Dödad av vildsvin", value: "734" },

  { title: "Skadad av människa", value: "735" },
  { title: "Dödad av människa", value: "736" },
  { title: "Sårskada, hud", value: "737" },
  { title: "Skärsår, hud underhud", value: "738" },
  { title: "Kontusion (krossår), hud underhud", value: "739" },
  { title: "Stångningsskada", value: "740" },
  { title: "Sticksår, hud underhud", value: "741" },
  { title: "Skadad av taggtråd/elstängsel/staket", value: "742" },
  { title: "Sårskada, leden involverad", value: "743" },
  { title: "Trycksår / liggsår, hud underhud", value: "744" },
  { title: "Stakningsskada", value: "745" },
  { title: "Svallkött, hud underhud", value: "746" },
  { title: "Skada av bindsle/halsrem/grimma", value: "747" },
  { title: "Bogbladssår", value: "748" },
  { title: "Ljumsksår", value: "749" },
  { title: "Svansskada", value: "750" },
  { title: "Traumatiska skador klo, hov, klöv", value: "751" },
  { title: "Spiktramp", value: "752" },
  { title: "Balltramp", value: "753" },
  { title: "Sår kronrand", value: "754" },
  { title: "Sömstick", value: "755" },
  { title: "Traumatisk skada, horn", value: "756" },
  { title: "Främmande kropp (corp al)", value: "757" },
  { title: "Traumatiska/mekaniska skador, muskulatur", value: "758" },

  { title: "Missbildning", value: "800" },
  { title: "Navelbråck", value: "801" },
  { title: "Medfött pungbråck", value: "802" },
  { title: "Missbildning horn", value: "803" },

  { title: "Symtom på njursvikt", value: "820" },
  { title: "Blod i urinen, hematuri", value: "821" },
  { title: "Urinträngningar u fastställd orsak", value: "822" },
  { title: "Urinsten nedre urin vägar", value: "823" },
  { title: "Urinvägsinfektion", value: "824" },
  { title: "Urinblåseruptur", value: "825" },
  { title: "Urinblåseprolaps", value: "826" },
  {
    title: "Symtom på sjukdom u fastställd orsak, urinblåsa/uretra",
    value: "827",
  },
  { title: "Akut pyelonefrit", value: "828" },
  { title: "Kronisk pyelonefrit", value: "829" },

  { title: "Symtom på neurologisk sjukdom", value: "830" },
  { title: "Ataxi", value: "831" },
  { title: "Traumatiska/mekaniska skador, perifera nerver", value: "832" },
  { title: "Radialispares (traumatisk)", value: "833" },
  { title: "Nervskada efter förlossning", value: "834" },
  { title: "Tuppspatt", value: "835" },
  { title: "Akut hjärnhinneinflammation, meningit", value: "836" },
  {
    title: "Symtom på sjukdom u fastställd orsak, multifokalt nervsystem",
    value: "837",
  },
  { title: "Kramptillstånd, okänd orsak", value: "838" },
  { title: "CCN (Cerebrocortikal nekros)", value: "839" },
  {
    title: "Symtom på sjukdom u fastställd orsak, ytteröra/öronlapp",
    value: "850",
  },
  { title: "Kroniska inflammationstillstånd, ytteröra/öronlapp", value: "851" },
  { title: "Equine aural plaque", value: "852" },
  { title: "Traumatiska/mekaniska skador, ytteröra/öronlapp", value: "853" },
  { title: "Blodöra", value: "854" },
  { title: "Öronskador av ID-märken", value: "855" },
  { title: "Sår övre/nedre ögonlock", value: "860" },
  { title: "Lägesförändringar ögonlock/3:e ögonlock", value: "861" },
  { title: "Inåtrullat ögonlock/entropion", value: "862" },
  {
    title: "Atresi eller annan obstr av tårkanalsöppning i näsborre",
    value: "863",
  },
  { title: "Ögoninfektion (Pink eye) - Moraxella bovis/ovis", value: "864" },
  { title: "Konjunktivit, akut", value: "865" },
  { title: "Konjunktivit, kronisk", value: "866" },
  { title: "Keratit, akut", value: "867" },
  { title: "Keratit, kronisk", value: "868" },
  { title: "Traumatiska hornhinnesår, ej perforerande", value: "869" },
  { title: "Traumatiska hornhinnesår, perforerande", value: "870" },
  { title: "Hornhinnesår", value: "871" },
  { title: "Uveit, akut", value: "872" },
  { title: "Uveit, kronisk", value: "873" },
  { title: "Symtom på blindhet/nedsatt syn", value: "874" },
  { title: "Bulbprolaps (inklusive traumatisk)", value: "875" },
  { title: "Symtom på sjukdom u fastställd orsak, hud underhud", value: "900" },
  { title: "Klåda", value: "901" },
  { title: "Blodutgjutning/hematom", value: "902" },
  { title: "Ödem, hud underhud", value: "903" },
  { title: "Ventrala subkutana ödem", value: "904" },
  { title: "Akut icke-infektiös inflammation, hud", value: "905" },
  { title: "Juvereksem", value: "906" },
  { title: "Kronisk hudinflammation", value: "907" },
  { title: "Fistel, hud underhud", value: "908" },
  { title: "Mankfistel", value: "909" },
  { title: "Nässelutslag/urtikaria/angioderma", value: "910" },
  { title: "Knottallergi", value: "911" },
  { title: "Fotosensibilitet", value: "912" },
  { title: "Papillom", value: "913" },
  { title: "Melanom", value: "914" },
  { title: "Sarkoid/inkar, hud underhud", value: "915" },
  { title: "Revorm (Pityriasis rosea, svin)", value: "916" },
  { title: "Mugg/rasp", value: "917" },
  { title: "Sommareksem", value: "918" },
  { title: "Förvuxna hovar", value: "920" },
  { title: "Förvuxna klövar", value: "921" },
  { title: "Patologiska hovformer", value: "922" },
  { title: "Korkskruvsklöv", value: "923" },
  { title: "Bredklöv (får)", value: "924" },
  { title: "Hovbroskförbening", value: "925" },
  { title: "Dålig hornkvalite, klo, hov, klöv", value: "926" },
  { title: "Klövassymmetri", value: "927" },
  { title: "Fång/laminitis", value: "930" },
  { title: "Förlossningsfång", value: "931" },
  { title: "Hovbenssänkning, -rotation", value: "932" },
  { title: "Kronisk fång/laminitis", value: "933" },
  { title: "Klövsulesår (pododermatitis circumcripta)", value: "934" },
  { title: "Läderhudsblödning/stengalla", value: "935" },
  { title: "Digital dermatit", value: "936" },
  { title: "Klövspaltsinflammation, -flegmon, panaritium", value: "940" },
  { title: "Klövspaltseksem/dermatit, interdigital dermatit", value: "941" },
  { title: "Hovböld", value: "942" },
  { title: "Strålröta", value: "943" },
  { title: "Klövröta", value: "944" },
  { title: "Klövböld", value: "945" },
  { title: "Fotsår/fotböld fjäderfä", value: "946" },
  { title: "Bärrandsröta", value: "947" },
  { title: "Hålvägg", value: "948" },
  { title: "Vårtigt eksem, klövar (verrukös dermatit)", value: "949" },
  { title: "Hovspricka", value: "950" },
  { title: "Sulömhet", value: "951" },
  { title: "Hovkapselavlossning", value: "952" },
  { title: "Limax", value: "953" },
  { title: "Främmande kropp i hov", value: "954" },
  { title: "Klövkapselavlossning", value: "955" },
  { title: "Klövskada", value: "956" },
  { title: "Dubbelsula", value: "957" },
  { title: "Klövsjukdom", value: "959" },
];

export const DiagnoseListInsurance = [
  { value: "A", title: "Hela Djuret" },
  { value: "AA", title: "Hela djuret" },
  { value: "AA0", title: "Normalvar, symtom u fastställd orsak, hela djuret" },
  { value: "AA00", title: "Normalvariation, hela djuret" },
  { value: "AA001", title: "Utan symtom på sjukdom (frisk)" },
  { value: "AA002", title: "Död" },
  { value: "AA0021", title: "Död avlivad" },
  { value: "AA0022", title: "Inlämnad död" },
  { value: "AA0023", title: "Död under tävling" },
  { value: "AA0024", title: "Oväntat narkosdöd" },
  { value: "AA0025", title: "Massdöd (fläktavbrott)" },
  { value: "AA00251", title: "Massdöd (fläktavbrott)" },
  { value: "AA00260", title: "Dödfödd" },
  { value: "AA00261", title: "Död pga medfödd svaghet" },
  { value: "AA00262", title: "Kasserad vid slakt" },
  { value: "AA00263", title: "Försvunnen" },
  { value: "AA0027", title: "Stulen" },
  { value: "AA0028", title: "Tjuvslakt" },
  { value: "AA0029", title: "Död utan säker diagnos" },
  { value: "AA0031", title: "Hälta" },
  { value: "AA00311", title: "Belastningshälta" },
  { value: "AA00312", title: "Rörelsehälta" },
  { value: "AA00313", title: "Distal hälta" },
  { value: "AA00314", title: "Proximal hälta" },
  { value: "AA00315", title: "Rörelsestörning" },
  { value: "AA00316", title: "Avvikande rörelsemönster (dålig travaktion)" },
  { value: "AA0032", title: "Svullnad i ben" },
  { value: "AA00321", title: "Svullen has" },
  { value: "AA0033", title: "Ryggbesvär" },
  { value: "AA0034", title: "Shivering" },
  { value: "AA004", title: "Komplikation till operation/behandling" },
  {
    value: "AA0041",
    title: "Blödning som komplikation till kirurgisk behandling",
  },
  {
    value: "AA0042",
    title: "Infektion som komplikation till kirurgisk behandling",
  },
  { value: "AA0043", title: "Komplikation till kastration" },
  { value: "AA0044", title: "Komplikation till injektion" },
  { value: "AA00441", title: "Komplikation till ledinjektion" },
  { value: "AA0045", title: "Komplikation till rektalisering" },
  { value: "AA005", title: "Aktivitetsstörning" },
  { value: "AA009", title: "Utan diagnos" },
  { value: "AA0091", title: "Profylax" },
  { value: "AA0092", title: "Hälsokontroll" },
  { value: "AA0093", title: "Besiktning" },
  { value: "AA0094", title: "Dräktighetsundersökning" },
  { value: "AA01", title: "Symtom på sjukdom u fastställd orsak, hela djuret" },
  { value: "AA0103", title: "Symtom på infektionssjukdom" },
  { value: "AA0104", title: "Symtom på parasitär sjukdom" },
  { value: "AA0105", title: "Symtom på metabolisk sjukdom" },
  { value: "AA0106", title: "Symtom på allergisk sjukdom" },
  { value: "AA0108", title: "Symtom på neoplastisk sjukdom" },
  { value: "AA0109", title: "Symtom på tumörsjukdom" },
  { value: "AA0110", title: "Symtom från rörelseapparaten" },
  { value: "AA01101", title: "Hälta UNS" },
  { value: "AA01102", title: "Avvikande rörelsemönster (dålig travaktion)" },
  { value: "AA01103", title: "Diffus svullnad i extremitet" },
  { value: "AA01104", title: "Ryggbesvär UNS" },
  { value: "AA01105", title: "Shivering" },
  { value: "AA0111", title: "Symtom på förgiftning" },
  { value: "AA0112", title: "Symtom på dehydrering" },
  { value: "AA0114", title: "Symtom på polyuri/polydipsi" },
  { value: "AA0115", title: "Symtom på polyuri" },
  { value: "AA0116", title: "Symtom på polydipsi" },
  { value: "AA0118", title: "Symtom på avmagring" },
  { value: "AA0120", title: "Symtom på infektionssjukdom" },
  { value: "AA0121", title: "Symtom på parasitär sjukdom" },
  { value: "AA0122", title: "Symtom på metabolisk sjukdom" },
  { value: "AA0123", title: "Symtom på allergisk sjukdom" },
  { value: "AA0124", title: "Symtom på neoplastisk sjukdom" },
  { value: "AA0125", title: "Symtom på hormonell sjukdom" },
  { value: "AA0126", title: "Symtom på förgiftning" },
  { value: "AA0127", title: "Symtom på dehydrering" },
  { value: "AA0128", title: "Symtom på polyuri/polydipsi" },
  { value: "AA01281", title: "Symtom på polyuri" },
  { value: "AA01282", title: "Symtom på polydipsi" },
  { value: "AA0129", title: "Symtom på avmagring" },
  { value: "AA01291", title: "Symtom på utmärgling/chachexi/kachexi" },
  { value: "AA0130", title: "Symptom på anorexi/polyfagi" },
  { value: "AA01301", title: "Symtom på anorexi" },
  { value: "AA01302", title: "Symtom på polyfagi" },
  { value: "AA01303", title: "Coprofagi" },
  { value: "AA0131", title: "Symptom på neurologisk sjukdom" },
  { value: "AA01311", title: "Epileptiforma kramper" },
  { value: "AA01312", title: "Toniska kramper" },
  { value: "AA01313", title: "Kloniska kramper" },
  { value: "AA01314", title: "Ataxi" },
  { value: "AA01315", title: "Pares / paralys" },
  { value: "AA01316", title: "Medvetslöshet" },
  { value: "AA01317", title: "Förlamning" },
  { value: "AA0133", title: "Symtom på trötthet" },
  { value: "AA0134", title: "Nedsatt allmäntillstånd" },
  { value: "AA0135", title: "Symtom på generell smärta" },
  { value: "AA0136", title: "Symtom på generell stelhet" },
  { value: "AA0137", title: "Symptom på generell smärta" },
  { value: "AA0138", title: "Symtom på syra/basrubbning" },
  { value: "AA0139", title: "Acidos" },
  { value: "AA0140", title: "Symptom på generell stelhet" },
  { value: "AA0141", title: "Respiratorisk acidos" },
  { value: "AA0142", title: "Alkalos" },
  { value: "AA0143", title: "Symptom på reumatologisk sjukdom" },
  { value: "AA0144", title: "Respiratorisk alkalos" },
  { value: "AA0145", title: "Symtom på syra/basrubbning" },
  { value: "AA01451", title: "Acidos" },
  { value: "AA01452", title: "Metabolisk acidos" },
  { value: "AA01453", title: "Respiratorisk acidos" },
  { value: "AA01454", title: "Alkalos" },
  { value: "AA01455", title: "Metabolisk alkalos" },
  { value: "AA01456", title: "Respiratorisk alkalos" },
  { value: "AA0146", title: "Tecken på vanvård" },
  { value: "AA0148", title: "Nedsmutsat djur" },
  { value: "AA0149", title: "Temperaturförändring" },
  { value: "AA015", title: "Hosta" },
  { value: "AA0150", title: "Feber" },
  { value: "AA0151", title: "Undertemperatur" },
  { value: "AA0152", title: "Hypertermi (värmeslag, malign hypertermi)" },
  { value: "AA0154", title: "Apati" },
  { value: "AA0155", title: "Hyperaktivitet" },
  { value: "AA0156", title: "Kollaps UNS" },
  { value: "AA0157", title: "Beteendeförändring" },
  { value: "AA0158", title: "Lynnesfel" },
  { value: "AA0159", title: "Agressivitet" },
  { value: "AA0160", title: "Kannibalism" },
  { value: "AA0161", title: "Svansbitning" },
  { value: "AA0162", title: "Vulvabitning" },
  { value: "AA0163", title: "Huvudhackning" },
  { value: "AA0164", title: "Kloakhackning" },
  { value: "AA0165", title: "Modern biter ihjäl ungarna" },
  { value: "AA0166", title: "Tvångsrörelser" },
  { value: "AA0167", title: "Vävning" },
  { value: "AA0168", title: "Krubbitning" },
  { value: "AA0169", title: "Flugfångning" },
  { value: "AA0170", title: "Luftsnappning" },
  { value: "AA0171", title: "Fjäderätning, äggpickning" },
  { value: "AA0173", title: "Psykogen polydipsi" },
  { value: "AA0174", title: "Förlossningshysteri" },
  { value: "AA0175", title: "Onormal svettning" },
  {
    value: "AA0176",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, hela djuret",
  },
  { value: "AA0177", title: "Missbildning, hela djuret" },
  { value: "AA0178", title: "Kromosomförändring" },
  { value: "AA0179", title: "Utvecklingsrubbn, tillväxtrubbn, hela djuret" },
  {
    value: "AA0183",
    title: "Metabol nutritionella degen/dystrof förändr, hela djuret",
  },
  { value: "AA0184", title: "Metaboliska förändringar, hela djuret" },
  { value: "AA0185", title: "Uremi u fastställd orsak" },
  { value: "AA0188", title: "Elektrolytrubbningar u fastställd orsak" },
  { value: "AA0189", title: "Hyperlipemi u fastställd orsak" },
  { value: "AA0190", title: "Symptom på vanvård" },
  { value: "AA01901", title: "Nedsmutsat djur" },
  { value: "AA0191", title: "Hyperkalemi u fastställd orsak" },
  { value: "AA0192", title: "Hypernatremi u fastställd orsak" },
  { value: "AA0193", title: "Hypocalcemi u fastställd orsak" },
  { value: "AA0194", title: "Kalvningsförlamning" },
  { value: "AA0195", title: "Paresis Puerperalis" },
  { value: "AA0197", title: "Pares (ej puerperal)" },
  { value: "AA0200", title: "Hypokalemi u fastställd orsak" },
  { value: "AA0201", title: "Hyponatremi u fastställd orsak" },
  { value: "AA0202", title: "Hypomagnesemi" },
  {
    value: "AA0205",
    title: "Övriga elektrolytförändringar u fastställd orsak",
  },
  { value: "AA0206", title: "Proteinrubbningar" },
  { value: "AA0207", title: "Hypoproteinemi" },
  { value: "AA0208", title: "Hypogammaglobulinemi" },
  { value: "AA0209", title: "Hypoalbuminemi" },
  { value: "AA0210", title: "Hyperlipemi" },
  { value: "AA0211", title: "Hemoglobinemi" },
  { value: "AA0212", title: "Methemoglobinemi" },
  { value: "AA0213", title: "Onormala blodlevervärden" },
  { value: "AA0214", title: "Eklampsi" },
  { value: "AA0215", title: "Övriga metaboliska störningar" },
  { value: "AA0216", title: "Hypoglykemi" },
  { value: "AA0222", title: "Nutritionella förändringar" },
  { value: "AA0223", title: "Fetma" },
  { value: "AA0225", title: "Avmagring pga underutfodring" },
  { value: "AA0226", title: "Sjukdomar pga vitamin / mineralrubbningar" },
  { value: "AA0227", title: "Vitaminstörningar" },
  { value: "AA0228", title: "Vitamin A brist" },
  { value: "AA0229", title: "Hypervitaminos A" },
  { value: "AA0230", title: "Vitamin D brist (Rakitis)" },
  { value: "AA0231", title: "Hypervitaminos D" },
  { value: "AA0232", title: "Thiamin brist" },
  { value: "AA0233", title: "Vitamin C brist" },
  { value: "AA0236", title: "Övriga vitamin brist eller vitaminöverskott" },
  { value: "AA0237", title: "Mineral / spårelement rubbning" },
  { value: "AA0238", title: "Selenbrist" },
  { value: "AA0239", title: "Järnbrist" },
  { value: "AA0240", title: "Kopparbrist" },
  { value: "AA0241", title: "Koboltbrist" },
  { value: "AA0242", title: "Jodbrist (struma)" },
  { value: "AA0243", title: "Parakeratos dietetica" },
  { value: "AA0244", title: "Zinkbrist" },
  { value: "AA0245", title: "Övriga rubbningar i mineral / spårelement" },
  { value: "AA0246", title: "Cirkulatoriska förändringar" },
  { value: "AA0247", title: "Blödningar, generellt" },
  { value: "AA0248", title: "Petekiala blödningar, generellt" },
  { value: "AA0249", title: "Ödem, ospecifikt" },
  { value: "AA0250", title: "Generella ventrala ödem u fastställd orsak" },
  { value: "AA0251", title: "Infekt, infl förändringar" },
  { value: "AA0253", title: "Specifika infektionssjukdomar" },
  { value: "AA0254", title: "Virusinfektioner hund" },
  { value: "AA0255", title: "Rabiesinfektion hund" },
  { value: "AA0256", title: "Valpsjuka" },
  { value: "AA0257", title: "HCC" },
  { value: "AA0258", title: "Parvovirusinfektion" },
  { value: "AA0259", title: "Coronavirusinfektion hos hund" },
  { value: "AA0260", title: "TBE (Tick borne encephalitis)" },
  { value: "AA0261", title: "Parainfluensa infektion hund" },
  { value: "AA0262", title: "Herpesinfektion hund" },
  { value: "AA0264", title: "Övriga virusinfektioner hund" },
  { value: "AA0266", title: "Virusinfektioner katt" },
  { value: "AA0267", title: "Rabies infektion katt" },
  { value: "AA0268", title: "Parvovirusinfektion (kattpest)" },
  { value: "AA0269", title: "Kattsnuva" },
  { value: "AA0270", title: "Leukemi hos katt" },
  { value: "AA0271", title: "Leukos hos katt" },
  { value: "AA0272", title: "FeLV infektion" },
  { value: "AA03", title: "Temperatur förändring" },
  { value: "AA031", title: "Feber" },
  { value: "AA032", title: "Undertemperatur" },
  { value: "AA033", title: "Hypertermi (värmeslag, malign hypertermi)" },
  { value: "AA05", title: "Aktivitetsstörning" },
  { value: "AA051", title: "Apati" },
  { value: "AA052", title: "Hyperaktivitet" },
  { value: "AA053", title: "Kollaps UNS" },
  { value: "AA06", title: "Beteendeförändring" },
  { value: "AA061", title: "Lynnesfel" },
  { value: "AA062", title: "Agressivitet" },
  {
    value: "AA0621",
    title: "Kannibalism (svansbitning svin, kloak- huvudhackning höns)",
  },
  { value: "AA063", title: "Tvångsrörelser" },
  { value: "AA0631", title: "Vävning" },
  { value: "AA0632", title: "Krubbitning" },
  { value: "AA0633", title: "Flugfångning" },
  { value: "AA0634", title: "Luftsnappning" },
  { value: "AA0635", title: "Fjäderätning, äggpickning" },
  { value: "AA0636", title: "Fjäderplockning" },
  { value: "AA064", title: "Psykogen polydipsi" },
  { value: "AA065", title: "Förlossningshysteri" },
  { value: "AA07", title: "Onormal svettning" },
  {
    value: "AA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, hela djuret",
  },
  { value: "AA10", title: "Missbildning, hela djuret" },
  { value: "AA101", title: "Kromosomförändring" },
  { value: "AA11", title: "Utvecklingsrubbn, tillväxtrubbn, hela djuret" },
  { value: "AA111", title: "Pellesyndromet (nedsatt tillväxt, svin)" },
  { value: "AA1111", title: "Pellesyndromet (nedsatt tillväxt, svin)" },
  { value: "AA1112", title: 'Nedsatt tillväxt ("Pellekalv")' },
  {
    value: "AA2",
    title: "Metabol nutritionella degen/dystrof förändr, hela djuret",
  },
  { value: "AA20", title: "Metaboliska förändringar, hela djuret" },
  { value: "AA201", title: "Uremi u fastställd orsak" },
  { value: "AA202", title: "Ikterus u fastställd orsak" },
  { value: "AA203", title: "Elektrolytrubbningar u fastställd orsak" },
  { value: "AA2031", title: "Hyperlipemi u fastställd orsak" },
  { value: "AA2032", title: "Hypercalcemi u fastställd orsak" },
  { value: "AA2033", title: "Hyperkalemi u fastställd orsak" },
  { value: "AA2034", title: "Hypernatremi u fastställd orsak" },
  { value: "AA2035", title: "Hypocalcemi u fastställd orsak" },
  { value: "AA20351", title: "Kalvningsförlamning (Puerp.pares, nöt)" },
  { value: "AA20352", title: "Pares (ej pueperal)" },
  { value: "AA20353", title: "Dräktighetsförlamning (hypocalcemi)" },
  { value: "AA2036", title: "Hypokalemi u fastställd orsak" },
  { value: "AA2037", title: "Hyponatremi u fastställd orsak" },
  { value: "AA2038", title: "Hypomagnesemi (betes-stall-kalvkramp)" },
  {
    value: "AA2039",
    title: "Övriga elektrolytförändringar u fastställd orsak",
  },
  { value: "AA204", title: "Proteinrubbningar" },
  { value: "AA2041", title: "Hypoproteinemi" },
  { value: "AA2042", title: "Hypogammaglobulinemi" },
  { value: "AA2043", title: "Hypoalbuminemi" },
  { value: "AA205", title: "Hyperlipemi" },
  { value: "AA206", title: "Hemoglobinemi" },
  { value: "AA2061", title: "Metylhemoglobinemi" },
  { value: "AA207", title: "Onormala blodlevervärden" },
  { value: "AA208", title: "Eklampsi hund och katt" },
  { value: "AA209", title: "Hypoglukemi /denna diagnos ersätts av AA20911/" },
  { value: "AA2091", title: "Övriga metaboliska störningar" },
  { value: "AA20911", title: "Hypoglykemi" },
  { value: "AA20912", title: "Acetonemi" },
  { value: "AA20913", title: "Nervös acetonemi (Stallkramp Mg/energibrist)" },
  {
    value: "AA20914",
    title: "Dräktighetstoxikos (acidos,leverförfettning) får",
  },
  { value: "AA2092", title: "Acetonemi" },
  { value: "AA2093", title: "Nervös acetonemi" },
  { value: "AA2094", title: "Dräktighetstoxikos (acidos, leverförfettning)" },
  { value: "AA21", title: "Nutritionella förändringar" },
  { value: "AA211", title: "Fetma" },
  { value: "AA212", title: "Avmagring pga underutfodring" },
  { value: "AA213", title: "Sjukdomar pga vitamin / mineralrubbningar" },
  { value: "AA2131", title: "Vitaminstörningar" },
  { value: "AA21311", title: "Vitamin A brist" },
  { value: "AA21312", title: "Hypervitaminos A" },
  { value: "AA21313", title: "Vitamin D brist (Rakitis)" },
  { value: "AA21314", title: "Hypervitaminos D" },
  { value: "AA21315", title: "Thiamin brist" },
  { value: "AA21316", title: "Vitamin C brist" },
  { value: "AA21317", title: "Vitamin E brist" },
  { value: "AA21318", title: "B vitaminbrist" },
  { value: "AA21319", title: "Övriga vitamin brist eller vitaminöverskott" },
  { value: "AA2132", title: "Mineral / spårelement rubbning" },
  { value: "AA21321", title: "Selenbrist" },
  { value: "AA21322", title: "Järnbrist" },
  { value: "AA21323", title: "Kopparbrist" },
  { value: "AA21324", title: "Koboltbrist" },
  { value: "AA21325", title: "Jodbrist (struma, se äv. EA2371)" },
  { value: "AA21329", title: "Övriga rubbningar i mineral / spårelement" },
  {
    value: "AA22",
    title: "Degenerativa/dystrofiska förändringar, hela djuret",
  },
  { value: "AA3", title: "Cirkulatoriska förändringar, hela djuret" },
  { value: "AA30", title: "Blödningar, generellt hela djuret" },
  { value: "AA301", title: "Petekiala blödningar, hela djuret" },
  { value: "AA31", title: "Ödem, hela djuret" },
  {
    value: "AA311",
    title: "Generella ventrala ödem u fastställd orsak , hela djuret",
  },
  { value: "AA32", title: "Ischemi, hela djuret" },
  { value: "AA33", title: "Infarkter, hela djuret" },
  { value: "AA34", title: "Hygrom, hela djuret, hela djuret" },
  { value: "AA4", title: "Infekt, infl förändringar" },
  { value: "AA40", title: "Specifika infektionssjukdomar, hela djuret" },
  { value: "AA401", title: "Virusinfektioner hund" },
  { value: "AA4011", title: "Rabies infektion hund" },
  { value: "AA4012", title: "Valpsjuka" },
  { value: "AA4013", title: "HCC" },
  { value: "AA4014", title: "Parvovirusinfektion" },
  { value: "AA4015", title: "Coronavirusinfektion hos hund" },
  { value: "AA4016", title: "TBE (Tick borne ecephalitis)" },
  { value: "AA4017", title: "Parainfluensa infektion" },
  { value: "AA4018", title: "Herpesinfektion" },
  { value: "AA4019", title: "Influensa, hund" },
  { value: "AA40199", title: "Övriga virusinfektioner hund" },
  { value: "AA402", title: "Virusinfektioner katt" },
  { value: "AA4021", title: "Rabies infektion katt" },
  { value: "AA4022", title: "Parvovirusinfektion (kattpest)" },
  { value: "AA4023", title: "Kattsnuva" },
  { value: "AA4024", title: "FeLV infektion" },
  { value: "AA4025", title: "FIP infektion" },
  { value: "AA4026", title: "FIV infektion" },
  { value: "AA4027", title: "FRV infektion" },
  { value: "AA4028", title: "FCV infektion" },
  { value: "AA4029", title: "Feline bornavirus infektion" },
  {
    value: "AA40291",
    title: "TSE- transmissible spongiform encefalopati, katt",
  },
  { value: "AA40292", title: "TBE (Tickborne encephalitis) katt" },
  { value: "AA40299", title: "Övriga virusinfektioner katt" },
  { value: "AA403", title: "Virusinfektioner häst" },
  { value: "AA4031", title: "Virusarterit" },
  { value: "AA40310", title: "Influensa" },
  { value: "AA40311", title: "Rhinovirus typ 1 infektion" },
  { value: "AA40312", title: "Rhinovirus typ 2 infektion" },
  { value: "AA40313", title: "Reovirus infektion" },
  { value: "AA40314", title: "Vesikulär stomatit" },
  { value: "AA40315", title: "Afrikansk hästpest" },
  { value: "AA4032", title: "Infektiös anemi(hästdiagnos)" },
  { value: "AA4033", title: "Rotavirusinfektion häst" },
  { value: "AA4034", title: "Coronavirusinfektion hos häst" },
  { value: "AA4035", title: "Adenovirusinfektion" },
  { value: "AA4036", title: "Ekvint herpes virus typ 1 infektion(virusabort)" },
  { value: "AA4037", title: "Ekvint herpes virus typ 2 infektion" },
  {
    value: "AA4038",
    title: "Ekvint herpes virus typ 3 infektion (coitalt exantem)",
  },
  {
    value: "AA4039",
    title: "Ekvint herpes virus typ 4 infektion (rhinopneumonit)",
  },
  { value: "AA40391", title: "Rabies, häst" },
  { value: "AA40394", title: "Bornavirus-infektion, häst" },
  { value: "AA40395", title: "Ekvina encefalomyeliter (EEV)" },
  { value: "AA40396", title: "TBE (Tickborne encephalitis) katt" },
  { value: "AA40397", title: "Blue tongue-infektion" },
  { value: "AA40398", title: "West nile virus-infektion" },
  { value: "AA40399", title: "Övriga virusinfektioner häst" },
  { value: "AA404", title: "Virusinfektioner nöt/får/get" },
  {
    value: "AA4040",
    title: "TSE- transmissible spongiform encefalopati (nöt, får, get)",
  },
  {
    value: "AA40401",
    title: "BSE (Bovin spongiform encephalopati, Madcow disease)",
  },
  { value: "AA40402", title: "BSE, får,get" },
  { value: "AA40403", title: "Scrapie (får,get)" },
  { value: "AA40404", title: "Nor98, får" },
  { value: "AA4041", title: "Parainfluensavirus typ 3 infektion (PIV-3)" },
  { value: "AA40410", title: "Boskapspest" },
  { value: "AA40411", title: "Lumpy skin disease" },
  { value: "AA40412", title: "Adenovirusinfektion nöt/får/get" },
  { value: "AA40413", title: "Fårkoppor" },
  { value: "AA40414", title: "Bluetongue (får)" },
  {
    value: "AA40415",
    title: "BSE (Bovin spongiform encephalopati, Madcow disease)",
  },
  { value: "AA40416", title: "Scrapie (får,get)" },
  { value: "AA40417", title: "Bovin virusleukos (BVL, oncovirus)" },
  { value: "AA40418", title: "Reovirusinfektion" },
  { value: "AA40419", title: "Rhinovirusinfektion" },
  { value: "AA4042", title: "Rotavirusinfektion nöt/får/get" },
  { value: "AA40420", title: "BRSV (Bovint respiratoriskt syncytialt virus)" },
  {
    value: "AA40421",
    title: "IBR/IPV (Inf.bovin rhinotr /Inf.pustulär vulvovaginit)",
  },
  { value: "AA40422", title: "Katarralfeber, elakartad (Herpesvirus)" },
  {
    value: "AA40423",
    title: "Coronavirusinfektion (vinterdys,kalvent,luftv.inf.)",
  },
  {
    value: "AA4043",
    title: "BVD/MD/BD (Bovin virusdiarré, Mukosal-, Borderdisease)",
  },
  { value: "AA40430", title: "Kronisk BVD" },
  { value: "AA40431", title: "BVDV-kroniker slaktbidrag" },
  { value: "AA40432", title: "BD Borderdisease" },
  { value: "AA40433", title: "Hairy shaker - kronisk borderdisease" },
  { value: "AA4044", title: "Bovin parvovirusinfektion" },
  { value: "AA4045", title: "Maedi/Visna" },
  {
    value: "AA4046",
    title: 'CAE (Caprin Artrit och Encephalit,"inf.pares", get)',
  },
  {
    value: "AA4047",
    title: "Louping ill (akut encephalomyelit, flavivirus, får)",
  },
  { value: "AA4048", title: "Rabies infektion nöt/får/get" },
  { value: "AA4049", title: "Mul- och klövsjuka nöt/får/get" },
  { value: "AA40490", title: "Mul- och klövsjuka (MK)" },
  { value: "AA40491", title: "Rift Valley fever" },
  {
    value: "AA40492",
    title: "AD (Aujeszkys dis, pseudorabies, Herpesvirus), nöt",
  },
  { value: "AA40493", title: "Vesiculär stomatit (VS), nöt/får/get" },
  { value: "AA40494", title: "Rotavirusinfektion, nöt/får/get" },
  { value: "AA40495", title: "Peste des petites ruminantes" },
  { value: "AA40497", title: "Smittsamt muneksem (Orf, får/get)" },
  { value: "AA40499", title: "Övriga virusinfektioner nöt/får/get" },
  { value: "AA405", title: "Virusinfektioner svin" },
  { value: "AA4051", title: "Am.svininfluensa" },
  { value: "AA40510", title: "Rotavirusinfektion svin" },
  { value: "AA40511", title: "TGE (Transmissible gastroenterit, Coronavirus)" },
  { value: "AA40512", title: "PED (Porcine epidemisk diarré, PED-virus)" },
  { value: "AA40513", title: "Porcin parvovirusinfektion" },
  { value: "AA40514", title: "PRRS (Porcint Repr. o Resp.Syndrom, togavirus)" },
  { value: "AA40515", title: "SMEDI (Stillbirth and infert., enterovirus)" },
  {
    value: "AA40516",
    title: "HEV/VWD (Hemaggl.enceph.myelit/Vomiting and Wasting dis.",
  },
  { value: "AA40517", title: "Afrikansk svinpest" },
  {
    value: "AA40518",
    title: "Teschen/Talfan (sporadisk encephalomyelit,svin)",
  },
  {
    value: "AA40519",
    title: "AD (Aujeskys disease, pseudorabies, Herpesvirus)",
  },
  { value: "AA40520", title: "Klassisk svinpest (Hog cholera)" },
  { value: "AA40521", title: "Rabies infektion svin" },
  { value: "AA40522", title: "PRCV (Porcint Resp. Coronavirus)" },
  { value: "AA40523", title: "Porcint cytomegalovirusinfektion" },
  { value: "AA40524", title: "Mul- och klövsjuka svin" },
  { value: "AA40525", title: "SVD (Swine vesicular disease)" },
  { value: "AA40526", title: "Vesikulär stomatit (VS), svin" },
  { value: "AA40527", title: "Rift Valley Fever, svin" },
  { value: "AA40528", title: "Bluetongue, svin" },
  {
    value: "AA40530",
    title: "Postweaning multisystemic wasting syndrome (PMWS)",
  },
  { value: "AA40531", title: "Circo-virusinfektion" },
  { value: "AA40549", title: "Övriga virusinfektioner svin" },
  { value: "AA40550", title: "Virusinfektioner höns / Övriga" },
  { value: "AA40551", title: "Mareks sjukdom (MD hönslamhet,herpesv.)" },
  { value: "AA40552", title: "Aviär encephalomyelit (epid.tremor, Picornav.)" },
  { value: "AA40553", title: "Infektiös bronkit (Coronav.)" },
  { value: "AA40554", title: "Infektiös bursit (Gumborosjukan, Birnav.)" },
  { value: "AA40555", title: "Blåvingesjuka (CAA Chicken anemia agent)" },
  {
    value: "AA40556",
    title: "Inklusionskropps-hepatit (IBH Adenov. fjäderfä)",
  },
  { value: "AA40557", title: "New castle disease" },
  {
    value: "AA40558",
    title: "Infektiös laryngotracheit (ILT,herpesv. fjäderfä)",
  },
  {
    value: "AA40559",
    title: "TRT/SHS (Turkey rhinotracheitis/swollen head syndrome)",
  },
  { value: "AA40560", title: "EDS (egg drop syndrome)" },
  { value: "AA40561", title: "Fågelkoppor" },
  { value: "AA40562", title: "Rotavirusinfektion hos höns" },
  { value: "AA40563", title: "AIV, fjäderfä" },
  { value: "AA40564", title: "Reovirus-infektion, fjäderfä" },
  { value: "AA40565", title: "Paramyxovirus, ej NDV" },
  { value: "AA40566", title: "Aviär leukos, ej Mareks sjukdom" },
  { value: "AA40567", title: "Rabbit viral haemorrhagic disease (RVHD)" },
  { value: "AA40599", title: "Övriga virus höns / övriga" },
  { value: "AA406", title: "Bakteriella infektioner" },
  { value: "AA4061", title: "Salmonella infektion" },
  {
    value: "AA40610",
    title: "Mykoplasmainf (SEP swine enzoot.pn.,Elakart.lungsj.nöt/get)",
  },
  {
    value: "AA40611",
    title: "Aktinomyk inf (A.bovis,benaktinos. A.Pyogenesinf mfl.)",
  },
  {
    value: "AA40612",
    title: "Antrax infektion (Mjältbrand,bacillus antrasis)",
  },
  { value: "AA40613", title: "Botryomykos infektion" },
  { value: "AA40614", title: "Bacillus piliformis infektion (Tyzzer´s)" },
  { value: "AA40615", title: "Botulism" },
  {
    value: "AA40616",
    title: "E.coli infektion (enterit, ödemsjuka svin mfl.)",
  },
  { value: "AA40617", title: "Tetanus infektion" },
  { value: "AA40618", title: "Intestinal clostridios (Baron Gruff)" },
  { value: "AA40619", title: "Clostridium difficile enterit" },
  { value: "AA4062", title: "Borrelia infektion" },
  {
    value: "AA40620",
    title: "Rhodococcus equi infektion (pneumoni / artrit mm)",
  },
  { value: "AA40621", title: "CEM (Contagiös equin metrit)" },
  { value: "AA40622", title: "Sepsis" },
  { value: "AA40623", title: "Fölsepsis" },
  { value: "AA40624", title: "Streptococcus equi infektion, kvarka" },
  { value: "AA40625", title: "Bordetella infektion" },
  { value: "AA40626", title: "Pseudomonas mallei Rots" },
  { value: "AA40627", title: "Kastad kvarka" },
  { value: "AA40628", title: "Streptococcus zooepidemicus" },
  { value: "AA4063", title: "Erlichia infektion (Betesfeber nöt mfl)" },
  { value: "AA40630", title: "Streptococcus equisimilis" },
  { value: "AA40631", title: "Mykoplasmainf (Elakart.lungsj.nöt/get)" },
  { value: "AA40632", title: "Mykoplasmahosta (får)" },
  { value: "AA40639", title: "Ehrlichia canis-infektion" },
  { value: "AA4064", title: "Nocardia infektion" },
  { value: "AA4065", title: "Leptospira infektion" },
  {
    value: "AA40651",
    title: "Clostridium perfr.typ A (enterotox.lamm,nekr.enterit höns)",
  },
  { value: "AA40652", title: "Clostridium perfr. typ B (lamm- kalvdysenteri)" },
  {
    value: "AA40653",
    title: 'Clostridium perfr.typ C (smitts.tarmbrand,svin."struck"får)',
  },
  {
    value: "AA40654",
    title: "Clostrisium perfr.typ D (overeating dis.pulpy kidney)",
  },
  { value: "AA40655", title: "Brådsot (Cl.septicum)" },
  { value: "AA40656", title: "Malingt ödem (Cl.septicum mfl.)" },
  { value: "AA40657", title: "Frasbrand (Cl.chauvoei,black leg disease)" },
  {
    value: "AA40658",
    title: "Pasturellos (P. multocida/hemolytica, enzootisk pneumoni)",
  },
  {
    value: "AA40659",
    title: "Hemhorragisk nekr. enterit, föl (Cl. perfr.typ B och C)",
  },
  { value: "AA4066", title: "Tuberkulos (Inkl M.tuberculosis, M.bovis)" },
  { value: "AA40660", title: "Q-fever (Queenstand fever, Coxiella burnetti)" },
  { value: "AA40661", title: "Aktinobacillos (Mjukdelsaktinos)" },
  { value: "AA40662", title: "Necrobacillos (Fusobakt.necrophorum)" },
  { value: "AA40663", title: "Listerios" },
  { value: "AA40664", title: "Campylobacter fetus infektion" },
  {
    value: "AA40665",
    title: "Pseudotuberculos (Corynebact.pseudot.C.ovis,böldsj.får/get",
  },
  { value: "AA40666", title: "Epirythrozoon ovis (rikettsie)" },
  { value: "AA40667", title: "Glässers sjukdom (Haemophilus parasuis,svin)" },
  {
    value: "AA40668",
    title: "Elakartad lungsjuka (Actinobacillus pleuropneumoniae,svin)",
  },
  { value: "AA40669", title: "Erysipelas (rödsjuka svin. nöt,får,fjäderfä)" },
  {
    value: "AA4067",
    title: "Annan mykobakt.inf (Paratuberkulos, Aviär tuberkulos mfl.)",
  },
  { value: "AA40670", title: "Svindysenteri (Serpulina hyodysenteriae)" },
  {
    value: "AA40671",
    title: "PIA (Porcine intest.adenomatos,Campylobact.inf.svin.(höns)",
  },
  {
    value: "AA40672",
    title: "Infektiös coryza (hönssnuva, Hemophilus paragallinarium)",
  },
  { value: "AA40673", title: "Hönskolera (Pasteurella)" },
  { value: "AA40674", title: "Hönspest (Salmonella pullorum)" },
  { value: "AA40675", title: "Ornithos  / Papegojsjuka" },
  { value: "AA40676", title: "Paratuberkulos" },
  { value: "AA40677", title: "SEP (swine enzoot.pneumoni)" },
  { value: "AA40678", title: "Mycoplasma wenyonii" },
  { value: "AA40679", title: "Mycoplasma suis" },
  { value: "AA4068", title: "Brucella spp, infektion med" },
  { value: "AA40680", title: "Harpest" },
  { value: "AA40681", title: "Listerios" },
  { value: "AA4069", title: "Infektion med Chlamydia spp" },
  { value: "AA40699", title: "Övriga bakteriella infektioner" },
  { value: "AA4070", title: "Leptospira infektion" },
  { value: "AA4071", title: "Lawsonia infektion" },
  { value: "AA4072", title: "Fotröta, får" },
  { value: "AA4073", title: "Klebsiella pneumonie, endometrit häst" },
  { value: "AA40731", title: "Klebsiella pneumonie, handjur häst" },
  { value: "AA4074", title: "Pseudomonas aeruginosa, endometrit häst" },
  { value: "AA40741", title: "Pseudomonas aeruginosa, handjur häst" },
  { value: "AA4075", title: "Mycoplasma haemofelis (fd Hemobartonella)" },
  { value: "AA4076", title: "Mycoplasma haemocanis (fd Hemobartonella)" },
  { value: "AA4077", title: "Nicoletella semolina" },
  { value: "AA408", title: "Mykotiska infektioner" },
  { value: "AA4081", title: "Aspergillus infektion" },
  { value: "AA4082", title: "Kryptokockus infektion" },
  { value: "AA4083", title: "Histoplasma infektion" },
  { value: "AA4084", title: "Sporotrichos infektion" },
  { value: "AA40899", title: "Övriga svampinfektioner" },
  { value: "AA41", title: "Inflammationstillstånd, akut, hela djuret" },
  { value: "AA42", title: "Kroniska inflammationstillstånd, hela djuret" },
  { value: "AA43", title: "Parasitära sjukdomar, hela djuret" },
  { value: "AA431", title: "Rundmaskar" },
  { value: "AA4311", title: "Spolmask" },
  { value: "AA43111", title: "Toxocara" },
  { value: "AA43112", title: "Toxascaris" },
  { value: "AA43113", title: "Parascaris" },
  { value: "AA43114", title: "Ascaris" },
  { value: "AA4312", title: "Blodmaskar" },
  { value: "AA43121", title: "Strongylus" },
  { value: "AA43122", title: "Cyathostomine (Trichonema)" },
  { value: "AA4313", title: "Hakmaskar" },
  { value: "AA43131", title: "Uncinaria" },
  { value: "AA43132", title: "Ancylostoma" },
  { value: "AA43133", title: "Bunostomum (B.trigonocephalum, får)" },
  { value: "AA4314", title: "Lungmask" },
  { value: "AA43141", title: "Dictyocaulus" },
  { value: "AA43142", title: "Capillaria" },
  {
    value: "AA43143",
    title: "Protostrongylus (P.rufescens,mellanst.lungmasken får,get)",
  },
  {
    value: "AA43144",
    title: "Muellerius (M.capillaris, lilla lungmasken får,get)",
  },
  { value: "AA43145", title: "Metastrongylus (M.apri lungmask svin)" },
  { value: "AA4315", title: "Övriga rundmaskar" },
  { value: "AA43151", title: "Strongyloides" },
  { value: "AA43152", title: "Oxyuris" },
  { value: "AA43153", title: "Onchocerca" },
  { value: "AA43154", title: "Habronema" },
  { value: "AA43155", title: "Dirofilaria" },
  { value: "AA43156", title: "Trichuris" },
  { value: "AA43157", title: "Thelazia" },
  { value: "AA43158", title: "Ostertagia (O.ostertagia,O.circumcincta)" },
  { value: "AA43159", title: "Cooperia (C.onchophora, nöt)" },
  { value: "AA43160", title: "Parafilaria (P.bovicola)" },
  {
    value: "AA43161",
    title: "Trichostrongylus (T.axei,lilla magmasken T.vitrinus, får)",
  },
  { value: "AA43162", title: "Haemonchus (H.contortus får,get)" },
  { value: "AA43163", title: "Nematodirus (N.filicollis,N.spathiger får,get)" },
  {
    value: "AA43165",
    title: "Oesophagostomum (O.venulosum,får O.dentatum,svin)",
  },
  { value: "AA43166", title: "Chabertia (C.ovina) får" },
  { value: "AA43167", title: "Hyostrongylus (H.rubidus,röd magmask svin)" },
  { value: "AA43168", title: "Trikinos (Trichinella spp, muskeltrikin svin)" },
  { value: "AA43169", title: "Heterakis (H.Gallinae, fjäderfä)" },
  { value: "AA43170", title: "Syngamus (S.trachea, gapmask)" },
  { value: "AA43171", title: "Capillarios (hårmask,fjäderfä)" },
  { value: "AA43172", title: "Angiostrongylus vasorum" },
  { value: "AA43173", title: "Filaroides osleri" },
  { value: "AA43174", title: "Crenosoma vulpis" },
  { value: "AA43175", title: "Capillaria vulpis" },
  { value: "AA432", title: "Bandmaskar" },
  { value: "AA4321", title: "Taenia" },
  { value: "AA43211", title: "Dynt, nöt (Cysticercus bovis)" },
  { value: "AA4322", title: "Diplyidium" },
  { value: "AA4323", title: "Echinococcus" },
  { value: "AA43231", title: "Echinococcus granulosus" },
  { value: "AA43232", title: "Echinococcus multilocularis" },
  { value: "AA4324", title: "Mesocestoides" },
  { value: "AA4325", title: "Diphyllobothrium" },
  { value: "AA4326", title: "Anoplocephala" },
  { value: "AA4327", title: "Moniezia infektion (nöt,får,get)" },
  { value: "AA433", title: "Protozooer" },
  { value: "AA4330", title: "Histomonas (H.melagridis) ffa kalkoner" },
  { value: "AA4331", title: "Coccidier" },
  { value: "AA43311", title: "Isospora" },
  { value: "AA43312", title: "Toxoplasma" },
  { value: "AA43313", title: "Sarcocystis" },
  { value: "AA43314", title: "Neospora" },
  { value: "AA43315", title: "Emeria" },
  { value: "AA4332", title: "Leichmania" },
  { value: "AA4333", title: "Giardia" },
  { value: "AA4334", title: "Babesia" },
  {
    value: "AA4335",
    title: "Beskällarsjuka (Dourine, Trypanosoma equiperdum,häst)",
  },
  { value: "AA4336", title: "Trichomonas fetus infektion, nöt" },
  { value: "AA4337", title: "Trichomoniasis (Trichomonas gallinae, fåglar)" },
  { value: "AA4338", title: "Parasitär sjukdom - cryptosporidios" },
  { value: "AA434", title: "Gasterophilus" },
  {
    value: "AA4342",
    title: "Stora leverflundran (Distomatos, Fasciola hepatica)",
  },
  { value: "AA4343", title: "Lilla leverflundran (Dicrocoelium dentriticum)" },
  { value: "AA4344", title: "Paramhistomum (Våmflundra)" },
  { value: "AA435", title: "Hemobartonella" },
  { value: "AA436", title: "Kvalster" },
  { value: "AA4361", title: "Mjällkvalster (cheyletiella)" },
  { value: "AA43611", title: "Cheyletiella yasguri (hund)" },
  { value: "AA43612", title: "Cheletiella blakei (katt)" },
  { value: "AA4362", title: "Skabb" },
  { value: "AA43621", title: "Rävskabb (Sarcoptes)" },
  { value: "AA43622", title: "Otodectes" },
  { value: "AA43623", title: "Notoedres" },
  { value: "AA43624", title: "Chorioptes (C.bovis,ovis)" },
  { value: "AA43625", title: "Psoroptes (P.bovis)" },
  { value: "AA43626", title: "Dermanyssus gallinae (röda hönskvalstret)" },
  {
    value: "AA43627",
    title: "Fotskabb (Knemidocoptes mutans, kalkben fjäderfä)",
  },
  { value: "AA43628", title: "Svinskabb" },
  { value: "AA4363", title: "Demodex" },
  { value: "AA4364", title: "Pneumonyssus" },
  { value: "AA4365", title: "Fästing" },
  { value: "AA43651", title: "Ixodes" },
  { value: "AA43652", title: "Brun hundfästning (Rhipicephalus)" },
  { value: "AA43653", title: "Fårfästing (Haemaphysalis punctata)" },
  { value: "AA437", title: "Parasitära insekter" },
  { value: "AA4371", title: "Loppor" },
  { value: "AA43711", title: "Kattloppa (Ctenocephalides felis)" },
  { value: "AA43712", title: "Hundloppa (Ctenocephalides canis)" },
  { value: "AA43713", title: "Ej artegen loppa" },
  { value: "AA43714", title: "Ceratophyllus gallinae (europeisk hönsloppa)" },
  { value: "AA4372", title: "Löss" },
  {
    value: "AA43721",
    title: "Päls-/fjäderätande lus (Tricodectes,Bovicola,Menopon",
  },
  { value: "AA43722", title: "Blodsugande lus (Linognatus, Haematopinus)" },
  { value: "AA43723", title: "Kattens lus ( Felicola subrostratus)" },
  { value: "AA43724", title: "Hästens lus ( Haematopinus asini )" },
  { value: "AA43725", title: "Svinlus" },
  { value: "AA4373", title: "Fluglarvangrepp (Myiasis)" },
  { value: "AA43731", title: "Fårstynglarv (Oestrus ovis)" },
  { value: "AA43732", title: "Styngflugelarver (häst)" },
  { value: "AA4374", title: "Knottangrepp" },
  { value: "AA439", title: "Övriga parasitangrepp" },
  { value: "AA5", title: "Immunologiska förändringar, hela djuret" },
  { value: "AA51", title: "Anafylaktisk chock, hela djuret" },
  { value: "AA52", title: "SLE" },
  { value: "AA53", title: "Anasarka" },
  { value: "AA54", title: "Myasthenia gravis" },
  { value: "AA55", title: "Combined immunodeficiency" },
  { value: "AA6", title: "Neoplastiska förändringar" },
  { value: "AA61", title: "Lymfosarkom / Malignt lymfom (leukos)" },
  { value: "AA7", title: "Trauma, fr kr, lägeförändr, term skad, hela djuret" },
  { value: "AA71", title: "Traumatiska / mekaniska skador, hela djuret" },
  { value: "AA711", title: "Trafikskada" },
  { value: "AA7111", title: "Död/skadad av motorfordon" },
  { value: "AA7112", title: "Död/skadad av tåg/påkörd" },
  { value: "AA712", title: "Fall från hög höjd" },
  { value: "AA713", title: "Skottskada" },
  { value: "AA715", title: "Blixtnedslag" },
  { value: "AA717", title: "Drunkning" },
  { value: "AA718", title: "Skadad av inredning i box, spilta el bås" },
  { value: "AA72", title: "Mekaniska skador" },
  { value: "AA721", title: "Död genom kvävning" },
  { value: "AA722", title: "Död genom strypning" },
  { value: "AA73", title: "Främmande kroppar" },
  { value: "AA74", title: "Lägesförändringar" },
  { value: "AA75", title: "Termiska skador" },
  { value: "AA751", title: "Brännskada" },
  { value: "AA7511", title: "Skadad vid brand/eldsvåda" },
  { value: "AA752", title: "Köldskada" },
  { value: "AA76", title: "Elskada" },
  { value: "AA77", title: "Skadad av djur" },
  { value: "AA771", title: "Skadad av tamdjur" },
  { value: "AA7711", title: "Skadad av häst" },
  { value: "AA7712", title: "Skadad av hund" },
  { value: "AA7713", title: "Skadad av katt" },
  { value: "AA7714", title: "Skadad av tjur/ko" },
  { value: "AA7715", title: "Skadad av får/get" },
  { value: "AA7716", title: "Skadad av svin" },
  { value: "AA772", title: "Skadad av vilda djur" },
  { value: "AA7721", title: "Skadad av björn" },
  { value: "AA7722", title: "Skadad av varg" },
  { value: "AA7723", title: "Skadad av lo" },
  { value: "AA7724", title: "Skadad av järv" },
  { value: "AA7725", title: "Skadad av örn" },
  { value: "AA7726", title: "Skadad av vildsvin" },
  { value: "AA7727", title: "Skadad av älg/älgskräck" },
  { value: "AA78", title: "Dödad av djur" },
  { value: "AA781", title: "Dödad av tamdjur" },
  { value: "AA7811", title: "Dödad av häst" },
  { value: "AA7812", title: "Dödad av hund" },
  { value: "AA7813", title: "Dödad av katt" },
  { value: "AA7814", title: "Dödad av tjur/ko" },
  { value: "AA7815", title: "Dödad av får/get" },
  { value: "AA7816", title: "Dödad av svin" },
  { value: "AA782", title: "Dödad av vilda djur" },
  { value: "AA7821", title: "Dödad av björn" },
  { value: "AA7822", title: "Dödad av varg" },
  { value: "AA7823", title: "Dödad av lo" },
  { value: "AA7824", title: "Dödad av järv" },
  { value: "AA7825", title: "Dödad av örn" },
  { value: "AA7826", title: "Dödad av vildsvin" },
  { value: "AA7827", title: "Dödad av älg" },
  { value: "AA79", title: "Mekaniskt skadad genom människa" },
  { value: "AA791", title: "Skadad av människa" },
  { value: "AA792", title: "Dödad av människa" },
  { value: "AA793", title: "Sexuellt övergrepp mot djur" },
  {
    value: "AA8",
    title: "Toxiska förändringar (Förgiftningar / bett), hela djuret",
  },
  { value: "AA81", title: "Förgiftning, hela djuret" },
  { value: "AA811", title: "Förgiftning med bekämpningsmedel" },
  { value: "AA8111", title: "Förgiftning med råttgift" },
  { value: "AA8112", title: "Förgiftning med organiska forsforföreningar" },
  { value: "AA8119", title: "Förgiftning med övriga bekämpningsmedel" },
  { value: "AA812", title: "Läkemedelspåverkan" },
  { value: "AA8121", title: "Läkemedelsförgiftning" },
  { value: "AA81211", title: "Förgiftning med vitamin / mineral preparat" },
  { value: "AA81212", title: "Förgiftning med hormoner" },
  { value: "AA81213", title: "Förgiftning med sedativa" },
  { value: "AA81214", title: "Förgiftning med analgetika" },
  { value: "AA81219", title: "Förgiftning med övriga läkemedel" },
  { value: "AA8122", title: "Överdos av läkemedel" },
  { value: "AA8123", title: "Läkemedelsbiverkning" },
  { value: "AA813", title: "Förgiftning med kemikalier" },
  { value: "AA8131", title: "Förgiftning med etylenglykol" },
  { value: "AA8132", title: "Förgiftning med petroleumprodukter" },
  { value: "AA8133", title: "Förgiftning med rengöringsmedel" },
  { value: "AA814", title: "Växttoxinförgiftning" },
  { value: "AA8141", title: "Ekollonförgiftning" },
  { value: "AA8142", title: "Idegranförgiftning" },
  { value: "AA8143", title: "Krukväxtförgiftning" },
  { value: "AA8144", title: "Algförgiftning" },
  { value: "AA8145", title: "Sprängörtsförgiftning" },
  { value: "AA8146", title: "Rhododendronförgiftning" },
  { value: "AA8149", title: "Övriga växtförgiftningar" },
  { value: "AA815", title: "Svampförgiftning" },
  { value: "AA8151", title: "Flugsvampförgiftning" },
  { value: "AA8158", title: "Mykotoxinförgiftningj" },
  { value: "AA8159", title: "Övrig svampförgiftning" },
  { value: "AA816", title: "Fodermedelsförgiftning" },
  { value: "AA8161", title: "Toxic fat (ödemsjuka, höns)" },
  {
    value: "AA8162",
    title: "Mineralförgiftning (koppar,selen etc. se äv.AA2132)",
  },
  { value: "AA8163", title: "Mykotoxinförgiftning" },
  { value: "AA8164", title: "Toxic fat (ödemsjuka, höns)" },
  { value: "AA819", title: "Övriga förgiftningar" },
  { value: "AA8191", title: "Blyförgiftning" },
  { value: "AA8192", title: "Koksaltförgiftning" },
  { value: "AA8193", title: "Paddförgiftning" },
  { value: "AA8194", title: "Fluorförgiftning" },
  { value: "AA8195", title: "Kvicksilverförgiftning" },
  { value: "AA8196", title: "Kopparförgiftning (se äv.AA8162,DD201,NG83)" },
  { value: "AA8197", title: "Nitritförgiftning" },
  { value: "AA8198", title: "Gödselgasförgiftning" },
  { value: "AA8199", title: "Choklad/teobrominförgiftning" },
  { value: "AA82", title: "Bett" },
  { value: "AA821", title: "Ormbett" },
  { value: "AA822", title: "Insektsbett" },
  { value: "AA9", title: "Idiopatiska, ospecif, multifakt, hela djuret" },
  { value: "AA91", title: "Chock" },
  { value: "AA911", title: "Cirkulatorisk chock" },
  { value: "AA912", title: "Kardiell chock" },
  { value: "AA913", title: "Endotoxin chock" },
  { value: "AA92", title: "Eosinofilt granulom" },
  { value: "AA93", title: "Arbetsinsufficiens" },
  { value: "AA931", title: "Arbetsinsufficiens + polycytemi" },
  { value: "AA932", title: "Arbetsinsufficiens utan polycytemi" },
  { value: "AA933", title: "Polycytemi" },
  { value: "AA94", title: "Gikt (hyperurikemi, fjäderfä)" },
  { value: "AQ", title: "Agrias specialkoder-orsakskoder" },
  { value: "AQ0021", title: "Dödfödd" },
  { value: "AQ0022", title: "Död genom kvävning" },
  { value: "AQ0023", title: "Död genom strypning" },
  { value: "AQ0024", title: "Död pga medfödd svaghet" },
  { value: "AQ0025", title: "Dödad/skadad av tåg /påkörd" },
  { value: "AQ0027", title: "Dödad av vilt" },
  { value: "AQ00271", title: "Dödad av varg" },
  { value: "AQ00272", title: "Dödad av lo" },
  { value: "AQ00273", title: "Dödad av björn" },
  { value: "AQ00274", title: "Dödad av örn" },
  { value: "AQ01", title: "Hälsokontroll" },
  { value: "AQ02", title: "Senior hälsokontroll" },
  { value: "AQ03", title: "Sexuell hälsokontroll/fertilitetsundersökning" },
  { value: "AQ511", title: "Hyposensibilisering" },
  { value: "AQ71", title: "Olycksfall UNS" },
  { value: "AQ713", title: "Skadad av vilda djur" },
  { value: "AQ7133", title: "Skadad av älg / älgskräck" },
  { value: "AQ716", title: "Skadad av inredning i box,spilta,bås" },
  { value: "AQ72", title: "Komplikationer till operationer/injektioner" },
  { value: "AQ721", title: "Komplikation till kastration" },
  { value: "AQ722", title: "Komplikation till ledinjektion" },
  { value: "AQ723", title: "Komplikation till rektalisering" },
  { value: "AQ731", title: "Kastration, OHE, NK" },
  { value: "AQ732", title: "Avhorning" },
  { value: "AQ733", title: "Nosringning" },
  { value: "AQ734", title: "Sporrexstirpation" },
  { value: "AQ735", title: "Svanskupering" },
  { value: "AQ736", title: "Kejsarsnitt/ sectio cecarea" },
  { value: "AQ741", title: "Skadad vid brand/eldsvåda" },
  { value: "AQ801", title: "Kasserad vid slakt" },
  { value: "AQ911", title: "Försvunnen" },
  { value: "AQ912", title: "Försvunnen under jakt" },
  { value: "AQ913", title: "Stulen" },
  { value: "AQ914", title: "Tjuvslakt" },
  {
    value: "C",
    title: "Cirk organ, blod, blodbild organ, lymfkärl och lymfkn sjukd",
  },
  { value: "CA", title: "Hjärta" },
  { value: "CA0", title: "Normalvar, symtom u fastställd orsak, hjärta" },
  { value: "CA00", title: "Normalvariation hjärta" },
  { value: "CA001", title: "Fysiologisk blåsljud / fysiologiskt flödesljud" },
  { value: "CA01", title: "Symtom på sjukdom u fastställd orsak, hjärta" },
  { value: "CA011", title: "Blåsljud u fastställd orsak" },
  { value: "CA0111", title: "Patologiskt flödesljud" },
  { value: "CA012", title: "Symtom på hjärtförstoring" },
  { value: "CA013", title: "Symtom på hjärtsvikt (inkl komplikationer)" },
  { value: "CA0131", title: "Symtom på vänstersidig hjärtsvikt" },
  { value: "CA0132", title: "Symtom på högersidig hjärtsvikt" },
  { value: "CA014", title: "EKG förändringar, oklassificerade" },
  {
    value: "CA015",
    title: "Nedsatt myokard kontraktilitet utan fastställd diagnos",
  },
  { value: "CA016", title: "Asynkron kontraktion av septum" },
  { value: "CA017", title: "Mitralisinsufficiens" },
  { value: "CA018", title: "Tricuspidalisinsufficiens" },
  { value: "CA019", title: "Aortainsufficiens" },
  { value: "CA020", title: "Pulmonalisinsufficiens" },
  { value: "CA021", title: "Ekokardiografiska tecken på hjärtförstoring" },
  {
    value: "CA0211",
    title: "Ekokardiografiska tecken på dilatation av vänster förmak",
  },
  {
    value: "CA0212",
    title: "Ekokardiografiska tecken på dilatation av vänster kammare",
  },
  {
    value: "CA0213",
    title: "Ekokardiografiska tecken på dilatation av höger förmak",
  },
  {
    value: "CA0214",
    title: "Ekokardiografiska tecken på dilatation av höger kammare",
  },
  { value: "CA0215", title: "Ekokardiografiska tecken på dilatation av aorta" },
  {
    value: "CA0216",
    title: "Ekokardiografiska tecken på dilatation av a. pulmonalis",
  },
  { value: "CA0217", title: "Paradoxal septumrörelse" },
  { value: "CA1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, hjärta" },
  { value: "CA10", title: "Missbildning hjärta" },
  { value: "CA101", title: "Aortastenos" },
  { value: "CA1011", title: "Supravalvulär aortastenos" },
  { value: "CA1012", title: "Valvulär aortastenos" },
  { value: "CA1013", title: "Subvalvulär aortastenos" },
  { value: "CA10131", title: "Aorta stenos-subvalvulär-diskret" },
  { value: "CA102", title: "Pulmonalisstenos" },
  { value: "CA1021", title: "Supravalvulär pulmonalisstenos" },
  { value: "CA1022", title: "Valvulär pulmonalisstenos" },
  { value: "CA10221", title: "Pulmonalis stenos-valvulär-dysplastisk" },
  { value: "CA10222", title: "Pulmonalis stenos-valvulär-hypoplastisk" },
  { value: "CA1023", title: "Subvalvulär pulmonalisstenos" },
  { value: "CA103", title: "Persisterande ductus arteriosus PDA" },
  { value: "CA1031", title: "Höger-vänster shuntande PDA" },
  { value: "CA104", title: "Septumdefekter" },
  { value: "CA1041", title: "Förmaksseptumdefekt" },
  { value: "CA1042", title: "Kammarseptumdefekt" },
  { value: "CA1043", title: "Atrioventrikulär septumdefekt" },
  { value: "CA105", title: "Klaffdefekter" },
  { value: "CA1051", title: "Mitralis / tricuspidalis- dysplasi" },
  { value: "CA10511", title: "Mitralis dysplasi" },
  { value: "CA10512", title: "Tricuspidalis dysplasi" },
  { value: "CA1052", title: "Tricuspid atresi" },
  { value: "CA1053", title: "Atrioventriculär stenos, kongenital" },
  { value: "CA10531", title: "Mitralis stenos, kongenital" },
  { value: "CA10532", title: "Tricuspidalis stenos, kongenital" },
  { value: "CA106", title: "Fallots tetralogi" },
  { value: "CA107", title: "Kongenital defekt av chordae tendineae" },
  { value: "CA1071", title: "Ektopiska chordae tendineae" },
  { value: "CA109", title: "Hjärtmissbildningar övriga" },
  { value: "CA1091", title: "Högerställd aortabåge" },
  { value: "CA1092", title: "Perikard-peritoneal bråck" },
  { value: "CA1093", title: "Endokardial cushion defekt" },
  { value: "CA1094", title: "Aortic / pulmonary window" },
  { value: "CA1095", title: "Endokardiell fibroelastos, primär" },
  { value: "CA1096", title: "Eisenmengers fysiologi" },
  { value: "CA1097", title: "Coarctatio aortae" },
  { value: "CA11", title: "Utvecklingsrubbn, tillväxtrubbn, hjärta" },
  { value: "CA2", title: "Metabol, nutrit, degen/dystrof förändr, hjärta" },
  { value: "CA20", title: "Metaboliska förändringar, hjärta" },
  { value: "CA21", title: "Nutritionella förändringar, hjärta" },
  { value: "CA211", title: "Taurinbristbetingad dilaterad kardiomyopati" },
  { value: "CA212", title: "Karnitinbristbetingad kardiomyopati" },
  {
    value: "CA213",
    title: "Mulberry heart disease (plötslig hjärtdöd,svin se äv.MA211)",
  },
  { value: "CA22", title: "Degenerativa/dystrofiska förändringar, hjärta" },
  { value: "CA221", title: "Kardiomyopati" },
  { value: "CA2211", title: "Dilaterad kardiomyopati" },
  { value: "CA22111", title: "Biventrikulär dilaterad kardiomyopati" },
  { value: "CA22112", title: "Vänster-sidig dilaterad kardiomyopati" },
  { value: "CA22113", title: "Höger-sidig dilaterad kardiomyopati" },
  { value: "CA2212", title: "Hypertrofisk kardiomyopati" },
  { value: "CA22121", title: "Symmetrisk hypertrofisk kardiomyopati" },
  { value: "CA22122", title: "Asymmetrisk hypertrofisk kardiomyopati" },
  { value: "CA2213", title: "Restriktiv kardiomyopati" },
  { value: "CA2214", title: "Kardiomyopati av boxertyp" },
  {
    value: "CA2215",
    title: "Exentrisk left ventrikulär moderator band kardiomyopati",
  },
  { value: "CA2216", title: "Intermediär kardiomyopati" },
  { value: "CA2217", title: "Arrytmogen kardiomyopati" },
  { value: "CA222", title: "Endokardos" },
  { value: "CA2221", title: "Mitralisendokardos" },
  { value: "CA2222", title: "Tricuspidalisendokardos" },
  { value: "CA2223", title: "Ruptur av cordae tendinae" },
  {
    value: "CA22231",
    title: "Partiell mitralisprolaps sek till ruptur av cordae tendinae",
  },
  {
    value: "CA22232",
    title: "Total mitralisprolaps (flail) sek till ruptur av corda tend",
  },
  { value: "CA2224", title: "Ruptur av vänster förmak" },
  { value: "CA22241", title: "Ruptur av vänster förmak" },
  { value: "CA2225", title: "Aortainsufficiens" },
  { value: "CA2226", title: "Pulmonalisinsufficiens" },
  { value: "CA2227", title: "Prolaps av atrioventriculär klaffarna" },
  { value: "CA22271", title: "Mitralis prolaps" },
  { value: "CA22272", title: "Tricuspidalis prolaps" },
  { value: "CA2228", title: "Atrioventriculärklaffs stenos, förvärvad" },
  { value: "CA22281", title: "Mitralis stenos, förvärvad" },
  { value: "CA22282", title: "Tricuspidalis stenos, förvärvad" },
  { value: "CA23", title: "Hormonella förändringar, hjärta" },
  { value: "CA231", title: "Kardiomyopati sekundärt till hypertyreos" },
  { value: "CA232", title: "Kardiomyopati sekundärt till hypotyreos" },
  { value: "CA3", title: "Cirkulatoriska förändringar, hjärta" },
  { value: "CA30", title: "Blödningar, hjärta" },
  { value: "CA301", title: "Hjärttamponad" },
  { value: "CA32", title: "Infarkter, hjärta" },
  { value: "CA34", title: "Trombos / emboli, hjärta" },
  { value: "CA341", title: "Tromb i förmak" },
  { value: "CA3411", title: "Tromb i vänster förmak" },
  { value: "CA3412", title: "Tromb i höger förmak" },
  { value: "CA342", title: "Ventriculär tromb" },
  { value: "CA3421", title: "Tromb i höger kammare" },
  { value: "CA3422", title: "Tromb i vänster kammare" },
  { value: "CA35", title: "Arterio-atero-skleros, hjärta" },
  { value: "CA352", title: "koronarkärls ateroskleros" },
  {
    value: "CA353",
    title: "Ateroskleros mindre kärl hjärta (small vessel disease)",
  },
  { value: "CA38", title: "Hjärtsvikt" },
  { value: "CA381", title: "Hjärtsvikt -högersidig" },
  { value: "CA382", title: "Hjärtsvikt-vänstersidig" },
  { value: "CA4", title: "Infekt, infl förändringar, hjärta" },
  { value: "CA40", title: "Specifika infektionssjukdomar, hjärta" },
  { value: "CA41", title: "Akuta inflammationstillstånd, hjärta" },
  { value: "CA411", title: "Akut serös / serofibrinös inflammation, hjärta" },
  { value: "CA412", title: "Akut purulent inflammation, hjärta" },
  { value: "CA413", title: "Specifika akuta infl tillstånd, hjärta" },
  { value: "CA419", title: "Övriga akuta infl tillstånd, hjärta" },
  { value: "CA4191", title: "Akut endokardit" },
  { value: "CA41911", title: "Akut valvulär endokardit" },
  { value: "CA4192", title: "Akut myokardit" },
  { value: "CA4193", title: "Akut perikardit" },
  { value: "CA42", title: "Kroniska inflammationstillstånd, hjärta" },
  { value: "CA421", title: "Kronisk serös inflammation, hjärta" },
  { value: "CA422", title: "Purulent inflammation, hjärta" },
  { value: "CA423", title: "Abscess/fistel, hjärta" },
  { value: "CA424", title: "Specifika kroniska infl tillstånd, hjärta" },
  { value: "CA429", title: "Övriga kroniska infl tillstånd, hjärta" },
  { value: "CA4291", title: "Kronisk endokardit" },
  { value: "CA42911", title: "Kronisk valvulär endokardit" },
  { value: "CA4292", title: "Kronisk myokardit" },
  { value: "CA43", title: "Parasitära sjukdomar, hjärta" },
  { value: "CA4321", title: "Dirofilarios (hjärtmask)" },
  { value: "CA4322", title: "Angiostrongylus" },
  { value: "CA5", title: "Immunmedierade tillstånd, hjärta" },
  { value: "CA51", title: "Myokardit sekundärt till SLE" },
  { value: "CA6", title: "Neoplastiska förändringar, hjärta" },
  { value: "CA61", title: "Hjärtbastumör" },
  { value: "CA62", title: "Hemangiosarkom i höger hjärtöra" },
  { value: "CA63", title: "Infiltrativ tumör i myokardiet" },
  { value: "CA64", title: "Hjärtlymfom" },
  { value: "CA65", title: "Hydropericardium orsakat av mesenteliom" },
  { value: "CA7", title: "Trauma, fr kr, lägeförändr, term skad, hjärta" },
  { value: "CA71", title: "Traumatiska / mekaniska skador, hjärta" },
  { value: "CA72", title: "Främmande kroppar, hjärta" },
  { value: "CA721", title: "Traumatisk pericardit" },
  { value: "CA73", title: "Lägesförändringar, hjärta" },
  { value: "CA731", title: "Dextro kardi" },
  { value: "CA74", title: "Termiska skador, hjärta" },
  { value: "CA8", title: "Toxiska förändringar, hjärta" },
  { value: "CA9", title: "Idiopatiska, ospecif, multifakt, hjärta" },
  { value: "CA91", title: "Extraslag" },
  { value: "CA911", title: "Supraventrikulära extraslag" },
  { value: "CA9111", title: "Förmaksextraslag" },
  { value: "CA91111", title: "Förmaksextraslag" },
  { value: "CA91112", title: "Nodala extraslag" },
  { value: "CA9112", title: "Nodala extraslag" },
  { value: "CA91121", title: "Kammarextraslag -uniforma" },
  { value: "CA91122", title: "Kammarextraslag -uniforma-höger kammare" },
  { value: "CA91123", title: "Kammarextraslag -uniforma-vänster kammare" },
  { value: "CA91124", title: "Kammarextraslag - multiforma" },
  { value: "CA91125", title: "Kammarextraslag - multiforma -höger kammare" },
  { value: "CA91126", title: "Kammarextraslag - multiforma -vänster kammare" },
  { value: "CA91127", title: "Kammarextraslag -biventriculära" },
  { value: "CA912", title: "Kammarextraslag" },
  { value: "CA9121", title: "Supraventrikulär takykardi" },
  { value: "CA91211", title: "Förmaksflimmer" },
  { value: "CA91212", title: "Sinustakykardi" },
  { value: "CA91213", title: "Förmakstakykardi" },
  { value: "CA91214", title: "Nodal takykardi" },
  { value: "CA91215", title: "Wolf-Parkinson-White syndrom" },
  { value: "CA91216", title: "Lown-Ganong-Levine syndromet" },
  { value: "CA91217", title: "Preexitation" },
  { value: "CA9122", title: "Kammartakykardi" },
  { value: "CA91221", title: "Torsades de pointes - liknande kammartakykardi" },
  { value: "CA9123", title: "Kammarflimmer" },
  { value: "CA913", title: "Bradykardi" },
  { value: "CA9131", title: "Sinusbradykardi" },
  { value: "CA9132", title: "Förmaksstillestånd" },
  { value: "CA9133", title: "Vagal bradykardi" },
  { value: "CA9134", title: "Sinusarrest/sinusblock" },
  { value: "CA9135", title: "Hyperkalemisk bradykardi/förmaksstillestånd" },
  { value: "CA914", title: "Retledningsrubbning/ar" },
  { value: "CA9141", title: "AV-block" },
  { value: "CA91411", title: "AV-block I" },
  { value: "CA91412", title: "AV-block II" },
  { value: "CA91413", title: "AV-block II, Mobitz Type I (A)" },
  { value: "CA91414", title: "AV-block II, Mobitz Type II" },
  { value: "CA91415", title: "AV-block III" },
  { value: "CA9143", title: "Retledningsrubbning övriga" },
  { value: "CA91431", title: "Sick sinus syndrom" },
  { value: "CA91433", title: "Skänkelblock" },
  { value: "CA91434", title: "Flyktrytm, nodal" },
  { value: "CA91435", title: "Flyktrytm, ventrikulär" },
  { value: "CA91436", title: "Flyktslag, nodala" },
  { value: "CA91437", title: "Flyktslag, ventrikulära" },
  { value: "CA91438", title: "Vänster skänkelblock  (LBBB)" },
  { value: "CA91439", title: "Höger skänkelblock  (RBBB)" },
  { value: "CA9144", title: "Intraventrikulärt block" },
  { value: "CA91440", title: "Vänster främre fascikel block  (LAFB)" },
  { value: "CA91441", title: "Vänster bakre fascikel block  (LPFB)" },
  { value: "CA915", title: "Övriga arrytmier" },
  { value: "CA9151", title: "Atrioventrikulär dissociation" },
  { value: "CA9152", title: "Parasystolier" },
  { value: "CA91521", title: "Förmaksparasystoli" },
  { value: "CA91522", title: "Kammarparasystoli" },
  { value: "CA9153", title: "Mobil pacemaker" },
  { value: "CA9154", title: "Sinusarrytmi" },
  { value: "CA92", title: "Takykardi" },
  { value: "CA921", title: "Supraventrikulär takykardi" },
  { value: "CA9211", title: "Förmaksflimmer" },
  { value: "CA9212", title: "Sinustakykardi" },
  { value: "CA9213", title: "Förmakstakykardi" },
  { value: "CA9214", title: "Nodal takycardi" },
  { value: "CA9215", title: "Wolf-Parkinson-White syndrom" },
  { value: "CA9216", title: "Lown-Ganong-Levine syndromet" },
  { value: "CA922", title: "Kammartakykardi" },
  { value: "CA923", title: "Kammarflimmer" },
  { value: "CA93", title: "Bradykardi" },
  { value: "CA931", title: "Sinusbradykardi" },
  { value: "CA932", title: "Förmaksstillestånd" },
  { value: "CA933", title: "Vagal bradykardi" },
  { value: "CA934", title: "Sinusarrest / sinusblock" },
  { value: "CA935", title: "Hyperkalemisk bradykardi / förmaksstillestånd" },
  { value: "CA94", title: "Retledningsrubbningar" },
  { value: "CA941", title: "AV-block" },
  { value: "CA9411", title: "AV-block I" },
  { value: "CA9412", title: "AV-block II" },
  { value: "CA9413", title: "AV-block III" },
  { value: "CA943", title: "Retledningsrubbning övriga" },
  { value: "CA9431", title: "Sick sinus syndrom" },
  { value: "CA9432", title: "Preexitation" },
  { value: "CA9433", title: "Skänkelblock" },
  { value: "CA94331", title: "Vänster skänkelblock  (LBBB)" },
  { value: "CA94332", title: "Höger skänkelblock  (RBBB)" },
  { value: "CA94333", title: "Vänster främre fascikel block  (LAFB)" },
  { value: "CA94334", title: "Vänster bakre fascikel block  (LPFB)" },
  { value: "CA944", title: "Intraventrikulärt block" },
  { value: "CA95", title: "Övriga arrytmier" },
  { value: "CA951", title: "Atrioventrikulär dissociation" },
  { value: "CA952", title: "Parasystolier" },
  { value: "CA9521", title: "Förmaksparasystoli" },
  { value: "CA9522", title: "Kammarparasystoli" },
  { value: "CA96", title: "Hypertoni" },
  { value: "CA97", title: "Hydroperikard" },
  { value: "CA971", title: "Benign idiopatisk hydroperikard" },
  { value: "CA99", title: "Övriga EKG störningar" },
  { value: "CA991", title: "Avikande elektrisk axel" },
  { value: "CA992", title: "Myocardaffektion" },
  { value: "CB", title: "Blod, blodbildande organ" },
  {
    value: "CB0",
    title: "Normalvariat, symtom u fastställd orsak, blod/blodbild organ",
  },
  { value: "CB00", title: "Normalvariation, blod, blodbildand organ" },
  {
    value: "CB01",
    title: "Symtom på sjd u fastställd orsak, blod, blodbildand organ",
  },
  { value: "CB011", title: "Leukocytos" },
  { value: "CB0111", title: "Lymfocytos" },
  { value: "CB0112", title: "Neutrofili" },
  { value: "CB012", title: "Leukopeni" },
  { value: "CB0121", title: "Lymfopeni" },
  { value: "CB0122", title: "Neutropeni" },
  { value: "CB013", title: "Eosinofili" },
  { value: "CB014", title: "Agranulocytos (ej medfödd)" },
  { value: "CB019", title: "Ospecifika förändringar i vita blodbilden" },
  { value: "CB02", title: "Symtom på mjältförstoring" },
  {
    value: "CB1",
    title: "Missbildn, utvecklrubbn, tillväxtrubbn, blod/blodbild organ",
  },
  { value: "CB10", title: "Missbildning, blod, blodbildand organ" },
  { value: "CB101", title: "Koagulationsrubbning" },
  { value: "CB1011", title: "Hemofili A (faktor VIII brist)" },
  { value: "CB1012", title: "Hemofili B (faktor IX brist)" },
  { value: "CB1013", title: "v Willebrands sjukdom" },
  { value: "CB102", title: "Granulocytopati (medfödd)" },
  {
    value: "CB11",
    title: "Utvecklingsrubbn, tillväxtrubbn, blod, blodbildand organ",
  },
  {
    value: "CB2",
    title: "Metabol, nutrit, degen/dystrof förändr, blod/blodbild organ",
  },
  { value: "CB20", title: "Metaboliska förändringar, blod, blodbildand organ" },
  {
    value: "CB21",
    title: "Nutritionella förändringar, blod, blodbildand organ",
  },
  {
    value: "CB22",
    title: "Degen/dystrof förändringar, blod, blodbildand organ",
  },
  { value: "CB23", title: "Hormonella förändringar, blod, blodbildand organ" },
  {
    value: "CB3",
    title: "Cirkulatoriska förändringar, blod, blodbildand organ",
  },
  { value: "CB30", title: "Blödningar, blod, blodbildand organ" },
  { value: "CB31", title: "Ödem, blod, blodbildand organ" },
  { value: "CB32", title: "Infarkter, blod, blodbildand organ" },
  { value: "CB34", title: "Trombos / emboli, blod, blodbildand organ" },
  {
    value: "CB35",
    title: "Arterio-atero-skleros förändringar, blod, blodbildand organ",
  },
  {
    value: "CB4",
    title: "Infektioner, infl förändringar, blod, blodbildand organ",
  },
  {
    value: "CB40",
    title: "Specifika infektionssjukdomar, blod, blodbildand organ",
  },
  { value: "CB401", title: "FeLV infektion i benmärg" },
  {
    value: "CB41",
    title: "Akuta inflammationstillstånd, blod, blodbildand organ",
  },
  {
    value: "CB411",
    title: "Akut serös / serofibrinös infl, blod/blodbildand organ",
  },
  {
    value: "CB412",
    title: "Akut purulent inflammation, blod, blodbildand organ",
  },
  {
    value: "CB413",
    title: "Specifika akuta infl tillstånd, blod, blodbildand organ",
  },
  {
    value: "CB419",
    title: "Övriga akuta infl tillstånd, blod, blodbildand organ",
  },
  {
    value: "CB42",
    title: "Kroniska inflammationstillstånd, blod, blodbildand organ",
  },
  {
    value: "CB421",
    title: "Kronisk serös inflammation, blod, blodbildand organ",
  },
  { value: "CB422", title: "Purulent inflammation, blod, blodbildand organ" },
  { value: "CB423", title: "Abscess/fistel, blod, blodbildand organ" },
  {
    value: "CB424",
    title: "Specifika kroniska infl tillstånd, blod, blodbildand organ",
  },
  {
    value: "CB429",
    title: "Övriga kroniska infl tillstånd, blod, blodbildand organ",
  },
  { value: "CB43", title: "Parasitära sjukdomar, blod, blodbildand organ" },
  { value: "CB5", title: "Immunmedierade tillstånd, blod, blodbildand organ" },
  { value: "CB51", title: "Neonatal isoerytrolys" },
  { value: "CB52", title: "Autoimmun hemolytisk anemi" },
  { value: "CB53", title: "Autoimmun trombocytopeni" },
  { value: "CB6", title: "Neoplastiska förändringar, blod, blodbildand organ" },
  { value: "CB61", title: "Malignt lymfom" },
  { value: "CB62", title: "Plasmacellsmyelom" },
  { value: "CB63", title: "Mastocytom" },
  { value: "CB64", title: "Mjältlymfom" },
  { value: "CB66", title: "Hemangiom" },
  { value: "CB67", title: "Hemangiosarkom" },
  {
    value: "CB7",
    title: "Trauma fr kr/lägeförändr/term skad, blod/blodbild organ",
  },
  {
    value: "CB71",
    title: "Traumatiska / mekaniska skador, blod, blodbildand organ",
  },
  { value: "CB711", title: "Mjältruptur" },
  { value: "CB72", title: "Främmande kroppar, blod, blodbildand organ" },
  { value: "CB73", title: "Lägesförändringar, blod, blodbildand organ" },
  { value: "CB731", title: "Mjältomvridning" },
  { value: "CB74", title: "Termiska skador, blod, blodbildand organ" },
  { value: "CB8", title: "Toxiska förändringar, blod, blodbildand organ" },
  {
    value: "CB9",
    title: "Idiopatiska, ospecific, multifaktor, blod, blodbild organ",
  },
  { value: "CB91", title: "Anemier" },
  { value: "CB911", title: "Regenerativ anemi" },
  { value: "CB9111", title: "Hemolytisk anemi" },
  { value: "CB9112", title: "Blödningsanemi" },
  { value: "CB912", title: "Nonregenerativ anemi" },
  { value: "CB9121", title: "Erytropoetinbristanemi (njursvikt)" },
  { value: "CB9122", title: "B12 bristanemi" },
  { value: "CB9123", title: "Folsyrebristanemi" },
  { value: "CB9124", title: "Järnbristanemi" },
  { value: "CB9125", title: "Toxisk anemi" },
  { value: "CB913", title: "Övriga anemier" },
  { value: "CB9131", title: "Anemi pga neoplasi" },
  { value: "CB9132", title: "Anemi pga kronisk infektion" },
  { value: "CB92", title: "Sjukdomar i vita blodkroppar" },
  { value: "CB93", title: "Koagulationsstörning" },
  { value: "CB931", title: "Trombocytopeni" },
  { value: "CB9311", title: "Trombocytopeni av okänd orsak" },
  { value: "CB932", title: "DIC" },
  { value: "CC", title: "Blodkärl, lymfkärl, lymfknutor" },
  {
    value: "CC0",
    title: "Normalvar, symtom u fastställd orsak, blkärl/lymfkärl/lymfkn",
  },
  { value: "CC00", title: "Normalvariation, blodkärl, lymfkärl, lymfkn." },
  {
    value: "CC01",
    title: "Symtom på sjd u fastställd orsak, blodkärl, lymfkärl, lymfkn",
  },
  { value: "CC011", title: "Lymfknuteförstoring" },
  { value: "CC012", title: "Mjältförstoring" },
  { value: "CC013", title: "Thymusförstoring" },
  {
    value: "CC1",
    title: "Missbild/utveckl. rubbn/tillväxtrubbn, blod-lymfkärl/lymfkn.",
  },
  { value: "CC10", title: "Missbildning, blodkärl, lymfkärl, lymfkn." },
  { value: "CC101", title: "Aneurysm" },
  { value: "CC102", title: "Teleangiektasi" },
  { value: "CC103", title: "Kongenitalt primärt lymfödem" },
  { value: "CC104", title: "Coarctatio aortae" },
  {
    value: "CC11",
    title: "Utvecklingsrubbn, tillväxtrubbn, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC2",
    title: "Metabol, nutrit, degen/dystrof förändr, blod-lymfkärl/lymfkn",
  },
  {
    value: "CC20",
    title: "Metaboliska förändringar, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC21",
    title: "Nutritionella förändringar, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC22",
    title: "Degen/dystrof förändringar, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC23",
    title: "Hormonella förändringar, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC3",
    title: "Cirkulatoriska förändringar, blodkärl, lymfkärl, lymfkn.",
  },
  { value: "CC30", title: "Blödningar, blodkärl, lymfkärl, lymfkn." },
  { value: "CC31", title: "Ödem, blodkärl, lymfkärl, lymfkn." },
  { value: "CC32", title: "Infarkter, blodkärl, lymfkärl, lymfkn." },
  { value: "CC34", title: "Trombos / emboli, blodkärl, lymfkärl, lymfkn." },
  { value: "CC341", title: "Aortatrombos, blodkärl, lymfkärl, lymfkn." },
  { value: "CC342", title: "Artärtrombos, blodkärl, lymfkärl, lymfkn." },
  { value: "CC343", title: "Embolisk- trombotisk- intermittent hälta" },
  { value: "CC344", title: "Antitrombin III- brist" },
  {
    value: "CC35",
    title: "Arterio-atero-skleros föränd, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC4",
    title: "Infektioner, infl förändringar, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC40",
    title: "Specifika infektionssjukdomar, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC41",
    title: "Akuta inflammationstillstånd, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC411",
    title: "Akut serös / serofibrinös infl, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC412",
    title: "Akut purulent inflammation, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC413",
    title: "Specifika akuta infl tillstånd, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC419",
    title: "Övriga akuta infl tillstånd, blodkärl, lymfkärl, lymfkn.",
  },
  { value: "CC4191", title: "Akut arterit" },
  { value: "CC4192", title: "Akut tromboflebit" },
  { value: "CC41921", title: "Periflebit" },
  { value: "CC4193", title: "Akut lymfangit" },
  { value: "CC4194", title: "Akut lymfadenit" },
  {
    value: "CC42",
    title: "Kroniska inflammationstillstånd, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC421",
    title: "Kronisk serös inflammation, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC422",
    title: "Purulent inflammation, blodkärl, lymfkärl, lymfkn.",
  },
  { value: "CC423", title: "Abscess/fistel, blodkärl, lymfkärl, lymfkn." },
  { value: "CC4231", title: "Kronisk abscederande lymfadenit" },
  {
    value: "CC424",
    title: "Specifika kron infl tillstånd, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC429",
    title: "Övriga kroniska infl tillstånd, blodkärl, lymfkärl, lymfkn.",
  },
  { value: "CC4291", title: "Kronisk arterit" },
  { value: "CC4292", title: "Kronisk tromboflebit" },
  { value: "CC4293", title: "Kronisk lymfangit" },
  { value: "CC4294", title: "Kronisk lymfadenit" },
  { value: "CC43", title: "Parasitära sjukdomar, blodkärl, lymfkärl, lymfkn." },
  { value: "CC431", title: "Kröskärlskada sekundärt till Strongylus" },
  {
    value: "CC5",
    title: "Immunmedierade tillstånd, blodkärl, lymfkärl, lymfkn.",
  },
  {
    value: "CC6",
    title: "Neoplastiska förändringar, blodkärl, lymfkärl, lymfkn.",
  },
  { value: "CC61", title: "Lymfosarkom i lymfknuta" },
  { value: "CC62", title: "Tumör i mjälte" },
  { value: "CC66", title: "Hemangiom" },
  { value: "CC67", title: "Hemangiosarkom" },
  { value: "CC68", title: "Lymfom i mjälte" },
  {
    value: "CC7",
    title: "Trauma fr kr/lägeförändr/term skad, blod-lymfkärl/ lymfkn.",
  },
  {
    value: "CC71",
    title: "Traumatiska / mekaniska skador, blodkärl, lymfkärl, lymfkn.",
  },
  { value: "CC711", title: "Sårskada, blodkärl, lymfoida organ inkl mjälte" },
  { value: "CC7111", title: "Sårskada, blodkärl" },
  { value: "CC7112", title: "Sårskada, lymfkärl" },
  { value: "CC7113", title: "Sårskada, lymfknuta" },
  { value: "CC7114", title: "Sårskada, mjälte" },
  { value: "CC7115", title: "Sårskada, thymus" },
  { value: "CC712", title: "Mjältruptur" },
  { value: "CC713", title: "Mjältomvridning" },
  { value: "CC72", title: "Främmande kroppar, blodkärl, lymfkärl, lymfkn." },
  { value: "CC721", title: "Främmande kropp, blodkärl" },
  { value: "CC722", title: "Främmande kropp, lymfkärl" },
  { value: "CC723", title: "Främmande kropp, lymfknuta" },
  { value: "CC724", title: "Främmande kropp, mjälte" },
  { value: "CC725", title: "Främmande kropp, thymus" },
  { value: "CC73", title: "Lägesförändringar, blodkärl, lymfkärl, lymfkn." },
  { value: "CC74", title: "Termiska skador, blodkärl, lymfkärl, lymfkn." },
  { value: "CC741", title: "Brännskada, blodkärl, lymfkärl, lymfkn." },
  { value: "CC742", title: "Köldskada, blodkärl, lymfkärl, lymfkn." },
  { value: "CC8", title: "Toxiska förändringar, blodkärl, lymfkärl, lymfkn." },
  {
    value: "CC9",
    title: "Idiopatiska, ospecif, multifakt, blodkärl, lymfkärl, lymfkn.",
  },
  { value: "CC91", title: "Aortaruptur" },
  { value: "CC96", title: "Hypertoni" },
  { value: "CC961", title: "Primär systemisk hypertoni" },
  { value: "CC962", title: "Sekundär hypertoni" },
  { value: "CC9621", title: "Hypertoni sekundär till njursjukdom" },
  { value: "CC9622", title: "Hypertoni sekundär till Cushing's Disease" },
  { value: "CC9623", title: "Hypertoni sekundär till Hypertyroidism" },
  { value: "CC9624", title: "Hypertoni sekundär till Pheochromocytom" },
  { value: "CC9625", title: "Hypertoni sekundär till CNS-sjukdom" },
  { value: "CC97", title: "Pulmonell hypertension" },
  { value: "CC98", title: "Hypotension" },
  { value: "CD", title: "Perifert blod och benmärg" },
  { value: "CD1", title: "Förändringar i alla blodcell-linjer" },
  { value: "CD11", title: "Pancytopeni" },
  {
    value: "CD111",
    title: "Pancytopeni orsakad av infektion (Parvovirus, FeLV, mfl)",
  },
  { value: "CD112", title: "Pancytopeni orsakad av läkemedel eller toxin" },
  { value: "CD113", title: "Pancytopeni orsakad av sertolicellstumör" },
  { value: "CD114", title: "Aplastisk anemi" },
  { value: "CD115", title: "Pancytopeni orsakad av leukemi" },
  { value: "CD116", title: "Pancytopeni av annan orsak" },
  { value: "CD2", title: "Förändringar i den vita blodbilden" },
  { value: "CD21", title: "Neutrofili" },
  { value: "CD211", title: "Neutrofili orsakad av inflammation/infektion" },
  {
    value: "CD212",
    title: "Neutrofili ors av glukokort (endogent kortisol, kortisonbeh)",
  },
  {
    value: "CD213",
    title: "Neutrofili orsakad av adrenalin (ansträngning/oro)",
  },
  { value: "CD214", title: "Neutrofil leukemoid reaktion" },
  { value: "CD215", title: "Neutrofili av annan orsak" },
  { value: "CD216", title: "Akut myeloid leukemi" },
  { value: "CD217", title: "Akut myelomonocytär leukemi" },
  { value: "CD218", title: "Kronisk myeloid leukemi" },
  { value: "CD22", title: "Neutropeni" },
  { value: "CD221", title: "Neutropeni orsakad av ökade förluster" },
  {
    value: "CD2211",
    title: "Neutropeni orsakad av omfattande bakteriell infektion",
  },
  { value: "CD2212", title: "Neutropeni immunmedierad" },
  { value: "CD222", title: "Neutropeni orsakad av minskad nybildning" },
  { value: "CD2221", title: "Benmärgsskada orsakad av infektion" },
  { value: "CD2222", title: "Benmärgsskada av annan orsak" },
  {
    value: "CD2223",
    title: "Medfödd cyklisk hematopoes (Grey Collie syndrome)",
  },
  { value: "CD223", title: "Neutropeni av annan orsak" },
  { value: "CD23", title: "Annan förändring hos neutrofilerna" },
  {
    value: "CD231",
    title: "Pelger-Huet anomali (heridit granul hyposegmentering)",
  },
  {
    value: "CD232",
    title: "Chediak-Higashi syndrom (heredit inklus i neutrofilerna)",
  },
  {
    value: "CD233",
    title: "Anaplasmos (A phagocytophilum, fd gran ehrlichios)",
  },
  { value: "CD234", title: "Sepsis" },
  { value: "CD24", title: "Eosinofili" },
  { value: "CD241", title: "Eosinofili orsakad av inflammation/infektion" },
  { value: "CD242", title: "Eosinofili orsakad av parasitinfektion" },
  { value: "CD243", title: "Esoinofili orsakad av hypersensitivitetsreaktion" },
  { value: "CD244", title: "Eosinofili orsakad av eosinofil pneumoni" },
  { value: "CD245", title: "Hypereosinofilt syndrom" },
  { value: "CD246", title: "Eosinofil leukemoid reaktion" },
  { value: "CD247", title: "Eosinofili av annan orsak" },
  { value: "CD248", title: "Eosinofil leukemi" },
  { value: "CD25", title: "Basofili" },
  { value: "CD251", title: "Basofili orsakad av hypersensitivitetsreaktion" },
  { value: "CD252", title: "Basofili av annan orsak" },
  { value: "CD26", title: "Lymfocytos" },
  { value: "CD261", title: "Lymfocytos orsakad av ansträngning/oro" },
  {
    value: "CD262",
    title: "Lymfocytos pga immunstimulering (inflammation/infektion)",
  },
  { value: "CD263", title: "Lymfocytos av annan orsak" },
  { value: "CD264", title: "Lymfocytär leukemi" },
  { value: "CD27", title: "Lymfopeni" },
  {
    value: "CD271",
    title: "Lymfopeni orsav glukokort (endog kortisol, kortison-beh)",
  },
  { value: "CD272", title: "Lymfopeni orsakad av infektion" },
  { value: "CD273", title: "Lymfopeni av annan orsakj" },
  { value: "CD274", title: "Lymfocytär leukemi" },
  { value: "CD28", title: "Monocytos" },
  {
    value: "CD281",
    title: "Moncytos ors av kron inflammation eller vävnadskada",
  },
  {
    value: "CD282",
    title: "Monocytos pga glukokort (endog kortisol, kortison-beh)",
  },
  { value: "CD30", title: "Leukopeni" },
  { value: "CD301", title: "Aplastisk anemi" },
  { value: "CD31", title: "Mastcytemi" },
  { value: "CD311", title: "Mastcytemi av okänd orsak" },
  { value: "CD312", title: "Mastcellsleukemi" },
  { value: "CD32", title: "Leukemi och myeloiska dysplasier" },
  { value: "CD321", title: "Akut myeloid leukemi" },
  { value: "CD322", title: "Akut myelomonocytär leukemi" },
  { value: "CD323", title: "Kronisk myeloid eller myelomonocytär leukemi" },
  { value: "CD324", title: "Eosinofil leukemi" },
  { value: "CD325", title: "Akut lymfocytär leukemi" },
  { value: "CD326", title: "Kronisk lymfocytär leukemi" },
  { value: "CD327", title: "Myeloid dysplastiskt syndrom (MDS)" },
  { value: "CD3271", title: "MDS orsakad av FeLV-infektion" },
  { value: "CD328", title: "Annan leukemi" },
  { value: "CD329", title: "Plasmacellsmyelom" },
  { value: "CD4", title: "Förändringar i den röda blodbilden" },
  { value: "CD41", title: "Blodbrist/anemi" },
  { value: "CD411", title: "Regenerativ anemi" },
  { value: "CD4111", title: "Akut blodförlustanemi" },
  { value: "CD4112", title: "Hemolytisk anemi" },
  { value: "CD41121", title: "Immunmedierad hemolytisk anemi" },
  {
    value: "CD41122",
    title: "Hemolyt anemi pga oxid skad(inkl Heinzkrp,methemogl bildn)",
  },
  { value: "CD41123", title: "Mycoplasma haemofelis (Haemobartonella felis)" },
  { value: "CD41124", title: "Mycoplasma haemocanis (Haemobartonella canis)" },
  { value: "CD41125", title: "Babesia gibsoni" },
  { value: "CD41126", title: "Babesia canis" },
  { value: "CD41127", title: "Andra blodparasiter i erytrocyterna" },
  { value: "CD41128", title: "Medfödd pyruvatkinasbrist" },
  { value: "CD41129", title: "Medfödd fosfofruktokinasbrist" },
  { value: "CD41130", title: "Neonatal isoerytrolys" },
  { value: "CD41131", title: "Andra medfödda hemolytiska anemier" },
  { value: "CD41132", title: "Hemolytisk anemi av annan orsak" },
  {
    value: "CD412",
    title: "Icke regenerativ samt otillräckligt regenerativ anemi",
  },
  { value: "CD4121", title: "Anemi sekundärt till inflammation" },
  { value: "CD4122", title: "Erytropoetinbrist vid njursvikt" },
  { value: "CD4123", title: "Järnbristanemi" },
  { value: "CD41231", title: "Järnbristanemi orsakad av bristande järnintag" },
  { value: "CD41232", title: "Järnbristanemi orsakad av kroniska blödningar" },
  {
    value: "CD4124",
    title: "Benmärgsskada pga läkemedel/toxiner, immunmed benmärgssk",
  },
  {
    value: "CD41241",
    title: "Östrogenorsakad benmärgsskada (Sertolicellstumör)",
  },
  { value: "CD41242", title: "Trimetoprimsulfa-orsakad benmärgsskada" },
  { value: "CD41243", title: "Immunmedierad benmärgsskada" },
  { value: "CD4125", title: "Benmärgsskada orsakad av infektioner" },
  { value: "CD41251", title: "Benmärgsskada pga Felint leukemivirus" },
  { value: "CD41252", title: "Benmärgsskada pga Ehrlichia canis" },
  { value: "CD41253", title: "Benmärgsskada pga Leishmania" },
  { value: "CD4126", title: "Benmärgsskada orsakad av neoplasi" },
  { value: "CD4127", title: "Aplastisk anemi" },
  { value: "CD4128", title: "Pure red blood cell aplasia" },
  { value: "CD4129", title: "Dyserytropoes" },
  { value: "CD4130", title: "Icke regenerativ anemi av annan orsak" },
  { value: "CD42", title: "Polycytemi" },
  { value: "CD421", title: "Polycytemi relativ (dehydrering)" },
  { value: "CD422", title: "Polycytemi primär (Polycytemia vera)" },
  { value: "CD423", title: "Polycytemi sekundär" },
  {
    value: "CD4231",
    title: "Sek polycytemi ors av nedsatt syresättn (hjärt/lungsjukdom)",
  },
  {
    value: "CD4232",
    title: "Sek polycytemi pga njurförändr (carcinom, pyelonefrit)",
  },
  { value: "CD5", title: "Förändringar i trombocyterna" },
  { value: "CD51", title: "Trombocytos" },
  { value: "CD511", title: "Trombocytos reaktiv" },
  { value: "CD5111", title: "Trombocytos, efter blödning eller trauma" },
  { value: "CD5112", title: "Trombocytos, i samband med  infektion" },
  { value: "CD5113", title: "Trombocytos, i samband med järnbrist" },
  { value: "CD5114", title: "Trombocytos, i samband med diabetes mellitus" },
  { value: "CD512", title: "Primär trombocytemi (myeloproliferativ sjukdom)" },
  { value: "CD52", title: "Trombocytopeni" },
  { value: "CD521", title: "Trombocytopeni orsakad av ökad omsättning" },
  { value: "CD5211", title: "Immunmedierad trombocytopeni" },
  { value: "CD5212", title: "Trombocytopeni i samband med infektion" },
  { value: "CD5213", title: "DIC-orsakad trombocytopeni" },
  { value: "CD522", title: "Trombocytopeni orsakad av minskad produktion" },
  { value: "CD5221", title: "Immunmedierad trombocytopeni" },
  { value: "CD5222", title: "Läkemedelsorsakad trombocytopeni" },
  { value: "CD523", title: "Idiopatisk trombocytopeni" },
  { value: "CD53", title: "Trombocytopati" },
  { value: "CD531", title: "Glanzmanns trombocytopati" },
  { value: "CD541", title: "von Willebrands sjukdom" },
  {
    value: "CD542",
    title: "Annan heredit trombocytopati (Ched-Hig,Ehlers-Danlos,mfl)",
  },
  {
    value: "CD543",
    title: "Trombocyopati i samb m sjukdom (uremi, leverskada, m.fl.)",
  },
  {
    value: "CD544",
    title: "Trombocytopati-läkemed.ors(acetylsalicyls,NSAID,östrog. mfl)",
  },
  { value: "CD545", title: "Immunmedierad trombocytopati" },
  { value: "CD6", title: "Koagulationsdefekter" },
  { value: "CD61", title: "Otillräcklig hemostas" },
  { value: "CD611", title: "Dikumarolförgiftning" },
  { value: "CD612", title: "Hemofili A (F VIII brist)" },
  { value: "CD613", title: "Hemofili B (F IX brist)" },
  { value: "CD614", title: "Annan specifik faktorbrist" },
  { value: "CD615", title: "Ökad fibrinolys" },
  { value: "CD62", title: "Hyperkoagulation" },
  { value: "CD621", title: "DIC (disseminerad intravasal koagulation)" },
  { value: "CD622", title: "Endotelskada som orsak till trombosbildning" },
  { value: "CD623", title: "Lungemboli" },
  { value: "CD624", title: "Idiopatisk trombosbildning" },
  { value: "CD7", title: "Förändringar i immunoglobulinstatus" },
  { value: "CD71", title: "Hypoimmunoglobulinemi" },
  { value: "CE", title: "Hjärtsäck" },
  { value: "CE0", title: "Normalvar, symtom u fastställd orsak, hjärtsäck" },
  { value: "CE00", title: "Normalvariation, hjärtsäck" },
  { value: "CE01", title: "Symtom på sjukdom u fastställd orsak, hjärtsäck" },
  {
    value: "CE011",
    title: "Symtom på endokrin sjd u fastställd orsak, hjärtsäck",
  },
  { value: "CE1", title: "Missbildn, utveckl rubbn, tillväxtrubbn, hjärtsäck" },
  { value: "CE10", title: "Missbildning, hjärtsäck" },
  { value: "CE101", title: "Kongenital defekt pericardium" },
  { value: "CE102", title: "Pericardiell cysta" },
  { value: "CE103", title: "Avsaknad av pericardium" },
  { value: "CE104", title: "Perikard-peritoneal bråck" },
  { value: "CE11", title: "Utvecklingsrubbn, tillväxtrubbn, hjärtsäck" },
  { value: "CE2", title: "Metabol, nutrit, degen/dystrof förändr, hjärtsäck" },
  { value: "CE20", title: "Metaboliska förändringar, hjärtsäck" },
  { value: "CE21", title: "Nutritionella förändringar, hjärtsäck" },
  { value: "CE22", title: "Degenerativa/dystrofiska förändringar, hjärtsäck" },
  { value: "CE23", title: "Hormonella förändringar, hjärtsäck" },
  { value: "CE3", title: "Cirkulatoriska förändringar, hjärtsäck" },
  { value: "CE30", title: "Blödningar, hjärtsäck" },
  { value: "CE31", title: "Ödem, hjärtsäck" },
  { value: "CE32", title: "Infarkter, hjärtsäck" },
  { value: "CE34", title: "Trombos/emboli, hjärtsäck" },
  { value: "CE35", title: "Arterio-atero-skleros förändringar, hjärtsäck" },
  { value: "CE4", title: "Infekt, infl förändringar, hjärtsäck" },
  { value: "CE40", title: "Specifika infektionssjukdomar, hjärtsäck" },
  { value: "CE41", title: "Akuta inflammationstillstånd, hjärtsäck" },
  { value: "CE411", title: "Akut perikardit" },
  { value: "CE42", title: "Kroniska inflammationstillstånd, hjärtsäck" },
  { value: "CE421", title: "Kronisk perikardit" },
  { value: "CE4211", title: "Kronisk konstriktiv perikardit" },
  { value: "CE43", title: "Parasitära sjukdomar, hjärtsäck" },
  { value: "CE5", title: "Immunmedierade tillstånd, hjärtsäck" },
  { value: "CE6", title: "Tumör i hjärtsäck" },
  { value: "CE61", title: "Mesenteliom i hjärtsäck" },
  { value: "CE7", title: "Trauma, fr kr, lägeförändr, term skad, hjärtsäck" },
  { value: "CE71", title: "Traumatiska/mekaniska skador, hjärtsäck" },
  { value: "CE72", title: "Främmande kroppar, hjärtsäck" },
  { value: "CE721", title: "Traumatisk pericardit" },
  { value: "CE73", title: "Lägesförändringar, hjärtsäck" },
  { value: "CE8", title: "Toxiska förändringar, hjärtsäck" },
  { value: "CE9", title: "Idiopatiska, ospecif, multifakt, hjärtsäck" },
  { value: "CE91", title: "Hydroperikard" },
  { value: "CE911", title: "Benign idiopatisk hydroperikard" },
  { value: "CE912", title: "Hydroperikard sekundärt till inflammation" },
  { value: "CE913", title: "Hydroperikard sekundärt till neoplasi" },
  {
    value: "D",
    title: "Digestionsorg, bukhåla, bukvägg, bäckenhåla perinealreg sjd",
  },
  { value: "DA", title: "Munhåla, tänder, spottkörtlar, matstrupe" },
  {
    value: "DA0",
    title: "Normalvar, symtom u fastställd orsak, munhåla/svalg",
  },
  { value: "DA00", title: "Normalvariation, munhåla/svalg" },
  {
    value: "DA01",
    title: "Symtom på sjukdom u fastställd orsak, munhåla tänder mm",
  },
  { value: "DA011", title: "Tuggningsvårigheter" },
  { value: "DA012", title: "Oförmåga att öppna / stänga munnen" },
  { value: "DA0121", title: "Oförmåga att öppna munnen" },
  { value: "DA0122", title: "Oförmåga att stänga munnen" },
  { value: "DA013", title: "Spottkörtelförstoring" },
  { value: "DA014", title: "Salivationsrubbning" },
  { value: "DA0141", title: "Ökad salivation" },
  { value: "DA0142", title: "Minskad salivation" },
  {
    value: "DA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, munhåla/svalg",
  },
  { value: "DA10", title: "Missbildning, munhåla/svalg" },
  { value: "DA101", title: "Gomspalt" },
  { value: "DA102", title: "Mikrognati (abnormt liten käke)" },
  { value: "DA103", title: "Megaesofagus" },
  { value: "DA1031", title: "Megaesofagus pga högerställd aorta" },
  { value: "DA1032", title: "Idiopatisk, medfödd megaesofagus" },
  { value: "DA104", title: "Esofagusdivertikel" },
  { value: "DA105", title: "Felplacerat tandanlag (öronfistel)" },
  { value: "DA106", title: "Permanent tand saknas" },
  { value: "DA107", title: "Tandcysta" },
  { value: "DA108", title: "Övertaliga tänder" },
  { value: "DA11", title: "Utvecklingsrubbn, tillväxtrubbn, munhåla/svalg" },
  { value: "DA111", title: "Persisterande mjölktänder" },
  { value: "DA112", title: "Bettfel" },
  { value: "DA1121", title: "Överbett" },
  { value: "DA1122", title: "Underbett" },
  { value: "DA1123", title: "Saxbett" },
  { value: "DA1124", title: "Glest bett" },
  { value: "DA1125", title: "Glatt bett (senilt)" },
  { value: "DA1126", title: "Tandanomalii smågnagare" },
  { value: "DA1128", title: "Förvuxen näbb" },
  { value: "DA1129", title: "Övriga bettfel" },
  { value: "DA113", title: "Emaljdefekter" },
  { value: "DA1131", title: "Medfödd emaljdefekt" },
  { value: "DA1132", title: "Läkemedelsinducerad emaljdefekt" },
  { value: "DA1133", title: "Missfärgning av tänder UNS" },
  { value: "DA114", title: "Spottkörtelstörning" },
  { value: "DA1141", title: "Spottkörtelscysta" },
  {
    value: "DA2",
    title: "Metabol, nutrit, degen/dystrof förändr, munhåla/svalg",
  },
  { value: "DA20", title: "Metaboliska förändringar, munhåla/svalg" },
  { value: "DA201", title: "Tandsten" },
  { value: "DA21", title: "Nutritionella förändringar, munhåla/svalg" },
  {
    value: "DA22",
    title: "Degenerativa/dystrofiska förändringar, munhåla/svalg",
  },
  { value: "DA23", title: "Hormonella förändringar, munhåla/svalg" },
  { value: "DA3", title: "Cirkulatoriska förändringar, munhåla/svalg" },
  { value: "DA30", title: "Blödningar, munhåla/svalg" },
  { value: "DA301", title: "Blödning i munhåla" },
  { value: "DA302", title: "Blödning i svalg" },
  { value: "DA31", title: "Ödem, munhåla/svalg" },
  { value: "DA32", title: "Infarkter, munhåla/svalg" },
  { value: "DA33", title: "Hygrom, munhåla/svalg" },
  { value: "DA34", title: "Trombos / emboli, munhåla/svalg" },
  { value: "DA35", title: "Arterio-atero-skleros förändringar, munhåla/svalg" },
  { value: "DA4", title: "Infekt, infl förändringar, munhåla/svalg" },
  { value: "DA40", title: "Specifika infektionssjukdomar, munhåla/svalg" },
  { value: "DA401", title: "Vincents stomatit (spiroketal stomatit)" },
  { value: "DA41", title: "Akuta inflammationstillstånd, munhåla/svalg" },
  {
    value: "DA411",
    title: "Akut serös / serofibrinös inflammation, munhåla/svalg",
  },
  { value: "DA412", title: "Akut purulent inflammation, munhåla/svalg" },
  { value: "DA4121", title: "Alveolarpyorré" },
  { value: "DA413", title: "Specifika akuta infl tillstånd, munhåla/svalg" },
  { value: "DA419", title: "Övriga akuta infl tillstånd, munhåla/svalg" },
  { value: "DA4191", title: "Akut gingivit" },
  { value: "DA4192", title: "Akut glossit" },
  { value: "DA4193", title: "Akut stomatit" },
  { value: "DA4194", title: "Akut parotit" },
  { value: "DA41941", title: "Akut parotit (spottkörtelinflammation)" },
  { value: "DA4195", title: "Akut spottkörtelinflammation" },
  { value: "DA4196", title: "Akut esofagit" },
  { value: "DA42", title: "Kroniska inflammationstillstånd, munhåla/svalg" },
  { value: "DA421", title: "Kronisk serös inflammation, munhåla/svalg" },
  { value: "DA422", title: "Purulent inflammation, munhåla/svalg" },
  { value: "DA423", title: "Abscess/fistel, munhåla/svalg" },
  { value: "DA4231", title: "Rotspetsabscess" },
  { value: "DA4232", title: "Parotisabscess" },
  { value: "DA424", title: "Specifika kroniska infl tillstånd, munhåla/svalg" },
  { value: "DA4241", title: "Eosinofila ulcera i munhåla" },
  { value: "DA429", title: "Övriga kroniska infl tillstånd, munhåla/svalg" },
  { value: "DA4291", title: "Kronisk gingivit" },
  { value: "DA4292", title: "Kronisk glossit" },
  { value: "DA4293", title: "Kronisk stomatit" },
  { value: "DA42931", title: "Kronisk ulcerös stomatit" },
  { value: "DA4294", title: "Kronisk parotit" },
  { value: "DA4295", title: "Kronisk spottkörtelinflammation" },
  { value: "DA4296", title: "Kronisk esofagit" },
  { value: "DA43", title: "Parasitära sjukdomar, munhåla/svalg" },
  { value: "DA5", title: "Immunmedierade tillstånd, munhåla/svalg" },
  { value: "DA51", title: "Eosinofila ulcera i munhåla" },
  { value: "DA52", title: "Eosinofilt plaque på läppen" },
  { value: "DA6", title: "Neoplastiska förändringar, munhåla/svalg" },
  { value: "DA61", title: "Benign tumör i munhåla/svalg" },
  { value: "DA611", title: "Epulider" },
  { value: "DA6111", title: "Epulider utan ben/brosk" },
  { value: "DA6112", title: "Epulid fibromatös/ossifierande" },
  { value: "DA6113", title: "Epulid överväxt av tandrad" },
  { value: "DA612", title: "Amyloblastiskt odontom" },
  { value: "DA62", title: "Malign tumör i munhåla/svalg" },
  { value: "DA621", title: "Skivepitelskarcinom, munhåla/svalg" },
  { value: "DA622", title: "Malignt melanom, munhåla/svalg" },
  { value: "DA6221", title: "Malignt melanom, munhåla" },
  { value: "DA623", title: "Fibrosarkom, munhåla/svalg" },
  { value: "DA624", title: "Malign tumör, munhåla" },
  { value: "DA69", title: "Övriga tumörer i munhåla / svalg" },
  {
    value: "DA7",
    title: "Idiopatiska, ospecificerade, multifaktoriella, munhåla/svalg",
  },
  { value: "DA71", title: "Traumatiska / mekaniska skador, munhåla/svalg" },
  { value: "DA711", title: "Tandfraktur" },
  { value: "DA7111", title: "Tanddislokation" },
  { value: "DA7112", title: "Vargtand som interfererar med betsling" },
  { value: "DA7113", title: "Utslagning av tand" },
  { value: "DA712", title: "Skarpbett" },
  { value: "DA7121", title: "Skarpbett (med munsår)" },
  { value: "DA713", title: "Hakbett" },
  { value: "DA714", title: "Tandnötningsanomali" },
  { value: "DA7141", title: "Vågbett" },
  { value: "DA715", title: "Sårskada munhåla / svalg" },
  { value: "DA7151", title: "Sårskada munhåla" },
  { value: "DA7152", title: "Sårskada läppar" },
  { value: "DA7153", title: "Sårskada tunga" },
  { value: "DA7154", title: "Sårskada i svalg" },
  { value: "DA7155", title: "Munsår" },
  { value: "DA7156", title: "Traumatisk skada näbb" },
  { value: "DA716", title: "Traumatisk gomspalt" },
  { value: "DA717", title: "Esofagusstriktur" },
  { value: "DA718", title: "Esofagusruptur" },
  { value: "DA719", title: "Betsel/bett-relaterade skador" },
  { value: "DA72", title: "Främmande kroppar, munhåla/svalg" },
  { value: "DA721", title: "Främmande kropp i munhåla" },
  { value: "DA722", title: "Främmande kropp i tunga" },
  { value: "DA723", title: "Främmande kropp i svalg" },
  { value: "DA7231", title: "Grässtrå i svalg" },
  { value: "DA724", title: "Främmande kropp i spottkörtel / spottkörtelgång" },
  { value: "DA725", title: "Främmande kropp i esofagus" },
  {
    value: "DA7251",
    title: "Akut sekundär tympanism (trumsjuka pga obstruktion alt.vagus",
  },
  { value: "DA727", title: "Foderinpackning" },
  { value: "DA728", title: "Esofagusförstoppning" },
  { value: "DA73", title: "Lägesförändringar, munhåla/svalg" },
  { value: "DA74", title: "Termiska skador, munhåla/svalg" },
  { value: "DA741", title: "Brännskada, munhåla/svalg" },
  { value: "DA742", title: "Köldskada, munhåla/svalg" },
  { value: "DA8", title: "Toxiska förändringar, munhåla/svalg" },
  { value: "DA81", title: "Frätskada, munhåla/svalg" },
  { value: "DA811", title: "Frätskada i munhåla" },
  { value: "DA812", title: "Frätskada i esofagus" },
  { value: "DA9", title: "Idiopatiska, ospecif, multifakt, munhåla/svalg" },
  { value: "DA91", title: "Rubbningar i sväljfunktionen" },
  { value: "DA911", title: "Kricofaryngeal akalasi" },
  { value: "DA94", title: "Karies" },
  { value: "DA941", title: "FORL/Neck lesions (katt)" },
  { value: "DA95", title: "Parodontit" },
  { value: "DA96", title: "Förvärvad esofagusdilatation" },
  { value: "DA961", title: "Idiopatisk esofagusdilatation/megaesofagus" },
  { value: "DA962", title: "Förvärvad esofagusstriktur" },
  { value: "DA963", title: "Förvärvad divertikel i esofagus" },
  { value: "DA97", title: "Tandproblem" },
  { value: "DB", title: "Magsäck, tarm, analområde" },
  { value: "DB0", title: "Normalvar, symtom u fastställd orsak, magsäck tarm" },
  { value: "DB00", title: "Normalvariation, magsäck tarm" },
  {
    value: "DB01",
    title: "Symtom på sjukdom u fastställd orsak, magsäck tarm",
  },
  { value: "DB011", title: "Kräkning" },
  { value: "DB0111", title: "Blodig kräkning" },
  { value: "DB0112", title: "Gallkräkning" },
  { value: "DB0113", title: "Morgonkräkning/kräkning på tom mage" },
  { value: "DB012", title: "Symtom på magsmärtor" },
  { value: "DB013", title: "Diarré" },
  { value: "DB0131", title: "Blodig diarré" },
  { value: "DB01311", title: "Blodtillblandad diarré" },
  { value: "DB0132", title: "Enterohemorrhagiskt syndrom" },
  { value: "DB0133", title: "Epizootisk virusdiarré -EVD" },
  { value: "DB0134", title: "Porcine epidemisk diarre - PED" },
  { value: "DB0135", title: "Dysenteriliknande diarré" },
  { value: "DB014", title: "Kräkning och diarré" },
  { value: "DB0141", title: "Blodig kräkning och diarré" },
  { value: "DB01411", title: "Blodtillblandad kräkning och diarré" },
  { value: "DB01412", title: "Slemtillblandad avföring/slemmig avföring" },
  { value: "DB01413", title: "Borborygmi/tarmkurr" },
  { value: "DB015", title: "Abnormt luktande avföring" },
  { value: "DB016", title: "Tenesmus" },
  { value: "DB017", title: "Symtom på förstoppning" },
  { value: "DB018", title: "Kolik" },
  { value: "DB0181", title: "Kolik u fastställd orsak" },
  { value: "DB0182", title: "Gaskolik" },
  { value: "DB0183", title: "Krampkolik" },
  { value: "DB019", title: "Fekal inkontinens" },
  {
    value: "DB1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, magsäck tarm",
  },
  { value: "DB10", title: "Missbildning, magsäck tarm" },
  { value: "DB101", title: "Atresia ani/recti" },
  { value: "DB102", title: "Ileo-colon agangnlionos (letalt vitt föl)" },
  { value: "DB11", title: "Utvecklingsrubbn, tillväxtrubbn, magsäck tarm" },
  { value: "DB111", title: "Pylorusstenos" },
  {
    value: "DB2",
    title: "Metabol, nutrit, degen/dystrof förändr, magsäck tarm",
  },
  { value: "DB20", title: "Metaboliska förändringar, magsäck tarm" },
  { value: "DB201", title: "Uremisk gastrit" },
  { value: "DB2011", title: "Uremiska kräkningar" },
  { value: "DB202", title: "Fölbrunstdiarré / nio dagars diarré" },
  { value: "DB203", title: "Utfodringsbetingad diarré" },
  {
    value: "DB204",
    title: "Kronisk sekundär tympanism (trumsjuka pga ruminit mellankalv",
  },
  { value: "DB21", title: "Nutritionella förändringar, magsäck tarm" },
  { value: "DB211", title: "Våmacidos" },
  { value: "DB212", title: "Akut primär tympanism (trumsjuka, bete)" },
  { value: "DB2121", title: "Akut primär tympanism (trumsjuka, bete)" },
  { value: "DB2122", title: "Kronisk primär typmanism (trumsjuka, stall)" },
  { value: "DB213", title: "Kronisk primär typmanism (trumsjuka, stall)" },
  {
    value: "DB22",
    title: "Degenerativa/dystrofiska förändringar, magsäck tarm",
  },
  { value: "DB23", title: "Hormonella förändringar, magsäck tarm" },
  { value: "DB3", title: "Cirkulatoriska förändringar, magsäck tarm" },
  { value: "DB30", title: "Blödningar, magsäck tarm" },
  { value: "DB301", title: "Blödning från magsäck" },
  { value: "DB302", title: "Blödning till tarm" },
  { value: "DB3021", title: "Blödning till tunntarm" },
  { value: "DB3022", title: "Blödning till grovtarm" },
  { value: "DB31", title: "Ödem, magsäck tarm" },
  { value: "DB311", title: "Ödem i tarmvägg" },
  { value: "DB32", title: "Infarkter, magsäck tarm" },
  { value: "DB321", title: "Ischemi i tarmavsnitt" },
  { value: "DB34", title: "Trombos / emboli, magsäck tarm" },
  { value: "DB341", title: "Trombotisk-embolisk kolik" },
  { value: "DB35", title: "Arterio-atero-skleros förändringar, magsäck tarm" },
  { value: "DB4", title: "Infekt, infl förändringar, magsäck tarm" },
  { value: "DB40", title: "Specifika infektionssjukdomar, magsäck tarm" },
  { value: "DB401", title: "Spädgrisdiarré" },
  { value: "DB41", title: "Akuta inflammationstillstånd, magsäck tarm" },
  {
    value: "DB411",
    title: "Akut serös / serofibrinös inflammation, magsäck tarm",
  },
  { value: "DB412", title: "Akut purulent inflammation, magsäck tarm" },
  { value: "DB413", title: "Specifika akuta infl tillstånd, magsäck tarm" },
  { value: "DB419", title: "Övriga akuta infl tillstånd, magsäck tarm" },
  { value: "DB4191", title: "Akut gastrit" },
  { value: "DB4192", title: "Akut enterit" },
  { value: "DB41921", title: "Akut främre enterit" },
  { value: "DB4193", title: "Akut gastroenterit" },
  { value: "DB4194", title: "Akut kolit / tyflit" },
  { value: "DB41941", title: "Akut kolit" },
  { value: "DB41942", title: "Akut tyflit" },
  { value: "DB4195", title: "Akut proktit" },
  { value: "DB4196", title: "Akut läkemedelsinducerat enterit / kolit" },
  { value: "DB4197", title: "Akut analsäcksinflammation" },
  { value: "DB41971", title: "Akut fistulerande analsäcksinflammation" },
  { value: "DB41972", title: "Akut purulent analsäcksinflammation" },
  { value: "DB41973", title: "Analsäcksinflammation, hemorrhagisk" },
  { value: "DB41974", title: "Analsäcksirritation" },
  { value: "DB42", title: "Kroniska inflammationstillstånd, magsäck tarm" },
  { value: "DB421", title: "Kronisk serös inflammation, magsäck tarm" },
  { value: "DB422", title: "Purulent inflammation, magsäck tarm" },
  { value: "DB423", title: "Abscess/fistel, magsäck tarm" },
  { value: "DB4231", title: "Tarmväggs- / krös abscess" },
  { value: "DB4232", title: "Perirektal / perianal abscess" },
  { value: "DB424", title: "Specifika kroniska infl tillstånd, magsäck tarm" },
  {
    value: "DB4241",
    title: "Kronisk eosinofil gastrit /enterit /gastroenterit",
  },
  { value: "DB42411", title: "Kronisk gastrit med ulcus" },
  { value: "DB42412", title: "Kronisk eosinofil gastrit" },
  { value: "DB42413", title: "Kronisk atrofisk gastrit" },
  { value: "DB42414", title: "Kronisk hypertrofisk gastrit" },
  { value: "DB42415", title: "Kronisk erosiv gastrit" },
  { value: "DB42416", title: "Kronisk follikulär gastrit" },
  { value: "DB4242", title: "Kronisk lymfocytär enterit" },
  { value: "DB4243", title: "Kronisk lymfocytär-plasmacytär enterit" },
  { value: "DB4244", title: "Kronisk granulomatös enterit" },
  { value: "DB4245", title: "Kronisk eosinofil kolit" },
  { value: "DB4246", title: "Kronisk lymfocytär-plasmacytär kolit" },
  { value: "DB4247", title: "Kronisk granulomatös kolit" },
  { value: "DB4251", title: "Kronisk eosinofil kolit" },
  { value: "DB4252", title: "Kronisk lymfocytär-plasmacytär kolit" },
  { value: "DB4253", title: "Kronisk granulomatös kolit" },
  { value: "DB4254", title: "Kronisk histiocytär kolit" },
  { value: "DB4255", title: "Kronisk ulcerös kolit" },
  { value: "DB4256", title: "Kronisk erosiv kolit" },
  { value: "DB429", title: "Övriga kroniska infl tillstånd, magsäck tarm" },
  { value: "DB4290", title: "Ruminit" },
  { value: "DB4291", title: "Kronisk gastrit" },
  { value: "DB42911", title: "Kronisk atrofisk gastrit" },
  { value: "DB42912", title: "Kronisk hypertrofisk gastrit" },
  { value: "DB42913", title: "Kronisk erosiv gastrit (inklusive ulkus)" },
  { value: "DB42914", title: "Kronisk follikulär gastrit" },
  { value: "DB4292", title: "Kronisk enterit" },
  { value: "DB4293", title: "Kronisk kolit /tyflit" },
  { value: "DB42931", title: "Kronisk ulcerös / erosiv kolit" },
  { value: "DB42932", title: "Colon irritabele" },
  { value: "DB4294", title: "Kronisk proktit" },
  { value: "DB4295", title: "Kronisk analsäcksinflammation" },
  { value: "DB42951", title: "Kronisk purulent analsäcksinflammation" },
  { value: "DB42952", title: "Kronisk fistulerande analsäcksinflammation" },
  { value: "DB43", title: "Parasitära sjukdomar, magsäck tarm" },
  { value: "DB5", title: "Immunmedierade tillstånd, magsäck tarm" },
  { value: "DB6", title: "Neoplastiska förändringar, magsäck tarm" },
  { value: "DB61", title: "Tumör i magsäck" },
  { value: "DB611", title: "Polyp i magsäck" },
  { value: "DB612", title: "Malign tumör i magsäck" },
  { value: "DB62", title: "Tumör i tunntarm" },
  { value: "DB63", title: "Tumör i tarmkrös" },
  { value: "DB64", title: "Tumör i cekum / kolon" },
  { value: "DB641", title: "Tumör i cekum" },
  { value: "DB642", title: "Tumör i kolon" },
  { value: "DB65", title: "Analadenom / analkarcinom" },
  { value: "DB651", title: "Analadenom" },
  { value: "DB652", title: "Analkarcinom" },
  { value: "DB66", title: "Tumör i analsäck" },
  { value: "DB661", title: "Karcinom i analsäck" },
  { value: "DB67", title: "Tumör i rektum" },
  { value: "DB671", title: "Tumör perirektalt" },
  { value: "DB672", title: "Polyp i rektum" },
  { value: "DB673", title: "Karcinom i rektum" },
  {
    value: "DB7",
    title: "Trauma, fr kr, lägeförändr, term skad, magsäck tarm",
  },
  { value: "DB71", title: "Traumatiska / mekaniska skador, magsäck tarm" },
  { value: "DB711", title: "Magsäcksdilatation efter foderförätning" },
  { value: "DB712", title: "Koprostas" },
  { value: "DB7121", title: "Koprostas i tunntarm" },
  { value: "DB7122", title: "Koprostas i cekum" },
  { value: "DB7123", title: "Koprostas i kolon" },
  { value: "DB71231", title: "Förstoppning orsakad av ben" },
  { value: "DB7124", title: "Koprostas i lilla kolon" },
  { value: "DB7125", title: "Koprostas rektum" },
  { value: "DB7126", title: "Mekoniumförstoppning" },
  { value: "DB7127", title: "Magsäcksinpackning" },
  { value: "DB713", title: "Kardiastenos" },
  { value: "DB714", title: "Ruptur av magsäck" },
  { value: "DB715", title: "Ruptur av tarm" },
  { value: "DB716", title: "Tarmstrikturer" },
  { value: "DB7161", title: "Rektumstriktur" },
  { value: "DB7162", title: "Tunntarmsstriktur" },
  { value: "DB7163", title: "Ileumstriktur" },
  { value: "DB717", title: "Krösruptur" },
  { value: "DB718", title: "Rektumskador" },
  { value: "DB7181", title: "Perforation efter rektalisering" },
  { value: "DB7182", title: "Perforation efter rektalisering" },
  { value: "DB719", title: "Rektovaginalfistel / kloak" },
  { value: "DB72", title: "Främmande kroppar, magsäck tarm" },
  { value: "DB721", title: "Främmande kropp i magsäck" },
  { value: "DB722", title: "Främmande kropp i tunntarm" },
  { value: "DB7221", title: "Främmande kropp tarm, ben" },
  { value: "DB7222", title: "Främmande kropp tarm, majskolv" },
  { value: "DB7223", title: "Främmande kropp tarm, boll/leksak" },
  { value: "DB7224", title: "Främmande kropp tarm, tyg" },
  { value: "DB7225", title: "Främmande kropp tarm, nål" },
  { value: "DB7226", title: "Främmande kropp tarm, tråd/snöre" },
  { value: "DB723", title: "Främmande kropp i kolon / rektum" },
  { value: "DB724", title: "Sandinpackning" },
  { value: "DB725", title: "Främmande kropp i analområdet" },
  { value: "DB726", title: "Traumatisk retikuloperitonit (vasst)" },
  { value: "DB728", title: "Löpmagsinpackning" },
  { value: "DB729", title: 'Vagusindigestion (vagusskada vid "vasst")' },
  { value: "DB73", title: "Lägesförändringar, magsäck tarm" },
  { value: "DB731", title: "Lägeförändring av magsäck" },
  { value: "DB7311", title: "Magsäcksdilatation" },
  { value: "DB7312", title: "Magsäcksomvridning" },
  { value: "DB7313", title: "Framfall av magsäck i esofagus" },
  { value: "DB7314", title: "Vänstersidig löpmagsdislokation" },
  { value: "DB7315", title: "Högersidig löpmagsdislokation" },
  { value: "DB7316", title: "Löpmagsomvridning" },
  { value: "DB732", title: "Tarmlägesförändring" },
  { value: "DB7321", title: "Omvridning av tunntarm" },
  { value: "DB7322", title: "Omvridning av grovtarm" },
  { value: "DB73221", title: "Flexion av grovtarmen" },
  { value: "DB73222", title: "Upphängning av grovtarm på mjält-njurbandet" },
  { value: "DB733", title: "Invagination" },
  { value: "DB7331", title: "Invagination av tunntarm" },
  { value: "DB73311", title: "Invagination av ileum i kolon" },
  { value: "DB7332", title: "Invagination av cekum" },
  { value: "DB7333", title: "Invagination av kolon" },
  { value: "DB734", title: "Inkarceration av tarm" },
  { value: "DB7341", title: "Inkarceration i foramen epiploicum" },
  { value: "DB7342", title: "Inkarceration av tarm i pungbråck" },
  { value: "DB735", title: "Tarmframfall" },
  { value: "DB7351", title: "Tarmframfall efter kastration" },
  { value: "DB7352", title: "Rektumprolaps" },
  { value: "DB736", title: "Striktur av tarm pga stjälkat lipom" },
  {
    value: "DB737",
    title: "Inkarc av tarm i patologiskt öppning (krösdefekt etc)",
  },
  { value: "DB738", title: "Inckarceration av tarm pga brid" },
  { value: "DB739", title: "Inkarceration i inre bråck, övriga" },
  { value: "DB74", title: "Termiska skador, magsäck tarm" },
  { value: "DB75", title: "Adherenser mellan tarm/magsäck/övriga dig.organ" },
  { value: "DB8", title: "Toxiska förändringar, magsäck tarm" },
  { value: "DB9", title: "Idiopatiska, ospecif, multifakt, magsäck tarm" },
  { value: "DB90", title: "Onormala resonansljud från buken" },
  { value: "DB901", title: "Plingljud vänster sida" },
  { value: "DB902", title: "Plingljud höger sida" },
  { value: "DB91", title: "Refluxgastrit" },
  { value: "DB92", title: "Ulcus ventriculi, magsår / löpmagssår" },
  { value: "DB921", title: "Blödande magsår" },
  { value: "DB922", title: "Ulcus i tunntarm" },
  { value: "DB93", title: "Malabsorption" },
  {
    value: "DB931",
    title: "Neonatal steatorré  (Rotav./Coccidier/stress sk.3-v diarré)",
  },
  { value: "DB932", title: "Avvänjningsdiarré svin" },
  { value: "DB933", title: "Diarre- tillväxtgris" },
  { value: "DB94", title: "Protein-loosing enteropati" },
  { value: "DB941", title: "Lymfangiektasi" },
  { value: "DB95", title: "Tarmflorerubbning" },
  { value: "DB96", title: "Krävdilatation (hängkräva)" },
  { value: "DB97", title: "Funktionell ileus" },
  { value: "DB98", title: "Ändtarmsstenos" },
  { value: "DB99", title: "Våmstörning" },
  { value: "DC", title: "Bukvägg, bäckenhåla" },
  {
    value: "DC0",
    title: "Normalvar, symtom u fastställd orsak, bukvägg, bäckenhåla",
  },
  { value: "DC00", title: "Normalvariation, bukvägg, bäckenhåla" },
  {
    value: "DC01",
    title: "Symtom på sjukdom u fastställd orsak, bukvägg, bäckenhåla",
  },
  { value: "DC011", title: "Ascites" },
  { value: "DC012", title: "Smärta från buken" },
  {
    value: "DC1",
    title: "Missbildn/utveckl.rubbn/tillväxtrubbn, bukvägg, bäckenhåla",
  },
  { value: "DC10", title: "Missbildning, bukvägg, bäckenhåla" },
  { value: "DC101", title: "Navelbråck" },
  { value: "DC102", title: "Missbildningar i bukvägg olika bråck" },
  {
    value: "DC11",
    title: "Utvecklingsrubbn, tillväxtrubbn, bukvägg, bäckenhåla",
  },
  { value: "DC111", title: "Kongenitalt inguinalbråck" },
  { value: "DC112", title: "Kongenitalt pungbråck" },
  { value: "DC113", title: "Kongenitalt bukbråck" },
  { value: "DC114", title: "Kongenitalt diafragmabråck" },
  { value: "DC115", title: "Kongenitalt hiatusbråck" },
  {
    value: "DC2",
    title: "Metabol, nutrit, degen/dystrof förändr, bukvägg, bäckenhåla",
  },
  { value: "DC20", title: "Metaboliska förändringar, bukvägg, bäckenhåla" },
  { value: "DC21", title: "Nutritionella förändringar, bukvägg, bäckenhåla" },
  { value: "DC211", title: "Steatit" },
  {
    value: "DC22",
    title: "Degenerativa/dystrofiska förändringar, bukvägg, bäckenhåla",
  },
  { value: "DC23", title: "Hormonella förändringar, bukvägg, bäckenhåla" },
  { value: "DC3", title: "Cirkulatoriska förändringar, bukvägg, bäckenhåla" },
  { value: "DC30", title: "Blödningar, bukvägg, bäckenhåla" },
  { value: "DC301", title: "Blödning till bukhåla" },
  { value: "DC302", title: "Retroperiteonal blödning" },
  { value: "DC31", title: "Ödem, bukvägg, bäckenhåla" },
  { value: "DC32", title: "Infarkter, bukvägg, bäckenhåla" },
  { value: "DC33", title: "Hygrom, bukvägg, bäckenhåla" },
  { value: "DC34", title: "Trombos / emboli, bukvägg, bäckenhåla" },
  {
    value: "DC35",
    title: "Arterio-atero-skleros förändringar, bukvägg, bäckenhåla",
  },
  { value: "DC4", title: "Infekt, infl förändringar, bukvägg, bäckenhåla" },
  {
    value: "DC40",
    title: "Specifika infektionssjukdomar, bukvägg, bäckenhåla",
  },
  { value: "DC41", title: "Akuta inflammationstillstånd, bukvägg, bäckenhåla" },
  {
    value: "DC411",
    title: "Akut serös / serofibrinös inflammation, bukvägg, bäckenhåla",
  },
  { value: "DC412", title: "Akut purulent inflammation, bukvägg, bäckenhåla" },
  { value: "DC4121", title: "Akut purulent peritonit" },
  {
    value: "DC413",
    title: "Specifika akuta infl tillstånd, bukvägg, bäckenhåla",
  },
  { value: "DC419", title: "Övriga akuta infl tillstånd, bukvägg, bäckenhåla" },
  { value: "DC4191", title: "Navelinfektion" },
  {
    value: "DC42",
    title: "Kroniska inflammationstillstånd, bukvägg, bäckenhåla",
  },
  { value: "DC421", title: "Kronisk serös inflammation, bukvägg, bäckenhåla" },
  { value: "DC422", title: "Purulent inflammation, bukvägg, bäckenhåla" },
  { value: "DC423", title: "Abscess/fistel, bukvägg, bäckenhåla" },
  { value: "DC4231", title: "Bukabscess" },
  { value: "DC4232", title: "Navelabscess" },
  {
    value: "DC424",
    title: "Specifika kroniska infl tillstånd, bukvägg, bäckenhåla",
  },
  {
    value: "DC429",
    title: "Övriga kroniska infl tillstånd, bukvägg, bäckenhåla",
  },
  { value: "DC4291", title: "Kronisk navelinflammation" },
  { value: "DC43", title: "Parasitära sjukdomar, bukvägg, bäckenhåla" },
  { value: "DC5", title: "Immunmedierade tillstånd, bukvägg, bäckenhåla" },
  { value: "DC6", title: "Neoplastiska förändringar, bukvägg, bäckenhåla" },
  { value: "DC61", title: "Tumör i peritoneum" },
  { value: "DC62", title: "Tumör i bukhålan" },
  { value: "DC63", title: "Tumör i bäckenhålan" },
  { value: "DC64", title: "Tumör i tarmkrös" },
  {
    value: "DC7",
    title: "Trauma, fr kr, lägeförändr, term skad, bukvägg, bäckenhåla",
  },
  {
    value: "DC71",
    title: "Traumatiska / mekaniska skador, bukvägg, bäckenhåla",
  },
  { value: "DC711", title: "Traumatiskt bråck" },
  { value: "DC7111", title: "Traumatiskt diafragmabråck" },
  { value: "DC7112", title: "Traumatiskt hiatusbråck" },
  { value: "DC7113", title: "Traumatiskt bukbråck" },
  { value: "DC7114", title: "Traumatiskt bråck i muskelfascia" },
  { value: "DC7115", title: "Traumatisk reektovaginalfistel" },
  { value: "DC7119", title: "Övriga traumatiska bråck" },
  { value: "DC72", title: "Främmande kroppar, bukvägg, bäckenhåla" },
  { value: "DC721", title: "Främmande kropp genom bukvägg (stakningsskada)" },
  { value: "DC73", title: "Lägesförändringar, bukvägg, bäckenhåla" },
  { value: "DC74", title: "Termiska skador, bukvägg, bäckenhåla" },
  { value: "DC741", title: "Brännskada, bukvägg, bäckenhåla" },
  { value: "DC742", title: "Köldskada, bukvägg, bäckenhåla" },
  { value: "DC8", title: "Toxiska förändringar, bukvägg, bäckenhåla" },
  {
    value: "DC9",
    title: "Idiopatiska, ospecif, multifakt, bukvägg, bäckenhåla",
  },
  { value: "DC91", title: "Peritoneala adherenser (brider)" },
  { value: "DC92", title: "Perinealbråck" },
  { value: "DC93", title: "Fettvävsnekros" },
  { value: "DC94", title: "Inguinalbråck, ljumskbråck, ej medfött" },
  { value: "DC95", title: "Pungbråck, utan känd etiologi (ej medfött)" },
  { value: "DD", title: "Lever" },
  { value: "DD0", title: "Normalvar, symtom u fastställd orsak, lever" },
  { value: "DD00", title: "Normalvariation, lever" },
  { value: "DD01", title: "Symtom på sjukdom u fastställd orsak, lever" },
  { value: "DD011", title: "Gulsot / Ikterus" },
  { value: "DD012", title: "Förhöjda transaminasvärden" },
  { value: "DD1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, lever" },
  { value: "DD10", title: "Missbildning, lever" },
  {
    value: "DD101",
    title: "Porta-cava-shunt eller annan kärlmissbildning i lever",
  },
  { value: "DD11", title: "Utvecklingsrubbn, tillväxtrubbn, lever" },
  { value: "DD2", title: "Metabol, nutrit, degen/dystrof förändr, lever" },
  { value: "DD20", title: "Metaboliska förändringar, lever" },
  { value: "DD201", title: "Kopparhepatit" },
  { value: "DD202", title: "Gallsten" },
  { value: "DD203", title: "Leverförfettning" },
  { value: "DD21", title: "Nutritionella förändringar, lever" },
  {
    value: "DD211",
    title: "Hepatosis dietetika (leverdystrofi,svin se äv.AA21331)",
  },
  { value: "DD22", title: "Degenerativa/dystrofiska förändringar, lever" },
  { value: "DD221", title: "Levercellsdegeneration" },
  { value: "DD222", title: "Levercirrhos" },
  { value: "DD23", title: "Hormonella förändringar, lever" },
  { value: "DD3", title: "Cirkulatoriska förändringar, lever" },
  { value: "DD30", title: "Blödningar, lever" },
  { value: "DD31", title: "Ödem, lever" },
  { value: "DD311", title: "Akut staslever" },
  { value: "DD312", title: "Kronisk staslever" },
  { value: "DD32", title: "Infarkter, lever" },
  { value: "DD34", title: "Trombos / emboli, lever" },
  { value: "DD35", title: "Arterio-atero-skleros förändringar, lever" },
  { value: "DD4", title: "Infekt, infl förändringar, lever" },
  { value: "DD40", title: "Specifika infektionssjukdomar, lever" },
  { value: "DD41", title: "Akuta inflammationstillstånd, lever" },
  { value: "DD411", title: "Akut serös / serofibrinös inflammation, lever" },
  { value: "DD412", title: "Akut purulent inflammation, lever" },
  { value: "DD413", title: "Specifika akuta infl tillstånd, lever" },
  { value: "DD419", title: "Övriga akuta infl tillstånd, lever" },
  { value: "DD4191", title: "Akut hepatit" },
  { value: "DD4192", title: "Akut kolangit / kolangiohepatit" },
  { value: "DD42", title: "Kroniska inflammationstillstånd, lever" },
  { value: "DD421", title: "Kronisk serös inflammation, lever" },
  { value: "DD422", title: "Purulent inflammation, lever" },
  { value: "DD423", title: "Abscess/fistel, lever" },
  { value: "DD4231", title: "Leverabscess, lever" },
  { value: "DD424", title: "Specifika kroniska infl tillstånd, lever" },
  { value: "DD429", title: "Övriga kroniska infl tillstånd, lever" },
  { value: "DD4291", title: "Kronisk hepatit" },
  { value: "DD42911", title: "Kronisk aktiv hepatit" },
  { value: "DD4292", title: "Kronisk kolangit / kolangiohepatit" },
  { value: "DD43", title: "Parasitära sjukdomar, lever" },
  { value: "DD5", title: "Immunmedierade tillstånd, lever" },
  { value: "DD6", title: "Neoplastiska förändringar, lever" },
  { value: "DD61", title: "Tumör i lever" },
  { value: "DD611", title: "Lymfosarkom i lever" },
  { value: "DD612", title: "Hemangiosarkom" },
  { value: "DD62", title: "Tumörer i gallgång" },
  { value: "DD621", title: "Gallgångskarcinom" },
  { value: "DD7", title: "Trauma, fr kr, lägeförändr, term skad, lever" },
  { value: "DD71", title: "Traumatiska / mekaniska skador, lever" },
  { value: "DD711", title: "Traumatisk leverruptur" },
  { value: "DD72", title: "Främmande kroppar, lever" },
  { value: "DD73", title: "Lägesförändringar, lever" },
  { value: "DD8", title: "Toxiska förändringar, lever" },
  { value: "DD81", title: "Läkemedelsinducerad levercellskada" },
  { value: "DD82", title: "Förhöjda levervärden pga kortisonbehandling" },
  { value: "DD89", title: "Övrig toxisk hepatos" },
  { value: "DD9", title: "Idiopatiska, ospecif, multifakt, lever" },
  { value: "DD91", title: "Leverförstoring / hepatomegali" },
  { value: "DD92", title: "Hepatos" },
  { value: "DE", title: "Pankreas" },
  { value: "DE0", title: "Normalvar, symtom u fastställd orsak, pankreas" },
  { value: "DE00", title: "Normalvariation, pankreas" },
  { value: "DE01", title: "Symtom på sjukdom u fastställd orsak, pankreas" },
  { value: "DE1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, pankreas" },
  { value: "DE10", title: "Missbildning, pankreas" },
  { value: "DE11", title: "Utvecklingsrubbn, tillväxtrubbn, pankreas" },
  { value: "DE2", title: "Metabol, nutrit, degen/dystrof förändr, pankreas" },
  { value: "DE20", title: "Metaboliska förändringar, pankreas" },
  { value: "DE21", title: "Nutritionella förändringar, pankreas" },
  { value: "DE22", title: "Degenerativa/dystrofiska förändringar, pankreas" },
  { value: "DE23", title: "Hormonella förändringar, pankreas" },
  { value: "DE3", title: "Cirkulatoriska förändringar, pankreas" },
  { value: "DE30", title: "Blödningar, pankreas" },
  { value: "DE31", title: "Ödem, pankreas" },
  { value: "DE32", title: "Infarkter, pankreas" },
  { value: "DE34", title: "Trombos / emboli, pankreas" },
  { value: "DE35", title: "Arterio-atero-skleros förändringar, pankreas" },
  { value: "DE4", title: "Infekt, infl förändringar, pankreas" },
  { value: "DE40", title: "Specifika infektionssjukdomar, pankreas" },
  { value: "DE41", title: "Akuta inflammationstillstånd, pankreas" },
  { value: "DE411", title: "Akut serös / serofibrinös inflammation, pankreas" },
  { value: "DE412", title: "Akut purulent inflammation, pankreas" },
  { value: "DE413", title: "Specifika akuta infl tillstånd, pankreas" },
  { value: "DE419", title: "Övriga akuta infl tillstånd, pankreas" },
  { value: "DE4191", title: "Akut pankreatit" },
  { value: "DE42", title: "Kroniska inflammationstillstånd, pankreas" },
  { value: "DE421", title: "Kronisk serös inflammation, pankreas" },
  { value: "DE422", title: "Purulent inflammation, pankreas" },
  { value: "DE423", title: "Abscess/fistel, pankreas" },
  { value: "DE424", title: "Specifika kroniska infl tillstånd, pankreas" },
  { value: "DE429", title: "Övriga kroniska infl tillstånd, pankreas" },
  { value: "DE4291", title: "Kronisk intermittent pankreatit" },
  { value: "DE43", title: "Parasitära sjukdomar, pankreas" },
  { value: "DE5", title: "Immunmedierade tillstånd, pankreas" },
  { value: "DE6", title: "Neoplastiska förändringar, pankreas" },
  { value: "DE61", title: "Tumör i pankreas" },
  { value: "DE611", title: "Insulinom" },
  { value: "DE612", title: "Adenokarcinom i pankreas" },
  { value: "DE613", title: "Zollinger-Ellison syndromet" },
  { value: "DE7", title: "Trauma, fr kr, lägeförändr, term skad, pankreas" },
  { value: "DE71", title: "Traumatiska / mekaniska skador, pankreas" },
  { value: "DE72", title: "Främmande kroppar, pankreas" },
  { value: "DE73", title: "Lägesförändringar, pankreas" },
  { value: "DE8", title: "Toxiska förändringar, pankreas" },
  { value: "DE9", title: "Idiopatiska, ospecif, multifakt, pankreas" },
  { value: "DE91", title: "Exokrin pankreasinsufficiens" },
  { value: "E", title: "Endokrina systemets sjukdomar" },
  { value: "EA", title: "Endokrina organ" },
  {
    value: "EA0",
    title: "Normalvar, symtom u fastställd orsak, endokrina organ",
  },
  { value: "EA00", title: "Normalvariation, endokrina organ" },
  {
    value: "EA01",
    title: "Symtom på sjukdom u fastställd orsak, endokrina organ",
  },
  {
    value: "EA011",
    title: "Symtom på endokrin sjd u fastställd orsak, endokrina organ",
  },
  { value: "EA012", title: "Symtom på hyperestrogenism" },
  {
    value: "EA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, endokrina organ",
  },
  { value: "EA10", title: "Missbildning, endokrina organ" },
  { value: "EA11", title: "Utvecklingsrubbn, tillväxtrubbn, endokrina organ" },
  { value: "EA111", title: "Brist på tillväxthormon (hypofysär dvärgväxt)" },
  { value: "EA112", title: "Överproduktion av tillväxthormon (akromegali)" },
  { value: "EA113", title: "Progestin-inducerad akromegali" },
  {
    value: "EA2",
    title: "Metabol, nutrit, degen/dystrof förändr, endokrina organ",
  },
  { value: "EA20", title: "Metaboliska förändringar, endokrina organ" },
  { value: "EA21", title: "Nutritionella förändringar, endokrina organ" },
  {
    value: "EA22",
    title: "Degenerativa/dystrofiska förändringar, endokrina organ",
  },
  { value: "EA23", title: "Hormonella förändringar, endokrina organ" },
  { value: "EA231", title: "Addisons sjukdom" },
  { value: "EA2311", title: "Primär hypoadrenokortisism" },
  { value: "EA2312", title: "Sekundär hypoadrenokortisism" },
  { value: "EA232", title: "Cushings syndrom" },
  { value: "EA2321", title: "Hypofysberoende Cushing" },
  { value: "EA2322", title: "Nybildning i binjurebarken" },
  { value: "EA2323", title: "Iatrogen Cushing" },
  { value: "EA233", title: "Diabetes insipidus" },
  { value: "EA2331", title: "Central diabetes insipidus" },
  { value: "EA2332", title: "Nefrogen diabetes insipidus" },
  { value: "EA234", title: "Diabetes mellitus" },
  { value: "EA2341", title: "Diabetes mellitus utan komplikation" },
  { value: "EA2342", title: "Diabetes mellitus med komplikation" },
  { value: "EA23421", title: "Diabetes mellitus med ketoacidos" },
  { value: "EA23422", title: "Diabetes mellitus med hypoglukemi" },
  { value: "EA2343", title: "Diabetes mellitus vid dräktighet" },
  { value: "EA2344", title: "Icke-insulinberoende diabetes mellitus" },
  { value: "EA2345", title: "Nedsatt glukostolerans, prediabetes" },
  { value: "EA2346", title: "Hyperinsulinism" },
  { value: "EA235", title: "Generell hypofysstörning" },
  { value: "EA236", title: "Paratyroidearubbning" },
  { value: "EA2361", title: "Hypoparatyreoidism" },
  { value: "EA2362", title: "Hyperparatyreoidism" },
  { value: "EA23621", title: "Nutritionell sekundär hyperparatyreoidism" },
  { value: "EA23622", title: "Renal sekundär hyperparatyreoidism" },
  { value: "EA237", title: "Tyroidearubbning" },
  { value: "EA2371", title: "Hypotyreos" },
  { value: "EA2372", title: "Hypertyreos" },
  { value: "EA2373", title: "Jodbriststruma" },
  { value: "EA2374", title: "Metaboliskt syndrom" },
  { value: "EA238", title: "Cushing-like disease" },
  { value: "EA3", title: "Cirkulatoriska förändringar, endokrina organ" },
  { value: "EA30", title: "Blödningar, endokrina organ" },
  { value: "EA31", title: "Ödem, endokrina organ" },
  { value: "EA32", title: "Infarkter, endokrina organ" },
  { value: "EA34", title: "Trombos / emboli, endokrina organ" },
  {
    value: "EA35",
    title: "Arterio-atero-skleros förändringar, endokrina organ",
  },
  { value: "EA4", title: "Infekt, infl förändringar, endokrina organ" },
  { value: "EA40", title: "Specifika infektionssjukdomar, endokrina organ" },
  { value: "EA41", title: "Akuta inflammationstillstånd, endokrina organ" },
  {
    value: "EA411",
    title: "Akut serös / serofibrinös inflammation, endokrina organ",
  },
  { value: "EA412", title: "Akut purulent inflammation, endokrina organ" },
  { value: "EA413", title: "Specifika akuta infl tillstånd, endokrina organ" },
  { value: "EA419", title: "Övriga akuta infl tillstånd, endokrina organ" },
  { value: "EA42", title: "Kroniska inflammationstillstånd, endokrina organ" },
  { value: "EA421", title: "Kronisk serös inflammation, endokrina organ" },
  { value: "EA422", title: "Purulent inflammation, endokrina organ" },
  { value: "EA423", title: "Abscess/fistel, endokrina organ" },
  {
    value: "EA424",
    title: "Specifika kroniska infl tillstånd, endokrina organ",
  },
  { value: "EA429", title: "Övriga kroniska infl tillstånd, endokrina organ" },
  { value: "EA43", title: "Parasitära sjukdomar, endokrina organ" },
  { value: "EA5", title: "Immunmedierade tillstånd, endokrina organ" },
  { value: "EA6", title: "Neoplastiska förändringar, endokrina organ" },
  { value: "EA61", title: "Tumör i tyroidea" },
  { value: "EA62", title: "Tumör i paratyroidea" },
  { value: "EA63", title: "Tumör i hypofys" },
  { value: "EA64", title: "Tumör i binjurar" },
  { value: "EA65", title: "Insulinom" },
  {
    value: "EA7",
    title: "Trauma, fr kr, lägeförändr, term skad, endokrina organ",
  },
  { value: "EA71", title: "Traumatiska / mekaniska skador, endokrina organ" },
  { value: "EA72", title: "Främmande kroppar, endokrina organ" },
  { value: "EA73", title: "Lägesförändringar, endokrina organ" },
  { value: "EA8", title: "Toxiska förändringar, endokrina organ" },
  { value: "EA9", title: "Idiopatiska, ospecif, multifakt, endokrina organ" },
  { value: "H", title: "Hudens, underhudens, klons och hovens sjukdomar" },
  { value: "HA", title: "Hud, underhud" },
  { value: "HA0", title: "Normalvar, symtom u fastställd orsak, hud underhud" },
  { value: "HA00", title: "Normalvariation, hud underhud" },
  {
    value: "HA01",
    title: "Symtom på sjukdom u fastställd orsak, hud underhud",
  },
  { value: "HA011", title: "Klåda" },
  { value: "HA0111", title: "Klåda med hudlesioner (utslag)" },
  { value: "HA0112", title: "Klåda utan hudlesioner (utslag)" },
  { value: "HA0113", title: "Hudlesioner (utslag) utan klåda" },
  { value: "HA0114", title: "Mjäll" },
  { value: "HA012", title: "Onormal hårrem" },
  { value: "HA0121", title: "Lokal alopeci (hårlöshet)" },
  { value: "HA0122", title: "Fläckvis alopeci (hårlöshet)" },
  { value: "HA0123", title: "Symmetrisk alopeci (bilateralt håravfall)" },
  {
    value: "HA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, hud underhud",
  },
  { value: "HA10", title: "Missbildning, hud underhud" },
  { value: "HA101", title: "Medfödd alopeci" },
  { value: "HA102", title: "Color mutant alopeci" },
  { value: "HA103", title: "Kongenital hypotrikos" },
  { value: "HA104", title: "Grey Collie syndrome" },
  { value: "HA105", title: "Epidermoidcysta" },
  { value: "HA106", title: "Dermoidcysta" },
  { value: "HA107", title: "Pineal alopeci" },
  { value: "HA108", title: "Medfödd pigmentrubbning" },
  { value: "HA1081", title: "Albinism" },
  { value: "HA109", title: "Dermatosis vegetans (klumpfotsjuka,svin)" },
  { value: "HA11", title: "Utvecklingsrubbn, tillväxtrubbn, hud underhud" },
  { value: "HA110", title: "Ärftligt betingade defekter hud" },
  { value: "HA11001", title: "Follikulär cysta, aterom" },
  { value: "HA11002", title: "Dermoidcysta" },
  { value: "HA11003", title: "Nevus" },
  { value: "HA11004", title: "Nodulär dermatofibros/kollagena nevi (schäfer)" },
  { value: "HA11005", title: "Mucinos (sharpei)" },
  { value: "HA11006", title: "Dermatomyosit" },
  { value: "HA11007", title: "Akrodermatit (bullterrier)" },
  { value: "HA11008", title: "Epidermolysis bullosa" },
  { value: "HA11009", title: "Ehler-Danlos syndrom (kutan astheni)" },
  { value: "HA11010", title: "Primärt lymfödem" },
  { value: "HA11011", title: "Cavalierklåda (syringomyeli)" },
  { value: "HA111", title: "Hypertrikos" },
  { value: "HA1111", title: "Medfödd alopeci" },
  {
    value: "HA1112",
    title: "Pattern baldness (tax, greyhound, vattenspaniel)",
  },
  { value: "HA1113", title: "Melanoderma och alopeci hos yorkshireterrier" },
  { value: "HA1114", title: "Follikeldystrofi/dysplasi" },
  { value: "HA1115", title: "Epiteliogenesis imperfecta" },
  { value: "HA112", title: "Abnorm keratinisering" },
  { value: "HA1121", title: "Iktyos" },
  { value: "HA1122", title: "Epidermal dysplasi (Westie)" },
  { value: "HA1123", title: "Flank alopeci återkommande, symmetrisk" },
  { value: "HA1124", title: "Post-clipping alopecia" },
  { value: "HA1125", title: "Post partum telogen defluxion" },
  { value: "HA113", title: "Förvärvad pigmentrubbning" },
  { value: "HA1131", title: "Vitiligo" },
  { value: "HA1132", title: "Iktyosiform dermatos hos golden" },
  {
    value: "HA1133",
    title: "Lichenoid psoriasiform dermatos (springer spaniel)",
  },
  { value: "HA1134", title: "Schnauzer komedon syndromet" },
  { value: "HA1135", title: "Stud tail, katt" },
  { value: "HA1139", title: "Övriga förvärvade pigmentrubbningar" },
  { value: "HA114", title: "Förvärvade keratiniseringsrubbningar hud" },
  { value: "HA1141", title: "Nasodigital hyperkeratos" },
  { value: "HA1142", title: "Sebakös adenit" },
  { value: "HA115", title: "Ärftligt betingade pigmentrubbningar hud" },
  { value: "HA1151", title: "Albinism" },
  { value: "HA1152", title: "Primär acantosis nigricans (tax)" },
  { value: "HA1153", title: "Nasal depigmentering" },
  { value: "HA1154", title: "Dermatosis vegetans (klumpfotsjuka,svin)" },
  { value: "HA116", title: "Förvärvade pigmentrubbningar hud" },
  { value: "HA1161", title: "Vitiligo" },
  { value: "HA1163", title: "Pigmentfläckar" },
  { value: "HA1164", title: "Utbredd bilateral pigmentering" },
  {
    value: "HA2",
    title: "Metabol, nutrit, degen/dystrof förändr, hud underhud",
  },
  { value: "HA20", title: "Metaboliska förändringar, hud underhud" },
  {
    value: "HA201",
    title: "Hepatokutant syndrom (metabolisk epidermal nekros)",
  },
  { value: "HA202", title: "Paraneoplastisk alopeci katt" },
  { value: "HA203", title: "Feline fragile skin" },
  { value: "HA204", title: "Calcinosis cutis" },
  { value: "HA21", title: "Nutritionella förändringar, hud underhud" },
  { value: "HA211", title: "Zinkresponsiv dermatos" },
  { value: "HA212", title: "Vitamin A responsiv dermatos" },
  { value: "HA219", title: "Övriga nutritionella dermatoser" },
  { value: "HA2191", title: "Pansteatit" },
  {
    value: "HA22",
    title: "Degenerativa/dystrofiska förändringar, hud underhud",
  },
  { value: "HA23", title: "Hormonella hudförändringar" },
  { value: "HA233", title: "Endokrin dermatos" },
  { value: "HA2332", title: "Cushingrelaterad dermatos" },
  { value: "HA2333", title: "Dermatos relaterad till hypotyroidism" },
  { value: "HA3", title: "Cirkulatoriska förändringar, hud underhud" },
  { value: "HA30", title: "Blödningar, hud underhud" },
  { value: "HA301", title: "Hematom" },
  { value: "HA302", title: "Petekiala blödningar" },
  { value: "HA31", title: "Ödem, hud underhud" },
  { value: "HA311", title: "Ventrala subkutana ödem" },
  { value: "HA32", title: "Ischemi, hud underhud" },
  { value: "HA321", title: "Svansspetsnekros" },
  { value: "HA33", title: "Infarkter, hud underhud" },
  { value: "HA34", title: "Hygrom, hud underhud" },
  { value: "HA341", title: "Bursitis olecrani" },
  { value: "HA342", title: "Bursitis calcanei" },
  { value: "HA343", title: "Hudförändringar vid precarpalbursit (jmfr MB716)" },
  { value: "HA344", title: "Bröstbensbursit (fjäderfä)" },
  { value: "HA37", title: "Serom i underhuden" },
  { value: "HA4", title: "Infekt, infl förändringar, hud underhud" },
  { value: "HA40", title: "Specifika infektionssjukdomar, hud underhud" },
  { value: "HA401", title: "Virusbetingade hudsjukdomar" },
  { value: "HA4011", title: "Feline cow pox" },
  { value: "HA4012", title: "Sarkoidos" },
  { value: "HA4013", title: "Bovint papillomvirus (se äv.HA611)" },
  { value: "HA4014", title: "Falska koppor, spenar" },
  { value: "HA4015", title: "Smittsamt muneksem (Orf, får/get)" },
  { value: "HA4018", title: "Svartskorv/svarteksem" },
  { value: "HA4019", title: "Herpes katt, hud" },
  { value: "HA402", title: "Bakteriella hudsjukdomar" },
  { value: "HA4021", title: "Nocardios i huden" },
  { value: "HA40211", title: "Intertrigo (hudvecksdermatit)" },
  { value: "HA40212", title: "Hot spot (fukteksem, retrievereksem)" },
  { value: "HA40213", title: "Impetigo" },
  { value: "HA40214", title: "Follikulit" },
  { value: "HA40215", title: "Superficial spreading pyoderma" },
  { value: "HA40216", title: "Mukokutan pyodermi" },
  { value: "HA4022", title: "Aktinomykos i huden" },
  { value: "HA40221", title: "Hot spot med djup pyodermi" },
  { value: "HA40222", title: "Nasal pyodermi" },
  { value: "HA40223", title: "Presspunktspyodermi (calluspyodermi)" },
  { value: "HA40225", title: "Furunkulos" },
  { value: "HA40226", title: "Akne" },
  { value: "HA40227", title: "Cellulit/flegmon" },
  { value: "HA40228", title: "Juvenil cellulit (fd lymfomatosis apostulosa)" },
  { value: "HA40229", title: "(Schäfer)pyodermi" },
  { value: "HA4023", title: "Kutan streptotrikos (nöt,får)" },
  { value: "HA40230", title: "Plantar fistulär pyodermi" },
  { value: "HA40231", title: "Cirkumanalfistlar" },
  { value: "HA40232", title: "Övriga fistlar" },
  { value: "HA40234", title: "Nocardios, hud" },
  { value: "HA40235", title: "Aktinomykos, hud" },
  { value: "HA4024", title: "Exsudativ epidermit (Staf.hyicus,svin)" },
  { value: "HA403", title: "Mykotiskt betingade hudsjukdomar" },
  { value: "HA4031", title: "Dermatofytos (ringorm)" },
  { value: "HA40311", title: "Ringorm/dermatofytos" },
  { value: "HA40312", title: "Jästsvamp (Malassezia)" },
  { value: "HA4032", title: "Jästsvampangrepp" },
  { value: "HA40321", title: "Candidiasis" },
  { value: "HA40322", title: "Malassezia angrepp" },
  { value: "HA4033", title: "Subkutana mykoser" },
  { value: "HA40331", title: "Sporotrikos i huden" },
  { value: "HA40332", title: "Pytiosis i huden" },
  { value: "HA41", title: "Akuta inflammationstillstånd, hud underhud" },
  { value: "HA411", title: "Serös dermatit" },
  { value: "HA412", title: "Purulent dermatit" },
  { value: "HA413", title: "Flegmon/cellulit" },
  { value: "HA4131", title: "Juvenil cellulit (fd lymfomatosis apostulosa)" },
  { value: "HA414", title: "Specifika akuta infl tillstånd, hud underhud" },
  { value: "HA419", title: "Övriga akuta infl tillstånd, hud underhud" },
  { value: "HA4191", title: "Ytlig akut pyodermi" },
  { value: "HA41911", title: "Hot spot" },
  { value: "HA41912", title: "Impetigo" },
  { value: "HA41913", title: "Akut follikulit" },
  { value: "HA41914", title: "Fjäderfollikulit" },
  { value: "HA4192", title: "Djup akut pyodermi" },
  { value: "HA4193", title: "Juvereksem" },
  { value: "HA4199", title: "Akut dermatit av okänt ursprung" },
  { value: "HA42", title: "Kroniska inflammationstillstånd, hud underhud" },
  { value: "HA421", title: "Kronisk serös dermatit, hud underhud" },
  { value: "HA4211", title: "Indolent ulcus hud" },
  { value: "HA4212", title: "Eosinofila ulcus hud" },
  { value: "HA4213", title: "Eosinofila  plaque hud" },
  { value: "HA4214", title: "Eosinofila  granulom hud" },
  { value: "HA4215", title: "Lineära granulom hud" },
  { value: "HA4216", title: "Eosinofil furunkulos" },
  { value: "HA422", title: "Purulent inflammation, hud underhud" },
  { value: "HA423", title: "Abscess/fistel, hud underhud" },
  { value: "HA4231", title: "Abscess, hud underhud" },
  { value: "HA4232", title: "Fistel, hud underhud" },
  { value: "HA42321", title: "Mankfistel" },
  { value: "HA42322", title: "Cirkumanalfistlar" },
  { value: "HA42329", title: "Övriga fistlar" },
  { value: "HA424", title: "Specifika kroniska infl tillstånd, hudunderhud" },
  { value: "HA4241", title: "Eosinofila granulationskomplexet" },
  { value: "HA42411", title: "Eosinofila ulcus" },
  { value: "HA42412", title: "Eosinofila  plaque" },
  { value: "HA42413", title: "Eosinofila  granulom" },
  { value: "HA42414", title: "Exfoliativ eosinofil dermatit" },
  { value: "HA425", title: "Nodulär pannikulit" },
  { value: "HA426", title: "Sommarsår (Habronemalarver)" },
  { value: "HA429", title: "Övriga kroniska infl tillstånd, hud underhud" },
  { value: "HA4291", title: "Ytliga kroniska pyodermier" },
  { value: "HA42911", title: "Hudveckspyodermi" },
  { value: "HA42912", title: "Kronisk follikulit" },
  { value: "HA4292", title: "Djup kronisk pyodermi" },
  { value: "HA42921", title: "Presspunkt pyodermi" },
  { value: "HA42922", title: "Furunkulos" },
  { value: "HA42923", title: "Pododermatit" },
  { value: "HA42924", title: "(Schäfer)pyodermi" },
  { value: "HA42925", title: "Plantar fistulär pyodermi" },
  { value: "HA42926", title: "Nasal pyodermi" },
  { value: "HA42929", title: "Övrig djup kronisk pyodermi" },
  { value: "HA4293", title: "Övriga kroniska pustulära sjukdomar" },
  { value: "HA42931", title: "Juvenil hyperkeratos" },
  { value: "HA42932", title: "Subkorneal postulär dermatos" },
  { value: "HA42933", title: "Sterila pyogranulomsyndromet" },
  { value: "HA42934", title: "Nodulär pannikulit" },
  { value: "HA4299", title: "Kronisk dermatit okänd orsak" },
  { value: "HA43", title: "Parasitära sjukdomar, hud underhud" },
  { value: "HA4301", title: "Loppor" },
  { value: "HA4302", title: "Löss" },
  { value: "HA4303", title: "Mjällkvalster (Cheyletiella)" },
  { value: "HA4304", title: "Rävskabb (Sarcoptes)" },
  { value: "HA4305", title: "Notoedres (kattskabb)" },
  { value: "HA4306", title: "Demodex (hårsäckskvalster)" },
  { value: "HA4307", title: "Otodectes (öronskabb)" },
  { value: "HA4308", title: "Fluglarvangrepp" },
  { value: "HA4309", title: "Knottangrepp" },
  { value: "HA431", title: "Sommarsår (Habronemalarver se äv.AA43154)" },
  { value: "HA4310", title: "Fästing" },
  { value: "HA4311", title: "Leishmaniasis" },
  { value: "HA5", title: "Immunologiska tillstånd, hud underhud" },
  { value: "HA51", title: "Allergi med hudaffektion" },
  { value: "HA511", title: "Urtikaria / angioderma" },
  { value: "HA512", title: "Atopi" },
  { value: "HA513", title: "Kontaktdermatit / allergi" },
  { value: "HA514", title: "Födoämnesallergi" },
  { value: "HA515", title: "Läkemedelsallergi" },
  { value: "HA516", title: "Hormonell överkänslighet" },
  { value: "HA517", title: "Överkänslighet mot parasiter och bakterier" },
  { value: "HA5171", title: "Loppallergi" },
  { value: "HA5172", title: "Överkänslighet mot parasiter" },
  { value: "HA51721", title: "Knottallergi" },
  { value: "HA51722", title: "Myggbettsöverkänslighet" },
  { value: "HA5173", title: "Överkänslighet mot bakterier" },
  { value: "HA52", title: "Autoimmuna hudsjukdomar" },
  { value: "HA521", title: "Pemfigus" },
  { value: "HA5211", title: "Pemfigus vulgaris" },
  { value: "HA5212", title: "Pemfigus vegetans" },
  { value: "HA5213", title: "Pemfigus foliaceus" },
  { value: "HA5214", title: "Pemfigus erythematosus" },
  { value: "HA5215", title: "Bullös pemfigoid" },
  { value: "HA5216", title: "Paraneoplastisk pemfigus" },
  { value: "HA5217", title: "Lupus" },
  { value: "HA52171", title: "Systemic lupus erytematosus (SLE)" },
  { value: "HA52172", title: "Discoid lupus erytematosus (DLE)" },
  { value: "HA52173", title: "Ulcerativ dermatos hos colie och sheltie" },
  {
    value: "HA52174",
    title: "Exfoliativ kutan lupus erytematosus hos vorsteh",
  },
  { value: "HA52176", title: "Köldagglutinin sjukdom" },
  { value: "HA522", title: "Discoid lupus erytematosus (DLE)" },
  { value: "HA523", title: "Köldagglutinin sjukdom" },
  { value: "HA524", title: "Dermatomyosit" },
  { value: "HA53", title: "Immunmedierad sjukdom" },
  { value: "HA531", title: "Cutan vaskulit" },
  { value: "HA532", title: "Toxisk epidermal nekrolys" },
  { value: "HA533", title: "Erythema multiforme" },
  { value: "HA534", title: "Linjär IgA dermatos" },
  { value: "HA535", title: "Fotosensibilitet" },
  { value: "HA536", title: "Aural plaque" },
  { value: "HA537", title: "Sebakös adenit" },
  {
    value: "HA538",
    title: "Uveodermatologiskt syndr (Voigt-Koynagi Harada-like syndr)",
  },
  { value: "HA6", title: "Neoplastiska förändringar, hud underhud" },
  { value: "HA61", title: "Epiteliala neoplasier, hud underhud" },
  { value: "HA611", title: "Papillom, hud underhud" },
  { value: "HA612", title: "Skivepitelskarcinom, hud underhud" },
  { value: "HA613", title: "Basalcellskarcinom, hud underhud" },
  { value: "HA614", title: "Plattepitelskarcinom, hud underhud" },
  { value: "HA615", title: "Analadenom" },
  { value: "HA616", title: "Analsäckskarcinom" },
  { value: "HA62", title: "Mesenkymala neoplasier, hud underhud" },
  { value: "HA621", title: "Fibrom, hud underhud" },
  { value: "HA622", title: "Lipom, hud underhud" },
  { value: "HA623", title: "Mastcellstumörer, hud underhud" },
  { value: "HA624", title: "Fibrosarkom, hud" },
  { value: "HA63", title: "Lymfohistiocytära neoplasier, hud underhud" },
  { value: "HA631", title: "Histiocytom, hud underhud" },
  { value: "HA632", title: "Kutant lymfosarkom" },
  { value: "HA633", title: "Mycosis fungoides, hud underhud" },
  { value: "HA634", title: "Systemisk histiocytos" },
  { value: "HA64", title: "Melanocytiska neoplasier, hud underhud" },
  { value: "HA641", title: "Melanom, hud underhud" },
  { value: "HA65", title: "Icke neoplastiska nybildningar" },
  { value: "HA654", title: "Sarkoid/inkar, hud underhud" },
  { value: "HA655", title: "Neurofibrom, neuron, hud underhud" },
  { value: "HA69", title: "Övriga neoplasier, hud underhud" },
  { value: "HA691", title: "Dermatofibros , hud underhud" },
  { value: "HA692", title: "Sarkoid / inkar   , hud underhud" },
  { value: "HA693", title: "Neurofibrom, neuron, hud underhud" },
  { value: "HA694", title: "Aural plaques" },
  { value: "HA695", title: "Nevus" },
  {
    value: "HA7",
    title: "Trauma, fr kr, lägeförändr, term skad, hud underhud",
  },
  { value: "HA71", title: "Traumatiska / mekaniska skador, hud underhud" },
  { value: "HA711", title: "Traumatiska skador  huden" },
  { value: "HA7111", title: "Bitsår, hud underhud" },
  { value: "HA7112", title: "Skärsår, hud underhud" },
  { value: "HA7113", title: "Kontusion (krossår), hud underhud" },
  { value: "HA71131", title: "Stångningsskada" },
  { value: "HA7114", title: "Sticksår, hud underhud" },
  { value: "HA7115", title: "Slitsår / rivsår, hud underhud" },
  { value: "HA71151", title: "Taggtrådsskada" },
  { value: "HA71152", title: "Sårsskada, lednära" },
  { value: "HA71153", title: "Sårskada, leden involverad" },
  { value: "HA7116", title: "Skottsår, hud underhud" },
  { value: "HA7117", title: "Trycksår / liggsår, hud underhud" },
  { value: "HA7118", title: "Stakningsskada" },
  { value: "HA7119", title: "Sårinfektion" },
  { value: "HA712", title: "Mekaniska skador, hud underhud" },
  { value: "HA7121", title: "Kontaktirritationsdermatit" },
  { value: "HA7122", title: "Svallkött, hud underhud" },
  { value: "HA7123", title: "Bindsleskada" },
  { value: "HA7124", title: "Bogbladssår" },
  { value: "HA7125", title: "Ljumsksår" },
  { value: "HA7126", title: "Svansskada" },
  { value: "HA72", title: "Främmande kroppar, hud underhud" },
  { value: "HA721", title: "Migrerande småax" },
  { value: "HA73", title: "Lägesförändringar, hud underhud" },
  { value: "HA74", title: "Termiska skador, hud underhud" },
  { value: "HA741", title: "Brännskada, hud underhud" },
  { value: "HA742", title: "Köldskada, hud underhud" },
  { value: "HA8", title: "Toxiska förändringar, hud underhud" },
  { value: "HA81", title: "UV-ljusrelaterad dermatos" },
  { value: "HA9", title: "Idiopatiska, ospecif, multifakt, hud underhud" },
  { value: "HA91", title: "Seborreisk hudsjukdom" },
  { value: "HA911", title: "Seborré komplexet" },
  { value: "HA9111", title: "Idiopatisk seborré" },
  { value: "HA9112", title: "Mjölkskorv hos valpar" },
  { value: "HA9113", title: "Svanskörtel seborré" },
  { value: "HA9114", title: "Seborré på öronlockskanten" },
  { value: "HA9115", title: "Cannon keratos" },
  { value: "HA9116", title: "Torr seborré" },
  { value: "HA9117", title: "Fet seborré" },
  { value: "HA912", title: "Schnautzer komedon" },
  { value: "HA913", title: "Dirty face syndromet (perser)" },
  { value: "HA914", title: "Nasodigetal hyperkeratos" },
  { value: "HA915", title: "Akne" },
  { value: "HA916", title: "Zinkresponsiv dermatos" },
  { value: "HA917", title: "A-vitamin responsiv dermatos" },
  { value: "HA92", title: "Psykogena hudsjukdomar" },
  { value: "HA921", title: "Akral slickdermatit" },
  { value: "HA922", title: "Psykogen alopeci" },
  { value: "HA929", title: "Övriga psykogen hudsjukdomar" },
  { value: "HA99", title: "Övrig hudsjukdom" },
  { value: "HA991", title: "Miliär dermatit hos katt" },
  { value: "HA9910", title: "Calcinosis circumscripta, tumoral calcinos" },
  { value: "HA9911", title: "Enkelsidig papulär dermatos" },
  { value: "HA9912", title: "Linjär keratos" },
  { value: "HA9913", title: "Ventral midline dermatit" },
  { value: "HA9914", title: "Revorm (Pityriasis rosea, svin)" },
  { value: "HA9915", title: "Tovad päls" },
  { value: "HA992", title: "Aterom" },
  { value: "HA993", title: "Pigmentsjukdom" },
  { value: "HA9931", title: "Förvärvad pigmentbrist av okänd genes" },
  { value: "HA9932", title: "Nasal depigmentering" },
  { value: "HA994", title: "Alopecier" },
  { value: "HA9941", title: "Alopeci utan fastsälld orsak" },
  { value: "HA99414", title: "Lineär alopeci" },
  { value: "HA9942", title: "Symmetrisk alopeci" },
  { value: "HA9943", title: "Alopecia areata" },
  { value: "HA995", title: "Mugg / Rasp" },
  { value: "HA9951", title: "Kronrandsdystrofi" },
  { value: "HA996", title: "Krustös papulös skenbensdermatit" },
  { value: "HA997", title: "Man- och svansskorv" },
  { value: "HA9971", title: "Kutan amyloidos hos häst" },
  { value: "HA998", title: "Kolagenolytiskt granulom, kutan nekrobios" },
  { value: "HA9981", title: "Nodulär nekros i axillerna" },
  { value: "HA999", title: "Sadelstadsknutor utan känd etiologi" },
  { value: "HA9991", title: "Systemisk granulomatös sjukdom" },
  { value: "HB", title: "Klor, hov" },
  { value: "HB0", title: "Normalvar, symtom u fastställd orsak, klor, hov" },
  { value: "HB00", title: "Normalvariation, klor, hov" },
  { value: "HB01", title: "Symtom på sjukdom u fastställd orsak, klor, hov" },
  { value: "HB011", title: "Förvuxna klor" },
  { value: "HB012", title: "Förvuxna hovar" },
  { value: "HB013", title: "Förvuxna klövar" },
  { value: "HB014", title: "Låg palmar hälta  (Palmar Heel Pain)" },
  {
    value: "HB1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, klor, hov",
  },
  { value: "HB10", title: "Missbildning, klor, hov" },
  { value: "HB101", title: "Patologiska hovformer" },
  { value: "HB1011", title: "Bockhov" },
  { value: "HB1012", title: "Björnfot" },
  { value: "HB1013", title: "Platthov" },
  { value: "HB1014", title: "Fullhov" },
  { value: "HB1015", title: "Trånghov" },
  { value: "HB1016", title: "Skevhov" },
  { value: "HB1017", title: "Krumhov" },
  { value: "HB102", title: "Patologiska klövformer" },
  { value: "HB103", title: "Korkskruvsklöv" },
  { value: "HB104", title: "Bredklöv (får)" },
  { value: "HB11", title: "Utvecklingsrubbn, tillväxtrubbn, klor, hov" },
  { value: "HB111", title: "Hovbroskförbening" },
  { value: "HB112", title: "Onykorrexi (sköra klor)" },
  { value: "HB113", title: "Klövassymmetri" },
  { value: "HB2", title: "Metabol, nutrit, degen/dystrof förändr, klor, hov" },
  { value: "HB20", title: "Metaboliska förändringar, klor, hov" },
  { value: "HB201", title: "Fång" },
  { value: "HB2011", title: "Akut fång" },
  { value: "HB20111", title: "Förlossningsfång" },
  { value: "HB20112", title: "Överbelastningsfång" },
  { value: "HB2012", title: "Uremisk fång" },
  { value: "HB2013", title: "Hovbenssänkning" },
  { value: "HB2014", title: "Hovbensrotation" },
  { value: "HB2015", title: "Kronisk fång (>6v)" },
  { value: "HB2016", title: "Klövsulesår (pododermatitis circumcripta)" },
  { value: "HB2017", title: "Klövbenssänkning, -rotation" },
  { value: "HB21", title: "Nutritionella förändringar, klor, hov" },
  { value: "HB22", title: "Degenerativa/dystrofiska förändringar, klor, hov" },
  { value: "HB3", title: "Cirkulatoriska förändringar, klor, hov" },
  { value: "HB30", title: "Blödningar, klor, hov" },
  { value: "HB301", title: "Läderhudsblödning (stengalla)" },
  { value: "HB302", title: "Sulblödning" },
  { value: "HB31", title: "Ödem, klor, hov" },
  { value: "HB32", title: "Ischemi, klor, hov" },
  { value: "HB33", title: "Infarkter, klor, hov" },
  { value: "HB4", title: "Infekt, infl förändringar, klor, hov" },
  { value: "HB40", title: "Specifika infektionssjukdomar, klor, hov" },
  { value: "HB41", title: "Akuta inflammationstillstånd, klor, hov" },
  { value: "HB411", title: "Akut serös inflammation, klor, hov" },
  { value: "HB4111", title: "Foteksem (dermatitis digitalis)" },
  { value: "HB412", title: "Akut purulent inflammation, klor, hov" },
  { value: "HB413", title: "Flegmon/cellulit, klor, hov" },
  { value: "HB4131", title: "Klövspaltsflegmon (Klövspaltsinflammation)" },
  { value: "HB4132", title: "Klövspaltseksem/dermatit, interdigital dermatit" },
  { value: "HB414", title: "Specifika akuta infl tillstånd, klor, hov" },
  { value: "HB419", title: "Övriga akuta infl tillstånd, klor, hov" },
  { value: "HB42", title: "Kroniska inflammationstillstånd, klor, hov" },
  { value: "HB421", title: "Kronisk serös inflammation, klor, hov" },
  { value: "HB422", title: "Purulent inflammation, klor, hov" },
  { value: "HB4221", title: "Hovböld" },
  { value: "HB4222", title: "Strålröta" },
  { value: "HB4223", title: "Klövröta" },
  { value: "HB4224", title: "Klövböld" },
  { value: "HB42241", title: "Klövböld, vita linjen" },
  { value: "HB42242", title: "Klövböld, sula/tån" },
  {
    value: "HB4225",
    title: "Klövspaltsdermatit(klövspaltseksem, dermatitis interdig.)",
  },
  { value: "HB4226", title: "Fotsår/fotböld fjäderfä" },
  { value: "HB4227", title: "Bärrandsröta" },
  { value: "HB4228", title: "White line disease" },
  { value: "HB4229", title: "Hålvägg" },
  { value: "HB423", title: "Abscess/fistel, klor, hov" },
  { value: "HB424", title: "Specifika kroniska infl tillstånd, klor, hov" },
  { value: "HB4241", title: "Klokapselinflammation" },
  { value: "HB429", title: "Övriga kroniska infl tillstånd, klor, hov" },
  { value: "HB4292", title: "Klokapselinflammation" },
  { value: "HB4293", title: "Onykomykos" },
  { value: "HB4294", title: "Verrukös dermatit (Vårtig dermatit)" },
  { value: "HB4295", title: "Symmetrisk lupoid onychodystrofi" },
  { value: "HB4296", title: "Hovkräfta" },
  { value: "HB43", title: "Parasitära sjukdomar, klor, hov" },
  { value: "HB5", title: "Immunologiska tillstånd, klor, hov" },
  { value: "HB6", title: "Neoplastiska förändringar, klor, hov" },
  { value: "HB61", title: "Hovkräfta" },
  { value: "HB7", title: "Trauma, fr kr, lägeförändr, term skad, klor, hov" },
  { value: "HB71", title: "Traumatiska / mekaniska skador, klor, hov" },
  { value: "HB711", title: "Traumatiska skador klor, hov" },
  { value: "HB7111", title: "Spiktramp" },
  { value: "HB71111", title: "Perforerande spiktramp" },
  { value: "HB7112", title: "Klobrott / Klokapselbrott" },
  { value: "HB7113", title: "Sår hov" },
  { value: "HB71131", title: "Balltramp" },
  { value: "HB71132", title: "Sår kronrand" },
  { value: "HB71133", title: "Sömstick" },
  { value: "HB71134", title: "Trampskada" },
  { value: "HB712", title: "Mekaniska skador, klor, hov" },
  { value: "HB7121", title: "Hornspricka" },
  { value: "HB7122", title: "Hornklyfta" },
  { value: "HB7123", title: "Hornpelare" },
  { value: "HB7124", title: "Hornkapselskada" },
  { value: "HB7125", title: "Sulömhet" },
  { value: "HB7126", title: "Exungulation" },
  { value: "HB7127", title: "Hålvägg" },
  { value: "HB7128", title: "Limax (hyperplasia interdigitalis)" },
  { value: "HB7129", title: "Belastningsseparation" },
  { value: "HB72", title: "Främmande kroppar, klor, hov" },
  { value: "HB721", title: "Främmande kropp i hov" },
  { value: "HB73", title: "Lägesförändringar, klor, hov" },
  { value: "HB74", title: "Termiska skador, klor, hov" },
  { value: "HB741", title: "Brännskada, klor, hov" },
  { value: "HB742", title: "Köldskada, klor, hov" },
  { value: "HB8", title: "Toxiska förändringar, klor, hov" },
  { value: "HB9", title: "Idiopatiska, ospecif, multifakt, klor, hov" },
  { value: "HB91", title: "Klokapselavlossning av okänd genes" },
  { value: "HB92", title: "Klövkapselavlossning" },
  { value: "HB93", title: "Klövskada" },
  { value: "HB94", title: "Dubbelsula" },
  { value: "HB95", title: "Klövsjukdom" },
  { value: "HC", title: "Specialiserad hud (t ex horn, kastanjer)" },
  {
    value: "HC0",
    title: "Normalvar, symtom u fastställd orsak, specialiserad hud",
  },
  { value: "HC00", title: "Normalvariation, specialiserad hud" },
  {
    value: "HC01",
    title: "Symtom på sjukdom u fastställd orsak, specialiserad hud",
  },
  {
    value: "HC011",
    title: "Symtom på endokrin sjd u fastställd orsak, specialiserad hud",
  },
  {
    value: "HC1",
    title: "Missbildn, utveckl rubbn, tillväxtrubbn, specialiserad hud",
  },
  { value: "HC10", title: "Missbildning, specialiserad hud" },
  { value: "HC101", title: "Missbildning trampdyna" },
  { value: "HC102", title: "Missbildning horn" },
  {
    value: "HC11",
    title: "Utvecklingsrubbn, tillväxtrubbn, specialiserad hud",
  },
  {
    value: "HC2",
    title: "Metabol, nutrit, degen/dystrof förändr, specialiserad hud",
  },
  { value: "HC20", title: "Metaboliska förändringar, specialiserad hud" },
  { value: "HC21", title: "Nutritionella förändringar, specialiserad hud" },
  {
    value: "HC22",
    title: "Degenerativa/dystrofiska förändringar, specialiserad hud",
  },
  { value: "HC23", title: "Hormonella förändringar, specialiserad hud" },
  { value: "HC3", title: "Cirkulatoriska förändringar, specialiserad hud" },
  { value: "HC30", title: "Blödningar, specialiserad hud" },
  { value: "HC31", title: "Ödem, specialiserad hud" },
  { value: "HC32", title: "Infarkter, specialiserad hud" },
  { value: "HC34", title: "Trombos/emboli, specialiserad hud" },
  {
    value: "HC35",
    title: "Arterio-atero-skleros förändringar, specialiserad hud",
  },
  { value: "HC4", title: "Infekt, infl förändringar, specialiserad hud" },
  { value: "HC40", title: "Specifika infektionssjukdomar, specialiserad hud" },
  { value: "HC41", title: "Akuta inflammationstillstånd, specialiserad hud" },
  {
    value: "HC42",
    title: "Kroniska inflammationstillstånd, specialiserad hud",
  },
  { value: "HC43", title: "Parasitära sjukdomar, specialiserad hud" },
  { value: "HC5", title: "Immunmedierade tillstånd, specialiserad hud" },
  { value: "HC6", title: "Tumör i specialiserad hud" },
  { value: "HC61", title: "Neoplastisk förändring, trampdyna" },
  { value: "HC62", title: "Neoplastisk förändring, horn" },
  {
    value: "HC7",
    title: "Trauma, fr kr, lägeförändr, term skad, specialiserad hud",
  },
  { value: "HC71", title: "Traumatiska/mekaniska skador, specialiserad hud" },
  { value: "HC711", title: "Traumatisk skada, trampdyna" },
  { value: "HC7111", title: "Sår trampdyna" },
  { value: "HC71111", title: "Skärsår trampdyna" },
  { value: "HC7112", title: "Brännskada trampdyna" },
  { value: "HC712", title: "Traumatisk skada, horn" },
  { value: "HC713", title: "Traumatisk skada, kastanj" },
  { value: "HC72", title: "Främmande kroppar, specialiserad hud" },
  { value: "HC721", title: "Främmande kropp, trampdyna" },
  { value: "HC722", title: "Främmande kropp, horn" },
  { value: "HC73", title: "Lägesförändringar, specialiserad hud" },
  { value: "HC8", title: "Toxiska förändringar, specialiserad hud" },
  { value: "HC9", title: "Idiopatiska, ospecif, multifakt, specialiserad hud" },
  {
    value: "K",
    title: "Könsorg sjd dräktighet, förlossn/puerperiet fortplan/uppfödn",
  },
  { value: "KA", title: "Hondjur / komplikationer vid dräktighet" },
  {
    value: "KA0",
    title: "Normalvar, symtom u fastställd orsak, könsorgan hondjur",
  },
  { value: "KA00", title: "Normalvariation, könsorgan hondjur" },
  { value: "KA001", title: "Normal löpning / brunst" },
  { value: "KA0011", title: "Anöstrus" },
  { value: "KA0012", title: "Proöstrus" },
  { value: "KA0013", title: "Östrus" },
  { value: "KA0014", title: "Metöstrus" },
  { value: "KA0015", title: "Diöstrus" },
  { value: "KA002", title: "Normal dräktighet" },
  { value: "KA0021", title: "Ej dräktig" },
  { value: "KA0022", title: "Dräktighet - ej fastställd" },
  { value: "KA00221", title: "Tvillingdräktighet" },
  { value: "KA003", title: "Normal förlossning" },
  { value: "KA004", title: "Normala könsorgan, hondjur" },
  { value: "KA0041", title: "Normal livmoder" },
  { value: "KA0042", title: "Normal/a äggstock/ar" },
  { value: "KA0043", title: "Normal vulva" },
  { value: "KA0044", title: "Normal vagina" },
  { value: "KA0045", title: "Normal cervix" },
  { value: "KA006", title: "Svag könsdrift" },
  { value: "KA009", title: "Tjuvbetäckning" },
  {
    value: "KA01",
    title: "Symtom på sjukdom u fastställd orsak, könsorgan hondjur",
  },
  { value: "KA011", title: "Flytningar" },
  { value: "KA0111", title: "Blodiga flytningar" },
  { value: "KA0112", title: "Variga flytningar" },
  { value: "KA0113", title: "Serösa flytningar" },
  { value: "KA0114", title: "Gröna flytningar" },
  { value: "KA0115", title: "Mukösa flytningar" },
  { value: "KA012", title: "Symtom på förlossningsarbete" },
  { value: "KA0121", title: "Värkar" },
  { value: "KA0122", title: "Eftervärkar" },
  { value: "KA013", title: "Symtom på skendräktighet" },
  { value: "KA014", title: "Symtom på onormalt löp / brunst" },
  { value: "KA0140", title: "Brunstig" },
  { value: "KA0141", title: "Symtom på förlängd brunst" },
  { value: "KA0142", title: "Bristande utebliven brunst / löpning" },
  { value: "KA0143", title: "Tyst brunst" },
  { value: "KA0144", title: "Symtomlös omlöpning" },
  { value: "KA0145", title: "Delad brunst (split östrus)" },
  { value: "KA015", title: "Symptom på bäckensymfysupplösning" },
  { value: "KA016", title: "Nymfomani" },
  { value: "KA017", title: "Symtom på wind-sucking" },
  { value: "KA018", title: "Nedsatt fruktsamhet (besättning)" },
  { value: "KA0182", title: "Parningsproblem" },
  {
    value: "KA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, könsorgan hondjur",
  },
  { value: "KA10", title: "Missbildning, könsorgan hondjur" },
  { value: "KA100", title: "Missbildning av äggstockar" },
  { value: "KA1001", title: "Äggstockshypoplasi" },
  { value: "KA101", title: "Missbildning av vulva" },
  { value: "KA1011", title: "Felställd eller indragen vulva" },
  { value: "KA1012", title: "Annan missbildning, vulva" },
  { value: "KA1013", title: "Lutande vulva" },
  { value: "KA102", title: "Vaginal missbildning" },
  { value: "KA1021", title: "Vaginalt septum" },
  { value: "KA1022", title: "Vaginalt striktur" },
  { value: "KA1023", title: "Dubbel vagina" },
  { value: "KA1024", title: "Urinpooling" },
  { value: "KA1025", title: "Annan missbildning, vagina" },
  { value: "KA103", title: "Hermafroditism, hondjur" },
  { value: "KA1031", title: "Pseudohermafroditism" },
  { value: "KA104", title: "Inverterad spene" },
  { value: "KA1041", title: "Övertaliga spenar" },
  { value: "KA1042", title: "Högt membran, spene/ar" },
  { value: "KA1049", title: "Övriga spenmissbildningar" },
  { value: "KA105", title: "Cervixmissbildningar" },
  { value: "KA1052", title: "Högt membran, spene/ar" },
  { value: "KA1053", title: "Lågt membran" },
  { value: "KA1054", title: "Outvecklad spenkanal" },
  { value: "KA1059", title: "Övriga spenmissbildningar" },
  { value: "KA106", title: "Kromosomdefekter" },
  { value: "KA1061", title: "Translokation (1/29)" },
  { value: "KA1062", title: "Freemartinism" },
  { value: "KA1071", title: "White heifer disease" },
  { value: "KA108", title: "Högt membran, spene/ar" },
  { value: "KA109", title: "Missbildning, uterus" },
  { value: "KA11", title: "Utvecklingsrubbn, tillväxtrubbn, könsorganhondjur" },
  { value: "KA111", title: "Persisterande hymen" },
  { value: "KA19", title: "Övriga utvecklingsrubbningar könsorgan hondjur" },
  {
    value: "KA2",
    title: "Metabol, nutrit, degen/dystrof förändr, könsorgan hondjur",
  },
  { value: "KA20", title: "Metaboliska förändringar, könsorgan hondjur" },
  { value: "KA201", title: "Hydroallantois" },
  { value: "KA21", title: "Nutritionella förändringar, könsorgan hondjur" },
  {
    value: "KA22",
    title: "Degenerativa/dystrofiska förändringar, könsorgan hondjur",
  },
  { value: "KA23", title: "Hormonella förändringar, könsorgan hondjur" },
  { value: "KA231", title: "Äggstockscysta" },
  { value: "KA2311", title: "Follikelcysta" },
  { value: "KA2313", title: "Cystisk gulkropp" },
  { value: "KA232", title: "Cystisk endometriehyperplasi" },
  { value: "KA233", title: "Vaginalprolaps / vaginalödem" },
  { value: "KA2331", title: "Vaginal hyperplasi" },
  { value: "KA234", title: "Mjölkstockning" },
  { value: "KA235", title: "Agalakti" },
  { value: "KA236", title: "Juverhyperplasi" },
  { value: "KA2361", title: "Cystisk juverhyperplasi" },
  { value: "KA3", title: "Cirkulatoriska förändringar, könsorgan hondjur" },
  { value: "KA30", title: "Blödningar, könsorgan hondjur" },
  { value: "KA301", title: "Vaginal blödning" },
  { value: "KA302", title: "Blödning som komplikation till kir ingrepp" },
  { value: "KA303", title: "Blödning, äggstock" },
  { value: "KA31", title: "Ödem, könsorgan hondjur" },
  { value: "KA32", title: "Infarkter, könsorgan hondjur" },
  { value: "KA33", title: "Hygrom, könsorgan hondjur" },
  { value: "KA34", title: "Trombos / emboli, könsorgan hondjur" },
  {
    value: "KA35",
    title: "Arterio-atero-skleros förändringar, könsorgan hondjur",
  },
  { value: "KA4", title: "Infekt, infl förändringar, könsorgan hondjur" },
  { value: "KA40", title: "Specifika infektionssjukdomar, könsorgan hondjur" },
  { value: "KA401", title: "Akut mastit med Staf.aureus" },
  { value: "KA4011", title: "Akut mastit med Staf.aureus, gangrenös form" },
  { value: "KA4012", title: "Akut mastit med koagulasneg.stafylokocker" },
  { value: "KA402", title: "Akut mastit med Strept.dysgalactie" },
  { value: "KA4021", title: "Akut mastit med Strept.uberis" },
  { value: "KA4022", title: "Akut mastit med Strept.agalactie" },
  { value: "KA403", title: "Akut mastit med E.coli" },
  { value: "KA404", title: "Akut mastit med Pseudomonas" },
  { value: "KA405", title: "Akut mastit med A.pyogenes" },
  {
    value: "KA406",
    title: "Akut mastit med Klebsiella (K.pneumoni,K.oxytoca)",
  },
  { value: "KA407", title: "Akut mastit med Pasteurella" },
  {
    value: "KA4071",
    title: "Akut mastit med P.Hemolytica, perakut gangränös form",
  },
  {
    value: "KA408",
    title: "Akut mastit med Jästsvampinf. (Candida,Trichosporum)",
  },
  { value: "KA409", title: "Mykotisk endometrit" },
  { value: "KA41", title: "Akuta inflammationstillstånd, könsorgan hondjur" },
  {
    value: "KA411",
    title: "Serös / serofibrinös inflammation, könsorgan hondjur",
  },
  { value: "KA412", title: "Akut purulent inflammation, könsorgan hondjur" },
  { value: "KA4121", title: "Pyometra" },
  {
    value: "KA413",
    title: "Specifika akuta infl tillstånd, könsorgan hondjur",
  },
  { value: "KA419", title: "Övriga akuta infl tillstånd, könsorgan hondjur" },
  { value: "KA4191", title: "Akut endometrit / metrit" },
  { value: "KA41911", title: "Akut puerp.metrit" },
  { value: "KA4192", title: "Mukometra" },
  { value: "KA4193", title: "Fusometra" },
  { value: "KA4194", title: "Vaginit" },
  { value: "KA41941", title: "Juvenil vaginit" },
  { value: "KA41942", title: "Nekrotiserande vaginit" },
  { value: "KA41943", title: "Vaginit, vuxet djur" },
  { value: "KA4195", title: "Akut mastit" },
  { value: "KA41951", title: "Kvig-/betesmastit" },
  { value: "KA41952", title: "Nekrotiserande mastit (juverbrand)" },
  { value: "KA41953", title: "Juverinflammation- blodförgiftning/förgiftning" },
  { value: "KA4196", title: "Akut thelit (speninfl.)" },
  {
    value: "KA4197",
    title: "Akut oophorit/salphingit (äggstock-/äggledarinfl.)",
  },
  {
    value: "KA42",
    title: "Kroniska inflammationstillstånd, könsorgan hondjur",
  },
  { value: "KA421", title: "Kronisk serös inflammation, könsorgan hondjur" },
  { value: "KA422", title: "Purulent inflammation, könsorgan hondjur" },
  { value: "KA423", title: "Abscess/fistel, könsorgan hondjur" },
  { value: "KA4231", title: "Cervixabscess" },
  { value: "KA4232", title: "Abscess vid ligatur vid overiehysterektomi" },
  { value: "KA424", title: "Specifika kroniska infl tillstånd" },
  { value: "KA4241", title: "Kronisk mastit med Staf.aureus" },
  { value: "KA42411", title: "Kronisk mastit med Staf.aureus, gangrenös form" },
  { value: "KA42412", title: "Kronisk mastit med koagulasneg.stafylokocker" },
  { value: "KA42413", title: "Juverböld" },
  { value: "KA4242", title: "Kronisk mastit med Strept.dysgalactie" },
  { value: "KA42421", title: "Kronisk mastit med Strept.uberis" },
  { value: "KA42422", title: "Kronisk mastit med Strept.agalactie" },
  { value: "KA4243", title: "Kronisk mastit med E.coli" },
  { value: "KA4244", title: "Kronisk mastit med Pseudomonas" },
  { value: "KA4245", title: "Kronisk mastit med A.pyogenes" },
  {
    value: "KA4246",
    title: "Kronisk mastit med Klebsiella (K.pneumoni,K.oxytoca)",
  },
  { value: "KA4247", title: "Kronisk mastit med Pasteurella" },
  {
    value: "KA42471",
    title: "Kronisk mastit med P.Hemolytica, perakut gangränös form",
  },
  {
    value: "KA4248",
    title: "Kronisk mastit med Jästsvampinf. (Candida,Trichosporum)",
  },
  { value: "KA429", title: "Övriga kroniska infl tillstånd" },
  { value: "KA4291", title: "Kronisk endometrit" },
  { value: "KA4293", title: "Juveraktinos (svin)" },
  { value: "KA4294", title: "Kronisk subklinisk mastit och höga celltal" },
  { value: "KA4295", title: "Kroniska mastiter" },
  { value: "KA4296", title: "Kronisk thelit (speninfl.)" },
  {
    value: "KA4297",
    title: "Kronisk oophorit/salphingit (äggstocks-/äggledarinfl.)",
  },
  { value: "KA4298", title: "Äggperitonit/ägguleperitonit" },
  { value: "KA43", title: "Parasitära sjukdomar, könsorgan hondjur" },
  { value: "KA44", title: "Inflammation, vulva" },
  { value: "KA45", title: "Inflammation, cervix" },
  { value: "KA46", title: "Inflammation, spenar" },
  { value: "KA47", title: "Spenvårtor" },
  { value: "KA49", title: "Könssjukdom, hondjur" },
  { value: "KA5", title: "Immunmedierade tillstånd, könsorgan hondjur" },
  { value: "KA6", title: "Neoplastiska förändringar, könsorgan hondjur" },
  { value: "KA61", title: "Tumör i äggstock" },
  { value: "KA611", title: "Granulosacellstumör" },
  { value: "KA62", title: "Tumör i livmoder" },
  { value: "KA63", title: "Tumör i cervix" },
  { value: "KA64", title: "Tumör i vulva / vagina" },
  { value: "KA641", title: "Stjälkat fibrom i vulva / vagina" },
  { value: "KA642", title: "Leiomyom i vulva / vagina" },
  { value: "KA643", title: "Nybildning, vulva" },
  { value: "KA644", title: "Nybildning, vagina" },
  { value: "KA65", title: "Tumör i juver" },
  { value: "KA66", title: "Tumör i spene" },
  {
    value: "KA7",
    title: "Trauma, fr kr, lägeförändr, term skad, könsorgan hondjur",
  },
  { value: "KA70", title: "Skada förlossningsvägar" },
  {
    value: "KA71",
    title: "Traumatiska skador / mekaniska skador, könsorgan hondjur",
  },
  { value: "KA711", title: "Uterusruptur" },
  { value: "KA712", title: "Vaginal perforation" },
  { value: "KA713", title: "Ligamentruptur juver" },
  { value: "KA714", title: "Spenskador" },
  { value: "KA7141", title: "Spenskada utan mastit" },
  { value: "KA7142", title: "Spenskada med mastit" },
  { value: "KA7143", title: "Spenförträngning" },
  { value: "KA7144", title: "Spenfistel (efter suturering)" },
  { value: "KA7145", title: "Spensår/bitsår på spenarna" },
  { value: "KA715", title: "Juversår (rivsår,skärsår,krossår)" },
  { value: "KA716", title: "Ljumsksår" },
  { value: "KA717", title: "Skador till följd av inseminering" },
  { value: "KA718", title: "Sårskada, vulva" },
  { value: "KA7181", title: "Sårskada- förlossningsbristning, vulva" },
  { value: "KA7182", title: "Sårskada- förlossningsbristning, kloakbildning" },
  { value: "KA719", title: "Sårskada, cervix" },
  { value: "KA7191", title: "Ärrstriktur, cervix" },
  { value: "KA72", title: "Främmande kroppar, könsorgan hondjur" },
  { value: "KA73", title: "Lägesförändringar, könsorgan hondjur" },
  { value: "KA731", title: "Livmoderomvridning" },
  { value: "KA732", title: "Livmoderprolaps" },
  { value: "KA74", title: "Termiska skador, könsorgan hondjur" },
  { value: "KA741", title: "Brännskada, könsorgan hondjur" },
  { value: "KA742", title: "Köldskada, könsorgan hondjur" },
  { value: "KA8", title: "Toxiska förändringar, könsorgan hondjur" },
  { value: "KA9", title: "Idiopatiska, ospecif, multifakt, könsorgan hondjur" },
  { value: "KA90", title: "Reproduktionsstörning" },
  { value: "KA91", title: "Fosterdöd / kastning" },
  { value: "KA911", title: "Värpnöd" },
  { value: "KA912", title: "Stenfoster" },
  { value: "KA913", title: "Tidig fosterdöd/resorption" },
  { value: "KA92", title: "Förlossningskomplikation / dystoki" },
  { value: "KA921", title: "Primär värksvaghet" },
  { value: "KA922", title: "Sekundär värksvaghet" },
  { value: "KA923", title: "Felläge av foster" },
  { value: "KA9231", title: "Åt sidan slaget huvud" },
  { value: "KA9232", title: "Underslaget ben" },
  { value: "KA9233", title: "Stussbjudning" },
  { value: "KA9234", title: "Övriga fellägen" },
  { value: "KA924", title: "Stort foster" },
  { value: "KA925", title: "Trånga fostervägar" },
  { value: "KA926", title: "Primär bristande cervixdilatation (får,ringwomb)" },
  { value: "KA927", title: "Sekundär bristande cervixdilatation" },
  { value: "KA929", title: "Hydrometra" },
  { value: "KA93", title: "Kvarbliven efterbörd" },
  { value: "KA94", title: "Subinvolution" },
  { value: "KA95", title: "Nedsatt fertilitet hondjur" },
  { value: "KA96", title: "MMA (Agalacti,grisningsfeber, svin)" },
  { value: "KA97", title: "Äggledar-/kloakprolaps" },
  { value: "KA98", title: "Juverödem" },
  { value: "KA99", title: "Nedsatt slutningsfunktion, cervix" },
  { value: "KB", title: "Handjur" },
  {
    value: "KB0",
    title: "Normalvar, symtom u fastställd orsak, könsorgan handjur",
  },
  { value: "KB00", title: "Normalvariation, könsorgan handjur" },
  { value: "KB001", title: "Hypersexualitet (satyriasis)" },
  { value: "KB002", title: "Svag könsdrift" },
  { value: "KB004", title: "Normala könsorgan, handjur" },
  { value: "KB0041", title: "Normala testiklar" },
  { value: "KB0042", title: "Normal penis" },
  {
    value: "KB01",
    title: "Symtom på sjukdom u fastställd orsak, könsorgan handjur",
  },
  { value: "KB011", title: "Impotens" },
  { value: "KB012", title: "Ingen ejakulation" },
  { value: "KB013", title: "Sterilitet" },
  { value: "KB0131", title: "Nedsatt fertilitet, handjur" },
  { value: "KB014", title: "Betäckningsoförmåga" },
  {
    value: "KB1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, könsorgan handjur",
  },
  { value: "KB10", title: "Missbildning, könsorgan handjur" },
  { value: "KB101", title: "Hermafroditism, handjur" },
  { value: "KB102", title: "Kryptorkism / monorkism" },
  { value: "KB1021", title: "Abdominell kryptorkism" },
  { value: "KB1022", title: "Inguinal kryptorkism" },
  { value: "KB103", title: "Parafimos" },
  { value: "KB104", title: "Fimos" },
  { value: "KB105", title: "Persisterande membran över glans penis" },
  { value: "KB106", title: "Spermiostas" },
  {
    value: "KB107",
    title: "Avsaknad av S-formig krök (svin,oförmåga att skafta ut)",
  },
  {
    value: "KB11",
    title: "Utvecklingsrubbn, tillväxtrubbn, könsorgan handjur",
  },
  { value: "KB111", title: "Testikelhypoplasi/aplasi" },
  { value: "KB112", title: "Kort preputium" },
  { value: "KB113", title: "Olikstora testiklar" },
  { value: "KB114", title: "Utvecklingsrubbningar, testiklar" },
  { value: "KB13", title: "Utvecklingsrubbning, penis" },
  {
    value: "KB2",
    title: "Metabol, nutrit, degen/dystrof förändr, könsorgan handjur",
  },
  { value: "KB20", title: "Metaboliska förändringar, könsorgan handjur" },
  { value: "KB21", title: "Nutritionella förändringar, könsorgan handjur" },
  {
    value: "KB22",
    title: "Degenerativa/dystrofiska förändringar, könsorgan handjur",
  },
  { value: "KB221", title: "Testikelatrofi" },
  { value: "KB222", title: "Testikeldegeneration" },
  { value: "KB23", title: "Hormonella förändringar, handjur" },
  { value: "KB231", title: "Prostatahypertrofi / prostatahyperplasi" },
  { value: "KB232", title: "Feminiseringssyndromet" },
  { value: "KB3", title: "Cirkulatoriska förändringar, könsorgan handjur" },
  { value: "KB30", title: "Blödningar, könsorgan handjur" },
  { value: "KB301", title: "Blödning från / i  penis" },
  { value: "KB302", title: "Hemospermi" },
  { value: "KB31", title: "Ödem, könsorgan handjur" },
  { value: "KB311", title: "Penisödem" },
  { value: "KB32", title: "Infarkter, könsorgan handjur" },
  { value: "KB33", title: "Hygrom, könsorgan handjur" },
  { value: "KB34", title: "Trombos / emboli, könsorgan handjur" },
  {
    value: "KB35",
    title: "Arterio-atero-skleros förändringar, könsorgan handjur",
  },
  { value: "KB4", title: "Infekt, infl förändringar, könsorgan handjur" },
  { value: "KB40", title: "Specifika infektionssjukdomar, könsorgan handjur" },
  { value: "KB41", title: "Akuta inflammationstillstånd, könsorgan handjur" },
  {
    value: "KB411",
    title: "Serös / serofibrinös inflammation, könsorgan handjur",
  },
  { value: "KB412", title: "Akut purulent inflammation, könsorgan handjur" },
  {
    value: "KB413",
    title: "Specifika akuta infl tillstånd, könsorgan handjur",
  },
  { value: "KB419", title: "Övriga akuta infl tillstånd, könsorgan handjur" },
  { value: "KB4191", title: "Akut testikelinflammation" },
  { value: "KB4192", title: "Akut  bitestikelinflammation" },
  { value: "KB4193", title: "Funikulit" },
  { value: "KB4194", title: "Akut prostatit" },
  { value: "KB4195", title: "Akut sädesblåseinflammation (seminovesikulit)" },
  {
    value: "KB4196",
    title: "Akut  inflammation i penis och preputium (Balanopostit)",
  },
  { value: "KB4197", title: "Testikeleksem (skrotal dermatit)" },
  { value: "KB4198", title: "Postitis (penisinflammation), akut" },
  { value: "KB4199", title: "Akut inflammation i bulbouretralkörtel" },
  {
    value: "KB42",
    title: "Kroniska inflammationstillstånd, könsorgan handjur",
  },
  { value: "KB421", title: "Kronisk serös inflammation, könsorgan handjur" },
  { value: "KB422", title: "Purulent inflammation, könsorgan handjur" },
  { value: "KB4221", title: "Förhudsinflammation" },
  { value: "KB423", title: "Abscess/fistel" },
  { value: "KB4231", title: "Prostataabscess" },
  {
    value: "KB424",
    title: "Specifika kroniska infl tillstånd, könsorgan handjur",
  },
  {
    value: "KB429",
    title: "Övriga kroniska infl tillstånd, könsorgan handjur",
  },
  { value: "KB4291", title: "Kronisk testikelinflammation" },
  { value: "KB4292", title: "Kronisk  bitestikelinflammation" },
  { value: "KB4293", title: "Kronisk funikulit" },
  { value: "KB4294", title: "Kronisk prostatit" },
  {
    value: "KB4295",
    title: "Kronisk sädesblåseinflammation (seminovesikulit)",
  },
  { value: "KB4296", title: "Postitis (penisinflammation)" },
  { value: "KB4298", title: "Penispapillom" },
  { value: "KB42991", title: "Sädesblåseinflammation (seminovesiculit)" },
  { value: "KB42992", title: "Inflammation i bulbouretralkörtel" },
  { value: "KB43", title: "Parasitära sjukdomar, könsorgan handjur" },
  { value: "KB49", title: "Könsjukdom, handjur" },
  { value: "KB5", title: "Immunmedierade tillstånd, könsorgan handjur" },
  { value: "KB6", title: "Neoplastiska förändringar, könsorgan handjur" },
  { value: "KB61", title: "Tumör i testikel" },
  { value: "KB611", title: "Sertoli cellstumör" },
  { value: "KB612", title: "Leydig cellstumör" },
  { value: "KB62", title: "Tumör i prostata" },
  { value: "KB621", title: "Nybildning, prostata" },
  { value: "KB622", title: "Nybildning, sädesblåsor" },
  { value: "KB623", title: "Nybildning, bulbouretralkörtel" },
  { value: "KB63", title: "Nybildning, penis" },
  { value: "KB65", title: "Nybildning, förhud" },
  {
    value: "KB7",
    title: "Trauma, fr kr, lägeförändr, term skad, könsorgan handjur",
  },
  { value: "KB71", title: "Traumatiska / mekaniska skador, könsorgan handjur" },
  { value: "KB711", title: "Penisbenfraktur" },
  { value: "KB712", title: "Penisframfall" },
  { value: "KB713", title: "Penisfraktur, nöt (bakom s-krök), häst" },
  { value: "KB72", title: "Främmande kroppar,könsorgan handjur" },
  { value: "KB721", title: "Främmande kropp, penis/förhud" },
  { value: "KB73", title: "Lägesförändringar, könsorgan handjur" },
  { value: "KB731", title: "Testikelomvridning" },
  { value: "KB732", title: "Bitestikelomvridning" },
  { value: "KB74", title: "Termiska skador, könsorgan handjur" },
  { value: "KB741", title: "Brännskada, könsorgan handjur" },
  { value: "KB742", title: "Köldskada, könsorgan handjur" },
  { value: "KB75", title: "Sårskada, testiklar" },
  { value: "KB76", title: "Sårskada, penis/förhud" },
  { value: "KB8", title: "Toxiska förändringar, könsorgan handjur" },
  { value: "KB9", title: "Idiopatiska, ospecif, multifakt, könsorgan handjur" },
  { value: "KB91", title: "Prostatacysta" },
  { value: "KB92", title: "Funktionell bitestikelrubbning" },
  { value: "KB981", title: "Urospermi" },
  { value: "KC", title: "Pediatrik" },
  { value: "KC0", title: "Normalvar, symtom u fastställd orsak, pediatrik" },
  { value: "KC00", title: "Normalvariation, pediatrik" },
  { value: "KC01", title: "Symtom på sjukdom u fastställd orsak, pediatrik" },
  { value: "KC011", title: "Simmare  (swimmers)" },
  { value: "KC0111", title: "Simmare  (swimmers)" },
  { value: "KC0112", title: "Fläkgris" },
  { value: "KC012", title: "Fading puppies / kittens" },
  { value: "KC013", title: "Toxic milk syndrome" },
  { value: "KC014", title: "För tidigt födda / svaga" },
  { value: "KC0141", title: "Prematur/för tidigt född" },
  { value: "KC0142", title: "Svaga ungar" },
  { value: "KC015", title: "Döda ungar" },
  { value: "KC016", title: "Skakgris" },
  {
    value: "KC1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, pediatrik",
  },
  { value: "KC10", title: "Missbildning, pediatrik" },
  { value: "KC11", title: "Utvecklingsrubbn, tillväxtrubbn, pediatrik" },
  { value: "KC111", title: "Ruminal drinker" },
  { value: "KC112", title: "Klumpfot" },
  { value: "KC2", title: "Metabol, nutrit, degen/dys förändringar, pediatrik" },
  { value: "KC20", title: "Metaboliska förändringar, pediatrik" },
  { value: "KC21", title: "Nutritionella förändringar, pediatrik" },
  { value: "KC211", title: "Hypoglykemi hos nyfödda" },
  { value: "KC22", title: "Degenerativa/dystrofiska förändringar, pediatrik" },
  { value: "KC23", title: "Hormonella förändringar, pediatrik" },
  { value: "KC231", title: "Diarré/fölbrunstdiarré" },
  { value: "KC3", title: "Cirkulatoriska förändringar, pediatrik" },
  { value: "KC30", title: "Blödningar, pediatrik" },
  { value: "KC301", title: "Navelblödning" },
  { value: "KC31", title: "Ödem, pediatrik" },
  { value: "KC32", title: "Infarkter, pediatrik" },
  { value: "KC33", title: "Hygrom, pediatrik" },
  { value: "KC34", title: "Trombos / emboli, pediatrik" },
  { value: "KC35", title: "Arterio-atero-skleros förändringar, pediatrik" },
  { value: "KC4", title: "Infekt, infl förändringar, pediatrik" },
  { value: "KC40", title: "Specifika infektionssjukdomar, pediatrik" },
  { value: "KC401", title: "Svartfoster" },
  { value: "KC41", title: "Akuta inflammationstillstånd, pediatrik" },
  { value: "KC411", title: "Serös /serofibrinös inflammation, pediatrik" },
  { value: "KC412", title: "Akut purulent inflammation, pediatrik" },
  { value: "KC413", title: "Specifika akuta infl tillstånd, pediatrik" },
  { value: "KC419", title: "Övriga akuta infl tillstånd, pediatrik" },
  { value: "KC42", title: "Kroniska inflammationstillstånd, pediatrik" },
  { value: "KC421", title: "Kronisk serös inflammation, pediatrikv" },
  { value: "KC422", title: "Purulent inflammation, pediatrik" },
  { value: "KC423", title: "Abscess/fistel, pediatrik" },
  { value: "KC424", title: "Specifika kroniska infl tillstånd, pediatrik" },
  { value: "KC429", title: "Övriga kroniska infl tillstånd, pediatrik" },
  { value: "KC43", title: "Parasitära sjukdomar, pediatrik" },
  { value: "KC44", title: "Diarre" },
  { value: "KC45", title: "Förstoppning" },
  { value: "KC46", title: "Navelinfektion" },
  { value: "KC5", title: "Immunmedierade tillstånd, pediatrik" },
  { value: "KC6", title: "Neoplastiska förändringar, pediatrik" },
  { value: "KC7", title: "Trauma, fr kr, lägeförändr, term skad, pediatrik" },
  { value: "KC71", title: "Traumatiska / mekaniska skador, pediatrik" },
  { value: "KC72", title: "Främmande kroppar, pediatrik" },
  { value: "KC73", title: "Lägesförändringar, pediatrik" },
  { value: "KC74", title: "Termiska skador, pediatrik" },
  { value: "KC741", title: "Brännskada, pediatrik" },
  { value: "KC742", title: "Köldskada, pediatrik" },
  { value: "KC8", title: "Toxiska förändringar, pediatrik" },
  { value: "KC9", title: "Idiopatiska, ospecif, multifakt, pediatrik" },
  { value: "KC91", title: "Hypotermi,nyfödda" },
  { value: "KC92", title: "Neonatal maladjustment syndrome" },
  { value: "L", title: "Ledernas och ligamentens sjukdomar" },
  { value: "LA", title: "Käkleder" },
  { value: "LA0", title: "Normalvar, symtom u fastställd orsak, käkled" },
  { value: "LA00", title: "Normalvariation, käkled" },
  { value: "LA01", title: "Symtom på sjukdom u fastställd orsak" },
  { value: "LA011", title: "Smärta från käkled" },
  { value: "LA0111", title: "Smärta från käkled vid palpation" },
  { value: "LA0112", title: "Smärta från käkled vid böjn/sträckn av led" },
  { value: "LA012", title: "Ansvällning av käkled UNS" },
  { value: "LA013", title: "Ökad mängd ledvätska (galla) UNS" },
  { value: "LA014", title: "Stelhet i käkled" },
  { value: "LA0141", title: "Ankylos i käklederna" },
  { value: "LA0142", title: "Kontraktur i käkled (ej muskelkontraktur)" },
  { value: "LA016", title: "Ökad värme i käkled UNS" },
  { value: "LA017", title: "Ökad rörlighet i käkled UNS" },
  { value: "LA018", title: "Krepitation från käkled UNS" },
  { value: "LA1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, käkled" },
  { value: "LA10", title: "Missbildning, käkled" },
  { value: "LA101", title: "Medfödd luxation av käkled" },
  { value: "LA1011", title: "Permanent medfödd luxation av käkled" },
  { value: "LA1012", title: "Habituell medfödd luxation av käkled" },
  { value: "LA102", title: "Annan medfödd felställning av käkled" },
  { value: "LA11", title: "Utvecklingsrubbn, tillväxtrubbn, käkled" },
  { value: "LA111", title: "Dysplasi av käkled" },
  { value: "LA119", title: "Övrig utvecklingsrubbning i käkled" },
  { value: "LA2", title: "Metabol, nutrit, degen/dystrof förändr, käkled" },
  { value: "LA20", title: "Metaboliska förändringar, käkled" },
  { value: "LA21", title: "Nutritionella förändringar, käkled" },
  { value: "LA211", title: "Calcinosis circumscripta, käkled" },
  { value: "LA22", title: "Degenerativa/dystrofiska förändringar, käkled" },
  { value: "LA221", title: "Degeneration av ledbrosk i käken" },
  { value: "LA2211", title: "Kroniskt deformerande artros i käkleden" },
  { value: "LA222", title: "Degeneration av ligament som berör käkleden" },
  { value: "LA223", title: "Synovial kondrometaplasi i käkleden" },
  { value: "LA224", title: "Lös benbit, käkled (Ledmus) (ej sek t OCD)" },
  { value: "LA23", title: "Hormonella förändringar, käkled" },
  { value: "LA3", title: "Cirkulatoriska förändringar, käkled" },
  { value: "LA30", title: "Blödningar, käkled" },
  { value: "LA31", title: "Ödem, käkled" },
  { value: "LA32", title: "Infarkter, käkled" },
  { value: "LA33", title: "Hygrom, käkled" },
  { value: "LA34", title: "Trombos / emboli, käkled" },
  { value: "LA35", title: "Arterio-atero-skleros förändringar, käkled" },
  { value: "LA4", title: "Infekt, infl förändringar, käkled" },
  { value: "LA40", title: "Specifika infektionssjukdomar, käkled" },
  { value: "LA41", title: "Akuta inflammationstillstånd, käkled" },
  { value: "LA411", title: "Serös / serofibrinös artrit i käkleden" },
  { value: "LA412", title: "Purulent artrit i käkleden" },
  { value: "LA413", title: "Specifika akuta infl tillstånd" },
  { value: "LA419", title: "Övriga akuta infl tillstånd" },
  { value: "LA42", title: "Kroniska inflammationstillstånd i käkleden" },
  { value: "LA421", title: "Kroniska serös artrit i käkleden" },
  { value: "LA422", title: "Kroniska purulent artrit i käkleden" },
  { value: "LA423", title: "Kroniska abscess/fistel i käkleden" },
  { value: "LA424", title: "Specifika kroniska infl tillstånd i käkleden" },
  { value: "LA429", title: "Övriga kroniska infl tillstånd i käkleden" },
  { value: "LA43", title: "Parasitära sjukdomar, käkled" },
  { value: "LA5", title: "Immunmedierade tillstånd, käkled" },
  { value: "LA51", title: "Reumatoid artrit i käkleden" },
  { value: "LA6", title: "Neoplastiska förändringar, käkled" },
  { value: "LA61", title: "Malign tumör i käkleden" },
  { value: "LA611", title: "Synovialt sarkom i käkleden" },
  { value: "LA612", title: "Kondrosarkom i käkleden" },
  { value: "LA62", title: "Benign tumör i käkleden" },
  { value: "LA621", title: "Synoviom i käkleden" },
  { value: "LA622", title: "Kondrom i käkleden" },
  { value: "LA69", title: "Tumör i käkleden UNS" },
  { value: "LA7", title: "Trauma, fr kr, lägeförändr, term skad, käkled" },
  { value: "LA71", title: "Traumatiska / mekaniska skador, käkled" },
  { value: "LA711", title: "Distorsion i käkleden" },
  { value: "LA712", title: "Ligamentruptur i käkleden" },
  { value: "LA714", title: "Sår öppen ledkapsel i käkleden" },
  { value: "LA72", title: "Främmande kroppar, käkled" },
  { value: "LA73", title: "Lägesförändringar, käkled" },
  { value: "LA731", title: "Luxation i käkleden" },
  { value: "LA7311", title: "Subluxation av  käkleden" },
  { value: "LA7312", title: "Total luxation av käkleden" },
  { value: "LA74", title: "Termiska skador, käkled" },
  { value: "LA741", title: "Brännskada, käkled" },
  { value: "LA742", title: "Köldskada, käkled" },
  { value: "LA8", title: "Toxiska förändringar, käkled" },
  { value: "LA9", title: "Idiopatiska ospecificerade multifaktoriella" },
  { value: "LB", title: "Kotpelarens leder" },
  {
    value: "LB0",
    title: "Normalvar, symtom u fastställd orsak, kotpelarens led",
  },
  { value: "LB00", title: "Normalvariation, kotpelarens led" },
  {
    value: "LB01",
    title: "Symtom på sjukdom u fastställd orsak , kotpelarens led",
  },
  { value: "LB011", title: "Smärta från kotpelarens led" },
  { value: "LB0111", title: "Smärta från kotpelarens leder vid palpation" },
  {
    value: "LB0112",
    title: "Smärta från kotpelarens leder vid böjn / sträckn av rygg",
  },
  { value: "LB012", title: "Ansvällning av kotpelarens led UNS" },
  {
    value: "LB013",
    title: "Ökad mängd ledvätska (galla) UNS, kotpelarens led",
  },
  { value: "LB014", title: "Stelhet i kotpelarens leder" },
  { value: "LB0141", title: "Ankylos kotpelarens leder" },
  {
    value: "LB0142",
    title: "Kontraktur i led (ej muskelkontraktur) kotpelarens leder",
  },
  { value: "LB016", title: "Ökad värme i kotpelarens leder UNS" },
  { value: "LB017", title: "Ökad rörlighet i kotpelarens leder UNS" },
  { value: "LB018", title: "Krepitation från kotpelarens leder UNS" },
  {
    value: "LB1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, kotpelarens led",
  },
  { value: "LB10", title: "Missbildning, kotpelarens led" },
  { value: "LB101", title: "Medfödd luxation av kotpelarens leder" },
  { value: "LB1011", title: "Permanent medfödd luxation av kotpelarens leder" },
  { value: "LB1012", title: "Habituell medfödd luxation av kotpelarens leder" },
  { value: "LB102", title: "Annan medfödd felställning av kotpelarens leder" },
  { value: "LB11", title: "Utvecklingsrubbn, tillväxtrubbn, kotpelarens led" },
  { value: "LB111", title: "Dysplasi av kotpelarens leder" },
  { value: "LB119", title: "Övrig utvecklingsrubbning i kotpelarens leder" },
  {
    value: "LB2",
    title: "Metabol, nutrit, degen/dystrof förändr, kotpelarens led",
  },
  { value: "LB20", title: "Metaboliska förändringar, kotpelarens led" },
  { value: "LB21", title: "Nutritionella förändringar, kotpelarens led" },
  { value: "LB211", title: "Calcinosis circumscripta i kotpelarens leder" },
  {
    value: "LB22",
    title: "Degenerativa/dystrofiska förändringar, kotpelarens led",
  },
  { value: "LB221", title: "Degeneration av ledbrosk i kotpelarens leder" },
  {
    value: "LB2211",
    title: "Kroniskt deformerande artros i kotpelarens leder",
  },
  {
    value: "LB222",
    title: "Degeneration av ligament som berör kotpelarens leder",
  },
  { value: "LB223", title: "Synovial kondrometaplasi i kotpelarens leder" },
  {
    value: "LB224",
    title: "Lös benbit kotpelarens led (Ledmus) (ej sek t OCD)",
  },
  { value: "LB23", title: "Hormonella förändringar, kotpelarens led" },
  { value: "LB3", title: "Cirkulatoriska förändringar, kotpelarens led" },
  { value: "LB30", title: "Blödningar, kotpelarens led" },
  { value: "LB31", title: "Ödem, kotpelarens led" },
  { value: "LB32", title: "Infarkter, kotpelarens led" },
  { value: "LB33", title: "Hygrom, kotpelarens led" },
  { value: "LB34", title: "Trombos / emboli, kotpelarens led" },
  {
    value: "LB35",
    title: "Arterio-atero-skleros förändringar, kotpelarens led",
  },
  { value: "LB4", title: "Infekt, infl förändringar, kotpelarens led" },
  { value: "LB40", title: "Specifika infektionssjukdomar, kotpelarens led" },
  { value: "LB41", title: "Akuta inflammationstillstånd, kotpelarens led" },
  { value: "LB411", title: "Serös / serofibrinös artrit i kotpelarens leder" },
  { value: "LB412", title: "Purulent artrit i kotpelarens leder" },
  {
    value: "LB413",
    title: "Specifika akuta infl tillstånd i kotpelarens leder",
  },
  { value: "LB419", title: "Övriga akuta infl tillstånd i kotpelarens leder" },
  { value: "LB42", title: "Kroniska inflammationstillstånd, kotpelarens led" },
  { value: "LB421", title: "Kroniska serös artrit i kotpelarens leder" },
  { value: "LB422", title: "Kroniska purulent artrit i kotpelarens leder" },
  {
    value: "LB423",
    title: "Kroniska abscess/fistel artrit i kotpelarens leder",
  },
  {
    value: "LB424",
    title: "Specifika kroniska infl tillstånd i kotpelarens leder",
  },
  {
    value: "LB429",
    title: "Övriga kroniska infl tillstånd i kotpelarens leder",
  },
  { value: "LB43", title: "Parasitära sjukdomar, kotpelarens led" },
  { value: "LB5", title: "Immunmedierade tillstånd, kotpelarens led" },
  { value: "LB51", title: "Reumatoid artrit i kotpelarens leder" },
  { value: "LB6", title: "Neoplastiska förändringar, kotpelarens led" },
  { value: "LB61", title: "Malign tumör i kotpelarens leder" },
  { value: "LB611", title: "Synovialt sarkom i kotpelarens leder" },
  { value: "LB612", title: "Kondrosarkom i kotpelarens leder" },
  { value: "LB62", title: "Benign tumör  i kotpelarens leder" },
  { value: "LB621", title: "Synoviom i kotpelarens leder" },
  { value: "LB622", title: "Kondrom i kotpelarens leder" },
  { value: "LB69", title: "Tumör  i kotpelarens leder UNS" },
  {
    value: "LB7",
    title: "Trauma, fr kr,  lägesförändr, term skador, kotpelarens led",
  },
  { value: "LB71", title: "Traumatiska / mekaniska skador, kotpelarens led" },
  { value: "LB711", title: "Distorsion i kotpelarens leder" },
  { value: "LB712", title: "Ligamentruptur i kotpelarens leder" },
  { value: "LB72", title: "Främmande kroppar, kotpelarens led" },
  { value: "LB73", title: "Lägesförändringar, kotpelarens led" },
  { value: "LB731", title: "Luxation i kotpelarens leder" },
  { value: "LB7311", title: "Subluxation i kotpelarens leder" },
  { value: "LB7312", title: "Total luxation  i kotpelarens leder" },
  { value: "LB74", title: "Termiska skador, kotpelarens led" },
  { value: "LB741", title: "Brännskada, kotpelarens led" },
  { value: "LB742", title: "Köldskada, kotpelarens led" },
  { value: "LB8", title: "Toxiska förändringar, kotpelarens led" },
  {
    value: "LB9",
    title: "Idiopatiska ospecificerade multifaktoriella, kotpelarens led",
  },
  { value: "LC", title: "Ileosakralleden" },
  {
    value: "LC0",
    title: "Normalvar, symtom u fastställd orsak, ileosakralleden",
  },
  { value: "LC00", title: "Normalvariation, ileosakralleden" },
  {
    value: "LC01",
    title: "Symtom på sjukdom u fastställd orsak, ileosakralleden",
  },
  { value: "LC011", title: "Smärta från ileosakralleden" },
  { value: "LC0111", title: "Smärta från ileosakralleden vid palpation" },
  {
    value: "LC0112",
    title: "Smärta från ileosakralleden vid böjn/sträckn av led",
  },
  { value: "LC012", title: "Ansvällning av ileosakralleden UNS" },
  {
    value: "LC013",
    title: "Ökad mängd ledvätska i ileosakralleden (galla) UNS",
  },
  { value: "LC014", title: "Stelhet i ileosakralleden" },
  { value: "LC0141", title: "Ankylos ileosakralleden" },
  {
    value: "LC0142",
    title: "Kontraktur i ileosakralleden (ej muskelkontraktur)",
  },
  {
    value: "LC015",
    title: "Genomtrampning eller översträckning av ileosakralleden",
  },
  { value: "LC016", title: "Ökad värme i ileosakralleden UNS" },
  { value: "LC017", title: "Ökad rörlighet i ileosakralleden UNS" },
  { value: "LC018", title: "Krepitation från ileosakralleden UNS" },
  {
    value: "LC1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, ileosakralleden",
  },
  { value: "LC10", title: "Missbildning, ileosakralleden" },
  { value: "LC101", title: "Medfödd luxation av ileosakralleden" },
  { value: "LC1011", title: "Permanent medfödd luxation av ileosakralleden" },
  { value: "LC1012", title: "Habituell medfödd luxation av ileosakralleden" },
  { value: "LC102", title: "Annan medfödd felställning av ileosakralleden" },
  { value: "LC11", title: "Utvecklingsrubbn, tillväxtrubbn ileosakralleden" },
  { value: "LC111", title: "Dysplasi av ileosakralleden" },
  { value: "LC119", title: "Övrig utvecklingsrubbning i ileosakralleden" },
  {
    value: "LC2",
    title: "Metabol, nutrit, degen/dystrof förändr, ileosakralleden",
  },
  { value: "LC20", title: "Metaboliska förändringar, ileosakralleden" },
  { value: "LC21", title: "Nutritionella förändringar, ileosakralleden" },
  { value: "LC211", title: "Calcinosis circumscripta i ileosakralleden" },
  {
    value: "LC22",
    title: "Degenerativa/dystrofiska förändringar, ileosakralleden",
  },
  { value: "LC221", title: "Degeneration av ledbrosk, ileosakralleden" },
  {
    value: "LC222",
    title: "Degeneration av ligament som berör ileosakralleden",
  },
  { value: "LC2221", title: "Kroniskt deformerande artros i ileosakralleden" },
  { value: "LC223", title: "Synovial kondrometaplasi i ileosakralleden" },
  {
    value: "LC224",
    title: "Lös benbit ileosakralleden (Ledmus) (ej sek t OCD)",
  },
  { value: "LC23", title: "Hormonella förändringar, ileosakralleden" },
  { value: "LC3", title: "Cirkulatoriska förändringar, ileosakralleden" },
  { value: "LC30", title: "Blödningar, ileosakralleden" },
  { value: "LC31", title: "Ödem, ileosakralleden" },
  { value: "LC32", title: "Infarkter, ileosakralleden" },
  { value: "LC33", title: "Hygrom, ileosakralleden" },
  { value: "LC34", title: "Trombos / emboli, ileosakralleden" },
  {
    value: "LC35",
    title: "Arterio-atero-skleros förändringar, ileosakralleden",
  },
  { value: "LC4", title: "Infekt, infl förändringar, ileosakralleden" },
  { value: "LC40", title: "Specifika infektionssjukdomar, ileosakralleden" },
  { value: "LC41", title: "Akuta inflammationstillstånd, ileosakralleden" },
  { value: "LC411", title: "Serös / serofibrinös artrit i ileosakralleden" },
  { value: "LC412", title: "Purulent artrit i ileosakralleden" },
  { value: "LC413", title: "Specifika akuta infl tillstånd i ileosakralleden" },
  { value: "LC419", title: "Övriga akuta infl tillstånd i ileosakralleden" },
  { value: "LC42", title: "Kroniska inflammationstillstånd, ileosakralleden" },
  { value: "LC421", title: "Kroniska serös artrit i ileosakralleden" },
  { value: "LC422", title: "Kroniska purulent artrit i ileosakralleden" },
  { value: "LC423", title: "Kroniska abscess/fistel artrit i ileosakralleden" },
  {
    value: "LC424",
    title: "Specifika kroniska infl tillstånd i ileosakralleden",
  },
  { value: "LC429", title: "Övriga kroniska infl tillstånd i ileosakralleden" },
  { value: "LC43", title: "Parasitära sjukdomar, ileosakralleden" },
  { value: "LC5", title: "Immunmedierade tillstånd, ileosakralleden" },
  { value: "LC51", title: "Reumatoid artrit i ileosakralleden" },
  { value: "LC6", title: "Neoplastiska förändringar, ileosakralleden" },
  { value: "LC61", title: "Malign tumör i ileosakralleden" },
  { value: "LC611", title: "Synovialt sarkom i ileosakralleden" },
  { value: "LC612", title: "Kondrosarkom i ileosakralleden" },
  { value: "LC62", title: "Benign tumör i ileosakralleden" },
  { value: "LC621", title: "Synoviom i ileosakralleden" },
  { value: "LC622", title: "Kondrom i ileosakralleden" },
  { value: "LC69", title: "Tumör i ileosakralleden UNS" },
  {
    value: "LC7",
    title: "Trauma, fr kr, lägeförändr, term skad, ileosakralleden",
  },
  { value: "LC71", title: "Traumatiska / mekaniska skador, ileosakralleden" },
  { value: "LC711", title: "Distorsion av ileosakralleden" },
  { value: "LC712", title: "Ligamentruptur av ileosakralleden" },
  { value: "LC72", title: "Främmande kroppar, ileosakralleden" },
  { value: "LC73", title: "Lägesförändringar, ileosakralleden" },
  { value: "LC731", title: "Luxation av ileosakralleden" },
  { value: "LC7311", title: "Subluxation av ileosakralleden" },
  { value: "LC7312", title: "Total luxation av  ileosakralleden" },
  { value: "LC74", title: "Termiska skador, ileosakralleden" },
  { value: "LC741", title: "Brännskada, ileosakralleden" },
  { value: "LC742", title: "Köldskada, ileosakralleden" },
  { value: "LC8", title: "Toxiska förändringar, ileosakralleden" },
  {
    value: "LC9",
    title: "Idiopatiska ospecificerade multifaktoriella, ileosakralleden",
  },
  { value: "LD", title: "Bogled" },
  { value: "LD0", title: "Normalvar, symtom u fastställd orsak,bogled" },
  { value: "LD00", title: "Normalvariation bogled" },
  { value: "LD01", title: "Symtom på sjukdom från bogled u fastställd orsak" },
  { value: "LD011", title: "Smärta från bogled" },
  { value: "LD0111", title: "Smärta från bogled vid palpation" },
  { value: "LD0112", title: "Smärta från bogled vid böjn/sträckn av led" },
  { value: "LD012", title: "Ansvällning av bogled UNS" },
  { value: "LD013", title: "Ökad mängd ledvätska (galla) UNS,bogled" },
  { value: "LD014", title: "Stelhet i bogled" },
  { value: "LD0141", title: "Ankylos i bogled" },
  { value: "LD0142", title: "Kontraktur i bogled (ej muskelkontraktur)" },
  { value: "LD015", title: "Genomtrampning eller översträckning av  bogled" },
  { value: "LD016", title: "Ökad värme  i bogled UNS" },
  { value: "LD017", title: "Ökad rörlighet  i bogled UNS" },
  { value: "LD018", title: "Krepitation från bogled UNS" },
  {
    value: "LD1",
    title: "Missbildning, utvecklingsrubbning, tillväxtrubbning,bogled",
  },
  { value: "LD10", title: "Missbildning,bogled" },
  { value: "LD101", title: "Medfödd luxation av  bogled" },
  { value: "LD1011", title: "Permanent medfödd luxation av  bogled" },
  { value: "LD1012", title: "Habituell medfödd luxation av  bogled" },
  { value: "LD102", title: "Annan medfödd felställning av  bogled" },
  { value: "LD103", title: "Medfödd ankylos av bogled" },
  { value: "LD11", title: "Utvecklingsrubbn, tillväxtrubbn,bogled" },
  { value: "LD111", title: "Dysplasi av  bogled" },
  { value: "LD119", title: "Övrig utvecklingsrubbning i bogled" },
  { value: "LD2", title: "Metabol, nutrit, degen/dystrof förändr,bogled" },
  { value: "LD20", title: "Metaboliska förändringar,bogled" },
  { value: "LD21", title: "Nutritionella förändringar,bogled" },
  { value: "LD211", title: "Calcinosis circumscripta,bogled" },
  { value: "LD22", title: "Degenerativa/dystrofiska förändringar,bogled" },
  { value: "LD221", title: "Degeneration av ledbrosk i bogled" },
  { value: "LD2211", title: "Kroniskt deformerande artros i bogled" },
  { value: "LD222", title: "Degeneration av ligament som berör bogleden" },
  { value: "LD223", title: "Synovial kondrometaplasi i bogled" },
  { value: "LD224", title: "Lös benbit (ledmus)  i bogled" },
  { value: "LD23", title: "Hormonella förändringar,bogled" },
  { value: "LD3", title: "Cirkulatoriska förändringar,bogled" },
  { value: "LD30", title: "Blödningar,bogled" },
  { value: "LD31", title: "Ödem,bogled" },
  { value: "LD32", title: "Infarkter,bogled" },
  { value: "LD33", title: "Hygrom,bogled" },
  { value: "LD34", title: "Trombos / emboli,bogled" },
  { value: "LD35", title: "Arterio-atero-skleros förändringar,bogled" },
  { value: "LD4", title: "Infekt, infl förändringar,bogled" },
  { value: "LD40", title: "Specifika infektionssjukdomar,bogled" },
  { value: "LD41", title: "Akuta inflammationstillstånd,bogled" },
  { value: "LD411", title: "Serös / serofibrinös artrit i bogled" },
  { value: "LD412", title: "Purulent artrit i bogled" },
  { value: "LD413", title: "Specifika akuta infl tillstånd i bogled" },
  { value: "LD419", title: "Övriga akuta infl tillstånd i bogled" },
  { value: "LD42", title: "Kroniska inflammationstillstånd i bogled" },
  { value: "LD421", title: "Kroniska serös artrit i bogled" },
  { value: "LD422", title: "Kroniska purulent artrit i bogled" },
  { value: "LD423", title: "Kroniska abscess/fistel artrit i bogled" },
  { value: "LD424", title: "Specifika kroniska infl tillstånd i bogled" },
  { value: "LD429", title: "Övriga kroniska infl tillstånd i bogled" },
  { value: "LD43", title: "Parasitära sjukdomar,bogled" },
  { value: "LD5", title: "Immunmedierade tillstånd,bogled" },
  { value: "LD51", title: "Reumatoid artrit i bogled" },
  { value: "LD6", title: "Neoplastiska förändringar,bogled" },
  { value: "LD61", title: "Malign tumör i bogled" },
  { value: "LD611", title: "Synovialt sarkom i bogled" },
  { value: "LD612", title: "Kondrosarkom i bogled" },
  { value: "LD62", title: "Benign tumör i led i bogled" },
  { value: "LD621", title: "Synoviom i bogled" },
  { value: "LD622", title: "Kondrom i bogled" },
  { value: "LD69", title: "Tumör i bogled UNS" },
  { value: "LD7", title: "Trauma, fr kr, lägeförändr, term skad,bogled" },
  { value: "LD71", title: "Traumatiska / Mekaniska skador i bogled" },
  { value: "LD711", title: "Vrickning (distorsion) i bogled" },
  { value: "LD712", title: "Ligamentruptur i bogled" },
  { value: "LD7121", title: "Kollateralligamentskada i bogled" },
  { value: "LD714", title: "Sår öppen ledkapsel i bogled" },
  { value: "LD72", title: "Främmande kroppar i bogled" },
  { value: "LD73", title: "Lägesförändringar i bogled" },
  { value: "LD731", title: "Luxation av bogled" },
  { value: "LD7311", title: "Subluxation av bogled" },
  { value: "LD7312", title: "Total luxation av bogled" },
  { value: "LD74", title: "Termiska skador,bogled" },
  { value: "LD741", title: "Brännskada,bogled" },
  { value: "LD742", title: "Köldskada,bogled" },
  { value: "LD8", title: "Toxiska förändringar,bogled" },
  { value: "LD9", title: "Idiopatiska ospecificerade multifaktoriella,bogled" },
  { value: "LE", title: "Armbågsled" },
  { value: "LE0", title: "Normalvar, symtom u fastställd orsak, armbågsled" },
  { value: "LE00", title: "Normalvariation, armbågsled" },
  { value: "LE01", title: "Symtom på sjukdom u fastställd orsak , armbågsled" },
  { value: "LE011", title: "Smärta från armbågsled" },
  { value: "LE0111", title: "Smärta från armbågsled vid palpation" },
  { value: "LE0112", title: "Smärta från armbågsled vid böjn/sträckn av led" },
  { value: "LE012", title: "Ansvällning av armbågsled UNS" },
  { value: "LE013", title: "Ökad mängd ledvätska (galla) UNS i armbågsled" },
  { value: "LE014", title: "Stelhet i armbågsled" },
  { value: "LE0141", title: "Ankylos i armbågsled" },
  { value: "LE0142", title: "Kontraktur  i armbågsled (ej muskelkontraktur)" },
  {
    value: "LE015",
    title: "Genomtrampning eller översträckning av  armbågsled",
  },
  {
    value: "LE016",
    title: "Ökad värme i armbågsledutan närmare specifikation",
  },
  {
    value: "LE017",
    title: "Ökad rörlighet i armbågsledutan närmare specifikation",
  },
  {
    value: "LE018",
    title: "Krepitation från  armbågsledutan närmare specifikation",
  },
  {
    value: "LE1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, armbågsled",
  },
  { value: "LE10", title: "Missbildning i armbågsled" },
  { value: "LE101", title: "Medfödd luxation av  armbågsled" },
  { value: "LE1011", title: "Permanent medfödd luxation av armbågsled" },
  { value: "LE1012", title: "Habituell medfödd luxation av  armbågsled" },
  { value: "LE102", title: "Annan medfödd felställning av armbågsled" },
  { value: "LE103", title: "Medfödd ankylos av armbågsled" },
  {
    value: "LE11",
    title: "Utvecklingsrubbningar tillväxtrubbningar i armbågsled",
  },
  { value: "LE111", title: "Dysplasi av armbågsled" },
  { value: "LE119", title: "Övrig utvecklingsrubbning i armbågsled" },
  { value: "LE2", title: "Metabol, nutrit, degen/dystrof förändr, armbågsled" },
  { value: "LE20", title: "Metaboliska förändringar, armbågsled" },
  { value: "LE21", title: "Nutritionella förändringar, armbågsled" },
  { value: "LE211", title: "Calcinosis circumscripta i armbågsled" },
  {
    value: "LE22",
    title: "Degenerativa/dystrofiska förändringar i armbågsled",
  },
  { value: "LE221", title: "Degeneration av ledbrosk i armbågsled" },
  { value: "LE2211", title: "Kroniskt deformerande artros i armbågsled" },
  { value: "LE222", title: "Degeneration av ligament som berör armbågsleden" },
  { value: "LE223", title: "Synovial kondrometaplasi i armbågsled" },
  { value: "LE224", title: "Lös benbit (ledmus) i armbågsled (ej sek t OCD)" },
  { value: "LE23", title: "Hormonella förändringar, armbågsled" },
  { value: "LE3", title: "Cirkulatoriska förändringar, armbågsled" },
  { value: "LE30", title: "Blödningar, armbågsled" },
  { value: "LE31", title: "Ödem, armbågsled" },
  { value: "LE32", title: "Infarkter, armbågsled" },
  { value: "LE33", title: "Hygrom, armbågsled" },
  { value: "LE34", title: "Trombos / emboli, armbågsled" },
  { value: "LE35", title: "Arterio-atero-skleros förändringar, armbågsled" },
  { value: "LE4", title: "Infekt, infl förändringar, armbågsled" },
  { value: "LE40", title: "Specifika infektionssjukdomar i armbågsled" },
  { value: "LE41", title: "Akut inflammationstillstånd i armbågsled" },
  { value: "LE411", title: "Serös / serofibrinös artrit i armbågsled" },
  { value: "LE412", title: "Purulent artrit i armbågsled" },
  { value: "LE413", title: "Specifika akuta infl tillstånd i armbågsled" },
  { value: "LE419", title: "Övriga akuta infl tillstånd i armbågsled" },
  { value: "LE42", title: "Kroniska inflammationstillstånd i armbågsled" },
  { value: "LE421", title: "Kroniska serös artrit i armbågsled" },
  { value: "LE422", title: "Kroniska purulent artrit i armbågsled" },
  { value: "LE423", title: "Kroniska abscess/fistel artrit i armbågsled" },
  { value: "LE424", title: "Specifika kroniska infl tillstånd i armbågsled" },
  { value: "LE429", title: "Övriga kroniska infl tillstånd i armbågsled" },
  { value: "LE43", title: "Parasitära sjukdomar, armbågsled" },
  { value: "LE5", title: "Immunmedierade tillstånd, armbågsled" },
  { value: "LE51", title: "Reumatoid artrit i armbågsled" },
  { value: "LE6", title: "Neoplastiska förändringar, armbågsled" },
  { value: "LE61", title: "Malign tumör i armbågsled" },
  { value: "LE611", title: "Synovialt sarkom i armbågsled" },
  { value: "LE612", title: "Kondrosarkom i armbågsled" },
  { value: "LE62", title: "Benign tumör i armbågsled" },
  { value: "LE621", title: "Synoviom i armbågsled" },
  { value: "LE622", title: "Kondrom i armbågsled" },
  { value: "LE69", title: "Tumör i led UNS i armbågsled" },
  { value: "LE7", title: "Trauma, fr kr, lägeförändr, term skad, armbågsled" },
  { value: "LE71", title: "Traumatiska / Mekaniska skador i armbågsled" },
  { value: "LE711", title: "Vrickning (distorsion) i armbågsled" },
  { value: "LE712", title: "Ligamentruptur i armbågsled" },
  { value: "LE7121", title: "Kollateralligament skada i armbågsled" },
  { value: "LE714", title: "Sår öppen ledkapsel i armbågsled" },
  { value: "LE72", title: "Främmande kroppar i armbågsled" },
  { value: "LE73", title: "Lägesförändringar i armbågsled" },
  { value: "LE731", title: "Luxation i armbågsled" },
  { value: "LE7311", title: "Subluxation av led i armbågsled" },
  { value: "LE7312", title: "Total luxation av led i armbågsled" },
  { value: "LE74", title: "Termiska skador, armbågsled" },
  { value: "LE741", title: "Brännskada, armbågsled" },
  { value: "LE742", title: "Köldskada, armbågsled, armbågsled" },
  { value: "LE8", title: "Toxiska förändringar" },
  {
    value: "LE9",
    title: "Idiopatiska ospecificerade multifaktoriella, armbågsled",
  },
  { value: "LF", title: "Karpalled" },
  { value: "LF0", title: "Normalvar, symtom u fastställd orsak, karpalled" },
  { value: "LF00", title: "Normalvariation, karpalled" },
  { value: "LF01", title: "Symtom på sjukdom u fastställd orsak , karpalled" },
  { value: "LF011", title: "Smärta från karpalled" },
  { value: "LF0111", title: "Smärta från karpalled vid palpation" },
  { value: "LF0112", title: "Smärta från karpalled vid böjn/sträckn av led" },
  { value: "LF012", title: "Ansvällning av karpalled UNS" },
  { value: "LF013", title: "Ökad mängd ledvätska i karpalled (galla) UNS" },
  { value: "LF014", title: "Stelhet i karpalled" },
  { value: "LF0141", title: "Ankylos i karpalled" },
  { value: "LF0142", title: "Kontraktur i karpalled(ej muskelkontraktur)" },
  { value: "LF015", title: "Genomtrampning eller översträckning av karpalled" },
  {
    value: "LF016",
    title: "Ökad värme i karpalled utan närmare specifikation",
  },
  {
    value: "LF017",
    title: "Ökad rörlighet i karpalled utan närmare specifikation",
  },
  {
    value: "LF018",
    title: "Krepitation från karpalled utan närmare specifikation",
  },
  {
    value: "LF1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, karpalled",
  },
  { value: "LF10", title: "Missbildning i karpalled" },
  { value: "LF101", title: "Medfödd luxation av karpalled" },
  { value: "LF1011", title: "Permanent medfödd luxation av karpalled" },
  { value: "LF1012", title: "Habituell medfödd luxation av karpalled" },
  { value: "LF102", title: "Annan medfödd felställning av karpalled" },
  { value: "LF103", title: "Medfödd ankylos av karpalled" },
  {
    value: "LF11",
    title: "Utvecklingsrubbningar tillväxtrubbningar i karpalled",
  },
  { value: "LF111", title: "Dysplasi av karpalled" },
  { value: "LF119", title: "Övrig utvecklingsrubbning i karpalled" },
  { value: "LF2", title: "Metabol, nutrit, degen/dystrof förändr, karpalled" },
  { value: "LF20", title: "Metaboliska förändringar i karpalled" },
  { value: "LF21", title: "Nutritionella förändringar i karpalled" },
  { value: "LF211", title: "Calcinosis circumscripta i karpalled" },
  { value: "LF22", title: "Degenerativa/dystrofiska förändringar i karpalled" },
  { value: "LF221", title: "Degeneration av ledbrosk i karpalled" },
  { value: "LF2211", title: "Kroniskt deformerande artros i karpalled" },
  { value: "LF222", title: "Degeneration av ligament som berör karpalleden" },
  { value: "LF223", title: "Synovial kondrometaplasi i karpalled" },
  {
    value: "LF224",
    title: "Lös benbit (ledmus) i karpalled ej sekundär till OCD).",
  },
  { value: "LF23", title: "Hormonella förändringar, karpalled" },
  { value: "LF3", title: "Cirkulatoriska förändringar, karpalled" },
  { value: "LF30", title: "Blödningar, karpalled" },
  { value: "LF31", title: "Ödem, karpalled" },
  { value: "LF32", title: "Infarkter, karpalled" },
  { value: "LF33", title: "Hygrom, karpalled" },
  { value: "LF34", title: "Trombos / emboli, karpalled" },
  { value: "LF35", title: "Arterio-atero-skleros förändringar, karpalled" },
  { value: "LF4", title: "Infekt, infl förändringar, karpalled" },
  { value: "LF40", title: "Specifika infektionssjukdomar i karpalled" },
  { value: "LF41", title: "Akuta inflammationstillstånd i karpalled" },
  { value: "LF411", title: "Serös / serofibrinös artrit i karpalled" },
  { value: "LF412", title: "Purulent artrit i karpalled" },
  { value: "LF413", title: "Specifika akuta infl tillstånd i karpalled" },
  { value: "LF419", title: "Övriga akuta infl tillstånd i karpalled" },
  { value: "LF42", title: "Kroniska inflammationstillstånd i karpalled" },
  { value: "LF421", title: "Kroniska serös artrit i karpalled" },
  { value: "LF422", title: "Kroniska purulent artrit i karpalled" },
  { value: "LF423", title: "Kroniska abscess/fistel artrit i karpalled" },
  { value: "LF424", title: "Specifika kroniska infl tillstånd i karpalled" },
  { value: "LF429", title: "Övriga kroniska infl tillstånd i karpalled" },
  { value: "LF43", title: "Parasitära sjukdomar, karpalled" },
  { value: "LF5", title: "Immunmedierade tillstånd, karpalled" },
  { value: "LF51", title: "Reumatoid artrit i karpalled" },
  { value: "LF6", title: "Neoplastiska förändringar, karpalled" },
  { value: "LF61", title: "Malign tumör i karpalled" },
  { value: "LF611", title: "Synovialt sarkom i karpalled" },
  { value: "LF612", title: "Kondrosarkom i karpalled" },
  { value: "LF62", title: "Benign tumör i karpalled" },
  { value: "LF621", title: "Synoviom i karpalled" },
  { value: "LF622", title: "Kondrom i karpalled" },
  { value: "LF69", title: "Tumör i karpalled UNS" },
  { value: "LF7", title: "Trauma, fr kr, lägeförändr, term skad, karpalled" },
  { value: "LF71", title: "Traumatiska / mekaniska skador, karpalled" },
  { value: "LF711", title: "Vrickning (distorsion) i karpalled" },
  { value: "LF712", title: "Ligamentruptur i karpalled" },
  { value: "LF7121", title: "Kolateralligamentskada i karpalled" },
  { value: "LF714", title: "Sår öppen ledkapsel i karpalled" },
  { value: "LF72", title: "Främmande kroppar i karpalled" },
  { value: "LF73", title: "Lägesförändringar i karpalled" },
  { value: "LF731", title: "Luxation i karpalled" },
  { value: "LF7311", title: "Subluxation av karpalled" },
  { value: "LF7312", title: "Total luxation av karpalled" },
  { value: "LF74", title: "Termiska skador, karpalled" },
  { value: "LF741", title: "Brännskada, karpalled" },
  { value: "LF742", title: "Köldskada, karpalled" },
  { value: "LF8", title: "Toxiska förändringar, karpalled" },
  {
    value: "LF9",
    title: "Idiopatiska ospecificerade multifaktoriella, karpalled",
  },
  { value: "LG", title: "Falangled 1 (kotled)" },
  { value: "LG0", title: "Normalvar, symtom u fastställd orsak, falangled 1" },
  { value: "LG00", title: "Normalvariation, falangled 1 (kotled)" },
  {
    value: "LG01",
    title: "Symtom på sjukdom u fastställd orsak, falangled 1 (kotled)",
  },
  { value: "LG011", title: "Smärta från falangled 1 (kotled)" },
  { value: "LG0111", title: "Smärta från falangled 1 (kotled)  vid palpation" },
  {
    value: "LG0112",
    title: "Smärta från falangled 1 (kotled)  vid böjn/sträckn av led",
  },
  { value: "LG012", title: "Ansvällning av falangled 1 (kotled)  UNS" },
  {
    value: "LG013",
    title: "Ökad mängd ledvätska i falangled 1 (kotled)  (galla) UNS",
  },
  { value: "LG014", title: "Stelhet i falangled 1 (kotled)" },
  { value: "LG0141", title: "Ankylos i falangled 1 (kotled)" },
  {
    value: "LG0142",
    title: "Kontraktur i falangled 1 (kotled)  (ej muskelkontraktur)",
  },
  {
    value: "LG015",
    title: "Genomtrampning eller översträckning av falangled 1 (kotled)",
  },
  { value: "LG016", title: "Ökad värme i falangled 1 (kotled)  UNS" },
  { value: "LG017", title: "Ökad rörlighet i falangled 1 (kotled)  UNS" },
  { value: "LG018", title: "Krepitation från falangled 1 (kotled)  UNS" },
  {
    value: "LG1",
    title: "Missbildn, utveckl.rubbn, tillväxtrubbn, falangled 1(kotled)",
  },
  { value: "LG10", title: "Missbildning, falangled 1 (kotled)" },
  { value: "LG101", title: "Medfödd luxation av falangled 1 (kotled)" },
  {
    value: "LG1011",
    title: "Permanent medfödd luxation av falangled 1 (kotled)",
  },
  {
    value: "LG1012",
    title: "Habituell medfödd luxation av falangled 1 (kotled)",
  },
  {
    value: "LG102",
    title: "Annan medfödd felställning av falangled 1 (kotled)",
  },
  { value: "LG103", title: "Medfödd ankylos av falangled 1 (kotled)" },
  {
    value: "LG11",
    title: "Utvecklingsrubbn tillväxtrubbn i falangled 1 (kotled)",
  },
  { value: "LG111", title: "Dysplasi av falangled 1 (kotled)" },
  { value: "LG119", title: "Övrig utvecklingsrubbning i falangled 1 (kotled)" },
  {
    value: "LG2",
    title: "Metabol, nutrit, degen/dystrof förändr, falangled 1 (kotled)",
  },
  { value: "LG20", title: "Metaboliska förändringar, falangled 1 (kotled)" },
  { value: "LG21", title: "Nutritionella förändringar, falangled 1 (kotled)" },
  { value: "LG211", title: "Calcinosis circumscripta i falangled 1 (kotled)" },
  {
    value: "LG22",
    title: "Degenerativa/dystrofiska förändringar, falangled 1 (kotled)",
  },
  { value: "LG221", title: "Degeneration av ledbrosk i falangled 1 (kotled)" },
  {
    value: "LG2211",
    title: "Kroniskt deformerande artros i falangled 1 (kotled)",
  },
  {
    value: "LG222",
    title: "Degeneration av ligament som berör falangled 1 (kotled)",
  },
  { value: "LG223", title: "Synovial kondrometaplasi i falangled 1 (kotled)" },
  {
    value: "LG224",
    title: "Lös benbit (ledmus)  i falangled 1 (kotled) (ej sek t OCD)",
  },
  { value: "LG23", title: "Hormonella förändringar, falangled 1 (kotled)" },
  { value: "LG3", title: "Cirkulatoriska förändringar, falangled 1 (kotled)" },
  { value: "LG30", title: "Blödningar, falangled 1 (kotled)" },
  { value: "LG31", title: "Ödem, falangled 1 (kotled)" },
  { value: "LG32", title: "Infarkter, falangled 1 (kotled)" },
  { value: "LG33", title: "Hygrom, falangled 1 (kotled)" },
  { value: "LG34", title: "Trombos / emboli, falangled 1 (kotled)" },
  {
    value: "LG35",
    title: "Arterio-atero-skleros förändringar, falangled 1 (kotled)",
  },
  { value: "LG4", title: "Infekt, infl förändringar, falangled 1 (kotled)" },
  {
    value: "LG40",
    title: "Specifika infektionssjukdomar i falangled 1 (kotled)",
  },
  {
    value: "LG41",
    title: "Akuta inflammationstillstånd i falangled 1 (kotled)",
  },
  {
    value: "LG411",
    title: "Serös / serofibrinös artrit i falangled 1 (kotled)",
  },
  { value: "LG412", title: "Purulent artrit i falangled 1 (kotled)" },
  {
    value: "LG413",
    title: "Specifika akuta infl tillstånd i falangled 1 (kotled)",
  },
  {
    value: "LG419",
    title: "Övriga akuta infl tillstånd i falangled 1 (kotled)",
  },
  {
    value: "LG42",
    title: "Kroniska inflammationstillstånd i falangled 1 (kotled)",
  },
  { value: "LG421", title: "Kroniska serös artrit i falangled 1 (kotled)" },
  { value: "LG422", title: "Kroniska purulent artrit i falangled 1 (kotled)" },
  {
    value: "LG423",
    title: "Kroniska abscess/fistel artrit i falangled 1 (kotled)",
  },
  {
    value: "LG424",
    title: "Specifika kroniska infl tillstånd i falangled 1 (kotled)",
  },
  {
    value: "LG429",
    title: "Övriga kroniska infl tillstånd i falangled 1 (kotled)",
  },
  { value: "LG43", title: "Parasitära sjukdomar, falangled 1 (kotled)" },
  { value: "LG5", title: "Immunmedierade tillstånd, falangled 1 (kotled)" },
  { value: "LG51", title: "Reumatoid artrit i falangled 1 (kotled)" },
  { value: "LG6", title: "Neoplastiska förändringar, falangled 1 (kotled)" },
  { value: "LG61", title: "Malign tumör i falangled 1 (kotled)" },
  { value: "LG611", title: "Synovialt sarkom i falangled 1 (kotled)" },
  { value: "LG612", title: "Kondrosarkom i falangled 1 (kotled)" },
  { value: "LG62", title: "Benign tumör i falangled 1 (kotled)" },
  { value: "LG621", title: "Synoviom i falangled 1 (kotled)" },
  { value: "LG622", title: "Kondrom i falangled 1 (kotled)" },
  { value: "LG69", title: "Tumör i falangled 1 (kotled)  UNS" },
  {
    value: "LG7",
    title: "Trauma, fr kr, lägeförändr, term skad, falangled 1 (kotled)",
  },
  { value: "LG71", title: "Traumatiska / Mekaniska skador i falangled1" },
  { value: "LG711", title: "Vrickning (distorsion) i falangled 1 (kotled)" },
  { value: "LG712", title: "Ligamentruptur i falangled 1 (kotled)" },
  { value: "LG7121", title: "Kollateralligamentskada i falangled 1 (kotled)" },
  { value: "LG714", title: "Sår öppen ledkapsel i falangled 1 (kotled)" },
  { value: "LG72", title: "Främmande kroppar i falangled 1 (kotled)" },
  { value: "LG73", title: "Lägesförändringar i falangled 1 (kotled)" },
  { value: "LG731", title: "Luxation av falangled 1 (kotled)" },
  { value: "LG7311", title: "Subluxation av falangled 1 (kotled)" },
  { value: "LG7312", title: "Total luxation av falangled 1 (kotled)" },
  { value: "LG74", title: "Termiska skador, falangled 1 (kotled)" },
  { value: "LG741", title: "Brännskada, falangled 1 (kotled)" },
  { value: "LG742", title: "Köldskada, falangled 1 (kotled)" },
  { value: "LG8", title: "Toxiska förändringar, falangled 1 (kotled)" },
  {
    value: "LG9",
    title: "Idiopatiska ospecif multifaktoriella, falangled 1 (kotled)",
  },
  { value: "LH", title: "Falangled 2 (kronled)" },
  {
    value: "LH0",
    title: "Normalvar, symtom u fastställd orsak falangled 2 (kronled)",
  },
  { value: "LH00", title: "Normalvariation, falangled 2 (kronled)" },
  {
    value: "LH01",
    title: "Symtom på sjukdom u fastställd orsak, falangled 2 (kronled)",
  },
  { value: "LH011", title: "Smärta från falangled 2 (kronled)" },
  {
    value: "LH0111",
    title: "Smärta från falangled 2 (kronled)  vid palpation",
  },
  {
    value: "LH0112",
    title: "Smärta från falangled 2 (kronled)  vid böjn/sträckn av led",
  },
  { value: "LH012", title: "Ansvällning av falangled 2 (kronled)  UNS" },
  {
    value: "LH013",
    title: "Ökad mängd ledvätska i falangled 2 (kronled)  (galla) UNS",
  },
  { value: "LH014", title: "Stelhet i falangled 2 (kronled)" },
  { value: "LH0141", title: "Ankylos i falangled 2 (kronled)" },
  {
    value: "LH0142",
    title: "Kontraktur i falangled 2 (kronled)  (ej muskelkontraktur)",
  },
  {
    value: "LH015",
    title: "Genomtrampning eller översträckning av falangled 2 (kronled)",
  },
  { value: "LH016", title: "Ökad värme i falangled 2 (kronled)  UNS" },
  { value: "LH017", title: "Ökad rörlighet i falangled 2 (kronled)  UNS" },
  { value: "LH018", title: "Krepitation från falangled 2 (kronled)  UNS" },
  {
    value: "LH1",
    title: "Missbildn, utvecklrubbn, tillväxtrubbn, falangled 2(kronled)",
  },
  { value: "LH10", title: "Missbildning, falangled 2 (kronled)" },
  { value: "LH101", title: "Medfödd luxation av falangled 2 (kronled)" },
  {
    value: "LH1011",
    title: "Permanent medfödd luxation av falangled 2 (kronled)",
  },
  {
    value: "LH1012",
    title: "Habituell medfödd luxation av falangled 2 (kronled)",
  },
  {
    value: "LH102",
    title: "Annan medfödd felställning av falangled 2 (kronled)",
  },
  { value: "LH103", title: "Medfödd ankylos av falangled 2 (kronled)" },
  {
    value: "LH11",
    title: "Utvecklingsrubbn tillväxtrubbningar i falangled 2 (kronled)",
  },
  { value: "LH111", title: "Dysplasi av falangled 2 (kronled)" },
  {
    value: "LH119",
    title: "Övrig utvecklingsrubbning i falangled 2 (kronled)",
  },
  {
    value: "LH2",
    title: "Metabol, nutrit, degen/dystrof förändr, falangled 2(kronled)",
  },
  { value: "LH20", title: "Metaboliska förändringar, falangled 2 (kronled)" },
  { value: "LH21", title: "Nutritionella förändringar, falangled 2 (kronled)" },
  { value: "LH211", title: "Calcinosis circumscripta i falangled 2 (kronled)" },
  {
    value: "LH22",
    title: "Degenerativa/dystrofiska förändringar, falangled 2 (kronled)",
  },
  { value: "LH221", title: "Degeneration av ledbrosk i falangled 2 (kronled)" },
  {
    value: "LH2211",
    title: "Kroniskt deformerande artros i falangled 2 (kronled)",
  },
  {
    value: "LH222",
    title: "Degeneration av ligament som berör falangleden  2",
  },
  { value: "LH223", title: "Synovial kondrometaplasi i falangled 2 (kronled)" },
  {
    value: "LH224",
    title: "Lös benbit (ledmus)  i falangled 2 (kronled) (ej sek t OCD)",
  },
  { value: "LH23", title: "Hormonella förändringar, falangled 2 (kronled)" },
  { value: "LH3", title: "Cirkulatoriska förändringar, falangled 2 (kronled)" },
  { value: "LH30", title: "Blödningar, falangled 2 (kronled)" },
  { value: "LH31", title: "Ödem, falangled 2 (kronled)" },
  { value: "LH32", title: "Infarkter, falangled 2 (kronled)" },
  { value: "LH33", title: "Hygrom, falangled 2 (kronled)" },
  { value: "LH34", title: "Trombos / emboli, falangled 2 (kronled)" },
  {
    value: "LH35",
    title: "Arterio-atero-skleros förändringar, falangled 2 (kronled)",
  },
  { value: "LH4", title: "Infekt, infl förändringar, falangled 2 (kronled)" },
  {
    value: "LH40",
    title: "Specifika infektionssjukdomar i falangled 2 (kronled)",
  },
  {
    value: "LH41",
    title: "Akuta inflammationstillstånd i falangled 2 (kronled)",
  },
  {
    value: "LH411",
    title: "Serös / serofibrinös artrit i falangled 2 (kronled)",
  },
  { value: "LH412", title: "Purulent artrit i falangled 2 (kronled)" },
  {
    value: "LH413",
    title: "Specifika akuta infl tillstånd i falangled 2 (kronled)",
  },
  {
    value: "LH419",
    title: "Övriga akuta infl tillstånd i falangled 2 (kronled)",
  },
  {
    value: "LH42",
    title: "Kroniska inflammationstillstånd i falangled 2 (kronled)",
  },
  { value: "LH421", title: "Kroniska serös artrit i falangled 2 (kronled)" },
  { value: "LH422", title: "Kroniska purulent artrit i falangled 2 (kronled)" },
  {
    value: "LH423",
    title: "Kroniska abscess/fistel artrit i falangled 2 (kronled)",
  },
  {
    value: "LH424",
    title: "Specifika kroniska infl tillstånd i falangled 2 (kronled)",
  },
  {
    value: "LH429",
    title: "Övriga kroniska infl tillstånd i falangled 2 (kronled)",
  },
  { value: "LH43", title: "Parasitära sjukdomar, falangled 2 (kronled)" },
  { value: "LH5", title: "Immunmedierade tillstånd, falangled 2 (kronled)" },
  { value: "LH51", title: "Reumatoid artrit i falangled 2 (kronled)" },
  { value: "LH6", title: "Neoplastiska förändringar, falangled 2 (kronled)" },
  { value: "LH61", title: "Malign tumör i falangled 2 (kronled)" },
  { value: "LH611", title: "Synovialt sarkom i falangled 2 (kronled)" },
  { value: "LH612", title: "Kondrosarkom i falangled 2 (kronled)" },
  { value: "LH62", title: "Benign tumör i falangled 2 (kronled)" },
  { value: "LH621", title: "Synoviom i falangled 2 (kronled)" },
  { value: "LH622", title: "Kondrom i falangled 2 (kronled)" },
  { value: "LH69", title: "Tumör i falangled 2 (kronled)  UNS" },
  {
    value: "LH7",
    title: "Trauma, fr kr, lägeförändr, term skad, falangled 2 (kronled)",
  },
  {
    value: "LH71",
    title: "Traumatiska / Mekaniska skador i falangled 2 (kronled)",
  },
  { value: "LH711", title: "Vrickning (distorsion) i falangled 2 (kronled)" },
  { value: "LH712", title: "Ligamentruptur i falangled 2 (kronled)" },
  { value: "LH7121", title: "Kollateralligamentskada i falangled 2 (kronled)" },
  { value: "LH714", title: "Sår öppen ledkapsel i falangled 2 (kronled)" },
  { value: "LH72", title: "Främmande kroppar i falangled 2 (kronled)" },
  { value: "LH73", title: "Lägesförändringar i falangled 2 (kronled)" },
  { value: "LH731", title: "Luxation av falangled 2 (kronled)" },
  { value: "LH7311", title: "Subluxation av falangled 2 (kronled)" },
  { value: "LH7312", title: "Total luxation av falangled 2 (kronled)" },
  { value: "LH74", title: "Termiska skador, falangled 2 (kronled)" },
  { value: "LH741", title: "Brännskada, falangled 2 (kronled)" },
  { value: "LH742", title: "Köldskada, falangled 2 (kronled)" },
  { value: "LH8", title: "Toxiska förändringar, falangled 2 (kronled)" },
  {
    value: "LH9",
    title: "Idiopatiska ospecificerade multifakt, falangled 2 (kronled)",
  },
  { value: "LJ", title: "Falangled 3 (hovled)" },
  {
    value: "LJ0",
    title: "Normalvar, symtom u fastställd orsak, falangled 3 (hovled)",
  },
  { value: "LJ00", title: "Normalvariation, falangled 3 (hovled)" },
  {
    value: "LJ01",
    title: "Symtom på sjukdom u fastställd orsak, falangled 3 (hovled)",
  },
  { value: "LJ011", title: "Smärta från falangled 3 (hovled)" },
  { value: "LJ0111", title: "Smärta från falangled 3 (hovled)  vid palpation" },
  {
    value: "LJ0112",
    title: "Smärta från falangled 3 (hovled)  vid böjn/sträckn av led",
  },
  { value: "LJ012", title: "Ansvällning av falangled 3 (hovled)  UNS" },
  {
    value: "LJ013",
    title: "Ökad mängd ledvätska i falangled 3 (hovled)  (galla) UNS",
  },
  { value: "LJ014", title: "Stelhet i falangled 3 (hovled)" },
  { value: "LJ0141", title: "Ankylos i falangled 3 (hovled)" },
  {
    value: "LJ0142",
    title: "Kontraktur i falangled 3 (hovled)  (ej muskelkontraktur)",
  },
  {
    value: "LJ015",
    title: "Genomtrampning eller översträckning av falangled 3 (hovled)",
  },
  { value: "LJ016", title: "Ökad värme i falangled 3 (hovled)  UNS" },
  { value: "LJ017", title: "Ökad rörlighet i falangled 3 (hovled)  UNS" },
  { value: "LJ018", title: "Krepitation från falangled 3 (hovled)  UNS" },
  {
    value: "LJ1",
    title: "Missbildn, utveckl.rubbn, tillväxtrubbn, falangled 3(hovled)",
  },
  { value: "LJ10", title: "Missbildning, falangled 3 (hovled)" },
  { value: "LJ101", title: "Medfödd luxation av falangled 3 (hovled)" },
  {
    value: "LJ1011",
    title: "Permanent medfödd luxation av falangled 3 (hovled)",
  },
  {
    value: "LJ1012",
    title: "Habituell medfödd luxation av falangled 3 (hovled)",
  },
  {
    value: "LJ102",
    title: "Annan medfödd felställning av falangled 3 (hovled)",
  },
  { value: "LJ103", title: "Medfödd ankylos av falangled 3" },
  {
    value: "LJ11",
    title: "Utvecklingsrubbn tillväxtrubbningar i falangled 3 (hovled)",
  },
  { value: "LJ111", title: "Dysplasi av falangled 3 (hovled)" },
  { value: "LJ119", title: "Övrig utvecklingsrubbning i falangled 3 (hovled)" },
  {
    value: "LJ2",
    title: "Metabol, nutrit, degen/dystrof förändr, falangled 3 (hovled)",
  },
  { value: "LJ20", title: "Metaboliska förändringar, falangled 3 (hovled)" },
  { value: "LJ21", title: "Nutritionella förändringar, falangled 3 (hovled)" },
  { value: "LJ211", title: "Calcinosis circumscripta i falangled 3 (hovled)" },
  {
    value: "LJ22",
    title: "Degenerativa/dystrofiska förändringar, falangled 3 (hovled)",
  },
  { value: "LJ221", title: "Degeneration av ledbrosk i falangled 3 (hovled)" },
  {
    value: "LJ2211",
    title: "Kroniskt deformerande artros i falangled 3 (hovled)",
  },
  {
    value: "LJ222",
    title: "Degeneration av ligament som berör falangleden  3",
  },
  { value: "LJ223", title: "Synovial kondrometaplasi i falangled 3 (hovled)" },
  {
    value: "LJ224",
    title: "Lös benbit (ledmus)  i falangled 3 (hovled) (ej sek t OCD)",
  },
  { value: "LJ23", title: "Hormonella förändringar, falangled 3 (hovled)" },
  { value: "LJ3", title: "Cirkulatoriska förändringar, falangled 3 (hovled)" },
  { value: "LJ30", title: "Blödningar, falangled 3 (hovled)" },
  { value: "LJ31", title: "Ödem, falangled 3 (hovled)" },
  { value: "LJ32", title: "Infarkter, falangled 3 (hovled)" },
  { value: "LJ33", title: "Hygrom, falangled 3 (hovled)" },
  { value: "LJ34", title: "Trombos / emboli, falangled 3 (hovled)" },
  {
    value: "LJ35",
    title: "Arterio-atero-skleros förändringar, falangled 3 (hovled)",
  },
  { value: "LJ4", title: "Infekt, infl förändringar, falangled 3 (hovled)" },
  {
    value: "LJ40",
    title: "Specifika infektionssjukdomar i falangled 3 (hovled)",
  },
  {
    value: "LJ41",
    title: "Akuta inflammationstillstånd i falangled 3 (hovled)",
  },
  {
    value: "LJ411",
    title: "Serös / serofibrinös artrit i falangled 3 (hovled)",
  },
  { value: "LJ412", title: "Purulent artrit i falangled 3 (hovled)" },
  {
    value: "LJ413",
    title: "Specifika akuta infl tillstånd i falangled 3 (hovled)",
  },
  {
    value: "LJ419",
    title: "Övriga akuta infl tillstånd i falangled 3 (hovled)",
  },
  {
    value: "LJ42",
    title: "Kroniska inflammationstillstånd i falangled 3 (hovled)",
  },
  { value: "LJ421", title: "Kroniska serös artrit i falangled 3 (hovled)" },
  { value: "LJ422", title: "Kroniska purulent artrit i falangled 3 (hovled)" },
  {
    value: "LJ423",
    title: "Kroniska abscess/fistel artrit i falangled 3 (hovled)",
  },
  {
    value: "LJ424",
    title: "Specifika kroniska infl tillstånd i falangled 3 (hovled)",
  },
  {
    value: "LJ429",
    title: "Övriga kroniska infl tillstånd i falangled 3 (hovled)",
  },
  { value: "LJ43", title: "Parasitära sjukdomar, falangled 3 (hovled)" },
  { value: "LJ5", title: "Immunmedierade tillstånd, falangled 3 (hovled)" },
  { value: "LJ51", title: "Reumatoid artrit i falangled 3 (hovled)" },
  { value: "LJ6", title: "Neoplastiska förändringar, falangled 3 (hovled)" },
  { value: "LJ61", title: "Malign tumör i falangled 3 (hovled)" },
  { value: "LJ611", title: "Synovialt sarkom i falangled 3 (hovled)" },
  { value: "LJ612", title: "Kondrosarkom i falangled 3 (hovled)" },
  { value: "LJ62", title: "Benign tumör i falangled 3 (hovled)" },
  { value: "LJ621", title: "Synoviom i falangled 3 (hovled)" },
  { value: "LJ622", title: "Kondrom i falangled 3 (hovled)" },
  { value: "LJ69", title: "Tumör i falangled 3 (hovled)  UNS" },
  {
    value: "LJ7",
    title: "Trauma, fr kr, lägeförändr, term skad, falangled 3 (hovled)",
  },
  {
    value: "LJ71",
    title: "Traumatiska / Mekaniska skador i falangled 3 (hovled)",
  },
  { value: "LJ711", title: "Vrickning (distorsion) i falangled 3 (hovled)" },
  { value: "LJ712", title: "Ligamentruptur i falangled 3 (hovled)" },
  { value: "LJ7121", title: "Kollateralligamentskada i falangled 3 (hovled)" },
  { value: "LJ714", title: "Sår öppen ledkapsel ifalangled 3 (hovled)" },
  { value: "LJ72", title: "Främmande kroppar i falangled 3 (hovled)" },
  { value: "LJ73", title: "Lägesförändringar i falangled 3 (hovled)" },
  { value: "LJ731", title: "Luxation av falangled 3 (hovled)" },
  { value: "LJ7311", title: "Subluxation av falangled 3 (hovled)" },
  { value: "LJ7312", title: "Total luxation av falangled 3 (hovled)" },
  { value: "LJ74", title: "Termiska skador, falangled 3 (hovled)" },
  { value: "LJ741", title: "Brännskada, falangled 3 (hovled)" },
  { value: "LJ742", title: "Köldskada, falangled 3 (hovled)" },
  { value: "LJ8", title: "Toxiska förändringar, falangled 3 (hovled)" },
  {
    value: "LJ9",
    title: "Idiopatiska ospecif multifaktoriella, falangled 3 (hovled)",
  },
  { value: "LK", title: "Höftled" },
  { value: "LK0", title: "Normalvar, symtom u fastställd orsak, höftled" },
  { value: "LK00", title: "Normalvariation, höftled" },
  { value: "LK01", title: "Symtom på sjukdom u fastställd orsak, höftled" },
  { value: "LK011", title: "Smärta från höftled" },
  { value: "LK0111", title: "Smärta från höftled vid palpation" },
  { value: "LK0112", title: "Smärta från höftled vid böjn/sträckn av led" },
  { value: "LK012", title: "Ansvällning av höftled UNS" },
  { value: "LK013", title: "Ökad mängd ledvätska i höftled (galla) UNS" },
  { value: "LK014", title: "Stelhet i höftled" },
  { value: "LK0141", title: "Ankylos i höftled" },
  { value: "LK0142", title: "Kontraktur i höftled (ej muskelkontraktur)" },
  { value: "LK015", title: "Genomtrampning eller översträckning av höftled" },
  { value: "LK016", title: "Ökad värme i höftled UNS" },
  { value: "LK017", title: "Ökad rörlighet i höftled UNS" },
  { value: "LK018", title: "Krepitation från höftled UNS" },
  { value: "LK1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, höftled" },
  { value: "LK10", title: "Missbildning i höftled" },
  { value: "LK101", title: "Medfödd luxation av höftled" },
  { value: "LK1011", title: "Permanent medfödd luxation av höftled" },
  { value: "LK1012", title: "Habituell medfödd luxation av höftled" },
  { value: "LK102", title: "Annan medfödd felställning av höftled" },
  { value: "LK11", title: "Utvecklingsrubbn, tillväxtrubbn, höftled" },
  { value: "LK111", title: "Höftledsdysplasi" },
  { value: "LK119", title: "Övrig utvecklingsrubbning i höftled" },
  { value: "LK2", title: "Metabol, nutrit, degen/dystrof förändr, höftled" },
  { value: "LK20", title: "Metaboliska förändringar, höftled" },
  { value: "LK21", title: "Nutritionella förändringar, höftled" },
  { value: "LK211", title: "Calcinosis circumscripta i höftled" },
  { value: "LK22", title: "Degenerativa/dystrofiska förändringar i höftled" },
  { value: "LK221", title: "Degeneration av ledbrosk i höftled" },
  { value: "LK2211", title: "Kroniskt deformerande artros i höftled" },
  { value: "LK222", title: "Degeneration av ligament som berör höftleden" },
  { value: "LK223", title: "Synovial kondrometaplasi i höftled" },
  { value: "LK224", title: "Lös benbit (ledmus) i höftled  (ej sek t OCD)" },
  { value: "LK23", title: "Hormonella förändringar, höftled" },
  { value: "LK3", title: "Cirkulatoriska förändringar, höftled" },
  { value: "LK30", title: "Blödningar, höftled" },
  { value: "LK31", title: "Ödem, höftled" },
  { value: "LK32", title: "Infarkter, höftled" },
  { value: "LK33", title: "Hygrom, höftled" },
  { value: "LK34", title: "Trombos / emboli, höftled" },
  { value: "LK35", title: "Arterio-atero-skleros förändringar, höftled" },
  { value: "LK4", title: "Infekt, infl förändringar, höftled" },
  { value: "LK40", title: "Specifika infektionssjukdomar i höftled" },
  { value: "LK41", title: "Akuta inflammationstillstånd i höftled" },
  { value: "LK411", title: "Serös / serofibrinös artrit i höftled" },
  { value: "LK412", title: "Purulent artrit i höftled" },
  { value: "LK413", title: "Specifika akuta infl tillstånd i höftled" },
  { value: "LK419", title: "Övriga akuta infl tillstånd i höftled" },
  { value: "LK42", title: "Kroniska inflammationstillstånd i höftled" },
  { value: "LK421", title: "Kroniska serös artrit i höftled" },
  { value: "LK422", title: "Kroniska purulent artrit i höftled" },
  { value: "LK423", title: "Kroniska abscess/fistel artrit i höftled" },
  { value: "LK424", title: "Specifika kroniska infl tillstånd i höftled" },
  { value: "LK429", title: "Övriga kroniska infl tillstånd i höftled" },
  { value: "LK43", title: "Parasitära sjukdomar, höftled" },
  { value: "LK5", title: "Immunmedierade tillstånd, höftled" },
  { value: "LK51", title: "Reumatoid artrit i höftled" },
  { value: "LK6", title: "Neoplastiska förändringar, höftled" },
  { value: "LK61", title: "Malign tumör i höftled" },
  { value: "LK611", title: "Synovialt sarkom i höftled" },
  { value: "LK612", title: "Kondrosarkom i höftled" },
  { value: "LK62", title: "Benign tumör i höftled" },
  { value: "LK621", title: "Synoviom i höftled" },
  { value: "LK622", title: "Kondrom i höftled" },
  { value: "LK69", title: "Tumör i höftled UNS" },
  { value: "LK7", title: "Trauma, fr kr, lägeförändr, term skad, höftled" },
  { value: "LK71", title: "Traumatiska / Mekaniska skador i höftled" },
  { value: "LK711", title: "Vrickning (distorsion) i höftled" },
  { value: "LK712", title: "Ligamentruptur i höftled" },
  { value: "LK714", title: "Sår öppen ledkapsel i höftled" },
  { value: "LK72", title: "Främmande kroppar i höftled" },
  { value: "LK73", title: "Lägesförändringar i höftled" },
  { value: "LK731", title: "Luxation i höftled" },
  { value: "LK7311", title: "Subluxation av höftled" },
  { value: "LK7312", title: "Total luxation av höftled" },
  { value: "LK74", title: "Termiska skador, höftled" },
  { value: "LK741", title: "Brännskada, höftled" },
  { value: "LK742", title: "Köldskada, höftled" },
  { value: "LK8", title: "Toxiska förändringar, höftled" },
  {
    value: "LK9",
    title: "Idiopatiska ospecificerade multifaktoriella, höftled",
  },
  { value: "LL", title: "Knäled" },
  { value: "LL0", title: "Normalvar, symtom u fastställd orsak, knäled" },
  { value: "LL00", title: "Normalvariation, knäled" },
  { value: "LL01", title: "Symtom på sjukdom u fastställd orsak , knäled" },
  { value: "LL011", title: "Smärta från knäled" },
  { value: "LL0111", title: "Smärta från knäled vid palpation" },
  { value: "LL0112", title: "Smärta från knäled vid böjn/sträckn av led" },
  { value: "LL012", title: "Ansvällning av knäled UNS" },
  { value: "LL013", title: "Ökad mängd ledvätska i knäled (galla) UNS" },
  { value: "LL014", title: "Stelhet i knäled" },
  { value: "LL0141", title: "Ankylos i knäled" },
  { value: "LL0142", title: "Kontraktur i knäled (ej muskelkontraktur)" },
  { value: "LL015", title: "Genomtrampning eller översträckning av knäled" },
  { value: "LL016", title: "Ökad värme i knäled UNS" },
  { value: "LL017", title: "Ökad rörlighet i knäled UNS" },
  { value: "LL018", title: "Krepitation från knäled UNS" },
  { value: "LL1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, knäled" },
  { value: "LL10", title: "Missbildning i knäled" },
  { value: "LL101", title: "Medfödd luxation av knäled" },
  { value: "LL1011", title: "Permanent medfödd luxation av knäled" },
  { value: "LL1012", title: "Habituell medfödd luxation av knäled" },
  { value: "LL102", title: "Annan medfödd felställning av knäled" },
  { value: "LL103", title: "Medfödd ankylos av knäled" },
  { value: "LL11", title: "Utvecklingsrubbningar tillväxtrubbningar i knäled" },
  { value: "LL111", title: "Dysplasi av knäled" },
  { value: "LL119", title: "Övrig utvecklingsrubbning i knäled" },
  { value: "LL2", title: "Metabol, nutrit, degen/dystrof förändr, knäled" },
  { value: "LL20", title: "Metaboliska förändringar, knäled" },
  { value: "LL21", title: "Nutritionella förändringar, knäled" },
  { value: "LL211", title: "Calcinosis circumscripta i knäled" },
  { value: "LL22", title: "Degenerativa/dystrofiska förändringar i knäled" },
  { value: "LL221", title: "Degeneration av ledbrosk i knäled" },
  { value: "LL2211", title: "Kroniskt deformerande artros i knäled" },
  { value: "LL222", title: "Degeneration av ligament som berör knäleden" },
  { value: "LL223", title: "Synovial kondrometaplasi i knäled" },
  { value: "LL224", title: "Ledmus i knäled (ej sek t OCD)" },
  { value: "LL225", title: "Meniskskada ej traumatisk i knäled" },
  { value: "LL23", title: "Hormonella förändringar, knäled" },
  { value: "LL3", title: "Cirkulatoriska förändringar, knäled" },
  { value: "LL30", title: "Blödningar, knäled" },
  { value: "LL31", title: "Ödem, knäled" },
  { value: "LL32", title: "Infarkter, knäled" },
  { value: "LL33", title: "Hygrom, knäled" },
  { value: "LL34", title: "Trombos / emboli, knäled" },
  { value: "LL35", title: "Arterio-atero-skleros förändringar, knäled" },
  { value: "LL4", title: "Infekt, infl förändringar, knäled" },
  { value: "LL40", title: "Specifika infektionssjukdomar i knäled" },
  { value: "LL41", title: "Akuta inflammationstillstånd i knäled" },
  { value: "LL411", title: "Serös / serofibrinös artrit i knäled" },
  { value: "LL412", title: "Purulent artrit i knäled" },
  { value: "LL413", title: "Specifika akuta infl tillstånd i knäled" },
  { value: "LL419", title: "Övriga akuta infl tillstånd i knäled" },
  { value: "LL42", title: "Kroniska inflammationstillstånd i knäled" },
  { value: "LL421", title: "Kroniska serös artrit i knäled" },
  { value: "LL422", title: "Kroniska purulent artrit i knäled" },
  { value: "LL423", title: "Kroniska abscess/fistel artrit i knäled" },
  { value: "LL424", title: "Specifika kroniska infl tillstånd i knäled" },
  { value: "LL429", title: "Övriga kroniska infl tillstånd i knäled" },
  { value: "LL43", title: "Parasitära sjukdomar, knäled" },
  { value: "LL5", title: "Immunmedierade tillstånd, knäled" },
  { value: "LL51", title: "Reumatoid artrit i knäled" },
  { value: "LL6", title: "Neoplastiska förändringar, knäled" },
  { value: "LL61", title: "Malign tumör i knäled" },
  { value: "LL611", title: "Synovialt sarkom i knäled" },
  { value: "LL612", title: "Kondrosarkom i knäled" },
  { value: "LL62", title: "Benign tumör i knäled" },
  { value: "LL621", title: "Synoviom i knäled" },
  { value: "LL622", title: "Kondrom i knäled" },
  { value: "LL69", title: "Tumör i knäled UNS" },
  { value: "LL7", title: "Trauma, fr kr, lägeförändr, term skad, knäled" },
  { value: "LL71", title: "Traumatiska / Mekaniska skador i knäled" },
  { value: "LL711", title: "Vrickning (distorsion) i knäled" },
  { value: "LL712", title: "Ligamentruptur i knäled" },
  { value: "LL7121", title: "Kollateralligamentskada" },
  { value: "LL7122", title: "Korsbandsskada" },
  { value: "LL713", title: "Traumatisk meniskskada" },
  { value: "LL714", title: "Sår öppen ledkapsel i knäled" },
  { value: "LL72", title: "Främmande kroppar i knäled" },
  { value: "LL73", title: "Lägesförändringar i knäled" },
  { value: "LL731", title: "Luxation i knäled" },
  { value: "LL7311", title: "Subluxation av knäled" },
  { value: "LL7312", title: "Total luxation av knäled" },
  { value: "LL74", title: "Termiska skador, knäled" },
  { value: "LL741", title: "Brännskada, knäled" },
  { value: "LL742", title: "Köldskada, knäled" },
  { value: "LL8", title: "Toxiska förändringar, knäled" },
  {
    value: "LL9",
    title: "Idiopatiska ospecificerade multifaktoriella, knäled",
  },
  { value: "LM", title: "Hasled" },
  { value: "LM0", title: "Normalvar, symtom u fastställd orsak, hasled" },
  { value: "LM00", title: "Normalvariation, hasled" },
  { value: "LM01", title: "Symtom på sjukdom u fastställd orsak , hasled" },
  { value: "LM011", title: "Smärta från hasled" },
  { value: "LM0111", title: "Smärta från hasled vid palpation" },
  { value: "LM0112", title: "Smärta från hasled vid böjn/sträckn av led" },
  { value: "LM012", title: "Ansvällning av hasled UNS" },
  { value: "LM013", title: "Ökad mängd ledvätska i hasled (galla) UNS" },
  { value: "LM014", title: "Stelhet i hasled" },
  { value: "LM0141", title: "Ankylos i hasled" },
  { value: "LM0142", title: "Kontraktur i hasled (ej muskelkontraktur)" },
  { value: "LM015", title: "Genomtrampning eller översträckning av hasled" },
  { value: "LM016", title: "Ökad värme i hasled UNS" },
  { value: "LM017", title: "Ökad rörlighet i hasled UNS" },
  { value: "LM018", title: "Krepitation från hasled UNS" },
  { value: "LM1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, hasled" },
  { value: "LM10", title: "Missbildning av hasled" },
  { value: "LM101", title: "Medfödd luxation av hasled" },
  { value: "LM1011", title: "Permanent medfödd luxation av hasled" },
  { value: "LM1012", title: "Habituell medfödd luxation av hasled" },
  { value: "LM102", title: "Annan medfödd felställning av hasled" },
  { value: "LM103", title: "Medfödd ankylos av hasled" },
  { value: "LM11", title: "Utvecklingsrubbn, tillväxtrubbn, hasled" },
  { value: "LM111", title: "Dysplasi av hasled" },
  { value: "LM119", title: "Övrig utvecklingsrubbning i hasled" },
  { value: "LM2", title: "Metabol, nutrit, degen/dystrof förändr, hasled" },
  { value: "LM20", title: "Metaboliska förändringar, hasled" },
  { value: "LM21", title: "Nutritionella förändringar, hasled" },
  { value: "LM211", title: "Calcinosis circumscripta i hasled" },
  { value: "LM22", title: "Degenerativa/dystrofiska förändringar, hasled" },
  { value: "LM221", title: "Degeneration av hasled" },
  { value: "LM2211", title: "Kroniskt deformerande artros i hasled" },
  { value: "LM2215", title: "Ossös spatt" },
  { value: "LM222", title: "Degeneration av ligament som berör hasleden" },
  { value: "LM223", title: "Synovial kondrometaplasi i hasled" },
  { value: "LM224", title: "Lös benbit (ledmus) i hasled  (ej sek t OCD)" },
  { value: "LM23", title: "Hormonella förändringar, hasled" },
  { value: "LM3", title: "Cirkulatoriska förändringar, hasled" },
  { value: "LM30", title: "Blödningar, hasled" },
  { value: "LM31", title: "Ödem, hasled" },
  { value: "LM32", title: "Infarkter, hasled" },
  { value: "LM33", title: "Hygrom, hasled" },
  { value: "LM34", title: "Trombos / emboli, hasled" },
  { value: "LM35", title: "Arterio-atero-skleros förändringar, hasled" },
  { value: "LM4", title: "Infekt, infl förändringar, hasled" },
  { value: "LM40", title: "Specifika infektionssjukdomar i hasled" },
  { value: "LM41", title: "Akuta inflammationstillstånd i hasled" },
  { value: "LM411", title: "Serös / serofibrinös artrit i hasled" },
  { value: "LM412", title: "Purulent artrit i hasled" },
  { value: "LM413", title: "Specifik akuta infl tillstånd i hasled" },
  { value: "LM419", title: "Övriga akuta infl tillstånd i hasled" },
  { value: "LM42", title: "Kroniska inflammationstillstånd i hasled" },
  { value: "LM421", title: "Kroniska serös artrit i hasled" },
  { value: "LM422", title: "Kroniska purulent artrit i hasled" },
  { value: "LM423", title: "Kroniska abscess/fistel artrit i hasled" },
  { value: "LM424", title: "Specifika kroniska infl tillstånd i hasled" },
  { value: "LM429", title: "Övriga kroniska infl tillstånd i hasled" },
  { value: "LM43", title: "Parasitära sjukdomar, hasled" },
  { value: "LM5", title: "Immunmedierade tillstånd, hasled" },
  { value: "LM51", title: "Reumatoid artrit i hasled" },
  { value: "LM6", title: "Neoplastiska förändringar, hasled" },
  { value: "LM61", title: "Malign tumör i hasled" },
  { value: "LM611", title: "Synovialt sarkom i hasled" },
  { value: "LM612", title: "Kondrosarkom i hasled" },
  { value: "LM62", title: "Benign tumör i hasled" },
  { value: "LM621", title: "Synoviom i hasled" },
  { value: "LM622", title: "Kondrom i hasled" },
  { value: "LM69", title: "Tumör i hasled UNS" },
  { value: "LM7", title: "Trauma, fr kr, lägeförändr, term skad, hasled" },
  { value: "LM71", title: "Traumatiska / Mekaniska skador i hasled" },
  { value: "LM711", title: "Vrickning (distorsion) i hasled" },
  { value: "LM712", title: "Ligamentruptur i hasled" },
  { value: "LM7121", title: "Kollateralligamentskada, hasled" },
  { value: "LM714", title: "Sår öppen ledkapsel i hasled" },
  { value: "LM72", title: "Främmande kroppar i hasled" },
  { value: "LM73", title: "Lägesförändringar i hasled" },
  { value: "LM731", title: "Luxation av hasled" },
  { value: "LM7311", title: "Subluxation av hasled" },
  { value: "LM7312", title: "Total luxation av hasled" },
  { value: "LM74", title: "Termiska skador, hasled" },
  { value: "LM741", title: "Brännskada, hasled" },
  { value: "LM742", title: "Köldskada, hasled" },
  { value: "LM8", title: "Toxiska förändringar, hasled" },
  {
    value: "LM9",
    title: "Idiopatiska ospecificerade multifaktoriella, hasled",
  },
  { value: "LM911", title: "Slipped tendon" },
  { value: "LR", title: "Flera leder involverade/ospecifierad led" },
  {
    value: "LR0",
    title: "Normalvar, symtom u fastställd orsak, flera (ospec) leder",
  },
  { value: "LR00", title: "Normalvariation, flera (ospec) leder" },
  {
    value: "LR01",
    title: "Symtom på sjukdom u fastställd orsak , flera (ospec) leder",
  },
  { value: "LR011", title: "Smärta från flera (ospec) leder" },
  { value: "LR0111", title: "Smärta från flera (ospec) leder vid palpation" },
  {
    value: "LR0112",
    title: "Smärta från  flera (ospec) leder vid böjn/sträckn av led",
  },
  { value: "LR012", title: "Ansvällning av  flera (ospec) leder UNS" },
  {
    value: "LR013",
    title: "Ökad mängd ledvätska (galla) UNS, flera (ospec) leder",
  },
  { value: "LR014", title: "Stelhet i  flera (ospec) leder" },
  { value: "LR0141", title: "Ankylos i flera (ospec) leder" },
  {
    value: "LR0142",
    title: "Kontraktur i  flera (ospec) leder (ej muskelkontraktur)",
  },
  {
    value: "LR015",
    title: "Genomtrampning eller översträckning av  flera (ospec) leder",
  },
  {
    value: "LR016",
    title: "Ökad värme i  flera (ospec) leder utan närmare specifikation",
  },
  { value: "LR017", title: "Ökad rörlighet i  flera (ospec) leder UNS" },
  { value: "LR018", title: "Krepitation från  flera (ospec) leder UNS" },
  {
    value: "LR1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, flera (ospec) lede",
  },
  { value: "LR10", title: "Missbildning, flera (ospec) leder" },
  { value: "LR101", title: "Medfödd luxation av  flera (ospec) leder" },
  {
    value: "LR1011",
    title: "Permanent medfödd luxation av  flera (ospec) leder",
  },
  {
    value: "LR1012",
    title: "Habituell medfödd luxation av  flera (ospec) leder",
  },
  {
    value: "LR102",
    title: "Annan medfödd felställning av  flera (ospec) leder",
  },
  { value: "LR103", title: "Medfödd ankylos av flera (ospec) leder" },
  {
    value: "LR11",
    title: "Utvecklingsrubbn, tillväxtrubbn, flera (ospec) leder",
  },
  { value: "LR111", title: "Dysplasi av  flera (ospec) leder" },
  { value: "LR119", title: "Övrig utvecklingsrubbning i  flera (ospec) leder" },
  {
    value: "LR2",
    title: "Metabol, nutrit, degen/dystrof förändr, flera (ospec) leder",
  },
  { value: "LR20", title: "Metaboliska förändringar, flera (ospec) leder" },
  { value: "LR21", title: "Nutritionella förändringar flera (ospec) leder" },
  { value: "LR211", title: "Calcinosis circumscripta flera (ospec) leder" },
  {
    value: "LR22",
    title: "Degenerativa/dystrofiska förändringar, flera (ospec) leder",
  },
  { value: "LR221", title: "Degeneration av ledbrosk, flera (ospec) leder" },
  { value: "LR2211", title: "Kronisk artros/benpålagringar/ospecificerad led" },
  {
    value: "LR222",
    title: "Degeneration av ligament som berör  flera (ospec) leder",
  },
  {
    value: "LR2221",
    title: "Kroniskt deformerande artros, flera (ospec) leder",
  },
  { value: "LR223", title: "Synovial kondrometaplasi, flera (ospec) leder" },
  {
    value: "LR224",
    title: "Lös benbit (Ledmus) (ej sekundär till OCD), fl (ospec) leder",
  },
  { value: "LR225", title: "Meniskskada ej traumatisk, flera (ospec) leder" },
  { value: "LR23", title: "Hormonella förändringar, flera (ospec) leder" },
  { value: "LR3", title: "Cirkulatoriska förändringar, flera (ospec) leder" },
  { value: "LR30", title: "Blödningar, flera (ospec) leder" },
  { value: "LR31", title: "Ödem, flera (ospec) leder" },
  { value: "LR32", title: "Infarkter, flera (ospec) leder" },
  { value: "LR33", title: "Hygrom, flera (ospec) leder" },
  { value: "LR34", title: "Trombos / emboli, flera (ospec) leder" },
  {
    value: "LR35",
    title: "Arterio-atero-skleros förändringar, flera (ospec) leder",
  },
  { value: "LR4", title: "Infekt, infl förändringar, flera (ospec) leder" },
  {
    value: "LR40",
    title: "Specifika infektionssjukdomar, flera (ospec) leder",
  },
  { value: "LR41", title: "Akuta inflammationstillstånd, flera (ospec) leder" },
  { value: "LR411", title: "Serös / serofibrinös artrit, flera (ospec) leder" },
  { value: "LR412", title: "Purulent artrit, flera (ospec) leder" },
  {
    value: "LR413",
    title: "Specifika akuta infl tillstånd, flera (ospec) leder",
  },
  { value: "LR419", title: "Övriga akuta infl tillstånd, flera (ospec) leder" },
  {
    value: "LR42",
    title: "Kroniska inflammationstillstånd, flera (ospec) leder",
  },
  { value: "LR421", title: "Kroniska serös artrit, flera (ospec) leder" },
  { value: "LR422", title: "Kroniska purulent artrit, flera (ospec) leder" },
  {
    value: "LR423",
    title: "Kroniska abscess/fistel artrit, flera (ospec) leder",
  },
  {
    value: "LR424",
    title: "Specifika kroniska infl tillstånd, flera (ospec) leder",
  },
  {
    value: "LR429",
    title: "Övriga kroniska infl tillstånd, flera (ospec) leder",
  },
  { value: "LR43", title: "Parasitära sjukdomar, flera (ospec) leder" },
  { value: "LR5", title: "Immunmedierade tillstånd, flera (ospec) leder" },
  { value: "LR51", title: "Reumatoid artrit, flera (ospec) leder" },
  { value: "LR6", title: "Neoplastiska förändringar, flera (ospec) leder" },
  { value: "LR61", title: "Malign tumör i  flera (ospec) leder" },
  { value: "LR611", title: "Synovialt sarkom i  flera (ospec) leder" },
  { value: "LR612", title: "Kondrosarkom i  flera (ospec) leder" },
  { value: "LR62", title: "Benign tumör i  flera (ospec) leder" },
  { value: "LR621", title: "Synoviom i  flera (ospec) leder" },
  { value: "LR622", title: "Kondrom i  flera (ospec) leder" },
  { value: "LR69", title: "Tumör i  flera (ospec) leder UNS" },
  {
    value: "LR7",
    title: "Trauma, fr kr,  lägesförändr, termiska skador, fl (ospec) le",
  },
  {
    value: "LR71",
    title: "Traumatiska / mekaniska skador, flera (ospec) leder",
  },
  { value: "LR711", title: "Vrickning (distorsion) flera (ospec) leder" },
  { value: "LR712", title: "Ligamentruptur flera (ospec) leder" },
  { value: "LR7121", title: "Kollateralligament skada flera (ospec) leder" },
  { value: "LR7122", title: "Korsbandsskada flera (ospec) leder" },
  { value: "LR713", title: "Traumatisk meniskskada flera (ospec) leder" },
  { value: "LR714", title: "Sår öppen ledkapsel i flera (ospec) leder" },
  { value: "LR72", title: "Främmande kroppar flera (ospec) leder" },
  { value: "LR73", title: "Lägesförändringar flera (ospec) leder" },
  { value: "LR731", title: "Luxation flera (ospec) leder" },
  { value: "LR7311", title: "Subluxation av flera (ospec) leder" },
  { value: "LR7312", title: "Total luxation av  flera (ospec) leder" },
  { value: "LR74", title: "Termiska skador flera (ospec) leder" },
  { value: "LR741", title: "Brännskada flera (ospec) leder" },
  { value: "LR742", title: "Köldskada flera (ospec) leder" },
  { value: "LR8", title: "Toxiska förändringar flera (ospec) leder" },
  {
    value: "LR9",
    title: "Idiopatiska ospecificerade multifaktoriella fl (ospec) leder",
  },
  { value: "LX", title: "Led" },
  { value: "LX0", title: "Normalvar, symtom u fastställd orsak, led" },
  { value: "LX00", title: "Normalvariation, led" },
  { value: "LX01", title: "Symtom på sjukdom u fastställd orsak, led" },
  { value: "LX011", title: "Smärta från led" },
  { value: "LX0111", title: "Smärta från led vid palpation" },
  { value: "LX0112", title: "Smärta från led vid böjning/sträckning" },
  { value: "LX012", title: "Ansvällning av led" },
  { value: "LX013", title: "Galla i led (ökad mängd vätska i led)" },
  { value: "LX014", title: "Stelhet i led" },
  { value: "LX0141", title: "Ankylos i led" },
  { value: "LX0142", title: "Kontraktur i led (ej muskelkontraktur)" },
  { value: "LX015", title: "Genomtrampning eller översträckning av led" },
  { value: "LX016", title: "Ökad värme i led" },
  { value: "LX017", title: "Ökad rörlighet i led" },
  { value: "LX018", title: "Krepitation i led" },
  { value: "LX1", title: "Missbildn, utveckl rubbn, tillväxtrubbn, led" },
  { value: "LX10", title: "Missbildning av led" },
  { value: "LX101", title: "Medfödd luxation av led" },
  { value: "LX103", title: "Medfödd ankylos av led" },
  { value: "LX11", title: "Utvecklingsrubbning/tillväxtrubbning i led" },
  { value: "LX111", title: "Dysplasi av led" },
  { value: "LX1111", title: "Höftledsdysplasi (HD)" },
  { value: "LX1112", title: "Armbågsledsdysplasi (AD)" },
  { value: "LX1113", title: "Inkongruens i led" },
  { value: "LX1114", title: "Patellaluxation medial" },
  { value: "LX11141", title: "Patellaluxation medial vänster" },
  { value: "LX11142", title: "Patellaluxation medial höger" },
  { value: "LX11143", title: "Patellaluxation medial vänster och höger" },
  { value: "LX1115", title: "Patellaluxation lateral" },
  { value: "LX11151", title: "Patellaluxation lateral vänster" },
  { value: "LX11152", title: "Patellaluxation lateral höger" },
  { value: "LX11153", title: "Patellaluxation lateral vänster och höger" },
  { value: "LX2", title: "Metabol, nutrit, degen/dystrof förändr, led" },
  { value: "LX21", title: "Metaboliska förändringar i led" },
  { value: "LX211", title: "Calcinosis circumscripta i led" },
  { value: "LX22", title: "Degenerativa/dystrofiska förändringar i led" },
  { value: "LX221", title: "Degeneration av ledbrosk" },
  { value: "LX2211", title: "Kronisk artros" },
  { value: "LX2212", title: "Ringkota" },
  { value: "LX2213", title: "Slipfåror i ledbrosk" },
  { value: "LX2214", title: "Spatt" },
  { value: "LX2215", title: "Temporohyoid osteoartropati" },
  { value: "LX222", title: "Degeneration av ligament i led" },
  { value: "LX2221", title: "Ruptur av korsband, degenerativ orsak" },
  { value: "LX2222", title: "Ruptur av menisk, degenerativ orsak" },
  { value: "LX2223", title: "Degeneration av menisk" },
  { value: "LX223", title: "Synovial kondrometaplasi i led" },
  { value: "LX224", title: "Lös benbit (ledmus) i led (ej sek t OCD)" },
  { value: "LX3", title: "Cirkulatoriska förändringar, led" },
  { value: "LX30", title: "Blödning i led" },
  { value: "LX4", title: "Infekt, infl förändringar, led" },
  { value: "LX40", title: "Ledinflammation" },
  { value: "LX400", title: "Artrit/ledinflammation med specifikt agens" },
  { value: "LX41", title: "Akut atrit/ledinflammation" },
  { value: "LX411", title: "Akut serös/serofibrinös artrit" },
  { value: "LX412", title: "Akut varig ledinflammation" },
  { value: "LX413", title: "Akut septisk artrit" },
  { value: "LX42", title: "Kronisk artrit/ledinflammation" },
  { value: "LX421", title: "Serös/serofibrinös artrit" },
  { value: "LX422", title: "Varig ledinflammation" },
  { value: "LX423", title: "Kronisk septisk artrit" },
  { value: "LX424", title: "Specifika artriter/ledinflammationer" },
  { value: "LX4241", title: "Villonodulär synovit" },
  { value: "LX5", title: "Immunmedierade tillstånd, led" },
  { value: "LX51", title: "Immunmedierad artrit" },
  { value: "LX511", title: "Reumatoid artrit" },
  { value: "LX52", title: "Iatrogen artrit" },
  { value: "LX6", title: "Tumör i led" },
  { value: "LX61", title: "Malign tumör i led" },
  { value: "LX611", title: "Synovialt sarkom" },
  { value: "LX612", title: "Kondrosarkom i led" },
  { value: "LX62", title: "Benign tumör i led" },
  { value: "LX621", title: "Synoviom" },
  { value: "LX622", title: "Kondrom i led" },
  { value: "LX7", title: "Trauma, fr kr, lägeförändr, term skad, led" },
  { value: "LX71", title: "Traumatiska/mekaniska skador i led" },
  { value: "LX711", title: "Vrickning/distorsion" },
  { value: "LX712", title: "Ruptur av ligament i led" },
  { value: "LX7121", title: "Ruptur av kollateralligament" },
  { value: "LX7122", title: "Ruptur av korsband" },
  { value: "LX7123", title: "Ruptur av multipla ligament" },
  { value: "LX713", title: "Ruptur av menisk, traumatisk" },
  { value: "LX714", title: "Sårskada med ledaffektion" },
  { value: "LX715", title: "Ruptur av ledkapsel" },
  { value: "LX716", title: "Synovialbråck" },
  { value: "LX717", title: "Synoviafistel" },
  { value: "LX718", title: "Ledbroskkada" },
  { value: "LX7181", title: "Ledbroskkada traumatisk" },
  { value: "LX72", title: "Främmande kropp i led" },
  { value: "LX73", title: "Lägesförändring av led" },
  { value: "LX731", title: "Traumatisk luxation" },
  { value: "LX7311", title: "Traumatisk subluxation" },
  { value: "LX7312", title: "Traumatisk total luxation" },
  { value: "LX8", title: "Toxiska förändringar i led" },
  { value: "LX9", title: "Idiopatiska ospecificerade multifaktoriella, led" },
  {
    value: "M",
    title: "Muskulaturens, senornas, senskidornas och bursornas sjukd",
  },
  { value: "MA", title: "Muskulatur" },
  { value: "MA0", title: "Normalvar, symtom u fastställd orsak, muskelatur" },
  { value: "MA00", title: "Normalvariation muskulatur" },
  { value: "MA01", title: "Symtom på sjukdom u fastställd orsak, muskulatur" },
  { value: "MA011", title: "Muskelömhet" },
  { value: "MA012", title: "Symptom på fläkning" },
  {
    value: "MA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, muskulatur",
  },
  { value: "MA10", title: "Missbildning, muskulatur" },
  { value: "MA11", title: "Utvecklingsrubbn, tillväxtrubbn, muskulatur" },
  { value: "MA111", title: "Medfödd muskelkontraktur" },
  { value: "MA112", title: "Medfödd muskelatrofi" },
  { value: "MA113", title: "Medfödd myotoni" },
  { value: "MA114", title: "Medfödda muskelfiberförändringar" },
  { value: "MA2", title: "Metabol, nutrit, degen/dystrof förändr, muskulatur" },
  { value: "MA20", title: "Metaboliska förändringar, muskulatur" },
  { value: "MA201", title: "Myolys, myoglobinuri, korsförlamning" },
  { value: "MA21", title: "Nutritionella förändringar, muskulatur" },
  { value: "MA211", title: "Nutritionell muskeldegeneration" },
  { value: "MA22", title: "Degenerativa/dystrofiska förändringar, muskulatur" },
  { value: "MA221", title: "Muskelkontraktur" },
  { value: "MA222", title: "Muskelatrofi" },
  { value: "MA223", title: "Myotoni" },
  { value: "MA224", title: "Muskelfiberförändringar" },
  { value: "MA225", title: "Förvärvad muskelkontraktur" },
  { value: "MA2251", title: "Infraspinatus kontraktur" },
  { value: "MA23", title: "Hormonella förändringar, muskulatur" },
  { value: "MA3", title: "Cirkulatoriska förändringar, muskulatur" },
  { value: "MA30", title: "Blödningar, muskulatur" },
  { value: "MA31", title: "Ödem, muskulatur" },
  { value: "MA32", title: "Infarkter, muskulatur" },
  { value: "MA33", title: "Hygrom, muskulatur" },
  { value: "MA34", title: "Trombos / emboli, muskulatur" },
  { value: "MA35", title: "Arterio-atero-skleros förändringar, muskulatur" },
  { value: "MA36", title: 'Ischemisk nekros ("Downer cow syndrom")' },
  { value: "MA4", title: "Infekt, infl förändringar, muskulatur" },
  { value: "MA40", title: "Specifika infektionssjukdomar, muskulatur" },
  { value: "MA41", title: "Akuta inflammationstillstånd, muskulatur" },
  {
    value: "MA411",
    title: "Akut serös / serofibrinös inflammation, muskulatur",
  },
  { value: "MA412", title: "Akut purulent inflammation, muskulatur" },
  { value: "MA413", title: "Specifika akuta infl tillstånd, muskulatur" },
  { value: "MA419", title: "Övriga akuta infl tillstånd, muskulatur" },
  { value: "MA4191", title: "Akut myosit" },
  { value: "MA42", title: "Kroniska inflammationstillstånd, muskulatur" },
  { value: "MA421", title: "Kronisk serös inflammation, muskulatur" },
  { value: "MA422", title: "Kronisk purulent inflammation, muskulatur" },
  { value: "MA423", title: "Abscess/fistel, muskulatur" },
  { value: "MA4231", title: "Abscess i muskel" },
  { value: "MA424", title: "Specifika kroniska infl tillstånd, muskulatur" },
  { value: "MA429", title: "Övriga kroniska infl tillstånd, muskulatur" },
  { value: "MA4291", title: "Kronisk myosit" },
  { value: "MA4292", title: "Muskelnekros" },
  { value: "MA43", title: "Parasitära sjukdomar, muskulatur" },
  { value: "MA5", title: "Immunmedierade tillstånd, muskulatur" },
  { value: "MA51", title: "Eosinofil myosit" },
  { value: "MA6", title: "Neoplastiska förändringar, muskulatur" },
  { value: "MA7", title: "Trauma, fr kr, lägeförändr, term skad, muskulatur" },
  { value: "MA71", title: "Traumatiska / mekaniska skador, muskulatur" },
  { value: "MA711", title: "Muskelskador pga sårskador" },
  { value: "MA712", title: "Muskelkontusion" },
  { value: "MA713", title: "Muskelsträckning" },
  { value: "MA714", title: "Traumatisk muskelruptur" },
  { value: "MA7141", title: "Ruptur av m gracilis" },
  { value: "MA7142", title: "Fläkning" },
  { value: "MA72", title: "Främmande kroppar i muskelatur" },
  { value: "MA73", title: "Lägesförändringar, muskulatur" },
  { value: "MA74", title: "Termiska skador, muskulatur" },
  { value: "MA741", title: "Brännskada, muskulatur" },
  { value: "MA742", title: "Köldskada, muskulatur" },
  { value: "MA8", title: "Toxiska förändringar, muskulatur" },
  {
    value: "MA9",
    title: "Idiopatiska ospecificerade multifaktoriella, muskulatur",
  },
  { value: "MB", title: "Senor, senskidor, bursor;" },
  {
    value: "MB0",
    title: "Normalvar, symtom u fastställd orsak, sena, senskida, bursa",
  },
  { value: "MB00", title: "Normalvariation, sena, senskida, bursa" },
  {
    value: "MB01",
    title: "Symtom på sjukdom u fastställd orsak, sena, senskida, bursa",
  },
  { value: "MB011", title: "Galla i senskida UNS" },
  {
    value: "MB1",
    title: "Missbildn, utveckl.rubbn, tillväxtrubbn, sena/senskida/bursa",
  },
  { value: "MB10", title: "Missbildning, sena, senskida, bursa" },
  {
    value: "MB11",
    title: "Utvecklingsrubbn, tillväxtrubbn, sena, senskida, bursa",
  },
  { value: "MB111", title: "Senkontraktur" },
  {
    value: "MB2",
    title: "Metabol, nutrit, degen/dystrof förändr, sena/senskida, bursa",
  },
  { value: "MB20", title: "Metaboliska förändringar, sena, senskida, bursa" },
  { value: "MB21", title: "Nutritionella förändringar, sena, senskida, bursa" },
  {
    value: "MB22",
    title: "Degenerativa/dystrofiska förändringar, sena, senskida, bursa",
  },
  { value: "MB23", title: "Hormonella förändringar, sena, senskida, bursa" },
  { value: "MB3", title: "Cirkulatoriska förändringar, sena, senskida, bursa" },
  { value: "MB30", title: "Blödningar, sena, senskida, bursa" },
  { value: "MB31", title: "Ödem, sena, senskida, bursa" },
  { value: "MB32", title: "Infarkter, sena, senskida, bursa" },
  { value: "MB33", title: "Hygrom, sena, senskida, bursa" },
  { value: "MB34", title: "Trombos / emboli, sena, senskida, bursa" },
  {
    value: "MB35",
    title: "Arterio-atero-skleros förändringar, sena, senskida, bursa",
  },
  { value: "MB4", title: "Infekt, infl förändringar, sena, senskida, bursa" },
  {
    value: "MB40",
    title: "Specifika infektionssjukdomar, sena, senskida, bursa",
  },
  {
    value: "MB41",
    title: "Akuta inflammationstillstånd, sena, senskida, bursa",
  },
  {
    value: "MB411",
    title: "Serös / serofibrinös inflammation i sena /senskida /bursa",
  },
  {
    value: "MB412",
    title: "Akut purulent inflammation i sena /senskida /bursa",
  },
  {
    value: "MB413",
    title: "Specifikt akuta infl tillstånd, sena, senskida, bursa",
  },
  {
    value: "MB419",
    title: "Övriga akuta infl tillstånd, sena, senskida, bursa",
  },
  { value: "MB4191", title: "Akut tendovaginit" },
  { value: "MB4192", title: "Akut bursit" },
  { value: "MB41921", title: "Akut piphas" },
  {
    value: "MB42",
    title: "Kroniskt inflammationstillstånd, sena, senskida, bursa",
  },
  {
    value: "MB421",
    title: "Kronisk serös inflammation, sena, senskida, bursa",
  },
  { value: "MB422", title: "Purulent inflammation, sena, senskida, bursa" },
  { value: "MB423", title: "Abscess/fistel, sena, senskida, bursa" },
  {
    value: "MB424",
    title: "Specifika kroniska infl tillstånd, sena, senskida, bursa",
  },
  {
    value: "MB429",
    title: "Övriga kroniska infl tillstånd, sena, senskida, bursa",
  },
  { value: "MB4291", title: "Kronisk tendovaginit" },
  { value: "MB4292", title: "Kronisk bursit" },
  { value: "MB42921", title: "Kronisk piphas" },
  { value: "MB43", title: "Parasitära sjukdomar, sena, senskida, bursa" },
  { value: "MB5", title: "Immunmedierade tillstånd, sena, senskida, bursa" },
  { value: "MB6", title: "Neoplastiska förändringar, sena, senskida, bursa" },
  {
    value: "MB7",
    title: "Trauma, fr kr, lägeförändr, term skad, sena, senskida, bursa",
  },
  {
    value: "MB71",
    title: "Traumatiska / mekaniska skador, sena, senskida, bursa",
  },
  { value: "MB711", title: "Tendinit" },
  { value: "MB7111", title: "Tendinit i ytliga böjsenan" },
  { value: "MB7112", title: "Tendinit i djupa böjsenan" },
  { value: "MB7113", title: "Tendinit  i djupa böjsenans förstärkningsband" },
  { value: "MB7114", title: "Tendinit  i gaffelband" },
  { value: "MB712", title: "Ligamentös spatt" },
  { value: "MB713", title: "Harspatt (försträckning av lig. plantare)" },
  { value: "MB714", title: "Ringbandsförträngning (annularligament)" },
  { value: "MB715", title: "Senruptur" },
  { value: "MB7151", title: "Ruptur av patellas raka band" },
  { value: "MB7152", title: "Ruptur av senan till m gastroknemius" },
  { value: "MB7153", title: "Ruptur av senan till m biceps brachii" },
  { value: "MB716", title: "Precarpalbursit" },
  { value: "MB717", title: "Hasledsbursit" },
  { value: "MB719", title: "Övriga senskador" },
  { value: "MB72", title: "Främmande kroppar, sena, senskida, bursa" },
  { value: "MB73", title: "Lägesförändringar, sena, senskida, bursa" },
  { value: "MB731", title: "Luxation av ytliga böjsenan vid tuber calcanei" },
  { value: "MB74", title: "Termiska skador, sena, senskida, bursa" },
  { value: "MB741", title: "Brännskada, sena, senskida, bursa" },
  { value: "MB742", title: "Köldskada, sena, senskida, bursa" },
  { value: "MB8", title: "Toxiska förändringar, sena, senskida, bursa" },
  {
    value: "MB9",
    title: "Idiopatiska ospecif multifaktoriella, sena, senskida, bursa",
  },
  { value: "MB91", title: "Spastisk pares" },
  { value: "MM", title: "Muskulatur" },
  {
    value: "MM0",
    title: "Normalvariation, symtom u fastställd orsak, muskulatur",
  },
  { value: "MM00", title: "Normalvariation muskulatur" },
  { value: "MM01", title: "Symtom på sjukdom u fastställd orsak, muskulatur" },
  { value: "MM011", title: "Symtom på muskelömhet" },
  { value: "MM012", title: "Symtom på fläkning" },
  { value: "MM013", title: "Symtom på muskelsvaghet" },
  { value: "MM014", title: "Symtom på muskelskada" },
  { value: "MM015", title: "Vattensvans/watertail" },
  {
    value: "MM1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, muskulatur",
  },
  { value: "MM11", title: "Medfödd muskelkontraktur" },
  { value: "MM12", title: "Medfödd muskelatrofi" },
  { value: "MM13", title: "Medfödd myotoni" },
  { value: "MM14", title: "Medfödda muskelfiberförändringar" },
  { value: "MM15", title: "Upplagringssjukdomar (storage disease)" },
  { value: "MM151", title: "Hyperkalemic Periodic Paralysis (HYPP)" },
  { value: "MM152", title: "Polysaccharide Storage Myopathy (PSSM)" },
  { value: "MM2", title: "Metabol, nutrit, degen/dystrof förändr, muskulatur" },
  { value: "MM20", title: "Metaboliska förändringar, muskulatur" },
  { value: "MM201", title: "Korsförlamning" },
  { value: "MM21", title: "Nutritionella förändringar, muskulatur" },
  { value: "MM211", title: "Nutritionell muskeldegeneration" },
  { value: "MM22", title: "Degenerativa/dystrofiska förändringar, muskulatur" },
  { value: "MM220", title: "Muskeldegeneration" },
  { value: "MM2201", title: "Selen/vitamin-E brist (muskeldegeneration)" },
  { value: "MM2211", title: "Fibrotisk kontraktur av muskel" },
  { value: "MM222", title: "Muskelatrofi" },
  { value: "MM2221", title: "Inaktivitetsatrofi av muskulatur" },
  { value: "MM2222", title: "Neurogen muskelatrofi" },
  { value: "MM223", title: "Myotoni" },
  { value: "MM224", title: "Muskelfiberförändringar" },
  { value: "MM23", title: "Hormonella förändringar, muskulatur" },
  { value: "MM231", title: "Hormonell myotoni" },
  { value: "MM3", title: "Cirkulatoriska förändringar, muskulatur" },
  { value: "MM30", title: "Blödning i muskulatur" },
  { value: "MM31", title: "Ödem i muskulatur" },
  { value: "MM32", title: "Infarkt i muskulatur" },
  { value: "MM33", title: "Hygrom i muskulatur" },
  { value: "MM34", title: "Trombos/emboli i muskulatur" },
  { value: "MM36", title: "Hypoxi i muskulatur" },
  { value: "MM361", title: "Hypoxi i muskulatur pga kärlanomali" },
  { value: "MM37", title: "Ischemisk nekros i muskulatur" },
  { value: "MM4", title: "Infekt, infl förändringar, muskulatur" },
  { value: "MM41", title: "Myosit, akut" },
  { value: "MM42", title: "Myosit, kronisk" },
  { value: "MM421", title: "Abscess i muskel" },
  { value: "MM43", title: "Nekros i muskel pga inflammation" },
  { value: "MM431", title: "Kronisk atrofisk myosit" },
  { value: "MM432", title: "Kronisk fibrotisk myosit" },
  { value: "MM45", title: "Parasitära sjukdomar i muskulatur" },
  { value: "MM5", title: "Immunmedierade tillstånd, muskulatur" },
  { value: "MM51", title: "Eosinofil myosit" },
  { value: "MM52", title: "Tuggmuskelmyosit" },
  { value: "MM53", title: "Immunmedierad myosit" },
  { value: "MM6", title: "Tumör i muskulatur" },
  { value: "MM61", title: "Tumör i muskel" },
  { value: "MM7", title: "Trauma, fr kr, lägeförändr, term skad, muskulatur" },
  { value: "MM71", title: "Traumatiska/mekaniska skador, muskulatur" },
  { value: "MM710", title: "Muskelbristning/ruptur av muskel" },
  { value: "MM711", title: "Muskelskador pga sårskador" },
  { value: "MM712", title: "Muskelkontusion" },
  { value: "MM713", title: "Muskelsträckning" },
  { value: "MM714", title: "Fläkning" },
  { value: "MM715", title: "Muskelkontraktur, förvärvad" },
  { value: "MM72", title: "Främmande kroppar i muskulatur" },
  { value: "MM74", title: "Termiska skador, muskulatur" },
  { value: "MM741", title: "Brännskada, muskulatur" },
  { value: "MM742", title: "Köldskada, muskulatur" },
  { value: "MMA8", title: "Toxiska förändringar, muskulatur" },
  {
    value: "MMA9",
    title: "Idiopatiska ospecificerade multifaktoriella, muskulatur",
  },
  { value: "MS", title: "Senor, senskidor, bursor" },
  {
    value: "MS0",
    title: "Normalvar, symtom u fastställd orsak, sena, senskida, bursa",
  },
  { value: "MS00", title: "Normalvariation, sena, senskida, bursa" },
  {
    value: "MS01",
    title: "Symtom på sjukdom u fastställd orsak, sena, senskida, bursa",
  },
  { value: "MS011", title: "Galla i senskida" },
  {
    value: "MS1",
    title: "Missbildn, utveckl.rubbn, tillväxtrubbn, sena/senskida/bursa",
  },
  { value: "MS10", title: "Missbildning, sena, senskida, bursa" },
  {
    value: "MS11",
    title: "Utvecklingsrubbn, tillväxtrubbn, sena, senskida, bursa",
  },
  { value: "MS111", title: "Senkontraktur" },
  { value: "MS1111", title: "Bockhov (kontraktur/kort böjsena)" },
  {
    value: "MS2",
    title: "Metabol, nutrit, degen/dystrof förändr, sena/senskida, bursa",
  },
  {
    value: "MS22",
    title: "Degenerativa/dystrofiska förändringar, sena, senskida, bursa",
  },
  { value: "MS221", title: "Eftergift i sena (genomtrampning)" },
  {
    value: "MS2211",
    title: "Björnfot, eftergift i gaffelband (genomtrampning)",
  },
  { value: "MS3", title: "Cirkulatoriska förändringar, sena, senskida, bursa" },
  { value: "MS30", title: "Blödningar, sena, senskida, bursa" },
  { value: "MS31", title: "Ödem, sena, senskida, bursa" },
  { value: "MS33", title: "Hygrom, sena, senskida, bursa" },
  { value: "MS4", title: "Infekt, infl förändringar, sena, senskida, bursa" },
  {
    value: "MS41",
    title: "Akut serös/serofibrinös inflammation i sena/senskida/bursa",
  },
  {
    value: "MS411",
    title: "Akut serös/serofibrinös inflammation i sena/senskida",
  },
  { value: "MS412", title: "Akut serös/serofibrinös inflammation i bursa" },
  { value: "MS4121", title: "Akut piphas" },
  { value: "MS413", title: "Akut purulent inflammation i sena/senskida/bursa" },
  { value: "MS4131", title: "Akut varig inflammation i senskida" },
  { value: "MS4132", title: "Akut varig inflammation i bursa" },
  { value: "MS414", title: "Akut fistel/abscess i sena/senskida" },
  { value: "MS415", title: "Akut fistel/abscess i bursa" },
  {
    value: "MS42",
    title: "Kronisk serös/serofibrinös infl i sena/senskida/bursa",
  },
  { value: "MS421", title: "Kronisk tendovaginit" },
  { value: "MS422", title: "Bursit, kronisk" },
  { value: "MS4221", title: "Piphas" },
  {
    value: "MS423",
    title: "Kronisk purulent inflammation i sena /senskida /bursa",
  },
  { value: "MS4231", title: "Kronisk varig inflammation i senskida" },
  { value: "MS4232", title: "Kronisk varig inflammation i bursa" },
  { value: "MS424", title: "Kronisk fistel/abscess i sena/senskida" },
  { value: "MS425", title: "Kronisk fistel/abscess i bursa" },
  { value: "MS5", title: "Immunmedierade tillstånd, sena, senskida, bursa" },
  { value: "MS6", title: "Tumör i sena, senskida, bursa" },
  {
    value: "MS7",
    title: "Trauma, fr kr, lägeförändr, term skad, sena, senskida, bursa",
  },
  {
    value: "MS71",
    title: "Traumatiska/mekaniska skador, sena, senskida, bursa",
  },
  { value: "MS711", title: "Tendinit" },
  { value: "MS7111", title: "Tendinit i ytliga böjsenan" },
  { value: "MS7112", title: "Tendinit i djupa böjsenan" },
  { value: "MS7113", title: "Tendinit i djupa böjsenans förstärkningsband" },
  { value: "MS7114", title: "Tendinit i gaffelband" },
  { value: "MS712", title: "Ligamentös spatt" },
  { value: "MS713", title: "Harspatt (försträckning av lig plantare)" },
  { value: "MS714", title: "Ringbandsförträngning (annularligament)" },
  { value: "MS715", title: "Ruptur av sena" },
  { value: "MS716", title: "Bursit (sek. till trauma)" },
  { value: "MS7161", title: "Slemsäcksinflammation, carpalled" },
  { value: "MS7162", title: "Slemsäcksinflammation, hasled" },
  { value: "MS7163", title: "Armbågsbursit" },
  { value: "MS717", title: "Sårskada sena, senskida, bursa" },
  { value: "MS7171", title: "Sårskada till sena" },
  { value: "MS71711", title: "Sårskada med avskuren sena" },
  { value: "MS7172", title: "Sårskada till senskida" },
  { value: "MS7173", title: "Sårskada till bursa" },
  { value: "MS719", title: "Ärrstriktur i sena pga trauma" },
  { value: "MS72", title: "Främmande kropp, sena, senskida, bursa" },
  { value: "MS73", title: "Lägesförändring, sena, senskida, bursa" },
  { value: "MS731", title: "Luxation av ytliga böjsenan vid tuber calcanei" },
  { value: "MS74", title: "Termisk skada, sena, senskida, bursa" },
  { value: "MS741", title: "Brännskada, sena, senskida, bursa" },
  { value: "MS742", title: "Köldskada, sena, senskida, bursa" },
  {
    value: "MS75",
    title: "Traumatiskt orsakad eftergift i ligament och senor",
  },
  { value: "MS8", title: "Toxiska förändringar, sena, senskida, bursa" },
  {
    value: "MS9",
    title: "Idiopatiska ospecif multifaktoriella, sena, senskida, bursa",
  },
  { value: "N", title: "Nervsystemets sjukdomar" },
  { value: "NA", title: "Storhjärna" },
  { value: "NA0", title: "Normalvar, symtom u fastställd orsak, storhjärna" },
  { value: "NA00", title: "Normalvariation, storhjärna" },
  { value: "NA01", title: "Symtom på sjukdom u fastställd orsak, storhjärna" },
  { value: "NA011", title: "Symtom påblindhet från CNS" },
  {
    value: "NA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, storhjärna",
  },
  { value: "NA10", title: "Missbildning, storhjärna" },
  { value: "NA101", title: "Vattenskalle (hydrocefalus)" },
  { value: "NA11", title: "Utvecklingsrubbn, tillväxtrubbn, storhjärna" },
  { value: "NA111", title: "Skotte kramp" },
  { value: "NA2", title: "Metabol, nutrit, degen/dystrof förändr, storhjärna" },
  { value: "NA20", title: "Metaboliska förändringar, storhjärna" },
  { value: "NA201", title: "Lysosomala upplagringssjukdomar" },
  { value: "NA2011", title: "Ceroid lipofuscinos, storhjärna" },
  { value: "NA202", title: "Neuronal abiotrofi, storhjärna" },
  { value: "NA21", title: "Nutritionella förändringar, storhjärna" },
  {
    value: "NA211",
    title: "CCN (Cerebrocortikal nekros) se även AA21315 (thiaminbrist)",
  },
  { value: "NA22", title: "Degenerativa/dystrofiska förändringar, storhjärna" },
  { value: "NA221", title: "Neuronal degeneration i storhjärna" },
  { value: "NA222", title: "Märgskide / axon degeneration i storhjärna" },
  { value: "NA223", title: "Storhjärn nekros" },
  { value: "NA224", title: "Stödjevävnadsdegeneration i storhjärna" },
  { value: "NA23", title: "Hormonella förändringar, storhjärna" },
  { value: "NA3", title: "Cirkulatoriska förändringar, storhjärna" },
  { value: "NA30", title: "Blödningar, storhjärna" },
  { value: "NA301", title: "Suduralt / epiduralt hematom, storhjärna" },
  { value: "NA302", title: "Subaraknoidal blödning, storhjärna" },
  { value: "NA303", title: "Blödning i hjärnvävnad, storhjärna" },
  { value: "NA31", title: "Ödem, storhjärna" },
  { value: "NA32", title: "Infarkter, storhjärna" },
  { value: "NA34", title: "Trombos / emboli, storhjärna" },
  { value: "NA35", title: "Arterio-atero-skleros förändringar, storhjärna" },
  { value: "NA4", title: "Infekt, infl förändringar, storhjärna" },
  { value: "NA40", title: "Specifika infektionssjukdomar, storhjärna" },
  { value: "NA41", title: "Akuta inflammationstillstånd, storhjärna" },
  {
    value: "NA411",
    title: "Akut serös / serofibrinös inflammation, storhjärna",
  },
  { value: "NA412", title: "Akut purulent inflammation, storhjärna" },
  { value: "NA413", title: "Specifika akuta infl tillstånd, storhjärna" },
  { value: "NA419", title: "Övriga akuta infl tillstånd, storhjärna" },
  { value: "NA42", title: "Kroniska inflammationstillstånd, storhjärna" },
  { value: "NA421", title: "Kroniskt serös inflammation, storhjärna" },
  { value: "NA422", title: "Purulent inflammation, storhjärna" },
  { value: "NA423", title: "Abscess/fistel, storhjärna" },
  { value: "NA424", title: "Specifika kroniska infl tillstånd, storhjärna" },
  { value: "NA429", title: "Övriga kroniska infl tillstånd, storhjärna" },
  { value: "NA43", title: "Parasitära sjukdomar, storhjärna" },
  { value: "NA5", title: "Immunmedierade tillstånd, storhjärna" },
  { value: "NA6", title: "Neoplastiska förändringar, storhjärna" },
  { value: "NA7", title: "Trauma, fr kr, lägeförändr, term skad, storhjärna" },
  { value: "NA71", title: "Traumatiska / mekaniska skador, storhjärna" },
  { value: "NA711", title: "Storhjärnskompression UNS" },
  { value: "NA72", title: "Främmande kroppar, storhjärna" },
  { value: "NA73", title: "Lägesförändringar, storhjärna" },
  { value: "NA8", title: "Toxiska förändringar, storhjärna" },
  { value: "NA81", title: "Endogena toxiner, storhjärna" },
  { value: "NA811", title: "Hepatisk encefalopati" },
  { value: "NA812", title: "Uremisk encefalopati" },
  { value: "NA9", title: "Idiopatiska, ospecif, multifakt, storhjärna" },
  { value: "NA91", title: "Idiopatisk epilepsi" },
  { value: "NB", title: "Lillhjärna" },
  { value: "NB0", title: "Normalvar, symtom u fastställd orsak, lillhjärna" },
  { value: "NB00", title: "Normalvariation, lillhjärna" },
  { value: "NB01", title: "Symtom på sjukdom u fastställd orsak, lillhjärna" },
  {
    value: "NB1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, lillhjärna",
  },
  { value: "NB10", title: "Missbildning, lillhjärna" },
  { value: "NB101", title: "Hypoplasi av lillhjärna" },
  { value: "NB11", title: "Utvecklingsrubbn, tillväxtrubbn, lillhjärna" },
  { value: "NB2", title: "Metabol, nutrit, degen/dystrof förändr, lillhjärna" },
  { value: "NB20", title: "Metaboliska förändringar, lillhjärna" },
  { value: "NB201", title: "Neuronal abiotrofi, lillhjärna" },
  { value: "NB2011", title: "Hereditär cerebellär neuronal abiotrofi" },
  { value: "NB2012", title: "Hereditär spino-cerebellär degeneration" },
  { value: "NB21", title: "Nutritionella förändringar, lillhjärna" },
  { value: "NB22", title: "Degenerativa/dystrofiska förändringar, lillhjärna" },
  { value: "NB221", title: "Neuronal degeneration i lillhjärna" },
  { value: "NB2211", title: "Purkinjecellsdegeneration i lillhjärna" },
  { value: "NB222", title: "Märgskide / axon degeneration i lillhjärna" },
  { value: "NB223", title: "Lillhjärn nekros" },
  { value: "NB224", title: "Stödjevävnadsdegeneration i lillhjärna" },
  { value: "NB23", title: "Hormonella förändringar, lillhjärna" },
  { value: "NB3", title: "Cirkulatoriska förändringar, lillhjärna" },
  { value: "NB30", title: "Blödningar, lillhjärna" },
  { value: "NB31", title: "Ödem, lillhjärna" },
  { value: "NB32", title: "Infarkter, lillhjärna" },
  { value: "NB34", title: "Trombos / emboli, lillhjärna" },
  { value: "NB35", title: "Arterio-atero-skleros förändringar, lillhjärna" },
  { value: "NB4", title: "Infekt, infl förändringar, lillhjärna" },
  { value: "NB40", title: "Specifika infektionssjukdomar, lillhjärna" },
  { value: "NB41", title: "Akuta inflammationstillstånd, lillhjärna" },
  {
    value: "NB411",
    title: "Akut serös / serofibrinös inflammation, lillhjärna",
  },
  { value: "NB412", title: "Akut purulent inflammation, lillhjärna" },
  { value: "NB413", title: "Specifika akuta infl tillstånd, lillhjärna" },
  { value: "NB419", title: "Övriga akuta infl tillstånd, lillhjärna" },
  { value: "NB42", title: "Kroniska inflammationstillstånd, lillhjärna" },
  { value: "NB421", title: "Kronisk serös inflammation, lillhjärna" },
  { value: "NB422", title: "Purulent inflammation, lillhjärna" },
  { value: "NB423", title: "Abscess/fistel, lillhjärna" },
  { value: "NB424", title: "Specifika kroniska infl tillstånd, lillhjärna" },
  { value: "NB429", title: "Övriga kroniska infl tillstånd, lillhjärna" },
  { value: "NB43", title: "Parasitära sjukdomar, lillhjärna" },
  { value: "NB5", title: "Immunmedierade tillstånd, lillhjärna" },
  { value: "NB6", title: "Neoplastiska förändringar, lillhjärna" },
  { value: "NB7", title: "Trauma, fr kr, lägeförändr, term skad, lillhjärna" },
  { value: "NB71", title: "Traumatiska / mekaniska skador, lillhjärna" },
  { value: "NB711", title: "Lillhjärnskompression UNS" },
  { value: "NB72", title: "Främmande kroppar, lillhjärna" },
  { value: "NB73", title: "Lägesförändringar, lillhjärna" },
  { value: "NB8", title: "Toxiska förändringar, lillhjärna" },
  { value: "NB9", title: "Idiopatiska, ospecif, multifakt, lillhjärna" },
  { value: "NC", title: "Vestibularisorgan" },
  {
    value: "NC0",
    title: "Normalvar, symtom u fastställd orsak, vetsibularisorgan",
  },
  { value: "NC00", title: "Normalvariation, vetsibularisorgan" },
  {
    value: "NC01",
    title: "Symtom på sjukdom u fastställd orsak, vetsibularisorgan",
  },
  { value: "NC011", title: "Vestibularissyndrom enkelsidigt" },
  { value: "NC012", title: "Vestibularissyndrom dubbelsidigt" },
  { value: "NC013", title: "Balansrubbning, okänd orsak" },
  {
    value: "NC1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, vetsibularisorgan",
  },
  { value: "NC10", title: "Missbildning, vetsibularisorgan" },
  {
    value: "NC11",
    title: "Utvecklingsrubbn, tillväxtrubbn, vetsibularisorgan",
  },
  {
    value: "NC2",
    title: "Metabol, nutrit, degen/dystrof förändr, vetsibularisorgan",
  },
  { value: "NC20", title: "Metaboliska förändringar, vetsibularisorgan" },
  { value: "NC21", title: "Nutritionella förändringar, vetsibularisorgan" },
  {
    value: "NC22",
    title: "Degenerativa/dystrofiska förändringar, vetsibularisorgan",
  },
  { value: "NC23", title: "Hormonella förändringar, vetsibularisorgan" },
  { value: "NC3", title: "Cirkulatoriska förändringar, vetsibularisorgan" },
  { value: "NC30", title: "Blödningar, vetsibularisorgan" },
  { value: "NC31", title: "Ödem, vetsibularisorgan" },
  { value: "NC32", title: "Infarkter, vetsibularisorgan" },
  { value: "NC34", title: "Trombos / emboli, vetsibularisorgan" },
  {
    value: "NC35",
    title: "Arterio-atero-skleros förändringar, vetsibularisorgan",
  },
  { value: "NC4", title: "Infekt, infl förändringar, vetsibularisorgan" },
  { value: "NC40", title: "Specifika infektionssjukdomar, vetsibularisorgan" },
  { value: "NC41", title: "Akuta inflammationstillstånd, vetsibularisorgan" },
  { value: "NC411", title: "Akut serösa / serofibrinösa, vetsibularisorgan" },
  { value: "NC412", title: "Akut purulent inflammation, vetsibularisorgan" },
  {
    value: "NC413",
    title: "Specifika akuta infl tillstånd, vetsibularisorgan",
  },
  { value: "NC419", title: "Övriga akuta infl tillstånd, vetsibularisorgan" },
  {
    value: "NC42",
    title: "Kroniska inflammationstillstånd, vetsibularisorgan",
  },
  { value: "NC421", title: "Kronisk serös inflammation, vetsibularisorgan" },
  { value: "NC422", title: "Purulent inflammation, vetsibularisorgan" },
  { value: "NC423", title: "Abscess/fistel, vetsibularisorgan" },
  {
    value: "NC424",
    title: "Specifika kroniska infl tillstånd, vetsibularisorgan",
  },
  {
    value: "NC429",
    title: "Övriga kroniska infl tillstånd, vetsibularisorgan",
  },
  { value: "NC43", title: "Parasitära sjukdomar, vetsibularisorgan" },
  { value: "NC5", title: "Immunmedierade tillstånd" },
  { value: "NC6", title: "Neoplastiska förändringar, vetsibularisorgan" },
  {
    value: "NC7",
    title: "Trauma, fr kr, lägeförändr, term skad, vetsibularisorgan",
  },
  { value: "NC71", title: "Traumatiska / mekaniska skador, vetsibularisorgan" },
  { value: "NC72", title: "Främmande kroppar, vetsibularisorgan" },
  { value: "NC73", title: "Lägesförändringar, vetsibularisorgan" },
  { value: "NC8", title: "Toxiska förändringar, vetsibularisorgan" },
  { value: "NC9", title: "Idiopatiska, ospecif, multifakt, vetsibularisorgan" },
  { value: "ND", title: "Hjärnstam" },
  { value: "ND0", title: "Normalvar, symtom u fastställd orsak, hjärnstam" },
  { value: "ND00", title: "Normalvariation, hjärnstam" },
  { value: "ND01", title: "Symtom på sjukdom u fastställd orsak, hjärnstam" },
  {
    value: "ND1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, hjärnstam",
  },
  { value: "ND10", title: "Missbildning, hjärnstam" },
  { value: "ND11", title: "Utvecklingsrubbn, tillväxtrubbn, hjärnstam" },
  { value: "ND2", title: "Metabol, nutrit, degen/dystrof förändr, hjärnstam" },
  { value: "ND20", title: "Metaboliska förändringar, hjärnstam" },
  { value: "ND21", title: "Nutritionella förändringar, hjärnstam" },
  { value: "ND22", title: "Degenerativa/dystrofiska förändringar, hjärnstam" },
  { value: "ND23", title: "Hormonella förändringar, hjärnstam" },
  { value: "ND3", title: "Cirkulatoriska förändringar, hjärnstam" },
  { value: "ND30", title: "Blödningar, hjärnstam" },
  { value: "ND31", title: "Ödem, hjärnstam" },
  { value: "ND32", title: "Infarkter, hjärnstam" },
  { value: "ND34", title: "Trombos / emboli, hjärnstam" },
  { value: "ND35", title: "Arterio-atero-skleros förändringar, hjärnstam" },
  { value: "ND4", title: "Infekt, infl förändringar, hjärnstam" },
  { value: "ND40", title: "Specifika infektionssjukdomar, hjärnstam" },
  {
    value: "ND401",
    title: "FSE (Fokal sym.encephalomalaci Cl.perfr typ D,enterotoxin)",
  },
  { value: "ND41", title: "Akuta inflammationstillstånd, hjärnstam" },
  {
    value: "ND411",
    title: "Akut serös / serofibrinös inflammation, hjärnstam",
  },
  { value: "ND412", title: "Akut purulent inflammation, hjärnstam" },
  { value: "ND413", title: "Specifika akuta infl tillstånd, hjärnstam" },
  { value: "ND419", title: "Övriga akuta infl tillstånd, hjärnstam" },
  { value: "ND42", title: "Kroniska inflammationstillstånd, hjärnstam" },
  { value: "ND421", title: "Kronisk serös inflammation, hjärnstam" },
  { value: "ND422", title: "Purulent inflammation, hjärnstam" },
  { value: "ND423", title: "Abscess/fistel, hjärnstam" },
  { value: "ND424", title: "Specifika kroniska infl tillstånd, hjärnstam" },
  { value: "ND429", title: "Övriga kroniska infl tillstånd, hjärnstam" },
  { value: "ND43", title: "Parasitära sjukdomar, hjärnstam" },
  { value: "ND5", title: "Immunmedierade tillstånd, hjärnstam" },
  { value: "ND6", title: "Neoplastiska förändringar, hjärnstam" },
  { value: "ND7", title: "Trauma, fr kr, lägeförändr, term skad, hjärnstam" },
  { value: "ND71", title: "Traumatiska / mekaniska skador, hjärnstam" },
  { value: "ND72", title: "Främmande kroppar, hjärnstam" },
  { value: "ND73", title: "Lägesförändringar, hjärnstam" },
  { value: "ND8", title: "Toxiska förändringar, hjärnstam" },
  { value: "ND9", title: "Idiopatiska, ospecif, multifakt, hjärnstam" },
  { value: "NE", title: "Ryggmärg, ryggmärgsrötter, cauda equina" },
  { value: "NE0", title: "Normalvar, symtom u fastställd orsak, ryggmärg" },
  { value: "NE00", title: "Normalvariation, ryggmärg" },
  { value: "NE01", title: "Symtom på sjukdom u fastställd orsak, ryggmärg" },
  { value: "NE1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, ryggmärg" },
  { value: "NE10", title: "Missbildning, ryggmärg" },
  { value: "NE101", title: "Spinal dysplai" },
  { value: "NE102", title: "Syringomyeli" },
  { value: "NE103", title: "Ryggmärgsbråck" },
  { value: "NE11", title: "Utvecklingsrubbn, tillväxtrubbn, ryggmärg" },
  { value: "NE2", title: "Metabol, nutrit, degen/dystrof förändr, ryggmärg" },
  { value: "NE20", title: "Metaboliska förändringar, ryggmärg" },
  { value: "NE21", title: "Nutritionella förändringar, ryggmärg" },
  { value: "NE22", title: "Degenerativa/dystrofiska förändringar, ryggmärg" },
  { value: "NE221", title: "Neuronal degeneration" },
  {
    value: "NE2211",
    title: "Progred kron degen myeloradikulopati (schäfervinglighet)",
  },
  { value: "NE2212", title: "Axon- /märgskidedegeneration" },
  { value: "NE22121", title: "Progressiv axonopati hos boxer" },
  { value: "NE2213", title: "Nekros ( myelomalaci)" },
  { value: "NE2214", title: "Stödjevävnadsdegeneration i ryggmärg" },
  { value: "NE23", title: "Hormonella förändringar, ryggmärg" },
  { value: "NE3", title: "Cirkulatoriska förändringar, ryggmärg" },
  { value: "NE30", title: "Blödningar, ryggmärg" },
  { value: "NE31", title: "Ödem, ryggmärg" },
  { value: "NE32", title: "Infarkter, ryggmärg" },
  { value: "NE34", title: "Trombos / emboli, ryggmärg" },
  { value: "NE341", title: "Broskemboli (FCE), ryggmärg" },
  { value: "NE35", title: "Arterio-atero-skleros förändringar, ryggmärg" },
  { value: "NE4", title: "Infekt, infl förändringar, ryggmärg" },
  { value: "NE40", title: "Specifika infektionssjukdomar, ryggmärg" },
  { value: "NE41", title: "Akuta inflammationstillstånd, ryggmärg" },
  { value: "NE411", title: "Akut serös / serofibrinös inflammation, ryggmärg" },
  { value: "NE412", title: "Akut purulent inflammation, ryggmärg" },
  { value: "NE413", title: "Specifika akuta infl tillstånd, ryggmärg" },
  { value: "NE419", title: "Övriga akuta infl tillstånd, ryggmärg" },
  { value: "NE42", title: "Kroniska inflammationstillstånd, ryggmärg" },
  { value: "NE421", title: "Kronisk serös inflammation, ryggmärg" },
  { value: "NE422", title: "Purulent inflammation, ryggmärg" },
  { value: "NE423", title: "Abscess/fistel, ryggmärg" },
  { value: "NE424", title: "Specifika kroniska infl tillstånd, ryggmärg" },
  { value: "NE425", title: "Nonpurulent inflammation, ryggmärg" },
  { value: "NE426", title: "Inflammationstillstånd, nervrötter, cauda equina" },
  { value: "NE429", title: "Övriga kroniska infl tillstånd, ryggmärg" },
  { value: "NE4291", title: "GME (granul meningoencefalomyelit), ryggmärg" },
  { value: "NE43", title: "Parasitära sjukdomar, ryggmärg" },
  {
    value: "NE431",
    title: "Toxoplasmos, ryggmärg, ryggmärgsrötter, cauda equina",
  },
  {
    value: "NE432",
    title: "Neosporos, ryggmärg, ryggmärgsrötter, cauda equina",
  },
  { value: "NE5", title: "Immunmedierade tillstånd, ryggmärg" },
  { value: "NE6", title: "Neoplastiska förändringar, ryggmärg" },
  { value: "NE7", title: "Trauma, fr kr, lägeförändr, term skad, ryggmärg" },
  { value: "NE71", title: "Traumatiska / mekaniska skador, ryggmärg" },
  { value: "NE711", title: "Ryggmärgskompression UNS" },
  { value: "NE715", title: "Nervrotsavulsion till plexus brachialis" },
  { value: "NE72", title: "Främmande kroppar, ryggmärg" },
  { value: "NE73", title: "Lägesförändringar, ryggmärg" },
  { value: "NE8", title: "Toxiska förändringar, ryggmärg" },
  { value: "NE9", title: "Idiopatiska, ospecif, multifakt, ryggmärg" },
  { value: "NF", title: "Perifera nerver inklusive nervändplattor" },
  {
    value: "NF0",
    title: "Normalvar, symtom u fastställd orsak, perifera nerver",
  },
  { value: "NF00", title: "Normalvariation, perifera nerver" },
  {
    value: "NF01",
    title: "Symtom på sjukdom u fastställd orsak, perifera nerver",
  },
  { value: "NF011", title: "Sensorimotorisk neuropati" },
  { value: "NF012", title: "Motorisk neuropati" },
  { value: "NF013", title: "Sensorisk neuropati" },
  {
    value: "NF1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, perifera nerver",
  },
  { value: "NF10", title: "Missbildning, perifera nerver" },
  { value: "NF11", title: "Utvecklingsrubbn, tillväxtrubbn, perifera nerver" },
  {
    value: "NF2",
    title: "Metabol, nutrit, degen/dystrof förändr, perifera nerver",
  },
  { value: "NF20", title: "Metaboliska förändringar, perifera nerver" },
  { value: "NF201", title: "Diabetesneuropati" },
  { value: "NF202", title: "Neuropati vid hypotyreoidism" },
  { value: "NF203", title: "Neuropati vid hypoglykemi" },
  { value: "NF21", title: "Nutritionella förändringar, perifera nerver" },
  {
    value: "NF22",
    title: "Degenerativa/dystrofiska förändringar, perifera nerver",
  },
  { value: "NF23", title: "Hormonella förändringar, perifera nerver" },
  { value: "NF3", title: "Cirkulatoriska förändringar, perifera nerver" },
  { value: "NF30", title: "Blödningar, perifera nerver" },
  { value: "NF31", title: "Ödem, perifera nerver" },
  { value: "NF32", title: "Infarkter, perifera nerver" },
  { value: "NF34", title: "Trombos / emboli, perifera nerver" },
  {
    value: "NF35",
    title: "Arterio-atero-skleros förändringar, perifera nerver",
  },
  { value: "NF4", title: "Infekt, infl förändringar, perifera nerver" },
  { value: "NF40", title: "Specifika infektionssjukdomar, perifera nerver" },
  { value: "NF41", title: "Akuta inflammationstillstånd, perifera nerver" },
  {
    value: "NF411",
    title: "Akut serös / serofibrinös inflammation, perifera nerver",
  },
  { value: "NF412", title: "Akut purulent inflammation, perifera nerver" },
  { value: "NF413", title: "Specifika akuta infl tillstånd, perifera nerver" },
  { value: "NF419", title: "Övriga akuta infl tillstånd, perifera nerver" },
  { value: "NF42", title: "Kroniska inflammationstillstånd, perifera nerver" },
  { value: "NF421", title: "Kroniskt serös inflammation, perifera nerver" },
  { value: "NF422", title: "Purulent inflammation, perifera nerver" },
  { value: "NF423", title: "Abscess/fistel, perifera nerver" },
  {
    value: "NF424",
    title: "Specifika kroniska infl tillstånd, perifera nerver",
  },
  { value: "NF429", title: "Övriga kroniska infl tillstånd, perifera nerver" },
  { value: "NF43", title: "Parasitära sjukdomar, perifera nerver" },
  { value: "NF5", title: "Immunmedierade tillstånd, perifera nerver" },
  { value: "NF51", title: "Myastenia gravis" },
  { value: "NF6", title: "Neoplastiska förändringar perifera nerver" },
  { value: "NF61", title: "Amputationsgranulom" },
  {
    value: "NF7",
    title: "Trauma, fr kr, lägeförändr, term skad, perifera nerver",
  },
  { value: "NF71", title: "Traumatiska / mekaniska skador, perifera nerver" },
  { value: "NF711", title: "Trauma mot kranialnerver" },
  { value: "NF7111", title: "Fascialispares (traumatisk)" },
  { value: "NF712", title: "Trauma mot plexus brachialis / nervus radialis" },
  { value: "NF7121", title: "Brachialispares (traumatisk)" },
  { value: "NF7122", title: "Radialispares (traumatisk)" },
  { value: "NF7123", title: "Trauma mot nervus suprascapularis" },
  { value: "NF7124", title: "Trauma mot nervus infraspinatus" },
  { value: "NF713", title: "Trauma mot nervus ischiadikus / nervus peroneus" },
  { value: "NF714", title: "Trauma mot nerver i samband med förlossning" },
  { value: "NF719", title: "Trauma mot annan perifer spinal nerv" },
  { value: "NF72", title: "Främmande kroppar, perifera nerver" },
  { value: "NF73", title: "Lägesförändringar, perifera nerver" },
  { value: "NF74", title: "Termiska skador, perifera nerver" },
  { value: "NF741", title: "Brännskada, perifera nerver" },
  { value: "NF742", title: "Köldskada, perifera nerver" },
  { value: "NF8", title: "Toxiska förändringar, perifera nerver" },
  { value: "NF9", title: "Idiopatiska, ospecif, multifakt, perifera nerver" },
  { value: "NF91", title: "Idiopatisk fascialisförlamning" },
  { value: "NF92", title: "Tuppspatt" },
  { value: "NF93", title: "Sjukdom i perifera nervsystemet" },
  { value: "NG", title: "Hjärn och / eller ryggmärgshinnor" },
  {
    value: "NG0",
    title: "Normalvar, symtom u fastställd orsak, ryggmärgshinnor",
  },
  { value: "NG00", title: "Normalvariation, ryggmärgshinnor" },
  {
    value: "NG01",
    title: "Symtom på sjukdom u fastställd orsak, ryggmärgshinnor",
  },
  {
    value: "NG1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, ryggmärgshinnor",
  },
  { value: "NG10", title: "Missbildning, ryggmärgshinnor" },
  { value: "NG11", title: "Utvecklingsrubbn, tillväxtrubbn, ryggmärgshinnor" },
  { value: "NG111", title: "Arachnoidal cysta/pseudocysta" },
  { value: "NG112", title: "Meningocoele" },
  {
    value: "NG2",
    title: "Metabol, nutrit, degen/dystrof förändr, ryggmärgshinnor",
  },
  { value: "NG20", title: "Metaboliska förändringar, ryggmärgshinnor" },
  { value: "NG21", title: "Nutritionella förändringar, ryggmärgshinnor" },
  { value: "NG22", title: "Degenerativa/dystrofiska förändringar," },
  { value: "NG221", title: "Pakymeningit (benmetaplasi i ryggmärgshinnor)" },
  { value: "NG23", title: "Hormonella förändringar, ryggmärgshinnor" },
  { value: "NG3", title: "Cirkulatoriska förändringar, ryggmärgshinnor" },
  { value: "NG30", title: "Blödningar, ryggmärgshinnor" },
  { value: "NG31", title: "Ödem, ryggmärgshinnor" },
  { value: "NG32", title: "Infarkter, ryggmärgshinnor" },
  { value: "NG34", title: "Trombos / emboli, ryggmärgshinnor" },
  {
    value: "NG35",
    title: "Arterio-atero-skleros förändringar, ryggmärgshinnor",
  },
  { value: "NG4", title: "Infekt, infl förändringar, ryggmärgshinnor" },
  { value: "NG40", title: "Specifika infektionssjukdomar, ryggmärgshinnor" },
  { value: "NG41", title: "Akuta inflammationstillstånd, ryggmärgshinnor" },
  {
    value: "NG411",
    title: "Akut serös / serofibrinös inflammation, ryggmärgshinnor",
  },
  { value: "NG412", title: "Akut purulent inflammation, ryggmärgshinnor" },
  { value: "NG413", title: "Specifika akuta infl tillstånd, ryggmärgshinnor" },
  { value: "NG414", title: "Eosinofil meningit, akut" },
  { value: "NG415", title: "Akut purulent meningit" },
  { value: "NG419", title: "Övriga akuta infl tillstånd, ryggmärgshinnor" },
  { value: "NG42", title: "Kroniska inflammationstillstånd, ryggmärgshinnor" },
  { value: "NG421", title: "Kronisk serös inflammation, ryggmärgshinnor" },
  { value: "NG422", title: "Purulent inflammation, ryggmärgshinnor" },
  { value: "NG423", title: "Abscess/fistel, ryggmärgshinnor" },
  {
    value: "NG424",
    title: "Specifika kroniska infl tillstånd, ryggmärgshinnor",
  },
  { value: "NG425", title: "Nonpurulent meningit" },
  { value: "NG429", title: "Övriga kroniska infl tillstånd, ryggmärgshinnor" },
  { value: "NG4291", title: "Steroid-responsibel meningit-arterit (SRMA)" },
  { value: "NG43", title: "Parasitära sjukdomar, ryggmärgshinnor" },
  { value: "NG5", title: "Immunmedierade tillstånd, ryggmärgshinnor" },
  { value: "NG6", title: "Neoplastiska förändringar, ryggmärgshinnor" },
  {
    value: "NG7",
    title: "Trauma, fr kr, lägeförändr, term skad, ryggmärgshinnor",
  },
  { value: "NG71", title: "Traumatiska / mekaniska skador, ryggmärgshinnor" },
  { value: "NG72", title: "Främmande kroppar, ryggmärgshinnor" },
  { value: "NG73", title: "Lägesförändringar, ryggmärgshinnor" },
  { value: "NG8", title: "Toxiska förändringar, ryggmärgshinnor" },
  { value: "NG9", title: "Idiopatiska, ospecif, multifakt, ryggmärgshinnor" },
  { value: "NH", title: "Autonoma nervsystemet" },
  {
    value: "NH0",
    title: "Normalvar, symtom u fastställd orsak autonoma nervsystemet",
  },
  { value: "NH00", title: "Normalvariation, autonoma nervsystemet" },
  {
    value: "NH01",
    title: "Symtom på sjukdom u fastställd orsak, autonoma nervsystemet",
  },
  {
    value: "NH1",
    title: "Missbildn/utveckl.rubbn/tillväxtrubbn, autonoma nervsystemet",
  },
  { value: "NH10", title: "Missbildning, autonoma nervsystemet" },
  {
    value: "NH11",
    title: "Utvecklingsrubbn, tillväxtrubbn, autonoma nervsystemet",
  },
  {
    value: "NH2",
    title: "Metabol, nutrit, degen/dystrof förändr, autonoma nervsystem",
  },
  { value: "NH20", title: "Metaboliska förändringar, autonoma nervsystemet" },
  { value: "NH21", title: "Nutritionella förändringar, autonoma nervsystemet" },
  {
    value: "NH22",
    title: "Degenerativa/dystrofiska förändringar, autonoma nervsystemet",
  },
  { value: "NH221", title: "Grässjuka" },
  { value: "NH222", title: "Felin dysautonomi" },
  { value: "NH23", title: "Hormonella förändringar, autonoma nervsystemet" },
  { value: "NH3", title: "Cirkulatoriska förändringar, autonoma nervsystemet" },
  { value: "NH30", title: "Blödningar, autonoma nervsystemet" },
  { value: "NH31", title: "Ödem, autonoma nervsystemet" },
  { value: "NH32", title: "Infarkter, autonoma nervsystemet" },
  { value: "NH34", title: "Trombos / emboli, autonoma nervsystemet" },
  {
    value: "NH35",
    title: "Arterio-atero-skleros förändringar, autonoma nervsystemet",
  },
  { value: "NH4", title: "Infekt, infl förändringar, autonoma nervsystemet" },
  {
    value: "NH40",
    title: "Specifika infektionssjukdomar, autonoma nervsystemet",
  },
  {
    value: "NH41",
    title: "Akuta inflammationstillstånd, autonoma nervsystemet",
  },
  {
    value: "NH411",
    title: "Akut serös / serofibrinös infl, autonoma nervsystemet",
  },
  {
    value: "NH412",
    title: "Akut purulent inflammation, autonoma nervsystemet",
  },
  {
    value: "NH413",
    title: "Specifika akuta infl tillstånd, autonoma nervsystemet",
  },
  {
    value: "NH419",
    title: "Övriga akuta infl tillstånd, autonoma nervsystemet",
  },
  {
    value: "NH42",
    title: "Kroniska inflammationstillstånd, autonoma nervsystemet",
  },
  {
    value: "NH421",
    title: "Kronisk serös inflammation, autonoma nervsystemet",
  },
  { value: "NH422", title: "Purulent inflammation, autonoma nervsystemet" },
  { value: "NH423", title: "Abscess/fistel, autonoma nervsystemet" },
  {
    value: "NH424",
    title: "Specifika kroniska infl tillstånd, autonoma nervsystemet",
  },
  {
    value: "NH429",
    title: "Övriga kroniska infl tillstånd, autonoma nervsystemet",
  },
  { value: "NH43", title: "Parasitära sjukdomar, autonoma nervsystemet" },
  { value: "NH5", title: "Immunmedierade tillstånd, autonoma nervsystemet" },
  { value: "NH6", title: "Neoplastiska förändringar, autonoma nervsystemet" },
  {
    value: "NH7",
    title: "Trauma, fr kr, lägeförändr, term skad, autonoma nervsystemet",
  },
  {
    value: "NH71",
    title: "Traumatiska / mekaniska skador, autonoma nervsystemet",
  },
  { value: "NH72", title: "Främmande kroppar, autonoma nervsystemet" },
  { value: "NH73", title: "Lägesförändringar, autonoma nervsystemet" },
  { value: "NH74", title: "Termiska skador, autonoma nervsystemet" },
  { value: "NH8", title: "Toxiska förändringar, autonoma nervsystemet" },
  {
    value: "NH9",
    title: "Idiopatiska, ospecif, multifakt, autonoma nervsystemet",
  },
  { value: "NH91", title: "Idiopatiskt Horner¿s syndrom" },
  { value: "NJ", title: "Multifokala förändringar i nervsystemet" },
  {
    value: "NJ0",
    title: "Normalvar, symtom u fastställd orsak, multifokalt nervsystem",
  },
  { value: "NJ00", title: "Normalvariation, multifokalt nervsystemet" },
  {
    value: "NJ01",
    title: "Symtom på sjukdom u fastställd orsak, multifokalt nervsystem",
  },
  { value: "NJ011", title: "Narkolepsi" },
  { value: "NJ012", title: "Rörelseavikelser" },
  { value: "NJ0121", title: "Ataxi /bristande samordning av rörelser UNS" },
  { value: "NJ0122", title: "Hållnings / rörelseavvikelser UNS" },
  { value: "NJ013", title: "Motorisk pares / paralys" },
  { value: "NJ0130", title: "Motorisk pares / paralys av tuggmuskulatur" },
  { value: "NJ0131", title: "Motorisk pares / paralys av tungmuskulatur" },
  { value: "NJ0132", title: "Motorisk pares / paralys av svalgmuskulatur" },
  { value: "NJ0133", title: "Motorisk pares / paralys av larynxmuskulatur" },
  { value: "NJ0134", title: "Motorisk pares / paralys av ansiktsmuskulatur" },
  {
    value: "NJ0135",
    title: "Motorisk pares / paralys av hals och bålmuskulatur",
  },
  { value: "NJ0136", title: "Motorisk pares / paralys av frambensmuskulatur" },
  { value: "NJ0137", title: "Motorisk pares / paralys av bakbensmuskulatur" },
  { value: "NJ0138", title: "Motorisk pares / paralys av svansmuskulatur" },
  {
    value: "NJ0139",
    title: "Motor pares/paralys av cauda equina (cauda equina syndromet)",
  },
  { value: "NJ014", title: "Sensibilitetsrubbningar" },
  { value: "NJ0141", title: "Sensibilitetsrubbningar i huvud" },
  { value: "NJ0142", title: "Sensibilitetsrubbningar i hals  / bål" },
  { value: "NJ0143", title: "Sensibilitetsrubbningar i extremiteter / svans" },
  { value: "NJ015", title: "Muskeltonusförändringar" },
  { value: "NJ0151", title: "Ökad muskeltonus / muskelkramp" },
  { value: "NJ0152", title: "Minskad muskeltonus" },
  { value: "NJ016", title: "Tremor" },
  { value: "NJ0161", title: "Tremor /skakningar i någon kroppsdel" },
  { value: "NJ0162", title: "Tremor i huvudet" },
  { value: "NJ017", title: "Fascikulationer" },
  { value: "NJ018", title: "Abnorma reflexer (spinala eller posturala)" },
  {
    value: "NJ1",
    title: "Missbildn,utveckl.rubbn,tillväxtrubbn, multifokalt nervsyst",
  },
  { value: "NJ10", title: "Missbildning, multifokalt nervsystemet" },
  { value: "NJ101", title: "Congenital tremor (svin)" },
  {
    value: "NJ11",
    title: "Utvecklingsrubbn, tillväxtrubbn, multifokalt nervsystemet",
  },
  {
    value: "NJ2",
    title: "Metabol, nutrit, degen/dystrof förändr, multifokalt nervsyst",
  },
  {
    value: "NJ20",
    title: "Metaboliska förändringar, multifokalt nervsystemet",
  },
  {
    value: "NJ201",
    title: "Lysosomala upplagringssjukdomar, multifokalt nervsystemet",
  },
  { value: "NJ2011", title: "Ceroid lipofuscinos" },
  { value: "NJ202", title: "Neuronal abiotrofi" },
  { value: "NJ2021", title: "Neuronal abiotrofi hos lapphund" },
  {
    value: "NJ21",
    title: "Nutritionella förändringar, multifokalt nervsystemet",
  },
  {
    value: "NJ22",
    title: "Degen/dystrof förändringar, multifokalt nervsystemet",
  },
  { value: "NJ221", title: "Neuronal degeneration multifokalt" },
  {
    value: "NJ222",
    title: "Märgskidedegeneration / axondegeneration multifokalt",
  },
  { value: "NJ223", title: "Nekros multifokalt" },
  { value: "NJ224", title: "Stödjevävnadsdegeneration multifokalt" },
  { value: "NJ23", title: "Hormonella förändringar, multifokalt nervsystemet" },
  {
    value: "NJ3",
    title: "Cirkulatoriska förändringar, multifokalt nervsystemet",
  },
  { value: "NJ30", title: "Blödningar, multifokalt nervsystemet" },
  { value: "NJ31", title: "Ödem, multifokalt nervsystemet" },
  { value: "NJ32", title: "Infarkter, multifokalt nervsystemet" },
  { value: "NJ34", title: "Trombos / emboli, multifokalt nervsystemet" },
  {
    value: "NJ35",
    title: "Arterio-atero-skleros förändringar, multifokalt nervsystemet",
  },
  {
    value: "NJ4",
    title: "Infekt, infl förändringar, multifokalt nervsystemet",
  },
  {
    value: "NJ40",
    title: "Specifika infektionssjukdomar, multifokalt  nervsystemet",
  },
  {
    value: "NJ41",
    title: "Akuta inflammationstillstånd, multifokalt nervsystemet",
  },
  {
    value: "NJ411",
    title: "Akut serös / serofibrinös infl, multifokalt nervsystemet",
  },
  {
    value: "NJ412",
    title: "Akut purulent inflammation, multifokalt nervsystemet",
  },
  {
    value: "NJ413",
    title: "Specifika akuta infl tillstånd, multifokalt nervsystemet",
  },
  { value: "NJ414", title: "Meningoencefalit" },
  { value: "NJ415", title: "Meningomyelit" },
  { value: "NJ416", title: "Meningoencefalomyelit" },
  {
    value: "NJ419",
    title: "Övriga akuta infl tillstånd, multifokalt nervsystemet",
  },
  {
    value: "NJ42",
    title: "Kroniska inflammationstillstånd, multifokalt nervsystemet",
  },
  {
    value: "NJ421",
    title: "Kroniskt serös inflammation, multifokalt nervsystemet",
  },
  { value: "NJ422", title: "Purulent inflammation, multifokalt nervsystemet" },
  { value: "NJ4221", title: "Purulent meningoencefalit" },
  { value: "NJ4222", title: "Purulent meningomyelit" },
  { value: "NJ4223", title: "Purulent meningoencefalomyelit" },
  { value: "NJ423", title: "Abscess/fistel, multifokalt nervsystemet" },
  {
    value: "NJ424",
    title: "Specifika kroniska infl tillstånd, multifokalt nervsystemet",
  },
  {
    value: "NJ425",
    title: "Nonpurulent inflammation, multifokalt nervsystemet",
  },
  { value: "NJ4251", title: "Nonpurulent meningoencefalit" },
  { value: "NJ4252", title: "Nonpurulent meningomyelit" },
  { value: "NJ4253", title: "Nonpurulent meningoencefalomyelit" },
  {
    value: "NJ429",
    title: "Övriga kroniska infl tillstånd, multifokalt nervsystemet",
  },
  { value: "NJ4291", title: "Vingelsjuka, nonpurulent meningoencephalomyelit" },
  {
    value: "NJ4292",
    title: "Granulomatös meningoencefalomyelit (GME), multifokalt",
  },
  { value: "NJ4296", title: "FIP, feline infectious peritonitis, multifokalt" },
  { value: "NJ43", title: "Parasitära sjukdomar, multifokalt nervsystemet" },
  { value: "NJ431", title: "Toxoplasmos, multifokalt" },
  { value: "NJ432", title: "Neosporos, multifokalt" },
  { value: "NJ5", title: "Immunmedierade tillstånd, multifokalt nervsystemet" },
  {
    value: "NJ6",
    title: "Neoplastiska förändringar, multifokalt nervsystemet",
  },
  {
    value: "NJ7",
    title: "Trauma, fr kr, lägeförändr, term skad, multifokalt nervsyste",
  },
  {
    value: "NJ71",
    title: "Traumatiska / mekaniska skador, multifokalt nervsystemet",
  },
  { value: "NJ72", title: "Främmande kroppar, multifokalt nervsystemet" },
  { value: "NJ73", title: "Lägesförändringar, multifokalt nervsystemet" },
  { value: "NJ8", title: "Toxiska förändringar, multifokalt nervsystemet" },
  {
    value: "NJ9",
    title: "Idiopatiska, ospecif, multifakt, multifokalt nervsystemet",
  },
  { value: "NJ91", title: "Kramptillstånd, okänd orsak" },
  { value: "NK", title: "Storhjärna, lillhjärna, hjärnstam" },
  {
    value: "NK0",
    title: "Normalvar, symtom u fastst ors, stor/lillhjärna, hjärnstam",
  },
  {
    value: "NK01",
    title: "Symtom på sjukdom u fastst ors,stor/lillhjärna, hjärnstam",
  },
  { value: "NK011", title: "Symtom på blindhet från CNS" },
  {
    value: "NK1",
    title: "Missbildn, utveckl/tillväxtrubbn, stor/lillhjärna, hjärnstam",
  },
  { value: "NK10", title: "Missbildning, storhjärna, lillhjärna, hjärnstam" },
  { value: "NK101", title: "Medfödd vattenskalle (kongenital hydrocefalus)" },
  { value: "NK1011", title: "Hypoplasi av lillhjärna" },
  {
    value: "NK11",
    title: "Utvecklingsrubbn, tillväxtrubbn, stor/lillhjärna, hjärnstam",
  },
  { value: "NK111", title: "Skottekramp" },
  { value: "NK112", title: "Arnold-Chiari-missbildning typ I" },
  {
    value: "NK2",
    title: "Metabol, nutrit, degen/dystrof förändr, stor/lillhj, hjärnst",
  },
  {
    value: "NK20",
    title: "Metaboliska förändringar, storhjärna, lillhjärna, hjärnstam",
  },
  { value: "NK201", title: "Hepatisk encefalopati" },
  { value: "NK202", title: "Uremisk encefalopati" },
  {
    value: "NK203",
    title: "Hypoglykemi, symtom fr stor/lillhjärna, hjärnstam",
  },
  {
    value: "NK204",
    title: "Hypocalcemi, symtom fr stor/lillhjärna, hjärnstam",
  },
  { value: "NK205", title: "Narkolepsi" },
  { value: "NK206", title: "Lysosomala upplagringssjukdomar" },
  { value: "NK2061", title: "Ceroid lipofuscinos" },
  {
    value: "NK207",
    title: "Neuronal abiotrofi, storhjärna, lillhjärna, hjärnstam",
  },
  { value: "NK2071", title: "Abiotrofi, lillhjärna" },
  {
    value: "NK21",
    title: "Nutritionella förändr, storhjärna, lillhjärna, hjärnstam",
  },
  { value: "NK211", title: "CCN (Cerebrocortikal nekros)" },
  {
    value: "NK22",
    title: "Degenerativa/dystrofiska förändr, stor/lillhjärna, hjärnstam",
  },
  { value: "NK221", title: "Neuronal degeneration, lillhjärna" },
  { value: "NK222", title: "Purkinjecellsdegeneration, lillhjärna" },
  { value: "NK223", title: "FSE (Felin spongiform encefalopati)" },
  {
    value: "NK3",
    title: "Cirkulatoriska förändringar, stor/lillhjärna, hjärnstam",
  },
  { value: "NK30", title: "Blödningar, storhjärna, lillhjärna, hjärnstam" },
  {
    value: "NK301",
    title: "Subduralt/epiduralt hematom, stor/lillhjärna, hjärnstam",
  },
  {
    value: "NK302",
    title: "Subaraknoidal blödning, storhjärna, lillhjärna, hjärnstam",
  },
  {
    value: "NK303",
    title: "Blödning i hjärnvävnad, storhjärna, lillhjärna, hjärnstam",
  },
  { value: "NK31", title: "Ödem, storhjärna, lillhjärna, hjärnstam" },
  { value: "NK32", title: "Infarkt, storhjärna, lillhjärna, hjärnstam" },
  { value: "NK34", title: "Trombos/emboli, storhjärna, lillhjärna, hjärnstam" },
  {
    value: "NK35",
    title: "Arterio-atero-skleros förändr, stor/lillhjärna, hjärnstam",
  },
  {
    value: "NK4",
    title: "Infekt, infl förändringar, storhjärna, lillhjärna, hjärnstam",
  },
  {
    value: "NK41",
    title: "Akuta inflammationstillstånd, stor/lillhjärna, hjärnstam",
  },
  {
    value: "NK42",
    title: "Kroniska inflammationstillstånd, stor/lillhjärna, hjärnstam",
  },
  {
    value: "NK422",
    title: "Kronisk purulent inflammation, stor/lillhjärna, hjärnstam",
  },
  { value: "NK423", title: "Abscess/fistel, storhjärna,lillhjärna, hjärnstam" },
  {
    value: "NK425",
    title: "Nonpurulent inflammation, stor/lillhjärna, hjärnstam",
  },
  { value: "NK4291", title: "GME (Granulomatös meningoencefalomyelit)" },
  { value: "NK4292", title: "Valpsjuka, symtom fr stor/lillhjärna, hjärnstam" },
  {
    value: "NK4293",
    title: "TBE (Tickborne encephalitis), symt från stor/lillhj, hjärnst",
  },
  { value: "NK4294", title: "Rabies, symtom från stor/lillhjärna, hjärnstam" },
  {
    value: "NK4295",
    title: "Vingelsjuka, Feline borna disease, symt stor/lillhj, hjärnst",
  },
  {
    value: "NK4296",
    title: "FIP (Feline infect peritonitis),symt fr stor/lillhj,hjärnst",
  },
  {
    value: "NK43",
    title: "Parasitära sjukdomar, storhjärna, lillhjärna, hjärnstam",
  },
  { value: "NK431", title: "Toxoplasmos, symt fr stor/lillhjärna, hjärnstam" },
  {
    value: "NK432",
    title: "Neosporos, symt fr storhjärna, lillhjärna, hjärnstam",
  },
  {
    value: "NK5",
    title: "Immunmedierade tillstånd, storhjärna, lillhjärna, hjärnstam",
  },
  {
    value: "NK6",
    title: "Tumörförändringar, storhjärna, lillhjärna, hjärnstam",
  },
  {
    value: "NK7",
    title: "Trauma, fr kr, lägeförändr, term skad, stor/lillhj, hjärnst",
  },
  {
    value: "NK71",
    title: "Traumatiska/mekan skador, stor/lillhjärna, hjärnstam",
  },
  {
    value: "NK712",
    title: "Hjärnskakning/commotio cerebri, stor/lillhjärna, hjärnstam",
  },
  { value: "NK713", title: "Traumatisk hjärnblödning" },
  { value: "NK714", title: "Skallskada" },
  {
    value: "NK72",
    title: "Främmande kroppar, storhjärna, lillhjärna, hjärnstam",
  },
  {
    value: "NK73",
    title: "Lägesförändringar, storhjärna, lillhjärna, hjärnstam",
  },
  { value: "NK731", title: "Framfall av storhjärna (tentoriuminklämning)" },
  { value: "NK732", title: "Framfall av lillhjärna genom foramen magnum" },
  {
    value: "NK8",
    title: "Toxiska förändringar,storhjärna, lillhjärna, hjärnstam",
  },
  { value: "NK81", title: "Blyförgiftning" },
  { value: "NK82", title: "Metronidazolförgiftning" },
  { value: "NK9", title: "Idiopatiska, ospecif, multifakt, storhjärna" },
  { value: "NK91", title: "Epilepsi utan känd etiologi" },
  { value: "NK93", title: "Vattenskalle utan känd etiologi" },
  { value: "NL", title: "Vestibularisorgan balansnerv" },
  {
    value: "NL0",
    title: "Normalvar,symtom u fastst orsak,vestibularisorg balansnerv",
  },
  {
    value: "NL01",
    title: "Symtom på sjukdom u fastst orsak,vestibularisorg balansnerv",
  },
  { value: "NL011", title: "Vestibularissyndrom enkelsidigt" },
  { value: "NL012", title: "Vestibularissyndrom dubbelsidigt" },
  {
    value: "NL1",
    title: "Missbildn,utv rubbn,tillväxtrubbn,vestibularisorg balansnerv",
  },
  {
    value: "NL2",
    title: "Metabol,nutrit,degen/dystrof för, vestibularisorg balansnerv",
  },
  {
    value: "NL3",
    title: "Cirkulatoriska förändringar, vestibularisorgan balansnerv",
  },
  { value: "NL30", title: "Blödningar, vestibularisorgan balansnerv" },
  {
    value: "NL4",
    title: "Infekt, infl förändringar, vestibularisorgan balansnerv",
  },
  { value: "NL40", title: "Specifika infektionssjukdomar, vestibularisorgan" },
  {
    value: "NL401",
    title: "FSE (Fokal sym encephalomalaci Cl perfr typ D,enterotoxin)",
  },
  { value: "NL5", title: "Immunmedierade tillstånd" },
  {
    value: "NL6",
    title: "Neoplastiska förändringar, vestibularisorgan balansnerv",
  },
  {
    value: "NL7",
    title: "Trauma,fr kr,lägeför,term skad,vestibularisorg balansnerv",
  },
  {
    value: "NL71",
    title: "Traumatiska/mekan skador, vestibularisorg balansnerv",
  },
  { value: "NL72", title: "Främmande kroppar, vestibularisorgan balansnerv" },
  { value: "NL8", title: "Toxiska förändringar, vestibularisorgan balansnerv" },
  { value: "NL81", title: "Streptomycinförgiftning" },
  {
    value: "NL9",
    title: "Idiopat, ospecif, multifakt, vestibularisorgan balansnerv",
  },
  { value: "NL91", title: "ODVS (Old dog vestibular syndrome)" },
  { value: "R", title: "Respirationsorganens och brösthålans sjukdomar" },
  { value: "RA", title: "Övre luftvägar" },
  {
    value: "RA0",
    title: "Normalvar, symtom u fastställd orsak, övre luftvägar",
  },
  { value: "RA00", title: "Normalvariation, övre luftvägar" },
  {
    value: "RA01",
    title: "Symtom på sjukdom u fastställd orsak, övre luftvägar",
  },
  { value: "RA011", title: "Onormalt näsflöde" },
  {
    value: "RA012",
    title: "Stenos / miss-ljud från övre luftvägar under arbete",
  },
  { value: "RA013", title: "Hosta från övre luftvägar" },
  { value: "RA0131", title: "Kennelhosta" },
  { value: "RA014", title: "Dyspné" },
  { value: "RA015", title: "Bakåtvänd nysning" },
  { value: "RA016", title: "Snuva" },
  {
    value: "RA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, övre luftvägar",
  },
  { value: "RA10", title: "Missbildning, övre luftvägar" },
  { value: "RA102", title: "Förträngning /dysfunktion av näsborre" },
  { value: "RA103", title: "Epiglottishypoplasi" },
  { value: "RA104", title: "Epiglottiscysta" },
  { value: "RA11", title: "Utvecklingsrubbn, tillväxtrubbn, övre luftvägar" },
  { value: "RA111", title: "Palatum molle elongatum" },
  { value: "RA112", title: "Brakiocefalisk obstruktion" },
  { value: "RA113", title: "Larynxkollaps" },
  { value: "RA114", title: "Farynxkollaps" },
  { value: "RA115", title: "Luftsäckstympanism" },
  {
    value: "RA2",
    title: "Metabol, nutrit, degen/dystrof förändr, övre luftvägar",
  },
  { value: "RA20", title: "Metaboliska förändringar, övre luftvägar" },
  { value: "RA21", title: "Nutritionella förändringar, övre luftvägar" },
  {
    value: "RA22",
    title: "Degenerativa/dystrofiska förändringar, övre luftvägar",
  },
  { value: "RA221", title: "Larynxförlamning" },
  { value: "RA23", title: "Hormonella förändringar, övre luftvägar" },
  { value: "RA3", title: "Cirkulatoriska förändringar, övre luftvägar" },
  { value: "RA30", title: "Blödningar, övre luftvägar" },
  { value: "RA301", title: "Epistaxis, övre luftvägar" },
  { value: "RA302", title: "Luftsäcksblödning" },
  { value: "RA303", title: "Petekiala slemhinneblödningar" },
  { value: "RA31", title: "Ödem, övre luftvägar" },
  { value: "RA311", title: "Larynxödem" },
  { value: "RA312", title: "Farynxödem" },
  { value: "RA313", title: "Glottisödem" },
  { value: "RA32", title: "Infarkter, övre luftvägar" },
  { value: "RA33", title: "Hygrom, övre luftvägar" },
  { value: "RA34", title: "Trombos / emboli, övre luftvägar" },
  {
    value: "RA35",
    title: "Arterio-atero-skleros förändringar, övre luftvägar",
  },
  { value: "RA4", title: "Infekt, infl förändringar, övre luftvägar" },
  { value: "RA40", title: "Specifika infektionssjukdomar, övre luftvägar" },
  { value: "RA41", title: "Akuta inflammationstillstånd, övre luftvägar" },
  { value: "RA410", title: "Svalginflammation/laryngit/ faryngit, akut" },
  { value: "RA4107", title: "Inflammation i arybrosk" },
  {
    value: "RA411",
    title: "Akut serös / serofibrinös inflammation, övre luftvägar",
  },
  { value: "RA4111", title: "Akut serös rinit" },
  { value: "RA41111", title: "Akut kattsnuva" },
  { value: "RA4112", title: "Ulecerativ rinit" },
  { value: "RA412", title: "Akut purulent inflammation, övre luftvägar" },
  { value: "RA4121", title: "Akut purulent rinit" },
  { value: "RA413", title: "Specifika akuta infl tillstånd, övre luftvägar" },
  { value: "RA419", title: "Övriga akuta infl tillstånd, övre luftvägar" },
  { value: "RA4191", title: "Akut tonsillit" },
  { value: "RA4192", title: "Akut faryngit" },
  { value: "RA4193", title: "Akut laryngit" },
  { value: "RA4194", title: "Akut epiglottit" },
  { value: "RA4195", title: "Akut aerocystit" },
  { value: "RA4196", title: "Akut sinuit" },
  { value: "RA4199", title: "Övriga akuta övre luftvägsinfektioner" },
  { value: "RA42", title: "Kroniska inflammationstillstånd, övre luftvägar" },
  { value: "RA420", title: "Svalginflammation/laryngit/faryngit, kronisk" },
  { value: "RA421", title: "Kronisk serös inflammation, övre luftvägar" },
  { value: "RA4211", title: "Kronisk serös rinit" },
  { value: "RA42111", title: "Kronisk kattsnuva" },
  { value: "RA422", title: "Purulent inflammation, övre luftvägar" },
  { value: "RA4221", title: "Kronisk purulent rinit" },
  { value: "RA4222", title: "Kronisk purulent sinuit" },
  { value: "RA4223", title: "Kronisk purulent aerocystit" },
  { value: "RA423", title: "Abscess/fistel, övre luftvägar" },
  { value: "RA4239", title: "Abscess/fistel i övre luftvägar utan känd orsak" },
  {
    value: "RA424",
    title: "Specifika kroniska infl tillstånd, övre luftvägar",
  },
  { value: "RA4241", title: "Mykotisk aerocystit" },
  {
    value: "RA4242",
    title: "Atrofisk rinit (Bordetella br./Past.multocida,nyssjuka svin)",
  },
  { value: "RA4243", title: "Mykotisk rinit" },
  { value: "RA429", title: "Övriga kroniska infl tillstånd, övre luftvägar" },
  { value: "RA4291", title: "Kronisk tonsillit" },
  { value: "RA4292", title: "Kronisk faryngit" },
  { value: "RA4293", title: "Kronisk laryngit" },
  { value: "RA4294", title: "Kronisk epiglottit" },
  { value: "RA4295", title: "Kronisk aerocystit" },
  { value: "RA4296", title: "Kronisk sinuit" },
  { value: "RA4299", title: "Övriga kroniska övre luftvägsinfektioner" },
  { value: "RA43", title: "Parasitära sjukdomar, övre luftvägar" },
  { value: "RA431", title: "Noskvalster/pneumonysses caninum" },
  { value: "RA5", title: "Immunmedierade tillstånd, övre luftvägar" },
  { value: "RA6", title: "Neoplastiska förändringar, övre luftvägar" },
  { value: "RA61", title: "Tumör i näshåla" },
  { value: "RA611", title: "Benigna tumörer i näshåla" },
  { value: "RA6111", title: "Papillom i näshåla" },
  { value: "RA612", title: "Maligna tumörer i näshåla" },
  { value: "RA6121", title: "Adenokarcinom i näshåla" },
  { value: "RA6122", title: "Skivepitelskarcinom i näshåla" },
  { value: "RA6123", title: "Osteosarkom i näshåla" },
  { value: "RA6124", title: "Fibrosarkom i näshåla" },
  { value: "RA62", title: "Tumör i bihåla" },
  { value: "RA621", title: "Benigna tumörer i bihåla" },
  { value: "RA622", title: "Maligna tumörer i bihåla" },
  { value: "RA63", title: "Tumör i farynx" },
  { value: "RA631", title: "Benigna tumörer i farynx" },
  { value: "RA632", title: "Maligna tumörer i farynx" },
  { value: "RA64", title: "Tumör i larynx" },
  { value: "RA641", title: "Benigna tumörer i larynx" },
  { value: "RA642", title: "Maligna tumörer i larynx" },
  { value: "RA65", title: "Tumör i tonsill" },
  { value: "RA651", title: "Benigna tumörer i tonsill" },
  { value: "RA652", title: "Maligna tumörer i tonsill" },
  { value: "RA66", title: "Tumör i luftsäck" },
  { value: "RA661", title: "Malignt melanom i luftsäck" },
  { value: "RA69", title: "Övriga tumörer i övre luftvägarna" },
  {
    value: "RA7",
    title: "Trauma, fr kr, lägeförändr, term skad, övre luftvägar",
  },
  { value: "RA71", title: "Traumatiska / mekaniska skador, övre luftvägar" },
  { value: "RA711", title: "Struppipning" },
  { value: "RA712", title: "Sårskada i mjuka gommen" },
  { value: "RA713", title: "Sårsskada i tonsiller och /eller svalg" },
  { value: "RA719", title: "Övriga sårskador i övre luftvägar" },
  { value: "RA72", title: "Främmande kroppar, övre luftvägar" },
  { value: "RA721", title: "Främmande kropp i näshåla" },
  { value: "RA722", title: "Främmande kropp i övre luftvägar" },
  { value: "RA723", title: "Främmande kropp i farynx" },
  { value: "RA724", title: "Främmande kropp i larynx" },
  { value: "RA73", title: "Lägesförändringar, övre luftvägar" },
  { value: "RA731", title: "Felläge mjuka gommen" },
  { value: "RA732", title: "Entrapment" },
  { value: "RA733", title: "Uppåtgående epiglottis" },
  { value: "RA74", title: "Termiska skador, övre luftvägar" },
  { value: "RA741", title: "Brännskada, övre luftvägar" },
  { value: "RA742", title: "Köldskada, övre luftvägar" },
  { value: "RA8", title: "Toxiska förändringar, övre luftvägar" },
  { value: "RA9", title: "Idiopatiska, ospecif, multifakt, övre luftvägar" },
  { value: "RA92", title: "Epiglottisdysfunktion" },
  { value: "RB", title: "Nedre luftvägarna" },
  {
    value: "RB0",
    title: "Normalvar, symtom u fastställd orsak, nedre luftvägar",
  },
  { value: "RB00", title: "Normalvariation, nedre luftvägar" },
  {
    value: "RB01",
    title: "Symtom på sjukdom u fastställd orsak, nedre luftvägar",
  },
  { value: "RB011", title: "Hosta från nedre luftvägar" },
  { value: "RB012", title: "Dyspné från nedre luftvägar" },
  { value: "RB013", title: "Apné" },
  { value: "RB014", title: "Forcerad andning" },
  {
    value: "RB1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, nedre luftvägar",
  },
  { value: "RB10", title: "Missbildning, nedre luftvägar" },
  { value: "RB101", title: "Kollaberad trakea" },
  { value: "RB102", title: "Trakeal hypoplasi" },
  { value: "RB1021", title: "Ciliär dysfunktion/Kartenengers syndrom" },
  { value: "RB103", title: "Bronkialcysta" },
  { value: "RB11", title: "Utvecklingsrubbn, tillväxtrubbn, nedre luftvägar" },
  {
    value: "RB2",
    title: "Metabol, nutrit, degen/dystrof förändr, nedre luftvägar",
  },
  { value: "RB20", title: "Metaboliska förändringar, nedre luftvägar" },
  { value: "RB21", title: "Nutritionella förändringar, nedre luftvägar" },
  {
    value: "RB22",
    title: "Degenerativa/dystrofiska förändringar, nedre luftvägar",
  },
  { value: "RB221", title: "Kollaberad trakea" },
  { value: "RB23", title: "Hormonella förändringar, nedre luftvägar" },
  { value: "RB3", title: "Cirkulatoriska förändringar, nedre luftvägar" },
  { value: "RB30", title: "Blödningar, nedre luftvägar" },
  { value: "RB301", title: "Lungblödning" },
  { value: "RB31", title: "Ödem, nedre luftvägar" },
  { value: "RB311", title: "Ödem pga cirkulationssvikt" },
  { value: "RB312", title: "Jakthundsödem" },
  { value: "RB313", title: "Lungödem pga elstöt" },
  { value: "RB314", title: "Neurogent lungödem" },
  { value: "RB32", title: "Infarkter, nedre luftvägar" },
  { value: "RB34", title: "Trombos / emboli, nedre luftvägar" },
  { value: "RB341", title: "Lungemboli" },
  {
    value: "RB35",
    title: "Arterio-atero-skleros förändringar, nedre luftvägar",
  },
  { value: "RB4", title: "Infekt, infl förändringar, nedre luftvägar" },
  { value: "RB40", title: "Specifika infektionssjukdomar, nedre luftvägar" },
  { value: "RB41", title: "Akuta inflammationstillstånd, nedre luftvägar" },
  {
    value: "RB411",
    title: "Akut serös / serofibrinös inflammation, nedre luftvägar",
  },
  { value: "RB412", title: "Akut purulent inflammation, nedre luftvägar" },
  { value: "RB413", title: "Specifika akuta infl tillstånd, nedre luftvägar" },
  { value: "RB4131", title: "Eosinofil pneumoni" },
  { value: "RB4132", title: "Nekrotiserande pneumoni" },
  { value: "RB419", title: "Övriga akuta infl tillstånd, nedre luftvägar" },
  { value: "RB4191", title: "Akut trakeit" },
  { value: "RB41911", title: "Kennelhosta" },
  { value: "RB4192", title: "Akut bronkit" },
  { value: "RB4193", title: "Akut bronkopneumoni" },
  { value: "RB42", title: "Kroniska inflammationstillstånd, nedre luftvägar" },
  { value: "RB421", title: "Kronisk serös inflammation, nedre luftvägar" },
  { value: "RB422", title: "Purulent inflammation, nedre luftvägar" },
  { value: "RB4221", title: "Kronisk purulent bronkopneumoni" },
  { value: "RB423", title: "Abscess/fistel, nedre luftvägar" },
  { value: "RB4231", title: "Abscederande pneumoni" },
  { value: "RB4232", title: "Viruspneumoni (PIV-3, Adeno,Reov, mfl)" },
  { value: "RB4233", title: "Mykotisk pneumoni" },
  {
    value: "RB424",
    title: "Specifika kroniska infl tillstånd, nedre luftvägar",
  },
  { value: "RB4241", title: "Kronisk eosinofil pneumoni" },
  { value: "RB4242", title: "Atypisk pneumoni (ex. Farmers Lung)" },
  { value: "RB429", title: "Övriga kroniska infl tillstånd, nedre luftvägar" },
  { value: "RB4291", title: "Kronisk trakeit" },
  { value: "RB4292", title: "Kronisk bronkit" },
  { value: "RB42921", title: "Astma" },
  { value: "RB42922", title: "Felin astma" },
  { value: "RB4293", title: "Kronisk bronkopneumoni" },
  { value: "RB4294", title: "Lungemfysem" },
  { value: "RB4295", title: "COPD (Chronic obstructive pulmonary disease)" },
  { value: "RB4296", title: "Lungfibros" },
  { value: "RB4297", title: "Kvickdrag" },
  { value: "RB4298", title: "Bronkiolit" },
  { value: "RB42981", title: "Peribronkiolit" },
  { value: "RB4299", title: "Alveolit" },
  { value: "RB43", title: "Parasitära sjukdomar, nedre luftvägar" },
  { value: "RB5", title: "Immunmedierade tillstånd, nedre luftvägar" },
  { value: "RB51", title: "Allergisk bronkit (astma)" },
  { value: "RB6", title: "Neoplastiska förändringar, nedre luftvägar" },
  { value: "RB61", title: "Tumör i trakea" },
  { value: "RB62", title: "Tumör i lunga" },
  { value: "RB621", title: "Primär lungtumör" },
  { value: "RB6211", title: "Adenocarcinom i lunga" },
  { value: "RB622", title: "Lungmetastaser" },
  {
    value: "RB7",
    title: "Trauma, fr kr, lägeförändr, term skad, nedre luftvägar",
  },
  { value: "RB71", title: "Traumatiska / mekaniska skador, nedre luftvägar" },
  { value: "RB711", title: "Lungkontusion" },
  { value: "RB712", title: "Atelektas" },
  { value: "RB713", title: "Sår på luftstrupen" },
  { value: "RB72", title: "Främmande kroppar, nedre luftvägar" },
  { value: "RB721", title: "Främmande kropp i trakea" },
  { value: "RB722", title: "Främmande kropp i bronker" },
  { value: "RB723", title: "Aspirationspneumoni" },
  { value: "RB73", title: "Lägesförändringar, nedre luftvägar" },
  { value: "RB731", title: "Torsion av lunglob" },
  { value: "RB74", title: "Termiska skador, nedre luftvägar" },
  { value: "RB741", title: "Brännskada, nedre luftvägar" },
  { value: "RB742", title: "Köldskada, nedre luftvägar" },
  { value: "RB8", title: "Toxiska förändringar, nedre luftvägar" },
  { value: "RB81", title: "Toxiskt lungödem" },
  { value: "RB82", title: "Rökgasödem i lunga" },
  { value: "RB83", title: "Luftvägsirritation pga rök" },
  { value: "RB9", title: "Idiopatiska, ospecif, multifakt, nedre luftvägar" },
  { value: "RC", title: "Brösthåla, brösthinnor" },
  {
    value: "RC0",
    title: "Normalvar, symtom u fastställd orsak, brösthåla /hinnor",
  },
  { value: "RC00", title: "Normalvariation, brösthåla /hinnor" },
  {
    value: "RC01",
    title: "Symtom på sjukdom u fastställd orsak, brösthåla /hinnor",
  },
  {
    value: "RC1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, brösthåla /hinnor",
  },
  { value: "RC10", title: "Missbildning, brösthåla /hinnor" },
  { value: "RC101", title: "Kylothorax" },
  {
    value: "RC11",
    title: "Utvecklingsrubbn, tillväxtrubbn, brösthåla /hinnor",
  },
  {
    value: "RC2",
    title: "Metabol, nutrit, degen/dystrof förändr, brösthåla /hinnor",
  },
  { value: "RC20", title: "Metaboliska förändringar, brösthåla /hinnor" },
  { value: "RC21", title: "Nutritionella förändringar, brösthåla /hinnor" },
  { value: "RC211", title: "Nutritionellt betingad hydrothorax" },
  {
    value: "RC22",
    title: "Degenerativa/dystrofiska förändringar, brösthåla /hinnor",
  },
  { value: "RC23", title: "Hormonella förändringar, brösthåla /hinnor" },
  { value: "RC3", title: "Cirkulatoriska förändringar, brösthåla /hinnor" },
  { value: "RC30", title: "Blödningar, brösthåla /hinnor" },
  { value: "RC301", title: "Hemothorax, brösthåla /hinnor" },
  { value: "RC31", title: "Ödem, brösthåla /hinnor" },
  { value: "RC32", title: "Infarkter, brösthåla /hinnor" },
  { value: "RC33", title: "Hygrom, brösthåla /hinnor" },
  { value: "RC34", title: "Trombos / emboli, brösthåla /hinnor" },
  {
    value: "RC35",
    title: "Arterio-atero-skleros förändringar, brösthåla /hinnor",
  },
  { value: "RC4", title: "Infekt, infl förändringar, brösthåla /hinnor" },
  { value: "RC40", title: "Specifika infektionssjukdomar, brösthåla /hinnor" },
  { value: "RC41", title: "Akuta inflammationstillstånd, brösthåla /hinnor" },
  {
    value: "RC411",
    title: "Akut serös / serofibrinös inflammation, brösthåla /hinnor",
  },
  { value: "RC4111", title: "Akut serös pleurit" },
  { value: "RC412", title: "Akut purulent inflammation, brösthåla /hinnor" },
  { value: "RC4121", title: "Akut purulent pleurit" },
  {
    value: "RC413",
    title: "Specifika akuta infl tillstånd, brösthåla /hinnor",
  },
  { value: "RC419", title: "Övriga akuta infl tillstånd, brösthåla /hinnor" },
  { value: "RC4191", title: "Pleuropneumonit / transportsjuka" },
  {
    value: "RC42",
    title: "Kroniska inflammationstillstånd, brösthåla /hinnor",
  },
  { value: "RC421", title: "Kroniskt serös inflammation, brösthåla /hinnor" },
  { value: "RC4211", title: "Kronisk serös pleurit" },
  {
    value: "RC422",
    title: "Kroniskt purulent inflammation, brösthåla /hinnor",
  },
  { value: "RC4221", title: "Kronisk purulent pleurit" },
  { value: "RC423", title: "Abscess/fistel, brösthåla /hinnor" },
  {
    value: "RC424",
    title: "Specifika kroniska infl tillstånd, brösthåla /hinnor",
  },
  {
    value: "RC429",
    title: "Övriga kroniska infl tillstånd, brösthåla /hinnor",
  },
  { value: "RC4291", title: "Kronisk pleurit" },
  { value: "RC43", title: "Parasitära sjukdomar, brösthåla /hinnor" },
  { value: "RC5", title: "Immunmedierade tillstånd, brösthåla /hinnor" },
  { value: "RC6", title: "Neoplastiska förändringar, brösthåla /hinnor" },
  { value: "RC61", title: "Tumör i brösthinnor" },
  { value: "RC62", title: "Tumör i mediastinum" },
  { value: "RC63", title: "Thymom" },
  {
    value: "RC7",
    title: "Trauma, fr kr, lägeförändr, term skad, brösthåla /hinnor",
  },
  { value: "RC71", title: "Traumatiska / mekaniska skador, brösthåla /hinnor" },
  { value: "RC711", title: "Pneumothorax" },
  { value: "RC712", title: "Traumatisk hemothorax" },
  { value: "RC713", title: "Pneumomediastinum" },
  { value: "RC72", title: "Främmande kroppar i brösthålan" },
  { value: "RC721", title: "Främmande kropp i brösthålan" },
  { value: "RC73", title: "Lägesförändringar, brösthåla /hinnor" },
  { value: "RC74", title: "Termiska skador, brösthåla /hinnor" },
  { value: "RC8", title: "Toxiska förändringar, brösthåla /hinnor" },
  { value: "RC9", title: "Idiopatiska, ospecif, multifakt, brösthåla /hinnor" },
  { value: "RC91", title: "Hydrothorax" },
  { value: "RC92", title: "Idiopatisk kylothorax" },
  { value: "RC93", title: "Spontan pneumothorax" },
  { value: "S", title: "Skelettets sjukdomar" },
  { value: "SA", title: "Huvudets ben" },
  { value: "SA0", title: "Normalvar, symtom u fastställd orsak, huvudets ben" },
  { value: "SA00", title: "Normalvariation, huvudets ben" },
  {
    value: "SA01",
    title: "Symtom på sjukdom u fastställd orsak, huvudets ben",
  },
  { value: "SA011", title: "Skelettömhet huvudets ben" },
  {
    value: "SA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, huvudets ben",
  },
  { value: "SA10", title: "Missbildning, huvudets ben" },
  { value: "SA101", title: "Övertaliga skelettdelar huvudets ben" },
  {
    value: "SA102",
    title: "Aplasi eller hypoplasi av skelettdelar huvudets ben",
  },
  { value: "SA103", title: "Felställda skelettdelar huvudets ben" },
  { value: "SA104", title: "Kondrodystrofi (akondroplasi) huvudets ben" },
  { value: "SA109", title: "Övriga skelettmissbildningar huvudets ben" },
  { value: "SA1091", title: "Kvarstående fontanell" },
  { value: "SA1092", title: "Occipital dysplasi (förstorat foramen magnum)" },
  { value: "SA1093", title: "Dysostos (defekt benbildning under fosterlivet)" },
  {
    value: "SA1099",
    title: "Övriga ej definierade skelettmissbildningar huvudets ben",
  },
  {
    value: "SA11",
    title: "Utvecklingsrubbningar tillväxtrubbningar huvudets ben",
  },
  { value: "SA111", title: "Samtidig ben och brosksjukdom" },
  { value: "SA1111", title: "Osteokondros huvudets ben" },
  { value: "SA113", title: "Osteokondrodysplasi , huvudets ben" },
  {
    value: "SA119",
    title: "Övriga utvecklingsrubbn, tillväxtrubbn huvudets ben",
  },
  { value: "SA1192", title: "Bencysta, aseptisk bennekros, huvudets ben" },
  { value: "SA1193", title: "Kraniomandibulär osteopati" },
  {
    value: "SA2",
    title: "Metabol, nutrit, degen/dystrof förändr, huvudets ben",
  },
  { value: "SA20", title: "Metaboliska förändringar huvudets ben" },
  { value: "SA201", title: "Osteomalaci / osteolys huvudets ben" },
  { value: "SA202", title: "Osteoporos huvudets ben" },
  { value: "SA203", title: "Osteodystrofi    huvudets ben" },
  { value: "SA204", title: "Benmetaplasier huvudets ben" },
  { value: "SA21", title: "Nutritionella förändringar, huvudets ben" },
  {
    value: "SA22",
    title: "Degenerativa/dystrofiska förändringar, huvudets ben",
  },
  { value: "SA224", title: "Pulmonär hypertrofisk osteopati i huvudets ben" },
  { value: "SA225", title: "Aseptisk bennekros i huvudets ben" },
  { value: "SA23", title: "Hormonella förändringar, huvudets ben" },
  { value: "SA3", title: "Cirkulatoriska förändringar, huvudets ben" },
  { value: "SA30", title: "Blödningar, huvudets ben" },
  { value: "SA301", title: "Subperiostal blödning, huvudets ben" },
  { value: "SA302", title: "Blödning till epiduralrum, huvudets ben" },
  { value: "SA4", title: "Infekt, infl förändringar, huvudets ben" },
  { value: "SA40", title: "Specifika infektionssjukdomar, huvudets ben" },
  { value: "SA41", title: "Akuta inflammationstillstånd, huvudets ben" },
  {
    value: "SA411",
    title: "Akut serös / serofibrinös inflammation, huvudets ben",
  },
  { value: "SA412", title: "Akut purulent inflammation huvudets ben" },
  { value: "SA419", title: "Övriga akuta infl tillstånd, huvudets ben" },
  { value: "SA4191", title: "Akut periostit huvudets ben" },
  { value: "SA41911", title: "Sekundär akut periostit huvudets ben" },
  { value: "SA4192", title: "Akut ostit huvudets ben" },
  { value: "SA4193", title: "Akut osteomyelit huvudets ben" },
  { value: "SA41931", title: "Sekundär akut osteomyelit huvudets ben" },
  {
    value: "SA41932",
    title: "Sekundär akut osteomyelit e kirurgiskt ingrepp huvudets ben",
  },
  { value: "SA42", title: "Kroniska inflammationstillstånd, huvudets ben" },
  { value: "SA421", title: "Kroniskt serös inflammation, huvudets ben" },
  { value: "SA422", title: "Purulenta huvudets ben" },
  { value: "SA423", title: "Abscess/fistel huvudets ben" },
  { value: "SA424", title: "Specifika kroniska infl tillstånd, huvudets ben" },
  { value: "SA429", title: "Övriga kroniska infl tillstånd, huvudets ben" },
  { value: "SA4291", title: "Kronisk periostit huvudets ben" },
  { value: "SA42911", title: "Sekundär kronisk periostit huvudets ben" },
  { value: "SA4292", title: "Kronisk ostit huvudets ben" },
  { value: "SA4293", title: "Kronisk osteomyelit huvudets ben" },
  { value: "SA42931", title: "Sekundär kronisk osteomyelit huvudets ben" },
  {
    value: "SA42932",
    title: "Sekundär kronisk osteomyelit e kir ingrepp huvudets ben",
  },
  { value: "SA4294", title: "Deformerande beninflammation huvudets ben" },
  { value: "SA5", title: "Immunmedierade tillstånd, huvudets benv" },
  { value: "SA6", title: "Neoplastiska förändringar, huvudets ben" },
  {
    value: "SA61",
    title: "Malign primär tumör i ben o/e brosk inkl led, huvudets ben",
  },
  {
    value: "SA611",
    title: "Malign tumör utgången från broskvävnad huvudets ben",
  },
  { value: "SA6111", title: "Kondrosarkom huvudets ben" },
  { value: "SA6112", title: "Kondromyxosarkom huvudets ben" },
  {
    value: "SA612",
    title: "Malign tumör utgången från benvävnad huvudets ben",
  },
  { value: "SA6121", title: "Osteosarkom huvudets ben" },
  { value: "SA613", title: "Malign tumör av bindvävsursprung huvudets ben" },
  { value: "SA6131", title: "Fibrosarkom huvudets ben" },
  {
    value: "SA614",
    title: "Malign tumör av vaskulärt eller lymf urspr huvudets ben",
  },
  {
    value: "SA6141",
    title: "Angiosarkom,hemangiendoteliom,hemangiopericytom huvudets ben",
  },
  { value: "SA615", title: "Metastas till skelett, huvudets ben" },
  { value: "SA619", title: "Skelettumör av malign karaktär, UNS huvudets ben" },
  {
    value: "SA62",
    title: "Benign tumör i ben o/e brosk inkl led huvudets ben",
  },
  {
    value: "SA621",
    title: "Benign tumör utgången från broskvävnad huvudets ben",
  },
  { value: "SA6211", title: "Kondroblastom huvudets ben" },
  { value: "SA6212", title: "Ostekondrom huvudets ben" },
  { value: "SA6213", title: "Enkondrom huvudets ben" },
  {
    value: "SA622",
    title: "Benign tumör utgången från benvävnad huvudets ben",
  },
  { value: "SA6221", title: "Osteom  huvudets ben" },
  { value: "SA6222", title: "Osteoblastom huvudets ben" },
  { value: "SA623", title: "Benign tumör av bindvävsursprung huvudets ben" },
  { value: "SA6231", title: "Fibrom huvudets ben" },
  { value: "SA6232", title: "Fibröst histiocytom huvudets ben" },
  {
    value: "SA624",
    title: "Benign tumör av vaskulärt eller lymf urspr huvudets ben",
  },
  { value: "SA6241", title: "Hemangiom  huvudets ben" },
  { value: "SA6242", title: "Lymfangiom  huvudets ben" },
  { value: "SA628", title: "Övriga benigna skelettumörer huvudets ben" },
  { value: "SA6281", title: "Jättecellstumör huvudets ben" },
  { value: "SA6282", title: "Adamantinom (tandanlagsnybildning) huvudets ben" },
  { value: "SA629", title: "Skelettumör av benign karaktär, UNS huvudets ben" },
  {
    value: "SA7",
    title: "Trauma, fr kr, lägeförändr, term skad, huvudets ben",
  },
  { value: "SA71", title: "Traumatiska / mekaniska skador, huvudets ben" },
  { value: "SA711", title: "Fissur i huvudets ben" },
  { value: "SA713", title: "Fraktur i huvudets ben" },
  {
    value: "SA7136",
    title: "Avsprängningsfraktur proximalt /kranialt i huvudets ben",
  },
  {
    value: "SA7137",
    title: "Avsprängningsfraktur distalt /kaudalt i huvudets ben",
  },
  { value: "SA7139", title: "Kompressionsfraktur i huvudets ben" },
  { value: "SA714", title: "Fraktur av speciell typ i huvudets ben" },
  { value: "SA7145", title: "Symfysfraktur av hårda gommen" },
  { value: "SA7146", title: "Fraktur av skallens ben ospecificerat" },
  {
    value: "SA7149",
    title: "Patologisk fraktur, spontanfraktur i huvudets ben UNS",
  },
  { value: "SA715", title: "Komplikation till frakturläkning i huvudets ben" },
  { value: "SA7151", title: "Pseudoartros i huvudets ben" },
  {
    value: "SA7152",
    title: "Atrofi av benvävnad i huvudets ben som kompl t  frakturläkn",
  },
  { value: "SA7153", title: "Felläkt fraktur i huvudets ben" },
  { value: "SA719", title: "Övriga skelettskador, huvudets ben" },
  { value: "SA72", title: "Främmande kroppar, huvudets ben" },
  { value: "SA73", title: "Lägesförändringar, huvudets ben" },
  { value: "SA74", title: "Termiska skador, huvudets ben" },
  { value: "SA741", title: "Brännskada, huvudets ben" },
  { value: "SA742", title: "Köldskada, huvudets ben" },
  { value: "SA8", title: "Toxiska förändringar, huvudets ben" },
  {
    value: "SA9",
    title: "Idiopatiska ospecificerade multifaktoriella, huvudets ben",
  },
  { value: "SB", title: "Mandibula" },
  { value: "SB 0", title: "Normalvar, symtom u fastställd orsak, mandibula" },
  { value: "SB00", title: "Normalvariation, mandibula" },
  { value: "SB01", title: "Symtom på sjukdom u fastställd orsak, mandibula" },
  { value: "SB011", title: "Skelettömhet i mandibula, mandibula" },
  {
    value: "SB1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, mandibula",
  },
  { value: "SB10", title: "Missbildning, mandibula" },
  { value: "SB101", title: "Övertaliga skelettdelar i mandibula" },
  {
    value: "SB102",
    title: "Aplasi eller hypoplasi av skelettdelar i mandibula",
  },
  { value: "SB103", title: "Felställda skelettdelar i mandibula" },
  { value: "SB104", title: "Kondrodystrofi (akondroplasi) i mandibula" },
  { value: "SB109", title: "Övriga skelettmissbildningar i mandibula" },
  {
    value: "SB1093",
    title: "Dysostos i mandibula (defekt benbildning under fosterlivet)",
  },
  {
    value: "SB1099",
    title: "Övriga ej definierade skelettmissbildningar i mandibula",
  },
  { value: "SB11", title: "Utvecklingsrubbn, tillväxtrubbn, mandibula" },
  { value: "SB111", title: "Samtidig ben och brosksjukdom, mandibula" },
  { value: "SB1111", title: "Osteokondros i mandibula" },
  {
    value: "SB1113",
    title: "Subkondral bencysta av osteokondrostyp i mandibula",
  },
  { value: "SB113", title: "Osteokondrodysplasi i mandibula" },
  {
    value: "SB119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i skelett, mandibula",
  },
  { value: "SB1191", title: "Panostit (endostsos) i mandibula" },
  { value: "SB1192", title: "Bencysta, aseptisk bennekros i mandibula" },
  { value: "SB1193", title: "Kraniomandibulär osteopati, mandibula" },
  {
    value: "SB1194",
    title: "Metafysär osteopati (hypertrofisk osteodystrofi), mandibula",
  },
  { value: "SB2", title: "Metabol, nutrit, degen/dystrof förändr, mandibula" },
  { value: "SB20", title: "Metaboliska förändringar, mandibula" },
  { value: "SB201", title: "Osteomalaci / osteolys i mandibula" },
  { value: "SB202", title: "Osteoporos i mandibula" },
  { value: "SB203", title: "Osteodystrofi i mandibula" },
  { value: "SB204", title: "Benmetaplasier i mandibula" },
  { value: "SB21", title: "Nutritionella förändringar, mandibula" },
  { value: "SB22", title: "Degenerativa/dystrofiska förändringar, mandibula" },
  { value: "SB224", title: "Pulmonär hypertrofisk osteopati i mandibula" },
  { value: "SB225", title: "Aseptisk bennekros i mandibula" },
  { value: "SB23", title: "Hormonella förändringar, mandibula" },
  { value: "SB3", title: "Cirkulatoriska förändringar, mandibula" },
  { value: "SB30", title: "Blödningar, mandibula" },
  { value: "SB301", title: "Subperiostal blödning i mandibula" },
  { value: "SB31", title: "Ödem, mandibula" },
  { value: "SB32", title: "Infarkter, mandibula" },
  { value: "SB33", title: "Hygrom, mandibula" },
  { value: "SB34", title: "Trombos / emboli, mandibula" },
  { value: "SB35", title: "Arterio-atero-skleros förändringar, mandibula" },
  { value: "SB4", title: "Infekt, infl förändringar, mandibula" },
  { value: "SB40", title: "Specifika infektionssjukdomar, mandibula" },
  { value: "SB41", title: "Akuta inflammationstillstånd, mandibula" },
  {
    value: "SB411",
    title: "Akut serös / serofibrinös inflammation, mandibula",
  },
  { value: "SB412", title: "Akut purulent inflammation, mandibula" },
  { value: "SB413", title: "Specifika akuta infl tillstånd, mandibula" },
  { value: "SB419", title: "Övriga akuta infl tillstånd, mandibula" },
  { value: "SB4191", title: "Akut periostit, mandibula" },
  { value: "SB41911", title: "Sekundär akut periostit, mandibula" },
  { value: "SB4192", title: "Akut ostit, mandibula" },
  { value: "SB4193", title: "Akut osteomyelit, mandibula" },
  { value: "SB41931", title: "Sekundär akut osteomyelit, mandibula" },
  {
    value: "SB41932",
    title: "Sekundär akut osteomyelit e kir ingrepp, mandibula",
  },
  { value: "SB4198", title: "Akut fysit , mandibula" },
  { value: "SB42", title: "Kroniska inflammationstillstånd, mandibula" },
  { value: "SB421", title: "Kroniskt serös inflammation, mandibula" },
  { value: "SB422", title: "Purulent inflammation, mandibula" },
  { value: "SB423", title: "Abscess/fistel, mandibula" },
  { value: "SB424", title: "Specifika kroniska infl tillstånd, mandibula" },
  { value: "SB429", title: "Övriga kroniska infl tillstånd, mandibula" },
  { value: "SB4291", title: "Kronisk periostit  i mandibula" },
  { value: "SB42911", title: "Sekundär kronisk periostit i mandibula" },
  { value: "SB4292", title: "Kronisk ostit i mandibula" },
  { value: "SB4293", title: "Kronisk osteomyelit i mandibula" },
  { value: "SB42931", title: "Sekundär kronisk osteomyelit i mandibula" },
  {
    value: "SB42932",
    title: "Sekundär kronisk osteomyelit i mandibula e kir ingrepp",
  },
  { value: "SB4294", title: "Deformerande beninflammation i mandibula" },
  { value: "SB4298", title: "Kronisk fysit , mandibula" },
  { value: "SB43", title: "Parasitära sjukdomar, mandibula" },
  { value: "SB5", title: "Immunmedierade tillstånd, mandibula" },
  { value: "SB6", title: "Neoplastiska förändringar, mandibula" },
  {
    value: "SB61",
    title: "Malign primär tumör i ben o/e brosk inkl led, mandibula",
  },
  {
    value: "SB611",
    title: "Malign tumör i mandibula utgången från broskvävnad",
  },
  { value: "SB6111", title: "Kondrosarkom i mandibula" },
  { value: "SB6112", title: "Kondromyxosarkom i mandibula" },
  { value: "SB612", title: "Malign tumör i mandibula utgången från benvävnad" },
  { value: "SB6121", title: "Osteosarkom i mandibula" },
  { value: "SB613", title: "Malign tumör i mandibula av bindvävsursprung" },
  { value: "SB6131", title: "Fibrosarkom i mandibula" },
  {
    value: "SB614",
    title: "Malign tumör i mandibula av vaskulärt eller lymf urspr",
  },
  {
    value: "SB6141",
    title: "Angiosarkom,hemangioendoteliom,hemangiopericytom i mandibula",
  },
  { value: "SB615", title: "Metastas till skelett i mandibula" },
  { value: "SB619", title: "Skelettumör av malign karaktär i mandibula, UNS" },
  { value: "SB62", title: "Benign tumör i ben o/e brosk inkl led, mandibula" },
  {
    value: "SB621",
    title: "Benign tumör i mandibula utgången från broskvävnad",
  },
  { value: "SB6211", title: "Kondroblastom i mandibula" },
  { value: "SB6212", title: "Ostekondrom i mandibula" },
  { value: "SB6213", title: "Enkondrom i mandibula" },
  { value: "SB622", title: "Benign tumör i mandibula utgången från benvävnad" },
  { value: "SB6221", title: "Osteom  i mandibula" },
  { value: "SB6222", title: "Osteoblastom i mandibula" },
  { value: "SB623", title: "Benign tumör i mandibula av bindvävsursprung" },
  { value: "SB6231", title: "Fibrom i mandibula" },
  { value: "SB6232", title: "Fibröst histiocytom i mandibula" },
  {
    value: "SB624",
    title: "Benign tumör i mandibula av vaskulärt eller lymf urspr",
  },
  { value: "SB6241", title: "Hemangiom i mandibula" },
  { value: "SB6242", title: "Lymfangiom i mandibula" },
  { value: "SB628", title: "Övriga benigna skelettumörer i mandibula" },
  { value: "SB6281", title: "Jättecellstumör i mandibula" },
  { value: "SB6282", title: "Adamantinom (tandanlagsnybildning) i mandibula" },
  { value: "SB629", title: "Skelettumör av benign karaktär i mandibula, UNS" },
  { value: "SB7", title: "Trauma, fr kr, lägeförändr, term skad, mandibula" },
  { value: "SB71", title: "Traumatiska / mekaniska skador, mandibula" },
  { value: "SB711", title: "Fissur, mandibula" },
  { value: "SB713", title: "Fraktur, mandibula" },
  { value: "SB7131", title: "Diafysfraktur  / fraktur på korpus, mandibula" },
  { value: "SB7132", title: "Lednära fraktur proximalt /kranialt, mandibula" },
  { value: "SB7133", title: "Lednära fraktur distalt /kaudalt, mandibula" },
  {
    value: "SB7134",
    title: "Frakt som berör angräns led/leder prox/kran, mandibula",
  },
  {
    value: "SB7135",
    title: "Frakt som berör angräns led/leder dist/kaud, mandibula",
  },
  {
    value: "SB7136",
    title: "Avsprängningsfraktur proximalt /kranialt, mandibula",
  },
  {
    value: "SB7137",
    title: "Avsprängningsfraktur distalt /kaudalt , mandibula",
  },
  { value: "SB7139", title: "Kompressionsfraktur, mandibula" },
  { value: "SB714", title: "Fraktur av speciell typ, mandibula" },
  {
    value: "SB7141",
    title: "Kortikal subperiostal fraktur (greenstick, mandibula)",
  },
  { value: "SB7142", title: "Fraktur av osteofyt, mandibula" },
  { value: "SB7143", title: "Mandibulasymfysfraktur" },
  { value: "SB7144", title: "Fraktur av underkäke, mandibula" },
  {
    value: "SB7149",
    title: "Patologisk fraktur i mandibula, spontanfraktur UNS",
  },
  { value: "SB715", title: "Komplikation till frakturläkning, mandibula" },
  { value: "SB7151", title: "Pseudoartros i mandibula" },
  {
    value: "SB7152",
    title: "Atrofi av benvävnad i mandibula som kompl t frakturläkn",
  },
  { value: "SB7153", title: "Felläkt fraktur i mandibula" },
  { value: "SB719", title: "Övriga skelettskador i mandibula" },
  { value: "SB72", title: "Främmande kroppar i mandibula" },
  { value: "SB73", title: "Lägesförändringar, mandibula" },
  { value: "SB74", title: "Termiska skador, mandibula" },
  { value: "SB741", title: "Brännskada, mandibula" },
  { value: "SB742", title: "Köldskada, mandibula" },
  { value: "SB8", title: "Toxiska förändringar, mandibula" },
  {
    value: "SB9",
    title: "Idiopatiska ospecificerade multifaktoriella, mandibula",
  },
  { value: "SC", title: "Kotpelaren" },
  { value: "SC0", title: "Normalvar, symtom u fastställd orsak, kotpelaren" },
  { value: "SC00", title: "Normalvariation, kotpelaren" },
  { value: "SC01", title: "Symtom på sjukdom u fastställd orsak, kotpelaren" },
  { value: "SC011", title: "Skelettömhet i kotpelaren" },
  { value: "SC012", title: "Torticollis" },
  { value: "SC013", title: "Ökad rörlighet i kotpelaren" },
  {
    value: "SC1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, kotpelaren",
  },
  { value: "SC10", title: "Missbildning i kotpelaren" },
  { value: "SC101", title: "Övertaliga skelettdelar i kotpelaren" },
  {
    value: "SC102",
    title: "Aplasi eller hypoplasi av skelettdelar, kotpelaren",
  },
  { value: "SC1021", title: "Aplasi eller felställning av dens axis" },
  { value: "SC103", title: "Felställda skelettdelar  i kotpelaren" },
  { value: "SC104", title: "Kondrodystrofi (akondroplasi) i kotpelaren" },
  { value: "SC109", title: "Övriga skelettmissbildningar i kotpelaren" },
  {
    value: "SC1093",
    title: "Dysostos (defekt benbildning under fosterlivet), kotpelaren",
  },
  { value: "SC1094", title: "Kotmissbildningar" },
  { value: "SC10941", title: "Spina bifida" },
  { value: "SC10942", title: "Missbildade halskotor" },
  { value: "SC10943", title: "Missbildade thorakolumbalkotor" },
  { value: "SC10944", title: "Missbildad sacrum" },
  { value: "SC10945", title: "Svansmissbildning (kroksvans)" },
  { value: "SC10946", title: "Sakralisation" },
  { value: "SC10947", title: "Fusion av kotkroppar (blockkota)" },
  { value: "SC10948", title: "Hemivertebra" },
  { value: "SC10949", title: "Shistosoma reflexum" },
  {
    value: "SC1099",
    title: "Övriga ej definierade skelettmissbildningar i kotpelaren",
  },
  {
    value: "SC11",
    title: "Utvecklingsrubbningar tillväxtrubbningar i kotpelaren",
  },
  { value: "SC111", title: "Samtidig ben och brosksjukdom, kotpelaren" },
  { value: "SC1111", title: "Osteokondros i kotpelaren" },
  { value: "SC11111", title: "Osteokondros utan ledmus, kotpelaren" },
  { value: "SC11112", title: "Osteokondros med ledmus, kotpelaren" },
  {
    value: "SC11113",
    title: "Subkondral bencysta av osteokondrostyp, kotpelaren",
  },
  {
    value: "SC112",
    title: "Cervikal vertebral instabilitet / stenos (wobbler)",
  },
  { value: "SC113", title: "Osteokondrodysplasi  i kotpelaren" },
  { value: "SC116", title: "Felaktig rygghållning" },
  { value: "SC1161", title: "Kyfos , karprygg" },
  { value: "SC1162", title: "Lordos, svankrygg" },
  { value: "SC1163", title: "Skolios" },
  {
    value: "SC119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i kotpelaren",
  },
  { value: "SC1192", title: "Bencysta, aseptisk bennekros i kotpelaren" },
  { value: "SC2", title: "Metabol, nutrit, degen/dystrof förändr, kotpelaren" },
  { value: "SC20", title: "Metaboliska förändringar, kotpelaren" },
  { value: "SC201", title: "Osteomalaci / osteolys i kotpelaren" },
  { value: "SC202", title: "Osteoporos i kotpelaren" },
  { value: "SC203", title: "Osteodystrofi   i kotpelaren" },
  { value: "SC204", title: "Benmetaplasier i kotpelaren" },
  {
    value: "SC2041",
    title: "Benmetaplasi nära kotpelaren, halsregionen,thorakolumbal reg",
  },
  { value: "SC21", title: "Nutritionella förändringar, kotpelaren" },
  { value: "SC22", title: "Degenerativa/dystrofiska förändringar, kotpelaren" },
  { value: "SC221", title: "Diskdegeneration" },
  { value: "SC2211", title: "Diskdegeneration utan förkalkning" },
  { value: "SC22111", title: "Lumbosacral instabilitet (L7-S1 syndromet)" },
  { value: "SC2212", title: "Diskdegeneration med förkalkning" },
  { value: "SC222", title: "Diskbråck" },
  { value: "SC2221", title: "Diskbråck i cervikalregionen" },
  { value: "SC2222", title: "Diskbråck i thorakolumbalregionen" },
  { value: "SC223", title: "Spondylos" },
  { value: "SC2231", title: "Lokal spondylos" },
  { value: "SC2232", title: "Generell utbredd spondylos" },
  { value: "SC225", title: "Aseptisk bennekros" },
  { value: "SC23", title: "Hormonella förändringar, kotpelaren" },
  { value: "SC3", title: "Cirkulatoriska förändringar, kotpelaren" },
  { value: "SC30", title: "Blödningar, kotpelaren" },
  { value: "SC301", title: "Subperiostal blödning, kotpelaren" },
  { value: "SC302", title: "Blödning till epiduralrum, kotpelaren" },
  { value: "SC31", title: "Ödem, kotpelaren" },
  { value: "SC32", title: "Infarkter, kotpelaren" },
  { value: "SC33", title: "Hygrom, kotpelaren" },
  { value: "SC34", title: "Trombos / emboli, kotpelaren" },
  { value: "SC35", title: "Arterio-atero-skleros förändringar, kotpelaren" },
  { value: "SC4", title: "Infekt, infl förändringar, kotpelaren" },
  { value: "SC40", title: "Specifika infektionssjukdomar, kotpelaren" },
  { value: "SC41", title: "Akuta inflammationstillstånd, kotpelaren" },
  {
    value: "SC411",
    title: "Akut serösa / serofibrinö inflammation, kotpelaren",
  },
  { value: "SC412", title: "Akut purulent inflammation, kotpelaren" },
  { value: "SC413", title: "Specifika akuta infl tillstånd, kotpelaren" },
  { value: "SC419", title: "Övriga akuta infl tillstånd, kotpelaren" },
  { value: "SC4191", title: "Akut periostit i kotpelaren" },
  { value: "SC41911", title: "Sekundär akut periostit i kotpelaren" },
  { value: "SC4192", title: "Akut ostit i kotpelaren" },
  { value: "SC4193", title: "Akut osteomyelit i kotpelaren" },
  { value: "SC41931", title: "Sekundär akut osteomyelit i kotpelaren" },
  {
    value: "SC41932",
    title: "Sekundär akut osteomyelit e kir ingrepp i kotpelaren",
  },
  { value: "SC4195", title: "Akut diskospondylit" },
  { value: "SC4198", title: "Akut fysit  i kotpelaren" },
  { value: "SC42", title: "Kroniska inflammationstillstånd, kotpelaren" },
  { value: "SC421", title: "Kronisk serös inflammation, kotpelaren" },
  { value: "SC422", title: "Purulent inflammation, kotpelaren" },
  { value: "SC423", title: "Abscess/fistel, kotpelaren" },
  { value: "SC424", title: "Specifika kroniska infl tillstånd, kotpelaren" },
  { value: "SC429", title: "Övriga kroniska infl tillstånd, kotpelaren" },
  { value: "SC4291", title: "Kronisk periostit  i kotpelaren" },
  { value: "SC42911", title: "Sekundär kronisk periostit i kotpelaren" },
  { value: "SC4292", title: "Kronisk ostit i kotpelaren" },
  { value: "SC4293", title: "Kronisk osteomyelit i kotpelaren" },
  { value: "SC42931", title: "Sekundär kronisk osteomyelit i kotpelaren" },
  {
    value: "SC42932",
    title: "Sekundär kronisk osteomyelit e kir ingrepp i  kotpelaren",
  },
  { value: "SC4294", title: "Deformerande beninflammation i kotpelaren" },
  { value: "SC4295", title: "Kronisk diskospondylit" },
  { value: "SC4298", title: "Kronisk fysit  i kotpelaren" },
  { value: "SC5", title: "Immunmedierade tillstånd, kotpelaren" },
  { value: "SC6", title: "Neoplastiska förändringar, kotpelaren" },
  {
    value: "SC61",
    title: "Malign primär tumör i ben o/e brosk inkl led i kotpelaren",
  },
  {
    value: "SC611",
    title: "Malign tumör utgången från broskvävnad i kotpelaren",
  },
  { value: "SC6111", title: "Kondrosarkom i kotpelaren" },
  { value: "SC6112", title: "Kondromyxosarkom i kotpelaren" },
  {
    value: "SC612",
    title: "Malign tumör utgången från benvävnad i kotpelaren",
  },
  { value: "SC6121", title: "Osteosarkom i kotpelaren" },
  { value: "SC613", title: "Malign tumör av bindvävsursprung i kotpelaren" },
  { value: "SC6131", title: "Fibrosarkom i kotpelaren" },
  {
    value: "SC614",
    title: "Malign tumör av vaskulärt eller lymf urspr i kotpelaren",
  },
  {
    value: "SC6141",
    title: "Angiosarkom,hemangiendoteliom,hemangiopericytom i kotpelaren",
  },
  { value: "SC615", title: "Metastas till skelett , kotpelaren" },
  { value: "SC619", title: "Skelettumör av malign karaktär, UNS i kotpelaren" },
  {
    value: "SC62",
    title: "Benign tumör i ben o/e brosk inkl led i kotpelaren",
  },
  {
    value: "SC621",
    title: "Benign tumör utgången från broskvävnad i kotpelaren",
  },
  { value: "SC6211", title: "Kondroblastom i kotpelaren" },
  { value: "SC6212", title: "Ostekondrom i kotpelaren" },
  { value: "SC6213", title: "Enkondrom i kotpelaren" },
  {
    value: "SC622",
    title: "Benign tumör utgången från benvävnad i kotpelaren",
  },
  { value: "SC6221", title: "Osteom  i kotpelaren" },
  { value: "SC6222", title: "Osteoblastom i kotpelaren" },
  { value: "SC623", title: "Benign tumör av bindvävsursprung i kotpelaren" },
  { value: "SC6231", title: "Fibrom i kotpelaren" },
  { value: "SC6232", title: "Fibröst histiocytom i kotpelaren" },
  {
    value: "SC624",
    title: "Benign tumör av vaskulärt eller lymf urspr i kotpelaren",
  },
  { value: "SC6241", title: "Hemangiom  i kotpelaren" },
  { value: "SC6242", title: "Lymfangiom  i kotpelaren" },
  { value: "SC628", title: "Övriga benigna skelettumörer i kotpelaren" },
  { value: "SC6281", title: "Jättecellstumör i kotpelaren" },
  { value: "SC6282", title: "Adamantinom (tandanlagsnybildning) i kotpelaren" },
  { value: "SC629", title: "Skelettumör av benign karaktär, UNS i kotpelaren" },
  { value: "SC7", title: "Trauma, fr kr, lägeförändr, term skad, kotpelaren" },
  { value: "SC71", title: "Traumatiska / mekaniska skador, kotpelaren" },
  { value: "SC711", title: "Fissur i kotpelaren" },
  { value: "SC713", title: "Fraktur i kotpelaren" },
  { value: "SC7131", title: "Diafysfraktur  / fraktur på korpus, kotpelaren" },
  { value: "SC7132", title: "Lednära fraktur proximalt /kranialt, kotpelaren" },
  { value: "SC7133", title: "Lednära fraktur kaudalt, kotpelaren" },
  {
    value: "SC7134",
    title: "Fraktur som berör angränsande led/leder kranialt,kotpelaren",
  },
  {
    value: "SC7135",
    title: "Fraktur som berör angränsande led / leder kaudalt,kotpelaren",
  },
  { value: "SC7136", title: "Avsprängningsfraktur kranialt, kotpelaren" },
  { value: "SC7137", title: "Avsprängningsfraktur kaudalt , kotpelaren" },
  { value: "SC7139", title: "Kompressionsfraktur, kotpelaren" },
  { value: "SC714", title: "Fraktur av speciell typ, kotpelaren" },
  {
    value: "SC7141",
    title: "Kortikal subperiostal fraktur (greenstick), kotpelaren",
  },
  { value: "SC7142", title: "Fraktur av osteofyt, kotpelaren" },
  {
    value: "SC7149",
    title: "Patologisk fraktur, spontanfraktur i kotpelaren UNS",
  },
  { value: "SC715", title: "Komplikation till frakturläknin, kotpelareng" },
  { value: "SC7151", title: "Pseudoartros, kotpelaren" },
  {
    value: "SC7152",
    title: "Atrofi av benvävnad som kompl t frakturläkn, kotpelaren",
  },
  { value: "SC7153", title: "Felläkt fraktur, kotpelaren" },
  {
    value: "SC7154",
    title: "Ankylos av angränsande led pga skelettskada, kotpelaren",
  },
  { value: "SC718", title: "Diskruptur" },
  { value: "SC7181", title: "Diskruptur med luxation mellan kotor" },
  { value: "SC7182", title: "Diskruptur utan luxation mellan kotor" },
  {
    value: "SC719",
    title: "Övriga skelettskadorutan närmare specifikation, kotpelaren",
  },
  { value: "SC72", title: "Främmande kroppar, kotpelaren" },
  { value: "SC73", title: "Lägesförändringar, kotpelaren" },
  {
    value: "SC731",
    title: "Intilliggande överridande tornutskott (kissing spines)",
  },
  { value: "SC732", title: "Ileo-sakralledsluxation" },
  {
    value: "SC739",
    title: "Övrig lägesförändringutan närmare specifikation, kotpelaren",
  },
  { value: "SC74", title: "Termiska skador, kotpelaren" },
  { value: "SC741", title: "Brännskada, kotpelaren" },
  { value: "SC742", title: "Köldskada, kotpelaren" },
  { value: "SC8", title: "Toxiska förändringar, kotpelaren" },
  {
    value: "SC9",
    title: "Idiopatiska ospecificerade multifaktoriella, kotpelaren",
  },
  { value: "SD", title: "Revben, bröstben" },
  { value: "SD00", title: "Normalvariation, revben/bröstben" },
  {
    value: "SD01",
    title: "Symtom på sjukdom u fastställd orsak, revben/bröstben",
  },
  { value: "SD011", title: "Skelettömhet i revben /bröstben" },
  {
    value: "SD1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, revben/bröstben",
  },
  { value: "SD10", title: "Missbildning, revben/bröstben" },
  { value: "SD101", title: "Övertaliga skelettdelar i revben /bröstben" },
  {
    value: "SD102",
    title: "Aplasi eller hypoplasi av skelettdelar, revben/bröstben",
  },
  { value: "SD1022", title: "Avsaknad av revben" },
  { value: "SD103", title: "Felställda skelettdelar" },
  { value: "SD1031", title: "Felställning av revben" },
  { value: "SD1032", title: "Felställning av skovelbrosk" },
  { value: "SD104", title: "Kondrodystrofi (akondroplasi) i revben /bröstben" },
  { value: "SD109", title: "Övriga skelettmissbildningar i revben /bröstben" },
  {
    value: "SD1093",
    title: "Dysostos (defekt benbildning u fosterlivet), revben/bröstben",
  },
  { value: "SD1095", title: "Missbildad bröstkorg" },
  {
    value: "SD1099",
    title: "Övriga ej definierade skelettmissbildn i revben /bröstben",
  },
  { value: "SD11", title: "Utvecklingsrubbn, tillväxtrubbn, revben/bröstben" },
  { value: "SD111", title: "Samtidig ben och brosksjukdom, revben/bröstben" },
  { value: "SD1111", title: "Osteokondros i revben /bröstben" },
  { value: "SD11111", title: "Osteokondros utan ledmus, revben/bröstben" },
  { value: "SD11112", title: "Osteokondros med ledmus, revben/bröstben" },
  { value: "SD113", title: "Osteokondrodysplasi  i revben /bröstben" },
  {
    value: "SD119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i revben /bröstben",
  },
  { value: "SD1192", title: "Bencysta, aseptisk bennekros, revben/bröstben" },
  {
    value: "SD2",
    title: "Metabol, nutrit, degen/dystrof förändr, revben/bröstben",
  },
  { value: "SD20", title: "Metaboliska förändringar, revben/bröstben" },
  { value: "SD201", title: "Osteomalaci / osteolys i revben /bröstben" },
  { value: "SD202", title: "Osteoporos i revben /bröstben" },
  { value: "SD203", title: "Osteodystrofi  i revben /bröstben" },
  { value: "SD204", title: "Benmetaplasier i revben /bröstben" },
  { value: "SD21", title: "Nutritionella förändringar, revben/bröstben" },
  {
    value: "SD22",
    title: "Degenerativa/dystrofiska förändringar, revben/bröstben",
  },
  { value: "SD223", title: "Spondylos i revben /bröstben" },
  { value: "SD225", title: "Aseptisk bennekros i revben /bröstben" },
  { value: "SD23", title: "Hormonella förändringar, revben/bröstben" },
  { value: "SD3", title: "Cirkulatoriska förändringar, revben/bröstben" },
  { value: "SD30", title: "Blödningar, revben/bröstben" },
  { value: "SD301", title: "Subperiostal blödning i revben /bröstben" },
  { value: "SD31", title: "Ödem, revben/bröstben" },
  { value: "SD32", title: "Infarkter, revben/bröstben" },
  { value: "SD33", title: "Hygrom, revben/bröstben" },
  { value: "SD34", title: "Trombos / emboli, revben/bröstben" },
  {
    value: "SD35",
    title: "Arterio-atero-skleros förändringar, revben/bröstben",
  },
  { value: "SD4", title: "Infekt, infl förändringar, revben/bröstben" },
  { value: "SD40", title: "Specifika infektionssjukdomar, revben/bröstben" },
  { value: "SD41", title: "Akuta inflammationstillstånd, revben/bröstben" },
  {
    value: "SD411",
    title: "Akut serös / serofibrinös inflammation, revben/bröstben",
  },
  { value: "SD412", title: "Akut purulent inflammation, revben/bröstben" },
  { value: "SD413", title: "Specifika akuta infl tillstånd, revben/bröstben" },
  { value: "SD419", title: "Övriga akuta infl tillstånd, revben/bröstben" },
  { value: "SD4191", title: "Akut periostit i revben /bröstben" },
  { value: "SD41911", title: "Sekundär akut periostit i revben /bröstben" },
  { value: "SD4192", title: "Akut ostit i revben /bröstben" },
  { value: "SD4193", title: "Akut osteomyelit i revben /bröstben" },
  { value: "SD41931", title: "Sekundär akut osteomyelit i revben /bröstben" },
  {
    value: "SD41932",
    title: "Sekundär akut osteomyelit  i revben /bröstben e kir ingrepp",
  },
  { value: "SD4198", title: "Akut fysit  i revben /bröstben" },
  {
    value: "SD42",
    title: "Kroniska inflammationstillstånd i revben /bröstben",
  },
  { value: "SD421", title: "Kronisk serös inflammation, revben/bröstben" },
  { value: "SD422", title: "Purulent inflammation, revben/bröstben" },
  { value: "SD423", title: "Abscess/fistel, revben/bröstben" },
  {
    value: "SD424",
    title: "Specifika kroniska infl tillstånd, revben/bröstben",
  },
  { value: "SD429", title: "Övriga kroniska infl tillstånd, revben/bröstben" },
  { value: "SD4291", title: "Kronisk periostit i revben /bröstben" },
  { value: "SD42911", title: "Sekundär kronisk periostit i revben /bröstben" },
  { value: "SD4292", title: "Kronisk ostit i revben /bröstben" },
  { value: "SD4293", title: "Kronisk osteomyelit i revben /bröstben" },
  {
    value: "SD42931",
    title: "Sekundär kronisk osteomyelit i revben /bröstben",
  },
  {
    value: "SD42932",
    title: "Sekundär kron osteomyelit  i revben /bröstben e kir ingrepp",
  },
  { value: "SD4294", title: "Deformerande beninflammation i revben /bröstben" },
  { value: "SD4295", title: "Kronisk diskospondylit i revben /bröstben" },
  { value: "SD4298", title: "Kronisk fysit i revben /bröstben" },
  { value: "SD5", title: "Immunmedierade tillstånd, revben/bröstben" },
  { value: "SD6", title: "Neoplastiska förändringar, revben/bröstben" },
  {
    value: "SD61",
    title: "Malign primär tumör i ben o/e brosk inkl led,revben/bröstben",
  },
  {
    value: "SD611",
    title: "Malign tumör utgången från broskvävnad i revben /bröstben",
  },
  { value: "SD6111", title: "Kondrosarkom i revben /bröstben" },
  { value: "SD6112", title: "Kondromyxosarkom i revben /bröstben" },
  {
    value: "SD612",
    title: "Malign tumör utgången från benvävnad i revben /bröstben",
  },
  { value: "SD6121", title: "Osteosarkom i revben /bröstben" },
  {
    value: "SD613",
    title: "Malign tumör av bindvävsursprung i revben /bröstben",
  },
  { value: "SD6131", title: "Fibrosarkom i revben /bröstben" },
  {
    value: "SD614",
    title: "Malign tumör av vaskulärt eller lymf urspr i revben/bröstben",
  },
  {
    value: "SD6141",
    title: "Angiosarkom,hemangiendoteliom,hemangiopericytom,rev/bröstben",
  },
  { value: "SD615", title: "Metastas till skeletti revben /bröstben" },
  {
    value: "SD619",
    title: "Skelettumör av malign karaktär,  i revben /bröstben UNS",
  },
  { value: "SD62", title: "Benign tumör i ben o/e brosk inkl led" },
  {
    value: "SD621",
    title: "Benign tumör utgången från broskvävnad i revben /bröstben",
  },
  { value: "SD6211", title: "Kondroblastom i revben /bröstben" },
  { value: "SD6212", title: "Ostekondrom i revben /bröstben" },
  { value: "SD6213", title: "Enkondrom i revben /bröstben" },
  {
    value: "SD622",
    title: "Benign tumör utgången från benvävnad i revben /bröstben",
  },
  { value: "SD6221", title: "Osteom  i revben /bröstben" },
  { value: "SD6222", title: "Osteoblastom i revben /bröstben" },
  {
    value: "SD623",
    title: "Benign tumör av bindvävsursprung i revben /bröstben",
  },
  { value: "SD6231", title: "Fibrom i revben /bröstben" },
  { value: "SD6232", title: "Fibröst histiocytom i revben /bröstben" },
  { value: "SD624", title: "Benign tumör av vaskulärt eller lymf urspr" },
  { value: "SD6241", title: "Hemangiom  i revben /bröstben" },
  { value: "SD6242", title: "Lymfangiom i revben /bröstben" },
  { value: "SD628", title: "Övriga benigna skelettumörer i revben /bröstben" },
  { value: "SD6281", title: "Jättecellstumör i revben /bröstben" },
  {
    value: "SD629",
    title: "Skelettumör av benign karaktär i revben /bröstben, UNS",
  },
  {
    value: "SD7",
    title: "Trauma, fr kr, lägeförändr, term skad, revben/bröstben",
  },
  { value: "SD71", title: "Traumatiska / mekaniska skador, revben/bröstben" },
  { value: "SD711", title: "Fissur i revben /bröstben" },
  { value: "SD712", title: "Epifysfraktur i revben /bröstben" },
  { value: "SD7129", title: "Öriga epifysskador i revben /bröstben" },
  { value: "SD713", title: "Fraktur i revben /bröstben" },
  { value: "SD7131", title: "Diafysfraktur  på revben /bröstben" },
  {
    value: "SD7132",
    title: "Lednära fraktur av revben /bröstben proximalt /kranialt",
  },
  {
    value: "SD7133",
    title: "Lednära fraktur distalt /kaudalt av revben /bröstben",
  },
  {
    value: "SD7134",
    title: "Frakt av revben/bröstben som berör angräns led/leder prox",
  },
  {
    value: "SD7135",
    title: "Frakt av revben/bröstben som berör angräns led/leder distalt",
  },
  {
    value: "SD7136",
    title: "Avsprängningsfraktur  av revben /bröstben proximalt",
  },
  {
    value: "SD7137",
    title: "Avsprängningsfraktur av revben /bröstben distalt",
  },
  { value: "SD7139", title: "Kompressionsfraktur, revben/bröstben" },
  { value: "SD714", title: "Fraktur av speciell typ, revben/bröstben" },
  {
    value: "SD7141",
    title: "Kortikal subperiostal fraktur (greenstick), revben/bröstben",
  },
  { value: "SD7142", title: "Fraktur av osteofyt, revben/bröstben" },
  {
    value: "SD7149",
    title: "Patologisk fraktur av revben /bröstben , spontanfraktur UNS",
  },
  {
    value: "SD715",
    title: "Komplikation till frakturläkning, revben/bröstben",
  },
  { value: "SD7151", title: "Pseudoartros i revben /bröstben" },
  {
    value: "SD7152",
    title: "Atrofi av benvävnad i revben/bröstben s kompl t frakturläkn",
  },
  { value: "SD7153", title: "Felläkt fraktur i revben /bröstben" },
  {
    value: "SD7154",
    title: "Ankylos av angränsande led pga skelettskada, revben/bröstben",
  },
  { value: "SD719", title: "Övriga skelettskador i revben /bröstben" },
  { value: "SD72", title: "Främmande kroppar, revben/bröstben" },
  { value: "SD73", title: "Lägesförändringar, revben/bröstben" },
  { value: "SD733", title: "Luxation av bröstben" },
  { value: "SD734", title: "Luxation av revben" },
  { value: "SD739", title: "Övrig lägesförändring i revben /bröstben" },
  { value: "SD74", title: "Termiska skador, revben/bröstben" },
  { value: "SD741", title: "Brännskada, revben/bröstben" },
  { value: "SD742", title: "Köldskada, revben/bröstben" },
  { value: "SD8", title: "Toxiska förändringar, revben/bröstben" },
  {
    value: "SD9",
    title: "Idiopatiska ospecificerade multifaktoriella, revben/bröstben",
  },
  { value: "SE", title: "Scapula" },
  { value: "SE0", title: "Normalvar, symtom u fastställd orsak, scapula" },
  { value: "SE00", title: "Normalvariation, scapula" },
  { value: "SE01", title: "Symtom på sjukdom u fastställd orsak, scapula" },
  { value: "SE011", title: "Skelettömhet, scapula" },
  { value: "SE1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, scapula" },
  { value: "SE10", title: "Missbildning, scapula" },
  { value: "SE101", title: "Övertaliga skelettdelar, scapula" },
  { value: "SE102", title: "Aplasi eller hypoplasi av skelettdelar, scapula" },
  { value: "SE103", title: "Felställda skelettdelar , scapula" },
  { value: "SE104", title: "Kondrodystrofi (akondroplasi), scapula" },
  { value: "SE109", title: "Övriga skelettmissbildningar, scapula" },
  {
    value: "SE1099",
    title: "Övriga ej definierade skelettmissbildningar, scapula",
  },
  { value: "SE11", title: "Utvecklingsrubbn, tillväxtrubbn, scapula" },
  { value: "SE111", title: "Samtidig ben och brosksjukdom, scapula" },
  { value: "SE1111", title: "Osteokondros, scapula" },
  { value: "SE11111", title: "Osteokondros utan ledmus i scapula" },
  { value: "SE11112", title: "Osteokondros med ledmus i scapula" },
  {
    value: "SE11113",
    title: "Subkondral bencysta av osteokondrostyp i scapula",
  },
  { value: "SE11114", title: "Förtidigt / försent sluten epifys  i scapula" },
  { value: "SE113", title: "Osteokondrodysplasi , scapula" },
  { value: "SE119", title: "Övriga Utvecklingsrubbn, tillväxtrubbn i scapula" },
  { value: "SE1192", title: "Bencysta, aseptisk bennekros i scapula" },
  { value: "SE2", title: "Metabol, nutrit, degen/dystrof förändr, scapula" },
  { value: "SE20", title: "Metaboliska förändringar, scapula" },
  { value: "SE201", title: "Osteomalaci / osteolys i scapula" },
  { value: "SE202", title: "Osteoporos i scapula" },
  { value: "SE203", title: "Osteodystrofi  i scapula" },
  { value: "SE204", title: "Benmetaplasier i scapula" },
  { value: "SE21", title: "Nutritionella förändringar, scapula" },
  { value: "SE22", title: "Degenerativa/dystrofiska förändringar, scapula" },
  { value: "SE224", title: "Pulmonär hypertrofisk osteopati i scapula" },
  { value: "SE225", title: "Aseptisk bennekros i scapula" },
  { value: "SE23", title: "Hormonella förändringar, scapula" },
  { value: "SE3", title: "Cirkulatoriska förändringar, scapula" },
  { value: "SE30", title: "Blödningar, scapula" },
  { value: "SE301", title: "Subperiostal blödning i scapula" },
  { value: "SE31", title: "Ödem, scapula" },
  { value: "SE32", title: "Infarkter, scapula" },
  { value: "SE33", title: "Hygrom, scapula" },
  { value: "SE34", title: "Trombos / emboli, scapula" },
  { value: "SE35", title: "Arterio-atero-skleros förändringar, scapula" },
  { value: "SE4", title: "Infekt, infl förändringar, scapula" },
  { value: "SE40", title: "Specifika infektionssjukdomar, scapula" },
  { value: "SE41", title: "Akuta inflammationstillstånd, scapula" },
  { value: "SE411", title: "Akut serös / serofibrinös inflammation, scapula" },
  { value: "SE412", title: "Akut purulent inflammation, scapula" },
  { value: "SE413", title: "Specifika akuta infl tillstånd, scapula" },
  { value: "SE419", title: "Övriga akuta infl tillstånd, scapula" },
  { value: "SE4191", title: "Akut periostit i scapula" },
  { value: "SE41911", title: "Sekundär akut periostit i scapula" },
  { value: "SE4192", title: "Akut ostit i scapula" },
  { value: "SE4193", title: "Akut osteomyelit i scapula" },
  { value: "SE41931", title: "Sekundär akut osteomyelit i scapula" },
  {
    value: "SE41932",
    title: "Sekundär akut osteomyelit i scapula e kir ingrepp",
  },
  { value: "SE4198", title: "Akut fysit  i scapula" },
  { value: "SE42", title: "Kroniska inflammationstillstånd, scapula" },
  { value: "SE421", title: "Kronisk serös inflammation, scapula" },
  { value: "SE422", title: "Purulent inflammation, scapula" },
  { value: "SE423", title: "Abscess/fistel, scapula" },
  { value: "SE424", title: "Specifika kroniska infl tillstånd, scapula" },
  { value: "SE429", title: "Övriga kroniska infl tillstånd, scapula" },
  { value: "SE4291", title: "Kronisk periostit i scapula" },
  { value: "SE42911", title: "Sekundär kronisk periostit i scapula" },
  { value: "SE4292", title: "Kronisk ostit i scapula" },
  { value: "SE4293", title: "Kronisk osteomyelit i scapula" },
  { value: "SE42931", title: "Sekundär kronisk osteomyelit i scapula" },
  {
    value: "SE42932",
    title: "Sekundär kronisk osteomyelit i scapula e kir ingrepp",
  },
  { value: "SE4294", title: "Deformerande beninflammation i scapula" },
  { value: "SE4298", title: "Kronisk fysit  i scapula" },
  { value: "SE43", title: "Parasitära sjukdomar, scapula" },
  { value: "SE5", title: "Immunmedierade tillstånd, scapula" },
  { value: "SE6", title: "Neoplastiska förändringar, scapula" },
  { value: "SE61", title: "Malign primär tumör i scapula o/e brosk inkl led" },
  { value: "SE611", title: "Malign tumör utgången från broskvävnad i scapula" },
  { value: "SE6111", title: "Kondrosarkom i scapula" },
  { value: "SE6112", title: "Kondromyxosarkom i scapula" },
  { value: "SE612", title: "Malign tumör utgången från benvävnad, scapula" },
  { value: "SE6121", title: "Osteosarkom i scapula" },
  { value: "SE613", title: "Malign tumör av bindvävsursprung, scapula" },
  { value: "SE6131", title: "Fibrosarkom i scapula" },
  {
    value: "SE614",
    title: "Malign tumör av vaskulärt eller lymf urspr, scapula",
  },
  {
    value: "SE6141",
    title: "Angiosarkom, hemangiendoteliom, hemangiopericytom i scapula",
  },
  { value: "SE615", title: "Metastas till skelett , scapula" },
  { value: "SE619", title: "Skelettumör av malign karaktär, UNS i scapula" },
  { value: "SE62", title: "Benign tumör i scapulao/e brosk inkl led" },
  { value: "SE621", title: "Benign tumör utgången från broskvävnad, scapula" },
  { value: "SE6211", title: "Kondroblastom i scapula" },
  { value: "SE6212", title: "Ostekondrom i scapula" },
  { value: "SE6213", title: "Enkondrom i scapula" },
  { value: "SE622", title: "Benign tumör utgången från benvävnad, scapula" },
  { value: "SE6221", title: "Osteom  i scapula" },
  { value: "SE6222", title: "Osteoblastom, scapula" },
  { value: "SE623", title: "Benign tumör av bindvävsursprung, scapula" },
  { value: "SE6231", title: "Fibrom i scapula" },
  { value: "SE6232", title: "Fibröst histiocytom i scapula" },
  {
    value: "SE624",
    title: "Benign tumör av vaskulärt eller lymf urspr, scapula",
  },
  { value: "SE6241", title: "Hemangiom i scapula" },
  { value: "SE6242", title: "Lymfangiom  i scapula" },
  { value: "SE628", title: "Övriga benigna skelettumörer, scapula" },
  { value: "SE6281", title: "Jättecellstumör i scapula" },
  { value: "SE629", title: "Skelettumör av benign karaktär, UNS i scapula" },
  { value: "SE7", title: "Trauma, fr kr, lägeförändr, term skad, scapula" },
  { value: "SE71", title: "Traumatiska / mekaniska skador i scapula" },
  { value: "SE711", title: "Fissur i scapula" },
  { value: "SE712", title: "Epifysfraktur i scapula" },
  {
    value: "SE7121",
    title: "Epifysfraktur (epifysglidning) proximalt i scapula",
  },
  {
    value: "SE7122",
    title: "Epifysfraktur (epifysglidning) distalt i scapula",
  },
  { value: "SE7123", title: "Epifysiolys i scapula" },
  { value: "SE71231", title: "Proximal epifysiolys, scapula" },
  { value: "SE71232", title: "Distal epifysiolys i scapula" },
  { value: "SE7129", title: "Öriga epifysskador i scapula" },
  { value: "SE713", title: "Fraktur i scapula" },
  { value: "SE7131", title: "Diafysfraktur av scapula" },
  { value: "SE7133", title: "Lednära fraktur distalt  i scapula" },
  {
    value: "SE7135",
    title: "Fraktur som berör angränsande led / leder distalt i scapula",
  },
  { value: "SE7136", title: "Avsprängningsfraktur proximalt  i scapula" },
  { value: "SE7137", title: "Avsprängningsfraktur distalt  i scapula" },
  { value: "SE7139", title: "Kompressionsfraktur i scapula" },
  { value: "SE714", title: "Fraktur av speciell typ i scapula" },
  {
    value: "SE7141",
    title: "Kortikal subperiostal fraktur (greenstick), scapula",
  },
  { value: "SE7142", title: "Fraktur av osteofyt, scapula" },
  {
    value: "SE7149",
    title: "Patologisk fraktur, spontanfraktur  i scapula UNS",
  },
  { value: "SE715", title: "Komplikation till frakturläkning i scapula" },
  { value: "SE7151", title: "Pseudoartros i scapula" },
  {
    value: "SE7152",
    title: "Atrofi av benvävnad som kompl t frakturläkn i scapula",
  },
  { value: "SE7153", title: "Felläkt fraktur i scapula" },
  {
    value: "SE7154",
    title: "Ankylos av angränsande led pga skelettskada i scapula",
  },
  { value: "SE719", title: "Övriga skelettskador i scapula" },
  { value: "SE72", title: "Främmande kroppar i scapula" },
  { value: "SE73", title: "Lägesförändringar i scapula" },
  { value: "SE739", title: "Övrig lägesförändring i scapula" },
  { value: "SE74", title: "Termiska skador, scapula" },
  { value: "SE741", title: "Brännskada, scapula" },
  { value: "SE742", title: "Köldskada, scapula" },
  { value: "SE8", title: "Toxiska förändringar, scapula" },
  {
    value: "SE9",
    title: "Idiopatiska ospecificerade multifaktoriella, scapula",
  },
  { value: "SF", title: "Humerus" },
  { value: "SF0", title: "Normalvar, symtom u fastställd orsak, humerus" },
  { value: "SF00", title: "Normalvariation, humerus" },
  { value: "SF01", title: "Symtom på sjukdom u fastställd orsak, humerus" },
  { value: "SF011", title: "Skelettömhet i humerus" },
  { value: "SF1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, humerus" },
  { value: "SF10", title: "Missbildning, humerus" },
  { value: "SF101", title: "Övertaliga skelettdelar i humerus" },
  { value: "SF102", title: "Aplasi eller hypoplasi av skelettdelar i humerus" },
  { value: "SF103", title: "Felställda skelettdelar , humerus" },
  { value: "SF104", title: "Kondrodystrofi i humerus (akondroplasi)" },
  { value: "SF109", title: "Övriga skelettmissbildningar i humerus" },
  {
    value: "SF1093",
    title: "Dysostos i humerus (defekt benbildning under fosterlivet)",
  },
  {
    value: "SF1099",
    title: "Övriga ej definierade skelettmissbildningar i humerus",
  },
  { value: "SF11", title: "Utvecklingsrubbn, tillväxtrubbn, humerus" },
  { value: "SF111", title: "Samtidig ben och brosksjukdom i humerus" },
  { value: "SF1111", title: "Osteokondros i humerus" },
  { value: "SF11111", title: "Osteokondros i humerusled utan ledmus" },
  { value: "SF11112", title: "Osteokondros i humerusled med ledmus" },
  {
    value: "SF11113",
    title: "Subkondral bencysta av osteokondrostyp, humerus",
  },
  { value: "SF1112", title: "Hörnstödsfraktur i humerus" },
  {
    value: "SF1113",
    title: "Benbroskfragment plantar/palmar i humerus (Birkeland)",
  },
  { value: "SF1114", title: "Förtidigt / försent sluten epifys , humerus" },
  { value: "SF1117", title: "Slabfraktur i humerus" },
  { value: "SF113", title: "Osteokondrodysplasi i humerus" },
  { value: "SF115", title: "Felaktig benställning, humerus" },
  { value: "SF1151", title: "Felaktig benställning framben, humerus" },
  { value: "SF1153", title: "Olika benlängd, humerus" },
  {
    value: "SF119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn  i humerus",
  },
  { value: "SF1191", title: "Panostit (endostsos) i humerus" },
  { value: "SF1192", title: "Bencysta, aseptisk bennekros i humerus" },
  {
    value: "SF1194",
    title: "Metafysär osteopati  i humerus (hypertrofisk osteodystrofi)",
  },
  { value: "SF2", title: "Metabol, nutrit, degen/dystrof förändr, humerus" },
  { value: "SF20", title: "Metaboliska förändringar, humerus" },
  { value: "SF201", title: "Osteomalaci / osteolys i humerus" },
  { value: "SF202", title: "Osteoporos i humerus" },
  { value: "SF203", title: "Osteodystrofi   i humerus" },
  { value: "SF204", title: "Benmetaplasier i humerus" },
  { value: "SF21", title: "Nutritionella förändringar, humerus" },
  { value: "SF22", title: "Degenerativa/dystrofiska förändringar, humerus" },
  { value: "SF224", title: "Pulmonär hypertrofisk osteopati i humerus" },
  { value: "SF225", title: "Aseptisk bennekros i humerus" },
  { value: "SF23", title: "Hormonella förändringar, humerus" },
  { value: "SF3", title: "Cirkulatoriska förändringar, humerus" },
  { value: "SF30", title: "Blödningar, humerus" },
  { value: "SF301", title: "Subperiostal blödning i humerus" },
  { value: "SF31", title: "Ödem, humerus" },
  { value: "SF32", title: "Infarkter, humerus" },
  { value: "SF33", title: "Hygrom, humerus" },
  { value: "SF34", title: "Trombos / emboli, humerus" },
  { value: "SF35", title: "Arterio-atero-skleros förändringar, humerus" },
  { value: "SF4", title: "Infekt, infl förändringar, humerus" },
  { value: "SF40", title: "Specifika infektionssjukdomar, humerus" },
  { value: "SF41", title: "Akuta inflammationstillstånd, humerus" },
  { value: "SF411", title: "Akut serös / serofibrinös inflammation, humerus" },
  { value: "SF412", title: "Akut purulent inflammation, humerus" },
  { value: "SF413", title: "Specifika akuta infl tillstånd, humerus" },
  { value: "SF419", title: "Övriga akuta infl tillstånd, humerus" },
  { value: "SF4191", title: "Akut periostit i humerus" },
  { value: "SF41911", title: "Sekundär akut periostit i humerus" },
  { value: "SF4192", title: "Akut ostit i humerus" },
  { value: "SF4193", title: "Akut osteomyelit i humerus" },
  { value: "SF41931", title: "Sekundär akut osteomyelit i humerus" },
  {
    value: "SF41932",
    title: "Sekundär akut osteomyelit i humerus e kir ingrepp",
  },
  { value: "SF4198", title: "Akut fysit  i humerus" },
  { value: "SF42", title: "Kroniska inflammationstillstånd, humerus" },
  { value: "SF421", title: "Kronisk serös inflammation, humerus" },
  { value: "SF422", title: "Purulent inflammation, humerus" },
  { value: "SF423", title: "Abscess/fistel, humerus" },
  { value: "SF424", title: "Specifika kroniska infl tillstånd, humerus" },
  { value: "SF429", title: "Övriga kroniska infl tillstånd, humerus" },
  { value: "SF4291", title: "Kronisk periostit i humerus (överben), humerus" },
  { value: "SF42911", title: "Sekundär kronisk periostit i humerus" },
  { value: "SF4292", title: "Kronisk ostit i humerus" },
  { value: "SF4293", title: "Kronisk osteomyelit i humerus" },
  { value: "SF42931", title: "Sekundär kronisk osteomyelit i humerus" },
  {
    value: "SF42932",
    title: "Sekundär kronisk osteomyelit i humerus e kir ingrepp",
  },
  { value: "SF4294", title: "Deformerande beninflammation i humerus" },
  { value: "SF4298", title: "Kronisk fysit i humerus" },
  { value: "SF43", title: "Parasitära sjukdomar, humerus" },
  { value: "SF5", title: "Immunmedierade tillstånd, humerus" },
  { value: "SF6", title: "Neoplastiska förändringar, humerus" },
  { value: "SF61", title: "Malign primär tumör i humeruso/e brosk inkl led" },
  { value: "SF611", title: "Malign tumör utgången från broskvävnad i humerus" },
  { value: "SF6111", title: "Kondrosarkom i humerus" },
  { value: "SF6112", title: "Kondromyxosarkom i humerus" },
  { value: "SF612", title: "Malign tumör utgången från benvävnad i humerus" },
  { value: "SF6121", title: "Osteosarkom i humerus" },
  { value: "SF613", title: "Malign tumör av bindvävsursprung i humerus" },
  { value: "SF6131", title: "Fibrosarkom i humerus" },
  {
    value: "SF614",
    title: "Malign tumör av vaskulärt eller lymf urspr i humerus",
  },
  {
    value: "SF6141",
    title: "Angiosarkom, hemangiendoteliom, hemangiopericytom i humerus",
  },
  { value: "SF615", title: "Metastas till skelett, humerus" },
  { value: "SF619", title: "Skelettumör av malign karaktär, UNS i humerus" },
  { value: "SF62", title: "Benign tumör i ben o/e brosk inkl led i humerus" },
  { value: "SF621", title: "Benign tumör i humerus utgången från broskvävnad" },
  { value: "SF6211", title: "Kondroblastom i humerus" },
  { value: "SF6212", title: "Ostekondrom i humerus" },
  { value: "SF6213", title: "Enkondrom i humerus" },
  { value: "SF622", title: "Benign tumör i humerus utgången från benvävnad" },
  { value: "SF6221", title: "Osteom i humerus" },
  { value: "SF6222", title: "Osteoblastom i humerus" },
  { value: "SF623", title: "Benign tumör i humerus av bindvävsursprung" },
  { value: "SF6231", title: "Fibrom i humerus" },
  { value: "SF6232", title: "Fibröst histiocytom i humerus" },
  {
    value: "SF624",
    title: "Benign tumör i humerus av vaskulärt eller lymf urspr",
  },
  { value: "SF6241", title: "Hemangiom i humerus" },
  { value: "SF6242", title: "Lymfangiom i humerus" },
  { value: "SF628", title: "Övriga benigna skelettumörer i humerus" },
  { value: "SF6281", title: "Jättecellstumör i humerus" },
  { value: "SF629", title: "Skelettumör i humerus av benign karaktär, UNS" },
  { value: "SF7", title: "Trauma, fr kr, lägeförändr, term skad, humerus" },
  { value: "SF71", title: "Traumatiska / mekaniska skador, humerus" },
  { value: "SF711", title: "Fissur av humerus" },
  { value: "SF712", title: "Epifysfraktur i humerus" },
  {
    value: "SF7121",
    title: "Epifysfraktur  i humerus (epifysglidning) proximalt",
  },
  {
    value: "SF7122",
    title: "Epifysfraktur  i humerus(epifysglidning) distalt",
  },
  { value: "SF7123", title: "Epifysiolys av humerus" },
  { value: "SF71231", title: "Proximal epifysiolys av humerus" },
  { value: "SF71232", title: "Distal epifysiolys av humerus" },
  { value: "SF7129", title: "Öriga epifysskador av humerus" },
  { value: "SF713", title: "Fraktur av humerus" },
  { value: "SF7131", title: "Diafysfraktur   av humerus" },
  { value: "SF7132", title: "Lednära fraktur  av humerus proximalt" },
  { value: "SF7133", title: "Lednära fraktur  av humerus distalt" },
  {
    value: "SF7134",
    title: "Fraktur  av humerus som berör angränsande led proximalt",
  },
  {
    value: "SF7135",
    title: "Fraktur  av humerus som berör angränsande led distalt",
  },
  { value: "SF7136", title: "Avsprängningsfraktur  av humerus proximalt" },
  { value: "SF7137", title: "Avsprängningsfraktur av humerus distalt" },
  { value: "SF7139", title: "Kompressionsfraktur av humerus" },
  { value: "SF714", title: "Fraktur av speciell typ av humerus" },
  {
    value: "SF7141",
    title: "Kortikal subperiostal fraktur av humerus (greenstick)",
  },
  { value: "SF7142", title: "Fraktur av osteofyt av humerus" },
  {
    value: "SF7149",
    title: "Patologisk fraktur av humerus, spontanfraktur UNS",
  },
  { value: "SF715", title: "Komplikation till frakturläkning av humerus" },
  { value: "SF7151", title: "Pseudoartros i humerus" },
  {
    value: "SF7152",
    title: "Atrofi av benvävnad i humerus som kompl t frakturläkn",
  },
  { value: "SF7153", title: "Felläkt fraktur av humerus" },
  {
    value: "SF7154",
    title: "Ankylos av angränsande led pga skelettskada i humerus",
  },
  { value: "SF719", title: "Övriga skelettskador av humerus" },
  { value: "SF72", title: "Främmande kroppar, humerus" },
  { value: "SF73", title: "Lägesförändringar, humerus" },
  { value: "SF739", title: "Övrig lägesförändring av humerus" },
  { value: "SF74", title: "Termiska skador, humerus" },
  { value: "SF741", title: "Brännskada, humerus" },
  { value: "SF742", title: "Köldskada, humerus" },
  { value: "SF8", title: "Toxiska förändringar, humerus" },
  {
    value: "SF9",
    title: "Idiopatiska ospecificerade multifaktoriella, humerus",
  },
  { value: "SG", title: "Radius/ulna" },
  { value: "SG0", title: "Normalvar, symtom u fastställd orsak radius/ulna" },
  { value: "SG00", title: "Normalvariation, radius/ulna" },
  { value: "SG01", title: "Symtom på sjukdom u fastställd orsak radius/ulna" },
  { value: "SG011", title: "Skelettömhet i radius/ulna" },
  {
    value: "SG1",
    title: "Missbildn, utvecklingsrubbning, tillväxtrubbning radius/ulna",
  },
  { value: "SG10", title: "Missbildning radius/ulna" },
  { value: "SG101", title: "Övertaliga skelettdelar i radius/ulna" },
  {
    value: "SG102",
    title: "Aplasi eller hypoplasi av skelettdelar i radius/ulna",
  },
  { value: "SG104", title: "Kondrodystrofi  i radius/ulna (akondroplasi)" },
  { value: "SG109", title: "Övriga skelettmissbildningar i radius/ulna" },
  {
    value: "SG1093",
    title: "Dysostos i radius/ulna (defekt benbildn under fosterlivet)",
  },
  {
    value: "SG1099",
    title: "Övriga ej definierade skelettmissbildningar i radius/ulna",
  },
  { value: "SG11", title: "Utvecklingsrubbn, tillväxtrubbn radius/ulna" },
  { value: "SG111", title: "Samtidig ben och brosksjukdom radius/ulna" },
  { value: "SG1111", title: "Osteokondros i radius/ulna" },
  { value: "SG11111", title: "Osteokondros i radius/ulna utan ledmus" },
  { value: "SG11112", title: "Osteokondros i radius/ulna med ledmus" },
  {
    value: "SG11113",
    title: "Subkondral bencysta i radius/ulna av osteokondrostyp",
  },
  {
    value: "SG11114",
    title: "Förtidigt / försent sluten epifys i radius/ulna",
  },
  {
    value: "SG11115",
    title: "Ununited processus anconeus  (armbågsledsdysplasi)",
  },
  { value: "SG11116", title: "Avlösning av processus coronoideus" },
  { value: "SG11117", title: "Slabfraktur i radius/ulna" },
  { value: "SG1112", title: "Hörnstödsfraktur i radius/ulna" },
  {
    value: "SG1113",
    title: "Benbroskfragm plant/palm (Birkeland) radius/ulna",
  },
  { value: "SG113", title: "Osteokondrodysplasi i radius/ulna" },
  { value: "SG115", title: "Felaktig benställning radius/ulna" },
  { value: "SG1151", title: "Felaktig benställning framben radius/ulna" },
  { value: "SG11511", title: "Felaktig benställning pga retained cartilage" },
  {
    value: "SG11512",
    title: "Felaktig benställn pga för tid sluten epifys i radius/ulna",
  },
  { value: "SG1153", title: "Olika benlängd radius/ulna" },
  {
    value: "SG119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn radius/ulna",
  },
  { value: "SG1191", title: "Panostit (endostsos) i radius/ulna" },
  { value: "SG1192", title: "Bencysta, aseptisk bennekros i radius/ulna" },
  {
    value: "SG1194",
    title: "Metafysär osteopati i radius/ulna (hypertrof osteodyst)",
  },
  { value: "SG19", title: "Övriga skelettskador i radius/ulna" },
  {
    value: "SG2",
    title: "Metabol, nutrit, degen/dystr förändringar radius/ulna",
  },
  { value: "SG20", title: "Metaboliska förändringar radius/ulna" },
  { value: "SG201", title: "Osteomalaci / osteolys i radius/ulna" },
  { value: "SG202", title: "Osteoporos i radius/ulna" },
  { value: "SG203", title: "Osteodystrofi  i radius/ulna" },
  { value: "SG204", title: "Benmetaplasier i radius/ulna" },
  { value: "SG21", title: "Nutritionella förändringar radius/ulna" },
  {
    value: "SG22",
    title: "Degenerativa/dystrofiska förändringar i radius/ulna",
  },
  { value: "SG224", title: "Pulmonär hypertrofisk osteopati i radius/ulna" },
  { value: "SG225", title: "Aseptisk bennekros i radius/ulna" },
  { value: "SG23", title: "Hormonella förändringar radius/ulna" },
  { value: "SG3", title: "Cirkulatoriska förändringar radius/ulna" },
  { value: "SG30", title: "Blödningar radius/ulna" },
  { value: "SG301", title: "Subperiostal blödning i radius/ulna" },
  { value: "SG31", title: "Ödem radius/ulna" },
  { value: "SG32", title: "Infarkter radius/ulna" },
  { value: "SG33", title: "Hygrom radius/ulna" },
  { value: "SG34", title: "Trombos / emboli radius/ulna" },
  { value: "SG35", title: "Arterio-atero-skleros förändringar radius/ulna" },
  { value: "SG4", title: "Infekt, infl förändringar radius/ulna" },
  { value: "SG40", title: "Specifika infektionssjukdomar radius/ulna" },
  { value: "SG41", title: "Akuta inflammationstillstånd radius/ulna" },
  {
    value: "SG411",
    title: "Akut serös / serofibrinös inflammation radius/ulna",
  },
  { value: "SG412", title: "Akut purulent inflammation radius/ulna" },
  { value: "SG413", title: "Specifika akuta infl tillstånd radius/ulna" },
  { value: "SG419", title: "Övriga akuta infl tillstånd radius/ulna" },
  { value: "SG4191", title: "Akut periostit i radius/ulna" },
  { value: "SG41911", title: "Sekundär akut periostit i radius/ulna" },
  { value: "SG4192", title: "Akut ostit i radius/ulna" },
  { value: "SG4193", title: "Akut osteomyelit i radius/ulna" },
  { value: "SG41931", title: "Sekundär akut osteomyelit i radius/ulna" },
  {
    value: "SG41932",
    title: "Sekundär akut osteomyelit i radius/ulna e kir ingrepp",
  },
  { value: "SG4198", title: "Akut fysit i radius/ulna" },
  { value: "SG42", title: "Kroniska inflammationstillstånd radius/ulna" },
  { value: "SG421", title: "Kronisk serös inflammation radius/ulna" },
  { value: "SG422", title: "Purulent inflammation radius/ulna" },
  { value: "SG423", title: "Abscess/fistel radius/ulna" },
  { value: "SG424", title: "Specifika kroniska infl tillstånd radius/ulna" },
  { value: "SG429", title: "Övriga kroniska infl tillstånd radius/ulna" },
  { value: "SG4291", title: "Kronisk periostit i radius/ulna (överben)" },
  { value: "SG42911", title: "Sekundär kronisk periostit i radius/ulna" },
  { value: "SG4292", title: "Kronisk ostit i radius/ulna" },
  { value: "SG4293", title: "Kronisk osteomyelit i radius/ulna" },
  { value: "SG42931", title: "Sekundär kronisk osteomyelit i radius/ulna" },
  {
    value: "SG42932",
    title: "Sekundär kronisk osteomyelit i radius/ulna e kir ingrepp",
  },
  { value: "SG4294", title: "Deformerande beninflammation i radius/ulna" },
  { value: "SG4298", title: "Kronisk fysit  i radius/ulna" },
  { value: "SG43", title: "Parasitära sjukdomar radius/ulna" },
  { value: "SG5", title: "Immunmedierade tillstånd radius/ulna" },
  { value: "SG6", title: "Neoplastiska förändringar radius/ulna" },
  {
    value: "SG61",
    title: "Malign primär tumör i  radius/ulna o/e brosk inkl led",
  },
  {
    value: "SG611",
    title: "Malign tumör i radius/ulna utgången från broskvävnad",
  },
  { value: "SG6111", title: "Kondrosarkom i radius/ulna" },
  { value: "SG6112", title: "Kondromyxosarkom i radius/ulna" },
  {
    value: "SG612",
    title: "Malign tumör i radius/ulna utgången från benvävnad",
  },
  { value: "SG6121", title: "Osteosarkom i radius/ulna" },
  { value: "SG613", title: "Malign tumör i radius/ulna av bindvävsursprung" },
  { value: "SG6131", title: "Fibrosarkom i radius/ulna" },
  {
    value: "SG614",
    title: "Malign tumör i radius/ulna av vaskulärt eller lymf urspr",
  },
  {
    value: "SG6141",
    title: "Angiosarkom, hemangiendoteliom,hemangiopericytom radius/ulna",
  },
  { value: "SG615", title: "Metastas till skelett radius/ulna" },
  {
    value: "SG619",
    title: "Skelettumör i radius/ulna av malign karaktär, UNS",
  },
  {
    value: "SG62",
    title: "Benign tumör i radius/ulna i ben o/e brosk inkl led",
  },
  {
    value: "SG621",
    title: "Benign tumör i radius/ulna utgången från broskvävnad",
  },
  { value: "SG6211", title: "Kondroblastom i radius/ulna" },
  { value: "SG6212", title: "Ostekondrom i radius/ulna" },
  { value: "SG6213", title: "Enkondrom i radius/ulna" },
  {
    value: "SG622",
    title: "Benign tumör i radius/ulna utgången från benvävnad",
  },
  { value: "SG6221", title: "Osteom i radius/ulna" },
  { value: "SG6222", title: "Osteoblastom i radius/ulna" },
  { value: "SG623", title: "Benign tumör i radius/ulna av bindvävsursprung" },
  { value: "SG6231", title: "Fibrom i radius/ulna" },
  { value: "SG6232", title: "Fibröst histiocytom i radius/ulna" },
  {
    value: "SG624",
    title: "Benign tumör i radius/ulna av vaskulärt eller lymf urspr",
  },
  { value: "SG6241", title: "Hemangiom i radius/ulna" },
  { value: "SG6242", title: "Lymfangiom i radius/ulna" },
  { value: "SG628", title: "Övriga benigna skelettumörer i radius/ulna" },
  { value: "SG6281", title: "Jättecellstumör i radius/ulna" },
  {
    value: "SG629",
    title: "Skelettumör i radius/ulna av benign karaktär, UNS",
  },
  {
    value: "SG7",
    title: "Trauma, fr kr, lägesförändr, termiska skador radius/ulna",
  },
  { value: "SG71", title: "Traumatiska / mekaniska skador radius/ulna" },
  { value: "SG711", title: "Fissur i radius/ulna" },
  { value: "SG712", title: "Epifysfraktur i radius/ulna" },
  {
    value: "SG7121",
    title: "Epifysfraktur i radius/ulna (epifysglidning) proximalt",
  },
  {
    value: "SG7122",
    title: "Epifysfraktur i radius/ulna (epifysglidning) distalt",
  },
  { value: "SG7123", title: "Epifysiolys i radius/ulna" },
  { value: "SG71231", title: "Proximal epifysiolys i radius/ulna" },
  { value: "SG71232", title: "Distal epifysiolys i radius/ulna" },
  { value: "SG7129", title: "Öriga epifysskador i radius/ulna" },
  { value: "SG713", title: "Fraktur i radius/ulna" },
  { value: "SG7131", title: "Diafysfraktur  av radius/ulna" },
  { value: "SG7132", title: "Lednära fraktur proximalt  av radius/ulna" },
  { value: "SG7133", title: "Lednära fraktur distalt  av radius/ulna" },
  {
    value: "SG7134",
    title: "Fraktur  av radius/ulna som berör angränsande led proximalt",
  },
  {
    value: "SG7135",
    title: "Fraktur  av radius/ulna som berör angränsande led distalt",
  },
  { value: "SG7136", title: "Avsprängningsfraktur av radius/ulna proximalt" },
  { value: "SG7137", title: "Avsprängningsfraktur av radius/ulna distalt" },
  { value: "SG7139", title: "Kompressionsfraktur av radius/ulna" },
  { value: "SG714", title: "Fraktur av speciell typ av radius/ulna" },
  {
    value: "SG7141",
    title: "Kortikal subperiostal fraktur  av radius/ulna(greenstick)",
  },
  { value: "SG7142", title: "Fraktur av osteofyt på radius/ulna" },
  {
    value: "SG7149",
    title: "Patologisk fraktur av radius/ulna, spontanfraktur UNS",
  },
  { value: "SG715", title: "Komplikation till frakturläkning av radius/ulna" },
  { value: "SG7151", title: "Pseudoartros av radius/ulna" },
  {
    value: "SG7152",
    title: "Atrofi av benvävnad  i radius/ulna som kompl t frakturläkn",
  },
  { value: "SG7153", title: "Felläkt fraktur av radius/ulna" },
  {
    value: "SG7154",
    title: "Ankylos av angränsande led pga skelettskada i radius/ulna",
  },
  { value: "SG72", title: "Främmande kroppar radius/ulna" },
  { value: "SG73", title: "Lägesförändringar av radius/ulna" },
  { value: "SG739", title: "Övrig lägesförändring radius/ulna" },
  { value: "SG74", title: "Termiska skador radius/ulna" },
  { value: "SG741", title: "Brännskada radius/ulna" },
  { value: "SG742", title: "Köldskada radius/ulna" },
  { value: "SG8", title: "Toxiska förändringar radius/ulna" },
  {
    value: "SG9",
    title: "Idiopatiska ospecificerade multifaktoriella radius/ulna",
  },
  { value: "SH", title: "Karpalben" },
  { value: "SH0", title: "Normalvar, symtom u fastställd orsak, karpalben" },
  { value: "SH00", title: "Normalvariation, karpalben" },
  { value: "SH01", title: "Symtom på sjukdom u fastställd orsak, karpalben" },
  { value: "SH011", title: "Skelettömhet i karpalben" },
  {
    value: "SH1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, karpalben",
  },
  { value: "SH10", title: "Missbildning, karpalben" },
  { value: "SH101", title: "Övertaliga skelettdelar i karpalben" },
  {
    value: "SH102",
    title: "Aplasi eller hypoplasi av skelettdelar i karpalben",
  },
  { value: "SH103", title: "Felställda skelettdelar  i karpalben" },
  { value: "SH104", title: "Kondrodystrofi (akondroplasi) i karpalben" },
  { value: "SH109", title: "Övriga skelettmissbildningar i karpalben" },
  {
    value: "SH1093",
    title: "Dysostos (defekt benbildning under fosterlivet), karpalben",
  },
  {
    value: "SH1099",
    title: "Övriga ej definierade skelettmissbildningar i karpalben",
  },
  {
    value: "SH11",
    title: "Utvecklingsrubbningar, tillväxtrubbninga, karpalbenr",
  },
  { value: "SH111", title: "Samtidig ben och brosksjukdom, karpalben" },
  { value: "SH1111", title: "Osteokondros i karpalben" },
  { value: "SH11111", title: "Osteokondros i karpalben utan ledmus" },
  { value: "SH11112", title: "Osteokondros i karpalben med ledmus" },
  {
    value: "SH11113",
    title: "Subkondral bencysta av osteokondrostyp i karpalben",
  },
  { value: "SH11114", title: "Förtidigt / försent sluten epifys  i karpalben" },
  { value: "SH11117", title: "Slabfraktur i karpalben" },
  { value: "SH1112", title: "Hörnstödsfraktur i karpalben" },
  {
    value: "SH1113",
    title: "Benbroskfragm plant/palm i karpalben (Birkeland)",
  },
  { value: "SH113", title: "Osteokondrodysplasi i karpalben" },
  { value: "SH115", title: "Felaktig benställning, karpalben" },
  { value: "SH1151", title: "Felaktig benställning framben, karpalben" },
  { value: "SH1153", title: "Olika benlängd, karpalben" },
  {
    value: "SH119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i karpalben",
  },
  { value: "SH1192", title: "Bencysta, aseptisk bennekros i karpalben" },
  {
    value: "SH1194",
    title: "Metafysär osteopati (hypertrofisk osteodystrofi), karpalben",
  },
  { value: "SH2", title: "Metabol, nutrit, degen/dystrof förändr, karpalben" },
  { value: "SH20", title: "Metaboliska förändringar, karpalben" },
  { value: "SH201", title: "Osteomalaci / osteolys i karpalben" },
  { value: "SH202", title: "Osteoporos i karpalben" },
  { value: "SH203", title: "Osteodystrofi  i karpalben" },
  { value: "SH204", title: "Benmetaplasier i karpalben" },
  { value: "SH21", title: "Nutritionella förändringar, karpalben" },
  { value: "SH22", title: "Degenerativa/dystrofiska förändringar i karpalben" },
  { value: "SH224", title: "Pulmonär hypertrofisk osteopati i karpalben" },
  { value: "SH225", title: "Aseptisk bennekros i karpalben" },
  { value: "SH23", title: "Hormonella förändringar, karpalben" },
  { value: "SH3", title: "Cirkulatoriska förändringar, karpalben" },
  { value: "SH30", title: "Blödningar, karpalben" },
  { value: "SH301", title: "Subperiostal blödning i karpalben" },
  { value: "SH31", title: "Ödem, karpalben" },
  { value: "SH32", title: "Infarkter, karpalben" },
  { value: "SH33", title: "Hygrom, karpalben" },
  { value: "SH34", title: "Trombos / emboli, karpalben" },
  { value: "SH35", title: "Arterio-atero-skleros förändringar, karpalben" },
  { value: "SH4", title: "Infekt, infl förändringar, karpalben" },
  { value: "SH40", title: "Specifika infektionssjukdomar, karpalben" },
  { value: "SH41", title: "Akuta inflammationstillstånd, karpalben" },
  {
    value: "SH411",
    title: "Akut serös / serofibrinös inflammation, karpalben",
  },
  { value: "SH412", title: "Akut purulent inflammation, karpalben" },
  { value: "SH413", title: "Specifika akuta infl tillstånd, karpalben" },
  { value: "SH419", title: "Övriga akuta infl tillstånd, karpalben" },
  { value: "SH4191", title: "Akut periostit i karpalben" },
  { value: "SH41911", title: "Sekundär akut periostit i karpalben" },
  { value: "SH4192", title: "Akut ostit i karpalben" },
  { value: "SH4193", title: "Akut osteomyelit i karpalben" },
  { value: "SH41931", title: "Sekundär akut osteomyelit i karpalben" },
  {
    value: "SH41932",
    title: "Sekundär akut osteomyelit i karpalben e kir ingrepp",
  },
  { value: "SH42", title: "Kroniska inflammationstillstånd, karpalben" },
  { value: "SH421", title: "Kronisk serös inflammation, karpalben" },
  { value: "SH422", title: "Purulent inflammation, karpalben" },
  { value: "SH423", title: "Abscess/fistel, karpalben" },
  { value: "SH424", title: "Specifika kroniska infl tillstånd, karpalben" },
  { value: "SH429", title: "Övriga kroniska infl tillstånd, karpalben" },
  { value: "SH4291", title: "Kronisk periostit (överben) i karpalben" },
  { value: "SH42911", title: "Sekundär kronisk periostit i karpalben" },
  { value: "SH4292", title: "Kronisk ostit i karpalben" },
  { value: "SH4293", title: "Kronisk osteomyelit i karpalben" },
  { value: "SH42931", title: "Sekundär kronisk osteomyelit i karpalben" },
  {
    value: "SH42932",
    title: "Sekundär kronisk osteomyelit i karpalben e kir ingrepp",
  },
  { value: "SH4294", title: "Deformerande beninflammation i karpalben" },
  { value: "SH43", title: "Parasitära sjukdomar, karpalben" },
  { value: "SH5", title: "Immunmedierade tillstånd, karpalben" },
  { value: "SH6", title: "Neoplastiska förändringar, karpalben" },
  { value: "SH61", title: "Malign primär tumör i karpalbeno/e brosk inkl led" },
  {
    value: "SH611",
    title: "Malign tumör i karpalben utgången från broskvävnad",
  },
  { value: "SH6111", title: "Kondrosarkom i karpalben" },
  { value: "SH6112", title: "Kondromyxosarkom i karpalben" },
  { value: "SH612", title: "Malign tumör i karpalben utgången från benvävnad" },
  { value: "SH6121", title: "Osteosark i karpalbenom i karpalben" },
  { value: "SH613", title: "Malign tumör av bindvävsursprung, karpalben" },
  { value: "SH6131", title: "Fibrosarkom i karpalben" },
  {
    value: "SH614",
    title: "Malign tumör i karpalben av vaskulärt eller lymf urspr",
  },
  {
    value: "SH6141",
    title: "Angiosarkom, hemangiendoteliom, hemangiopericytom, karpalben",
  },
  { value: "SH615", title: "Metastas till skelett, karpalben" },
  { value: "SH619", title: "Skelettumör i karpalben av malign karaktär, UNS" },
  { value: "SH62", title: "Benign tumör i karpalbeno/e brosk inkl led" },
  {
    value: "SH621",
    title: "Benign tumör i karpalben utgången från broskvävnad",
  },
  { value: "SH6211", title: "Kondroblastom i karpalben" },
  { value: "SH6212", title: "Ostekondrom i karpalben" },
  { value: "SH6213", title: "Enkondrom i karpalben" },
  { value: "SH622", title: "Benign tumör i karpalben utgången från benvävnad" },
  { value: "SH6221", title: "Osteom  i karpalben" },
  { value: "SH6222", title: "Osteoblastom i karpalben" },
  { value: "SH623", title: "Benign tumör i karpalben av bindvävsursprung" },
  { value: "SH6231", title: "Fibrom i karpalben" },
  { value: "SH6232", title: "Fibröst histiocytom i karpalben" },
  {
    value: "SH624",
    title: "Benign tumör i karpalben av vaskulärt eller lymf urspr",
  },
  { value: "SH6241", title: "Hemangiom i karpalben" },
  { value: "SH6242", title: "Lymfangiom i karpalben" },
  { value: "SH6251", title: "Jättecellstumör i karpalben" },
  { value: "SH628", title: "Övriga benigna skelettumörer i karpalben" },
  { value: "SH629", title: "Skelettumör i karpalben av benign karaktär, UNS" },
  { value: "SH7", title: "Trauma, fr kr, lägeförändr, term skad, karpalben" },
  { value: "SH71", title: "Traumatiska / mekaniska skador, karpalben" },
  { value: "SH711", title: "Fissur i karpalben" },
  { value: "SH712", title: "Epifysfraktur i karpalben" },
  {
    value: "SH7121",
    title: "Epifysfraktur  i karpalben (epifysglidning) proximalt",
  },
  {
    value: "SH7122",
    title: "Epifysfraktur i karpalben (epifysglidning) distalt",
  },
  { value: "SH7123", title: "Epifysiolys i karpalben" },
  { value: "SH71231", title: "Proximal epifysiolys i karpalben" },
  { value: "SH71232", title: "Distal epifysiolys i karpalben" },
  { value: "SH7129", title: "Öriga epifysskador i karpalben" },
  { value: "SH713", title: "Fraktur i karpalben" },
  { value: "SH7131", title: "Diafysfraktur  / fraktur på korpus i karpalben" },
  { value: "SH7132", title: "Lednära fraktur proximalt i karpalben" },
  { value: "SH7133", title: "Lednära fraktur distalt i karpalben" },
  {
    value: "SH7134",
    title: "Fraktur i karpalben som berör angränsande led proximalt",
  },
  {
    value: "SH7135",
    title: "Fraktur i karpalben som berör angränsande led distalt",
  },
  { value: "SH7136", title: "Avsprängningsfraktur i karpalben proximalt" },
  { value: "SH7137", title: "Avsprängningsfraktur i karpalben distalt" },
  { value: "SH7139", title: "Kompressionsfraktur i karpalben" },
  { value: "SH714", title: "Fraktur av speciell typ i karpalben" },
  {
    value: "SH7141",
    title: "Kortikal subperiostal fraktur (greenstick), karpalben",
  },
  { value: "SH7142", title: "Fraktur av osteofyt, karpalben" },
  {
    value: "SH7149",
    title: "Patologisk fraktur, spontanfraktur i karpalben UNS",
  },
  { value: "SH715", title: "Komplikation till frakturläkning i karpalben" },
  { value: "SH7151", title: "Pseudoartros i karpalben" },
  {
    value: "SH7152",
    title: "Atrofi av benvävnad i karpalben som kompl t frakturläkn",
  },
  { value: "SH7153", title: "Felläkt fraktur i karpalben" },
  {
    value: "SH7154",
    title: "Ankylos av angränsande led pga skelettskada, karpalben",
  },
  { value: "SH719", title: "Övriga skelettskador i karpalben" },
  { value: "SH72", title: "Främmande kroppar, karpalben" },
  { value: "SH73", title: "Lägesförändringar i karpalben" },
  { value: "SH739", title: "Övrig lägesförändring, karpalben" },
  { value: "SH74", title: "Termiska skador, karpalben" },
  { value: "SH741", title: "Brännskada, karpalben" },
  { value: "SH742", title: "Köldskada, karpalben" },
  { value: "SH8", title: "Toxiska förändringar, karpalben" },
  {
    value: "SH9",
    title: "Idiopatiska ospecificerade multifaktoriella, karpalben",
  },
  { value: "SI", title: "Metakarpal ben" },
  {
    value: "SI0",
    title: "Normalvar, symtom u fastställd orsak, metakarpalben",
  },
  { value: "SI00", title: "Normalvariation, metakarpalben" },
  {
    value: "SI01",
    title: "Symtom på sjukdom u fastställd orsak, metakarpalben",
  },
  { value: "SI011", title: "Skelettömhet i metakarpal ben" },
  {
    value: "SI1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, metakarpalben",
  },
  { value: "SI10", title: "Missbildning, metakarpalben" },
  { value: "SI101", title: "Övertaliga skelettdelar, metakarpalben" },
  {
    value: "SI102",
    title: "Aplasi eller hypoplasi av skelettdelar, metakarpalben",
  },
  { value: "SI103", title: "Felställda skelettdelar , metakarpalben" },
  { value: "SI104", title: "Kondrodystrofi (akondroplasi) i metakarpal ben" },
  { value: "SI109", title: "Övriga skelettmissbildningar i metakarpal ben" },
  {
    value: "SI1099",
    title: "Övriga ej definierade skelettmissbildningar i metakarpal ben",
  },
  { value: "SI11", title: "Utvecklingsrubbn, tillväxtrubbn, metakarpalben" },
  { value: "SI111", title: "Samtidig ben och brosksjukdom, metakarpalben" },
  { value: "SI1111", title: "Osteokondros i metakarpal ben" },
  { value: "SI11111", title: "Osteokondros utan ledmus i metakarpal ben" },
  { value: "SI11112", title: "Osteokondros med ledmus" },
  { value: "SI11113", title: "Subkondral bencysta av osteokondrostyp" },
  { value: "SI11117", title: "Slabfraktur i metakarpal ben" },
  { value: "SI112", title: "Hörnstödsfraktur i metakarpal ben" },
  {
    value: "SI113",
    title: "Benbroskfragment plantar/palmar i metakarpal ben (Birkeland)",
  },
  {
    value: "SI119",
    title: "Övriga utvecklingsrubbn, tillväxtrubbn i, metakarpalben",
  },
  { value: "SI1191", title: "Panostit (endostsos) i metakarpal ben" },
  { value: "SI1192", title: "Bencysta, aseptisk bennekros i metakarpal ben" },
  {
    value: "SI1194",
    title: "Metafysär osteopati (hypertrof osteodystrf) i metakarpal ben",
  },
  { value: "SI13", title: "Osteokondrodysplasi i metakarpal ben" },
  {
    value: "SI2",
    title: "Metabol, nutrit, degen/dystrof förändr, metakarpalben",
  },
  { value: "SI20", title: "Metaboliska förändringar, metakarpalben" },
  { value: "SI201", title: "Osteomalaci / osteolys i metakarpal ben" },
  { value: "SI202", title: "Osteoporos i metakarpal ben" },
  { value: "SI203", title: "Osteodystrofi i metakarpal ben" },
  { value: "SI204", title: "Benmetaplasier i metakarpal ben" },
  { value: "SI21", title: "Nutritionella förändringar, metakarpalben" },
  {
    value: "SI22",
    title: "Degenerativa/dystrofiska förändringar i metakarpal ben",
  },
  { value: "SI224", title: "Pulmonär hypertrofisk osteopati i metakarpal ben" },
  { value: "SI225", title: "Aseptisk bennekros i metakarpal ben" },
  { value: "SI23", title: "Hormonella förändringar, metakarpalben" },
  { value: "SI3", title: "Cirkulatoriska förändringar, metakarpalben" },
  { value: "SI30", title: "Blödningar, metakarpalben" },
  { value: "SI301", title: "Subperiostal blödning i metakarpal ben" },
  { value: "SI31", title: "Ödem, metakarpalben" },
  { value: "SI32", title: "Infarkter, metakarpalben" },
  { value: "SI33", title: "Hygrom, metakarpalben" },
  { value: "SI34", title: "Trombos / emboli, metakarpalben" },
  { value: "SI35", title: "Arterio-atero-skleros förändringar, metakarpalben" },
  { value: "SI4", title: "Infekt, infl förändringar, metakarpalben" },
  { value: "SI40", title: "Specifika infektionssjukdomar, metakarpalben" },
  { value: "SI41", title: "Akuta inflammationstillstånd, metakarpalben" },
  {
    value: "SI411",
    title: "Akut serös / serofibrinös inflammation, metakarpalben",
  },
  { value: "SI412", title: "Akut purulent inflammation, metakarpalben" },
  { value: "SI413", title: "Specifika akuta infl tillstånd, metakarpalben" },
  { value: "SI419", title: "Övriga akuta infl tillstånd, metakarpalben" },
  { value: "SI4191", title: "Akut periostit i metakarpal ben" },
  { value: "SI41911", title: "Sekundär akut periostit i metakarpal ben" },
  { value: "SI4192", title: "Akut ostit i metakarpal ben" },
  { value: "SI4193", title: "Akut osteomyelit i metakarpal ben" },
  { value: "SI41931", title: "Sekundär akut osteomyelit i metakarpal ben" },
  {
    value: "SI41932",
    title: "Sekundär akut osteomyelit i metakarpal ben e kir ingrepp",
  },
  { value: "SI42", title: "Kroniska inflammationstillstånd, metakarpalben" },
  { value: "SI421", title: "Kronisk serös inflammation, metakarpalben" },
  { value: "SI422", title: "Purulent inflammation, metakarpalben" },
  { value: "SI423", title: "Abscess/fistel, metakarpalben" },
  { value: "SI424", title: "Specifika kroniska infl tillstånd, metakarpalben" },
  { value: "SI429", title: "Övriga kroniska infl tillstånd, metakarpalben" },
  { value: "SI4291", title: "Kronisk periostit i metakarpal ben (överben)" },
  { value: "SI42911", title: "Sekundär kronisk periostit i metakarpal ben" },
  { value: "SI4292", title: "Kronisk ostit i metakarpal ben" },
  { value: "SI4293", title: "Kronisk osteomyelit i metakarpal ben" },
  { value: "SI42931", title: "Sekundär kronisk osteomyelit i metakarpal ben" },
  {
    value: "SI42932",
    title: "Sekundär kronisk osteomyelit i metakarpal ben e kir ingrepp",
  },
  { value: "SI4294", title: "Deformerande beninflammation i metakarpal ben" },
  { value: "SI43", title: "Parasitära sjukdomar, metakarpalben" },
  { value: "SI5", title: "Immunmedierade tillstånd, metakarpalben" },
  { value: "SI6", title: "Neoplastiska förändringar, metakarpalben" },
  {
    value: "SI61",
    title: "Malign primär tumör i metakarpalbeno/e brosk inkl led",
  },
  {
    value: "SI611",
    title: "Malign tumör i metakarpal ben utgången från broskvävnad",
  },
  { value: "SI6111", title: "Kondrosarkom i metakarpal ben" },
  { value: "SI6112", title: "Kondromyxosarkom i metakarpal ben" },
  {
    value: "SI612",
    title: "Malign tumör i metakarpal ben utgången från benvävnad",
  },
  { value: "SI6121", title: "Osteosarkom i metakarpal ben" },
  {
    value: "SI613",
    title: "Malign tumör i metakarpal ben av bindvävsursprung",
  },
  { value: "SI6131", title: "Fibrosarkom i metakarpal ben" },
  {
    value: "SI614",
    title: "Malign tumör i metakarpal ben  av vaskulärt eller lymf urspr",
  },
  {
    value: "SI6141",
    title: "Angiosarkom,hemangiendoteliom,hemangiopericytom,metakarpalbe",
  },
  { value: "SI615", title: "Metastas till skelett, metakarpalben" },
  {
    value: "SI619",
    title: "Skelettumör i metakarpal ben av malign karaktär, UNS",
  },
  { value: "SI62", title: "Benign tumör i metakarpalbeno/e brosk inkl led" },
  {
    value: "SI621",
    title: "Benign tumör i metakarpal ben utgången från broskvävnad",
  },
  { value: "SI6211", title: "Kondroblastom i metakarpal ben" },
  { value: "SI6212", title: "Ostekondrom i metakarpal ben" },
  { value: "SI6213", title: "Enkondrom i metakarpal ben" },
  {
    value: "SI622",
    title: "Benign tumör i metakarpal ben utgången från benvävnad",
  },
  { value: "SI6221", title: "Osteom i metakarpal ben" },
  { value: "SI6222", title: "Osteoblastom i metakarpal ben" },
  {
    value: "SI623",
    title: "Benign tumör i metakarpal ben av bindvävsursprung",
  },
  { value: "SI6231", title: "Fibrom i metakarpal ben" },
  { value: "SI6232", title: "Fibröst histiocytom i metakarpal ben" },
  {
    value: "SI624",
    title: "Benign tumör i metakarpal ben av vaskulärt eller lymf urspr",
  },
  { value: "SI6241", title: "Hemangiom i metakarpal ben" },
  { value: "SI6242", title: "Lymfangiom  i metakarpal ben" },
  { value: "SI628", title: "Övriga benigna skelettumörer i metakarpal ben" },
  { value: "SI6281", title: "Jättecellstumör i metakarpal ben" },
  {
    value: "SI629",
    title: "Skelettumör i metakarpal ben av benign karaktär, UNS",
  },
  {
    value: "SI7",
    title: "Trauma, fr kr, lägeförändr, term skad, metakarpalben",
  },
  { value: "SI71", title: "Traumatiska / mekaniska skador i metakarpal ben" },
  { value: "SI711", title: "Fissur i metakarpal ben" },
  { value: "SI712", title: "Epifysfraktur i metakarpal ben" },
  {
    value: "SI7121",
    title: "Epifysfraktur  i metakarpal ben (epifysglidning) proximalt",
  },
  {
    value: "SI7122",
    title: "Epifysfraktur  i metakarpal ben (epifysglidning) distalt",
  },
  { value: "SI7123", title: "Epifysiolys i metakarpal ben" },
  { value: "SI71231", title: "Proximal epifysiolys i metakarpal ben" },
  { value: "SI71232", title: "Distal epifysiolys i metakarpal ben" },
  { value: "SI7129", title: "Öriga epifysskador i metakarpal ben" },
  { value: "SI713", title: "Fraktur i metakarpal ben" },
  { value: "SI7131", title: "Diafysfraktur av metakarpal ben" },
  { value: "SI7132", title: "Lednära fraktur av metakarpal ben proximalt" },
  { value: "SI7133", title: "Lednära fraktur av metakarpal ben distalt" },
  {
    value: "SI7134",
    title: "Fraktur av metakarpal ben  som berör angränsande led prox",
  },
  {
    value: "SI7135",
    title: "Fraktur av metakarpal ben som berör angränsande led distalt",
  },
  {
    value: "SI7136",
    title: "Avsprängningsfraktur  av metakarpal ben proximalt",
  },
  { value: "SI7137", title: "Avsprängningsfraktur av metakarpal ben distalt" },
  { value: "SI7139", title: "Kompressionsfraktur av metakarpal ben" },
  { value: "SI714", title: "Fraktur av speciell typ av metakarpal ben" },
  {
    value: "SI7141",
    title: "Kortikal subperiostal fraktur av metakarpal ben (greenstick)",
  },
  { value: "SI7142", title: "Fraktur av osteofyt av metakarpal ben" },
  {
    value: "SI7149",
    title: "Patologisk fraktur, spontanfraktur av metakarpal ben UNS",
  },
  {
    value: "SI715",
    title: "Komplikation till frakturläkning av metakarpal ben",
  },
  { value: "SI7151", title: "Pseudoartros av metakarpal ben" },
  {
    value: "SI7152",
    title: "Atrofi av benvävnad i metakarpal ben som kompl t frakturläkn",
  },
  { value: "SI7153", title: "Felläkt fraktur i metakarpal ben" },
  {
    value: "SI7154",
    title: "Ankylos av angränsande led pga skelettskada, metakarpalben",
  },
  { value: "SI719", title: "Övriga skelettskador i metakarpal ben" },
  { value: "SI72", title: "Främmande kroppar, metakarpalben" },
  { value: "SI73", title: "Lägesförändringar, metakarpalben" },
  { value: "SI739", title: "Övrig lägesförändring av metakarpal ben" },
  { value: "SI74", title: "Termiska skador, metakarpalben" },
  { value: "SI741", title: "Brännskada, metakarpalben" },
  { value: "SI742", title: "Köldskada, metakarpalben" },
  { value: "SI8", title: "Toxiska förändringar, metakarpalben" },
  {
    value: "SI9",
    title: "Idiopatiska ospecificerade multifaktoriella, metakarpalben",
  },
  { value: "SJ", title: "Kotsenben, sesamoidben" },
  {
    value: "SJ0",
    title: "Normalvar, symtom u fastställd orsak, kotsen-sesamoidben",
  },
  { value: "SJ00", title: "Normalvariation, kotsen-sesamoidben" },
  {
    value: "SJ01",
    title: "Symtom på sjukdom u fastställd orsak, kotsen-sesamoidben",
  },
  { value: "SJ011", title: "Skelettömhet i kotsenben / sesamoidben" },
  {
    value: "SJ1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, kotsen-sesamoidben",
  },
  { value: "SJ10", title: "Missbildning, kotsen-sesamoidben" },
  { value: "SJ101", title: "Övertaliga skelettdelar, kotsen-sesamoidben" },
  {
    value: "SJ102",
    title: "Aplasi eller hypoplasi av skelettdelar, kotsen-sesamoidben",
  },
  { value: "SJ103", title: "Felställda skelettdelar , kotsen-sesamoidben" },
  {
    value: "SJ104",
    title: "Kondrodystrofi (akondroplasi) i kotsenben / sesamoidben",
  },
  {
    value: "SJ109",
    title: "Övriga skelettmissbildningari kotsenben / sesamoidben",
  },
  {
    value: "SJ1099",
    title: "Övriga ej def skelettmissbildn i kotsenben / sesamoidben",
  },
  {
    value: "SJ11",
    title: "Utvecklingsrubbn, tillväxtrubbn i kotsenben / sesamoidben",
  },
  {
    value: "SJ111",
    title: "Samtidig ben och brosksjukdom, kotsen-sesamoidben",
  },
  { value: "SJ1111", title: "Osteokondros i kotsenben / sesamoidben" },
  { value: "SJ11117", title: "Slabfraktur i kotsenben / sesamoidben" },
  { value: "SJ1112", title: "Hörnstödsfraktur i kotsenben / sesamoidben" },
  {
    value: "SJ1113",
    title: "Benbroskfragment plant/palmar kotsen/sesamoidben (Birkeland)",
  },
  { value: "SJ113", title: "Osteokondrodysplasi i kotsenben / sesamoidben" },
  {
    value: "SJ119",
    title: "Övriga utveckl.rubbn, tillväxtrubbn, kotsen-sesamoidben",
  },
  {
    value: "SJ1192",
    title: "Bencysta, aseptisk bennekros i kotsenben / sesamoidben",
  },
  {
    value: "SJ2",
    title: "Metabol, nutrit, degen/dystrof förändr, kotsen-sesamoidben",
  },
  { value: "SJ20", title: "Metaboliska förändringar, kotsen-sesamoidben" },
  { value: "SJ201", title: "Osteomalaci / osteolys i kotsenben / sesamoidben" },
  { value: "SJ202", title: "Osteoporos i kotsenben / sesamoidben" },
  { value: "SJ203", title: "Osteodystrofi i kotsenben / sesamoidben" },
  { value: "SJ204", title: "Benmetaplasier i kotsenben / sesamoidben" },
  { value: "SJ21", title: "Nutritionella förändringar, kotsen-sesamoidben" },
  {
    value: "SJ22",
    title: "Degenerativa/dystrofiska förändr i kotsenben/sesamoidben",
  },
  { value: "SJ225", title: "Aseptisk bennekros i kotsenben / sesamoidben" },
  { value: "SJ23", title: "Hormonella förändringar, kotsen-sesamoidben" },
  {
    value: "SJ3",
    title: "Cirkulatoriska förändringar, kotsen-    sesamoidben",
  },
  { value: "SJ30", title: "Blödningar, kotsen-sesamoidben" },
  { value: "SJ301", title: "Subperiostal blödning i kotsenben / sesamoidben" },
  { value: "SJ31", title: "Ödem, kotsen-sesamoidben" },
  { value: "SJ32", title: "Infarkter, kotsen-sesamoidben" },
  { value: "SJ33", title: "Hygrom, kotsen-sesamoidben" },
  { value: "SJ34", title: "Trombos / emboli, kotsen-sesamoidben" },
  {
    value: "SJ35",
    title: "Arterio-atero-skleros förändringar, kotsen-sesamoidben",
  },
  { value: "SJ4", title: "Infekt, infl förändringar, kotsen-sesamoidben" },
  { value: "SJ40", title: "Specifika infektionssjukdomar, kotsen-sesamoidben" },
  { value: "SJ41", title: "Akuta inflammationstillstånd, kotsen-sesamoidben" },
  {
    value: "SJ411",
    title: "Akut serös / serofibrinös inflammation, kotsen-sesamoidben",
  },
  { value: "SJ412", title: "Akut purulent inflammation, kotsen-sesamoidben" },
  {
    value: "SJ413",
    title: "Specifika akuta infl tillstånd, kotsen-sesamoidben",
  },
  { value: "SJ419", title: "Övriga akuta infl tillstånd, kotsen-sesamoidben" },
  { value: "SJ4191", title: "Akut periostit i kotsenben / sesamoidben" },
  {
    value: "SJ41911",
    title: "Sekundär akut periostit i kotsenben / sesamoidben",
  },
  { value: "SJ4192", title: "Akut ostit i kotsenben / sesamoidben" },
  { value: "SJ41921", title: "Akut sesamoidit" },
  { value: "SJ41923", title: "Akut kotsenbensinflammation" },
  { value: "SJ4193", title: "Akut osteomyelit i kotsenben / sesamoidben" },
  {
    value: "SJ41931",
    title: "Sekundär akut osteomyelit i kotsenben / sesamoidben",
  },
  {
    value: "SJ41932",
    title: "Sek akut osteomyelit i kotsenben / sesamoidben e kir ingrepp",
  },
  {
    value: "SJ42",
    title: "Kroniska inflammationstillstånd, kotsen-sesamoidben",
  },
  { value: "SJ421", title: "Kronisk serös inflammation, kotsen-sesamoidben" },
  { value: "SJ422", title: "Purulent inflammation, kotsen-sesamoidben" },
  { value: "SJ423", title: "Abscess/fistel, kotsen-sesamoidben" },
  {
    value: "SJ424",
    title: "Specifika kroniska infl tillstånd, kotsen-sesamoidben",
  },
  {
    value: "SJ429",
    title: "Övriga kroniska infl tillstånd, kotsen-sesamoidben",
  },
  {
    value: "SJ4291",
    title: "Kronisk periostit i kotsenben / sesamoidben (överben)",
  },
  {
    value: "SJ42911",
    title: "Sekundär kronisk periostit i kotsenben / sesamoidben",
  },
  { value: "SJ4292", title: "Kronisk ostit i kotsenben / sesamoidben" },
  { value: "SJ42921", title: "Kronisk sesamoidit" },
  { value: "SJ42923", title: "Kronisk kotsenbensinflammation" },
  { value: "SJ4293", title: "Kronisk osteomyelit i kotsenben / sesamoidben" },
  {
    value: "SJ42931",
    title: "Sekundär kronisk osteomyelit i kotsenben / sesamoidben",
  },
  {
    value: "SJ42932",
    title: "Sek kron osteomyelit i kotsenben / sesamoidben e kir ingrepp",
  },
  {
    value: "SJ4294",
    title: "Deformerande beninflammation i kotsenben / sesamoidben",
  },
  { value: "SJ43", title: "Parasitära sjukdomar, kotsen-sesamoidben" },
  { value: "SJ5", title: "Immunmedierade tillstånd, kotsen-sesamoidben" },
  { value: "SJ6", title: "Neoplastiska förändringar, kotsen-sesamoidben" },
  {
    value: "SJ61",
    title: "Malign primär tumör i kotsen-sesamoidbeno/e brosk inkl led",
  },
  {
    value: "SJ611",
    title: "Malign tumör i kotsenben/sesamoidben utgången från broskvävn",
  },
  { value: "SJ6111", title: "Kondrosarkom i kotsenben / sesamoidben" },
  { value: "SJ6112", title: "Kondromyxosarkom i kotsenben / sesamoidben" },
  {
    value: "SJ612",
    title: "Malign tumör i kotsenben / sesamoidben utgången från benvävn",
  },
  { value: "SJ6121", title: "Osteosarkom i kotsenben / sesamoidben" },
  {
    value: "SJ613",
    title: "Malign tumör i kotsenben / sesamoidben av bindvävsursprung",
  },
  { value: "SJ6131", title: "Fibrosarkom i kotsenben / sesamoidben" },
  {
    value: "SJ614",
    title: "Malign tumör i kotsen/sesamoidben av vaskulärt e lymf urspr",
  },
  {
    value: "SJ6141",
    title: "Angiosarkom, hemangiendoteliom,hemangiopericytom,sesamoidben",
  },
  { value: "SJ615", title: "Metastas till skelett, kotsen-sesamoidben" },
  {
    value: "SJ619",
    title: "Skelettumör i kotsenben/sesamoidben av malign karaktär, UNS",
  },
  {
    value: "SJ62",
    title: "Benign tumör i kotsen-sesamoidbeno/e brosk inkl led",
  },
  {
    value: "SJ621",
    title: "Benign tumör i kotsenben/sesamoidben utgången fr broskvävnad",
  },
  { value: "SJ6211", title: "Kondroblastom i kotsenben / sesamoidben" },
  { value: "SJ6212", title: "Ostekondrom i kotsenben / sesamoidben" },
  { value: "SJ6213", title: "Enkondrom i kotsenben / sesamoidben" },
  {
    value: "SJ622",
    title: "Benign tumör i kotsenben / sesamoidben utgången fr benvävnad",
  },
  { value: "SJ6221", title: "Osteom i kotsenben / sesamoidben" },
  { value: "SJ6222", title: "Osteoblastom i kotsenben / sesamoidben" },
  {
    value: "SJ623",
    title: "Benign tumör i kotsenben / sesamoidben av bindvävsursprung",
  },
  { value: "SJ6231", title: "Fibrom i kotsenben / sesamoidben" },
  { value: "SJ6232", title: "Fibröst histiocytom i kotsenben / sesamoidben" },
  {
    value: "SJ624",
    title: "Benign tumör i kotsen/sesamoidben av vaskulärt / lymf urspr",
  },
  { value: "SJ6241", title: "Hemangiom i kotsenben / sesamoidben" },
  { value: "SJ6242", title: "Lymfangiom  i kotsenben / sesamoidben" },
  {
    value: "SJ628",
    title: "Övriga benigna skelettumörer i kotsenben / sesamoidben",
  },
  { value: "SJ6281", title: "Jättecellstumör i kotsenben / sesamoidben" },
  {
    value: "SJ629",
    title: "Skelettumör i kotsenben/sesamoidben av benign karaktär, UNS",
  },
  {
    value: "SJ7",
    title: "Trauma, fr kr, lägeförändr, term skad, kotsen-sesamoidben",
  },
  {
    value: "SJ71",
    title: "Traumatiska / mekaniska skador i kotsenben / sesamoidben",
  },
  { value: "SJ711", title: "Fissur i kotsenben / sesamoidben" },
  { value: "SJ713", title: "Fraktur i kotsenben / sesamoidben" },
  {
    value: "SJ7131",
    title: "Diafysfraktur  / fraktur på korpus i kotsenben / sesamoidben",
  },
  {
    value: "SJ7132",
    title: "Lednära fraktur proximalt  i kotsenben / sesamoidben",
  },
  {
    value: "SJ7133",
    title: "Lednära fraktur distalt  i kotsenben / sesamoidben",
  },
  {
    value: "SJ7134",
    title: "Frakt som berör angräns led/leder i kotsenben / sesamoidben",
  },
  {
    value: "SJ7135",
    title: "Frakt som berör angräns led/leder dist i kotsen/sesamoidben",
  },
  {
    value: "SJ7136",
    title: "Avsprängningsfrakt prox/kran i kotsenben / sesamoidben",
  },
  {
    value: "SJ7137",
    title: "Avsprängningsfraktur dist/kaudal i kotsenben / sesamoidben",
  },
  { value: "SJ7139", title: "Kompressionsfraktur i kotsenben / sesamoidben" },
  {
    value: "SJ714",
    title: "Fraktur av speciell typ i kotsenben / sesamoidben",
  },
  { value: "SJ7142", title: "Fraktur av osteofyt i kotsenben / sesamoidben" },
  { value: "SJ7148", title: "Kotsenbensfraktur" },
  {
    value: "SJ7149",
    title: "Patologisk fraktur, spontanfrakt i kotsenben/sesamoidben UNS",
  },
  {
    value: "SJ715",
    title: "Komplikation till frakturläkning i kotsenben / sesamoidben",
  },
  { value: "SJ7151", title: "Pseudoartros i kotsenben / sesamoidben" },
  {
    value: "SJ7152",
    title: "Atrofi av benvävn i kotsen/sesamoidben s kompl t frakt.läkn",
  },
  { value: "SJ7153", title: "Felläkt fraktur i kotsenben / sesamoidben" },
  {
    value: "SJ7154",
    title: "Ankylos av angräns led pga skelettskada i kotsen/sesamoidben",
  },
  { value: "SJ719", title: "Övriga skelettskador i kotsenben / sesamoidben" },
  { value: "SJ72", title: "Främmande kroppar i kotsenben / sesamoidben" },
  { value: "SJ73", title: "Lägesförändringar, kotsen-sesamoidben" },
  { value: "SJ739", title: "Övrig lägesförändring i kotsenben / sesamoidben" },
  { value: "SJ74", title: "Termiska skador, kotsen-sesamoidben" },
  { value: "SJ741", title: "Brännskada, kotsen-sesamoidben" },
  { value: "SJ742", title: "Köldskada, kotsen-sesamoidben" },
  { value: "SJ8", title: "Toxiska förändringar, kotsen-sesamoidben" },
  {
    value: "SJ9",
    title: "Idiopatiska ospecif multifaktoriella, kotsen-sesamoidben",
  },
  { value: "SK", title: "Falangben 1 (kotben)" },
  {
    value: "SK0",
    title: "Normalvar, symtom u fastställd orsak, falangben 1 (kotben)",
  },
  { value: "SK00", title: "Normalvariation, falangben 1 (kotben)" },
  {
    value: "SK01",
    title: "Symtom på sjukdom u fastställd orsak, falangben 1(kotben)",
  },
  { value: "SK011", title: "Skelettömhet i falangben 1 (kotben)" },
  {
    value: "SK1",
    title: "Missbildn, utveckl.rubbn, tillväxtrubbn, falangben 1(kotben)",
  },
  { value: "SK10", title: "Missbildning, falangben 1 (kotben)" },
  { value: "SK101", title: "Övertaliga skelettdelar, falangben 1 (kotben)" },
  { value: "SK1011", title: "Polydaktyli i falangben 1 (kotben)" },
  {
    value: "SK102",
    title: "Aplasi eller hypoplasi av skelettdelar, falangben 1 (kotben)",
  },
  { value: "SK103", title: "Felställda skelettdelar , falangben 1 (kotben)" },
  {
    value: "SK104",
    title: "Kondrodystrofi (akondroplasi) i falangben 1 (kotben)",
  },
  {
    value: "SK109",
    title: "Övriga skelettmissbildningar i falangben 1 (kotben)",
  },
  {
    value: "SK1093",
    title: "Dysostos i falangben 1 (kotben) (def benbildn u fosterlivet)",
  },
  { value: "SK1096", title: "Missbildad falang i falangben 1 (kotben)" },
  {
    value: "SK1099",
    title: "Övriga ej def skelettmissbildningar i falangben 1 (kotben)",
  },
  { value: "SK11", title: "Utvecklingsrubbn, tillväxtrubbn, falangben 1" },
  { value: "SK111", title: "Samtidig ben och brosksjukdom, falangben 1" },
  { value: "SK1111", title: "Osteokondros i falangben 1 (kotben)" },
  {
    value: "SK11111",
    title: "Osteokondros utan ledmus i falangben 1 (kotben)",
  },
  { value: "SK11112", title: "Osteokondros med ledmus i falangben 1 (kotben)" },
  {
    value: "SK11113",
    title: "Subkondral bencysta av osteokondrostyp i falangben 1(kotben)",
  },
  {
    value: "SK11114",
    title: "Förtidigt / försent sluten epifys  i falangben 1 (kotben)",
  },
  { value: "SK11117", title: "Slabfraktur i falangben 1 (kotben)" },
  { value: "SK1112", title: "Hörnstödsfraktur i falangben 1 (kotben)" },
  {
    value: "SK1113",
    title: "Benbroskfragment plant/palm i falangben 1(kotben)(Birkeland)",
  },
  { value: "SK113", title: "Osteokondrodysplasi  i falangben 1 (kotben)" },
  { value: "SK115", title: "Felaktig benställning, falangben 1" },
  {
    value: "SK1151",
    title: "Felaktig benställning framben i falangben 1 (kotben)",
  },
  { value: "SK1153", title: "Olika benlängd, falangben 1" },
  {
    value: "SK119",
    title: "Övriga utveckl.rubbn, tillväxtrubbn, falangben 1 (kotben)",
  },
  { value: "SK1191", title: "Panostit (endostsos) i falangben 1 (kotben)" },
  {
    value: "SK1192",
    title: "Bencysta, aseptisk bennekros i falangben 1 (kotben)",
  },
  {
    value: "SK2",
    title: "Metabol, nutrit, degen/dystrof förändr, falamgben 1",
  },
  { value: "SK20", title: "Metaboliska förändringar, falangben 1 (kotben)" },
  { value: "SK201", title: "Osteomalaci / osteolys i falangben 1 (kotben)" },
  { value: "SK202", title: "Osteoporos i falangben 1 (kotben)" },
  { value: "SK203", title: "Osteodystrofi i falangben 1 (kotben)" },
  { value: "SK204", title: "Benmetaplasier i falangben 1 (kotben)" },
  { value: "SK21", title: "Nutritionella förändringar, falangben 1" },
  {
    value: "SK22",
    title: "Degenerativa/dystrofiska förändringar, falangben 1 (kotben)",
  },
  { value: "SK225", title: "Aseptisk bennekros i falangben 1 (kotben)" },
  { value: "SK23", title: "Hormonella förändringar, falangben 1 (kotben)" },
  { value: "SK3", title: "Cirkulatoriska förändringar, falangben 1 (kotben)" },
  { value: "SK30", title: "Blödningar, falangben 1 (kotben)" },
  { value: "SK301", title: "Subperiostal blödning i falangben 1 (kotben)" },
  { value: "SK31", title: "Ödem, falangben 1 (kotben)" },
  { value: "SK32", title: "Infarkter, falangben 1 (kotben)" },
  { value: "SK33", title: "Hygrom, falangben 1 (kotben)" },
  { value: "SK34", title: "Trombos / emboli, falangben 1 (kotben)" },
  {
    value: "SK35",
    title: "Arterio-atero-skleros förändringar, falangben 1 (kotben)",
  },
  { value: "SK4", title: "Infekt, infl förändringar, falangben 1(kotben)" },
  {
    value: "SK40",
    title: "Specifika infektionssjukdomar, falangben 1 (kotben)",
  },
  {
    value: "SK41",
    title: "Akuta inflammationstillstånd, falangben 1 (kotben)",
  },
  {
    value: "SK411",
    title: "Akut serös / serofibrinös inflammation, falangben 1 (kotben)",
  },
  { value: "SK412", title: "Akut purulent inflammation, falangben 1 (kotben)" },
  {
    value: "SK413",
    title: "Specifika akuta infl tillstånd, falangben 1 (kotben)",
  },
  {
    value: "SK419",
    title: "Övriga akuta infl tillstånd, falangben 1 (kotben)",
  },
  { value: "SK4191", title: "Akut periostit i falangben 1 (kotben)" },
  { value: "SK41911", title: "Sekundär akut periostit i falangben 1 (kotben)" },
  { value: "SK4192", title: "Akut ostit i falangben 1 (kotben)" },
  { value: "SK4193", title: "Akut osteomyelit i falangben 1 (kotben)" },
  {
    value: "SK41931",
    title: "Sekundär akut osteomyelit i falangben 1 (kotben)",
  },
  {
    value: "SK41932",
    title: "Sek akut osteomyelit i falangben 1 (kotben)   e kir ingrepp",
  },
  { value: "SK4198", title: "Akut fysit  i falangben 1 (kotben)" },
  {
    value: "SK42",
    title: "Kroniska inflammationstillstånd, falangben 1 (kotben)",
  },
  { value: "SK421", title: "Kronisk serös inflammation, falangben 1 (kotben)" },
  { value: "SK422", title: "Purulent inflammation, falangben 1 (kotben)" },
  { value: "SK423", title: "Abscess/fistel, falangben 1 (kotben)" },
  {
    value: "SK424",
    title: "Specifika kroniska infl tillstånd, falangben 1 (kotben)",
  },
  {
    value: "SK429",
    title: "Övriga kroniska infl tillstånd, falangben 1 (kotben)",
  },
  {
    value: "SK4291",
    title: "Kronisk periostit  i falangben 1 (kotben)  (överben)",
  },
  {
    value: "SK42911",
    title: "Sekundär kronisk periostit i falangben 1 (kotben)",
  },
  { value: "SK4292", title: "Kronisk ostit i falangben 1 (kotben)" },
  { value: "SK4293", title: "Kronisk osteomyelit i falangben 1 (kotben)" },
  {
    value: "SK42931",
    title: "Sekundär kronisk osteomyelit i falangben 1 (kotben)",
  },
  {
    value: "SK42932",
    title: "Sek kron osteomyelit i falangben 1 (kotben) e kir ingrepp",
  },
  {
    value: "SK4294",
    title: "Deformerande beninflammation i falangben 1 (kotben)",
  },
  { value: "SK4298", title: "Kronisk fysit i falangben 1 (kotben)" },
  { value: "SK43", title: "Parasitära sjukdomar, falangben 1(kotben)" },
  { value: "SK5", title: "Immunmedierade tillstånd, falangben 1 (kotben)" },
  { value: "SK6", title: "Neoplastiska förändringar, falangben 1 (kotben)" },
  {
    value: "SK61",
    title: "Malign primär tumör i falangben 1(kotben) o/e brosk inkl led",
  },
  {
    value: "SK611",
    title: "Malign tumör i falangben 1(kotben) utgången från broskvävnad",
  },
  { value: "SK6111", title: "Kondrosarkom i falangben 1 (kotben)" },
  { value: "SK6112", title: "Kondromyxosarkom i falangben 1 (kotben)" },
  {
    value: "SK612",
    title: "Malign tumör i falangben 1 (kotben) utgången från benvävnad",
  },
  { value: "SK6121", title: "Osteosarkom i falangben 1 (kotben)" },
  {
    value: "SK613",
    title: "Malign tumör i falangben 1 (kotben) av bindvävsursprung",
  },
  { value: "SK6131", title: "Fibrosarkom i falangben 1 (kotben)" },
  {
    value: "SK614",
    title: "Malign tumör av vaskulärt/lymf urspr, falangben 1(kotben)",
  },
  {
    value: "SK6141",
    title: "Angiosarkom, hemangiendoteliom,hemangiopericytom,falangben 1",
  },
  { value: "SK615", title: "Metastas till skelett , falangben 1" },
  {
    value: "SK619",
    title: "Skelettumör i falangben 1 (kotben) av malign karaktär, UNS",
  },
  { value: "SK62", title: "Benign tumör i falangben 1o/e brosk inkl led" },
  {
    value: "SK621",
    title: "Benign tumör i falangben 1 (kotben) utgången fr broskvävnad",
  },
  { value: "SK6211", title: "Kondroblastom i falangben 1 (kotben)" },
  { value: "SK6212", title: "Ostekondrom i falangben 1 (kotben)" },
  { value: "SK6213", title: "Enkondrom i falangben 1 (kotben)" },
  {
    value: "SK622",
    title: "Benign tumör utgången från benvävnad, falangben 1 (kotben)",
  },
  { value: "SK6221", title: "Osteom  i falangben 1 (kotben)" },
  { value: "SK6222", title: "Osteoblastom i falangben 1 (kotben)" },
  {
    value: "SK623",
    title: "Benign tumör i falangben 1 (kotben) av bindvävsursprung",
  },
  { value: "SK6231", title: "Fibrom i falangben 1 (kotben)" },
  { value: "SK6232", title: "Fibröst histiocytom i falangben 1 (kotben)" },
  {
    value: "SK624",
    title: "Benign tumör i falangben 1 (kotben) av vaskulärt/lymf urspr",
  },
  { value: "SK6241", title: "Hemangiom i falangben 1 (kotben)" },
  { value: "SK6242", title: "Lymfangiom i falangben 1 (kotben)" },
  {
    value: "SK628",
    title: "Övriga benigna skelettumörer i falangben 1 (kotben)",
  },
  { value: "SK6281", title: "Jättecellstumör i falangben 1 (kotben)" },
  {
    value: "SK629",
    title: "Skelettumör i falangben 1 (kotben) av benign karaktär, UNS",
  },
  { value: "SK7", title: "Trauma, fr kr, lägeförändr, term skad, falangben 1" },
  {
    value: "SK71",
    title: "Traumatiska / mekaniska skador i falangben 1 (kotben)",
  },
  { value: "SK711", title: "Fissur i falangben 1 (kotben)" },
  { value: "SK712", title: "Epifysfraktur av falangben 1 (kotben)" },
  {
    value: "SK7121",
    title: "Epifysfrakt av falangben 1 (kotben) (epifysglidning) prox",
  },
  {
    value: "SK7122",
    title: "Epifysfraktur av falangben 1 (kotben) (epifysglidning) dist",
  },
  { value: "SK7123", title: "Epifysiolys av falangben 1 (kotben)" },
  { value: "SK71231", title: "Proximal epifysiolys av falangben 1 (kotben)" },
  { value: "SK71232", title: "Distal epifysiolys av falangben 1 (kotben)" },
  { value: "SK7129", title: "Öriga epifysskador av falangben 1 (kotben)" },
  { value: "SK713", title: "Fraktur av falangben 1 (kotben)" },
  { value: "SK7131", title: "Diafysfraktur  av falangben 1 (kotben)" },
  {
    value: "SK7132",
    title: "Lednära fraktur av falangben 1 (kotben) proximalt",
  },
  { value: "SK7133", title: "Lednära fraktur av falangben 1 (kotben) distalt" },
  {
    value: "SK7134",
    title: "Fraktur av falangben 1 (kotben) som berör angräns led prox",
  },
  {
    value: "SK7135",
    title: "Fraktur av falangben 1 (kotben) som berör angräns led dist",
  },
  {
    value: "SK7136",
    title: "Avsprängningsfraktur av falangben 1 (kotben) proximalt",
  },
  {
    value: "SK7137",
    title: "Avsprängningsfraktur av falangben 1 (kotben) distalt",
  },
  { value: "SK7139", title: "Kompressionsfraktur av falangben 1 (kotben)" },
  { value: "SK714", title: "Fraktur av speciell typ av falangben 1 (kotben)" },
  {
    value: "SK7141",
    title: "Kortikal subperiostal frakt av falangben1(kotben)(greenstick",
  },
  { value: "SK7142", title: "Fraktur av osteofyt av falangben 1 (kotben)" },
  {
    value: "SK7149",
    title: "Patologisk fraktur, spontanfrakt av falangben 1 (kotben) UNS",
  },
  {
    value: "SK715",
    title: "Komplikation till frakturläkning av falangben 1 (kotben)",
  },
  { value: "SK7151", title: "Pseudoartros av falangben 1 (kotben)" },
  {
    value: "SK7152",
    title: "Atrofi av benvävn i falangben 1(kotben), kompl t frakturläkn",
  },
  { value: "SK7153", title: "Felläkt fraktur av falangben 1 (kotben)" },
  {
    value: "SK7154",
    title: "Ankylos av angränsande led pga skelettskada, falangben 1",
  },
  { value: "SK719", title: "Övriga skelettskador av falangben 1 (kotben)" },
  { value: "SK72", title: "Främmande kroppar, falangben 1" },
  { value: "SK73", title: "Lägesförändringar, falangben 1" },
  { value: "SK739", title: "Övrig lägesförändring av falangben 1 (kotben)" },
  { value: "SK74", title: "Termiska skador, falangben 1" },
  { value: "SK741", title: "Brännskada, falangben 1" },
  { value: "SK742", title: "Köldskada, falangben 1" },
  { value: "SK8", title: "Toxiska förändringar, falangben 1" },
  {
    value: "SK9",
    title: "Idiopatiska ospecif multifaktoriella, falangben 1 (kotben)",
  },
  { value: "SL", title: "Falangben 2 (kronben)" },
  {
    value: "SL0",
    title: "Normalvar, symtom u fastställd orsak, falangben 2 (kronben)",
  },
  { value: "SL00", title: "Normalvariation, falangben 2 (kronben)" },
  {
    value: "SL01",
    title: "Symtom på sjukdom u fastställd orsak, falangben 2 (kronben)",
  },
  { value: "SL011", title: "Skelettömhet i falangben 2 (kronben)" },
  {
    value: "SL1",
    title: "Missbildn, utveckl.rubbn, tillväxtrubbn, falangben 2(kronben",
  },
  { value: "SL10", title: "Missbildning, falangben 2 (kronben)" },
  { value: "SL101", title: "Övertaliga skelettdelar, falangben 2 (kronben)" },
  { value: "SL1011", title: "Polydaktali" },
  {
    value: "SL102",
    title: "Aplasi eller hypoplasi av skelettdelar, falangben 2(kronben)",
  },
  { value: "SL103", title: "Felställda skelettdelar i falangben 2 (kronben)" },
  {
    value: "SL104",
    title: "Kondrodystrofi (akondroplasi) i falangben 2 (kronben)",
  },
  {
    value: "SL109",
    title: "Övriga skelettmissbildningar i falangben 2 (kronben)",
  },
  {
    value: "SL1093",
    title: "Dysostos (def benbildn u fosterlivet), falangben 2 (kronben)",
  },
  { value: "SL1096", title: "Missbildad falang i falangben 2 (kronben)" },
  {
    value: "SL10962",
    title: "Syndaktali (sammanväxning av falanger) i falangben 2",
  },
  {
    value: "SL1099",
    title: "Övriga ej def skelettmissbildningar i falangben 2 (kronben)",
  },
  { value: "SL11", title: "Utvecklingsrubbn, tillväxtrubbn, falangben 2" },
  {
    value: "SL111",
    title: "Samtidig ben och brosksjukdom, falangben 2 (kronben)",
  },
  { value: "SL1111", title: "Osteokondros i falangben 2 (kronben)" },
  {
    value: "SL11111",
    title: "Osteokondros utan ledmus i falangben 2 (kronben)",
  },
  {
    value: "SL11112",
    title: "Osteokondros med ledmus i falangben 2 (kronben)",
  },
  {
    value: "SL11113",
    title: "Subkondral bencysta av osteokondrostyp, falangben 2(kronben)",
  },
  {
    value: "SL11114",
    title: "Förtidigt / försent sluten epifys i falangben 2 (kronben)",
  },
  { value: "SL11117", title: "Slabfraktur i falangben 2 (kronben)" },
  { value: "SL1112", title: "Hörnstödsfraktur i falangben 2 (kronben)" },
  {
    value: "SL1113",
    title: "Benbroskfragm plant/palm i falangben 2 (kronben) (Birkeland)",
  },
  { value: "SL113", title: "Osteokondrodysplasi i falangben 2 (kronben)" },
  { value: "SL115", title: "Felaktig benställning, falangben 2 (kronben)" },
  {
    value: "SL1151",
    title: "Felaktig benställning framben, falangben 2 (kronben)",
  },
  { value: "SL1153", title: "Olika benlängd, falangben 2 (kronben)" },
  {
    value: "SL119",
    title: "Övriga utveckl.rubbn, tillväxtrubbn i falangben 2 (kronben)",
  },
  { value: "SL1191", title: "Panostit (endostsos) i falangben 2 (kronben)" },
  {
    value: "SL1192",
    title: "Bencysta, aseptisk bennekros i falangben 2 (kronben)",
  },
  {
    value: "SL2",
    title: "Metabol, nutrit, degen/dystrof förändr, falangben 2(kronben)",
  },
  { value: "SL20", title: "Metaboliska förändringar, falangben 2 (kronben)" },
  { value: "SL201", title: "Osteomalaci / osteolys i falangben 2 (kronben)" },
  { value: "SL202", title: "Osteoporos i falangben 2 (kronben)" },
  { value: "SL203", title: "Osteodystrofi  i falangben 2, (kronben)" },
  { value: "SL204", title: "Benmetaplasier i falangben 2 (kronben)" },
  { value: "SL21", title: "Nutritionella förändringar, falangben 2 (kronben)" },
  {
    value: "SL22",
    title: "Degenerativa/dystrofiska förändringar, falangben 2 (kronben)",
  },
  {
    value: "SL224",
    title: "Pulmonär hypertrofisk osteopati i falangben 2 (kronben)",
  },
  { value: "SL225", title: "Aseptisk bennekros i falangben 2 (kronben)" },
  { value: "SL23", title: "Hormonella förändringar, falangben 2 (kronben)" },
  { value: "SL3", title: "Cirkulatoriska förändringar, falangben 2 (kronben)" },
  { value: "SL30", title: "Blödningar, falangben 2 (kronben)" },
  { value: "SL301", title: "Subperiostal blödning i falangben 2 (kronben)" },
  { value: "SL31", title: "Ödem, falangben 2 (kronben)" },
  { value: "SL32", title: "Infarkter, falangben 2 (kronben)" },
  { value: "SL33", title: "Hygrom, falangben 2 (kronben)" },
  { value: "SL34", title: "Trombos / emboli, falangben 2 (kronben)" },
  {
    value: "SL35",
    title: "Arterio-atero-skleros förändringar, falangben 2 (kronben)",
  },
  { value: "SL4", title: "Infekt, infl förändringar, falangben 2 (kronben)" },
  {
    value: "SL40",
    title: "Specifika infektionssjukdomar, falangben 2 (kronben)",
  },
  {
    value: "SL41",
    title: "Akuta inflammationstillstånd, falangben 2 (kronben)",
  },
  {
    value: "SL411",
    title: "Akut serös / serofibrinös infl, falangben 2 (kronben)",
  },
  {
    value: "SL412",
    title: "Akut purulent inflammation, falangben 2 (kronben)",
  },
  {
    value: "SL413",
    title: "Specifika akuta infl tillstånd, falangben 2 (kronben)",
  },
  {
    value: "SL419",
    title: "Övriga akuta infl tillstånd, falangben 2 (kronben)",
  },
  { value: "SL4191", title: "Akut periostit i falangben 2 (kronben)" },
  {
    value: "SL41911",
    title: "Sekundär akut periostit i falangben 2 (kronben)",
  },
  { value: "SL4192", title: "Akut ostit i falangben 2 (kronben)" },
  { value: "SL4193", title: "Akut osteomyelit i falangben 2 (kronben)" },
  {
    value: "SL41931",
    title: "Sekundär akut osteomyelit i falangben 2 (kronben)",
  },
  {
    value: "SL41932",
    title: "Sek akut osteomyelit i falangben 2 (kronben) e kir ingrepp",
  },
  { value: "SL4198", title: "Akut fysit  i kronben" },
  {
    value: "SL42",
    title: "Kroniska inflammationstillstånd, falangben 2 (kronben)",
  },
  {
    value: "SL421",
    title: "Kronisk serös inflammation, falangben 2 (kronben)",
  },
  { value: "SL422", title: "Purulent inflammation, falangben 2 (kronben)" },
  { value: "SL423", title: "Abscess/fistel, falangben 2 (kronben)" },
  {
    value: "SL424",
    title: "Specifika kroniska infl tillstånd, falangben 2 (kronben)",
  },
  {
    value: "SL429",
    title: "Övriga kroniska infl tillstånd, falangben 2 (kronben)",
  },
  {
    value: "SL4291",
    title: "Sekundär kronisk periostit i falangben 2 (kronben)",
  },
  {
    value: "SL42911",
    title: "Kronisk periostit (överben) i falangben 2 (kronben)",
  },
  { value: "SL4292", title: "Kronisk ostit i falangben 2 (kronben)" },
  { value: "SL4293", title: "Kronisk osteomyelit i falangben 2 (kronben)" },
  {
    value: "SL42931",
    title: "Sekundär kronisk osteomyelit i falangben 2 (kronben)",
  },
  {
    value: "SL42932",
    title: "Sek kron osteomyelit i falangben 2 (kronben) e kir ingrepp",
  },
  {
    value: "SL4294",
    title: "Deformerande beninflammation i falangben 2 (kronben)",
  },
  { value: "SL4298", title: "Kronisk fysit  i kronben" },
  { value: "SL43", title: "Parasitära sjukdomar, falangben 2 (kronben)" },
  { value: "SL5", title: "Immunmedierade tillstånd, falangben 2 (kronben)" },
  { value: "SL6", title: "Neoplastiska förändringar, falangben 2 (kronben)" },
  {
    value: "SL61",
    title: "Malign primär tumör i falangben 2o/e brosk inkl led",
  },
  {
    value: "SL611",
    title: "Malign tumör i falangben 2 (kronben) utgån från broskvävnad",
  },
  { value: "SL6111", title: "Kondrosarkom i falangben 2 (kronben)" },
  { value: "SL6112", title: "Kondromyxosarkom i falangben 2 (kronben)" },
  {
    value: "SL612",
    title: "Malign tumör i falangben 2 (kronben) utgången från benvävnad",
  },
  { value: "SL6121", title: "Osteosarkom i falangben 2 (kronben)" },
  {
    value: "SL613",
    title: "Malign tumör i falangben 2 (kronben) av bindvävsursprung",
  },
  { value: "SL6131", title: "Fibrosarkom i falangben 2 (kronben)" },
  {
    value: "SL614",
    title: "Malign tumör i falangben 2(kronben) av vaskulärt/lymf urspr",
  },
  {
    value: "SL6141",
    title: "Angiosarkom,hemangiendoteliom,hemangiopericytom falangben 2",
  },
  { value: "SL615", title: "Metastas till skelett , falangben 2 (kronben)" },
  {
    value: "SL619",
    title: "Skelettumör i falangben 2 (kronben) av malign karaktär, UNS",
  },
  {
    value: "SL62",
    title: "Benign tumör i falangben 2 (kronben) o/e brosk inkl led",
  },
  {
    value: "SL621",
    title: "Benign tumör  i kronben  utgången från broskvävnad",
  },
  { value: "SL6211", title: "Kondroblastom i falangben 2 (kronben)" },
  { value: "SL6212", title: "Ostekondrom i falangben 2 (kronben)" },
  { value: "SL6213", title: "Enkondrom i falangben 2 (kronben)" },
  {
    value: "SL622",
    title: "Benign tumör i falangben 2 (kronben) utgången från benvävnad",
  },
  { value: "SL6221", title: "Osteom i falangben 2 (kronben)" },
  { value: "SL6222", title: "Osteoblastom i falangben 2 (kronben)" },
  {
    value: "SL623",
    title: "Benign tumör i falangben 2 (kronben) av bindvävsursprung",
  },
  { value: "SL6231", title: "Fibrom i falangben 2 (kronben)" },
  { value: "SL6232", title: "Fibröst histiocytom i falangben 2 (kronben)" },
  {
    value: "SL624",
    title: "Benign tumör i falangben 2 (kronben) av vaskulärt/lymf urspr",
  },
  { value: "SL6241", title: "Hemangiom  i falangben 2 (kronben)" },
  { value: "SL6242", title: "Lymfangiom i falangben 2 (kronben)" },
  {
    value: "SL628",
    title: "Övriga benigna skelettumörer i falangben 2 (kronben)",
  },
  { value: "SL6281", title: "Jättecellstumör i falangben 2 (kronben)" },
  {
    value: "SL629",
    title: "Skelettumör i falangben 2 (kronben) av benign karaktär, UNS",
  },
  {
    value: "SL7",
    title: "Trauma, fr kr, lägeförändr, term skad, falangben 2 (kronben)",
  },
  {
    value: "SL71",
    title: "Traumatiska / mekaniska skador i falangben 2 (kronben)",
  },
  { value: "SL711", title: "Fissur i falangben 2 (kronben)" },
  { value: "SL712", title: "Epifysfraktur i falangben 2 (kronben)" },
  {
    value: "SL7121",
    title: "Epifysfraktur i falangben 2 (kronben) (epifysglidning) prox",
  },
  {
    value: "SL7122",
    title: "Epifysfraktur i falangben 2 (kronben) (epifysglidning) dist",
  },
  { value: "SL7123", title: "Epifysiolys i falangben 2 (kronben)" },
  { value: "SL71231", title: "Proximal epifysiolys i falangben 2 (kronben)" },
  { value: "SL71232", title: "Distal epifysiolys i falangben 2 (kronben)" },
  { value: "SL7129", title: "Öriga epifysskador i falangben 2 (kronben)" },
  { value: "SL713", title: "Fraktur av falangben 2 (kronben)" },
  { value: "SL7131", title: "Diafysfraktur av falangben 2 (kronben)" },
  {
    value: "SL7132",
    title: "Lednära fraktur av falangben 2 (kronben) proximalt",
  },
  {
    value: "SL7133",
    title: "Lednära fraktur av falangben 2 (kronben) distalt",
  },
  {
    value: "SL7134",
    title: "Frakt av falangben 2(kronben) som berör angräns led prox",
  },
  {
    value: "SL7135",
    title: "Fraktur av falangben 2 (kronben) som berör angräns led dist",
  },
  {
    value: "SL7136",
    title: "Avsprängningsfraktur av falangben 2 (kronben) proximalt",
  },
  {
    value: "SL7137",
    title: "Avsprängningsfraktur av falangben 2 (kronben) distalt",
  },
  { value: "SL7139", title: "Kompressionsfraktur av falangben 2 (kronben)" },
  { value: "SL714", title: "Fraktur av speciell typ av falangben 2 (kronben)" },
  {
    value: "SL7141",
    title: "Kortikal subperiostal frakt, falangben 2(kronben)(greenstick",
  },
  { value: "SL7142", title: "Fraktur av osteofyt av falangben 2 (kronben)" },
  {
    value: "SL7149",
    title: "Patologisk frakt, spontanfrakt av falangben 2 (kronben) UNS",
  },
  {
    value: "SL715",
    title: "Komplikation till frakturläkning av falangben 2 (kronben)",
  },
  { value: "SL7151", title: "Pseudoartros av falangben 2 (kronben)" },
  {
    value: "SL7152",
    title: "Atrofi av benvävn,falangben 2(kronben) som kompl t fraktläkn",
  },
  { value: "SL7153", title: "Felläkt fraktur av falangben 2 (kronben)" },
  {
    value: "SL7154",
    title: "Ankylos av angräns led pga skelettskada, falangben2 (kronben",
  },
  { value: "SL719", title: "Övriga skelettskador av falangben 2 (kronben)" },
  { value: "SL72", title: "Främmande kroppar, falangben 2 (kronben)" },
  { value: "SL73", title: "Lägesförändringar, falangben 2 (kronben)" },
  { value: "SL739", title: "Övrig lägesförändring av falangben 2 (kronben)" },
  { value: "SL74", title: "Termiska skador, falangben 2 (kronben)" },
  { value: "SL741", title: "Brännskada, falangben 2 (kronben)" },
  { value: "SL742", title: "Köldskada, falangben 2 (kronben)" },
  { value: "SL8", title: "Toxiska förändringar, falangben 2 (kronben)" },
  {
    value: "SL9",
    title: "Idiopatiska ospecif multifaktoriella, falangben 2 (kronben)",
  },
  { value: "SM", title: "Falangben 3 (hovben)" },
  { value: "SM0", title: "Normalvar, symtom u fastställd orsak, falangben 3" },
  { value: "SM00", title: "Normalvariation, falangben 3" },
  { value: "SM01", title: "Symtom på sjukdom u fastställd orsak, falangben 3" },
  { value: "SM011", title: "Skelettömhet i falangben 3 (hovben)" },
  {
    value: "SM1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, falangben 3",
  },
  { value: "SM10", title: "Missbildning, falangben 3" },
  { value: "SM101", title: "Övertaliga skelettdelar i falangben 3 (hovben)" },
  { value: "SM1011", title: "Polydaktyli, falangben 3" },
  {
    value: "SM102",
    title: "Aplasi eller hypoplasi av skelettdelar i falangben 3(hovben)",
  },
  { value: "SM103", title: "Felställda skelettdelar  i falangben 3 (hovben)" },
  {
    value: "SM104",
    title: "Kondrodystrofi (akondroplasi) i falangben 3 (hovben)",
  },
  {
    value: "SM109",
    title: "Övriga skelettmissbildningar i falangben 3 (hovben)",
  },
  {
    value: "SM1093",
    title: "Dysostos i falangben 3(hovben)(def benbildn un fosterlivet)",
  },
  { value: "SM1096", title: "Missbildad falang i falangben 3 (hovben)" },
  { value: "SM10961", title: "Medfödd klubbfot" },
  {
    value: "SM1099",
    title: "Övriga ej definierade skelettmissbildningar, falangben 3",
  },
  { value: "SM11", title: "Utvecklingsrubbn, tillväxtrubbn, falangben 3" },
  { value: "SM111", title: "Samtidig ben och brosksjukdom, falangben 3" },
  { value: "SM1111", title: "Osteokondros i falangben 3 (hovben)" },
  {
    value: "SM11111",
    title: "Osteokondros i falangben 3 (hovben) utan ledmus",
  },
  { value: "SM11112", title: "Osteokondros i falangben 3 (hovben) med ledmus" },
  {
    value: "SM11113",
    title: "Subkondral bencysta i falangben 3(hovben) av osteokondrostyp",
  },
  {
    value: "SM11114",
    title: "Förtidigt / försent sluten epifys i falangben 3 (hovben)",
  },
  { value: "SM11117", title: "Slabfraktur i falangben 3 (hovben)" },
  { value: "SM1112", title: "Hörnstödsfraktur i falangben 3 (hovben)" },
  {
    value: "SM1113",
    title: "Benbroskfragm plant/palm i falangben 3 (hovben) (Birkeland)",
  },
  { value: "SM113", title: "Osteokondrodysplasi  i falangben 3 (hovben)" },
  { value: "SM115", title: "Felaktig benställning, falangben 3" },
  {
    value: "SM1151",
    title: "Felaktig benställning i falangben 3 (hovben) framben",
  },
  {
    value: "SM1152",
    title: "Felaktig benställning i falangben 3 (hovben) bakben",
  },
  {
    value: "SM119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn, falangben 3",
  },
  { value: "SM1192", title: "Bencysta, aseptisk bennekros, falangben 3" },
  {
    value: "SM2",
    title: "Metabol, nutrit, degen/dystrof förändr, falangben 3",
  },
  { value: "SM20", title: "Metaboliska förändringar i falangben 3 (hovben)" },
  { value: "SM201", title: "Osteomalaci / osteolys i falangben 3 (hovben)" },
  { value: "SM202", title: "Osteoporos i falangben 3 (hovben)" },
  { value: "SM203", title: "Osteodystrofi  i falangben 3 (hovben)" },
  { value: "SM204", title: "Benmetaplasier i falangben 3 (hovben)" },
  { value: "SM21", title: "Nutritionella förändringar i falangben 3 (hovben)" },
  {
    value: "SM22",
    title: "Degenerativa/dystrofiska förändringar i falangben 3 (hovben)",
  },
  { value: "SM225", title: "Aseptisk bennekros i falangben 3 (hovben)" },
  { value: "SM23", title: "Hormonella förändringar i falangben 3 (hovben)" },
  { value: "SM3", title: "Cirkulatoriska förändringar, falangben 3" },
  { value: "SM30", title: "Blödningar i falangben 3 (hovben)" },
  { value: "SM301", title: "Subperiostal blödning i falangben 3 (hovben)" },
  { value: "SM31", title: "Ödem, falangben 3" },
  { value: "SM32", title: "Infarkter, falangben 3" },
  { value: "SM33", title: "Hygrom, falangben 3" },
  { value: "SM34", title: "Trombos / emboli, falangben 3" },
  { value: "SM35", title: "Arterio-atero-skleros förändringar, falangben 3" },
  { value: "SM4", title: "Infekt, infl förändringar, falangben 3" },
  { value: "SM40", title: "Specifika infektionssjukdomar, falangben 3" },
  {
    value: "SM41",
    title: "Akuta inflammationstillstånd i falangben 3 (hovben)",
  },
  {
    value: "SM411",
    title: "Akut serös / serofibrinös inflammation, falangben 3",
  },
  { value: "SM412", title: "Akut purulent inflammation, falangben 3" },
  { value: "SM413", title: "Specifika akuta infl tillstånd, falangben 3" },
  { value: "SM419", title: "Övriga akuta infl tillstånd, falangben 3" },
  { value: "SM4191", title: "Akut periostit i falangben 3 (hovben)" },
  { value: "SM41911", title: "Sekundär akut periostit i falangben 3 (hovben)" },
  { value: "SM4192", title: "Akut ostit i falangben 3 (hovben)" },
  { value: "SM4193", title: "Akut osteomyelit i falangben 3 (hovben)" },
  {
    value: "SM41931",
    title: "Sekundär akut osteomyelit i falangben 3 (hovben)",
  },
  {
    value: "SM41932",
    title: "Sek akut osteomyelit i falangben 3 (hovben) e kir ingrepp",
  },
  { value: "SM4198", title: "Akut fysit  i falangben 3 (hovben)" },
  {
    value: "SM42",
    title: "Kroniska inflammationstillstånd i falangben 3 (hovben)",
  },
  { value: "SM421", title: "Kronisk serös inflammation, falangben 3" },
  { value: "SM422", title: "Purulent inflammation, falangben 3" },
  { value: "SM423", title: "Abscess/fistel, falangben 3" },
  { value: "SM424", title: "Specifika kroniska infl tillstånd, falangben 3" },
  { value: "SM429", title: "Övriga kroniska infl tillstånd, falangben 3" },
  { value: "SM4291", title: "Kronisk periostit i falangben 3 (hovben)" },
  {
    value: "SM42911",
    title: "Sekundär kronisk periostit i falangben 3 (hovben)",
  },
  { value: "SM4292", title: "Kronisk ostit i falangben 3 (hovben)" },
  { value: "SM4293", title: "Kronisk osteomyelit i falangben 3 (hovben)" },
  {
    value: "SM42931",
    title: "Sekundär kronisk osteomyelit i falangben 3 (hovben)",
  },
  {
    value: "SM42932",
    title: "Sekundär kronisk osteomyelit  i hovben e kir ingrepp",
  },
  {
    value: "SM4294",
    title: "Deformerande beninflammation i falangben 3 (hovben)",
  },
  { value: "SM4298", title: "Kronisk fysit  i falangben 3 (hovben)" },
  { value: "SM43", title: "Parasitära sjukdomar, falangben 3" },
  { value: "SM5", title: "Immunmedierade tillstånd, falangben 3" },
  { value: "SM6", title: "Neoplastiska förändringar, falangben 3" },
  {
    value: "SM61",
    title: "Malign primär tumör i falangben 3o/e brosk inkl led",
  },
  {
    value: "SM611",
    title: "Malign tumör i falangben 3 (hovben) utgången från broskvävn",
  },
  { value: "SM6111", title: "Kondrosarkom i falangben 3 (hovben)" },
  { value: "SM6112", title: "Kondromyxosarkom i falangben 3 (hovben)" },
  {
    value: "SM612",
    title: "Malign tumör i falangben 3 (hovben) utgången från benvävnad",
  },
  { value: "SM6121", title: "Osteosarkom i falangben 3 (hovben)" },
  {
    value: "SM613",
    title: "Malign tumör i falangben 3 (hovben) av bindvävsursprung",
  },
  { value: "SM6131", title: "Fibrosarkom i falangben 3 (hovben)" },
  {
    value: "SM614",
    title: "Malign tumör i falangben 3 (hovben) av vaskulärt/lymf urspr",
  },
  {
    value: "SM6141",
    title: "Angiosarkom,hemangiendoteliom,hemangiopericytom,falangben 3",
  },
  { value: "SM615", title: "Metastas till skelett  i hovben" },
  {
    value: "SM619",
    title: "Skelettumör i falangben 3 (hovben) av malign karaktär, UNS",
  },
  { value: "SM62", title: "Benign tumör i falangben 3o/e brosk inkl led" },
  {
    value: "SM621",
    title: "Benign tumör i falangben 3 (hovben) utgången från broskvävn",
  },
  { value: "SM6211", title: "Kondroblastom i falangben 3 (hovben)" },
  { value: "SM6212", title: "Ostekondrom i falangben 3 (hovben)" },
  { value: "SM6213", title: "Enkondrom i falangben 3 (hovben)" },
  {
    value: "SM622",
    title: "Benign tumör i falangben 3 (hovben) utgången från benvävnad",
  },
  { value: "SM6221", title: "Osteom  i falangben 3 (hovben)" },
  { value: "SM6222", title: "Osteoblastom i falangben 3 (hovben)" },
  {
    value: "SM623",
    title: "Benign tumör i falangben 3 (hovben) av bindvävsursprung",
  },
  { value: "SM6231", title: "Fibrom i falangben 3 (hovben)" },
  { value: "SM6232", title: "Fibröst histiocytom i falangben 3 (hovben)" },
  {
    value: "SM624",
    title: "Benign tumör  i hovbenav vaskulärt eller lymf urspr",
  },
  { value: "SM6241", title: "Hemangiom i falangben 3 (hovben)" },
  { value: "SM6242", title: "Lymfangiom i falangben 3 (hovben)" },
  {
    value: "SM628",
    title: "Övriga benigna skelettumörer i falangben 3 (hovben)",
  },
  { value: "SM6281", title: "Jättecellstumör i falangben 3 (hovben)" },
  {
    value: "SM629",
    title: "Skelettumör  i falangben 3 (hovben) av benign karaktär, UNS",
  },
  { value: "SM7", title: "Trauma, fr kr, lägeförändr, term skad, falangben 3" },
  {
    value: "SM71",
    title: "Traumatiska / mekaniska skador i falangben 3 (hovben)",
  },
  { value: "SM711", title: "Fissur i falangben 3 (hovben)" },
  { value: "SM712", title: "Epifysfraktur i falangben 3 (hovben)" },
  {
    value: "SM7121",
    title: "Epifysfraktur i falangben 3 (hovben) (epifysglidning) prox",
  },
  { value: "SM7123", title: "Epifysiolys i falangben 3 (hovben)" },
  { value: "SM71231", title: "Proximal epifysiolys i falangben 3 (hovben)" },
  { value: "SM7129", title: "Öriga epifysskador i falangben 3 (hovben)" },
  { value: "SM713", title: "Fraktur av falangben 3" },
  { value: "SM7131", title: "Diafysfraktur i falangben 3 (hovben)" },
  {
    value: "SM7132",
    title: "Lednära fraktur av falangben 3 (hovben) proximalt",
  },
  {
    value: "SM7134",
    title: "Fraktur av falangben 3 (hovben) som berör angräns led prox",
  },
  {
    value: "SM7136",
    title: "Avsprängningsfraktur av falangben 3 (hovben) proximalt",
  },
  { value: "SM7139", title: "Kompressionsfraktur av falangben 3 (hovben)" },
  { value: "SM714", title: "Fraktur av speciell typ av falangben 3 (hovben)" },
  {
    value: "SM7141",
    title: "Kortikal subperiostal frakt av falangben3(hovben)(greenstick",
  },
  { value: "SM7142", title: "Fraktur av osteofyt av falangben 3 (hovben)" },
  {
    value: "SM7149",
    title: "Patologisk fraktur, spontanfrakt av falangben 3 (hovben) UNS",
  },
  {
    value: "SM715",
    title: "Komplikation till frakturläkning av falangben 3 (hovben)",
  },
  { value: "SM7151", title: "Pseudoartros av falangben 3 (hovben)" },
  {
    value: "SM7152",
    title: "Atrofi av benvävn i falangben3(hovben), kompl t frakturläkn",
  },
  { value: "SM7153", title: "Felläkt fraktur av falangben 3 (hovben)" },
  {
    value: "SM7154",
    title: "Ankylos av angränsande led pga skelettskada, falangben 3",
  },
  { value: "SM719", title: "Övriga skelettskador i falangben 3 (hovben)" },
  { value: "SM72", title: "Främmande kroppar, falangben 3" },
  { value: "SM73", title: "Lägesförändringar, falangben 3" },
  { value: "SM735", title: "Hovbenssäkning, falangben 3" },
  { value: "SM739", title: "Övrig lägesförändring av falangben 3 (hovben)" },
  { value: "SM74", title: "Termiska skador, falangben 3" },
  { value: "SM741", title: "Brännskada, falangben 3" },
  { value: "SM742", title: "Köldskada, falangben 3" },
  { value: "SM8", title: "Toxiska förändringar, falangben 3" },
  {
    value: "SM9",
    title: "Idiopatiska ospecificerade multifaktoriella, falangben 3",
  },
  { value: "SN", title: "Strålben" },
  { value: "SN0", title: "Normalvar, symtom u fastställd orsak, strålben" },
  { value: "SN00", title: "Normalvariation i strålben" },
  { value: "SN01", title: "Symtom på sjukdom u fastställd orsak, strålben" },
  { value: "SN011", title: "Skelettömhet i strålben" },
  { value: "SN1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, strålben" },
  { value: "SN10", title: "Missbildning i strålben" },
  { value: "SN101", title: "Övertaliga skelettdelar i strålben" },
  {
    value: "SN102",
    title: "Aplasi eller hypoplasi av skelettdelar i strålben",
  },
  { value: "SN103", title: "Felställda skelettdelar i strålben" },
  { value: "SN104", title: "Kondrodystrofi i strålben (akondroplasi)" },
  { value: "SN109", title: "Övriga skelettmissbildningar i strålben" },
  {
    value: "SN1093",
    title: "Dysostos i strålben (defekt benbildning under fosterlivet)",
  },
  {
    value: "SN1099",
    title: "Övriga ej definierade skelettmissbildningar i strålben",
  },
  { value: "SN11", title: "Utvecklingsrubbn, tillväxtrubbn, strålben" },
  { value: "SN111", title: "Samtidig ben och brosksjukdom, strålben" },
  { value: "SN1111", title: "Osteokondros i strålben" },
  { value: "SN11111", title: "Osteokondros i strålben utan ledmus" },
  { value: "SN11112", title: "Osteokondros i strålben med ledmus" },
  {
    value: "SN11113",
    title: "Subkondral bencysta i strålben av osteokondrostyp",
  },
  { value: "SN113", title: "Osteokondrodysplasi  i strålben" },
  { value: "SN119", title: "Övriga Utvecklingsrubbn, tillväxtrubbn i skelett" },
  { value: "SN1192", title: "Bencysta, aseptisk bennekros, strålben" },
  { value: "SN2", title: "Metabol, nutrit, degen/dystrof förändr, strålben" },
  { value: "SN20", title: "Metaboliska förändringar, strålben" },
  { value: "SN201", title: "Osteomalaci / osteolys i strålben" },
  { value: "SN202", title: "Osteoporos i strålben" },
  { value: "SN203", title: "Osteodystrofi i strålben" },
  { value: "SN204", title: "Benmetaplasier i strålben" },
  { value: "SN21", title: "Nutritionella förändringar, strålben" },
  { value: "SN22", title: "Degenerativa/dystrofiska förändringar i strålben" },
  { value: "SN225", title: "Aseptisk bennekros i strålben" },
  { value: "SN3", title: "Cirkulatoriska förändringar, strålben" },
  { value: "SN30", title: "Blödningar, strålben" },
  { value: "SN31", title: "Ödem, strålben" },
  { value: "SN32", title: "Infarkter, strålben" },
  { value: "SN33", title: "Hygrom, strålben" },
  { value: "SN34", title: "Trombos / emboli, strålben" },
  { value: "SN35", title: "Arterio-atero-skleros förändringar, strålben" },
  { value: "SN4", title: "Infekt, infl förändringar, strålben" },
  { value: "SN40", title: "Specifika infektionssjukdomar, strålben" },
  { value: "SN41", title: "Akuta inflammationstillstånd, strålben" },
  { value: "SN411", title: "Akut serös / serofibrinös inflammation, strålben" },
  { value: "SN412", title: "Akut purulent inflammation, strålben" },
  { value: "SN413", title: "Specifika akuta infl tillstånd, strålben" },
  { value: "SN419", title: "Övriga akuta infl tillstånd, strålben" },
  { value: "SN4192", title: "Akut ostit i strålben" },
  { value: "SN41922", title: "Akut podotrokleit" },
  { value: "SN4193", title: "Akut osteomyelit i strålben" },
  { value: "SN41931", title: "Sekundär akut osteomyelit i strålben" },
  {
    value: "SN41932",
    title: "Sekundär akut osteomyelit i strålben e kir ingrepp",
  },
  { value: "SN42", title: "Kroniska inflammationstillstånd, strålben" },
  { value: "SN421", title: "Kronisk serös inflammation, strålben" },
  { value: "SN422", title: "Purulent inflammation, strålben" },
  { value: "SN423", title: "Abscess/fistel, strålben" },
  { value: "SN424", title: "Specifika kroniska infl tillstånd, strålben" },
  { value: "SN429", title: "Övriga kroniska infl tillstånd, strålben" },
  { value: "SN4292", title: "Kronisk ostit i strålben" },
  { value: "SN42922", title: "Kronisk podotrokleit" },
  { value: "SN4293", title: "Kronisk osteomyelit i strålben" },
  { value: "SN42931", title: "Sekundär kronisk osteomyelit i strålben" },
  {
    value: "SN42932",
    title: "Sekundär kronisk osteomyelit i strålben e kir ingrepp",
  },
  { value: "SN4294", title: "Deformerande beninflammation i strålben" },
  { value: "SN43", title: "Parasitära sjukdomar, strålben" },
  { value: "SN5", title: "Immunmedierade tillstånd, strålben" },
  { value: "SN6", title: "Neoplastiska förändringar, strålben" },
  { value: "SN61", title: "Malign primär tumör i strålbeno/e brosk inkl led" },
  {
    value: "SN611",
    title: "Malign tumör i strålben utgången från broskvävnad",
  },
  { value: "SN6111", title: "Kondrosarkom i strålben" },
  { value: "SN6112", title: "Kondromyxosarkom i strålben" },
  { value: "SN612", title: "Malign tumör i strålben utgången från benvävnad" },
  { value: "SN6121", title: "Osteosarkom i strålben" },
  { value: "SN613", title: "Malign tumör i strålben av bindvävsursprung" },
  { value: "SN6131", title: "Fibrosarkom i strålben" },
  {
    value: "SN614",
    title: "Malign tumör  i strålbenav vaskulärt eller lymf urspr",
  },
  {
    value: "SN6141",
    title: "Angiosarkom, hemangiendoteliom, hemangiopericytom i strålben",
  },
  { value: "SN615", title: "Metastas i strålben till skelett , strålben" },
  { value: "SN619", title: "Skelettumör av malign karaktär i strålben, UNS" },
  { value: "SN62", title: "Benign tumör i strålbeno/e brosk inkl led" },
  {
    value: "SN621",
    title: "Benign tumör i strålben utgången från broskvävnad",
  },
  { value: "SN6211", title: "Kondroblastom i strålben" },
  { value: "SN6212", title: "Ostekondrom i strålben" },
  { value: "SN6213", title: "Enkondrom i strålben" },
  { value: "SN622", title: "Benign tumör i strålben utgången från benvävnad" },
  { value: "SN6221", title: "Osteom i strålben" },
  { value: "SN6222", title: "Osteoblastom i strålben" },
  { value: "SN623", title: "Benign tumör i strålben av bindvävsursprung" },
  { value: "SN6231", title: "Fibrom i strålben" },
  { value: "SN6232", title: "Fibröst histiocytom i strålben" },
  {
    value: "SN624",
    title: "Benign tumör i strålben av vaskulärt eller lymf urspr",
  },
  { value: "SN6241", title: "Hemangiom i strålben" },
  { value: "SN6242", title: "Lymfangiom i strålben" },
  { value: "SN628", title: "Övriga benigna skelettumörer i strålben" },
  { value: "SN6281", title: "Jättecellstumör i strålben" },
  { value: "SN629", title: "Skelettumör av benign karaktär i strålben, UNS" },
  { value: "SN7", title: "Trauma, fr kr, lägeförändr, term skad, strålben" },
  { value: "SN71", title: "Traumatiska / mekaniska skador, strålben" },
  { value: "SN711", title: "Fissur i strålben, strålben" },
  { value: "SN713", title: "Fraktur av strålben, strålben" },
  { value: "SN714", title: "Fraktur av speciell typ av strålben" },
  {
    value: "SN7149",
    title: "Patologisk fraktur, spontanfraktur av strålben UNS",
  },
  { value: "SN715", title: "Komplikation till frakturläkning av strålben" },
  { value: "SN7151", title: "Pseudoartros av strålben" },
  {
    value: "SN7152",
    title: "Atrofi av benvävnad i strålben som kompl t frakturläkn",
  },
  { value: "SN7153", title: "Felläkt fraktur av strålben" },
  { value: "SN719", title: "Övriga skelettskador av strålben" },
  { value: "SN72", title: "Främmande kroppar, strålben" },
  { value: "SN73", title: "Lägesförändringar, strålben" },
  { value: "SN739", title: "Övrig lägesförändring av strålben" },
  { value: "SN74", title: "Termiska skador, strålben" },
  { value: "SN741", title: "Brännskada, strålben" },
  { value: "SN742", title: "Köldskada, strålben" },
  { value: "SN8", title: "Toxiska förändringar, strålben" },
  {
    value: "SN9",
    title: "Idiopatiska ospecificerade multifaktoriella, strålben",
  },
  { value: "SO", title: "Bäcken" },
  { value: "SO0", title: "Normalvar, symtom u fastställd orsak, bäcken" },
  { value: "SO00", title: "Normalvariation av bäcken" },
  { value: "SO01", title: "Symtom på sjukdom u fastställd orsak, bäcken" },
  { value: "SO011", title: "Skelettömhet i bäcken" },
  { value: "SO1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, bäcken" },
  { value: "SO10", title: "Missbildning i bäcken" },
  { value: "SO102", title: "Aplasi eller hypoplasi av skelettdelar i bäcken" },
  { value: "SO103", title: "Felställda skelettdelar  i bäcken" },
  { value: "SO104", title: "Kondrodystrofi (akondroplasi) i bäcken" },
  { value: "SO109", title: "Övriga skelettmissbildningar i bäcken" },
  { value: "SO1097", title: "Missbildat bäcken" },
  { value: "SO10971", title: "Asymetri av bäckenhalvor" },
  {
    value: "SO1099",
    title: "Övriga ej definierade skelettmissbildningar i bäcken",
  },
  { value: "SO11", title: "Utvecklingsrubbn, tillväxtrubbn, bäcken" },
  { value: "SO111", title: "Samtidig ben och brosksjukdom, bäcken" },
  { value: "SO113", title: "Osteokondrodysplasi  i bäcken" },
  { value: "SO119", title: "Övriga Utvecklingsrubbn, tillväxtrubbn i bäcken" },
  { value: "SO2", title: "Metabol, nutrit, degen/dystrof förändr, bäcken" },
  { value: "SO20", title: "Metaboliska förändringar, bäcken" },
  { value: "SO201", title: "Osteomalaci / osteolys i bäcken" },
  { value: "SO202", title: "Osteoporos i bäcken" },
  { value: "SO203", title: "Osteodystrofi  i bäcken" },
  { value: "SO204", title: "Benmetaplasier i bäcken" },
  { value: "SO21", title: "Nutritionella förändringar, bäcken" },
  { value: "SO22", title: "Degenerativa/dystrofiska förändringar, bäcken" },
  { value: "SO225", title: "Aseptisk bennekros i bäcken" },
  { value: "SO23", title: "Hormonella förändringar, bäcken" },
  { value: "SO3", title: "Cirkulatoriska förändringar, bäcken" },
  { value: "SO30", title: "Blödningar, bäcken" },
  { value: "SO301", title: "Subperiostal blödning i bäcken" },
  { value: "SO31", title: "Ödem, bäcken" },
  { value: "SO32", title: "Infarkter, bäcken" },
  { value: "SO33", title: "Hygrom, bäcken" },
  { value: "SO34", title: "Trombos / emboli, bäcken" },
  { value: "SO35", title: "Arterio-atero-skleros förändringar, bäcken" },
  { value: "SO4", title: "Infekt, infl förändringar, bäcken" },
  { value: "SO40", title: "Specifika infektionssjukdomar, bäcken" },
  { value: "SO41", title: "Akuta inflammationstillstånd, bäcken" },
  { value: "SO411", title: "Akut serös / serofibrinös inflammation, bäcken" },
  { value: "SO412", title: "Akut purulent inflammation, bäcken" },
  { value: "SO413", title: "Specifika akuta infl tillstånd, bäcken" },
  { value: "SO419", title: "Övriga akuta infl tillstånd, bäcken" },
  { value: "SO4191", title: "Akut periostit i bäcken" },
  { value: "SO41911", title: "Sekundär akut periostit i bäcken" },
  { value: "SO4192", title: "Akut ostit i bäcken" },
  { value: "SO4193", title: "Akut osteomyelit i bäcken" },
  { value: "SO41931", title: "Sekundär akut osteomyelit i bäcken" },
  {
    value: "SO41932",
    title: "Sekundär akut osteomyelit i bäcken e kir ingrepp",
  },
  { value: "SO42", title: "Kroniska inflammationstillstånd i bäcken" },
  { value: "SO421", title: "Kronisk serös inflammation, bäcken" },
  { value: "SO422", title: "Purulent inflammation, bäcken" },
  { value: "SO423", title: "Abscess/fistel, bäcken" },
  { value: "SO424", title: "Specifika kroniska infl tillstånd, bäcken" },
  { value: "SO429", title: "Övriga kroniska infl tillstånd i bäcken" },
  { value: "SO4291", title: "Kronisk periostit  i bäcken" },
  { value: "SO42911", title: "Sekundär kronisk periostit i bäcken" },
  { value: "SO4292", title: "Kronisk ostit i bäcken" },
  { value: "SO4293", title: "Kronisk osteomyelit i bäcken" },
  { value: "SO42931", title: "Sekundär kronisk osteomyelit i bäcken" },
  {
    value: "SO42932",
    title: "Sekundär kronisk osteomyelit i bäcken e kir ingrepp",
  },
  { value: "SO4294", title: "Deformerande beninflammation i bäcken" },
  { value: "SO43", title: "Parasitära sjukdomar, bäcken" },
  { value: "SO5", title: "Immunmedierade tillstånd, bäcken" },
  { value: "SO6", title: "Neoplastiska förändringar, bäcken" },
  { value: "SO61", title: "Malign primär tumör i bäckeno/e brosk inkl led" },
  { value: "SO611", title: "Malign tumör i bäcken utgången från broskvävnad" },
  { value: "SO6111", title: "Kondrosarkom i bäcken" },
  { value: "SO6112", title: "Kondromyxosarkom i bäcken" },
  { value: "SO612", title: "Malign tumör i bäcken utgången från benvävnad" },
  { value: "SO6121", title: "Osteosarkom i bäcken" },
  { value: "SO613", title: "Malign tumör i bäcken av bindvävsursprung" },
  { value: "SO6131", title: "Fibrosarkom i bäcken" },
  {
    value: "SO614",
    title: "Malign tumör i bäcken av vaskulärt eller lymf urspr",
  },
  {
    value: "SO6141",
    title: "Angiosarkom, hemangiendoteliom, hemangiopericytom i bäcken",
  },
  { value: "SO615", title: "Metastas i bäcken till skelett" },
  { value: "SO619", title: "Skelettumör i bäcken av malign karaktär, UNS" },
  { value: "SO62", title: "Benign tumör i bäckeno/e brosk inkl led" },
  { value: "SO621", title: "Benign tumör i bäcken utgången från broskvävnad" },
  { value: "SO6211", title: "Kondroblastom i bäcken" },
  { value: "SO6212", title: "Ostekondrom i bäcken" },
  { value: "SO6213", title: "Enkondrom i bäcken" },
  { value: "SO622", title: "Benign tumör i bäcken utgången från benvävnad" },
  { value: "SO6221", title: "Osteom  i bäcken" },
  { value: "SO6222", title: "Osteoblastom i bäcken" },
  { value: "SO623", title: "Benign tumör i bäcken av bindvävsursprung" },
  { value: "SO6231", title: "Fibrom i bäcken" },
  { value: "SO6232", title: "Fibröst histiocytom i bäcken" },
  {
    value: "SO624",
    title: "Benign tumör  i bäckenav vaskulärt eller lymf urspr",
  },
  { value: "SO6241", title: "Hemangiom i bäcken" },
  { value: "SO6242", title: "Lymfangiom i bäcken" },
  { value: "SO628", title: "Övriga benigna skelettumörer i bäcken" },
  { value: "SO6281", title: "Jättecellstumör i bäcken" },
  { value: "SO629", title: "Skelettumör  i bäcken av benign karaktär, UNS" },
  { value: "SO7", title: "Trauma, fr kr, lägeförändr, term skad, bäcken" },
  { value: "SO71", title: "Traumatiska / mekaniska skador i bäcken" },
  { value: "SO711", title: "Fissur i bäcken" },
  { value: "SO713", title: "Fraktur i bäcken" },
  { value: "SO7132", title: "Lednära fraktur  i bäcken kranialt" },
  { value: "SO7133", title: "Lednära fraktur  i bäcken kaudalt" },
  {
    value: "SO7134",
    title: "Fraktur  i bäcken som berör angränsande led/leder /kranialt",
  },
  {
    value: "SO7135",
    title: "Fraktur i bäcken  som berör angränsande led/leder /kaudalt",
  },
  {
    value: "SO7136",
    title: "Avsprängningsfraktur i bäcken  proximalt /kranialt",
  },
  { value: "SO7137", title: "Avsprängningsfraktur i bäcken  distalt /kaudalt" },
  { value: "SO7139", title: "Kompressionsfraktur i bäcken" },
  { value: "SO714", title: "Fraktur i bäckenav speciell typ" },
  { value: "SO7147", title: "Acetabulumfraktur" },
  { value: "SO7149", title: "Patologisk fraktur, spontanfraktur i bäcken UNS" },
  { value: "SO715", title: "Komplikation till frakturläkning, bäcken" },
  { value: "SO7151", title: "Pseudoartros i bäcken" },
  {
    value: "SO7152",
    title: "Atrofi av benvävnad i bäcken  som kompl t frakturläkn",
  },
  { value: "SO7153", title: "Felläkt fraktur i bäcken" },
  {
    value: "SO7154",
    title: "Ankylos av angränsande led pga skelettskada, bäcken",
  },
  { value: "SO719", title: "Övriga skelettskador i bäcken" },
  { value: "SO72", title: "Främmande kroppar, bäcken" },
  { value: "SO73", title: "Lägesförändringar, bäcken" },
  { value: "SO739", title: "Övrig lägesförändring, bäcken" },
  { value: "SO74", title: "Termiska skador, bäcken" },
  { value: "SO741", title: "Brännskada, bäcken" },
  { value: "SO742", title: "Köldskada, bäcken" },
  { value: "SO8", title: "Toxiska förändringar, bäcken" },
  {
    value: "SO9",
    title: "Idiopatiska ospecificerade multifaktoriella, bäcken",
  },
  { value: "SO91", title: "Avlösning av tuber ishii (svin)" },
  { value: "SO92", title: "Avlösning av tuber coxae, nöt" },
  { value: "SP", title: "Femur" },
  { value: "SP0", title: "Normalvar, symtom u fastställd orsak, femur" },
  { value: "SP00", title: "Normalvariation, femur" },
  { value: "SP01", title: "Symtom på sjukdom u fastställd orsak" },
  { value: "SP011", title: "Skelettömhet i femur" },
  { value: "SP1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, femur" },
  { value: "SP10", title: "Missbildning i femur" },
  { value: "SP103", title: "Felställda skelettdelar i femur" },
  { value: "SP104", title: "Kondrodystrofi (akondroplasi) i femur" },
  { value: "SP109", title: "Övriga skelettmissbildningar i femur" },
  {
    value: "SP1093",
    title: "Dysostos i femur (defekt benbildning under fosterlivet)",
  },
  {
    value: "SP1099",
    title: "Övriga ej definierade skelettmissbildningar i femur",
  },
  { value: "SP11", title: "Utvecklingsrubbn, tillväxtrubbn, femur" },
  { value: "SP111", title: "Samtidig ben och brosksjukdom, femur" },
  { value: "SP1111", title: "Osteokondros i femur" },
  { value: "SP11111", title: "Osteokondros i femur utan ledmus" },
  { value: "SP11112", title: "Osteokondros i femur med ledmus" },
  { value: "SP11113", title: "Subkondral bencysta i femur av osteokondrostyp" },
  { value: "SP11114", title: "Förtidigt / försent sluten epifys i femur" },
  { value: "SP11117", title: "Slabfraktur i femur" },
  { value: "SP1112", title: "Hörnstödsfraktur i femur" },
  { value: "SP1113", title: "Benbroskfragm plant/palm i femur (Birkeland)" },
  { value: "SP115", title: "Felaktig benställning i femur" },
  { value: "SP1152", title: "Felaktig benställning bakben, femur" },
  { value: "SP11521", title: "Coxa valgum (utåtvriden sned)" },
  { value: "SP11522", title: "Coxa varaum   (sned)" },
  { value: "SP11523", title: "Genu valgum (kobent)" },
  { value: "SP11524", title: "Genu varum (hjulbent)" },
  { value: "SP1153", title: "Olika benlängd i femur" },
  {
    value: "SP119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i skelett, femur",
  },
  { value: "SP1191", title: "Panostit (endostsos) i femur" },
  { value: "SP1192", title: "Bencysta, aseptisk bennekros i femur" },
  { value: "SP1195", title: "Legg-Perthes sjukdom" },
  { value: "SP2", title: "Metabol, nutrit, degen/dystrof förändr, femur" },
  { value: "SP20", title: "Metaboliska förändringar, femur" },
  { value: "SP201", title: "Osteomalaci / osteolys i femur" },
  { value: "SP202", title: "Osteoporos i  i femur" },
  { value: "SP203", title: "Osteodystrofi , femur" },
  { value: "SP204", title: "Benmetaplasier i femur" },
  { value: "SP21", title: "Nutritionella förändringar, femur" },
  { value: "SP22", title: "Degenerativa/dystrofiska förändringar i femur" },
  { value: "SP225", title: "Aseptisk bennekros i femur" },
  { value: "SP23", title: "Hormonella förändringar, femur" },
  { value: "SP3", title: "Cirkulatoriska förändringar, femur" },
  { value: "SP30", title: "Blödningar, femur" },
  { value: "SP301", title: "Subperiostal blödning i femur" },
  { value: "SP31", title: "Ödem, femur" },
  { value: "SP32", title: "Infarkter, femur" },
  { value: "SP33", title: "Hygrom, femur" },
  { value: "SP34", title: "Trombos / emboli, femur" },
  { value: "SP35", title: "Arterio-atero-skleros förändringar, femur" },
  { value: "SP4", title: "Infekt, infl förändringar, femur" },
  { value: "SP40", title: "Specifika infektionssjukdomar, femur" },
  { value: "SP41", title: "Akuta inflammationstillstånd, femur" },
  { value: "SP411", title: "Akut serös / serofibrinös inflammation, femur" },
  { value: "SP412", title: "Akut purulent inflammation, femur" },
  { value: "SP413", title: "Specifika akuta infl tillstånd i femur" },
  { value: "SP419", title: "Övriga akuta infl tillstånd i femur" },
  { value: "SP4191", title: "Akut periostit i femur" },
  { value: "SP41911", title: "Sekundär akut periostit i femur" },
  { value: "SP4192", title: "Akut ostit i femur" },
  { value: "SP4193", title: "Akut osteomyelit i femur" },
  { value: "SP41931", title: "Sekundär akut osteomyelit i femur" },
  {
    value: "SP41932",
    title: "Sekundär akut osteomyelit i femur e kir ingrepp",
  },
  { value: "SP4198", title: "Akut fysit i femur" },
  { value: "SP42", title: "Kroniska inflammationstillstånd, femur" },
  { value: "SP421", title: "Kronisk serös inflammation, femur" },
  { value: "SP422", title: "Purulent inflammation, femur" },
  { value: "SP423", title: "Abscess/fistel, femur" },
  { value: "SP424", title: "Specifika kroniska infl tillstånd, femur" },
  { value: "SP429", title: "Övriga kroniska infl tillstånd, femur" },
  { value: "SP4291", title: "Kronisk periostit i femur" },
  { value: "SP42911", title: "Sekundär kronisk periostit i femur" },
  { value: "SP4292", title: "Kronisk ostit i femur" },
  { value: "SP4293", title: "Kronisk osteomyelit i femur" },
  { value: "SP42931", title: "Sekundär kronisk osteomyelit i femur" },
  {
    value: "SP42932",
    title: "Sekundär kronisk osteomyelit i femur e kir ingrepp",
  },
  { value: "SP4294", title: "Deformerande beninflammation i femur" },
  { value: "SP4298", title: "Kronisk fysit i femur" },
  { value: "SP43", title: "Parasitära sjukdomar, femur" },
  { value: "SP5", title: "Immunmedierade tillstånd, femur" },
  { value: "SP6", title: "Neoplastiska förändringar, femur" },
  { value: "SP61", title: "Malign primär tumör i femu ro/e brosk inkl led" },
  { value: "SP611", title: "Malign tumör i femur utgången från broskvävnad" },
  { value: "SP6111", title: "Kondrosarkom i fem i femurur" },
  { value: "SP6112", title: "Kondromyxosarkom, femur" },
  { value: "SP612", title: "Malign tumör i femur utgången från benvävnad" },
  { value: "SP6121", title: "Osteosarkom i femur" },
  { value: "SP613", title: "Malign tumör i femur av bindvävsursprung" },
  { value: "SP6131", title: "Fibrosarkom i femur" },
  {
    value: "SP614",
    title: "Malign tumör i femur av vaskulärt eller lymf urspr",
  },
  {
    value: "SP6141",
    title: "Angiosarkom, hemangiendoteliom, hemangiopericytom i femur",
  },
  { value: "SP615", title: "Metastas i femur till skelett" },
  { value: "SP619", title: "Skelettumör i femur av malign karaktär, UNS" },
  { value: "SP62", title: "Benign tumör i femur o/e brosk inkl led" },
  { value: "SP621", title: "Benign tumör i femur utgången från broskvävnad" },
  { value: "SP6211", title: "Kondroblastom i femur" },
  { value: "SP6212", title: "Ostekondrom i femur" },
  { value: "SP6213", title: "Enkondrom i femur" },
  { value: "SP622", title: "Benign tumör i femur utgången från benvävnad" },
  { value: "SP6221", title: "Osteom i femur" },
  { value: "SP6222", title: "Osteoblastom i femur" },
  { value: "SP623", title: "Benign tumör i femur av bindvävsursprung" },
  { value: "SP6231", title: "Fibrom i femur" },
  { value: "SP6232", title: "Fibröst histiocytom i femur" },
  {
    value: "SP624",
    title: "Benign tumör i femur av vaskulärt eller lymf urspr",
  },
  { value: "SP6241", title: "Hemangiom i femur" },
  { value: "SP6242", title: "Lymfangiom i femur" },
  { value: "SP628", title: "Övriga benigna skelettumörer i femur" },
  { value: "SP6281", title: "Jättecellstumör i femur" },
  { value: "SP629", title: "Skelettumör i femur av benign karaktär, UNS" },
  { value: "SP7", title: "Trauma, fr kr, lägeförändr, term skad, femur" },
  { value: "SP71", title: "Traumatiska / mekaniska skador, femur" },
  { value: "SP711", title: "Fissur i femur" },
  { value: "SP712", title: "Epifysfraktur i femur" },
  {
    value: "SP7121",
    title: "Epifysfraktur i femur (epifysglidning) proximalt",
  },
  { value: "SP7122", title: "Epifysfraktur i femur (epifysglidning) distalt" },
  { value: "SP7123", title: "Epifysiolys i femur" },
  { value: "SP71231", title: "Proximal epifysiolys i femur" },
  { value: "SP71232", title: "Distal epifysiolys i femur" },
  { value: "SP7129", title: "Öriga epifysskador i femur" },
  { value: "SP713", title: "Fraktur i femur" },
  { value: "SP7131", title: "Diafysfraktur i femur" },
  { value: "SP7132", title: "Lednära fraktur av femur proximalt" },
  { value: "SP7133", title: "Lednära fraktur av femur distalt" },
  {
    value: "SP7134",
    title: "Fraktur av femur som berör angränsande led proximalt",
  },
  {
    value: "SP7135",
    title: "Fraktur av femur som berör angränsande led distalt",
  },
  { value: "SP7136", title: "Avsprängningsfraktur av femur proximalt" },
  { value: "SP7137", title: "Avsprängningsfraktur av femur distalt" },
  { value: "SP7139", title: "Kompressionsfraktur av femur" },
  { value: "SP714", title: "Fraktur av speciell typ av femur" },
  {
    value: "SP7141",
    title: "Kortikal subperiostal fraktur av femur (greenstick)",
  },
  { value: "SP7142", title: "Fraktur av osteofyt av femur" },
  { value: "SP7149", title: "Patologisk fraktur, spontanfraktur av femur UNS" },
  { value: "SP715", title: "Komplikation till frakturläkning av femur" },
  { value: "SP7151", title: "Pseudoartros av femur" },
  {
    value: "SP7152",
    title: "Atrofi av benvävnad i femur som kompl t frakturläkn",
  },
  { value: "SP7153", title: "Felläkt fraktur av femur" },
  {
    value: "SP7154",
    title: "Ankylos av angränsande led pga skelettskada, femur",
  },
  { value: "SP719", title: "Övriga skelettskador, femur" },
  { value: "SP72", title: "Främmande kroppar, femur" },
  { value: "SP73", title: "Lägesförändringar, femur" },
  { value: "SP739", title: "Övrig lägesförändring av femur" },
  { value: "SP74", title: "Termiska skador, femur" },
  { value: "SP741", title: "Brännskada, femur" },
  { value: "SP742", title: "Köldskada, femur" },
  { value: "SP8", title: "Toxiska förändringar, femur" },
  { value: "SP9", title: "Idiopatiska ospecificerade multifaktoriella, femur" },
  { value: "SQ", title: "Patella, fabella" },
  {
    value: "SQ0",
    title: "Normalvar, symtom u fastställd orsak, patella/fabella",
  },
  { value: "SQ00", title: "Normalvariation av patella/fabella" },
  {
    value: "SQ01",
    title: "Symtom på sjukdom u fastställd orsak, patella/fabella",
  },
  { value: "SQ011", title: "Skelettömhet i patella/fabella" },
  {
    value: "SQ1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, patella/fabella",
  },
  { value: "SQ10", title: "Missbildning, patella/fabella" },
  { value: "SQ101", title: "Övertaliga skelettdelar i patella/fabella" },
  {
    value: "SQ102",
    title: "Aplasi eller hypoplasi av skelettdelar i patella/fabella",
  },
  { value: "SQ103", title: "Felställda skelettdelar i patella/fabella" },
  { value: "SQ1033", title: "Medial patellaluxation" },
  { value: "SQ1034", title: "Lateral patellaluxation" },
  { value: "SQ1035", title: "Patellaupphakning" },
  { value: "SQ104", title: "Kondrodystrofi (akondroplasi) i patella/fabella" },
  { value: "SQ109", title: "Övriga skelettmissbildningar i patella/fabella" },
  {
    value: "SQ1099",
    title: "Övriga ej def skelettmissbildningar i patella/fabella",
  },
  { value: "SQ11", title: "Utvecklingsrubbn, tillväxtrubbn i patella/fabella" },
  { value: "SQ111", title: "Samtidig ben och brosksjukdom, patella/fabella" },
  { value: "SQ1111", title: "Osteokondros i patella/fabella" },
  { value: "SQ11111", title: "Osteokondros i patella/fabella utan ledmus" },
  { value: "SQ11112", title: "Osteokondros i patella/fabellamed ledmus" },
  {
    value: "SQ11113",
    title: "Subkondral bencysta i patella/fabella av osteokondrostyp",
  },
  { value: "SQ113", title: "Osteokondrodysplasi i patella/fabella" },
  {
    value: "SQ119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i patella/fabella",
  },
  { value: "SQ1192", title: "Bencysta, aseptisk bennekros i patella/fabella" },
  {
    value: "SQ2",
    title: "Metabol, nutrit, degen/dystrof förändr, patella/fabella",
  },
  { value: "SQ20", title: "Metaboliska förändringar, patella/fabella" },
  { value: "SQ201", title: "Osteomalaci / osteolys i patella/fabella" },
  { value: "SQ202", title: "Osteoporos i patella/fabella" },
  { value: "SQ203", title: "Osteodystrofi i patella/fabella" },
  { value: "SQ204", title: "Benmetaplasier i patella/fabella" },
  { value: "SQ21", title: "Nutritionella förändringar, patella/fabella" },
  {
    value: "SQ22",
    title: "Degenerativa/dystrofiska förändringar i patella/fabella",
  },
  { value: "SQ225", title: "Aseptisk bennekros i patella/fabella" },
  { value: "SQ23", title: "Hormonella förändringar, patella/fabella" },
  { value: "SQ3", title: "Cirkulatoriska förändringar, patella/fabella" },
  { value: "SQ30", title: "Blödningar, patella/fabella" },
  { value: "SQ301", title: "Subperiostal blödning i patella/fabella" },
  { value: "SQ31", title: "Ödem, patella/fabella" },
  { value: "SQ32", title: "Infarkter, patella/fabella" },
  { value: "SQ33", title: "Hygrom, patella/fabella" },
  { value: "SQ34", title: "Trombos / emboli, patella/fabella" },
  {
    value: "SQ35",
    title: "Arterio-atero-skleros förändringar, patella/fabella",
  },
  { value: "SQ4", title: "Infekt, infl förändringar, patella/fabella" },
  { value: "SQ40", title: "Specifika infektionssjukdomar, patella/fabella" },
  { value: "SQ41", title: "Akuta inflammationstillstånd, patella/fabella" },
  {
    value: "SQ411",
    title: "Akut serös / serofibrinös inflammation, patella/fabella",
  },
  { value: "SQ412", title: "Akut purulent inflammation, patella/fabella" },
  { value: "SQ413", title: "Specifika akuta infl tillstånd i patella/fabella" },
  { value: "SQ419", title: "Övriga akuta infl tillstånd, patella/fabella" },
  { value: "SQ4191", title: "Akut periostit i patella/fabella" },
  { value: "SQ41911", title: "Sekundär akut periostit i patella/fabella" },
  { value: "SQ4192", title: "Akut ostit i patella/fabella" },
  { value: "SQ4193", title: "Akut osteomyelit i patella/fabella" },
  { value: "SQ41931", title: "Sekundär akut osteomyelit i patella/fabella" },
  {
    value: "SQ41932",
    title: "Sekundär akut osteomyelit i patella/fabella e kir ingrepp",
  },
  { value: "SQ42", title: "Kroniska inflammationstillstånd i patella/fabella" },
  { value: "SQ421", title: "Kronisk serös inflammation, patella/fabella" },
  { value: "SQ422", title: "Purulent inflammation, patella/fabella" },
  { value: "SQ423", title: "Abscess/fistel, patella/fabella" },
  {
    value: "SQ424",
    title: "Specifika kroniska infl tillstånd i patella/fabella",
  },
  { value: "SQ429", title: "Övriga kroniska infl tillstånd i patella/fabella" },
  { value: "SQ4291", title: "Kronisk periostit  i patella/fabella" },
  { value: "SQ42911", title: "Sekundär kronisk periostit i patella/fabella" },
  { value: "SQ4292", title: "Kronisk ostit i patella/fabella" },
  { value: "SQ4293", title: "Kronisk osteomyelit i patella/fabella" },
  { value: "SQ42931", title: "Sekundär kronisk osteomyelit i patella/fabella" },
  {
    value: "SQ42932",
    title: "Sekundär kronisk osteomyelit i patella/fabella e kir ingrepp",
  },
  { value: "SQ4294", title: "Deformerande beninflammation i patella/fabella" },
  { value: "SQ43", title: "Parasitära sjukdomar, patella/fabella" },
  { value: "SQ5", title: "Immunmedierade tillstånd, patella/fabella" },
  { value: "SQ6", title: "Neoplastiska förändringar, patella/fabella" },
  {
    value: "SQ61",
    title: "Malign primär tumör i patella/fabellao/e brosk inkl led",
  },
  {
    value: "SQ611",
    title: "Malign tumör i patella/fabella utgången från broskvävnad",
  },
  { value: "SQ6111", title: "Kondrosarkom i patella/fabella" },
  { value: "SQ6112", title: "Kondromyxosarkom i patella/fabella" },
  {
    value: "SQ612",
    title: "Malign tumör i patella/fabella utgången från benvävnad",
  },
  { value: "SQ6121", title: "Osteosarkom i patella/fabella" },
  {
    value: "SQ613",
    title: "Malign tumör i patella/fabella av bindvävsursprung",
  },
  { value: "SQ6131", title: "Fibrosarkom i patella/fabella" },
  {
    value: "SQ614",
    title: "Malign tumör i patella/fabella av vaskulärt eller lymf urspr",
  },
  {
    value: "SQ6141",
    title: "Angiosarkom,hemangiendoteliom,hemangiopericytom,patella/fab",
  },
  { value: "SQ615", title: "Metastas i patella/fabella till skelett" },
  {
    value: "SQ619",
    title: "Skelettumör i patella/fabella av malign karaktär, UNS",
  },
  { value: "SQ62", title: "Benign tumör i patella/fabellao/e brosk inkl led" },
  {
    value: "SQ621",
    title: "Benign tumör i patella/fabella utgången från broskvävnad",
  },
  { value: "SQ6211", title: "Kondroblastom i patella/fabella" },
  { value: "SQ6212", title: "Ostekondrom i patella/fabella" },
  { value: "SQ6213", title: "Enkondrom i patella/fabella" },
  {
    value: "SQ622",
    title: "Benign tumör i patella/fabella utgången från benvävnad",
  },
  { value: "SQ6221", title: "Osteom  i patella/fabella" },
  { value: "SQ6222", title: "Osteoblastom i patella/fabella" },
  {
    value: "SQ623",
    title: "Benign tumör i patella/fabella av bindvävsursprung",
  },
  { value: "SQ6231", title: "Fibrom i patella/fabella" },
  { value: "SQ6232", title: "Fibröst histiocytom i patella/fabella" },
  {
    value: "SQ624",
    title: "Benign tumör i patella/fabella av vaskulärt eller lymf urspr",
  },
  { value: "SQ6241", title: "Hemangiom i patella/fabella" },
  { value: "SQ6242", title: "Lymfangiom i patella/fabella" },
  { value: "SQ628", title: "Övriga benigna skelettumörer i patella/fabella" },
  { value: "SQ6281", title: "Jättecellstumör i patella/fabella" },
  {
    value: "SQ629",
    title: "Skelettumör av benign karaktär i patella/fabella,UNS",
  },
  {
    value: "SQ7",
    title: "Trauma, fr kr, lägeförändr, term skad, patella/fabella",
  },
  { value: "SQ71", title: "Traumatiska / mekaniska skador i patella/fabella" },
  { value: "SQ711", title: "Fissur i patella/fabella" },
  { value: "SQ713", title: "Fraktur av patella/fabella" },
  { value: "SQ7136", title: "Chipfraktur av patella proximalt" },
  { value: "SQ7137", title: "Chipfraktur av patella distalt" },
  { value: "SQ714", title: "Fraktur av patella/fabella av speciell typ" },
  {
    value: "SQ7149",
    title: "Patologisk fraktur, spontanfraktur av patella/fabella UNS",
  },
  {
    value: "SQ715",
    title: "Komplikation till frakturläkning av patella/fabella",
  },
  {
    value: "SQ7152",
    title: "Atrofi av benvävn i patella/fabella som kompl t frakturläkn",
  },
  { value: "SQ7153", title: "Felläkt fraktur, patella/fabella" },
  {
    value: "SQ7154",
    title: "Ankylos av angränsande led pga skelettskada, patella/fabella",
  },
  { value: "SQ719", title: "Övriga skelettskador i patella/fabella" },
  { value: "SQ72", title: "Främmande kroppar, patella/fabella" },
  { value: "SQ73", title: "Lägesförändringar, patella/fabella" },
  { value: "SQ739", title: "Övrig lägesförändring av patella/fabella" },
  { value: "SQ74", title: "Termiska skador, patella/fabella" },
  { value: "SQ741", title: "Brännskada, patella/fabella" },
  { value: "SQ742", title: "Köldskada, patella/fabella" },
  { value: "SQ8", title: "Toxiska förändringar, patella/fabella" },
  {
    value: "SQ9",
    title: "Idiopatiska ospecificerade multifaktoriella, patella/fabella",
  },
  { value: "SR", title: "Tibia, fibula" },
  { value: "SR0", title: "Normalvar, symtom u fastställd orsak, tibia/fibula" },
  { value: "SR00", title: "Normalvariation i tibia/fibula" },
  {
    value: "SR01",
    title: "Symtom på sjukdom u fastställd orsak, tibia/fibula",
  },
  { value: "SR011", title: "Skelettömhet i tibia/fibula" },
  {
    value: "SR1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, tibia/fibula",
  },
  { value: "SR10", title: "Missbildning i tibia/fibula" },
  { value: "SR101", title: "Övertaliga skelettdelar i tibia/fibula" },
  {
    value: "SR102",
    title: "Aplasi eller hypoplasi av skelettdelar i tibia/fibula",
  },
  { value: "SR103", title: "Felställda skelettdelar  i tibia/fibula" },
  { value: "SR104", title: "Kondrodystrofi (akondroplasi) i tibia/fibula" },
  { value: "SR109", title: "Övriga skelettmissbildningar i tibia/fibula" },
  {
    value: "SR1099",
    title: "Övriga ej definierade skelettmissbildningar i tibia/fibula",
  },
  {
    value: "SR11",
    title: "Utvecklingsrubbningar tillväxtrubbningar i tibia/fibula",
  },
  { value: "SR111", title: "Samtidig ben och brosksjukdom i tibia/fibula" },
  { value: "SR1111", title: "Osteokondros i tibia/fibula" },
  { value: "SR11111", title: "Osteokondros  i tibia/fibulautan ledmus" },
  { value: "SR11112", title: "Osteokondros i tibia/fibula med ledmus" },
  {
    value: "SR11113",
    title: "Subkondral bencysta i tibia/fibula av osteokondrostyp",
  },
  {
    value: "SR11114",
    title: "Förtidigt / försent sluten epifys  i tibia/fibula",
  },
  { value: "SR11117", title: "Slabfraktur i tibia/fibula" },
  { value: "SR1112", title: "Hörnstödsfraktur i tibula/fibula" },
  {
    value: "SR1113",
    title: "Benbroskfragm plant/palm i tibula/fibula (Birkeland)",
  },
  { value: "SR113", title: "Osteokondrodysplasi  i tibia/fibula" },
  { value: "SR115", title: "Felaktig benställning i tibia/fibula" },
  { value: "SR1153", title: "Olika benlängd i tibia/fibula" },
  {
    value: "SR119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i tibia/fibula",
  },
  { value: "SR1191", title: "Panostit (endostsos) i tibia/fibula" },
  { value: "SR1192", title: "Bencysta, aseptisk bennekros i tibia/fibula" },
  {
    value: "SR1194",
    title: "Metafysär osteopati (hypertrof osteodystrofi) i tibia/fibula",
  },
  {
    value: "SR2",
    title: "Metabol, nutrit, degen/dystrof förändr, tibia/fibula",
  },
  { value: "SR20", title: "Metaboliska förändringar i tibia/fibula" },
  { value: "SR201", title: "Osteomalaci / osteolys i tibia/fibula" },
  { value: "SR202", title: "Osteoporos i tibia/fibula" },
  { value: "SR203", title: "Osteodystrofi  i tibia/fibula" },
  { value: "SR204", title: "Benmetaplasier i tibia/fibula" },
  { value: "SR21", title: "Nutritionella förändringar i tibia/fibula" },
  {
    value: "SR22",
    title: "Degenerativa/dystrofiska förändringar i tibia/fibula",
  },
  { value: "SR224", title: "Pulmonär hypertrofisk osteopati i tibia/fibula" },
  { value: "SR225", title: "Aseptisk bennekros i tibia/fibula" },
  { value: "SR23", title: "Hormonella förändringar i tibia/fibula" },
  { value: "SR3", title: "Cirkulatoriska förändringar, tibia/fibula" },
  { value: "SR30", title: "Blödningar, tibia/fibula" },
  { value: "SR301", title: "Subperiostal blödning i tibia/fibula" },
  { value: "SR31", title: "Ödem, tibia/fibula" },
  { value: "SR32", title: "Infarkter, tibia/fibula" },
  { value: "SR33", title: "Hygrom, tibia/fibula" },
  { value: "SR34", title: "Trombos / emboli, tibia/fibula" },
  { value: "SR35", title: "Arterio-atero-skleros förändringar, tibia/fibula" },
  { value: "SR4", title: "Infekt, infl förändringar, tibia/fibula" },
  { value: "SR40", title: "Specifika infektionssjukdomar i tibia/fibula" },
  { value: "SR41", title: "Akuta inflammationstillstånd i tibia/fibula" },
  {
    value: "SR411",
    title: "Akut serös / serofibrinös inflammation, tibia/fibula",
  },
  { value: "SR412", title: "Akut purulent inflammation, tibia/fibula" },
  { value: "SR413", title: "Specifika akuta infl tillstånd i tibia/fibula" },
  { value: "SR419", title: "Övriga akuta infl tillstånd i tibia/fibula" },
  { value: "SR4191", title: "Akut periostit i tibia/fibula" },
  { value: "SR41911", title: "Sekundär akut periostit i tibia/fibula" },
  { value: "SR4192", title: "Akut ostit i tibia/fibula" },
  { value: "SR4193", title: "Akut osteomyelit i tibia/fibula" },
  { value: "SR41931", title: "Sekundär akut osteomyelit i tibia/fibula" },
  {
    value: "SR41932",
    title: "Sekundär akut osteomyelit  i tibia/fibulae kir ingrepp",
  },
  { value: "SR4198", title: "Akut fysit i tibia/fibula" },
  { value: "SR42", title: "Kroniska inflammationstillstånd i tibia/fibula" },
  { value: "SR421", title: "Kronisk serös inflammation, tibia/fibula" },
  { value: "SR422", title: "Purulent inflammation, tibia/fibula" },
  { value: "SR423", title: "Abscess/fistel, tibia/fibula" },
  { value: "SR424", title: "Specifika kroniska infl tillstånd i tibia/fibula" },
  { value: "SR429", title: "Övriga kroniska infl tillstånd i tibia/fibula" },
  { value: "SR4291", title: "Kronisk periostit  i tibia/fibula (överben)" },
  { value: "SR42911", title: "Sekundär kronisk periostit i tibia/fibula" },
  { value: "SR4292", title: "Kronisk ostit i tibia/fibula" },
  { value: "SR4293", title: "Kronisk osteomyelit i tibia/fibula" },
  { value: "SR42931", title: "Sekundär kronisk osteomyelit i tibia/fibula" },
  {
    value: "SR42932",
    title: "Sekundär kronisk osteomyelit  i tibia/fibulae kir ingrepp",
  },
  { value: "SR4294", title: "Deformerande beninflammation i tibia/fibula" },
  { value: "SR4298", title: "Kronisk fysit i tibia/fibula" },
  { value: "SR43", title: "Parasitära sjukdomar, tibia/fibula" },
  { value: "SR5", title: "Immunmedierade tillstånd, tibia/fibula" },
  { value: "SR6", title: "Neoplastiska förändringar, tibia/fibula" },
  {
    value: "SR61",
    title: "Malign primär tumör i tibia/fibulao/e brosk inkl led",
  },
  {
    value: "SR611",
    title: "Malign tumör i tibia/fibula utgången från broskvävnad",
  },
  { value: "SR6111", title: "Kondrosarkom i tibia/fibula" },
  { value: "SR6112", title: "Kondromyxosarkom i tibia/fibula" },
  {
    value: "SR612",
    title: "Malign tumör i tibia/fibula utgången från benvävnad",
  },
  { value: "SR6121", title: "Osteosarkom i tibia/fibula" },
  { value: "SR613", title: "Malign tumör i tibia/fibula av bindvävsursprung" },
  { value: "SR6131", title: "Fibrosarkom i tibia/fibula" },
  {
    value: "SR614",
    title: "Malign tumör i tibia/fibula av vaskulärt eller lymf urspr",
  },
  {
    value: "SR6141",
    title: "Angiosarkom,hemangiendoteliom,hemangiopericytom,tibia/fibula",
  },
  { value: "SR615", title: "Metastas i tibia/fibula till skelett" },
  {
    value: "SR619",
    title: "Skelettumör  i tibia/fibulaav malign karaktär, UNS",
  },
  { value: "SR62", title: "Benign tumör i tibia/fibulao/e brosk inkl led" },
  {
    value: "SR621",
    title: "Benign tumör i tibia/fibula utgången från broskvävnad",
  },
  { value: "SR6211", title: "Kondroblastom i tibia/fibula" },
  { value: "SR6212", title: "Ostekondrom i tibia/fibula" },
  { value: "SR6213", title: "Enkondrom i tibia/fibula" },
  {
    value: "SR622",
    title: "Benign tumör i tibia/fibula utgången från benvävnad",
  },
  { value: "SR6221", title: "Osteom i tibia/fibula" },
  { value: "SR6222", title: "Osteoblastom i tibia/fibula" },
  { value: "SR623", title: "Benign tumör i tibia/fibula av bindvävsursprung" },
  { value: "SR6231", title: "Fibrom i tibia/fibula" },
  { value: "SR6232", title: "Fibröst histiocytom i tibia/fibula" },
  {
    value: "SR624",
    title: "Benign tumör i tibia/fibula av vaskulärt eller lymf urspr",
  },
  { value: "SR6241", title: "Hemangiom  i tibia/fibula" },
  { value: "SR6242", title: "Lymfangiom  i tibia/fibula" },
  { value: "SR628", title: "Övriga benigna i tibia/fibula skelettumörer" },
  { value: "SR6281", title: "Jättecellstumör i tibia/fibula" },
  {
    value: "SR629",
    title: "Skelettumör av benign karaktär i tibia/fibula, UNS",
  },
  {
    value: "SR7",
    title: "Trauma, fr kr, lägeförändr, term skad, tibia/fibula",
  },
  { value: "SR71", title: "Traumatiska / mekaniska skador i tibia/fibula" },
  { value: "SR711", title: "Fissur i tibia/fibula" },
  { value: "SR712", title: "Epifysfraktur i tibia/fibula" },
  {
    value: "SR7121",
    title: "Epifysfraktur i tibia/fibula (epifysglidning) proximalt",
  },
  {
    value: "SR7122",
    title: "Epifysfraktur i tibia/fibula (epifysglidning) distalt",
  },
  { value: "SR7123", title: "Epifysiolys i tibia/fibula" },
  { value: "SR71231", title: "Proximal epifysiolys i tibia/fibula" },
  { value: "SR71232", title: "Distal epifysiolys i tibia/fibula" },
  { value: "SR7129", title: "Öriga epifysskador i tibia/fibula" },
  { value: "SR713", title: "Fraktur i tibia/fibula" },
  { value: "SR7131", title: "Diafysfraktur av tibia/fibula" },
  { value: "SR7132", title: "Lednära fraktur av tibia/fibula proximalt" },
  { value: "SR7133", title: "Lednära fraktur av tibia/fibula distalt" },
  {
    value: "SR7134",
    title: "Fraktur av tibia/fibula som berör angränsande led proximalt",
  },
  {
    value: "SR7135",
    title: "Fraktur av tibia/fibula som berör angränsande led distalt",
  },
  { value: "SR7136", title: "Avsprängningsfraktur av tibia/fibula proximalt" },
  { value: "SR7137", title: "Avsprängningsfraktur av tibia/fibula distalt" },
  { value: "SR7139", title: "Kompressionsfraktur av tibia/fibula" },
  { value: "SR714", title: "Fraktur av speciell typ av tibia/fibula" },
  {
    value: "SR7141",
    title: "Kortikal subperiostal fraktur av tibia/fibula (greenstick)",
  },
  { value: "SR7142", title: "Fraktur av osteofyt av tibia/fibula" },
  {
    value: "SR7149",
    title: "Patologisk fraktur, spontanfraktur av tibia/fibula UNS",
  },
  { value: "SR715", title: "Komplikation till frakturläkning av tibia/fibula" },
  { value: "SR7151", title: "Pseudoartros av tibia/fibula" },
  {
    value: "SR7152",
    title: "Atrofi av benvävnad i tibia/fibula som kompl t frakturläkn",
  },
  { value: "SR7153", title: "Felläkt fraktur av tibia/fibula" },
  {
    value: "SR7154",
    title: "Ankylos av angränsande led pga skelettskada i tibia/fibula",
  },
  { value: "SR719", title: "Övriga skelettskador av tibia/fibula" },
  { value: "SR72", title: "Främmande kroppar, tibia/fibula" },
  { value: "SR73", title: "Lägesförändringar, tibia/fibula" },
  { value: "SR739", title: "Övrig lägesförändring av tibia/fibula" },
  { value: "SR74", title: "Termiska skador, tibia/fibula" },
  { value: "SR741", title: "Brännskada, tibia/fibula" },
  { value: "SR742", title: "Köldskada, tibia/fibula" },
  { value: "SR8", title: "Toxiska förändringar, tibia/fibula" },
  {
    value: "SR9",
    title: "Idiopatiska ospecificerade multifaktoriella, tibia/fibula",
  },
  { value: "SS", title: "Tarsalben" },
  { value: "SS0", title: "Normalvar, symtom u fastställd orsak, tarsalben" },
  { value: "SS00", title: "Normalvariation i tarsalben" },
  { value: "SS01", title: "Symtom på sjukdom u fastställd orsak, tarsalben" },
  { value: "SS011", title: "Skelettömhet i tarsalben" },
  {
    value: "SS1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, tarsalben",
  },
  { value: "SS10", title: "Missbildning i tarsalben" },
  { value: "SS101", title: "Övertaliga skelettdelar i tarsalben" },
  {
    value: "SS102",
    title: "Aplasi eller hypoplasi av skelettdelar i tarsalben",
  },
  { value: "SS103", title: "Felställda skelettdelar i tarsalben" },
  { value: "SS104", title: "Kondrodystrofi (akondroplasi) i tarsalben" },
  { value: "SS109", title: "Övriga skelettmissbildningar i tarsalben" },
  {
    value: "SS1099",
    title: "Övriga ej definierade skelettmissbildningar i tarsalben",
  },
  { value: "SS11", title: "Utvecklingsrubbn, tillväxtrubbn, tarsalben" },
  { value: "SS111", title: "Samtidig ben och brosksjukdom, tarsalben" },
  { value: "SS1111", title: "Osteokondros i tarsalben" },
  { value: "SS11111", title: "Osteokondros i tarsalben utan ledmus" },
  { value: "SS11112", title: "Osteokondros i tarsalben med ledmus" },
  {
    value: "SS11113",
    title: "Subkondral bencysta i tarsalben av osteokondrostyp",
  },
  { value: "SS11117", title: "Slabfraktur i tarsalben" },
  { value: "SS1112", title: "Hörnstödsfraktur i tarsalben" },
  {
    value: "SS1113",
    title: "Benbroskfragm plant/palm i tarsalben (Birkeland)",
  },
  { value: "SS113", title: "Osteokondrodysplasi i tarsalben" },
  { value: "SS115", title: "Felaktig benställning i tarsalben" },
  {
    value: "SS119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i tarsalben",
  },
  { value: "SS1192", title: "Bencysta, aseptisk bennekros i tarsalben" },
  { value: "SS2", title: "Metabol, nutrit, degen/dystrof förändr, tarsalben" },
  { value: "SS20", title: "Metaboliska förändringar i tarsalben" },
  { value: "SS201", title: "Osteomalaci / osteolys i tarsalben" },
  { value: "SS202", title: "Osteoporos i tarsalben" },
  { value: "SS203", title: "Osteodystrofi i tarsalben" },
  { value: "SS204", title: "Benmetaplasier i tarsalben" },
  { value: "SS21", title: "Nutritionella förändringar i tarsalben" },
  { value: "SS22", title: "Degenerativa/dystrofiska förändringar i tarsalben" },
  { value: "SS225", title: "Aseptisk bennekros i tarsalben" },
  { value: "SS23", title: "Hormonella förändringar, tarsalben" },
  { value: "SS3", title: "Cirkulatoriska förändringar, tarsalben" },
  { value: "SS30", title: "Blödningar, tarsalben" },
  { value: "SS301", title: "Subperiostal blödning i tarsalben" },
  { value: "SS31", title: "Ödem, tarsalben" },
  { value: "SS32", title: "Infarkter, tarsalben" },
  { value: "SS33", title: "Hygrom, tarsalben" },
  { value: "SS34", title: "Trombos / emboli, tarsalben" },
  { value: "SS35", title: "Arterio-atero-skleros förändringar, tarsalben" },
  { value: "SS4", title: "Infekt, infl förändringar, tarsalben" },
  { value: "SS40", title: "Specifika infektionssjukdomar, tarsalben" },
  { value: "SS41", title: "Akuta inflammationstillstånd i tarsalben" },
  {
    value: "SS411",
    title: "Akut serös / serofibrinös inflammation, tarsalben",
  },
  { value: "SS412", title: "Akut purulent inflammation, tarsalben" },
  { value: "SS413", title: "Specifika akuta infl tillstånd i tarsalben" },
  { value: "SS419", title: "Övriga akuta infl tillstånd i tarsalben" },
  { value: "SS4191", title: "Akut periostit i tarsalben" },
  { value: "SS41911", title: "Sekundär akut periostit i tarsalben" },
  { value: "SS4192", title: "Akut ostit i tarsalben" },
  { value: "SS4193", title: "Akut osteomyelit i tarsalben" },
  { value: "SS41931", title: "Sekundär akut osteomyelit i tarsalben" },
  {
    value: "SS41932",
    title: "Sekundär akut osteomyelit i tarsalben e kir ingrepp",
  },
  { value: "SS42", title: "Kroniska inflammationstillstånd i tarsalben" },
  { value: "SS421", title: "Kronisk serös inflammation, tarsalben" },
  { value: "SS422", title: "Purulent inflammation, tarsalben" },
  { value: "SS423", title: "Abscess/fistel, tarsalben" },
  { value: "SS424", title: "Specifika kroniska infl tillstånd i tarsalben" },
  { value: "SS429", title: "Övriga kroniska infl tillstånd i tarsalben" },
  { value: "SS4291", title: "Kronisk periostit (överben) i tarsalben" },
  { value: "SS42911", title: "Sekundär kronisk periostit i tarsalben" },
  { value: "SS4292", title: "Kronisk ostit i tarsalben" },
  { value: "SS4293", title: "Kronisk osteomyelit i tarsalben" },
  { value: "SS42931", title: "Sekundär kronisk osteomyelit i tarsalben" },
  {
    value: "SS42932",
    title: "Sekundär kronisk osteomyelit i tarsalben e kir ingrepp",
  },
  { value: "SS4294", title: "Deformerande beninflammation i tarsalben" },
  { value: "SS43", title: "Parasitära sjukdomar, tarsalben" },
  { value: "SS5", title: "Immunmedierade tillstånd, tarsalben" },
  { value: "SS6", title: "Neoplastiska förändringar, tarsalben" },
  { value: "SS61", title: "Malign primär tumör i tarsalbeno/e brosk inkl led" },
  {
    value: "SS611",
    title: "Malign tumör i tarsalben utgången från broskvävnad",
  },
  { value: "SS6111", title: "Kondrosarkom i tarsalben" },
  { value: "SS6112", title: "Kondromyxosarkom i tarsalben" },
  { value: "SS612", title: "Malign tumör i tarsalben utgången från benvävnad" },
  { value: "SS6121", title: "Osteosarkom i tarsalben" },
  { value: "SS613", title: "Malign tumör i tarsalben av bindvävsursprung" },
  { value: "SS6131", title: "Fibrosarkom i tarsalben" },
  {
    value: "SS614",
    title: "Malign tumör i tarsalben av vaskulärt eller lymf urspr",
  },
  {
    value: "SS6141",
    title: "Angiosarkom, hemangiendoteliom, hemangiopericytom, tarsalben",
  },
  { value: "SS615", title: "Metastas i tarsalben till skelett" },
  { value: "SS619", title: "Skelettumör  i tarsalbenav malign karaktär, UNS" },
  { value: "SS62", title: "Benign tumör i tarsalben o/e brosk inkl led" },
  {
    value: "SS621",
    title: "Benign tumör i tarsalben utgången från broskvävnad",
  },
  { value: "SS6211", title: "Kondroblastom i tarsalben" },
  { value: "SS6212", title: "Ostekondrom i tarsalben" },
  { value: "SS6213", title: "Enkondrom i tarsalben" },
  { value: "SS622", title: "Benign tumör i tarsalben utgången från benvävnad" },
  { value: "SS6221", title: "Osteom  i tarsalben" },
  { value: "SS6222", title: "Osteoblastom i tarsalben" },
  { value: "SS623", title: "Benign tumör  i tarsalbenav bindvävsursprung" },
  { value: "SS6231", title: "Fibrom i tarsalben" },
  { value: "SS6232", title: "Fibröst histiocytom i tarsalben" },
  {
    value: "SS624",
    title: "Benign tumör i tarsalben av vaskulärt eller lymf urspr",
  },
  { value: "SS6241", title: "Hemangiom i tarsalben" },
  { value: "SS6242", title: "Lymfangiom i tarsalben" },
  { value: "SS628", title: "Övriga benigna skelettumörer i tarsalben" },
  { value: "SS6281", title: "Jättecellstumör i tarsalben" },
  { value: "SS629", title: "Skelettumör i tarsalben av benign karaktär, UNS" },
  { value: "SS7", title: "Trauma, fr kr, lägeförändr, term skad, tarsalben" },
  { value: "SS71", title: "Traumatiska / mekaniska skador i tarsalben" },
  { value: "SS711", title: "Fissur i tarsalben" },
  { value: "SS712", title: "Epifysfraktur i tarsalben" },
  { value: "SS7129", title: "Öriga epifysskador i tarsalben" },
  { value: "SS713", title: "Fraktur i tarsalben" },
  { value: "SS7131", title: "Diafysfraktur i tarsalben  / fraktur på korpus" },
  { value: "SS7132", title: "Lednära fraktur i tarsalben proximalt /kranialt" },
  { value: "SS7133", title: "Lednära fraktur i tarsalben distalt /kaudalt" },
  {
    value: "SS7134",
    title: "Fraktur i tarsalben som berör angräns led/leder prox/kran",
  },
  {
    value: "SS7135",
    title: "Fraktur i tarsalben som berör angräns led/leder dist/kaudalt",
  },
  {
    value: "SS7136",
    title: "Avsprängningsfraktur i tarsalben proximalt /kranialt",
  },
  {
    value: "SS7137",
    title: "Avsprängningsfraktur i tarsalben distalt /kaudalt",
  },
  { value: "SS7139", title: "Kompressionsfraktur i tarsalben" },
  { value: "SS714", title: "Fraktur av speciell typ i tarsalben" },
  {
    value: "SS71491",
    title: "Kortikal subperiostal fraktur i tarsalben (greenstick)",
  },
  { value: "SS71492", title: "Fraktur av osteofyt i tarsalben" },
  {
    value: "SS71499",
    title: "Patologisk fraktur, spontanfraktur i tarsalben UNS",
  },
  { value: "SS715", title: "Komplikation till frakturläkning i tarsalben" },
  { value: "SS7151", title: "Pseudoartros i tarsalben" },
  {
    value: "SS7152",
    title: "Atrofi av benvävnad i tarsalben som kompl t frakturläkn",
  },
  { value: "SS7153", title: "Felläkt fraktur i tarsalben" },
  {
    value: "SS7154",
    title: "Ankylos av angränsande led pga skelettskada i tarsalben",
  },
  { value: "SS719", title: "Övriga skelettskador i tarsalben" },
  { value: "SS72", title: "Främmande kroppar, tarsalben" },
  { value: "SS73", title: "Lägesförändringar, tarsalben" },
  { value: "SS739", title: "Övrig lägesförändring i tarsalben" },
  { value: "SS74", title: "Termiska skador, tarsalben" },
  { value: "SS741", title: "Brännskada, tarsalben" },
  { value: "SS742", title: "Köldskada, tarsalben" },
  { value: "SS8", title: "Toxiska förändringar, tarsalben" },
  {
    value: "SS9",
    title: "Idiopatiska ospecificerade multifaktoriella, tarsalben",
  },
  {
    value: "SSD0",
    title: "Normalvar, symtom u fastställd orsak, revben/bröstben",
  },
  { value: "ST", title: "Metatarsalben" },
  {
    value: "ST0",
    title: "Normalvar, symtom u fastställd orsak, metatarsalben",
  },
  { value: "ST00", title: "Normalvariation i metatarsalben" },
  {
    value: "ST01",
    title: "Symtom på sjukdom u fastställd orsak, metatarsalben",
  },
  { value: "ST011", title: "Skelettömhet i metatarsalben" },
  {
    value: "ST1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, metatarsalben",
  },
  { value: "ST10", title: "Missbildning i metatarsalben" },
  { value: "ST101", title: "Övertaliga skelettdelar i metatarsalben" },
  { value: "ST1011", title: "Polydaktyli i metatarsalben" },
  {
    value: "ST102",
    title: "Aplasi eller hypoplasi av skelettdelar i metatarsalben",
  },
  { value: "ST103", title: "Felställda skelettdelar i metatarsalben" },
  { value: "ST104", title: "Kondrodystrofi (akondroplasi) i metatarsalben" },
  { value: "ST109", title: "Övriga skelettmissbildningar i metatarsalben" },
  {
    value: "ST1099",
    title: "Övriga ej definierade skelettmissbildningar i metatarsalben",
  },
  { value: "ST11", title: "Utvecklingsrubbn, tillväxtrubbn, metatarsalben" },
  { value: "ST111", title: "Samtidig ben och brosksjukdom i metatarsalben" },
  { value: "ST1111", title: "Osteokondros i metatarsalben" },
  { value: "ST11111", title: "Osteokondros i metatarsalben utan ledmus" },
  { value: "ST11112", title: "Osteokondros i metatarsalben med ledmus" },
  {
    value: "ST11113",
    title: "Subkondral bencysta i metatarsalben av osteokondrostyp",
  },
  {
    value: "ST11114",
    title: "Förtidigt / försent sluten epifys i metatarsalben",
  },
  { value: "ST11117", title: "Slabfraktur i metatarsalben" },
  { value: "ST1112", title: "Hörnstödsfraktur  i metatarsalben" },
  {
    value: "ST1113",
    title: "Benbroskfragm plant/palm i metatarsalben (Birkeland)",
  },
  { value: "ST113", title: "Osteokondrodysplasi i metatarsalben" },
  { value: "ST115", title: "Felaktig benställning i metatarsalben" },
  { value: "ST1152", title: "Felaktig benställning bakben i metatarsalben" },
  { value: "ST1153", title: "Olika benlängd i metatarsalben" },
  {
    value: "ST119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i metatarsalben",
  },
  { value: "ST1192", title: "Bencysta, aseptisk bennekros i metatarsalben" },
  {
    value: "ST1194",
    title: "Metafysär osteopati (hypertrof osteodystr) i metatarsalben",
  },
  {
    value: "ST2",
    title: "Metabol, nutrit, degen/dystrof förändr, metatarsalben",
  },
  { value: "ST20", title: "Metaboliska förändringar i metatarsalben" },
  { value: "ST201", title: "Osteomalaci / osteolys i metatarsalben" },
  { value: "ST202", title: "Osteoporos i metatarsalben" },
  { value: "ST203", title: "Osteodystrofi  i metatarsalben" },
  { value: "ST204", title: "Benmetaplasier i metatarsalben" },
  { value: "ST21", title: "Nutritionella förändringar i metatarsalben" },
  {
    value: "ST22",
    title: "Degenerativa/dystrofiska förändringar i metatarsalben",
  },
  { value: "ST224", title: "Pulmonär hypertrofisk osteopati i metatarsalben" },
  { value: "ST225", title: "Aseptisk bennekros i metatarsalben" },
  { value: "ST23", title: "Hormonella förändringar i metatarsalben" },
  { value: "ST3", title: "Cirkulatoriska förändringar, metatarsalben" },
  { value: "ST30", title: "Blödningar, metatarsalben" },
  { value: "ST301", title: "Subperiostal blödning i metatarsalben" },
  { value: "ST31", title: "Ödem, metatarsalben" },
  { value: "ST32", title: "Infarkter, metatarsalben" },
  { value: "ST33", title: "Hygrom, metatarsalben" },
  { value: "ST34", title: "Trombos / emboli, metatarsalben" },
  { value: "ST35", title: "Arterio-atero-skleros förändringar, metatarsalben" },
  { value: "ST4", title: "Infekt, infl förändringar, metatarsalben" },
  { value: "ST40", title: "Specifika infektionssjukdomar, metatarsalben" },
  { value: "ST41", title: "Akuta inflammationstillstånd i metatarsalben" },
  {
    value: "ST411",
    title: "Akut serös / serofibrinös inflammation, metatarsalben",
  },
  { value: "ST412", title: "Akut purulent inflammation, metatarsalben" },
  { value: "ST413", title: "Specifika akuta infl tillstånd i metatarsalben" },
  { value: "ST419", title: "Övriga akuta infl tillstånd i metatarsalben" },
  { value: "ST4191", title: "Akut periostit i metatarsalben" },
  { value: "ST41911", title: "Sekundär akut periostit i metatarsalben" },
  { value: "ST4192", title: "Akut ostit i metatarsalben" },
  { value: "ST4193", title: "Akut osteomyelit i metatarsalben" },
  { value: "ST41931", title: "Sekundär akut osteomyelit i metatarsalben" },
  {
    value: "ST41932",
    title: "Sekundär akut osteomyelit  i metatarsalbene kir ingrepp",
  },
  { value: "ST4198", title: "Akut fysit  i metatarsalben" },
  { value: "ST42", title: "Kroniska inflammationstillstånd i metatarsalben" },
  { value: "ST421", title: "Kronisk serös inflammation" },
  { value: "ST422", title: "Purulent inflammation, metatarsalben" },
  { value: "ST423", title: "Abscess/fistel, metatarsalben" },
  {
    value: "ST424",
    title: "Specifika kroniska infl tillstånd i metatarsalben",
  },
  { value: "ST429", title: "Övriga kroniska infl tillstånd i metatarsalben" },
  { value: "ST4291", title: "Kronisk periostit i metatarsalben (överben)" },
  { value: "ST42911", title: "Sekundär kronisk i metatarsalben periostit" },
  { value: "ST4292", title: "Kronisk ostit i metatarsalben" },
  { value: "ST4293", title: "Kronisk osteomyelit i metatarsalben" },
  { value: "ST42931", title: "Sekundär kronisk osteomyelit i metatarsalben" },
  {
    value: "ST42932",
    title: "Sekundär kronisk osteomyelit i metatarsalben e kir ingrepp",
  },
  { value: "ST4294", title: "Deformerande beninflammation i metatarsalben" },
  { value: "ST4295", title: "Kronisk diskospondylit i metatarsalben" },
  { value: "ST4298", title: "Kronisk fysit  i metatarsalben" },
  { value: "ST43", title: "Parasitära sjukdomar, metatarsalben" },
  { value: "ST5", title: "Immunmedierade tillstånd, metatarsalben" },
  { value: "ST6", title: "Neoplastiska förändringar, metatarsalben" },
  {
    value: "ST61",
    title: "Malign primär tumör i metatarsalbeno/e brosk inkl led",
  },
  {
    value: "ST611",
    title: "Malign tumör i metatarsalben utgången från broskvävnad",
  },
  { value: "ST6111", title: "Kondrosarkom i metatarsalben" },
  { value: "ST6112", title: "Kondromyxosarkom i metatarsalben" },
  {
    value: "ST612",
    title: "Malign tumör i metatarsalben utgången från benvävnad",
  },
  { value: "ST6121", title: "Osteosarkom i metatarsalben" },
  { value: "ST613", title: "Malign tumör i metatarsalben av bindvävsursprung" },
  { value: "ST6131", title: "Fibrosarkom i metatarsalben" },
  {
    value: "ST614",
    title: "Malign tumör i metatarsalben av vaskulärt eller lymf urspr",
  },
  {
    value: "ST6141",
    title: "Angiosarkom,hemangiendoteliom,hemangiopericytom, metatarsalb",
  },
  { value: "ST615", title: "Metastas i metatarsalben till skelett" },
  {
    value: "ST619",
    title: "Skelettumör i metatarsalben av malign karaktär, UNS",
  },
  { value: "ST62", title: "Benign tumör i metatarsalbeno/e brosk inkl led" },
  {
    value: "ST621",
    title: "Benign tumör i metatarsalben utgången från broskvävnad",
  },
  { value: "ST6211", title: "Kondroblastom i metatarsalben" },
  { value: "ST6212", title: "Ostekondrom i metatarsalben" },
  { value: "ST6213", title: "Enkondrom i metatarsalben" },
  {
    value: "ST622",
    title: "Benign tumör i metatarsalben utgången från benvävnad",
  },
  { value: "ST6221", title: "Osteom  i metatarsalben" },
  { value: "ST6222", title: "Osteoblastom i metatarsalben" },
  { value: "ST623", title: "Benign tumör  i metatarsalbenav bindvävsursprung" },
  { value: "ST6231", title: "Fibrom i metatarsalben" },
  { value: "ST6232", title: "Fibröst histiocytom i metatarsalben" },
  {
    value: "ST624",
    title: "Benign tumör i metatarsalben av vaskulärt eller lymf urspr",
  },
  { value: "ST6241", title: "Hemangiom i metatarsalben" },
  { value: "ST6242", title: "Lymfangiom i metatarsalben" },
  { value: "ST628", title: "Övriga benigna skelettumörer i metatarsalben" },
  { value: "ST6281", title: "Jättecellstumör i metatarsalben" },
  {
    value: "ST629",
    title: "Skelettumör av benign karaktär i metatarsalben, UNS",
  },
  {
    value: "ST7",
    title: "Trauma, fr kr, lägeförändr, term skad, metatarsalben",
  },
  { value: "ST71", title: "Traumatiska / mekaniska skador i metatarsalben" },
  { value: "ST711", title: "Fissur i metatarsalben" },
  { value: "ST712", title: "Epifysfraktur i metatarsalben" },
  {
    value: "ST7121",
    title: "Epifysfraktur  i metatarsalben(epifysglidning) proximalt",
  },
  {
    value: "ST7122",
    title: "Epifysfraktur  i metatarsalben(epifysglidning) distalt",
  },
  { value: "ST7123", title: "Epifysiolys i metatarsalben" },
  { value: "ST71231", title: "Proximal epifysiolys i metatarsalben" },
  { value: "ST71232", title: "Distal epifysiolys i metatarsalben" },
  { value: "ST7129", title: "Öriga epifysskador i metatarsalben" },
  { value: "ST713", title: "Fraktur i metatarsalben" },
  { value: "ST7131", title: "Diafysfraktur i metatarsalben" },
  { value: "ST7132", title: "Lednära fraktur av metatarsalben proximalt" },
  { value: "ST7133", title: "Lednära fraktur av metatarsalben distalt" },
  {
    value: "ST7134",
    title: "Fraktur av metatarsalben som berör angränsande led proximalt",
  },
  {
    value: "ST7135",
    title: "Fraktur av metatarsalben som berör angränsande led distalt",
  },
  { value: "ST7136", title: "Avsprängningsfraktur av metatarsalben proximalt" },
  { value: "ST7137", title: "Avsprängningsfraktur av metatarsalben distalt" },
  { value: "ST7139", title: "Kompressionsfraktur av metatarsalben" },
  { value: "ST714", title: "Fraktur av speciell typ av metatarsalben" },
  {
    value: "ST7141",
    title: "Kortikal subperiostal fraktur  av metatarsalben (greenstick)",
  },
  { value: "ST7142", title: "Fraktur av osteofyt av metatarsalben" },
  {
    value: "ST7149",
    title: "Patologisk fraktur, spontanfraktur av metatarsalben UNS",
  },
  {
    value: "ST715",
    title: "Komplikation till frakturläkning av metatarsalben",
  },
  { value: "ST7151", title: "Pseudoartros av metatarsalben" },
  {
    value: "ST7152",
    title: "Atrofi av benvävnad i metatarsalben som kompl t  frakturläkn",
  },
  { value: "ST7153", title: "Felläkt fraktur av metatarsalben" },
  {
    value: "ST7154",
    title: "Ankylos av angränsande led pga skelettskada i metatarsalben",
  },
  { value: "ST719", title: "Övriga skelettskador av metatarsalben" },
  { value: "ST72", title: "Främmande kroppar, metatarsalben" },
  { value: "ST73", title: "Lägesförändringar, metatarsalben" },
  { value: "ST739", title: "Övrig lägesförändring av metatarsalben" },
  { value: "ST74", title: "Termiska skador, metatarsalben" },
  { value: "ST741", title: "Brännskada, metatarsalben" },
  { value: "ST742", title: "Köldskada, metatarsalben" },
  { value: "ST8", title: "Toxiska förändringar, metatarsalben" },
  {
    value: "ST9",
    title: "Idiopatiska ospecificerade multifaktoriella, metatarsalben",
  },
  { value: "SU", title: "Griffelbenben" },
  { value: "SU0", title: "Normalvar, symtom u fastställd orsak, griffelben" },
  { value: "SU00", title: "Normalvariation i griffelben" },
  { value: "SU01", title: "Symtom på sjukdom u fastställd orsak, griffelben" },
  { value: "SU011", title: "Skelettömhet i griffelben" },
  {
    value: "SU1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, griffelben",
  },
  { value: "SU10", title: "Missbildning i griffelben" },
  { value: "SU101", title: "Övertaliga skelettdelar i griffelben" },
  { value: "SU1011", title: "Polydaktyli i griffelben" },
  {
    value: "SU102",
    title: "Aplasi eller hypoplasi av skelettdelar i griffelben",
  },
  { value: "SU103", title: "Felställda skelettdelar i griffelben" },
  { value: "SU104", title: "Kondrodystrofi (akondroplasi) i griffelben" },
  { value: "SU109", title: "Övriga skelettmissbildningar i griffelben" },
  {
    value: "SU1099",
    title: "Övriga ej definierade skelettmissbildningar i griffelben",
  },
  { value: "SU11", title: "Utvecklingsrubbn, tillväxtrubbn, griffelben" },
  { value: "SU111", title: "Samtidig ben och brosksjukdom i griffelben" },
  { value: "SU1111", title: "Osteokondros i griffelben, griffelben" },
  { value: "SU11111", title: "Osteokondros i griffelben utan ledmus" },
  { value: "SU11112", title: "Osteokondros i griffelben med ledmus" },
  {
    value: "SU11113",
    title: "Subkondral bencysta i griffelben av osteokondrostyp",
  },
  { value: "SU11114", title: "Förtidigt / försent sluten epifys i griffelben" },
  { value: "SU11117", title: "Slabfraktur i griffelben" },
  { value: "SU1112", title: "Hörnstödsfraktur  i griffelben" },
  {
    value: "SU1113",
    title: "Benbroskfragm plant/palm i griffelben (Birkeland)",
  },
  { value: "SU113", title: "Osteokondrodysplasi i griffelben" },
  { value: "SU115", title: "Felaktig benställning i griffelben" },
  { value: "SU1152", title: "Felaktig benställning bakben i griffelben" },
  { value: "SU1153", title: "Olika benlängd i griffelben" },
  {
    value: "SU119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i griffelben",
  },
  { value: "SU1192", title: "Bencysta, aseptisk bennekros i griffelben" },
  {
    value: "SU1194",
    title: "Metafysär osteopati (hypertrof osteodystrofi) i griffelben",
  },
  { value: "SU2", title: "Metabol, nutrit, degen/dystrof förändr, griffelben" },
  { value: "SU20", title: "Metaboliska förändringar i griffelben" },
  { value: "SU201", title: "Osteomalaci / osteolys i griffelben" },
  { value: "SU202", title: "Osteoporos i griffelben" },
  { value: "SU203", title: "Osteodystrofi  i griffelben" },
  { value: "SU204", title: "Benmetaplasier i griffelben" },
  { value: "SU21", title: "Nutritionella förändringar i griffelben" },
  {
    value: "SU22",
    title: "Degenerativa/dystrofiska förändringar i griffelben",
  },
  { value: "SU224", title: "Pulmonär hypertrofisk osteopati i griffelben" },
  { value: "SU225", title: "Aseptisk bennekros i griffelben" },
  { value: "SU23", title: "Hormonella förändringar i griffelben" },
  { value: "SU3", title: "Cirkulatoriska förändringar, griffelben" },
  { value: "SU30", title: "Blödningar, griffelben" },
  { value: "SU301", title: "Subperiostal blödning i griffelben" },
  { value: "SU31", title: "Ödem, griffelben" },
  { value: "SU32", title: "Infarkter, griffelben" },
  { value: "SU33", title: "Hygrom, griffelben" },
  { value: "SU34", title: "Trombos / emboli, griffelben" },
  { value: "SU35", title: "Arterio-atero-skleros förändringar, griffelben" },
  { value: "SU4", title: "Infekt, infl förändringar, griffelben" },
  { value: "SU40", title: "Specifika infektionssjukdomar, griffelben" },
  { value: "SU41", title: "Akuta inflammationstillstånd i griffelben" },
  {
    value: "SU411",
    title: "Akut serös / serofibrinös inflammation, griffelben",
  },
  { value: "SU412", title: "Akut purulent inflammation, griffelben" },
  { value: "SU413", title: "Specifika akuta infl tillstånd i griffelben" },
  { value: "SU419", title: "Övriga akuta infl tillstånd i griffelben" },
  { value: "SU4191", title: "Akut periostit i griffelben" },
  { value: "SU41911", title: "Sekundär akut periostit i griffelben" },
  { value: "SU4192", title: "Akut ostit i griffelben" },
  { value: "SU4193", title: "Akut osteomyelit i griffelben" },
  { value: "SU41931", title: "Sekundär akut osteomyelit i griffelben" },
  {
    value: "SU41932",
    title: "Sekundär akut osteomyelit  i griffelbene kir ingrepp",
  },
  { value: "SU4198", title: "Akut fysit  i griffelben" },
  { value: "SU42", title: "Kroniska inflammationstillstånd i griffelben" },
  { value: "SU421", title: "Kronisk serös inflammation, griffelben" },
  { value: "SU422", title: "Purulent inflammation, griffelben" },
  { value: "SU423", title: "Abscess/fistel, griffelben" },
  { value: "SU424", title: "Specifika kroniska infl tillstånd i griffelben" },
  { value: "SU429", title: "Övriga kroniska infl tillstånd i griffelben" },
  { value: "SU4291", title: "Kronisk periostit i griffelben (överben)" },
  { value: "SU42911", title: "Sekundär kronisk i griffelben periostit" },
  { value: "SU4292", title: "Kronisk ostit i griffelben" },
  { value: "SU42921", title: "Kronisk sesamoidit i griffelben" },
  { value: "SU4293", title: "Kronisk osteomyelit i griffelben" },
  { value: "SU42931", title: "Sekundär kronisk osteomyelit i griffelben" },
  {
    value: "SU42932",
    title: "Sekundär kronisk osteomyelit i griffelben e kir ingrepp",
  },
  { value: "SU4294", title: "Deformerande beninflammation i griffelben" },
  { value: "SU4295", title: "Kronisk diskospondylit i griffelben" },
  { value: "SU4298", title: "Kronisk fysit  i griffelben" },
  { value: "SU43", title: "Parasitära sjukdomar, griffelben" },
  { value: "SU5", title: "Immunmedierade tillstånd, griffelben" },
  { value: "SU6", title: "Neoplastiska förändringar, griffelben" },
  {
    value: "SU61",
    title: "Malign primär tumör i griffelben o/e brosk inkl led",
  },
  {
    value: "SU611",
    title: "Malign tumör i griffelben utgången från broskvävnad",
  },
  { value: "SU6111", title: "Kondrosarkom i griffelben" },
  { value: "SU6112", title: "Kondromyxosarkom i griffelben" },
  {
    value: "SU612",
    title: "Malign tumör i griffelben utgången från benvävnad",
  },
  { value: "SU6121", title: "Osteosarkom i griffelben" },
  { value: "SU613", title: "Malign tumör i griffelben av bindvävsursprung" },
  { value: "SU6131", title: "Fibrosarkom i griffelben" },
  {
    value: "SU614",
    title: "Malign tumör i griffelben av vaskulärt eller lymf urspr",
  },
  {
    value: "SU6141",
    title: "Angiosarkom, hemangiendoteliom,hemangiopericytom, griffelben",
  },
  { value: "SU615", title: "Metastas i griffelben till skelett" },
  { value: "SU619", title: "Skelettumör i griffelben av malign karaktär, UNS" },
  { value: "SU62", title: "Benign tumör i griffelben o/e brosk inkl led" },
  {
    value: "SU621",
    title: "Benign tumör i griffelben utgången från broskvävnad",
  },
  { value: "SU6211", title: "Kondroblastom i griffelben" },
  { value: "SU6212", title: "Ostekondrom i griffelben" },
  { value: "SU6213", title: "Enkondrom i griffelben" },
  {
    value: "SU622",
    title: "Benign tumör i griffelben utgången från benvävnad",
  },
  { value: "SU6221", title: "Osteom  i griffelben" },
  { value: "SU6222", title: "Osteoblastom i griffelben" },
  { value: "SU623", title: "Benign tumör  i griffelben av bindvävsursprung" },
  { value: "SU6231", title: "Fibrom i griffelben" },
  { value: "SU6232", title: "Fibröst histiocytom i griffelben" },
  {
    value: "SU624",
    title: "Benign tumör i griffelben av vaskulärt eller lymf urspr",
  },
  { value: "SU6241", title: "Hemangiom i griffelben" },
  { value: "SU6242", title: "Lymfangiom i griffelben" },
  { value: "SU628", title: "Övriga benigna skelettumörer i griffelben" },
  { value: "SU6281", title: "Jättecellstumör i griffelben" },
  { value: "SU629", title: "Skelettumör av benign karaktär i griffelben, UNS" },
  { value: "SU7", title: "Trauma, fr kr, lägeförändr, term skad, griffelben" },
  { value: "SU71", title: "Traumatiska / mekaniska skador i griffelben" },
  { value: "SU711", title: "Fissur i griffelben" },
  { value: "SU713", title: "Fraktur av griffelben" },
  { value: "SU7131", title: "Diafysfraktur i griffelben" },
  { value: "SU7132", title: "Lednära fraktur av griffelben proximalt" },
  { value: "SU7133", title: "Lednära fraktur av griffelben distalt" },
  {
    value: "SU7134",
    title: "Fraktur av griffelben som berör angränsande led proximalt",
  },
  {
    value: "SU7135",
    title: "Fraktur av griffelben som berör angränsande led distalt",
  },
  { value: "SU7136", title: "Avsprängningsfraktur av griffelben proximalt" },
  { value: "SU7137", title: "Avsprängningsfraktur av griffelben distalt" },
  { value: "SU7139", title: "Kompressionsfraktur av griffelben" },
  { value: "SU714", title: "Fraktur av speciell typ av griffelben" },
  {
    value: "SU7141",
    title: "Kortikal subperiostal fraktur  av griffelben (greenstick)",
  },
  { value: "SU7142", title: "Fraktur av osteofyt av griffelben" },
  {
    value: "SU7149",
    title: "Patologisk fraktur, spontanfraktur av griffelben UNS",
  },
  { value: "SU715", title: "Komplikation till frakturläkning av griffelben" },
  { value: "SU7151", title: "Pseudoartros av griffelben" },
  {
    value: "SU7152",
    title: "Atrofi av benvävnad i griffelben som kompl t frakturläkn",
  },
  { value: "SU7153", title: "Felläkt fraktur av griffelben" },
  {
    value: "SU7154",
    title: "Ankylos av angränsande led pga skelettskada i griffelben",
  },
  { value: "SU719", title: "Övriga skelettskador av griffelben" },
  { value: "SU72", title: "Främmande kroppar, griffelben" },
  { value: "SU73", title: "Lägesförändringar, griffelben" },
  { value: "SU739", title: "Övrig lägesförändring av griffelben" },
  { value: "SU74", title: "Termiska skador, griffelben" },
  { value: "SU741", title: "Brännskada, griffelben" },
  { value: "SU742", title: "Köldskada, griffelben" },
  { value: "SU8", title: "Toxiska förändringar, griffelben" },
  {
    value: "SU9",
    title: "Idiopatiska ospecificerade multifaktoriella, griffelben",
  },
  { value: "SX", title: "Skelett" },
  { value: "SX0", title: "Normalvar, symtom u fastställd orsak, skelett" },
  { value: "SX00", title: "Normalvariation, skelett" },
  { value: "SX01", title: "Symtom på sjukdom u fastställd orsak, skelett" },
  { value: "SX011", title: "Skelettömhet" },
  { value: "SX1", title: "Missbildn, utveckl. rubbn, tillväxtrubbn, skelett" },
  { value: "SX10", title: "Missbildning, skelett" },
  { value: "SX101", title: "Övertaliga skelettdelar" },
  { value: "SX1011", title: "Polydaktyli" },
  { value: "SX102", title: "Aplasi/hypoplasi av skelettdelar" },
  { value: "SX103", title: "Felställda skelettdelar" },
  { value: "SX104", title: "Kondrodystrofi i skelett (akondroplasi)" },
  { value: "SX109", title: "Övriga skelettmissbildningar" },
  { value: "SX1091", title: "Spina bifida" },
  { value: "SX1092", title: "Fusion av kotkroppar (blockkota)" },
  { value: "SX1093", title: "Hemivertebrae" },
  { value: "SX1094", title: "Occipital/foramen magnum dysplasi" },
  { value: "SX1095", title: "Missbildning av ryggkota" },
  { value: "SX10951", title: "Missbildning av svanskota (kroksvans)" },
  { value: "SX1096", title: "Missbildning av sakrum" },
  { value: "SX10961", title: "Övergångskota (lumbarisation/sakralisation)" },
  { value: "SX1097", title: "Shistosoma reflexum" },
  { value: "SX1098", title: "Medfödd klubbfot" },
  { value: "SX1099", title: "Missbildning av käke (inkl bettfel)" },
  { value: "SX10991", title: "Underbett (Brachygnatia sup)" },
  { value: "SX10992", title: "Överbett (Brachygnatia inf)" },
  { value: "SX10993", title: "Tångbett" },
  { value: "SX10994", title: "Korsbett" },
  { value: "SX10995", title: "Postnormalt bett" },
  { value: "SX11", title: "Utvecklingsrubbn, tillväxtrubbn, skelett" },
  { value: "SX111", title: "Osteokondros" },
  { value: "SX1111", title: "Osteokondros utan ledmus" },
  { value: "SX1112", title: "Osteokondros med ledmus" },
  { value: "SX1113", title: "Subkondral bencysta av osteokondrostyp" },
  { value: "SX1114", title: "Fragment av proc coronoideus, FPC" },
  { value: "SX112", title: "Övriga utveckl/tillväxtrubbn skelett" },
  { value: "SX1121", title: "Ununited proc anconeus UPA" },
  { value: "SX1122", title: "Cysta i ben" },
  { value: "SX11221", title: "Subkondral bencysta" },
  { value: "SX1123", title: "Leg-Perthes sjukdom" },
  { value: "SX1124", title: "Metafysär osteopati" },
  { value: "SX113", title: "Utvecklingsrubbning i tillväxtzon" },
  { value: "SX1131", title: "För tidigt sluten tillväxtzon" },
  { value: "SX1132", title: "För sent sluten tillväxtzon" },
  { value: "SX1133", title: "Retained cartilage" },
  { value: "SX114", title: "Osteokondrodysplasi" },
  { value: "SX115", title: "Felaktig benställning" },
  { value: "SX1151", title: "Sabelbenthet häst" },
  { value: "SX1152", title: "Parallellförskjutning i karpus häst" },
  { value: "SX1153", title: "Förkortning av ben/olika benlängd" },
  { value: "SX1154", title: "Valgusställning" },
  { value: "SX1155", title: "Varusställning" },
  { value: "SX1156", title: "Björnfot" },
  { value: "SX1157", title: "Windswept" },
  { value: "SX116", title: "Felaktig rygghållning" },
  { value: "SX1161", title: "Kyfos (karprygg)" },
  { value: "SX1162", title: "Lordos (svankrygg)" },
  { value: "SX1163", title: "Skolios" },
  { value: "SX1164", title: "Torticollis" },
  { value: "SX117", title: "Cervikal vertebral instabilitet/stenos,CVI" },
  { value: "SX118", title: "Bencysta, aseptisk bennekros" },
  { value: "SX2", title: "Metabol, nutrit, degen/dystrof förändr skelett" },
  { value: "SX20", title: "Metaboliska förändringar, skelett" },
  { value: "SX201", title: "Osteomalaci/osteolys" },
  { value: "SX202", title: "Osteoporos" },
  { value: "SX203", title: "Osteodystrofi" },
  { value: "SX204", title: "Benmetaplasier" },
  { value: "SX21", title: "Nutritionella förändringar skelett" },
  { value: "SX211", title: "Osteopeni" },
  { value: "SX212", title: "Nutritionell sek. hyperparathyreoidism" },
  { value: "SX22", title: "Degenerativa/dystrofiska förändringar skelett" },
  { value: "SX221", title: "Diskdegeneration" },
  { value: "SX2211", title: "Diskdegeneration utan förkalkning" },
  { value: "SX22111", title: "Lumbosakralsyndromet (L7-S1) " },
  { value: "SX2212", title: "Diskdegeneration med förkalkning" },
  { value: "SX222", title: "Diskbråck" },
  { value: "SX2221", title: "Diskbråck i cervikalregionen       " },
  { value: "SX2222", title: "Diskbråck i thorakolumbalregionen" },
  { value: "SX223", title: "Spondylos" },
  { value: "SX2231", title: "Spondylos, generell utbredd" },
  { value: "SX2232", title: "Spondylos, lokal" },
  { value: "SX224", title: "Akropaki/(pulmonär) hypertrofisk osteopati" },
  { value: "SX225", title: "Aseptisk bennekros" },
  { value: "SX3", title: "Cirkulatoriska förändringar skelett" },
  { value: "SX30", title: "Blödningar i skelett" },
  { value: "SX301", title: "Subperiostal blödning" },
  { value: "SX4", title: "Infekt, infl förändringar, skelett" },
  { value: "SX41", title: "Akuta infekt, infl förändringar, skelett" },
  { value: "SX411", title: "Akuta infektioner, skelett" },
  { value: "SX4111", title: "Akut periostit, infektiös" },
  { value: "SX4112", title: "Akut ostit" },
  { value: "SX41121", title: "Akut ostit, infektiös" },
  { value: "SX4113", title: "Akut osteomyelit" },
  { value: "SX41131", title: "Akut primär osteomyelit" },
  { value: "SX41132", title: "Akut sekundär osteomyelit" },
  { value: "SX41135", title: "Akut osteomyelit i tillväxtzon/fysit" },
  { value: "SX41136", title: "Akut osteomyelit med bensekvester" },
  { value: "SX4114", title: "Akut spondylit" },
  { value: "SX4115", title: "Akut diskospondylit" },
  { value: "SX412", title: "Akuta inflammationstillstånd, skelett" },
  { value: "SX4121", title: "Benhinneinflammation, akut" },
  { value: "SX4122", title: "Akut hypertrofisk osteodystrofi" },
  { value: "SX4123", title: "Akut kraniomandibulär osteopati" },
  { value: "SX4124", title: "Akut panosteit" },
  { value: "SX4125", title: "Akut senbensinflammation" },
  { value: "SX4130", title: "Akut epifysit" },
  { value: "SX42", title: "Kroniska infekt, infl förändringar, skelett" },
  { value: "SX421", title: "Kroniska infektioner, skelett" },
  { value: "SX4211", title: "Kronisk periostit, infektiös" },
  { value: "SX4212", title: "Kronisk ostit" },
  { value: "SX42121", title: "Kronisk ostit, infektiös" },
  { value: "SX4213", title: "Kronisk osteomyelit" },
  { value: "SX42133", title: "Kronisk primär osteomyelit" },
  { value: "SX42134", title: "Kronisk sekundär osteomyelit" },
  { value: "SX42135", title: "Osteomyelit i tillväxtzon/fysit" },
  { value: "SX42136", title: "Osteomyelit med bensekvester" },
  { value: "SX4214", title: "Kronisk spondylit" },
  { value: "SX4215", title: "Kronisk diskospondylit" },
  { value: "SX422", title: "Kroniska inflammationstillstånd, skelett" },
  { value: "SX4221", title: "Benhinneinflammation" },
  { value: "SX42211", title: "Kronisk periostit (överben)" },
  { value: "SX4222", title: "Kronisk hypertrofisk osteodystrofi" },
  { value: "SX4223", title: "Kraniomandibulär osteopati" },
  { value: "SX4224", title: "Panostit" },
  { value: "SX4225", title: "Sesamoidit" },
  { value: "SX4226", title: "Strålbenshälta/kronisk podotrochleit" },
  { value: "SX4227", title: "Deformerande beninflammation" },
  { value: "SX4228", title: "Enostosis-like lesions" },
  { value: "SX4229", title: "Skleros tredje karpalbenet" },
  { value: "SX4230", title: "Kronisk epifysit" },
  { value: "SX5", title: "Immunmedierade tillstånd, skelett" },
  { value: "SX6", title: "Tumör i skelett" },
  { value: "SX61", title: "Malign primär tumör i skelett" },
  { value: "SX611", title: "Kondrosarkom" },
  { value: "SX612", title: "Kondromyxosarkom" },
  { value: "SX613", title: "Osteosarkom" },
  { value: "SX614", title: "Fibrosarkom" },
  {
    value: "SX615",
    title: "Angiosarkom, hemangiendoteliom, hemangiopericytom",
  },
  { value: "SX62", title: "Benign tumör i skelett" },
  { value: "SX621", title: "Kondroblastom" },
  { value: "SX622", title: "Ostekondrom" },
  { value: "SX623", title: "Osteom" },
  { value: "SX624", title: "Osteoblastom" },
  { value: "SX625", title: "Fibrom" },
  { value: "SX626", title: "Fibröst histiocytom" },
  { value: "SX627", title: "Hemangiom" },
  { value: "SX628", title: "Jättecellstumör" },
  { value: "SX629", title: "Adamantinom" },
  { value: "SX63", title: "Metastas till skelett" },
  { value: "SX7", title: "Trauma, fr kr, lägeförändr, term skad, skelett" },
  { value: "SX71", title: "Fissur" },
  { value: "SX72", title: "Fraktur" },
  { value: "SX721", title: "Diafys/metafysfraktur" },
  { value: "SX7211", title: "Tvärfraktur" },
  { value: "SX7212", title: "Spiralfraktur" },
  { value: "SX7213", title: "Splitterfraktur" },
  { value: "SX7214", title: "Greenstickfraktur" },
  { value: "SX7215", title: "Stressfraktur" },
  { value: "SX722", title: "Epifysfraktur" },
  { value: "SX7221", title: "Lednära fraktur proximalt" },
  { value: "SX7222", title: "Lednära fraktur distalt" },
  { value: "SX723", title: "Fraktur in i angränsande led" },
  { value: "SX7231", title: "Fraktur in i angränsande led proximalt" },
  { value: "SX72311", title: "Kondylfraktur proximalt" },
  { value: "SX7232", title: "Fraktur in i angränsande led distalt" },
  { value: "SX72321", title: "Kondylfraktur distalt" },
  { value: "SX724", title: "Fraktur i tillväxtbrosk" },
  { value: "SX7241", title: "Fraktur i tillväxtbrosk, proximalt" },
  { value: "SX7242", title: "Fraktur i tillväxtbrosk, distalt" },
  { value: "SX725", title: "Avsprängningsfraktur/avulsion" },
  { value: "SX7251", title: "Avulsion av muskelfäste" },
  { value: "SX7252", title: "Avulsion av senfäste" },
  { value: "SX7253", title: "Avulsion av ligamentfäste" },
  { value: "SX726", title: "Fraktur av speciell typ" },
  { value: "SX7261", title: "Fraktur av osteofyt" },
  { value: "SX7262", title: "Öppen fraktur" },
  { value: "SX7263", title: "Patologisk fraktur" },
  { value: "SX7264", title: "Kompressionsfraktur" },
  { value: "SX7265", title: "Chipfraktur" },
  { value: "SX7266", title: "Slabfraktur" },
  { value: "SX7267", title: "Hörnstödsfraktur" },
  { value: "SX7268", title: "Benbroskfragm plant/palm (Birkeland)" },
  { value: "SX7269", title: "Benbroskfragment dorsalt" },
  { value: "SX729", title: "Kallus" },
  { value: "SX73", title: "Komplikation till frakturläkning" },
  {
    value: "SX731",
    title: "Försenad eller utebliven frakturläkning, vaskulär",
  },
  {
    value: "SX732",
    title: "Försenad eller utebliven frakturläkning, avaskulär",
  },
  { value: "SX733", title: "Felläkt fraktur" },
  { value: "SX734", title: "Ankylos av angränsande led pga skelettskada" },
  { value: "SX735", title: "Pseudoartros" },
  { value: "SX74", title: "Diskruptur traumatisk" },
  { value: "SX741", title: "Diskruptur med luxation mellan kotor" },
  { value: "SX742", title: "Diskruptur utan luxation mellan kotor" },
  { value: "SX75", title: "Övriga skelettskador" },
  { value: "SX751", title: "Sårskada till benhinna" },
  { value: "SX752", title: "Sårskada till skelett" },
  { value: "SX76", title: "Lägeförändring av ben" },
  { value: "SX761", title: "Överridande tornutskott (kissing spine)" },
  { value: "SX762", title: "Hovbenssänkning" },
  { value: "SX763", title: "Patellaupphakning" },
  {
    value: "SX764",
    title: "Catching, partiell/intermittent patellaupphakning",
  },
  {
    value: "SX9",
    title: "Idiopatiska ospecificerade multifaktoriella, skelett",
  },
  { value: "SZ", title: "Flera ben involverade/ospecificerat ben" },
  {
    value: "SZ0",
    title: "Normalvar, symtom u fastställd orsak, flera (ospec) ben",
  },
  { value: "SZ00", title: "Normalvariation, flera (ospec) ben" },
  {
    value: "SZ01",
    title: "Symtom på sjukdom u fastställd orsak, flera (ospec) ben",
  },
  { value: "SZ011", title: "Skelettömhet, flera (ospec) ben" },
  { value: "SZ012", title: "Torticollis flera (ospec) ben" },
  { value: "SZ013", title: "Ökad rörlighet i kotpelaren flera (ospec) ben" },
  {
    value: "SZ1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, flera (ospec) ben",
  },
  { value: "SZ10", title: "Missbildning, flera (ospec) ben" },
  { value: "SZ101", title: "Övertaliga skelettdelar, flera (ospec) ben" },
  { value: "SZ1011", title: "Polydaktyli flera (ospec) ben" },
  {
    value: "SZ102",
    title: "Aplasi eller hypoplasi av skelettdelar, flera (ospec) ben",
  },
  {
    value: "SZ1021",
    title: "Aplasi eller felställninng av dens axis flera (ospec) ben",
  },
  { value: "SZ1022", title: "Avsaknad av revben flera (ospec) ben" },
  { value: "SZ103", title: "Felställda skelettdelar  flera (ospec) ben" },
  { value: "SZ1031", title: "Felställning av revben flera (ospec) ben" },
  { value: "SZ1032", title: "Felställning av skovelbrosk flera (ospec) ben" },
  { value: "SZ1033", title: "Medial patellaluxation flera (ospec) ben" },
  { value: "SZ1034", title: "Lateral patellaluxation flera (ospec) ben" },
  { value: "SZ1035", title: "Patellaupphakning flera (ospec) ben" },
  { value: "SZ104", title: "Kondrodystrofi (akondroplasi) flera (ospec) ben" },
  { value: "SZ109", title: "Övriga skelettmissbildningar, flera (ospec) ben" },
  { value: "SZ1091", title: "Kvarstående fontanell flera (ospec) ben" },
  {
    value: "SZ1092",
    title: "Occipital dysplasi (först foramen magnum) flera (ospec) ben",
  },
  {
    value: "SZ1093",
    title: "Dysostos (defekt benbildn un fosterlivet) flera (ospec) ben",
  },
  { value: "SZ1094", title: "Kotmissbildningar flera (ospec) ben" },
  { value: "SZ10941", title: "Spina bifida flera (ospec) ben" },
  { value: "SZ10942", title: "Missbildade halskotor  flera (ospec) ben" },
  {
    value: "SZ10943",
    title: "Missbildade thorakolumbalkotor flera (ospec) ben",
  },
  { value: "SZ10944", title: "Missbildad sacrum flera (ospec) ben" },
  {
    value: "SZ10945",
    title: "Svansmissbildning (kroksvans) flera (ospec) ben",
  },
  { value: "SZ10946", title: "Sacralisation flera (ospec) ben" },
  {
    value: "SZ10947",
    title: "Fusion av kotkroppar (blockkota) flera (ospec) ben",
  },
  { value: "SZ10948", title: "Hemivertebra flera (ospec) ben" },
  { value: "SZ1095", title: "Missbildad bröstkorg flera (ospec) ben" },
  { value: "SZ1096", title: "Missbildad falang flera (ospec) ben" },
  { value: "SZ10961", title: "Medfödd klubbfot flera (ospec) ben" },
  {
    value: "SZ10962",
    title: "Syndaktali (sammanväxning av falanger), flera (ospec) ben",
  },
  { value: "SZ1097", title: "Missbildat bäcken flera (ospec) ben" },
  { value: "SZ10971", title: "Asymetri av bäckenhalvor flera (ospec) ben" },
  {
    value: "SZ1099",
    title: "Övriga ej def skelettmissbildningar, flera (ospec) ben",
  },
  {
    value: "SZ11",
    title: "Utvecklingsrubbn, tillväxtrubbn, flera (ospec) ben",
  },
  { value: "SZ111", title: "Samtidig ben och brosksjukdom, flera (ospec) ben" },
  { value: "SZ1111", title: "Osteokondros, flera (ospec) ben" },
  { value: "SZ11111", title: "Osteokondros utan ledmus, flera (ospec) ben" },
  { value: "SZ11112", title: "Osteokondros med ledmus, flera (ospec) ben" },
  {
    value: "SZ11113",
    title: "Subkondral bencysta av osteokondrostyp, flera (ospec) ben",
  },
  {
    value: "SZ11114",
    title: "Förtidigt / försent sluten epifys , flera (ospec) ben",
  },
  {
    value: "SZ11115",
    title: "Ununited processus anconeus(Armbågsledsdysplasi) fle",
  },
  {
    value: "SZ11116",
    title: "Avlösning av processus coronoideus flera (ospec) ben",
  },
  { value: "SZ11117", title: "Slabfraktur, flera (ospec) ben" },
  { value: "SZ1112", title: "Hörnstödsfraktur, flera (ospec) ben" },
  {
    value: "SZ1113",
    title: "Benbroskfragm plant/palm (Birkeland), flera (ospec) ben",
  },
  {
    value: "SZ112",
    title: "Cervikal, vertebral instabil/stenos (wobbler) fl (ospec) ben",
  },
  { value: "SZ113", title: "Osteokondrodysplasi , flera (ospec) ben" },
  { value: "SZ115", title: "Felaktig benställning, flera (ospec) ben" },
  {
    value: "SZ1151",
    title: "Felaktig benställning framben, flera (ospec) ben",
  },
  {
    value: "SZ11511",
    title: "Felaktig benställning pga retained cartilage, fl (ospec) ben",
  },
  {
    value: "SZ11512",
    title: "Felaktig benställn för tidigt sluten epifys, fle (ospec) ben",
  },
  { value: "SZ1152", title: "Felaktig benställning bakben, flera (ospec) ben" },
  {
    value: "SZ11521",
    title: "Coxa valgum (utåtvriden sned) flera (ospec) ben",
  },
  { value: "SZ11522", title: "Coxa varum   (sned) flera (ospec) ben" },
  { value: "SZ11523", title: "Genu valgum (kobent) flera (ospec) ben" },
  { value: "SZ11524", title: "Genu varum (hjulbent) flera (ospec) ben" },
  { value: "SZ1153", title: "Olika benlängd flera (ospec) ben" },
  { value: "SZ116", title: "Felaktig rygghållning flera (ospec) ben" },
  { value: "SZ1161", title: "Kyfos , karprygg flera (ospec) ben" },
  { value: "SZ1162", title: "Lordos svankrygg flera (ospec) ben" },
  { value: "SZ1163", title: "Skolios flera (ospec) ben" },
  {
    value: "SZ119",
    title: "Övriga Utvecklingsrubbn, tillväxtrubbn i, flera (ospec) ben",
  },
  { value: "SZ1191", title: "Panostit (endostsos), flera (ospec) ben" },
  { value: "SZ1192", title: "Bencysta, aseptisk bennekros, flera (ospec) ben" },
  { value: "SZ1193", title: "Kraniomandibulär osteopati, flera (ospec) ben" },
  {
    value: "SZ1194",
    title: "Metafysär osteopati (hypertro osteodystrofi), fl (ospec) ben",
  },
  { value: "SZ1195", title: "Legg-Perthes sjukdom flera (ospec) ben" },
  {
    value: "SZ2",
    title: "Metabol, nutrit, degen/dystrof förändr, flera (ospec) ben",
  },
  { value: "SZ20", title: "Metaboliska förändringar, flera (ospec) ben" },
  { value: "SZ201", title: "Osteomalaci / osteolys, flera (ospec) ben" },
  { value: "SZ202", title: "Osteoporos, flera (ospec) ben" },
  { value: "SZ203", title: "Osteodystrofi, flera (ospec) ben" },
  { value: "SZ204", title: "Benmetaplasier, flera (ospec) ben" },
  {
    value: "SZ2041",
    title: "Benmetaplasi nära kotpel, halsreg,thorakolumbalreg fl ben",
  },
  { value: "SZ21", title: "Nutritionella förändringar, flera (ospec) ben" },
  {
    value: "SZ22",
    title: "Degenerativa/dystrofiska förändringar, flera (ospec) ben",
  },
  { value: "SZ221", title: "Diskdegeneration flera (ospec) ben" },
  {
    value: "SZ2211",
    title: "Diskdegeneration utan förkalkning flera (ospec) ben",
  },
  {
    value: "SZ22111",
    title: "Lumbosacral (L7-S1 syndromet) flera (ospec) ben",
  },
  {
    value: "SZ2212",
    title: "Diskdegeneration med förkalkning flera (ospec) ben",
  },
  { value: "SZ222", title: "Diskbråck flera (ospec) ben" },
  { value: "SZ2221", title: "Diskbråck i cervikalregionen flera (ospec) ben" },
  {
    value: "SZ2222",
    title: "Diskbråck i thorakolumbalregionen flera (ospec) ben",
  },
  { value: "SZ223", title: "Spondylos flera (ospec) ben" },
  { value: "SZ2231", title: "Lokal spondylos flera (ospec) ben" },
  { value: "SZ2232", title: "Generell utbredd spondylos flera (ospec) ben" },
  {
    value: "SZ224",
    title: "Pulmonär hypertrofisk osteopati, flera (ospec) ben",
  },
  { value: "SZ225", title: "Aseptisk bennekros, flera (ospec) ben" },
  { value: "SZ23", title: "Hormonella förändringar, flera (ospec) ben" },
  { value: "SZ3", title: "Cirkulatoriska förändringar, flera (ospec) ben" },
  { value: "SZ30", title: "Blödningar, flera (ospec) ben" },
  { value: "SZ301", title: "Subperiostal blödning, flera (ospec) ben" },
  { value: "SZ302", title: "Blödning till epiduralrum flera (ospec) ben" },
  { value: "SZ31", title: "Ödem, flera (ospec) ben" },
  { value: "SZ32", title: "Infarkter, flera (ospec) ben" },
  { value: "SZ33", title: "Hygrom, flera (ospec) ben" },
  { value: "SZ34", title: "Trombos / emboli, flera (ospec) ben" },
  {
    value: "SZ35",
    title: "Arterio-atero-skleros förändringar, flera (ospec) ben",
  },
  { value: "SZ4", title: "Infekt, infl förändringar, flera (ospec) ben" },
  { value: "SZ40", title: "Specifika infektionssjukdomar, flera (ospec) ben" },
  { value: "SZ41", title: "Akuta inflammationstillstånd, flera (ospec) ben" },
  {
    value: "SZ411",
    title: "Akut serös / serofibrinös inflammation, flera (ospec) ben",
  },
  { value: "SZ412", title: "Akut purulent inflammation, flera (ospec) ben" },
  {
    value: "SZ413",
    title: "Specifika akuta infl tillstånd, flera (ospec) ben",
  },
  { value: "SZ419", title: "Övriga akuta infl tillstånd, flera (ospec) ben" },
  { value: "SZ4191", title: "Akut periostit, flera (ospec) ben" },
  { value: "SZ41911", title: "Sekundär akut periostit, flera (ospec) ben" },
  { value: "SZ4192", title: "Akut ostit, flera (ospec) ben" },
  { value: "SZ41921", title: "Akut sesamoidit flera (ospec) ben" },
  { value: "SZ41922", title: "Akut podotrokleit flera (ospec) ben" },
  { value: "SZ41923", title: "Akut kotsenbensinflammation flera (ospec) ben" },
  { value: "SZ4193", title: "Akut osteomyelit, flera (ospec) ben" },
  { value: "SZ41931", title: "Sekundär akut osteomyelit, flera (ospec) ben" },
  {
    value: "SZ41932",
    title: "Sekundär akut osteomyelit , flera (ospec) ben e kir ingrepp",
  },
  { value: "SZ4195", title: "Akut diskospondylit flera (ospec) ben" },
  {
    value: "SZ4198",
    title: "Akut fysit, flera (ospec) ben  flera (ospec) ben",
  },
  {
    value: "SZ42",
    title: "Kroniska inflammationstillstånd, flera (ospec) ben",
  },
  { value: "SZ421", title: "Kronisk serös inflammation, flera (ospec) ben" },
  { value: "SZ422", title: "Purulent inflammation, flera (ospec) ben" },
  { value: "SZ423", title: "Abscess/fistel, flera (ospec) ben" },
  {
    value: "SZ424",
    title: "Specifika kroniska infl tillstånd, flera (ospec) ben",
  },
  {
    value: "SZ429",
    title: "Övriga kroniska infl tillstånd, flera (ospec) ben",
  },
  { value: "SZ4291", title: "Kronisk periostit (överben), flera (ospec) ben" },
  { value: "SZ42911", title: "Sekundär kronisk periostit, flera (ospec) ben" },
  { value: "SZ4292", title: "Kronisk ostit, flera (ospec) ben" },
  { value: "SZ42921", title: "Kronisk sesamoidit flera (ospec) ben" },
  { value: "SZ42922", title: "Kronisk podotrokleit flera (ospec) ben" },
  {
    value: "SZ42923",
    title: "Kronisk kotsenbensinflammation flera (ospec) ben",
  },
  { value: "SZ4293", title: "Kronisk osteomyelit flera (ospec) ben" },
  {
    value: "SZ42931",
    title: "Sekundär kronisk osteomyelit, flera (ospec) ben",
  },
  {
    value: "SZ42932",
    title: "Sekundär kronisk osteomyelit , fle (ospec) ben e kir ingrepp",
  },
  { value: "SZ4294", title: "Deformerande beninflammation, flera (ospec) ben" },
  { value: "SZ4295", title: "Kronisk diskospondylit flera (ospec) ben" },
  { value: "SZ4298", title: "Kronisk fysit , flera (ospec) ben" },
  { value: "SZ43", title: "Parasitära sjukdomar, flera (ospec) ben" },
  { value: "SZ5", title: "Immunmedierade tillstånd, flera (ospec) ben" },
  { value: "SZ6", title: "Neoplastiska förändringar, flera (ospec) ben" },
  { value: "SZ61", title: "Malign primär tumör i fleraben o/e brosk inkl led" },
  {
    value: "SZ611",
    title: "Malign tumör utgången från broskvävnad, flera (ospec) ben",
  },
  { value: "SZ6111", title: "Kondrosarkom, flera (ospec) ben" },
  { value: "SZ6112", title: "Kondromyxosarkom, flera (ospec) ben" },
  {
    value: "SZ612",
    title: "Malign tumör utgången från benvävnad, flera (ospec) ben",
  },
  { value: "SZ6121", title: "Osteosarkom, flera (ospec) ben" },
  {
    value: "SZ613",
    title: "Malign tumör av bindvävsursprung, flera (ospec) ben",
  },
  { value: "SZ6131", title: "Fibrosarkom, flera (ospec) ben" },
  {
    value: "SZ614",
    title: "Malign tumör av vaskulärt eller lymf urspr, fle (ospec) ben",
  },
  {
    value: "SZ6141",
    title: "Angiosarkom, hemangioendoteliom, ,flera (ospec) ben",
  },
  { value: "SZ615", title: "Metastas till skelett, flera (ospec) ben" },
  {
    value: "SZ619",
    title: "Skelettumör av malign karaktär, UNS, flera (ospec) ben",
  },
  {
    value: "SZ62",
    title: "Benign tumör i ben o/e brosk inkl led, flera (ospec) ben",
  },
  {
    value: "SZ621",
    title: "Benign tumör utgången från broskvävnad, flera (ospec) ben",
  },
  { value: "SZ6211", title: "Kondroblastom, flera (ospec) ben" },
  { value: "SZ6212", title: "Ostekondrom, flera (ospec) ben" },
  { value: "SZ6213", title: "Enkondrom, flera (ospec) ben" },
  {
    value: "SZ622",
    title: "Benign tumör utgången från benvävnad, flera (ospec) ben",
  },
  { value: "SZ6221", title: "Osteom , flera (ospec) ben" },
  { value: "SZ6222", title: "Osteoblastom, flera (ospec) ben" },
  {
    value: "SZ623",
    title: "Benign tumör av bindvävsursprung, flera (ospec) ben",
  },
  { value: "SZ6231", title: "Fibrom, flera (ospec) ben" },
  { value: "SZ6232", title: "Fibröst histiocytom, flera (ospec) ben" },
  {
    value: "SZ624",
    title: "Benign tumör av vaskulärt eller lymf urspr, fle (ospec) ben",
  },
  { value: "SZ6241", title: "Hemangiom , flera (ospec) ben" },
  { value: "SZ6242", title: "Lymfangiom, flera (ospec) ben" },
  { value: "SZ628", title: "Övriga benigna skelettumörer, flera (ospec) ben" },
  { value: "SZ6281", title: "Jättecellstumör, flera (ospec) ben" },
  {
    value: "SZ6282",
    title: "Adamantinom (tandanlagsnybildning), flera (ospec) ben",
  },
  {
    value: "SZ629",
    title: "Skelettumör av benign karaktär, UNS, flera (ospec) ben",
  },
  {
    value: "SZ7",
    title: "Trauma, fr kr, lägeförändr, term skad, flera (ospec) ben",
  },
  { value: "SZ71", title: "Traumatiska / mekaniska skador, flera (ospec) ben" },
  { value: "SZ711", title: "Fissur, flera (ospec) ben" },
  { value: "SZ712", title: "Epifysfraktur, flera (ospec) ben" },
  {
    value: "SZ7121",
    title: "Epifysfraktur (epifysglidning) proximalt, flera (ospec) ben",
  },
  {
    value: "SZ7122",
    title: "Epifysfraktur (epifysglidning) distalt, flera (ospec) ben",
  },
  { value: "SZ7123", title: "Epifysiolys, flera (ospec) ben" },
  { value: "SZ71231", title: "Proximal epifysiolys, flera (ospec) ben" },
  { value: "SZ71232", title: "Distal epifysiolys, flera (ospec) ben" },
  { value: "SZ7129", title: "Öriga epifysskador, flera (ospec) ben" },
  { value: "SZ713", title: "Fraktur, flera (ospec) ben" },
  {
    value: "SZ7131",
    title: "Diafysfraktur  / fraktur på korpus, flera (ospec) ben",
  },
  {
    value: "SZ7132",
    title: "Lednära fraktur proximalt /kranialt, flera (ospec) ben",
  },
  {
    value: "SZ7133",
    title: "Lednära fraktur distalt /kaudalt, flera (ospec) ben",
  },
  {
    value: "SZ7134",
    title: "Frakt som berör angräns led/leder prox/kran, fle (ospec) ben",
  },
  {
    value: "SZ7135",
    title: "Frakt som berör angräns led/leder dist/kaud, fle (ospec) ben",
  },
  {
    value: "SZ7136",
    title: "Avsprängningsfraktur proximalt /kranialt, flera (ospec) ben",
  },
  {
    value: "SZ7137",
    title: "Avsprängningsfraktur distalt /kaudalt , flera (ospec) ben",
  },
  { value: "SZ7139", title: "Kompressionsfraktur, flera (ospec) ben" },
  { value: "SZ714", title: "Fraktur av speciell typ, flera (ospec) ben" },
  {
    value: "SZ7141",
    title: "Kortikal subperiostal fraktur (greenstick), fl (ospec) ben",
  },
  { value: "SZ7142", title: "Fraktur av osteofyt, flera (ospec) ben" },
  { value: "SZ7143", title: "Mandibulasymfysfraktur flera (ospec) ben" },
  { value: "SZ7144", title: "Fraktur av underkäke flera (ospec) ben" },
  { value: "SZ7145", title: "Symfysfraktur av hårda gommen flera (ospec) ben" },
  {
    value: "SZ7146",
    title: "Fraktur av skallens ben ospecificerat flera (ospec) ben",
  },
  { value: "SZ7147", title: "Acetabulumfraktur flera (ospec) ben" },
  { value: "SZ7148", title: "Kotsenbensfraktur flera (ospec) ben" },
  {
    value: "SZ7149",
    title: "Patologisk fraktur, spontanfraktur UNS, flera (ospec) ben",
  },
  {
    value: "SZ715",
    title: "Komplikation till frakturläkning, flera (ospec) ben",
  },
  { value: "SZ7151", title: "Pseudoartros, flera (ospec) ben" },
  {
    value: "SZ7152",
    title: "Atrofi av benvävnad kompl t frakturläkn, flera (ospec) ben",
  },
  { value: "SZ7153", title: "Felläkt fraktur, flera (ospec) ben" },
  {
    value: "SZ7154",
    title: "Ankylos av angränsande led pga skelettsk, flera (ospec) ben",
  },
  { value: "SZ718", title: "Diskruptur flera (ospec) ben" },
  {
    value: "SZ7181",
    title: "Diskruptur med luxation mellan kotor flera (ospec) ben",
  },
  {
    value: "SZ7182",
    title: "Diskruptur utan luxation mellan kotor flera (ospec) ben",
  },
  { value: "SZ719", title: "Övriga skelettskador, flera (ospec) ben" },
  { value: "SZ72", title: "Främmande kroppar, flera (ospec) ben" },
  { value: "SZ73", title: "Lägesförändringar, flera (ospec) ben" },
  {
    value: "SZ731",
    title: "Intilliggande överridande tornutskott (kissing spine) fl ben",
  },
  { value: "SZ732", title: "Ileo-sakralledsluxation flera (ospec) ben" },
  { value: "SZ733", title: "Luxation av bröstben flera (ospec) ben" },
  { value: "SZ734", title: "Luxation av revben flera (ospec) ben" },
  { value: "SZ735", title: "Hovbenssäkning flera (ospec) ben" },
  { value: "SZ739", title: "Övrig lägesförändring, flera (ospec) ben" },
  { value: "SZ74", title: "Termiska skador, flera (ospec) ben" },
  { value: "SZ741", title: "Brännskada, flera (ospec) ben" },
  { value: "SZ742", title: "Köldskada, flera (ospec) ben" },
  { value: "SZ8", title: "Toxiska förändringar, flera (ospec) ben" },
  {
    value: "SZ9",
    title: "Idiopatiska ospecificerade multifakt, flera (ospec) ben",
  },
  { value: "U", title: "Urinorganens sjukdomar" },
  { value: "UA", title: "Övre urinvägar; njure, uretär" },
  { value: "UA0", title: "Normalvar, symtom u fastställd orsak, njure/uretär" },
  { value: "UA00", title: "Normalvariation, njure/uretär" },
  {
    value: "UA01",
    title: "Symtom på sjukdom u fastställd orsak, njure/uretär",
  },
  {
    value: "UA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, njure/uretär",
  },
  { value: "UA10", title: "Missbildning, njure/uretär" },
  { value: "UA101", title: "Cystanjure" },
  { value: "UA102", title: "Njuraplasi" },
  { value: "UA103", title: "Progressiv nefropati (PNP)" },
  { value: "UA104", title: "Ektopisk uretär" },
  { value: "UA11", title: "Utvecklingsrubbn, tillväxtrubbn, njure/uretär" },
  {
    value: "UA2",
    title: "Metabol, nutrit, degen/dystrof förändr, njure/uretär",
  },
  { value: "UA20", title: "Metaboliska förändringar, njure/uretär" },
  { value: "UA201", title: "Njursten övre urinvägar" },
  { value: "UA2011", title: "Magnesium-ammonium-fosfat stenar övre urinvägar" },
  { value: "UA2012", title: "Kalciumfosfat stenar övre urinvägar" },
  { value: "UA2013", title: "Kalciumoxalat stenar övre urinvägar" },
  { value: "UA2014", title: "Cystin stenar övre urinvägar" },
  { value: "UA2015", title: "Urat stenar övre urinvägar" },
  { value: "UA2016", title: "Blandad typ urinstenar övre urinvägar" },
  { value: "UA2019", title: "Övriga urinstenar övre urinvägar" },
  { value: "UA202", title: "Amyloidos" },
  { value: "UA203", title: "Nefros" },
  { value: "UA204", title: "Fanconis syndrom" },
  { value: "UA21", title: "Nutritionella förändringar, njure/uretär" },
  {
    value: "UA22",
    title: "Degenerativa/dystrofiska förändringar, njure/uretär",
  },
  { value: "UA23", title: "Hormonella förändringar, njure/uretär" },
  { value: "UA3", title: "Cirkulatoriska förändringar, njure/uretär" },
  { value: "UA30", title: "Blödningar, njure/uretär" },
  { value: "UA301", title: "Blödningar i njure" },
  { value: "UA302", title: "Blödningar i uretär" },
  { value: "UA32", title: "Infarkter, njure/uretär" },
  { value: "UA34", title: "Trombos / emboli, njure/uretär" },
  { value: "UA35", title: "Arterio-atero-skleros förändringar, njure/uretär" },
  { value: "UA4", title: "Infekt, infl förändringar, njure/uretär" },
  { value: "UA40", title: "Specifika infektionssjukdomar, njure/uretär" },
  { value: "UA41", title: "Akuta inflammationstillstånd, njure/uretär" },
  {
    value: "UA411",
    title: "Akut serös / serofibrinös inflammation, njure/uretär",
  },
  { value: "UA412", title: "Akut purulent inflammation, njure/uretär" },
  { value: "UA4121", title: "Akut pyelonefrit" },
  { value: "UA4122", title: "Akut abscenderande nefrit" },
  { value: "UA413", title: "Specifika akuta infl tillstånd, njure/uretär" },
  { value: "UA419", title: "Övriga akuta infl tillstånd, njure/uretär" },
  { value: "UA4191", title: "Akut nefrit" },
  { value: "UA4192", title: "Akut glomerulinefrit" },
  { value: "UA4193", title: "Akut interstitiell nefrit" },
  { value: "UA42", title: "Kroniska inflammationstillstånd, njure/uretär" },
  { value: "UA422", title: "Purulent inflammation, njure/uretär" },
  { value: "UA4221", title: "Kronisk pyelonefrit" },
  { value: "UA423", title: "Abscess/fistel, njure/uretär" },
  { value: "UA4231", title: "Kronisk abscederande nefrit" },
  { value: "UA424", title: "Specifika kroniska infl tillstånd, njure/uretär" },
  { value: "UA429", title: "Övriga kroniska infl tillstånd, njure/uretär" },
  { value: "UA4291", title: "Kronisk nefrit" },
  { value: "UA4292", title: "Kronisk glomerulinefrit" },
  { value: "UA4293", title: "Kronisk interstitiell nefrit" },
  { value: "UA43", title: "Parasitära sjukdomar, njure/uretär" },
  { value: "UA5", title: "Immunmedierade tillstånd, njure/uretär" },
  { value: "UA6", title: "Neoplastiska förändringar, njure/uretär" },
  { value: "UA61", title: "Adenokarcinom i njure" },
  {
    value: "UA62",
    title: "Multifokalt cystiskt adenokarcinom m dermatofibros",
  },
  { value: "UA69", title: "Övriga njurtumörer" },
  {
    value: "UA7",
    title: "Trauma, fr kr, lägeförändr, term skad, njure/uretär",
  },
  { value: "UA71", title: "Traumatiska / mekaniska skador, njure/uretär" },
  { value: "UA72", title: "Främmande kroppar, njure/uretär" },
  { value: "UA73", title: "Lägesförändringar, njure/uretär" },
  { value: "UA74", title: "Termiska skador, njure/uretär" },
  { value: "UA8", title: "Toxiska förändringar, njure/uretär" },
  { value: "UA81", title: "Myoglobininducerad nefros" },
  { value: "UA82", title: "Läkemedelsinducerad toxisk nefros" },
  { value: "UA89", title: "Övrig toxisk nefros" },
  { value: "UA9", title: "Idiopatiska, ospecif, multifakt, njure/uretär" },
  { value: "UB", title: "Nedre urinvägar; urinblåsa uretra" },
  {
    value: "UB0",
    title: "Normalvar, symtom u fastställd orsak, urinblåsa/uretra",
  },
  { value: "UB00", title: "Normalvariation, urinblåsa/uretra" },
  {
    value: "UB01",
    title: "Symtom på sjukdom u fastställd orsak, urinblåsa/uretra",
  },
  { value: "UB011", title: "Hematuri" },
  { value: "UB012", title: "Urinträngningar u fastställd orsak" },
  { value: "UB013", title: "Tömningssvårigheter" },
  { value: "UB014", title: "Urininkontinens" },
  { value: "UB015", title: "Proteinuri" },
  {
    value: "UB1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, urinblåsa/uretra",
  },
  { value: "UB10", title: "Missbildning, urinblåsa/uretra" },
  { value: "UB11", title: "Utvecklingsrubbn, tillväxtrubbn, urinblåsa/uretra" },
  { value: "UB111", title: "Pelvic Bladder" },
  { value: "UB112", title: "Urinblåsdivertikel" },
  { value: "UB113", title: "Urachusfistel" },
  {
    value: "UB2",
    title: "Metabol, nutrit, degen/dystrof förändr, urinblåsa/uretra",
  },
  { value: "UB20", title: "Metaboliska förändringar, urinblåsa/uretra" },
  { value: "UB201", title: "Urinsten nedre urin vägar" },
  {
    value: "UB2011",
    title: "Magnesium-ammonium-fosfat stenar nedre urin vägar",
  },
  { value: "UB2012", title: "Kalciumfosfat stenar nedre urin vägar" },
  { value: "UB2013", title: "Kalciumoxalat stenar nedre urin vägar" },
  { value: "UB2014", title: "Cystin stenar nedre urin vägar" },
  { value: "UB2015", title: "Urat stenar nedre urin vägar" },
  { value: "UB2016", title: "Blandad typ urinstenar nedre urin vägar" },
  { value: "UB2017", title: "Uretraplugg nedre urin vägar" },
  { value: "UB2019", title: "Övriga urinstenar nedre urin vägar" },
  { value: "UB21", title: "Nutritionella förändringar, urinblåsa/uretra" },
  {
    value: "UB22",
    title: "Degenerativa/dystrofiska förändringar, urinblåsa/uretra",
  },
  { value: "UB23", title: "Hormonella förändringar, urinblåsa/uretra" },
  { value: "UB3", title: "Cirkulatoriska förändringar, urinblåsa/uretra" },
  { value: "UB30", title: "Blödningar, urinblåsa/uretra" },
  { value: "UB31", title: "Ödem, urinblåsa/uretra" },
  { value: "UB32", title: "Infarkter, urinblåsa/uretra" },
  { value: "UB33", title: "Hygrom, urinblåsa/uretra" },
  { value: "UB34", title: "Trombos / emboli, urinblåsa/uretra" },
  {
    value: "UB35",
    title: "Arterio-atero-skleros förändringar, urinblåsa/uretra",
  },
  { value: "UB4", title: "Infekt, infl förändringar, urinblåsa/uretra" },
  { value: "UB40", title: "Specifika infektionssjukdomar, urinblåsa/uretra" },
  { value: "UB41", title: "Akuta inflammationstillstånd, urinblåsa/uretra" },
  {
    value: "UB411",
    title: "Akut serös / serofibrinös inflammation, urinblåsa/uretra",
  },
  { value: "UB412", title: "Akut purulent inflammation, urinblåsa/uretra" },
  { value: "UB413", title: "Specifika akuta infl tillstånd, urinblåsa/uretra" },
  { value: "UB419", title: "Övriga akuta infl tillstånd, urinblåsa/uretra" },
  { value: "UB4191", title: "Akut cystit" },
  { value: "UB4192", title: "Akut uretrit" },
  { value: "UB42", title: "Kroniska inflammationstillstånd, urinblåsa/uretra" },
  { value: "UB421", title: "Kronisk serös inflammation, urinblåsa/uretra" },
  { value: "UB422", title: "Purulent inflammation, urinblåsa/uretra" },
  { value: "UB423", title: "Abscess/fistel, urinblåsa/uretra" },
  {
    value: "UB424",
    title: "Specifika kroniska infl tillstånd, urinblåsa/uretra",
  },
  { value: "UB429", title: "Övriga kroniska infl tillstånd, urinblåsa/uretra" },
  { value: "UB4291", title: "Kronisk cystit" },
  { value: "UB4292", title: "Kronisk uretrit" },
  { value: "UB43", title: "Parasitära sjukdomar, urinblåsa/uretra" },
  { value: "UB5", title: "Immunmedierade tillstånd, urinblåsa/uretra" },
  { value: "UB6", title: "Neoplastiska förändringar, urinblåsa/uretra" },
  { value: "UB61", title: "Tumör i urinblåsa" },
  { value: "UB62", title: "Polyper i urinblåsa" },
  { value: "UB69", title: "Övriga tumörer i nedre urinvägarna" },
  {
    value: "UB7",
    title: "Trauma, fr kr, lägeförändr, term skad, urinblåsa/uretra",
  },
  { value: "UB71", title: "Traumatiska / mekaniska skador, urinblåsa/uretra" },
  { value: "UB711", title: "Urinblåseruptur" },
  { value: "UB712", title: "Urinblåseprolaps" },
  { value: "UB72", title: "Främmande kroppar, urinblåsa/uretra" },
  { value: "UB73", title: "Lägesförändringar, urinblåsa/uretra" },
  { value: "UB74", title: "Termiska skador, urinblåsa/uretra" },
  { value: "UB8", title: "Toxiska förändringar, urinblåsa/uretra" },
  { value: "UB9", title: "Idiopatiska, ospecif, multifakt, urinblåsa/uretra" },
  { value: "UB91", title: "Blåsatoni" },
  { value: "UB92", title: "Inkontinens" },
  { value: "UB921", title: "Inkontinens efter ovariehysterektomi" },
  { value: "UB93", title: "Reflexdyssynergi" },
  { value: "UB94", title: "Idiopatisk cystit" },
  { value: "XB", title: "Behandlingar på friska djur" },
  { value: "XB10", title: "Kastration (NK,OHE), behandling" },
  { value: "XB11", title: "Sterilisering (vasektomi), behandling" },
  { value: "XB12", title: "Avhorning, behandling" },
  { value: "XB13", title: "Kloklippning, behandling" },
  { value: "XB14", title: "Sporrextirpation, behandling" },
  { value: "XB15", title: "Vingklippning, behandling" },
  { value: "XB16", title: "Näbbklippning, behandling" },
  { value: "XB17", title: "Verkning av hov/klöv, behandling" },
  { value: "XB18", title: "Vargtandsextraktion, behandling" },
  { value: "XB19", title: "Pälsvård, behandling" },
  { value: "XB20", title: "Nosringning, behandling" },
  { value: "XB21", title: "Skoning (normal), behandling" },
  { value: "XB22", title: "ID-chipmärkning, behandling" },
  { value: "XB23", title: "ID-tatuering, behandling" },
  { value: "XB24", title: "ID-märkning annan metod, behandling" },
  { value: "XB30", title: "Avlivning på begäran" },
  { value: "XB31", title: "Bad, behandling" },
  { value: "XB61", title: "Brunstinducering" },
  { value: "XB62", title: "Uppskjutande/hämmande av brunst el löpning" },
  { value: "XB63", title: "Antikonceptionell behandling" },
  { value: "XB64", title: "Abortinducering, behandling" },
  { value: "XB65", title: "Normalförlossning, behandling" },
  { value: "XB66", title: "Förlossningsinduktion, behandling" },
  { value: "XB67", title: "Kejsarsnitt/sectio cecarea, behandling" },
  { value: "XB68", title: "Hormonbehandling av handjur" },
  { value: "XB69", title: "Reproduktion, behandling" },
  { value: "XB99", title: "Övriga åtgärder på friska djur, behandling" },
  { value: "XP", title: "Profylax och undersökningar" },
  { value: "XP10", title: "Rådgivning, profylax" },
  { value: "XP11", title: "Vaccination, profylax" },
  { value: "XP12", title: "Behandling mot ektoparasiter, profylax" },
  { value: "XP13", title: "Avmaskning, profylax" },
  { value: "XP14", title: "Vitamin/mineralbehandling, profylax" },
  { value: "XP15", title: "Antibiotikabehandling, profylax" },
  { value: "XP19", title: "Övrig medicinsk behandling, profylax" },
  { value: "XP20", title: "Röntgenundersökning , profylax" },
  { value: "XP201", title: "Röntgenundersökning, besiktning" },
  { value: "XP202", title: "Röntgenundersökning, export" },
  { value: "XP21", title: "Ögonundersökning, profylax" },
  { value: "XP22", title: "Tand/munhåleundersökning, profylax" },
  { value: "XP23", title: "Funktionsprov, arbetsprov, profylax" },
  { value: "XP24", title: "Ålders- eller könsbestämning, profylax" },
  { value: "XP25", title: "Lab.analys av mjölk, blod, urin m.m." },
  { value: "XP30", title: "Hälsokontroll" },
  { value: "XP301", title: "Senior hälsokontroll" },
  { value: "XP31", title: "Friskintyg" },
  { value: "XP32", title: "Besiktning" },
  { value: "XP321", title: "Besiktning, avbruten" },
  { value: "XP33", title: "Intyg" },
  { value: "XP40", title: "Omhändertagande av dött djur" },
  { value: "XP41", title: "Obduktion" },
  { value: "XP42", title: "Postmortal undersökning" },
  { value: "XP60", title: "Gynekologisk undersökning, profylax" },
  { value: "XP61", title: "Dräktighetsundersökning, profylax" },
  { value: "XP62", title: "Spermaprov- fertilitetskontroll, profylax" },
  {
    value: "XP63",
    title: "Sexuell hälsokontroll/fertilitetsundersökning, profylax",
  },
  { value: "XP99", title: "Övrigt, profylax" },
  { value: "XP991", title: "Övrig undersökning, profylax" },
  { value: "XU", title: "Uppdrag" },
  { value: "XU10", title: "Uppdrag djurpass" },
  { value: "XU11", title: "Uppdrag intyg" },
  { value: "XU12", title: "Uppdrag hälsoundersökning med intyg" },
  { value: "XU13", title: "Uppdrag hälsoundersökning utan intyg" },
  { value: "XU14", title: "Uppdrag vid export (tredje land)" },
  { value: "XU15", title: "Uppdrag vid gränskontroll (tredje land)" },
  { value: "XU16", title: "Uppdrag vid utförsel/införsel (EU)" },
  { value: "XU17", title: "Uppdrag åt HF/Svensk Mjölk" },
  { value: "XU18", title: "Uppdrag åt SJV" },
  { value: "XU19", title: "Uppdrag åt SLV" },
  { value: "XU20", title: "Uppdrag åt SvDHV" },
  { value: "XU21", title: "Uppdrag åt kommun" },
  { value: "XU22", title: "Uppdrag åt polisen" },
  { value: "XU23", title: "Uppdrag åt övriga myndigheter/organisationer" },
  { value: "XU30", title: "Uppdrag planerade besök" },
  { value: "XU31", title: "Uppdrag tillsyn delegerad behandling" },
  { value: "XU32", title: "Uppdrag utlämn/förskrivn, delegerad behandling" },
  { value: "XU40", title: "Summarapportering" },
  { value: "XU99", title: "Uppdrag övrigt" },
  { value: "XX8806", title: "Delegerad behand. för avstämn." },
  { value: "XX8807", title: "Uppföljande behand. för avstämn." },
  { value: "XX8808", title: "Svinn" },
  { value: "XX8809", title: "Narkos" },
  { value: "XX8811", title: "Ultraljudsundersökning" },
  { value: "Z", title: "Öron sjukdomar" },
  { value: "ZA", title: "Ytteröra, öronlapp" },
  {
    value: "ZA0",
    title: "Normalvar, symtom u fastställd orsak, ytteröra/öronlapp",
  },
  { value: "ZA00", title: "Normalvariation, ytteröra/öronlapp" },
  {
    value: "ZA01",
    title: "Symtom på sjukdom u fastställd orsak, ytteröra/öronlapp",
  },
  {
    value: "ZA1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, ytteröra/öronlapp",
  },
  { value: "ZA10", title: "Missbildning, ytteröra/öronlapp" },
  { value: "ZA101", title: "Öronfistel" },
  { value: "ZA102", title: "Missbildat ytteröra" },
  {
    value: "ZA11",
    title: "Utvecklingsrubbn, tillväxtrubbn, ytteröra/öronlapp",
  },
  {
    value: "ZA2",
    title: "Metabol, nutrit, degen/dystrof förändr, ytteröra/öronlapp",
  },
  { value: "ZA20", title: "Metaboliska förändringar, ytteröra/öronlapp" },
  { value: "ZA21", title: "Nutritionella förändringar, ytteröra/öronlapp" },
  {
    value: "ZA22",
    title: "Degenerativa/dystrofiska förändringar, ytteröra/öronlapp",
  },
  { value: "ZA23", title: "Hormonella förändringar, ytteröra/öronlapp" },
  { value: "ZA3", title: "Cirkulatoriska förändringar, ytteröra/öronlapp" },
  { value: "ZA30", title: "Blödningar, ytteröra/öronlapp" },
  { value: "ZA301", title: "Blödning från hörselgången" },
  { value: "ZA31", title: "Ödem, ytteröra/öronlapp" },
  { value: "ZA311", title: "Ödem i öronlapp" },
  { value: "ZA32", title: "Infarkter, ytteröra/öronlapp" },
  { value: "ZA33", title: "Hygrom, ytteröra/öronlapp" },
  { value: "ZA34", title: "Trombos / emboli, ytteröra/öronlapp" },
  {
    value: "ZA35",
    title: "Arterio-atero-skleros förändringar, ytteröra/öronlapp",
  },
  { value: "ZA4", title: "Infekt, infl förändringar, ytteröra/öronlapp" },
  { value: "ZA40", title: "Specifika infektionssjukdomar, ytteröra/öronlapp" },
  { value: "ZA401", title: "Pseudomonasotit" },
  { value: "ZA402", title: "Öronpapillom / öronvårtor" },
  { value: "ZA403", title: "Malasseziaotit (jästsvamp)" },
  { value: "ZA41", title: "Akuta inflammationstillstånd, ytteröra/öronlapp" },
  {
    value: "ZA411",
    title: "Akut serös / serofibrinös inflammation, ytteröra/öronlapp",
  },
  { value: "ZA412", title: "Akut purulent inflammation, ytteröra/öronlapp" },
  { value: "ZA4121", title: "Akut purulent extern otit" },
  {
    value: "ZA413",
    title: "Specifika akuta infl tillstånd, ytteröra/öronlapp",
  },
  { value: "ZA419", title: "Övriga akuta infl tillstånd, ytteröra/öronlapp" },
  { value: "ZA4191", title: "Akut extern otit" },
  {
    value: "ZA42",
    title: "Kroniska inflammationstillstånd, ytteröra/öronlapp",
  },
  { value: "ZA421", title: "Kronisk serös inflammation, ytteröra/öronlapp" },
  { value: "ZA422", title: "Purulent inflammation, ytteröra/öronlapp" },
  { value: "ZA4221", title: "Kronisk purulent otit" },
  { value: "ZA423", title: "Abscess/fistel, ytteröra/öronlapp" },
  {
    value: "ZA424",
    title: "Specifika kroniska infl tillstånd, ytteröra/öronlapp",
  },
  { value: "ZA4241", title: "Kronisk bakteriell otit (ej pseudomonas)" },
  {
    value: "ZA429",
    title: "Övriga kroniska infl tillstånd, ytteröra/öronlapp",
  },
  { value: "ZA4291", title: "Kronisk extern otit" },
  { value: "ZA4292", title: "Kronisk proliferativ extern otit" },
  { value: "ZA43", title: "Parasitära sjukdomar, ytteröra/öronlapp" },
  { value: "ZA431", title: "Demodex, ytteröra/öronlapp" },
  { value: "ZA432", title: "Otodectes" },
  { value: "ZA433", title: "Mygg, ytteröra/öronlapp" },
  { value: "ZA434", title: "Flugor, ytteröra/öronlapp" },
  { value: "ZA5", title: "Immunmedierade tillstånd, ytteröra/öronlapp" },
  { value: "ZA51", title: "Allergi, ytteröra/öronlapp" },
  { value: "ZA512", title: "Atopisk dermatit, ytteröra/öronlapp" },
  { value: "ZA513", title: "Sterila granulom, ytteröra/öronlapp" },
  { value: "ZA514", title: "Födoämnesreaktioner/allergi, ytteröra/öronlapp" },
  { value: "ZA515", title: "Vaskulit, ytteröra/öronlapp" },
  { value: "ZA536", title: "Equine aural plaque" },
  { value: "ZA6", title: "Neoplastiska förändringar, ytteröra/öronlapp" },
  { value: "ZA61", title: "Tumör i öronlapp" },
  { value: "ZA62", title: "Tumör i hörselgång" },
  {
    value: "ZA7",
    title: "Trauma, fr kr, lägeförändr, term skad, ytteröra/öronlapp",
  },
  { value: "ZA71", title: "Traumatiska / mekaniska skador, ytteröra/öronlapp" },
  { value: "ZA711", title: "Blodöra" },
  { value: "ZA712", title: "Öronlappssår" },
  { value: "ZA713", title: "Sår i hörselgången" },
  { value: "ZA714", title: "Traumatisk trumhinneskada" },
  { value: "ZA715", title: "Öronskador av ID-märken" },
  { value: "ZA72", title: "Främmande kroppar, ytteröra/öronlapp" },
  { value: "ZA721", title: "Främmande kropp i hörselgång" },
  { value: "ZA73", title: "Lägesförändringar, ytteröra/öronlapp" },
  { value: "ZA74", title: "Termiska skador, ytteröra/öronlapp" },
  { value: "ZA741", title: "Brännskada, ytteröra/öronlapp" },
  { value: "ZA742", title: "Köldskada, ytteröra/öronlapp" },
  { value: "ZA7421", title: "Förfrysning av öronlapp" },
  { value: "ZA8", title: "Toxiska förändringar, ytteröra/öronlapp" },
  { value: "ZA81", title: "UV-relaterad dermatos, ytteröra/öronlapp" },
  { value: "ZA9", title: "Idiopatiska, ospecif, multifakt, ytteröra/öronlapp" },
  { value: "ZA91", title: "Headshaker" },
  { value: "ZB", title: "Mellanöra, inneröra" },
  {
    value: "ZB0",
    title: "Normalvar, symtom u fastställd orsak, mellanöra/inneröra",
  },
  { value: "ZB00", title: "Normalvariation, mellanöra/inneröra" },
  {
    value: "ZB01",
    title: "Symtom på sjukdom u fastställd orsak, mellanöra/inneröra",
  },
  { value: "ZB011", title: "Symtom på mellanöronaffektion" },
  { value: "ZB012", title: "Symtom på inneröronaffektion" },
  { value: "ZB013", title: "Symtom på hörselnedsättning" },
  {
    value: "ZB1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, mellanöra/inneröra",
  },
  { value: "ZB10", title: "Missbildning, mellanöra/inneröra" },
  { value: "ZB101", title: "Missbildning av mellanöra" },
  { value: "ZB102", title: "Missbildning av inneröra" },
  {
    value: "ZB11",
    title: "Utvecklingsrubbn, tillväxtrubbn, mellanöra/inneröra",
  },
  { value: "ZB111", title: "Medfödd dövhet" },
  {
    value: "ZB2",
    title: "Metabol, nutrit, degen/dystrof förändr, mellanöra/inneröra",
  },
  { value: "ZB20", title: "Metaboliska förändringar, mellanöra/inneröra" },
  { value: "ZB21", title: "Nutritionella förändringar, mellanöra/inneröra" },
  {
    value: "ZB22",
    title: "Degenerativa/dystrofiska förändringar, mellanöra/inneröra",
  },
  { value: "ZB23", title: "Hormonella förändringar, mellanöra/inneröra" },
  { value: "ZB3", title: "Cirkulatoriska förändringar, mellanöra/inneröra" },
  { value: "ZB30", title: "Blödningar, mellanöra/inneröra" },
  { value: "ZB301", title: "Blödning i mellanörat" },
  { value: "ZB302", title: "Blödning i innerörat" },
  { value: "ZB31", title: "Ödem, mellanöra/inneröra" },
  { value: "ZB32", title: "Infarkter, mellanöra/inneröra" },
  { value: "ZB33", title: "Hygrom, mellanöra/inneröra" },
  { value: "ZB34", title: "Trombos / emboli, mellanöra/inneröra" },
  {
    value: "ZB35",
    title: "Arterio-atero-skleros förändringar, mellanöra/inneröra",
  },
  { value: "ZB4", title: "Infekt, infl förändringar, mellanöra/inneröra" },
  { value: "ZB40", title: "Specifika infektionssjukdomar, mellanöra/inneröra" },
  { value: "ZB41", title: "Akuta inflammationstillstånd, mellanöra/inneröra" },
  {
    value: "ZB411",
    title: "Akut serös / serofibrinös inflammation, mellanöra/inneröra",
  },
  { value: "ZB412", title: "Akut purulent inflammation, mellanöra/inneröra" },
  {
    value: "ZB413",
    title: "Specifika akuta infl tillstånd, mellanöra/inneröra",
  },
  { value: "ZB419", title: "Övriga akuta infl tillstånd, mellanöra/inneröra" },
  { value: "ZB4191", title: "Akut primär otitis media (hel trumhinna)" },
  { value: "ZB4192", title: "Akut sekundär otitis media (trasig trumhinna)" },
  { value: "ZB4193", title: "Akut otitis interna" },
  {
    value: "ZB42",
    title: "Kroniska inflammationstillstånd, mellanöra/inneröra",
  },
  { value: "ZB421", title: "Kronisk serös inflammation, mellanöra/inneröra" },
  { value: "ZB422", title: "Purulent inflammation, mellanöra/inneröra" },
  { value: "ZB4221", title: "Purulent inflammation i bulla tympani" },
  { value: "ZB423", title: "Abscess/fistel, mellanöra/inneröra" },
  {
    value: "ZB424",
    title: "Specifika kroniska infl tillstånd, mellanöra/inneröra",
  },
  {
    value: "ZB429",
    title: "Övriga kroniska infl tillstånd, mellanöra/inneröra",
  },
  { value: "ZB4291", title: "Kronisk primär otitis media (hel trumhinna)" },
  {
    value: "ZB4292",
    title: "Kronisk sekundär otitis media (trasig trumhinna)",
  },
  { value: "ZB4293", title: "Kronisk otitis interna" },
  { value: "ZB43", title: "Parasitära sjukdomar, mellanöra/inneröra" },
  { value: "ZB5", title: "Immunmedierade tillstånd, mellanöra/inneröra" },
  { value: "ZB6", title: "Neoplastiska förändringar, mellanöra/inneröra" },
  { value: "ZB61", title: "Tumör i mellanöra" },
  { value: "ZB62", title: "Tumör i inneröra" },
  {
    value: "ZB7",
    title: "Trauma, lägesförändr term skador, mellanöra/inneröra",
  },
  {
    value: "ZB71",
    title: "Traumatiska / mekaniska skador, mellanöra/inneröra",
  },
  { value: "ZB711", title: "Sår in i mellanöra" },
  { value: "ZB72", title: "Främmande kroppar, mellanöra/inneröra" },
  { value: "ZB73", title: "Lägesförändringar, mellanöra/inneröra" },
  { value: "ZB74", title: "Termiska skador, mellanöra/inneröra" },
  { value: "ZB741", title: "Brännskada, mellanöra/inneröra" },
  { value: "ZB742", title: "Köldskada, mellanöra/inneröra" },
  { value: "ZB8", title: "Toxiska förändringar, mellanöra/inneröra" },
  { value: "ZB81", title: "Toxiskt inducerad dövhet" },
  {
    value: "ZB9",
    title: "Idiopatiska, ospecif, multifakt, mellanöra/inneröra",
  },
  { value: "ZB91", title: "Förvärvad dövhet av okänd genes" },
  { value: "ZZAA0", title: "Övre luftvägar normalvar/standardingr/-utredn" },
  { value: "ZZAA00", title: "Övre luftvägar normalvariation" },
  { value: "ZZAA01", title: "Övre luftvägar utredn ospec tillstånd" },
  { value: "ZZAA03", title: "Nässpolning utan fynd" },
  {
    value: "ZZAA1",
    title: "Övre luftvägar kongen/utvecklingsmässiga/konstit. sjd",
  },
  { value: "ZZAA11", title: "Övre luftvägar missbildning" },
  { value: "ZZAA12", title: "Pallatum molle elongatum" },
  {
    value: "ZZAA2",
    title: "Övre luftvägar, metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZAA21", title: "Epistaxis" },
  { value: "ZZAA3", title: "Övre luftvägar dystrofisk/degenerativ sjd" },
  { value: "ZZAA31", title: "Larynxpares" },
  { value: "ZZAA4", title: "Övre luftvägar infl/immunol sjd" },
  { value: "ZZAA41", title: "Cavalierulcus" },
  { value: "ZZAA5", title: "Övre luftvägar,infektiös sjd" },
  { value: "ZZAA51", title: "Övre luftvägar virussjd" },
  { value: "ZZAA52", title: "Övre luftvägar,bakteriell inf.sjd." },
  { value: "ZZAA521", title: "Laryngit" },
  { value: "ZZAA522", title: "Pharyngit/tonsillit" },
  { value: "ZZAA524", title: "Rhinit/sinuit" },
  { value: "ZZAA53", title: "Mykotisk rhinit/sinuit" },
  { value: "ZZAA54", title: "Övre luftvägar parasitär sjd" },
  { value: "ZZAA541", title: "Rhinit/sinuit ors. av Pneumonysses caninum" },
  { value: "ZZAA542", title: "Svalgkorm" },
  { value: "ZZAA6", title: "Övre luftvägar mekaniska/traum sjd/corp.al." },
  { value: "ZZAA61", title: "Övre luftvägar. corp. alienum" },
  { value: "ZZAA7", title: "Övre luftvägar toxisk sjd" },
  { value: "ZZAA8", title: "Övre luftvägar neoplastisk sjd" },
  { value: "ZZAA81", title: "Tumör näshåla/bihåla" },
  { value: "ZZAA82", title: "Tumör pharynx" },
  { value: "ZZAA83", title: "Tumör larynx" },
  { value: "ZZAA9", title: "Övre luftvägar ospec/multifakt/idiopatisk sjd" },
  { value: "ZZAA91", title: "Inverted sneeze" },
  {
    value: "ZZAB0",
    title: "Nedre luftvägar , normalvar./standardingrepp/-utredn.",
  },
  { value: "ZZAB00", title: "Nedre luftvägar,normalstatus./normalvariation" },
  { value: "ZZAB01", title: "Nedre luftvägar,utredning ospec. tillstånd" },
  {
    value: "ZZAB1",
    title: "Nedre luftvägar , kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZAB11", title: "Kollaberad trachea" },
  {
    value: "ZZAB2",
    title: "Nedre luftvägar,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZAB3", title: "Nedre luftvägar,dystrofisk/degenerativ sjd" },
  { value: "ZZAB4", title: "Nedre luftvägar,inflammatorisk/immunologisk sjd" },
  { value: "ZZAB41", title: "Astma" },
  { value: "ZZAB5", title: "Nedre luftvägar,infektiös sjd" },
  { value: "ZZAB51", title: "Nedre luftvägar,virussjd" },
  { value: "ZZAB511", title: "Kennelhosta" },
  { value: "ZZAB52", title: "Nedre luftvägar,bakteriella sjd" },
  { value: "ZZAB521", title: "Bronkit" },
  { value: "ZZAB522", title: "Pneumoni" },
  { value: "ZZAB524", title: "Pyothorax" },
  { value: "ZZAB525", title: "TBC" },
  { value: "ZZAB526", title: "Tracheit" },
  { value: "ZZAB53", title: "Nedre luftvägar,mykotisk sjd" },
  { value: "ZZAB54", title: "Nedre luftvägar,parasitär sjd" },
  { value: "ZZAB6", title: "Nedre luftvägar,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZAB61", title: "Nedre luftvägar,corpus alienum" },
  { value: "ZZAB62", title: "Lungödem" },
  { value: "ZZAB621", title: "Jaktödem" },
  { value: "ZZAB622", title: "Lungödem t.följd av elektrisk ström" },
  { value: "ZZAB63", title: "Pneumothorax" },
  { value: "ZZAB64", title: "Lungblödning traumatisk" },
  { value: "ZZAB7", title: "Nedre luftvägar,toxisk sjd" },
  { value: "ZZAB8", title: "Nedre luftvägar,neoplastisk sjd" },
  { value: "ZZAB81", title: "Tumör i trachea" },
  { value: "ZZAB82", title: "Tumör i lunga/lungsäck" },
  { value: "ZZAB9", title: "Nedre luftvägar,ospec./multifakt./idiopatsik sjd" },
  { value: "ZZAB91", title: "Hydrothorax" },
  { value: "ZZAB92", title: "Hemothorax" },
  { value: "ZZAB93", title: "Chylothorax" },
  { value: "ZZAB94", title: "Lungblödning okänd orsak" },
  { value: "ZZAB95", title: "Lungemfysem" },
  { value: "ZZBA0", title: "Bukvägg,normalvar./standardingrepp/-utredn." },
  { value: "ZZBA00", title: "Bukvägg,normalstatus/normalvariation" },
  { value: "ZZBA01", title: "Bukvägg,utredning ospec.tillstånd" },
  { value: "ZZBA1", title: "Bukvägg,kongenital/utveckl.mässig/konstit. sjd" },
  { value: "ZZBA11", title: "Bukbråck,medfött" },
  { value: "ZZBA12", title: "Diafragmabråck,medfött" },
  { value: "ZZBA13", title: "Inguinalbråck,medfött" },
  { value: "ZZBA14", title: "Navelbråck,medfött" },
  { value: "ZZBA51", title: "Navelinfektion" },
  { value: "ZZBA6", title: "Bukvägg,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZBA61", title: "Bukbråck,traumatiskt" },
  { value: "ZZBA62", title: "Diafragmabråck,traumatiskt" },
  { value: "ZZBA63", title: "Inguinalbråck,traumatiskt" },
  { value: "ZZBA64", title: "Navelbråck,traumatiskt" },
  { value: "ZZBA65", title: "Perinealbråck" },
  { value: "ZZBA8", title: "Bukvägg,neoplastisk sjd" },
  {
    value: "ZZCA0",
    title: "Hjärta o kärlsystem,normalvar./standardingrepp/-utredn.",
  },
  {
    value: "ZZCA00",
    title: "Hjärta o kärlsystem,normalstatus/normalvariation",
  },
  { value: "ZZCA01", title: "Hjärta o kärlsystem,utredning ospec.tillstånd" },
  { value: "ZZCA011", title: "Ultraljudsundersökning hjärta utan diagn." },
  { value: "ZZCA012", title: "Enalaprilförsöket Eva Adolfsson" },
  {
    value: "ZZCA1",
    title: "Hjärta o kärlsystem,kongenital/utveckl.mässig/konstit. sjd",
  },
  { value: "ZZCA11", title: "Aortastenos" },
  { value: "ZZCA12", title: "Pulmonalisstenos" },
  { value: "ZZCA13", title: "Patent Ductus Arteriosus" },
  { value: "ZZCA14", title: "Septumdefekt" },
  { value: "ZZCA15", title: "Mitralis/tricuspidalisdysplasi" },
  { value: "ZZCA16", title: "Fallots tetralogi" },
  { value: "ZZCA17", title: "Kärlmissbildning övrig" },
  {
    value: "ZZCA2",
    title: "Hjärta o kärlsystem,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZCA21", title: "Trombos" },
  { value: "ZZCA211", title: "Aortatrombos" },
  { value: "ZZCA22", title: "Infarkt" },
  { value: "ZZCA3", title: "Hjärta o kärlsystem,dystrofisk/degenerativ sjd" },
  { value: "ZZCA31", title: "Cardiomyopati,dilaterad" },
  { value: "ZZCA32", title: "Cardiomyopati,hypertrofisk" },
  { value: "ZZCA33", title: "Cardiomyopati,boxer-" },
  {
    value: "ZZCA4",
    title: "Hjärta o kärlsystem,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZCA41", title: "Endocardos" },
  { value: "ZZCA42", title: "Myocardit" },
  { value: "ZZCA5", title: "Hjärta o kärlsystem,infektiös sjd" },
  { value: "ZZCA51", title: "Pericardit" },
  { value: "ZZCA52", title: "Endocardit" },
  {
    value: "ZZCA6",
    title: "Hjärta o kärlsystem,mekanisk/traumatisk sjd/corp.al.",
  },
  { value: "ZZCA62", title: "Värmeslag" },
  { value: "ZZCA63", title: "Hjärttamponad" },
  { value: "ZZCA64", title: "Ruptur chordae tendin." },
  { value: "ZZCA7", title: "Hjärta o kärlsystem,toxisk sjd" },
  { value: "ZZCA8", title: "Hjärta o kärlsystem,neoplastisk sjd" },
  { value: "ZZCA81", title: "Hjärtbastumör" },
  {
    value: "ZZCA9",
    title: "Hjärta o kärlsystem,ospec./multifakt./idiopatsik sjd",
  },
  { value: "ZZCA91", title: "Arrytmi" },
  { value: "ZZCA92", title: "Hypertoni" },
  { value: "ZZCA93", title: "Benignt idiopatiskt hydropericard" },
  { value: "ZZCA94", title: "Chock" },
  { value: "ZZCA941", title: "Hypovolemisk chock" },
  { value: "ZZCA942", title: "Chock ors.av hjärtinsuff." },
  { value: "ZZCA97", title: "Vitium cordis,ors.ej fastställd" },
  { value: "ZZCA98", title: "Insuff.cordis,ors.ej fastställd" },
  {
    value: "ZZDA0",
    title: "Munhåla/tänder/spottk./matstr.,normalvar./standardingr./-utr",
  },
  {
    value: "ZZDA00",
    title: "Munhåla/tänder/spottk/matstr.,normalstatus/normalvariation",
  },
  {
    value: "ZZDA01",
    title: "Munhåla/tänder/spottk/matstr.,utredning ospec.tillstånd",
  },
  {
    value: "ZZDA1",
    title: "Munh/tänder/spottk/matstr.,kongenital/utv.mässig/konstit sjd",
  },
  { value: "ZZDA11", title: "Mjölktänder,persisterande" },
  { value: "ZZDA12", title: "Permanent tand saknas" },
  { value: "ZZDA13", title: "Sialocoele/ranula" },
  { value: "ZZDA14", title: "Megaoesophagus" },
  { value: "ZZDA15", title: "Felaktig tandställning" },
  {
    value: "ZZDA2",
    title: "Munh/tänder/spottk./matstr.,metabolisk/nutr/horm sjd",
  },
  { value: "ZZDA21", title: "Tandsten" },
  {
    value: "ZZDA3",
    title: "Munhåla/tänder/spottk/matstr.,dystrofisk/degenerativ sjd",
  },
  {
    value: "ZZDA4",
    title: "Munh/tänder/spottk/matstr.,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZDA41", title: "Oesophagit" },
  { value: "ZZDA42", title: "Gingivit/stomatit" },
  { value: "ZZDA5", title: "Munhåla/tänder/spottk/matstr.,infektiös sjd" },
  { value: "ZZDA51", title: "Karies/rotspetsabscess" },
  { value: "ZZDA53", title: "Sialoadenit" },
  {
    value: "ZZDA6",
    title: "Munh/tänder/spottk/matstr.,mekanisk/traumatisk sjd/corp.al.",
  },
  { value: "ZZDA61", title: "Tandfraktur" },
  { value: "ZZDA62", title: "Corp.al.munhåla/svalg" },
  { value: "ZZDA63", title: "Corp.al.matstrupe" },
  { value: "ZZDA64", title: "Striktur matstrupe" },
  { value: "ZZDA7", title: "Munhåla/tänder/spottk/matstr.,toxisk sjd" },
  { value: "ZZDA8", title: "Munhåla/tänder/spottk/matstr.,neoplastisk sjd" },
  { value: "ZZDA81", title: "Epulider" },
  { value: "ZZDA87", title: "Tumör munhåla/svalg" },
  { value: "ZZDA88", title: "Tumör matstrupe" },
  {
    value: "ZZDA9",
    title: "Munh/tänder/spottk/matstr.,ospec./multifakt/idiopatsik sjd",
  },
  {
    value: "ZZDB0",
    title: "Magsäck/tarm/analområde,normalvar./standardingrepp/-utredn.",
  },
  {
    value: "ZZDB00",
    title: "Magsäck/tarm/analområde,normalstatus/normalvariation",
  },
  {
    value: "ZZDB01",
    title: "Magsäck/tarm/analområde,utredning ospec.tillstånd",
  },
  { value: "ZZDB08", title: "Spolning analsäckar" },
  {
    value: "ZZDB1",
    title: "Magsäck/tarm/analomr,kongenital/utveckl.mässig/konstit sjd",
  },
  { value: "ZZDB11", title: "Pylorusstenos" },
  {
    value: "ZZDB2",
    title: "Magsäck/tarm/analomr,metabolisk/nutritionell/hormonell sjd",
  },
  {
    value: "ZZDB3",
    title: "Magsäck/tarm/analområde,dystrofisk/degenerativ sjd",
  },
  {
    value: "ZZDB4",
    title: "Magsäck/tarm/analområde,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZDB41", title: "Gastrit" },
  { value: "ZZDB411", title: "Akut gastrit" },
  { value: "ZZDB412", title: "Kronisk ospec.gastrit" },
  { value: "ZZDB413", title: "Atrofisk gastrit" },
  { value: "ZZDB414", title: "Follikulär gastrit" },
  { value: "ZZDB415", title: "Erosiv/ulcerativ  gastrit" },
  { value: "ZZDB42", title: "Enterit/gastroenterit/kolit,kronisk" },
  { value: "ZZDB421", title: "Lymfocytär-/plasmacellsenterit" },
  { value: "ZZDB422", title: "Eosinifil enterit" },
  { value: "ZZDB423", title: "Lymfangiektasi" },
  { value: "ZZDB424", title: "Erosiv kolit" },
  { value: "ZZDB425", title: "Irritable colon" },
  { value: "ZZDB44", title: "Bursitis paranalis" },
  { value: "ZZDB45", title: "Proktit" },
  { value: "ZZDB5", title: "Magsäck/tarm/analområde,infektiös sjd" },
  { value: "ZZDB51", title: "Enterit/gastroenterit,akut" },
  { value: "ZZDB511", title: "Parvovirusenterit" },
  { value: "ZZDB512", title: "Hemorrhagisk enterit" },
  { value: "ZZDB513", title: "Salmonellos" },
  { value: "ZZDB514", title: "Parasitär enterit" },
  { value: "ZZDB52", title: "Peritonit, allmän/lokal" },
  {
    value: "ZZDB6",
    title: "Magsäck/tarm/analområde,mekanisk/traumatisk sjd/corp.al.",
  },
  { value: "ZZDB61", title: "Coprostas" },
  { value: "ZZDB62", title: "Corp.al.magsäck" },
  { value: "ZZDB63", title: "Corp.al.tarm" },
  { value: "ZZDB64", title: "Förätning/magsäcksdilatation" },
  { value: "ZZDB65", title: "Magsäcksomvridning" },
  { value: "ZZDB66", title: "Lägeförändring tarm" },
  { value: "ZZDB68", title: "Tarminvagination" },
  { value: "ZZDB69", title: "Divertikel tarmvägg" },
  { value: "ZZDB7", title: "Magsäck/tarm/analområde,toxisk sjd" },
  { value: "ZZDB8", title: "Magsäck/tarm/analområde,neoplastisk sjd" },
  { value: "ZZDB81", title: "Tumör magsäck" },
  { value: "ZZDB82", title: "Tumör i tarm" },
  { value: "ZZDB83", title: "Analadenom/-adenocarcinom" },
  { value: "ZZDB84", title: "Tumör i analsäck" },
  {
    value: "ZZDB9",
    title: "Magsäck/tarm/analområde,ospec/multifakt/idiopatsik sjd",
  },
  { value: "ZZDB91", title: "Inappetens, närmare diagn. ej fastställd" },
  { value: "ZZDB92", title: "Gaskolik" },
  { value: "ZZDB93", title: "Avmagring" },
  { value: "ZZDB94", title: "Megacolon" },
  {
    value: "ZZDC0",
    title: "Lever/pancreas,normalvar/standardingrepp/-utredn.",
  },
  { value: "ZZDC00", title: "Lever/pancreas,normalstatus/normalvariation" },
  { value: "ZZDC01", title: "Lever/pancreas,utredning ospec.tillstånd" },
  {
    value: "ZZDC1",
    title: "Lever/pancreas,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZDC11", title: "Porta-cavashunt" },
  { value: "ZZDC12", title: "Pancreasinsufficiens" },
  {
    value: "ZZDC2",
    title: "Lever/pancreas,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZDC21", title: "Kopparhepatit" },
  { value: "ZZDC22", title: "Gallsten" },
  { value: "ZZDC3", title: "Lever/pancreas,dystrofisk/degenerativ sjd" },
  { value: "ZZDC31", title: "Leverförfettning" },
  { value: "ZZDC32", title: "Levercirros" },
  { value: "ZZDC4", title: "Lever/pancreas,inflammatorisk/immunologisk sjd" },
  { value: "ZZDC42", title: "Hepatos,icke infektiös" },
  { value: "ZZDC421", title: "Akut hepatos,ospecificerad" },
  { value: "ZZDC422", title: "Kronisk hepatos,ospecificerad" },
  { value: "ZZDC423", title: "Kronisk aktiv hepatit" },
  { value: "ZZDC5", title: "Lever/pancreas,infektiös sjd" },
  { value: "ZZDC51", title: "Cholecystit" },
  { value: "ZZDC52", title: "Hepatit,infektiös" },
  { value: "ZZDC521", title: "Akut hepatit,ospec." },
  { value: "ZZDC522", title: "Kronisk hepatit,ospec." },
  { value: "ZZDC523", title: "HCC." },
  { value: "ZZDC524", title: "Granulomatös hepatit" },
  { value: "ZZDC525", title: "Leptospira ichterohemorrhagica" },
  { value: "ZZDC53", title: "Pancreatit/pancreasböld" },
  { value: "ZZDC6", title: "Lever/pancreas,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZDC7", title: "Lever/pancreas,toxisk sjd" },
  { value: "ZZDC8", title: "Lever/pancreas,neoplastisk sjd" },
  { value: "ZZDC81", title: "Tumör lever/gallvägar" },
  { value: "ZZDC811", title: "Tumör lever/gallvägar,carcinom" },
  { value: "ZZDC812", title: "Tumör lever/gallvägar,hemangiosarkom" },
  { value: "ZZDC813", title: "Tumör lever/gallvägar,leucos" },
  { value: "ZZDC85", title: "Tumör pancreas" },
  { value: "ZZDC851", title: "Insulinom" },
  { value: "ZZDC9", title: "Lever/pancreas,ospec/multifakt/idiopatsik sjd" },
  { value: "ZZDC91", title: "Ascites utan fastställd orsak" },
  {
    value: "ZZEA0",
    title: "Endokrina systemsjukdomar,normalvar/standardingrepp/-utredn.",
  },
  {
    value: "ZZEA2",
    title: "Endokrina systemsjukdomar,metabolisk/nutritionell/horm sjd",
  },
  { value: "ZZEA20", title: "Addisons sjukdom" },
  { value: "ZZEA21", title: "Cushings sjd" },
  { value: "ZZEA22", title: "Diabetes incipidus" },
  { value: "ZZEA23", title: "Diabetes mellitus" },
  { value: "ZZEA24", title: "Hypothyreos" },
  { value: "ZZEA25", title: "Hyperthyreos" },
  { value: "ZZEA26", title: "Hyposomatotropinism" },
  { value: "ZZEA27", title: "Hypopituitarism" },
  { value: "ZZEA28", title: "Hyperparathyreoidism" },
  { value: "ZZEA29", title: "Hypersomatotropism" },
  { value: "ZZEA81", title: "Tumör i sköldkörtel" },
  { value: "ZZEA82", title: "Tumör i binjure" },
  { value: "ZZEA83", title: "Tumör i hypofys" },
  {
    value: "ZZEA9",
    title: "Endokrina systemsjukdomar,ospec/multifakt/idiopatsik sjd",
  },
  { value: "ZZFA7", title: "Förgiftning/insektsbett/ormbett,toxisk sjd" },
  { value: "ZZFA71", title: "Förgiftning ospec." },
  { value: "ZZFA711", title: "Förgiftning kemikalier" },
  { value: "ZZFA712", title: "Förgiftning läkemedel" },
  { value: "ZZFA713", title: "Förgiftning bekämpningsmedel" },
  { value: "ZZFA714", title: "Förgiftning svamp-" },
  { value: "ZZFA715", title: "Förgiftning växt-" },
  { value: "ZZFA716", title: "Botulism" },
  { value: "ZZFA719", title: "Förgiftning,övriga toxiner" },
  { value: "ZZFA72", title: "Insekts/ormbett" },
  { value: "ZZFA721", title: "Insektsbett" },
  { value: "ZZFA722", title: "Ormbett" },
  { value: "ZZHA0", title: "Hud,normalvar/standardingrepp/-utredn." },
  { value: "ZZHA00", title: "Hud,normalstatus/normalvariation" },
  { value: "ZZHA01", title: "Hud,utredning ospec.tillstånd" },
  { value: "ZZHA02", title: "Allergitest" },
  { value: "ZZHA03", title: "Vingklippning" },
  { value: "ZZHA1", title: "Hud,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZHA11", title: "Alopecier" },
  { value: "ZZHA111", title: "Color mutant alopecia" },
  { value: "ZZHA112", title: "Kongenital hypotrichos" },
  { value: "ZZHA12", title: "Hypertrichos" },
  { value: "ZZHA13", title: "Abnorm keratinisering" },
  { value: "ZZHA14", title: "Pigmentrubbningar" },
  { value: "ZZHA141", title: "Albinism" },
  { value: "ZZHA142", title: "Vitiligo" },
  { value: "ZZHA15", title: "Dermoidcysta" },
  { value: "ZZHA2", title: "Hud,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZHA21", title: "Acanthosis nigricans" },
  { value: "ZZHA22", title: "Endocrin alopeci" },
  { value: "ZZHA23", title: "Hormonell hudsjd,hanhund" },
  { value: "ZZHA231", title: "Sertolicellstumör" },
  { value: "ZZHA232", title: "Male feminizing syndrome" },
  { value: "ZZHA233", title: "Testosteron-responsive dermatos" },
  { value: "ZZHA234", title: "Hyperandrogenism" },
  { value: "ZZHA28", title: "Nutritionell hudsjd" },
  { value: "ZZHA281", title: "Nutritionell hudsjd,zinkbrist" },
  { value: "ZZHA282", title: "Nutritionell hudsjd,hypovitaminos A" },
  { value: "ZZHA283", title: "Nutritionell hudsjd,övrig" },
  { value: "ZZHA284", title: "Dalmatian bronzing syndrome" },
  { value: "ZZHA3", title: "Hud,dystrofisk/degenerativ sjd" },
  { value: "ZZHA4", title: "Hud,inflammatorisk/immunologisk sjd" },
  { value: "ZZHA41", title: "Immunologisk hudsjd" },
  { value: "ZZHA411", title: "Allergiskt betingad hudsjd" },
  { value: "ZZHA412", title: "Autoimmun hudsjd" },
  { value: "ZZHA413", title: "Immunmedierad hudsjd" },
  { value: "ZZHA42", title: "Pyodermi" },
  { value: "ZZHA421", title: "Ytlig pyodermi" },
  { value: "ZZHA422", title: "Djup pyodermi" },
  { value: "ZZHA43", title: "Pustulär hudsjukdom,övrig" },
  { value: "ZZHA431", title: "Juvenil hyperkeratos" },
  { value: "ZZHA432", title: "Juvenil cellulit" },
  { value: "ZZHA433", title: "Subcornela pustulär dermatos" },
  { value: "ZZHA434", title: "Steril eosinofil pustulos" },
  { value: "ZZHA435", title: "Sterila pyogranulom-syndromet" },
  { value: "ZZHA436", title: "Nodulär panniculit" },
  { value: "ZZHA44", title: "Atherom" },
  { value: "ZZHA5", title: "Hud,infektiös sjd" },
  { value: "ZZHA511", title: "Abscess / flegmon" },
  { value: "ZZHA52", title: "Bakteriell hudsjd" },
  { value: "ZZHA521", title: "Abscess/flegmon" },
  { value: "ZZHA522", title: "Nocardia/aktinomykos" },
  { value: "ZZHA523", title: "Sårinfektion post op." },
  { value: "ZZHA53", title: "Hudmykos" },
  { value: "ZZHA531", title: "Dermatophytos" },
  { value: "ZZHA532", title: "Candidiasis" },
  { value: "ZZHA54", title: "Parasitär hudsjd" },
  { value: "ZZHA541", title: "Parasitär hudsjd,kvalster" },
  { value: "ZZHA542", title: "Loppangrepp" },
  { value: "ZZHA543", title: "Lusangrepp" },
  { value: "ZZHA544", title: "Fluglarvsangrepp hud/subcutis" },
  { value: "ZZHA6", title: "Hud,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZHA61", title: "Kontaktirritationsdermatit" },
  { value: "ZZHA62", title: "Hygrom" },
  { value: "ZZHA621", title: "Bursitis olecrani" },
  { value: "ZZHA622", title: "Bursitis calcanei" },
  { value: "ZZHA63", title: "Köldskada" },
  { value: "ZZHA64", title: "Brännskada" },
  { value: "ZZHA65", title: "Vulnus" },
  { value: "ZZHA651", title: "Vulnus contusum" },
  { value: "ZZHA652", title: "Vulnus dilaceratum" },
  { value: "ZZHA653", title: "Vulnus incisum" },
  { value: "ZZHA654", title: "Vulnus morsum" },
  { value: "ZZHA655", title: "Vulnus punctum" },
  { value: "ZZHA656", title: "Vulnus sclopetarium" },
  { value: "ZZHA66", title: "Hematom hud/underhud" },
  { value: "ZZHA67", title: "Subkutant emfysem" },
  { value: "ZZHA68", title: "Corp. al. cutis / subcutis" },
  { value: "ZZHA7", title: "Hud,toxisk sjd" },
  { value: "ZZHA8", title: "Hud,neoplastisk sjd" },
  { value: "ZZHA81", title: "Tumor cutis/subcutis" },
  { value: "ZZHA9", title: "Hud,ospec./multifakt./idiopatsik sjd" },
  { value: "ZZHA91", title: "Psykogen hudsjd" },
  { value: "ZZHA911", title: "Acral slickdermatit" },
  { value: "ZZHA912", title: "Psykogen alopeci/dermatit" },
  { value: "ZZHA97", title: "Seborreisk hudsjd" },
  { value: "ZZHA971", title: "Seborrekomplexet" },
  { value: "ZZHA972", title: "Schnauzer comedo syndrome" },
  { value: "ZZHA973", title: "Svanskörtelseborre'" },
  { value: "ZZHA974", title: "Nasodigital hyperkeratos" },
  { value: "ZZHA975", title: "Acne" },
  { value: "ZZHa976", title: "Talgkörtelinflammation" },
  { value: "ZZHA99", title: "Hudsjd av okänd etiologi" },
  { value: "ZZHA991", title: "Pruritus,okänd orsak" },
  { value: "ZZHA992", title: "Dermatit,orsak ej fastställd" },
  { value: "ZZHA993", title: "Miliärt excem" },
  { value: "ZZHA994", title: "Eosinofila granulom-komplexet" },
  { value: "ZZHB0", title: "Klor,normalvar./standardingrepp/-utredn." },
  { value: "ZZHB00", title: "Klor,normalstatus/normalvariation" },
  { value: "ZZHB01", title: "Klor,utredning ospec.tillstånd" },
  { value: "ZZHB02", title: "Kloklippning" },
  { value: "ZZHB1", title: "Klor,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZHB11", title: "Onychorexis" },
  { value: "ZZHB5", title: "Klor,infektiös sjd" },
  { value: "ZZHB52", title: "Bakteriell klofalsinflammation" },
  { value: "ZZHB53", title: "Onychomykos" },
  { value: "ZZHB6", title: "Klor,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZHB61", title: "Klobrott" },
  { value: "ZZHB91", title: "Klokapselavlossning idiopatisk" },
  { value: "ZZIA5", title: "Infektionssjukdom, ej specificerad" },
  { value: "ZZIA51", title: "Viros, allmän" },
  { value: "ZZIA512", title: "HCC" },
  { value: "ZZIA513", title: "TBE (Tick Borne Encephalitis)" },
  { value: "ZZIA514", title: "Valpsjuka" },
  { value: "ZZIA516", title: "FIP (Feline Infectious Peritonitis)" },
  { value: "ZZIA517", title: "Kattpest" },
  { value: "ZZIA518", title: "Kattsnuva" },
  { value: "ZZIA519", title: "FIV/FeLV" },
  { value: "ZZIA52", title: "Bakteriell systemsjukdom" },
  { value: "ZZIA521", title: "Sepsis" },
  { value: "ZZIA522", title: "Bakteriemi" },
  { value: "ZZIA523", title: "Borrelios" },
  { value: "ZZIA524", title: "Erlichios" },
  { value: "ZZIA525", title: "Nocardios" },
  { value: "ZZIA529", title: "Kaninsnuva (Pasteurellos)" },
  { value: "ZZIA53", title: "Mykotisk systeminfektion" },
  { value: "ZZIA54", title: "Parasitär syteminfektion" },
  { value: "ZZIA541", title: "Hemobartonellos" },
  { value: "ZZIA542", title: "Toxoplasmos" },
  { value: "ZZIA91", title: "Infektion ospecifik" },
  {
    value: "ZZKA0",
    title: "Könsorgan hondjur/pediatrik,normalvar/standardingrepp/utredn",
  },
  { value: "ZZKA00", title: "Könsorgan hondjur/pediatrik,normalvariation" },
  {
    value: "ZZKA01",
    title: "Könsorgan hondjur/pediatrik,utredning ospec.tillstånd",
  },
  { value: "ZZKA02", title: "Hormonbehandling" },
  { value: "ZZKA021", title: "Abortspruta" },
  { value: "ZZKA022", title: "P-spruta" },
  { value: "ZZKA03", title: "Insemination" },
  { value: "ZZKA031", title: "Insemination färsk sperma" },
  { value: "ZZKA032", title: "Insemination frusen sperma" },
  { value: "ZZKA04", title: "Könsorgan hondjur övr.standardingr/utredn." },
  { value: "ZZKA041", title: "Dräktighetsunders." },
  { value: "ZZKA042", title: "Fertilitetsutredning" },
  { value: "ZZKA043", title: "Hälsokontroll könsorgan hon-" },
  { value: "ZZKA044", title: "Normalkastration hondjur" },
  { value: "ZZKA045", title: "Vaginalprovtagning" },
  {
    value: "ZZKA1",
    title: "Könsorg hondj/pediatrik,kongenital/utv.mässig/konstit.sjd",
  },
  { value: "ZZKA11", title: "Missbildningar honliga könsorgan" },
  { value: "ZZKA111", title: "Indragen vulva" },
  { value: "ZZKA112", title: "Inverterad spene" },
  {
    value: "ZZKA2",
    title: "Könsorg hondj/pediatr,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZKA21", title: "Eklampsi" },
  { value: "ZZKA22", title: "Agalakti" },
  { value: "ZZKA23", title: "Metrorrhagi" },
  { value: "ZZKA24", title: "Vaginalprolaps" },
  { value: "ZZKA25", title: "Skendräktighet" },
  { value: "ZZKA26", title: "Äggstockscysta" },
  { value: "ZZKA28", title: "Agalakti." },
  {
    value: "ZZKA3",
    title: "Könsorgan hondjur/pediatrik,dystrofisk/degenerativ sjd",
  },
  {
    value: "ZZKA4",
    title: "Könsorgan hondj/pediatrik,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZKA5", title: "Könsorgan hondjur/pediatrik,infektiös sjd" },
  {
    value: "ZZKA52",
    title: "Könsorgan hondjur/pediatrik,bakteriell infektion",
  },
  { value: "ZZKA521", title: "Endometrit,metrit" },
  { value: "ZZKA522", title: "Pyo/hydro/fusometra" },
  { value: "ZZKA523", title: "Cervicit/cervixabscess" },
  { value: "ZZKA524", title: "Mastit" },
  { value: "ZZKA525", title: "Vaginit" },
  {
    value: "ZZKA6",
    title: "Könsorgan hondjur/pediatrik,mekanisk/traumatisk sjd/corp.al.",
  },
  { value: "ZZKA61", title: "Könsorgan hondjur/pediatrik,trauma" },
  { value: "ZZKA62", title: "Uterusprolaps" },
  { value: "ZZKA63", title: "Uterustorsion" },
  { value: "ZZKA64", title: "Uterusruptur" },
  { value: "ZZKA7", title: "Könsorgan hondjur/pediatrik,toxisk sjd" },
  { value: "ZZKA8", title: "Könsorgan hondjur/pediatrik,neoplastisk sjd" },
  { value: "ZZKA81", title: "Tumor mammae" },
  { value: "ZZKA82", title: "Tumor ovarii" },
  { value: "ZZKA83", title: "Tumor uteri" },
  { value: "ZZKA84", title: "Tumor vaginae" },
  {
    value: "ZZKA9",
    title: "Könsorgan hondjur/pediatrik,ospec./multifakt./idiopatisk sjd",
  },
  { value: "ZZKA91", title: "Fosterdöd/kastning" },
  { value: "ZZKA92", title: "Dystokia" },
  { value: "ZZKA93", title: "Ret.sec" },
  { value: "ZZKA94", title: "Subinvolution" },
  { value: "ZZKA95", title: "Nedsatt fertilitet/sterilitet" },
  { value: "ZZKA96", title: "Fading puppie-syndromet" },
  { value: "ZZKA97", title: "Swimmer-syndromet" },
  { value: "ZZKA98", title: "Mjölkstockning" },
  {
    value: "ZZKB0",
    title: "Könsorgan handjur,normalvar./standardingrepp/-utredn.",
  },
  { value: "ZZKB00", title: "Könsorgan handjur,normalvariation" },
  { value: "ZZKB01", title: "Könsorgan handjur,utredning ospec.tillstånd" },
  { value: "ZZKB02", title: "Hormonbehandling." },
  { value: "ZZKB04", title: "Könsorgan handjur standardingrepp/utredn" },
  { value: "ZZKB041", title: "Spermasamling" },
  { value: "ZZKB042", title: "Spermaprovtagning" },
  { value: "ZZKB043", title: "Fertilitetsutredning." },
  { value: "ZZKB044", title: "Könsorgan handjur,hälsokontroll" },
  { value: "ZZKB045", title: "Normalkastration handjur" },
  {
    value: "ZZKB1",
    title: "Könsorgan handjur,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZKB11", title: "Kryptorchism" },
  { value: "ZZKB12", title: "Paraphimos" },
  { value: "ZZKB13", title: "Phimos" },
  {
    value: "ZZKB2",
    title: "Könsorgan handjur,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZKB21", title: "Prostatahypertrofi/hyperplasi" },
  { value: "ZZKB22", title: "Prostatacysta" },
  { value: "ZZKB23", title: "Hyper/hyposexualitet" },
  { value: "ZZKB3", title: "Könsorgan handjur,dystrofisk/degenerativ sjd" },
  { value: "ZZKB31", title: "Testikelatrofi" },
  {
    value: "ZZKB4",
    title: "Könsorgan handjur,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZKB5", title: "Könsorgan handjur,infektiös sjd" },
  { value: "ZZKB52", title: "Könsorgan handjur,bakteriella infektioner" },
  { value: "ZZKB521", title: "Preputialkatarr,balanophostit" },
  { value: "ZZKB522", title: "Prostatit,akut" },
  { value: "ZZKB523", title: "Prostatit,kronisk" },
  { value: "ZZKB524", title: "Prostataabscess" },
  { value: "ZZKB525", title: "Orcit/epididymit" },
  {
    value: "ZZKB6",
    title: "Könsorgan handjur,mekanisk/traumatisk sjd/corp.al.",
  },
  { value: "ZZKB61", title: "Fraktur penisben" },
  { value: "ZZKB62", title: "Penisframfall" },
  { value: "ZZKB63", title: "Testikeltorsion" },
  { value: "ZZKB64", title: "Könsorgan handjur,trauma" },
  { value: "ZZKB7", title: "Könsorgan handjur,toxisk sjd" },
  { value: "ZZKB8", title: "Könsorgan handjur,neoplastisk sjd" },
  { value: "ZZKB81", title: "Tumor testis" },
  { value: "ZZKB82", title: "Tumor prostatae" },
  {
    value: "ZZKB9",
    title: "Könsorgan handjur,ospec./multifakt./idiopatisk sjd",
  },
  {
    value: "ZZLA0",
    title: "Flera leder involverade,normalvar/standardingrepp/-utredn.",
  },
  { value: "ZZLA00", title: "Flera leder involverade,normalvariation" },
  {
    value: "ZZLA01",
    title: "Flera leder involverade,utredning ospec.tillstånd",
  },
  { value: "ZZLA02", title: "Flera leder involverade,hältutredning" },
  {
    value: "ZZLA1",
    title: "Flera leder involverade,kongenital/utv.mässig/konstit sjd",
  },
  { value: "ZZLA12", title: "Flera leder involverade,osteochondros" },
  {
    value: "ZZLA2",
    title: "Flera leder involverade,metabolisk/nutritionell/hormonell sj",
  },
  {
    value: "ZZLA3",
    title: "Flera leder involverade,dystrofisk/degenerativ sjd",
  },
  { value: "ZZLA31", title: "Flera leder involverade,arthros" },
  {
    value: "ZZLA4",
    title: "Flera leder involverade,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZLA41", title: "Flera leder involverade,ledinflammation" },
  { value: "ZZLA5", title: "Flera leder involverade,infektiös sjd" },
  { value: "ZZLA52", title: "Flera leder involverade,infektiös artrit" },
  {
    value: "ZZLA6",
    title: "Flera leder involverade,mekanisk/traumatisk sjd/corp.al.",
  },
  { value: "ZZLA61", title: "Flera leder involverade,distorsion" },
  { value: "ZZLA64", title: "Flera leder involverade,ledbandsskada" },
  { value: "ZZLA65", title: "Flera leder involverade,luxation/subluxation" },
  { value: "ZZLA7", title: "Flera leder involverade,toxisk sjd" },
  { value: "ZZLA8", title: "Flera leder involverade,neoplastisk sjd" },
  {
    value: "ZZLA9",
    title: "Flera leder involverade,ospec/multifakt/idiopatisk sjd",
  },
  { value: "ZZLB0", title: "Käkleder,normalvar./standardingrepp/-utredn." },
  { value: "ZZLB00", title: "Käkleder,normalvariation" },
  { value: "ZZLB01", title: "Käkleder,utredning ospec.tillstånd" },
  { value: "ZZLB1", title: "Käkleder,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZLB11", title: "Käkleder,dysplasi" },
  { value: "ZZLB12", title: "Käkleder,osteochondros" },
  { value: "ZZLB2", title: "Käkleder,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZLB3", title: "Käkleder,dystrofisk/degenerativ sjd" },
  { value: "ZZLB31", title: "Käkleder,arthros" },
  { value: "ZZLB4", title: "Käkleder,inflammatorisk/immunologisk sjd" },
  { value: "ZZLB41", title: "Käkleder,ledinflammation" },
  { value: "ZZLB5", title: "Käkleder,infektiös sjd" },
  { value: "ZZLB52", title: "Käkleder,infektiös artrit" },
  { value: "ZZLB6", title: "Käkleder,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZLB61", title: "Käkleder,distorsion" },
  { value: "ZZLB62", title: "Käkleder,meniskskada" },
  { value: "ZZLB65", title: "Käkleder,luxation/subluxation" },
  { value: "ZZLB7", title: "Käkleder,toxisk sjd" },
  { value: "ZZLB8", title: "Käkleder,neoplastisk sjd" },
  { value: "ZZLB9", title: "Käkleder,ospec,/multifakt/idiopatisk sjd" },
  {
    value: "ZZLC0",
    title: "Kotpelarens leder,normalvar/standardingrepp/-utredn.",
  },
  { value: "ZZLC00", title: "Kotpelarens leder,normalvariation" },
  { value: "ZZLC01", title: "Kotpelarens leder,utredning ospec.tillstånd" },
  { value: "ZZLC02", title: "Kotpelarens leder,hältutredning" },
  {
    value: "ZZLC1",
    title: "Kotpelarens leder,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZLC14", title: "Kotpelare sacralisation" },
  {
    value: "ZZLC2",
    title: "Kotpelarens leder,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZLC3", title: "Kotpelarens leder,dystrofisk/degenerativ sjd" },
  { value: "ZZLC31", title: "Kotpelarens leder,arthros" },
  {
    value: "ZZLC4",
    title: "Kotpelarens leder,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZLC41", title: "Kotpelarens leder,ledinflammation" },
  { value: "ZZLC5", title: "Kotpelarens leder,infektiös sjd" },
  { value: "ZZLC52", title: "Kotpelarens leder,infektiös artrit" },
  {
    value: "ZZLC6",
    title: "Kotpelarens leder,mekanisk/traumanisk sjd/corp.al.",
  },
  { value: "ZZLC61", title: "Kotpelarens leder,distorsion" },
  { value: "ZZLC64", title: "Kotpelarens leder,ledbandsskada" },
  { value: "ZZLC65", title: "Kotpelarens leder,luxation/subluxation" },
  { value: "ZZLC7", title: "Kotpelarens leder,toxisk sjd" },
  { value: "ZZLC8", title: "Kotpelarens leder,neoplastisk sjd" },
  {
    value: "ZZLC9",
    title: "Kotpelarens leder,ospec./multifakt./idiopatisk sjd",
  },
  {
    value: "ZZLF0",
    title: "Ileo-sacralled,normalvar/standardingrepp/-utredn.",
  },
  { value: "ZZLF00", title: "Ileo-sacralled,normalvariation" },
  { value: "ZZLF01", title: "Ileo-sacralled,utredning ospec.tillstånd" },
  { value: "ZZLF02", title: "Ileo-sacralled,hältutredning" },
  {
    value: "ZZLF1",
    title: "Ileo-sacralled,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZLF14", title: "Sacralisation" },
  {
    value: "ZZLF2",
    title: "Ileo-sacralled,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZLF3", title: "Ileo-sacralled,dystrofisk/degenerativ sjd" },
  { value: "ZZLF31", title: "Ileo-sacralled,arthros" },
  { value: "ZZLF4", title: "Ileo-sacralled,inflammatorisk/immunologisk sjd" },
  { value: "ZZLF41", title: "Ileo-sacralled,inflammation" },
  { value: "ZZLF5", title: "Ileo-sacralled,infektiös sjd" },
  { value: "ZZLF52", title: "Ileo-sacralled,infektiös artrit" },
  { value: "ZZLF6", title: "Ileo-sacralled,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZLF61", title: "Ileo-sacralled,distorsion" },
  { value: "ZZLF65", title: "Ileo-sacralled,luxation/subluxation" },
  { value: "ZZLF7", title: "Ileo-sacralled,toxisk sjd" },
  { value: "ZZLF8", title: "Ileo-sacralled,neoplastisk sjd" },
  { value: "ZZLF9", title: "Ileo-sacralled,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZLH0", title: "Bogled,normalvar/standardingrepp/-utredn." },
  { value: "ZZLH00", title: "Bogled,normalvariation" },
  { value: "ZZLH01", title: "Bogled,utredning ospec,tillstånd" },
  { value: "ZZLH02", title: "Bogled,hältutredning" },
  { value: "ZZLH1", title: "Bogled,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZLH12", title: "Bogled,osteochondros" },
  { value: "ZZLH2", title: "Bogled,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZLH3", title: "Bogled,dystrofisk/degenerativ sjd" },
  { value: "ZZLH31", title: "Bogled,arthros" },
  { value: "ZZLH4", title: "Bogled,inflammatorisk/immunologisk sjd" },
  { value: "ZZLH41", title: "Bogled,ledinflammation" },
  { value: "ZZLH5", title: "Bogled,infektiös sjd" },
  { value: "ZZLH52", title: "Bogled,infektiös artrit" },
  { value: "ZZLH6", title: "Bogled,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZLH61", title: "Bogled,distorsion" },
  { value: "ZZLH64", title: "Bogled,ledbandsskada övrig" },
  { value: "ZZLH65", title: "Bogled,luxation/subluxation" },
  { value: "ZZLH7", title: "Bogled,toxisk sjd" },
  { value: "ZZLH8", title: "Bogled,neoplastisk sjd" },
  { value: "ZZLH9", title: "Bogled,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZLK0", title: "Armbågsled,normalvar/standardingrepp/utredning" },
  { value: "ZZLK00", title: "Armbågsled,normalvariation" },
  { value: "ZZLK01", title: "Armbågsled,utredning ospec.tillstånd" },
  { value: "ZZLK02", title: "Armbågsled,hältutredning" },
  { value: "ZZLK1", title: "Armbågsled,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZLK11", title: "Armbågsled,dysplasi" },
  { value: "ZZLK12", title: "Armbågsled,osteochondros" },
  { value: "ZZLK121", title: "Armbågsled,OCD/coron.proc" },
  { value: "ZZLK122", title: "Armbågsled,ununited anconeal proc." },
  { value: "ZZLK2", title: "Armbågsled,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZLK3", title: "Armbågsled,dystrofisk/degenerativ sjd" },
  { value: "ZZLK31", title: "Armbågsled,arthros" },
  { value: "ZZLK4", title: "Armbågsled,inflammatorisk/immunologisk sjd" },
  { value: "ZZLK41", title: "Armbågsled,ledinflammation" },
  { value: "ZZLK5", title: "Armbågsled,infektiös sjd" },
  { value: "ZZLK52", title: "Armbågsled,infektiös artrit" },
  { value: "ZZLK6", title: "Armbågsled,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZLK61", title: "Armbågsled,distorsion" },
  { value: "ZZLK64", title: "Armbågsled,ledbandsskada" },
  { value: "ZZLK65", title: "Armbågsled,luxation/subluxation" },
  { value: "ZZLK7", title: "Armbågsled,toxisk sjd" },
  { value: "ZZLK8", title: "Armbågsled,neoplastisk sjd" },
  { value: "ZZLK9", title: "Armbågsled,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZLL0", title: "Carpalled,normalvar/standardingrepp/-utredn." },
  { value: "ZZLL00", title: "Carpalled,normalvariation" },
  { value: "ZZLL01", title: "Carpalled,utredning ospec.tillstånd" },
  { value: "ZZLL02", title: "Carpalled,hältutredning" },
  { value: "ZZLL1", title: "Carpalled,kongenital/utveckl.mässig/konstit sjd" },
  { value: "ZZLL12", title: "Carpalled,osteochondros" },
  { value: "ZZLL2", title: "Carpalled,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZLL3", title: "Carpalled,dystrofisk/degenerativ sjd" },
  { value: "ZZLL31", title: "Carpalled,arthros" },
  { value: "ZZLL4", title: "Carpalled,inflammatorisk/immunologisk sjd" },
  { value: "ZZLL41", title: "Carpalled,ledinflammation" },
  { value: "ZZLL5", title: "Carpalled,infektiös sjd" },
  { value: "ZZLL52", title: "Carpalled,infektiös artrit" },
  { value: "ZZLL6", title: "Carpalled,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZLL61", title: "Carpalled,distorsion" },
  { value: "ZZLL64", title: "Carpalled,ledbandsskada" },
  { value: "ZZLL65", title: "Carpalled,luxation/subluxation" },
  { value: "ZZLL7", title: "Carpalled,toxisk sjd" },
  { value: "ZZLL8", title: "Carpalled,neoplastisk sjd" },
  { value: "ZZLL9", title: "Carpalled,ospec/multifakt/idiopatisk sjd" },
  {
    value: "ZZLM0",
    title: "Falangleder fram,normalvar/standardingrepp/-utredn.",
  },
  { value: "ZZLM00", title: "Falangleder fram,normalvariation" },
  { value: "ZZLM01", title: "Falangleder fram,utredning ospec.tillstånd" },
  { value: "ZZLM02", title: "Falangleder fram,hältutredning" },
  {
    value: "ZZLM1",
    title: "Falangleder fram,kongenital/utveckl.mässig/konstit.sjd",
  },
  {
    value: "ZZLM2",
    title: "Falangleder fram,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZLM3", title: "Falangleder fram,dystrofisk/degenerativ sjd" },
  { value: "ZZLM31", title: "Falangleder fram,arthros" },
  { value: "ZZLM4", title: "Falangleder fram,inflammatorisk/immunologisk sjd" },
  { value: "ZZLM41", title: "Falangleder fram,ledinflammation" },
  { value: "ZZLM5", title: "Falangleder fram,infektiös sjd" },
  { value: "ZZLM52", title: "Falangleder fram,infektiös artrit" },
  {
    value: "ZZLM6",
    title: "Falangleder fram,mekanisk/traumatisk sjd/corp.al.",
  },
  { value: "ZZLM61", title: "Falangleder fram,distorsion" },
  { value: "ZZLM64", title: "Falangleder fram,ledbandsskada" },
  { value: "ZZLM65", title: "Falangleder fram,luxation/subluxation" },
  { value: "ZZLM8", title: "Falangleder fram,neoplastisk sjd" },
  { value: "ZZLM9", title: "Falangleder fram,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZLO00", title: "Höftled,normalvariation" },
  { value: "ZZLO01", title: "Höftled,utredning ospec.tillstånd" },
  { value: "ZZLO02", title: "Höftled,hältutredning" },
  { value: "ZZLO1", title: "Höftled,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZLO11", title: "Höftled,dysplasi" },
  { value: "ZZLO12", title: "Höftled,osteochondros" },
  { value: "ZZLO2", title: "Höftled,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZLO3", title: "Höftled,dystrofisk/degenerativ sjd" },
  { value: "ZZLO31", title: "Höftled,arthros" },
  { value: "ZZLO32", title: "Leg-Perthes sjd" },
  { value: "ZZLO4", title: "Höftled,inflammatorisk/immunologisk sjd" },
  { value: "ZZLO41", title: "Höftled,ledinflammation" },
  { value: "ZZLO5", title: "Höftled,infektiös sjd" },
  { value: "ZZLO52", title: "Höftled,infektiös artrit" },
  { value: "ZZLO6", title: "Höftled,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZLO61", title: "Höftled,distorsion" },
  { value: "ZZLO64", title: "Höftled,ledbandsskada" },
  { value: "ZZLO65", title: "Höftled,luxation/subluxation" },
  { value: "ZZLO8", title: "Höftled,neoplastisk sjd" },
  { value: "ZZLO9", title: "Höftled,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZLOL0", title: "Höftled,normalvar/standardingrepp/-utredn" },
  { value: "ZZLP0", title: "Knäled,normalvar/standardingrepp/-utredn." },
  { value: "ZZLP00", title: "Knäled,normalvariation" },
  { value: "ZZLP01", title: "Knäled,utredning ospec.tillstånd" },
  { value: "ZZLP02", title: "Knäled,hältutredning" },
  { value: "ZZLP1", title: "Knäled,kongenital/utveckl.mässig/konstit sjd" },
  { value: "ZZLP12", title: "Knäled,osteochondros" },
  { value: "ZZLP13", title: "Patellaluxation" },
  { value: "ZZLP2", title: "Knäled,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZLP3", title: "Knäled,dystrofisk/degenerativ sjd" },
  { value: "ZZLP31", title: "Knäled arthros" },
  { value: "ZZLP4", title: "Knäled,inflammatorisk/immunologisk sjd" },
  { value: "ZZLP41", title: "Knäled,ledinflammation" },
  { value: "ZZLP5", title: "Knäled,infektiös sjd" },
  { value: "ZZLP52", title: "Knäled,infektiös artrit" },
  { value: "ZZLP6", title: "Knäled,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZLP61", title: "Knäled,distorsion" },
  { value: "ZZLP62", title: "Knäled,meniskskada" },
  { value: "ZZLP63", title: "Korsbandsruptur/gonit" },
  { value: "ZZLP64", title: "Knäled,ledbandsskada" },
  { value: "ZZLP65", title: "Knäled,luxation/subluxation" },
  { value: "ZZLP8", title: "Knäled,neoplastisk sjd" },
  { value: "ZZLP9", title: "Knäled,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZLR0", title: "Hasled,normalvar/standardingrepp/-utredn." },
  { value: "ZZLR00", title: "Hasled,normalvariation" },
  { value: "ZZLR01", title: "Hasled,utredning ospec.tillstånd" },
  { value: "ZZLR02", title: "Hasled,hältutredning" },
  { value: "ZZLR1", title: "Hasled,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZLR12", title: "Hasled,osteochondros" },
  { value: "ZZLR2", title: "Hasled,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZLR3", title: "Hasled,dystrofisk/degenerativ sjd" },
  { value: "ZZLR31", title: "Hasled,arthros" },
  { value: "ZZLR4", title: "Hasled,inflammatorisk/immunologisk sjd" },
  { value: "ZZLR41", title: "Hasled,ledinflammation" },
  { value: "ZZLR5", title: "Hasled,infektiös sjd" },
  { value: "ZZLR52", title: "Hasled,infektiös artrit" },
  { value: "ZZLR6", title: "Hasled,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZLR61", title: "Hasled,distorsion" },
  { value: "ZZLR64", title: "Hasled,ledbandsskada" },
  { value: "ZZLR65", title: "Hasled,luxation/subluxation" },
  { value: "ZZLR8", title: "Hasled,neoplastisk sjd" },
  { value: "ZZLR9", title: "Hasled,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZLS00", title: "Falangleder bakben,normalvariation" },
  { value: "ZZLS01", title: "Falangleder bakben,utredning ospec.tillstånd" },
  { value: "ZZLS02", title: "Falangleder bakben,hältutredning" },
  {
    value: "ZZLS1",
    title: "Falangleder bakben,kongenital/utveckl.mässig/konstit sjd",
  },
  {
    value: "ZZLS2",
    title: "Falangleder bakben,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZLS3", title: "Falangleder bakben,dystrofisk/degenerativ sjd" },
  { value: "ZZLS31", title: "Falangleder bakben,arthros" },
  {
    value: "ZZLS4",
    title: "Falangleder bakben,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZLS41", title: "Falangleder bakben,ledinflammation" },
  { value: "ZZLS5", title: "Falangleder bakben,infektiös sjd" },
  { value: "ZZLS52", title: "Falangleder bakben,infektiös artrit" },
  {
    value: "ZZLS6",
    title: "Falangleder bakben,mekanisk/traumatisk sjd/corp.al.",
  },
  { value: "ZZLS61", title: "Falangleder bakben,distorsion" },
  { value: "ZZLS64", title: "Falangleder bakben,ledbandsskada" },
  { value: "ZZLS65", title: "Falangleder bakben,luxation/subluxation" },
  { value: "ZZLS8", title: "Falangleder bakben,neoplastisk sjd" },
  {
    value: "ZZLS9",
    title: "Falangleder bakben,ospec/multifakt/idiopatisk sjd",
  },
  {
    value: "ZZLSL0",
    title: "Falangleder bakben,normalvar/standardingrepp/-utredn.",
  },
  {
    value: "ZZMA0",
    title: "Muskulatur/senor,normalvar/standardingrepp/-utredn.",
  },
  { value: "ZZMA00", title: "Muskulatur/senor,normalvariation" },
  { value: "ZZMA01", title: "Muskulatur/senor,utredning ospec.tillstånd" },
  {
    value: "ZZMA1",
    title: "Muskulatur/senor,kongenital/utveckl.mässig/konstit.sjd",
  },
  {
    value: "ZZMA2",
    title: "Muskulatur/senor,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZMA21", title: "Myolys/myoglobinuri" },
  { value: "ZZMA3", title: "Muskulatur/senor,dystrofisk/degenerativ sjd" },
  { value: "ZZMA31", title: "Kontraktur muskel,sena" },
  { value: "ZZMA4", title: "Muskulatur/senor,inflammatorisk/immunologisk sjd" },
  { value: "ZZMA41", title: "Tendinit" },
  { value: "ZZMA411", title: "Tendinit / fibros i bicepssenan" },
  { value: "ZZMA412", title: "Fibros m. gracilis/semimembr./semitend." },
  { value: "ZZMA413", title: "Förkalkning m.supraspinatus" },
  { value: "ZZMA42", title: "Myosit lokal" },
  { value: "ZZMA43", title: "Polymyosit" },
  { value: "ZZMA5", title: "Muskulatur/senor,infektiös sjd" },
  {
    value: "ZZMA6",
    title: "Muskulatur/senor,mekanisk/traumatisk sjd/corp.al.",
  },
  { value: "ZZMA61", title: "Slipped tendon" },
  { value: "ZZMA62", title: "Sträckning muskel/sena" },
  { value: "ZZMA63", title: "Traumatisk ruptur muskel/sena" },
  { value: "ZZMA64", title: "Avskuren sena/senor" },
  { value: "ZZMA65", title: "Hematom muskulatur" },
  { value: "ZZMA7", title: "Muskulatur/senor,toxisk sjd" },
  { value: "ZZMA8", title: "Muskulatur/senor,neoplastisk sjd" },
  { value: "ZZMA9", title: "Muskulatur/senor,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZMA91", title: "Sjukgymnastikbehandling" },
  { value: "ZZNA0", title: "Hjärna,normalvar/standardingrepp/-utredn." },
  { value: "ZZNA00", title: "Hjärna,normalvarition" },
  { value: "ZZNA01", title: "Hjärna,utredning ospec.tillstånd" },
  { value: "ZZNA1", title: "Hjärna,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZNA11", title: "Hydrocephalus" },
  { value: "ZZNA12", title: "Cerebellär hypoplasi/ataxi" },
  { value: "ZZNA13", title: "Epilepsi" },
  { value: "ZZNA2", title: "Hjärna,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZNA21", title: "Hjärnblödning" },
  { value: "ZZNA3", title: "Hjärna,dystrofisk/degenerativ sjd" },
  { value: "ZZNA4", title: "Hjärna,inflammatorisk/immunologisk sjd" },
  { value: "ZZNA41", title: "Encephalit,icke infektiös" },
  { value: "ZZNA42", title: "Meningit,icke infektiös" },
  { value: "ZZNA5", title: "Hjärna,infektiös sjd" },
  { value: "ZZNA51", title: "Hjärna,viros" },
  { value: "ZZNA511", title: "Vingelsjuka" },
  { value: "ZZNA512", title: "Encephalit/meningit,viros" },
  { value: "ZZNA52", title: "Hjärna,bakteriell inf." },
  { value: "ZZNA521", title: "Encephalit/meningit,bakteriell" },
  { value: "ZZNA523", title: "Tetanus" },
  { value: "ZZNA59", title: "Hjärna,infektiösa sjd,okänd orsak" },
  { value: "ZZNA591", title: "Encephalit/meningit,okänd orsak" },
  { value: "ZZNA6", title: "Hjärna,mekanisk/traumanisk sjd/corp.al." },
  { value: "ZZNA61", title: "Commotio cerebri/traumatisk hjärnskada" },
  { value: "ZZNA7", title: "Hjärna,toxisk sjd" },
  { value: "ZZNA8", title: "Hjärna,neoplastisk sjd" },
  { value: "ZZNA81", title: "Hjärntumör/intrakraniell tumör" },
  { value: "ZZNA9", title: "Hjärna ospec/multifakt/idiopatisk sjd" },
  { value: "ZZNA92", title: "Fly catching,idiopatisk" },
  { value: "ZZNA93", title: "Pares/paralys,övrig intrakraniell" },
  { value: "ZZNA94", title: "Vestibularissyndrom" },
  { value: "ZZNA95", title: "Skottekramp" },
  { value: "ZZNA96", title: "Balansrubbning,okänd etiologi" },
  { value: "ZZNA97", title: "Kramptillstånd,okänd etiologi" },
  { value: "ZZNA98", title: "Psykisk rubbning/aggressivitet" },
  { value: "ZZNB0", title: "Ryggmärg,normalvar/standardingrepp/-utredn." },
  { value: "ZZNB00", title: "Ryggmärg,normalvariation" },
  { value: "ZZNB01", title: "Ryggmärg,utredning ospec.tillstånd" },
  { value: "ZZNB1", title: "Ryggmärg,kongenital/utveckl.mässig/konstit sjd" },
  {
    value: "ZZNB11",
    title: "Wobbler/instabilitet/missbildning halskotepelare",
  },
  { value: "ZZNB111", title: "Wobblerrtg borzoi" },
  { value: "ZZNB12", title: "Epiduralcysta" },
  { value: "ZZNB13", title: "Sinus dermoid" },
  { value: "ZZNB2", title: "Ryggmärg,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZNB3", title: "Ryggmärg,dystrofisk/degenerativ sjd" },
  { value: "ZZNB31", title: "Cartilaginous embolus" },
  { value: "ZZNB32", title: "L7S1-syndromet" },
  { value: "ZZNB33", title: "Prolapsus disci" },
  { value: "ZZNB330", title: "Prol.disc.cerv.,konservativ beh." },
  { value: "ZZNB331", title: "Prol.disc.cerv.,op.fenestrering" },
  { value: "ZZNB332", title: "Prol.disc.cerv.,op.laminektomi/slot" },
  { value: "ZZNB34", title: "Prol.disc.thoraco/lumb" },
  { value: "ZZNB340", title: "Prol.disc.thoraco/lumb.,konservativ beh." },
  { value: "ZZNB341", title: "Prol.disc.thoraco/lumb.,op.fenestrering" },
  { value: "ZZNB342", title: "Prol.disc.thoraco/lumb.,op.laminektomi" },
  { value: "ZZNB35", title: "Radikulomyelopati" },
  { value: "ZZNB36", title: "Ryggmärgskompression övrig" },
  { value: "ZZNB4", title: "Ryggmärg,inflammatorisk/immunologisk sjd" },
  { value: "ZZNB5", title: "Ryggmärg,infektiös sjd" },
  { value: "ZZNB6", title: "Ryggmärg,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZNB7", title: "Ryggmärg,toxisk sjd" },
  { value: "ZZNB8", title: "Ryggmärg,neoplastisk sjd" },
  { value: "ZZNB81", title: "Tumör i/vid sidan av ryggmärgen" },
  { value: "ZZNB9", title: "Ryggmärg,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZNC00", title: "Perifera nerver,normalvariation" },
  { value: "ZZNC01", title: "Perifera nerver,utredning ospec.tillstånd" },
  {
    value: "ZZNC1",
    title: "Perifera nerver,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZNC11", title: "Axonopati boxer" },
  {
    value: "ZZNC2",
    title: "Periferera nerver,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZNC3", title: "Periferera nerver,dystrofisk/degenerativ sjd" },
  { value: "ZZNC31", title: "Fascialispares" },
  { value: "ZZNC32", title: "Svalgpares" },
  { value: "ZZNC33", title: "Larynxpares." },
  { value: "ZZNC35", title: "Trigeminusparalys/sensoriskt bortfall" },
  { value: "ZZNC36", title: "Felin dysautonomi" },
  { value: "ZZNC4", title: "Perifera nerver,inflammatorisk/immunologisk sjd" },
  { value: "ZZNC41", title: "Water tail." },
  { value: "ZZNC42", title: "Polyradikuloneurit" },
  { value: "ZZNC43", title: "Polyneurit övriga" },
  { value: "ZZNC5", title: "Perifera nerver,infektiös sjd" },
  { value: "ZZNC6", title: "Perifera nerver,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZNC61", title: "Perifer nervskada/plexusskada extremitetsnerver" },
  { value: "ZZNC62", title: "Perifer nervskada kranialnerver" },
  { value: "ZZNC7", title: "Perifera nerver,toxisk sjd" },
  { value: "ZZNC8", title: "Perifera nerver,neoplastisk sjd" },
  { value: "ZZNC9", title: "Perifera nerver,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZNC91", title: "Pares/paralys övriga perifera nerver" },
  {
    value: "ZZNCN0",
    title: "Perifera nerver,normalvar/standardingrepp/-utredn.",
  },
  { value: "ZZOA0", title: "Observation,normalvar/standardingrepp/-utredn." },
  { value: "ZZOA00", title: "Normalstatus,normalvariation" },
  { value: "ZZOA01", title: "Hälsokontroll" },
  { value: "ZZOA02", title: "Besiktning/intyg" },
  { value: "ZZOA03", title: "Vaccination" },
  { value: "ZZOA04", title: "Avlivning/omhändertagande" },
  { value: "ZZOA05", title: "Omhändertagande av dött djur" },
  { value: "ZZOA06", title: "HD- och/eller armbågsledsröntgen" },
  { value: "ZZOA07", title: "ID-märkning" },
  { value: "ZZOA08", title: "Suturtagning/dräntagning" },
  { value: "ZZOA09", title: "Seniorkontroll" },
  { value: "ZZOA091", title: "Seniorhälsokontroll" },
  { value: "ZZOA092", title: "Tjänstehundkontroll" },
  { value: "ZZOA9", title: "Observation,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZOA91", title: "Utredning trötthet, diagn. ej fastställd" },
  { value: "ZZOA92", title: "Vanvård" },
  { value: "ZZOA93", title: "Observation efter trafikskada" },
  { value: "ZZOA94", title: "Observation efter annat trauma" },
  { value: "ZZOA95", title: "Obesitas" },
  { value: "ZZOA97", title: "Rabiesprovtagning" },
  {
    value: "ZZRA0",
    title: "Benmärg/lymforetikulära syst,normalvar/standardingrepp/utred",
  },
  { value: "ZZRA00", title: "Benmärg/lymforetikulära syst.,normalvariation" },
  {
    value: "ZZRA01",
    title: "Benmärg/lymforetikulära syst.,utredning ospec.tillstånd",
  },
  {
    value: "ZZRA1",
    title: "Benmärg/lymforetikulära syst,kongenital/utv.mäss/konstit sjd",
  },
  { value: "ZZRA11", title: "Faktor VIII-brist" },
  { value: "ZZRA12", title: "von Willebrands sjukdom" },
  { value: "ZZRA13", title: "Koagulationsfaktorbrist övrig" },
  {
    value: "ZZRA2",
    title: "Benmärg/lymforetikulära syst,metabolisk/nutri/hormonell sjd",
  },
  { value: "ZZRA21", title: "Anemi" },
  { value: "ZZRA211", title: "Anemi pga bristsjd eller intoxikation" },
  { value: "ZZRA212", title: "Anemi regenerativ,ospecifik" },
  { value: "ZZRA213", title: "Anemi non-regenerativ,ospecifik" },
  {
    value: "ZZRA3",
    title: "Benmärg/lymforetikulära syst.,dystrofisk/degenerativ sjd",
  },
  { value: "ZZRA31", title: "Leukopeni,ospec.etiologi" },
  {
    value: "ZZRA4",
    title: "Benmärg/lymforetikulära syst,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZRA41", title: "Autoimmun sjd" },
  { value: "ZZRA411", title: "Systemisk Lupus Erythematosus (SLE)" },
  { value: "ZZRA412", title: "Rheumatoid artrit" },
  { value: "ZZRA413", title: "Myastenia gravis" },
  { value: "ZZRA414", title: "Autoimmun hemolytisk anemi" },
  { value: "ZZRA415", title: "Autoimmun trombocytopeni" },
  { value: "ZZRA42", title: "Systemisk allergisjd" },
  { value: "ZZRA421", title: "Akut överkänslighetsreaktion,anafylaxi" },
  { value: "ZZRA5", title: "Benmärg/lymforetikulära syst.,infektiös sjd" },
  { value: "ZZRA51", title: "Lymfangit/lymfadenit" },
  {
    value: "ZZRA6",
    title: "Benmärg/lymforetikulära syst,mekanisk/traumatisk sjd/corp.al",
  },
  { value: "ZZRA61", title: "Mjältruptur traumatisk" },
  { value: "ZZRA612", title: "Mjälte omvridning" },
  { value: "ZZRA62", title: "Mjältomvridning" },
  { value: "ZZRA7", title: "Benmärg/lymforetikulära syst.,toxisk sjd" },
  { value: "ZZRA8", title: "Benmärg/lymforetikulära syst.,neoplastisk sjd" },
  { value: "ZZRA81", title: "Leukos" },
  { value: "ZZRA82", title: "Mjälttumör" },
  { value: "ZZRA821", title: "Mjälttumör brusten" },
  {
    value: "ZZRA9",
    title: "Benmärg/lymforetikulära syst.,ospec/multifakt/idiopatisk sjd",
  },
  { value: "ZZRA91", title: "Mjältförstoring, ospecifik" },
  { value: "ZZRA92", title: "Polycytemia vera" },
  { value: "ZZRA93", title: "Ökad blödningstendens, okänd etiologi" },
  { value: "ZZRA94", title: "Trombocytdysfunktion" },
  { value: "ZZRA95", title: "Disseminerad Intravaskulär Koagulation DIC" },
  { value: "ZZRA96", title: "Lymfknuteförstoring, orsak ej fastställd" },
  { value: "ZZRA97", title: "Blödning bröst-/bukhåla" },
  { value: "ZZRA98", title: "Blödning ospecificerad" },
  {
    value: "ZZSA0",
    title: "Flr.skelettben involv.normalvar./standingrepp/-utredning",
  },
  { value: "ZZSA00", title: "Flr.skelettben involv.normalvariation" },
  { value: "ZZSA01", title: "Flr.skelettben involv.utredning ospec.tillstånd" },
  { value: "ZZSA03", title: "Stift/skruv/plattborttagning" },
  { value: "ZZSA04", title: "Sporrexstirpation" },
  {
    value: "ZZSA1",
    title: "Flr.skelettben involv.,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZSA11", title: "Flr.skelettben involv.,bencysta" },
  { value: "ZZSA12", title: "Flr.skelettben involv.,missbildning" },
  { value: "ZZSA13", title: "Flr.skelettben involv.,retained cartilage" },
  { value: "ZZSA14", title: "Flr. skelettben involv., tillväxtrubbning" },
  {
    value: "ZZSA2",
    title: "Flr.skelettben involv.,metabol/nutritionell/hormonell sjd",
  },
  { value: "ZZSA21", title: "Flr.skelettben involv.osteomalaci/dystrofi" },
  {
    value: "ZZSA22",
    title: "Flr.skelettben involv.,hypertrofisk osteodystrofi",
  },
  { value: "ZZSA23", title: "Achropaki" },
  {
    value: "ZZSA3",
    title: "Flr.skelettben involv.,dystrofisk/degenerativ sjd",
  },
  {
    value: "ZZSA31",
    title: "Flr.skelettben involv.kraniomandibulär osteopati",
  },
  { value: "ZZSA32", title: "Flr.skelettben involv. spondylos" },
  { value: "ZZSA33", title: "Osteoporos" },
  {
    value: "ZZSA4",
    title: "Flr.skelettben involv.,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZSA41", title: "Flr.skelettben involv.,panosteit" },
  { value: "ZZSA5", title: "Flr.skelettben involv. infektiös sjd" },
  { value: "ZZSA52", title: "Flr.skelettben involv.bakteriella infektioner" },
  { value: "ZZSA521", title: "Flr.skelettben involv. osteomyelit" },
  {
    value: "ZZSA6",
    title: "Flr.skelettben involv.,mek./traumatisk sjd/corp.al",
  },
  {
    value: "ZZSA61",
    title: "Flr.skelettben involv.,epifysglid/epifysiolys/epifysskada",
  },
  { value: "ZZSA62", title: "Flr.skelettben involv.,fraktur" },
  {
    value: "ZZSA621",
    title: "Flr.skelettben involv.,fraktur,okompl/enkel/sluten",
  },
  {
    value: "ZZSA622",
    title: "Flr.skelettben involv.,fraktur,flera fragment/öppen",
  },
  {
    value: "ZZSA63",
    title: "Flr.skelettben involv.,kompl.till frakturläkning",
  },
  { value: "ZZSA64", title: "Flr.skelettben involv.,periostit" },
  { value: "ZZSA65", title: "Flr.skelettben involv.,prematur epifysslutning" },
  {
    value: "ZZSA66",
    title: "Flr.skelettben involv.,korrekt.kir.vid felaktig benaxel",
  },
  { value: "ZZSA7", title: "Flr.skelettben involv.,toxisk sjd" },
  { value: "ZZSA8", title: "Flr.skelettben involv.neoplastisk sjd" },
  {
    value: "ZZSA9",
    title: "Flr.skelettben involv.,ospec./multifakt./idiopatisk sjd",
  },
  {
    value: "ZZSA91",
    title: "Flr.skelettben involv.hypertrofisk osteodystrofi",
  },
  { value: "ZZSB0", title: "Huvudets ben,normalvar/standardingrepp/utredn." },
  { value: "ZZSB00", title: "Huvudets ben,normalvariation" },
  { value: "ZZSB01", title: "Huvudets ben,utredning ospec.tillstånd" },
  { value: "ZZSB03", title: "Huvudets ben, spik/skruv/plattborttagning" },
  {
    value: "ZZSB1",
    title: "Huvudets ben,kongenital/utveckl.mässig/konstit sjd",
  },
  { value: "ZZSB11", title: "Huvudets ben,bencysta" },
  { value: "ZZSB12", title: "Huvudets ben,missbildning" },
  {
    value: "ZZSB2",
    title: "Huvudets ben,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZSB21", title: "Huvudets ben,osteomalaci/dystrofi" },
  { value: "ZZSB211", title: "Rubber jaw" },
  { value: "ZZSB3", title: "Huvudets ben,dystrofisk/degenerativ sjd" },
  { value: "ZZSB31", title: "Kraniomandibulär osteopati" },
  { value: "ZZSB4", title: "Huvudets ben,inflammatorisk/immunologisk sjd" },
  { value: "ZZSB5", title: "Huvudets ben,infektiös sjd" },
  { value: "ZZSB52", title: "Huvudets ben,bakteriella infektioner" },
  { value: "ZZSB521", title: "Huvudets ben,osteomyelit" },
  { value: "ZZSB6", title: "Huvudets ben,mekanisk/traumatisk sjd/corp.al." },
  {
    value: "ZZSB61",
    title: "Huvudets ben,epifysglidning/epifysiolys/epifysskada",
  },
  { value: "ZZSB62", title: "Huvudets ben,fraktur" },
  { value: "ZZSB621", title: "Huvudets ben,fraktur,okompliserad/enkel/sluten" },
  { value: "ZZSB622", title: "Huvudets ben,fraktur,flera fragment/öppen" },
  { value: "ZZSB63", title: "Huvudets ben,komplikationer till frakturläkning" },
  { value: "ZZSB64", title: "Huvudets ben,periostit" },
  { value: "ZZSB65", title: "Huvudets ben,prematur epifysslutning" },
  { value: "ZZSB7", title: "Huvudets ben,toxisk sjd" },
  { value: "ZZSB8", title: "Huvudets ben,neoplastisk sjd" },
  { value: "ZZSB9", title: "Huvudets ben,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZSC0", title: "Kotpelaren,normalvar/standardingrepp/-utredn." },
  { value: "ZZSC00", title: "Kotpelaren,normalvariation" },
  { value: "ZZSC01", title: "Kotpelaren,utredning ospec.tillstånd" },
  { value: "ZZSC02", title: "Svansamputation" },
  { value: "ZZSC03", title: "Kotpelaren, spik/skruv/plattborttagning" },
  { value: "ZZSC1", title: "Kotpelaren,kongenital/utveckl.mässig/konstit sjd" },
  { value: "ZZSC11", title: "Kotpelaren,bencysta" },
  { value: "ZZSC12", title: "Kotpelaren,missbildning" },
  { value: "ZZSC14", title: "Atlantoaxial subluxation" },
  { value: "ZZSC2", title: "Kotpelaren,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZSC21", title: "Kotpelaren,osteomalaci/dystrofi" },
  { value: "ZZSC22", title: "Kotpelaren,hypertrofisk osteodystrofi" },
  { value: "ZZSC3", title: "Kotpelaren,dystrofisk/degenerativ sjd" },
  { value: "ZZSC32", title: "Spondylos" },
  { value: "ZZSC4", title: "Kotpelaren,inflammatorisk/immunologisk sjd" },
  { value: "ZZSC5", title: "Kotpelaren,infektiös sjd" },
  { value: "ZZSC52", title: "Kotpelaren,bakteriella infektioner" },
  { value: "ZZSC521", title: "Kotpelaren,osteomyelit" },
  { value: "ZZSC522", title: "Diskospondylit/spondylit" },
  { value: "ZZSC6", title: "Kotpelaren,mekanisk/traumatisk sjd/corp.al." },
  {
    value: "ZZSC61",
    title: "Kotpelaren,epifysglidning/epifysiolys/epifysskada",
  },
  { value: "ZZSC62", title: "Kotpelaren,fraktur" },
  { value: "ZZSC621", title: "Kotpelaren,fraktur,okompliserad/enkel/sluten" },
  { value: "ZZSC622", title: "Kotpelaren,fraktur,flera fragment/öppen" },
  { value: "ZZSC63", title: "Kotpelaren,komplikationer till frakturläkning" },
  { value: "ZZSC64", title: "Kotpelaren,periostit" },
  { value: "ZZSC65", title: "Kotpelaren,prematur epifysslutning" },
  { value: "ZZSC7", title: "Kotpelaren,toxisk sjd" },
  { value: "ZZSC8", title: "Kotpelaren,neoplastisk sjd" },
  { value: "ZZSC9", title: "Kotpelaren,ospec/multifakt/idiopatisk sjd" },
  {
    value: "ZZSD0",
    title: "Revben,bröstben,normalvar/standardingrepp/-utredn.",
  },
  { value: "ZZSD00", title: "Revben,bröstben,normalvariation" },
  { value: "ZZSD01", title: "Revben,bröstben,utredning ospec.tillstånd" },
  {
    value: "ZZSD1",
    title: "Revben,bröstben,kongenital/utveckl.mässig/konstit sjd",
  },
  { value: "ZZSD11", title: "Revben,bröstben,bencysta" },
  { value: "ZZSD12", title: "Revben,bröstben,missbildning" },
  { value: "ZZSD13", title: "Revben,bröstben,retained cartilage" },
  {
    value: "ZZSD2",
    title: "Revben,bröstben,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZSD21", title: "Revben,bröstben,osteomalaci/dystrofi" },
  { value: "ZZSD22", title: "Revben,bröstben,hypertrofisk osteodystrofi" },
  { value: "ZZSD3", title: "Revben,bröstben,dystrofisk/degenerativ sjd" },
  { value: "ZZSD4", title: "Revben,bröstben,inflammatorisk/immunologisk sjd" },
  { value: "ZZSD41", title: "Revben,bröstben,panosteit" },
  { value: "ZZSD5", title: "Revben,bröstben,infektiös sjd" },
  { value: "ZZSD52", title: "Revben,bröstben,bakteriella infektioner" },
  { value: "ZZSD521", title: "Revben,bröstben,osteomyelit" },
  { value: "ZZSD6", title: "Revben,bröstben,mekanisk/traumatisk sjd/corp.al." },
  {
    value: "ZZSD61",
    title: "Revben,bröstben,epifysglidning/epifysiolys/epifysskada",
  },
  { value: "ZZSD62", title: "Revben,bröstben,fraktur" },
  {
    value: "ZZSD621",
    title: "Revben,bröstben,fraktur,okompliserad/enkel/sluten",
  },
  { value: "ZZSD622", title: "Revben,bröstben,fraktur,flera fragment/öppen" },
  {
    value: "ZZSD63",
    title: "Revben,bröstben,komplikationer till frakturläkning",
  },
  { value: "ZZSD64", title: "Revben,bröstben,periostit" },
  { value: "ZZSD65", title: "Revben,bröstben,prematur epifysslutning" },
  { value: "ZZSD7", title: "Revben,bröstben,toxisk sjd" },
  { value: "ZZSD8", title: "Revben,bröstben,neoplastisk sjd" },
  { value: "ZZSD9", title: "Revben,bröstben,ospec./multifakt/idiopatisk sjd" },
  { value: "ZZSE0", title: "Scapula,normalvar/standardingrepp/-utredn." },
  { value: "ZZSE00", title: "Scapula,normalvariation" },
  { value: "ZZSE01", title: "Scapula,utredning ospec.tillstånd" },
  { value: "ZZSE03", title: "Scapula, spik/skruv/plattborttagning" },
  { value: "ZZSE1", title: "Scapula,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZSE11", title: "Scapula,bencysta" },
  { value: "ZZSE12", title: "Scapula,missbildning" },
  { value: "ZZSE13", title: "Scapula,retained cartilage" },
  { value: "ZZSE2", title: "Scapula,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZSE21", title: "Scapula,osteomalaci/dystrofi" },
  { value: "ZZSE22", title: "Scapula,hypertrofisk osteodystrofi" },
  { value: "ZZSE3", title: "Scapula,dystrofisk/degenerativ sjd" },
  { value: "ZZSE4", title: "Scapula,inflammatorisk/immunologisk sjd" },
  { value: "ZZSE41", title: "Scapula,panosteit" },
  { value: "ZZSE5", title: "Scapula,infektiös sjd" },
  { value: "ZZSE52", title: "Scapula,bakteriella infektioner" },
  { value: "ZZSE521", title: "Scapula,osteomyelit" },
  { value: "ZZSE6", title: "Scapula,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZSE61", title: "Scapula,epifysglidning/epifysiolys/epifysskada" },
  { value: "ZZSE62", title: "Scapula,fraktur" },
  { value: "ZZSE621", title: "Scapula,fraktur,okomplicerad/enkel/sluten" },
  { value: "ZZSE622", title: "Scapula,fraktur,flera fragment/öppen" },
  { value: "ZZSE63", title: "Scapula,komplikationer till frakturläkning" },
  { value: "ZZSE64", title: "Scapula,periostit" },
  { value: "ZZSE65", title: "Scapula,prematur epifysslutning" },
  { value: "ZZSE66", title: "Scapula,korrektionskirurgi vid felaktig benaxel" },
  { value: "ZZSE7", title: "Scapula,toxisk sjd" },
  { value: "ZZSE8", title: "Scapula,neoplastisk sjd" },
  { value: "ZZSE9", title: "Scapula,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZSF0", title: "Humerus,normalvar/standardingrepp/-utredn." },
  { value: "ZZSF00", title: "Humerus,normalvariation" },
  { value: "ZZSF01", title: "Humerus,utredning ospec.tillstånd" },
  { value: "ZZSF03", title: "Humerus, stift/skruv/plattborttagning" },
  { value: "ZZSF1", title: "Humerus,kongenital/utveckl.mässig/konstit sjd" },
  { value: "ZZSF11", title: "Humerus,bencysta" },
  { value: "ZZSF12", title: "Humerus,missbildning" },
  { value: "ZZSF13", title: "Humerus,retained cartilage" },
  { value: "ZZSF2", title: "Humerus,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZSF21", title: "Humerus,osteomalaci/dystrofi" },
  { value: "ZZSF22", title: "Humerus,hypertrofisk osteodystrofi" },
  { value: "ZZSF3", title: "Humerus,dystrofisk/degenerativ sjd" },
  { value: "ZZSF4", title: "Humerus,inflammatorisk/immunologisk sjd" },
  { value: "ZZSF41", title: "Humerus,panosteit" },
  { value: "ZZSF5", title: "Humerus,infektiös sjd" },
  { value: "ZZSF52", title: "Humerus,bakteriella infektioner" },
  { value: "ZZSF521", title: "Humerus,osteomyelit" },
  { value: "ZZSF6", title: "Humerus,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZSF61", title: "Humerus,epifysglidning/epifysiolys/epifysskada" },
  { value: "ZZSF62", title: "Humerus,fraktur" },
  { value: "ZZSF621", title: "Humerus,fraktur,okomplicerad/enkel/sluten" },
  { value: "ZZSF622", title: "Humerus,fraktur,flera fragment/öppen" },
  { value: "ZZSF63", title: "Humerus,komplikationer till frakturläkning" },
  { value: "ZZSF64", title: "Humerus,periostit" },
  { value: "ZZSF65", title: "Humerus,prematur epifysslutning" },
  { value: "ZZSF66", title: "Humerus,korrektionskirurgi vid felaktig benaxel" },
  { value: "ZZSF7", title: "Humerus,toxisk sjd" },
  { value: "ZZSF8", title: "Humerus,neoplastisk sjd" },
  { value: "ZZSF9", title: "Humerus,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZSG0", title: "Radius/ulna,normalvar/standardingrepp/-utredn." },
  { value: "ZZSG00", title: "Radius/ulna,normalvariation" },
  { value: "ZZSG01", title: "Radius/ulna,utredning ospec,tillstånd" },
  { value: "ZZSG03", title: "Radius/ulna, spik/skruv/plattborttagning" },
  {
    value: "ZZSG1",
    title: "Radius/ulna,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZSG11", title: "Radius/ulna,bencysta" },
  { value: "ZZSG12", title: "Radius/ulna,missbildning" },
  { value: "ZZSG13", title: "Radius/ulna,retained certilage" },
  {
    value: "ZZSG2",
    title: "Radius/ulna,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZSG21", title: "Radius/ulna,osteomalaci/dystrofi" },
  { value: "ZZSG22", title: "Radius/ulna,hypertrofisk osteodystrofi" },
  { value: "ZZSG3", title: "Radius/ulna,dystrofisk/degenerativ sjd" },
  { value: "ZZSG4", title: "Radius/ulna,inflammatorisk/immunologisk sjd" },
  { value: "ZZSG41", title: "Radius/ulna,panosteit" },
  { value: "ZZSG5", title: "Radius/ulna,infektiös sjd" },
  { value: "ZZSG52", title: "Radius/ulna,bakteriella infektioner" },
  { value: "ZZSG521", title: "Radius/ulna,osteomyelit" },
  { value: "ZZSG6", title: "Radius/ulna,mekanisk/traumatisk sjd/corp.al." },
  {
    value: "ZZSG61",
    title: "Radius/ulna,epifysglidning/epifysiolys/epifysskada",
  },
  { value: "ZZSG62", title: "Radius/ulna,fraktur" },
  { value: "ZZSG621", title: "Radius/ulna,fraktur,okomplicerad/enkel/sluten" },
  { value: "ZZSG622", title: "Radius/ulna,fraktur,flera fragment/öppen" },
  { value: "ZZSG63", title: "Radius/ulna,komplikationer till frakturläkning" },
  { value: "ZZSG64", title: "Radius/ulna,periostit" },
  { value: "ZZSG65", title: "Radius/ulna,prematur epifysslutning" },
  {
    value: "ZZSG66",
    title: "Radius/ulna,korrektionskirurgi vid felaktig benaxel",
  },
  { value: "ZZSG7", title: "Radius/ulna,toxisk sjd" },
  { value: "ZZSG8", title: "Radius/ulna,neoplastisk sjd" },
  { value: "ZZSG9", title: "Radius/ulna,ospec/multifakt/idiopatisk sjd" },
  { value: "ZZSH0", title: "Carpalben,normalvar/standardingrepp/-utredn." },
  { value: "ZZSH00", title: "Carpalben,normalvariation" },
  { value: "ZZSH01", title: "Carpalben,utredning ospec.tillstånd" },
  { value: "ZZSH03", title: "Carpalben, spik/skruv/plattborttagning" },
  { value: "ZZSH1", title: "Carpalben,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZSH11", title: "Carpalben,bencysta" },
  { value: "ZZSH12", title: "Carpalben,missbildning" },
  { value: "ZZSH13", title: "Carpalben,retained cartilage" },
  { value: "ZZSH2", title: "Carpalben,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZSH21", title: "Carpalben,osteomalaci/dystrofi" },
  { value: "ZZSH22", title: "Carpalben,hypertrofisk/osteodystrofi" },
  { value: "ZZSH3", title: "Carpalben,dystrofisk/degenerativ sjd" },
  { value: "ZZSH4", title: "Carpalben,inflammatorisk/immunologisk sjd" },
  { value: "ZZSH41", title: "Carpalben,panosteit" },
  { value: "ZZSH5", title: "Carpalben,infektiös sjd" },
  { value: "ZZSH52", title: "Carpalben,bakteriella infektioner" },
  { value: "ZZSH521", title: "Carpalben,osteomyelit" },
  { value: "ZZSH6", title: "Carpalben,mekanisk/traumatisk sjd/corp.al." },
  {
    value: "ZZSH61",
    title: "Carpalben,epifysglidning/epifysiolys/epifysskada",
  },
  { value: "ZZSH62", title: "Carpalben,fraktur" },
  { value: "ZZSH621", title: "Carpalben,fraktur,okomplicerad/enkel/sluten" },
  { value: "ZZSH622", title: "Carpalben,fraktur,flera fragment/öppen" },
  { value: "ZZSH63", title: "Carpalben,komplikationer till frakturläkning" },
  { value: "ZZSH64", title: "Carpalben,periostit" },
  {
    value: "ZZSH66",
    title: "Carpalben,korrektionskirurgi vid felaktig benaxel",
  },
  { value: "ZZSH7", title: "Carpalben,toxisk sjd" },
  { value: "ZZSH8", title: "Carpalben,neoplastisk sjd" },
  { value: "ZZSH9", title: "Carpalben,ospec/multifakt/idiopatisk sjd" },
  {
    value: "ZZSK0",
    title: "Metacarpal/falang/sesamoidben,normalvar/standardingr/utredn.",
  },
  { value: "ZZSK00", title: "Metacarpal/falang/sesamoidben,normalvariation" },
  {
    value: "ZZSK01",
    title: "Metacarpal/falang/sesamoidben,utredning ospec.tillstånd",
  },
  { value: "ZZSK02", title: "Amputation tå" },
  {
    value: "ZZSK03",
    title: "Metacarpal/falangben, spik/skruv/plattborttagning",
  },
  { value: "ZZSK04", title: "Sporrexstirpation framtass" },
  {
    value: "ZZSK1",
    title: "Metacarpal/falang/sesamoidben,kongenital/utv.mässig/konstit",
  },
  { value: "ZZSK11", title: "Metacarpal/falang/sesamoidben,bencysta" },
  { value: "ZZSK12", title: "Metacarpal/falang/sesamoidben,missbildning" },
  {
    value: "ZZSK13",
    title: "Metacarpal/falang/sesamoidben,retained cartilage",
  },
  {
    value: "ZZSK2",
    title: "Metacarpal/falang/sesamoidben,metabolisk/nutri/hormonell sjd",
  },
  {
    value: "ZZSK21",
    title: "Metacarpal/falang/sesamoidben,osteomalaci/dystrofi",
  },
  {
    value: "ZZSK22",
    title: "Metacarpal/falang/sesamoidben,hypertrofisk osteodystrofi",
  },
  {
    value: "ZZSK3",
    title: "Metacarpal/falang/sesamoidben,dystrofisk/degenerativ sjd",
  },
  {
    value: "ZZSK4",
    title: "Metacarpal/falang/sesamoidben,inflammatorisk/immunologisk sj",
  },
  { value: "ZZSK41", title: "Metacarpal/falang/sesamoidben,panosteit" },
  { value: "ZZSK5", title: "Metacarpal/falang/sesamoidben,infektiös sjd" },
  {
    value: "ZZSK52",
    title: "Metacarpal/falang/sesamoidben,bakteriella infektioner",
  },
  { value: "ZZSK521", title: "Metacarpal/falang/sesamoidben,osteomyelit" },
  {
    value: "ZZSK6",
    title: "Metacarpal/falang/sesamoidben,mekanisk/traumatisk sjd/corpal",
  },
  {
    value: "ZZSK61",
    title: "Metacarpal/falang/sesamoidben,epifysgl/epifysiolys/epifyskad",
  },
  { value: "ZZSK62", title: "Metacarpal/falang/sesamoidben,fraktur" },
  {
    value: "ZZSK621",
    title: "Metacarpal/falang/sesamoidben,frakt,okompl/enkel/sluten",
  },
  {
    value: "ZZSK622",
    title: "Metacarpal/falang/sesamoidben,fraktur,flera fragment/öppen",
  },
  {
    value: "ZZSK63",
    title: "Metacarpal/falang/sesamoidben,komplikat.till frakturläkning",
  },
  { value: "ZZSK64", title: "Metacarpal/falang/sesamoidben,periostit" },
  {
    value: "ZZSK65",
    title: "Metacarpal/falang/sesamoidben,prematur epifysslutning",
  },
  {
    value: "ZZSK66",
    title: "Metacarpal/falang/sesamoidben,korrektionskir v.felakt.benaxe",
  },
  { value: "ZZSK7", title: "Metacarpal/falang/sesamoidben,toxisk sjd" },
  { value: "ZZSK8", title: "Metacarpal/falang/sesamoidben,neoplastisk sjd" },
  {
    value: "ZZSK9",
    title: "Metacarpal/falang/sesamoidben,ospec/multifakt/idiopatisk sjd",
  },
  { value: "ZZSN0", title: "Bäckenets ben,normalvar/standarding/-utredn." },
  { value: "ZZSN00", title: "Bäckenets ben,normalvariation" },
  { value: "ZZSN01", title: "Bäckenets ben,utredning ospec.tillstånd" },
  { value: "ZZSN03", title: "Bäckenets ben, spik/skruv/plattborttagning" },
  {
    value: "ZZSN1",
    title: "Bäckenets ben, kongenital/utveckl.Mässig/konstit.sjd",
  },
  { value: "ZZSN11", title: "Bäckenets ben, bencysta" },
  { value: "ZZSN12", title: "Bäckenets ben missbildning" },
  {
    value: "ZZSN2",
    title: "Bäckenets ben, metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZSN21", title: "Bäckenets ben, osteomalaci/dystrofi" },
  { value: "ZZSN22", title: "Bäckenets ben,hypertrofisk osteodystrofi" },
  { value: "ZZSN24", title: "Foglossning / symfysglidning" },
  { value: "ZZSN3", title: "Bäckenets ben,dystrofisk/degenerativ sjd" },
  { value: "ZZSN4", title: "Bäckenets ben, inflammatorisk/immunologisk sjd" },
  { value: "ZZSN41", title: "Bäckenets ben, panosteit" },
  { value: "ZZSN5", title: "Bäckenets ben, infektiös sjd" },
  { value: "ZZSN52", title: "Bäckenets ben, bakteriella infektioner" },
  { value: "ZZSN521", title: "Bäckenets ben, osteomyelit" },
  { value: "ZZSN6", title: "Bäckenets ben, mekanisk/traumatisk sjd/corp al." },
  {
    value: "ZZSN61",
    title: "Bäckenets ben, epifysglidning/epifysiolys/epifysskada",
  },
  { value: "ZZSN62", title: "Bäckenets ben, fraktur" },
  {
    value: "ZZSN621",
    title: "Bäckenets ben, fraktur,okomplicerad/enkel/sluten",
  },
  { value: "ZZSN622", title: "Bäckenets ben, fraktur, flera fragment/öppen" },
  {
    value: "ZZSN63",
    title: "Bäckenets ben, komlikationer till frakturläkning",
  },
  { value: "ZZSN64", title: "Bäckenets ben, periostit" },
  { value: "ZZSN65", title: "Bäckenets ben, prematur epifysslutning" },
  {
    value: "ZZSN66",
    title: "Bäckenets ben,korrektionskirurgi vid felaktig benaxel",
  },
  { value: "ZZSN7", title: "Bäckenets ben, toxisk sjd" },
  { value: "ZZSN8", title: "Bäckenets ben, neoplastisk sjd" },
  { value: "ZZSN9", title: "Bäckenets ben, ospec./multifakt./idiopatisk sjd" },
  {
    value: "ZZSO0",
    title: "Femur/patella/fabellae, normalvar/standardingrepp/-utredn.",
  },
  { value: "ZZSO00", title: "Femur/patella/fabellae, normalvariation" },
  {
    value: "ZZSO01",
    title: "Femur/patella/fabellae, utredning ospec. tillstånd",
  },
  { value: "ZZSO03", title: "Femur/patella, spik/skruv/plattborttagning" },
  {
    value: "ZZSO1",
    title: "Femur/patella/fabellae,kongenital/utveckl.mässig/konstit sjd",
  },
  { value: "ZZSO11", title: "Femur/patella/fabellae, bencysta" },
  { value: "ZZSO12", title: "Femur/patella/fabellae, missbildning" },
  { value: "ZZSO13", title: "Femur/patella/fabellae,retained cartilage" },
  {
    value: "ZZSO2",
    title: "Femur/patella/fabellae,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZSO21", title: "Femur/patella/fabellae,  osteomalaci/dystrofi" },
  {
    value: "ZZSO22",
    title: "Femur/patella/fabellae, hypertrofisk osteodystrofi",
  },
  {
    value: "ZZSO3",
    title: "Femur/patella/fabellae,dystrofisk/degenerativ sjd",
  },
  { value: "ZZSO32", title: "Spondylos." },
  {
    value: "ZZSO4",
    title: "Femur/patella/fabellae, inflammatorisk/immunologisk sjd",
  },
  { value: "ZZSO41", title: "Femur/patella/fabellae,panosteit" },
  { value: "ZZSO5", title: "Femur/patella/fabellae,infektiös sjd" },
  { value: "ZZSO52", title: "Femur/patella/fabellae,bakteriella infektioner" },
  { value: "ZZSO521", title: "Femur/patella/fabellae,osteomyelit" },
  {
    value: "ZZSO6",
    title: "Femur/patella/fabellae,mekanisk/traumatisk sjd/corp.al.",
  },
  {
    value: "ZZSO61",
    title: "Femur/patella/fabellae,epifysglidning/epifysiolys/epifysskad",
  },
  { value: "ZZSO62", title: "Femur/patella/fabellae,fraktur" },
  {
    value: "ZZSO621",
    title: "Femur/patella/fabellae,fraktur,okomplicerad/enkel/sluten",
  },
  {
    value: "ZZSO622",
    title: "Femur/patella/fabellae,fraktur,flera fragment/öppen",
  },
  {
    value: "ZZSO63",
    title: "Femur/patella/fabellae,komlikationer till frakturläkning",
  },
  { value: "ZZSO64", title: "Femur/patella/fabellae,periostit" },
  { value: "ZZSO65", title: "Femur/patella/fabellae,prematur epifysslutning" },
  {
    value: "ZZSO66",
    title: "Femur/patella/fabellae,korrektionskirurgi vid felaktig benax",
  },
  { value: "ZZSO7", title: "Femur/patella/fabellae,toxisk sjd" },
  { value: "ZZSO8", title: "Femur/patella/fabellae,neoplastisk sjd" },
  {
    value: "ZZSO9",
    title: "Femur/patella/fabellae,ospec./multifakt./idiopatisk sjd",
  },
  { value: "ZZSP0", title: "Tibia/fibula,normalvar/standardingrepp/-utredn" },
  { value: "ZZSP00", title: "Tibia/fibula,normalvariation" },
  { value: "ZZSP01", title: "Tibia/fibula,utredning ospec.tillstånd" },
  { value: "ZZSP03", title: "Tibia/fibula, spik/skruv/plattborttagning" },
  {
    value: "ZZSP1",
    title: "Tibia/fibula,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZSP11", title: "Tibia/fibula,bencysta" },
  { value: "ZZSP12", title: "Tibia/fibula,missbildning" },
  { value: "ZZSP13", title: "Tibia/fibula,retained cartilage" },
  {
    value: "ZZSP2",
    title: "Tibia/fibula,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZSP21", title: "Tibia/fibula,osteomalaci/dystrofi" },
  { value: "ZZSP22", title: "Tibia/fibula,hypertrofisk osteodystrofi" },
  { value: "ZZSP3", title: "Tibia/fibula,dystrofisk/degenerativ sjd" },
  { value: "ZZSP4", title: "Tibia/fibula,inflammatorisk/immunologisk sjd" },
  { value: "ZZSP41", title: "Tibia/fibula,panosteit" },
  { value: "ZZSP5", title: "Tibia/fibula,infektiös sjd" },
  { value: "ZZSP52", title: "Tibia/fibula,bakteriella infektioner" },
  { value: "ZZSP521", title: "Tibia/fibula,osteomyelit" },
  { value: "ZZSP6", title: "Tibia/fibula,mekanisk/traumatisk sjd/corp.al." },
  {
    value: "ZZSP61",
    title: "Tibia/Fibula,epifysglidning/epifysiolys/epifysskada",
  },
  { value: "ZZSP62", title: "Tibia/fibula,fraktur" },
  { value: "ZZSP621", title: "Tibia/fibula,fraktur,okomplicerad/enkel/sluten" },
  { value: "ZZSP622", title: "Tibia/fibula,fraktur,flera fragment/öppen" },
  { value: "ZZSP63", title: "Tibia/fibula,komlikationer till frakturläkning" },
  { value: "ZZSP64", title: "Tibia/fibula,periostit" },
  { value: "ZZSP65", title: "Tibia/fibula,prematur epifysslutning" },
  {
    value: "ZZSP66",
    title: "Tibia/fibula,korrektionskirurgi vid felaktig benaxel",
  },
  { value: "ZZSP7", title: "Tibia/fibula,toxisk sjd" },
  { value: "ZZSP8", title: "Tibia/fibula,neoplastisk sjd" },
  { value: "ZZSP9", title: "Tibia/fabula,ospec./multifakt./idiopatisk sjd" },
  { value: "ZZSR0", title: "Tarsalben,normalvar./standardingrepp/-utredn" },
  { value: "ZZSR00", title: "Tarsalben,normalvariation" },
  { value: "ZZSR01", title: "Tarsalben,utredning ospec.tillstånd" },
  { value: "ZZSR03", title: "Tarsalben, spik/skruv/plattborttagning" },
  { value: "ZZSR1", title: "Tarsalben,kongenital/utveckl.mässig/konstit.sjd" },
  { value: "ZZSR11", title: "Tarsalben,bencysta" },
  { value: "ZZSR12", title: "Tarsalben,missbildning" },
  { value: "ZZSR13", title: "Tarsalben,retained cartilage" },
  { value: "ZZSR2", title: "Tarsalben,metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZSR21", title: "Tarsalben,osteomalaci/dystrofi" },
  { value: "ZZSR22", title: "Tarsalben,hypertrofisk osteodystrofi" },
  { value: "ZZSR3", title: "Tarsalben,dystrofisk/degenerativ sjd" },
  { value: "ZZSR4", title: "Tarsalben,inflammatorisk/immunologisk sjd" },
  { value: "ZZSR41", title: "Tarsalben,panosteit" },
  { value: "ZZSR5", title: "Tarsalben,infektiös sjd" },
  { value: "ZZSR52", title: "Tarsalben,bakteriella infektioner" },
  { value: "ZZSR521", title: "Tarsalben,osteomyelit" },
  { value: "ZZSR6", title: "Tarsalben,mekanisk/traumatisk sjd/corp.al" },
  {
    value: "ZZSR61",
    title: "Tarsalben,epifysglidning/epifysiolys/epifysskada",
  },
  { value: "ZZSR62", title: "Tarsalben, fraktur" },
  { value: "ZZSR621", title: "Tarsalben, fraktur, okompl/enkel/sluten" },
  { value: "ZZSR622", title: "Tarsalben,fraktur,flera fragment/öppen" },
  { value: "ZZSR63", title: "Tarsalben,komplikationer till frakturläkning" },
  { value: "ZZSR64", title: "Tarsalben,periostit" },
  { value: "ZZSR65", title: "Tarsalben,prematur epifysslutning" },
  {
    value: "ZZSR66",
    title: "Tarsalben,korrektionskirurgi vid felaktig benaxel",
  },
  { value: "ZZSR7", title: "Tarsalben,toxisk sjd" },
  { value: "ZZSr8", title: "Tarsalben,neoplastisk sjd" },
  { value: "ZZSR9", title: "Tarsalben,ospec./multifakt./idiopatisk sjd" },
  {
    value: "ZZSS0",
    title: "Metatarsal/fal/ses.ben, normalvar./standardingr./-utredn.",
  },
  { value: "ZZSS00", title: "Metatarsal/falang/sesamoidben,normalvariation" },
  {
    value: "ZZSS01",
    title: "Metatarsal/falang/sesamoidben,utredn. ospec.tillstånd",
  },
  { value: "ZZSS02", title: "Amputation tå." },
  {
    value: "ZZSS03",
    title: "Metatarsal/falangben, spik/skruv/plattborttagning",
  },
  { value: "ZZSS04", title: "Sporrexstirpation baktass" },
  {
    value: "ZZSS1",
    title: "Metatarsal/falang/sesamoidben,kongen./utv.mässig/konstit.sjd",
  },
  { value: "ZZSS11", title: "Metatarsal/falang/sesamoidben,bencysta" },
  { value: "ZZSS12", title: "Metatarsal/falang/sesamoidben,missbildning" },
  {
    value: "ZZSS13",
    title: "Metatarsal/falang/sesamoidben,retained cartilage",
  },
  {
    value: "ZZSS2",
    title: "Metatarsal/falang/sesamoidben,metabol/nutritionell/hormo.sjd",
  },
  {
    value: "ZZSS21",
    title: "Metatarsal/falang/sesamoidben,osteomalaci/dystrofi",
  },
  {
    value: "ZZSS22",
    title: "Metatarsal/falang/sesamoidben,hypertrofisk/osteodystrofi",
  },
  {
    value: "ZZSS3",
    title: "Metatarsal/falang/sesamoidben,dystrofisk/degenerativ sjd",
  },
  {
    value: "ZZSS4",
    title: "Metatarsal/falang/sesamoidben,inflammatorisk/immunologisksjd",
  },
  { value: "ZZSS41", title: "Metatarsal/falang/sesamoidben,panosteit" },
  { value: "ZZSS5", title: "Metatarsal/falang/sesamoidben,infektiös sjd" },
  {
    value: "ZZSS52",
    title: "Mtetarsal/falang/sesamoidben,bakteriella infektioner",
  },
  { value: "ZZSS521", title: "Metatarsal/falang/sesamoidben,osteomyelit" },
  {
    value: "ZZSS6",
    title: "Metatarsal/falang/sesamoidben,mek./traum.sjd./corp al",
  },
  {
    value: "ZZSS61",
    title: "metatarsal/falang/sesamoidben,epifysglidn/ep.-lys/ep.-skada",
  },
  { value: "ZZSS62", title: "Metatarsal/falang/sesamoidben,fraktur" },
  {
    value: "ZZSS621",
    title: "Metatarsal/falang/sesamoidben,fraktur,okompl/enkel/sluten",
  },
  {
    value: "ZZSS622",
    title: "Metatarsal/falang/sesamoidben,fraktur,flera fragment/öppen",
  },
  {
    value: "ZZSS63",
    title: "Metatarsal/falang/sesamoidben,kompl. till frakturläkning",
  },
  { value: "ZZSS64", title: "Metatarsal/falang/sesamoidben,periostit" },
  {
    value: "ZZSs65",
    title: "Metatarsal/falang/sesamoidben,prematur epifysslutning",
  },
  {
    value: "ZZSS66",
    title: "Metatarsal/falang/sesamoidben,korrektkir.vid felakt.benaxel",
  },
  { value: "ZZSS7", title: "Metatarsal/falang/sesamoidben,toxisk sjd." },
  { value: "ZZSS8", title: "Metatarsal/falang/sesamoidben,neoplastisk sjd." },
  {
    value: "ZZSs9",
    title: "Metatarsal/falang/sesamoidben,ospec/multifakt/idiopat.sjd.",
  },
  {
    value: "ZZUA0",
    title: "Övre urinvägar,normalvar./stand.ingrepp/-utredning",
  },
  { value: "ZZUA00", title: "Övre urinvägar,normalvariation" },
  { value: "ZZUA01", title: "Övre urinvägar,utredning ospec.tillstånd" },
  { value: "ZZUA03", title: "Utredning polyuri/polydipsi,ospec,orsak" },
  { value: "ZZUA04", title: "Ultraljud njurar" },
  {
    value: "ZZUA1",
    title: "Övre urinvägar,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZUA11", title: "Cystanjure,cystiska förändringar i njure" },
  { value: "ZZUA17", title: "Ektopisk(a) uretär(er)" },
  {
    value: "ZZUA2",
    title: "Övre urinvägar,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZUA21", title: "Urinsten övre urinvägar" },
  { value: "ZZUA211", title: "Urinsten övre urinvägar,trippelfosfat" },
  { value: "ZZUA212", title: "Urinsten övre urinvägar,oxalat" },
  { value: "ZZUA213", title: "Urinsten övre urinvägar,urat" },
  { value: "ZZUA214", title: "Urinsten övre urinvägar,cystein" },
  { value: "ZZUA215", title: "Urinsten övre urinvägar,blandad typ" },
  { value: "ZZUA216", title: "Urinsten övre urinvägar,övrig typ" },
  { value: "ZZUA23", title: "Uremi" },
  { value: "ZZUA3", title: "Övre urinvägar,dystrofisk/degenerativ sjd" },
  { value: "ZZUA4", title: "Övre urinvägar,inflammatorisk/immunologisk sjd" },
  { value: "ZZUA41", title: "Glomerulonefrit" },
  { value: "ZZUA43", title: "Kronisk interstitiell nefrit" },
  { value: "ZZUA45", title: "Amyloidos" },
  { value: "ZZUA5", title: "Övre urinvägar,infektiös sjd" },
  { value: "ZZUA51", title: "Övre urinvägsinfektion" },
  { value: "ZZUA6", title: "Övre urinvägar,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZUA7", title: "Övre urinvägar,toxisk sjd" },
  { value: "ZZUA79", title: "Nefros" },
  { value: "ZZUA8", title: "Övre urinvägar,neoplastisk sjd" },
  { value: "ZZUA9", title: "Övre urinvägar,ospec./multifakt./idiopatisk sjd" },
  { value: "ZZUA91", title: "Psykogen polydipsi" },
  {
    value: "ZZUB0",
    title: "Nedre urinvägar,normalvar./standardingrepp/-utredn.",
  },
  { value: "ZZUB00", title: "Nedre urinvägar,normalvariation" },
  { value: "ZZUB01", title: "Nedre urinvägar,utredning ospec.tillstånd" },
  { value: "ZZUB05", title: "Utredning inkontinens,ospecifik orsak" },
  {
    value: "ZZUB1",
    title: "Nedre urinvägar,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZUB11", title: 'Inkontinens p.g.a."pöl i vagina"' },
  { value: "ZZUB12", title: "Pelvic bladder" },
  {
    value: "ZZUB2",
    title: "Nedre urinvägar,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZUB21", title: "Urinsten nedre urinvägar" },
  { value: "ZZUB211", title: "Urinsten nedre urinvägar, trippelfosfat" },
  { value: "ZZUB212", title: "Urinsten nedre urinvägar,oxalat" },
  { value: "ZZUB213", title: "Urinsten nedre urinvägar,urat" },
  { value: "ZZUB214", title: "Urinsten nedre urinvägar,cystein" },
  { value: "ZZUB215", title: "Urinsten nedre urinvägar,blandad typ" },
  { value: "ZZUB216", title: "Urinsten nedre urinvägar,övrig typ" },
  { value: "ZZUB22", title: "Kristalluri utan urinstenar" },
  { value: "ZZUB23", title: "Inkontinens post ovariehysterektomi" },
  { value: "ZZUB3", title: "Nedre urinvägar,dystrofisk/degenerativ sjd" },
  { value: "ZZUB4", title: "Nedre urinvägar,inflammatorisk/immunologisk sjd" },
  { value: "ZZUB5", title: "Nedre urinvägar,infektiös sjd" },
  { value: "ZZUB51", title: "Nedre urinvägsinfektion" },
  { value: "ZZUB6", title: "Nedre urinvägar,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZUB7", title: "Nedre urinvägar,toxisk sjd" },
  { value: "ZZUB8", title: "Nedre urinvägar,neoplastisk sjd" },
  { value: "ZZUB9", title: "Nedre urinvägar,ospec./multifakt./idiopatisk sjd" },
  { value: "ZZUB91", title: "Inkontinens/tömningssvårigheter" },
  { value: "ZZUB911", title: "Blåsatoni" },
  { value: "ZZUB912", title: "Reflexdyssynergi" },
  { value: "ZZUB92", title: "Hematuri, ospecificerad" },
  { value: "ZZZA0", title: "Öra,normalvar./standardingrepp/-utredn" },
  { value: "ZZZA00", title: "Öra,normalvariation" },
  { value: "ZZZA01", title: "Öra,utredning ospec.tillstånd" },
  { value: "ZZZA02", title: "Öronrengöring utan otit" },
  { value: "ZZZA11", title: "Medfödd dövhet" },
  { value: "ZZZA12", title: "BAER hörseltest" },
  { value: "ZZZA2", title: "Öra, metabolisk/nutritionell/hormonell sjd" },
  { value: "ZZZA3", title: "Öra,dystofisk/degenerativ sjd" },
  { value: "ZZZA4", title: "Öra, inflammatorisk/immunologisk sjd" },
  { value: "ZZZA5", title: "Öra,infektiös sjd" },
  { value: "ZZZA54", title: "Parasitär otit" },
  { value: "ZZZA541", title: "Parasitär otit,Otodectes" },
  { value: "ZZZA59", title: "Bakteriell/svampinfektion öra" },
  { value: "ZZZA591", title: "Dermatit öronlappshud" },
  { value: "ZZZA592", title: "Otitis externa" },
  { value: "ZZZA593", title: "Otitis media,sekundär" },
  { value: "ZZZA594", title: "Otitis media, primär" },
  { value: "ZZZA595", title: "Otitis interna" },
  { value: "ZZZA6", title: "Öra,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZZA61", title: "Blodöra" },
  { value: "ZZZA62", title: "Corp.al.hörselgång" },
  { value: "ZZZA63", title: "Traumatisk trumhinneruptur" },
  { value: "ZZZA7", title: "Öra,toxisk sjd" },
  { value: "ZZZA8", title: "Öra,neoplastisk sjd" },
  { value: "ZZZA81", title: "Tumör i hörselgång" },
  { value: "ZZZA82", title: "Tumör i mellanöra" },
  { value: "ZZZA9", title: "Öra,ospec./multifakt./idioplatisk sjd" },
  { value: "ZZZA91", title: "Dövhet, förvärvad, perifer" },
  { value: "ZZZA92", title: "Dövhet, förvärvad,central" },
  { value: "ZZZA93", title: "Tinnitus, objektiv" },
  {
    value: "ZZÖA0",
    title: "Adnexa/ögonlock/tredje ögonlock,normalvar./standardingr/-utr",
  },
  { value: "ZZÖA00", title: "Adnexa/ögonlock/tredje ögonlock,normalvariation" },
  {
    value: "ZZÖA01",
    title: "Adnexa/ögonlock/tredje ögonlock,utredning ospec.tillstånd",
  },
  {
    value: "ZZÖA1",
    title: "Adnexa/ögonlock/tredje ögonlock,kongen/utv.mässig/konst sjd",
  },
  { value: "ZZÖA11", title: "Atresi av ögonlock" },
  { value: "ZZÖA12", title: "Dermoid ögonlock" },
  { value: "ZZÖA13", title: "Distichiasis/trichiasis/ektopiska cilia" },
  { value: "ZZÖA15", title: "Entropion/ektropion" },
  {
    value: "ZZÖA17",
    title: 'Prolaberad körtel tredje ögonlocket,"cherry eye"',
  },
  {
    value: "ZZÖA18",
    title: "Broskmissbildning tredje ögonlock,everterat brosk",
  },
  { value: "ZZÖA19", title: "Cysta i tredje ögonlock" },
  {
    value: "ZZÖA2",
    title: "Anexa/ögonlock/tredjeögonlock metabol/nutrition/hormone/sjd",
  },
  {
    value: "ZZÖA3",
    title: "Adnexa/ögonlock/tredje ögonlock,dystrofisk/degenerativ sjd",
  },
  {
    value: "ZZÖA4",
    title: "Adnexa/ögonlock/tredje ögolock,inflammatorisk/immunolog/sjd",
  },
  {
    value: "ZZÖA41",
    title: "Blepharit o periokulär dermatit, icke infektiös typ",
  },
  { value: "ZZÖA43", title: "Chalazion/hordeolum/adenit/granulom ögonlock" },
  {
    value: "ZZÖA44",
    title: "Chalazion/hordeolum/adenit i ögonlockskörtlar/granulom",
  },
  { value: "ZZÖA47", title: "Plasmom tredje ögonlock" },
  { value: "ZZÖA5", title: "Adnexa/ögonlock/tredje ögonlock,infektiös sjd" },
  { value: "ZZÖA51", title: "Blepharit o periokulär dermatit,infektiös typ" },
  {
    value: "ZZÖA6",
    title: "Adnexa/ögonlock/tredje ögonlock,mekan/traumati sjd/corp.al.",
  },
  { value: "ZZÖA7", title: "Adnexa/ögonlock/tredje ögonlock,toxisk sjd" },
  { value: "ZZÖA8", title: "Adnexa/ögonlock/tredje ögonlock,neoplastisk sjd" },
  {
    value: "ZZÖA9",
    title: "Adnexa/ögonlock/tredje ögonlock,ospec./multifakt./idiopa sjd",
  },
  { value: "ZZÖB00", title: "Konjunktiva/episclera/tårorgan,normalvariation" },
  {
    value: "ZZÖB01",
    title: "Konjunktiva/episclera/tårorgan,utredning ospec.tillstånd",
  },
  {
    value: "ZZÖB1",
    title: "Konjuktiva/episclera/tårorgan,kongenit/utveckl.mäss/kons sjd",
  },
  { value: "ZZÖB11", title: "Dermoid conjunktiva" },
  { value: "ZZÖB12", title: "Cysta i konjunktiva" },
  { value: "ZZÖB17", title: "Atresi/obstruktion av tårorgan" },
  {
    value: "ZZÖB2",
    title: "Konjunktiva/episclera/tårorgan,metabol/nutrition/hormon sjd",
  },
  {
    value: "ZZÖB3",
    title: "Konjunktiva/episclera/tårorgan,dystrofisk/degenerativ sjd",
  },
  {
    value: "ZZÖB4",
    title: "Konjunktiva/episclera/tårorgan,inflammatorisk/immunolog sjd",
  },
  { value: "ZZÖB41", title: "Konjunktivit av ej infektiös typ" },
  { value: "ZZÖB45", title: "Episklerit" },
  { value: "ZZÖB46", title: "Nodulär fasciit" },
  { value: "ZZÖB47", title: "Keratoconjunktivitis sicca, KCS" },
  { value: "ZZÖB5", title: "Konjunktiva/episclera/tårorgan,infektiös sjd" },
  { value: "ZZÖB51", title: "Konjunktivit av infektiös typ" },
  { value: "ZZÖB53", title: "Konjunktivit,virusbetingad" },
  { value: "ZZÖB54", title: "Konjunktivit,bakteriell" },
  { value: "ZZÖB55", title: "Konjuktivit,chlamydia" },
  { value: "ZZÖB57", title: "Dacrocystit" },
  {
    value: "ZZÖB6",
    title: "Konjunktiva/episclera/tårorgan,mekanisk/traumat sjd/corp.al",
  },
  { value: "ZZÖB7", title: "Konjunktiva/episclera/tårorgan,toxisk sjd" },
  { value: "ZZÖB8", title: "Konjunktiva/episclera/tårorgan,neoplastisk" },
  {
    value: "ZZÖB9",
    title: "Konjunktiva/episclera/tårorgan,ospec./multifakt./idiopat sjd",
  },
  { value: "ZZÖB97", title: "Epiphora,ej spec.etiologi" },
  {
    value: "ZZÖBÖ0",
    title: "Konjunktiva/episclera/tårorgan,normalvar/standardin/-utredn.",
  },
  {
    value: "ZZÖC0",
    title: "Cornea/sclera,normalvar./standardingrepp/-utredn.",
  },
  { value: "ZZÖC00", title: "Cornea/sclera,normalvariation" },
  { value: "ZZÖC01", title: "Cornea/sclera,utredning ospec.tillstånd" },
  {
    value: "ZZÖC1",
    title: "Cornea/sclera,kongenital/utveckl.mässig/konstit.sjd",
  },
  { value: "ZZÖC11", title: "Dermoid i cornea" },
  {
    value: "ZZÖC2",
    title: "Cornea/sclera,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZÖC3", title: "Cornea/sclera,dystrofisk/degenerativ sjd" },
  { value: "ZZÖC31", title: "Corneadystrofi" },
  { value: "ZZÖC311", title: "Corneadystrofi,stromal/epitelial" },
  { value: "ZZÖC312", title: "Corneadystrofi,endothelial" },
  { value: "ZZÖC313", title: "Cornea nigrum" },
  { value: "ZZÖC33", title: "Corneadegeneration" },
  { value: "ZZÖC4", title: "Cornea/sclera,inflammatorisk/immunologisk sjd" },
  { value: "ZZÖC41", title: "Keratit,ospec.ej ulcerativ" },
  { value: "ZZÖC42", title: 'Kronisk ytlig keratit,"pannus"' },
  { value: "ZZÖC43", title: "Pigmentös keratit" },
  {
    value: "ZZÖC44",
    title: "Eosinofil proliferativ keratit/konjunktivit/keratokonjunkti",
  },
  { value: "ZZÖC45", title: "Nodulär granulomatös episklerokeratit" },
  { value: "ZZÖC48", title: "Sklerit" },
  { value: "ZZÖC5", title: "Cornea/sclera,infektiös sjd" },
  { value: "ZZÖC51", title: "Ulcus corneae,ytligt epitelialt" },
  {
    value: "ZZÖC511",
    title: "Ulcus corneae,ytligt epitelialt, epitelial erosion",
  },
  {
    value: "ZZÖC512",
    title: "Ulcus corneae,ytligt epitelialt p.g.a.kvarstå bidraga faktor",
  },
  {
    value: "ZZÖC513",
    title: 'Ulcus corneae,ytligt epitelialt,kroniskt("boxerulcus")',
  },
  { value: "ZZÖC514", title: "Ytlig punktformig keratit" },
  { value: "ZZÖC53", title: "Ulcus corneae,stromalt" },
  {
    value: "ZZÖC531",
    title: "Ulcus corneae,stromalt,ej perf.el descemetocoele",
  },
  { value: "ZZÖC532", title: "Ulcus corneae,stromalt,smältande" },
  { value: "ZZÖC533", title: "Ulcus corneae,stromalt descemetocoele" },
  { value: "ZZÖC534", title: "Ulcus corneae,stromalt,perforerande" },
  { value: "ZZÖC55", title: "Infektiös keratit" },
  { value: "ZZÖC6", title: "Cornea/sclera,mekanisk/traumatisk sjd/corp.al" },
  { value: "ZZÖC61", title: "Vulnus corneae" },
  { value: "ZZÖC611", title: "Vulnus corneae,ej perforerande" },
  { value: "ZZÖC612", title: "Vulnus corneae,perforerande" },
  { value: "ZZÖC63", title: "Corpus alienum corneae" },
  { value: "ZZÖC631", title: "Corpus alienum corneae,ej perforerande" },
  { value: "ZZÖC632", title: "Corpus alienum corneae,perforerande" },
  { value: "ZZÖC7", title: "Cornea/sclera,toxisk sjd" },
  { value: "ZZÖC8", title: "Cornea/sclera,neoplastisk sjd" },
  { value: "ZZÖC9", title: "Cornea/sclera osp./multifakt./idiopatisk sjd" },
  {
    value: "ZZÖD0",
    title: "Främre ögonkammare/uvea,normalvar./standardingrepp/-utredn.",
  },
  { value: "ZZÖD00", title: "Främre ögonkammare/uvea,normalvariation" },
  {
    value: "ZZÖD01",
    title: "Främre ögonkammare/uvea,utredning ospec.tillstånd",
  },
  {
    value: "ZZÖD1",
    title: "Främre ögonkammare/uvea,kongenital/utveckl.mässig/konst sjd",
  },
  { value: "ZZÖD11", title: "Irishypoplasi/anirid" },
  { value: "ZZÖD12", title: "Iriscolobom" },
  { value: "ZZÖD13", title: "Persisterande pupillmembran" },
  { value: "ZZÖD14", title: "Iriscysta av utvecklingsmässig typ" },
  { value: "ZZÖD17", title: "Goniodysgenes" },
  {
    value: "ZZÖD2",
    title: "Främre ögonkammare/uvea,metabolisk/nutritionell/hormonel sjd",
  },
  { value: "ZZÖD21", title: "Lipidblandad aqueous" },
  {
    value: "ZZÖD3",
    title: "Främre ögonkammare/uvea,dystrofisk/degenerativ sjd",
  },
  { value: "ZZÖD31", title: "Irisatrofi" },
  {
    value: "ZZÖD4",
    title: "Främre ögonkammare/uvea,inflammatorisk/immunologisk sjd",
  },
  { value: "ZZÖD41", title: "Uveit" },
  { value: "ZZÖD411", title: "Främre uveit" },
  { value: "ZZÖD413", title: "Bakre uveit/choroidit" },
  { value: "ZZÖD415", title: "Uveodermala syndromet" },
  {
    value: "ZZÖD43",
    title: "Främre ögonkammare/uvea,postinflammatoriska förändringar",
  },
  { value: "ZZÖD431", title: "Synechier" },
  { value: "ZZÖD433", title: "Iriscysta,inflammatoriskt betingad" },
  { value: "ZZÖD5", title: "Främre ögonkammare/uvea,infektiös sjd" },
  { value: "ZZÖD51", title: "Infektiös uveit" },
  {
    value: "ZZÖD6",
    title: "Främre ögonkammare/uvea,mekanisk/traumatisk sjd/corp.al.",
  },
  { value: "ZZÖD61", title: "Traumatisk irisskada" },
  { value: "ZZÖD62", title: "Hyphema" },
  { value: "ZZÖD7", title: "Främre ögonkammare/uvea,toxisk sjd" },
  { value: "ZZÖD8", title: "Främre ögonkammare/uvea,neoplastisk sjd" },
  { value: "ZZÖD81", title: "Tumör i främre uvea" },
  { value: "ZZÖD82", title: "Tumör i bakre uvea" },
  { value: "ZZÖD83", title: "Tumör i hela uvea" },
  {
    value: "ZZÖD9",
    title: "Främre ögonkammare/uvea,ospec./multifakt./idiopatisk sjd",
  },
  {
    value: "ZZÖE0",
    title: "Lins/glaskropp,normalvar./standardingrepp/-utredn",
  },
  { value: "ZZÖE00", title: "Lins/glaskropp,normalvariation" },
  { value: "ZZÖE01", title: "Lins/glaskropp,utredning ospec.tillstånd" },
  {
    value: "ZZÖE1",
    title: "Lins/glaskropp,kongenital/utveckl.mässig/konstit. sjd",
  },
  { value: "ZZÖE11", title: "Katarakt" },
  { value: "ZZÖE12", title: "Mikrophaki" },
  { value: "ZZÖE13", title: "Linscolobom" },
  { value: "ZZÖE14", title: "Lenticonus/lentiglobus" },
  { value: "ZZÖE17", title: "PHPV/PHTLV/PHA" },
  {
    value: "ZZÖE2",
    title: "Lins/glaskropp,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZÖE3", title: "Lins/glaskropp, dystrofisk/degenerativ sjd" },
  { value: "ZZÖE31", title: "Linsluxatioin" },
  { value: "ZZÖE37", title: "Asteroid hyalos" },
  { value: "ZZÖE38", title: "Synchysis scintillans" },
  { value: "ZZÖE4", title: "Lins/glaskropp,inflammatorisk/immunologisk sjd" },
  { value: "ZZÖE5", title: "Lins/glaskropp,infektiös sjd" },
  { value: "ZZÖE6", title: "Lins/glaskropp,mekanisk/traumatisk sjd/corp.al." },
  { value: "ZZÖE61", title: "Blödning i vitreus" },
  { value: "ZZÖE7", title: "Lins/glaskropp,toxisk sjd" },
  { value: "ZZÖE8", title: "Lins/glaskropp,neoplastisk sjd" },
  { value: "ZZÖE9", title: "Lins/glaskropp,ospec./multifakt./idiopatisk sjd" },
  {
    value: "ZZÖF0",
    title: "Retina/synnerv,normalvar/stand.ingrepp/-utredning",
  },
  { value: "ZZÖF00", title: "Retina/synnerv, normalvariation" },
  { value: "ZZÖF01", title: "Retina/synnerv, utredn. ospec. tillstånd" },
  {
    value: "ZZÖF1",
    title: "Retina/synnerv, kongenital/utveckl.mässig/konstit. sjd",
  },
  { value: "ZZÖF11", title: "Retinal dysplasi" },
  { value: "ZZÖF12", title: "Collie Eye Anomaly, CEA" },
  { value: "ZZÖF17", title: "Nervus opticus-hypolplasi/aplasi" },
  {
    value: "ZZÖF2",
    title: "Retina/synnerv,metabolisk/nutritionell/hormonell sjd",
  },
  { value: "ZZÖF21", title: "Lipemia retinalis" },
  { value: "ZZÖF3", title: "Retina/synnerv,dystrofisk/degenerativ sjd" },
  { value: "ZZÖF31", title: "Progressiv retinal atrofi,PRA" },
  { value: "ZZÖF32", title: "Hemeralopi" },
  { value: "ZZÖF33", title: "Retinal degeneration, ospec." },
  { value: "ZZÖF4", title: "Retina/synnerv, inflammatorisk/immunologisk sjd" },
  { value: "ZZÖF41", title: "Inflammatorisk retinit/chorioretinit" },
  { value: "ZZÖF47", title: "Opticusneurit" },
  { value: "ZZÖF5", title: "Retina/synnerv, infektiös sjd" },
  { value: "ZZÖF6", title: "Retina/synnerv,mekanisk/traumatisk sjd/corp al" },
  { value: "ZZÖF61", title: "Traumatisk retinit/chorioretinit" },
  { value: "ZZÖF62", title: "Ablatio Retinae" },
  { value: "ZZÖF8", title: "Retina/synnerv, neoplastisk sjd" },
  { value: "ZZÖF9", title: "Retina/synnerv, ospec/multifakt/idiopatisk sjd" },
  {
    value: "ZZÖG0",
    title: "Ögonlob/orbita/neurooftalm.tillst.normalvar./standingr/utred",
  },
  {
    value: "ZZÖG00",
    title: "Ögomlob/orbita/neurooftalm. tills.normalvariation",
  },
  {
    value: "ZZÖG01",
    title: "Ögonlob/orbita/neurooftalm. tillst utredn. ospec.tillstånd",
  },
  { value: "ZZÖG02", title: "Ögonlysning" },
  {
    value: "ZZÖG1",
    title: "Ögonlob/orbita/neurooftalm.tillst.kongen./utveck.mäss/konsti",
  },
  { value: "ZZÖG11", title: "Mikrophtalmi/multipla anomalier i ögonloben" },
  { value: "ZZÖG15", title: "Cysta i orbita" },
  {
    value: "ZZÖG2",
    title: "Ögonlob/orbita/neurooftalm.tillst.metabol./nutri/hormon sjd",
  },
  {
    value: "ZZÖG3",
    title: "Ögonlob/orbita/neurooftalm.tillst.dystrofisk/degenerativ sjd",
  },
  { value: "ZZÖG31", title: "Phthisis bulbi" },
  {
    value: "ZZÖG4",
    title: "Ögonlob/orbita/neurooftalm.tillst.inflamm./immunolog.sjd",
  },
  { value: "ZZÖG41", title: "Endoftalmit, panoftalmit" },
  { value: "ZZÖG5", title: "Ögonlob/orbita/neurooftalm.tillst.infektiös sjd" },
  { value: "ZZÖG55", title: "Orbital cellulit/abscess" },
  { value: "ZZÖG59", title: "Spastiska pupillsyndromet (FeLV)" },
  {
    value: "ZZÖG6",
    title: "Ögonlob/orbita/neurooftalm.tillst.mek./traumatisksjd/corp al",
  },
  { value: "ZZÖG61", title: "Glaukom" },
  { value: "ZZÖG63", title: "Buftalmi/hydroftalmi" },
  { value: "ZZÖG65", title: "Corpus alienum i orbitan" },
  { value: "ZZÖG66", title: "Bulbprolaps" },
  { value: "ZZÖG67", title: "Cysta i orbita, mucocoele" },
  { value: "ZZÖG7", title: "Ögonlob/orbita/neurooftalm. tillst. toxisk sjd" },
  {
    value: "ZZÖG8",
    title: "Ögonlob/orbita/neurooftalm. tillst. neoplastisk sjd",
  },
  { value: "ZZÖG81", title: "Tumör i ögonlob" },
  { value: "ZZÖG85", title: "Tumör i orbitan" },
  {
    value: "ZZÖG9",
    title: "Ögonlob/orbita/neurooftalm.tillst.ospec/multif./idiopat.sjd",
  },
  { value: "ZZÖG95", title: "Anisocori, ospec." },
  { value: "ZZÖG97", title: "Horners syndrom" },
  { value: "ZZÖG98", title: "Oftalmoplegi" },
  { value: "Ö", title: "Ögats sjukdomar" },
  { value: "ÖA", title: "Ögonlock och tredje ögonlock" },
  {
    value: "ÖA0",
    title: "Normalvar, symtom u fastställd orsak, ögonlock/3:e ögonlock",
  },
  { value: "ÖA00", title: "Normalvariation, ögonlock/3:e ögonlock" },
  {
    value: "ÖA01",
    title: "Symtom på sjukdom u fastställd orsak ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA1",
    title: "Missbildn, utveckl.rubbn, tillväxtrubbn, ögonlock/3:e ögonl",
  },
  { value: "ÖA10", title: "Missbildningar ögonlock/3:e ögonlock" },
  { value: "ÖA101", title: "Dermoid ögonlock/3:e ögonlock" },
  { value: "ÖA102", title: "Agenesi av ögonlock, colobom" },
  { value: "ÖA105", title: "Cysta i tredje ögonlocket" },
  {
    value: "ÖA11",
    title: "Utvecklingsrubbn, tillväxtrubbn ögonlock/3:e ögonlock",
  },
  { value: "ÖA111", title: "Distichiasis, trichiasis, ektopiska cilia" },
  { value: "ÖA113", title: "Euryblepharon, för stor ögonlocksöppning" },
  {
    value: "ÖA114",
    title: "Mikropalpebral fissur, för liten ögonlocksöppning",
  },
  { value: "ÖA119", title: "Övriga ögonlocksmissbildningar" },
  {
    value: "ÖA2",
    title: "Metabol, nutrit, degen/dystr förändr ögonlock/3:e ögonlock",
  },
  { value: "ÖA20", title: "Metaboliska förändringar ögonlock/3:e ögonlock" },
  { value: "ÖA201", title: "Amyloidos." },
  { value: "ÖA21", title: "Nutritionella förändringar ögonlock/3:e ögonlock" },
  {
    value: "ÖA22",
    title: "Degenerativa/dystrofiska förändringar ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA225",
    title: "Atrofi av tredje ögonlock, senil förtunning av 3:e ögonlock",
  },
  { value: "ÖA23", title: "Hormonella förändringar ögonlock/3:e ögonlock" },
  { value: "ÖA3", title: "Cirkulatoriska förändringar ögonlock/3:e ögonlock" },
  { value: "ÖA30", title: "Blödningar ögonlock/3:e ögonlock" },
  { value: "ÖA31", title: "Ödem ögonlock/3:e ögonlock" },
  { value: "ÖA32", title: "Infarkter ögonlock/3:e ögonlock" },
  { value: "ÖA33", title: "Hygrom ögonlock/3:e ögonlock" },
  { value: "ÖA34", title: "Trombos / emboli ögonlock/3:e ögonlock" },
  {
    value: "ÖA35",
    title: "Arterio-atero-skleros förändringar ögonlock/3:e ögonlock",
  },
  { value: "ÖA4", title: "Infekt, infl förändringar ögonlock/3:e ögonlock" },
  {
    value: "ÖA40",
    title: "Specifika infektionssjukdomar ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA41",
    title: "Akuta inflammationstillstånd ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA411",
    title: "Akut serös / serofibrinös inflammation ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA4111",
    title: "Akut blefarit av icke purulent karaktär ögonlock/3:e ögonl",
  },
  { value: "ÖA412", title: "Akut purulent inflammation ögonlock/3:e ögonlock" },
  {
    value: "ÖA4121",
    title: "Akut blefarit av purulent karaktär ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA4122",
    title: "Akut flegmon/cellulit i ögonlock ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA413",
    title: "Specifika akuta infl tillstånd ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA419",
    title: "Övriga akuta infl tillstånd ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA42",
    title: "Kroniska inflammationstillstånd ögonlock/3:e ögonlock",
  },
  { value: "ÖA421", title: "Kronisk serös inflammation ögonlock/3:e ögonlock" },
  { value: "ÖA4211", title: "Kronisk blefarit av icke purulent karaktär" },
  { value: "ÖA422", title: "Purulent inflammation ögonlock/3:e ögonlock" },
  {
    value: "ÖA4221",
    title: "Kronisk blefarit av purulent karaktär ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA4222",
    title: "Kronisk flegmon/cellulit i ögonlock ögonlock/3:e ögonlock",
  },
  { value: "ÖA423", title: "Abscess/fistel ögonlock/3:e ögonlock" },
  { value: "ÖA4231", title: "Abscess/fistel i ögonlock" },
  {
    value: "ÖA424",
    title: "Specifika kroniska infl tillstånd ögonlock/3:e ögonlock",
  },
  { value: "ÖA4241", title: "Eosinofil plaque  ögonlock/3:e ögonlock" },
  { value: "ÖA4242", title: "Plasmom i övre/nedre ögonlock." },
  { value: "ÖA4243", title: "Plasmom tredje ögonlock," },
  {
    value: "ÖA429",
    title: "Övriga kroniska infl tillstånd ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA4291",
    title: "Chalazion, hordeolum och andra solitära granulom i ögonlock.",
  },
  {
    value: "ÖA4292",
    title: "Multifokal granulomatös inflammation, ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA4299",
    title: "Postinflammatoriskt tillstånd ögonlock/3:e ögonlock",
  },
  {
    value: "ÖA42991",
    title: "Ärr, pigmentförändring, alopeci ögonlock/3:e ögonlock",
  },
  { value: "ÖA43", title: "Parasitära sjukdomar ögonlock/3:e ögonlock" },
  { value: "ÖA5", title: "Immunmedierade tillstånd ögonlock/3:e ögonlock" },
  { value: "ÖA51", title: "Immunmedierad blefarit" },
  { value: "ÖA6", title: "Neoplastiska förändringar ögonlock/3:e ögonlock" },
  { value: "ÖA61", title: "Tumör i övre/nedre ögonlock/adnexa" },
  {
    value: "ÖA611",
    title: "Benigna förändringar i övre/nedre ögonlock/adnexa",
  },
  {
    value: "ÖA6111",
    title: "Epiteliala neoplasier/dysplas, pre-neoplast förändr ögonl",
  },
  { value: "ÖA612", title: "Sarkoid i övre/nedre ögonlock/adnexa" },
  { value: "ÖA613", title: "Adenom i övre/nedre ögonlock/adnexa" },
  { value: "ÖA614", title: "Maligna neoplasier  i övre/nedre ögonlock/adnexa" },
  {
    value: "ÖA6141",
    title: "Plattepitelskarcinom i övre/nedre ögonlock/adnexa",
  },
  { value: "ÖA6142", title: "Adenocarcinom  i övre/nedre ögonlock/adnexa" },
  { value: "ÖA6143", title: "Melanom i övre/nedre ögonlock/adnexa" },
  { value: "ÖA62", title: "Tumör i tredje ögonlock" },
  { value: "ÖA621", title: "Benigna förändringar ögonlock/3:e ögonlock" },
  {
    value: "ÖA6211",
    title: "Epiteliala neoplasi/dysplasi, pre-neoplast förändr 3:e ögonl",
  },
  { value: "ÖA622", title: "Adenom i tredje ögonlock" },
  { value: "ÖA623", title: "Maligna neoplasier i tredje ögonlock" },
  { value: "ÖA6231", title: "Plattepitelskarcinom i tredje ögonlock" },
  { value: "ÖA6232", title: "Adenocarcinom  i tredje ögonlock" },
  { value: "ÖA6233", title: "Melanom i tredje ögonlock" },
  {
    value: "ÖA7",
    title: "Trauma, fr kr, lägesförändr, term skador ögonlock/3:e ögonl",
  },
  {
    value: "ÖA71",
    title: "Traumatiska / mekaniska skador ögonlock/3:e ögonlock",
  },
  { value: "ÖA711", title: "Sår övre/nedre ögonlock" },
  { value: "ÖA7111", title: "Rivsår övre/nedre ögonlock" },
  { value: "ÖA7112", title: "Sticksår / skottsår.övre/nedre ögonlock" },
  { value: "ÖA7113", title: "Krossår övre/nedre ögonlock" },
  { value: "ÖA7114", title: "Skrapsår ögonlock/3:e ögonlock" },
  { value: "ÖA712", title: "Sårskada tredje ögonlock" },
  { value: "ÖA72", title: "Främmande kroppar ögonlock/3:e ögonlock" },
  { value: "ÖA721", title: "Främmande kropp  övre/nedre ögonlock." },
  {
    value: "ÖA722",
    title: "Främm kr i 3:e ögonlock eller 3:e ögonlockets konjunktiva.",
  },
  { value: "ÖA73", title: "Lägesförändringar ögonlock/3:e ögonlock" },
  {
    value: "ÖA730",
    title: "Inåtrullat/utåtrullat ögonlock- entropion/ektropion",
  },
  { value: "ÖA731", title: "Entropion" },
  { value: "ÖA732", title: "Ektropion" },
  {
    value: "ÖA733",
    title: "Ptosis, annat än vid facialispares eller Horner's syndrom.",
  },
  { value: "ÖA734", title: 'Prolaberad tårkörtel, "cherry eye"' },
  { value: "ÖA735", title: "Broskmissbildningar, eversion av brosk" },
  { value: "ÖA737", title: "Framfall av tredje ögonlocket" },
  { value: "ÖA74", title: "Termiska skador ögonlock/3:e ögonlock" },
  { value: "ÖA741", title: "Brännskada ögonlock/3:e ögonlock" },
  { value: "ÖA7411", title: "Iatrogena brännskador ögonlock/3:e ögonlock" },
  { value: "ÖA742", title: "Köldskada ögonlock/3:e ögonlock" },
  { value: "ÖA7421", title: "Iatrogen köldskada ögonlock/3:e ögonlock" },
  { value: "ÖA8", title: "Toxiska förändringar ögonlock/3:e ögonlock" },
  { value: "ÖA81", title: "Kemiskt betingade skador på ögonlock/3:e ögonlock" },
  {
    value: "ÖA9",
    title: "Idiopatiska, ospecif, multifakt ögonlock/3:e ögonlock",
  },
  { value: "ÖB", title: "Konjunktiva, tårorgan, episclera" },
  {
    value: "ÖB0",
    title: "Normalvar, symtom u säker orsak, konjunkt/tårorgan/episclera",
  },
  { value: "ÖB00", title: "Normalvariation konjunktiva, tårorgan, episclera" },
  { value: "ÖB000", title: "Onormal tårfilm" },
  {
    value: "ÖB01",
    title: "Symtom på sjd u säker orsak, konjunktiva/tårorgan/episclera",
  },
  { value: "ÖB011", title: "Epifora av icke specificerad etiologi" },
  {
    value: "ÖB1",
    title: "Missbildn, utveckl.rubbn,tillväxtrubbn konjt/tårorg/episcler",
  },
  { value: "ÖB10", title: "Missbildningar konjunktiva, tårorgan, episclera" },
  { value: "ÖB101", title: "Dermoid i konjunktiva." },
  { value: "ÖB102", title: "Cysta i konjunktiva." },
  {
    value: "ÖB103",
    title: "Atresi eller annan obstruktion av tårpuncta, tårkanalerna",
  },
  {
    value: "ÖB104",
    title: "Atresi eller annan obstr av tårkanalsöppning i näsborre",
  },
  {
    value: "ÖB107",
    title: "Divert/multipla öppn/kongen fist/cystor/missbildn, tårkanal",
  },
  {
    value: "ÖB11",
    title: "Utvecklingsrubbn/tillväxtrubbn konjunkt, tårorgan, episclera",
  },
  {
    value: "ÖB2",
    title: "Metabol,nutrit,degen/dystr föränd konjunkt, tårorgan, episcl",
  },
  {
    value: "ÖB20",
    title: "Metaboliska förändringar konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB21",
    title: "Nutritionella förändringar konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB22",
    title: "Degen/dystrof förändringar konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB23",
    title: "Hormonella förändringar konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB3",
    title: "Cirkulatoriska förändringar konjunktiva, tårorgan, episclera",
  },
  { value: "ÖB30", title: "Blödningar konjunktiva, tårorgan, episclera" },
  {
    value: "ÖB301",
    title: "Subkonjunkt/episkleral blödn konjunkt, tårorgan, episclera",
  },
  { value: "ÖB31", title: "Ödem konjunktiva, tårorgan, episclera" },
  { value: "ÖB311", title: "Chemos" },
  { value: "ÖB32", title: "Infarkter konjunktiva, tårorgan, episclera" },
  { value: "ÖB33", title: "Hygrom konjunktiva, tårorgan, episclera" },
  { value: "ÖB34", title: "Trombos / emboli konjunktiva, tårorgan, episclera" },
  {
    value: "ÖB35",
    title: "Arterio-atero-skleros förändr konjunkt, tårorgan, episclera",
  },
  {
    value: "ÖB4",
    title: "Infekt, infl förändringar konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB40",
    title: "Specif infektionssjukdomar konjunktiva, tårorgan, episclera",
  },
  { value: "ÖB401", title: "Infektiös keratokonjungtivit (Moraxella bovis)" },
  {
    value: "ÖB41",
    title: "Akuta infl.tillstånd konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB411",
    title: "Akut serös/serofibrinös infl konjunkt, tårorgan, episclera",
  },
  { value: "ÖB4111", title: "Akut serös konjunktivit/chemos." },
  {
    value: "ÖB412",
    title: "Akut purulent/mukopurulent infl konjunkt, tårorgan, episcler",
  },
  { value: "ÖB4121", title: "Akut purulent konjunktivit" },
  {
    value: "ÖB413",
    title: "Spec akuta infl tillstånd konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB419",
    title: "Övriga akuta infl tillstånd konjunktiva, tårorgan, episclera",
  },
  { value: "ÖB4191", title: "Akut mukös konjunktivit" },
  { value: "ÖB4193", title: "Episklerit, akut" },
  { value: "ÖB4194", title: "Keratoconjunctivitis sicca, akut." },
  {
    value: "ÖB42",
    title: "Kroniska infl.tillstånd konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB421",
    title: "Kronisk serös inflammation konjunktiva, tårorgan, episclera",
  },
  { value: "ÖB4211", title: "Kronisk serös konjunktivit/kemos." },
  {
    value: "ÖB422",
    title: "Purulent / mukös infl konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB4221",
    title: "Kronisk mukopurulent eller purulent konjunktivit",
  },
  { value: "ÖB423", title: "Abscess/fistel konjunktiva, tårorgan, episclera" },
  { value: "ÖB4231", title: "Abscess/fistel i konjunktiva." },
  {
    value: "ÖB424",
    title: "Specif kroniska infl tillstånd konjunkt, tårorgan, episclera",
  },
  { value: "ÖB4241", title: "Episklerit, granulomatös." },
  {
    value: "ÖB429",
    title: "Övriga kron infl tillstånd konjunktiva, tårorgan, episclera",
  },
  { value: "ÖB4291", title: "Kronisk mukös konjunktivit" },
  {
    value: "ÖB4292",
    title: "Episklerit, kron ospecif, konjunkt, tårorgan, episclera",
  },
  { value: "ÖB4293", title: "Kronisk keratoconjunctivitis sicca." },
  { value: "ÖB4294", title: "Nodulär fasciit." },
  { value: "ÖB4297", title: "Dacryocystit, canaliculit." },
  {
    value: "ÖB4299",
    title: "Postinfl förändr, konjunktiva, tårorgan, episclera",
  },
  { value: "ÖB42991", title: "Symblepharon" },
  {
    value: "ÖB43",
    title: "Parasitära sjukdomar, konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB5",
    title: "Immunmedierade tillstånd, konjunktiva, tårorgan, episclera",
  },
  { value: "ÖB51", title: "Kemos vid akut överkänslighet." },
  {
    value: "ÖB6",
    title: "Neoplastiska förändringar, konjunktiva, tårorgan, episclera",
  },
  { value: "ÖB61", title: "Konjunktivala neoplasier." },
  {
    value: "ÖB611",
    title: "Benign epitelial neoplasi/dysplasi, pre-neoplasti, konjunkt",
  },
  { value: "ÖB612", title: "Plattepitelskarcinom, konjunktiva" },
  { value: "ÖB613", title: "Angiosarkom, konjunktiva" },
  { value: "ÖB62", title: "Episklerala neoplasier" },
  { value: "ÖB63", title: "Neoplasier i tårorgan" },
  {
    value: "ÖB7",
    title: "Trauma, fr kr, lägesförändr,term skador konjunkt, tårorgan",
  },
  {
    value: "ÖB71",
    title: "Traumatiska/mekaniska skador konjunkt, tårorgan, episclera",
  },
  { value: "ÖB711", title: "Sår i konjunktiva." },
  { value: "ÖB712", title: "Tårkanalsskada" },
  { value: "ÖB72", title: "Främmande kroppar konjunktiva / tårkanal" },
  { value: "ÖB721", title: "Främmande kropp i konjunktiva." },
  {
    value: "ÖB722",
    title: "Främmande kropp i tårkanal eller andra delar av tårorganen.",
  },
  {
    value: "ÖB73",
    title: "Lägesförändringar, konjunktiva, tårorgan, episclera",
  },
  { value: "ÖB74", title: "Termiska skador, konjunktiva, tårorgan, episclera" },
  { value: "ÖB741", title: "Brännskada, konjunktiva, tårorgan, episclera" },
  { value: "ÖB742", title: "Köldskada, konjunktiva, tårorgan, episclera" },
  {
    value: "ÖB8",
    title: "Toxiska förändringar, konjunktiva, tårorgan, episclera",
  },
  {
    value: "ÖB81",
    title: "Kemisk frätskada/icke iatrogen/ ulcera i konjunktiva.",
  },
  {
    value: "ÖB82",
    title: "Iatrogen, toxisk/kemisk skada, konjunkt, tårorgan, episclera",
  },
  {
    value: "ÖB9",
    title: "Idiopatiska/ospecif/multifakt, konjunkt, tårorgan, episclera",
  },
  { value: "ÖB91", title: "Konjunktivala tillstånd" },
  { value: "ÖB92", title: "Episklerala tillstånd" },
  { value: "ÖB93", title: "Tillstånd i tårorgan" },
  { value: "ÖC", title: "Cornea, sklera" },
  {
    value: "ÖC0",
    title: "Normalvar, symtom u fastställd orsak, kornea sklera",
  },
  { value: "ÖC00", title: "Normalvariation, kornea sklera" },
  {
    value: "ÖC01",
    title: "Symtom på sjukdom u fastställd orsak, kornea sklera",
  },
  {
    value: "ÖC1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, kornea sklera",
  },
  { value: "ÖC10", title: "Missbildningar, kornea sklera" },
  { value: "ÖC101", title: "Dermoid cornea." },
  { value: "ÖC102", title: "Mikrokornea" },
  { value: "ÖC103", title: "Stafylom, sklera" },
  { value: "ÖC11", title: "Utvecklingsrubbn, tillväxtrubbn, kornea sklera" },
  { value: "ÖC111", title: "Inklusioncysta i kornea, utvecklingsmässig" },
  {
    value: "ÖC2",
    title: "Metabol, nutrit, degen/dystrof förändr, kornea sklera",
  },
  { value: "ÖC20", title: "Metaboliska förändringar, kornea sklera" },
  { value: "ÖC21", title: "Nutritionella förändringar, kornea sklera" },
  {
    value: "ÖC22",
    title: "Degenerativa/dystrofiska förändringar, kornea sklera",
  },
  { value: "ÖC221", title: "Korneadystrofi, epitelial och / eller stromal" },
  {
    value: "ÖC2211",
    title: "Cornea nigrum, korneal sekvester, korneal mumifikation",
  },
  { value: "ÖC2212", title: "Korneal dystrofi med lipidinlagring." },
  { value: "ÖC222", title: "Korneadystrofi, endotelial" },
  { value: "ÖC223", title: "Korneadegeneration inklusive lipidkeratopati." },
  { value: "ÖC23", title: "Hormonella förändringar, kornea sklera" },
  { value: "ÖC3", title: "Cirkulatoriska förändringar, kornea sklera" },
  { value: "ÖC30", title: "Blödningar, kornea sklera" },
  {
    value: "ÖC301",
    title: "Korneal/stromal  blodimbibition, utan etiologisk diagnos.",
  },
  { value: "ÖC302", title: "Skleral blödning" },
  { value: "ÖC31", title: "Ödem, kornea sklera" },
  { value: "ÖC311", title: "Korneaödem utan etiologisk diagnos" },
  { value: "ÖC32", title: "Infarkter, kornea sklera" },
  { value: "ÖC33", title: "Hygrom, kornea sklera" },
  { value: "ÖC34", title: "Trombos / emboli, kornea sklera" },
  { value: "ÖC35", title: "Arterio-atero-skleros förändringar, kornea sklera" },
  { value: "ÖC4", title: "Infekt, infl förändringar, kornea sklera" },
  { value: "ÖC40", title: "Specifika infektionssjukdomar, kornea sklera" },
  { value: "ÖC401", title: "Herpeskeratit" },
  { value: "ÖC41", title: "Akuta inflammationstillstånd, kornea sklera" },
  {
    value: "ÖC411",
    title: "Akut serös / serofibrinös inflammation, kornea sklera",
  },
  {
    value: "ÖC4111",
    title: "Akuta ytliga (epiteliala) ulcera, kornea sklera.",
  },
  {
    value: "ÖC41111",
    title: "Okomplicerade epiteliala erosioner, kornea sklera",
  },
  { value: "ÖC412", title: "Akut purulent inflammation, kornea sklera" },
  { value: "ÖC413", title: "Specifika akuta infl tillstånd, kornea sklera" },
  { value: "ÖC419", title: "Övriga akuta infl tillstånd, kornea sklera" },
  {
    value: "ÖC4199",
    title: "Akut keratit, övrig, ospecificerad, icke ulcerativ,",
  },
  { value: "ÖC42", title: "Kroniska inflammationstillstånd, kornea sklera" },
  { value: "ÖC421", title: "Kronisk serös inflammation, kornea sklera" },
  {
    value: "ÖC4211",
    title: "Kroniska ytliga (epiteliala) sår, kornea sklera.",
  },
  {
    value: "ÖC42111",
    title: "Kron epiteliala erosioner pga persist retning, kornea sklera",
  },
  {
    value: "ÖC42112",
    title: 'Kroniska epiteliala erosioner, inklusive "boxerulcus"',
  },
  { value: "ÖC422", title: "Purulent inflammation, kornea sklera" },
  { value: "ÖC4221", title: "Hornhinnesår, stromala." },
  {
    value: "ÖC42211",
    title: "Hornhinnesår, stromalt, ej descemetocele eller perforerande",
  },
  { value: "ÖC42212", title: "Hornhinnesår, descemetocele" },
  { value: "ÖC42213", title: "Hornhinnesår smältande (= keratomalaci)." },
  { value: "ÖC42214", title: "Hornhinnesår, perforerande" },
  { value: "ÖC42217", title: "Sklerit, ej granulomatös/nekrotiserande." },
  { value: "ÖC423", title: "Abscess/fistel, kornea sklera" },
  { value: "ÖC4231", title: "Intrastromal nekros/abscess i hornhinna." },
  { value: "ÖC424", title: "Specifika kroniska infl tillstånd, kornea sklera" },
  {
    value: "ÖC4241",
    title: "Nodulär, granulomatös episklerokeratit, kornea sklera",
  },
  {
    value: "ÖC4242",
    title: "Eosinofil proliferativ keratit o/e eosinofil konjunktivit",
  },
  { value: "ÖC4247", title: "Sklerit, granulomatös/nekrotiserande." },
  { value: "ÖC429", title: "Övriga kroniska infl tillstånd, kornea sklera" },
  { value: "ÖC4291", title: "Pigmentös keratit" },
  { value: "ÖC4292", title: 'Kronisk ytlig keratit, "pannus".' },
  {
    value: "ÖC4293",
    title: "Kronisk keratit, övrig, ospecificerad, icke ulcerativ",
  },
  { value: "ÖC4299", title: "Postinflammatoriska tillstånd, kornea sklera" },
  { value: "ÖC42991", title: "Ärr, vaskualisering, kornea" },
  { value: "ÖC43", title: "Parasitära sjukdomar, kornea" },
  { value: "ÖC5", title: "Immunmedierade tillstånd, kornea sklera" },
  { value: "ÖC6", title: "Neoplastiska förändringar, kornea sklera" },
  { value: "ÖC61", title: "Tumör i kornea" },
  { value: "ÖC611", title: "Benigna epiteliala neoplasier/dysplasier, kornea" },
  { value: "ÖC612", title: "Plattepitelskarcinom, kornea" },
  { value: "ÖC613", title: "Angiosarkom, kornea," },
  { value: "ÖC619", title: "Övriga korneala neoplasier" },
  { value: "ÖC63", title: "Sklerala neoplasier" },
  { value: "ÖC631", title: "Limbala melanom" },
  { value: "ÖC639", title: "Övriga sklerala tumörer" },
  {
    value: "ÖC7",
    title: "Trauma, fr kr, lägeförändr, term skad, kornea sklera",
  },
  { value: "ÖC71", title: "Traumatiska / mekaniska skador, kornea sklera" },
  { value: "ÖC711", title: "Traumatiska  hornhinnesår, ej perforerande" },
  { value: "ÖC712", title: "Traumatiska  hornhinnesår, perforerande" },
  {
    value: "ÖC7121",
    title: "Traumatiska  hornhinnesår pga skarpt våld, kornea",
  },
  {
    value: "ÖC7122",
    title: "Traumatiska  hornhinnesår pga trubbigt våld, kornea",
  },
  {
    value: "ÖC713",
    title: "Inklusionscysta i kornea, sannolikt traumatiskt betingad.",
  },
  { value: "ÖC717", title: "Traumatiska senhinnesår, ej perforerande" },
  { value: "ÖC718", title: "Traumatiska senhinnesårperforerande." },
  { value: "ÖC7181", title: "Traumatiska senhinnesår pga skarpt våld, sklera" },
  {
    value: "ÖC7182",
    title: "Traumatiska senhinnesår pga trubbigt våld, sklera",
  },
  { value: "ÖC72", title: "Främmande kroppar, kornea sklera" },
  { value: "ÖC721", title: "Främmande kropp kornea, ej perforerande" },
  { value: "ÖC722", title: "Främmande kropp kornea, perforerande." },
  { value: "ÖC727", title: "Främmande kropp sklera, ej perforerande" },
  { value: "ÖC728", title: "Främmande kropp  sklera, perforerande." },
  { value: "ÖC73", title: "Lägesförändringar, kornea sklera" },
  { value: "ÖC74", title: "Termiska skador, kornea sklera" },
  { value: "ÖC741", title: "Brännskada, kornea sklera" },
  { value: "ÖC742", title: "Köldskada, kornea sklera" },
  { value: "ÖC8", title: "Toxiska förändringar, kornea sklera" },
  { value: "ÖC81", title: "Toxiska förändringar i kornea" },
  { value: "ÖC811", title: "Toxiskt/kemiskt betingat hornhinnesår." },
  { value: "ÖC812", title: "Toxiska förändringar i sklerala" },
  { value: "ÖC9", title: "Idiopatiska, ospecif, multifakt, kornea sklera" },
  { value: "ÖC91", title: "Hornhinnesår" },
  { value: "ÖD", title: "Uvea, främre ögonkammare" },
  {
    value: "ÖD0",
    title: "Normalvar, symtom u fastställd orsak, uvea fr ögonkammare",
  },
  { value: "ÖD00", title: "Normalvariation, uvea främre ögonkammare" },
  {
    value: "ÖD001",
    title: "Heterokromi, anisokromi, nevi, m.fl. pigmentvarianter.",
  },
  {
    value: "ÖD002",
    title: "Ofullständig utv av tapetum lucidum, tapetumvariationer etc",
  },
  {
    value: "ÖD01",
    title: "Symtom på sjukdom u fastställd orsak, uvea fr ögonkammare",
  },
  {
    value: "ÖD1",
    title: "Missbildn/utveckl. rubbn/tillväxtrubbn, uvea fr ögonkammare",
  },
  { value: "ÖD10", title: "Missbildningar, uvea främre ögonkammare" },
  { value: "ÖD101", title: "Irishypoplasi, aniridi." },
  { value: "ÖD102", title: "Iriskolobom, ciliarkroppskolobom." },
  {
    value: "ÖD103",
    title: 'Kolobom i koroidea, "perifera choroidala cystor".',
  },
  { value: "ÖD104", title: "Iriscystor, ärftligt betingade" },
  { value: "ÖD105", title: "Iriscystor typ missbildning." },
  {
    value: "ÖD11",
    title: "Utvecklingsrubbn, tillväxtrubbn, uvea fr ögonkammare",
  },
  { value: "ÖD111", title: "Goniodysgenesi" },
  { value: "ÖD112", title: "Persisterande pupillmembran" },
  {
    value: "ÖD2",
    title: "Metabol, nutrit, degen/dystrof förändr, uvea fr ögonkammare",
  },
  { value: "ÖD20", title: "Metaboliska förändringar, uvea fr ögonkammare" },
  { value: "ÖD21", title: "Nutritionella förändringar, uvea fr ögonkammare" },
  {
    value: "ÖD22",
    title: "Degenerativa/dystrofiska förändringar, uvea fr ögonkammare",
  },
  { value: "ÖD221", title: "Irisatrofi, stromal eller sfinktermuskulär" },
  { value: "ÖD222", title: "Cystiska corpora nigra" },
  { value: "ÖD223", title: "Choroideremi, choroidal atrofi/degeneration" },
  { value: "ÖD23", title: "Hormonella förändringar, uvea fr ögonkammare" },
  {
    value: "ÖD3",
    title: "Cirkulatoriska förändringar, uvea främre ögonkammare",
  },
  { value: "ÖD30", title: "Blödningar, uvea fr ögonkammare" },
  { value: "ÖD301", title: "Hyfema, icke traumatisk." },
  { value: "ÖD302", title: "Petekier/eckhymoser/blödningar i främre uvea" },
  { value: "ÖD303", title: "Blödningar i bakre uvea." },
  { value: "ÖD31", title: "Ödem, uvea fr ögonkammare" },
  { value: "ÖD32", title: "Infarkter, uvea fr ögonkammare" },
  { value: "ÖD33", title: "Hygrom, uvea fr ögonkammare" },
  { value: "ÖD34", title: "Trombos / emboli, uvea fr ögonkammare" },
  {
    value: "ÖD35",
    title: "Arterio-atero-skleros förändringar, uvea fr ögonkammare",
  },
  { value: "ÖD4", title: "Infekt, infl förändringar, uvea främre ögonkammare" },
  {
    value: "ÖD40",
    title: "Specifika infektionssjukdomar, uvea fr ögonkammare",
  },
  { value: "ÖD41", title: "Akuta inflammationstillstånd, uvea fr ögonkammare" },
  {
    value: "ÖD411",
    title: "Akut serös / serofibrinös inflammation, uvea fr ögonkammare",
  },
  {
    value: "ÖD4111",
    title: "Främre akut uveit utan uppenbart purulenta inslag",
  },
  {
    value: "ÖD4112",
    title: "Bakre akut uveit utan uppenbart purulenta inslag",
  },
  { value: "ÖD4113", title: "Akut  panuveit utan uppenbart purulenta inslag" },
  { value: "ÖD412", title: "Akut purulent inflammation, uvea fr ögonkammare" },
  {
    value: "ÖD4121",
    title: "Akut främre uveit med uppenbart purulenta/exudativa inslag",
  },
  {
    value: "ÖD4123",
    title: "Akut bakre uveit med uppenbart purulenta/exudativa inslag",
  },
  {
    value: "ÖD4125",
    title: "Akut panuveit med uppenbart purulenta/exudativa inslag",
  },
  {
    value: "ÖD413",
    title: "Specifika akuta infl tillstånd, uvea fr ögonkammare",
  },
  { value: "ÖD419", title: "Övriga akuta infl tillstånd, uvea fr ögonkammare" },
  { value: "ÖD4191", title: "Akut traumatisk uveit" },
  {
    value: "ÖD42",
    title: "Kroniska inflammationstillstånd, uvea fr ögonkammare",
  },
  { value: "ÖD421", title: "Kronisk serös inflammation, uvea fr ögonkammare" },
  {
    value: "ÖD4211",
    title: "Främre kronisk uveit utan uppenbart purulenta inslag",
  },
  {
    value: "ÖD4212",
    title: "Bakre kronisk uveit utan uppenbart purulenta inslag",
  },
  {
    value: "ÖD4213",
    title: "Kronisk panuveit utan uppenbart purulenta inslag",
  },
  { value: "ÖD422", title: "Purulent inflammation, uvea fr ögonkammare" },
  {
    value: "ÖD4221",
    title: "Kron främre uveit med uppenbart purulenta/exudativa inslag",
  },
  {
    value: "ÖD4223",
    title: "Kron bakre uveit med uppenbart purulenta/exudativa inslag",
  },
  {
    value: "ÖD4225",
    title: "Kronisk panuveit med uppenbart purulenta/exudativa inslag",
  },
  { value: "ÖD423", title: "Abscess/fistel, uvea fr ögonkammare" },
  { value: "ÖD4231", title: "Intrastromala abscesser i uvea" },
  {
    value: "ÖD424",
    title: "Specifika kroniska infl tillstånd, uvea fr ögonkammare",
  },
  { value: "ÖD4241", title: "Granulomatös/proliferativ främre uveit." },
  { value: "ÖD4242", title: "Granulomatös/proliferativ bakre uveit." },
  { value: "ÖD4243", title: "Granulomatös/proliferativ panuveit." },
  { value: "ÖD4244", title: "Uveo - (-meningo-) - dermala syndrom, VKH." },
  {
    value: "ÖD4245",
    title: "Proliferativ uveit vid linsruptur, phacoclastisk uveit.",
  },
  { value: "ÖD4246", title: "Fokala granulom i uvea" },
  { value: "ÖD42461", title: "Främre uveala granulom, fokala." },
  { value: "ÖD42462", title: "Bakre uveala granulom, fokala." },
  {
    value: "ÖD429",
    title: "Övriga kroniska infl tillstånd, uvea fr ögonkammare",
  },
  {
    value: "ÖD4299",
    title: "Postinflammatoriska förändringar, uvea fr ögonkammare",
  },
  {
    value: "ÖD42991",
    title: "Synechier, fibrösa stråk, pigmentförändr, pigmentmigration,",
  },
  { value: "ÖD42992", title: "Atrofi corpora nigra." },
  { value: "ÖD42993", title: "Iriscystor, inflammatoriskt betingade." },
  { value: "ÖD43", title: "Parasitära sjukdomar, uvea fr ögonkammare" },
  { value: "ÖD5", title: "Immunmedierade tillstånd, uvea främre ögonkammare" },
  { value: "ÖD6", title: "Neoplastiska förändringar, uvea främre ögonkammare" },
  { value: "ÖD61", title: "Tumörer i främre uvea" },
  { value: "ÖD611", title: "Melanom i främre uvea" },
  { value: "ÖD612", title: "Epiteliala ciliarkroppsneoplasier" },
  { value: "ÖD619", title: "Övriga neoplasier/dysplasier i främre uvea" },
  { value: "ÖD62", title: "Neoplasier i bakre uvea" },
  { value: "ÖD621", title: "Melanom i bakre uvea" },
  { value: "ÖD629", title: "Övriga neoplasier/dysplasier i bakre uvea" },
  {
    value: "ÖD7",
    title: "Trauma,fr kr, lägesförändr, term skador, uvea fr ögonkammare",
  },
  {
    value: "ÖD71",
    title: "Traumatiska / mekaniska skador, uvea fr ögonkammare",
  },
  { value: "ÖD711", title: "Sår involverande uvea." },
  { value: "ÖD712", title: "Traumatisk blödning till ögonkammaren." },
  { value: "ÖD72", title: "Främmande kroppar, uvea fr ögonkammare" },
  { value: "ÖD721", title: "Främmande kropp i uvea eller främre ögonkammare." },
  { value: "ÖD73", title: "Lägesförändringar, uvea fr ögonkammare" },
  { value: "ÖD731", title: "Iris bombé." },
  { value: "ÖD735", title: "Avlossning av ciliarkropp, cyclodialys" },
  { value: "ÖD74", title: "Termiska skador, uvea fr ögonkammare" },
  { value: "ÖD741", title: "Brännskada, uvea fr ögonkammare" },
  { value: "ÖD742", title: "Köldskada, uvea fr ögonkammare" },
  { value: "ÖD8", title: "Toxiska förändringar, uvea främre ögonkammare" },
  {
    value: "ÖD9",
    title: "Idiopatiska, ospecif, multifakt, uvea främre ögonkammare",
  },
  { value: "ÖE", title: "Lins, glaskropp (corpus vitreum)" },
  {
    value: "ÖE0",
    title: "Normalvar, symtom u fastställd orsak, lins glaskropp",
  },
  { value: "ÖE00", title: "Normalvariation, lins glaskropp" },
  {
    value: "ÖE01",
    title: "Symtom på sjukdom u fastställd orsak, lins glaskropp",
  },
  {
    value: "ÖE1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, lins glaskropp",
  },
  { value: "ÖE10", title: "Missbildningar, lins glaskropp" },
  { value: "ÖE101", title: "Katarakt, kongenital." },
  { value: "ÖE102", title: "Mikrofaki, afaki" },
  { value: "ÖE103", title: "Linskolobom" },
  { value: "ÖE104", title: "Lenticonus, lentiglobus" },
  { value: "ÖE105", title: "Linscysta" },
  { value: "ÖE106", title: "Sferofaki" },
  { value: "ÖE107", title: "Persisterande hyaloidkärl utan PHTVL/PHPV" },
  { value: "ÖE108", title: "PHTVL/PHPV, persisterande iridokapsulära kärl" },
  { value: "ÖE11", title: "Utvecklingsrubbn, tillväxtrubbn, lins glaskropp" },
  {
    value: "ÖE2",
    title: "Metabol, nutrit, degen/dystr förändr, lins glaskropp",
  },
  { value: "ÖE20", title: "Metaboliska förändringar, lins glaskropp" },
  { value: "ÖE201", title: "Katarakt, ej kongenital, ej nutritionell" },
  {
    value: "ÖE202",
    title: "Katarakt med linsresorption / Morgagnisk katarakt.",
  },
  { value: "ÖE21", title: "Nutritionella förändringar, lins glaskropp" },
  { value: "ÖE211", title: "Katarakt, nutritionell." },
  {
    value: "ÖE22",
    title: "Degenerativa/dystrofiska förändringar, lins glaskropp",
  },
  { value: "ÖE221", title: "Asteroid hyalos" },
  { value: "ÖE222", title: "Synchysis scintillans, cholesterolosis bulbi" },
  { value: "ÖE223", title: "Syneresis, vätskeomvandling av glaskropp" },
  { value: "ÖE23", title: "Hormonella förändringar, lins glaskropp" },
  { value: "ÖE3", title: "Cirkulatoriska förändringar, lins glaskropp" },
  { value: "ÖE30", title: "Blödningar, lins glaskropp" },
  { value: "ÖE301", title: "Blödning i glaskropp, ej traumatisk." },
  { value: "ÖE4", title: "Infekt, infl förändringar, lins glaskropp" },
  { value: "ÖE40", title: "Specifika infektionssjukdomar, lins glaskropp" },
  { value: "ÖE41", title: "Akuta inflammationstillstånd, lins glaskropp" },
  {
    value: "ÖE411",
    title: "Akut serös / serofibrinös inflammation, lins glaskropp",
  },
  { value: "ÖE412", title: "Akut purulent inflammation, lins glaskropp" },
  { value: "ÖE413", title: "Specifika akuta infl tillstånd, lins glaskropp" },
  { value: "ÖE419", title: "Övriga akuta infl tillstånd, lins glaskropp" },
  { value: "ÖE42", title: "Kroniska inflammationstillstånd, lins glaskropp" },
  { value: "ÖE421", title: "Kronisk serös inflammation, lins glaskropp" },
  { value: "ÖE422", title: "Purulent inflammation, lins glaskropp" },
  { value: "ÖE423", title: "Abscess/fistel, lins glaskropp" },
  {
    value: "ÖE424",
    title: "Specifika kroniska infl tillstånd, lins glaskropp",
  },
  { value: "ÖE429", title: "Övriga kroniska infl tillstånd, lins glaskropp" },
  { value: "ÖE4299", title: "Post-inflammatoriska tillstånd, lins glaskropp" },
  {
    value: "ÖE42991",
    title: "Fibrösa stråk m. fl. post-infl tillstånd i glaskropp",
  },
  { value: "ÖE42992", title: "Preretinala membran" },
  { value: "ÖE43", title: "Parasitära sjukdomar, lins glaskropp" },
  { value: "ÖE5", title: "Immunmedierade tillstånd, lins glaskropp" },
  { value: "ÖE6", title: "Neoplastiska förändringar, lins glaskropp" },
  {
    value: "ÖE7",
    title: "Trauma, fr kr, lägeförändr, term skad, lins glaskropp",
  },
  { value: "ÖE71", title: "Traumatiska / mekaniska skador, lins glaskropp" },
  { value: "ÖE711", title: "Katarakt, traumatisk eller post-traumatisk." },
  { value: "ÖE7111", title: "Katarakt efter operativt ingrepp" },
  { value: "ÖE712", title: "Linskapselruptur." },
  { value: "ÖE713", title: "Blödning i glaskropp, traumatisk." },
  { value: "ÖE72", title: "Främmande kroppar, lins glaskropp" },
  { value: "ÖE721", title: "Främmande kropp involverande lins" },
  { value: "ÖE725", title: "Främmande kropp i glaskropp" },
  { value: "ÖE73", title: "Lägesförändringar, lins glaskropp" },
  { value: "ÖE731", title: "Linsluxation / subluxation" },
  { value: "ÖE732", title: "Glaskroppsframfall i främre segmentet" },
  { value: "ÖE74", title: "Termiska skador, lins glaskropp" },
  { value: "ÖE741", title: "Brännskada, lins glaskropp" },
  { value: "ÖE742", title: "Köldskada, lins glaskropp" },
  { value: "ÖE8", title: "Toxiska förändringar, lins glaskropp" },
  { value: "ÖE81", title: "Senil skleros" },
  { value: "ÖE9", title: "Idiopatiska, ospecif, multifakt, lins glaskropp" },
  { value: "ÖF", title: "Retina, synnerv" },
  {
    value: "ÖF0",
    title: "Normalvar, symtom u fastställd orsak, retina synnerv",
  },
  { value: "ÖF00", title: "Normalvariation, retina synnerv" },
  {
    value: "ÖF01",
    title: "Symtom på sjukdom u fastställd orsak, retina synnerv",
  },
  {
    value: "ÖF011",
    title: "Abnormt slingrande retinala kärl, utan etiologisk diagnos.",
  },
  { value: "ÖF012", title: "Symtom på blindhet/nedsatt synförmåga" },
  {
    value: "ÖF1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, retina synnerv",
  },
  { value: "ÖF10", title: "Missbildningar, retina synnerv" },
  {
    value: "ÖF101",
    title: "Collie eye anomaly, CEA. (collie, sheltie, border collie)",
  },
  {
    value: "ÖF102",
    title: "Kolobom eller CEA-likn (ej collie, sheltie, border collie)",
  },
  { value: "ÖF103", title: "Retinalt septum" },
  { value: "ÖF107", title: "Synnervshypoplasi (aplasi)" },
  { value: "ÖF11", title: "Utvecklingsrubbn, tillväxtrubbn, retina synnerv" },
  { value: "ÖF111", title: "Retinala veck (ej klassade som retinal dysplasi)" },
  { value: "ÖF112", title: "Retinal dysplasi eller vitreoretinal dysplasi" },
  { value: "ÖF1121", title: "Multifokal typ av retinal dysplasi" },
  { value: "ÖF1122", title: "Total typ av retinal dysplasi" },
  { value: "ÖF1123", title: '""Geografisk retinal" dysplasi"' },
  { value: "ÖF113", title: "Abnorma retinala kärl" },
  {
    value: "ÖF2",
    title: "Metabol, nutrit, degen/dystrof förändr, retina synnerv",
  },
  { value: "ÖF20", title: "Metaboliska förändringar, retina synnerv" },
  { value: "ÖF201", title: "Lipemia retinalis." },
  {
    value: "ÖF203",
    title: "Retinala förändringar vid metabolisk systemsjukdom.",
  },
  { value: "ÖF2031", title: "Ceroid-lipofuscinos" },
  {
    value: "ÖF204",
    title: "Hypertensiv näthinnesjukdom, med eller utan näthinneavlossn",
  },
  { value: "ÖF21", title: "Nutritionella förändringar, retina synnerv" },
  { value: "ÖF211", title: "Felin central retinal degeneration, FCRD" },
  {
    value: "ÖF22",
    title: "Degenerativa/dystrofiska förändringar, retina synnerv",
  },
  { value: "ÖF221", title: "Specifika retinala tillstånd." },
  { value: "ÖF2211", title: "Progressiv retinal atrofi, PRA" },
  { value: "ÖF2212", title: "SARD, Sudden Aquired Retinal Degeneration" },
  { value: "ÖF2213", title: "CPRA , Central PRA, pigmentepitel dysplasi" },
  { value: "ÖF2214", title: "Hemeralopi. (dagblindhet)" },
  { value: "ÖF2215", title: "Kongenital (stationär) nattblindhet, CSNB" },
  {
    value: "ÖF222",
    title: "Perifer cystisk degenerat, cystor vid ora ciliaris retinae",
  },
  { value: "ÖF223", title: "Synnervsatrofi" },
  {
    value: "ÖF229",
    title: "Övriga degenerativa/dystrofiska tillstånd, retina synnerv",
  },
  {
    value: "ÖF2291",
    title: "Retinal degeneration / dystrofi, övrig; ospecifik retinopati",
  },
  { value: "ÖF2292", title: "Nyktalopi  (nattblindhet) utan annan diagnos." },
  { value: "ÖF2293", title: "Hemeralopi utan annan diagnos." },
  { value: "ÖF23", title: "Hormonella förändringar, retina synnerv" },
  { value: "ÖF231", title: "Retinopati pga diabetes mellitus" },
  { value: "ÖF3", title: "Cirkulatoriska förändringar, retina synnerv" },
  { value: "ÖF30", title: "Blödningar, retina synnerv" },
  { value: "ÖF301", title: "Retinala blödningar utan etiologisk diagnos" },
  { value: "ÖF31", title: "Ödem, retina synnerv" },
  { value: "ÖF311", title: "Retinalt ödem utan etiologisk diagnos" },
  { value: "ÖF312", title: "Papillödem." },
  { value: "ÖF32", title: "Infarkter, retina synnerv" },
  { value: "ÖF33", title: "Hygrom, retina synnerv" },
  { value: "ÖF34", title: "Trombos / emboli, retina synnerv" },
  {
    value: "ÖF35",
    title: "Arterio-atero-skleros förändringar, retina synnerv",
  },
  { value: "ÖF4", title: "Infekt, infl förändringar, retina synnerv" },
  { value: "ÖF40", title: "Specifika infektionssjukdomar, retina synnerv" },
  { value: "ÖF41", title: "Akuta inflammationstillstånd, retina synnerv" },
  {
    value: "ÖF411",
    title: "Akut serös / serofibrinös inflammation, retina synnerv",
  },
  { value: "ÖF4111", title: "Akut  retinit/korioretinit" },
  { value: "ÖF4115", title: "Akut  opticus-neurit" },
  { value: "ÖF412", title: "Akut purulent inflammation, retina synnerv" },
  { value: "ÖF413", title: "Specifika akuta infl tillstånd, retina synnerv" },
  { value: "ÖF419", title: "Övriga akuta infl tillstånd, retina synnerv" },
  { value: "ÖF42", title: "Kroniska inflammationstillstånd, retina synnerv" },
  { value: "ÖF421", title: "Kronisk serös inflammation, retina synnerv" },
  { value: "ÖF4211", title: "Kronisk retinit/chorioretinit" },
  { value: "ÖF4215", title: "Kronisk opticus-neurit." },
  { value: "ÖF422", title: "Purulent inflammation, retina synnerv" },
  { value: "ÖF423", title: "Abscess/fistel, retina synnerv" },
  {
    value: "ÖF424",
    title: "Specifika kroniska infl tillstånd, retina synnerv",
  },
  {
    value: "ÖF4245",
    title: "Granulomatös meningoencefalit (GME), reticulos, i synnerv.",
  },
  { value: "ÖF429", title: "Övriga kroniska infl tillstånd, retina synnerv" },
  { value: "ÖF4299", title: "Post-inflammatoriska tillstånd, retina synnerv" },
  {
    value: "ÖF42991",
    title: "Retinala ärr, post-inflammatorisk pigmentering o.d.",
  },
  { value: "ÖF42992", title: "Retinal degenerat/atrofi post infl, retinopati" },
  { value: "ÖF43", title: "Parasitära sjukdomar, retina synnerv" },
  {
    value: "ÖF431",
    title: "Retinal larva migrans, chorioretinal larva migrans.",
  },
  { value: "ÖF432", title: "Ophthalmomyiasis interna (fluglarver i ögat)" },
  { value: "ÖF433", title: "Toxoplasmos i retina." },
  { value: "ÖF5", title: "Immunmedierade tillstånd, retina synnerv" },
  { value: "ÖF6", title: "Neoplastiska förändringar, retina synnerv" },
  {
    value: "ÖF7",
    title: "Trauma, fr kr, lägeförändr, term skad, retina synnerv",
  },
  { value: "ÖF71", title: "Traumatiska / mekaniska skador, retina synnerv" },
  {
    value: "ÖF711",
    title: "Traumat retinit/chorioretinit/opticusneurit/N.opticus-avulsi",
  },
  { value: "ÖF714", title: "Retinala hål, bristningar etc" },
  { value: "ÖF72", title: "Främmande kroppar, retina synnerv" },
  { value: "ÖF73", title: "Lägesförändringar, retina synnerv" },
  { value: "ÖF731", title: "Ablatio retinae, näthinneavlossning" },
  { value: "ÖF7311", title: "Partiell näthinneavlossning" },
  { value: "ÖF7312", title: "Total näthinneavlossning" },
  { value: "ÖF74", title: "Termiska skador, retina synnerv" },
  { value: "ÖF741", title: "Brännskada, retina synnerv" },
  { value: "ÖF742", title: "Köldskada, retina synnerv" },
  { value: "ÖF8", title: "Toxiska förändringar, retina synnerv" },
  {
    value: "ÖF81",
    title: "Toxiskt betingade retinala/korioretinala tillstånd",
  },
  { value: "ÖF82", title: "Toxiskt betingade tillstånd i synnerv" },
  { value: "ÖF9", title: "Idiopatiska, ospecif, multifakt, retina synnerv" },
  {
    value: "ÖG",
    title: "Hela ögonbulben, ögonhålan, neuro-oftalmologiska tillstånd",
  },
  {
    value: "ÖG0",
    title: "Normalvar, symtom u fastställd orsak, hela ögonbulben",
  },
  { value: "ÖG00", title: "Normalvariation, hela ögonbulben" },
  {
    value: "ÖG01",
    title: "Symtom på sjukdom u fastställd orsak, hela ögonbulben",
  },
  { value: "ÖG011", title: "Symtom på blindhet/nedsatt syn" },
  { value: "ÖG012", title: "Symtom på synnedsättning" },
  {
    value: "ÖG1",
    title: "Missbildn, utveckl. rubbn, tillväxtrubbn, hela ögonbulben",
  },
  { value: "ÖG10", title: "Missbildningar, hela ögonbulben" },
  { value: "ÖG101", title: "Missbildningar i bulben" },
  { value: "ÖG1011", title: "Mikroftalmi utan andra anomalier i bulben." },
  {
    value: "ÖG1012",
    title: "Mikroftalmi m andra anomalier ;multipla anomalier i bulben.",
  },
  { value: "ÖG102", title: "Missbildningar i orbita" },
  { value: "ÖG105", title: "Orbital arteriovenös shunt" },
  { value: "ÖG11", title: "Utvecklingsrubbn, tillväxtrubbn, hela ögonbulben" },
  { value: "ÖG111", title: "Utvecklingsmässigt betingade tillstånd i bulben" },
  { value: "ÖG1111", title: "Mikroftalmi av förvärvat slag" },
  { value: "ÖG115", title: "Utvecklingsmässigt betingade tillstånd i orbita" },
  {
    value: "ÖG2",
    title: "Metabol, nutrit, degen/dystrof förändr, hela ögonbulben",
  },
  { value: "ÖG20", title: "Metaboliska förändringar, hela ögonbulben" },
  { value: "ÖG201", title: "Neuro-oftalmologiska tillstånd" },
  { value: "ÖG2011", title: "Synfältsförlust av neurologiskt betingat slag" },
  { value: "ÖG2012", title: "Amblyopi, centralnervöst betingad blindhet." },
  { value: "ÖG21", title: "Nutritionella förändringar, hela ögonbulben" },
  {
    value: "ÖG22",
    title: "Degenerativa/dystrofiska förändringar, hela ögonbulben",
  },
  { value: "ÖG221", title: "Phtisis bulbi" },
  { value: "ÖG23", title: "Hormonella förändringar, hela ögonbulben" },
  { value: "ÖG3", title: "Cirkulatoriska förändringar, hela ögonbulben" },
  { value: "ÖG30", title: "Blödningar, hela ögonbulben" },
  {
    value: "ÖG301",
    title: "Intraokulära blödningar omfattande större delen av bulben",
  },
  { value: "ÖG305", title: "Orbitala blödningar" },
  { value: "ÖG31", title: "Ödem, hela ögonbulben" },
  { value: "ÖG32", title: "Infarkter, hela ögonbulben" },
  { value: "ÖG33", title: "Hygrom, hela ögonbulben" },
  { value: "ÖG34", title: "Trombos / emboli, hela ögonbulben" },
  { value: "ÖG35", title: "Arterio-atero-skleros förändringar" },
  { value: "ÖG4", title: "Infekt, infl förändringar, hela ögonbulben" },
  { value: "ÖG40", title: "Specifika infektionssjukdomar, hela ögonbulben" },
  { value: "ÖG401", title: "Spastiska pupillsyndromet hos katt (FeLV)" },
  { value: "ÖG41", title: "Akuta inflammationstillstånd, hela ögonbulben" },
  {
    value: "ÖG411",
    title: "Akut serös / serofibrinös inflammation, hela ögonbulben",
  },
  { value: "ÖG412", title: "Akut purulent inflammation, hela ögonbulben" },
  { value: "ÖG4121", title: "Akut endoftalmit" },
  { value: "ÖG4122", title: "Akut panoftalmit" },
  { value: "ÖG413", title: "Specifika akuta infl tillstånd, hela ögonbulben" },
  { value: "ÖG419", title: "Övriga akuta infl tillstånd, hela ögonbulben" },
  { value: "ÖG4191", title: "Akut  orbital cellulit/flegmon" },
  { value: "ÖG42", title: "Kroniska inflammationstillstånd, hela ögonbulben" },
  { value: "ÖG421", title: "Kronisk serös inflammation, hela ögonbulben" },
  { value: "ÖG422", title: "Purulent inflammation, hela ögonbulben" },
  { value: "ÖG4221", title: "Kronisk endoftalmit" },
  { value: "ÖG4222", title: "Kronisk panoftalmit" },
  { value: "ÖG423", title: "Abscess/fistel, hela ögonbulben" },
  { value: "ÖG4231", title: "Orbital cellulit/abscess, retrobulbär abscess" },
  {
    value: "ÖG424",
    title: "Specifika kroniska infl tillstånd, hela ögonbulben",
  },
  { value: "ÖG429", title: "Övriga kroniska infl tillstånd, hela ögonbulben" },
  { value: "ÖG4299", title: "Postinflammatoriska tillstånd, hela ögonbulben" },
  { value: "ÖG43", title: "Parasitära sjukdomar, hela ögonbulben" },
  { value: "ÖG431", title: "Parasitärt betingade tillstånd i bulben" },
  { value: "ÖG4311", title: "Intraokulär parasit, fritt rörlig i bulben." },
  { value: "ÖG432", title: "Parasitärt betingade tillstånd i orbita" },
  { value: "ÖG5", title: "Immunmedierade tillstånd, hela ögonbulben" },
  { value: "ÖG6", title: "Neoplastiska förändringar, hela ögonbulben" },
  { value: "ÖG61", title: "Neoplasier i ögongloben" },
  {
    value: "ÖG611",
    title: "Neoplasier i bulben, som ej kan hänföras till särskild del",
  },
  { value: "ÖG612", title: "Neoplasier i orbita" },
  {
    value: "ÖG7",
    title: "Trauma, fr kr, lägeförändr, term skad, hela ögonbulben",
  },
  { value: "ÖG71", title: "Traumatiska / mekaniska skador, hela ögonbulben" },
  { value: "ÖG711", title: "Glaukom." },
  { value: "ÖG7111", title: "Primärt glaukom." },
  { value: "ÖG7112", title: "Sekundärt glaukom." },
  { value: "ÖG7114", title: "Buftalmi, hydroftalmi" },
  {
    value: "ÖG712",
    title: "Mucocoele / cysta i orbita, gl zygomaticus-mucocoele",
  },
  { value: "ÖG72", title: "Främmande kroppar, hela ögonbulben" },
  {
    value: "ÖG721",
    title: "Fr kropp i glob, som ej kan hänföras till särskild del",
  },
  { value: "ÖG722", title: "Främmande kropp i orbita" },
  { value: "ÖG73", title: "Lägesförändringar, hela ögonbulben" },
  { value: "ÖG731", title: "Exophthalmus utan etiologisk diagnos" },
  { value: "ÖG732", title: "Enophthalmus utan etiologisk diagnos." },
  { value: "ÖG733", title: "Strabismus (skelögdhet)." },
  {
    value: "ÖG734",
    title: "Framfall av tredje ögonlocket utan etiologisk diagnos.",
  },
  { value: "ÖG74", title: "Termiska skador, hela ögonbulben" },
  { value: "ÖG741", title: "Brännskada, hela ögonbulben" },
  { value: "ÖG742", title: "Köldskada, hela ögonbulben" },
  { value: "ÖG8", title: "Toxiska förändringar, hela ögonbulben" },
  { value: "ÖG9", title: "Idiopatiska, ospecif, multifakt, hela ögonbulben" },
  {
    value: "ÖG91",
    title: "Idiopatiska, ospecif, multifakt tillstånd i ögongloben",
  },
  {
    value: "ÖG95",
    title: "Idiopatiska, ospecif, multifaktillstånd tillstånd i orbita",
  },
  {
    value: "ÖG97",
    title: "Idiopatiska, ospecif, multifakt neuro-oftalmologiska tillst",
  },
  { value: "ÖG971", title: "Horner's syndrom." },
  { value: "ÖG972", title: "Oftalmoplegi" },
  { value: "ÖG9721", title: "Extern oftalmoplegi" },
  {
    value: "ÖG9722",
    title: "Bortfall av specifika yttre ögonmuskelfunktioner,",
  },
  {
    value: "ÖG973",
    title: "Tillstånd rörande pupillreflexer, utan etiologisk diagnos",
  },
  {
    value: "ÖG9731",
    title: "Afferent nedsättning eller bortfall av  pupillreflex",
  },
  {
    value: "ÖG9732",
    title: "Efferent nedsättning eller bortfall av  pupillreflex",
  },
  { value: "ÖG9739", title: "Annat tillstånd rörande pupillreflex" },
];
