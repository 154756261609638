/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { CountryDetailsResponse } from "../models/management/CountryDetailsResponse";
import NumberFormatter from "../util/NumberFormatter";
import CostCalculator from "../util/CostCalculator";
import { strings } from "../common/Strings/Strings";
import { useClinic } from "../contexts/ClinicContext";

interface Props {
  countryDetails?: CountryDetailsResponse;
  netPrice: number;
  vat: number;
}

export const PriceWithVat: React.FC<Props> = ({
  countryDetails,
  netPrice,
  vat,
}: Props) => {
  const { clinic } = useClinic();

  return (
    <div>
      {NumberFormatter.formatPrice(
        CostCalculator.getGrossPrice(
          netPrice,
          vat,
          countryDetails,
          clinic || undefined
        ),
        countryDetails?.currency
      )}

      <div className="text-xs text-gray-500">
        {NumberFormatter.formatPrice(netPrice, countryDetails?.currency)}{" "}
        {strings.plusVat}
      </div>
    </div>
  );
};

export default PriceWithVat;
