/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { strings } from "../../../common/Strings/Strings";
import ClinicMap from "./Appointments/ClinicMap";
import ReservationTypeApi from "../../../api/ReservationTypeApi";
import {
  generateOptions,
  getGeneralError,
  getStreetAndHouseDetails,
  getSunday,
} from "../../../util/helperFunctions";
import { ReservationTypeResponse } from "../../../models/reservationtype/ReservationTypeResponse";
import ClinicApi from "../../../api/ClinicApi";
import logger from "../../../util/logger";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { useUser } from "../../../contexts/UserContext";
import { UserPetResponse } from "../../../models/pet/UserPetResponse";
import UserPetApi from "../../../api/UserPetApi";
import { PageProps } from "../../../models/PageProps";
import { Loader } from "../../../components/Loader";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { ShiftForConsultationPeriodsResponse } from "../../../models/shift/ShiftForConsultationPeriodsResponse";
import ReservationCreationApi from "../../../api/ReservationCreationApi";
import { BasicClinicResponse } from "../../../models/clinic/BasicClinicResponse";
import Button from "../../../components/Button";
import { MapPin } from "../../../common/Icons/MapPin";
import ClinicLogo from "../../../components/Pictures/Clinic/ClinicLogo";
import RadioButtonGroup from "../../../components/ReactHookFormFields/General/RadioButton/RadioButtonGroup";

interface Location {
  lat: number;
  lng: number;
}

interface ReservationTypeForm {
  selectedClinics: BasicClinicResponse[];
  resType: ReservationTypeResponse | undefined;
  videoConsultation: "inPerson" | "video";
}

// amount of weeks consultation periods are checked for
// this constant is used on OwnerCalendarPage as well
const maxUpcomingWeekRange = 2;

const OwnerReservation: React.FC<PageProps> = ({
  setPageLoading,
}: PageProps) => {
  const { user } = useUser();

  const { userPetId } = useParams<"userPetId">();

  const [pet, setPet] = useState<UserPetResponse>();
  const [clinics, setClinics] = useState<BasicClinicResponse[]>([]);

  const [resTypes, setResTypes] = useState<ReservationTypeResponse[]>([]);
  const [hiddenResTypes, setHiddenResTypes] = useState<boolean>(false);

  const [selectedClinic, setSelectedClinic] = useState<BasicClinicResponse>();
  const [selectedResType, setSelectedResType] =
    useState<ReservationTypeResponse>();

  const [loadingShifts, setLoadingShifts] = useState<boolean>(false);
  const [shifts, setShifts] = useState<ShiftForConsultationPeriodsResponse[]>();

  const [position, setPosition] = useState<Location>();
  const [hasPrimaryAddress, setHasPrimaryAddress] = useState<boolean | null>(
    null
  );
  const [primaryLocation, setPrimaryLocation] = useState<Location>();
  const [resTypesLoading, setResTypesLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { control, register, setValue, watch } = useForm<ReservationTypeForm>({
    mode: "onChange", defaultValues: { videoConsultation: "inPerson" }
  });

  useEffect(() => {
    const fetchPet = async () => {
      if (userPetId) {
        try {
          const resp = await UserPetApi.getUserPet(userPetId);
          setPet(resp.data);
        } catch (e) {
          setError(await getGeneralError(e));
        } finally {
          setPageLoading(false);
        }
      } else {
        setPageLoading(false);
      }
    };

    void fetchPet();
  }, [setPageLoading, userPetId]);

  useEffect(() => {
    setHasPrimaryAddress(false);
    if (!user) return;
    const primaryAddress = user.primaryAddress;
    setHasPrimaryAddress(primaryAddress !== undefined);
    const lat = primaryAddress?.latitude;
    const lng = primaryAddress?.longitude;
    if (lat && lng) {
      setPrimaryLocation({ lat, lng });
    }
  }, [user]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setPosition({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    const getClinics = async () => {
      try {
        const response = await ClinicApi.getClinics({
          availableForReservation: true,
        });
        setClinics(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
        logger.error(err);
      }
    };

    void getClinics();
  }, []);

  useEffect(() => {
    if (!selectedResType?.id || !selectedClinic?.id) {
      return;
    }

    const getConsultationPeriods = async (clinicId: string) => {
      const date = new Date();
      const startDate = date;
      const endDate = moment(getSunday(date))
        .set({ hour: 23, minute: 59, second: 59 })
        .add(maxUpcomingWeekRange, "weeks") // this magic constant is also used in OwnerCalendarPage
        .toDate();

      setLoadingShifts(true);
      try {
        const response = await ReservationCreationApi.getConsultationPeriods(
          clinicId,
          startDate,
          endDate,
          selectedResType.id
        );

        setShifts(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
        logger.error(err);
      } finally {
        setLoadingShifts(false);
      }
    };

    void getConsultationPeriods(selectedClinic.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResType]);

  const getReservationTypes = async (clinicId: string) => {
    setHiddenResTypes(true);
    if (clinicId) {
      setResTypesLoading(true);
      try {
        setValue("resType", undefined);
        setSelectedResType(undefined);
        setShifts(undefined);
        const response = await ReservationTypeApi.getReservationTypesOfClinic(
          clinicId,
          true
        );
        setResTypes(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setResTypesLoading(false);
      }
    }
  };

  const onReservationTypeChange = (reservationType: string) => {
    if (reservationType !== "") {
      resTypes.forEach((resType: ReservationTypeResponse) => {
        if (resType.id === reservationType) {
          setSelectedResType(resType);
        }
      });
    }
  };

  const selectClinic = (clinic: BasicClinicResponse) => {
    if (clinic) {
      void getReservationTypes(clinic.id);
      setSelectedClinic(clinic);
      setValue("selectedClinics", [clinic]);
    } else {
      setSelectedClinic(undefined);
      setResTypes([]);
      setHiddenResTypes(false);
    }
  };

  if (hasPrimaryAddress === null) {
    return (
      <main className="main-signed-in bg-transparent">
        <section>
          <div className="px-4 lg:px-6 py-6">
            <LoaderInline />
          </div>
        </section>
      </main>
    );
  }
  if (!hasPrimaryAddress) {
    return (
      <main className="main-signed-in bg-transparent">
        <section>
          <div className="px-4 lg:px-6 py-6">
            <AlertBox
              message={
                <>
                  <div>{strings.warningUserHasNoPrimaryAddress}</div>
                  <Link to="/profile-settings">
                    {strings.warningUserHasNoPrimaryAddressLinkToProfile}
                  </Link>
                </>
              }
              type={AlertType.INFO}
            />
          </div>
        </section>
      </main>
    );
  }
  return (
    <main className="lg:container lg:mx-auto lg:min-h-screen lg:rounded-none lg:border-b-0 pb-14 pt-14 xl:pt-28">
      <section>
        <div className="px-4 py-6">
          <form className="flex flex-wrap md:flex-nowrap md:space-x-4">
            <div className="md:tw-card w-full space-y-6 md:max-w-fit md:p-8">
              <div>
                <h1 className="text-xl font-semibold leading-tight text-zinc-800 dark:text-white lg:text-2xl">
                  {strings.findClinic}
                </h1>
              </div>
              <AlertBox message={error} />
              <CombinedSelect
                name="selectedClinics"
                placeholder={strings.search}
                control={control}
                options={clinics}
                labelKey="name"
                label={strings.search}
                onChange={(selectedClinics) => {
                  if (selectedClinics && selectedClinics[0]) {
                    selectClinic(selectedClinics[0]);
                  }
                }}
                allowNew={false}
              />
              <div className="flex items-center justify-center">
                <span className="w-full border border-gray-200 dark:border-gray-700" />
                <span className="px-4 text-gray-500 dark:text-gray-400">
                  {strings.OR}
                </span>
                <span className="w-full border border-gray-200 dark:border-gray-700" />
              </div>
              <div>
                <div>
                  <div className="pb-2 flex">
                    <MapPin className="mr-1" />
                    {strings.findClinicByMap}
                  </div>
                  <div style={{ height: "500px", width: "100%" }}>
                    <ClinicMap
                      markers={clinics}
                      center={
                        position || primaryLocation || { lat: 12, lng: 32 }
                      }
                      selectClinic={selectClinic}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div hidden={!hiddenResTypes}>
              <div className="tw-card w-full lg:w-96 md:sticky md:top-14 xl:top-28 mt-4 md:mt-0">
                <div className="px-4 py-5 space-y-4">
                  <div>
                    <div className="flex items-center space-x-3">
                      <div className="rounded-full border-2 border-gray-100 w-10 h-10 flex-shrink-0 bg-white bg-no-repeat bg-cover bg-center dark:border-gray-800 dark:bg-gray-700">
                        <ClinicLogo
                          clinicId={selectedClinic?.id}
                          logoPictureId={selectedClinic?.logoPictureId}
                        />
                      </div>
                      <div>
                        <div>{selectedClinic?.name}</div>
                        <div>
                          {`${selectedClinic?.address?.zip} ${selectedClinic?.address?.city},`}{" "}
                          {selectedClinic?.address &&
                            getStreetAndHouseDetails(selectedClinic.address)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {resTypesLoading ? (
                    <LoaderInline />
                  ) : (
                    <>
                      {resTypes.length === 0 ? (
                        <AlertBox
                          message={strings.onlineReservationIsNotAvailable}
                          type={AlertType.INFO}
                        />
                      ) : (
                        <>
                          <Select
                            name="resType"
                            label={strings.reservationTypes}
                            disabledChooseOptionTitle={
                              strings.chooseReservationType
                            }
                            register={register}
                            options={generateOptions(resTypes, "name", "id")}
                            onChange={(e) =>
                              onReservationTypeChange(e.target.value)
                            }
                          />
                          <div hidden={!selectedResType?.isVideoConsultationAllowed}>
                            <RadioButtonGroup
                              control={control}
                              name="videoConsultation"
                              options={[
                                { value: "inPerson", label: strings.inPersonExamination },
                                {
                                  value: "video",
                                  label: strings.onlineVideoConsultation,
                                },
                              ]}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {loadingShifts ? (
                    <LoaderInline />
                  ) : (
                    <AlertBox
                      message={strings.noAvailableShiftsForThisReservationType}
                      hidden={
                        !selectedClinic ||
                        !selectedResType ||
                        !shifts ||
                        shifts.length > 0
                      }
                      type={AlertType.WARNING}
                    />
                  )}
                  <div hidden={resTypes.length === 0 || resTypesLoading}>
                    <Link
                      to={{
                        pathname: "/reservation/calendar",
                        hash: "",
                        search: "",
                      }}
                      state={{
                        clinic: selectedClinic,
                        pet,
                        length: selectedResType
                          ? selectedResType.minutes
                          : null,
                        resType: selectedResType || null,
                        maxUpcomingWeekRange,
                        videoConsultation: watch("videoConsultation"),
                      }}
                    >
                      <Button
                        disabled={
                          loadingShifts ||
                          resTypesLoading ||
                          !selectedClinic ||
                          !selectedResType ||
                          (shifts && shifts.length === 0)
                        }
                      >
                        {strings.addAppointment}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default Loader(OwnerReservation);
