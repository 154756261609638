/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import React from "react";
import Modal from "react-bootstrap/Modal";
import { GeoPharmacy } from "../../../models/pharmacy/GeoPharmacy";
import { CommodityPrescriptionItemFrontend } from "../../../models/ePrescription/local/CommodityPrescriptionItemFrontend";
import ProductDetailSectionCommodity from "../productDetails/ProductDetailSectionCommodity";
import { PackageType } from "../../../models/ePrescription/VARA/PackageType";

interface Props {
  open: boolean;
  close(): void;
  forEdit?: CommodityPrescriptionItemFrontend;
  geoPharmacies: GeoPharmacy[];
  updateCommodityPrescription(u: CommodityPrescriptionItemFrontend): void;
  packageTypes: PackageType[];
}

const CommodityUpdateModal: React.FC<Props> = ({
  open,
  close,
  forEdit,
  geoPharmacies,
  updateCommodityPrescription,
  packageTypes
}) => {
  return (
    <Modal show={open} onHide={close} animation={false} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {forEdit && forEdit.product && forEdit.product.name
            ? forEdit.product.name
            : ""}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body bsPrefix="custom-modal-body">
        <ProductDetailSectionCommodity
          forEdit={forEdit}
          addPrescription={updateCommodityPrescription}
          packageTypes={packageTypes}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CommodityUpdateModal;
