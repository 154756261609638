/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../Strings/Strings";
import logger from "../../../../../util/logger";
import FastLoginApi from "../../../../../api/FastLoginApi";
import { setUpAuthToken } from "../../../../../util/axiosSetup";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { useUser } from "../../../../../contexts/UserContext";
import {
  getLoggedInUsers,
  LoggedInUser,
  setEmailForActiveUser,
  setProfilePictureIdForActiveUser,
  updateLoggedInUser,
} from "../../../../../util/LocalStorageVariables";
import ChangeUserModalRow from "./ChangeUserModalRow";
import PinNumberForm from "./PinNumberForm";
import Modal from "../../../../../components/Modal/Modal";

interface Props {
  showModal: boolean;
  closeModal: () => void;
}

const ChangeUserModal: React.FC<Props> = ({ showModal, closeModal }: Props) => {
  const { user, fetchUser } = useUser();
  const [users, setUsers] = useState<LoggedInUser[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!showModal) {
      return;
    }

    const refreshFastLoginToken = async () => {
      try {
        const response = await FastLoginApi.getFastLoginToken();
        updateLoggedInUser((u: LoggedInUser) => ({
          ...u,
          fastLoginToken: response.data.token,
        }));
      } catch (e) {
        logger.error(e);
      }
    };

    setUsers(getLoggedInUsers());

    /* Refresh fast login token for active user before account change */
    void refreshFastLoginToken();

    setError(null);
    setSelectedUserId(undefined);
  }, [showModal]);

  useEffect(() => {
    if (!showModal) {
      return;
    }

    const primaryEmail = user.emails.find((e) => e.contactType === "PRIMARY");
    setEmailForActiveUser(primaryEmail?.value ?? "");

    setProfilePictureIdForActiveUser(user.profilePicture ?? "");
  }, [showModal, user.emails, user.profilePicture]);

  const submitSwitchAccountForm = async (pinCode: string) => {
    const selectedUser = users.find((u) => u.userId === selectedUserId);

    if (!selectedUser) {
      return;
    }

    setError(null);
    setLoading(true);

    try {
      const response = await FastLoginApi.loginWithPinCode({
        pinCode,
        token: selectedUser.fastLoginToken || "",
      });
      setUpAuthToken(response.data);
      fetchUser().then(() => {
        window.location.replace("/");
      });
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={closeModal} id="changeUserModal" show={showModal}>
      <Modal.Header title={strings.switchAccount} />
      <Modal.Body noSpacing>
        <ul className="py-4 font-normal text-gray-700 dark:text-gray-400">
          {users.map((u) => (
            <ChangeUserModalRow
              key={u.userId}
              isCurrentUser={
                selectedUserId === u.userId ||
                (!selectedUserId && u.userId === user.userId)
              }
              selectUser={() => {
                setSelectedUserId(u.userId);
              }}
              user={u}
            />
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer hidden={!selectedUserId || selectedUserId === user.userId}>
        <PinNumberForm
          submitSwitchAccountForm={submitSwitchAccountForm}
          error={error}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeUserModal;
