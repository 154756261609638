import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { Card } from "../../../components/Cards/Card";
import { SiteManagerPage } from "../SiteManagerPage";
import { PageProps } from "../../../models/PageProps";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import { usePager } from "../../../hooks/hooks";
import { getGeneralError } from "../../../util/helperFunctions";
import VideoConsultationApi from "../../../api/VideoConsultationApi";
import { VideoChatUser } from "../../../models/videoConsultation/VideoChatRoomUsers";
import {
  convertObjectToMap,
  VideoChatRoomsMap,
} from "../../../models/videoConsultation/VideoChatRooms";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import { calculateDuration } from "../../VideoConsultation/Utils";
import MediaDevicesState from "./MediaDevicesState";
import LoaderInline from "../../../components/LoaderInline";

const POLL_INTERVAL = 1000; // 1 seconds

const VideoChatMonitor: React.FC<PageProps> = ({
  setPageLoading,
}: PageProps) => {
  const [error, setError] = useState<string | null>(null);
  const [rooms, setRooms] = useState<VideoChatRoomsMap>(new Map());

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await VideoConsultationApi.getRooms();
        setRooms(convertObjectToMap(response.data));
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setPageLoading(false);
      }
    };

    // Set the page loading state
    setPageLoading(true);

    // Fetch the initial data
    void fetchRooms();

    // Poll the server using the POLL_INTERVAL
    const intervalId = setInterval(fetchRooms, POLL_INTERVAL);

    // Cleanup on unmount
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pager = usePager(10);
  const roomArray = Array.from(rooms.entries());

  return (
    <main>
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <AlertBox message={error} />
          {!error && roomArray.length === 0 && (
            <AlertBox
              closeAble={false}
              message={strings.noActiveRoomsMessage}
              type={AlertType.INFO}
            />
          )}
          <div className="flex flex-wrap items-center">
            {roomArray.map(([roomId, room]) => (
              <Card
                key={roomId}
                size="max"
                title={`${strings.roomId}: ${roomId}`}
                type="simple"
              >
                <PageableTable
                  pager={pager}
                  isLoading={false}
                  hidden={room.users.length === 0}
                >
                  <PageableTableHead>
                    <tr>
                      <th className="text-left tw-th">
                        {strings.videoChatUser}
                      </th>
                      <th className="text-left tw-th">{strings.joinDate}</th>
                      <th className="text-left tw-th">
                        {strings.chatDuration}
                      </th>
                      <th className="text-left tw-th">
                        {" "}
                        {strings.stateOfMediaDevices}
                      </th>
                    </tr>
                  </PageableTableHead>
                  <PageableTableBody>
                    <>
                      {room.users.map((user: VideoChatUser) => (
                        <tr key={user.id}>
                          <td className="px-4 py-4 text-sm whitespace-nowrap">
                            <div className="flex flex-row items-center">
                              <div className="w-10 h-10 mr-3">
                                <UserProfilePicture userId={user.id} />
                              </div>
                              <p>{user.name}</p>
                            </div>
                          </td>
                          <td className="px-4 py-4 text-sm whitespace-nowrap">
                            {user.joined
                              ? moment(user.joined).format(
                                  dateAndTime.momentDateTimeFormat
                                )
                              : "-"}
                          </td>
                          <td className="px-4 py-4 text-sm whitespace-nowrap">
                            {user.joined ? calculateDuration(user.joined) : "-"}
                          </td>
                          <td className="px-4 py-4 text-sm whitespace-nowrap">
                            <MediaDevicesState
                              camEnabled={user.mediaConfig?.camEnabled || false}
                              camMissing={user.mediaConfig?.camMissing || false}
                              camError={user.mediaConfig?.camError || false}
                              micEnabled={user.mediaConfig?.micEnabled || false}
                              micMissing={user.mediaConfig?.micMissing || false}
                              micError={user.mediaConfig?.micError || false}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  </PageableTableBody>
                </PageableTable>
                {(room.isEndingCall || room.users.length === 0) && (
                  <>
                    <AlertBox
                      closeAble={false}
                      message={
                        <div className="flex flex-row gap-2">
                          <p>{strings.roomIsBeingDeleted}</p>
                          <LoaderInline />
                        </div>
                      }
                      type={AlertType.INFO}
                    />
                  </>
                )}
              </Card>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default SiteManagerPage(
  VideoChatMonitor,
  strings.videoConsultationRoomMonitor
);
