/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";

interface Props<T> {
  currentTabKey: T;
  setTab(newTab: T): void;
  tabs: Array<{ title: string; tab: T }>;
}

const Tabs: React.FC<Props<any>> = ({
  currentTabKey,
  setTab,
  tabs,
}: Props<any>) => (
  <div className="border border-gray-200 rounded-md bg-gray-50 !p-2 text-center text-md font-medium cursor-pointer text-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300">
    <ul className="flex flex-wrap -mb-px">
      {tabs.map(({ title, tab }, index) => {
        const current = currentTabKey === tab;
        return (
          <li
            className={current ? "tw-tab-active" : "tw-tab-inactive"}
            key={index}
            onClick={() => {
              setTab(tab);
            }}
          >
            {title}
          </li>
        );
      })}
    </ul>
  </div>
);

export default Tabs;
