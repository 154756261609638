/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { PetOwnerResponse } from "../../models/pet/PetOwnerResponse";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";

interface Props {
  petOwner: PetOwnerResponse;
}

const PetOwnerInfo = ({ petOwner }: Props): ReactElement => (
  <div className="flex items-start">
    <div style={{ width: "40px", height: "40px", marginRight: "12px" }}>
      <UserProfilePicture userId={petOwner?.userDetails?.id} />
    </div>
    <div>
      <Link to={`/pet-owner-profile/${petOwner?.petOwnerDetails.id}`}>
        {petOwner?.petOwnerDetails.fullName}
      </Link>
      <div>{petOwner?.petOwnerDetails.personalId}</div>
      <div>
        {petOwner?.petOwnerDetails?.phones[0] &&
          petOwner?.petOwnerDetails?.phones[0].value}
      </div>
      <div>
        {petOwner?.petOwnerDetails?.emails?.[0]?.value}
      </div>
    </div>
  </div>
);

export default PetOwnerInfo;
