/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { PageProps } from "../../../models/PageProps";
import { Loader } from "../../../components/Loader";
import UserPetApi from "../../../api/UserPetApi";
import { getGeneralError, howOld } from "../../../util/helperFunctions";
import {
  getStringFromEnumsOrReturn,
  petGenders,
  petSpecies,
  strings,
} from "../../../common/Strings/Strings";
import { PetDetailsResponse } from "../../../models/pet/PetDetailsResponse";
import UserPetHistory from "../History/UserPetHistory/UserPetHistory";
import PetProfilePicture from "../../../components/Pictures/Pet/PetProfilePicture";
import { Card } from "../../../components/Cards/Card";
import AlertBox from "../../../components/AlertBox";
import UserPetListRowActions from "../../PetOwner/UserPetOwner/Pets/UserPetListRowActions";
import Tooltip from "../../../components/Tooltip";
import { Heart } from "../../../common/Icons/Heart";

const UserPetProfile: React.FC<PageProps> = ({ setPageLoading }: PageProps) => {
  const [userPet, setUserPet] = useState<PetDetailsResponse>();
  const [error, setError] = useState<string | null>(null);
  const params = useParams<"petId">();
  const petId = params.petId;

  useEffect(() => {
    const fetchPet = async (id?: string) => {
      if (id) {
        try {
          const resp = await UserPetApi.getUserPet(id);
          setUserPet(resp.data.userPetDetails);
        } catch (e) {
          setError(await getGeneralError(e));
        } finally {
          setPageLoading(false);
        }
      }
    };

    void fetchPet(petId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petId]);

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div className="flex flex-wrap items-center">
            <div style={{ width: "100px", height: "100px" }}>
              <PetProfilePicture
                species={userPet?.species}
                petId={userPet?.id}
                profilePictureId={userPet?.profilePicture}
              />
            </div>
            <div className="text-xl font-bold lg:text-2xl text-zinc-800 dark:text-white ml-3 flex items-center">
              {userPet?.name}
              {userPet?.deceased ? (
                <div>
                  <Tooltip content={strings.deceased}>
                    <Heart className="ml-2" variant="solid" />
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="ml-auto">
              <UserPetListRowActions petId={userPet?.id} />
            </div>
          </div>
          <Card size="max" title={strings.basicInfo} type="simple">
            <div className="row pb-4">
              <div className="col-6 col-md-2 py-1">
                <div>{strings.species}</div>
                <div className="bold">
                  {userPet?.species && petSpecies[userPet?.species]}
                </div>
              </div>
              <div className="col-6 col-md-2 py-1">
                <div>{strings.breed}</div>
                <div className="bold">{userPet?.breed}</div>
              </div>
              <div className="col-6 col-md-2 py-1">
                <div>{strings.gender}</div>
                <div className="bold">
                  {userPet?.gender && petGenders[userPet?.gender]}
                </div>
              </div>
              <div className="col-6 col-md-2 py-1">
                <div>{strings.color}</div>
                <div className="bold">{userPet?.color}</div>
              </div>
              <div className="col-6 col-md-2 py-1">
                <div>{strings.dateOfBirth}</div>
                <div className="bold">
                  {userPet?.dateOfBirth ? (
                    <>
                      {moment(userPet.dateOfBirth).format("LL")}{" "}
                      <div>
                        ({howOld(moment(userPet.dateOfBirth), "year")}{" "}
                        {strings.years}{" "}
                        {howOld(moment(userPet.dateOfBirth), "month")}{" "}
                        {strings.monthCnt})
                      </div>
                    </>
                  ) : (
                    <>-</>
                  )}
                </div>
              </div>
              <div className="col-6 col-md-2 py-1">
                <div>{strings.chipNumber}</div>
                <div className="bold">{userPet?.chipNumber}</div>
              </div>
              <div className="col-6 col-md-2 py-1">
                <div>{strings.tattooNumber}</div>
                <div className="bold">{userPet?.tattooNumber}</div>
              </div>
              <div className="col-6 col-md-2 py-1">
                <div>{strings.passportNumber}</div>
                <div className="bold">{userPet?.passportNumber}</div>
              </div>
              <div className="col-6 col-md-2 py-1">
                <div>{strings.insuranceCompany}</div>
                <div className="bold">{userPet?.insuranceCompany?.name}</div>
              </div>
              <div className="col-6 col-md-2 py-1">
                <div>{strings.insuranceNumber}</div>
                <div className="bold">{userPet?.insuranceNumber}</div>
              </div>
              <div className="col-6 col-md-2 py-1">
                <div>{strings.chronicDiseases}</div>
                <div>
                  {userPet?.chronicDiseases?.map((disease) => (
                    <span className="badge badge-pill badge-danger mr-2 mb-2">
                      {getStringFromEnumsOrReturn(disease)}
                    </span>
                  ))}
                </div>
              </div>
              <div className="col-6 col-md-4 py-1">
                <div>{strings.comment}</div>
                <div className="bold">{userPet?.comment}</div>
              </div>
            </div>
            <AlertBox message={error} />
          </Card>
          <Card size="max" title={strings.history} type="simple">
            <UserPetHistory pet={userPet} />
          </Card>
        </div>
      </section>
    </main>
  );
};

export default Loader(UserPetProfile);
