/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import { getGeneralError } from "../../util/helperFunctions";
import GlobalSettingsApi from "../../api/GlobalSettingsApi";
import LoaderInline from "../../components/LoaderInline";
import MaintenanceSettings from "./MaintenanceSettings";
import Switch from "../../components/ReactHookFormFields/General/Switch";
import AlertBox from "../../components/AlertBox";

interface Props {
  isActiveTab: boolean;
}

interface GlobalSettingsForm {
  unemployedWorkerLoginEnabled: boolean;
}

const GlobalSettings: React.FC<Props> = ({ isActiveTab }: Props) => {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();

  const {
    control,
    reset,
    getValues,
    handleSubmit,
    formState: { isValid, isDirty, errors },
  } = useForm<GlobalSettingsForm>({
    mode: "onChange",
  });

  useEffect(() => {
    if (!isActiveTab) return;

    const getSettings = async () => {
      try {
        const response = await GlobalSettingsApi.getSettings();
        reset(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoaded(true);
      }
    };

    void getSettings();
  }, [isActiveTab, reset]);

  const saveSettings = async (data: GlobalSettingsForm) => {
    setSaving(true);

    try {
      const response = await GlobalSettingsApi.updateSettings(data);
      reset(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <LoaderInline className="my-3" hidden={isLoaded} />
      <div hidden={!isLoaded} className="container pb-4">
        <div className="row">
          <div className="col-sm-2" />
          <div className="col-sm-8">
            <div className="card">
              <div className="card-body">
                <div className="block-label p-3">{strings.globalSettings}</div>
                <form onSubmit={handleSubmit(saveSettings)}>
                  <div className="row m-3 p-0">
                    <div className="col-6 m-0 p-0">
                      <div className="paragraph">{`${strings.unemployedWorkerLoginEnabled}`}</div>
                    </div>
                    <div className="col-6">
                      <Switch
                        className="float-right"
                        name="unemployedWorkerLoginEnabled"
                        control={control}
                        value={getValues("unemployedWorkerLoginEnabled")}
                        error={errors.unemployedWorkerLoginEnabled}
                      />
                    </div>
                  </div>

                  <AlertBox message={error} className="mb-3" />

                  <div className="d-flex flex-row-reverse hr-on-top p-3">
                    {isSaving ? (
                      <LoaderInline />
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={!isValid || !isDirty}
                      >
                        {strings.save}
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div hidden={!isLoaded}>
        <MaintenanceSettings />
      </div>
    </>
  );
};

export default GlobalSettings;
