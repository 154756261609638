/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import MultiplicationFieldsComponent from "../../InputFieldsSimple/MultiplicationFieldsComponent";
import { InventoryItemUnit } from "../../../models/inventory/item/InventoryItemUnit";

interface FieldProps {
  control: Control<any>;
  disabled?: boolean;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  label?: string;
  name?: string;
  readOnly?: boolean;
  required?: boolean;
  unit?: InventoryItemUnit;
}

const MultiplicationFields = ({
  control,
  disabled,
  name,
  fieldOptions,
  label,
  readOnly,
  required,
  unit
}: FieldProps): ReactElement => {
  return (
    <Controller
      control={control}
      name={name || "multiplicationResult"}
      rules={fieldOptions}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <MultiplicationFieldsComponent
          disabled={disabled}
          error={error}
          label={label}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
          unit={unit}
          value={value}
        />
      )}
    />
  );
};

export default MultiplicationFields;
