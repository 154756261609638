/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import { getGeneralError } from "../../util/helperFunctions";
import AlertBox from "../../components/AlertBox";
import { Card } from "../../components/Cards/Card";
import { ClinicFeatureResponse } from "../../models/management/ClinicFeatureResponse";
import CountryApi from "../../api/CountryApi";
import { ClinicFeatureRequest } from "../../models/management/ClinicFeatureRequest";
import { Button } from "react-bootstrap";
import CheckBox from "../../components/ReactHookFormFields/General/CheckBox";

interface Props {
  isActiveTab: boolean;
  countryCode: string;
}

interface ClinicFeaturesForm {
  [key: string]: boolean;
}

export default function ClinicFeaturesSection(props: Props): ReactElement {
  const { isActiveTab, countryCode } = props;
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [clinicFeatures, setClinicFeatures] = useState<ClinicFeatureResponse[]>(
    []
  );

  const clinicFeaturesForm = useForm<ClinicFeaturesForm>({
    mode: "onChange",
  });
  const { register, handleSubmit } = clinicFeaturesForm;

  const updateFormValues = (clinicFeatures: ClinicFeatureResponse[]) => {
    const values: any = [];
    clinicFeatures.forEach((i) => (values[i.feature] = i.enabled));
    return values;
  };

  const getClinicFeatures = async () => {
    setLoading(true);
    try {
      const resp = await CountryApi.getClinicFeatures(countryCode);
      clinicFeaturesForm.reset(updateFormValues(resp.data));
      setClinicFeatures(resp.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getClinicFeatures();
    /* eslint-disable-next-line */
  }, [isActiveTab, countryCode]);

  const save = async (data: ClinicFeaturesForm) => {
    try {
      setLoading(true);
      const request: ClinicFeatureRequest[] = clinicFeatures.map((i) => ({
        feature: i.feature,
        enabled: data[i.feature] || false,
      }));
      const resp = await CountryApi.updateClinicFeatures(countryCode, request);
      clinicFeaturesForm.reset(updateFormValues(resp.data));
      setClinicFeatures(resp.data);
    } catch (e) {
      const err: any = e;
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center md:mt-4">
        <Card
          loading={isLoading}
          type="simple"
          size="lg"
          title={strings.clinicFeatures}
        >
          <AlertBox message={error} className="mb-2" />
          <form onSubmit={handleSubmit(save)}>
            <div className="tw-table-container">
              <table className="tw-table">
                <thead className="tw-thead">
                  <tr>
                    <th className="tw-th">{strings.feature}</th>
                  </tr>
                </thead>
                <tbody className="tw-tbody">
                  {clinicFeatures.map((feature) => (
                    <tr key={feature.feature}>
                      <td className="px-4 py-3">
                        <CheckBox
                          register={register}
                          name={feature.feature}
                          label={feature.feature}
                          defaultChecked={feature.enabled}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="ml-auto mt-3">
              <Button type="submit">{strings.save}</Button>
            </div>
          </form>
        </Card>
      </div>
    </>
  );
}
