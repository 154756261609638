/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { ClinicPetResponse } from "../../../models/pet/ClinicPetResponse";
import Paging from "../../../components/Paging";
import PageSizeSelect from "../../../components/PageSizeSelect";
import { useDebouncedState } from "../../../hooks/hooks";
import PetListRow from "../UserPetOwner/Pets/PetListRow";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import EmptyListText from "../../../components/EmptyListText";

interface Props {
  petOwner?: PetOwnerResponse;
  pets: ClinicPetResponse[];
  showPageSection?: boolean;
}

const PetList = ({
  petOwner,
  pets,
  showPageSection = true,
}: Props): ReactElement => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filteredPets, setFilteredPets] = useState<ClinicPetResponse[]>(pets);
  const petNameSearchQuery = useDebouncedState("", 400);

  useEffect(() => {
    setFilteredPets(pets);
  }, [pets]);

  useEffect(() => {
    const query = petNameSearchQuery.value.toLowerCase();
    const newPets = pets.filter(
      (p) => p.clinicPetDetails?.name?.toLowerCase().includes(query)
    );
    setFilteredPets(newPets);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petNameSearchQuery.value]);

  if (pets && pets.length === 0) {
    return (
      <div className="tw-card p-3 mt-6">
        <EmptyListText />
      </div>
    );
  }

  return (
    <>
      <div className="my-3 tw-card divide-y dark:divide-gray-700">
        {filteredPets
          .slice(page * pageSize, (page + 1) * pageSize)
          .map(
            ({ clinicPetDetails: pet, userPetDetails }: ClinicPetResponse) => (
              <PetListRow
                key={pet.id}
                isUserSide={false}
                petDetails={pet}
                petOwner={petOwner}
                userPet={userPetDetails}
              />
            )
          )}
      </div>
      {showPageSection ? (
        <div className="flex">
          <div className="flex items-center">
            <PageSizeSelect
              pageSize={pageSize}
              setPageSize={(newPageSize: number) => {
                setPageSize(newPageSize);
              }}
              totalPages={Math.ceil(filteredPets.length / pageSize)}
              totalResults={filteredPets.length}
            />
          </div>
          <div className="ml-auto">
            <Paging
              totalPages={Math.ceil(filteredPets.length / pageSize)}
              currentPage={page}
              selectPage={(newPageNumber: number) => {
                setPage(newPageNumber);
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PetList;
