/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const prescriptionMethodsSv = {
  AURI: "Användning i örat",
  BIDI: "Näbbdoppning",
  BU: "Buckal användning",
  CUTN: "Kutan användning - på huden",
  DENT: "Dental användning",
  DIPP: "Badning",
  DW: "Användning i dricksvatten",
  GAEN: "Gastroenteral användning",
  GING: "Användning på tandköttet",
  IH: "Anv. för inhalation",
  INAC: "Intra-artikulär användning",
  INBU: "Intrabursal användning",
  INCD: "Intrakardiell användning",
  INFE: "Användning i foder",
  MOUTH: "I munnen",
  NASA: "Nasal användning",
  OCUL: "Okulär användning",
  ORAL: "Oral användning",
  ORMU: "Användning i munhålan",
  PDON: "Periodontal användning",
  PO: "Per oral",
  POON: "Pour-on",
  PR: "Per rectal",
  RECT: "Rektal användning",
  SL: "Sublingual användning",
  SMUC: "Submukosal användning",
  SPON: "Spot-on",
  SUCO: "Användning i konjunktivalsäcken",
  SUCU: "Subkutan användning",
  TEAT: "Användning på spenar",
  TO: "Topical",
  TODR: "Att strös på fodret",
  VAGI: "Vaginal användning",
  WABO: "Behandling via vatten"
};