/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import { petNotificationIcon } from "../../../../assets/AssetConfig";
import UserApi from "../../../../api/UserApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import AlertBox from "../../../../components/AlertBox";
import { Card } from "../../../../components/Cards/Card";
import Button from "../../../../components/Button";
import { BellAlert } from "../../../../common/Icons/BellAlert";

interface Props {
  onNextStep(skip: boolean): void;
  skippable?: boolean;
}

const NotificationsSetup = ({
  onNextStep,
  skippable = false,
}: Props): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    setLoading(true);

    try {
      await UserApi.setupUserProfilePreferences({
        receiveSMSNotifications: true,
      });
      onNextStep(false);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      onAction={skippable ? () => onNextStep(true) : undefined}
      title={strings.allowNotifications}
    >
      <div className="flex items-center space-x-2">
        <BellAlert variant="outline" />
        <div className="font-semibold">{strings.allowNotifications}</div>
      </div>
      <div>
        <p>{strings.notificationInfo}</p>
      </div>
      <div className="flex items-center justify-center">
        <img
          src={petNotificationIcon}
          className="w-40"
          alt="Allow notifications"
        />
      </div>
      <AlertBox message={error} />
      <Button disabled={loading} onClick={onSubmit}>
        {strings.allowNotificationsButton}
      </Button>
    </Card>
  );
};

export default NotificationsSetup;
