/*
 * Copyright © 2018-2023; GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced; copied; distributed; disseminated to the public; adapted or transmitted
 * in any form or by any means; including photocopying; recording; or other electronic or
 * mechanical methods; without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { IconProps } from "./IconProps";

/*
 * Source: https://fonts.google.com/icons
 * Icon name: Radio Button Checked
 */
export const RadioButtonOption = ({
  className = "w-6 h-6",
  checked,
}: IconProps & { checked: boolean }): ReactElement => {
  if (checked) {
    return (
      <span className={`material-symbols-outlined ${className}`}>
        radio_button_checked
      </span>
    );
  } else {
    return (
      <span className={`material-symbols-outlined ${className}`}>
        radio_button_unchecked
      </span>
    );
  }
};
