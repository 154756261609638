/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Control, RegisterOptions, useController } from "react-hook-form";
import React, { ReactElement, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Colors } from "../../../models/Colors";
import {
  dateAndTime,
  findLocale,
  strings,
} from "../../../common/Strings/Strings";
import Tooltip from "../../Tooltip";
import { InfoFilled } from "../../../common/Icons/InfoFilled";

type Detail = "month" | "year" | "decade" | "century";
export type DatePickerValue = Date | null | [Date | null, Date | null];

interface FieldProps {
  control: Control<any>;
  name: string;
  classInput?: string;
  classLabel?: string;
  className?: string;
  defaultValue?: Date | Date[] | null;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  format?: string;
  label?: string;
  labelIcon?: string | ReactElement;
  labelOff?: boolean;
  max?: string;
  maxDetail?: Detail;
  min?: string;
  onChange?(e: DatePickerValue): void;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  showRequired?: boolean;
  suffix?: string;
  toolTipText?: string | ReactElement;
  width?: string;
}

const DatePickerInput = ({
  control,
  name: n,
  classInput,
  classLabel,
  className,
  defaultValue,
  fieldOptions,
  format,
  label,
  labelIcon,
  labelOff,
  max,
  maxDetail,
  min,
  onChange: customOnChange,
  optional,
  readOnly,
  required,
  showRequired,
  suffix,
  toolTipText,
  width,
}: FieldProps): ReactElement => {
  const [space, setSpace] = useState<number>(0);
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController({ name: n, control, rules: registerOptions, defaultValue });

  useEffect(() => {
    if (suffix) {
      if (toolTipText) {
        setSpace(2);
      } else {
        setSpace(1);
      }
    } else if (toolTipText) {
      setSpace(1);
    }
  }, [suffix, toolTipText]);

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  return (
    <div
      className={`${className || ""} ${error ? "has-error" : ""}`}
      style={{ width }}
    >
      {labelOff ? (
        <></>
      ) : (
        <label htmlFor={name} className={classLabel}>
          {labelIcon ? (
            typeof labelIcon === "string" ? (
              <i className={labelIcon} />
            ) : (
              <span style={{ verticalAlign: "text-bottom" }}>{labelIcon}</span>
            )
          ) : (
            ""
          )}{" "}
          {label}
          {showRequired ? " *" : ""}
          {optional ? (
            <span style={{ color: Colors.INPUTBORDER }}>
              {" "}
              ({strings.optional})
            </span>
          ) : (
            <></>
          )}
        </label>
      )}
      <div className="relative w-full">
        <DatePicker
          name={name}
          className={
            classInput ||
            `tw-input ${space === 1 && "pr-10"} ${space === 2 && "pr-20"}`
          }
          calendarIcon={null}
          clearIcon={null}
          disabled={readOnly}
          format={format || dateAndTime.dateFormat}
          locale={findLocale()}
          maxDate={max ? new Date(max) : new Date("2999-12-31")}
          maxDetail={maxDetail}
          minDate={min ? new Date(min) : new Date("1000-01-01")}
          minDetail="year"
          onChange={(e: DatePickerValue) => {
            onChange(e);

            if (customOnChange) {
              customOnChange(e);
            }
          }}
          value={value ? new Date(value) : undefined}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-2">
          {suffix ? (
            <div>
              <span>{suffix}</span>
            </div>
          ) : (
            <></>
          )}
          {toolTipText ? (
            <div>
              <Tooltip content={toolTipText} placement="top">
                <span>
                  <InfoFilled className="text-sky-500" />
                </span>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <p>{error && error.message}</p>
    </div>
  );
};

export default DatePickerInput;

DatePickerInput.defaultProps = {
  classInput: undefined,
  classLabel: undefined,
  className: undefined,
  defaultValue: undefined,
  fieldOptions: undefined,
  format: undefined,
  label: undefined,
  labelIcon: undefined,
  labelOff: false,
  max: undefined,
  maxDetail: undefined,
  min: undefined,
  onChange: undefined,
  optional: false,
  readOnly: false,
  required: false,
  showRequired: false,
  suffix: false,
  toolTipText: undefined,
  width: undefined,
};
