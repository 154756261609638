/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  petGenders,
  petSpecies,
  strings,
} from "../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { PersonDetailsResponse } from "../../../models/contact/PersonDetailsResponse";
import { PetDetailsResponse } from "../../../models/pet/PetDetailsResponse";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import SimpleTextarea from "../../../components/InputFieldsSimple/SimpleTextarea";
import CloseButton from "../../../components/CloseButton";
import PetTransferApi from "../../../api/PetTransferApi";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import { getGeneralError } from "../../../util/helperFunctions";
import PetOwnerSearchSection from "./PetOwnerSearchSection";
import { Pencil } from "../../../common/Icons/Pencil";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import PetProfilePicture from "../../../components/Pictures/Pet/PetProfilePicture";
import HorizontalLine from "../../../components/HorizontalLine";

interface Props {
  close(): void;
  isOpen: boolean;
  pet?: PetDetailsResponse;
  petOwner?: PersonDetailsResponse;
  ownerPictureUserId?: string;
  userPetId?: string;
}

const ChangeOwnerModal = ({
  close,
  isOpen,
  pet,
  petOwner,
  ownerPictureUserId,
  userPetId,
}: Props): ReactElement => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [noteVisible, setNoteVisible] = useState<boolean>(false);
  const [selectedOwner, setSelectedOwner] = useState<PetOwnerResponse>();
  const [note, setNote] = useState<string>("");
  const navigate = useNavigate();

  const onClose = () => {
    if (isSuccess) {
      navigate(`/pet-owner-profile/${petOwner?.id}`);
    } else {
      setNote("");
      setNoteVisible(false);
      setError(undefined);
      close();
    }
  };

  const onSubmit = async () => {
    if (!pet || !selectedOwner) {
      return;
    }

    setSubmitting(true);

    if (userPetId) {
      try {
        await PetTransferApi.userTransfer(pet.id, {
          newOwnerUserId: selectedOwner?.userDetails?.id,
          newOwnerEmail: selectedOwner?.petOwnerDetails?.emails[0].value,
          note,
        });
        setIsSuccess(true);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await PetTransferApi.clinicTransfer(pet.id, {
          newOwnerId: selectedOwner?.petOwnerDetails.id,
        });
        setIsSuccess(true);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Modal handleClose={onClose} show={isOpen}>
      <Modal.Header title={strings.changeOwner} />
      <Modal.Body>
        <div className="pb-3 space-y-3">
          <b>{strings.pet}</b>
          <div className="flex items-center">
            <div className="h-20 w-20 mr-3">
              <PetProfilePicture
                border
                borderWidth="3px"
                petId={userPetId}
                species={pet?.species}
              />
            </div>
            <div>
              <b>{pet?.name}</b>
              <div>
                {pet?.species && petSpecies[pet?.species]},{" "}
                {pet?.gender && petGenders[pet?.gender]}
                {pet?.color && `, ${pet?.color}`}, {pet?.breed}
              </div>
            </div>
          </div>
        </div>
        <HorizontalLine />
        <div className="pb-3 space-y-3">
          <b>{strings.currentPetOwner}</b>
          <div className="flex items-center">
            <div className="h-20 w-20 mr-3">
              <UserProfilePicture
                border
                borderWidth="3px"
                userId={ownerPictureUserId}
              />
            </div>
            <div>
              <b>{petOwner?.fullName}</b>
              <div>{petOwner?.personalId}</div>
            </div>
          </div>
        </div>
        <HorizontalLine />
        <div className="pb-3 space-y-3">
          <div className="flex">
            <b>{strings.newOwner}</b>
            {selectedOwner ? (
              <Button
                onClick={() => {
                  setSelectedOwner(undefined);
                }}
                variant="icon"
              >
                <Pencil className="w-6 h-6" />
              </Button>
            ) : (
              <></>
            )}
          </div>
          <div className="flex items-center">
            {selectedOwner ? (
              <>
                <div className="h-20 w-20 mr-3">
                  <UserProfilePicture
                    border
                    borderWidth="3px"
                    userId={selectedOwner?.userDetails?.id}
                  />
                </div>
                <div>
                  <b>{selectedOwner?.petOwnerDetails.fullName}</b>
                  <div>{selectedOwner?.petOwnerDetails.personalId}</div>
                  <div>{selectedOwner?.userDetails?.emails[0]?.value}</div>
                </div>
              </>
            ) : (
              <PetOwnerSearchSection onSelectOwner={setSelectedOwner} />
            )}
          </div>
        </div>
        <HorizontalLine hidden={!userPetId} />
        <div hidden={!userPetId}>
          {noteVisible ? (
            <SimpleTextarea
              label={strings.note}
              name="noteToOwner"
              minRows={3}
              maxLength={100}
              onChange={(value: string) => {
                setNote(value);
              }}
              value=""
            />
          ) : (
            <Button
              onClick={() => {
                setNoteVisible(true);
              }}
              variant="link"
            >
              <div className="flex items-center">
                <PlusIcon className="w-6 h-6" />
                <div>{strings.addNote}</div>
              </div>
            </Button>
          )}
        </div>
        <AlertBox className="my-5" message={error} />
        <AlertBox
          className="my-5"
          closeAble={false}
          hidden={!isSuccess}
          message={strings.savedSuccessfully}
          type={AlertType.SUCCESS}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={submitting}
          onClick={onSubmit}
          variant="danger"
        >
          {strings.changeOwner}
        </Button>
        <CloseButton disabled={submitting} onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeOwnerModal;
