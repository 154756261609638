/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { TodoResponse } from "../../models/todo/TodoResponse";
import { Colors } from "../../models/Colors";
import {
  dateAndTime,
  getStringFromEnumsOrReturn,
  petGenders,
  petSpecies,
  strings,
} from "../../common/Strings/Strings";
import TodoApi from "../../api/TodoApi";
import { useClinic } from "../../contexts/ClinicContext";
import { UpdateTodoRequest } from "../../models/todo/UpdateTodoRequest";
import LoaderInline from "../../components/LoaderInline";
import { getGeneralError } from "../../util/helperFunctions";
import TodoModal from "./TodoModal";
import { ChronicDisease } from "../../models/medical/ChronicDisease";
import { ClinicPetResponse } from "../../models/pet/ClinicPetResponse";
import Button from "../../components/Button";
import { Clock } from "../../common/Icons/Clock";
import { CheckCircle } from "../../common/Icons/CheckCircle";
import { DocumentText } from "../../common/Icons/DocumentText";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import PetProfilePicture from "../../components/Pictures/Pet/PetProfilePicture";

interface Props {
  onError(message: string | null): void;
  reload(): void;
  sm?: boolean;
  todo: TodoResponse;
}

const TodoCard = ({
  onError,
  reload,
  sm = false,
  todo,
}: Props): ReactElement => {
  const { clinic } = useClinic();
  const [loading, setLoading] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(todo.completed || false);
  const [editTodo, setEditTodo] = useState<boolean>(false);
  const [pet, setPet] = useState<ClinicPetResponse>();
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  useEffect(() => {
    if (todo.linkedMedicalRecord) {
      setPet(todo.linkedMedicalRecord?.pet);
    } else if (todo.linkedPet) {
      setPet(todo.linkedPet.pet);
    }
    if (
      !todo.assignee &&
      !todo.description &&
      (sm || (pet === undefined && todo.linkedMedicalRecord === undefined))
    ) {
      setIsEmpty(true);
    }
  }, [todo, sm, pet]);

  const completeTodo = async () => {
    try {
      if (clinic) {
        setLoading(true);
        const request: UpdateTodoRequest = {
          ...todo,
          assigneeUserId: todo?.assignee?.userId,
          completed: true,
          linkedMedicalRecordId:
            todo?.linkedMedicalRecord?.universalMedicalRecordId,
          linkedPetId: todo?.linkedPet?.pet.clinicPetDetails.id,
          linkedPetOwnerId: todo?.linkedPetOwner?.petOwnerDetails.id,
        };
        const response = await TodoApi.updateTodo(clinic.id, todo.id, request);
        setCompleted(response.data.completed);
      }
    } catch (err) {
      onError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  const taskState = (): ReactElement => {
    if (loading) {
      return <LoaderInline />;
    }
    if (completed) {
      return (
        <div className="flex items-center space-x-1.5 text-green-500">
          <CheckCircle className="flex-shrink-0 w-6 h-6" />
          <span className="text-sm font-normal">{strings.completed}</span>
        </div>
      );
    }
    return (
      <Button variant="basic" onClick={completeTodo}>
        <div className="flex items-center space-x-1.5">
          <CheckCircle className="flex-shrink-0 w-6 h-6 text-gray-300 dark:text-gray-600" />
          <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
            {strings.complete}
          </span>
        </div>
      </Button>
    );
  };

  return (
    <div
      className="flex flex-col justify-between tw-card shadow h-full"
      style={{ minHeight: sm ? undefined : "200px" }}
    >
      <div
        className="p-4 flex flex-shrink-0 space-x-3 border-b border-gray-200 dark:border-gray-700"
        onClick={() => {
          setEditTodo(true);
        }}
        role="button"
        tabIndex={0}
      >
        <div
          className="mt-2 rounded-full w-2.5 h-2.5 flex-shrink-0"
          style={{
            backgroundColor: todo?.color || Colors.PRIMARY,
          }}
        />
        <p className="font-semibold">{todo.title}</p>
      </div>

      {!isEmpty && (
        <div className="p-4 flex flex-shrink-0 flex-col grow space-y-4">
          {todo?.description && <p className="break-all">{todo.description}</p>}

          {todo?.assignee && (
            <div className="flex space-x-3 items-center">
              <div className="w-10 h-10">
                <UserProfilePicture userId={todo?.assignee?.userId} />
              </div>
              <p>
                <b>{todo?.assignee?.fullName}</b>
              </p>
            </div>
          )}

          {pet && !sm && (
            <>
              <div className="flex space-x-3 items-center">
                <div className="w-10 h-10">
                  <PetProfilePicture
                    species={pet?.clinicPetDetails.species}
                    petId={pet?.userPetDetails?.id}
                    profilePictureId={pet?.userPetDetails?.profilePicture}
                  />
                </div>
                <p>
                  <b className="mr-1">{pet?.clinicPetDetails.name}</b>
                  <span className="text-gray-400">
                    {pet?.clinicPetDetails?.species
                      ? ` ${petSpecies[pet?.clinicPetDetails?.species]}, ${pet
                          ?.clinicPetDetails?.breed}, ${
                          petGenders[pet?.clinicPetDetails?.gender]
                        }`
                      : ""}
                  </span>
                </p>
              </div>
              {pet?.clinicPetDetails?.chronicDiseases?.length > 0 ? (
                <div className="space-x-1">
                  {pet?.clinicPetDetails?.chronicDiseases?.map(
                    (disease: ChronicDisease, index) => (
                      <Button key={index} variant="pill-red">
                        {getStringFromEnumsOrReturn(disease)}
                      </Button>
                    )
                  )}
                </div>
              ) : (
                <></>
              )}
            </>
          )}

          {todo.linkedMedicalRecord && !sm ? (
            <div className="flex color-light-grey">
              <Link
                to={`/medical-record/${todo.linkedMedicalRecord.medicalRecordId}`}
              >
                <div className="tw-link-tercier flex space-x-2 items-center">
                  <DocumentText />
                  <div>
                    {todo.linkedMedicalRecord.reservationType?.name}{" "}
                    {todo.linkedMedicalRecord.medicalRecordDateTime ? (
                      <span>
                        (
                        {moment(
                          todo.linkedMedicalRecord.medicalRecordDateTime
                        ).format(dateAndTime.momentDateTimeFormat)}
                        )
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}

      <div className="p-4 flex flex-shrink-0 justify-between items-center space-x-3 border-t border-gray-200 dark:border-gray-700">
        {taskState()}
        {todo.dueDate ? (
          <div className="flex items-center space-x-1.5">
            <Clock className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {moment(todo.dueDate).format("MMM DD")}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <TodoModal
        modifyInput={{ ...todo, completed }}
        onHide={(isSuccess) => {
          setEditTodo(false);
          if (isSuccess) {
            reload();
          }
        }}
        show={editTodo}
      />
    </div>
  );
};

export default TodoCard;
