/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const breedsHu = {
  DOG: [
    "Abruzzói juhászkutya",
    "Affenpinscher",
    "Afgán agár",
    "Afrikai oroszlánkutya",
    "Airedale terrier",
    "Akbash",
    "Akita inu",
    "Alaszkai malamut",
    "Alentejo masztiff",
    "Alopekisz",
    "Alpesi tacskókopó",
    "Altdeutscher Hütehunde",
    "Amerikai Bulldog",
    "Amerikai akita inu",
    "Amerikai cocker spániel",
    "Amerikai eszkimó kutya",
    "Amerikai meztelen terrier",
    "Amerikai pitbull terrier",
    "Amerikai rókakopó",
    "Amerikai staffordshire terrier",
    "Amerikai vízispániel",
    "Amerikai-kanadai fehér juhászkutya",
    "Anatóliai juhászkutya",
    "Angol agár",
    "Angol bulldog",
    "Angol cocker spániel",
    "Angol juhászkutya",
    "Angol masztiff",
    "Angol mosómedvekopó",
    "Angol pointer",
    "Angol rókakopó",
    "Angol springer spániel",
    "Angol szetter",
    "Angol véreb",
    "Angol-francia falkavadász kopó",
    "Appenzelli havasi kutya",
    "Arab agár",
    "Ardenneki pásztorkutya",
    "Argentin dog",
    "Ariége-i kopó",
    "Ariége-i vizsla",
    "Armant",
    "Artois-i kopó",
    "Atlaszi hegyikutya",
    "Ausztrál csonkafarkú pásztorkutya",
    "Ausztrál juhászkutya",
    "Ausztrál kelpie",
    "Ausztrál pásztorkutya",
    "Ausztrál selyemszőrű terrier",
    "Ausztrál terrier",
    "Auvergne-i vizsla",
    "Azawakh",
    "Azori-szigeteki kutya",
    "Bajor hegyi véreb",
    "Bali hegyikutya",
    "Bandog",
    "Barbet",
    "Basenji",
    "Basset hound",
    "Beagle",
    "Beagle harrier",
    "Bearded collie",
    "Beauce-i juhászkutya",
    "Bedlington terrier",
    "Belga griffon",
    "Belga juhászkutya",
    "Belga masztiff",
    "Belga vizsla",
    "Bergamói juhászkutya",
    "Berni kopó",
    "Berni pásztorkutya",
    "Berni pásztorkutya by-K",
    "Bernáthegyi",
    "Bichon bolognese",
    "Bichon frisé",
    "Bichon havanese",
    "Biewer yorkshire terrier",
    "Billy",
    "Black mouth cur",
    "Blue lacy",
    "Boerboel",
    "Bolognai pincs",
    "Bordeaux-i dog",
    "Border collie",
    "Border terrier",
    "Boston terrier",
    "Boszniai kopó",
    "Bourbonnais-i vizsla",
    "Boxer",
    "Boykin spániel",
    "Brabanti kis griffon",
    "Brabançon",
    "Brazil kopó",
    "Brazil masztiff",
    "Brazil terrier",
    "Bretagne-i cserszínű basset",
    "Bretagne-i cserszínű griffon",
    "Breton spániel",
    "Brie-i juhászkutya",
    "Broholmer",
    "Brüsszeli griffon",
    "Bukovinai pásztorkutya",
    "Bulldog",
    "Bullmasztiff",
    "Bullterrier",
    "Burgosi vizsla",
    "Bőrtokos kalippó",
    "Cairn terrier",
    "Cane corso",
    "Cardigan welsh corgi",
    "Carolina kutya",
    "Castro Laboreiro-i pásztorkutya",
    "Catahoulai leopárdkutya",
    "Cavalier King Charles spániel",
    "Chesapeake Bay retriever",
    "Chinook",
    "Chippiparai",
    "Cimarrón Uruguayo",
    "Cirneco dell’Etna",
    "Clumber spániel",
    "Coton de tuléar",
    "Csau csau",
    "Cseh juhászkutya",
    "Cseh szálkás szakállú vizsla",
    "Cseh terrier",
    "Csehszlovák farkaskutya",
    "Csivava",
    "Dalmata",
    "Dandie Dinmont-terrier",
    "Dobermann",
    "Drenti vizsla",
    "Drever",
    "Drótszőrű foxterrier",
    "Drótszőrű griffon",
    "Drótszőrű isztriai kopó",
    "Drótszőrű magyar vizsla",
    "Drótszőrű német vizsla",
    "Drótszőrű portugál kopó",
    "Dunker",
    "Dán-svéd őrkutya",
    "Délorosz juhászkutya",
    "Entlebuchi havasi kutya",
    "Erdélyi kopó  Magyarország",
    "Estrelai hegyikutya",
    "Eszkimó kutya",
    "Eurázsiai",
    "Fehér-cser francia kopó",
    "Fehér-cser nagy angol-francia kopó",
    "Fehér-fekete francia kopó",
    "Fehér-fekete nagy angol-francia kopó",
    "Fehérorosz juhászkutya vagy kelet-európai juhászkutya",
    "Fekete norvég elghund",
    "Fekete sery",
    "Fekete-cser mosómedvekopó",
    "Field spániel",
    "Finn kopó",
    "Finn lapphund",
    "Finn spicc",
    "Flandriai pásztorkutya",
    "Foxterrier",
    "Francia bulldog",
    "Francia kopó",
    "Francia spániel",
    "Francia vizsla",
    "Fríz vizsla",
    "Fríz vízikutya",
    "Fáraókutya",
    "Félhosszúszőrű pireneusi juhászkutya",
    "Gascogne-i francia vizsla",
    "Gascogne-i kék basset",
    "Gascogne-i kék griffon",
    "Glen of Imaal terrier",
    "Golden retriever",
    "Gordon szetter",
    "Griffon",
    "Groenendael",
    "Grönlandi kutya",
    "Göndörszőrű retriever",
    "Görög kopó",
    "Hahoawu",
    "Halden kopó",
    "Hamilton-kopó",
    "Hannoveri véreb",
    "Harlekin pincser",
    "Harrier",
    "Havannai pincs",
    "Hertha pointer",
    "Himalájai pásztorkutya",
    "Hokkaido inu",
    "Holland juhászkutya",
    "Holland smoushond",
    "Hortaye Borzaya",
    "Horvát juhászkutya",
    "Hosszúszőrű német vizsla",
    "Hosszúszőrű pireneusi juhászkutya",
    "Hosszúszőrű skót juhászkutya",
    "Hovawart",
    "Husky",
    "Hygen kopó",
    "Ibizai kopó",
    "Inka kopasz kutya",
    "Isztriai kopó",
    "Izlandi juhászkutya",
    "Jack Russell terrier",
    "Japán csin",
    "Japán spicc",
    "Japán terrier",
    "Jurai kopó",
    "Jämthund",
    "Kai ken",
    "Kalag Tazi",
    "Kaliba kutya",
    "Kanadai eszkimó kutya",
    "Kangal",
    "Kangaroo Dog",
    "Kanni",
    "Kanári-szigeteki kopó",
    "Kanári-szigeteki kutya",
    "Karszti pásztorkutya",
    "Karéliai medvekutya",
    "Katalán pásztorkutya",
    "Kaukázusi juhászkutya",
    "Keeshond",
    "Kelet-szibériai lajka",
    "Kerry blue terrier",
    "King Charles spániel",
    "King Shepherd",
    "Kis angol agár",
    "Kis angol terrier",
    "Kis angol-francia rókakopó",
    "Kis berni kopó",
    "Kis gascogne-i kék kopó",
    "Kis gascon-saintonge-i kopó",
    "Kis jurai kopó",
    "Kis luzerni kopó",
    "Kis münsterlandi vizsla",
    "Kis oroszlánkutya",
    "Kis schwyzi kopó",
    "Kis svájci kopó",
    "Kis vendée-i griffon basset",
    "Kishu ken",
    "Kisspitz",
    "Komondor  Magyarország",
    "Kooikerhondje",
    "Koreai jindo kutya",
    "Kras-medencei juhászkutya",
    "Kromfohrlandi",
    "Kunming kutya",
    "Kuvasz  Magyarország",
    "Kyi leo",
    "Kánaán kutya",
    "Kárpáti pásztorkutya",
    "Kínai kopasz kutya",
    "Közép schnauzer",
    "Közép uszkár",
    "Közép-ázsiai juhászkutya",
    "Középspitz",
    "Labrador retriever",
    "Laekenois",
    "Lagotto romagnolo",
    "Lajka",
    "Lakeland terrier",
    "Lancashire heeler",
    "Landseer",
    "Lapp pásztorkutya",
    "Lengyel agár",
    "Lengyel alföldi juhászkutya",
    "Lengyel hegyi juhászkutya",
    "Lengyel kopó",
    "Lengyel vadászkutya",
    "Leonbergi",
    "Leopard cur",
    "Lhasa apso",
    "Longdog",
    "Lucas terrier",
    "Lundehund",
    "Lurcher",
    "Luzerni kopó",
    "Magyar agár",
    "Magyar vizsla",
    "Majestic tree hound",
    "Malinois",
    "Mallorcai masztiff",
    "Mallorcai pásztorkutya",
    "Manchester terrier",
    "Markiesje",
    "Mexikói meztelen kutya",
    "Mioritic pásztorkutya",
    "Montenegrói hegyikopó",
    "Mopsz",
    "Moszkvai hosszú szőrű toy terrier",
    "Moszkvai őrkutya",
    "Mountain cur",
    "Mudhol Hound",
    "Mudi",
    "Máltai selyemkutya",
    "Münsterlandi vizsla",
    "Nagy angol-francia kopó",
    "Nagy gascogne-i kék kopó",
    "Nagy gascon-saintonge-i kopó",
    "Nagy münsterlandi vizsla",
    "Nagy svájci havasi kutya",
    "Nagy vendée-i griffon",
    "Nagy vendée-i griffon basset",
    "Nagyspitz",
    "Nivernais-i griffon",
    "Norfolk terrier",
    "Norrbotteni spicc",
    "Norvég buhund",
    "Norvég elghund",
    "Norvég lundehund",
    "Norwich terrier",
    "Nyugat-orosz agár",
    "Nyugat-szibériai lajka",
    "Nápolyi masztiff",
    "Német dog",
    "Német fürjészeb",
    "Német juhászkutya",
    "Német kopó",
    "Német pinscher",
    "Német spicc",
    "Német vadászterrier",
    "Német vizsla",
    "Olasz agár",
    "Olasz griffon",
    "Olasz kopó",
    "Olasz vizsla",
    "Olasz volpino",
    "Orosz agár",
    "Orosz fekete terrier",
    "Orosz toy terrier",
    "Orosz-európai lajka",
    "Osztrák kopó",
    "Osztrák pinscher",
    "Padilokoon",
    "Patterdale terrier",
    "Pekingi palotakutya",
    "Pembroke welsh corgi",
    "Perui meztelen kutya",
    "Perzsa agár",
    "Phalène",
    "Phu-quoc kutya",
    "Pikárdiai juhászkutya",
    "Pikárdiai kék spániel",
    "Pikárdiai spániel",
    "Pireneusi francia vizsla",
    "Pireneusi hegyikutya",
    "Pireneusi juhászkutya",
    "Pireneusi masztiff",
    "Pointer",
    "Poitevin",
    "Pomerániai törpespicc",
    "Pont-Audemer-i spániel",
    "Porcelánkopó",
    "Portugál juhászkutya",
    "Portugál kopó",
    "Portugál vizsla",
    "Portugál vízikutya",
    "Pudelpointer",
    "Puli",
    "Pumi",
    "Rajapalayam",
    "Rampur agár",
    "Rat terrier",
    "Rhodesian ridgeback",
    "Román pásztor kutya",
    "Rottweiler",
    "Rouilers-i pászorkutya",
    "Rövidszőrű isztriai kopó",
    "Rövidszőrű magyar vizsla",
    "Rövidszőrű német vizsla",
    "Rövidszőrű skót juhászkutya",
    "Saage kochee",
    "Saarloosi farkaskutya",
    "Saint-germaini vizsla",
    "Sarplaninai juhászkutya",
    "Savoye-i pásztorkutya",
    "Schapendoes",
    "Schiller-kopó",
    "Schipperke",
    "Schnauzer",
    "Schwyzi kopó",
    "Sealyham terrier",
    "Shar pei",
    "Shetlandi juhászkutya",
    "Shikoku inu",
    "Shiloh juhászkutya",
    "Si-cu",
    "Siba inu",
    "Sibaken",
    "Silken Windhound",
    "Simaszőrű foxterrier",
    "Simaszőrű portugál kopó",
    "Simaszőrű retriever",
    "Sinka",
    "Skye terrier",
    "Skót juhászkutya",
    "Skót szarvasagár",
    "Skót terrier",
    "Smålandi kopó",
    "Soft coated wheaten terrier",
    "Spanyol agár",
    "Spanyol kopó",
    "Spanyol masztiff",
    "Spanyol vízikutya",
    "Spániel",
    "Staffordshire bullterrier",
    "Stájeri drótszőrű kopó",
    "Sussexi spániel",
    "Svájci fehér juhászkutya",
    "Svájci kopó",
    "Svéd juhászspitz",
    "Svéd lapphund",
    "Szamojéd",
    "Szerb kopó",
    "Szerb trikolor kopó",
    "Szetter",
    "Szibériai husky",
    "Szlovák csuvacs",
    "Szlovák drótszőrű vizsla",
    "Szlovák kopó",
    "Szálkásszőrű német vizsla",
    "Szálkásszőrű tacskó",
    "Szávavölgyi kopó",
    "Szíriai pásztorkutya",
    "Szürke norvég elghund",
    "Tacskó",
    "Taigan",
    "Tajvani kutya",
    "Tasi",
    "Telomian",
    "Tervueren",
    "Thai ridgeback",
    "Tibeti masztiff",
    "Tibeti spániel",
    "Tibeti terrier",
    "Tiroli kopó",
    "Tornjak",
    "Tosza inu",
    "Toy uszkár",
    "Trikolor francia kopó",
    "Trikolor nagy angol-francia kopó",
    "Tátrai juhászkutya",
    "Törpe pinscher",
    "Törpe schnauzer",
    "Törpe uszkár",
    "Törpespicc",
    "Törpespitz",
    "Uszkár",
    "Utonagan",
    "Vadkacsavadász retriever",
    "Vendée-i griffon basset",
    "Vendée-i griffonkopó",
    "Vesztfáliai tacskókopó",
    "Vidrakopó",
    "Vizsla",
    "Vörös ír szetter",
    "Vörös-fehér ír szetter",
    "Weimari vizsla",
    "Welsh corgi",
    "Welsh springer spániel",
    "Welsh terrier",
    "West highland white terrier",
    "Wetterhoun",
    "Whippet",
    "Wolfspitz",
    "Yorkshire terrier",
    "bolonka cvetna",
    "bolonka francuska",
    "Észak-amerikai juhászkutya",
    "Észt kopó",
    "Ír farkaskutya",
    "Ír szetter",
    "Ír terrier",
    "Ír vízispániel",
    "Óangol juhászkutya",
    "Ónémet juhászkutya",
    "Óriás schnauzer",
    "Óriás uszkár",
    "Új-guineai éneklő kutya",
    "Újfundlandi",
    "Ősi dán vizsla"
  ],
  CAT: [
    "Abesszin",
    "Alpine lynx",
    "American lynx",
    "Amerikai csonkafarkú",
    "Amerikai drótszőrű",
    "Amerikai görbefülű",
    "Amerikai keuda",
    "Amerikai rövid szőrű",
    "Anatoli",
    "Ausztrál Tiffanie",
    "Ausztrál köd",
    "Balinéz",
    "Bengáli",
    "Birman",
    "Bohemian rex",
    "Bombay",
    "Bristol",
    "Brit hosszú szőrű",
    "Brit rövidszőrű",
    "Burma",
    "Burmilla",
    "Chausie",
    "Cheetoh",
    "Colorpoint rövid szőrű",
    "Colourpoint",
    "Cornish rex",
    "Cymric",
    "Desert lynx",
    "Devon rex",
    "Doni szfinx",
    "Egyiptomi mau",
    "Európai rövidszőrű",
    "Exotic",
    "Foldex",
    "German rex",
    "Havanna",
    "Highland lynx",
    "Hócipős",
    "Japán csonkafarkú",
    "Jávai",
    "Kaliforniai pettyes macska",
    "Kanaani",
    "Karthauzi",
    "Keleti rövid szőrű",
    "Korat",
    "Kuril-szigeti csonkafarkú",
    "La Perm",
    "Maine Coon",
    "Manx",
    "Munchkin",
    "Nebelung",
    "Neva Masquarade",
    "Norvég erdei macska",
    "Ocicat",
    "Ojos Azules",
    "Orosz kék",
    "Perzsa",
    "Peterbald",
    "Pixiebob",
    "RagaMuffin",
    "Ragdoll",
    "Selkirk rex",
    "Skót lógófülű",
    "Sokoke",
    "Szafari",
    "Szavanna",
    "Szerengeti",
    "Szfinx",
    "Szibériai",
    "Szingapura",
    "Sziámi macska",
    "Szomáli",
    "Thai",
    "Tiffany",
    "Tonkinéz",
    "Toyger",
    "Török Van",
    "Török angóramacska",
    "Ural rex",
    "Vienna Woods",
    "York"
  ],
  HORSE: [
    "Abaco vadló",
    "Abesszíniai",
    "Abtenauer",
    "Adaj",
    "Adayev",
    "Aegidienbergi",
    "Aenos",
    "Akhal tekini",
    "Al Khamsa",
    "Albán ló",
    "Albínó ló",
    "Altai ló",
    "Alter Real",
    "Altmarki",
    "American Cream Draft",
    "American Gaited",
    "American Quarter Horse",
    "American Saddlebred Horse",
    "American Show Pony",
    "Shetlandi póni",
    "Amerikai Miniatűr ló",
    "Amerikai Quarter póni",
    "Amerikai foltos ló",
    "Amerikai melegvérű",
    "Amerikai póni",
    "Amerikai sétáló póni",
    "Amerikai telivér",
    "Anatolian Native",
    "Andalúz",
    "Andravida",
    "Anglo Kabardin",
    "Anglo-Arab",
    "Angol hátaspóni",
    "Angol telivér",
    "Appaloosa",
    "Appendix",
    "Arab",
    "Arab félvér",
    "Arab telivér",
    "Aralusian",
    "Arany Amerikai Hátasló",
    "Ardenni",
    "Argentine Criollo",
    "Ariegeois",
    "Assateague",
    "Asturian",
    "Ausztrál hátaspóni",
    "Ausztrál póni",
    "Ausztráliai Brumby",
    "Auxois",
    "Avelignese",
    "Azték ló",
    "Bajor melegvérű",
    "Baleár",
    "Bali",
    "Baluchi",
    "Ban-ei",
    "Banker",
    "Bardigiano",
    "Bashkir",
    "Bashkir curly horse",
    "Basque-Navarre",
    "Batak",
    "Bazutó póni",
    "Belga hidegvérű",
    "Belga melegvérű",
    "Belorusz fogatló",
    "Berber ló",
    "Bhirum póni",
    "Bhutia póni",
    "Bitjug",
    "Blazer",
    "Boer",
    "Boszniai póni",
    "Boulonnais",
    "Brandenburger",
    "Breton",
    "Bretó Cerda",
    "Brindle Horse",
    "Brit Pettyes póni",
    "Brit Sport ló",
    "Brit melegvérű",
    "Brumby-ló",
    "Buckskin",
    "Bugyonnij",
    "Bulgarer",
    "Burguete",
    "Burmese vagy Shan",
    "Buryat",
    "Caballo Chileno",
    "Calabrese",
    "Camargue-i ló",
    "Campolino",
    "Cayuse Indián Póni",
    "Charantais",
    "Charollais",
    "Cheju",
    "Chickasaw",
    "Chickasaw",
    "Cleveland bay",
    "Clydesdale",
    "Comtois",
    "Connemara póni",
    "Crioulo",
    "Cseh hidegvérű",
    "Cseh melegvérű",
    "Dales póni",
    "Dartmoor póni",
    "Dongola",
    "Döle Trotter",
    "Döle ügető",
    "Egyiptomi",
    "Eriskay póni",
    "Exmoor póni",
    "Faeroes póni",
    "Falabella",
    "Fell póni",
    "Galiceno",
    "Galloway póni",
    "Galíciai póni",
    "Garrano",
    "Gelderlander",
    "Georgiai Grand ló",
    "Gidrán",
    "Gotland póni",
    "Groningen",
    "Guangxi",
    "Gypsy Vanner – Tinker póni",
    "Hackney",
    "Hackney póni",
    "Haflingi",
    "Haflingi kisló",
    "Hannoveri",
    "Hegu",
    "Hess és rajnai ló",
    "Hesszeni",
    "Highland póni",
    "Hirzai",
    "Hokkaido póni",
    "Holland igásló",
    "Holland melegvérű",
    "Holsteini ló",
    "Hucul ló",
    "Hunter",
    "ISR",
    "Indiai ló",
    "Iomud",
    "Izlandi póni",
    "Izraeli ló",
    "Jakut póni",
    "Jugoszláv hegyi póni",
    "Jávai póni",
    "Kabardini",
    "Kalmyk",
    "Kanadai Sport ló",
    "Kanadai ló",
    "Karabair",
    "Karabakh",
    "Karelian",
    "Karthúsiai ló",
    "Kaspi póni",
    "Kathiawari",
    "Kazakh",
    "Kelet Bolgár ló",
    "Keletfríz ló",
    "Kentucky hegyi hátasló",
    "Kerry Bog Póni",
    "Kinsky",
    "Kirdi póni",
    "Kisbéri félvér",
    "Kiso",
    "Kladrubi",
    "Konyik ló",
    "Kuhaylan",
    "Kunfakó",
    "Kushum",
    "Kustanair",
    "Kínai póni",
    "Landesi póni",
    "Lett hidegvérű",
    "Lett ló",
    "Limousin ló",
    "Lipicai ló",
    "Lippit Morgan",
    "Litvániai ló",
    "Llanero",
    "Lokai",
    "Losino",
    "Lundy póni",
    "Luzitán ló",
    "Magyar félvér",
    "Magyar hidegvérű",
    "Magyar sportló",
    "Magyar ügető",
    "Malopolski",
    "Mangalarga",
    "Mangalarga Marchador",
    "Manipur ló",
    "Maremann",
    "Marismeno",
    "Marokkói Berber ló",
    "Marwari",
    "Masuren",
    "Mecklenburgi",
    "Mecklenburgi hidegvérű",
    "Medicine Hat horse",
    "Menorquin",
    "Messara",
    "Mezen",
    "Mezőhegyesi sportló",
    "Mingrelian",
    "Minusinsk",
    "Misaki",
    "Missouri fox trotter",
    "Miszaki póni",
    "Miyako",
    "Mongol ló",
    "Montana Travler",
    "Morab",
    "Morgan horse",
    "Morocco Spotted Horse",
    "Morochuco",
    "Mountain Pleasure Horse",
    "Moyle",
    "Muraközi",
    "Murgese",
    "Musztáng",
    "Nafan más néven Tibeti póni",
    "Narraganset Pacer",
    "Narym",
    "National Show ló",
    "National Spotted Saddle horse",
    "New forest",
    "Newfoundland póni",
    "Nez Perce",
    "Nigériai ló",
    "Nokota",
    "Noma",
    "Nooitgedacht póni",
    "Norfolk ügető",
    "Norman cob",
    "Norvég póni",
    "Nyugat-Afrikai Berber",
    "Nyugat-Szudáni póni",
    "Nápolyi",
    "Német lovagló-póni",
    "Német sportló",
    "Nóniusz",
    "Nóri ló",
    "Ob",
    "Ocracoke",
    "Olasz hidegvérű",
    "Olasz hátasló",
    "Oldenburgi",
    "Orlov ügető",
    "Orosz hátasló",
    "Orosz igásló",
    "Pahlavan",
    "Palomino",
    "Pampa",
    "Pantaneiro",
    "Paso",
    "Paso fino",
    "Peneia",
    "Percheron",
    "Persano",
    "Perzsa Arab",
    "Pindos póni",
    "Pintabian",
    "Pinto",
    "Pintó",
    "Pinzgauer",
    "Pleveni ló",
    "Poitou ló",
    "Polesian",
    "Posavac",
    "Pozan",
    "Przewalski-ló",
    "Póló póni",
    "Qatgani",
    "QuArab",
    "Quariesian",
    "Quarter horse",
    "Rajnai melegvérű",
    "Rajnai-Német hidegvérű",
    "Ranchero Stock horse",
    "Renano",
    "Riding Horse",
    "Rijpaard",
    "Riwoche",
    "Rocky mountain póni",
    "Román sportló",
    "Rottal",
    "Sable szigeti póni",
    "Saddlebred",
    "Sadecki",
    "Salerno",
    "Sandalwoodi póni - Sandalwood",
    "Sanhe",
    "Schleswigi hidegvérű",
    "Schwarzwaldi",
    "Semigreu Romanesc",
    "Senne",
    "Shagya-arab",
    "Shetlandi póni",
    "Shirazi",
    "Shire",
    "Skyrosi ló",
    "Slaski",
    "Slatinany",
    "Sokólsk",
    "Sorraia póni",
    "Spanyol Berber",
    "Spanyol Jennet ló",
    "Spanyol Musztáng",
    "Spanyol ló",
    "Spanyol-Norman ló",
    "Spiti póni",
    "Spotted horse",
    "Standardbred",
    "Strelet Arab",
    "Stuhm",
    "Suffolk Punch",
    "Sulphur",
    "Sulphur Springs Musztáng",
    "Sumba és Sumbawa póni",
    "Sunicho póni",
    "Svájci melegvérű",
    "Svéd ardenni",
    "Svéd félvér",
    "Svéd melegvérű",
    "Szardíniai ló",
    "Szicíliai ló",
    "Sziléziai Nóri",
    "Szlovén hidegvérű",
    "Szomáli póni",
    "Szovjet igásló",
    "Szudán ló",
    "Szíriai ló",
    "Taishu",
    "Tarpán",
    "Tavda",
    "Tawleed",
    "Tchenarani",
    "Tennessee sétáló ló",
    "Tennuvian",
    "Tersk ló",
    "Thesszáliai ló",
    "Tigris ló",
    "Timor póni",
    "Tokara póni",
    "Tolfetano",
    "Torij",
    "Torik",
    "Trakehneni",
    "Tuigpaard",
    "Tyerszki ló",
    "Törpeló",
    "Türkmén ló",
    "Ukrajnai Saddle",
    "Vendéen",
    "Vesztfáliai hidegvérű",
    "Vesztfáliai póni",
    "Viatka",
    "Virginia Highlander",
    "Vlaamperd",
    "Vladimir ló",
    "Walkaloosa",
    "Welara",
    "Welsh póni",
    "Weser-Ems póni",
    "Westfáliai",
    "Wielkopolski",
    "Wilbur-Cruce Spanish",
    "Württembergi",
    "Xilongol",
    "Yabou",
    "Yanqi",
    "Yili",
    "Yonaguni",
    "Yorkshire kocsiló",
    "Zaniskari póni",
    "Zeeland",
    "Zemaituka",
    "Zweibrückeni",
    "Észak-Amerikai poroszkáló ló",
    "Észak-Svéd ló",
    "Északi hátasló",
    "Északi igásló",
    "Északkeleti ló",
    "Észtországi Natív",
    "Ír hobbiló",
    "Ír igásló",
    "Ír sportló",
    "Öszvér"
  ],
  RABBIT: [
    "Alaska",
    "Altex",
    "American",
    "American Chinchilla",
    "American Sable",
    "American Fuzzy Lop",
    "Angora",
    "Argente Bleu",
    "Argente Brun",
    "Argente Clair",
    "Argente Crème",
    "Argente Noir",
    "Argente St Hubert",
    "Argente de Champagne",
    "Armenian Marder",
    "Astrex",
    "Aurora Negro",
    "Baladi Black",
    "Baladi Red",
    "Baladi White",
    "Bauscat",
    "Beaver",
    "Beige",
    "Belgian Hare",
    "Beveren",
    "Beveren",
    "Big Silver Marten",
    "Blanc de Bouscat",
    "Blanc de Chauny",
    "Blanc de Hotot",
    "Blanc de L'Oural",
    "Blanc de Popielno",
    "Blanc de Termonde",
    "Blue of Ham",
    "Blue of Sint-Niklaas",
    "Bourbonnais Grey",
    "Brazilian",
    "Britannia Petite",
    "British Giant",
    "Brown Chestnut of Lorraine",
    "Caldes",
    "Californian",
    "Canadian Plush Lop",
    "Carmagnola Grey",
    "Cashmere Lop",
    "Champagne d'Argent",
    "Chaudry",
    "Checkered Giant",
    "Chinchilla",
    "Chinchilla Giganta",
    "Cinnamon",
    "Continental Giant Coloured",
    "Continental Giant White",
    "Creme d'Argent",
    "Criollo",
    "Cuban Brown",
    "Czech Red",
    "Czech Solver",
    "Czech Spot",
    "Czech White",
    "Dalmatian",
    "Deilenaar",
    "Dutch",
    "Dutch",
    "Dwarf Hotot",
    "Dwarf Lop",
    "Elfin",
    "Enderby Island",
    "English Angora",
    "English Lop",
    "English Spot",
    "Fauve de Bourgogne",
    "Fee de Marbourg",
    "Feh",
    "Feu Noir",
    "Flemish Giant",
    "Florida White",
    "Fox",
    "Fox–Silver",
    "French Angora",
    "French Lop",
    "Gabali",
    "German Angora",
    "German Lop",
    "Giant Angora",
    "Giant Chinchilla",
    "Giant Havana",
    "Giant Marburger",
    "Giant Papillon",
    "Giant Silver",
    "Giant Smoke Pearl",
    "Giza White",
    "Golden Fawn",
    "Golden Glavcot",
    "Gotland",
    "Gouwenaar",
    "Grey Pearl of Halle",
    "Güzelçamlı",
    "Harlequin",
    "Harlequin Dutch",
    "Harlequin Rex",
    "Havana",
    "Himalayan",
    "Holland Lop",
    "Hulstlander",
    "Hungarian Giant",
    "Isabella",
    "Jamora ",
    "Japanese Dutch",
    "Japanese White",
    "Jersey Wooly",
    "Kabyle",
    "Lilac",
    "Lionhead",
    "Liptov Baldspotted Rabbit",
    "Lynx",
    "Marburger Feh",
    "Mecklenburger Piebald",
    "Meissner Lop",
    "Mellerud rabbit",
    "Mini Cashmere Lop",
    "Mini Lion Lop",
    "Mini Lop",
    "Mini Rex",
    "Mini Satin",
    "Miniature Cashmere Lop",
    "Miniature Lion Lop",
    "Miniature Lop",
    "Miniature Papillon",
    "Miniature Rex",
    "Miniature Satin",
    "Moshtohor",
    "Netherland Dwarf",
    "New Zealand",
    "New Zealand red",
    "New Zealand white",
    "Nitransky",
    "Old English Red",
    "Opossum",
    "Orange",
    "Orylag ",
    "Palomino",
    "Pannon White",
    "Patagonian",
    "Perlfee",
    "Petite Brabancon",
    "Plush Lop",
    "Pointed Beveren",
    "Polish",
    "Rex",
    "Rex du Poitou",
    "Rhinelander",
    "Rhone",
    "Rouennais",
    "Russian",
    "Sachsengold ",
    "Sallander",
    "San Juan",
    "Satin Angora",
    "Satin",
    "Schwarzgrannen",
    "Separator",
    "Siamese Sable",
    "Siberian",
    "Silver",
    "Silver Fox",
    "Silver Fox",
    "Silver Marten",
    "Simenwar",
    "Sitka",
    "Slovenian Rabbit",
    "Smoke Pearl",
    "Spanish Giant",
    "Squirrel",
    "Standard Chinchilla",
    "Standard Rex",
    "Stone rabbit",
    "Sussex",
    "Swan",
    "Swedish Fur",
    "Swedish Hare",
    "Swiss Fox",
    "Tadla",
    "Tan",
    "Teddy Dwarf",
    "Teddy Lop",
    "Thrianta",
    "Thuringer",
    "Tri-Colour Dutch",
    "Tri-Colour English",
    "Trønder",
    "V-line",
    "Velveteen Lop",
    "Vienna Coloured",
    "Vienna White",
    "Wheaten",
    "Wheaten Lynx",
    "White Country",
    "Zemmouri",
    "Zika",
    "Ørestad"
  ]
};