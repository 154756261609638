/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { petSpecies, strings } from "../../../../common/Strings/Strings";
import SearchInput from "../../../../components/InputFieldsSimple/SearchInput";
import { useDebouncedState } from "../../../../hooks/hooks";
import Filters from "../../../../components/ReactHookFormFields/General/Filters";
import { PetSpecies } from "../../../../models/pet/PetSpeciesEnum";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal/Modal";
import CloseButton from "../../../../components/CloseButton";

export type SpeciesFilter = Array<PetSpecies | "all">;

interface Props {
  defaultFilters?: { speciesFilters?: SpeciesFilter; query?: string };
  returnFilters(speciesFilters?: SpeciesFilter, query?: string): void;
}

interface PetFiltersForm {
  filters: SpeciesFilter;
}

const PetSearch = ({ defaultFilters, returnFilters }: Props): ReactElement => {
  const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const petNameSearchQuery = useDebouncedState("", 400);
  const { control, handleSubmit, getValues, setValue } =
    useForm<PetFiltersForm>({
      defaultValues: { filters: defaultFilters?.speciesFilters || ["all"] },
    });
  const [debounced, setDebounced] = useState<boolean>(false);

  useEffect(() => {
    if (defaultFilters?.speciesFilters) {
      setValue("filters", defaultFilters.speciesFilters);
    }
    if (defaultFilters?.query) {
      petNameSearchQuery.setValue(defaultFilters.query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilters]);

  const onPetNameSearch = (query: string) => {
    setDebounced(true);
    petNameSearchQuery.setValue(query);
  };

  const applyFilters = ({ filters }: PetFiltersForm) => {
    returnFilters(filters, petNameSearchQuery.value);
    setFilterModalOpen(false);
  };

  useEffect(() => {
    if (debounced) {
      const query = petNameSearchQuery.value.toLowerCase();
      returnFilters(getValues().filters, query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petNameSearchQuery.value]);

  return (
    <>
      <div className="flex items-end">
        <div>
          <SearchInput
            loading={false}
            onChange={onPetNameSearch}
            enableCreateButton={false}
            placeholder={`${strings.search}...`}
            label={strings.search}
            value={defaultFilters?.query}
          />
        </div>
        <div className="ml-3" style={{ height: "38px" }}>
          <Button
            onClick={() => {
              setFilterModalOpen(true);
            }}
            variant="tertiary"
          >
            {strings.filter}
          </Button>
        </div>
      </div>
      <Modal
        handleClose={() => {
          setFilterModalOpen(false);
        }}
        show={isFilterModalOpen}
      >
        <Modal.Header title={strings.filter} />
        <Modal.Body>
          <div className="mb-2">
            <form>
              <Filters
                control={control}
                defaultFilters={defaultFilters?.speciesFilters || ["all"]}
                name="filters"
                options={[
                  { value: "all", title: strings.selectAll },
                  { value: PetSpecies.DOG, title: petSpecies[PetSpecies.DOG] },
                  { value: PetSpecies.CAT, title: petSpecies[PetSpecies.CAT] },
                  {
                    value: PetSpecies.HORSE,
                    title: petSpecies[PetSpecies.HORSE],
                  },
                ]}
                title={strings.species}
              />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-main-button"
            onClick={handleSubmit(applyFilters)}
          >
            {strings.applyFilters}
          </Button>
          <CloseButton
            onClick={() => {
              setFilterModalOpen(false);
            }}
            text={strings.cancel}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PetSearch;
