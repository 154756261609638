/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useUser } from "../../../../../../contexts/UserContext";
import UserApi from "../../../../../../api/UserApi";
import AlertBox, { AlertType } from "../../../../../../components/AlertBox";
import { getGeneralError } from "../../../../../../util/helperFunctions";
import { PinState } from "./PinCodeSettings";
import { Card } from "../../../../../../components/Cards/Card";
import CodeInputsForm from "../../../../../../components/ReactHookFormFields/Specific/CodeInputsForm";
import { strings } from "../../../../../../common/Strings/Strings";
import LoaderInline from "../../../../../../components/LoaderInline";
import Button from "../../../../../../components/Button";
import { Phone } from "../../../../../../common/Icons/Phone";

interface Props {
  setPinState: (pinState: PinState) => void;
  asCard?: boolean;
}

const ConfirmNewCode: React.FC<Props> = ({
  setPinState,
  asCard = true,
}: Props) => {
  const { user, setUser } = useUser();

  const [responseMessage, setResponseMessage] = useState<string | null>();
  const [responseType, setResponseType] = useState<AlertType>(AlertType.ERROR);
  const [loading, setLoading] = useState<boolean>(false);

  const confirmPin = async (smsCode: string) => {
    setLoading(true);
    setResponseMessage(null);

    try {
      const response = await UserApi.confirmPinCode({ smsCode });
      setResponseMessage(strings.pinSaved);
      setResponseType(AlertType.SUCCESS);
      setUser(response.data);
      setPinState(PinState.HasCode);
    } catch (err) {
      setResponseMessage(await getGeneralError(err));
      setResponseType(AlertType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const content = (
    <>
      <div className="flex items-center space-x-2 text-zinc-800 dark:text-white">
        <Phone />
        <div className="font-semibold leading-tight">
          {strings.enterVerificationCode}
        </div>
      </div>
      <div>
        {strings.formatString(
          strings.verificationCodeSentTo,
          <b>{user?.confirmedMobilePhone?.value}</b>
        )}
      </div>
      <CodeInputsForm
        disableNotNumberCharacters={false}
        label={strings.verificationCode}
        size={6}
        submit={(result: string) => {
          void confirmPin(result);
        }}
      />
      <AlertBox
        message={responseMessage}
        className="my-5"
        type={responseType}
      />
      <LoaderInline hidden={!loading} />
      <Button
        hidden={loading}
        onClick={() => {
          setPinState(PinState.NewCode);
          setResponseMessage(null);
        }}
        variant="link"
      >
        {strings.addNewPin}
      </Button>
    </>
  );

  if (!asCard) {
    return content;
  }

  return (
    <Card size="md" type="simple" title={strings.verifyPin}>
      {content}
    </Card>
  );
};

export default ConfirmNewCode;
