/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logger from "../../../../../util/logger";
import { ClinicAndClinicPetsResponse } from "../../../../../models/pet/ClinicAndClinicPetsResponse";
import { PublicPet } from "../../../../../models/pet/PublicPet";
import {
  petGenders,
  petSpecies,
  strings,
} from "../../../../../common/Strings/Strings";
import {
  PetTransferResponse,
  PetTransferStatus,
} from "../../../../../models/petTransfer/PetTransferResponse";
import { UserPetResponse } from "../../../../../models/pet/UserPetResponse";
import AcceptOwnershipModal from "./ClinicRegistrations/AcceptOwnershipModal";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import DeclineOwnershipModal from "./ClinicRegistrations/DeclineOwnershipModal";
import AcceptTransferOwnershipModal from "./Transfer/AcceptTransferOwnershipModal";
import DeclineTransferOwnershipModal from "./Transfer/DeclineTransferOwnershipModal";
import LoaderInline from "../../../../../components/LoaderInline";
import PetOwnerApi from "../../../../../api/PetOwnerApi";
import { useUser } from "../../../../../contexts/UserContext";
import Button from "../../../../../components/Button";
import UserProfilePicture from "../../../../../components/Pictures/User/UserProfilePicture";
import ClinicLogo from "../../../../../components/Pictures/Clinic/ClinicLogo";
import PetProfilePicture from "../../../../../components/Pictures/Pet/PetProfilePicture";

interface Props {
  limitResults?: number;
  myPets?: UserPetResponse[];
  reloadMyPets?(): void;
}

const PendingPets = ({
  limitResults,
  myPets,
  reloadMyPets,
}: Props): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const [transfers, setTransfers] = useState<PetTransferResponse[]>([]);
  const [transferWaitings, setTransfersWaitings] = useState<
    PetTransferResponse[]
  >([]);
  const [clinicRegistrations, setClinicRegistrations] = useState<
    ClinicAndClinicPetsResponse[]
  >([]);
  const [pendingPetCount, setPendingPetCount] = useState<number>(0);
  const [acceptOwnershipPet, setAcceptOwnershipPet] = useState<{
    pet: PublicPet;
    petOwnerId: string;
  }>();
  const [declineOwnershipPet, setDeclineOwnershipPet] = useState<{
    pet: PublicPet;
    petOwnerId: string;
  }>();
  const [acceptTransferOwnership, setAcceptTransferOwnership] =
    useState<PetTransferResponse>();
  const [declineTransferOwnership, setDeclineTransferOwnership] =
    useState<PetTransferResponse>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [reloadPendingPets, setReloadPendingPets] = useState<boolean>(false);

  const { user } = useUser();

  useEffect(() => {
    const getNotifications = async () => {
      try {
        const response = await PetOwnerApi.getPendingPets();
        const transferTemp: PetTransferResponse[] = [];
        const transferWaitingTemp: PetTransferResponse[] = [];
        const clinicRegList: ClinicAndClinicPetsResponse[] = [];

        const count =
          response.data.clinicRegisteredPets.length +
          response.data.petTransfers.length;
        const limit = limitResults || count;

        response.data.petTransfers.forEach((petTransfer) => {
          if (transferTemp.length + transferWaitingTemp.length >= limit) {
            return;
          }
          if (
            (petTransfer.status === PetTransferStatus.WAITING_FOR_NEW_OWNER &&
              petTransfer?.newOwner?.userId === user.userId) ||
            (petTransfer.status === PetTransferStatus.WAITING_FOR_OLD_OWNER &&
              petTransfer.oldOwner.userId === user.userId)
          ) {
            transferTemp.push(petTransfer);
          } else {
            transferWaitingTemp.push(petTransfer);
          }
        });

        response.data.clinicRegisteredPets.forEach(
          (clinicRegistration: ClinicAndClinicPetsResponse) => {
            clinicRegistration.pets.forEach((pet) => {
              if (
                clinicRegList.length +
                  transferTemp.length +
                  transferWaitingTemp.length <
                limit
              ) {
                clinicRegList.push({ ...clinicRegistration, pets: [pet] });
              }
            });
          }
        );

        setTransfers(transferTemp);
        setTransfersWaitings(transferWaitingTemp);
        setClinicRegistrations(clinicRegList);
        setPendingPetCount(count);
      } catch (err) {
        logger.error(err);
      } finally {
        setLoading(false);
      }
    };

    void getNotifications();
  }, [reloadPendingPets, limitResults, user]);

  if (loading && !limitResults) {
    return (
      <div className="space-y-6">
        <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
          {strings.pendingPets}
        </h1>
        <LoaderInline />
      </div>
    );
  }

  const getMessage = (transfer: PetTransferResponse): string => {
    if (transfer.status === PetTransferStatus.WAITING_FOR_OLD_OWNER) {
      if (transfer.oldOwner.userId === user.userId) {
        return strings.formatString(
          strings.acceptAsOldOwner,
          transfer?.newOwner?.fullName,
          transfer.pet.name
        );
      }
      return strings.formatString(
        strings.waitingForOldOwner,
        transfer.pet.name
      );
    }
    if (transfer.status === PetTransferStatus.WAITING_FOR_NEW_OWNER) {
      if (transfer?.newOwner?.userId === user.userId) {
        return strings.formatString(
          strings.acceptAsNewOwner,
          transfer.oldOwner.fullName,
          transfer.pet.name
        );
      }
      return strings.formatString(
        strings.waitingForNewOwner,
        transfer.pet.name
      );
    }
    return "";
  };

  return (
    <>
      {!loading &&
      (clinicRegistrations.length > 0 ||
        transfers.length > 0 ||
        transferWaitings.length > 0) ? (
        <div className="space-y-6">
          <div className="flex items-end">
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.pendingPets} ({pendingPetCount})
            </h1>
            <div className="ml-auto items-center" hidden={!limitResults}>
              <Link to="/pending-pets">{strings.seeAllPendingPets}</Link>
            </div>
          </div>
          <AlertBox
            hidden={!isSuccess}
            message={strings.savedSuccessfully}
            type={AlertType.SUCCESS}
          />
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
            {transferWaitings.map((transfer: PetTransferResponse) => (
              <div key={transfer.id}>
                <div className="tw-card p-4 divide-y dark:divide-gray-700 h-full flex flex-col">
                  <div style={{ paddingBottom: "14px" }}>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "14px",
                        }}
                      >
                        <UserProfilePicture userId={transfer.oldOwner.userId} />
                      </div>
                      <b>{getMessage(transfer)}</b>
                    </div>
                  </div>
                  <div className="flex flex-col items-center grow py-6">
                    <div style={{ width: "80px", height: "80px" }}>
                      <PetProfilePicture species={transfer?.pet?.species} />
                    </div>
                    <b style={{ padding: "8px 0 8px 0" }}>
                      {transfer?.pet.name}
                    </b>
                    <div>
                      {transfer?.pet?.species &&
                        petSpecies[transfer?.pet?.species]}
                      ,{" "}
                      {transfer?.pet?.gender &&
                        petGenders[transfer?.pet?.gender]}
                      {transfer?.pet?.color && `, ${transfer?.pet?.color}`},{" "}
                      {transfer?.pet?.breed}
                    </div>
                  </div>
                  <div
                    style={{ padding: "24px 0 24px 0" }}
                    hidden={!transfer?.note}
                    className="hr-on-top"
                  >
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{ wordBreak: "break-all" }}
                    >
                      <i>{`"${transfer?.note}"`}</i>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {transfers.map((transfer: PetTransferResponse) => (
              <div key={transfer.id}>
                <div className="tw-card p-4 divide-y dark:divide-gray-700 h-full flex flex-col">
                  <div className="pb-4">
                    <div className="flex items-center">
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "14px",
                        }}
                      >
                        <UserProfilePicture userId={transfer.oldOwner.userId} />
                      </div>
                      <b>{getMessage(transfer)}</b>
                    </div>
                  </div>
                  <div className="flex flex-col items-center grow py-6">
                    <div style={{ width: "80px", height: "80px" }}>
                      <PetProfilePicture species={transfer?.pet?.species} />
                    </div>
                    <b style={{ padding: "8px 0 8px 0" }}>
                      {transfer?.pet.name}
                    </b>
                    <div>
                      {transfer?.pet?.species &&
                        petSpecies[transfer?.pet?.species]}
                      ,{" "}
                      {transfer?.pet?.gender &&
                        petGenders[transfer?.pet?.gender]}
                      {transfer?.pet?.color && `, ${transfer?.pet?.color}`},{" "}
                      {transfer?.pet?.breed}
                    </div>
                  </div>
                  <div
                    style={{ padding: "24px 0 24px 0" }}
                    hidden={!transfer?.note}
                  >
                    <div
                      className="flex flex-col items-center"
                      style={{ wordBreak: "break-all" }}
                    >
                      <i>{`"${transfer?.note}"`}</i>
                    </div>
                  </div>
                  <div className=" flex flex-col items-center space-y-4">
                    <Button
                      onClick={() => {
                        setAcceptTransferOwnership(transfer);
                      }}
                    >
                      {transfer.status ===
                      PetTransferStatus.WAITING_FOR_OLD_OWNER
                        ? strings.yes
                        : strings.acceptOwnership}
                    </Button>
                    <Button
                      variant="link"
                      onClick={() => {
                        setDeclineTransferOwnership(transfer);
                      }}
                    >
                      {transfer.status ===
                      PetTransferStatus.WAITING_FOR_OLD_OWNER
                        ? strings.no
                        : strings.declineOwnership}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            {clinicRegistrations.map(
              (
                clinicRegistration: ClinicAndClinicPetsResponse,
                index: number
              ) => (
                <Fragment key={index}>
                  {clinicRegistration.pets.map((pet: PublicPet) => (
                    <div key={pet.id}>
                      <div className="tw-card p-4 divide-y dark:divide-gray-700 h-full flex flex-col">
                        <div style={{ paddingBottom: "14px" }}>
                          <div className="d-flex align-items-center">
                            <div
                              style={{
                                width: "40px",
                                height: "40px",
                                marginRight: "14px",
                              }}
                            >
                              <ClinicLogo
                                clinicId={clinicRegistration.clinic.id}
                              />
                            </div>
                            <b>
                              {strings.formatString(
                                strings.clinicHasRegisteredYourPet,
                                clinicRegistration.clinic.name
                              )}
                            </b>
                          </div>
                        </div>
                        <div className="flex flex-col items-center grow py-6">
                          <div style={{ width: "80px", height: "80px" }}>
                            <PetProfilePicture species={pet?.species} />
                          </div>
                          <b style={{ padding: "8px 0 8px 0" }}>{pet.name}</b>
                          <div>
                            {pet?.species && petSpecies[pet?.species]},{" "}
                            {pet?.gender && petGenders[pet?.gender]}
                            {pet?.color && `, ${pet?.color}`}, {pet?.breed}
                          </div>
                        </div>
                        <div className="flex flex-col items-center space-y-4">
                          <Button
                            onClick={() => {
                              setAcceptOwnershipPet({
                                pet,
                                petOwnerId: clinicRegistration.petOwnerId,
                              });
                            }}
                          >
                            {strings.acceptOwnership}
                          </Button>
                          <Button
                            variant="link"
                            onClick={() => {
                              setDeclineOwnershipPet({
                                pet,
                                petOwnerId: clinicRegistration.petOwnerId,
                              });
                            }}
                          >
                            {strings.declineOwnership}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Fragment>
              )
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <AcceptOwnershipModal
        close={() => {
          setAcceptOwnershipPet(undefined);
        }}
        isOpen={!!acceptOwnershipPet}
        myPets={myPets}
        pet={acceptOwnershipPet?.pet}
        petOwnerId={acceptOwnershipPet?.petOwnerId}
        setSuccess={(value) => {
          setIsSuccess(value);
          setAcceptOwnershipPet(undefined);
          setReloadPendingPets(!reloadPendingPets);
          if (reloadMyPets) {
            reloadMyPets();
          }
        }}
      />
      <DeclineOwnershipModal
        close={() => {
          setDeclineOwnershipPet(undefined);
        }}
        isOpen={!!declineOwnershipPet}
        pet={declineOwnershipPet?.pet}
        petOwnerId={declineOwnershipPet?.petOwnerId}
        setSuccess={(value) => {
          setIsSuccess(value);
          setDeclineOwnershipPet(undefined);
          setReloadPendingPets(!reloadPendingPets);
        }}
      />
      <AcceptTransferOwnershipModal
        close={() => {
          setAcceptTransferOwnership(undefined);
        }}
        isOpen={!!acceptTransferOwnership}
        transfer={acceptTransferOwnership}
        setSuccess={(value) => {
          setIsSuccess(value);
          setAcceptTransferOwnership(undefined);
          setReloadPendingPets(!reloadPendingPets);
          if (reloadMyPets) {
            reloadMyPets();
          }
        }}
      />
      <DeclineTransferOwnershipModal
        close={() => {
          setDeclineTransferOwnership(undefined);
        }}
        isOpen={!!declineTransferOwnership}
        transfer={declineTransferOwnership}
        setSuccess={(value) => {
          setIsSuccess(value);
          setDeclineTransferOwnership(undefined);
          setReloadPendingPets(!reloadPendingPets);
        }}
      />
    </>
  );
};

export default PendingPets;
