/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import EditDiscountModal from "./EditDiscountModal";
import ClinicApi from "../../../api/ClinicApi";
import { BasicClinicResponse } from "../../../models/clinic/BasicClinicResponse";
import { ClinicDiscountResponse } from "../../../models/discount/ClinicDiscountResponse";
import ClinicDiscountApi from "../../../api/ClinicDiscountApi";
import BeforeDeleteModal from "../../../components/Modals/BeforeDeleteModal";
import { Link } from "react-router-dom";
import { PageResponse } from "../../../models/PageResponse";
import { usePager } from "../../../hooks/hooks";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";

interface Props {
  isActiveTab: boolean;
}

export default function DiscountSection({ isActiveTab }: Props): ReactElement {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [clinics, setClinics] = useState<BasicClinicResponse[]>([]);
  const [discounts, setDiscounts] =
    useState<PageResponse<ClinicDiscountResponse>>();
  const pager = usePager(50);

  const [isEditModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setDeleteModalVisible] =
    useState<boolean>(false);

  const [selectedDiscount, setSelectedDiscount] =
    useState<ClinicDiscountResponse>();

  const getClinics = async () => {
    setLoading(true);
    try {
      const response = await ClinicApi.getClinics();
      setClinics(response.data);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  const getDiscounts = async () => {
    setLoading(true);
    try {
      const response = await ClinicDiscountApi.getDiscounts({
        pageNumber: pager.pageNumber,
        pageSize: pager.pageSize,
      });
      setDiscounts(response.data);
      setError(null);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getClinics();
  }, [isActiveTab]);

  useEffect(() => {
    if (!isActiveTab) return;
    void getDiscounts();
  }, [isActiveTab, pager.pageSize, pager.pageNumber]);

  const openEditModal = (discount?: ClinicDiscountResponse) => {
    setSelectedDiscount(discount);
    setEditModalVisible(true);
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
  };

  const openDeleteModal = (discount: ClinicDiscountResponse) => {
    setSelectedDiscount(discount);
    setDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const deleteDiscount = async () => {
    closeDeleteModal();
    if (!selectedDiscount) {
      return;
    }

    setLoading(true);

    try {
      await ClinicDiscountApi.deleteDiscount(selectedDiscount.id);
      await getDiscounts();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="px-4 lg:px-6 py-6">
        <div className="flex mb-3">
          <div className="ml-auto">
            <Button variant="primary" onClick={() => openEditModal()}>
              {strings.addNew}
            </Button>
          </div>
        </div>
        <PageableTable
          pager={pager}
          isLoading={loading}
          content={discounts}
          error={error}
        >
          <PageableTableHead>
            <tr>
              <th className="text-left tw-th">{strings.clinic}</th>
              <th className="text-left tw-th">{strings.startDate}</th>
              <th className="text-left tw-th">{strings.endDate}</th>
              <th className="text-left tw-th"></th>
            </tr>
          </PageableTableHead>
          <PageableTableBody>
            {discounts &&
              discounts.elements.map((discount, index: number) => (
                <tr key={index}>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <Link to={`/management/clinics/${discount.clinicId}`}>
                      {discount.clinicName}
                    </Link>
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    {discount.validityStart.toString()}
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    {discount.validityEnd?.toString()}
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap text-right">
                    <Button
                      variant="secondary"
                      className="mr-2"
                      onClick={() => openEditModal(discount)}
                    >
                      <i className="fas fa-pencil-alt" />
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => openDeleteModal(discount)}
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </td>
                </tr>
              ))}
          </PageableTableBody>
        </PageableTable>
      </div>
      <EditDiscountModal
        getDiscounts={getDiscounts}
        onHide={closeEditModal}
        clinics={clinics}
        selectedDiscount={selectedDiscount}
        show={isEditModalVisible}
      />
      <BeforeDeleteModal
        open={isDeleteModalVisible}
        onHide={closeDeleteModal}
        returnYes={deleteDiscount}
      />
    </>
  );
}
