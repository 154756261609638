/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { CommodityArticle } from "../../../models/ePrescription/VARA/CommodityArticle";
import { CommodityProduct } from "../../../models/ePrescription/VARA/CommodityProduct";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import Field from "../../../components/ReactHookFormFields/General/Field";
import PackageInfoCommodity from "./PackageInfoCommodity";

interface Props {
  commodityProduct?: CommodityProduct;
}

const PackageSelectionCommodity: React.FC<Props> = ({
  commodityProduct,
}: Props) => {
  const [selectedPackage, setSelectedPackage] = useState<CommodityArticle>();

  const {
    control,
    formState: { errors },
    getValues,
    register,
    setValue,
    trigger,
  } = useFormContext();

  useEffect(() => {
    const v = getValues("selectedPackage");
    if (commodityProduct?.articles?.length === 1 && (!v || v?.length === 0)) {
      setValue("selectedPackage", commodityProduct.articles, {
        shouldValidate: true,
      });
      setSelectedPackage(commodityProduct.articles[0]);
    }
  }, [commodityProduct, getValues, setValue]);

  useEffect(() => {
    void trigger();
  }, [trigger]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="min-w-80">
          <CombinedSelect
            allowNew={false}
            className="mb-0"
            control={control}
            label={strings.packages}
            labelKey={(ca: CommodityArticle | any) =>
              `${ca.label} (${ca.title})`
            }
            name="selectedPackage"
            onChange={(p: CommodityArticle[] | null) => {
              setSelectedPackage(p ? p[0] : undefined);
            }}
            options={commodityProduct?.articles || []}
            required
          />
        </div>
        <div className="pl-4">
          <label>{strings.packagingQuantity}</label>
          <Field
            autoComplete={AutoCompleteOptions.off}
            className="mb-0"
            error={errors.numberOfPackages}
            fieldOptions={{
              max: {
                value: 9999,
                message: strings.formatString(strings.mustBeBetween, 1, 10000),
              },
              min: {
                value: 1,
                message: strings.formatString(strings.mustBeBetween, 1, 10000),
              },
            }}
            name="numberOfPackages"
            register={register}
            required
            type="number"
            width="60px"
          />
        </div>
      </div>
      <PackageInfoCommodity selectedPackage={selectedPackage} />
    </>
  );
};

export default PackageSelectionCommodity;
