/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Control } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import logger from "../../../util/logger";
import CountryApi from "../../../api/CountryApi";
import { CountryResponse } from "../../../models/management/CountryResponse";
import { defaultCountryCodeOfClinic } from "../../../common/DefaultCountry";
import { useClinic } from "../../../contexts/ClinicContext";
import CombinedSelect from "../General/Select/CombinedSelect";

interface FieldProps {
  control: Control<any>;
  defaultCountry?: CountryResponse;
  label?: string;
  name?: string;
  optional?: boolean;
  options?: CountryResponse[];
  readOnly?: boolean;
  required?: boolean;
  setValue?(name: string, value: unknown, config?: object): void;
  showRequired?: boolean;
}

const SelectCountry = ({
  control,
  defaultCountry,
  label,
  name,
  optional = false,
  options,
  readOnly,
  required = false,
  setValue,
  showRequired,
}: FieldProps): ReactElement => {
  const [countries, setCountries] = useState<CountryResponse[]>([]);
  const { clinic } = useClinic();

  useEffect(() => {
    if (defaultCountry) {
      if (setValue) {
        setValue(name || "country", [defaultCountry]);
      }
    } else {
      const defaultCountryCode = defaultCountryCodeOfClinic(clinic);
      const filteredCountry = countries?.filter(
        (c) => c.countryCode === defaultCountryCode
      );

      if (filteredCountry) {
        if (setValue) {
          setValue(name || "country", filteredCountry);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic, countries, defaultCountry, setValue]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const resp = await CountryApi.getCountries();
        const sortedCounties = resp.data.sort((c1, c2) =>
          c1.displayName.localeCompare(c2.displayName, undefined, {
            sensitivity: "base",
          })
        );
        setCountries(sortedCounties);
        setCountries(resp.data);
      } catch (e) {
        logger.error(e);
      }
    };

    if (!options) {
      void getCountries();
    } else {
      setCountries(options);
    }
  }, [options]);

  return (
    <CombinedSelect
      allowNew={false}
      control={control}
      name={name || "country"}
      label={label || strings.country}
      labelKey="displayName"
      multiple={false}
      optional={optional}
      options={countries}
      required={required}
      showRequired={showRequired}
      readOnly={readOnly}
    />
  );
};

export default SelectCountry;
