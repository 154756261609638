/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactNode, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { TimeZoneResponse } from "../../../models/time/TimeZoneResponse";
import { AddressRequest } from "../../../models/contact/AddressRequest";
import { LocatedAddressRequest } from "../../../models/contact/LocatedAddressRequest";
import GeoApi from "../../../api/GeoApi";
import { LocationDTO } from "../../../models/geo/LocationDTO";
import {
  getGeneralError,
  removeTrailingDot,
} from "../../../util/helperFunctions";
import CombinedSelect from "../../ReactHookFormFields/General/Select/CombinedSelect";
import TimeZoneApi from "../../../api/TimeZoneApi";
import { strings } from "../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../AlertBox";
import GoogleMap, { MapLocation } from "../../map/GoogleMap";
import CheckBox from "../../ReactHookFormFields/General/CheckBox";
import { Pencil } from "../../../common/Icons/Pencil";
import Button from "../../Button";

interface Props {
  address?: AddressRequest;
  countryName?: string;
  changeAddress(): void;
  isMapCenterToCurrentLocation: boolean;
  setLocalizedAddress(
    address: LocatedAddressRequest,
    onError?: (error: any) => void,
    onFinally?: () => void
  ): void;
}

interface AddressLocationForm {
  timeZone: TimeZoneResponse[] | null;
  location: LocationDTO | null;
  verifiedByUser: boolean;
}

const AddressMapCheck: React.FC<Props> = ({
  address,
  countryName,
  changeAddress,
  isMapCenterToCurrentLocation,
  setLocalizedAddress,
}: Props) => {
  const [mapCenter, setMapCenter] = useState<LocationDTO | null>(null);
  const [locationDetected, setLocationDetected] = useState(false);
  const [timeZones, setTimeZones] = useState<TimeZoneResponse[]>([]);

  const [mapLoading, setMapLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isSubmitted, isValid, errors },
  } = useForm<AddressLocationForm>({
    defaultValues: { verifiedByUser: false },
    mode: "onChange",
  });

  const getAddressString = (): ReactNode => {
    const isAddressValid = !!(
      address?.street &&
      address?.city &&
      address?.countryCode &&
      address?.zip
    );

    if (isAddressValid) {
      const formattedAddress = `${removeTrailingDot(address.street)}, ${
        address.city
      }`;
      const countryAndZip = `${countryName} ${address.zip}`;

      return (
        <>
          {address.houseDetails && (
            <>
              {address.houseDetails}
              <br />
            </>
          )}
          {formattedAddress}
          <br />
          {countryAndZip}
        </>
      );
    }

    return <AlertBox message={`${strings.invalidAddress}!`} />;
  };

  useEffect(() => {
    if ("geolocation" in navigator && isMapCenterToCurrentLocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setMapCenter({ lat: coords.latitude, lng: coords.longitude });
      });
    }
  }, [isMapCenterToCurrentLocation]);

  useEffect(() => {
    if (!address) return;

    const transformAddresses = async (addresses: AddressRequest[]) => {
      if (!addresses) return;
      setMapLoading(true);
      try {
        const googleResponse = await GeoApi.getGeoFromAddresses(addresses);
        const { data } = googleResponse;
        if (
          Array.isArray(data) &&
          Array.isArray(data[0]) &&
          data[0].length !== 0
        ) {
          const coordinates = GeoApi.getLocationFromResponse(
            googleResponse.data[0]
          );
          setValue("location", coordinates);
          setMapCenter(coordinates);
          setLocationDetected(true);
        }
      } catch (err) {
        setError(await getGeneralError(err));
        setLocationDetected(false);
      } finally {
        setMapLoading(false);
      }
    };

    void transformAddresses([address]);
  }, [address, setValue]);

  useEffect(() => {
    const getTimeZones = async () => {
      try {
        const resp = await TimeZoneApi.getTimeZones();
        setTimeZones(resp.data);
      } catch (err) {
        setError(await getGeneralError(err));
      }
    };

    void getTimeZones();
  }, []);

  useEffect(() => {
    if (!address) return;

    const guessedTimeZone = timeZones.find((t) =>
      t.zoneId.includes(address.city)
    );

    const defaultTimeZone = timeZones.find((t) =>
      t.zoneId.includes(moment.tz.guess())
    );

    if (guessedTimeZone) {
      setValue("timeZone", [guessedTimeZone]);
    } else if (defaultTimeZone) {
      setValue("timeZone", [defaultTimeZone]);
    }
  }, [address, setValue, timeZones]);

  const onError = async (err: any) => {
    setError(await getGeneralError(err));
  };

  const onFinally = () => {
    setLoading(false);
  };

  const submit = ({ location, timeZone }: AddressLocationForm) => {
    if (!address) return;

    setLoading(true);
    if (location && timeZone) {
      const request: LocatedAddressRequest = {
        ...address,
        zoneId: timeZone[0].zoneId,
        latitude: location.lat,
        longitude: location.lng,
      };
      setLocalizedAddress(request, onError, onFinally);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between space-x-4">
        <span className="font-semibold text-zinc-800 dark:text-zinc-300">
          {getAddressString()}
        </span>
        <Button
          aria-label={strings.edit}
          className="tw-icon"
          onClick={changeAddress}
          variant="link"
        >
          <span className="sr-only">{strings.edit}</span>
          <Pencil />
        </Button>
      </div>
      <AlertBox
        hidden={mapLoading}
        message={
          locationDetected
            ? strings.locationDetectedByAddress
            : strings.addressNotFound
        }
        type={locationDetected ? AlertType.SUCCESS : AlertType.ERROR}
      />
      <p>{strings.checkOnTheMap}</p>
      <form className="space-y-6" onSubmit={handleSubmit(submit)}>
        <Controller
          control={control}
          name="location"
          rules={{ required: true }}
          render={({
            field: { onChange, value },
            fieldState: { error: e },
          }) => (
            <div style={{ border: e ? "1px solid red" : "" }}>
              <GoogleMap
                center={mapCenter !== null ? mapCenter : undefined}
                location={value || undefined}
                mapHeight="320px"
                mapWidth="100%"
                onClick={(l: MapLocation) => {
                  onChange(l);
                }}
              />
            </div>
          )}
        />
        <CheckBox
          error={errors.verifiedByUser}
          fieldOptions={{
            validate: { isChecked: (v) => !!v },
          }}
          label={strings.iHaveVerifiedTheTimeZoneAndLocation}
          name="verifiedByUser"
          register={register}
          required
        />
        <CombinedSelect
          allowNew={false}
          control={control}
          isTypeahead
          label={strings.timeZone}
          labelKey="zoneId"
          multiple={false}
          name="timeZone"
          options={timeZones}
          placeholder={strings.chooseFromWithDots}
          required
          showRequired
        />
        <AlertBox message={error} />
        <AlertBox
          hidden={
            errors.location?.type !== "required" &&
            errors.timeZone?.type !== "required" &&
            errors.verifiedByUser?.type !== "required"
          }
          message={strings.pleaseFillRequiredFields}
        />
        <Button
          disabled={isSubmitting || loading}
          loading={loading}
          type="submit"
          variant="primary"
        >
          {strings.saveAndContinue}
        </Button>
      </form>
    </div>
  );
};

export default AddressMapCheck;
