/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { FieldError } from "react-hook-form";
import React, { ReactElement, useEffect, useState } from "react";
import { Tooltip } from "../Tooltip";
import { Colors } from "../../models/Colors";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { Option } from "../../models/Option";

interface FieldProps {
  name: string;
  options: Option[];
  autoComplete?: AutoCompleteOptions;
  classLabel?: string;
  className?: string;
  disabledChooseOptionTitle?: string;
  error?: FieldError;
  firstOption?: Option;
  isChooseOption?: boolean;
  label?: string;
  labelIcon?: string;
  labelOff?: boolean;
  onChange?(e: React.ChangeEvent<HTMLSelectElement>): void;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  separationEnabled?: boolean;
  sm?: boolean;
  toolTipText?: string;
  value?: any;
  width?: string;
}

const SimpleSelect = ({
  name,
  options,
  autoComplete,
  disabledChooseOptionTitle,
  classLabel,
  className,
  error,
  firstOption,
  isChooseOption = true,
  label,
  labelIcon,
  labelOff,
  onChange,
  optional,
  readOnly,
  required,
  separationEnabled,
  toolTipText,
  value: v,
  width,
}: FieldProps): ReactElement => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (v) {
      setValue(v);
    } else {
      setValue("");
    }
  }, [v]);

  const labelClass = classLabel || "";

  const optionList = options.map((option: Option) => (
    <option value={option.value} key={option.value}>
      {separationEnabled
        ? option.title
            .split(" ")
            .map((str: string) => getStringFromEnumsOrReturn(str))
            .map((result: string) => `${result} `)
        : getStringFromEnumsOrReturn(option.title)}
    </option>
  ));

  return (
    <div
      className={`${className} ${error ? "has-error" : ""}`}
      style={{ width }}
    >
      {labelOff ? (
        <></>
      ) : (
        <label className={labelClass} htmlFor={name}>
          {labelIcon ? <i className={labelIcon} /> : ""} {label}
          {optional ? (
            <span style={{ color: Colors.INPUTBORDER }}>
              {" "}
              ({strings.optional})
            </span>
          ) : (
            <></>
          )}
        </label>
      )}
      <div className="d-flex align-items-center">
        <select
          autoComplete={autoComplete || AutoCompleteOptions.on}
          className="tw-input"
          disabled={readOnly}
          name={name}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (onChange) {
              onChange(e);
            }

            setValue(e.target.value);
          }}
          required={required}
          value={value}
        >
          {isChooseOption ? (
            <option value="" disabled>
              {disabledChooseOptionTitle || strings.pleaseChoose}
            </option>
          ) : (
            <></>
          )}
          {firstOption ? (
            <option value={firstOption.value}>{firstOption.title}</option>
          ) : (
            <></>
          )}
          {optionList}
        </select>
        {toolTipText ? (
          <div
            style={{
              borderBottomLeftRadius: "0",
              borderTopLeftRadius: "0",
              fontSize: "20px",
              borderLeft: "0",
            }}
          >
            <Tooltip content={toolTipText} placement="top">
              <i className="fas fa-info-circle text-sky-500 ml-2" />
            </Tooltip>
          </div>
        ) : (
          <></>
        )}
      </div>
      <p>{error && error.message?.toString()}</p>
    </div>
  );
};

export default SimpleSelect;

SimpleSelect.defaultProps = {
  autoComplete: AutoCompleteOptions.on,
  isChooseOption: true,
  classLabel: undefined,
  className: undefined,
  error: undefined,
  firstOption: undefined,
  label: undefined,
  labelIcon: undefined,
  labelOff: false,
  onChange: undefined,
  optional: false,
  readOnly: false,
  required: false,
  separationEnabled: false,
  sm: false,
  toolTipText: undefined,
  value: undefined,
};
