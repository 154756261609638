/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import StatusSignal from "../StatusSignal";
import { prescriptionRegexp } from "../../../util/Validations";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";

const CommentToThePharmacistSection: React.FC = () => {
  const {
    register,
    formState: { errors, isValid },
  } = useFormContext();

  return (
    <div className="tw-card space-y-4 p-4">
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
        {strings.commentToPharmacist}
      </h2>
      <form>
        <Textarea
          register={register}
          name="prescriptionComment"
          allowMoreCharactersToInput
          autoComplete={AutoCompleteOptions.off}
          error={errors.prescriptionComment}
          fieldOptions={{
            maxLength: 35,
            pattern: {
              value: prescriptionRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          maxLength={35}
          minRows={2}
          placeholder={strings.commentToPharmacist}
          showCharacterCount
        />
      </form>
    </div>
  );
};

export default CommentToThePharmacistSection;
