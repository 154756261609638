/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  RegisterOptions,
} from "react-hook-form";
import React, { ReactElement, useEffect, useState } from "react";
import { Tooltip } from "../../Tooltip";
import { Colors } from "../../../models/Colors";
import { strings } from "../../../common/Strings/Strings";
import { InputModeEnum } from "../../../models/InputModeEnum";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { RegisterReturnType } from "../../../models/RegisterReturnType";
import { InfoFilled } from "../../../common/Icons/InfoFilled";

interface FieldProps {
  name: string;
  register(ref: string, registerOptions?: RegisterOptions): RegisterReturnType;
  allowMoreCharactersToInput?: boolean;
  autoComplete?: AutoCompleteOptions;
  classInput?: string;
  classLabel?: string;
  className?: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  fieldOptions?: RegisterOptions;
  inputMode?: InputModeEnum;
  label?: string;
  labelIcon?: string;
  labelOff?: boolean;
  maxLength?: number;
  maxRows?: number;
  minRows?: number;
  onChange?(e: string): void;
  optional?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  showCharacterCount?: boolean;
  showRequired?: boolean;
  toolTipText?: string | JSX.Element;
  width?: string;
}

const Textarea = ({
  name,
  register,
  allowMoreCharactersToInput,
  autoComplete,
  classInput,
  classLabel,
  className,
  error,
  fieldOptions,
  inputMode,
  label,
  labelIcon,
  labelOff,
  maxLength,
  maxRows = 50,
  minRows = 1,
  onChange,
  optional,
  placeholder = label,
  readOnly,
  required,
  showCharacterCount,
  showRequired,
  toolTipText,
  width,
}: FieldProps): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<RegisterOptions>(
    fieldOptions || {}
  );
  const [value, setValue] = useState<string>("");
  const [rows, setRows] = useState<number>(minRows);

  const alignRows = (newValue: string) => {
    const dataLength = newValue.split(/\r\n|\r|\n/).length;

    if (dataLength > minRows) {
      setRows(dataLength);
    }
    if (dataLength > maxRows) {
      setRows(maxRows);
    }
  };

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  useEffect(() => {
    if (maxLength) {
      setRegisterOptions({
        ...registerOptions,
        maxLength: {
          value: maxLength,
          message: strings.tooManyCharactersEntered,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxLength]);

  const inputModeForMobile = inputMode || InputModeEnum.text;

  return (
    <div
      className={`${className || ""} ${error ? "has-error" : ""}`}
      style={{ width }}
    >
      {labelOff ? (
        <></>
      ) : (
        <label htmlFor={name} className={classLabel}>
          {labelIcon ? <i className={labelIcon} /> : ""} {label}
          {showRequired ? " *" : ""}
          {optional ? (
            <span style={{ color: Colors.INPUTBORDER }}>
              {" "}
              ({strings.optional})
            </span>
          ) : (
            <></>
          )}
        </label>
      )}
      <div className="relative w-full">
        <textarea
          {...register(name, registerOptions)}
          autoComplete={autoComplete || AutoCompleteOptions.on}
          className={classInput || `tw-input ${!!toolTipText && "pr-10"}`}
          inputMode={inputModeForMobile}
          maxLength={allowMoreCharactersToInput ? undefined : maxLength}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (showCharacterCount && maxLength) {
              setValue(e.target.value);
            }

            alignRows(e.target.value);

            void register(name).onChange(e);
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          placeholder={placeholder}
          readOnly={readOnly}
          rows={rows}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-2">
          {toolTipText ? (
            <div>
              <Tooltip content={toolTipText} placement="top">
                <span>
                  <InfoFilled className="text-sky-500" />
                </span>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex w-full items-center">
        <p>{error && error.message?.toString()}</p>
        {showCharacterCount && maxLength ? (
          <div className="ml-auto">
            {value && value.length > maxLength ? (
              <span className="text-red-500 text-xs">
                {`${strings.formatString(
                  strings.charactersAbove,
                  value ? Math.abs(maxLength - value.length) : 0
                )}`}
              </span>
            ) : (
              <span className="text-zinc-400 text-xs">
                {`${strings.formatString(
                  strings.charactersLeft,
                  value ? maxLength - value.length : maxLength
                )}`}
              </span>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Textarea;

Textarea.defaultProps = {
  autoComplete: AutoCompleteOptions.on,
  allowMoreCharactersToInput: false,
  classInput: undefined,
  classLabel: undefined,
  className: undefined,
  error: undefined,
  fieldOptions: undefined,
  inputMode: undefined,
  label: undefined,
  labelIcon: undefined,
  labelOff: false,
  maxLength: undefined,
  maxRows: 50,
  minRows: 1,
  onChange: undefined,
  optional: false,
  placeholder: undefined,
  readOnly: false,
  required: false,
  showCharacterCount: false,
  toolTipText: undefined,
  width: undefined,
};
