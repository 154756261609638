/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import AddAddressSteps from "../../../../components/contact/address/AddAddressSteps";
import UserApi from "../../../../api/UserApi";
import { LocatedAddressRequest } from "../../../../models/contact/LocatedAddressRequest";
import { getGeneralError } from "../../../../util/helperFunctions";
import { strings } from "../../../../common/Strings/Strings";
import { Card } from "../../../../components/Cards/Card";

interface Props {
  onNextStep(skip: boolean): void;
  skippable?: boolean;
}

const AddressSetup = ({
  onNextStep,
  skippable = false,
}: Props): ReactElement => {
  const [error, setError] = useState<string | null>(null);

  const submitAddress = async (address: LocatedAddressRequest) => {
    try {
      await UserApi.setupUserProfileAddress({ address });
      onNextStep(false);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  };

  return (
    <Card
      footerText={strings.setupYourProfileLater}
      onAction={skippable ? () => onNextStep(true) : undefined}
      title={strings.yourAddress}
    >
      <AddAddressSteps error={error} setAddress={submitAddress} />
    </Card>
  );
};

export default AddressSetup;
