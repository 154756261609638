/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import StatisticsApi from "../../../api/StatisticsApi";
import { strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import { Option } from "../../../models/Option";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { MonthlyClinicCostsResponse } from "../../../models/statistics/MonthlyClinicCostsResponse";
import CostSummarySection from "./CostSummarySection";
import MonthSelectOfStats from "./MonthSelectOfStats";
import { Card } from "../../../components/Cards/Card";

interface Props {
  clinic?: ClinicResponse;
}

const CostStatisticsTab: React.FC<Props> = ({ clinic }: Props) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [months, setMonths] = useState<Option[]>([]);
  const [currentMonth, setCurrentMonth] = useState<string | number>();

  const [costs, setCosts] = useState<Map<string, MonthlyClinicCostsResponse>>();
  const [currentCosts, setCurrentCosts] =
    useState<MonthlyClinicCostsResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!clinic) return;

    const getClinicCosts = async () => {
      if (!clinic) return;
      setLoading(true);
      try {
        const response = await StatisticsApi.getClinicMonthlyCosts(clinic.id);

        const list: Map<string, MonthlyClinicCostsResponse> = new Map();
        const monthList: Option[] = [];

        response.data.forEach((fees) => {
          monthList.push({
            value: fees.month,
            title: moment(fees.month, undefined, strings.getLanguage()).format(
              "YYYY MMMM"
            ),
          });

          list.set(fees.month, fees);
        });

        setMonths(monthList);
        setCurrentMonth(monthList[0]?.value);
        setCosts(list);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void getClinicCosts();
  }, [clinic]);

  useEffect(() => {
    if (!currentMonth) return;
    setCurrentCosts(costs?.get(currentMonth.toString()));
  }, [currentMonth, costs]);

  return (
    <Card
      actionText={strings.clinics}
      loading={loading}
      onAction={() => navigate("/globalvet/clinics")}
      size="lg"
      title={strings.costSummary}
      type="simple"
    >
      <AlertBox
        type={AlertType.INFO}
        hidden={months.length > 0 || error !== null || loading}
        message={strings.statisticsErrorMsg}
        closeAble={false}
      />
      <AlertBox message={error} />
      <div hidden={months.length <= 0 || error != null}>
        <div className="flex">
          <MonthSelectOfStats
            months={months}
            setCurrentMonth={setCurrentMonth}
          />
        </div>
      </div>
      <CostSummarySection
        costs={currentCosts}
        hideForecast={moment().format("YYYY-MM") !== currentMonth}
      />
    </Card>
  );
};

export default CostStatisticsTab;
