/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../common/Strings/Strings";
import StripeIntegration from "./Topics/StripeIntegration";
import SzamlazzIntegrationSteps from "./Topics/SzamlazzIntegrationSteps";
import { Link, useParams } from "react-router-dom";
import { ChevronRight } from "../../common/Icons/ChevronRight";

export enum SupportTopics {
  all = "all",
  stripe = "stripe",
  szamlazz = "szamlazz",
}

const Topics: React.FC = () => {
  const { topic } = useParams();

  const getContent = (t: SupportTopics) => {
    switch (t) {
      case "all":
        return (
          <>
            <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
              {strings.supportTopics}
            </h2>
            <div className="grid grid-cols-12 gap-6">
              <StripeIntegration />
              <SzamlazzIntegrationSteps />
            </div>
          </>
        );
      case "szamlazz":
        return (
          <>
            <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex items-center space-x-2">
              <Link to={`/help/topics/${SupportTopics.all}`}>
                {strings.supportTopics}
              </Link>{" "}
              <ChevronRight /> {strings.szamlazzIntegration}
            </h2>
            <div className="grid grid-cols-12 gap-6">
              <SzamlazzIntegrationSteps />
            </div>
          </>
        );
      case "stripe":
        return (
          <>
            <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex items-center space-x-2">
              <Link to={`/help/topics/${SupportTopics.all}`}>
                {strings.supportTopics}
              </Link>{" "}
              <ChevronRight /> {strings.stripeIntegration}
            </h2>
            <div className="grid grid-cols-12 gap-6">
              <StripeIntegration />
            </div>
          </>
        );
      default:
        return (
          <>
            <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
              {strings.supportTopics}
            </h2>
            <div className="grid grid-cols-12 gap-6">
              <StripeIntegration />
              <SzamlazzIntegrationSteps />
            </div>
          </>
        );
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.helpCenter}
            </h1>
            <div className="space-y-4">
              {getContent(topic as SupportTopics)}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Topics;
