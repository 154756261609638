/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getGeneralError } from "../../../util/helperFunctions";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import InvoiceApi from "../../../api/InvoiceApi";
import { strings } from "../../../common/Strings/Strings";
import EmailReminders from "./EmailReminders";
import PaymentHistory from "./PaymentHistory";
import { CostEntryResponse } from "../../../models/costaccounting/CostEntryResponse";
import CostAccountingApi from "../../../api/CostAccountingApi";
import InvoiceInfos from "./InvoiceInfos";
import InvoicePairDetails from "./InvoicePairDetails";
import UrlTab from "../../../components/Tabs/UrlTab";
import LocationIndicator from "../../../components/Tabs/LocationIndicator";

interface Props {
  longInvoiceId?: string;
  invoiceId?: string;
  setPageLoading: (isLoading: boolean) => void;
}

type InvoiceDetailsTabKey = "basic" | "email-reminders" | "payment-history";

const InvoiceDetails: React.FC<Props> = (props: Props) => {
  const { longInvoiceId, invoiceId, setPageLoading } = props;

  const [key, setKey] = useState<InvoiceDetailsTabKey>("basic");
  const [invoice, setInvoice] = useState<InvoiceResponse>();
  const [error, setError] = useState<string | null>(null);
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [reloadInvoice, setReloadInvoice] = useState<boolean>(false);

  const withdrawPayment = async (entry: CostEntryResponse) => {
    try {
      if (!invoice) {
        return;
      }
      setPageLoading(true);
      const response = await CostAccountingApi.withdrawPayment(
        invoice.id,
        entry.id
      );
      setInvoice(response.data);
      setPaymentError(null);
      setPageLoading(false);
    } catch (e) {
      setPaymentError(await getGeneralError(e));
      setPageLoading(false);
    }
  };

  const syncPayment = async (entry: CostEntryResponse) => {
    try {
      if (!invoice) {
        return;
      }
      setPageLoading(true);
      const response = await CostAccountingApi.syncPayment(
        invoice.id,
        entry.id
      );
      setInvoice(response.data);
      setPaymentError(null);
      setPageLoading(false);
    } catch (e) {
      setPaymentError(await getGeneralError(e));
      setPageLoading(false);
    }
  };

  useEffect(() => {
    const getInvoice = async () => {
      try {
        setPageLoading(true);
        if (longInvoiceId) {
          const response = await InvoiceApi.getInvoiceByLongId(longInvoiceId);
          setInvoice(response.data);
        } else if (invoiceId) {
          const response = await InvoiceApi.getInvoice(invoiceId);
          setInvoice(response.data);
        }
        setError(null);
        setPageLoading(false);
      } catch (e) {
        setError(await getGeneralError(e));
        setPageLoading(false);
      }
    };

    void getInvoice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, longInvoiceId, reloadInvoice]);

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div>
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.invoiceDetails}
            </h1>
          </div>
          <div className="border border-gray-200 rounded-md bg-gray-50 !p-4 text-center text-md font-medium text-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300">
            <ul className="flex flex-wrap -mb-px">
              <UrlTab
                useBaseUrlOnly
                currentTabKey={key}
                tabKey="basic"
                title={
                  invoice?.paidImmediately
                    ? strings.cashInvoice
                    : strings.invoice
                }
              />
              <UrlTab
                currentTabKey={key}
                tabKey="payment-history"
                title={strings.paymentHistory}
              />
              <UrlTab
                currentTabKey={key}
                tabKey="email-reminders"
                title={strings.emailReminders}
              />
            </ul>
          </div>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <LocationIndicator
                    location="basic"
                    setLocation={(location: InvoiceDetailsTabKey) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <InvoiceInfos
                      error={error}
                      isCustomerView={!!longInvoiceId}
                      isPairInvoice={false}
                      invoice={invoice}
                      reload={() => {
                        setReloadInvoice(!reloadInvoice);
                      }}
                      setError={setError}
                      setInvoice={setInvoice}
                    />
                    <InvoicePairDetails
                      isCustomerView={!!longInvoiceId}
                      invoicePairId={invoice?.relatedInvoiceId}
                    />
                  </div>
                </>
              }
            />
            <Route
              path="payment-history"
              element={
                <>
                  <LocationIndicator
                    location="payment-history"
                    setLocation={(location: InvoiceDetailsTabKey) => {
                      setKey(location);
                    }}
                  />
                  {invoice && (
                    <PaymentHistory
                      error={paymentError}
                      invoice={invoice}
                      reload={() => {
                        setReloadInvoice(!reloadInvoice);
                      }}
                      syncPayment={syncPayment}
                      withdrawPayment={withdrawPayment}
                    />
                  )}
                </>
              }
            />
            <Route
              path="email-reminders"
              element={
                <>
                  <LocationIndicator
                    location="email-reminders"
                    setLocation={(location: InvoiceDetailsTabKey) => {
                      setKey(location);
                    }}
                  />
                  {invoice && (
                    <EmailReminders
                      allowSendNew={!longInvoiceId}
                      invoice={invoice}
                      setInvoice={setInvoice}
                    />
                  )}
                </>
              }
            />
          </Routes>
        </div>
      </section>
    </main>
  );
};

export default InvoiceDetails;
