/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import { prescriptionRegexp } from "../../../../util/Validations";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import { getStreetAndHouseDetails } from "../../../../util/helperFunctions";
import { PersonDetailsResponse } from "../../../../models/contact/PersonDetailsResponse";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { PublicAddressResponse } from "../../../../models/contact/PublicAddressResponse";
import CombinedSelectComponent from "../../../../components/InputFieldsSimple/CombinedSelectComponent";
import { DeliverySelectionTypeEnum } from "../../../../models/ePrescription/local/DeliverySelectionTypeEnum";

interface Props {
  owner?: PersonDetailsResponse;
}

const HomeDeliveryAddress: React.FC<Props> = ({ owner }: Props) => {
  const {
    getValues,
    formState: { errors },
    register,
    setValue,
    trigger,
  } = useFormContext();

  const isHomeDelivery = (v: string | undefined) => {
    const type = getValues();
    if (type.deliveryType === DeliverySelectionTypeEnum.HOME) {
      return v && v.length > 0;
    }
    return true;
  };

  return (
    <>
      <div
        hidden={!owner || owner?.addresses?.length === 0}
      >
        <CombinedSelectComponent
          label={strings.availableAddresses}
          labelKey={(a: PublicAddressResponse | any) =>
            `${getStreetAndHouseDetails(a)}, ${a.zip}, ${a.city}`
          }
          name="addresses"
          onChange={(address: PublicAddressResponse[] | null) => {
            const a = address ? address[0] : null;

            if (a) {
              setValue("streetAddress", getStreetAndHouseDetails(a));
              setValue("city", a.city);
              setValue("postalCode", a.zip);
              setValue("building", a.houseDetails);

              void trigger();
            }
          }}
          optional
          options={owner?.addresses || []}
          value={[]}
        />
      </div>
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.city}
        fieldOptions={{
          maxLength: 28,
          pattern: {
            value: prescriptionRegexp,
            message: strings.notAllowedCharacter,
          },
          validate: {
            isHomeDelivery,
          },
        }}
        label={strings.city}
        maxLength={28}
        name="city"
        register={register}
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        disableNotNumberCharacters
        error={errors.postalCode}
        fieldOptions={{
          minLength: 1,
          maxLength: 6,
          pattern: {
            value: /^[0-9 ]*$/,
            message: strings.notAllowedCharacter,
          },
          validate: {
            isHomeDelivery,
          },
        }}
        label={strings.zip}
        maxLength={6}
        name="postalCode"
        register={register}
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.streetAddress}
        fieldOptions={{
          maxLength: 35,
          pattern: {
            value: prescriptionRegexp,
            message: strings.notAllowedCharacter,
          },
          validate: {
            isHomeDelivery,
          },
        }}
        label={strings.street}
        maxLength={35}
        name="streetAddress"
        register={register}
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        error={errors.building}
        fieldOptions={{
          maxLength: 35,
          pattern: {
            value: prescriptionRegexp,
            message: strings.notAllowedCharacter,
          },
        }}
        label={strings.building}
        maxLength={35}
        name="building"
        optional
        register={register}
      />
    </>
  );
};

export default HomeDeliveryAddress;

HomeDeliveryAddress.defaultProps = {
  owner: undefined,
};
