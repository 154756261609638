/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { strings } from "../../../common/Strings/Strings";
import MonthCalendarNavigationMobile from "../Day/MonthCalendarNavigationMobile";
import { ChevronRight } from "../../../common/Icons/ChevronRight";
import { ChevronLeft } from "../../../common/Icons/ChevronLeft";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";

interface Props {
  close(): void;
  open: boolean;
  selectedDay: string;
  setSelectedDay(newDay: string): void;
}

const YearSelectModal: React.FC<Props> = (props: Props) => {
  const { close, open, selectedDay, setSelectedDay } = props;
  const [year, setYear] = useState<number>(moment(selectedDay).year());
  const [localSelectedDay, setLocalSelectedDay] = useState<string>();
  const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  useEffect(() => {
    if (localSelectedDay) {
      setSelectedDay(localSelectedDay);
      close();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localSelectedDay]);

  const nextYear = () => {
    if (year + 1 < 2100) {
      setYear(year + 1);
    }
  };

  const previousYear = () => {
    if (year - 1 > 1900) {
      setYear(year - 1);
    }
  };

  return (
    <Modal show={open} handleClose={close} size="modal-lg">
      <Modal.Header>
        <div className="flex space-x-4">
          <Button
            variant="link"
            onClick={() => {
              setLocalSelectedDay(moment().format());
            }}
          >
            <span className="tw-link-tercier">{strings.today}</span>
          </Button>
          <Button variant="icon" onClick={previousYear}>
            <ChevronLeft />
          </Button>
          <Button variant="icon" onClick={nextYear}>
            <ChevronRight />
          </Button>
          <div className="font-bold">{year}</div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {months.map((value, index) => {
            const monthDate = moment(selectedDay).month(value).year(year);

            return (
              <div key={index}>
                <div className="text-sm">{monthDate.format("MMMM")}</div>
                <MonthCalendarNavigationMobile
                  inputDate={monthDate.format("YYYY-MM-DD")}
                  localSelectedDay={localSelectedDay}
                  setLocalSelectedDay={(newDate: string) => {
                    setLocalSelectedDay(newDate);
                  }}
                />
              </div>
            );
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default YearSelectModal;
