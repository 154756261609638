/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { MedicalRecordStatus } from "../../../models/medical/MedicalRecordStatus";
import {
  dateAndTime,
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import { MedicalRecordResponse } from "../../../models/medical/MedicalRecordResponse";
import { MedicalRecordVersionResponse } from "../../../models/medical/MedicalRecordVersionResponse";
import Created from "./Created";
import Signed from "./Signed";
import InvoiceApi from "../../../api/InvoiceApi";
import logger from "../../../util/logger";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { ReservationResponse } from "../../../models/reservation/ReservationResponse";
import { ReservationState } from "../../../models/reservation/ReservationState";
import Payment from "./Payment";
import ClickOutsideDetector from "../../../components/ClickOutsideDetector";
import { ChevronDown } from "../../../common/Icons/ChevronDown";
import Button from "../../../components/Button";

interface Props {
  dropUp?: boolean;
  medicalRecordData?: MedicalRecordResponse;
  medicalRecordVersions?: MedicalRecordVersionResponse[];
  reservationData?: ReservationResponse;
}

const VersionSection = ({
  dropUp = false,
  medicalRecordData,
  medicalRecordVersions,
  reservationData,
}: Props): ReactElement => {
  const [isDown, setIsDown] = useState<boolean>(false);
  const [originalMedicalRecord, setOriginalMedicalRecord] =
    useState<MedicalRecordVersionResponse>();
  const [medicalRecords, setMedicalRecords] = useState<
    MedicalRecordVersionResponse[]
  >([]);
  const [currentMedicalRecord, setCurrentMedicalRecord] =
    useState<MedicalRecordVersionResponse>();
  const [invoice, setInvoice] = useState<InvoiceResponse>();
  const [paymentIndex, setPaymentIndex] = useState<number>();

  useEffect(() => {
    if (medicalRecordVersions && medicalRecordVersions?.length > 0) {
      const original = medicalRecordVersions[medicalRecordVersions.length - 1];
      setOriginalMedicalRecord(original);

      if (original.status === MedicalRecordStatus.SIGNED) {
        setMedicalRecords([
          ...medicalRecordVersions,
          {
            ...original,
            status: MedicalRecordStatus.IN_PROGRESS,
            signDate: undefined,
          },
        ]);
      } else {
        setMedicalRecords(medicalRecordVersions);
      }
    }
    if (medicalRecordData) {
      setCurrentMedicalRecord({
        creationDate: medicalRecordData.creationDate,
        createdBy: medicalRecordData.createdBy,
        id: medicalRecordData.id,
        status: medicalRecordData.status,
        signedBy: medicalRecordData.signedBy,
        signDate: medicalRecordData.signDate,
      });
    }
  }, [medicalRecordVersions, medicalRecordData]);

  const getInvoiceData = async (invoiceId: string) => {
    try {
      const response = await InvoiceApi.getInvoice(invoiceId);
      setInvoice(response.data);
    } catch (e) {
      logger.error(e);
    }
  };

  useEffect(() => {
    if (medicalRecordData && medicalRecordData.invoiceId) {
      void getInvoiceData(medicalRecordData.invoiceId);
    }
  }, [medicalRecordData]);

  const returnIndex = (): number | undefined => {
    if (invoice) {
      for (let i = 0; i < medicalRecords.length; i += 1) {
        const time =
          medicalRecords[i].signDate || medicalRecords[i].creationDate;
        if (!moment(invoice.creationDate).isBefore(time)) {
          return i;
        }
      }
    }

    return undefined;
  };

  useEffect(() => {
    let newIndex = returnIndex();

    if (newIndex === undefined) {
      setPaymentIndex(medicalRecords.length);
    } else {
      setPaymentIndex(newIndex);
    }
  }, [medicalRecords, invoice]);

  const getVersionTitle = () => {
    const isTheOriginal =
      currentMedicalRecord?.id === originalMedicalRecord?.id;

    const reservationState = (
      <div hidden={!medicalRecordData?.invoiceId}>
        <Button
          variant={
            reservationData?.state === ReservationState.READY_FOR_PAYMENT
              ? "pill-tertiary"
              : reservationData?.state === ReservationState.PAID
              ? "pill-green"
              : "pill-red"
          }
          small
        >
          {getStringFromEnumsOrReturn(reservationData?.state)}
        </Button>
      </div>
    );

    switch (currentMedicalRecord?.status) {
      case MedicalRecordStatus.IN_PROGRESS:
        return (
          <>
            <Button
              variant={isTheOriginal ? "pill-tertiary" : "pill-primary"}
              small
            >
              {isTheOriginal ? strings.created : strings.underCorrection}
            </Button>
            {reservationState}
            <div className="text-sm text-zinc-800 dark:text-white">
              {currentMedicalRecord?.createdBy?.fullName} (
              {currentMedicalRecord?.creationDate &&
                moment(currentMedicalRecord?.creationDate).format(
                  dateAndTime.momentDateTimeFormat
                )}
              )
            </div>
          </>
        );
      case MedicalRecordStatus.SIGNED:
        return (
          <>
            <Button variant="pill-primary" small>
              {isTheOriginal ? strings.signed : strings.correctionSigned}
            </Button>
            {reservationState}
            <div className="text-sm text-zinc-800 dark:text-white">
              {currentMedicalRecord?.signedBy?.fullName} (
              {currentMedicalRecord?.signDate &&
                moment(currentMedicalRecord?.signDate).format(
                  dateAndTime.momentDateTimeFormat
                )}
              )
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const getVersion = (
    record: MedicalRecordVersionResponse,
    corrected: boolean
  ) => {
    switch (record.status) {
      case MedicalRecordStatus.IN_PROGRESS:
        return (
          <Created
            creator={record.createdBy}
            date={record.creationDate}
            hr={corrected}
            title={corrected ? strings.underCorrection : strings.created}
          />
        );
      case MedicalRecordStatus.SIGNED:
        return (
          <Signed
            medicalRecord={medicalRecordData}
            showLink={record.id !== currentMedicalRecord?.id}
            title={corrected ? strings.correctionSigned : strings.signed}
            version={record}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="dropdown">
      <ClickOutsideDetector
        listen
        onClickOutside={() => {
          setIsDown(false);
        }}
      >
        <div
          className="tw-card w-auto flex justify-between items-center space-x-2 p-3 cursor-pointer"
          onClick={() => {
            setIsDown(!isDown);
          }}
          role="button"
          tabIndex={0}
        >
          <div className="flex items-center space-x-2">{getVersionTitle()}</div>

          <ChevronDown />
        </div>
        <div
          className={`dropdown-medical-record-status absolute z-10 shadow-md rounded-lg w-full lg:w-96 bg-white dark:bg-gray-700 ${
            isDown ? "block" : "hidden"
            } ${dropUp ? " bottom-[52px] " : ""}
          `}
        >
          <ul className="px-5 py-6 flex flex-col space-y-5">
            <div hidden={!invoice || paymentIndex !== 0}>
              <Payment hr invoice={invoice} reservation={reservationData} />
            </div>
            {medicalRecords.slice(0, paymentIndex).map((record, index) => {
              return (
                <Fragment key={index}>
                  {getVersion(record, record.id !== originalMedicalRecord?.id)}
                </Fragment>
              );
            })}
            <div hidden={!invoice || paymentIndex === 0}>
              <Payment hr invoice={invoice} reservation={reservationData} />
            </div>
            {medicalRecords
              .slice(paymentIndex, medicalRecords.length)
              .map((record, index) => {
                return (
                  <Fragment key={index}>
                    {getVersion(
                      record,
                      record.id !== originalMedicalRecord?.id
                    )}
                  </Fragment>
                );
              })}
          </ul>
        </div>
      </ClickOutsideDetector>
    </div>
  );
};

export default VersionSection;
