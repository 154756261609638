/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../../Strings/Strings";
import CodeInputsForm from "../../../../../components/ReactHookFormFields/Specific/CodeInputsForm";
import LoaderInline from "../../../../../components/LoaderInline";
import AlertBox from "../../../../../components/AlertBox";

interface Props {
  error: string | null;
  loading: boolean;
  submitSwitchAccountForm: (pinCode: string) => void;
}

const PinNumberForm: React.FC<Props> = ({
  error,
  loading,
  submitSwitchAccountForm,
}: Props) => (
  <div className="flex flex-col flex-grow justify-center items-center">
    <p className="text-xl">{strings.enterPin}</p>
    <div className="mt-5 mb-2">
      <CodeInputsForm
        submit={submitSwitchAccountForm}
        hideCharacters
        readOnly={loading}
      />
    </div>
    {loading ? (
      <LoaderInline className="mt-10" />
    ) : (
      <AlertBox message={error} className="mt-5 w-100" closeAble={false} />
    )}
  </div>
);

export default PinNumberForm;
