/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import InventoryCategoryApi from "../../../api/InventoryCategoryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { UpdateInventoryCategoryRequest } from "../../../models/inventory/category/UpdateInventoryCategoryRequest";
import { InventoryCategoryResponse } from "../../../models/inventory/category/InventoryCategoryResponse";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import AlertBox from "../../../components/AlertBox";
import Field from "../../../components/ReactHookFormFields/General/Field";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";

interface Props {
  clinicId?: string;
  category: InventoryCategoryResponse;
  isOpen: boolean;
  close: () => void;
  reloadCategories: (isDeleted: boolean, newName?: string) => void;
}

interface FormData {
  name: string;
}

const EditCategoryModal: React.FC<Props> = (props: Props) => {
  const { clinicId, isOpen, close, reloadCategories, category } = props;
  const [error, setError] = useState<string | null>(null);
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
  const [isBeforeDeleteCategory, setIsBeforeDeleteCategory] =
    useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
    setValue,
  } = useForm<FormData>({ defaultValues: { name: "" } });

  useEffect(() => {
    if (category) {
      setValue("name", category.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const submitForm = async (data: FormData) => {
    if (clinicId) {
      setButtonsDisabled(true);

      try {
        const request: UpdateInventoryCategoryRequest = { name: data.name };
        await InventoryCategoryApi.updateCategory(
          clinicId,
          category.id,
          request
        );
        close();
        reloadCategories(false, data.name);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setButtonsDisabled(false);
      }
    }
  };

  const beforeDeleteCategory = () => {
    setIsBeforeDeleteCategory(true);
  };

  const deleteCategory = async () => {
    setButtonsDisabled(true);

    try {
      await InventoryCategoryApi.removeCategory(category.id);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setIsBeforeDeleteCategory(false);
      setButtonsDisabled(false);
      close();
      reloadCategories(true);
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.editCategory} />
      <Modal.Body noSpacing>
        <form className="modal-body" id="updateCategoryName">
          <Field
            name="name"
            register={register}
            autoComplete={AutoCompleteOptions.off}
            label={strings.name}
            readOnly={!category.deletable}
            required
          />
          <AlertBox message={error} closeAble={false} />
          <AlertBox
            message={strings.areYouSureToDeleteCategory}
            closeAble={false}
            hidden={!isBeforeDeleteCategory}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        {isBeforeDeleteCategory ? (
          <>
            <Button
              className="modal-main-button"
              disabled={!category.deletable || buttonsDisabled}
              loading={buttonsDisabled}
              onClick={deleteCategory}
              type="submit"
              variant="danger"
            >
              {strings.yes}
            </Button>
            <Button
              className="modal-sub-button"
              onClick={() => {
                setIsBeforeDeleteCategory(false);
              }}
              variant="secondary"
            >
              {strings.no}
            </Button>
          </>
        ) : (
          <>
            <Button
              className="modal-main-button"
              disabled={
                !isDirty || !isValid || !category.deletable || buttonsDisabled
              }
              form="updateCategoryName"
              loading={buttonsDisabled}
              onClick={handleSubmit(submitForm)}
            >
              {strings.save}
            </Button>
            <Button
              className="modal-sub-button"
              disabled={!category.deletable}
              onClick={beforeDeleteCategory}
              variant="secondary"
            >
              {strings.delete}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditCategoryModal;
