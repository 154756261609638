/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import jwtDecode from "jwt-decode";
import { strings } from "../../common/Strings/Strings";
import DiscountSection from "./Discounts/DiscountSection";
import GlobalSettingsSection from "./GlobalSettingsSection";
import { PageProps } from "../../models/PageProps";
import { getAccessToken } from "../../util/LocalStorageVariables";
import SiteManagersSection from "./SiteManagersSection";
import { SiteManagerPage } from "./SiteManagerPage";
import { useChosenRole } from "../Main/Onboarding/ChooseRole";
import CountrySection from "./CountrySection";

function ManagerSettingsPage(props: PageProps) {
  const authorities: string[] = jwtDecode(getAccessToken())?.authorities || [];
  const { setPageLoading } = props;
  const [key, setKey] = useState<string>("config");

  useChosenRole("manager");
  useEffect(() => {
    setPageLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasAuthority = (authority: string): boolean =>
    authorities.includes("MANAGER_FULL") || authorities.includes(authority);

  return (
    <div
      id="settings-tabs"
      className="d-flex justify-content-center flex-column settings-tabs"
    >
      <Tabs
        className="justify-content-center"
        id="managementTabs"
        activeKey={key}
        onSelect={(k: any): void => {
          setKey(k);
        }}
      >
        {hasAuthority("MANAGER_CONFIG") && (
          <Tab eventKey="config" title={strings.config}>
            <div className="row my-4">
              <div className="col-12">
                <GlobalSettingsSection isActiveTab={key === "config"} />
              </div>
            </div>
          </Tab>
        )}

        {hasAuthority("MANAGER_CONFIG") && (
          <Tab eventKey="countries" title={strings.countries}>
            <div className="row my-4">
              <div className="col-12">
                <CountrySection isActiveTab={key === "countries"} />
              </div>
            </div>
          </Tab>
        )}

        {hasAuthority("MANAGER_FEES") && (
          <Tab eventKey="discounts" title={strings.discounts}>
            <div className="row my-4">
              <div className="col-12">
                <DiscountSection isActiveTab={key === "discounts"} />
              </div>
            </div>
          </Tab>
        )}

        {hasAuthority("MANAGER_MANAGERS") && (
          <Tab eventKey="managers" title={strings.managers}>
            <div className="row my-4">
              <div className="col-12">
                <SiteManagersSection isActiveTab={key === "managers"} />
              </div>
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default SiteManagerPage(
  ManagerSettingsPage,
  strings.SITE_MANAGER_SETTINGS
);
