/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import qs from "query-string";
import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { PhoneConfirmationRequest } from "../models/contact/PhoneConfirmationRequest";
import { Void } from "../models/Void";
import { axiosCommon } from "../util/axiosSetup";

export default class ContactsConfirmationApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static confirmEmail(emailId: string, token: string): ApiPromise<void> {
    const query = qs.stringify({ token });
    const url = `${this.baseUrl}/emails/${emailId}/confirm?${query}`;
    return axiosCommon.post(url);
  }

  static confirmPhone(
    phoneId: string,
    token: string,
    confirmationRequest: PhoneConfirmationRequest
  ): ApiPromise<Void> {
    const query = qs.stringify({ token });
    const url = `${this.baseUrl}/phones/${phoneId}/confirm?${query}`;
    return axiosCommon.post(url, confirmationRequest);
  }

  static checkPhoneConfirmationTokenValid(
    phoneId: string,
    token: string
  ): ApiPromise<Void> {
    const query = qs.stringify({ token });
    const url = `${this.baseUrl}/phones/${phoneId}/check-confirmation-token-valid?${query}`;
    return axiosCommon.post(url);
  }
}
