/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageProps } from "../../../models/PageProps";
import { getGeneralError } from "../../../util/helperFunctions";
import { Loader } from "../../../components/Loader";
import { strings } from "../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Tabs from "../../../components/Tabs/Tabs";
import { UserDetailsResponse } from "../../../models/management/UserDetailsResponse";
import UserManagementApi from "../../../api/UserManagementApi";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import UserBasicInfoTab from "./UserBasicInfoTab";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "../../../components/Modal/Modal";
import CloseButton from "../../../components/CloseButton";
import Button from "../../../components/Button";
import ChangePasswordModal from "./ChangePasswordModal";
import AnalyticsEventsTable from "../Analytics/AnalyticsEventsTable";

enum UserDetailsTab {
  basic = "basic",
  events = "events",
}

type Params = {
  userId: string;
};

const UserDetailsPage: React.FC<PageProps> = (props: PageProps) => {
  const { setPageLoading } = props;
  const { userId } = useParams<Params>();

  const [user, setUser] = useState<UserDetailsResponse>();
  const [key, setKey] = useState<UserDetailsTab>(UserDetailsTab.basic);
  const [error, setError] = useState<string | null>(null);

  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState<boolean>(false);

  const [gdprExtractSuccess, setGdprExtractSuccess] = useState<boolean>(false);

  const getUserDetails = async (id: string) => {
    try {
      setPageLoading(true);
      const resp = await UserManagementApi.getUserDetails(id);
      setUser(resp.data);
      setError(null);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      void getUserDetails(userId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const sendGdprExtract = async () => {
    if (!userId) return;
    closeConfirmationModal();

    setError(null);
    setGdprExtractSuccess(false);

    try {
      await UserManagementApi.sendGdprExtract(userId);
      setGdprExtractSuccess(true);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  };

  if (!user) {
    return <></>;
  }

  if (error) {
    return <AlertBox message={error} />;
  }

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div className="flex flex-wrap items-center">
            <div style={{ width: "100px", height: "100px" }}>
              <UserProfilePicture
                userId={user.userId}
                pictureId={user.profilePicture}
              />
            </div>
            <div className="text-xl font-bold lg:text-2xl text-zinc-800 dark:text-white ml-3">
              {user.fullName || user.emails[0].value}
            </div>
            <div className="ml-auto">
              <Dropdown className="rounded-dropdown">
                <Dropdown.Toggle
                  as="div"
                  bsPrefix="toggle-kebab"
                  id="dropdown-pets-more"
                >
                  <span className="material-icons">more_vert</span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="mt-1 p-0">
                  <Dropdown.Item
                    bsPrefix="calendar-menu dropdown-item"
                    as="button"
                    onClick={openConfirmationModal}
                  >
                    {strings.sendExtractToUser}
                  </Dropdown.Item>
                  <Dropdown.Item
                    bsPrefix="calendar-menu dropdown-item"
                    as="button"
                    onClick={() => setChangePasswordModalOpen(true)}
                  >
                    {strings.changePassword}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div hidden={!gdprExtractSuccess}>
            <AlertBox
              message={strings.gdprExtractSentSuccessfully}
              type={AlertType.SUCCESS}
              className="my-3"
            />
          </div>

          <Tabs
            tabs={[
              { title: strings.mainPage, tab: UserDetailsTab.basic },
              { title: strings.activityLogs, tab: UserDetailsTab.events },
            ]}
            currentTabKey={key}
            setTab={setKey}
          />

          <AlertBox message={error} />

          {key === UserDetailsTab.basic && <UserBasicInfoTab user={user} />}

          {key === UserDetailsTab.events && (
            <AnalyticsEventsTable userId={user.userId} />
          )}
        </div>
      </section>

      <Modal
        show={isConfirmationModalOpen}
        handleClose={closeConfirmationModal}
        size="modal-sm"
      >
        <Modal.Header title={strings.warning} />
        <Modal.Body>
          <div className="text-center m-5">
            {strings.warningYouAreAboutToSendEmail}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="modal-main-button"
            type="button"
            onClick={sendGdprExtract}
          >
            {strings.confirm}
          </Button>
          <CloseButton onClick={closeConfirmationModal} />
        </Modal.Footer>
      </Modal>
      <ChangePasswordModal
        userId={user.userId}
        isOpen={isChangePasswordModalOpen}
        close={() => setChangePasswordModalOpen(false)}
      />
    </main>
  );
};

export default Loader(UserDetailsPage);
