/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ComponentType, useEffect, useState } from "react";
import GoogleMap, {
  MapLocation,
  MarkerMapLocation,
} from "../../../../components/map/GoogleMap";
import { BasicClinicResponse } from "../../../../models/clinic/BasicClinicResponse";

interface Props {
  center?: MapLocation;
  markers: BasicClinicResponse[];
  selectClinic?(clinic: BasicClinicResponse): void;
}

const ClinicMap: ComponentType<Props> = ({
  center,
  markers: clinicLocations,
  selectClinic,
}: Props) => {
  const [markers, setMarkers] = useState<MarkerMapLocation[]>();

  useEffect(() => {
    const list: MarkerMapLocation[] = [];
    clinicLocations.forEach((cwo: BasicClinicResponse) => {
      if (cwo.address?.latitude && cwo.address?.longitude) {
        list.push({
          lat: cwo.address.latitude,
          lng: cwo.address.longitude,
          id: cwo.id.toString(),
          content: cwo.name,
        });
      }
    });
    setMarkers(list);
  }, [clinicLocations]);

  const onMarkerClick = (key: string) => {
    const clinic = clinicLocations?.find((cwo) => cwo.id === key);
    if (clinic && selectClinic) {
      selectClinic(clinic);
    }
  };

  return (
    <>
      <GoogleMap
        center={center}
        mapHeight="500px"
        mapWidth="500px"
        markers={markers}
        onMarkerClick={onMarkerClick}
        zoom={13}
      />
    </>
  );
};

export default ClinicMap;
