/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { PetFormProps } from "./PetForm";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { generateOptions } from "../../../util/helperFunctions";
import { PetGenders } from "../../../models/PetAttributes";
import SelectSpeciesAndBreed from "../../../components/ReactHookFormFields/Specific/SelectSpeciesAndBreed";
import BirthDatePicker from "../../../components/ReactHookFormFields/Specific/BirthDatePicker";

const PetFormMandatoryTab: React.FC = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<PetFormProps>();

  return (
    <>
      <Field
        autoComplete={AutoCompleteOptions.off}
        name="name"
        register={register}
        label={strings.name}
        error={errors.name}
        required
        showRequired
      />
      <SelectSpeciesAndBreed
        classNameSpecies=""
        classNameBreed=""
        control={control}
        required
        showRequired
      />
      <Select
        autoComplete={AutoCompleteOptions.off}
        name="gender"
        options={generateOptions(PetGenders)}
        register={register}
        error={errors.gender}
        label={strings.gender}
        required
        showRequired
      />
      <Field
        autoComplete={AutoCompleteOptions.off}
        name="color"
        register={register}
        label={strings.color}
        optional
      />
      <BirthDatePicker control={control} optional pet />
    </>
  );
};

export default PetFormMandatoryTab;
