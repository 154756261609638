/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { strings } from "./Strings/Strings";
import { QuestionMarkCircle } from "./Icons/QuestionMarkCircle";
import { copyrightStatement } from "./NavBar/NavBar";

function Footer(): ReactElement {
  return (
    <footer
      className="w-full p-4 bg-white border-t border-gray-200 shadow hidden lg:flex lg:items-center lg:justify-between lg:-mt-14 lg:p-4 dark:bg-gray-800 dark:border-gray-700"
      id="footer"
    >
      <div className="container flex flex-wrap flex-col lg:flex-row items-center lg:justify-between mx-auto px-0">
        <ul className="flex items-center flex-col lg:flex-row lg:space-x-8 text-sm font-medium">
          <li>
            <span className="w-full lg:w-auto py-2 pl-3 pr-4 lg:p-0 text-gray-500 font-normal sm:text-center dark:text-gray-400">
              {copyrightStatement}
            </span>
          </li>
          {/* TODO: Terms of Service */}
          <li hidden>
            <Link
              className="flex items-center space-x-1 w-full lg:w-auto py-2 pl-3 pr-4 lg:p-0 text-gray-600 hover:text-sky-500 dark:text-gray-300 dark:hover:text-gray-200 transition-all duration-300 font-normal no-underline"
              to="/"
            >
              <span className="text-sm">Terms of Service</span>
            </Link>
          </li>
          <li>
            <Link
              className="flex items-center space-x-1 w-full lg:w-auto py-2 pl-3 pr-4 lg:p-0 text-gray-600 hover:text-sky-500 dark:text-gray-300 dark:hover:text-gray-200 transition-all duration-300 font-normal no-underline"
              to="/privacy-policy"
            >
              <span className="text-sm">{strings.privacyPolicy}</span>
            </Link>
          </li>
          <li>
            <Link
              className="flex items-center space-x-1 w-full lg:w-auto py-2 pl-3 pr-4 lg:p-0 text-gray-600 hover:text-sky-500 dark:text-gray-300 dark:hover:text-gray-200 transition-all duration-300 font-normal no-underline"
              to="/cookies"
            >
              <span className="text-sm">{strings.manageCookies}</span>
            </Link>
          </li>
        </ul>
        <ul className="flex items-center flex-col lg:flex-row lg:space-x-8 text-sm font-medium">
          <li>
            <Link
              to="/help"
              className="flex items-center space-x-1 w-full lg:w-auto py-2 pl-3 pr-4 lg:p-0 text-gray-600 hover:text-sky-500 dark:text-gray-300 dark:hover:text-gray-200 transition-all duration-300"
            >
              <QuestionMarkCircle className="w-6 h-6 flex-shrink-0" />
              <span>{strings.help}</span>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
