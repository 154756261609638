/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../common/Strings/Strings";
import { Package } from "../../../models/ePrescription/VARA/Package";

interface Props {
  mapPackingType(packageType?: string): string;
  selectedPackage?: Package;
}

const PackageInfo: React.FC<Props> = ({
  mapPackingType,
  selectedPackage,
}: Props) => {
  const resultManufacturerText = (p: Package | null): string => {
    return p?.displayStakeholder?.name || "-";
  };

  return (
    <>
      {selectedPackage ? (
        <div>
          <div className="grid grid-cols-12 gap-4 pt-2">
            <div className="col-span-6 md:col-span-2 flex flex-col">
              <div className="italic">{strings.name}</div>
              {selectedPackage.label}
            </div>
            <div className="col-span-6 md:col-span-2 flex flex-col">
              <div className="italic">{strings.nplPackId}</div>
              {selectedPackage.nplPackId}
            </div>
            <div className="col-span-6 md:col-span-2 flex flex-col">
              <div className="italic"> {strings.marketed}</div>
              {selectedPackage.isOnTheMarket ? strings.yes : strings.no}
            </div>
            <div className="col-span-6 md:col-span-2 flex flex-col">
              <div className="italic">{strings.manufacturer}</div>
              {selectedPackage.nplPackId
                ? resultManufacturerText(selectedPackage)
                : ""}
            </div>
            <div className="col-span-6 md:col-span-2 flex flex-col">
              <div className="italic">{strings.typeOfPacking}</div>
              {mapPackingType(selectedPackage.innerPackageType)}
            </div>
            <div className="col-span-6 md:col-span-2 flex flex-col">
              <div className="italic">{strings.ddd}</div>
              {selectedPackage.dddPerPackage}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PackageInfo;
