/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import Select from "../../../../components/ReactHookFormFields/General/Select/Select";
import VaraApi from "../../../../api/VaraApi";
import { AtcCode } from "../../../../models/ePrescription/VARA/AtcCode";
import { MedicinalProduct } from "../../../../models/ePrescription/VARA/MedicinalProduct";
import { Option } from "../../../../models/Option";
import { SearchParams } from "../../../../models/ePrescription/local/SearchParams";
import Paging from "../../../../components/Paging";
import hardcodedStrings from "../../fixValues/hardcodedStrings";
import LoaderInline from "../../../../components/LoaderInline";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import { useDebouncedState } from "../../../../hooks/hooks";
import logger from "../../../../util/logger";
import { getGeneralError } from "../../../../util/helperFunctions";
import PageSizeSelect from "../../../../components/PageSizeSelect";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import CheckBox from "../../../../components/ReactHookFormFields/General/CheckBox";
import ResultRow from "./ResultRow";
import EmptyListText from "../../../../components/EmptyListText";

export const isEnough = (
  barcode: string | undefined,
  product: string | undefined
): boolean => {
  if (product && product.length === 1) {
    if ((barcode && barcode.length === 1) || !barcode || barcode === "") {
      return strings.notEnoughFields;
    }
  }
  if (barcode && barcode.length === 1) {
    if ((product && product.length === 1) || !product || product === "") {
      return strings.notEnoughFields;
    }
  }

  return true;
};

interface FormData {
  product: string;
  substance: string;
  category: string;
  showOnlyMarketed: boolean;
  showOnlyUnMarketed: boolean;
}

interface SearchSize {
  count: number;
  pages: number;
  pageNum: number;
  totalPages: number;
}

interface Props {
  isOnlyLicensed: boolean;
  selectProduct(product: MedicinalProduct): void;
  resetSearch: boolean;
}

const SearchProduct: React.FC<Props> = ({
  isOnlyLicensed,
  selectProduct,
  resetSearch,
}: Props) => {
  const [searchResult, setSearchResult] = useState<MedicinalProduct[]>([]);
  const [categories, setCategories] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSize, setDataSize] = useState<SearchSize>({
    count: 0,
    pages: 0,
    pageNum: 0,
    totalPages: 1,
  });
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const productSearchParams = useDebouncedState<SearchParams>(
    { onTheMarket: true },
    600
  );

  const {
    register,
    formState: { errors, isValid },
    getValues,
    setError,
    reset,
  } = useForm<FormData>({
    defaultValues: { showOnlyMarketed: true, showOnlyUnMarketed: false },
    mode: "onChange",
  });

  useEffect(() => {
    setPage(0);
    setDataSize({
      count: 0,
      pages: 0,
      pageNum: 0,
      totalPages: 1,
    });
    productSearchParams.setValue({ onTheMarket: true });
    setSearchResult([]);
    reset();
  }, [resetSearch]);

  useEffect(() => {
    let isMounted = true;

    const getAtcCodes = async () => {
      try {
        const response = await VaraApi.getAtcCodes();

        if (isMounted) {
          const categs: Option[] = [];
          response.data.forEach((atc: AtcCode) => {
            if (atc.label.length === 1) {
              categs.push({
                value: atc.label,
                title: atc.description,
              });
            }
          });
          setCategories(categs);
        }
      } catch (err) {
        const response = await getGeneralError(err);
        const formError: ErrorOption = {
          message: response || undefined,
        };
        if (isMounted) {
          setError("category", formError);
        }
        logger.error(err);
      }
    };

    void getAtcCodes();

    return () => {
      isMounted = false;
    };
  }, [setError]);

  const initDataSize = () => {
    setDataSize({
      count: 0,
      pages: 0,
      pageNum: 0,
      totalPages: 1,
    });
  };

  useEffect(() => {
    let isMounted = true;

    const search = async ({
      atcCode,
      pageNumber,
      substanceKeyword,
      productNameKeyword,
      onTheMarket,
      pageSize: ps,
    }: SearchParams) => {
      setLoading(true);

      let productTypes: string[];

      if (isOnlyLicensed) {
        productTypes = hardcodedStrings.notApprovedProducts;
      } else {
        productTypes = hardcodedStrings.approvedProducts;
      }

      try {
        const response = await VaraApi.searchProducts({
          pageNumber,
          pageSize: ps,
          productNameKeyword,
          substanceKeyword,
          atcCode,
          productTypes,
          onTheMarket,
        });
        if (isMounted) {
          setSearchResult(response.data.items);
          setDataSize({
            count: response.data.count,
            pages: response.data.pageSize,
            pageNum: response.data.pageNum,
            totalPages: response.data.totalPages,
          });
        }
      } catch (err) {
        logger.error(err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (
      (productSearchParams.value.productNameKeyword ||
        productSearchParams.value.substanceKeyword) &&
      isValid
    ) {
      const request: SearchParams = {
        productNameKeyword: productSearchParams.value.productNameKeyword,
        substanceKeyword: productSearchParams.value.substanceKeyword,
        atcCode: productSearchParams.value.atcCode,
        onTheMarket: productSearchParams.value.onTheMarket,
        pageNumber: page,
        pageSize,
      };

      void search(request);
    }

    return () => {
      isMounted = false;
    };
  }, [productSearchParams.value, page, pageSize, isOnlyLicensed, isValid]);

  const searchInput = (): void => {
    setSearchResult([]);
    initDataSize();

    const {
      product,
      category,
      showOnlyMarketed,
      showOnlyUnMarketed,
      substance,
    } = getValues();

    let onTheMarket;

    if (showOnlyMarketed && !showOnlyUnMarketed) {
      onTheMarket = true;
    }

    if (!showOnlyMarketed && showOnlyUnMarketed) {
      onTheMarket = false;
    }

    const searchInputs: SearchParams = {
      productNameKeyword: product,
      substanceKeyword: substance,
      atcCode: category,
      onTheMarket,
    };

    productSearchParams.setValue(searchInputs);

    if (page !== 0) {
      setPage(0);
    }
  };

  return (
    <div className="space-y-4 pt-4">
      <form className="space-y-4">
        <div className="grid md:grid-cols-3 gap-4">
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.product}
            fieldOptions={{
              deps: ["substance"],
              validate: {
                isEnough: (v: string | undefined) =>
                  isEnough(getValues("substance"), v),
              },
            }}
            label={strings.product}
            name="product"
            onChange={searchInput}
            placeholder={strings.product}
            register={register}
          />
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.substance}
            fieldOptions={{
              deps: ["product"],
              validate: {
                isEnough: (v: string | undefined) =>
                  isEnough(v, getValues("product")),
              },
            }}
            label={strings.substance}
            name="substance"
            onChange={searchInput}
            placeholder={strings.substance}
            register={register}
          />
          <Select
            autoComplete={AutoCompleteOptions.off}
            error={errors.category}
            isChooseOption={false}
            label={strings.category}
            name="category"
            onChange={searchInput}
            options={categories}
            register={register}
          />
        </div>
        <div className="flex space-x-4">
          <CheckBox
            defaultChecked
            label={strings.showOnlyMarketed}
            name="showOnlyMarketed"
            onChange={searchInput}
            register={register}
          />
          <CheckBox
            label={strings.showOnlyUnMarketed}
            name="showOnlyUnMarketed"
            onChange={searchInput}
            register={register}
          />
        </div>
      </form>
      {/* MOBILE VIEW */}
      <div className="md:hidden">
          <table className="tw-table">
            <thead>
              <tr>
                <th colSpan={2}>{strings.product}</th>
              </tr>
            </thead>
            {loading ? (
              <></>
            ) : (
              <tbody>
                {searchResult
                  .filter((medicine: MedicinalProduct) => {
                    if (!isOnlyLicensed) {
                      return (
                        medicine.productType &&
                        !hardcodedStrings.notApprovedProducts.includes(
                          medicine.productType
                        )
                      );
                    }
                    return medicine;
                  })
                  .map((medicine: MedicinalProduct, index: number) => {
                    return (
                      <ResultRow
                        index={index}
                        isMobile
                        key={index}
                        medicine={medicine}
                        selectProduct={selectProduct}
                      />
                    );
                  })}
              </tbody>
            )}
          </table>
      </div>
      {/* NORMAL VIEW */}
      <div className="hidden md:block">
        <div className="tw-table-container">
          <table className="tw-table">
            <thead className="tw-thead">
              <tr>
                <th className="tw-th">{strings.product}</th>
                <th className="tw-th">{strings.pharmaceuticalForm}</th>
                <th className="tw-th">{strings.strength}</th>
                <th className="tw-th">{strings.manufacturer}</th>
                <th className="tw-th" />
              </tr>
            </thead>
            <tbody className="tw-tbody">
              {loading ? (
                <tr>
                  <td colSpan={5} className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                    <LoaderInline />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {!loading && searchResult.length === 0 ? (
                <tr>
                  <td colSpan={5} className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                    <EmptyListText />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {searchResult
                .filter((medicine: MedicinalProduct) => {
                  if (!isOnlyLicensed) {
                    return (
                      medicine.productType &&
                      !hardcodedStrings.notApprovedProducts.includes(
                        medicine.productType
                      )
                    );
                  }
                  return medicine;
                })
                .map((medicine: MedicinalProduct, index: number) => {
                  return (
                    <ResultRow
                      index={index}
                      key={index}
                      medicine={medicine}
                      selectProduct={selectProduct}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center">
        {loading ? (
          <></>
        ) : (
          <PageSizeSelect
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={dataSize.totalPages}
            totalResults={dataSize.count}
          />
        )}
        <div className="ml-auto">
          <Paging
            currentPage={page}
            totalPages={dataSize.totalPages}
            selectPage={(newPage: number) => {
              setPage(newPage);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchProduct;
