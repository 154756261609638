/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useCallback, useEffect, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import {
  canManageClinic,
  getGeneralError,
} from "../../../../util/helperFunctions";
import LoaderInline from "../../../../components/LoaderInline";
import AlertBox from "../../../../components/AlertBox";
import { AccountingCodeResponse } from "../../../../models/accounting/AccountingCodeResponse";
import AccountingCodeApi from "../../../../api/AccountingCodeApi";
import AccountingCodeModal from "./AccountingCodeModal";
import { Trash } from "../../../../common/Icons/Trash";
import { Pencil } from "../../../../common/Icons/Pencil";
import Button from "../../../../components/Button";
import { PlusIcon } from "../../../../common/Icons/PlusIcon";
import EmptyListText from "../../../../components/EmptyListText";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";

interface Props {
  clinic: ClinicResponse;
  isActiveTab: boolean;
  fortnoxEnabled: boolean;
}

const AccountingCodesTab: React.FC<Props> = (props: Props) => {
  const { clinic, isActiveTab, fortnoxEnabled } = props;

  const [error, setError] = useState<string | null>(null);
  const [accountingCodes, setAccountingCodes] = useState<
    AccountingCodeResponse[]
  >([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [isAccountingCodeModalOpen, setAccountingCodeModalOpen] =
    useState<boolean>(false);
  const [selectedAccountingCode, setSelectedAccountingCode] =
    useState<AccountingCodeResponse>();

  const openModal = () => {
    setAccountingCodeModalOpen(true);
  };

  const closeModal = () => {
    setAccountingCodeModalOpen(false);
    setSelectedAccountingCode(undefined);
  };

  const editAccountingCode = (accountingCode: AccountingCodeResponse) => {
    setSelectedAccountingCode(accountingCode);
    openModal();
  };

  const getAccountingCodes = useCallback(async () => {
    setLoading(true);

    try {
      const response = await AccountingCodeApi.getAccountingCodesOfClinic(
        clinic.id
      );
      setAccountingCodes(response.data);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  }, [clinic]);

  const syncAccountingCodes = useCallback(async () => {
    setLoading(true);

    try {
      const response = await AccountingCodeApi.syncAccountingCodesOfClinic(
        clinic.id
      );
      setAccountingCodes(response.data);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  }, [clinic]);

  const triggerReload = () => {
    void getAccountingCodes();
  };

  useEffect(() => {
    if (isActiveTab) {
      void getAccountingCodes();
    }
  }, [clinic, isActiveTab, getAccountingCodes]);

  const deleteAccountingCode = async (
    accountingCode: AccountingCodeResponse
  ) => {
    try {
      setLoading(true);
      if (accountingCode.id === undefined) return;
      await AccountingCodeApi.removeAccountingCode(
        clinic.id,
        accountingCode.id
      );
      triggerReload();
    } catch (e) {
      setError(await getGeneralError(e));
      setLoading(false);
    }
  };

  const readOnly = !canManageClinic(clinic);

  return (
    <div className="w-full md:tw-card md:max-w-2xl space-y-6 md:p-8">
      <div>
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
          {strings.accountingCodes}
          <div className="ml-auto">
            <LoaderInline hidden={!loading} />
          </div>
        </h2>
      </div>
      <div>
        {fortnoxEnabled && !readOnly && (
          <Button
            onClick={syncAccountingCodes}
            variant="secondary"
            className="mr-2"
          >
            {strings.syncWithFortnox}
          </Button>
        )}
      </div>
      <div>
        <Button variant="link" onClick={openModal} hidden={readOnly}>
          <div className="flex">
            <PlusIcon /> <span>{strings.addNew}</span>
          </div>
        </Button>
      </div>
      <AlertBox message={error} className="mb-3" />
      <div>
        <div className="tw-table-container">
          <table className="tw-table" id="accountingCodes">
            <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
              <tr>
                <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.code}
                </th>
                <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.name}
                </th>
                <th
                  hidden={readOnly}
                  className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  {strings.operations}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
              {accountingCodes.length === 0 ? (
                <tr>
                  <td
                    className="px-4 py-3 text-sm font-medium whitespace-nowrap"
                    colSpan={3}
                  >
                    <EmptyListText />
                  </td>
                </tr>
              ) : (
                <>
                  {accountingCodes.map((accountingCode) => (
                    <tr key={accountingCode.id}>
                      <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                        {accountingCode.code}
                      </td>
                      <td className="px-4 py-3 text-sm font-medium">
                        {accountingCode.name}
                      </td>
                      <td
                        hidden={readOnly}
                        className="px-4 py-3 text-sm font-medium whitespace-nowrap text-right"
                      >
                        <Button
                          aria-label={strings.edit}
                          onClick={() => {
                            editAccountingCode(accountingCode);
                          }}
                          variant="icon"
                        >
                          <span className="sr-only">{strings.edit}</span>
                          <Pencil />
                        </Button>
                        <Button
                          aria-label={strings.delete}
                          className="tw-link ml-3"
                          onClick={() => {
                            void deleteAccountingCode(accountingCode);
                          }}
                          variant="icon"
                        >
                          <span className="sr-only">{strings.delete}</span>
                          <Trash />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <AccountingCodeModal
          clinicId={clinic.id}
          reload={triggerReload}
          isOpen={isAccountingCodeModalOpen}
          close={closeModal}
          accountingCode={selectedAccountingCode}
        />
      </div>
      <div>
        <Button variant="link" onClick={openModal} hidden={readOnly}>
          <div className="flex">
            <PlusIcon /> <span>{strings.addNew}</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default AccountingCodesTab;
