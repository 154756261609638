/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { strings } from "../../common/Strings/Strings";
import CloseButton from "../../components/CloseButton";
import AlertBox, { AlertType } from "../../components/AlertBox";
import { TodoResponse } from "../../models/todo/TodoResponse";
import { Colors } from "../../models/Colors";
import PetOwnerInfo from "./PetOwnerInfo";
import PetInfo from "./PetInfo";
import { useClinic } from "../../contexts/ClinicContext";
import { getGeneralError } from "../../util/helperFunctions";
import TodoApi from "../../api/TodoApi";
import { Circle } from "../../common/Circle";
import { ClinicPetResponse } from "../../models/pet/ClinicPetResponse";
import { PetOwnerResponse } from "../../models/pet/PetOwnerResponse";
import Modal from "../../components/Modal/Modal";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import Button from "../../components/Button";
import { CalendarIcon } from "../../common/Icons/Calendar";
import { Pencil } from "../../common/Icons/Pencil";
import IconBubble from "../../components/IconBubble";
import moment from "moment";

interface Props {
  changeState(): void;
  modifyInput?: TodoResponse;
  onHide: (isSuccess?: boolean) => void;
  show: boolean;
}

const DeleteTodoModal = ({
  changeState,
  modifyInput,
  onHide,
  show,
}: Props): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { clinic } = useClinic();
  const [pet, setPet] = useState<ClinicPetResponse>();
  const [petOwner, setPetOwner] = useState<PetOwnerResponse>();
  const [success, setSuccess] = useState<string>();

  useEffect(() => {
    if (modifyInput?.linkedMedicalRecord) {
      setPet(modifyInput.linkedMedicalRecord?.pet);
      setPetOwner(modifyInput.linkedMedicalRecord.petOwner);
    } else if (modifyInput?.linkedPet) {
      setPet(modifyInput.linkedPet.pet);
      setPetOwner(modifyInput.linkedPet.petOwner);
    } else if (modifyInput?.linkedPetOwner) {
      setPetOwner(modifyInput.linkedPetOwner);
    }
  }, [modifyInput]);

  const deleteTodo = async () => {
    if (clinic && modifyInput) {
      setLoading(true);

      try {
        await TodoApi.deleteTodo(clinic.id, modifyInput.id);
        setSuccess(strings.taskDeletedSuccessfully);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const onModalClose = () => {
    if (success) {
      onHide(true);
    } else {
      changeState();
    }
  };

  return (
    <Modal
      handleClose={() => {
        onHide(!!success);
      }}
      show={show}
    >
      <Modal.Header title={strings.deleteTask} />
      <Modal.Body>
        <div className="space-y-4">
          <div>{strings.areYouSureDeleteTask}</div>
          <div className="flex items-center space-x-3">
            <div
              style={{
                color: modifyInput?.color || Colors.PRIMARY,
              }}
            >
              <Circle height="40px" width="40px" />
            </div>
            <div className="font-bold">{modifyInput?.title}</div>
          </div>
          <div hidden={!modifyInput?.dueDate}>
            <div className="flex items-center space-x-3">
              <IconBubble>
                <CalendarIcon />
              </IconBubble>
              <div className="font-bold">
                {moment(modifyInput?.dueDate).format("LL")}
              </div>
            </div>
          </div>
          {pet && (
            <div>
              <PetInfo pet={pet} />
            </div>
          )}
          {petOwner && (
            <div>
              <PetOwnerInfo petOwner={petOwner} />
            </div>
          )}
          <div hidden={!modifyInput?.assignee}>
            <div className="flex items-center space-x-3">
              <IconBubble>
                <UserProfilePicture userId={modifyInput?.assignee?.userId} />
              </IconBubble>
              <div className="font-bold">{modifyInput?.assignee?.fullName}</div>
            </div>
          </div>
          <div hidden={!modifyInput?.description}>
            <div className="flex items-center space-x-3">
              <IconBubble>
                <Pencil />
              </IconBubble>
              <div className="line-clamp-1 font-bold">
                {modifyInput?.description}
              </div>
            </div>
          </div>
          <AlertBox
            closeAble={false}
            message={success}
            type={AlertType.SUCCESS}
          />
          <AlertBox closeAble={false} message={error} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CloseButton
          onClick={onModalClose}
          text={!success ? strings.back : strings.close}
        />
        <Button disabled={loading || !!success} onClick={deleteTodo}>
          {strings.delete}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTodoModal;
