/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { strings } from "../../common/Strings/Strings";
import CloseButton from "../../components/CloseButton";
import { useUser } from "../../contexts/UserContext";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button";

interface Props {
  closeSignModal(): void;
  petName: string;
  sign(): void;
  signModalOpened: boolean;
  petOwnerEmail?: string;
}

const SignModal = ({
  closeSignModal,
  petName,
  sign,
  signModalOpened,
  petOwnerEmail,
}: Props): ReactElement => {
  const { user } = useUser();
  return (
    <Modal show={signModalOpened} handleClose={closeSignModal}>
      <Modal.Header>{strings.signMedicalRecord}</Modal.Header>
      <Modal.Body>
        <>
          <div className="text-3xl text-center">{strings.areYouSure}</div>
          <div className="text-center">
            {strings.formatString(strings.signText, petName)}
          </div>
          <div className="flex flex-col items-center">
            <div className="w-20 h-20">
              <UserProfilePicture userId={user.userId} />
            </div>
            <div className="text-center mt-2">
              {strings.signer}: <b>{user.details.fullName}</b>
            </div>
          </div>
          {petOwnerEmail && (
            <p>
              {strings.willBeSentToEmail} <b>{petOwnerEmail}</b>.
            </p>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={closeSignModal} text={strings.cancel} />
        <Button
          type="submit"
          onClick={() => {
            void sign();
          }}
        >
          {strings.sign}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SignModal;
