/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import LoaderInline from "../../components/LoaderInline";
import RecoveryApi from "../../api/RecoveryApi";
import { UpdatePasswordRequest } from "../../models/user/UpdatePasswordRequest";
import { getGeneralError } from "../../util/helperFunctions";
import Field from "../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { passwordRegexp } from "../../util/Validations";
import { Card } from "../../components/Cards/Card";
import AlertBox from "../../components/AlertBox";
import Button from "../../components/Button";

type Params = {
  tokenId: string;
  token: string;
};

interface ResetPasswordForm {
  password: string;
  passwordAgain: string;
}

const defaultFormValues = {
  password: "",
  passwordAgain: "",
};

const ResetPassword: React.FC = () => {
  const { token, tokenId } = useParams<Params>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [tokenValid, setTokenValid] = useState<boolean>(false);
  const [tokenValidationLoading, setTokenValidationLoading] =
    useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenIsValid = async (_token: string, _tokenId: string) => {
      setTokenValidationLoading(true);
      try {
        await RecoveryApi.checkTokenIsValid(_tokenId, _token);
        setTokenValid(true);
      } catch (e) {
        setError(await getGeneralError(e));
        setTokenValid(false);
      } finally {
        setTokenValidationLoading(false);
      }
    };

    if (token && tokenId) {
      void checkTokenIsValid(token, tokenId);
    }
  }, [token, tokenId]);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ResetPasswordForm>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  const submitForm = async (values: UpdatePasswordRequest) => {
    setLoading(true);

    if (token && tokenId) {
      try {
        await RecoveryApi.resetPassword(tokenId, token, values);
        navigate("/");
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const renderPasswordChangeForm = () => (
    <form className="space-y-6" onSubmit={handleSubmit(submitForm)}>
      <Field
        name="password"
        label={strings.newPassword}
        type="password"
        register={register}
        autoComplete={AutoCompleteOptions.newPassword}
        fieldOptions={{
          required: true,
          pattern: {
            value: passwordRegexp,
            message: strings.passwordValid,
          },
        }}
        error={errors.password}
      />
      <Field
        name="passwordAgain"
        label={strings.newPasswordAgain}
        type="password"
        register={register}
        autoComplete={AutoCompleteOptions.newPassword}
        fieldOptions={{
          required: true,
          validate: {
            passwordAgainIsSameAsPassword: (v) =>
              v.toString() === getValues("password") || strings.confirmValid,
          },
        }}
        error={errors.passwordAgain}
      />
      <AlertBox message={error} />
      <Button disabled={!isValid} loading={loading} type="submit">
        {strings.savePassword}
      </Button>
    </form>
  );

  return (
    <Card title={strings.resetPassword}>
      {tokenValidationLoading ? (
        <LoaderInline />
      ) : tokenValid ? (
        renderPasswordChangeForm()
      ) : (
        <AlertBox message={error} />
      )}
    </Card>
  );
};

export default ResetPassword;
