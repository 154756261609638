/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useLocation } from "react-router-dom";
import UserDropdownMenu from "./UserDropdownMenu";
import Notifications from "../Notifications/Notifications";

const UserMenu: React.FC = () => {
  const location = useLocation();

  return (
    <div className="absolute top-0 right-0 h-full xl:h-auto xl:relative">
      <div className="h-full xl:h-auto flex flex-wrap items-center space-x-4 xl:space-x-5 xl:order-2">
        <ul className="relative flex items-center flex-row space-x-3 xl:space-x-6 xl:text-md xl:font-medium">
          <li>
            <Notifications visible={location.pathname !== "/profile/setup"} />
          </li>
          <li>
            <UserDropdownMenu />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserMenu;
