/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import CloseButton from "../../../components/CloseButton";
import NumberFormatter from "../../../util/NumberFormatter";
import SimpleCheckBox from "../../../components/InputFieldsSimple/SimpleCheckBox";
import { TreatmentResponse } from "../../../models/treatment/TreatmentResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";

interface Props {
  close: () => void;
  deleteTreatment: (
    isDeleteAllSubItemsToo: boolean,
    resetDescriptions: boolean
  ) => void;
  isOpen: boolean;
  treatmentToDelete?: TreatmentResponse & { contentNotAvailable?: boolean };
}

const DeleteTreatmentModal: React.FC<Props> = ({
  close,
  deleteTreatment,
  isOpen,
  treatmentToDelete,
}: Props) => {
  const [deleteRelatedItems, setDeleteRelatedItems] = useState<boolean>(true);
  const [resetDescriptions, setResetDescriptions] = useState<boolean>(true);

  useEffect(() => {
    if (treatmentToDelete?.contentNotAvailable) {
      setResetDescriptions(false);
    } else if (
      (!treatmentToDelete?.description ||
        treatmentToDelete?.description === "") &&
      (!treatmentToDelete?.advices || treatmentToDelete?.advices === "")
    ) {
      setResetDescriptions(false);
    } else {
      setResetDescriptions(true);
    }
  }, [treatmentToDelete]);

  const deleteSelectedTreatment = () => {
    if (treatmentToDelete) {
      deleteTreatment(deleteRelatedItems, resetDescriptions);
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={`${strings.remove} (${treatmentToDelete?.name})`} />
      <Modal.Body>
        <div className="px-3">
          <SimpleCheckBox
            checked={resetDescriptions}
            name="resetDescriptions"
            label={strings.resetTreatmentDescriptions}
            onChange={(value: boolean) => {
              setResetDescriptions(value);
            }}
          />
        </div>
        <div className="px-3">
          <SimpleCheckBox
            className={
              treatmentToDelete?.contentNotAvailable ? "opacity-50" : ""
            }
            checked={deleteRelatedItems}
            name="deleteRelatedItems"
            label={strings.deleteRelatedItems}
            onChange={(value: boolean) => {
              setDeleteRelatedItems(value);
            }}
            readOnly={treatmentToDelete?.contentNotAvailable}
          />
          <table
            className="table table-striped mt-2 mb-0"
            style={{
              opacity:
                !deleteRelatedItems || treatmentToDelete?.contentNotAvailable
                  ? 0.4
                  : 1,
            }}
          >
            <thead>
              <tr>
                <th className="no-border-on-top">{strings.name}</th>
                <th className="text-right no-border-on-top">
                  {strings.quantity}
                </th>
                <th className="no-border-on-top" />
              </tr>
            </thead>
            <tbody>
              {treatmentToDelete?.usedItems?.map((item) => (
                <tr key={item.id}>
                  <td>{item.item.name}</td>
                  <td className="text-right">{item.quantity}</td>
                  <td className="text-left">
                    {quantityUnits[item.item.acquisitionUnit || "PIECES"]}
                  </td>
                </tr>
              ))}
              {treatmentToDelete?.fees?.map((item) => (
                <tr key={item.id}>
                  <td>{item.fee.name}</td>
                  <td className="text-right">
                    {NumberFormatter.format(item.quantity)}
                  </td>
                  <td />
                </tr>
              ))}
            </tbody>
          </table>
          <AlertBox
            hidden={!treatmentToDelete?.contentNotAvailable}
            message={strings.contentNotAvailable}
            type={AlertType.WARNING}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          onClick={deleteSelectedTreatment}
          variant="danger"
        >
          {strings.remove}
        </Button>
        <CloseButton onClick={close} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTreatmentModal;
