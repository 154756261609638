/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import {
  prescriptionNameRegexp,
  prescriptionRegexp,
} from "../../../../util/Validations";
import { strings } from "../../../../common/Strings/Strings";
import PhoneNumber from "../../../../components/ReactHookFormFields/Specific/PhoneNumber";

import DatePickerInput from "../../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import { PersonDetailsResponse } from "../../../../models/contact/PersonDetailsResponse";
import { getStreetAndHouseDetails } from "../../../../util/helperFunctions";
import { mergeNames } from "./PersonalIdOwnerForm";

export interface BirthDateFormProps {
  addresses: string;
  phones: string;
  familyNameForeign: string;
  givenNameForeign: string;
  birthDateForeign: string;
  phoneNumberForeign: string;
  cityForeign: string;
  zipForeign: string;
  addressForeign: string;
}

interface Props {
  defaultCountryCode?: string;
  petOwner?: PersonDetailsResponse;
}

const BirthDateOwnerForm: React.FC<Props> = ({
  defaultCountryCode,
  petOwner,
}: Props) => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext();

  useEffect(() => {
    if (petOwner) {
      const phone = petOwner.phones[0];
      const address = petOwner.addresses[0];

      setValue("givenNameForeign", mergeNames(petOwner));
      setValue("familyNameForeign", petOwner.lastName);
      setValue(
        "birthDateForeign",
        petOwner.dateOfBirth ? moment(petOwner.dateOfBirth).toDate() : null
      );
      setValue("phoneNumberForeign", phone?.value);
      setValue("addressForeign", getStreetAndHouseDetails(address));
      setValue("cityForeign", address?.city);
      setValue("zipForeign", address?.zip.replace(/\s/g, ""));

      void trigger();
    }
  }, [petOwner, setValue, trigger]);

  return (
    <form className="space-y-2">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.givenNameForeign}
          fieldOptions={{
            maxLength: 35,
            pattern: {
              value: prescriptionNameRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.firstName}
          maxLength={35}
          name="givenNameForeign"
          register={register}
          required
          showRequired
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.familyNameForeign}
          fieldOptions={{
            maxLength: 35,
            pattern: {
              value: prescriptionNameRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.lastName}
          maxLength={35}
          name="familyNameForeign"
          register={register}
          required
          showRequired
        />
        <DatePickerInput
          control={control}
          fieldOptions={{
            maxLength: 35,
            validate: {
              isInTheFuture: (v: Date | null) =>
                moment(v, "YYYY-MM-DD").diff(moment(), "day") < 0 ||
                strings.notAllowedCharacter,
              isInThePast: (v: Date | null) =>
                moment(v).isAfter("1900-01-01") || strings.notAllowedCharacter,
            },
          }}
          label={strings.dateOfBirth}
          name="birthDateForeign"
          required
          showRequired
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <PhoneNumber
          control={control}
          country={defaultCountryCode}
          id="phoneNumberForBirthDateOwner"
          label={strings.phone}
          name="phoneNumberForeign"
          required
          showRequired
        />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div>
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.addressForeign}
            fieldOptions={{
              maxLength: 35,
              pattern: {
                value: prescriptionRegexp,
                message: strings.notAllowedCharacter,
              },
            }}
            label={strings.address}
            maxLength={35}
            name="addressForeign"
            register={register}
            required
            showRequired
          />
        </div>
        <div>
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.cityForeign}
            fieldOptions={{
              maxLength: 28,
              pattern: {
                value: prescriptionRegexp,
                message: strings.notAllowedCharacter,
              },
            }}
            label={strings.city}
            maxLength={28}
            name="cityForeign"
            register={register}
            required
            showRequired
          />
        </div>
        <div>
          <Field
            autoComplete={AutoCompleteOptions.off}
            disableNotNumberCharacters
            error={errors.zipForeign}
            fieldOptions={{
              maxLength: 6,
              minLength: 1,
              pattern: {
                value: /[^\s]/,
                message: strings.notAllowedCharacter,
              },
            }}
            label={strings.zip}
            name="zipForeign"
            maxLength={6}
            register={register}
            required
            showRequired
          />
        </div>
      </div>
    </form>
  );
};

export default BirthDateOwnerForm;

BirthDateOwnerForm.defaultProps = {
  defaultCountryCode: undefined,
};
