/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import ReactGA from "react-ga4";
import { getCookieConsentValue } from "react-cookie-consent";
import Params from "../common/Params";
import { getActiveUserId } from "./LocalStorageVariables";

export default class Analytics {
  static init() {
    const grant = Boolean(getCookieConsentValue()) ? "granted" : "denied";
    ReactGA.initialize(Params.googleAnalyticsID, {
      gaOptions: {
        userId: getActiveUserId(),
      },
      gtagOptions: {
        ad_user_data: grant,
        ad_personalization: grant,
        ad_storage: grant,
        analytics_storage: grant,
      },
    });
  }

  static setAnalyticsEnabled(enabled: Boolean) {
    const grant = enabled ? "granted" : "denied";
    ReactGA.gtag("consent", "update", {
      ad_user_data: grant,
      ad_personalization: grant,
      ad_storage: grant,
      analytics_storage: grant,
    });
  }

  static setUser(userId?: string) {
    ReactGA.set({ userId });
  }
}
