/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { chronicDiseases as chronicDiseasesEnum } from "../Strings/Strings";
import { ChronicDisease } from "../../models/medical/ChronicDisease";

export interface ChronicDiseaseOption {
  id: ChronicDisease;
  name: string;
}

export const chronicDiseasesList: ChronicDiseaseOption[] = [
  {
    name: chronicDiseasesEnum[ChronicDisease.ADDISON],
    id: ChronicDisease.ADDISON
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.ALLERGY],
    id: ChronicDisease.ALLERGY
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.ARTHROSIS],
    id: ChronicDisease.ARTHROSIS
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.CUCHING],
    id: ChronicDisease.CUCHING
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.DIABETES],
    id: ChronicDisease.DIABETES
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.HYPERTHYROIDISM],
    id: ChronicDisease.HYPERTHYROIDISM
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.HYPOTHYROIDISM],
    id: ChronicDisease.HYPOTHYROIDISM
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.IBD],
    id: ChronicDisease.IBD
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.KIDNEY_DISEASE],
    id: ChronicDisease.KIDNEY_DISEASE
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.LIVER_DISEASE],
    id: ChronicDisease.LIVER_DISEASE
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.NEUROLOGIC_DISEASE],
    id: ChronicDisease.NEUROLOGIC_DISEASE
  },
  {
    name: chronicDiseasesEnum[ChronicDisease.OTHER],
    id: ChronicDisease.OTHER
  }
];

export const toChronicDiseaseOption = (
  simpleList?: ChronicDisease[],
  responseList?: any[]
): ChronicDiseaseOption[] => {
  if (simpleList) {
    let result: ChronicDiseaseOption[] = [];

    simpleList.forEach((cd: ChronicDisease) => {
      result.push({ id: cd, name: chronicDiseasesEnum[cd] });
    });

    return result;
  } else if (responseList) {
    let result: ChronicDiseaseOption[] = [];

    responseList.forEach((cd: any) => {
      result.push({ id: cd.disease, name: chronicDiseasesEnum[cd.disease] });
    });

    return result;
  }

  return [];
};

export const toChronicDiseaseRequest = (
  list: ChronicDiseaseOption[],
  oldList?: any[]
): any[] => {
  let result: any[] = [];

  list.forEach((cdo: ChronicDiseaseOption) => {
    result.push({ disease: cdo.id, removing: false });
  });

  oldList?.forEach((chr: any) => {
    const contains: ChronicDiseaseOption | undefined = list.find(
      (item: ChronicDiseaseOption) => item.id === chr.disease
    );

    /* If the old disease is not part of the new list, and it is not confirmed yet, we can remove it */
    if (!contains && !chr.confirmed) {
      result.push({ disease: chr.disease, removing: true });
    }
  });

  return result;
};

export const filterConfirmedDiseases = (list?: any[]) => {
  let result: ChronicDiseaseOption[] = [];

  if (!list) {
    return chronicDiseasesList;
  }

  chronicDiseasesList.forEach((disease: ChronicDiseaseOption) => {
    const item: any | undefined = list.find(
      (item: any) => item.disease === disease.id
    );

    if (!item || !item?.confirmed) {
      result.push(disease);
    }
  });

  return result;
};

export const listOnlyUnconfirmedDiseases = (
  list?: any[]
): ChronicDiseaseOption[] => {
  let result: ChronicDiseaseOption[] = [];

  if (!list) {
    return [];
  }

  list.forEach((disease: any) => {
    if (!disease.confirmed) {
      result.push({
        name: chronicDiseasesEnum[disease.disease],
        id: disease.disease
      });
    }
  });

  return result;
};
