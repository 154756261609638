/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Col, Row } from "react-bootstrap";
import { Control } from "react-hook-form";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import CostCalculator from "../../../util/CostCalculator";
import { FeeItemResponse } from "../../../models/management/FeeItemResponse";
import { DiscountItemResponse } from "../../../models/discount/DiscountItemResponse";
import PriceInputsControlled from "../../../components/ReactHookFormFields/Specific/PriceInputsControlled";

interface Props {
  control: Control<any>;
  cost: string;
  fee?: FeeItemResponse;
  currency?: string;
  discount?: DiscountItemResponse;
}
const DiscountItemInputs: React.FC<Props> = ({
  control,
  cost,
  currency,
  fee,
  discount,
}: Props) => {
  return (
    <div className="text-left">
      <div className="font-weight-bold pt-3 pb-2">
        {getStringFromEnumsOrReturn(cost)}
      </div>
      <Row>
        <Col className="text-left align-middle">
          <div className="small">{strings.originalPrice}</div>
          <div className="font-weight-bold">
            {/* TODO: rounding decimal length -> country of "GlobalVet" */}
            {CostCalculator.getGrossPriceOfItem({
              price: fee?.price || 0,
              quantity: 1,
              vat: fee?.vat,
            })}{" "}
            {currency}
          </div>
          <div className="small text-muted">
            {fee?.price || 0} {currency} {strings.plusVat}
          </div>
        </Col>
        <Col className="col-8">
          <PriceInputsControlled
            name={cost}
            label={strings.discountedPrice}
            control={control}
            currency={currency}
            vat={fee?.vat}
            value={discount?.price}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DiscountItemInputs;
