/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import PictureSettings from "../PictureSettings";
import { strings } from "../../../common/Strings/Strings";
import { PetDetailsResponse } from "../../../models/pet/PetDetailsResponse";

interface Props {
  pet: PetDetailsResponse;
  refresh: () => any;
}

/* We are only using the user pet data for picture currently (not the clinic pet data) */
const PetProfilePictureSettings: React.FC<Props> = ({
  pet,
  refresh,
}: Props) => (
  <PictureSettings
    ownerType="pet"
    logoText={strings.petProfilePicture}
    onRefresh={refresh}
    owner={pet}
    ownerId={pet.id}
    petSpecies={pet.species}
    pictureId={pet.profilePicture}
  />
);

export default PetProfilePictureSettings;
