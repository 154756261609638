/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import AlertBox from "../../../../../../components/AlertBox";
import { strings } from "../../../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../../../util/helperFunctions";
import { PublicPet } from "../../../../../../models/pet/PublicPet";
import CloseButton from "../../../../../../components/CloseButton";
import logger from "../../../../../../util/logger";
import PetOwnerApi from "../../../../../../api/PetOwnerApi";
import Modal from "../../../../../../components/Modal/Modal";
import Button from "../../../../../../components/Button";
import PetProfilePicture from "../../../../../../components/Pictures/Pet/PetProfilePicture";

interface Props {
  close(): void;
  isOpen: boolean;
  pet?: PublicPet;
  petOwnerId?: string;
  setSuccess(value: boolean): void;
}

const DeclineOwnershipModal = ({
  close,
  isOpen,
  pet,
  petOwnerId,
  setSuccess,
}: Props): ReactElement => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();

  const submitDecline = async () => {
    if (petOwnerId) {
      setSubmitting(true);
      try {
        await PetOwnerApi.unlinkPetOwnerFromUser(petOwnerId);
        setSuccess(true);
      } catch (e) {
        setError(await getGeneralError(e));
        logger.error(e);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.declineOwnership} />
      <Modal.Body>
        <div>{strings.areYouSureToDecline}</div>
        <div className="flex items-center">
          <div className="w-[80px] h-[80px]">
            <PetProfilePicture species={pet?.species} />
          </div>
          <b className="pl-5">{pet?.name}</b>
        </div>
        <AlertBox className="mt-5" message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={submitting}
          loading={submitting}
          onClick={submitDecline}
          variant="danger"
        >
          {strings.declineOwnership}
        </Button>
        <CloseButton disabled={submitting} onClick={close} />
      </Modal.Footer>
    </Modal>
  );
};

export default DeclineOwnershipModal;
