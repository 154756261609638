/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../common/Strings/Strings";
import { ClinicResponse } from "../../models/clinic/ClinicResponse";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import CountryApi from "../../api/CountryApi";
import { getGeneralError } from "../../util/helperFunctions";
import AlertBox from "../../components/AlertBox";
import IncomeSummaryModal from "./IncomeSummaryModal";
import PendingInvoiceList from "./InvoiceListing/PendingInvoiceList";
import InvoiceList from "./InvoiceListing/InvoiceList";
import Button from "../../components/Button";
import { ShoppingCart } from "../../common/Icons/ShoppingCart";

interface Props {
  clinic?: ClinicResponse;
}

export type TabState = "inProgress" | "active";

const WorkerInvoices = ({ clinic }: Props): ReactElement => {
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [isIncomeSummaryModalOpen, setIncomeSummaryModalOpen] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;
    const clinicId = clinic?.id;

    if (!clinicId) {
      return undefined;
    }

    const getCountryDetails = async (clinicId: string) => {
      try {
        const response = await CountryApi.getCountryDetailsOfClinic(clinicId);
        if (isMounted) {
          setCountryDetails(response.data);
        }
      } catch (err) {
        if (isMounted) {
          setError(await getGeneralError(err));
        }
      }
    };

    void getCountryDetails(clinicId);

    return () => {
      isMounted = false;
    };
  }, [clinic]);

  return (
    <>
      <div className="flex content-center flex-column space-y-6">
        <div className="flex justify-between">
          <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
            {strings.sales}
          </h1>
          <div className="ml-auto">
            <Button
              variant="tertiary"
              onClick={() => {
                setIncomeSummaryModalOpen(true);
              }}
            >
              {strings.incomeSummary}
            </Button>
          </div>
          <div hidden={!clinic} className="ml-3">
            <Link to={`/invoices/new`}>
              <Button>
                <ShoppingCart className="h-5 w-5" />
              </Button>
            </Link>
          </div>
        </div>
        <AlertBox message={error} />
        <PendingInvoiceList clinic={clinic} />
        <InvoiceList clinic={clinic} />
      </div>
      <IncomeSummaryModal
        clinic={clinic}
        countryDetails={countryDetails}
        close={() => {
          setIncomeSummaryModalOpen(false);
        }}
        isOpen={isIncomeSummaryModalOpen}
      />
    </>
  );
};

export default WorkerInvoices;

WorkerInvoices.defaultProps = {
  clinic: undefined,
};
