/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logger from "../util/logger";
import qs from "query-string";

/* simple: simple string attributes, or nested, non string types */
export const useDeepLinking = <T>(
  input?: T,
  simple = true
): [T | undefined, (nV: T | undefined) => void, boolean] => {
  const [loadedValue, setLoadedValue] = useState<T | undefined>(input);
  const [deepLinkedValuesDeserialized, setDeepLinkedValuesDeserialized] = useState<boolean>(false);
  const { pathname, search } = useLocation();

  const clean = (obj: any) => {
    Object.keys(obj).forEach((propName) => {
      if (obj.hasOwnProperty(propName)) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        } else if (typeof obj[propName] === "object") {
          clean(obj[propName]);
        }
      }
    });

    return obj;
  };

  const encode = (a: Record<string, any>, nested: boolean): string => {
    /* Handles nested objects and arrays as well. */
    if(nested){
      const b = JSON.stringify(clean(a));
      const c = encodeURIComponent(b);

      return c;
    }
    else {
      return qs.stringify({...a});
    }

  };

  const decode = (d: string, nested: boolean): T | undefined => {
    try {
      /* Handles nested objects and arrays as well. */
      if(nested){
        const e = decodeURIComponent(d);
        const f = JSON.parse(e) as T;
        return f;
      }
      else {
        return qs.parse(d) as unknown as T;
      }
    } catch (e) {
      logger.error(e);
    }

  return undefined;
  };

  const putIntoUrl = (newValue: T | undefined) => {
    const newUrl = `${pathname  }${  newValue ? "?" + encode(newValue, simple) : ""}`;
    window.history.replaceState({ path: newUrl }, "", newUrl);
  };

  const onValueChange = useCallback(
    (newValue: T | undefined) => {
      putIntoUrl(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [input]
  );

  useEffect(() => {
    if (input) {
      putIntoUrl(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  useEffect(() => {
    if (search && search.length > 0) {
      setLoadedValue(decode(search.substring(1), simple));
    }
    setDeepLinkedValuesDeserialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [loadedValue, onValueChange, deepLinkedValuesDeserialized];
};
