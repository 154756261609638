/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import * as Sentry from "@sentry/react";
import React, { ErrorInfo, ReactNode } from "react";
import { getGeneralError } from "./helperFunctions";
import logger from "./logger";
import AlertBox from "../components/AlertBox";

interface Props {
  children: ReactNode;
}

interface State {
  errorMessage: string | null;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { errorMessage: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { errorMessage: error.message };
  }

  componentDidMount(): void {
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const data = { error, info };
    logger.error(data);

    const userId = localStorage.getItem("userId");
    if (userId) {
      Sentry.setContext("user", { userId });
    }
    Sentry.captureException(error);
    getGeneralError(error)
      .then(msg => this.setState({errorMessage: msg}))
  }

  componentWillUnmount(): void {
    window.removeEventListener(
      "unhandledrejection",
      this.promiseRejectionHandler
    );
  }

  promiseRejectionHandler = async (event: PromiseRejectionEvent) => {
    this.setState({ errorMessage: await getGeneralError(event.reason) });
  };

  render(): ReactNode {
    const { state, props } = this;
    if (state.errorMessage) {
      return (
        <div className="fix-container">
          <div className="background-rectangle">
            <AlertBox
              message={state.errorMessage}
              closeAble={false}
              className="m-3"
            />
          </div>
        </div>
      );
    }
    return props.children;
  }
}

export default ErrorBoundary;
