/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { petGenders, petSpecies } from "../../../../common/Strings/Strings";
import { CalendarReservation } from "../../../../models/calendar/CalendarReservation";
import { Colors } from "../../../../models/Colors";
import { ViewTypes } from "../../MainCalendar";
import { ReservationState } from "../../../../models/reservation/ReservationState";
import IconBubble from "../../../../components/IconBubble";
import ReservationStateIcons from "../../Reservation/ReservationStateIcons";

interface Props {
  height: number;
  isItActive?: boolean;
  reservation?: CalendarReservation;
  viewType?: ViewTypes;
}

const AppointmentCard = ({
  height,
  isItActive,
  reservation,
  viewType,
}: Props): ReactElement => {
  const borderColor = reservation?.isEmergency
    ? Colors.RED
    : reservation?.reservationType?.color;

  const petLabel = ({ pet, petOfNewOwner }: CalendarReservation) => (
    <>
      <b>{petOfNewOwner ? petOfNewOwner.name : pet?.name || ""}</b>
      {pet
        ? ` (${petSpecies[pet.species]}, ${pet.breed}, ${
            petGenders[pet.gender]
          })`
        : ""}
    </>
  );

  return (
    <div
      className="relative h-full py-0.5 flex justify-center"
      style={{ borderColor: borderColor }}
    >
      {/* Left vertical line */}
      <div
        className="absolute top-0.5 bottom-0.5 left-0 w-2"
        style={{ backgroundColor: borderColor }}
      />

      {/* Text content */}
      <div
        className={`border ${
          isItActive ? "border-inherit" : "border-gray-300 dark:border-gray-600"
        } hover:border-inherit focus:border-inherit hover:shadow-md focus:shadow-md rounded-r-lg cursor-pointer w-full pl-4 pr-10 flex flex-col justify-center text-sm transition-all duration-300`}
      >
        <div
          className={
            reservation?.state === ReservationState.PAID ||
            reservation?.state === ReservationState.PENDING_PAYMENT
              ? "line-through text-gray-500 dark:text-gray-400"
              : ""
          }
        >
          <div className="truncate overflow-hidden">
            {reservation && petLabel(reservation)}
          </div>
          <div className="truncate overflow-hidden">
            {reservation?.description}
          </div>
        </div>
      </div>
      {/* Icons */}
      <div className="absolute top-0.5 bottom-0.5 right-0 flex gap-0.5 pr-1 justify-center items-center">
        <div className="flex flex-col gap-0.5">
          <div
            hidden={
              reservation?.lastMedicalRecordId !==
                reservation?.lastSignedMedicalRecordId ||
              reservation?.lastMedicalRecordId === null
            }
          >
            <IconBubble
              height="16px"
              backgroundColor={Colors.PRIMARY}
              icon="edit"
              iconColor={Colors.WHITE}
              iconFontSize="10px"
              width="16px"
              margin={"0 0 0 0"}
            />
          </div>
          <ReservationStateIcons
            notArrivedVisible={false}
            size="h-4 w-4"
            reservationState={reservation?.state}
          />
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;

AppointmentCard.defaultProps = {
  reservation: undefined,
  viewType: undefined,
};
