/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import {
  generateOptions,
  getGeneralError,
} from "../../../util/helperFunctions";
import { LocalLanguageResponse } from "../../../models/language/LocalLanguageResponse";
import logger from "../../../util/logger";
import LanguageApi from "../../../api/LanguageApi";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import SimpleSelect from "../../../components/InputFieldsSimple/SimpleSelect";
import AlertBox from "../../../components/AlertBox";

interface Props {
  setLanguage(lan: string | undefined): void;
}

const InstructionLanguageSection: React.FC<Props> = ({
  setLanguage,
}: Props) => {
  const [languages, setLanguages] = useState<LocalLanguageResponse[]>([]);
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    let isMounted = true;

    const getLanguages = async () => {
      try {
        const response = await LanguageApi.getLanguages();
        if (isMounted) {
          setLanguages(response.data);
        }
      } catch (err) {
        if (isMounted) {
          setError(await getGeneralError(err));
        }
        logger.error(err);
      }
    };

    void getLanguages();

    return () => {
      isMounted = false;
    };
  }, []);

  const languageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "-") {
      setLanguage(undefined);
    } else {
      setLanguage(e.target.value);
    }
  };

  return (
    <div className="tw-card space-y-4 p-4">
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
        {strings.instructionLanguage}
      </h2>
      <div>
        <SimpleSelect
          name="instructionLanguage"
          options={generateOptions(languages, "displayLanguage", "code")}
          autoComplete={AutoCompleteOptions.off}
          isChooseOption={false}
          firstOption={{ title: "-", value: "-" }}
          label={strings.pleaseSelectIfNotSwedish}
          onChange={languageChange}
          optional
        />
      </div>
      <AlertBox message={error} />
    </div>
  );
};

export default InstructionLanguageSection;
