/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { ReservationState } from "../../../models/reservation/ReservationState";
import { Clock } from "../../../common/Icons/Clock";
import { MagnifyingGlassCircle } from "../../../common/Icons/MagnifyingGlassCircle";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import { CurrencyDollar } from "../../../common/Icons/CurrencyDollar";
import IconBubble from "../../../components/IconBubble";

interface Props {
  notArrivedVisible?: boolean;
  reservationState?: ReservationState;
  size?: string;
}

const ReservationStateIcons = ({
  notArrivedVisible = true,
  reservationState,
  size = "w-10 h-10",
}: Props): ReactElement => {
  switch (reservationState) {
    case ReservationState.ARRIVED:
      return (
        <Clock
          className={`${size} flex-shrink-0 text-orange-500 dark:text-orange-500`}
          variant="solid"
        />
      );
    case ReservationState.PAID:
      return (
        <CheckCircle
          className={`${size} flex-shrink-0 text-green-500 dark:text-green-500`}
        />
      );
    case ReservationState.PENDING_PAYMENT:
      return (
        <CurrencyDollar
          className={`${size} flex-shrink-0 text-red-500 dark:text-red-500`}
        />
      );
    case ReservationState.READY_FOR_PAYMENT:
      return (
        <CurrencyDollar
          className={`${size} flex-shrink-0 text-slate-500 dark:text-slate-500`}
        />
      );
    case ReservationState.UNDER_TREATMENT:
      return (
        <MagnifyingGlassCircle
          className={`${size} flex-shrink-0 text-sky-500 dark:text-sky-500`}
        />
      );
    default:
      return notArrivedVisible ? <IconBubble width={size} /> : <></>;
  }
};

export default ReservationStateIcons;
