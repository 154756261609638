/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../AlertBox";
import Button from "../../Button";
import { Envelope } from "../../../common/Icons/Envelope";

interface Props {
  email: string;
  error?: null | string;
  loading: boolean;
  resend(): void;
  success?: boolean;
}

const EmailConfirmationInfoBase: React.FC<Props> = ({
  email,
  error,
  loading,
  resend,
  success = false,
}: Props) => (
  <div className="space-y-6">
    <div className="flex items-center space-x-2">
      <Envelope />
      <div className="font-semibold">{strings.pleaseCheckYourEmailInbox}</div>
    </div>
    <div>
      <p>{strings.formatString(strings.sentYouEmailLink, <b>{email}</b>)}</p>
      <p>{strings.pleaseCheckSpam}</p>
    </div>
    <div className="flex items-center justify-between">
      <Button disabled={loading} onClick={resend} variant="link">
        {strings.resendConfirmationEmailBtnTxt}
      </Button>
    </div>
    <AlertBox closeAble={false} className="my-5" message={error} />
    <AlertBox
      closeAble={false}
      className="my-5"
      message={strings.emailSentSuccessfully}
      hidden={!success}
      type={AlertType.SUCCESS}
    />
  </div>
);

export default EmailConfirmationInfoBase;
