/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getStringFromEnumsOrReturn, strings } from "../../common/Strings/Strings";
import CloseButton from "../../components/CloseButton";
import AlertBox from "../../components/AlertBox";
import Textarea from "../../components/ReactHookFormFields/General/Textarea";
import LoaderInline from "../../components/LoaderInline";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button";
import IconBubble from "../../components/IconBubble";
import { CalendarIcon } from "../../common/Icons/Calendar";
import { ReservationResponse } from "../../models/reservation/ReservationResponse";
import moment from "moment";
import ReservationStateIcons from "../Calendar/Reservation/ReservationStateIcons";

interface Props {
  cancellable: boolean;
  closeDeleteModal(): void;
  deleteError: null | string;
  deleteReservation(values: { explanation: string }): void;
  modalDeleteIsOpen: boolean;
  reservation?: ReservationResponse;
}

const DeleteAppointmentModal = ({
  cancellable,
  closeDeleteModal,
  deleteError,
  deleteReservation,
  modalDeleteIsOpen,
  reservation,
}: Props): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<{ explanation: string }>();

  useEffect(() => {
    setLoading(false);
  }, [modalDeleteIsOpen]);

  const submit = (values: { explanation: string }) => {
    setLoading(true);
    deleteReservation(values);
  };

  return (
    <Modal show={modalDeleteIsOpen} handleClose={closeDeleteModal}>
      <Modal.Header>
        <h3 className="truncate overflow-hidden xl:text-xl font-bold leading-tight text-zinc-800 dark:text-white">
          {strings.cancelAppointment}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleSubmit(submit)}
          className="space-y-6"
          id="deleteAppointmentForm"
        >
          <p>{strings.AreYouSureDeleteAppointment}</p>
          <div>
            <h4 className="text-lg font-semibold leading-tight text-zinc-800 dark:text-white">
              {reservation?.reservationType?.name} ({reservation?.pet?.name})
            </h4>
          </div>
          <div className="flex items-center space-x-3">
            <IconBubble>
              <CalendarIcon />
            </IconBubble>
            <div className="font-bold">
              {reservation && moment(reservation?.startDateTime).format("LLL")}
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <ReservationStateIcons reservationState={reservation?.state}/>
            <div className="font-bold">
              {getStringFromEnumsOrReturn(reservation?.state)}
            </div>
          </div>
          <Textarea
            name="explanation"
            register={register}
            label={strings.whyDoYouWantToRejectAppointment}
            minRows={2}
            required
          />
          <AlertBox
            message={deleteError}
            hidden={cancellable || deleteError === null}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={closeDeleteModal} />
        {loading ? (
          <LoaderInline />
        ) : (
          <Button form="deleteAppointmentForm" variant="danger" type="submit">
            {strings.cancelAppointment}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteAppointmentModal;
