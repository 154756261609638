/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../Strings/Strings";
import { Cog } from "../../Icons/Cog";
import { Users } from "../../Icons/Users";
import { MenuItemSiteManager } from "./MenuItem";
import { SiteManagerAuthority } from "../../../models/management/SiteManagerAuthority";
import { Document } from "../../Icons/Document";
import { ShieldCheck } from "../../Icons/ShieldCheck";
import { BankNotes } from "../../Icons/BankNotes";
import { ChatBubbleLeftRight } from "../../Icons/ChatBubbleLeftRight";
import { BuildingOfficeTwo } from "../../Icons/BuildingOfficeTwo";
import { ArrowTrendingUp } from "../../Icons/ArrowTrendingUp";
import { VideoCamera } from "../../Icons/VideoCamera";

const iconClassName = "w-6 h-6 flex-shrink-0";

const SiteManagerMenuItems: MenuItemSiteManager[] = [
  {
    type: "SITE_MANAGER_SETTINGS",
    name: strings.SITE_MANAGER_SETTINGS,
    href: "/management/settings",
    icon: <Cog className={iconClassName} />,
  },
  {
    type: "USERS",
    name: strings.USERS,
    href: "/management/users",
    authority: SiteManagerAuthority.MANAGER_USERS,
    icon: <Users className={iconClassName} />,
  },
  {
    type: "CLINICS",
    name: strings.CLINICS,
    href: "/management/clinics",
    authority: SiteManagerAuthority.MANAGER_CLINICS,
    icon: <BuildingOfficeTwo className={iconClassName} />,
  },
  {
    type: "BILLING",
    name: strings.BILLING,
    href: "/management/billing",
    authority: SiteManagerAuthority.MANAGER_BILLING,
    icon: <BankNotes className={iconClassName} />,
  },
  {
    type: "VET_DOCUMENTS",
    name: strings.VET_DOCUMENTS,
    href: "/management/vet-documents",
    authority: SiteManagerAuthority.MANAGER_DOCUMENTS,
    icon: <Document className={iconClassName} />,
  },
  {
    type: "INSURANCE",
    name: strings.INSURANCE,
    href: "/management/insurance",
    authority: SiteManagerAuthority.MANAGER_INSURANCE,
    icon: <ShieldCheck className={iconClassName} />,
  },
  {
    type: "FEEDBACKS",
    name: strings.FEEDBACKS,
    href: "/management/feedbacks",
    authority: SiteManagerAuthority.MANAGER_FEEDBACKS,
    icon: <ChatBubbleLeftRight className={iconClassName} />,
  },
  {
    type: "ANALYTICS",
    name: strings.ANALYTICS,
    href: "/management/analytics/events",
    authority: SiteManagerAuthority.MANAGER_CLINICS,
    icon: <ArrowTrendingUp className={iconClassName} />,
  },
  {
    type: "VIDEOCHAT_MONITOR",
    name: strings.VIDEOCHAT_MONITOR,
    href: "/management/videochat-monitor",
    authority: SiteManagerAuthority.MANAGER_FULL,
    icon: <VideoCamera className={iconClassName} variant="outline" />,
  },
];

export default SiteManagerMenuItems;
