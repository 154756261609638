/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const breedsSv = {
  DOG: [
    "Affenpinscher",
    "Afghanhund",
    "Africanis",
    "Aidi",
    "Ainu",
    "Airedaleterrier",
    "Akbash",
    "Akita",
    "Alano español",
    "Alaskan malamute",
    "Alentejomastiff",
    "Alpenländische dachsbracke",
    "Altdeutsche Hütehunde",
    "American Bulldog",
    "American English Coonhound",
    "American Leopard Hound",
    "American akita",
    "American eskimo dog",
    "American foxhound",
    "American hairless terrier",
    "American staffordshire terrier",
    "American toy fox terrier",
    "American water spaniel",
    "Amerikansk cocker spaniel",
    "Amerikansk pitbullterrier",
    "Anatolisk herdehund",
    "Anglo-français de petite vénerie",
    "Anglo-russkaja gontjaja",
    "Appenzeller sennenhund",
    "Ariégeois",
    "Australian Silky Terrier",
    "Australian cattledog",
    "Australian kelpie",
    "Australian shepherd",
    "Australian stock dog",
    "Australian stumpy tail cattle dog",
    "Australisk terrier",
    "Azawakh",
    "Balgarski barak",
    "Balgarsko gontje",
    "Balgarsko ovtjarsko kutje",
    "Balkanski gonic",
    "Barbado da Terceira",
    "Barbet",
    "Basenji",
    "Basset artésien normand",
    "Basset bleu de gascogne",
    "Basset fauve de bretagne",
    "Basset griffon vendéen",
    "Basset hound",
    "Bayersk viltspårhund",
    "Beagle",
    "Beagle-harrier",
    "Bearded collie",
    "Beauceron",
    "Bedlingtonterrier",
    "Belgisk vallhund",
    "Bergamasco",
    "Berger blanc suisse",
    "Berger de Bauce",
    "Berger de Brie",
    "Berger de Crau",
    "Berger des pyrénées",
    "Berger picard",
    "Berner sennenhund",
    "Bernerstövare",
    "Bichon frisé",
    "Bichon havanais",
    "Bichon petit chien lion",
    "Biewer Terrier",
    "Billy",
    "Black and tan coonhound",
    "Blodhund",
    "Blue Lacy",
    "Bluetick coonhound",
    "Boerboel",
    "Bolognese",
    "Bolonka",
    "Bordeauxdogg",
    "Border collie",
    "Borderterrier",
    "Borzoi",
    "Bosanski ostrodlaki gonic-barak",
    "Bostonterrier",
    "Bouvier des ardennes",
    "Bouvier des flandres",
    "Boxer",
    "Boykin Spaniel",
    "Bracco italiano",
    "Brandlbracke",
    "Braque d'auvergne",
    "Braque de l’ariège",
    "Braque du bourbonnais",
    "Braque dupuy",
    "Braque français",
    "Braque saint-germain",
    "Breton",
    "Briard",
    "Briquet de Provence",
    "Briquet griffon vendéen",
    "Broholmer",
    "Bruno Saint Hubert français",
    "Buldogue campeiro",
    "Buldogue serrano",
    "Bull Dog",
    "Bullenbeisser",
    "Bullmastiff",
    "Bullterrier",
    "Burjat-mongolskij volkodav",
    "Böhmisk vallhund",
    "Ca de bestiar",
    "Ca de bou",
    "Cairnterrier",
    "Canaan dog",
    "Canadian eskimo dog",
    "Cane corso",
    "Cane da pastore Bergamasco",
    "Caravan Hound",
    "Carea leonés",
    "Carolina Dog",
    "Catahoula Leopard Dog",
    "Cavalier king charles spaniel",
    "Ceskoslovenský vlciak",
    "Cesky fousek",
    "Ceskyterrier",
    "Chart polski",
    "Chesapeake bay retriever",
    "Chien Corse",
    "Chien de Montagne de l'Atlas",
    "Chien d’artois",
    "Chien gris de Saint Louis",
    "Chihuahua",
    "Chinese crested dog",
    "Chinook",
    "Chippiparai",
    "Chodský pes",
    "Chortaj",
    "Chow chow",
    "Cimarrón uruguayo",
    "Ciobanesc romanesc carpatin",
    "Ciobanesc romanesc corb",
    "Ciobanesc romanesc de bucovina",
    "Ciobanesc romanesc mioritic",
    "Cirneco dell'etna",
    "Clumber spaniel",
    "Coban Köpegi",
    "Cocker spaniel",
    "Collie",
    "Combai",
    "Coton de tuléar",
    "Crnogorski planinski gonic",
    "Curly coated retriever",
    "Cursinu",
    "Czeslovakian Wolfdog",
    "Cão da serra da estrela",
    "Cão da serra de aires",
    "Cão de castro laboreiro",
    "Cão de gado transmontano",
    "Cão fila de são miguel",
    "Dalbohund",
    "Dalmatiner",
    "Dandie dinmont terrier",
    "Dansk spids",
    "Dansk-svensk gårdshund",
    "Deutsch Drahthaar",
    "Deutsch Kurzhaar",
    "Deutsch Langhaar",
    "Deutsch stichelhaar",
    "Deutsche Dogge",
    "Deutsche bracke",
    "Deutscher Jagdterrier",
    "Deutscher Pinscher",
    "Dhokhi apso",
    "Dingo",
    "Do-Khyi",
    "Dobermann",
    "Dogo argentino",
    "Dogo canario",
    "Dogo guatemalteco",
    "Dogue brasileiro",
    "Dogue de bordeaux",
    "Drentsche patrijshond",
    "Drever",
    "Dunkerstövare",
    "Dvärgpinscher",
    "Dvärgpudel",
    "Dvärgschnauzer",
    "Dvärgspets",
    "Dvärgtax",
    "Engelsk blodhund",
    "Engelsk bulldogg",
    "Engelsk cocker spaniel",
    "Engelsk foxhound",
    "Engelsk setter",
    "Engelsk springer spaniel",
    "English Coonhound",
    "English toy terrier",
    "Entlebucher sennenhund",
    "Erdélyi kopó",
    "Estnisk stövare",
    "Eurasier",
    "Euskal Artzain Txakurra",
    "Faraohund",
    "Field spaniel",
    "Fila brasileiro",
    "Finsk lapphund",
    "Finsk spets",
    "Finsk stövare",
    "Flat coated retriever",
    "Foxhound",
    "Foxterrier",
    "Fransk bulldogg",
    "Fransk vattenspaniel / Fransk vattenhund",
    "Français blanc et noir",
    "Français blanc et orange",
    "Français tricolore",
    "Frisisk vattenhund",
    "Galgo español",
    "Gammel dansk hönsehund",
    "Gampr",
    "Gascon saintongeois",
    "Golden retriever",
    "Gonczy polski",
    "Gordonsetter",
    "Gos Rater Valencià",
    "Gos d'atura catalá (Perro de pastor catalán",
    "Gotlandsstövare",
    "Grand anglo-français blanc et noir",
    "Grand anglo-français blanc et orange",
    "Grand anglo-français tricolore",
    "Grand basset griffon vendéen",
    "Grand bleu de gascogne",
    "Grand danois",
    "Grand gascon saintongeois",
    "Grand griffon vendéen",
    "Great japanese dog",
    "Greyhound",
    "Griffon belge",
    "Griffon bleu de gascogne",
    "Griffon bruxellois",
    "Griffon d'arret à poil dur",
    "Griffon fauve de bretagne",
    "Griffon nivernais",
    "Griffon à poil laineux",
    "Groenendael",
    "Grosser münsterländer",
    "Grosser schweizer sennenhund",
    "Grosspitz",
    "Gråhund",
    "Grönlandshund",
    "Haldenstövare",
    "Hamiltonstövare",
    "Hannoveransk viltspårhund",
    "Harrier",
    "Hedehund",
    "Hellinikos ichnilatis",
    "Hellinikos poimenikos",
    "Hokkaido",
    "Hollandse herdershond",
    "Hollandse smoushond",
    "Hovawart",
    "Hrvatski ovcar",
    "Hygenstövare",
    "Hälleforshund",
    "Irish glen of imaal terrier",
    "Irish softcoated wheaten terrier",
    "Irländsk röd och vit setter",
    "Irländsk röd setter",
    "Irländsk terrier",
    "Irländsk varghund",
    "Irländsk vattenspaniel",
    "Isländsk fårhund",
    "Istarski gonic",
    "Italiensk pointer",
    "Italiensk vinthund",
    "Jack russell terrier",
    "Jakutskaja lajka",
    "Japanese chin",
    "Japansk spets",
    "Japansk terrier",
    "Jemtse apso",
    "Jugoslovenski Ovcarski Pas",
    "Jurastövare",
    "Juzjnorusskaja ovtjarka",
    "Jämthund",
    "Kai",
    "Kangal Çoban Köpeği",
    "Kanintax",
    "Karabash",
    "Karakatjan",
    "Karelsk björnhund",
    "Katalansk vallhund",
    "Kaukasisk ovtjarka",
    "Kavkazskaja ovtjarka",
    "Keeshond",
    "Kelpie",
    "Kerry Beagle",
    "Kerry blue terrier",
    "Kinesisk nakenhund",
    "King charles spaniel",
    "Kintamani-Balihund",
    "Kishu",
    "Kleiner münsterländer",
    "Kleinspitz",
    "Kokoni",
    "Kombai",
    "Komondor",
    "Kooikerhondje",
    "Kopó",
    "Korea jindo dog",
    "Korthals",
    "Korthårig vorsteh",
    "Kraski ovcar",
    "Kritikos lagonikos",
    "Kromfohrländer",
    "Kunming langgou",
    "Kuvasz",
    "Labrador retriever",
    "Lagotto romagnolo",
    "Lajka",
    "Lakelandterrier",
    "Lakenois",
    "Lancashire heeler",
    "Landseer",
    "Lapphund",
    "Lapsk vallhund",
    "Leiko helliniko tsopanoskilo",
    "Leonberger",
    "Lhasa apso",
    "Lietuviu skalikas",
    "Lupo italiano",
    "Luzernerstövare",
    "Långhårig vorsteh",
    "Löwchen",
    "Magyar agár",
    "Majorero",
    "Mali medimurski pas",
    "Malinois",
    "Mallorcamastiff",
    "Malteser",
    "Manchesterterrier",
    "Maneto",
    "Maremmano abruzzese",
    "Markiesje",
    "Mastiff",
    "Mastin español",
    "Mastino napoletano",
    "Mastín del Pirineo",
    "Mellanasiatisk ovtjarka",
    "Mellanpinscher",
    "Mellanpudel",
    "Mellanschnauzer",
    "Mexikansk nakenhund",
    "Miniature american shepherd",
    "Miniatyrbullterrier",
    "Mittelspitz",
    "Molossos tis Ipeiro",
    "Mops",
    "Moskovskaja storozjevaja",
    "Moskvadvärgterrier",
    "Mountain Cur",
    "Mudhol",
    "Mudi",
    "Mâtin Belge",
    "Münsterländer",
    "Neapolitansk mastiff",
    "Nederlandse kooikerhondje",
    "Nenetskaja olenegonka lajka",
    "Newfoundlandshund",
    "Nihon teria",
    "Norfolkterrier",
    "Norrbottenspets",
    "Norsk buhund",
    "Norsk lundehund",
    "Norsk älghund",
    "Norwichterrier",
    "Nova scotia duck tolling retriever",
    "Nya Guineas sjungande hund",
    "Ogar polski",
    "Ohar",
    "Old english sheepdog",
    "Olde english bulldogge brasileiro",
    "Olenegonnyj sjpits (Nenetskaja olenegonka lajka",
    "Otterhound",
    "Ovejero magallánico",
    "Ovelheiro gaúcho",
    "Pachón navarro",
    "Papillon",
    "Parson russell terrier",
    "Pashmi",
    "Pekingese",
    "Perdigueiro português",
    "Perdiguero de burgos",
    "Perro de agua español",
    "Perro de pastor catalán",
    "Perro de pastor garafiano",
    "Perro de pastor mallorquín",
    "Perro de pastor vasco",
    "Perro dogo mallorquín (Ca de bou",
    "Perro leonés de pastor",
    "Perro sin pelo del perú",
    "Peruansk nakenhund",
    "Petit basset griffon vendéen",
    "Petit bleu de gascogne",
    "Petit brabancon",
    "Petit gascon saintongeois",
    "Phaléne",
    "Picardy",
    "Pinscher",
    "Pitbull Terrier",
    "Plott",
    "Podenco andaluz",
    "Podenco canario",
    "Podenco ibicenco",
    "Podenco valenciano",
    "Podengo portugues",
    "Pointer",
    "Poitevin",
    "Polski owczarek nizinny",
    "Polski owczarek podhalanski",
    "Pomeranian (Tysk spets / Zwergspitz",
    "Porcelaine",
    "Portugisisk vattenhund",
    "Posavski gonic",
    "Prazský krysarík",
    "Pudel",
    "Pudelpointer",
    "Puli",
    "Pumi",
    "Pyreneisk mastiff",
    "Pyreneisk vallhund",
    "Pyrenéerhund",
    "Rafeiro do alentejo",
    "Rajapalayam",
    "Rampur Hound",
    "Rastreador brasileiro",
    "Rat terrier",
    "Ratonero bodeguero andaluz",
    "Ratonero valenciano",
    "Redbone Coonhound",
    "Redtick Coonhound",
    "Rhodesian ridgeback",
    "Riesenschnauzer",
    "Rottweiler",
    "Russkaja gontjaja",
    "Russkaja ochotnitjija spaniel",
    "Russkaja pegaja gontjaja",
    "Russkaja tsvetnaja bolonka",
    "Russkiy toy",
    "Rysk fläckig stövare",
    "Rysk stövare",
    "Rysk svart terrier",
    "Rysk vinthund",
    "Rysk-europeisk lajka",
    "Saarloos wolfhond",
    "Sabueso español",
    "Saluki",
    "Samojedhund",
    "Sankt bernhardshund",
    "Sarplaninac",
    "Schafpudel",
    "Schapendoes",
    "Schillerstövare",
    "Schipperke",
    "Schnauzer",
    "Schweizer sennenhund",
    "Schweiziska små stövare",
    "Schweiziska stövare",
    "Schwyzerstövare",
    "Schäfer",
    "Sealyhamterrier",
    "Segugio dell'Appennino",
    "Segugio italiano",
    "Segugio maremmano",
    "Serbski gonic",
    "Serbski trobojni gonic",
    "Shar pei",
    "Shetland sheepdog",
    "Shiba",
    "Shih tzu",
    "Shikoku",
    "Siberian husky",
    "Silken Windhound",
    "Silkyterrier",
    "Skotsk hjorthund",
    "Skotsk terrier",
    "Skyeterrier",
    "Sloughi",
    "Slovenský cuvac",
    "Slovenský hrubosrsty stavac",
    "Slovenský kopov",
    "Släthårig foxterrier",
    "Smålandsstövare",
    "Spansk mastiff",
    "Spansk vattenhund",
    "Spinone",
    "Sredneasiatskaja ovtjarka",
    "Srpski pastirski pas",
    "St. John's Dog",
    "Stabijhoun",
    "Staffordshire bullterrier",
    "Steirische rauhhaarbracke",
    "Stichelhaariger deutscher vorsteh",
    "Storpudel",
    "Strellufstövare",
    "Strävhårig foxterrier",
    "Strävhårig vorsteh",
    "Sulimovhund",
    "Sussex spaniel",
    "Svart terrier",
    "Svensk lapphund",
    "Svensk vit älghund",
    "Sydrysk ovtjarka",
    "Taigan",
    "Taiwan dog",
    "Tax",
    "Teddy Roosevelt Terrier",
    "Tenterfield terrier",
    "Terrier brasileiro",
    "Terrier chileno",
    "Tervueren",
    "Thai bangkaew dog",
    "Thai ridgeback dog",
    "Tibetansk mastiff",
    "Tibetansk spaniel",
    "Tibetansk terrier",
    "Tirolerbracke",
    "Tjornyj terjer",
    "Tjukotskaja jesdovaja",
    "Tornjak",
    "Tosa",
    "Toy Fox Terrier",
    "Toypudel",
    "Transsylvansk kopó",
    "Treeing Tennessee Brindle",
    "Treeing walker coonhound",
    "Tweed Water Spaniel",
    "Tysk jaktterrier",
    "Tysk schäferhund",
    "Tysk spets",
    "Ungersk vinthund",
    "Ungersk viszla",
    "Utterhund",
    "Veadeiro pampeano",
    "Viszla",
    "Vit herdehund",
    "Volpino italiano",
    "Vorsteh",
    "Vostotjnoevropejskaja ovtjarka",
    "Västgötaspets",
    "Västsibirisk lajka",
    "Wachtelhund",
    "Weimaraner",
    "Welsh corgi cardigan",
    "Welsh corgi pembroke",
    "Welsh springer spaniel",
    "Welshterrier",
    "West highland white terrier",
    "Westfälische dachsbracke",
    "Wetterhoun",
    "Whippet",
    "Wolfspitz",
    "Working kelpie",
    "Xoloitzcuintle",
    "Yorkshireterrier",
    "Zwergspitz",
    "Épagneul Barbet",
    "Épagneul Breton",
    "Épagneul bleu de picardie",
    "Épagneul de pont-audemer",
    "Épagneul de saint usuge",
    "Épagneul français",
    "Épagneul picard",
    "Österreichischer pinscher",
    "Östsibirisk lajka",
    "Český horský pes",
    "Český strakatý pes"
  ],
  CAT: [
    "Abessinier",
    "Afrodite",
    "American Curl",
    "American Shorthair",
    "American Wirehair",
    "Amerikansk Bobtail",
    "Amerikansk Burma",
    "Anatoli",
    "Arabisk Mau",
    "Asian",
    "Asiatiskt Longhair",
    "Asiatiskt Shorthair",
    "Australian Bombay",
    "Australian Mist",
    "Balines",
    "Bengal",
    "Bohemian Rex",
    "Bombay",
    "Brasiliansk korthår",
    "Brittiskt korthår",
    "Brittiskt långhår",
    "Burma",
    "Burmilla",
    "Burmilla korthår",
    "Burmilla långhår",
    "Celtic shorthair",
    "Ceylonkatt",
    "Chartreux",
    "Chausie",
    "Chinchilla longhair",
    "Chinese Li Hua",
    "Classicat",
    "Colourpoint shorthair",
    "Cornish rex",
    "Cymric",
    "Devon rex",
    "Don sphynx",
    "Donskoy",
    "Egyptisk mau",
    "Europeisk Burma",
    "Europisk korthår",
    "Europé",
    "Exotic",
    "German rex",
    "Havana",
    "Havana brown",
    "Helig birma",
    "Highlander longhair",
    "Highlander shorthair",
    "Isle of Man longhair",
    "Isle of Man shorthair",
    "Japanese bobtail",
    "Korat",
    "Kurilean Bobtail",
    "La Perm",
    "Lykoi",
    "Maine Coon",
    "Manx",
    "Mekong Bobtail",
    "Minskin",
    "Munchkin",
    "Nebelung",
    "Neva Masquerade",
    "Norsk skogkatt",
    "Ocicat",
    "Orientaliskt korthår",
    "Orientaliskt långhår",
    "Perser",
    "Peterbald",
    "Pixie Bob",
    "Ragdoll",
    "Russian",
    "Russian blue",
    "Savannah",
    "Scottish Fold",
    "Scottish Straight",
    "Selkirk Rex",
    "Serengeti",
    "Siames",
    "Sibirisk katt",
    "Singapura",
    "Snowshoe",
    "Sokoke",
    "Somali",
    "Sphynx",
    "Thai",
    "Tiffany",
    "Tonkines",
    "Toyger",
    "Turkisk angora",
    "Turkisk van",
    "York Chocolate"
  ],
  HORSE: [
    "Abacoberber",
    "Abstang",
    "Abyssinier",
    "Achaltekeer",
    "Achetta",
    "Adaev",
    "Aegidienberger",
    "Afshari",
    "Akhal-teké",
    "Albansk ponny",
    "Altaiponny",
    "Alter-real",
    "American Albino",
    "American Indian Horse",
    "American Paint Horse",
    "American Quarter Horse",
    "American White",
    "American cream",
    "American cream draft",
    "American saddlebred",
    "American spotted paso",
    "American walking ponny",
    "Amerikansk curlyhäst",
    "Amerikansk miniatyrhäst",
    "Amerikansk ponny",
    "Amerikansk shetlandsponny",
    "Amerikansk sportponny",
    "Amerikansk travare",
    "Amerikanskt varmblod",
    "Anadoluponny",
    "Andalusier",
    "Andisk ponny",
    "Andravida",
    "Anglo-Luso",
    "Anglo-arabo-sardo",
    "Anglo-karachai",
    "Angloarab",
    "Anglokabarda",
    "Appaloosa",
    "Arabiskt fullblod",
    "Arabo-Frieser",
    "Arabo-Haflinger",
    "Araboulonnais",
    "Arappaloosa",
    "Ardahan",
    "Ardenner",
    "Arenbergponny",
    "Argentinsk modeponny",
    "Argentinsk poloponny",
    "Argentinsk ranchhäst",
    "Argentinsk sporthäst",
    "Ariégeoisponny",
    "Arravani",
    "Asiatisk vildhäst",
    "Asseteagueponny",
    "Astrakhanhäst",
    "Asturconponny",
    "Australian Draught",
    "Australisk boskapshäst",
    "Australisk ponny",
    "Australisk ridponny",
    "Australisk vildhäst",
    "Australiskt varmblod",
    "Auvergnehäst",
    "Auxois",
    "Avelignese",
    "Azorisk ponny",
    "Azteca eller Azteker",
    "Bagualeshäst",
    "Baguioponny",
    "Baixadeirohäst",
    "Bajauponny",
    "Bajkalponny",
    "Baladihäst",
    "Balearisk ponny",
    "Balikunponny",
    "Balinesisk ponny",
    "Baluchihäst",
    "Bandiagarahäst",
    "Baneihäst",
    "Bankerhäst",
    "Bardigiano",
    "Barthaisponny",
    "Basjkir",
    "Baskisk bergshäst",
    "Baskisk ponny",
    "Basutoponny",
    "Batakponny",
    "Bayerskt varmblod",
    "Belgisk ardenner",
    "Belgisk ridponny",
    "Belgisk sporthäst",
    "Belgiskt varmblod",
    "Bengalisk häst",
    "Berberhäst",
    "Bergmannhäst",
    "Bhirumponny",
    "Bhutiaponny",
    "Blazerhäst",
    "Boboponny",
    "Boerhäst",
    "Boerponny",
    "Boliviansk ponny",
    "Borneoponny",
    "Boseponny",
    "Bosnisk ponny",
    "Boulognesare",
    "Brabanthäst",
    "Brandenburgare",
    "Brasiliansk ponny",
    "Brasiliansk sporthäst",
    "Bretagnare",
    "British spotted pony",
    "Brittisk appaloosa",
    "Brittisk ridponny",
    "Brittisk sporthäst",
    "Brumby",
    "Bucovina",
    "Budjonni",
    "Bulgarisk ponny",
    "Buohaiponny",
    "Burguetehäst",
    "Burgundiskt kallblod",
    "Burmesisk ponny",
    "Buryatponny",
    "Calabrese",
    "Calvinia",
    "Camarguehäst",
    "Camarillo white horse",
    "Campeiro",
    "Campolina",
    "Canadian cutting horse",
    "Canadian pacer",
    "Canadian rustic pony",
    "Canik",
    "Carolina marsh tacky",
    "Castillonhäst",
    "Catriahäst",
    "Cayuseponny",
    "Certisino",
    "Cesky Teplokrevnik",
    "Chahouponny",
    "Chaidamuponny",
    "Chakouyiponny",
    "Chalosseponny",
    "Chamurtiponny",
    "Charahäst",
    "Chejuponny",
    "Chernemor",
    "Cheval Demi sang du Centre",
    "Cheval de Corlay",
    "Cheval d’Auvergne",
    "Chickasawhäst",
    "Chilensk corralero",
    "Chilkowponny",
    "Chiloteponny",
    "Chincoteagueponny",
    "Choctawponny",
    "Chumysh-häst",
    "Chyantaponny",
    "Cleveland Bay",
    "Clydesdale",
    "Coffin Bayponny",
    "Colonial spanish horse",
    "Colorado ranger",
    "Comtoishäst",
    "Connemaraponny",
    "Corajoso",
    "Costaricense Paso",
    "Costeñonhäst",
    "Country Saddle Horse",
    "Criollo",
    "Criollo Colombiano",
    "Criollo de Trote",
    "Cubano de Paso",
    "Cumberland Island-häst",
    "Cumbivilcasponny",
    "Cypriotisk häst",
    "Dahomanhäst",
    "Dales",
    "Dansk sportponny",
    "Danskt varmblod",
    "Danube deltaponny",
    "Danubier",
    "Darashouri",
    "Darfurponny",
    "Dartmoorponny",
    "Datongponny",
    "Deli-Orman",
    "Deliboz",
    "Deliponny",
    "Desert Norman",
    "Deutsches Classic Pony",
    "Deutsches Reitpony",
    "Djerma",
    "Dolny-Iskar",
    "Donauhäst",
    "Dongolo",
    "Donhäst",
    "Dosanko",
    "Drumhorse",
    "Dzabh",
    "Dölehäst",
    "Dülmenponny",
    "Ege Midillisi",
    "Egyptisk arab",
    "Egyptisk häst",
    "Einsiedler",
    "Elisponny",
    "Engelskt fullblod",
    "Eriskayponny",
    "Erlunchunponny",
    "Esperiaponny",
    "Estnisk buskponny",
    "Estnisk häst",
    "Estniskt kallblod",
    "Etiopisk ponny",
    "Exmoorponny",
    "Falabella",
    "Fell",
    "Feral Ponies of Virginia",
    "Finskt kallblod",
    "Finskt varmblod",
    "Fjordhäst",
    "Fleuvehäst",
    "Floresponny",
    "Florida cracker horse",
    "Foutahäst",
    "Fox Trotter",
    "Franches-Montagnes",
    "Fransk ardenner",
    "Fransk ridhäst",
    "Fransk ridponny",
    "Fransk travare",
    "Franskt halvblod",
    "Fredriksborgare",
    "Freiberger",
    "Frieserhäst",
    "Friesisk sporthäst",
    "Furioso",
    "Furioso-Northstar",
    "Färöponny",
    "Gala",
    "Galiceñoponny",
    "Galicisk ponny",
    "Garrano",
    "Gayoeponny",
    "Gelderländare",
    "Georgian Grande",
    "Gharkawi",
    "Ghazi Khani",
    "Giarab",
    "Giaraponny",
    "Gidran-arab",
    "Goklanhäst",
    "Golden american saddlebred",
    "Gotlandsruss",
    "Grekisk häst",
    "Grekisk ponny",
    "Greyson Highlands Pony",
    "Groningen",
    "Guangxiponny",
    "Guanzhongponny",
    "Gudbrandsdal",
    "Guizhouponny",
    "Gypsy Vanner",
    "Hack Horse",
    "Hackneyhäst",
    "Hackneyponny",
    "Haflinger",
    "Half-Saddlebred",
    "Hannoveranare",
    "Hantamhäst",
    "Heihehäst",
    "Heilongkiang",
    "Hensonhäst",
    "Hequponny",
    "Hessenhäst",
    "Highlandponny",
    "Hinisponny",
    "Hirzaihäst",
    "Hispano-arab",
    "Hmonghäst",
    "Hokkaidoponny",
    "Holländsk körhäst",
    "Holländsk miniatyrhäst",
    "Holländsk ridponny",
    "Holländskt kallblod",
    "Holländskt varmblod",
    "Holsteiner",
    "Horse of the American",
    "Hrvatski Posavac",
    "Hrvatski hladnokrvnjak",
    "Hucul",
    "Hungerian Dun",
    "Iberiskt varmblod",
    "Iliahäst",
    "Indisk ponny",
    "Indiskt halvblod",
    "International striped horse",
    "Irish Cob",
    "Irish draught",
    "Irländsk sporthäst",
    "Islandshäst",
    "Israelisk häst",
    "Italiensk ridhäst",
    "Italiensk travare",
    "Italienskt kallblod",
    "Jafhäst",
    "Javaponny",
    "Jianchangponny",
    "Jielinhäst",
    "Jinhongponny",
    "Jinzhouponny",
    "Jomudhäst",
    "Jugoslaviskt kallblod",
    "Jurahäst",
    "Jutsk häst",
    "KWPN",
    "Kabardin",
    "Kagoshimaponny",
    "Kaimanawaponny",
    "Kalmyk",
    "Kamerunponny",
    "Kanadensisk häst",
    "Kanadensiskt varmblod",
    "Kandachimeponny",
    "Kaphäst",
    "Karabaghhäst",
    "Karabair",
    "Karakachanponny",
    "Karpatisk ponny",
    "Kartusianer häst",
    "Kaspisk häst",
    "Kathiawari",
    "Kaukasisk ponny",
    "Kazakponny",
    "Ke-Er-Qin",
    "Kentucky Mountain Saddle Horse",
    "Kerry Bogponny",
    "Kigermustang",
    "Kinesisk guoxia",
    "Kinskyhäst",
    "Kirdiponny",
    "Kisber felver",
    "Kisoponny",
    "Kladrubhäst",
    "Klepper",
    "Knabstrup",
    "Kondudohäst",
    "Konik",
    "Kordofani",
    "Korsikansk ponny",
    "Kotokoliponny",
    "Kroatiskt kallblod",
    "Kroatiskt varmblod eller Hrvatski Posavac",
    "Kubansk pasohäst",
    "Kubansk pinto",
    "Kubansk travare",
    "Kuda Padiponny",
    "Kuda-Gayoe",
    "Kumingan",
    "Kurdisk häst",
    "Kushumhäst",
    "Kustanair",
    "Kuznetskhäst",
    "Kyrgyzponny",
    "Lac La Croix Indian Pony",
    "Lakka",
    "Lakota",
    "Landaisponny",
    "Lavradeirohäst",
    "Lehmkuhlenerponny",
    "Lesotho",
    "Lettiskt varmblod",
    "Leutstettenhäst",
    "Lewitzer",
    "Lichuanponny",
    "Liebenthalerponny",
    "Lijiangponny",
    "Lipizzaner",
    "Lippitt Morgan",
    "Liptako",
    "Litauiskt kallblod",
    "Ljutomertravare",
    "Llanerohäst",
    "Lokai",
    "Lombokponny",
    "Losinoponny",
    "Lundyponny",
    "Lusitano",
    "M'Bayarponny",
    "Maah Glab",
    "Macassar",
    "Magyar Hidegveru",
    "Malakinhäst",
    "Malapolski",
    "Mallorquiner",
    "Mangalarga Paulista",
    "Mangalarga marchador",
    "Manipurihäst",
    "Marajoarahäst",
    "Maremma",
    "Marquesashäst",
    "Marwarihäst",
    "Mbai",
    "Mecklenburgare",
    "Megrelisk häst",
    "Megrelponny",
    "Menorquiner",
    "Merak Sakten Pata",
    "Messaraponny",
    "Metistravare",
    "Mezenhäst",
    "Mezohegyes sporthäst",
    "Mimoseano",
    "Miniatyrhäst",
    "Minusinponny",
    "Misakiponny",
    "Missouri fox trotter",
    "Miyakoponny",
    "Miyazaki",
    "Modeponny",
    "Mogodponny",
    "Mongolisk ponny",
    "Mongolisk vildhäst",
    "Montana Travler",
    "Monterufoliponny",
    "Morab",
    "Morganhäst",
    "Morocco spotted horse",
    "Morochucoponny",
    "Mossihäst",
    "Mount Rogers Pony",
    "Mountain pleasure horse",
    "Moylehäst",
    "Mulassier",
    "Murakozer",
    "Murgese",
    "Mustang",
    "Mytileneponny",
    "Mérensponny",
    "Nambuhäst",
    "Namibisk vildhäst",
    "Nangchenponny",
    "Narymponny",
    "National show horse",
    "Navarraponny",
    "Nederländsk appaloosaponny",
    "New Forest-ponny",
    "Newfoundlandponny",
    "Nez Perce Horse",
    "Nigeriansk häst",
    "Nokotahäst",
    "Nomaponny",
    "Nonius",
    "Nooitgedachter",
    "Nordestinohäst",
    "Nordkirchener",
    "Nordkirchenerponny",
    "Nordlandshäst",
    "Nordsvensk brukshäst",
    "Noriker",
    "Normandisk cob",
    "Norsk fjordhäst",
    "Nova khirgiz",
    "Oberlander",
    "Obponny",
    "Oldenburgare",
    "Orlov-Rostopchin",
    "Orlovtravare",
    "Ostlandshäst",
    "Padangponny",
    "Pahlavanhäst",
    "Paint",
    "Paint.",
    "Palomino",
    "Pampashäst",
    "Panjeponny",
    "Pantaneirohäst",
    "Paso Fino",
    "Patibarcina",
    "Pechoraponny",
    "Peneiaponny",
    "Pentrohäst",
    "Pentroponny",
    "Percheron",
    "Perianganponny",
    "Persano",
    "Persisk arab",
    "Peruansk pasohäst",
    "Petiso Argentino",
    "Pindos",
    "Pinkafeld",
    "Pintabian",
    "Pinto Cubano",
    "Pinzgauer",
    "Piquiraponny",
    "Plevenhäst",
    "Poitevin",
    "Polesisk ponny",
    "Poloponny",
    "Poney Francais de Selle",
    "Pony Mousseye",
    "Pony of Americas",
    "Pottok",
    "Priob-ponny",
    "Przewalski",
    "Pura Raza Espanola eller P.R.E",
    "Pura Raza Española",
    "Pura Raza Galega",
    "Purosangue Orientale",
    "Purucaponny",
    "Quarab",
    "Quarter Pony",
    "Quarterhäst",
    "Quatganihäst",
    "Qubahäst",
    "Rackhäst",
    "Rahvan",
    "Rajshahiponny",
    "Retuertahäst",
    "Rhenländare",
    "Rhenländskt kallblod",
    "Rila Mountain",
    "Riwocheponny",
    "Rocky mountain horse",
    "Rumänskt kallblod",
    "Rysk ridhäst",
    "Rysk travare",
    "Ryskt kallblod",
    "Sable Islandponny",
    "Sachsiskt-Thürinskt kallblod",
    "Sachsiskt-Thürinskt varmblod",
    "Saddlebred",
    "Salernohäst",
    "Samolacoponny",
    "San fratello",
    "Sandalwoodponny",
    "Sandanponny",
    "Sanhehäst",
    "Sanheponny",
    "Sanjabi",
    "Sardinsk häst",
    "Sardinsk ponny",
    "Sarvarhäst",
    "Schleswiger",
    "Schwarzwaldhäst",
    "Schweiziskt varmblod",
    "Sella italiano",
    "Selle francais",
    "Shacklefordhäst",
    "Shagya-arab",
    "Shaleshäst",
    "Shanponny",
    "Shetlandsponny",
    "Shirehäst",
    "Siamesisk ponny",
    "Siciliano Indigeno",
    "Silesisk häst",
    "Silla Argentina",
    "Single Foot Horse",
    "Siniponny",
    "Skyrosponny",
    "Slovakisk ridponny",
    "Slovakiskt varmblod",
    "Slovensk travare",
    "Sokolsky",
    "Somalisk ponny",
    "Somme Bay-häst",
    "Sorraia",
    "Spansk Angloarab",
    "Spansk Jennethäst",
    "Spansk mustang",
    "Spansk-normandisk häst",
    "Spitiponny",
    "Spotted saddle horse",
    "Sri Lankaponny",
    "Standardbred",
    "Stara Planina",
    "Stonewall Sport Horse",
    "Sudanesisk häst",
    "Sudansk ponny",
    "Suffolk Punch",
    "Sugarbush Draft",
    "Sulawesiponny",
    "Sumba och sumbawaponny",
    "Sunichoponny",
    "Svensk ardenner",
    "Svensk ridponny",
    "Svenskt varmblod",
    "Sydafrikansk miniatyrhäst",
    "Sydafrikanskt varmblod",
    "Sydtyskt kallblod",
    "Syrisk häst",
    "Sárvárhäst",
    "Taishuponny",
    "Tavdaponny",
    "Tawleed",
    "Tcheneran",
    "Tennessee walking horse",
    "Tennuvian",
    "Tersk",
    "Thaiponny",
    "Thessalisk ponny",
    "Tibetansk ponny",
    "Tigerhäst",
    "Timorponny",
    "Tinker",
    "Tjeckiskt kallblod",
    "Tjeckiskt varmblod",
    "Tjeckoslovakisk ridponny",
    "Tjernemor",
    "Togaponny",
    "Tokaraponny",
    "Tolfetano",
    "Torihäst",
    "Trait du Nord",
    "Trakehnare",
    "Trakyaponny",
    "Tres Sangre",
    "Trocha Pura Colombiana",
    "Trottatore Italiano",
    "Trotteur Francais",
    "Tuigpaard",
    "Tunisisk ponny",
    "Tushinponny",
    "Tuvaponny",
    "Tysk ridponny",
    "Tysk shetlandsponny",
    "Ukrainsk ridhäst",
    "Ungersk black häst",
    "Ungerskt halvblod",
    "Ungerskt kallblod",
    "Ungerskt varmblod",
    "Unmolhäst",
    "Uzunyayla",
    "Venezuelisk Criollo",
    "Ventassohäst",
    "Viatka",
    "Virginia Highlander",
    "Vitryskt kallblod",
    "Vlaamperd",
    "Vladimirhäst",
    "Voronezh-häst",
    "Walkaloosa",
    "Warlanderhäst",
    "Welara",
    "Welsh av halvblodstyp",
    "Welsh cob",
    "Welsh mountain",
    "Welshponny",
    "Welshponny av Cobtyp",
    "Weser-Ems",
    "Westfalisk häst",
    "Wielkopolski",
    "Wyomisk vildhäst",
    "Württembergare",
    "Xinanponny",
    "Xylingolponny",
    "Yabouponny",
    "Yagha",
    "Yakutponny",
    "Yanqiponny",
    "Yemeni",
    "Yeniseihäst",
    "Yiliponny",
    "Yonaguniponny",
    "Yunnanponny",
    "Yururiponny",
    "Yutaponny",
    "Zaniskariponny",
    "Zemaituka",
    "Zweibrücker",
    "danubian och danube",
    "eriskayaponny",
    "Çukurovahäst",
    "Ölandshäst",
    "Öselponny",
    "Östbulgarisk häst",
    "Österrikiskt varmblod"
  ],
  RABBIT: [
    "Alaska",
    "Altex",
    "American",
    "American Chinchilla",
    "American Sable",
    "American Fuzzy Lop",
    "Angora",
    "Argente Bleu",
    "Argente Brun",
    "Argente Clair",
    "Argente Crème",
    "Argente Noir",
    "Argente St Hubert",
    "Argente de Champagne",
    "Armenian Marder",
    "Astrex",
    "Aurora Negro",
    "Baladi Black",
    "Baladi Red",
    "Baladi White",
    "Bauscat",
    "Beaver",
    "Beige",
    "Belgian Hare",
    "Beveren",
    "Beveren",
    "Big Silver Marten",
    "Blanc de Bouscat",
    "Blanc de Chauny",
    "Blanc de Hotot",
    "Blanc de L'Oural",
    "Blanc de Popielno",
    "Blanc de Termonde",
    "Blue of Ham",
    "Blue of Sint-Niklaas",
    "Bourbonnais Grey",
    "Brazilian",
    "Britannia Petite",
    "British Giant",
    "Brown Chestnut of Lorraine",
    "Caldes",
    "Californian",
    "Canadian Plush Lop",
    "Carmagnola Grey",
    "Cashmere Lop",
    "Champagne d'Argent",
    "Chaudry",
    "Checkered Giant",
    "Chinchilla",
    "Chinchilla Giganta",
    "Cinnamon",
    "Continental Giant Coloured",
    "Continental Giant White",
    "Creme d'Argent",
    "Criollo",
    "Cuban Brown",
    "Czech Red",
    "Czech Solver",
    "Czech Spot",
    "Czech White",
    "Dalmatian",
    "Deilenaar",
    "Dutch",
    "Dutch",
    "Dwarf Hotot",
    "Dwarf Lop",
    "Elfin",
    "Enderby Island",
    "English Angora",
    "English Lop",
    "English Spot",
    "Fauve de Bourgogne",
    "Fee de Marbourg",
    "Feh",
    "Feu Noir",
    "Flemish Giant",
    "Florida White",
    "Fox",
    "Fox–Silver",
    "French Angora",
    "French Lop",
    "Gabali",
    "German Angora",
    "German Lop",
    "Giant Angora",
    "Giant Chinchilla",
    "Giant Havana",
    "Giant Marburger",
    "Giant Papillon",
    "Giant Silver",
    "Giant Smoke Pearl",
    "Giza White",
    "Golden Fawn",
    "Golden Glavcot",
    "Gotland",
    "Gouwenaar",
    "Grey Pearl of Halle",
    "Güzelçamlı",
    "Harlequin",
    "Harlequin Dutch",
    "Harlequin Rex",
    "Havana",
    "Himalayan",
    "Holland Lop",
    "Hulstlander",
    "Hungarian Giant",
    "Isabella",
    "Jamora ",
    "Japanese Dutch",
    "Japanese White",
    "Jersey Wooly",
    "Kabyle",
    "Lilac",
    "Lionhead",
    "Liptov Baldspotted Rabbit",
    "Lynx",
    "Marburger Feh",
    "Mecklenburger Piebald",
    "Meissner Lop",
    "Mellerud rabbit",
    "Mini Cashmere Lop",
    "Mini Lion Lop",
    "Mini Lop",
    "Mini Rex",
    "Mini Satin",
    "Miniature Cashmere Lop",
    "Miniature Lion Lop",
    "Miniature Lop",
    "Miniature Papillon",
    "Miniature Rex",
    "Miniature Satin",
    "Moshtohor",
    "Netherland Dwarf",
    "New Zealand",
    "New Zealand red",
    "New Zealand white",
    "Nitransky",
    "Old English Red",
    "Opossum",
    "Orange",
    "Orylag ",
    "Palomino",
    "Pannon White",
    "Patagonian",
    "Perlfee",
    "Petite Brabancon",
    "Plush Lop",
    "Pointed Beveren",
    "Polish",
    "Rex",
    "Rex du Poitou",
    "Rhinelander",
    "Rhone",
    "Rouennais",
    "Russian",
    "Sachsengold ",
    "Sallander",
    "San Juan",
    "Satin Angora",
    "Satin",
    "Schwarzgrannen",
    "Separator",
    "Siamese Sable",
    "Siberian",
    "Silver",
    "Silver Fox",
    "Silver Fox",
    "Silver Marten",
    "Simenwar",
    "Sitka",
    "Slovenian Rabbit",
    "Smoke Pearl",
    "Spanish Giant",
    "Squirrel",
    "Standard Chinchilla",
    "Standard Rex",
    "Stone rabbit",
    "Sussex",
    "Swan",
    "Swedish Fur",
    "Swedish Hare",
    "Swiss Fox",
    "Tadla",
    "Tan",
    "Teddy Dwarf",
    "Teddy Lop",
    "Thrianta",
    "Thuringer",
    "Tri-Colour Dutch",
    "Tri-Colour English",
    "Trønder",
    "V-line",
    "Velveteen Lop",
    "Vienna Coloured",
    "Vienna White",
    "Wheaten",
    "Wheaten Lynx",
    "White Country",
    "Zemmouri",
    "Zika",
    "Ørestad"
  ]
};