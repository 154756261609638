/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { prescriptionRegexp } from "../../../util/Validations";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { FeeType } from "../../../models/ePrescription/local/FeeType";
import PriceInputs from "../../../components/ReactHookFormFields/Specific/PriceInputs";
import Button from "../../../components/Button";
import { ClinicFeeResponse } from "../../../models/clinic/ClinicFeeResponse";
import Modal from "../../../components/Modal/Modal";

interface Props {
  open: boolean;
  close(): void;
  defaultFee?: ClinicFeeResponse;
  feeType: FeeType;
  returnNewAmount(amount: number): void;
}

export interface FeeForm {
  amount: number;
}

const DefaultFeeModal: React.FC<Props> = ({
  open,
  close,
  defaultFee,
  feeType,
  returnNewAmount,
}: Props) => {
  const {
    control,
    formState: { isValid },
    handleSubmit,
    setValue,
  } = useForm<FeeForm>({
    mode: "onChange",
  });

  const onClose = () => {
    close();
  };

  useEffect(() => {
    if (defaultFee) {
      setValue("amount", defaultFee?.amount);
    }
  }, [defaultFee, setValue]);

  const onSubmit = ({ amount }: FeeForm) => {
    returnNewAmount(amount);
    onClose();
  };

  return (
    <Modal handleClose={onClose} show={open}>
      <Modal.Header title={strings.fees} />
      <Modal.Body>
        <AlertBox
          type={AlertType.INFO}
          message={strings.formatString(
            strings.defaultFeeToolTip,
            defaultFee?.amount?.toString() || ""
          )}
        />
        <form>
          {/* TODO - country specific */}
          <PriceInputs
            control={control}
            currency="SEK"
            fieldOptions={{
              pattern: {
                value: prescriptionRegexp,
                message: strings.notAllowedCharacter,
              },
              validate: {
                positive: (v: number) =>
                  (feeType === FeeType.FEE && v >= 0) ||
                  (feeType === FeeType.AUTOMATIC && !Number.isNaN(v)),
                maximumValue: (v: number) =>
                  (feeType === FeeType.FEE && v < 1000) ||
                  feeType === FeeType.AUTOMATIC,
              },
            }}
            label={strings.prescriptionFee}
            name="amount"
            required
            vat={defaultFee?.vat || 0}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
          type="submit"
        >
          {strings.save}
        </Button>
        <Button
          className="modal-sub-button"
          onClick={onClose}
          variant="secondary"
        >
          {strings.close}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DefaultFeeModal;
