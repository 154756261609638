/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import Modal from "../../../components/Modal/Modal";
import CloseButton from "../../../components/CloseButton";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../../components/AlertBox";

interface Props {
  isOpen: boolean;
  close: () => void;
  error?: string | null;
}

const ErrorModal: React.FC<Props> = ({ isOpen, close, error }: Props) => (
  <Modal handleClose={close} show={isOpen}>
    <Modal.Header title={strings.error} />
    <Modal.Body>
      <AlertBox message={error} closeAble={false} />
    </Modal.Body>
    <Modal.Footer extraClassName="justify-end">
      <CloseButton asMain onClick={close} />
    </Modal.Footer>
  </Modal>
);

export default ErrorModal;

ErrorModal.defaultProps = {
  error: undefined,
};
