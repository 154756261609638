/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { PersonDetailsResponse } from "../../../../models/contact/PersonDetailsResponse";
import { getStreetAndHouseDetails } from "../../../../util/helperFunctions";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import {
  fullYearSwedishPersonalId,
  prescriptionNameRegexp,
  prescriptionRegexp,
} from "../../../../util/Validations";
import { strings } from "../../../../common/Strings/Strings";
import PhoneNumber from "../../../../components/ReactHookFormFields/Specific/PhoneNumber";
import CombinedSelect from "../../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { PublicPhoneResponse } from "../../../../models/contact/PublicPhoneResponse";
import { PublicAddressResponse } from "../../../../models/contact/PublicAddressResponse";

export interface PersonalIdForm {
  givenName: string;
  familyName: string;
  idNumber: string;
  addresses: string;
  phones: string;
  phoneNumber: string;
  city: string;
  zip: string;
  address: string;
}

interface Props {
  defaultCountryCode?: string;
  petOwner?: PersonDetailsResponse;
}

export const mergeNames = (
  petOwner: PersonDetailsResponse
): string | undefined => {
  if (petOwner) {
    let prefix = "";

    if (petOwner.prefix) {
      prefix = petOwner.prefix;
    }

    let middleName = "";

    if (petOwner.middleName) {
      middleName = petOwner.middleName;
    }

    let firstName = "";

    if (petOwner.firstName) {
      firstName = petOwner.firstName;
    }

    return `${prefix}${prefix ? " " : ""}${middleName}${
      middleName ? " " : ""
    }${firstName}`;
  }
  return undefined;
};

const OwnerSelection: React.FC<Props> = ({
  defaultCountryCode,
  petOwner,
}: Props) => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext();

  useEffect(() => {
    if (petOwner) {
      const phone = petOwner.phones[0];
      const address = petOwner.addresses[0];

      setValue("givenName", mergeNames(petOwner));
      setValue("familyName", petOwner.lastName);
      setValue("idNumber", petOwner.personalId?.replace("-", ""));
      setValue("phoneNumber", phone?.value);
      setValue("phones", phone ? [phone] : null);
      setValue("address", getStreetAndHouseDetails(address));
      setValue("addresses", address ? [address] : null);
      setValue("city", address?.city);
      // Remove spaces
      setValue("zip", address?.zip.replace(/\s/g, ""));

      void trigger();
    }
  }, [petOwner, setValue, trigger]);

  return (
    <form className="space-y-2">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.givenName}
          fieldOptions={{
            maxLength: 35,
            pattern: {
              value: prescriptionNameRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.firstName}
          maxLength={35}
          name="givenName"
          register={register}
          required
          showRequired
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.familyName}
          fieldOptions={{
            maxLength: 35,
            pattern: {
              value: prescriptionNameRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.lastName}
          maxLength={35}
          name="familyName"
          register={register}
          required
          showRequired
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.idNumber}
          fieldOptions={{
            maxLength: 12,
            pattern: {
              value: fullYearSwedishPersonalId.matchRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.idNumber}
          maxLength={12}
          name="idNumber"
          placeholder={strings.personalIdFormatPlaceholder}
          register={register}
          required
          showRequired
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div hidden={(petOwner?.phones?.length || 0) < 2}>
          <CombinedSelect
            control={control}
            label={strings.availablePhones}
            labelKey="value"
            name="phones"
            onChange={(phone: PublicPhoneResponse[] | null) => {
              const p = phone ? phone[0] : null;
              if (p) {
                setValue("phoneNumber", p?.value);
              }
            }}
            options={petOwner?.phones || []}
          />
        </div>
        <div>
          <PhoneNumber
            control={control}
            country={defaultCountryCode}
            id="phoneNumberForPersonalIdOwner"
            label={strings.phone}
            name="phoneNumber"
            required
            showRequired
          />
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div hidden={(petOwner?.addresses.length || 0) < 2}>
          <CombinedSelect
            control={control}
            label={strings.availableAddresses}
            labelKey={(a: PublicAddressResponse | any) =>
              `${getStreetAndHouseDetails(a)}, ${a.zip}, ${a.city}`
            }
            name="addresses"
            onChange={(address: PublicAddressResponse[] | null) => {
              const a = address ? address[0] : null;

              if (a) {
                setValue("address", getStreetAndHouseDetails(a));
                setValue("city", a.city);
                setValue("zip", a.zip);
              }
            }}
            options={petOwner?.addresses || []}
          />
        </div>
        <div>
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.address}
            fieldOptions={{
              maxLength: 35,
              pattern: {
                value: prescriptionRegexp,
                message: strings.notAllowedCharacter,
              },
            }}
            label={strings.address}
            maxLength={35}
            name="address"
            register={register}
            required
            showRequired
          />
        </div>
        <div>
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.city}
            fieldOptions={{
              maxLength: 28,
              pattern: {
                value: prescriptionRegexp,
                message: strings.notAllowedCharacter,
              },
            }}
            label={strings.city}
            maxLength={28}
            name="city"
            register={register}
            required
            showRequired
          />
        </div>
        <div>
          <Field
            autoComplete={AutoCompleteOptions.off}
            disableNotNumberCharacters
            error={errors.zip}
            fieldOptions={{
              maxLength: 6,
              minLength: 1,
              pattern: {
                value: /\S/,
                message: strings.notAllowedCharacter,
              },
            }}
            label={strings.zip}
            name="zip"
            maxLength={6}
            register={register}
            required
            showRequired
          />
        </div>
      </div>
    </form>
  );
};

export default OwnerSelection;

OwnerSelection.defaultProps = {
  petOwner: undefined,
};
