/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Control, Controller } from "react-hook-form";
import moment from "moment";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import BirthDateComponent from "./BirthDateComponent";

type DateFormatOrder = "DMY" | "YMD" | "MDY";

interface Props {
  control: Control<any>;
  dateFormat?: DateFormatOrder;
  defaultValue?: string | Date;
  label?: string;
  name?: string;
  optional?: boolean;
  pet?: boolean;
  readOnly?: boolean;
  required?: boolean;
  showRequired?: boolean;
}

const BirthDatePicker: React.FC<Props> = ({
  control,
  defaultValue,
  name: n,
  required,
  ...rest
}: Props) => (
  <Controller
    control={control}
    name={n || "dateOfBirth"}
    rules={{
      required,
      validate: {
        isInTheFuture: (date: string | undefined) =>
          date === undefined ||
          date === "" ||
          moment().isAfter(date) ||
          strings.invalidDate,
      },
    }}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <BirthDateComponent
        defaultDay={
          defaultValue
            ? moment(defaultValue).date()
            : value && moment(value).date()
        }
        defaultMonth={
          defaultValue
            ? moment(defaultValue).month()
            : value && moment(value).month() + 1
        }
        defaultYear={
          defaultValue
            ? moment(defaultValue).year()
            : value && moment(value).year()
        }
        error={error}
        onChange={onChange}
        required={required}
        value={value}
        {...rest}
      />
    )}
  />
);

export default BirthDatePicker;

BirthDatePicker.defaultProps = {
  dateFormat: dateAndTime.dateFormatOrder,
  defaultValue: undefined,
  name: "dateOfBirth",
  label: strings.dateOfBirth,
  optional: false,
  pet: false,
  readOnly: false,
  required: false,
};
