/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { getGeneralError } from "../../../util/helperFunctions";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { PageResponse } from "../../../models/PageResponse";
import { usePager } from "../../../hooks/hooks";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import moment from "moment";
import Button from "../../../components/Button";
import { ClinicChargeResponse } from "../../../models/charge/ClinicChargeResponse";
import ChargeManagementApi from "../../../api/ChargeManagementApi";
import { ChargeStatus } from "../../../models/charge/ChargeStatus";
import Tooltip from "../../../components/Tooltip";
import NumberFormatter from "../../../util/NumberFormatter";
import { downloadInvoicePdfForClinicCharge } from "../../../util/FileUtils";
import Tag, { TagType } from "../../../components/Tag";
import { GlobalVetInvoiceResponse } from "../../../models/management/GlobalVetInvoiceResponse";
import { Link } from "react-router-dom";
import { ExclamationMark } from "../../../common/Icons/ExclamationMark";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import { Document } from "../../../common/Icons/Document";
import {
  getGVInvoicingErrorMessage,
  getGVInvoicingSuccessMessage,
} from "../../../util/InvoiceSystemUtils";

interface Props {
  clinicId?: string;
  setPageLoading?: (isLoading: boolean) => void;
  refresh?: boolean;
}

const ClinicChargesTable: React.FC<Props> = (props: Props) => {
  const { clinicId, setPageLoading, refresh } = props;

  const [charges, setCharges] = useState<PageResponse<ClinicChargeResponse>>();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const pager = usePager(50);

  const getCharges = async () => {
    setLoading(true);

    try {
      const response = await ChargeManagementApi.getClinicCharges({
        clinicId: clinicId,
        pageNumber: pager.pageNumber,
        pageSize: pager.pageSize,
      });
      setCharges(response.data);
      setError(null);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
      if (setPageLoading) setPageLoading(false);
    }
  };

  useEffect(() => {
    void getCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicId, pager.pageNumber, pager.pageSize, refresh]);

  const getChargeStatus = (status: ChargeStatus): ReactElement | undefined => {
    if (status === ChargeStatus.INVOICED) {
      return <Tag type={TagType.danger} text={strings.pendingInvoice} />;
    }
    if (status === ChargeStatus.NO_CARD || status === ChargeStatus.PENDING) {
      return <Tag type={TagType.warning} text={strings.notPaid} />;
    }
    if (status === ChargeStatus.COMPLETED) {
      return <Tag type={TagType.success} text={strings.paid} />;
    }
    return undefined;
  };

  const getInvoiceState = (invoice: GlobalVetInvoiceResponse) => {
    let icon;

    switch (invoice.valid) {
      case false:
        icon = (
          <Tooltip
            content={
              invoice.validationError || getGVInvoicingErrorMessage(invoice)
            }
            placement="right"
          >
            <div className="text-red-500">
              <ExclamationMark />
            </div>
          </Tooltip>
        );
        break;
      case true:
        icon = (
          <Tooltip
            content={getGVInvoicingSuccessMessage(invoice)}
            placement="right"
          >
            <div className="color-green">
              <CheckCircle />
            </div>
          </Tooltip>
        );
        break;
      default:
        icon = undefined;
        break;
    }

    return icon;
  };

  return (
    <div>
      <PageableTable
        pager={pager}
        isLoading={loading}
        content={charges}
        error={error}
      >
        <PageableTableHead>
          <tr>
            <th className="tw-th">{strings.state}</th>
            <th className="tw-th">{strings.clinic}</th>
            <th className="tw-th">{strings.invoiceCreationDate}</th>
            <th className="tw-th">{strings.paymentDate}</th>
            <th className="tw-th text-right">{strings.totalCost}</th>
            <th className="tw-th text-center">{strings.invoiceSent}</th>
            <th className="tw-th text-right">{strings.pdf}</th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {charges &&
            charges.elements.map((charge) => (
              <tr key={charge.id}>
                <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                  {getChargeStatus(charge.status)}
                </td>
                <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                  <Link to={`/management/clinics/${charge.clinicId}`}>
                    {charge.clinicName}
                  </Link>
                  <div className="mt-1">{`${moment(charge.startDate).format(
                    dateAndTime.momentDateFormat
                  )} - ${moment(charge.endDate).format(
                    dateAndTime.momentDateFormat
                  )}`}</div>
                </td>
                <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                  {charge?.creationDate &&
                    moment(charge.creationDate).format(
                      dateAndTime.momentDateTimeFormat
                    )}
                </td>
                <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                  {charge?.paymentDate &&
                    moment(charge.paymentDate).format(
                      dateAndTime.momentDateTimeFormat
                    )}
                </td>
                <td className="px-4 py-3 text-sm font-medium whitespace-nowrap text-right">
                  {NumberFormatter.formatPrice(charge.total, charge.currency)}
                </td>
                <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                  <div className="d-flex align-items-center justify-content-center">
                    {charge?.invoice && getInvoiceState(charge.invoice)}
                  </div>
                </td>
                <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                  <div className="d-flex align-items-end justify-content-end">
                    <Button
                      className="tw-link"
                      variant="icon"
                      hidden={!charge.invoice?.valid}
                      onClick={async () => {
                        try {
                          await downloadInvoicePdfForClinicCharge(charge);
                        } catch (err) {
                          setError(await getGeneralError(err));
                        }
                      }}
                    >
                      <Document />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
        </PageableTableBody>
      </PageableTable>
    </div>
  );
};

export default ClinicChargesTable;
