/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { Navigate, PathRouteProps, useLocation } from "react-router-dom";
import moment from "moment";
import { useMaintenance } from "../../contexts/MaintenanceContext";
import { MaintenanceModeMessageBanner } from "../../components/MaintenanceModeMessageBanner";
import Params from "../../common/Params";
import {
  getAccessToken,
  getLocaleForActiveUser,
  getValueOfActiveUser,
  UserRole,
} from "../LocalStorageVariables";
import { useLocale } from "../../contexts/LocaleContext";
import NavBar from "../../common/NavBar/NavBar";
import {
  DoctorMenuItem,
  PetOwnerMenuItem,
  SiteManagerMenuItem,
} from "../../common/NavBar/MenuItem/MenuItemTypes";
import { strings } from "../../common/Strings/Strings";
import "moment/locale/sv";
import { useUser } from "../../contexts/UserContext";
import PetOwnerMobileMenu from "../../common/NavBar/FooterNavs/PetOwner/PetOwnerMobileMenu";
import DoctorMobileMenu from "../../common/NavBar/FooterNavs/Doctor/DoctorMobileMenu";
import { setupDarkMode } from "../DarkMode";
import SiteManagerMobileMenu from "../../common/NavBar/FooterNavs/SiteManager/SiteManagerMobileMenu";

interface AppRouteProps extends PathRouteProps {
  // The main component to render.
  component: any;
  // The component is only available in the swedish language.
  isOnlySwedish?: boolean;
  // Display second navbar or not for the route.
  isSecondNavbarDisabled?: boolean;
  // The currently active doctor menu item that represents the currently active doctor page.
  activeDoctorMenu?: DoctorMenuItem;
  // The currently active pet owner menu item that represents the currently pet owner page.
  activePetOwnerMenu?: PetOwnerMenuItem;
  // The site manager menu item that represents the currently active site manager page.
  activeSiteManagerMenu?: SiteManagerMenuItem;
  // The component is available during a maintenance period.
  availableDuringMaintenance?: boolean;
  // The component is only available for not authenticated users.
  onlyUnauthenticated?: boolean;
  // The component is only available for authenticated users.
  requiresAuthentication?: boolean;
}

const RouteWrapper = ({
  component: ContentComponent,
  activeDoctorMenu,
  activePetOwnerMenu,
  activeSiteManagerMenu,
  availableDuringMaintenance = false,
  isOnlySwedish = false,
  isSecondNavbarDisabled,
  onlyUnauthenticated = false,
  requiresAuthentication = false,
}: AppRouteProps) => {
  const maintenance = useMaintenance();
  const localeContext = useLocale();
  const role: UserRole | null = getValueOfActiveUser("role");
  const { user } = useUser();
  const location = useLocation();

  useEffect(() => {
    const isSwedish = Params.prescriptionSwedish;

    if (isSwedish === "on" && isOnlySwedish) {
      localeContext?.changeLocale("sv");
    } else {
      const locale = getLocaleForActiveUser();

      if (locale !== null && `${locale}` !== "sv") {
        localeContext?.resetLocale();
      }
    }
  }, [isOnlySwedish, localeContext]);

  useEffect(() => {
    const lan =
      strings.getLanguage() === "gb" ? "en-gb" : strings.getLanguage();
    moment.locale(lan);
  }, []);

  // Dark mode setup must be used here since the RouteWrapper overrides setup
  useEffect(() => {
    setupDarkMode();
  }, []);

  // Maintenance is active --> Show maintenance banner
  if (maintenance?.maintenanceModeEnabled && !availableDuringMaintenance) {
    return <MaintenanceModeMessageBanner />;
  }

  // The component requires authentication, but the user is not logged in (no access token)
  // --> Redirect to login form (e.g. sensitive personal information pages)
  if (requiresAuthentication && !getAccessToken()) {
    return <Navigate to="/login" replace state={{ from: location.pathname }} />;
  }

  // The user is logged in, but the registration is not finished --> Redirect to profile setup
  if (requiresAuthentication && !user.isRegistrationFinished) {
    if (location.pathname !== "/profile/setup") {
      return <Navigate to="/profile/setup" replace />;
    }
  }

  // The component requires an unauthenticated user, but the user is logged in (has access token)
  // --> Redirect to base path (e.g. login/registration pages)
  if (onlyUnauthenticated && getAccessToken()) {
    return <Navigate to="/" replace />;
  }

  // Normal render --> Show content with top and bottom navigation bars
  return (
    <>
      <NavBar
        {...{
          activeDoctorMenu,
          activePetOwnerMenu,
          activeSiteManagerMenu,
          isSecondNavbarDisabled,
        }}
      />
      <ContentComponent />
      {role === "owner" && (
        <PetOwnerMobileMenu activeMenu={activePetOwnerMenu} />
      )}
      {role === "doctor" && <DoctorMobileMenu activeMenu={activeDoctorMenu} />}
      {role === "manager" && (
        <SiteManagerMobileMenu activeMenu={activeSiteManagerMenu} />
      )}
    </>
  );
};

export default RouteWrapper;
