/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../../common/Strings/Strings";
import EmailList from "./Emails/EmailOwnershipList";
import { EmailRequest } from "../../../../models/contact/EmailRequest";
import ClinicContactsApi from "../../../../api/ClinicContactsApi";
import PhoneList from "./Phones/PhoneOwnershipList";
import { PhoneRequest } from "../../../../models/contact/PhoneRequest";
import { PhoneConfirmationRequest } from "../../../../models/contact/PhoneConfirmationRequest";
import AddressList from "./Adresses/AddressOwnershipList";
import { LocatedAddressRequest } from "../../../../models/contact/LocatedAddressRequest";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import { AddressResponse } from "../../../../models/contact/AddressResponse";
import { canManageClinic } from "../../../../util/helperFunctions";
import { Card } from "../../../../components/Cards/Card";

interface Props {
  clinic: ClinicResponse;
}

const ContactInformation: React.FC<Props> = ({ clinic }: Props) => {
  const defaultCountryCode =
    clinic?.addresses.find((o: AddressResponse) => o.contactType === "PRIMARY")
      ?.countryCode ||
    clinic?.addresses.find((o: AddressResponse) => o.contactType === "PRIMARY")
      ?.countryCode ||
    clinic?.countryCode;

  const readOnly = !canManageClinic(clinic);

  return (
    <Card size="lg" title={strings.contacts} type="simple">
      <EmailList
        owner={clinic}
        readOnly={readOnly}
        addEmail={(clinicId: string, email: EmailRequest) =>
          ClinicContactsApi.addClinicEmail(clinicId, email)
        }
        removeEmail={(clinicId: string, emailId: string) =>
          ClinicContactsApi.removeClinicEmail(clinicId, emailId)
        }
        promoteEmail={(clinicId: string, emailId: string) =>
          ClinicContactsApi.setPrimaryClinicEmail(clinicId, emailId)
        }
        resendConfirmation={(emailId: string) =>
          ClinicContactsApi.sendClinicEmailConfirmation(emailId)
        }
      />
      <PhoneList
        owner={clinic}
        readOnly={readOnly}
        defaultCountryCode={defaultCountryCode}
        addPhone={(clinicId: string, phone: PhoneRequest) =>
          ClinicContactsApi.addClinicPhone(clinicId, phone)
        }
        confirmPhoneInstantly={(
          clinicId: string,
          phoneId: string,
          request: PhoneConfirmationRequest
        ) =>
          ClinicContactsApi.confirmClinicPhoneInstantly(
            clinicId,
            phoneId,
            request
          )
        }
        removePhone={(clinicId: string, phoneId: string) =>
          ClinicContactsApi.removeClinicPhone(clinicId, phoneId)
        }
        promotePhone={(clinicId: string, phoneId: string) =>
          ClinicContactsApi.setPrimaryClinicPhone(clinicId, phoneId)
        }
        resendConfirmation={(clinicId: string, phoneId: string) =>
          ClinicContactsApi.sendClinicPhoneConfirmation(clinicId, phoneId)
        }
      />
      <AddressList
        owner={clinic}
        readOnly={readOnly}
        addAddress={(clinicId: string, addressRequest: LocatedAddressRequest) =>
          ClinicContactsApi.addClinicAddress(clinicId, addressRequest)
        }
        removeAddress={(clinicId: string, addressId: string) =>
          ClinicContactsApi.removeClinicAddress(clinicId, addressId)
        }
        promoteAddress={(clinicId: string, addressId: string) =>
          ClinicContactsApi.setPrimaryClinicAddress(clinicId, addressId)
        }
      />
    </Card>
  );
};

export default ContactInformation;
