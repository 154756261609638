/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import EmailConfirmationInfoBase from "../../../../../components/contact/confirmation/EmailConfirmationInfoBase";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { EmailResponse } from "../../../../../models/contact/EmailResponse";
import { ApiPromise } from "../../../../../models/ApiPromise";
import Modal from "../../../../../components/Modal/Modal";

interface Props {
  email: EmailResponse | null;
  resendConfirmation: (ownershipId: string) => ApiPromise<void>;
  show: boolean;
  onHide: () => void;
}
const ResendEmailConfirmationModal: React.FC<Props> = ({
  email,
  resendConfirmation,
  show,
  onHide,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const [resendSuccess, setResendSuccess] = useState<boolean>(false);

  useEffect(() => {
    const resetStateAndErrors = () => {
      setError(null);
      setResendLoading(false);
      setResendSuccess(false);
    };

    if (show) {
      resetStateAndErrors();
    }
  }, [show]);

  const resendEmailConfirmationCode = async () => {
    if (!(email && email.id)) {
      return;
    }

    setError(null);
    setResendLoading(true);
    setResendSuccess(false);

    try {
      setResendLoading(true);
      await resendConfirmation(email.id);
      setResendSuccess(true);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.emailNotConfirmed} />
      <Modal.Body>
        {email ? (
          <div className="space-y-6 md:p-2">
            <EmailConfirmationInfoBase
              email={email.value}
              error={error}
              loading={resendLoading}
              resend={resendEmailConfirmationCode}
              success={resendSuccess}
            />
          </div>
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ResendEmailConfirmationModal;
