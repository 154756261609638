/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import logger from "../../../util/logger";
import { strings } from "../../../common/Strings/Strings";
import Button from "../../../components/Button";
import EmailRecovery from "./EmailRecovery";
import SmsRecovery from "./SmsRecovery";
import SmsCode from "./SmsCode";

type RecoveryType = "email" | "mobile";

const PasswordRecovery: React.FC = () => {
  const [recoveryType, setRecoveryType] = useState<RecoveryType>("email");
  const [smsCodeId, setSmsCodeId] = useState<string>();
  const [recoveryPhoneNumber, setRecoveryPhoneNumber] = useState<string>();

  const [captcha, setCaptcha] = useState<ReCAPTCHA | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [captchaValid, setCaptchaValid] = useState<boolean>(false);

  useEffect(() => {
    if (captcha) {
      captcha.reset();
    }
  }, [captcha]);

  const resetCaptcha = (): void => {
    if (captcha) {
      captcha.reset();
      setCaptchaValid(true);
      setRecaptchaToken(null);
    }
  };

  const onReCaptchaChange = (captchaToken: string | null): void => {
    logger.debug("Captcha verified: ", recaptchaToken);
    setCaptchaValid(true);
    setRecaptchaToken(captchaToken);
  };

  return (
    <main className="lg:min-h-screen pb-0 lg:pb-14 pt-14">
      <section>
        <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <div className="w-full md:max-w-md md:tw-card space-y-6 md:p-8">
            <div>
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.passwordRecovery}
              </h1>
            </div>
            <div>
              <p>
                {recoveryType === "email"
                  ? strings.resetPasswordEmailInfo
                  : strings.resetPasswordMobileInfo}
              </p>
            </div>
            {smsCodeId ? (
              <SmsCode
                smsCodeId={smsCodeId}
                phoneNumber={recoveryPhoneNumber ?? ""}
              />
            ) : (
              <div>
                {recoveryType === "email" ? (
                  <EmailRecovery
                    setCaptcha={setCaptcha}
                    resetCaptcha={resetCaptcha}
                    captchaValid={captchaValid}
                    recaptchaToken={recaptchaToken}
                    onCaptchaChange={onReCaptchaChange}
                  />
                ) : (
                  <SmsRecovery
                    setCaptcha={setCaptcha}
                    resetCaptcha={resetCaptcha}
                    captchaValid={captchaValid}
                    recaptchaToken={recaptchaToken}
                    onCaptchaChange={onReCaptchaChange}
                    setSmsCodeId={setSmsCodeId}
                    setSmsInfoMessage={setRecoveryPhoneNumber}
                  />
                )}
              </div>
            )}
            <div>
              {recoveryType === "email" ? (
                <Button
                  onClick={() => setRecoveryType("mobile")}
                  variant="link"
                >
                  {strings.recoverViaMobile}
                </Button>
              ) : (
                <Button onClick={() => setRecoveryType("email")} variant="link">
                  {strings.recoverViaEmail}
                </Button>
              )}
            </div>
            <div>
              <Link to="/login" className="tw-link text-left text-sm">
                {strings.backToLogin}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PasswordRecovery;
