/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import TestInvoiceApi from "../../../api/TestInvoiceApi";
import { Tooltip } from "../../../components/Tooltip";
import NumberFormatter from "../../../util/NumberFormatter";
import { downloadTestInvoicePdf } from "../../../util/FileUtils";
import TestInvoicePaymentModal from "../TestInvoice/TestInvoicePaymentModal";
import Button from "../../../components/Button";
import { Trash } from "../../../common/Icons/Trash";
import { ExclamationMark } from "../../../common/Icons/ExclamationMark";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import {
  getGVInvoicingErrorMessage,
  getGVInvoicingSuccessMessage,
} from "../../../util/InvoiceSystemUtils";
import { GlobalVetInvoiceResponse } from "../../../models/management/GlobalVetInvoiceResponse";

interface Props {
  isActiveTab: boolean;
  countryCode: string;
  onLoadingChange: (isLoading: boolean) => void;
  onError: (error: string | null) => void;
}

const TestInvoices: React.FC<Props> = (props: Props) => {
  const { isActiveTab, countryCode, onLoadingChange, onError } = props;

  const [invoices, setInvoices] = useState<GlobalVetInvoiceResponse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] =
    useState<GlobalVetInvoiceResponse>();

  const getTestInvoices = async () => {
    onLoadingChange(true);
    try {
      const resp = await TestInvoiceApi.getInvoices(countryCode);
      setInvoices(resp.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      onLoadingChange(false);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getTestInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab, countryCode]);

  const deleteInvoice = async (invoiceId: string) => {
    onLoadingChange(true);
    try {
      await TestInvoiceApi.deleteInvoice(countryCode, invoiceId);
      await getTestInvoices();
    } catch (err) {
      onError(await getGeneralError(err));
    } finally {
      onLoadingChange(false);
    }
  };

  const openPaymentModal = (invoice: GlobalVetInvoiceResponse) => {
    setSelectedInvoice(invoice);
    setPaymentModalOpen(true);
  };

  const closePaymentModal = () => {
    setPaymentModalOpen(false);
    setSelectedInvoice(undefined);
  };

  const getInvoiceState = (invoice: GlobalVetInvoiceResponse) => {
    let icon;

    switch (invoice.valid) {
      case false:
        icon = (
          <Tooltip
            content={
              invoice.validationError || getGVInvoicingErrorMessage(invoice)
            }
            placement="right"
          >
            <ExclamationMark className="text-red-500" />
          </Tooltip>
        );
        break;
      case true:
        icon = (
          <Tooltip
            content={getGVInvoicingSuccessMessage(invoice)}
            placement="right"
          >
            <CheckCircle className="color-green" />
          </Tooltip>
        );
        break;
      default:
        icon = undefined;
        break;
    }

    return icon;
  };

  return (
    <>
      <div className="tw-card">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
          {strings.testInvoices}
        </h2>

        <div className="p-4 space-y-4">
          <AlertBox message={error} />
          <div hidden={invoices.length <= 0}>
            <table className="tw-table">
              <thead className="tw-thead">
                <tr>
                  <th className="tw-th" />
                  <th className="tw-th">{strings.invoiceCreationDate}</th>
                  <th className="tw-th">{strings.totalCost}</th>
                  <th className="tw-th" />
                </tr>
              </thead>
              <tbody className="tw-tbody">
                {invoices.map((invoice) => (
                  <tr>
                    <td className="p-4">{getInvoiceState(invoice)}</td>
                    <td className="p-4">
                      <Button
                        variant="link"
                        onClick={async () => {
                          onLoadingChange(true);
                          setError(null);
                          try {
                            await downloadTestInvoicePdf(countryCode, invoice);
                            if (!invoice.valid) {
                              await getTestInvoices();
                            }
                          } catch (err) {
                            setError(await getGeneralError(err));
                          } finally {
                            onLoadingChange(false);
                          }
                        }}
                      >
                        {moment(invoice.creationDate).format(
                          dateAndTime.momentDateTimeFormat
                        )}
                      </Button>
                    </td>
                    <td className="p-4">
                      {NumberFormatter.formatPrice(
                        invoice.totalCost,
                        invoice.currency
                      )}
                    </td>
                    <td className="p-4 text-right space-x-4">
                      <Button
                        variant="icon"
                        onClick={() => deleteInvoice(invoice.id)}
                      >
                        <Trash />
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => openPaymentModal(invoice)}
                      >
                        {strings.pay}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div hidden={invoices.length > 0}>
            <i>{strings.noTestInvoiceCreatedYet}</i>
          </div>
          <div className="flex items-center">
            <div className="ml-auto">
              <Link to={`/management/countries/${countryCode}/test-invoice`}>
                <Button>{strings.createTestInvoice}</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {selectedInvoice && (
        <TestInvoicePaymentModal
          isOpen={isPaymentModalOpen}
          close={closePaymentModal}
          countryCode={countryCode}
          invoice={selectedInvoice}
        />
      )}
    </>
  );
};

export default TestInvoices;
