/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import { StripeIntegrationRequest } from "../../../../../models/clinic/StripeIntegrationRequest";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { PrevStateSetter } from "./StripeIntegration";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import stripeLogo from "../../../../../assets/Images/stripe_logo.png";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import StripeApi from "../../../../../api/StripeApi";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal/Modal";

interface Props {
  clinic: ClinicResponse;
  setClinic: (setter: PrevStateSetter) => void;
  show: boolean;
  onHide: () => void;
}

interface AddStripeIntegrationData {
  privateKey: string;
  publicKey: string;
}

const defaultFormValues = {
  privateKey: "",
  publicKey: "",
};

const StripeIntegrationModal: React.FC<Props> = ({
  clinic,
  setClinic,
  show,
  onHide,
}: Props) => {
  const [addStripeLoading, setAddStripeLoading] = useState<boolean>(false);
  const [disableStripeLoading, setDisableStripeLoading] =
    useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.ERROR);

  const addStripeIntegration = async (stripeData: AddStripeIntegrationData) => {
    setAddStripeLoading(true);

    const request: StripeIntegrationRequest = {
      enabling: true,
      privateKey: stripeData.privateKey,
      publicKey: stripeData.publicKey,
    };

    try {
      await StripeApi.enableStripe(clinic.id, request);
      setAlertMessage(strings.savedSuccessfully);
      setAlertType(AlertType.SUCCESS);
      setClinic((prevState) => {
        const newState = prevState;
        newState.stripeEnabled = true;
        return newState;
      });
    } catch (error) {
      setAlertMessage(await getGeneralError(error));
      setAlertType(AlertType.ERROR);
    } finally {
      setAddStripeLoading(false);
    }
  };

  const disableStripeIntegration = async () => {
    setDisableStripeLoading(true);

    const request: StripeIntegrationRequest = {
      enabling: false,
      privateKey: null,
      publicKey: null,
    };

    try {
      await StripeApi.enableStripe(clinic.id, request);
      setAlertMessage(strings.savedSuccessfully);
      setAlertType(AlertType.SUCCESS);
      setClinic((prevState) => {
        const newState = prevState;
        newState.stripeEnabled = false;
        return newState;
      });
    } catch (error) {
      setAlertMessage(await getGeneralError(error));
      setAlertType(AlertType.ERROR);
    } finally {
      setDisableStripeLoading(false);
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AddStripeIntegrationData>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    const resetFormAndErrors = () => {
      reset(defaultFormValues);
      setAlertMessage(null);
    };

    if (show) {
      resetFormAndErrors();
    }
  }, [reset, show]);

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.stripeIntegration} />
      <Modal.Body>
        <img className="mb-3" width="120px" alt="stripeLogo" src={stripeLogo} />
        <AlertBox
          closeAble={false}
          type={AlertType.WARNING}
          message={strings.stripeIntegrationCostWarning}
        />
        <form
          className="space-y-6"
          onSubmit={handleSubmit(addStripeIntegration)}
          id="addStripeIntegrationForm"
        >
          <Field
            name="privateKey"
            label={strings.privateKey}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            required
            error={errors.privateKey}
          />
          <Field
            name="publicKey"
            label={strings.publicKey}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            required
            error={errors.publicKey}
          />
        </form>
        <AlertBox message={alertMessage} type={alertType} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || addStripeLoading}
          form="addStripeIntegrationForm"
          loading={addStripeLoading}
          type="submit"
        >
          {strings.save}
        </Button>
        <Button
          className="modal-sub-button"
          disabled={!clinic.stripeEnabled}
          loading={disableStripeLoading}
          onClick={disableStripeIntegration}
          variant="danger"
        >
          {strings.turnOff}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StripeIntegrationModal;
