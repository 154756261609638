/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment } from "react";
import moment from "moment";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import NumberFormatter from "../../../util/NumberFormatter";
import { CostItemResponse } from "../../../models/statistics/CostItemResponse";
import CostCalculator from "../../../util/CostCalculator";
import { MonthlyClinicCostsResponse } from "../../../models/statistics/MonthlyClinicCostsResponse";
import { ClinicChargeResponse } from "../../../models/charge/ClinicChargeResponse";

interface Props {
  costs?: MonthlyClinicCostsResponse | ClinicChargeResponse;
  hideForecast: boolean;
}

const CostSummarySection: React.FC<Props> = (props: Props) => {
  const { costs, hideForecast } = props;

  const formatPrice = (price: number) =>
    NumberFormatter.formatPrice(price, costs?.currency);

  const getOriginalPrice = (
    discountedPrice: number,
    discountPercent: number
  ) => {
    const remainingPercent = (100 - discountPercent) / 100;
    return formatPrice(discountedPrice / remainingPercent);
  };

  const getGrossPrice = (item: CostItemResponse) =>
    /* TODO: rounding decimal length -> country of "GlobalVet"*/
    formatPrice(
      CostCalculator.getGrossPriceOfItem({
        price: item.unitPrice,
        quantity: item.quantity,
        vat: item.vat,
      })
    );

  const getNetPrice = (item: CostItemResponse) =>
    formatPrice(
      CostCalculator.getNetPriceOfItem({
        price: item.unitPrice,
        quantity: item.quantity,
        vat: item.vat,
      })
    );

  return (
    <>
      {costs && (
        <div className="tw-table-container">
          <table className="tw-table">
            <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
              <tr>
                <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.feeName}
                </th>
                <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.unitPrice}
                </th>
                <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.quantity}
                </th>
                <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.netPrice}
                </th>
                <th className="py-3.5 px-3 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400t">
                  {strings.grossPrice}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
              {costs.periods.map((period, index) => (
                <Fragment key={index}>
                  <tr
                    key={period.startDate}
                    className="bg-gray-50 dark:bg-gray-800"
                  >
                    <td className="px-3 py-3 text-sm font-bold" colSpan={4}>
                      {`${moment(period.startDate).format(
                        dateAndTime.momentDateFormat
                      )} - ${moment(period.endDate).format(
                        dateAndTime.momentDateFormat
                      )}`}
                      {period.trialDiscountPercent ? (
                        <span className="ml-3 badge badge-primary">
                          {period.trialDiscountPercent}% {strings.discount}
                        </span>
                      ) : null}
                    </td>
                    <td className="px-3 py-3 text-sm font-bold">
                      {period.trialDiscountPercent ? (
                        <div>
                          {getOriginalPrice(
                            period.subtotal,
                            period.trialDiscountPercent
                          )}
                        </div>
                      ) : null}
                      {formatPrice(period.subtotal)}
                    </td>
                  </tr>
                  {period.costs.map((cost) => (
                    <tr key={period.startDate + cost.name}>
                      <td className="px-3 py-3 text-sm font-medium">
                        {cost.name}
                      </td>
                      <td className="px-3 py-3 text-sm font-medium whitespace-nowrap">
                        {formatPrice(cost.unitPrice)}
                      </td>
                      <td className="px-3 py-3 text-sm font-medium whitespace-nowrap">
                        {cost.quantity}
                      </td>
                      <td className="px-3 py-3 text-sm font-medium whitespace-nowrap">
                        {getNetPrice(cost)}
                      </td>
                      <td className="px-3 py-3 text-sm font-medium whitespace-nowrap">
                        {getGrossPrice(cost)}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
              <tr className="bg-gray-50 dark:bg-gray-800">
                <td className="px-3 py-3 text-sm font-bold whitespace-nowrap">
                  {hideForecast
                    ? strings.summaryOfFees
                    : strings.sumFeeAtMoment}
                </td>
                <td />
                <td />
                <td />
                <td className="px-3 py-3 text-sm font-bold whitespace-nowrap">
                  {formatPrice(costs.total)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CostSummarySection;
