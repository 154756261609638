/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLocalStorage } from "@uidotdev/usehooks";
import { findLocale } from "../../common/Strings/Strings";
import LoaderInline from "../../components/LoaderInline";

interface Props {
  apiKey: string;
  children: any;
  clientSecret?: string;
}

const StripeSection: React.FC<Props> = ({
  apiKey,
  children,
  clientSecret,
}: Props) => {
  const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");

  const getLocale = (): any => findLocale();
  const stripePromise = loadStripe(apiKey, { locale: getLocale() });

  if (stripePromise === null) {
    return <LoaderInline className="my-3" />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: getLocale(),
        clientSecret,
        appearance: {
          theme: darkMode ? "night" : "stripe",
          variables: {
            colorPrimary: darkMode ? "rgb(39 39 42)" : "rgb(228 228 231)",
            colorBackground: darkMode ? "rgb(55 65 81)" : "rgb(249 250 251)",
            colorText: darkMode ? "rgb(228 228 231)" : "rgb(39 39 42)",
          },
        },
        loader: "auto",
      }}
    >
      {children}
    </Elements>
  );
};

export default StripeSection;
