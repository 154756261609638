/*
 * Copyright © GlobalVET AB, 2019.
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  prescriptionCancellationReasons,
  strings,
} from "../../common/Strings/Strings";
import { CancellationFlag } from "../../models/ePrescription/cancellation/CancellationFlag";
import { Option } from "../../models/Option";
import { prescriptionRegexp } from "../../util/Validations";
import ControlledField from "../../components/ReactHookFormFields/General/Controlled/ControlledField";
import Select from "../../components/ReactHookFormFields/General/Select/Select";
import Textarea from "../../components/ReactHookFormFields/General/Textarea";

export interface PrescriptionWithdrawalFormData {
  reason: string;
  cancellationFlag: CancellationFlag;
  ciIndex: string;
  cipIndex: string;
}

interface Props {
  ciIndex: number;
  cipIndex: number;
  updateCancellationItems(v: PrescriptionWithdrawalFormData): void;
  validateList(isValid: boolean): void;
}

const WithdrawForm: React.FC<Props> = ({
  ciIndex,
  cipIndex,
  updateCancellationItems,
  validateList
}: Props) => {
  const {
    control,
    formState: { isValid, errors },
    getValues,
    register,
    watch,
  } = useForm<PrescriptionWithdrawalFormData>({ mode: "onChange" });
  const [cancellationFlag] = watch(["cancellationFlag"]);

  const options: Option[] = [
    {
      title:
        prescriptionCancellationReasons[
          CancellationFlag.ANIMAL_CARER_INCORRECT
        ],
      value: CancellationFlag.ANIMAL_CARER_INCORRECT,
    },
    {
      title:
        prescriptionCancellationReasons[CancellationFlag.MEDICATION_INCORRECT],
      value: CancellationFlag.MEDICATION_INCORRECT,
    },
    {
      title:
        prescriptionCancellationReasons[
          CancellationFlag.PRESCRIPTION_OUT_OF_DATE
        ],
      value: CancellationFlag.PRESCRIPTION_OUT_OF_DATE,
    },
    {
      title: prescriptionCancellationReasons[CancellationFlag.OTHER],
      value: CancellationFlag.OTHER,
    },
  ];

  useEffect(() => {
    const subscription = watch(data => {
      updateCancellationItems(data as PrescriptionWithdrawalFormData);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    validateList(isValid);
  }, [isValid]);

  return (
    <form>
      <ControlledField
        className="d-none"
        control={control}
        name="ciIndex"
        type="number"
        value={ciIndex}
      />
      <ControlledField
        className="d-none"
        control={control}
        name="cipIndex"
        type="number"
        value={cipIndex}
      />
      <Select
        error={errors.cancellationFlag}
        isChooseOption
        label={strings.reason}
        name="cancellationFlag"
        options={options}
        register={register}
        required
      />
      {cancellationFlag === CancellationFlag.OTHER ? (
        <Textarea
          error={errors.reason}
          fieldOptions={{
            maxLength: 80,
            minLength: 1,
            pattern: {
              value: prescriptionRegexp,
              message: strings.notAllowedCharacte,
            },
            validate: {
              isRequired: value => {
                const cancellationFlag = getValues("cancellationFlag");
                return cancellationFlag === CancellationFlag.OTHER
                  ? value.length > 0
                  : true;
              },
            },
          }}
          label={strings.comment}
          name="reason"
          maxLength={80}
          minRows={2}
          placeholder={strings.reason}
          register={register}
        />
      ) : (
        <></>
      )}
    </form>
  );
};

export default WithdrawForm;
