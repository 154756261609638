/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { PageResponse } from "../models/PageResponse";
import { ReservationDeletionRequest } from "../models/reservation/ReservationDeletionRequest";
import { ReservationResponse } from "../models/reservation/ReservationResponse";
import { axiosCommon } from "../util/axiosSetup";
import { localDateTimeStringOf } from "../util/helperFunctions";
import { ReservationStateRequest } from "../models/reservation/ReservationStateRequest";
import qs from "query-string";

export default class ReservationApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getUserReservations(): ApiPromise<ReservationResponse[]> {
    const url = `${this.baseUrl}/user-reservations`;
    return axiosCommon.get(url);
  }

  static getReservation(
    reservationId: string
  ): ApiPromise<ReservationResponse> {
    const url = `${this.baseUrl}/reservations/${reservationId}`;
    return axiosCommon.get(url);
  }

  static deleteReservation(
    reservationId: string,
    request?: ReservationDeletionRequest
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/reservations/${reservationId}`;
    return axiosCommon.delete(url, { data: request });
  }

  static getClinicPetReservations(
    petId: string,
    pageSize?: number,
    pageNumber?: number
  ): ApiPromise<PageResponse<ReservationResponse>> {
    let url = `${this.baseUrl}/clinic-pets/${petId}/reservations`;
    if (pageSize) {
      url += `?pageSize=${pageSize}&pageNumber=${pageNumber || 0}`;
    }
    return axiosCommon.get(url);
  }

  static getClinicReservations(
    clinicId: string,
    startTime: Date,
    endTime: Date
  ): ApiPromise<ReservationResponse[]> {
    const query = qs.stringify({
      startTime: localDateTimeStringOf(startTime),
      endTime: localDateTimeStringOf(endTime),
    });
    const url = `${this.baseUrl}/clinics/${clinicId}/reservations?${query}`;
    return axiosCommon.get(url);
  }

  static updateReservationState(
    reservationId: string,
    request?: ReservationStateRequest
  ): ApiPromise<ReservationResponse> {
    const url = `${this.baseUrl}/reservations/${reservationId}/state`;
    return axiosCommon.put(url, request);
  }
}
