/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, Fragment, useState } from "react";
import { MedicalRecordFileResponse } from "../../../models/medical/MedicalRecordFileResponse";
import { MedicalRecordFileType } from "../../../models/medical/MedicalRecordFileType";
import FileRow from "./FileRow";
import AlertBox from "../../../components/AlertBox";

interface Props {
  canEdit: boolean;
  files: MedicalRecordFileResponse[];
  resIdS?: string;
  type: MedicalRecordFileType;
}

const Files = ({ canEdit, files, resIdS, type }: Props): ReactElement => {
  const [error, setError] = useState<string | null>(null);

  return (
    <div hidden={files.length <= 0} className="grid grid-cols-2 lg:grid-cols-4 gap-2">
      {files.map((file: MedicalRecordFileResponse) => {
        if (file.type === type) {
          return (
            <Fragment key={file.fileId}>
              <FileRow
                canEdit={canEdit}
                file={file}
                resIdS={resIdS}
                setError={setError}
              />
            </Fragment>
          );
        }
        return <Fragment key={file.fileId} />;
      })}
      <AlertBox className="mt-3" closeAble={false} message={error} />
    </div>
  );
};

export default Files;
