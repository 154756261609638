/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import PrescriptionApi from "../../api/PrescriptionApi";
import { dateAndTime, petTypes, strings } from "../../common/Strings/Strings";
import { HistoryData } from "../../models/ePrescription/history/HistoryData";
import SpeciesFunctions from "../../common/AnimalSpecies/SpeciesFunctions";
import { PrescriptionLinkState } from "../../models/ePrescription/local/PrescriptionLinkState";
import { NewPrescriptionStatus } from "../../models/ePrescription/response/NewPrescriptionStatus";
import ItemList from "../ePrescriptionHistory/ItemList";
import { getGeneralError } from "../../util/helperFunctions";
import { Tooltip } from "../../components/Tooltip";
import AlertBox from "../../components/AlertBox";
import { useSpecies } from "../../contexts/SpeciesContext";
import { LocalizedPetSpeciesResponse } from "../../models/pet/LocalizedPetSpeciesResponse";
import AddButtonIcon from "../../components/AddButtonIcon";
import Button from "../../components/Button";
import { CheckCircle } from "../../common/Icons/CheckCircle";
import { ExclamationMark } from "../../common/Icons/ExclamationMark";

interface Props {
  clinicId: string;
  medicalRecordId: string;
  petId: string;
  signed: boolean;
  state: PrescriptionLinkState;
}

const PrescriptionSection: React.FC<Props> = ({
  medicalRecordId,
  clinicId,
  state,
  signed,
  petId,
}: Props) => {
  const [prescriptions, setPrescriptions] = useState<HistoryData[]>();
  const [error, setError] = useState<string | null>(null);
  const [reload, setReload] = useState<boolean>(true);

  const speciesCodesAndNames: LocalizedPetSpeciesResponse[] =
    useSpecies().speciesCodesAndNames;

  const getPrescriptions = async (mrId: string) => {
    try {
      const response = await PrescriptionApi.getPrescriptionsOfClinic(
        clinicId,
        undefined,
        undefined,
        undefined,
        mrId
      );
      setPrescriptions(response.data.elements);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  };

  useEffect(() => {
    if (medicalRecordId) {
      void getPrescriptions(medicalRecordId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalRecordId, clinicId, reload]);

  const statusIcon = (status: NewPrescriptionStatus) => {
    let icon = (
      <Tooltip content={strings.successfulPrescription} placement="left">
        <CheckCircle className="text-green-500" />
      </Tooltip>
    );

    switch (status) {
      case NewPrescriptionStatus.FAILED:
        icon = (
          <Tooltip content={strings.failedPrescription} placement="left">
            <ExclamationMark className="text-red-500" />
          </Tooltip>
        );
        break;
      case NewPrescriptionStatus.WARNINGS:
        icon = (
          <Tooltip content={strings.warningPrescription} placement="left">
            <ExclamationMark className="text-orange-500" />
          </Tooltip>
        );
        break;
      default:
        break;
    }

    return icon;
  };

  const prescriptionDescription = (prescription: HistoryData) => (
    <div>
      <table className="tw-table">
        <tbody>
          <tr>
            <td>
              <b>{strings.date}</b>
            </td>
            <td>
              {moment
                .unix(prescription.creationTimestamp)
                .format(dateAndTime.momentDateFormat)}
            </td>
          </tr>
          <tr>
            <td>
              <b>{strings.vet}</b>
            </td>
            <td>
              {prescription.model.veterinaryInformation?.givenName}{" "}
              {prescription.model.veterinaryInformation?.familyName}
            </td>
          </tr>
          <tr>
            <td>
              <b>{strings.pet}</b>
            </td>
            <td>
              {prescription.model.animal?.animalIds[0]},{" "}
              {SpeciesFunctions.getAnimalStringFromCode(
                speciesCodesAndNames,
                prescription.model.animal?.speciesCode
              )}
              ,{" "}
              {prescription.model.animal?.animalIdType
                ? petTypes[prescription.model.animal?.animalIdType]
                : ""}
            </td>
          </tr>
          <tr>
            <td>
              <b>{strings.petOwner}</b>
            </td>
            <td>
              {prescription.model.animalCarer?.givenName
                ? prescription.model.animalCarer?.givenName
                : ""}{" "}
              {prescription.model.animalCarer?.familyName
                ? prescription.model.animalCarer?.familyName
                : ""}
              {prescription.model.animalCarer?.familyName ||
              prescription.model.animalCarer?.givenName ? (
                ", "
              ) : (
                <></>
              )}
              {prescription.model.animalCarer?.personalNumber}
              {prescription.model.animalCarer?.birthDate}
              {prescription.model.animalCarer?.personalNumber ||
              prescription.model.animalCarer?.birthDate ? (
                ", "
              ) : (
                <></>
              )}
              {prescription.model.animalCarer?.organizationName
                ? prescription.model.animalCarer?.organizationName
                : ""}
              {prescription.model.animalCarer?.organizationName ? ", " : <></>}
              {prescription.model.animalCarer?.organizationId
                ? prescription.model.animalCarer?.organizationId
                : ""}
              {prescription.model.animalCarer?.organizationId ? ", " : <></>}
              {prescription.model.animalCarer?.streetAddress}
              {", "}
              {prescription.model.animalCarer?.postalCode}{" "}
              {prescription.model.animalCarer?.city}
              {", "}
              {prescription.model.animalCarer?.phoneNumber}
            </td>
          </tr>
          {prescription.model.receiverPharmacy ? (
            <tr>
              <td>
                <b>{strings.receiverPharmacy}</b>
              </td>
              <td>
                {prescription.model.receiverPharmacy?.shortName}
                {", "}
                {prescription.model.receiverPharmacy?.officeAddress}
                {", "}
                {prescription.model.receiverPharmacy?.zipCode}{" "}
                {prescription.model.receiverPharmacy?.city}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {prescription.model.delivery ? (
            <tr>
              <td>
                <b>{strings.delivery}</b>
              </td>
              <td>
                {prescription.model.delivery?.pharmacy ? (
                  <>
                    {prescription.model.delivery?.pharmacy.shortName}
                    {", "}
                    {prescription.model.delivery?.pharmacy.officeAddress}
                    {", "}
                    {prescription.model.delivery?.pharmacy.zipCode}{" "}
                    {prescription.model.delivery?.pharmacy.city}
                  </>
                ) : (
                  <>
                    {prescription.model.delivery?.streetAddress}
                    {", "}
                    {prescription.model.delivery?.building}{" "}
                    {prescription.model.delivery?.building ? ", " : <></>}
                    {prescription.model.delivery?.postalCode}{" "}
                    {prescription.model.delivery?.city}
                  </>
                )}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {prescription.model.prescription.comment &&
          prescription.model.prescription.comment !== "" ? (
            <tr>
              <td>
                <b>{strings.commentToPharmacist}</b>
              </td>
              <td>
                {prescription.model.prescription.comment}
              </td>
            </tr>
          ) : (
            <></>
          )}
          <tr>
            <td>
              <b>{strings.prescriptionItem}</b>
            </td>
            <td>
              <ItemList
                withdrawalAllowed
                historyData={prescription}
                activePrescription={prescription.model.prescription}
                triggerReload={() => {
                  setReload(!reload);
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <>
      <AlertBox message={error} className="mb-3" />
      {prescriptions?.map((prescription: HistoryData) => (
        <>
          {prescription.creation.data.internalResponse.result ===
          NewPrescriptionStatus.FAILED ? (
            <></>
          ) : (
            <div className="tw-card flex p-3">
              {prescriptionDescription(prescription)}
              <div className="ml-auto">
                {statusIcon(prescription.creation.data.internalResponse.result)}
              </div>
            </div>
          )}
        </>
      ))}

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-6" hidden={signed}>
          <Link to={`/prescription/${petId}`} state={state}>
            <Button variant="primary-outline" fullWidth disabled={signed}>
              <div className="flex items-center justify-center">
                <AddButtonIcon />
                {strings.prescription}
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PrescriptionSection;
