/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useLayoutEffect } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { PhoneType, phoneTypeOptions } from "../../../models/contact/PhoneType";
import AlertBox from "../../AlertBox";
import PhoneNumber from "../../ReactHookFormFields/Specific/PhoneNumber";
import Select from "../../ReactHookFormFields/General/Select/Select";
import CheckBox from "../../ReactHookFormFields/General/CheckBox";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../Button";
import Modal from "../../Modal/Modal";

export interface NewPhoneForm {
  isPublic?: boolean;
  phone: string;
  phoneType: PhoneType;
}

interface Props {
  close(): void;
  error: string | null;
  isOpen: boolean;
  loading: boolean;
  onSubmit(value: NewPhoneForm): void;
  isUser?: boolean;
  defaultCountryCode?: string;
}

const AddPhoneModal: React.FC<Props> = ({
  close,
  error,
  isOpen,
  loading,
  onSubmit,
  isUser = false,
  defaultCountryCode,
}: Props) => {
  const {
    register,
    control,
    formState: { isValid },
    getValues,
    reset,
  } = useForm<NewPhoneForm>({ mode: "onChange" });

  useLayoutEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.addPhoneNumber} />
      <Modal.Body noSpacing>
        <form className="modal-body" id="addPhoneForm">
          <PhoneNumber
            control={control}
            country={defaultCountryCode}
            id="phone"
            name="phone"
            autocomplete={AutoCompleteOptions.off}
            label={strings.phoneNumber}
            required
          />
          <Select
            name="phoneType"
            options={phoneTypeOptions}
            register={register}
            isChooseOption={false}
            label={strings.phoneType}
            required
          />
          {isUser ? (
            <div>
              <CheckBox
                name="isPublic"
                register={register}
                label={strings.publicForVeterinaryWorkers}
              />
            </div>
          ) : (
            <></>
          )}
          <AlertBox message={error} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || loading}
          form="addPhoneForm"
          fullWidth={false}
          loading={loading}
          onClick={() => {
            onSubmit(getValues());
            reset();
          }}
        >
          {strings.addPhoneNumber}
        </Button>
        <Button
          className="modal-sub-button"
          disabled={loading}
          onClick={close}
          variant="secondary"
        >
          {strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPhoneModal;
