/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../../components/AlertBox";
import { BasicUserResponse } from "../../../models/user/BasicUserResponse";
import VetApi from "../../../api/VetApi";
import { getGeneralError } from "../../../util/helperFunctions";
import logger from "../../../util/logger";
import { VetProfessionWithUserResponse } from "../../../models/vet/VetProfessionWithUserResponse";
import CloseButton from "../../../components/CloseButton";
import Button from "../../../components/Button";

interface Props {
  onHide: () => void;
  onUpdated?: (vetProfWithUser: VetProfessionWithUserResponse) => any;
  setVetProfWithUser: (vetProfWithUser: VetProfessionWithUserResponse) => void;
  show: boolean;
  user: BasicUserResponse;
  userEmail?: string;
}

const ApprovalModal: React.FC<Props> = ({
  onHide,
  onUpdated,
  setVetProfWithUser,
  show,
  user,
  userEmail,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onApprove = async () => {
    setLoading(true);

    try {
      const resp = await VetApi.approveUserAsVet(user.userId);
      setVetProfWithUser(resp.data);
      if (onUpdated !== undefined) {
        onUpdated(resp.data);
      }
      onHide();
    } catch (e) {
      setError(await getGeneralError(e));
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal center show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{strings.approveVetProfessionConfirmation}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <div className="mb-3">
            {strings.approveVetProfessionConfirmationMessage}
          </div>
          <div>
            <div>
              <strong>{`${strings.name}: `}</strong>
              {user.fullName}
            </div>
            <div>
              <strong>{`${strings.userId}: `}</strong>
              {user.userId}
            </div>
            <div>
              <strong>{`${strings.email}: `}</strong>
              {userEmail}
            </div>
          </div>
        </div>
        <AlertBox message={error} className="my-3" />
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={onHide} text={strings.cancel} />
        <Button
          className="ml-auto"
          fullWidth={false}
          loading={loading}
          onClick={onApprove}
          variant="primary"
        >
          {strings.approve}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovalModal;
