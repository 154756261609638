/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { strings } from "../common/Strings/Strings";
import SimpleSelect from "./InputFieldsSimple/SimpleSelect";

interface Props {
  loading?: boolean;
  pageSize: number;
  setPageSize(pageSize: number): void;
  totalPages: number;
  totalResults: number;
}

const PageSizeSelect = ({
  loading,
  pageSize,
  setPageSize,
  totalPages,
  totalResults,
}: Props): ReactElement => (
  <>
    {loading ? (
      <></>
    ) : (
      <>
        <SimpleSelect
          isChooseOption={false}
          className="mb-0"
          name="resultsPerPage"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setPageSize(Number(e.target.value));
          }}
          options={[
            { title: "10", value: 10 },
            { title: "15", value: 15 },
            { title: "20", value: 20 },
            { title: "25", value: 25 },
            { title: "50", value: 50 },
          ]}
          value={pageSize}
          width="70px"
        />

        <div className="ml-3">
          {`${strings.total}: `}
          <b>{`${totalResults} ${strings.results}, ${totalPages} ${strings.pages}`}</b>
        </div>
      </>
    )}
  </>
);

PageSizeSelect.defaultProps = {
  loading: false,
};

export default PageSizeSelect;
