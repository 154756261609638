import Resizer from "react-image-file-resizer";
import { FastAverageColor, FastAverageColorResult } from "fast-average-color";
import { strings } from "../common/Strings/Strings";

interface ResizeImageOptions {
  file: File;
  width: number;
  height: number;
  format?: "WEBP" | "JPEG" | "PNG";
  quality?: number;
  rotation?: number;
}

export const AcceptedImageTypes = [".jpg", ".jpeg", ".png", ".svg", ".webp"];

export const ImageResizeDimensions = {
  width: 300,
  height: 300,
};

export const isSupportedImageFileType = (file: File): boolean => {
  const fileExtension = `${file.type.split("/")[1]}`;
  if (fileExtension === "svg+xml") {
    return AcceptedImageTypes.includes(".svg");
  }
  return AcceptedImageTypes.includes(`.${file.type.split("/")[1]}`);
};

export const unsupportedFileTypeMessage = (): string => {
  let typesList = "";

  AcceptedImageTypes.forEach((type, index) => {
    const t = type.slice(1).toUpperCase();

    if (index === 0) {
      typesList += ` ${t}`;
    } else if (index === AcceptedImageTypes.length - 1) {
      typesList += ` ${strings.or} ${t}.`;
    } else {
      typesList += `, ${t}`;
    }
  });

  return strings.unsupportedFileType + typesList;
};

const getFileExtension = (file: File) => file.type.split("/")[1].toUpperCase();

export const resizeImage = ({
  file,
  width,
  height,
  format,
  quality = 100,
  rotation = 0,
}: ResizeImageOptions): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      format ?? getFileExtension(file),
      quality,
      rotation,
      (uri: any) => {
        resolve(uri);
      },
      "blob"
    );
  });

export const getAverageColor = async (
  imageUrl: string
): Promise<FastAverageColorResult> =>
  new Promise((resolve, reject) => {
    const fac = new FastAverageColor();
    const image = new Image();

    image.crossOrigin = "Anonymous";
    image.src = imageUrl;

    image.onload = () => {
      const color: FastAverageColorResult = fac.getColor(image);
      resolve(color);
    };

    image.onerror = (error) => {
      reject(error);
    };
  });
