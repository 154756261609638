/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Button } from "react-bootstrap";
import React, { useState } from "react";
import moment from "moment";
import GDPRApi from "../../../api/GDPRApi";
import { strings } from "../../../common/Strings/Strings";
import { LocalLanguageResponse } from "../../../models/language/LocalLanguageResponse";
import { PolicyResponse } from "../../../models/management/PolicyResponse";
import { getGeneralError } from "../../../util/helperFunctions";
import { PolicyType } from "../../../models/management/PolicyType";
import { CountrySettingsResponse } from "../../../models/management/CountrySettingsResponse";
import AddPolicyModal from "./AddPolicyModal";
import EditPolicyModal from "./EditPolicyModal";
import DeletePolicyModal from "./DeletePolicyModal";
import AlertBox from "../../../components/AlertBox";
import SimpleCheckBox from "../../../components/InputFieldsSimple/SimpleCheckBox";

interface Props {
  policyType: PolicyType;
  policyName: string;
  policies: PolicyResponse[];
  languages: LocalLanguageResponse[];
  country: CountrySettingsResponse;
  onPolicyChanged: (policy: PolicyResponse) => void;
  onPrimaryPolicyChanged: (policy: PolicyResponse) => void;
}

export const formatVersion = (v: string): string => {
  const currentLocale = moment.locale();
  moment.locale(strings.getLanguage());
  const versionStr = moment(v)
    .utc()
    .utcOffset(-1 * new Date().getTimezoneOffset())
    .format("YYYY-MMM-DD hh:mm:ss A");
  moment.locale(currentLocale);
  return versionStr;
};

const PolicyList: React.FC<Props> = ({
  policyType,
  policyName,
  policies,
  languages,
  country,
  onPolicyChanged,
  onPrimaryPolicyChanged,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [selectedPolicyLanguageCode, setSelectedPolicyLanguageCode] =
    useState<string>();
  const [selectedPolicyStatement, setSelectedPolicyStatement] =
    useState<string>();
  const [selectedPolicyVersion, setSelectedPolicyVersion] = useState<string>();

  const [policyToDelete, setPolicyToDelete] = useState<PolicyResponse>();

  const openAddModal = () => {
    setShowAddModal(true);
    setError(null);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openEditModal = (policy: PolicyResponse) => {
    setShowEditModal(true);
    setSelectedPolicyLanguageCode(policy.languageCode);
    setSelectedPolicyStatement(policy.statement);
    setSelectedPolicyVersion(formatVersion(policy.version));
    setError(null);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedPolicyLanguageCode(undefined);
    setSelectedPolicyStatement(undefined);
    setSelectedPolicyVersion(undefined);
  };

  const openDeleteModal = (policy: PolicyResponse) => {
    setShowDeleteModal(true);
    setPolicyToDelete(policy);
    setError(null);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setPolicyToDelete(undefined);
  };

  const setPolicyPrimary = async (policy: PolicyResponse) => {
    setLoading(true);
    onPrimaryPolicyChanged(policy);

    try {
      await GDPRApi.updatePrimaryPolicy(policy);
    } catch (e) {
      setError(await getGeneralError(e));
      onPolicyChanged(policy);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AlertBox message={error} className="mt-2" />
      <div className="card mb-4">
        <div className="block-label p-3 d-flex border-bottom-0">
          <div className="flex-grow-1 h5 m-0">{policyName}</div>
          <div>
            <Button variant="primary" onClick={openAddModal}>
              {strings.addNew}
            </Button>
          </div>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{strings.language}</th>
              <th className="text-center">{strings.primary}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {policies
              .filter((i) => i.countryCode === country.countryCode)
              .map((policy) => (
                <tr key={`${policy.countryCode}-${policy.languageCode}`}>
                  <td className="align-middle">{policy.languageName}</td>
                  <td className="text-center">
                    <div className="d-flex justify-content-center mt-1">
                      <SimpleCheckBox
                        id={`${policy.countryCode}-${policy.languageCode}`}
                        name="primaryForCountry"
                        checked={policy.primaryForCountry}
                        onChange={(checked) =>
                          checked && setPolicyPrimary(policy)
                        }
                      />
                    </div>
                  </td>
                  <td className="align-middle text-right">
                    <Button
                      className="mr-2"
                      variant="secondary"
                      onClick={() => {
                        openEditModal(policy);
                      }}
                      disabled={loading}
                    >
                      <i className="fas fa-pencil-alt" />
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        openDeleteModal(policy);
                      }}
                      disabled={loading}
                    >
                      <i className="fas fa-trash" />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <AddPolicyModal
        country={country}
        languages={languages}
        onHide={closeAddModal}
        onPolicyChanged={onPolicyChanged}
        policies={policies}
        policyName={policyName}
        policyType={policyType}
        show={showAddModal}
      />
      <EditPolicyModal
        country={country}
        language={selectedPolicyLanguageCode}
        languages={languages}
        onHide={closeEditModal}
        onPolicyChanged={onPolicyChanged}
        policyName={policyName}
        policyType={policyType}
        setVersion={setSelectedPolicyVersion}
        show={showEditModal}
        statement={selectedPolicyStatement}
        version={selectedPolicyVersion}
      />
      <DeletePolicyModal
        onHide={closeDeleteModal}
        onPolicyChanged={onPolicyChanged}
        policyName={policyName}
        policyToDelete={policyToDelete}
        setError={setError}
        show={showDeleteModal}
      />
    </>
  );
};

export default PolicyList;
