/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ChildrenProps } from "../models/ChildrenProps";

interface IModalContext {
  openModalIds: string[]; // IDs of currently open modals
  addModalId: (idToAdd: string) => void; // Add a modal ID to the list
  removeModalId: (idToRemove: string) => void; // Remove a modal ID from the list
}

const ModalContext = createContext<IModalContext | null>(null);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const useModal = () => useContext(ModalContext)!;

export const ModalProvider: React.FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [openModalIds, setOpenModalIds] = useState<string[]>([]);

  const addModalId = useCallback((idToAdd: string) => {
    setOpenModalIds((prevIds) => [...prevIds, idToAdd]);
  }, []);

  const removeModalId = useCallback((idToRemove: string) => {
    setOpenModalIds((prevIds) => prevIds.filter((id) => id !== idToRemove));
  }, []);

  const ctx = useMemo(
    () => ({
      openModalIds,
      addModalId,
      removeModalId,
    }),
    [addModalId, openModalIds, removeModalId]
  );

  return <ModalContext.Provider value={ctx}>{children}</ModalContext.Provider>;
};
