/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import LoaderInline from "../../../components/LoaderInline";
import { getGeneralError } from "../../../util/helperFunctions";
import { getAccessToken } from "../../../util/LocalStorageVariables";
import logger from "../../../util/logger";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import EmailConfirmationInvalidBase from "../../../components/contact/confirmation/EmailConfirmationInvalidBase";
import ResendEmailConfirmation from "./ResendEmailConfirmation";
import { ApiPromise } from "../../../models/ApiPromise";
import { SendConfirmationEmailResponse } from "../../../models/user/SendConfirmationEmailResponse";
import { Card } from "../../../components/Cards/Card";
import Button from "../../../components/Button";

interface Props {
  confirm: () => Promise<any>;
  emailId: string;
  resendMail: (emailId: string) => ApiPromise<SendConfirmationEmailResponse>;
  token: string;
  onRedirect?: () => void;
}

const EmailConfirmation: React.FC<Props> = ({
  confirm,
  emailId,
  resendMail,
  token,
  onRedirect,
}: Props) => {
  const navigate = useNavigate();

  const [redirectSeconds, setRedirectSeconds] = useState<number | undefined>(5);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

  const [resendEmail, setResendEmail] = useState<string>();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const startRedirect = (seconds: number) => {
    setRedirectSeconds(seconds);
    const interval = setInterval(() => {
      setRedirectSeconds((prev) => (prev ? prev - 1 : undefined));
    }, 1000);
    setIntervalId(interval);
  };

  useEffect(() => {
    if (!onRedirect) return;

    if (redirectSeconds !== undefined && redirectSeconds <= 0) {
      if (intervalId) {
        setRedirectSeconds(undefined);
        clearInterval(intervalId);
      }
      onRedirect();
    }

    /* eslint-disable-next-line */
  }, [redirectSeconds]);

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        await confirm();
        setError(null);
        if (getAccessToken() && onRedirect) {
          startRedirect(5);
        }
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void confirmEmail();
  }, [token, emailId, confirm, onRedirect]);

  const sendConfirmationMail = async () => {
    setLoading(true);
    try {
      const resp = await resendMail(emailId);
      setResendEmail(resp.data.email);
    } catch (err) {
      logger.error(err);
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  const display = () => {
    if (loading) {
      return <LoaderInline className="flex justify-center" />;
    }
    if (resendEmail) {
      return (
        <ResendEmailConfirmation
          email={resendEmail ?? ""}
          emailId={emailId}
          resend={(id) => resendMail(id)}
        />
      );
    }
    if (error) {
      return (
        <EmailConfirmationInvalidBase
          loading={loading}
          message={error}
          resend={sendConfirmationMail}
        />
      );
    }
    return (
      <div>
        <AlertBox
          className="my-5"
          closeAble={false}
          message={strings.emailVerificationIsSuccessful}
          type={AlertType.SUCCESS}
        />
        {onRedirect ? (
          <div>
            <p hidden={!redirectSeconds} className="my-3">
              {strings.redirectInSeconds?.replace("{1}", redirectSeconds)}
            </p>
            <Link to="/">
              <Button>{strings.continue}</Button>
            </Link>
          </div>
        ) : (
          <div>
            <p className="my-3">{strings.emailVerificationIsSuccessfulInfo}</p>
            <Link to="/login">
              <Button>{strings.goToSignIn}</Button>
            </Link>
          </div>
        )}
      </div>
    );
  };

  const signInVisible = !!resendEmail || !!error || !!onRedirect;

  return (
    <Card title={strings.emailVerification}>
      {display()}
      {signInVisible && (
        <div className="grid grid-cols-2 gap-4 my-5">
          <div className="flex justify-start">
            <span className="text-left text-sm font-medium text-gray-500 dark:text-gray-400">
              {strings.alreadyHaveAccount}
            </span>
          </div>
          <div className="flex justify-end">
            <Button
              className="text-sm"
              onClick={() => navigate("/login")}
              variant="link"
            >
              {strings.signInHere}
            </Button>
          </div>
        </div>
      )}
    </Card>
  );
};

export default EmailConfirmation;
