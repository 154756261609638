/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";
import { CreateClinicBasicInfoForm } from "../CreateClinic";
import AlertBox from "../../../components/AlertBox";

interface Props {
  onNext(): void;
}

const AddressTab: React.FC<Props> = ({ onNext }: Props) => {
  const {
    register,
    formState: { errors },
    trigger,
  } = useFormContext<CreateClinicBasicInfoForm>();

  return (
    <div className="space-y-6">
      <Field
        autoComplete={AutoCompleteOptions.addressLine1}
        error={errors.address?.street}
        label={strings.addressLine1}
        name="address.street"
        register={register}
        required
        showRequired
      />
      <Field
        autoComplete={AutoCompleteOptions.addressLine2}
        error={errors.address?.houseDetails}
        label={strings.addressLine2}
        name="address.houseDetails"
        optional
        register={register}
      />
      <Field
        autoComplete={AutoCompleteOptions.addressLevel1}
        error={errors.address?.city}
        label={strings.city}
        name="address.city"
        register={register}
        required
        showRequired
      />
      <Field
        autoComplete={AutoCompleteOptions.postalCode}
        error={errors.address?.zip}
        label={strings.postalCode}
        name="address.zip"
        placeholder={strings.postalCode}
        register={register}
        required
        showRequired
      />
      <AlertBox
        hidden={
          errors.address?.street?.type !== "required" &&
          errors.address?.city?.type !== "required" &&
          errors.address?.zip?.type !== "required"
        }
        message={strings.pleaseFillRequiredFields}
      />
      <Button
        onClick={() => {
          const response = trigger([
            "address.street",
            "address.city",
            "address.zip",
            "address.houseDetails",
          ]);

          response.then((isValid: boolean) => {
            if (isValid) {
              onNext();
            }
          });
        }}
      >
        {strings.saveAndContinue}
      </Button>
    </div>
  );
};

export default AddressTab;
