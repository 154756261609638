/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { MedicinalProduct } from "../../../models/ePrescription/VARA/MedicinalProduct";
import { Package } from "../../../models/ePrescription/VARA/Package";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import Field from "../../../components/ReactHookFormFields/General/Field";
import PackageInfo from "./PackageInfo";

interface Props {
  mapPackingType(packageType?: string): string;
  medicalProduct?: MedicinalProduct;
}

const PackageSelection: React.FC<Props> = ({
  mapPackingType,
  medicalProduct,
}: Props) => {
  const [selectedPackage, setSelectedPackage] = useState<Package>();

  const {
    control,
    getValues,
    register,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext();

  useEffect(() => {
    const v = getValues("selectedPackage");
    if (medicalProduct?.packages?.length === 1 && (!v || v?.length === 0)) {
      setValue("selectedPackage", medicalProduct?.packages, {
        shouldValidate: true, shouldDirty: true
      });
      setSelectedPackage(medicalProduct.packages[0]);
    }
  }, [medicalProduct, getValues, setValue]);

  useEffect(() => {
    void trigger();
  }, [trigger]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="min-w-80">
          <CombinedSelect
            allowNew={false}
            control={control}
            label={strings.packages}
            labelKey="label"
            name="selectedPackage"
            onChange={(p: Package[] | null) => {
              setSelectedPackage(p ? p[0] : undefined);
            }}
            options={medicalProduct?.packages || []}
            required
          />
        </div>
        <div className="pl-4">
          <label>{strings.packagingQuantity}</label>
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.numberOfPackages}
            fieldOptions={{
              max: {
                value: 9999,
                message: strings.formatString(strings.mustBeBetween, 1, 10000),
              },
              min: {
                value: 1,
                message: strings.formatString(strings.mustBeBetween, 1, 10000),
              },
            }}
            name="numberOfPackages"
            register={register}
            required
            type="number"
            width="60px"
          />
        </div>
      </div>
      <PackageInfo
        mapPackingType={mapPackingType}
        selectedPackage={selectedPackage}
      />
    </>
  );
};

export default PackageSelection;
