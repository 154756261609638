/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { FreeTextMedicalRecordRequest } from "../../../models/medical/FreeTextMedicalRecordRequest";
import { useClinic } from "../../../contexts/ClinicContext";
import MedicalRecordApi from "../../../api/MedicalRecordApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { strings } from "../../../common/Strings/Strings";
import Field from "../../../components/ReactHookFormFields/General/Field";
import DatePickerInput from "../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Modal from "../../../components/Modal/Modal";
import CloseButton from "../../../components/CloseButton";
import Button from "../../../components/Button";

interface Props {
  close(): void;
  isOpen: boolean;
  petId: string;
  reload?(): void;
}

const NewExternalMedicalRecordModal = ({ close, isOpen, petId, reload }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { clinic } = useClinic();
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
  } = useForm<FreeTextMedicalRecordRequest>();

  const onSubmit = async (request: FreeTextMedicalRecordRequest) => {
    setError(null);
    setIsSuccess(false);

    if (clinic && request) {
      setSubmitting(true);

      try {
        await MedicalRecordApi.createFreeTextMedicalRecord(
          clinic.id,
          petId,
          request
        );
        setIsSuccess(true);
        if(reload){
          reload();
        }
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Modal handleClose={close} show={isOpen} size="modal-lg">
      <Modal.Header title={strings.addNewExternalMedicalRecord} />
      <Modal.Body>
        <Field
          error={errors.title}
          label={strings.title}
          name="title"
          register={register}
        />
        <div className="flex">
          <div className="mr-5 w-full">
            <DatePickerInput
              control={control}
              label={strings.startTime}
              name="startDateTime"
            />
          </div>
          <div className="w-full">
            <DatePickerInput
              control={control}
              fieldOptions={{
                validate: {
                  isAfterStartTime: (v) => {
                    const start = getValues("startDateTime");
                    return (
                      moment(start).isBefore(moment(v), "day") ||
                      moment(start).isSame(moment(v), "day") ||
                      strings.startLaterThanEnd
                    );
                  },
                },
              }}
              label={strings.endTime}
              name="endDateTime"
            />
          </div>
        </div>
        <Textarea
          label={strings.description}
          error={errors.description}
          name="description"
          minRows={20}
          register={register}
          required
          showRequired
        />
        <AlertBox message={error} />
        <AlertBox
          closeAble={false}
          hidden={!isSuccess}
          message={strings.savedSuccessfully}
          type={AlertType.SUCCESS}
        />
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={close} text={strings.cancel} />
        <Button
          className="modal-main-button"
          disabled={submitting}
          loading={submitting}
          onClick={handleSubmit(onSubmit)}
        >
          {strings.create}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewExternalMedicalRecordModal;
