/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import TicketingApi from "../../api/TicketingApi";
import { strings } from "../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../components/AlertBox";
import Field from "../../components/ReactHookFormFields/General/Field";
import Select from "../../components/ReactHookFormFields/General/Select/Select";
import Textarea from "../../components/ReactHookFormFields/General/Textarea";
import PhoneNumber from "../../components/ReactHookFormFields/Specific/PhoneNumber";
import { useUser } from "../../contexts/UserContext";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { TICKET_TYPES, TicketType } from "../../models/support/TicketType";
import { generateOptions, getGeneralError } from "../../util/helperFunctions";
import Button from "../../components/Button";

interface UnauthorizedTicketForm {
  title: string;
  type: TicketType;
  content: string;
  fullName: string;
  email: string;
  phone?: string;
}

interface TicketTypeOptions {
  key: TicketType;
  value: string;
}

export const TicketCreationPage: React.FC = () => {
  const { user } = useUser();

  const ticketTypeOptions: TicketTypeOptions[] = TICKET_TYPES;

  const [loading, setLoading] = useState<boolean>(false);
  const [isCreated, setIsCreated] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<UnauthorizedTicketForm>({
    mode: "onChange",
  });

  const submit = async (formData: UnauthorizedTicketForm) => {
    const { title, type, content, fullName, email, phone } = formData;

    const requestBody = {
      title,
      content,
      type,
      userEmail: email,
      userFullName: fullName,
      userLocale: strings.getLanguage(),
      userPhone: phone,
    };

    setLoading(true);

    try {
      if (user.userId) {
        await TicketingApi.createAuthorizedTicket(requestBody);
      } else {
        await TicketingApi.createUnauthorizedTicket(requestBody);
      }
      setErrorMessage(null);
      reset();
      setIsCreated(true);
    } catch (e) {
      setErrorMessage(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="main-default">
      <section>
        <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <form
            className="w-full md:max-w-md md:tw-card space-y-6 md:p-8"
            onSubmit={handleSubmit(submit)}
          >
            <div>
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.supportTicketHeader}
              </h1>
            </div>
            <Field
              className="w-100"
              name="title"
              register={register}
              autoComplete={AutoCompleteOptions.off}
              error={errors.title}
              label={strings.supportTicketTitle}
              required
            />
            <Select
              name="type"
              register={register}
              autoComplete={AutoCompleteOptions.off}
              error={errors.type}
              options={generateOptions(ticketTypeOptions, "value", "key")}
              label={strings.supportTicketType}
              required
            />
            <Textarea
              className="w-100"
              name="content"
              register={register}
              autoComplete={AutoCompleteOptions.off}
              error={errors.content}
              label={strings.supportTicketDescription}
              minRows={5}
              required
            />
            {!user.userId && (
              <>
                <Field
                  className="w-100"
                  name="fullName"
                  register={register}
                  autoComplete={AutoCompleteOptions.off}
                  error={errors.fullName}
                  placeholder={strings.supportTicketFullName}
                  label={strings.supportTicketFullName}
                  required
                />
                <Field
                  className="w-100"
                  name="email"
                  type="email"
                  register={register}
                  autoComplete={AutoCompleteOptions.off}
                  error={errors.email}
                  label={strings.supportTicketEmail}
                  required
                  showRequired
                />
                <PhoneNumber
                  className="w-100"
                  control={control}
                  id="phone"
                  name="phone"
                  label={strings.supportTicketPhoneNumber}
                  required
                  showRequired
                />
              </>
            )}
            <AlertBox message={errorMessage} type={AlertType.ERROR} />
            <AlertBox
              className="my-3"
              hidden={!isCreated}
              message={strings.supportTicketCreated}
              type={AlertType.SUCCESS}
            />
            <Button
              disabled={!isValid || loading}
              loading={loading}
              type="submit"
              variant="primary"
            >
              {strings.supportTicketSendButton}
            </Button>
            {!!user.userId && (
              <div>
                <Link
                  to="/support/tickets"
                  className="tw-link text-left text-sm"
                >
                  {strings.backToTicketsPage}
                </Link>
              </div>
            )}
          </form>
        </div>
      </section>
    </main>
  );
};
