/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../util/helperFunctions";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import ManualOwnerPasswordInput from "./ManualOwnerPasswordInput";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import Button from "../../../../components/Button";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import ClinicApi from "../../../../api/ClinicApi";
import Modal from "../../../../components/Modal/Modal";
import ClinicLogo from "../../../../components/Pictures/Clinic/ClinicLogo";

interface Props {
  clinic: ClinicResponse;
  show: boolean;
  hide: () => void;
}

interface DeleteClinicForm {
  clinicName: string;
  password: string;
}

const defaultFormValues = {
  clinicName: "",
  password: "",
};

const DeleteClinicModal: React.FC<Props> = ({ clinic, show, hide }: Props) => {
  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const sendClinicDeletionEmail = async (data: DeleteClinicForm) => {
    setErrorMessage(null);
    setSendingEmail(true);

    try {
      await ClinicApi.sendClinicDeletionMail(clinic.id, data.password);
      setEmailSent(true);
    } catch (e) {
      setErrorMessage(await getGeneralError(e));
    } finally {
      setSendingEmail(false);
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<DeleteClinicForm>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    const resetFormAndErrors = () => {
      reset(defaultFormValues);
      setErrorMessage(null);
    };

    if (show) {
      resetFormAndErrors();
    }
  }, [reset, show]);

  return (
    <Modal handleClose={hide} show={show}>
      <Modal.Header title={strings.deleteClinic} />
      <Modal.Body>
        {!emailSent ? (
          <>
            <p>{strings.organizationDeleteConfirmation}</p>
            <div className="flex flex-row items-center">
              <div className="w-20">
                <ClinicLogo
                  clinicId={clinic.id}
                  logoPictureId={clinic?.logoPictureId}
                />
              </div>
              <p className="ml-3 text-lg font-bold">{clinic.name}</p>
            </div>
            <AlertBox
              className="mb-3"
              closeAble={false}
              type={AlertType.WARNING}
              message={strings.organizationDeletionWarning}
            />
            <form
              className="space-y-6"
              onSubmit={handleSubmit(sendClinicDeletionEmail)}
              id="deleteClinicForm"
            >
              <Field
                name="clinicName"
                label={strings.clinicName}
                register={register}
                autoComplete={AutoCompleteOptions.off}
                fieldOptions={{
                  required: true,
                  validate: {
                    orgNameIsCorrect: (v) =>
                      v === clinic.name || strings.organizationNameIsIncorrect,
                  },
                }}
                error={errors.clinicName}
              />
              <ManualOwnerPasswordInput
                register={register}
                error={errors.password}
              />
            </form>
            <AlertBox message={errorMessage} />
          </>
        ) : (
          <AlertBox
            type={AlertType.SUCCESS}
            message={strings.clinicDeletionConfirmationEmailSent}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || sendingEmail}
          hidden={emailSent}
          form="deleteClinicForm"
          loading={sendingEmail}
          type="submit"
          variant="danger"
        >
          {strings.deleteClinic}
        </Button>
        <Button
          className="modal-main-button"
          hidden={!emailSent}
          loading={sendingEmail}
          onClick={hide}
        >
          {strings.close}
        </Button>
        <Button
          className="modal-sub-button"
          disabled={sendingEmail}
          hidden={emailSent}
          onClick={hide}
          variant="secondary"
        >
          {strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteClinicModal;
