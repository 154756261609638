/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import SimpleInput from "./InputFieldsSimple/SimpleInput";

interface Props {
  label?: string | null;
  text?: string | null;
  inputLike?: boolean;
  widthPercent: number;
}

const InfoTextWithLabel = ({
  label,
  text,
  inputLike,
  widthPercent,
}: Props): ReactElement => (
  <div className={`w-${widthPercent} py-2`}>
    <div className="info-text-label">{label ?? ""}</div>
    {inputLike ? (
      <SimpleInput
        className="mt-2 mb-0"
        name="info-text"
        value={text}
        disabled
      />
    ) : (
      <div className="info-text">{text ?? ""}</div>
    )}
  </div>
);

export default InfoTextWithLabel;
