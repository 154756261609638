/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useCallback, useEffect, useState } from "react";
import AlertBox from "../../../components/AlertBox";
import { Loader } from "../../../components/Loader";
import { useClinic } from "../../../contexts/ClinicContext";
import { SettingsTabs } from "../../../models/clinic/SettingsTabs";
import { PageProps } from "../../../models/PageProps";
import ClinicSettingsMenu from "./ClinicSettingsMenu";
import ClinicSettingsTabs from "./ClinicSettingsTabs";
import ClinicApi from "../../../api/ClinicApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import { ClinicUpdateRequest } from "../../../models/clinic/ClinicUpdateRequest";

export interface Notification {
  emailReminder: boolean;
  emailReminderTime: string;
  smsReminder: boolean;
  smsReminderTime: string;
  smsOnReservationCancel: boolean;
  smsOnReservationReschedule: boolean;
}

export const getClinicUpdateDto = (
  clinic: ClinicResponse
): ClinicUpdateRequest => ({
  name: clinic.name,
  features: clinic.features,
  emailBefore: clinic.emailBefore,
  smsBefore: clinic.smsBefore,
  inventoryStockTrackingEnabled: clinic.inventoryStockTrackingEnabled,
  smsOnReservationCancel: clinic.smsOnReservationCancel,
  smsOnReservationReschedule: clinic.smsOnReservationReschedule,
  vat: clinic.vat,
  businessName: clinic.businessName,
  businessStructure: clinic.businessStructure,
  language: clinic.language,
  registrationNumber: clinic.registrationNumber,
});

const ClinicSettings: React.FC<PageProps> = ({ setPageLoading }: PageProps) => {
  const { clinic, setClinic } = useClinic();
  const [error, setError] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState<SettingsTabs>(
    SettingsTabs.basic
  );

  const [notification, setNotification] = useState<Notification>();

  const getClinic = useCallback(async () => {
    if (!clinic?.id) {
      return;
    }

    try {
      const response = await ClinicApi.getClinic(clinic.id);
      const { data } = response;

      setClinic(data);

      const reminder: Notification = {
        emailReminder: !!data.emailBefore,
        emailReminderTime: data.emailBefore ? data.emailBefore.toString() : "",
        smsReminder: !!data.smsBefore,
        smsReminderTime: data.smsBefore ? data.smsBefore.toString() : "",
        smsOnReservationCancel: data.smsOnReservationCancel,
        smsOnReservationReschedule: data.smsOnReservationReschedule,
      };

      setNotification(reminder);
      setError(null);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPageLoading(false);
    }
  }, [clinic?.id, setClinic, setPageLoading]);

  useEffect((): void => {
    if (currentTab !== SettingsTabs.reservationTypes) {
      void getClinic();
    }
  }, [currentTab, getClinic]);

  return (
    <main className="main-signed-in">
      <section>
        <AlertBox message={error} className="m-3" />
        {clinic && (
          <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
            <div>
              <h1 className="flex items-center text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {clinic.name}
              </h1>
            </div>
            <div className="border border-gray-200 rounded-md bg-gray-50 !p-4 text-center text-md font-medium text-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300">
              <ClinicSettingsMenu clinic={clinic} currentTab={currentTab} />
            </div>
            <ClinicSettingsTabs
              clinic={clinic}
              getClinic={getClinic}
              setClinic={setClinic}
              notification={notification}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            />
          </div>
        )}
      </section>
    </main>
  );
};

export default Loader(ClinicSettings);
