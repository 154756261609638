/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

/*
 * Responsive design layout breakpoints by Tailwind (by min. width)
 * Official: sm, md, lg, xl, 2xl
 * Unofficial (for small mobile screens): xs, 2xs, 3xs, 4xs
 */
import { getEnumName } from "./helperFunctions";

export enum TailwindResponsiveBreakpoints {
  "3xs" = 0, // width: 0-359 px
  "2xs" = 360, // width: 360-479 px
  "xs" = 480, // width: 480-639 px
  sm = 640, // width: 640-787 px
  md = 768, // width: 787-1023 px
  lg = 1024, // width: 1024-1279 px
  xl = 1280, // width: 1280-1535 px
  "2xl" = 1536, // min. width: 1536 px
}

// Returns the breakpoint category based on the current screen size
export const getScreenSizeCategory = (
  byName = false
): TailwindResponsiveBreakpoints | string => {
  const screenWidth = window.innerWidth;

  // Convert the enum to number array
  const breakpointValues: number[] = Object.values(
    TailwindResponsiveBreakpoints
  ).map(Number);

  // Find the first breakpoint where screenWidth is less than its value
  const breakpoint = breakpointValues.find((value) => screenWidth < value);

  // If no breakpoint is found (screen width is greater than the largest breakpoint),
  // the category defaults to the largest breakpoint (2xl)
  if (breakpoint === undefined) {
    return TailwindResponsiveBreakpoints["2xl"];
  }

  // Else the category is the previous category
  const breakpointIndex = breakpointValues.indexOf(breakpoint);
  const category = breakpointValues[breakpointIndex - 1];

  if (byName) {
    return getEnumName(TailwindResponsiveBreakpoints, category);
  }

  return category;
};
