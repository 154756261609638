/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { DropDirection } from "react-bootstrap/esm/DropdownContext";
import { useClinic } from "../../../../contexts/ClinicContext";
import { NewReservationPetType } from "../../../../models/calendar/NewReservationPetType";
import { SavedReservationProgress } from "../../../../models/calendar/SavedReservationProgress";
import { makeListSentenceFromWords } from "../../../../util/helperFunctions";
import {
  appointmentToRescheduleKey,
  deleteFromLocalStorage,
  saveReservationInProgress,
} from "../../../../util/LocalStorageVariables";
import { HighlightedText } from "../../../../util/StringUtils";
import { petGenders, petSpecies, strings } from "../../../Strings/Strings";
import { ClinicPetAndOwnerResponse } from "../../../../models/pet/ClinicPetAndOwnerResponse";
import { PublicPhoneResponse } from "../../../../models/contact/PublicPhoneResponse";
import TodoModal from "../../../../pages/Todo/TodoModal";
import { PetProfileTabs } from "../../../../pages/Pet/PetProfile/ClinicPetProfile";
import { Feature } from "../../../../models/clinic/Feature";
import PetProfilePicture from "../../../../components/Pictures/Pet/PetProfilePicture";
import { Heart } from "../../../Icons/Heart";

interface Props {
  dropDirection: DropDirection;
  line: ClinicPetAndOwnerResponse;
  isFirstRow: boolean;
  query: string;
}

const SearchResultRow: React.FC<Props> = ({
  dropDirection,
  line,
  isFirstRow,
  query,
}: Props) => {
  const [newTodo, setNewTodo] = useState<boolean>(false);
  const { clinic } = useClinic();
  const navigate = useNavigate();

  const tdClassName = isFirstRow
    ? "align-middle no-border-on-top"
    : "align-middle";

  const startNewReservation = (petAndOwner: ClinicPetAndOwnerResponse) => {
    const reservation: SavedReservationProgress = {
      petState: {
        state: NewReservationPetType.SELECTED,
        pet: petAndOwner.pet,
        petOwner: petAndOwner.petOwner,
      },
      petStatePermanent: true,
      symptoms: [],
    };

    saveReservationInProgress(reservation);
    deleteFromLocalStorage(appointmentToRescheduleKey);

    navigate("/");
  };

  return (
    <tr
      key={`${line.pet.clinicPetDetails.id}_${line.pet.clinicPetDetails.name}`}
    >
      <td className={tdClassName}>
        <div className="flex items-start">
          <div className="py-1 pr-3">
            <div style={{ width: "30px", height: "30px" }}>
              <PetProfilePicture
                species={line.pet.clinicPetDetails.species}
                petId={line.pet.clinicPetDetails.id}
              />
            </div>
          </div>
          <div className="py-1 pr-3 text-sm" style={{ whiteSpace: "nowrap" }}>
            <div className="flex items-center">
              <HighlightedText
                text={line.pet.clinicPetDetails.name}
                highlights={query.split(" ")}
              />
              {line.pet.clinicPetDetails?.deceased ? (
                <div>
                  <Heart className="ml-2 h-5 w-5" variant="solid" />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              {makeListSentenceFromWords([
                petSpecies[line.pet.clinicPetDetails.species],
                petGenders[line.pet.clinicPetDetails.gender],
                line.pet.clinicPetDetails.breed,
                line.pet.clinicPetDetails.lastMedicalRecordWeight &&
                  `${line.pet.clinicPetDetails.lastMedicalRecordWeight} kg`,
              ])}
            </div>
            <div className="flex italic">
              <HighlightedText
                text={line.petOwner.petOwnerDetails.fullName}
                highlights={query.split(" ")}
              />
              {line.petOwner.petOwnerDetails.personalId && (
                <div className="text-sm ml-2">
                  <HighlightedText
                    text={line.petOwner.petOwnerDetails.personalId}
                    highlights={query.split(" ")}
                  />
                </div>
              )}
              {line.petOwner.petOwnerDetails.phones?.map(
                (phone: PublicPhoneResponse) => (
                  <div className="text-sm ml-2">
                    <HighlightedText
                      text={phone.value}
                      highlights={query.split(" ")}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </td>
      <td className={tdClassName}>
        <div className="flex items-center flex-wrap md:flex-nowrap">
          <Dropdown className="rounded-dropdown" drop={dropDirection}>
            <Dropdown.Toggle
              as="div"
              bsPrefix="toggle-kebab"
              id="dropdown-pets-more"
            >
              <span className="material-icons">more_vert</span>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="mt-1 p-0">
              {clinic?.features.includes(Feature.RESERVATION) && (
                <Dropdown.Item
                  as="div"
                  bsPrefix="calendar-menu dropdown-item hr-on-bottom"
                  onClick={() => {
                    startNewReservation(line);
                  }}
                >
                  {strings.newReservation}
                </Dropdown.Item>
              )}
              <Dropdown.Item
                as="div"
                bsPrefix="calendar-menu dropdown-item hr-on-bottom"
                onClick={() => {
                  setNewTodo(true);
                }}
              >
                {strings.newTask}
              </Dropdown.Item>
              <Dropdown.Item
                bsPrefix="calendar-menu dropdown-item hr-on-bottom"
                href={`/prescription/${line.pet.clinicPetDetails.id}`}
              >
                {strings.newPrescription}
              </Dropdown.Item>
              <Dropdown.Item bsPrefix="calendar-menu dropdown-item hr-on-bottom">
                <Link
                  className="no-underline"
                  to={`/prescriptions/pet/${line.pet.clinicPetDetails.id}`}
                  state={{ petName: line.pet.clinicPetDetails.name }}
                >
                  {strings.prescriptions}
                </Link>
              </Dropdown.Item>
              <Dropdown.Item
                bsPrefix="calendar-menu dropdown-item hr-on-bottom"
                href={`/clinic-pet-profile/${line.pet.clinicPetDetails.id}/${PetProfileTabs.basic}`}
              >
                {strings.petProfile}
              </Dropdown.Item>
              <Dropdown.Item
                bsPrefix="calendar-menu dropdown-item hr-on-bottom"
                href={`/pet-owner-profile/${line.petOwner.petOwnerDetails.id}`}
              >
                {strings.ownerProfile}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <TodoModal
          onHide={() => {
            setNewTodo(false);
          }}
          show={newTodo}
          todoForPet={line}
        />
      </td>
    </tr>
  );
};

export default SearchResultRow;
