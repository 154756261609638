/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import { MedicinalProduct } from "../../../../models/ePrescription/VARA/MedicinalProduct";
import { Package } from "../../../../models/ePrescription/VARA/Package";
import { VaraItem } from "../../../../models/inventory/item/VaraItem";
import PackageInfo from "../../../ePrescription/productDetails/PackageInfo";
import Button from "../../../../components/Button";
import { PackageType } from "../../../../models/ePrescription/VARA/PackageType";
import CombinedSelectComponent from "../../../../components/InputFieldsSimple/CombinedSelectComponent";

interface Props {
  medicalProduct?: MedicinalProduct;
  packageTypes: PackageType[];
  returnPackages?(p: VaraItem): void;
}

const VaraPackageSelection: React.FC<Props> = ({
  medicalProduct,
  packageTypes,
  returnPackages,
}: Props) => {
  const [selectedPackage, setSelectedPackage] = useState<Package[] | null>(
    null
  );

  const mapPackingType = (packageType?: string): string => {
    if (packageTypes) {
      packageTypes.forEach((pt: PackageType) => {
        if (pt.label === packageType) {
          packageType = pt.description;
        }
      });
    }

    return packageType || "";
  };

  const submit = () => {
    const resultPackage = selectedPackage?.[0];

    if (resultPackage && returnPackages) {
      const newVaraItem: VaraItem = {
        name: `${medicalProduct?.name || resultPackage.title} (${
          medicalProduct?.strength || ""
        })`,
        barcode: resultPackage?.barcode,
        unit: resultPackage?.quantityNumericUnit,
        packageContent: resultPackage?.quantityNumeric,
        varaNplPackId: resultPackage.nplPackId,
      };
      returnPackages(newVaraItem);
    }
  };

  return (
    <div className="space-y-4">
      <CombinedSelectComponent
        labelKey="label"
        name="packageSelect"
        onChange={(v: Package[] | null) => {
          setSelectedPackage(v);
        }}
        options={medicalProduct?.packages || []}
        required
        value={selectedPackage}
      />
      {!!selectedPackage && selectedPackage?.length > 0 && (
        <PackageInfo
          mapPackingType={mapPackingType}
          selectedPackage={selectedPackage[0]}
        />
      )}
      <Button minW onClick={submit}>
        {strings.use}
      </Button>
    </div>
  );
};

export default VaraPackageSelection;
