/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import SubsectionTemplate from "./Templates/SubsectionTemplate";
import { strings } from "../../../common/Strings/Strings";
import { ReservationAccessScope } from "../../../models/reservation/ReservationAccessScope";
import SimpleInputTemplate from "./Templates/SimpleInputTemplate";
import TreatmentSection from "../Treatment/TreatmentSection";
import { useMainSectionContext } from "./MainSectionContext";
import TextAreaTemplate from "./Templates/TextAreaTemplate";
import Button from "../../../components/Button";
import { MedicalRecordFileType } from "../../../models/medical/MedicalRecordFileType";
import AddButtonIcon from "../../../components/AddButtonIcon";
import Files from "../Files/Files";
import AddItems from "../Treatment/AddItems/AddItems";

const TreatmentSectionMain: React.FC = () => {
  const {
    medicalRecord,
    signed,
    accessScope,
    chipNumber,
    setChipNumber,
    tattooNumber,
    setTattooNumber,
    newlyAdded,
    countryDetails,
    markTreatmentToDelete,
    error,
    handleTreatmentsChange,
    clinicTreatmentsByPetSpecies,
    triggerChange,
    treatmentText,
    setTreatmentText,
    setFilesModalOpen,
    files,
    resIdS, 
  } = useMainSectionContext();

  return (
    <SubsectionTemplate title={strings.treatment}>
      <div className="flex space-x-4">
        <div hidden={!!medicalRecord?.pet.chipNumber}>
          <SimpleInputTemplate
            name="chipNumber"
            label={strings.chipNumber}
            signed={signed}
            value={chipNumber || ""}
            setValue={setChipNumber}
            triggerChange={triggerChange}
          />
        </div>
        <div hidden={!!medicalRecord?.pet.tattooNumber}>
          <SimpleInputTemplate
            name="tattooNumber"
            label={strings.tattooNumber}
            signed={signed}
            value={tattooNumber || ""}
            setValue={setTattooNumber}
            triggerChange={triggerChange}
          />
        </div>
      </div>
      <TreatmentSection
        accessScope={accessScope}
        currentTreatments={newlyAdded.treatments}
        countryDetails={countryDetails}
        markTreatmentToDelete={markTreatmentToDelete}
        error={error}
        handleTreatmentsChange={handleTreatmentsChange}
        signed={signed}
        treatments={clinicTreatmentsByPetSpecies}
      />
      {medicalRecord && (
        <AddItems
          accessScope={accessScope}
          clinicId={medicalRecord.clinicId}
          signed={signed}
        />
      )}
      <TextAreaTemplate
        name="treatment"
        label={strings.description}
        accessScope={accessScope}
        signed={signed}
        value={treatmentText}
        setValue={setTreatmentText}
        triggerChange={triggerChange}
      />
      {!signed && (
        <div className="grid grid-cols-12 gap-4">
          <Button
            variant="primary-outline"
            fullWidth
            className="col-span-12 lg:col-span-6"
            onClick={() => setFilesModalOpen(MedicalRecordFileType.TREATMENT)}
          >
            <div className="flex items-center justify-center">
              <AddButtonIcon />
              {strings.documents}
            </div>
          </Button>
        </div>
      )}
      <Files
        canEdit={!(signed || accessScope === ReservationAccessScope.READ)}
        files={files}
        resIdS={resIdS}
        type={MedicalRecordFileType.TREATMENT}
      />
    </SubsectionTemplate>
  );
};

export default TreatmentSectionMain;
