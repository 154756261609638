/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */


import { CalendarRowData } from "./CalendarRowData";

export enum TupleType {
  EMPTY = "EMPTY",
  RESERVATION_START = "RESERVATION_START",
  RESERVATION = "RESERVATION",
  SHIFT_START = "SHIFT_START",
  SHIFT = "SHIFT"
}

export enum ZipType {
  ZIP_NEXT_BLOCK = "ZIP_NEXT_BLOCK",
  ZIPPED = "ZIPPED",
  NONE = "NONE"
}

export type CalendarTableRowUnit = [CalendarRowData | undefined, TupleType, ZipType];
