/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { PrescriptionItemFrontend } from "../../../../models/ePrescription/local/PrescriptionItemFrontend";
import { PrescriptionItem } from "../../../../models/ePrescription/sending/PrescriptionItem";
import { Instruction } from "../../../../models/ePrescription/sending/Instruction";
import { Interval } from "../../../../models/ePrescription/local/Interval";
import {
  prescriptionIntakes,
  prescriptionMethods,
  strings,
} from "../../../../common/Strings/Strings";
import { Package } from "../../../../models/ePrescription/sending/Package";
import { Prescription } from "../../../../models/ePrescription/sending/Prescription";
import { LicensedPrescriptionItemFrontend } from "../../../../models/ePrescription/local/LicensedPrescriptionItemFrontend";
import { WaitingPeriods } from "../../../../models/ePrescription/local/WaitingPeriods";
import { WaitingPeriod } from "../../../../models/ePrescription/sending/WaitingPeriod";
import { WaitingPeriodType } from "../../../../models/ePrescription/sending/WaitingPeriodType";
import { CommodityPrescriptionItemFrontend } from "../../../../models/ePrescription/local/CommodityPrescriptionItemFrontend";
import { PrescriptionModel } from "../../../../models/ePrescription/sending/PrescriptionModel";
import { TestFlag } from "../../../../models/ePrescription/sending/TestFlag";
import hardcodedStrings, {
  specificPharmacyTest2,
} from "../../fixValues/hardcodedStrings";
import { Animal } from "../../../../models/ePrescription/sending/Animal";
import { AnimalCarer } from "../../../../models/ePrescription/sending/AnimalCarer";
import { Delivery } from "../../../../models/ePrescription/sending/Delivery";
import { ExtendedPrescriptionItem } from "../../../../models/ePrescription/persistence/ExtendedPrescriptionItem";
import { ExtendedPackage } from "../../../../models/ePrescription/persistence/ExtendedPackage";
import { ExtendedPrescription } from "../../../../models/ePrescription/persistence/ExtendedPrescription";
import { UserResponse } from "../../../../models/user/UserResponse";
import { FeeType } from "../../../../models/ePrescription/local/FeeType";
import { Payment } from "../../../../models/ePrescription/sending/Payment";
import helperFunctions from "../../../../util/helperFunctions";
import { DosageTexts } from "../../../../models/ePrescription/local/DosageTexts";
import { ExtendedDelivery } from "../../../../models/ePrescription/persistence/ExtendedDelivery";
import { PersistenceModel } from "../../../../models/ePrescription/persistence/PersistenceModel";
import { Pharmacy } from "../../../../models/pharmacy/Pharmacy";
import { ExtendedProduct } from "../../../../models/ePrescription/persistence/ExtendedProduct";
import { PrescriptionItemType } from "../../../../models/ePrescription/persistence/PrescriptionItemType";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import { ClinicFeeResponse } from "../../../../models/clinic/ClinicFeeResponse";
import { ClinicFeeType } from "../../../../models/clinic/ClinicFeeType";
import {
  GiroAccountResponse,
  GiroAccountType,
} from "../../../../models/clinic/GiroAccountResponse";
import Params from "../../../../common/Params";
import { Option } from "../../../../models/Option";

export const generateUUId = (): string => uuidv4();

export const reverseFormatBirthDate = (
  birthDate: string | undefined
): string => {
  if (birthDate === undefined) {
    return "";
  }
  return moment(birthDate, "YYYYMMDD").format("YYYY-MM-DD");
};

const isTestEnvironment = Params.prescriptionTestEnvironment === "on";

export const generateTextFromIntervals = (
  instruction: DosageTexts | { [key: string]: any }
): string => {
  const { freeText, totalAmount, intervals } = instruction;
  let text = "";

  if (totalAmount) {
    text += `${strings.totalAmount}: ${totalAmount}. `;
  }

  intervals.forEach((interval: Interval, intervalIndex: number) => {
    const sentences: string[] = [];

    if (
      interval.dose &&
      interval.dose !== 0 &&
      interval.unit &&
      interval.unit !== ""
    ) {
      sentences.push(`${interval.dose.toString()} ${interval.unit}`);
    }

    if (interval.period && interval.period !== "") {
      sentences.push(prescriptionIntakes[interval.period]);
    }

    if (interval.daysType === "everyOtherWeek") {
      sentences.push(strings.everyOtherWeek);
    }
    else if (interval.daysType === "everyOtherDay") {
      sentences.push(strings.everyOtherDay);
    }
    else if (interval.daysType === "long") {
      sentences.push(strings.forFurther);
    } else if (interval.daysType === "custom") {
      if (interval.days && interval.days !== 0) {
        sentences.push(strings.formatString(strings.forPeriod, interval.days));
      }
    } else if (interval.daysType) {
      sentences.push(
        strings.formatString(strings.forPeriod, interval.daysType?.toString())
      );
    }

    interval?.method?.forEach((item: Option) => {
      if (item.value) {
        sentences.push(prescriptionMethods[item.value]);
      } else {
        sentences.push(item.title);
      }
    });

    sentences.forEach((word: string, index: number) => {
      text += sentences[index - 1]
        ? word.toLowerCase()
        : helperFunctions.capitalize(word);
      text += sentences[index + 1] ? ", " : ".";
    });
    text += intervals[intervalIndex + 1] ? " " : "";
  });

  if (freeText) {
    text += intervals[0] && intervals[0].dose ? " " : "";
    text += freeText;
  }

  return text;
};

export const transformPackages = (
  p: ExtendedPackage,
  isCommodityPackage = false
): Package[] => {
  const packages: Package[] = [];

  if (isCommodityPackage) {
    const newp: Package = {
      nvn: p.nvn,
      numberOfPackages: p.numberOfPackages,
    };

    if (p.numberOfPackages > 0) {
      packages.push(newp);
    }
  } else {
    const newp: Package = {
      nplPackId: p.nplPackId,
      numberOfPackages: p.numberOfPackages,
    };

    if (p.numberOfPackages > 0) {
      packages.push(newp);
    }
  }
  return packages;
};

export const transformPersistencePackages = (
  p: ExtendedPackage
): ExtendedPackage[] => {
  const packages: ExtendedPackage[] = [];

  if (p.numberOfPackages > 0) {
    packages.push(p);
  }

  return packages;
};

export const transformWaitingPeriods = (
  wp?: WaitingPeriods
): WaitingPeriod[] | undefined => {
  if (wp) {
    const wps: WaitingPeriod[] = [];
    if (wp.slaughter && wp.slaughter !== "") {
      wps.push({
        type: WaitingPeriodType.SLAUGHTER,
        days: parseInt(wp.slaughter, 10),
      });
    }
    if (wp.milk && wp.milk !== "") {
      wps.push({
        type: WaitingPeriodType.MILK,
        days: parseInt(wp.milk, 10),
      });
    }
    if (wp.egg && wp.egg !== "") {
      wps.push({
        type: WaitingPeriodType.EGGS,
        days: parseInt(wp.egg, 10),
      });
    }
    if (wp.honey && wp.honey !== "") {
      wps.push({
        type: WaitingPeriodType.HONEY,
        days: parseInt(wp.honey, 10),
      });
    }
    return wps;
  }

  return undefined;
};

const transformPrescription = (
  p: PrescriptionItemFrontend[],
  instructionLanguage: string | undefined
): PrescriptionItem[] => {
  const prescriptionItemList: PrescriptionItem[] = [];

  p.forEach((pItem: PrescriptionItemFrontend) => {
    const instruction: Instruction = {
      text: generateTextFromIntervals(pItem.dosageText),
    };

    if (instructionLanguage) {
      instruction.language = instructionLanguage;
    }

    const newPrescriptionItem: PrescriptionItem = {
      latestRequestedTimeForDispensing: pItem.latestRequestedTimeForDispensing
        ? moment(pItem.latestRequestedTimeForDispensing).format(
            hardcodedStrings.dateFormat
          )
        : null,
      nplId: pItem.product.nplId,
      instructions: instruction,
      packages: transformPackages(pItem.package),
      repeatDispensing: pItem.repeatDispensing
        ? pItem.repeatDispensing
        : { numberOfTimes: 1 },
      waitingPeriods: transformWaitingPeriods(pItem.waitingPeriods),
      antimicrobial: pItem.antimicrobial,
      validUntil: pItem.validUntil
        ? moment(pItem.validUntil).format("YYYY-MM-DD")
        : undefined,
    };

    prescriptionItemList.push(newPrescriptionItem);
  });

  return prescriptionItemList;
};

const transformLicensedPrescription = (
  lp: LicensedPrescriptionItemFrontend[],
  instructionLanguage: string | undefined
): PrescriptionItem[] => {
  const prescriptionItemList: PrescriptionItem[] = [];

  lp.forEach((lpItem: LicensedPrescriptionItemFrontend) => {
    const instruction: Instruction = {
      text: generateTextFromIntervals(lpItem.dosageText),
    };

    if (instructionLanguage) {
      instruction.language = instructionLanguage;
    }

    const newLicensedPrescriptionItem: PrescriptionItem = {
      latestRequestedTimeForDispensing: lpItem.latestRequestedTimeForDispensing
        ? moment(lpItem.latestRequestedTimeForDispensing).format(
            hardcodedStrings.dateFormat
          )
        : null,
      nplId: lpItem.nplId,
      instructions: instruction,
      packages: lpItem.package,
      repeatDispensing: lpItem.repeatDispensing
        ? lpItem.repeatDispensing
        : { numberOfTimes: 1 },
      licensed: lpItem.freeTextLicense
        ? {
            ...lpItem.freeTextLicense,
            pharmacyId: isTestEnvironment
              ? hardcodedStrings.specificPharmacyTest2
              : lpItem.freeTextLicense.pharmacyId,
          }
        : undefined,
      waitingPeriods: transformWaitingPeriods(lpItem.waitingPeriods),
      antimicrobial: lpItem.antimicrobial,
      validUntil: lpItem.validUntil
        ? moment(lpItem.validUntil).format("YYYY-MM-DD")
        : undefined,
    };

    prescriptionItemList.push(newLicensedPrescriptionItem);
  });

  return prescriptionItemList;
};

const transformCommodityPrescription = (
  cp: CommodityPrescriptionItemFrontend[],
  instructionLanguage: string | undefined
): PrescriptionItem[] => {
  const prescriptionItemList: PrescriptionItem[] = [];

  cp.forEach((cpItem: CommodityPrescriptionItemFrontend) => {
    const instruction: Instruction | undefined =
      generateTextFromIntervals(cpItem.dosageText).length > 0
        ? {
            text: generateTextFromIntervals(cpItem.dosageText),
          }
        : undefined;

    if (instructionLanguage && instruction) {
      instruction.language = instructionLanguage;
    }

    const newPrescriptionItem: PrescriptionItem = {
      latestRequestedTimeForDispensing: cpItem.latestRequestedTimeForDispensing
        ? moment(cpItem.latestRequestedTimeForDispensing).format(
            hardcodedStrings.dateFormat
          )
        : null,
      instructions: instruction,
      packages: transformPackages(cpItem.package, true),
      repeatDispensing: cpItem.repeatDispensing
        ? cpItem.repeatDispensing
        : { numberOfTimes: 1 },
      waitingPeriods: transformWaitingPeriods(cpItem.waitingPeriods),
    };

    prescriptionItemList.push(newPrescriptionItem);
  });

  return prescriptionItemList;
};

const transformPersistencePrescription = (
  p: PrescriptionItemFrontend[],
  instructionLanguage: string | undefined
): ExtendedPrescriptionItem[] => {
  const prescriptionItemList: ExtendedPrescriptionItem[] = [];

  p.forEach((pi: PrescriptionItemFrontend) => {
    const instruction: Instruction = {
      text: generateTextFromIntervals(pi.dosageText),
    };

    if (instructionLanguage) {
      instruction.language = instructionLanguage;
    }

    const product: ExtendedProduct = {
      type: pi.isVaraLicensed
        ? PrescriptionItemType.VARALICENSED
        : PrescriptionItemType.STANDARD,
      nplId: pi.product.nplId,
      name: pi.product.name,
      pharmaceuticalForm: pi.product.pharmaceuticalForm,
      strength: pi.product.strength,
      productType: pi.product.productType,
    };

    const newPrescriptionItem: ExtendedPrescriptionItem = {
      latestRequestedTimeForDispensing: pi.latestRequestedTimeForDispensing
        ? moment(pi.latestRequestedTimeForDispensing).format(
            hardcodedStrings.dateFormat
          )
        : null,
      product,
      instructions: instruction,
      packages: transformPersistencePackages(pi.package),
      repeatDispensing: pi.repeatDispensing
        ? pi.repeatDispensing
        : { numberOfTimes: 1 },
      waitingPeriods: transformWaitingPeriods(pi.waitingPeriods),
      antimicrobial: pi.antimicrobial,
      validUntil: pi.validUntil
        ? moment(pi.validUntil).format("YYYY-MM-DD")
        : undefined,
    };

    prescriptionItemList.push(newPrescriptionItem);
  });

  return prescriptionItemList;
};

const transformCommodityPersistencePrescription = (
  p: CommodityPrescriptionItemFrontend[],
  instructionLanguage: string | undefined
): ExtendedPrescriptionItem[] => {
  const prescriptionItemList: ExtendedPrescriptionItem[] = [];

  p.forEach((pc: CommodityPrescriptionItemFrontend) => {
    const instruction: Instruction = {
      text: generateTextFromIntervals(pc.dosageText),
    };

    if (instructionLanguage) {
      instruction.language = instructionLanguage;
    }

    const product: ExtendedProduct = {
      type: PrescriptionItemType.COMMODITY,
      name: pc.product.name,
      productType: pc.product.productType,
      description: pc.product.description,
      title: pc.product.title,
    };

    const newPrescriptionItem: ExtendedPrescriptionItem = {
      latestRequestedTimeForDispensing: pc.latestRequestedTimeForDispensing
        ? moment(pc.latestRequestedTimeForDispensing).format(
            hardcodedStrings.dateFormat
          )
        : null,
      product,
      instructions: instruction,
      packages: transformPersistencePackages(pc.package),
      repeatDispensing: pc.repeatDispensing
        ? pc.repeatDispensing
        : { numberOfTimes: 1 },
      waitingPeriods: transformWaitingPeriods(pc.waitingPeriods),
    };

    prescriptionItemList.push(newPrescriptionItem);
  });

  return prescriptionItemList;
};

const transformLicensedPersistencePrescription = (
  pl: LicensedPrescriptionItemFrontend[],
  instructionLanguage: string | undefined
): ExtendedPrescriptionItem[] => {
  const prescriptionItemList: ExtendedPrescriptionItem[] = [];

  pl.forEach((lpi: LicensedPrescriptionItemFrontend) => {
    const instruction: Instruction = {
      text: generateTextFromIntervals(lpi.dosageText),
    };

    if (instructionLanguage) {
      instruction.language = instructionLanguage;
    }

    const newPrescriptionItem: ExtendedPrescriptionItem = {
      latestRequestedTimeForDispensing: lpi.latestRequestedTimeForDispensing
        ? moment(lpi.latestRequestedTimeForDispensing).format(
            hardcodedStrings.dateFormat
          )
        : null,
      licensed: lpi.freeTextLicense
        ? {
            ...lpi.freeTextLicense,
            pharmacyId: isTestEnvironment
              ? hardcodedStrings.specificPharmacyTest2
              : lpi.freeTextLicense.pharmacyId,
          }
        : undefined,
      instructions: instruction,
      packages: lpi.package,
      repeatDispensing: lpi.repeatDispensing
        ? lpi.repeatDispensing
        : { numberOfTimes: 1 },
      waitingPeriods: transformWaitingPeriods(lpi.waitingPeriods),
      antimicrobial: lpi.antimicrobial,
      validUntil: lpi.validUntil
        ? moment(lpi.validUntil).format("YYYY-MM-DD")
        : undefined,
    };

    prescriptionItemList.push(newPrescriptionItem);
  });

  return prescriptionItemList;
};

const generatePrescription = (
  p: PrescriptionItemFrontend[] | undefined,
  pl: LicensedPrescriptionItemFrontend[] | undefined,
  pc: CommodityPrescriptionItemFrontend[] | undefined,
  comment: string | undefined,
  instructionLanguage: string | undefined,
  issueTime: string,
  uuid: string
): Prescription => {
  let prescriptionItemList: PrescriptionItem[] = [];

  if (p) {
    prescriptionItemList = prescriptionItemList.concat(
      transformPrescription(p, instructionLanguage)
    );
  }

  if (pl) {
    prescriptionItemList = prescriptionItemList.concat(
      transformLicensedPrescription(pl, instructionLanguage)
    );
  }

  if (pc) {
    prescriptionItemList = prescriptionItemList.concat(
      transformCommodityPrescription(pc, instructionLanguage)
    );
  }

  const prescription: Prescription = {
    uuid,
    issueTime,
    items: prescriptionItemList,
  };

  if (comment) {
    prescription.comment = comment;
  }

  return prescription;
};

const generatePersistenceModel = (
  p: PrescriptionItemFrontend[] | undefined,
  pl: LicensedPrescriptionItemFrontend[] | undefined,
  pc: CommodityPrescriptionItemFrontend[] | undefined,
  comment: string | undefined,
  instructionLanguage: string | undefined,
  animal: Animal,
  animalCarer: AnimalCarer,
  delivery: ExtendedDelivery | undefined,
  receiverPharmacy: Pharmacy | undefined,
  vet: UserResponse,
  selectedFeeType: FeeType,
  medicalRecordId: string | undefined,
  payment: Payment | undefined,
  interchangeUuid: string,
  messageUuid: string,
  organizationClinicId: string,
  issueTime: string,
  uuid: string,
  prescriptionFee: number
): PersistenceModel => {
  let prescriptionItemList: ExtendedPrescriptionItem[] = [];

  if (p) {
    prescriptionItemList = prescriptionItemList.concat(
      transformPersistencePrescription(p, instructionLanguage)
    );
  }
  if (pl) {
    prescriptionItemList = prescriptionItemList.concat(
      transformLicensedPersistencePrescription(pl, instructionLanguage)
    );
  }
  if (pc) {
    prescriptionItemList = prescriptionItemList.concat(
      transformCommodityPersistencePrescription(pc, instructionLanguage)
    );
  }

  const prescription: ExtendedPrescription = {
    uuid,
    issueTime,
    items: prescriptionItemList,
    comment,
  };

  const persistenceModel: PersistenceModel = {
    animal,
    animalCarer,
    delivery,
    prescription,
    payment,
    clinicId: organizationClinicId,
    varaVersion: hardcodedStrings.varaVersion,
    messageUuid,
    interchangeUuid,
    medicalRecordId,
    receiverPharmacy,
    prescriptionFee,
  };

  if (comment) {
    prescription.comment = comment;
  }

  return persistenceModel;
};

const createPayment = (
  clinicData: ClinicResponse | undefined,
  selectedFeeType: FeeType,
  prescriptionFee: number
): Payment | undefined => {
  if (selectedFeeType === FeeType.FEE) {
    let clinicPrescriptionFee: ClinicFeeResponse | undefined;

    if (clinicData?.fees) {
      clinicData.fees.forEach((fee: ClinicFeeResponse) => {
        if (fee.type === ClinicFeeType.PRESCRIPTION_FEE) {
          clinicPrescriptionFee = fee;
        }
      });
    }

    let plusgiroAccount;

    if (clinicData?.giroAccounts) {
      clinicData.giroAccounts.forEach((giroAccount: GiroAccountResponse) => {
        if (
          giroAccount.id === clinicPrescriptionFee?.giroAccountId &&
          giroAccount.type === GiroAccountType.PLUSGIROT
        ) {
          plusgiroAccount = giroAccount.account;
        }
      });
    }

    let bankgiroAccount;

    if (clinicData?.giroAccounts) {
      clinicData.giroAccounts.forEach((giroAccount: GiroAccountResponse) => {
        if (
          giroAccount.id === clinicPrescriptionFee?.giroAccountId &&
          giroAccount.type === GiroAccountType.BANKGIROT
        ) {
          bankgiroAccount = giroAccount.account;
        }
      });
    }

    if (plusgiroAccount || bankgiroAccount) {
      return {
        amountInSek: prescriptionFee,
        plusgiroAccount,
        bankgiroAccount,
      };
    }
    return undefined;
  }
  return undefined;
};

/* Creates the request object for prescription service */
export const generatePrescriptionRequest = (
  clinicId: string,
  prescription: PrescriptionItemFrontend[] | undefined,
  licensedPrescription: LicensedPrescriptionItemFrontend[] | undefined,
  commodityPrescription: CommodityPrescriptionItemFrontend[] | undefined,
  comment: string | undefined,
  instructionLanguage: string | undefined,
  animal: Animal,
  animalCarer: AnimalCarer,
  delivery: ExtendedDelivery | undefined,
  receiverPharmacy: Pharmacy | undefined,
  vet: UserResponse,
  selectedFeeType: FeeType,
  prescriptionFee: number,
  clinicData?: ClinicResponse,
  medicalRecordId?: string
): PrescriptionModel => {
  const prescriptionIssueTime = moment().format("YYYY-MM-DDTkk:mm");
  const prescriptionUuid = generateUUId();

  const formattedPrescription = generatePrescription(
    prescription,
    licensedPrescription,
    commodityPrescription,
    comment,
    instructionLanguage,
    prescriptionIssueTime,
    prescriptionUuid
  );

  const payment: Payment | undefined = createPayment(
    clinicData,
    selectedFeeType,
    prescriptionFee
  );

  const interchangeUuid = generateUUId();
  const messageUuid = generateUUId();

  let d: Delivery | undefined;

  if (delivery?.pharmacy) {
    d = {
      pharmacyId: isTestEnvironment
        ? specificPharmacyTest2
        : delivery.pharmacy.glnCode,
    };
  } else if (delivery) {
    d = {
      building: delivery.building,
      postalCode: delivery.postalCode,
      streetAddress: delivery.streetAddress,
      city: delivery.city,
    };
  }

  let receiverPharmacyId;

  if (receiverPharmacy) {
    if (isTestEnvironment) {
      receiverPharmacyId = hardcodedStrings.specificPharmacyTest1;
    } else {
      receiverPharmacyId = receiverPharmacy.glnCode;
    }
  } else if (isTestEnvironment) {
    receiverPharmacyId = hardcodedStrings.defaultPharmacyGLNTest;
  } else {
    receiverPharmacyId = hardcodedStrings.defaultPharmacyGLN;
  }

  const globalvetModel = generatePersistenceModel(
    prescription,
    licensedPrescription,
    commodityPrescription,
    comment,
    instructionLanguage,
    animal,
    animalCarer,
    delivery,
    receiverPharmacy,
    vet,
    selectedFeeType,
    medicalRecordId,
    payment,
    interchangeUuid,
    messageUuid,
    clinicId,
    prescriptionIssueTime,
    prescriptionUuid,
    prescriptionFee
  );

  return {
    globalvetModel,
    testFlag: isTestEnvironment ? TestFlag.TEST : TestFlag.PRODUCTION,
    clinicId,
    messageUuid,
    interchangeUuid,
    varaVersion: hardcodedStrings.varaVersion,
    animal,
    animalCarer,
    prescription: formattedPrescription,
    delivery: d,
    payment,
    receiverPharmacyId,
  };
};

export default {
  reverseFormatBirthDate,
  generateTextFromIntervals,
  transformWaitingPeriods,
  generateUUId,
  generatePrescriptionRequest,
};
