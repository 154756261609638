/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../../common/Strings/Strings";
import LoaderInline from "../../../../../components/LoaderInline";
import { useClinic } from "../../../../../contexts/ClinicContext";
import { getGeneralError } from "../../../../../util/helperFunctions";
import AlertBox from "../../../../../components/AlertBox";
import TreatmentApi from "../../../../../api/TreatmentApi";
import { TreatmentResponse } from "../../../../../models/treatment/TreatmentResponse";
import TreatmentResultRow from "./TreatmentResultRow";
import SelectSpecies from "../../../../../components/ReactHookFormFields/Specific/SelectSpecies";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { SearchTreatments } from "../../../../../models/apiparams/SearchTreatments";
import PageSizeSelect from "../../../../../components/PageSizeSelect";
import Paging from "../../../../../components/Paging";
import AddTreatmentWithItemsModal, {
  AddTreatmentForm,
} from "./AddTreatmentWithItemsModal";
import { CartItem } from "../../../../../util/InvoiceCartUtils";
import DelayedOnChangeInput from "../../../../../components/ReactHookFormFields/Specific/DelayedOnChangeInput";
import EmptyListText from "../../../../../components/EmptyListText";
import { CountryDetailsResponse } from "../../../../../models/management/CountryDetailsResponse";

interface Props {
  addCustomCost(): void;
  addToCart(item: CartItem): void;
  countryDetails?: CountryDetailsResponse;
}

const AddTreatments = ({
  addCustomCost,
  addToCart,
  countryDetails,
}: Props): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [treatments, setTreatments] = useState<TreatmentResponse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { control, register, getValues, setValue } = useForm<SearchTreatments>({
    defaultValues: { pageSize: 25 },
  });
  const [reloadTreatments, setReloadTreatments] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalTreatments, setTotalTreatments] = useState<number>(0);
  const [treatmentToAdd, setTreatmentToAdd] = useState<{
    treatment: TreatmentResponse;
    quantity: number;
  }>();
  const { clinic } = useClinic();

  const getTreatments = async () => {
    setLoading(true);
    if (clinic) {
      try {
        const request = getValues();
        const response = await TreatmentApi.getTreatmentsOfClinic(
          clinic.id,
          request
        );
        setTreatments(
          response.data.elements.sort((a, b) => a.name.localeCompare(b.name))
        );
        setTotalPages(response.data.totalPages);
        setTotalTreatments(response.data.totalElements);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getTreatments();

    /* eslint-disable-next-line */
  }, [clinic, reloadTreatments]);

  const triggerReload = () => {
    setReloadTreatments(!reloadTreatments);
  };

  const addTreatmentBasePrice = (
    { accountingCode, name, price, id, vat }: TreatmentResponse,
    quantity: number
  ) => {
    const newItem: CartItem = {
      name,
      price: price || 1,
      quantity,
      vat: vat || 0,
      accountingCode,
      localId: id?.toString(),
    };
    addToCart(newItem);
  };

  const chooseTreatment = (treatment: TreatmentResponse, quantity: number) => {
    if (treatment.usedItems?.length > 0 || treatment.fees?.length > 0) {
      setTreatmentToAdd({ treatment, quantity });
    } else {
      addTreatmentBasePrice(treatment, quantity);
    }
  };

  return (
    <>
      <div className="py-4">
        <form>
          <div className="w-full flex flex-nowrap md:flex-nowrap space-x-4">
            <div className="w-64">
              <div className="flex justify-between">
                <label htmlFor="search">{strings.search}</label>
                <div
                  aria-hidden
                  role="button"
                  className="text-sky-500"
                  onClick={() => {
                    addCustomCost();
                  }}
                  style={{ cursor: "pointer", fontSize: "12px" }}
                >
                  <b>{strings.addNew}</b>
                </div>
              </div>
              <DelayedOnChangeInput
                className="mb-0"
                labelOff
                name="name"
                placeholder={strings.treatmentName}
                register={register}
                onChange={() => {
                  setValue("pageNumber", 0);
                  triggerReload();
                }}
              />
              <Field
                className="hidden"
                name="pageSize"
                register={register}
                type="number"
              />
              <Field
                className="hidden"
                name="pageNumber"
                register={register}
                type="number"
              />
            </div>
            <SelectSpecies
              className="mb-0"
              control={control}
              onChange={() => {
                triggerReload();
                setValue("pageNumber", 0);
              }}
            />
          </div>
        </form>
      </div>
      <div>
        <AlertBox message={error} className="mb-3" />
        <div className="tw-table-container">
          <table className="tw-table">
            <thead className="tw-thead">
              <tr>
                <th className="tw-th">{strings.treatment}</th>
                <th className="tw-th">
                  {strings.price} ({strings.net})
                </th>
                <th className="tw-th">{strings.vat}</th>
                <th className="tw-th">
                  {strings.price} ({strings.gross})
                </th>
                <th className="tw-th">{strings.quantity}</th>
                <th className="tw-th" />
              </tr>
            </thead>
            <tbody className="tw-tbody">
              {loading ? (
                <tr>
                  <td
                    colSpan={6}
                    className="px-4 py-4 text-sm font-medium whitespace-nowrap"
                  >
                    <LoaderInline />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {!loading && treatments.length === 0 ? (
                <tr>
                  <td
                    colSpan={6}
                    className="px-4 py-4 text-sm font-medium whitespace-nowrap"
                  >
                    <EmptyListText />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {!loading &&
                treatments.map((result: TreatmentResponse, index: number) => (
                  <TreatmentResultRow
                    choose={chooseTreatment}
                    clinic={clinic || undefined}
                    countryDetails={countryDetails}
                    result={result}
                    index={index}
                    key={result.id}
                  />
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex py-3 flex-wrap">
          <div className="flex items-center">
            <PageSizeSelect
              pageSize={getValues("pageSize") || 25}
              setPageSize={(newPageSize: number) => {
                setValue("pageSize", newPageSize);
                triggerReload();
              }}
              totalPages={totalPages}
              totalResults={totalTreatments}
            />
          </div>
          <div className="ml-auto">
            <Paging
              currentPage={getValues("pageNumber") || 0}
              totalPages={totalPages}
              selectPage={(newPageNumber: number) => {
                setValue("pageNumber", newPageNumber);
                triggerReload();
              }}
            />
          </div>
        </div>
        <AddTreatmentWithItemsModal
          close={() => {
            setTreatmentToAdd(undefined);
          }}
          clinic={clinic || undefined}
          countryDetails={countryDetails}
          treatment={treatmentToAdd?.treatment}
          response={(values: AddTreatmentForm) => {
            const treatment = { ...treatmentToAdd };

            if (treatment?.treatment && treatment?.quantity) {
              addTreatmentBasePrice(treatment.treatment, treatment.quantity);
            }

            if (values.fees) {
              treatment?.treatment?.fees?.forEach((fee) => {
                addToCart({
                  accountingCode: fee.fee.accountingCode,
                  name: fee.fee.name,
                  price: fee.fee.price || 0,
                  quantity: fee.quantity,
                  vat: fee.fee.vat,
                  localId: fee.fee.id.toString(),
                });
              });
            }
            if (values.items) {
              treatment?.treatment?.usedItems?.forEach((item) => {
                addToCart({
                  accountingCode: item.item.accountingCode,
                  inventoryItemId: item.item.id,
                  localId: item.item.id.toString(),
                  name: item.item.name,
                  price: item.item.sellingPrice || 0,
                  quantity: item.quantity,
                  unit: item.item.acquisitionUnit || undefined,
                  vat: item.item.sellingVat || 0,
                });
              });
            }

            setTreatmentToAdd(undefined);
          }}
        />
      </div>
    </>
  );
};

export default AddTreatments;
