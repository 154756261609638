/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";

export const removeDiacritics = (s: string) =>
  s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const getHighlightedCharIndices = (str: string, query: string): number[] => {
  const m = removeDiacritics(str)
    .toLowerCase()
    .indexOf(removeDiacritics(query).toLowerCase());
  if (m < 0) return [];
  return [...Array(query.length).keys()].map(i => i + m);
};

interface HighlightedTextProps {
  text: string;
  highlights: string[];
}

export function HighlightedText({ text, highlights }: HighlightedTextProps) {
  const indices = new Set(
    highlights.flatMap(t => getHighlightedCharIndices(text, t))
  );
  const elements: ReactElement[] = [];
  let i = 0;
  while (i < text.length) {
    const rangeStart = i;
    let rangeEnd = i + 1;
    const isBold = indices.has(i);
    for (i += 1; i < text.length && indices.has(i) === isBold; i += 1) {
      rangeEnd += 1;
    }
    const range = text
      .substring(rangeStart, rangeEnd)
      .replaceAll(" ", "\u00a0");
    elements.push(
      isBold ? <b key={i}>{range}</b> : <span key={i}>{range}</span>
    );
  }

  return <>{elements}</>;
}
