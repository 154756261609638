/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Dropdown, Flowbite } from "flowbite-react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { NotificationResponse } from "../../../models/notification/NotificationResponse";
import NotificationList from "./NotificationList";
import NotificationsButton from "./NotificationsButton";
import { useUser } from "../../../contexts/UserContext";
import { isPosteriorRegistrationNotificationMutedForUser } from "./NotificationTypes/OwnerPosteriorRegistrationNotification";
import { NotificationType } from "../../../models/notification/NotificationType";
import { dropdownTheme } from "../Themes/DropdownTheme";
import { useNotification } from "../../../contexts/NotificationContext";

interface Props {
  visible?: boolean;
}

export const Notifications: React.FC<Props> = ({ visible }: Props) => {
  const { user } = useUser();
  const { notifications, fetchNotifications } = useNotification();

  const [shownNotifications, setShownNotifications] = useState<
    NotificationResponse[]
  >([]);

  const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");

  useEffect(() => {
    if (isPosteriorRegistrationNotificationMutedForUser(user.userId)) {
      setShownNotifications(
        notifications.filter(
          (i) => i.type !== NotificationType.LINKED_UNREGISTERED_PETS
        )
      );
    } else {
      setShownNotifications(notifications);
    }
  }, [notifications, user.userId]);

  const refreshNotifications = () => {
    void fetchNotifications();
  };

  if (!visible) {
    return null;
  }

  return (
    <Flowbite theme={{ theme: dropdownTheme, dark: darkMode }}>
      <Dropdown
        color={darkMode ? "dark" : "light"}
        dismissOnClick={false}
        label={
          <NotificationsButton notificationCount={shownNotifications.length} />
        }
        placement="bottom"
        size="lg"
      >
        <NotificationList
          refresh={refreshNotifications}
          notifications={shownNotifications}
        />
      </Dropdown>
    </Flowbite>
  );
};

export default Notifications;
