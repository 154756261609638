/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserClinicWithRolesResponse } from "../../../../models/clinic/UserClinicWithRolesResponse";
import { strings } from "../../../Strings/Strings";
import { CheckSimple } from "../../../Icons/CheckSimple";
import { useClinic } from "../../../../contexts/ClinicContext";
import { Cog } from "../../../Icons/Cog";
import { ChevronDown } from "../../../Icons/ChevronDown";
import ClickOutsideDetector from "../../../../components/ClickOutsideDetector";
import { Environments } from "../../../../models/Environments";
import EnvironmentBadge from "../../EnvironmentBadge";
import ClinicLogo from "../../../../components/Pictures/Clinic/ClinicLogo";

interface Props {
  environment: Environments;
}

const ClinicDropdown: React.FC<Props> = ({ environment }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { fetchClinic, clinics, clinic } = useClinic();
  const navigate = useNavigate();

  const handleClinicChoice = async (
    userClinic: UserClinicWithRolesResponse
  ) => {
    setIsOpen(false);
    await fetchClinic(userClinic.clinic.id);
    navigate("/");
  };

  return (
    <div className="flex items-center">
      <ClickOutsideDetector
        listen
        onClickOutside={() => {
          setIsOpen(false);
        }}
      >
        <div className="relative w-full xl:w-80">
          <button
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
            type="button"
            className="w-full xl:w-80 h-14 xl:px-4 flex justify-between items-center space-x-6 bg-gray-600 font-medium text-gray-100 hover:text-white focus:text-white xl:dark:border-l xl:dark:border-r dark:border-b dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:text-white dark:focus:text-white transition-all duration-300"
          >
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10">
                <ClinicLogo
                  border
                  borderWidth="2px"
                  clinicId={clinic?.id}
                  logoPictureId={clinic?.logoPictureId}
                />
              </div>
              <div className="flex flex-col items-start -space-y-0.5">
                {clinic?.name || strings.setActiveClinic}
              </div>
            </div>
            <ChevronDown className="hidden xl:flex w-5 h-5 flex-shrink-0" />
          </button>
          <div
            id="dropdownNavbarBranch"
            hidden={!isOpen}
            className="dropdown-navbar-branch z-30 shadow-md rounded-lg w-full xl:w-80 bg-white divide-y divide-gray-200 dark:border-l dark:border-r dark:border-b dark:border-gray-700 dark:bg-gray-800 dark:divide-gray-700"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              margin: "0px",
              transform: "translate(0px, 66px)",
            }}
          >
            <ul className="py-2 text-md text-zinc-800 dark:text-gray-100">
              <li>
                <div className="flex justify-between items-center px-4 py-2 dark:hover:text-white">
                  <span className="text-lg font-semibold">
                    {strings.clinics}
                  </span>
                  <Link className="tw-icon tw-link" to="/globalvet/clinics">
                    <span className="sr-only">{strings.settings}</span>
                    <Cog className="w-5 h-5" />
                  </Link>
                </div>
              </li>
              {clinics.map((userClinic: UserClinicWithRolesResponse) => (
                <li key={userClinic.clinic.id}>
                  <div
                    onClick={() => {
                      void handleClinicChoice(userClinic);
                    }}
                    role="button"
                    tabIndex={0}
                    key={userClinic.clinic.id}
                    className="active flex justify-between items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <div className="flex items-center space-x-3">
                      <div className="rounded-full border-2 border-gray-100 w-10 h-10 flex-shrink-0 bg-white bg-no-repeat bg-cover bg-center dark:border-gray-800 dark:bg-gray-700">
                        <ClinicLogo
                          borderWidth="2px"
                          clinicId={userClinic?.clinic.id}
                        />
                      </div>
                      <span>{userClinic.clinic.name}</span>
                    </div>
                    {clinic?.id === userClinic.clinic.id ? (
                      <CheckSimple className="hidden w-5 h-5" />
                    ) : (
                      <></>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            <ul className="py-2 text-md text-gray-700 dark:text-gray-400">
              <li>
                <Link
                  to="/clinics/create"
                  className="flex justify-between items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <div className="flex items-center space-x-3">
                    <span className="tw-link">+ {strings.createClinic}</span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </ClickOutsideDetector>
      <EnvironmentBadge
        className="hidden sm:flex ml-5"
        environment={environment}
      />
    </div>
  );
};

export default ClinicDropdown;
