/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

// https://gist.github.com/DiegoSalazar/4075533
function isIdValidWithLuhnAlgorithm(id: string): boolean {
  let nCheck = 0;
  let bEven = false;
  id = id.replace(/\D/g, "");

  for (let n = id.length - 1; n >= 0; n -= 1) {
    const cDigit = id.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven) {
      nDigit *= 2;
      if (nDigit > 9) {
        nDigit -= 9;
      }
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
}

export default function isSwedishOrganizationNumberValid(id: string): boolean {
  return (
    /^\d{6}-?\d{4}/.test(id) &&
    isIdValidWithLuhnAlgorithm(id.replace("-", "")) &&
    id.charAt(0) !== "4"
  );
}
