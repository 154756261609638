/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { FieldError } from "react-hook-form";
import { Tooltip } from "../Tooltip";
import { Colors } from "../../models/Colors";
import { strings } from "../../common/Strings/Strings";
import ClickOutsideDetector from "../ClickOutsideDetector";
import { arrowDownIcon } from "../../assets/AssetConfig";
import { InfoFilled } from "../../common/Icons/InfoFilled";

interface ColourSelectInputProps {
  error?: FieldError;
  name: string;
  onChange(e: string | null): void;
  value: string | null;
}

export interface ColourSelectComponentProps {
  classLabel?: string;
  className?: string;
  label?: string;
  labelIcon?: string;
  labelOff?: boolean;
  optional?: boolean;
  options?: string[];
  readonly?: boolean;
  showRequired?: boolean;
  toolTipText?: string | ReactElement;
  width?: string;
}

const ColourSelectComponent = ({
  options,
  classLabel,
  className,
  error,
  name: n,
  label,
  labelIcon,
  labelOff,
  onChange,
  optional,
  readonly,
  showRequired,
  toolTipText,
  value,
  width,
}: ColourSelectComponentProps & ColourSelectInputProps): ReactElement => {
  const [isDown, setIsDown] = useState<boolean>(false);

  return (
    <ClickOutsideDetector
      listen
      onClickOutside={() => {
        setIsDown(false);
      }}
    >
      <div className="dropdown" style={{ width }}>
        <div
          className={`${className || ""} ${error ? "has-error" : ""}`}
          style={{ width }}
        >
          {labelOff ? (
            <></>
          ) : (
            <label htmlFor={n} className={classLabel}>
              {labelIcon ? <i className={labelIcon} /> : ""} {label}
              {showRequired ? " *" : ""}
              {optional ? (
                <span style={{ color: Colors.INPUTBORDER }}>
                  {" "}
                  ({strings.optional})
                </span>
              ) : (
                <></>
              )}
            </label>
          )}
          <div
            className={`flex items-center w-full tw-input ${
              readonly ? "text-gray-400 dark:text-gray-400" : ""
            }`}
            role="button"
            tabIndex={0}
            onClick={() => {
              if (!readonly) {
                setIsDown((prev) => !prev);
              }
            }}
          >
            <div
              className="mr-2"
              style={{
                height: "20px",
                width: "20px",
                backgroundColor: value || Colors.PRIMARY,
                borderRadius: "2px",
              }}
            />
            <span className="align-middle">{value || Colors.PRIMARY}</span>
            <div className="ml-auto" role="button" tabIndex={0}>
              <img src={arrowDownIcon} alt="dropdown icon" width="15px" />
            </div>
            {toolTipText ? (
              <div className="ml-2">
                <Tooltip content={toolTipText} placement="top">
                  <span>
                    <InfoFilled className="text-sky-500" />
                  </span>
                </Tooltip>
              </div>
            ) : (
              <></>
            )}
          </div>
          <p>{error && error.message}</p>
        </div>
        <ul
          className={`dropdown-menu w-full ${isDown ? "show" : ""}`}
          style={{ overflowY: "auto", maxHeight: "300px", padding: 0 }}
        >
          {options?.map((item: string, index) => (
            <li
              className="dropdown-item"
              style={{ cursor: "pointer", padding: "6px 16px" }}
              onClick={() => {
                onChange(item);
                setIsDown(false);
              }}
              key={index}
            >
              <div className="flex items-center">
                <div
                  className="mr-2"
                  style={{
                    height: "20px",
                    width: "20px",
                    backgroundColor: item,
                    borderRadius: "2px",
                  }}
                />
                <div>{item}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </ClickOutsideDetector>
  );
};

export default ColourSelectComponent;
