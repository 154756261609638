/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Check } from "../../../../Icons/Check";
import {
  getEmailForUser,
  getProfilePictureIdForUser,
  LoggedInUser,
} from "../../../../../util/LocalStorageVariables";
import UserProfilePicture from "../../../../../components/Pictures/User/UserProfilePicture";

interface Props {
  isCurrentUser: boolean;
  selectUser(): void;
  user: LoggedInUser;
}

const ChangeUserModalRow: React.FC<Props> = ({
  isCurrentUser,
  selectUser,
  user,
}: Props) => (
  <li
    className={isCurrentUser ? "cursor-default" : "cursor-pointer"}
    key={user.userId}
    onClick={() => {
      if (isCurrentUser) return;
      selectUser();
    }}
  >
    <div className="active flex justify-between items-center px-5 py-3 hover:bg-gray-50 hover:text-gray-800 dark:hover:bg-gray-600 dark:hover:text-gray-300 transition-all duration-300">
      <div className="w-full flex items-center space-x-4">
        <div className="w-10 h-10">
          <UserProfilePicture
            border
            borderWidth="2px"
            userId={user.userId}
            pictureId={getProfilePictureIdForUser(user.userId)}
          />
        </div>
        <span>{user.name || getEmailForUser(user.userId) || user.userId}</span>
      </div>
      {isCurrentUser && <Check />}
    </div>
  </li>
);

export default ChangeUserModalRow;
