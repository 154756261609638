/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ModalHeader, { Props as ModalHeaderProps } from "./ModalHeader";
import ModalBody, { Props as ModalBodyProps } from "./ModalBody";
import ModalFooter, { Props as ModalFooterProps } from "./ModalFooter";
import ClickOutsideDetector from "../ClickOutsideDetector";
import { findChildByName, getElementHeight } from "../../util/HtmlUtils";
import { TailwindResponsiveBreakpoints } from "../../util/TailwindResponsiveBreakpoints";
import { useModal } from "../../contexts/ModalContext";

interface Props {
  children: ReactElement | ReactElement[];
  handleClose(): void;
  show: boolean;
  allowAutomaticClose?: boolean;
  allowAutomaticOpen?: boolean;
  closeButton?: boolean;
  closeOnClickOutside?: boolean;
  handleOpen?(): void;
  hideOnDesktop?: boolean;
  id?: string; // Used by the modal context to determine open modals in the application
  ignoreScrollbar?: boolean; // True if the modal should not change scrollbar visibility
  onShow?(): void;
  size?: "modal-sm" | "modal-md" | "modal-lg" | "modal-xl" | "";
}

type ModalChildrenTypes = {
  Header: React.FC<ModalHeaderProps>;
  Body: React.FC<ModalBodyProps>;
  Footer: React.FC<ModalFooterProps>;
};

type ModalComponent = React.FunctionComponent<Props> & ModalChildrenTypes;

const Modal: ModalComponent = ({
  children,
  handleClose,
  show,
  allowAutomaticClose = false,
  allowAutomaticOpen = false,
  closeButton = true,
  closeOnClickOutside = true,
  handleOpen,
  hideOnDesktop,
  id,
  ignoreScrollbar = false,
  onShow,
  size = "",
}: Props) => {
  const defaultId = useId();
  const modalId = id || defaultId;

  const { openModalIds, addModalId, removeModalId } = useModal();

  const [sizeClass, setSizeClass] = useState<string>("");

  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const [contentHeight, setContentHeight] = useState<number>(
    containerRef?.current?.clientHeight || 0
  );
  const [headerHeight, setHeaderHeight] = useState<number>(
    headerRef?.current?.clientHeight || 0
  );
  const [footerHeight, setFooterHeight] = useState<number>(
    footerRef?.current?.clientHeight || 0
  );

  const [bodyMaxHeight, setBodyMaxHeight] = useState<number>(0);

  const setHeights = useCallback(() => {
    containerRef && setContentHeight(getElementHeight(containerRef));
    headerRef && setHeaderHeight(getElementHeight(headerRef));
    footerRef && setFooterHeight(getElementHeight(footerRef));
  }, [containerRef, headerRef, footerRef]);

  const [onShowHappened, setOnShowHappened] = useState<boolean>(false);

  useEffect(() => {
    if (show && onShow && !onShowHappened) {
      onShow();
      setOnShowHappened(true);
    }
  }, [onShow, onShowHappened, show]);

  useEffect(() => {
    if (!show) {
      setOnShowHappened(false);
    }
  }, [show]);

  // Make sure the remove the modal from the context when calling the handleClose()
  const handleRemoveCheckedClose = useCallback(() => {
    removeModalId(modalId);
    handleClose();
  }, [handleClose, modalId, removeModalId]);

  const handleDesktopAndMobileTransition = useCallback(() => {
    if (!hideOnDesktop) return;

    const isMobile = window.innerWidth < TailwindResponsiveBreakpoints.xl;

    if (isMobile) {
      // Open the modal when window size becomes small
      allowAutomaticOpen && handleOpen && handleOpen();
    } else {
      // Close the modal when the window size becomes big
      allowAutomaticClose && handleRemoveCheckedClose();
    }
  }, [
    allowAutomaticClose,
    allowAutomaticOpen,
    handleRemoveCheckedClose,
    handleOpen,
    hideOnDesktop,
  ]);

  const handleResize = useCallback(() => {
    setHeights();
    handleDesktopAndMobileTransition();
  }, [handleDesktopAndMobileTransition, setHeights]);

  useLayoutEffect(() => {
    // Calculate height on initial render
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    switch (size) {
      case "modal-sm":
        setSizeClass("xl:max-w-[350px]");
        break;
      case "modal-md":
        setSizeClass("xl:max-w-[500px]");
        break;
      case "modal-lg":
        setSizeClass("xl:max-w-[800px]");
        break;
      case "modal-xl":
        setSizeClass("xl:max-w-[1140px]");
        break;
      default:
        setSizeClass("xl:max-w-md");
        break;
    }
  }, [size]);

  // Manage modal context
  useEffect(() => {
    if (show) {
      addModalId(modalId);
    } else {
      removeModalId(modalId);
    }

    // Make sure the remove the modal from the context when the modal itself is removed from the DOM without changing visibility
    return () => {
      removeModalId(modalId);
    };
  }, [addModalId, modalId, removeModalId, show]);

  // Manage body scrollbar visibility
  useEffect(() => {
    if (ignoreScrollbar) return undefined;

    const body = document.querySelector("body");
    if (!body) return undefined;

    // True if is the first opened modal (since more modals can be open at the same time)
    const isFirstModal = openModalIds.length === 1;

    // Hide scrollbar when modal is open
    if (show) {
      body.style.overflow = "hidden";
    }

    // Ensure scrollbar is shown only when the first modal component unmounts
    // When secondary, etc. modals are closed the scrollbar should still remain invisible
    return () => {
      if (isFirstModal) body.style.overflow = "auto";
    };
  }, [ignoreScrollbar, openModalIds, show]);

  // Set up the modal components
  const childrenArray = React.Children.toArray(children);

  const modalHeader = findChildByName(childrenArray, "ModalHeader") || null;
  const modalHeaderElement = modalHeader as ReactElement<ModalHeaderProps>;
  const modalHeaderWithProps = React.isValidElement(modalHeaderElement)
    ? React.cloneElement<ModalHeaderProps>(modalHeaderElement, {
        closeButton,
        handleClose: handleRemoveCheckedClose,
      })
    : null;

  const modalBody = findChildByName(childrenArray, "ModalBody") || null;

  const modalFooter = findChildByName(childrenArray, "ModalFooter") || null;
  const modalFooterElement = modalFooter as ReactElement<ModalFooterProps>;
  const modalFooterWithProps = React.isValidElement(modalFooterElement)
    ? React.cloneElement<ModalFooterProps>(modalFooterElement, {
        calculateModalHeights: setHeights,
      })
    : null;

  // Calculate the max height of the body (based on the header and footer height)
  useEffect(() => {
    setBodyMaxHeight(contentHeight - (footerHeight + headerHeight));
  }, [contentHeight, footerHeight, headerHeight]);

  if (!show) {
    return null;
  }

  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 z-50 w-full xl:p-8 overflow-x-hidden overflow-y-hidden xl:overflow-y-auto xl:inset-0 h-screen xl:h-full justify-center items-center flex"
        id="ignore-spacing"
        ref={containerRef}
      >
        <div
          className={`relative w-full h-full max-h-full xl:h-auto xl:max-h-auto ${sizeClass}`}
        >
          {/* Modal content */}
          <ClickOutsideDetector
            className="h-full max-h-full xl:h-auto xl:max-h-auto"
            listen
            onClickOutside={() => {
              closeOnClickOutside && handleRemoveCheckedClose();
            }}
          >
            <div className="w-full xl:w-auto h-full max-h-full xl:h-auto xl:max-h-auto xl:rounded-lg xl:shadow xl:border xl:border-gray-200 bg-white dark:bg-gray-800 xl:dark:border-gray-700">
              <div id="modal-header" ref={headerRef}>
                {modalHeaderWithProps}
              </div>
              <div
                className="h-auto overflow-y-auto"
                style={{
                  maxHeight: bodyMaxHeight || undefined,
                }}
                id="modal-body"
              >
                {modalBody}
              </div>
              <div id="modal-footer" ref={footerRef}>
                {modalFooterWithProps}
              </div>
            </div>
          </ClickOutsideDetector>
        </div>
      </div>
      {/* Modal backdrop */}
      <div
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
        id="ignore-spacing"
      />
    </>
  );
};

// Attach the Modal subcomponents as properties of the Modal component.
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
