/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import { PhoneConfirmationRequest } from "../../../../../models/contact/PhoneConfirmationRequest";
import { ApiPromise } from "../../../../../models/ApiPromise";
import { PhoneRequest } from "../../../../../models/contact/PhoneRequest";
import { ContactType } from "../../../../../models/contact/ContactType";
import AddPhoneModal, {
  NewPhoneForm,
} from "../../../../../components/Modals/Phone/AddPhoneModal";
import ResendPhoneConfirmationModal from "../../../../../components/Modals/Phone/ResendPhoneConfirmationModal";
import ConfirmPhoneModal from "../../../../../components/Modals/Phone/ConfirmPhoneModal";
import PromotePhoneModal from "../../../../../components/Modals/Phone/PromotePhoneModal";
import { PhoneResponse } from "../../../../../models/contact/PhoneResponse";
import UnconfirmedPhoneTag from "./UnconfirmedPhoneTag";
import { Pencil } from "../../../../../common/Icons/Pencil";
import { Trash } from "../../../../../common/Icons/Trash";
import Button from "../../../../../components/Button";
import DeletePhoneModal from "./DeletePhoneModal";
import { PhoneType } from "../../../../../models/contact/PhoneType";

type ContactOwner = ClinicResponse;

interface Props {
  owner: ContactOwner;
  addPhone: (ownerId: string, email: PhoneRequest) => ApiPromise<ContactOwner>;
  removePhone: (
    ownerId: string,
    ownershipId: string
  ) => ApiPromise<ContactOwner>;
  promotePhone: (
    ownerId: string,
    emailOwnershipId: string
  ) => ApiPromise<ClinicResponse>;
  confirmPhoneInstantly: (
    ownerId: string,
    ownershipId: string,
    req: PhoneConfirmationRequest
  ) => ApiPromise<ClinicResponse>;
  resendConfirmation: (
    ownerId: string,
    ownershipId: string
  ) => ApiPromise<void>;
  defaultCountryCode?: string;
  readOnly: boolean;
}

const PhoneOwnershipList: React.FC<Props> = ({
  owner,
  addPhone,
  removePhone,
  promotePhone,
  confirmPhoneInstantly,
  resendConfirmation,
  defaultCountryCode,
  readOnly,
}: Props) => {
  const [phones, setPhones] = useState<PhoneResponse[]>(
    owner.phones ? owner.phones : []
  );

  const [phoneToConfirm, setPhoneToConfirm] = useState<PhoneResponse | null>(
    null
  );
  const [phoneToPromote, setPhoneToPromote] = useState<PhoneResponse | null>(
    null
  );
  const [phoneToDelete, setPhoneToDelete] = useState<PhoneResponse>();

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showResendConfirmationModal, setShowResendConfirmationModal] =
    useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showPromoteModal, setShowPromoteModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [resendConfirmationLoading, setResendConfirmationLoading] =
    useState<boolean>(false);
  const [confirmationLoading, setConfirmationLoading] =
    useState<boolean>(false);
  const [additionLoading, setAdditionLoading] = useState<boolean>(false);
  const [promotionLoading, setPromotionLoading] = useState<boolean>(false);

  const [confirmationError, setConfirmationError] = useState<string | null>(
    null
  );
  const [resendConfirmationError, setResendConfirmationError] = useState<
    string | null
  >(null);
  const [additionError, setAdditionError] = useState<string | null>(null);
  const [promotionError, setPromotionError] = useState<string | null>(null);

  const openConfirmationModal = (): void => {
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = (): void => {
    setShowConfirmationModal(false);
    setPhoneToConfirm(null);
    setConfirmationError(null);
  };

  const openResendConfirmationModal = (phone: PhoneResponse) => {
    if (readOnly) return;
    setPhoneToConfirm(phone);
    setShowResendConfirmationModal(true);
  };

  const closeResendConfirmationModal = () => {
    setShowResendConfirmationModal(false);
    setResendConfirmationError(null);
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    setAdditionError(null);
  };

  const openPromoteModal = (phone: PhoneResponse): void => {
    if (phone.contactType === ContactType.PRIMARY) {
      return;
    }

    setPhoneToPromote(phone);
    setShowPromoteModal(true);
  };

  const closePromoteModal = () => {
    setShowPromoteModal(false);
    setPromotionError(null);
  };

  const confirmPhone = async (smsCode: string) => {
    if (phoneToConfirm === null) {
      return;
    }

    setConfirmationLoading(true);
    setConfirmationError(null);

    try {
      await confirmPhoneInstantly(owner.id, phoneToConfirm.id, {
        smsCode,
      });
      setPhones(
        phones.map((p) => {
          if (p.id === phoneToConfirm.id) {
            p.confirmed = true;
          }
          return p;
        })
      );
      closeConfirmationModal();
    } catch (err) {
      setConfirmationError(await getGeneralError(err));
    } finally {
      setConfirmationLoading(false);
    }
  };

  const resendPhoneConfirmationCode = async () => {
    if (!phoneToConfirm?.id) {
      return;
    }

    setResendConfirmationLoading(true);
    setConfirmationError(null);

    try {
      await resendConfirmation(owner.id, phoneToConfirm.id);
      closeResendConfirmationModal();
      openConfirmationModal();
    } catch (err) {
      setResendConfirmationError(await getGeneralError(err));
    } finally {
      setResendConfirmationLoading(false);
    }
  };

  const addNewPhoneNumber = async ({ phone, phoneType }: NewPhoneForm) => {
    setAdditionLoading(true);

    const oldPhones: PhoneResponse[] = [...phones];

    try {
      const response = await addPhone(owner.id, { value: phone, phoneType });
      const newPhones = response.data.phones;

      const newPhone = newPhones.find(
        (p: PhoneResponse) =>
          oldPhones.find((old) => old.id === p.id) === undefined
      );

      if (newPhone !== undefined && newPhone.phoneType === PhoneType.MOBILE) {
        setPhoneToConfirm(newPhone);
        setShowConfirmationModal(true);
      }

      setPhones(newPhones);
      closeAddModal();
      setAdditionError(null);
    } catch (err) {
      setAdditionError(await getGeneralError(err));
    } finally {
      setAdditionLoading(false);
    }
  };

  const promotePhoneNumber = async () => {
    if (
      phoneToPromote === null ||
      phoneToPromote.contactType === ContactType.PRIMARY
    ) {
      return;
    }

    setPromotionLoading(true);
    setPromotionError(null);

    try {
      await promotePhone(owner.id, phoneToPromote.id);
      setPhones((prevState) => {
        const prevPrimary = prevState.find(
          (o) => o.contactType === ContactType.PRIMARY
        );
        const newPrimary = prevState.find((o) => o.id === phoneToPromote.id);
        if (prevPrimary !== undefined) {
          prevPrimary.contactType = ContactType.PRIVATE;
        }
        if (newPrimary !== undefined) {
          newPrimary.contactType = ContactType.PRIMARY;
        }
        return prevState;
      });
      closePromoteModal();
    } catch (err) {
      setPromotionError(await getGeneralError(err));
    } finally {
      setPromotionLoading(false);
    }
  };

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 font-bold">
          <p>{strings.phoneNumber.toUpperCase()}</p>
        </div>
        {phones.map((phone, index) => (
          <div
            className="p-4 flex justify-between items-center space-x-4"
            key={index}
          >
            <div>
              <div className="flex flex-wrap items-center">
                <p>{phone.value}</p>
                <UnconfirmedPhoneTag
                  phone={phone}
                  onClick={() => openResendConfirmationModal(phone)}
                />
                <button
                  className={`tw-btn-pill-tercier-small ${
                    phone.confirmed ? "mx-3" : ""
                  }`}
                  disabled
                  hidden={phone.contactType !== ContactType.PRIMARY}
                  type="button"
                >
                  {strings.primary}
                </button>
              </div>
            </div>
            <div className="flex">
              <Button
                aria-label={strings.edit}
                hidden={phone.contactType === ContactType.PRIMARY || readOnly}
                onClick={() => {
                  openPromoteModal(phone);
                }}
                variant="icon"
              >
                <span className="sr-only">{strings.edit}</span>
                <Pencil />
              </Button>
              <Button
                aria-label={strings.delete}
                hidden={readOnly}
                className="tw-link ml-3"
                onClick={() => {
                  setShowDeleteModal(true);
                  setPhoneToDelete(phone);
                }}
                showTextWhileLoading={false}
                variant="icon"
              >
                <span className="sr-only">{strings.delete}</span>
                <Trash />
              </Button>
            </div>
          </div>
        ))}
        <div className="p-4" hidden={readOnly}>
          <Button
            aria-label={strings.addEmail}
            onClick={openAddModal}
            variant="link"
          >
            <span className="sr-only">{strings.addEmail}</span>+{" "}
            {strings.addPhoneNumber}
          </Button>
        </div>
      </div>
      <ConfirmPhoneModal
        close={closeConfirmationModal}
        error={confirmationError}
        isOpen={showConfirmationModal}
        loading={confirmationLoading}
        onSubmit={confirmPhone}
        onResendCode={resendPhoneConfirmationCode}
        phoneToConfirm={phoneToConfirm}
      />
      <ResendPhoneConfirmationModal
        close={closeResendConfirmationModal}
        error={resendConfirmationError}
        isOpen={showResendConfirmationModal}
        loading={resendConfirmationLoading}
        onSubmit={resendPhoneConfirmationCode}
      />
      <AddPhoneModal
        close={closeAddModal}
        defaultCountryCode={defaultCountryCode}
        error={additionError}
        isOpen={showAddModal}
        loading={additionLoading}
        onSubmit={addNewPhoneNumber}
      />
      <PromotePhoneModal
        close={closePromoteModal}
        error={promotionError}
        isOpen={showPromoteModal}
        loading={promotionLoading}
        onSubmit={promotePhoneNumber}
        phoneNumber={phoneToPromote?.value}
      />
      <DeletePhoneModal
        phone={phoneToDelete}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        owner={owner}
        removePhone={removePhone}
        setPhones={(previousState) => setPhones(previousState)}
        show={showDeleteModal}
      />
    </>
  );
};

export default PhoneOwnershipList;
