/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import EmailConfirmationInfoBase from "../../../../components/contact/confirmation/EmailConfirmationInfoBase";
import UserApi from "../../../../api/UserApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import { useInterval } from "../../../../hooks/useInterval";

interface Props {
  email: string;
  onNext(): void;
}

// Interval in milliseconds to poll the server if the email is confirmed
const interval = 1000;

const VerifyEmail: React.FC<Props> = ({ email, onNext }: Props) => {
  const [resendSuccessful, setResendSuccessful] = useState<boolean>(false);

  const [isPolling, setIsPolling] = useState<boolean>(true);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleStop = () => {
    setIsPolling(false);
  };

  const sendConfirmationMail = async () => {
    setLoading(true);
    setResendSuccessful(false);
    setError(null);

    try {
      await UserApi.sendRegistrationConfirmationEmail(email);
      setResendSuccessful(true);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  const checkEmailConfirmed = async (emailToConfirm: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await UserApi.checkUserEmailConfirmed(emailToConfirm);
      const { confirmed } = response.data;
      if (confirmed) {
        handleStop();
        onNext();
      }
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  useInterval(
    () => {
      void checkEmailConfirmed(email);
    },
    // Interval in milliseconds or null to stop it
    isPolling ? interval : null
  );

  return (
    <EmailConfirmationInfoBase
      email={email}
      error={error}
      loading={loading}
      resend={sendConfirmationMail}
      success={resendSuccessful}
    />
  );
};

export default VerifyEmail;
