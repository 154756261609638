/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../../common/Strings/Strings";
import UserApi from "../../../../../api/UserApi";
import { getGeneralError } from "../../../../../util/helperFunctions";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import { passwordRegexp } from "../../../../../util/Validations";
import Button from "../../../../../components/Button";
import { Card } from "../../../../../components/Cards/Card";

interface ChangePasswordForm {
  password: string;
  passwordAgain: string;
}

const defaultFormValues = {
  password: "",
  passwordAgain: "",
};

const ChangePassword: React.FC = () => {
  const [alertMessage, setAlertMessage] = useState<string | null>();
  const [alertType, setAlertType] = useState<AlertType>(AlertType.ERROR);
  const [loading, setLoading] = useState<boolean>(false);

  const changePassword = async (formData: ChangePasswordForm) => {
    setLoading(true);
    try {
      await UserApi.changePassword(formData);
      setAlertMessage(strings.passwordChangedSuccessfully);
      setAlertType(AlertType.SUCCESS);
    } catch (e) {
      setAlertMessage(await getGeneralError(e));
      setAlertType(AlertType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ChangePasswordForm>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  return (
    <Card size="md" type="simple" title={strings.changePassword}>
      <form className="space-y-6" onSubmit={handleSubmit(changePassword)}>
        <Field
          name="password"
          label={strings.newPassword}
          type="password"
          register={register}
          autoComplete={AutoCompleteOptions.newPassword}
          fieldOptions={{
            required: true,
            pattern: {
              value: passwordRegexp,
              message: strings.passwordValid,
            },
          }}
          error={errors.password}
        />
        <Field
          name="passwordAgain"
          label={strings.newPasswordAgain}
          type="password"
          register={register}
          autoComplete={AutoCompleteOptions.newPassword}
          fieldOptions={{
            required: true,
            validate: {
              passwordAgainIsSameAsPassword: (v) =>
                v === getValues("password") || strings.confirmValid,
            },
          }}
          error={errors.passwordAgain}
        />
        <AlertBox message={alertMessage} type={alertType} />
        <Button disabled={!isValid} loading={loading} type="submit">
          {strings.changeMyPassword}
        </Button>
      </form>
    </Card>
  );
};

export default ChangePassword;
