/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { useNavigate } from "react-router-dom";
import React from "react";
import { PetOwnerMenuItem } from "../../MenuItem/MenuItemTypes";
import { NavMenuLinkClasses } from "../../Themes/NavTheme";
import { strings } from "../../../Strings/Strings";

interface Props {
  href: string;
  menu: PetOwnerMenuItem;
  activeMenu?: PetOwnerMenuItem;
}

const PetOwnerNavItem: React.FC<Props> = ({
  activeMenu,
  href,
  menu,
}: Props) => {
  const navigate = useNavigate();

  const activeMenuClass = NavMenuLinkClasses.normal.active;
  const simpleMenuClass = NavMenuLinkClasses.normal.simple;

  const isActive = menu === activeMenu;
  const menuClass = isActive ? activeMenuClass : simpleMenuClass;

  return (
    <button
      className={`${menuClass}${isActive ? " tw-menu-active" : ""}`}
      onClick={() => navigate(href)}
      type="button"
    >
      {strings[menu]}
    </button>
  );
};

export default PetOwnerNavItem;
