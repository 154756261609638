/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import { WeekCollaborator } from "./WeekController";
import Button from "../../../components/Button";
import SubstitutionModal from "../Modals/SubstitutionModal/SubstitutionModal";
import DeleteMultipleShiftsModal from "../Modals/SubstitutionModal/DeleteMultipleShiftsModal";

interface Props {
  collaborators: WeekCollaborator[];
  checkedCollaborators(weekCollabList: WeekCollaborator[]): void;
  weekStartDay?: Date;
  weekEndDay?: Date;
}

const WeekCollaboratorChecks: React.FC<Props> = ({
  collaborators,
  checkedCollaborators,
  weekEndDay,
  weekStartDay,
}: Props) => {
  const { getValues, register } = useForm();
  const [collaboratorToSubstitute, setCollaboratorToSubstitute] =
    useState<WeekCollaborator>();
  const [deleteMultipleShiftsModalOpen, setDeleteMultipleShiftsModalOpen] =
    useState<boolean>(false);

  const onChange = (id: string): void => {
    const values = getValues();
    checkedCollaborators(
      collaborators.map((c: WeekCollaborator) => {
        if (c.userId === id) {
          return { ...c, checked: values[id.toString()] };
        }
        return c;
      })
    );
  };

  return (
    <div className="mb-3">
      <div className="flex">
        {collaborators.length === 0 ? (
          <AlertBox
            message={strings.noShiftsOnThisWeek}
            type={AlertType.INFO}
            closeAble={false}
          />
        ) : (
          <form className="flex flex-wrap">
            {collaborators.map((item, index) => (
              <div className="pb-1 pt-1 pl-0 pr-4" key={`collabs-${index}`}>
                <CheckBox
                  defaultChecked
                  label={item.fullName}
                  name={item.userId.toString()}
                  onChange={() => {
                    onChange(item.userId);
                  }}
                  register={register}
                />
              </div>
            ))}
          </form>
        )}
        <Button
          className="tw-link ml-auto"
          onClick={() => {
            setDeleteMultipleShiftsModalOpen(true);
          }}
          variant="link"
        >
          {strings.deleteShifts}
        </Button>
      </div>
      {collaborators
        .filter((i) => i.deleted)
        .map((collaborator) => (
          <AlertBox
            className="mt-3"
            message={
              <>
                <div>
                  <b>{collaborator.fullName}</b>
                  {strings.deletedItsProfile}
                </div>
                <div>
                  <Button
                    className="tw-link-red"
                    onClick={() => setCollaboratorToSubstitute(collaborator)}
                    variant="link"
                  >
                    {strings.substitutionTitle}
                  </Button>
                </div>
              </>
            }
          />
        ))}
      <SubstitutionModal
        open={collaboratorToSubstitute !== undefined}
        close={() => setCollaboratorToSubstitute(undefined)}
        employee={collaboratorToSubstitute}
        employeeType={collaboratorToSubstitute?.employmentType}
        reload={() => window.location.reload()}
        deleteEmployee
      />
      <DeleteMultipleShiftsModal
        close={() => {
          setDeleteMultipleShiftsModalOpen(false);
        }}
        end={weekEndDay}
        open={deleteMultipleShiftsModalOpen}
        reload={() => window.location.reload()}
        start={weekStartDay}
      />
    </div>
  );
};

export default WeekCollaboratorChecks;
