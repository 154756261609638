/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { PetSpecies } from "../../../models/pet/PetSpeciesEnum";
import {
  HematologyParams,
  HematologyUnits,
  HematologyReferenceRanges,
} from "../../../common/ExaminationReferences/Hematology";
import { ExclamationMark } from "../../../common/Icons/ExclamationMark";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import SimpleInput from "../../../components/InputFieldsSimple/SimpleInput";

interface Props {
  index: number;
  species: PetSpecies;
  updateResults(value: number, index: number): void;
}

const HematologyRow: React.FC<Props> = ({
  index,
  species,
  updateResults,
}: Props) => {
  const [value, setValue] = useState<number>();
  const concreteSpecies: string = PetSpecies[species];
  const rangeBeginning = HematologyReferenceRanges[concreteSpecies]
    ? HematologyReferenceRanges[concreteSpecies][index].start
    : null;
  const rangeEnding = HematologyReferenceRanges[concreteSpecies]
    ? HematologyReferenceRanges[concreteSpecies][index].end
    : null;

  useEffect(() => {
    if (value) {
      updateResults(value, index);
    }
  }, [index, updateResults, value]);

  const ranges = (): string => {
    if (rangeBeginning !== null) {
      if (rangeEnding !== null) {
        return `${rangeBeginning  } - ${  rangeEnding}`;
      }
    }
    return "";
  };

  const resultIcon = () => {
    if (
      value === undefined ||
      (rangeBeginning === null && rangeEnding === null)
    ) {
      return <></>;
    }
    if (
      ((rangeBeginning && value >= rangeBeginning) || !rangeBeginning) &&
      rangeEnding &&
      value <= rangeEnding
    ) {
      return <CheckCircle className="h-6 w-6 text-green-500" />;
    } 
      return <ExclamationMark className="h-6 w-6 text-red-500" />;
    
  };

  return (
    <tr>
      <td className="px-2 py-1 text-sm font-medium">
        {HematologyParams[index]}
      </td>
      <td className="px-2 py-1 text-sm font-medium whitespace-nowrap">
        {HematologyUnits[index]}
      </td>
      <td className="px-2 py-1 text-sm font-medium whitespace-nowrap">
        {ranges()}
      </td>
      <td className="px-2 py-1 text-sm font-medium whitespace-nowrap">
        <SimpleInput
          labelOff
          name={`biochemistry-${index}`}
          onChange={(v) => {
            if (v) {
              setValue(parseFloat(v));
            } else {
              setValue(undefined);
            }
          }}
          type="number"
        />
      </td>
      <td className="px-2 py-1 text-sm font-medium whitespace-nowrap">
        {resultIcon()}
      </td>
    </tr>
  );
};

export default HematologyRow;
