/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { CountrySettingsResponse } from "../../../models/management/CountrySettingsResponse";
import CountryApi from "../../../api/CountryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { positiveOrZeroTwoDecimal } from "../../../util/Validations";
import { CountryUpdateRequest } from "../../../models/management/CountryUpdateRequest";
import Switch from "../../../components/ReactHookFormFields/General/Switch";
import Button from "../../../components/Button";
import PricesSection from "./PricesSection";

interface Props {
  isActiveTab: boolean;
  countryCode: string;
}

const defaultFormValues = (
  country?: CountrySettingsResponse,
  globalvetEnabled?: boolean
) => {
  const defaultValues: CountryUpdateRequest = {
    globalvetEnabled: globalvetEnabled || false,
  };

  defaultValues.minimalChargeValue = country?.minimalChargeValue;
  defaultValues.trialDiscountPercent = country?.trialDiscountPercent;
  defaultValues.trialPeriodDays = country?.trialPeriodDays;
  defaultValues.trialEndReminderBeforeDays =
    country?.trialEndReminderBeforeDays;

  return defaultValues;
};

const FeeSection = ({ isActiveTab, countryCode }: Props): ReactElement => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [globalvetEnabled, setGlobalvetEnabled] = useState(false);
  const [country, setCountry] = useState<CountrySettingsResponse>();
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.ERROR);

  useEffect(() => {
    if (!isActiveTab) return;

    const getCountrySettings = async () => {
      try {
        const resp = await CountryApi.getCountrySettingsByCode(countryCode);
        setGlobalvetEnabled(resp.data?.globalvetEnabled);
        setCountry(resp.data);
        setIsLoaded(true);
      } catch (err) {
        setError(await getGeneralError(err));
      }
    };

    void getCountrySettings();
  }, [countryCode, isActiveTab]);

  useEffect(() => {
    const getCountryDetails = async () => {
      try {
        const response = await CountryApi.getCountryDetails(countryCode);
        setCountryDetails(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      }
    };

    void getCountryDetails();
  }, [countryCode]);

  const submitFees = async (request: CountryUpdateRequest) => {
    if (!country) return;

    setAlertMessage(null);
    setLoading(true);

    try {
      const response = await CountryApi.updateCountry(country.id, request);
      setCountry(response.data);
      setGlobalvetEnabled(response.data.globalvetEnabled);
      setAlertMessage(strings.savedSuccessfully);
      setAlertType(AlertType.SUCCESS);
    } catch (err) {
      setAlertMessage(await getGeneralError(err));
      setAlertType(AlertType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<CountryUpdateRequest>({
    mode: "onChange",
    defaultValues: defaultFormValues(country),
  });

  useEffect(() => {
    reset(defaultFormValues(country, globalvetEnabled));
  }, [country, globalvetEnabled, reset]);

  return (
    <>
      {!isLoaded ? (
        <LoaderInline className="my-3" />
      ) : (
        <div className="container frame">
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2">
              <AlertBox message={error} className="my-3" />
            </div>
          </div>
          {country && countryDetails && (
            <>
              <div className="row">
                <div className="col-sm-12 col-md-8 offset-md-2">
                  <div className="card">
                    <div className="block-label">
                      <div className="h5 m-3">{strings.settings}</div>
                    </div>
                    <form>
                      <div className="card-body">
                        <Row className="mb-3">
                          <Col className="d-flex justify-content-between">
                            {strings.serviceEnabledForCountry}
                            <div className="col-6 m-0 p-0">
                              <Switch
                                name="globalvetEnabled"
                                className="float-right"
                                control={control}
                                value={globalvetEnabled}
                              />
                            </div>
                          </Col>
                        </Row>

                        <div className="hr-on-top pt-3">
                          <Field
                            name="minimalChargeValue"
                            label={strings.minimalChargeValue}
                            fieldOptions={{
                              pattern: {
                                value: positiveOrZeroTwoDecimal.matchRegexp,
                                message: strings.notAllowedCharacter,
                              },
                            }}
                            register={register}
                            autoComplete={AutoCompleteOptions.off}
                            suffix={countryDetails?.currency}
                            error={errors.minimalChargeValue}
                          />
                        </div>
                        <Row>
                          <Col>
                            <Field
                              name="trialDiscountPercent"
                              label={strings.trialDiscountPercent}
                              placeholder=""
                              register={register}
                              autoComplete={AutoCompleteOptions.off}
                              suffix="%"
                              error={errors.trialDiscountPercent}
                            />
                          </Col>
                          <Col>
                            <Field
                              name="trialPeriodDays"
                              label={strings.trialPeriodDays}
                              placeholder=""
                              register={register}
                              autoComplete={AutoCompleteOptions.off}
                              suffix={strings.days}
                              error={errors.trialPeriodDays}
                            />
                          </Col>
                          <Col>
                            <Field
                              name="trialEndReminderBeforeDays"
                              label={strings.trialEndReminderBeforeDays}
                              placeholder=""
                              type="number"
                              register={register}
                              autoComplete={AutoCompleteOptions.off}
                              suffix={strings.daysBefore}
                              error={errors.trialEndReminderBeforeDays}
                            />
                          </Col>
                        </Row>

                        <AlertBox
                          className="mt-3"
                          message={alertMessage}
                          type={alertType}
                        />
                      </div>
                      <div className="hr-on-top p-3 d-flex">
                        <Button

                          className="ml-auto"
                          disabled={!isValid || !isDirty || loading}
                        loading={loading}
                          onClick={handleSubmit(submitFees)}
                        variant="primary"
                          >
                            {strings.save
                          }
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <PricesSection
                isActiveTab={isActiveTab}
                country={country}
                countryDetails={countryDetails}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default FeeSection;
