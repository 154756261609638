/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { strings } from "../common/Strings/Strings";
import { RoundingStrategy } from "../models/clinic/RoundingStrategy";

export default class NumberFormatter {
  static roundToTwoDecimal(number: number): number {
    return this.round(number, 2);
  }

  static round(number: number, decimalPrecision: number): number {
    const precision = 10 ** decimalPrecision;
    return (
      Math.round((Number(number) + Number.EPSILON) * precision) / precision
    );
  }

  static roundToNearestHalf(number: number): number {
    return this.round(number * 2, 0) / 2;
  }

  static roundBy(number: number, strategy: RoundingStrategy): number {
    switch (strategy) {
      case RoundingStrategy.INTEGER:
        return this.round(number, 0);
      case RoundingStrategy.TWO_DECIMAL:
        return this.round(number, 2);
      case RoundingStrategy.NEAREST_HALF:
        return this.roundToNearestHalf(number);
    }
  }

  static format(number: number): string {
    return this.roundToTwoDecimal(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${strings.thousandsSeparator}`);
  }

  static formatPrice(price: number, currency?: string | null): string {
    return currency ? `${this.format(price)} ${currency}` : this.format(price);
  }
}
