/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { VideoChatRoomUsers } from "./VideoChatRoomUsers";

export type VideoChatRoom = {
  users: VideoChatRoomUsers;
  isEndingCall: boolean;
};

/**
 * Represents a mapping of room IDs to their corresponding users.
 * This type uses a plain object where each key is a room ID (string) and the value is a
 * corresponding set of users.
 */
export type VideoChatRooms = { [key: string]: VideoChatRoom };

/**
 * Represents a mapping of room IDs to their corresponding users.
 * This type uses the Javascript Map object where the keys are room IDs (strings) and the
 * values are the corresponding sets of users.
 */
export type VideoChatRoomsMap = Map<string, VideoChatRoom>;

// Converts a VideoChatRooms type to a VideoChatRoomsMap type
export const convertObjectToMap = (
  rooms: VideoChatRooms
): VideoChatRoomsMap => {
  const map = new Map<string, VideoChatRoom>();

  Object.keys(rooms).forEach((key: string) => {
    map.set(key, rooms[key]);
  });

  return map;
};
