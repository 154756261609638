/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import {
  getAddressString,
  getGeneralError,
} from "../../../../../util/helperFunctions";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import { ContactType } from "../../../../../models/contact/ContactType";
import { ApiPromise } from "../../../../../models/ApiPromise";
import { LocatedAddressRequest } from "../../../../../models/contact/LocatedAddressRequest";
import AlertBox from "../../../../../components/AlertBox";
import { AddressResponse } from "../../../../../models/contact/AddressResponse";
import AddNewLocatedAddressModal from "../../../../../components/Modals/Address/AddNewLocatedAddressModal";
import PromoteAddressModal from "./PromoteAddressModal";
import { Pencil } from "../../../../../common/Icons/Pencil";
import { Trash } from "../../../../../common/Icons/Trash";
import Button from "../../../../../components/Button";
import DeleteAddressModal from "./DeleteAddressModal";

export type ContactOwner = ClinicResponse;

interface Props {
  owner: ContactOwner;
  addAddress: (
    ownerId: string,
    email: LocatedAddressRequest
  ) => ApiPromise<ContactOwner>;
  removeAddress: (
    ownerId: string,
    ownershipId: string
  ) => ApiPromise<ContactOwner>;
  promoteAddress: (
    ownerId: string,
    emailOwnershipId: string
  ) => ApiPromise<ClinicResponse>;
  readOnly: boolean;
}

const AddressOwnershipList: React.FC<Props> = ({
  owner,
  addAddress,
  removeAddress,
  promoteAddress,
  readOnly,
}: Props) => {
  const [addresses, setAddresses] = useState<AddressResponse[]>(
    owner.addresses || []
  );

  const [addressToPromote, setAddressToPromote] =
    useState<AddressResponse | null>(null);
  const [addressToDelete, setAddressToDelete] = useState<AddressResponse>();

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showPromoteModal, setShowPromoteModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openPromoteModal = (address: AddressResponse) => {
    if (address.contactType === ContactType.PRIMARY) {
      return;
    }

    setAddressToPromote(address);
    setShowPromoteModal(true);
  };

  const closePromoteModal = () => {
    setShowPromoteModal(false);
  };

  const addNewAddress = async (address: LocatedAddressRequest) => {
    try {
      const response = await addAddress(owner.id, address);
      setAddresses(response.data.addresses);
      closeAddModal();
      setError(null);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  };

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 font-bold">
          <p>{strings.address.toUpperCase()}</p>
        </div>
        {addresses.map((address, index) => (
          <div
            className="p-4 flex justify-between items-center space-x-4"
            key={index}
          >
            <div>
              <div className="flex flex-wrap items-center">
                <p className="mr-3">{getAddressString(address)}</p>
                <button
                  className="tw-btn-pill-tercier-small"
                  disabled
                  hidden={address.contactType !== ContactType.PRIMARY}
                  type="button"
                >
                  {strings.primary}
                </button>
              </div>
            </div>
            <div className="flex">
              <Button
                aria-label={strings.edit}
                hidden={address.contactType === ContactType.PRIMARY || readOnly}
                onClick={() => {
                  openPromoteModal(address);
                }}
                variant="icon"
              >
                <span className="sr-only">{strings.edit}</span>
                <Pencil />
              </Button>
              <Button
                aria-label={strings.delete}
                hidden={readOnly}
                className="tw-link ml-3"
                onClick={() => {
                  setShowDeleteModal(true);
                  setAddressToDelete(address);
                }}
                showTextWhileLoading={false}
                variant="icon"
              >
                <span className="sr-only">{strings.delete}</span>
                <Trash />
              </Button>
            </div>
          </div>
        ))}
        <div className="p-4" hidden={readOnly}>
          <Button
            aria-label={strings.addEmail}
            onClick={openAddModal}
            variant="link"
          >
            <span className="sr-only">{strings.addEmail}</span>+{" "}
            {strings.addAddress}
          </Button>
        </div>
        <AlertBox message={error} className="m-3" />
      </div>
      <AddNewLocatedAddressModal
        close={closeAddModal}
        isOpen={showAddModal}
        onSubmit={addNewAddress}
      />
      <PromoteAddressModal
        address={addressToPromote}
        onHide={closePromoteModal}
        owner={owner}
        promoteAddress={promoteAddress}
        setAddresses={(previousState) => setAddresses(previousState)}
        show={showPromoteModal}
      />
      <DeleteAddressModal
        address={addressToDelete}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        owner={owner}
        removeAddress={removeAddress}
        setAddresses={(previousState) => setAddresses(previousState)}
        show={showDeleteModal}
      />
    </>
  );
};

export default AddressOwnershipList;
