/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { strings } from "../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../util/helperFunctions";
import { ReservationResponse } from "../../../../models/reservation/ReservationResponse";
import { CountryResponse } from "../../../../models/management/CountryResponse";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import ReservationApi from "../../../../api/ReservationApi";
import CountryApi from "../../../../api/CountryApi";
import logger from "../../../../util/logger";
import { ChronicDiseaseOption } from "../../../../common/ChronicDiseases/ChronicDiseases";
import { useClinic } from "../../../../contexts/ClinicContext";
import { defaultCountryCodeOfClinic } from "../../../../common/DefaultCountry";
import PetOwnerApi from "../../../../api/PetOwnerApi";
import { NewPetAndOwnerRequest } from "../../../../models/pet/NewPetAndOwnerRequest";
import { PetFormProps } from "../../../Pet/CreateNewPet/PetForm";
import { PetOwnerFullForm } from "../CreatePetOwner/CreatePetOwner";
import LoaderInline from "../../../../components/LoaderInline";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import CheckBox from "../../../../components/ReactHookFormFields/General/CheckBox";
import Button from "../../../../components/Button";
import CreatePet from "./CreatePet";
import CreatePetOwner from "./CreatePetOwner";

interface FinishPetAndOwnerForm extends PetFormProps, PetOwnerFullForm {
  userInformedAboutGdpr: boolean;
}

const CreatePetAndOwner: React.FC = () => {
  const { clinic } = useClinic();
  const [countries, setCountries] = useState<CountryResponse[]>([]);

  const defaultCountryCode = defaultCountryCodeOfClinic(clinic);

  const defaultCountry = countries.find(
    (c) => c.countryCode === defaultCountryCode
  );

  const petOwnerFormMethods = useForm<FinishPetAndOwnerForm>({
    mode: "onChange",
    defaultValues: {
      country: defaultCountry ? [defaultCountry] : [],
    },
  });
  const {
    handleSubmit,
    trigger,
    reset,
    register,
    formState: { errors },
  } = petOwnerFormMethods;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const { resId } = useParams<"resId">();
  const navigate = useNavigate();

  useEffect(() => {
    const loadCountries = async () => {
      try {
        const resp = await CountryApi.getCountries();
        setCountries(resp.data);
      } catch (e) {
        logger.error(e);
      }
    };

    void loadCountries();
  }, []);

  useEffect(() => {
    const fillFormFromReservation = (r: ReservationResponse) => {
      reset({
        prefix: r.petOwner.prefix,
        firstName: r.petOwner.firstName,
        middleName: r.petOwner.middleName,
        lastName: r.petOwner.lastName,
        mobilePhone: r.petOwner.phones[0].value,
        name: r.petOfNewOwner?.name,
        country: defaultCountry ? [defaultCountry] : [],
      });

      void trigger();
    };

    const getReservation = async (id: string) => {
      try {
        const r = await ReservationApi.getReservation(id);
        if (r) {
          fillFormFromReservation(r.data);
        }
      } catch (err) {
        setErrorMessage(await getGeneralError(err));
        logger.error(err);
      }
    };

    if (resId) {
      void getReservation(resId);
    }
  }, [resId, countries, defaultCountry, trigger, reset]);

  const submit = async (f: FinishPetAndOwnerForm) => {
    const {
      breed,
      chipNumber,
      chronicDiseases,
      color,
      comment,
      gender,
      dateOfBirth,
      deceased,
      insuranceCompany,
      insuranceNumber,
      name,
      passportNumber,
      species,
      tattooNumber,
    } = f;
    const {
      city,
      country,
      email,
      firstName,
      houseDetails,
      lastName,
      middleName,
      mobilePhone,
      ownerComment,
      ownerDateOfBirth,
      personalId,
      prefix,
      street,
      zip,
    } = f;

    const requestBody: NewPetAndOwnerRequest = {
      pet: {
        breed: breed[0].name,
        chipNumber,
        chronicDiseases:
          chronicDiseases?.map((disease: ChronicDiseaseOption) => disease.id) ||
          [],
        color,
        comment,
        dateOfBirth,
        deceased,
        gender,
        insuranceCompanyId:
          insuranceCompany?.length === 1 ? insuranceCompany[0].id : undefined,
        insuranceNumber,
        name,
        passportNumber,
        species: species[0].value,
        tattooNumber,
      },
      petOwner: {
        address: {
          countryCode: country[0].countryCode,
          city,
          zip,
          street,
          houseDetails: houseDetails || undefined,
        },
        comment: ownerComment,
        dateOfBirth: moment(ownerDateOfBirth).format("YYYY-MM-DD"),
        email: email?.length === 0 ? undefined : email,
        firstName,
        lastName,
        middleName,
        mobilePhone,
        personalId,
        prefix,
      },
    };

    setLoadingSubmit(true);

    if (resId) {
      try {
        await PetOwnerApi.createPetOwnerForReservation(resId, requestBody);
        navigate(`/reservation/${resId}/medical-record/`, { replace: true });
      } catch (err) {
        setErrorMessage(await getGeneralError(err));
        logger.error(err);
      } finally {
        setLoadingSubmit(false);
      }
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col items-center justify-center space-y-6 md:max-w-md mx-auto">
            <h1 className="flex items-center text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.fillMissingData}
            </h1>
            <AlertBox
              message={strings.fillPetDataBeforeMedicalRecordMsg}
              hidden={!resId}
              type={AlertType.INFO}
            />
            <FormProvider {...petOwnerFormMethods}>
              <form className="space-y-4" onSubmit={handleSubmit(submit)}>
                <CreatePetOwner countryOptions={countries}/>
                <CreatePet />
                <div>
                  <CheckBox
                    name="userInformedAboutGdpr"
                    register={register}
                    autoComplete={AutoCompleteOptions.off}
                    error={errors.userInformedAboutGdpr}
                    label={strings.iHaveInformedOwnerAboutPolicy}
                    required
                  />
                </div>
                <AlertBox message={errorMessage} />
                <div className="flex">
                  {loadingSubmit ? (
                    <LoaderInline />
                  ) : (
                    <Button type="submit">
                      {strings.proceedToMedicalRecord}
                    </Button>
                  )}
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CreatePetAndOwner;
