/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import ReservationApi from "../../api/ReservationApi";
import logger from "../../util/logger";
import { ReservationDeletionRequest } from "../../models/reservation/ReservationDeletionRequest";
import CloseButton from "../CloseButton";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../util/LocalStorageVariables";
import { getGeneralError } from "../../util/helperFunctions";
import AlertBox from "../AlertBox";
import Textarea from "../ReactHookFormFields/General/Textarea";
import Modal from "../Modal/Modal";
import Button from "../Button";

interface Props {
  cancellation: boolean;
  onHide: () => void;
  reload?(): void;
  reservationId: string;
  show: boolean;
}

export default function DeleteReservation({
  cancellation,
  onHide,
  reload,
  reservationId,
  show,
}: Props): ReactElement {
  const role: UserRole | null = getValueOfActiveUser("role");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<{ explanation: string }>();

  const submit = async ({ explanation }: { explanation: string }) => {
    const del = cancellation ? true : window.confirm(strings.areYouSure);

    if (del) {
      setLoading(true);
      const request: ReservationDeletionRequest = {
        explanation,
        employeeRequest: role === "doctor",
      };

      try {
        await ReservationApi.deleteReservation(reservationId, request);
        onHide();
        if (reload) {
          reload();
        }
      } catch (e) {
        setError(await getGeneralError(e));
        logger.error("Cannot delete reservation.", e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header
        title={
          cancellation ? strings.cancelAppointment : strings.rejectAppointment
        }
      />
      <Modal.Body>
        <form>
          {cancellation ? (
            strings.AreYouSureDeleteAppointment
          ) : (
            <Textarea
              error={errors.explanation}
              label={strings.whyDoYouWantToRejectAppointment}
              name="explanation"
              register={register}
              required
            />
          )}
        </form>
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading}
          loading={loading}
          variant="danger"
          onClick={handleSubmit(submit)}
        >
          {cancellation ? strings.confirm : strings.reject}
        </Button>
        <CloseButton onClick={onHide} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
}
