/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import Button from "../../../../components/Button";
import { CheckCircle } from "../../../../common/Icons/CheckCircle";
import { Pencil } from "../../../../common/Icons/Pencil";
import ClinicPetOwnerForm1 from "../CreatePetOwner/ClinicPetOwnerForm1";
import ClinicPetOwnerForm2 from "../CreatePetOwner/ClinicPetOwnerForm2";
import { CountryResponse } from "../../../../models/management/CountryResponse";

interface Props {
  countryOptions: CountryResponse[];
}

const CreatePetOwner: React.FC<Props> = ({countryOptions}) => {
  const [stepPetOwner, setStepPetOwner] = useState<number>(1);
  const { trigger } = useFormContext();

  return (
    <div className="w-full md:max-w-md md:tw-card space-y-6 md:p-8">
      <div className="flex justify-between space-x-4">
        <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
          {strings.petOwner}
        </h1>
        <span className="text-gray-500 dark:text-gray-400">
          {stepPetOwner < 3 ? (
            `${stepPetOwner  }/2`
          ) : (
            <div className="flex space-x-2">
              <CheckCircle className="text-green-500" />
              <Button
                variant="icon"
                onClick={() => {
                  setStepPetOwner(1);
                }}
              >
                <Pencil />
              </Button>
            </div>
          )}
        </span>
      </div>
      <div className="space-y-4" hidden={stepPetOwner === 3}>
        <div hidden={!(stepPetOwner === 1)}>
          <ClinicPetOwnerForm1 countryOptions={countryOptions}/>
        </div>
        <div hidden={!(stepPetOwner === 2)}>
          <ClinicPetOwnerForm2 />
        </div>
        <Button
          className="w-full"
          onClick={() => {
            if (stepPetOwner === 1) {
              const response = trigger([
                "firstName",
                "lastName",
                "prefix",
                "middleName",
                "city",
                "zip",
                "country",
                "street",
                "houseDetails",
              ]);

              response.then((isValid: boolean) => {
                if (isValid) {
                  setStepPetOwner(2);
                }
              });
            } else if (stepPetOwner === 2) {
              const response = trigger([
                "ownerComment",
                "ownerDateOfBirth",
                "personalId",
                "email",
                "mobilePhone",
              ]);

              response.then((isValid: boolean) => {
                if (isValid) {
                  setStepPetOwner(3);
                }
              });
            }
          }}
          variant="primary-outline"
        >
          {strings.saveAndContinue}
        </Button>
        <div hidden={stepPetOwner === 1}>
          <Button
            variant="link"
            onClick={() => {
              setStepPetOwner(1);
            }}
          >
            {strings.back}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreatePetOwner;
