/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { PageProps } from "../../../models/PageProps";
import { getGeneralError } from "../../../util/helperFunctions";
import PetOwnerApi from "../../../api/PetOwnerApi";
import { Loader } from "../../../components/Loader";
import { PersonDetailsResponse } from "../../../models/contact/PersonDetailsResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { strings } from "../../../common/Strings/Strings";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import { UpdatePetOwnerRequest } from "../../../models/pet/UpdatePetOwnerRequest";
import PetOwnerPhones from "../../../components/contact/petOwner/PetOwnerPhones";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import PetOwnerEmails from "../../../components/contact/petOwner/PetOwnerEmails";
import PetOwnerAddresses from "../../../components/contact/petOwner/PetOwnerAddresses";
import GeneralUserInfoForm, { GeneralUserForm } from "./CreatePetOwner/GeneralUserInfoForm";
import { ContactType } from "../../../models/contact/ContactType";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import { Card } from "../../../components/Cards/Card";
import Tabs from "../../../components/Tabs/Tabs";
import Button from "../../../components/Button";

enum EditPetOwnerTabs {
  basicInfo = "basic",
  contacts = "contacts",
}

const EditPetOwner: React.FC<PageProps> = ({ setPageLoading }: PageProps) => {
  const [petOwner, setPetOwner] = useState<PetOwnerResponse>();
  const [petOwnerDetails, setPetOwnerDetails] =
    useState<PersonDetailsResponse>();
  const [userId, setUserId] = useState<string>();
  const [updateResponse, setUpdateResponse] = useState<{
    message: string | null;
    type: AlertType;
  } | null>(null);
  const [isUpdateSubmitting, setIsUpdateSubmitting] = useState<boolean>(false);

  const [key, setKey] = useState<EditPetOwnerTabs>(EditPetOwnerTabs.basicInfo);

  const petOwnerFormMethods = useForm<GeneralUserForm>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty },
  } = petOwnerFormMethods;
  const { petOwnerId } = useParams<"petOwnerId">();
  const navigate = useNavigate();

  const fillForm = (
    {
      dateOfBirth,
      firstName,
      lastName,
      middleName,
      personalId,
      prefix,
    }: PersonDetailsResponse,
    comment?: string
  ) => {
    reset({
      comment,
      ownerDateOfBirth: dateOfBirth ? new Date(dateOfBirth) : undefined,
      firstName,
      lastName,
      middleName,
      personalId,
      prefix,
    });
  };

  useEffect(() => {
    const fetchPetOwner = async (id: string) => {
      try {
        const resp = await PetOwnerApi.getPetOwner(id);
        fillForm(
          resp.data.petOwner.petOwnerDetails,
          resp.data.petOwner.comment
        );
        setPetOwnerDetails(resp.data.petOwner.petOwnerDetails);
        setUserId(resp.data.petOwner?.userDetails?.id);
        setPetOwner(resp.data.petOwner);
      } catch (e) {
        setUpdateResponse({
          message: await getGeneralError(e),
          type: AlertType.ERROR,
        });
      } finally {
        setPageLoading(false);
      }
    };

    if (petOwnerId) {
      void fetchPetOwner(petOwnerId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petOwnerId]);

  const updateBasicInfos = async ({
    comment,
    firstName,
    lastName,
    middleName,
    ownerDateOfBirth,
    personalId,
    prefix,
  }: GeneralUserForm) => {
    if (petOwnerDetails?.id) {
      try {
        setUpdateResponse(null);
        setIsUpdateSubmitting(true);
        const request: UpdatePetOwnerRequest = {
          comment: comment || "",
          dateOfBirth: ownerDateOfBirth ? moment(ownerDateOfBirth).format("YYYY-MM-DD") : undefined,
          firstName,
          lastName,
          middleName:
            middleName && middleName?.length > 0 ? middleName : undefined,
          personalId,
          prefix: prefix && prefix?.length > 0 ? prefix : undefined,
        };
        await PetOwnerApi.updatePetOwner(petOwnerDetails.id, request);
        setUpdateResponse({
          message: strings.savedSuccessfully,
          type: AlertType.SUCCESS,
        });
      } catch (e) {
        setUpdateResponse({
          message: await getGeneralError(e),
          type: AlertType.ERROR,
        });
      } finally {
        setIsUpdateSubmitting(false);
      }
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
            {strings.formatString(
              strings.editProfile,
              petOwner?.userDetails?.fullName || ""
            )}
          </h1>

          <Tabs
            tabs={[
              { title: strings.basicInfo, tab: EditPetOwnerTabs.basicInfo },
              {
                title: strings.contacts,
                tab: EditPetOwnerTabs.contacts,
              },
            ]}
            currentTabKey={key}
            setTab={setKey}
          />

          <div className="flex flex-col justify-center items-center space-y-6">
            {key === EditPetOwnerTabs.basicInfo && (
              <Card
                actionText={strings.back}
                onAction={() => navigate(-1)}
                size="lg"
                title={strings.basicInfo}
                type="simple"
              >
                <div className="w-full flex flex-col justify-center items-center">
                  <div style={{ width: "100px", height: "100px" }}>
                    <UserProfilePicture border userId={userId} />
                  </div>
                  <p className="mt-6 text-xl">{petOwnerDetails?.fullName}</p>
                </div>
                <FormProvider {...petOwnerFormMethods}>
                  <form
                    className="space-y-6"
                    onSubmit={handleSubmit(updateBasicInfos)}
                  >
                    <GeneralUserInfoForm
                      primaryAddressCountryCode={
                        petOwner?.addresses.find(
                          (a) => a.contactType === ContactType.PRIMARY
                        )?.countryCode
                      }
                    />
                    <Textarea
                      name="comment"
                      minRows={2}
                      register={register}
                      label={strings.comment}
                      toolTipText={strings.theOwnerWillNotSee}
                    />
                    <AlertBox
                      message={updateResponse?.message}
                      type={updateResponse?.type}
                    />
                    <div className="flex flex-wrap gap-x-4 gap-y-6 md:flex-nowrap justify-between pt-6">
                      <Button
                        disabled={!isDirty}
                        fullWidth
                        onClick={() => reset()}
                        variant="secondary"
                      >
                        {strings.discardChanges}
                      </Button>
                      <Button
                        fullWidth
                        loading={isUpdateSubmitting}
                        type="submit"
                      >
                        {strings.save}
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              </Card>
            )}

            {key === EditPetOwnerTabs.contacts && (
              <Card size="lg" title={strings.contacts} type="simple">
                <PetOwnerEmails owner={petOwner} />
                <PetOwnerPhones owner={petOwner} />
                <PetOwnerAddresses owner={petOwner} />
              </Card>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(EditPetOwner);
