/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../AlertBox";
import Button from "../../Button";
import Modal from "../../Modal/Modal";

interface Props {
  close(): void;
  error: string | null;
  isOpen: boolean;
  loading: boolean;
  onSubmit(): void;
  phoneNumber: string | undefined;
}

const PromotePhoneModal: React.FC<Props> = ({
  close,
  isOpen,
  loading,
  phoneNumber,
  onSubmit,
  error,
}: Props) => (
  <Modal handleClose={close} show={isOpen}>
    <Modal.Header title={strings.setPrimaryPhone} />
    <Modal.Body>
      {strings.primaryPhonePromotionTextPart1}
      <b>{phoneNumber}</b>
      {strings.primaryPhonePromotionTextPart2}
      <AlertBox message={error} />
    </Modal.Body>
    <Modal.Footer>
      <Button
        className="modal-main-button"
        disabled={loading}
        fullWidth={false}
        loading={loading}
        onClick={onSubmit}
      >
        {strings.setAsPrimary}
      </Button>
      <Button
        className="modal-sub-button"
        disabled={loading}
        onClick={close}
        variant="secondary"
      >
        {strings.cancel}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default PromotePhoneModal;
