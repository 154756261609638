/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useParams } from "react-router-dom";
import { PageProps } from "../../models/PageProps";
import { Loader } from "../../components/Loader";
import { useSearchParams } from "../../hooks/hooks";
import { generateState } from "../../util/OAuthUtils";
import AlertBox, { AlertType } from "../../components/AlertBox";
import { strings } from "../../common/Strings/Strings";
import { getGeneralError } from "../../util/helperFunctions";
import { FortnoxIntegrationRequest } from "../../models/clinic/FortnoxIntegrationRequest";
import BillingSystemConfigApi from "../../api/BillingSystemConfigApi";
import FortnoxApi from "../../api/FortnoxApi";

type PageParams = {
  clinicId?: string;
  countryCode?: string;
};

const FortnoxAuthPage = ({ setPageLoading }: PageProps) => {
  const { clinicId, countryCode } = useParams<PageParams>();
  const searchParams = useSearchParams();
  const [error, setError] = useState<string | null>();
  const [success, setSuccess] = useState<boolean>(false);

  const authorize = async () => {
    try {
      let clientId: string;

      if (clinicId) {
        const response = await FortnoxApi.getClientIdByClinicId(clinicId);
        clientId = response.data;
      } else if (countryCode) {
        const response = await FortnoxApi.getClientIdByCountryCode(countryCode);
        clientId = response.data;
      } else {
        return;
      }

      const stateCode = generateState();
      sessionStorage.setItem("fortnoxState", stateCode);

      const request = {
        client_id: clientId,
        response_type: "code",
        state: stateCode,
        scope:
          "bookkeeping settings invoice payment customer companyinformation",
        redirect_uri: window.location.href,
        access_type: "offline",
      };

      const params = qs.stringify(request);
      const url = `https://apps.fortnox.se/oauth-v1/auth?${params}`;
      window.location.replace(url);
    } catch (e) {
      setError(await getGeneralError(e));
      setSuccess(false);
      setPageLoading(false);
    }
  };

  const sendAuthorizationCode = async (code: string) => {
    try {
      setPageLoading(true);
      const request: FortnoxIntegrationRequest = {
        enabling: true,
        authCode: code,
        redirectUri: window.location.href.split("?")[0],
      };

      if (clinicId) {
        await FortnoxApi.enableFortnox(clinicId, request);
      } else if (countryCode) {
        await BillingSystemConfigApi.addFortnoxIntegration(
          countryCode,
          request
        );
      }

      setSuccess(true);
    } catch (e) {
      setError(await getGeneralError(e));
      setSuccess(false);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    setSuccess(false);
    if (!searchParams.code && !searchParams.error && !searchParams.state) {
      void authorize();
      return;
    }

    setPageLoading(false);

    if (searchParams.state !== sessionStorage.getItem("fortnoxState")) {
      setError("Invalid request");
      return;
    }

    if (searchParams.code) {
      void sendAuthorizationCode(searchParams.code);
    } else {
      setError(searchParams.error_description || strings.simpleError);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="main-signed-in">
      <section>
        <div className="mx-auto flex flex-col items-center justify-center space-y-6 px-4 py-10 lg:px-0 lg:py-14">
          <div className="w-full space-x-0 space-y-10 lg:w-auto lg:space-x-6 lg:space-y-0">
            <div className="w-full md:max-w-md md:tw-card md:p-8 space-y-4">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.fortnoxIntegration}
              </h1>
              <AlertBox message={error} closeAble={false} />
              <AlertBox
                hidden={!success}
                message={strings.fortnoxIntegrationSuccess}
                type={AlertType.SUCCESS}
                closeAble={false}
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(FortnoxAuthPage);
