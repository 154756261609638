/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { findLocale, strings } from "../../../common/Strings/Strings";
import Params from "../../../common/Params";
import AlertBox from "../../../components/AlertBox";
import RecoveryApi from "../../../api/RecoveryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import PhoneNumber from "../../../components/ReactHookFormFields/Specific/PhoneNumber";
import Button from "../../../components/Button";

interface Props {
  setCaptcha: (captcha: ReCAPTCHA | null) => void;
  resetCaptcha: () => void;
  captchaValid: boolean;
  recaptchaToken: string | null;
  onCaptchaChange: (captchaToken: string | null) => void;
  setSmsCodeId: (smsCodeId: string) => void;
  setSmsInfoMessage: (message: string) => void;
}

interface SmsRecoveryForm {
  mobilePhone: string;
}

const defaultFormValues = {
  mobilePhone: "",
};

const SmsRecovery: React.FC<Props> = ({
  setCaptcha,
  resetCaptcha,
  captchaValid,
  recaptchaToken,
  onCaptchaChange,
  setSmsCodeId,
  setSmsInfoMessage,
}: Props) => {
  const [error, setError] = useState<string | ReactElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const submitForm = async (formData: SmsRecoveryForm) => {
    setLoading(true);
    try {
      const resp = await RecoveryApi.requireMobileRecovery(
        { mobilePhone: formData.mobilePhone },
        recaptchaToken
      );
      setSmsCodeId(resp.data.smsCodeId);
      setSmsInfoMessage(formData.mobilePhone);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
      resetCaptcha();
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<SmsRecoveryForm>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  return (
    <form className="space-y-6" onSubmit={handleSubmit(submitForm)}>
      <PhoneNumber
        id="mobilePhone"
        name="mobilePhone"
        label={strings.phone}
        control={control}
        autocomplete={AutoCompleteOptions.telNational}
        required
      />
      {Params.captcha === "on" && (
        <ReCAPTCHA
          ref={(e) => {
            setCaptcha(e);
          }}
          hl={findLocale()}
          sitekey={Params.recaptchaSiteKey}
          size="normal"
          onChange={onCaptchaChange}
        />
      )}
      <AlertBox closeAble={false} message={error} />
      <Button
        disabled={
          !isDirty ||
          !isValid ||
          loading ||
          (!captchaValid && Params.captcha === "on")
        }
        loading={loading}
        type="submit"
      >
        {strings.sendRecoverySms}
      </Button>
    </form>
  );
};

export default SmsRecovery;
