/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import ReservationApi from "../../../api/ReservationApi";
import { CancellableReservationResponse } from "../../../models/reservation/CancellableReservationResponse";
import logger from "../../../util/logger";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import LoaderInline from "../../../components/LoaderInline";
import AppointmentRow from "./Rows/AppointmentRow";
import MedicalRecordApi from "../../../api/MedicalRecordApi";
import { MedicalRecordResponse } from "../../../models/medical/MedicalRecordResponse";
import MedicalRecordRow from "./Rows/MedicalRecordRow";
import { FreeTextMedicalRecordResponse } from "../../../models/medical/FreeTextMedicalRecordResponse";
import { useClinic } from "../../../contexts/ClinicContext";
import ExternalMedicalRecordRow from "./Rows/ExternalMedicalRecordRow";
import PrescriptionApi from "../../../api/PrescriptionApi";
import { NewPrescriptionStatus } from "../../../models/ePrescription/response/NewPrescriptionStatus";
import { HistoryData } from "../../../models/ePrescription/history/HistoryData";
import PrescriptionRow from "./Rows/PrescriptionRow";
import { strings } from "../../../common/Strings/Strings";
import HistoryActions from "./HistoryActions";
import { ClinicPetResponse } from "../../../models/pet/ClinicPetResponse";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import { generateUUId } from "../../ePrescription/sections/finish/sendingRequestProcesser";
import SendInEmailModal from "./SendInEmailModal";
import Button from "../../../components/Button";
import EmptyListText from "../../../components/EmptyListText";
import Filters from "../../../components/ReactHookFormFields/General/Filters";
import { useForm } from "react-hook-form";

interface Props {
  limitResults?: number;
  owner?: PetOwnerResponse;
  petId?: string;
  pet?: ClinicPetResponse;
}

export enum RowType {
  MedicalRecord = "MedicalRecord",
  Reservation = "Reservation",
  External = "External",
  Prescription = "Prescription",
}

export interface MergedHistory {
  type: RowType;
  value:
    | MedicalRecordResponse
    | CancellableReservationResponse
    | FreeTextMedicalRecordResponse
    | HistoryData;
  dateTime: string;
}

export type HistoryFilterType = Array<RowType>;

interface HistoryFiltersForm {
  filters: HistoryFilterType;
}

const PetHistory: React.FC<Props> = ({
  limitResults = 100,
  petId,
  pet,
  owner,
}: Props) => {
  const [reservations, setReservations] = useState<
    CancellableReservationResponse[]
  >([]);
  const [loadingReservations, setLoadingReservations] =
    useState<boolean>(false);

  const [medicalRecords, setMedicalRecords] = useState<MedicalRecordResponse[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [externalMedicalRecords, setExternalMedicalRecords] = useState<
    FreeTextMedicalRecordResponse[]
  >([]);
  const [loadingExternalMedicalRecords, setLoadingExternalMedicalRecords] =
    useState<boolean>(false);

  const [prescriptions, setPrescriptions] = useState<HistoryData[]>([]);
  const [loadingPrescriptions, setLoadingPrescriptions] =
    useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [triggerReload, setTriggerReload] = useState<boolean>(false);
  const [mergedList, setMergedList] = useState<Array<MergedHistory>>([]);
  const { clinic } = useClinic();

  const [selectedDocuments, setSelectedDocuments] = useState<
    Array<MergedHistory>
  >([]);
  const formId = generateUUId();
  const [emailModal, setEmailModal] = useState<boolean>(false);
  const { control, watch } = useForm<HistoryFiltersForm>({
    defaultValues: {
      filters: [RowType.MedicalRecord, RowType.External, RowType.Prescription],
    },
  });
  const filters = watch("filters") || [];

  const reload = () => {
    setTriggerReload(!triggerReload);
  };

  const getReservations = async () => {
    setReservations([]);
    if (
      petId !== undefined &&
      !!filters.find((f) => f === RowType.Reservation)
    ) {
      setLoadingReservations(true);
      try {
        const response = await ReservationApi.getClinicPetReservations(
          petId,
          50
        );
        const dataArray: CancellableReservationResponse[] = [];

        response.data.elements.forEach((reservation) => {
          const dateTime = moment(`${reservation.startDateTime}`);
          const item: CancellableReservationResponse = {
            ...reservation,
            cancellable:
              dateTime.isAfter(moment()) &&
              reservation.lastMedicalRecordId === undefined,
          };

          dataArray.push(item);
        });

        setReservations(dataArray);
      } catch (err) {
        setError(await getGeneralError(err));
        logger.error(err);
      } finally {
        setLoadingReservations(false);
      }
    }
  };

  const getMedicalRecords = async () => {
    setMedicalRecords([]);
    if (
      petId !== undefined &&
      !!filters.find((f) => f === RowType.MedicalRecord)
    ) {
      setLoading(true);
      try {
        const resp = await MedicalRecordApi.getMedicalRecordsOfClinicPet(
          petId,
          50
        );
        setMedicalRecords(resp.data.elements);
      } catch (err) {
        setError(strings.couldNotRetrieveMedicalRecords);
        logger.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchFreeTextMedicalRecords = async () => {
    setExternalMedicalRecords([]);
    if (petId && clinic && !!filters.find((f) => f === RowType.External)) {
      setLoadingExternalMedicalRecords(true);
      try {
        const resp =
          await MedicalRecordApi.getFreeTextMedicalRecordsForPetInClinic(
            petId,
            clinic.id,
            50
          );
        setExternalMedicalRecords(resp.data.elements);
      } catch (err) {
        setError(strings.couldNotRetrieveExternalMedicalRecords);
        logger.error(err);
      } finally {
        setLoadingExternalMedicalRecords(false);
      }
    }
  };

  const getPrescriptionsOfClinic = async () => {
    setPrescriptions([]);
    if (petId && clinic && !!filters.find((f) => f === RowType.Prescription)) {
      setLoadingPrescriptions(true);
      try {
        const response = await PrescriptionApi.getPrescriptionsOfClinic(
          clinic.id,
          petId,
          undefined,
          undefined,
          undefined,
          [NewPrescriptionStatus.ACCEPTED, NewPrescriptionStatus.WARNINGS]
        );
        setPrescriptions(response.data.elements);
      } catch (err) {
        setError(strings.couldNotRetrievePrescriptions);
        logger.error(err);
      } finally {
        setLoadingPrescriptions(false);
      }
    }
  };

  useEffect(() => {
    if (petId !== undefined) {
      void getReservations();
      void getMedicalRecords();
      void fetchFreeTextMedicalRecords();
      void getPrescriptionsOfClinic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petId, clinic, triggerReload, filters]);

  useEffect(() => {
    if (
      !loading &&
      !loadingReservations &&
      !loadingExternalMedicalRecords &&
      !loadingPrescriptions
    ) {
      const newMerged: MergedHistory[] = [
        ...reservations.map((r) => ({
          type: RowType.Reservation,
          value: r,
          dateTime: r.startDateTime,
        })),
        ...medicalRecords.map((mr) => ({
          type: RowType.MedicalRecord,
          value: mr,
          dateTime: mr.previousVersionId
            ? moment(mr.reservationDateTime).add(1, "minute").format()
            : mr.reservationDateTime,
        })),
        ...externalMedicalRecords.map((emr) => ({
          type: RowType.External,
          value: emr,
          dateTime:
            emr.startDateTime || emr.endDateTime || emr.creationDateTime,
        })),
        ...prescriptions.map((p) => ({
          type: RowType.Prescription,
          value: p,
          dateTime: moment.unix(p.creationTimestamp).format(),
        })),
      ];

      newMerged.sort(
        (a, b) => moment(b.dateTime).valueOf() - moment(a.dateTime).valueOf()
      );
      setMergedList(newMerged);
    }
  }, [
    medicalRecords,
    reservations,
    externalMedicalRecords,
    prescriptions,
    loading,
    loadingReservations,
    loadingExternalMedicalRecords,
    loadingPrescriptions,
  ]);

  return (
    <>
      <AlertBox message={error} className="my-3" />
      {loading ||
      loadingExternalMedicalRecords ||
      loadingReservations ||
      loadingPrescriptions ? (
        <LoaderInline className="m-3" />
      ) : (
        <div className="space-y-6">
          <div className="flex flex-wrap gap-x-4 gap-y-6 justify-between items-center">
            <div>
              <div>
                <Filters
                  control={control}
                  defaultFilters={filters || ["all"]}
                  name="filters"
                  options={[
                    {
                      value: RowType.Reservation,
                      title: strings.appointments,
                    },
                    {
                      value: RowType.MedicalRecord,
                      title: strings.medicalRecords,
                    },
                    {
                      value: RowType.Prescription,
                      title: strings.prescriptions,
                    },
                    {
                      value: RowType.External,
                      title: strings.externalMedicalRecords,
                    },
                  ]}
                />
              </div>
              <Button
                className="ml-4"
                hidden={selectedDocuments.length === 0}
                onClick={() => {
                  setEmailModal(true);
                }}
                variant="tertiary"
              >
                {`${strings.attachToEmail} (${selectedDocuments.length})`}
              </Button>
            </div>
            <div>
              {pet ? <HistoryActions pet={pet} owner={owner} reload={reload} /> : <></>}
            </div>
          </div>
          <div className="tw-card mt-3">
            {mergedList.length === 0 ? (
              <div className="p-3">
                <EmptyListText />
              </div>
            ) : (
              <></>
            )}
            {mergedList
              .slice(0, limitResults)
              .map((item: MergedHistory, index) => {
                if (item.type === RowType.MedicalRecord) {
                  return (
                    <Fragment key={item.value.id}>
                      <MedicalRecordRow
                        isNextExist={!!mergedList[index + 1]}
                        formId={formId}
                        medicalRecord={item.value}
                        onChanged={(value: boolean) => {
                          if (value) {
                            setSelectedDocuments([...selectedDocuments, item]);
                          } else {
                            setSelectedDocuments([
                              ...selectedDocuments.filter(
                                (doc) => doc.value.id !== item.value.id
                              ),
                            ]);
                          }
                        }}
                        returnError={setError}
                      />
                    </Fragment>
                  );
                }
                if (item.type === RowType.Reservation) {
                  return (
                    <Fragment key={item.value.id}>
                      <AppointmentRow
                        isNextExist={!!mergedList[index + 1]}
                        reservation={item.value}
                        reloadList={reload}
                      />
                    </Fragment>
                  );
                }
                if (item.type === RowType.External) {
                  return (
                    <Fragment key={item.value.id}>
                      <ExternalMedicalRecordRow
                        isNextExist={!!mergedList[index + 1]}
                        externalMedicalRecord={item.value}
                        reloadList={reload}
                        returnError={setError}
                      />
                    </Fragment>
                  );
                }
                if (item.type === RowType.Prescription) {
                  return (
                    <Fragment key={item.value.id}>
                      <PrescriptionRow
                        isNextExist={!!mergedList[index + 1]}
                        prescription={item.value}
                      />
                    </Fragment>
                  );
                }
                return <></>;
              })}
          </div>
          <SendInEmailModal
            close={() => {
              setEmailModal(false);
            }}
            isOpen={emailModal}
            owner={owner}
            pet={pet}
            selectedDocuments={selectedDocuments}
          />
        </div>
      )}
    </>
  );
};

export default PetHistory;
