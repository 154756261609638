/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import "moment-timezone";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import AddressForm, { CreateSimpleAddressForm } from "./AddressForm";
import { LocatedAddressRequest } from "../../../models/contact/LocatedAddressRequest";
import AddressMapCheck from "./AddressMapCheck";
import Button from "../../Button";
import AlertBox from "../../AlertBox";

interface Props {
  error?: string | null;
  setAddress(a: LocatedAddressRequest): void;
}

type AddressSteps = "address" | "map";

const AddAddressSteps: React.FC<Props> = ({ error, setAddress }: Props) => {
  const [addressStep, setAddressStep] = useState<AddressSteps>("address");
  const addressFormMethods = useForm<CreateSimpleAddressForm>();
  const { getValues, handleSubmit, clearErrors } = addressFormMethods;

  const continueToMap = () => {
    setAddressStep("map");
  };

  switch (addressStep) {
    case "map":
      return (
        <AddressMapCheck
          address={{
            ...getValues(),
            countryCode: getValues().countries[0].countryCode,
          }}
          changeAddress={() => {
            setAddressStep("address");
            clearErrors();
          }}
          countryName={getValues().countries[0].displayName}
          isMapCenterToCurrentLocation={false}
          setLocalizedAddress={setAddress}
        />
      );
    case "address":
      return (
        <div className="space-y-6">
          <FormProvider {...addressFormMethods}>
            <AddressForm />
          </FormProvider>
          <AlertBox message={error} />
          <Button onClick={handleSubmit(continueToMap)}>
            {strings.saveAndContinue}
          </Button>
        </div>
      );
    default:
      return <></>;
  }
};

export default AddAddressSteps;
