/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CancellableReservationResponse } from "../../../../models/reservation/CancellableReservationResponse";
import { dateAndTime, strings } from "../../../../common/Strings/Strings";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../../../util/LocalStorageVariables";
import DeleteReservation from "../../../../components/Modals/DeleteReservation";
import Button from "../../../../components/Button";
import HorizontalLine from "../../../../components/HorizontalLine";
import { DocumentText } from "../../../../common/Icons/DocumentText";
import { Trash } from "../../../../common/Icons/Trash";

interface Props {
  isNextExist: boolean;
  reservation: CancellableReservationResponse | any;
  reloadList?(): void;
}

const AppointmentRow: React.FC<Props> = ({
  isNextExist,
  reservation,
  reloadList,
}: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [reservationRejectId, setReservationRejectId] = useState<string>("");
  const role: UserRole | null = getValueOfActiveUser("role");

  const getMedicalRecordUrl = () => {
    let medicalRecordUrl;

    if (reservation.lastMedicalRecordId === undefined) {
      medicalRecordUrl = `/reservation/${reservation.id}/medical-record/`;
    } else {
      medicalRecordUrl = `/medical-record/${reservation.lastMedicalRecordId}`;
    }

    return medicalRecordUrl;
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const deleteReservation = (id: string) => {
    setReservationRejectId(id);
    openModal();
  };

  return (
    <>
      <div
        className="flex flex-wrap py-3 space-y-3 lg:space-y-0"
        key={reservation.id}
      >
        <div className="col-12 col-lg-2 flex items-center">
          <div
            style={{
              marginRight: "8px",
              marginBottom: "11px",
              marginLeft: "30px",
            }}
          />
          <div className="flex flex-col justify-center">
            <div className="uppercase text-sm">{strings.appointment}</div>
            <div className="font-bold">
              {moment(reservation.startDateTime).format("LL")}
            </div>
            <div>
              {moment(reservation.startDateTime).format(dateAndTime.timeFormat)}{" "}
              - {moment(reservation.endDateTime).format(dateAndTime.timeFormat)}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 flex flex-col justify-center">
          <b>{reservation.reservationType?.name || null}</b>
          <div className="line-clamp-1">{reservation.description}</div>
        </div>
        <div className="col-12 col-lg-2 flex flex-col justify-center">
          {reservation.collaborator.fullName}
        </div>
        <div className="col-12 col-lg-1 flex justify-end items-center space-x-3">
          <div
            hidden={
              moment(reservation.startDateTime).valueOf() < moment().valueOf()
            }
          >
            <Button
              disabled={
                moment(reservation.startDateTime).valueOf() < moment().valueOf()
              }
              onClick={() => {
                deleteReservation(reservation.id);
              }}
              variant="icon"
            >
              <Trash />
            </Button>
          </div>
          <Link to={getMedicalRecordUrl()}>
            <Button variant="icon">
              <DocumentText />
            </Button>
          </Link>
        </div>
        <DeleteReservation
          cancellation={role !== "doctor"}
          show={modalIsOpen}
          onHide={closeModal}
          reservationId={reservationRejectId}
          reload={reloadList}
        />
      </div>
      {isNextExist && <HorizontalLine />}
    </>
  );
};

export default AppointmentRow;
