/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import SimpleTextarea from "./InputFieldsSimple/SimpleTextarea";
/*import ReactQuill from "react-quill";
import "../pages/Manager/Policy/ReactQuill.css";*/

interface Props {
  value?: string;
  setValue: (value: string) => void;
}

const HtmlEditorTextarea = ({ value, setValue }: Props): ReactElement => (
  /*
  TODO: fix react quill or replace it (Buffer.isBuffer is not a function)


  <ReactQuill
    value={value}
    onChange={(value) => setValue(value)}
    theme="snow"
    modules={{
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ script: "sub" }, { script: "super" }],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          {
            color: [
              "", // This is "Colorless" mode: Shows as black in light mode and white in dark mode
              "#2e2e2f",
              "#465364",
              "#5f6f85",
              "#a7afba",
              "#3B5998",
              "#0ca2fe",
            ],
          },
        ],
        ["clean"],
      ],
    }}
  />
  */
  <SimpleTextarea
    name="statementReplacementField" 
    minRows={25} 
    onChange={(text) => { setValue(text); }} 
    value={value}
  />
);

export default HtmlEditorTextarea;
