/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { strings } from "../common/Strings/Strings";
import { PageProps } from "../models/PageProps";
import { Spinner, SpinnerSize } from "../common/Icons/Spinner";

type GeneralProp = PageProps & any;

export const Loader = (
  WrappedComponent:
    | React.FC<GeneralProp>
    | (({ setPageLoading }: GeneralProp) => ReactElement),
  loadingMessage: string = strings.loading
) => {
  function LoaderComponent(props: any) {
    const [isLoading, setLoading] = useState<boolean>(true);

    const setLoadingState = (isComponentLoading: boolean) => {
      setLoading(isComponentLoading);
    };

    const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");

    return (
      <>
        {isLoading && (
          <div
            className="flex justify-center items-center"
            style={{ height: "100vh" }}
          >
            <div className="text-center">
              <Spinner size={SpinnerSize.ExtraLarge} dark={!darkMode} />
              <div className="mt-2 dark:text-white">{loadingMessage}</div>
            </div>
          </div>
        )}

        <div hidden={isLoading}>
          <WrappedComponent {...props} setPageLoading={setLoadingState} />
        </div>
      </>
    );
  }

  return LoaderComponent;
};
