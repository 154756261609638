/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { IntervalUnitEnum } from "../../../models/ePrescription/sending/IntervalUnitEnum";
import { Option } from "../../../models/Option";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Field from "../../../components/ReactHookFormFields/General/Field";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { MinusInverseIcon } from "../../../common/Icons/MinusInverseIcon";
import { PlusInverseIcon } from "../../../common/Icons/PlusInverseIcon";

const RepeatDispensingBox: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    getValues,
    register,
    formState: { errors },
  } = useFormContext();

  const periods: Option[] = [
    { title: strings.dayOrDays, value: IntervalUnitEnum.DAYS },
    { title: strings.weeks, value: IntervalUnitEnum.WEEKS },
    { title: strings.months, value: IntervalUnitEnum.MONTHS },
  ];

  return (
    <div className="tw-card">
      <div
        role="button"
        tabIndex={0}
        className="px-3 py-2 flex items-center"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyDown={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="font-semibold">{strings.redemption}</div>
        <div className="ml-auto">
          <button type="button">
            {isOpen ? (
              <MinusInverseIcon className="text-gray-500" />
            ) : (
              <PlusInverseIcon className="text-gray-500" />
            )}
          </button>
        </div>
      </div>
      {isOpen ? (
        <div className="grid grid-cols-12 gap-3 px-3 py-2">
          <div className="col-span-4">
            <Field
              autoComplete={AutoCompleteOptions.off}
              error={errors.numberOfTimes}
              fieldOptions={{
                max: {
                  value: 99,
                  message: strings.formatString(strings.mustBeBetween, 1, 99),
                },
                min: {
                  value: 1,
                  message: strings.formatString(strings.mustBeBetween, 1, 99),
                },
              }}
              label={strings.limit}
              name="numberOfTimes"
              placeholder={strings.limit}
              register={register}
              required
              type="number"
            />
          </div>
          <div className="col-span-4">
            <Field
              autoComplete={AutoCompleteOptions.off}
              error={errors.interval}
              fieldOptions={{
                validate: {
                  isInTimeRange: (v: number) => {
                    if (!v) {
                      return true;
                    }
                    const intervalUnit = getValues("intervalUnit");
                    if (intervalUnit) {
                      switch (intervalUnit) {
                        case IntervalUnitEnum.WEEKS:
                          return v >= 1 && v <= 52;
                        case IntervalUnitEnum.MONTHS:
                          return v >= 1 && v <= 12;
                        default:
                          return v >= 1 && v <= 365;
                      }
                    }
                    return strings.oneYearInterval;
                  },
                },
              }}
              label={strings.expeditionInterval}
              name="interval"
              optional
              placeholder={strings.expeditionInterval}
              register={register}
              type="number"
            />
          </div>
          <div className="col-span-4">
            <Select
              autoComplete={AutoCompleteOptions.off}
              error={errors.intervalUnit}
              fieldOptions={{ deps: ["interval"] }}
              label={strings.expeditionIntervalUnit}
              name="intervalUnit"
              optional
              options={periods}
              register={register}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RepeatDispensingBox;
