/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import InvoiceApi from "../../../api/InvoiceApi";
import { getGeneralError } from "../../../util/helperFunctions";
import LoaderInline from "../../../components/LoaderInline";
import InvoiceInfos from "./InvoiceInfos";

interface Props {
  isCustomerView: boolean;
  invoicePairId?: string;
}

const InvoicePairDetails: React.FC<Props> = ({
  isCustomerView,
  invoicePairId,
}: Props) => {
  const [invoice, setInvoice] = useState<InvoiceResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [reloadInvoice, setReloadInvoice] = useState<boolean>(false);

  const getInvoice = async () => {
    if (invoicePairId) {
      setLoading(true);

      try {
        const response = await InvoiceApi.getInvoice(invoicePairId);
        setInvoice(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    void getInvoice();
  }, [invoicePairId, reloadInvoice]);

  return (
    <div hidden={!invoicePairId}>
      {loading ? (
        <LoaderInline />
      ) : (
        <div>
          {invoice ? (
            <InvoiceInfos
              error={error}
              isCustomerView={isCustomerView}
              isPairInvoice={true}
              invoice={invoice}
              reload={() => {
                setReloadInvoice((prev) => !prev);
              }}
              setError={setError}
              setInvoice={setInvoice}
            />
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default InvoicePairDetails;
