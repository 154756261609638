/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export enum SiteManagerAuthority {
  MANAGER_FULL = "MANAGER_FULL",
  MANAGER_CONFIG = "MANAGER_CONFIG",
  MANAGER_FEES = "MANAGER_FEES",
  MANAGER_POLICIES = "MANAGER_POLICIES",
  MANAGER_MANAGERS = "MANAGER_MANAGERS",
  MANAGER_USERS = "MANAGER_USERS",
  MANAGER_CLINICS = "MANAGER_CLINICS",
  MANAGER_BILLING = "MANAGER_BILLING",
  MANAGER_DOCUMENTS = "MANAGER_DOCUMENTS",
  MANAGER_INSURANCE = "MANAGER_INSURANCE",
  MANAGER_FEEDBACKS = "MANAGER_FEEDBACKS",
  MANAGER_CLINIC_REMOTE_ACCESS = "MANAGER_CLINIC_REMOTE_ACCESS",
}
