/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment } from "react";
import moment from "moment";
import { strings } from "../../../../common/Strings/Strings";
import { MedicalRecordResponse } from "../../../../models/medical/MedicalRecordResponse";
import { MedicalTreatmentResponse } from "../../../../models/medical/MedicalTreatmentResponse";
import MedicalRecordActions from "../MedicalRecordActions";
import SimpleCheckBox from "../../../../components/InputFieldsSimple/SimpleCheckBox";
import Tag, { TagType } from "../../../../components/Tag";
import HorizontalLine from "../../../../components/HorizontalLine";
import { MedicalRecordFileResponse } from "../../../../models/medical/MedicalRecordFileResponse";
import FileRow from "../../../MedicalRecord/Files/FileRow";

interface Props {
  formId: string;
  isNextExist: boolean;
  medicalRecord: MedicalRecordResponse | any;
  onChanged(value: boolean): void;
  returnError(message: string | null): void;
}

export const medicalRecordStatus = (
  medicalRecord: MedicalRecordResponse | any
) => {
  if (medicalRecord?.signedBy) {
    return <Tag type={TagType.primary} text={strings.signed} />;
  }
  if (!medicalRecord.signedBy && medicalRecord?.previousVersionId) {
    return <Tag type={TagType.secondary} text={strings.underCorrection} />;
  }
  return <Tag type={TagType.primaryOutlined} text={strings.inProgress} />;
};

const MedicalRecordRow: React.FC<Props> = ({
  formId,
  isNextExist,
  medicalRecord,
  onChanged,
  returnError,
}: Props) => (
  <>
    <div
      className="flex flex-wrap py-3 space-y-3 lg:space-y-0"
      key={medicalRecord.id}
    >
      <div className="col-12 col-lg-2 flex items-center">
        <div>
          <SimpleCheckBox
            name={`${medicalRecord.id}/${formId}`}
            onChange={onChanged}
            readOnly={!medicalRecord.signedBy}
          />
        </div>
        <div className="flex flex-col justify-center">
          <div className="uppercase text-sm">{strings.medicalRecord}</div>
          <div className="font-bold">
            {moment(medicalRecord.reservationDateTime).format("LL")}
          </div>
          <div className="mb-3 md:mb-0">
            {medicalRecordStatus(medicalRecord)}
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-7 flex flex-col justify-center">
        <div hidden={!medicalRecord.weight}>
          <div className="flex">
            <b>{strings.weight}:&nbsp;</b>
            {`${medicalRecord.weight} kg`}
          </div>
        </div>
        <div hidden={medicalRecord.diagnoses.length === 0}>
          <div className="flex">
            <b>{strings.diagnoses}:&nbsp;</b>
            {medicalRecord.diagnoses.map((d: string, index: number) => (
              <div key={index}>{`${d}${
                medicalRecord.diagnoses[index + 1] ? ", " : ""
              }`}</div>
            ))}
          </div>
        </div>
        <div hidden={medicalRecord.treatments.length === 0}>
          <div className="flex">
            <b>{strings.treatments}:&nbsp;</b>
            {medicalRecord.treatments.map(
              (t: MedicalTreatmentResponse, index: number) => (
                <div>{`${t.name}${
                  medicalRecord.treatments[index + 1] ? ", " : ""
                }`}</div>
              )
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 pt-2">
          {medicalRecord.files?.map((file: MedicalRecordFileResponse) => (
            <div key={file.fileId}>
              <FileRow
                canEdit={false}
                file={file}
                resIdS={medicalRecord.reservationId}
                setError={returnError}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="col-12 col-lg-2 flex flex-col justify-center">
        {`${medicalRecord.vet.fullName}`}
      </div>
      <div className="col-12 col-lg-1 flex justify-end items-center">
        <MedicalRecordActions
          medicalRecord={medicalRecord}
          returnError={returnError}
        />
      </div>
    </div>
    {isNextExist && <HorizontalLine />}
  </>
);

export default MedicalRecordRow;
