/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { strings } from "../../../common/Strings/Strings";
import CloseButton from "../../../components/CloseButton";
import InsuranceApi from "../../../api/InsuranceApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { InsuranceCompanyResponse } from "../../../models/insurance/InsuranceCompanyResponse";
import AlertBox from "../../../components/AlertBox";
import Button from "../../../components/Button";

interface Props {
  show: boolean;
  onHide: () => void;
  refresh: () => void;
  selectedCompany?: InsuranceCompanyResponse;
}

const InsuranceCompanyDeletionModal: React.FC<Props> = ({
  show,
  onHide,
  refresh,
  selectedCompany,
}: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const deleteCompany = async () => {
    if (!selectedCompany) {
      return;
    }

    setLoading(true);
    try {
      await InsuranceApi.deleteInsuranceCompany(selectedCompany.id);
      onHide();
      refresh();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal centered show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fas fa-exclamation-circle text-red-500" />
          {strings.warning}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center px-4 py-2">
          {strings.areYouSure}
        </div>
        <AlertBox message={error} className="mb-2" />
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={onHide} text={strings.cancel} />
        <Button
          className="ml-auto"
          fullWidth={false}
          loading={isLoading}
          onClick={deleteCompany}
          variant="danger"
        >
          {strings.delete}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InsuranceCompanyDeletionModal;
