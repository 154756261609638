import React, { MutableRefObject, ReactElement, RefObject } from "react";
import moment, { Duration } from "moment";
import { DataConnection, MediaConnection, Peer } from "peerjs";
import DetectRTC from "detectrtc";
import logger from "../../util/logger";
import { VideoChatUser } from "../../models/videoConsultation/VideoChatRoomUsers";
import { CheckCircle } from "../../common/Icons/CheckCircle";
import { XCircle } from "../../common/Icons/XCircle";
import { strings } from "../../common/Strings/Strings";
import joinSoundEffectDefault from "../../assets/audio/join_sound_effect.wav";
import joinSoundEffectAlternative from "../../assets/audio/join_sound_effect_2.wav";
import leaveSoundEffectDefault from "../../assets/audio/leave_sound_effect.wav";
import leaveSoundEffectAlternative from "../../assets/audio/leave_sound_effect_2.wav";
import { getElementHeightById } from "../../util/HtmlUtils";
import Params from "../../common/Params";

export interface MediaFeatures {
  hasMicrophone: boolean;
  hasMicrophonePermission: boolean;
  hasWebcam: boolean;
  hasWebcamPermission: boolean;
}

export interface MediaErrors {
  micError: boolean;
  camError: boolean;
}

export interface MediaConfig {
  camEnabled: boolean;
  micEnabled: boolean;
  camMissing: boolean;
  micMissing: boolean;
}

export interface AudioVideoToggleData {
  camEnabled: boolean;
  micEnabled: boolean;
}

export interface VideoChatUserConnection {
  id: string; // The ID of the remote peer / connection
  dataConnection?: DataConnection;
  mediaConnection?: MediaConnection;
  user?: VideoChatUser;
  mediaConfig?: MediaConfig;
}

export enum LoadState {
  "MISSING" = "Missing",
  "INIT" = "Init",
  "LOADING" = "Loading",
  "LOADED" = "Loaded",
}

interface MediaDebugProps {
  audioTracks: number;
  camEnabled: boolean;
  camMissing: boolean;
  liveAudioTracks: number;
  liveVideoTracks: number;
  micEnabled: boolean;
  micMissing: boolean;
  placeholderLoadState: LoadState;
  streamId: string;
  videoIsLive: boolean;
  videoIsPaused: boolean | "Unknown";
  videoLoadState: LoadState;
  videoReadyState: number | "Unknown";
  videoTracks: number;
}

interface MediaTrackProperties {
  id: string;
  kind: string;
  label: string;
  enabled: boolean;
  muted: boolean;
  readyState: string;
  constraints?: MediaTrackConstraints;
  settings?: MediaTrackSettings;
  capabilities?: MediaTrackCapabilities;
}

interface MediaStreamProperties {
  id: string;
  active: boolean;
  audioTracks: MediaTrackProperties[];
  videoTracks: MediaTrackProperties[];
}

// [For debug] Returns a MediaStream information object
// noinspection JSUnusedGlobalSymbols
export const getMediaStreamProperties = (
  stream: MediaStream | null | undefined
): MediaStreamProperties | null => {
  if (!stream) {
    return null;
  }

  const audioTracks = stream.getAudioTracks().map((track) => ({
    id: track.id,
    kind: track.kind,
    label: track.label,
    enabled: track.enabled,
    muted: track.muted,
    readyState: track.readyState,
    constraints: track.getConstraints ? track.getConstraints() : undefined,
    settings: track.getSettings ? track.getSettings() : undefined,
    capabilities: track.getCapabilities ? track.getCapabilities() : undefined,
  }));

  const videoTracks = stream.getVideoTracks().map((track) => ({
    id: track.id,
    kind: track.kind,
    label: track.label,
    enabled: track.enabled,
    muted: track.muted,
    readyState: track.readyState,
    constraints: track.getConstraints ? track.getConstraints() : undefined,
    settings: track.getSettings ? track.getSettings() : undefined,
    capabilities: track.getCapabilities ? track.getCapabilities() : undefined,
  }));

  return {
    id: stream.id,
    active: stream.active,
    audioTracks,
    videoTracks,
  };
};

const getEnabledIcon = (enabled: boolean) =>
  enabled ? (
    <CheckCircle className="text-green-600 dark:text-green-500" />
  ) : (
    <XCircle className="text-red-500 dark:text-red-400" />
  );

interface DebugFlags {
  simulateMissingMicrophone: boolean;
  simulateMissingCamera: boolean;
}

// Check the required features (microphone, webcam and the related permissions)
export const checkMediaFeatures = async (
  debugFlags: DebugFlags = {
    simulateMissingMicrophone: false,
    simulateMissingCamera: false,
  }
): Promise<MediaFeatures> =>
  new Promise((resolve) => {
    DetectRTC.load(() => {
      const mediaFeatures: MediaFeatures = {
        hasMicrophone: debugFlags.simulateMissingMicrophone
          ? false
          : DetectRTC.hasMicrophone,
        hasMicrophonePermission: DetectRTC.isWebsiteHasMicrophonePermissions,
        hasWebcam: debugFlags.simulateMissingCamera
          ? false
          : DetectRTC.hasWebcam,
        hasWebcamPermission: DetectRTC.isWebsiteHasWebcamPermissions,
      };
      resolve(mediaFeatures);
    });
  });

/**
 * Checks if the user manually denied the permissions (this should work in most browsers)
 * Note: A side effect is that this opens a prompt (asking for microphone or camera permission)
 * Note2: This should only run if the user allows only either microphone or camera
 */
async function isMediaDenied(media: "audio" | "video") {
  try {
    // Convert the media type to the corresponding permission name
    const permissionName = media === "audio" ? "microphone" : "camera";
    let permissionStatus;

    // Check if the Permissions API is available
    if (navigator.permissions && navigator.permissions.query) {
      try {
        permissionStatus = await navigator.permissions.query({
          name: permissionName as PermissionName,
        });
      } catch (error) {
        console.warn(
          "Permissions API query failed, falling back to getUserMedia.",
          error
        );
      }
    }

    // If the Permissions API is available and the state is 'denied', return true
    if (permissionStatus && permissionStatus.state === "denied") {
      return true;
    }

    // Fallback to using getUserMedia if Permissions API is not available or does not return 'denied'
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        [media]: true,
      });
      stream.getTracks().forEach((track) => track.stop());
      return false;
    } catch (error) {
      return true;
    }
  } catch (error) {
    // Assume permission is denied if an error occurs
    return true;
  }
}

/**
 * Sets up the video stream of the local user's camera
 * Optionally add a stream ref if it's already been acquired
 * Returns MediaData if successful or null if the media could not be acquired (e.g. permission is denied)
 */
export const setupUserVideo = async (
  videoRef: RefObject<HTMLVideoElement | undefined>,
  mediaFeatures: MediaFeatures,
  requestMedia: "audio" | "video" | "all",
  setMediaError?: (media: "audio" | "video") => void,
  streamRef?: RefObject<MediaStream | null>
): Promise<MediaStream | null> => {
  let stream: MediaStream | null = null;

  // If there are no media devices, nothing should be requested
  if (!mediaFeatures.hasMicrophone && !mediaFeatures.hasWebcam) {
    return null;
  }

  let micDenied = false;
  let camDenied = false;

  // If one of the permissions are missing, check which one
  // This should only happen if the user manually denied one of them
  if (
    mediaFeatures.hasMicrophone &&
    mediaFeatures.hasWebcam &&
    mediaFeatures.hasMicrophonePermission !== mediaFeatures.hasWebcamPermission
  ) {
    micDenied = await isMediaDenied("audio");
    camDenied = await isMediaDenied("video");
  }

  const shouldRequestAudio =
    requestMedia === "video"
      ? false
      : mediaFeatures.hasMicrophone && !micDenied;
  const shouldRequestVideo =
    requestMedia === "audio" ? false : mediaFeatures.hasWebcam && !camDenied;

  try {
    // If the user media has not been acquired yet, get the user media
    if (!streamRef || !streamRef.current) {
      stream = await navigator.mediaDevices.getUserMedia({
        video: shouldRequestVideo
          ? {
              width: { ideal: 4096 },
              height: { ideal: 2160 },
            }
          : undefined,
        audio: shouldRequestAudio,
      });
    } else {
      stream = streamRef.current;
    }

    if (videoRef.current && stream) {
      const videoElement = videoRef.current;
      if ("srcObject" in videoRef.current) {
        videoElement.srcObject = stream;
      } else {
        // Fallback for older browsers
        videoElement.src = window.URL.createObjectURL(stream as any);
      }
    }
  } catch (error: any) {
    // Check common WebRTC error types
    if (
      error.name === "NotAllowedError" ||
      error.name === "PermissionDeniedError"
    ) {
      logger.error(
        "[📹VideoChat] Permission denied for accessing user media:",
        error
      );
    } else if (
      error.name === "NotFoundError" ||
      error.name === "DevicesNotFoundError"
    ) {
      logger.error(
        "[📹VideoChat] No microphone or webcam found or available:",
        error
      );
    } else {
      logger.error("[📹VideoChat] Error accessing user media:", error);

      // Try again with one of the media devices both were requested
      if (shouldRequestAudio && shouldRequestVideo) {
        const errorString: string = error.toString();
        if (errorString.includes("audio")) {
          setMediaError && setMediaError("audio");
          return setupUserVideo(
            videoRef,
            mediaFeatures,
            "video",
            setMediaError,
            streamRef
          );
        }
        setMediaError && setMediaError("video");
        return setupUserVideo(
          videoRef,
          mediaFeatures,
          "audio",
          setMediaError,
          streamRef
        );
      }

      // Set the error for the requested media
      if (setMediaError) {
        shouldRequestAudio ? setMediaError("audio") : setMediaError("video");
      }
      return null;
    }
  }

  return stream;
};

export interface VideoLayout {
  area: number;
  cols: number;
  rows: number;
  width: number;
  height: number;
}

/**
 * Recalculates the optimal layout for a given number of videos within a container element,
 * ensuring the layout maximizes the total area used while respecting the given aspect ratios.
 *
 * @param {RefObject<HTMLDivElement>} containerRef - Reference to the container element.
 * @param {number} videoCount - The number of videos to layout.
 * @param {number} [minAspectRatio=1] - The minimum aspect ratio (width/height) for the videos.
 * @param {number} [maxAspectRatio=16/9] - The maximum aspect ratio (width/height) for the videos.
 * @returns {VideoLayout} - The best layout configuration.
 */
export const recalculateLayout = (
  containerRef: RefObject<HTMLDivElement>,
  videoCount: number,
  minAspectRatio = 1,
  maxAspectRatio = 16 / 9
): VideoLayout => {
  // Get container dimensions, default to 0 if undefined
  const containerWidth = Math.floor(
    containerRef.current?.getBoundingClientRect()?.width || 0
  );
  const containerHeight = Math.floor(
    containerRef.current?.getBoundingClientRect()?.height || 0
  );

  // Initial best layout placeholder
  let bestLayout: VideoLayout = {
    area: 0,
    cols: 0,
    rows: 0,
    width: 0,
    height: 0,
  };

  // Iterate over possible column counts
  for (let cols = 1; cols <= videoCount; cols += 1) {
    // Calculate the number of rows needed for the current column count
    const rows = Math.ceil(videoCount / cols);

    // Calculate initial width and height for the current layout
    let width = Math.floor(containerWidth / cols);
    let height = Math.floor(containerHeight / rows);
    const aspectRatio = width / height;

    // Adjust width and height to respect the min and max aspect ratios
    if (aspectRatio < minAspectRatio) {
      height = Math.floor(width / minAspectRatio);
    } else if (aspectRatio > maxAspectRatio) {
      width = Math.floor(height * maxAspectRatio);
    }

    // Calculate the area of the current layout (total area occupied by all videos)
    const area = width * height * cols * rows;

    // Update the best layout if the current layout is better
    if (area > bestLayout.area) {
      bestLayout = {
        area,
        width,
        height,
        rows,
        cols,
      };
    }
  }

  // Return the best layout found
  return bestLayout;
};

export const formatMediaConfig = (mediaConfig: MediaConfig) => {
  const camStatus = mediaConfig.camEnabled ? "✅Enabled" : "❌Disabled";
  const camMissingStatus = mediaConfig.camMissing ? "⚠️Missing" : "✅Present";

  const micStatus = mediaConfig.micEnabled ? "✅Enabled" : "❌Disabled";
  const micMissingStatus = mediaConfig.micMissing ? "⚠️Missing" : "✅Present";

  return `Camera: ${camStatus} ${camMissingStatus} | Microphone: ${micStatus} ${micMissingStatus}`;
};

export const getFormattedTimestamp = () =>
  `[${moment().format("HH:mm:ss.SSS")}]`;

// Creates a new peer that represents the local user
// Uses the auto generated ID for the peer id, custom ID can cause problems with some special characters
export const createPeer = (): Peer => {
  // Extract the hostname from the URL
  const url = new URL(Params.videoChatSignalServiceBaseURL);
  const isLocalhost = url.hostname.includes("localhost");

  let peer;

  if (isLocalhost) {
    // Uses the locally hosted peer server (from videochat-signal-service)
    peer = new Peer({
      host: url.hostname,
      port: 9000,
      secure: false,
    });
  } else {
    // Uses the official PeerServer Cloud for staging/production
    // (since the GlobalVET peer server only works for localhost right now)
    peer = new Peer();
  }

  return peer;
};

export interface LocalConfig {
  mediaConfig: MediaConfig;
  peerRef: MutableRefObject<Peer | undefined>;
  streamRef: MutableRefObject<MediaStream | null>;
  user: VideoChatUser;
}

export interface CallInfo {
  mediaConfig: MediaConfig;
  user: VideoChatUser;
}

interface CallConstraints {
  mandatory: {
    OfferToReceiveAudio: boolean;
    OfferToReceiveVideo: boolean;
  };
  offerToReceiveAudio: number;
  offerToReceiveVideo: number;
}

interface CallOptions {
  constraints: CallConstraints;
  sdpTransform: (sdp: string) => string;
}

// TODO: Update comment
/**
 * Create an initiator peer.
 * For every P2P connection (which is one of the edges in the topology) one of the two peers is the initiator.
 * Used to create peers who are already in the video chat room.
 * These peers (or one of the peers) initiate the connection.
 * They emit the signal first, this way this object representing the remote peer will initiate the connection when joining the room.
 */
export const callUser = (
  userToCall: VideoChatUser,
  { mediaConfig, peerRef, streamRef, user }: LocalConfig,
  addOrUpdateRemoteUser: (remoteUser: VideoChatUserConnection) => void
): VideoChatUserConnection => {
  const peer = peerRef.current;
  const stream = streamRef.current;

  if (!peer) {
    throw new Error("[📹VideoChat] Peer is missing!");
  }

  // Connect to the remote peer (data connection)
  const dataConnection = peer.connect(userToCall.peerId);

  // This options object should be given to the call function
  // This makes asymmetric streams (streams with different kinds and number of tracks) work properly between peers
  const options: CallOptions = {
    constraints: {
      mandatory: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
      },
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1,
    },
    sdpTransform: (sdp: string) =>
      sdp.replace(
        "a=fmtp:111 minptime=10;useinbandfec=1",
        "a=fmtp:111 ptime=5;useinbandfec=1;stereo=1;maxplaybackrate=48000;maxaveragebitrate=128000;sprop-stereo=1"
      ),
  };

  // Call the peer (media connection)
  const mediaConnection = peer.call(
    userToCall.peerId,
    stream ?? new MediaStream(),
    options
  );

  // Create the callerInfo (local user info)
  const callerInfo: CallInfo = {
    mediaConfig,
    user,
  };

  // Send the local user's state to the callee after successful data connection
  dataConnection.on("open", () => {
    dataConnection.send(JSON.stringify(callerInfo));
  });

  // Process the remote user's state
  // The first time the caller (local user) receives data is instantly after a successful connection
  // Only handle the data once, the RemoteVideo component will handle subsequent data event (e.g. mic/cam toggle)
  dataConnection.once("data", (data: any) => {
    const callInfo: CallInfo = JSON.parse(data.toString());

    addOrUpdateRemoteUser({
      id: dataConnection.peer,
      dataConnection,
      mediaConnection,
      user: callInfo.user,
      mediaConfig: callInfo.mediaConfig,
    });

    logger.info(
      `[📹VideoChat] ${
        callInfo.user.name
      } has joined the room. Remote user media config: ${formatMediaConfig(
        callInfo.mediaConfig
      )}.`
    );
  });

  // Handle data connection error
  dataConnection.on("error", (error) => {
    logger.error(`[📹VideoChat] Data connection error: ${error}`);
  });

  // Handle media connection error
  mediaConnection.on("error", (error) => {
    logger.error(`[📹VideoChat] Media connection error: ${error}`);
  });

  logger.info(`[📹VideoChat] Calling ${userToCall.name}...`);

  return {
    id: userToCall.id,
    dataConnection,
    mediaConnection,
    user: userToCall,
  };
};

/**
 * Create a peer (who is not an initiator).
 * For every P2P connection (which is one of the edges in the topology) one of the two peers is not the initiator.
 * Used to create peers who are arriving to the video chat room where the local user is already present.
 * They respond to the initiator peers.
 * They answer (emit back) the signal, this way this object representing the arriving remote peer will respond to the connection request.
 */
export const answerCall = (
  callerUser: MutableRefObject<VideoChatUserConnection>,
  { mediaConfig, peerRef, streamRef, user }: LocalConfig
): void => {
  const peer = peerRef.current;
  const stream = streamRef.current;

  if (!peer) {
    throw new Error("[📹VideoChat] Peer is missing!");
  }

  if (!stream) {
    throw new Error("[📹VideoChat] Stream is is missing!");
  }

  // Create the callee info (local user info)
  const calleeInfo = {
    mediaConfig: {
      camEnabled: mediaConfig.camEnabled,
      micEnabled: mediaConfig.micEnabled,
      camMissing: mediaConfig.camMissing,
      micMissing: mediaConfig.micMissing,
    },
    user,
  };

  // Send the local user's state to the caller user after successful data connection
  peer.on("connection", (connection: DataConnection) => {
    connection.on("open", () => {
      // Assign the connection
      callerUser.current.dataConnection = connection;

      // Send the data
      connection.send(calleeInfo);
      logger.info(
        `[📹VideoChat] ${getFormattedTimestamp()} Connected to user ?.`
      );
    });
  });

  logger.info(
    `[📹VideoChat] Peer successfully created for newly arriving user: ${user.name}.`
  );
};

export const copyAddressToClipboard = () => {
  void navigator.clipboard.writeText(window.location.href);
};

export const timeToString = (callTime: number) =>
  moment().startOf("day").add(callTime, "milliseconds").format("HH:mm:ss");

export const usersToNameArray = (users: VideoChatUser[]) =>
  users.map((user) => user.name);

type TrackType = "video" | "audio" | "all";

export const getMediaTracks = (
  trackType: TrackType,
  mediaStream?: MediaStream | null,
  onlyLive = false
): MediaStreamTrack[] => {
  if (!mediaStream) {
    return [];
  }

  if (trackType === "audio") {
    const audioTracks = mediaStream.getAudioTracks();
    return onlyLive
      ? audioTracks.filter((t) => t.readyState === "live" && t.enabled)
      : audioTracks;
  }

  if (trackType === "video") {
    const videoTracks = mediaStream.getVideoTracks();
    return onlyLive
      ? videoTracks.filter((t) => t.readyState === "live" && t.enabled)
      : videoTracks;
  }

  const tracks = mediaStream.getTracks();
  return onlyLive
    ? tracks.filter((t) => t.readyState === "live" && t.enabled)
    : tracks;
};

export const getMediaTracksForVideo = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  trackType: TrackType,
  onlyLive = false
): MediaStreamTrack[] => {
  const videoElement = videoRef.current;

  if (!videoElement) {
    return [];
  }

  const mediaStream = videoElement.srcObject as MediaStream | null;

  return getMediaTracks(trackType, mediaStream, onlyLive);
};

export const stopMediaTracks = (
  mediaStream: MediaStream | null,
  trackType: TrackType = "all"
): void => {
  if (!mediaStream) {
    return;
  }

  const tracks = getMediaTracks(trackType, mediaStream);

  tracks.forEach((track) => {
    track.stop();
  });
};

export const stopMediaTracksForVideo = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  trackType: TrackType = "all"
): void => {
  const videoElement = videoRef.current;

  if (!videoElement) {
    return;
  }

  const mediaStream = videoElement.srcObject as MediaStream | null;
  stopMediaTracks(mediaStream, trackType);
};

/**
 * Toggle a video element's media track's (audio or video) enabled state.
 * Optionally make it a one-way operation when specifying the setTo parameter (enable or disable).
 */
export const toggleMediaTrack = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  trackType: "video" | "audio",
  setTo?: boolean,
  stopTrack = true
) => {
  const tracks = getMediaTracksForVideo(videoRef, trackType);

  if (tracks.length === 0) {
    logger.error(
      `[📹VideoChat] No ${trackType} tracks found in the media stream!`
    );
    return;
  }

  if (tracks.length > 1) {
    logger.warn(
      `[📹VideoChat] There ${tracks.length} ${trackType} tracks found in the media stream!`
    );
  }

  const mainTrack = tracks[0];

  mainTrack.enabled = setTo !== undefined ? setTo : !mainTrack.enabled;

  if (stopTrack) {
    if (setTo === undefined || setTo) {
      mainTrack.stop();
    }
  }
};

export const countMediaTracksForVideo = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  trackType: "video" | "audio" | "all",
  onlyLive = false
): number => {
  if (!videoRef.current) return 0;

  if (trackType === "all") {
    const audioTracks = getMediaTracksForVideo(videoRef, "audio", onlyLive);
    const videoTracks = getMediaTracksForVideo(videoRef, "video", onlyLive);
    return videoTracks.length + audioTracks.length;
  }

  const tracks = getMediaTracksForVideo(videoRef, trackType, onlyLive);
  return tracks.length;
};

export const isVideoStreamLive = (stream?: MediaStream | null): boolean => {
  if (!stream) {
    return false;
  }

  const videoTracks = stream.getVideoTracks();

  return (
    videoTracks.length > 0 &&
    videoTracks.some(
      (videoTrack) => videoTrack.readyState === "live" && videoTrack.enabled
    )
  );
};

export const isAudioStreamLive = (stream?: MediaStream | null): boolean => {
  if (!stream) {
    return false;
  }

  const audioTracks = stream.getAudioTracks();

  return (
    audioTracks.length > 0 &&
    audioTracks.some(
      (audioTracks) => audioTracks.readyState === "live" && audioTracks.enabled
    )
  );
};

export const isVideoLive = (
  videoRef: RefObject<HTMLVideoElement | null>
): boolean => {
  const video = videoRef.current;

  if (!video) {
    return false;
  }

  const mediaStream = video.srcObject as MediaStream | null;

  return (
    isVideoStreamLive(mediaStream) && !video.paused && video.readyState === 4
  );
};

export const isVoiceLive = (
  videoRef: RefObject<HTMLVideoElement | null>
): boolean => {
  const video = videoRef.current;

  if (!video) {
    return false;
  }

  const mediaStream = video.srcObject as MediaStream | null;

  return isAudioStreamLive(mediaStream);
};

export enum SoundEffect {
  JOIN_ROOM,
  LEAVE_ROOM,
}

type SoundEffectVariant = "default" | "alternative";

const soundEffects = {
  [SoundEffect.JOIN_ROOM]: {
    default: joinSoundEffectDefault,
    alternative: joinSoundEffectAlternative,
  },
  [SoundEffect.LEAVE_ROOM]: {
    default: leaveSoundEffectDefault,
    alternative: leaveSoundEffectAlternative,
  },
};

export const playSoundEffect = (
  soundEffectType: SoundEffect,
  variant: SoundEffectVariant = "alternative",
  volume = 0.1
) => {
  const soundEffect = soundEffects[soundEffectType]?.[variant];

  if (!soundEffect) {
    logger.error("Unknown sound effect:", soundEffectType, variant);
    return;
  }

  const audio = new Audio(soundEffect);
  audio.currentTime = 0; // Reset audio to start if already playing
  audio.volume = volume;
  void audio.play();
};

export const getMicrophoneErrorString = ({
  hasMicrophone,
  hasMicrophonePermission,
}: MediaFeatures) => {
  if (!hasMicrophone) {
    return strings.noMicrophoneDetected;
  }

  if (!hasMicrophonePermission) {
    return strings.microphoneAccessDenied;
  }

  return strings.microphoneWorks;
};

export const getWebcamErrorString = ({
  hasWebcam,
  hasWebcamPermission,
}: MediaFeatures) => {
  if (!hasWebcam) {
    return strings.noWebcamDetected;
  }

  if (!hasWebcamPermission) {
    return strings.webcamAccessDenied;
  }

  return strings.webcamWorks;
};

export const getSectionHeight = () => {
  const firstNavbarHeight =
    getElementHeightById("doctor-navbar") ||
    getElementHeightById("owner-navbar") ||
    getElementHeightById("manager-navbar") ||
    0;

  const footerHeight =
    getElementHeightById("footer") ||
    getElementHeightById("doctor-mobile-menu") ||
    getElementHeightById("owner-mobile-menu") ||
    getElementHeightById("manager-mobile-menu") ||
    0;

  return window.innerHeight - (firstNavbarHeight + footerHeight);
};

// TODO: Video height

// [For debug] Display media information
export const displayMediaDebug = (props: MediaDebugProps): ReactElement => (
  <div className="absolute w-full h-full flex flex-wrap justify-start items-start content-baseline gap-1 pt-16 px-5">
    {Object.entries(props).map(([key, value]) => (
      <p
        key={key}
        className="h-fit text-white bg-gray-500 bg-opacity-40 rounded-full p-2"
      >
        {`${key}: ${
          value !== null && value !== undefined ? value.toString() : value
        }`}
      </p>
    ))}
  </div>
);

// [For debug] Display permissions
export const createMediaFeaturesTable = (
  mediaFeatures: MediaFeatures,
  mediaErrors: MediaErrors
) => (
  <table className="table table-auto">
    <thead>
      <tr>
        <th>Feature</th>
        <th>State</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Microphone device</td>
        <td>{getEnabledIcon(mediaFeatures?.hasMicrophone)}</td>
      </tr>
      <tr>
        <td>Microphone permission</td>
        <td>{getEnabledIcon(mediaFeatures?.hasMicrophonePermission)}</td>
      </tr>
      <tr>
        <td>Microphone works</td>
        <td>{getEnabledIcon(!mediaErrors.micError)}</td>
      </tr>
      <tr>
        <td>Camera device</td>
        <td>{getEnabledIcon(mediaFeatures?.hasWebcam)}</td>
      </tr>
      <tr>
        <td>Camera permission</td>
        <td>{getEnabledIcon(mediaFeatures?.hasWebcamPermission)}</td>
      </tr>
      <tr>
        <td>Camera works</td>
        <td>{getEnabledIcon(!mediaErrors.camError)}</td>
      </tr>
    </tbody>
  </table>
);

// [For debug] Useful for detecting media stream leaks (active videos = started media stream tracks)
export const countActiveVideos = () => {
  const videoElements = document.querySelectorAll("video");
  const idList: string[] = [];

  videoElements.forEach((video) => {
    const mediaStream = video.srcObject as MediaStream;
    if (
      mediaStream &&
      mediaStream.getTracks().some((track) => track.readyState === "live")
    ) {
      idList.push(video.id);
    }
  });

  logger.info("[📹🪲VideoChat Debug] Active video IDs:", idList);
};

// [For debug] Useful for detecting media stream leaks (enables videos = enabled media stream tracks)
export const countEnabledVideos = () => {
  const videoElements = document.querySelectorAll("video");
  const idList: string[] = [];

  videoElements.forEach((video) => {
    const mediaStream = video.srcObject as MediaStream;
    if (mediaStream) {
      const hasEnabledTrack = mediaStream
        .getVideoTracks()
        .some((track) => track.enabled);
      if (hasEnabledTrack) {
        idList.push(video.id);
      }
    }
  });

  logger.info("[📹🪲VideoChat Debug] Enabled video IDs:", idList);
};

// [For debug] List all available media devices
export const listMediaDevices = () => {
  navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      logger.info("[📹🪲VideoChat Debug] Available media devices:");
      devices.forEach((device) => {
        logger.info(
          `Device ID: ${device.deviceId}, Label: ${device.label}, Kind: ${device.kind}`
        );
      });
    })
    .catch((error) => {
      logger.error(
        "[📹🪲VideoChat Debug] Error enumerating media devices:",
        error
      );
    });
};

/**
 * Calculates the duration between a given date and the current moment.
 * @param date - The date object to compare with the current moment.
 * @returns A string representing the duration between the date and now.
 */
export const calculateDuration = (date: Date): string => {
  // Convert the input date to a Moment object
  const givenDate = moment(date);

  // Get the current moment
  const now = moment();

  // Calculate the duration between the current moment and the given date
  const duration: Duration = moment.duration(now.diff(givenDate));

  // Format the duration as a human-readable string
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  // Construct a formatted string based on the non-zero components of the duration
  const parts: string[] = [];
  if (years) parts.push(`${years} year${years > 1 ? "s" : ""}`);
  if (months) parts.push(`${months} month${months > 1 ? "s" : ""}`);
  if (days) parts.push(`${days} day${days > 1 ? "s" : ""}`);
  if (hours) parts.push(`${hours} hour${hours > 1 ? "s" : ""}`);
  if (minutes) parts.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
  if (seconds) parts.push(`${seconds} second${seconds > 1 ? "s" : ""}`);

  return parts.length ? parts.join(", ") : "0 seconds";
};
