/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";

// A simple horizontal line that works in dark mode

interface Props {
  hidden?: boolean;
  margin?: number;
}

const HorizontalLine: React.FC<Props> = ({ hidden, margin = 3 }: Props) => {
  if (hidden) {
    return null;
  }

  return (
    <hr className={`h-px my-${margin} bg-gray-200 border-0 dark:bg-gray-700`} />
  );
};

export default HorizontalLine;
