/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment } from "react";
import { Stakeholder } from "../../../../models/ePrescription/VARA/Stakeholder";
import { CommodityProduct } from "../../../../models/ePrescription/VARA/CommodityProduct";
import Button from "../../../../components/Button";
import { strings } from "../../../../common/Strings/Strings";

interface Props {
  index: number;
  isMobile?: boolean;
  medicine: CommodityProduct;
  selectProduct(product: CommodityProduct): void;
}

const CommodityResultRow: React.FC<Props> = ({
  index,
  isMobile = false,
  medicine,
  selectProduct,
}: Props) => {
  const listStakeholders = (med: CommodityProduct): string[] => {
    const stakeholders: string[] = [];
    med.stakeholders.forEach((s: Stakeholder) => {
      stakeholders.push(s.name);
      stakeholders.push(", ");
    });

    stakeholders.pop();

    return stakeholders;
  };

  if (isMobile) {
    return (
      <Fragment key={index}>
        <tr>
          <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
            <b>{medicine.name}</b> <br />
            {medicine.title}
            {listStakeholders(medicine)} <br />
            {medicine.commodityGroup}
          </td>
          <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
            <Button
              fullWidth={false}
              type="button"
              onClick={() => {
                selectProduct(medicine);
              }}
            >
              {strings.choose}
            </Button>
          </td>
        </tr>
      </Fragment>
    );
  }
  return (
    <Fragment key={index}>
      <tr>
        <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
          <h2 className="font-semibold text-gray-800 dark:text-white">
            {medicine.name}
          </h2>
          <div className="text-sm font-normal text-gray-600 dark:text-gray-400">
            {medicine.title}
          </div>
        </td>
        <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
          {listStakeholders(medicine)}
        </td>
        <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
          {medicine.commodityGroup}
        </td>
        <td className="px-4 py-2 text-sm font-medium whitespace-nowrap text-right">
          <Button
            fullWidth={false}
            type="button"
            onClick={() => {
              selectProduct(medicine);
            }}
          >
             {strings.choose}
          </Button>
        </td>
      </tr>
    </Fragment>
  );
};

export default CommodityResultRow;
