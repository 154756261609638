/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { getAddressString } from "../../../util/helperFunctions";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import { Card } from "../../../components/Cards/Card";
import { ClinicDetailsResponse } from "../../../models/management/ClinicDetailsResponse";
import { BusinessStructure } from "../../../models/clinic/BusinessStructure";
import UnconfirmedEmailTag from "../../Clinic/Settings/Contacts/Emails/UnconfirmedEmailTag";
import { ContactType } from "../../../models/contact/ContactType";
import UnconfirmedPhoneTag from "../../Clinic/Settings/Contacts/Phones/UnconfirmedPhoneTag";
import { ClinicEmploymentType } from "../../../models/employment/ClinicEmploymentType";
import { EmployeeResponse } from "../../../models/user/EmployeeResponse";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import Tooltip from "../../../components/Tooltip";
import { ExclamationMark } from "../../../common/Icons/ExclamationMark";
import { collaboratorGroupTitles } from "../../Clinic/Settings/ClinicUsers/CollaboratorsTab";
import EmptyListText from "../../../components/EmptyListText";
import { Link } from "react-router-dom";

interface Props {
  clinic: ClinicDetailsResponse;
}

const ClinicBasicInfoTab: React.FC<Props> = (props: Props) => {
  const { clinic } = props;

  const employeesOfType = (employeeType: string): Array<EmployeeResponse> => {
    switch (employeeType) {
      case "MANAGER":
        return clinic.managers;
      case "VET":
        return clinic.vets;
      case "ASSISTANT":
        return clinic.assistants;
      case "AUTHORIZED_ASSISTANT":
        return clinic.authorizedAssistants;
      case "RECEPTIONIST":
        return clinic.receptionists;
      default:
        return [];
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <Card size="max" title={strings.basicInfo} type="simple">
            <div>
              <div>
                <div>{strings.clinicName}</div>
                <div className="bold">{clinic.name}</div>
              </div>
              <div className="mt-3">
                <div>{strings.businessStructure}</div>
                <div className="bold">
                  {getStringFromEnumsOrReturn(clinic.businessStructure)}
                </div>
              </div>
              <div className="mt-3">
                <div>{strings.businessName}</div>
                <div className="bold">{clinic.businessName}</div>
              </div>
              <div className="mt-3">
                <div>{strings.officialLanguage}</div>
                <div className="bold">{clinic.language}</div>
              </div>
              <div className="mt-3">
                <div>{strings.vatNumber}</div>
                <div className="bold">{clinic.vat}</div>
              </div>
              <div className="mt-3">
                <div>
                  {clinic.businessStructure === BusinessStructure.COMPANY
                    ? strings.organisationId
                    : strings.personalId}
                </div>
                <div className="bold">{clinic.registrationNumber}</div>
              </div>
            </div>
          </Card>
          <div className="mt-5">
            <Card size="max" title={strings.bankAndBilling} type="simple">
              <div>
                <div>
                  <div>{strings.creditCard}</div>
                  <div className="bold" hidden={!clinic.cardHolder}>
                    {clinic.cardHolder}
                  </div>
                  <div
                    className="bold"
                    hidden={!clinic.last4}
                  >{`**** **** **** ${clinic.last4}`}</div>
                </div>
                <div className="mt-3" hidden={!clinic.giroAccounts?.length}>
                  <div>{strings.bankAndPlusGiroAccount}</div>
                  {clinic.giroAccounts?.map((account) => (
                    <div className="flex flex-wrap items-center">
                      <div className="bold">
                        {account.type}: {account.account}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-3">
                  <div>{strings.stripeIntegration}</div>
                  <div className="bold">
                    {clinic.stripeEnabled
                      ? strings.integrated
                      : strings.notIntegrated}
                  </div>
                </div>
                <div className="mt-3">
                  <div>{strings.fortnoxInvoiceSystem}</div>
                  <div className="bold" hidden={clinic.fortnoxEnabled}>
                    {strings.notIntegrated}
                  </div>
                  <div className="bold" hidden={!clinic.fortnoxEnabled}>
                    {clinic.fortnoxAuthenticationRequired
                      ? strings.authenticationNeeded
                      : strings.integrated}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <Card size="max" title={strings.contacts} type="simple">
            <div>
              <div>
                <div>{strings.phones}</div>
                {clinic?.phones?.map((phone) => (
                  <div className="flex flex-wrap items-center mb-2">
                    <div className="bold">{phone.value}</div>
                    <UnconfirmedPhoneTag phone={phone} onClick={() => {}} />
                    <button
                      className={`tw-btn-pill-tercier-small ${
                        phone.confirmed ? "mx-3" : ""
                      }`}
                      disabled
                      hidden={phone.contactType !== ContactType.PRIMARY}
                      type="button"
                    >
                      {strings.primary}
                    </button>
                  </div>
                ))}
              </div>
              <div className="mt-3">
                <div>{strings.emails}</div>
                {clinic?.emails?.map((email) => (
                  <div className="flex flex-wrap items-center mb-2">
                    <div className="bold">{email.value}</div>
                    <UnconfirmedEmailTag email={email} onClick={() => {}} />
                    <button
                      className={`tw-btn-pill-tercier-small ${
                        email.confirmed ? "mx-3" : ""
                      }`}
                      disabled
                      hidden={email.contactType !== ContactType.PRIMARY}
                      type="button"
                    >
                      {strings.primary}
                    </button>
                  </div>
                ))}
              </div>
              <div className="mt-3">
                <div>{strings.addresses}</div>
                {clinic?.addresses?.map((address) => (
                  <div className="flex flex-wrap items-center mb-2">
                    <div className="bold mr-3">{getAddressString(address)}</div>
                    <button
                      className="tw-btn-pill-tercier-small"
                      disabled
                      hidden={address.contactType !== ContactType.PRIMARY}
                      type="button"
                    >
                      {strings.primary}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </Card>
          <div className="mt-5">
            <Card size="max" title={strings.collaborators} type="simple">
              <div className="tw-table-container">
                <table className="tw-table">
                  <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                    <tr className="bg-gray-50 dark:bg-gray-800">
                      <td
                        colSpan={5}
                        className="px-4 py-2 text-sm font-bold whitespace-nowrap uppercase"
                      >
                        {strings.owner}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                          <Link
                            to={`/management/users/${clinic.owner?.userId}`}
                          >
                            {clinic?.owner?.fullName}
                          </Link>
                        </div>
                      </td>
                    </tr>

                    {Object.keys(collaboratorGroupTitles).map(
                      (employmentType) => {
                        const employees = employeesOfType(employmentType);
                        const collaboratorGroupTitle =
                          collaboratorGroupTitles[
                            employmentType as ClinicEmploymentType
                          ];

                        return (
                          <>
                            <tr className="bg-gray-50 dark:bg-gray-800">
                              <td
                                colSpan={5}
                                className="px-4 py-2 text-sm font-bold whitespace-nowrap uppercase"
                              >
                                {collaboratorGroupTitle}
                              </td>
                            </tr>
                            <tr hidden={employees.length > 0}>
                              <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                                <EmptyListText />
                              </td>
                            </tr>
                            {employees.map((employee: EmployeeResponse) => (
                              <tr key={employee.userId}>
                                <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                                  <div className="flex items-center">
                                    <span>
                                      {!employee.request ? (
                                        <CheckCircle className="text-green-600 h-5 w-5" />
                                      ) : (
                                        <Tooltip
                                          content={strings.pendingRequest}
                                          placement="right"
                                        >
                                          <ExclamationMark className="text-orange-500 h-5 w-5" />
                                        </Tooltip>
                                      )}
                                    </span>
                                    <span className="ml-2">
                                      <Link
                                        to={`/management/users/${employee.userId}`}
                                      >
                                        {employee.fullName ||
                                          employee.email?.value ||
                                          employee?.request?.requestedEmail}
                                      </Link>
                                      <span
                                        hidden={!employee.deleted}
                                        className="ml-2 badge badge-danger"
                                      >
                                        {strings.deletedProfile}
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicBasicInfoTab;
