/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { SocialLoginRequest } from "../models/login/SocialLoginRequest";
import { OAuth2AccessToken } from "../models/login/OAuth2AccessToken";
import Params from "../common/Params";

export default class SocialLoginApi {
  private static baseUrl = `${Params.authBaseURL}${Params.authPrefix}`;

  static loginWithFacebook(accessToken: string): ApiPromise<OAuth2AccessToken> {
    const url = `${this.baseUrl}/login/facebook`;
    const request: SocialLoginRequest = { accessToken };
    return axiosCommon.post(url, request);
  }

  static loginWithGoogle(accessToken: string): ApiPromise<OAuth2AccessToken> {
    const url = `${this.baseUrl}/login/google`;
    const request: SocialLoginRequest = { accessToken };
    return axiosCommon.post(url, request);
  }
}
