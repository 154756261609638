/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import Prices, { PriceInputProps } from "../../InputFieldsSimple/Prices";

interface Props extends PriceInputProps {
  control: Control<any>;
  fieldOptions?: RegisterOptions;
  name: string;
}

const PriceInputs: React.FC<Props> = ({
  name,
  control,
  fieldOptions,
  required,
  onChange: customOnChange,
  ...rest
}: Props) => (
  <Controller
    name={name}
    control={control}
    rules={{
      ...fieldOptions,
      required,
      validate: {
        positive: (v) => v === undefined || Number(v) >= 0,
      },
    }}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <Prices
        error={error}
        onChange={(v?: number) => {
          onChange(v !== undefined ? v : null);
          if (customOnChange) {
            customOnChange(v);
          }
        }}
        value={value}
        {...rest}
      />
    )}
  />
);

export default PriceInputs;
