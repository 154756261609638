/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import PKCE from "js-pkce";
import ITokenResponse from "js-pkce/dist/ITokenResponse";
import IObject from "js-pkce/dist/IObject";
import formUrlEncoded from "form-urlencoded";
import Params from "../common/Params";
import { axiosCommon, axiosRefresh } from "../util/axiosSetup";
import Urls from "../util/Urls";
import { ApiPromise } from "../models/ApiPromise";

export default class AuthApi {
  private static baseUrl = `${Params.authBaseURL}${Params.authPrefix}`;

  private static pkce = new PKCE({
    client_id: "globalvet",
    redirect_uri: `${Params.frontendBaseURL}/login`,
    authorization_endpoint: `${this.baseUrl}/oauth2/authorize`,
    token_endpoint: `${this.baseUrl}/oauth2/token`,
    requested_scopes: "user",
  });

  static exchangeForAccessToken(
    url: string,
    additionalParams?: IObject
  ): Promise<ITokenResponse> {
    return this.pkce.exchangeForAccessToken(url, additionalParams);
  }

  static refreshAccessToken(refreshToken: string): ApiPromise<ITokenResponse> {
    const url = `${this.baseUrl}/oauth2/token`;
    const params = {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      scope: "user",
    };
    const config = {
      auth: {
        username: Urls.CLIENT_NAME,
        password: Urls.CLIENT_SECRET,
      },
    };
    return axiosRefresh.post(url, formUrlEncoded(params), config);
  }

  static authorizeUrl(additionalParams?: IObject): string {
    return this.pkce.authorizeUrl(additionalParams);
  }

  static logoutOnServer() {
    const url = `${this.baseUrl}/logout`;
    return axiosCommon.get(url, { withCredentials: true });
  }
}
