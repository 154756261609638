/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../AlertBox";
import { PhoneResponse } from "../../../models/contact/PhoneResponse";
import VerifyPhone from "../../contact/user/phone/VerifyPhone";
import Modal from "../../Modal/Modal";
import CloseButton from "../../CloseButton";

interface Props {
  close(): void;
  error: string | null;
  isOpen: boolean;
  loading: boolean;
  onSubmit(smsCode: string): void;
  onResendCode(): void;
  phoneToConfirm: PhoneResponse | null;
}

const ConfirmPhoneModal: React.FC<Props> = ({
  isOpen,
  error,
  loading,
  onSubmit,
  onResendCode,
  close,
  phoneToConfirm,
}: Props) => (
  <Modal handleClose={close} show={isOpen}>
    <Modal.Header title={strings.verifyYourPhone} />
    <Modal.Body>
      <VerifyPhone
        loading={loading}
        onSubmitCode={onSubmit}
        phoneToConfirm={phoneToConfirm}
        onResendCode={onResendCode}
      />
      <AlertBox message={error} />
    </Modal.Body>
    <Modal.Footer extraClassName="justify-end">
      <CloseButton
        asMain
        disabled={loading}
        loading={loading}
        onClick={close}
        text={strings.cancel}
      />
    </Modal.Footer>
  </Modal>
);

export default ConfirmPhoneModal;
