/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./common/Footer";
import CookieApproval from "./components/CookieApproval";
import { ClinicProvider } from "./contexts/ClinicContext";
import { LocaleProvider } from "./contexts/LocaleContext";
import { MaintenanceProvider } from "./contexts/MaintenanceContext";
import { SpeciesProvider } from "./contexts/SpeciesContext";
import { UserProvider } from "./contexts/UserContext";
import FortnoxAuthPage from "./pages/Accounting/FortnoxAuthPage";
import InvoiceDetailsPage from "./pages/Accounting/InvoiceDetails/InvoiceDetailsPage";
import OwnerInvoicePage from "./pages/PetOwner/UserPetOwner/Invoices/OwnerInvoicePage";
import { ClinicDeletionConfirmation } from "./pages/GlobalVet/Settings/DeleteClinic/ClinicDeletionConfirmation";
import ClinicInvoices from "./pages/Clinic/ClinicInvoices";
import CreateClinic from "./pages/Clinic/CreateClinic";
import ClinicSettings from "./pages/Clinic/Settings/ClinicSettings";
import Device from "./pages/Device";
import Prescription from "./pages/ePrescription/MainEPrescription";
import Prescriptions from "./pages/ePrescriptionHistory/PrescriptionHistoryMain";
import Inventory from "./pages/Inventory/Inventory";
import InventoryItem from "./pages/Inventory/CreateItem/InventoryItemPage";
import InventoryItemDetails from "./pages/Inventory/Details/InventoryItemDetailsPage";
import ItemCounting from "./pages/Inventory/Counting/ItemCountingPage";
import Main from "./pages/Main/Main";
import MainDoctor from "./pages/Main/Onboarding/MainDoctor";
import Management from "./pages/Manager/Dashboard";
import MedicalRecord from "./pages/MedicalRecord/MedicalRecord";
import OpenLastMedicalRecord from "./pages/MedicalRecord/OpenLastMedicalRecord";
import NotFound from "./pages/NotFound";
import AddNewPet from "./pages/Pet/CreateNewPet/AddNewPet";
import CreatePetAndOwner from "./pages/PetOwner/ClinicPetOwner/FinishPetRegistration/CreatePetAndOwner";
import EditPet from "./pages/Pet/EditPet/EditPet";
import ClinicPetProfile from "./pages/Pet/PetProfile/ClinicPetProfile";
import UserPetProfile from "./pages/Pet/PetProfile/UserPetProfile";
import Appointments from "./pages/PetOwner/UserPetOwner/Appointments/Appointments";
import CreatePetOwner from "./pages/PetOwner/ClinicPetOwner/CreatePetOwner/CreatePetOwner";
import EditPetOwner from "./pages/PetOwner/ClinicPetOwner/EditPetOwner";
import PetOwners from "./pages/PetOwner/ClinicPetOwner/Owners/PetOwners";
import PetOwnerProfile from "./pages/PetOwner/ClinicPetOwner/PetOwnerProfile";
import OwnerCalendarPage from "./pages/PetOwner/UserPetOwner/OwnerCalendarPage";
import OwnerReservation from "./pages/PetOwner/UserPetOwner/OwnerReservation";
import MyPetsPage from "./pages/PetOwner/UserPetOwner/Pets/MyPetsPage";
import PosteriorRegistrations from "./pages/PetOwner/UserPetOwner/Pets/PendingPets/PendingPetsPage";
import SentryTestPage from "./pages/SentryTestPage";
import Support from "./pages/Support/Support";
import SupportTopics from "./pages/Support/Topics";
import { TicketCreationPage } from "./pages/Support/TicketCreationPage";
import TicketPage from "./pages/Support/Ticket/TicketPage";
import { UserTicketsPage } from "./pages/Support/UserTicket/UserTicketsPage";
import CreateTreatmentPage from "./pages/Treatments/CreateAndEditTreatment/CreateTreatmentPage";
import EmploymentConfirmation from "./pages/UserManagement/EmploymentConfirmation";
import Login from "./pages/UserManagement/Login";
import MfaConfirm from "./pages/UserManagement/MfaConfirm";
import PasswordRecovery from "./pages/UserManagement/PasswordRecovery/PasswordRecovery";
import UserProfileSetup from "./pages/UserManagement/Registration/UserProfileSetup";
import SocialRegistrationSuccessful from "./pages/UserManagement/Registration/RegistrationSteps/RegistrationSuccessful/SocialRegistrationSuccessful";
import ResetPassword from "./pages/UserManagement/ResetPassword";
import UserRegistration from "./pages/UserManagement/Registration/UserRegistration";
import ErrorBoundary from "./util/ErrorBoundary";
import RouteWrapper from "./util/ReactRouter/RouteWrapper";
import ScrollToTop from "./util/ReactRouter/ScrollToTop";
import FeedbackPage from "./pages/Feedback/FeedbackPage";
import OptionalSettings from "./pages/Main/Onboarding/OptionalProfileSteps";
import BillingPage from "./pages/Manager/BillingPage";
import VetDocumentsPage from "./pages/Manager/VetDocumentsPage/VetDocumentsPage";
import InsurancePage from "./pages/Manager/InsurancePage/InsurancePage";
import UsersPage from "./pages/Manager/Users/UsersPage";
import FeedbacksPage from "./pages/Manager/FeedbacksPage";
import ManagerSettingsPage from "./pages/Manager/ManagerSettingsPage";
import CountrySettingsPage from "./pages/Manager/CountrySettingsPage";
import CreateTestInvoice from "./pages/Manager/TestInvoice/CreateTestInvoice";
import Todos from "./pages/Todo/Todos";
import CreateInvoicePage from "./pages/Accounting/CreateInvoice/CreateInvoicePage";
import InvoicePage from "./pages/Accounting/InvoicePage";
import ReservationDeletionPage from "./pages/PetOwner/UserPetOwner/Appointments/ReservationDeletionPage";
import UserEmailConfirmation from "./pages/Contact/EmailConfirmation/UserEmailConfirmation";
import GlobalVetSettings from "./pages/GlobalVet/GlobalVetSettings";
import ClinicEmailConfirmation from "./pages/Contact/EmailConfirmation/ClinicEmailConfirmation";
import ClinicsPage from "./pages/Main/Onboarding/ClinicSide/ClinicsPage";
import { NotificationProvider } from "./contexts/NotificationContext";
import ProfileSettings from "./pages/UserManagement/ProfileSettings/ProfileSettings";
import { ModalProvider } from "./contexts/ModalContext";
import ManageCookies from "./pages/Policy/PolicyTypes/ManageCookies";
import GDPRConsent from "./pages/Policy/PolicyTypes/GDPRConsent";
import PrivacyPolicy from "./pages/Policy/PolicyTypes/PrivacyPolicy";
import GeneralTermsAndConditions from "./pages/Policy/PolicyTypes/GeneralTermsAndConditions";
import DataProcessingTermsAndConditions from "./pages/Policy/PolicyTypes/DataProcessingTermsAndConditions";
import AnalyticsEventsPage from "./pages/Manager/Analytics/AnalyticsEventsPage";
import ClinicListPage from "./pages/Manager/Clinics/ClinicListPage";
import VideoConsultation from "./pages/VideoConsultation/VideoConsultation";
import ClinicDetailsPage from "./pages/Manager/Clinics/ClinicDetailsPage";
import UserDetailsPage from "./pages/Manager/Users/UserDetailsPage";
import RoomMonitor from "./pages/Manager/VideoConsultation/VideoChatMonitor";

function App(): ReactElement {
  return (
    <BrowserRouter>
      <CookieApproval />
      <ScrollToTop>
        <div className="App_root">
          <UserProvider>
            <NotificationProvider>
              <ClinicProvider>
                <LocaleProvider>
                  <SpeciesProvider>
                    <MaintenanceProvider>
                      <ModalProvider>
                        <ErrorBoundary>
                          <Routes>
                            <Route
                              path="/sentry-test"
                              element={
                                <RouteWrapper component={SentryTestPage} />
                              }
                            />
                            <Route
                              path="/"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={Main}
                                />
                              }
                            />
                            <Route
                              path="/pet-owner"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="MY_PETS"
                                  requiresAuthentication
                                  component={MyPetsPage}
                                />
                              }
                            />
                            <Route
                              path="/vet"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="CALENDAR"
                                  requiresAuthentication
                                  component={MainDoctor}
                                />
                              }
                            />
                            <Route
                              path="/management"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  availableDuringMaintenance
                                  component={Management}
                                />
                              }
                            />
                            <Route
                              path="/management/settings"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="SITE_MANAGER_SETTINGS"
                                  requiresAuthentication
                                  availableDuringMaintenance
                                  component={ManagerSettingsPage}
                                />
                              }
                            />
                            <Route
                              path="/management/countries/:countryCode"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="SITE_MANAGER_SETTINGS"
                                  requiresAuthentication
                                  component={CountrySettingsPage}
                                />
                              }
                            />
                            <Route
                              path="/management/countries/:countryCode/test-invoice"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="SITE_MANAGER_SETTINGS"
                                  requiresAuthentication
                                  component={CreateTestInvoice}
                                />
                              }
                            />
                            <Route
                              path="/management/countries/:countryCode/fortnox-auth"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="SITE_MANAGER_SETTINGS"
                                  requiresAuthentication
                                  component={FortnoxAuthPage}
                                />
                              }
                            />
                            <Route
                              path="/management/countries/:countryCode/:tab"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="SITE_MANAGER_SETTINGS"
                                  requiresAuthentication
                                  component={CountrySettingsPage}
                                />
                              }
                            />
                            <Route
                              path="/management/countries/:countryCode"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="SITE_MANAGER_SETTINGS"
                                  requiresAuthentication
                                  component={CountrySettingsPage}
                                />
                              }
                            />
                            <Route
                              path="/management/billing"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="BILLING"
                                  requiresAuthentication
                                  component={BillingPage}
                                />
                              }
                            />
                            <Route
                              path="/management/vet-documents"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="VET_DOCUMENTS"
                                  requiresAuthentication
                                  component={VetDocumentsPage}
                                />
                              }
                            />
                            <Route
                              path="/management/insurance"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="INSURANCE"
                                  requiresAuthentication
                                  component={InsurancePage}
                                />
                              }
                            />
                            <Route
                              path="/management/users"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="USERS"
                                  requiresAuthentication
                                  component={UsersPage}
                                />
                              }
                            />
                            <Route
                              path="/management/users/:userId"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="USERS"
                                  requiresAuthentication
                                  component={UserDetailsPage}
                                />
                              }
                            />
                            <Route
                              path="/management/feedbacks"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="FEEDBACKS"
                                  requiresAuthentication
                                  component={FeedbacksPage}
                                />
                              }
                            />
                            <Route
                              path="/management/analytics/events"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="ANALYTICS"
                                  requiresAuthentication
                                  component={AnalyticsEventsPage}
                                />
                              }
                            />
                            <Route
                              path="/management/videochat-monitor/"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="VIDEOCHAT_MONITOR"
                                  requiresAuthentication
                                  component={RoomMonitor}
                                />
                              }
                            />
                            <Route
                              path="/management/clinics"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="CLINICS"
                                  requiresAuthentication
                                  component={ClinicListPage}
                                />
                              }
                            />
                            <Route
                              path="/management/clinics/:clinicId"
                              element={
                                <RouteWrapper
                                  activeSiteManagerMenu="CLINICS"
                                  requiresAuthentication
                                  component={ClinicDetailsPage}
                                />
                              }
                            />
                            <Route
                              path="/auth/:email/:code"
                              element={<RouteWrapper component={MfaConfirm} />}
                            />
                            <Route
                              path="/invoices/new"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="SALES"
                                  component={CreateInvoicePage}
                                />
                              }
                            />
                            <Route
                              path="/invoices/edit/:invoiceId/:type"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="SALES"
                                  component={CreateInvoicePage}
                                />
                              }
                            />
                            <Route
                              path="/invoices/clinic/:invoiceId/*"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="SALES"
                                  component={InvoicePage}
                                />
                              }
                            />
                            <Route
                              path="/invoices/:longInvoiceId/*"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="INVOICES"
                                  component={InvoiceDetailsPage}
                                />
                              }
                            />
                            <Route
                              path="/invoices"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="INVOICES"
                                  requiresAuthentication
                                  component={OwnerInvoicePage}
                                />
                              }
                            />
                            <Route
                              path="/appointments"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="APPOINTMENTS"
                                  requiresAuthentication
                                  component={Appointments}
                                />
                              }
                            />
                            <Route
                              path="/registration"
                              element={
                                <RouteWrapper
                                  component={UserRegistration}
                                  onlyUnauthenticated
                                />
                              }
                            />
                            <Route
                              path="/social-registration-successful"
                              element={
                                <RouteWrapper
                                  component={SocialRegistrationSuccessful}
                                  onlyUnauthenticated
                                />
                              }
                            />
                            <Route
                              path="/profile/setup"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={UserProfileSetup}
                                />
                              }
                            />
                            <Route
                              path="/profile/doctor-setup"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={OptionalSettings}
                                />
                              }
                            />
                            <Route
                              path="/user-emails/:emailId/confirm/:token"
                              element={
                                <RouteWrapper
                                  component={UserEmailConfirmation}
                                />
                              }
                            />
                            <Route
                              path="/clinic-emails/:emailId/confirm/:token"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={ClinicEmailConfirmation}
                                />
                              }
                            />
                            <Route
                              path="/password-recovery"
                              element={
                                <RouteWrapper component={PasswordRecovery} />
                              }
                            />
                            <Route
                              path="/reset-password/:tokenId/:token"
                              element={
                                <RouteWrapper component={ResetPassword} />
                              }
                            />
                            <Route
                              path="/clinic-deletion-confirmation/:clinicId"
                              element={
                                <RouteWrapper
                                  component={ClinicDeletionConfirmation}
                                />
                              }
                            />
                            <Route
                              path="/clinics/create"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="SETTINGS"
                                  requiresAuthentication
                                  component={CreateClinic}
                                  isSecondNavbarDisabled
                                />
                              }
                            />
                            <Route
                              path="/medical-record/:recordId"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={MedicalRecord}
                                />
                              }
                            />
                            <Route
                              path="/medical-record/:universalId/latest"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={MedicalRecord}
                                />
                              }
                            />
                            <Route
                              path="/prescription/:clinicPetId"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="PRESCRIPTIONS"
                                  requiresAuthentication
                                  component={Prescription}
                                  isOnlySwedish
                                />
                              }
                            />
                            <Route
                              path="/prescription"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="PRESCRIPTIONS"
                                  requiresAuthentication
                                  component={Prescription}
                                  isOnlySwedish
                                />
                              }
                            />
                            <Route
                              path="/prescriptions/:prescriptionId"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="PRESCRIPTIONS"
                                  requiresAuthentication
                                  component={Prescriptions}
                                />
                              }
                            />
                            <Route
                              path="/reservation/:resId/medical-record"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={MedicalRecord}
                                />
                              }
                            />
                            <Route
                              path="/last-medical-record/:petId"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={OpenLastMedicalRecord}
                                />
                              }
                            />
                            <Route
                              path="/reservation/:resId/medical-record/pet-and-owner"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  activeDoctorMenu="CALENDAR"
                                  component={CreatePetAndOwner}
                                />
                              }
                            />
                            <Route
                              path="/login"
                              element={
                                <RouteWrapper
                                  availableDuringMaintenance
                                  component={Login}
                                  onlyUnauthenticated
                                />
                              }
                            />
                            <Route
                              path="/profile-settings/*"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={ProfileSettings}
                                />
                              }
                            />
                            <Route
                              path="/owners"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="OWNERS"
                                  requiresAuthentication
                                  component={PetOwners}
                                />
                              }
                            />
                            <Route
                              path="/todos"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="TASKS"
                                  requiresAuthentication
                                  component={Todos}
                                />
                              }
                            />
                            <Route
                              path="/pet-profile/:petId"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="MY_PETS"
                                  requiresAuthentication
                                  component={UserPetProfile}
                                />
                              }
                            />
                            <Route
                              path="/clinic-pet-profile/:clinicPetId/:tabKey"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="MY_PETS"
                                  requiresAuthentication
                                  component={ClinicPetProfile}
                                />
                              }
                            />
                            <Route
                              path="/create-pet-owner"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="OWNERS"
                                  requiresAuthentication
                                  component={CreatePetOwner}
                                />
                              }
                            />
                            <Route
                              path="/pet-owner-profile/:petOwnerId"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="OWNERS"
                                  requiresAuthentication
                                  component={PetOwnerProfile}
                                />
                              }
                            />
                            <Route
                              path="/edit-pet-owner-profile/:petOwnerId"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="OWNERS"
                                  requiresAuthentication
                                  component={EditPetOwner}
                                />
                              }
                            />
                            <Route
                              path="/add-new-pet"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="MY_PETS"
                                  requiresAuthentication
                                  component={AddNewPet}
                                />
                              }
                            />
                            <Route
                              path="/edit-pet/:userPetId"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="MY_PETS"
                                  requiresAuthentication
                                  component={EditPet}
                                />
                              }
                            />
                            <Route
                              path="/add-new-clinic-pet/:clinicPetOwnerId"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={AddNewPet}
                                />
                              }
                            />
                            <Route
                              path="/edit-clinic-pet/:clinicPetId"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={EditPet}
                                />
                              }
                            />
                            <Route
                              path="/device"
                              element={<RouteWrapper component={Device} />}
                            />
                            <Route
                              path="/reservation/calendar"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="APPOINTMENTS"
                                  requiresAuthentication
                                  component={OwnerCalendarPage}
                                />
                              }
                            />
                            <Route
                              path="/reservations/:reservationId/delete"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="APPOINTMENTS"
                                  requiresAuthentication
                                  component={ReservationDeletionPage}
                                />
                              }
                            />
                            <Route
                              path="/reservation/:userPetId"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="APPOINTMENTS"
                                  requiresAuthentication
                                  component={OwnerReservation}
                                />
                              }
                            />
                            <Route
                              path="/reservation"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="APPOINTMENTS"
                                  requiresAuthentication
                                  component={OwnerReservation}
                                />
                              }
                            />
                            <Route
                              path="/treatments"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="SETTINGS"
                                  requiresAuthentication
                                  component={CreateTreatmentPage}
                                />
                              }
                            />
                            <Route
                              path="/treatments/:treatmentId"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="SETTINGS"
                                  requiresAuthentication
                                  component={CreateTreatmentPage}
                                />
                              }
                            />
                            <Route
                              path="/inventory"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="INVENTORY"
                                  requiresAuthentication
                                  component={Inventory}
                                />
                              }
                            />
                            <Route
                              path="/inventory/create-item"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="INVENTORY"
                                  requiresAuthentication
                                  component={InventoryItem}
                                />
                              }
                            />
                            <Route
                              path="/inventory/item/:itemId/edit"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="INVENTORY"
                                  requiresAuthentication
                                  component={InventoryItem}
                                />
                              }
                            />
                             <Route
                              path="/inventory/item/:itemId/details/*"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="INVENTORY"
                                  requiresAuthentication
                                  component={InventoryItemDetails}
                                />
                              }
                            />
                            <Route
                              path="/inventory/item/:itemId/counting-stock"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="INVENTORY"
                                  requiresAuthentication
                                  component={ItemCounting}
                                />
                              }
                            />
                            <Route
                              path="/sales"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="SALES"
                                  requiresAuthentication
                                  component={ClinicInvoices}
                                />
                              }
                            />
                            <Route
                              path="/prescriptions"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="PRESCRIPTIONS"
                                  requiresAuthentication
                                  component={Prescriptions}
                                />
                              }
                            />
                            <Route
                              path="/prescriptions/pet/:petId"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="PRESCRIPTIONS"
                                  requiresAuthentication
                                  component={Prescriptions}
                                />
                              }
                            />
                            <Route
                              path="/mypets/prescriptions"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="PRESCRIPTIONS"
                                  requiresAuthentication
                                  component={Prescriptions}
                                />
                              }
                            />
                            <Route
                              path="/pending-pets"
                              element={
                                <RouteWrapper
                                  activePetOwnerMenu="MY_PETS"
                                  requiresAuthentication
                                  component={PosteriorRegistrations}
                                />
                              }
                            />
                            <Route
                              path="/meet/:roomId"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={VideoConsultation}
                                />
                              }
                            />
                            <Route
                              path="/clinic-employment-requests/:code"
                              element={
                                <RouteWrapper
                                  requiresAuthentication
                                  component={EmploymentConfirmation}
                                />
                              }
                            />
                            <Route
                              path="/clinic-settings/*"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="SETTINGS"
                                  requiresAuthentication
                                  component={ClinicSettings}
                                />
                              }
                            />
                            <Route
                              path="/clinics/:clinicId/fortnox-auth"
                              element={
                                <RouteWrapper
                                  activeDoctorMenu="SETTINGS"
                                  requiresAuthentication
                                  component={FortnoxAuthPage}
                                />
                              }
                            />
                            <Route
                              path="/gdpr-consent"
                              element={<RouteWrapper component={GDPRConsent} />}
                            />
                            <Route
                              path="/gdpr-consent/:countryCode"
                              element={<RouteWrapper component={GDPRConsent} />}
                            />
                            <Route
                              path="/general-terms-and-conditions"
                              element={
                                <RouteWrapper
                                  component={GeneralTermsAndConditions}
                                />
                              }
                            />
                            <Route
                              path="/general-terms-and-conditions/:countryCode"
                              element={
                                <RouteWrapper
                                  component={GeneralTermsAndConditions}
                                />
                              }
                            />
                            <Route
                              path="/data-processing-terms-and-conditions"
                              element={
                                <RouteWrapper
                                  component={DataProcessingTermsAndConditions}
                                />
                              }
                            />
                            <Route
                              path="/data-processing-terms-and-conditions/:countryCode"
                              element={
                                <RouteWrapper
                                  component={DataProcessingTermsAndConditions}
                                />
                              }
                            />
                            <Route
                              path="/privacy-policy"
                              element={
                                <RouteWrapper component={PrivacyPolicy} />
                              }
                            />
                            <Route
                              path="/cookies"
                              element={
                                <RouteWrapper component={ManageCookies} />
                              }
                            />
                            <Route
                              path="/support/tickets/:ticketId"
                              element={
                                <RouteWrapper
                                  component={TicketPage}
                                  onlyUnauthenticated
                                />
                              }
                            />
                            <Route
                              path="/support/new-ticket"
                              element={
                                <RouteWrapper component={TicketCreationPage} />
                              }
                            />
                            <Route
                              path="/support/tickets"
                              element={
                                <RouteWrapper component={UserTicketsPage} />
                              }
                            />
                            <Route
                              path="/help"
                              element={<RouteWrapper component={Support} />}
                            />
                            <Route
                              path="/help/topics/:topic"
                              element={
                                <RouteWrapper component={SupportTopics} />
                              }
                            />
                            <Route
                              path="/feedback/:feedbackId"
                              element={
                                <RouteWrapper component={FeedbackPage} />
                              }
                            />
                            <Route
                              path="/globalvet/clinics"
                              element={
                                <RouteWrapper
                                  component={ClinicsPage}
                                  isSecondNavbarDisabled
                                  requiresAuthentication
                                />
                              }
                            />
                            <Route
                              path="/globalvet/settings/:clinicId/*"
                              element={
                                <RouteWrapper
                                  component={GlobalVetSettings}
                                  isSecondNavbarDisabled
                                />
                              }
                            />
                            <Route
                              path="*"
                              element={
                                <RouteWrapper
                                  availableDuringMaintenance
                                  component={NotFound}
                                />
                              }
                            />
                          </Routes>
                        </ErrorBoundary>
                        <Footer />
                      </ModalProvider>
                    </MaintenanceProvider>
                  </SpeciesProvider>
                </LocaleProvider>
              </ClinicProvider>
            </NotificationProvider>
          </UserProvider>
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
