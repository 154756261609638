/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { TreatmentResponse } from "../../../models/treatment/TreatmentResponse";
import { MedicalTreatmentResponse } from "../../../models/medical/MedicalTreatmentResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import TreatmentDetails from "../../Treatments/TreatmentDetails";
import ChevronButtonUp from "../../../components/Buttons/ChevronButtonUp";
import ChevronButtonDown from "../../../components/Buttons/ChevronButtonDown";
import { XMark } from "../../../common/Icons/XMark";
import Button from "../../../components/Button";
import { useClinic } from "../../../contexts/ClinicContext";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";

interface Props {
  canEdit: boolean;
  countryDetails?: CountryDetailsResponse;
  markTreatmentToDelete: () => void;
  medicalTreatment: MedicalTreatmentResponse;
  treatment?: TreatmentResponse;
  quantity: number;
}

const TreatmentInfoBlock: React.FC<Props> = ({
  canEdit,
  countryDetails,
  markTreatmentToDelete,
  medicalTreatment,
  treatment,
  quantity,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { clinic } = useClinic();

  return (
    <div key={treatment?.id} className="tw-card px-3 py-2">
      <div className="flex">
        <Button
          variant="basic"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div className="flex items-center">
            <div className="font-bold text-base flex space-x-3">
              {isOpen ? <ChevronButtonUp /> : <ChevronButtonDown />}
              <div>{quantity}x</div>
              <div>{treatment?.name || medicalTreatment?.name}</div>
            </div>
          </div>
        </Button>
        <div className="ml-auto flex items-center">
          {canEdit ? null : (
            <Button variant="basic" onClick={markTreatmentToDelete}>
              <XMark className="w-5 h-5" />
            </Button>
          )}
        </div>
      </div>
      <div hidden={!isOpen}>
        <AlertBox
          hidden={!!treatment}
          message={strings.contentNotAvailable}
          type={AlertType.WARNING}
        />
        <div>
          {treatment && (
            <TreatmentDetails
              clinic={clinic || undefined}
              countryDetails={countryDetails}
              treatment={treatment}
              canEdit
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TreatmentInfoBlock;
