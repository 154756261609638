/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { InputModeEnum } from "../../models/InputModeEnum";
import { strings } from "../../common/Strings/Strings";
import LoaderInline from "../LoaderInline";
import { Tooltip } from "../Tooltip";
import { MagnifyingGlass } from "../../common/Icons/MagnifyingGlass";
import { InfoFilled } from "../../common/Icons/InfoFilled";
import { PlusIcon } from "../../common/Icons/PlusIcon";

/* eslint-disable react/no-unused-prop-types */
interface FieldProps {
  autoComplete?: AutoCompleteOptions;
  className?: string;
  clear?(): void;
  clinicId?: number;
  enableCreateButton?: boolean;
  filled?: boolean;
  inputMode?: InputModeEnum;
  label?: string;
  loading: boolean;
  onChange(e: string): void;
  onEnterDown?(): void;
  placeholder?: string;
  readOnly?: boolean;
  toolTipText?: string | ReactElement;
  value?: string;
}

const SearchInput = ({
  autoComplete,
  enableCreateButton = true,
  inputMode,
  label,
  loading,
  onChange,
  onEnterDown,
  placeholder = strings.searchPetOwner,
  readOnly,
  toolTipText,
  value: v,
}: FieldProps): ReactElement => {
  const navigate = useNavigate();
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (v) {
      setValue(v);
    }
  }, [v]);

  const onKeyDown = (event: any) => {
    if (onEnterDown && event.keyCode === 13) {
      event.preventDefault();
      onEnterDown();
    }
  };

  const onClick = () => {
    navigate(`/create-pet-owner`, { replace: true });
  };

  return (
    <div>
      {label ? <label>{label}</label> : <></>}
      <div className="relative w-full">
        <button
          className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
          style={{ zIndex: 0 }}
          type="button"
        >
          {loading ? (
            <LoaderInline />
          ) : (
            <MagnifyingGlass className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          )}
        </button>
        <input
          inputMode={inputMode}
          autoComplete={autoComplete || AutoCompleteOptions.off}
          className="tw-input pl-10"
          name="search"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value);
            setValue(e.target.value);
          }}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          readOnly={readOnly}
          type="text"
          value={value}
        />
        {enableCreateButton ? (
          <button
            className="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-sky-500 rounded-r-lg border border-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-100 dark:bg-sky-500 dark:hover:bg-sky-600 dark:focus:ring-sky-800 transition-all duration-300"
            type="button"
            onClick={onClick}
          >
            <PlusIcon className="w-5 h-5 stroke-3" />
          </button>
        ) : (
          <></>
        )}

        {toolTipText ? (
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-2">
            <Tooltip content={toolTipText} placement="top">
              <span>
                <InfoFilled className="text-sky-500" />
              </span>
            </Tooltip>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SearchInput;

SearchInput.defaultProps = {
  autoComplete: AutoCompleteOptions.on,
  className: undefined,
  clinicId: undefined,
  inputMode: undefined,
  onEnterDown: undefined,
  readOnly: false,
};
