/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 *
 */

import React, { useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import PharmacyMap from "../sections/pharmacy/PharmacyMap";
import { GeoPharmacy } from "../../../models/pharmacy/GeoPharmacy";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";

interface Props {
  isOpen: boolean;
  close(): void;
  geoPharmacies: GeoPharmacy[];
  returnPharmacy(p: GeoPharmacy): void;
}

const PharmacySection: React.FC<Props> = ({
  isOpen,
  close,
  geoPharmacies,
  returnPharmacy,
}: Props) => {
  const [selectedPharmacy, setDisplayPharmacy] = useState<GeoPharmacy>();
  return (
    <Modal handleClose={close} show={isOpen} size="modal-lg">
      <Modal.Header title={strings.addSpecificPharmacy} />
      <Modal.Body>
        <div className="flex">
          {isOpen ? (
            <PharmacyMap
              markers={geoPharmacies}
              returnPharmacy={(p: GeoPharmacy) => {
                setDisplayPharmacy(p);
              }}
            />
          ) : (
            <></>
          )}
          {selectedPharmacy ? (
            <div className="text-gray-500 paragraph-small pl-3">
              <div className="py-1 mb-1 text-gray-500">
                {selectedPharmacy.longName}
              </div>
              <Button
                className="mb-1 w-full"
                onClick={() => {
                  returnPharmacy(selectedPharmacy);
                  close();
                }}
              >
                {strings.selectPharmacy}
              </Button>
              <div className="">
                {selectedPharmacy.officeAddress} <br />
                {selectedPharmacy.zipCode} {selectedPharmacy.city}
              </div>
              <div className="pt-1 d-flex">
                {selectedPharmacy.generalPhone}
                <span className="fa-stack ml-auto align-self-center text-xs">
                  <i className="fas fa-circle fa-stack-2x color-input-bg" />
                  <i className="fas fa-phone fa-stack-1x color-light-grey" />
                </span>
              </div>
              <div className="pb-1 d-flex">
                {selectedPharmacy.prescriptionPhone}
                <span className="fa-stack ml-auto align-self-center  text-xs">
                  <i className="fas fa-circle fa-stack-2x color-input-bg" />
                  <i className="fas fa-prescription fa-stack-1x color-light-grey" />
                </span>
              </div>
              <div className="d-flex">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>{strings.monday}</b>
                      </td>
                      <td>
                        {selectedPharmacy.mondayOpens1} -{" "}
                        {selectedPharmacy.mondayCloses1}
                      </td>
                      <td>&nbsp;</td>
                      <td>
                        {selectedPharmacy.mondayOpens2}{" "}
                        {selectedPharmacy.mondayOpens2 ? "-" : ""}{" "}
                        {selectedPharmacy.mondayCloses2}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{strings.tuesday}</b>
                      </td>
                      <td>
                        {selectedPharmacy.tuesdayOpens1} -{" "}
                        {selectedPharmacy.tuesdayCloses1}
                      </td>
                      <td>&nbsp;</td>
                      <td>
                        {selectedPharmacy.tuesdayOpens2}{" "}
                        {selectedPharmacy.tuesdayOpens2 ? "-" : ""}{" "}
                        {selectedPharmacy.tuesdayCloses2}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{strings.wednesday}</b>
                      </td>
                      <td>
                        {selectedPharmacy.wednesdayOpens1} -{" "}
                        {selectedPharmacy.wednesdayCloses1}
                      </td>
                      <td>&nbsp;</td>
                      <td>
                        {selectedPharmacy.wednesdayOpens2}{" "}
                        {selectedPharmacy.wednesdayOpens2 ? "-" : ""}{" "}
                        {selectedPharmacy.wednesdayCloses2}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{strings.thursday}</b>
                      </td>
                      <td>
                        {selectedPharmacy.thursdayOpens1} -{" "}
                        {selectedPharmacy.thursdayCloses1}
                      </td>
                      <td>&nbsp;</td>
                      <td>
                        {selectedPharmacy.thursdayOpens2}{" "}
                        {selectedPharmacy.thursdayOpens2 ? "-" : ""}{" "}
                        {selectedPharmacy.thursdayCloses2}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{strings.friday}</b>
                      </td>
                      <td>
                        {selectedPharmacy.fridayOpens1} -{" "}
                        {selectedPharmacy.fridayCloses1}
                      </td>
                      <td>&nbsp;</td>
                      <td>
                        {selectedPharmacy.fridayOpens2}{" "}
                        {selectedPharmacy.fridayOpens2 ? "-" : ""}{" "}
                        {selectedPharmacy.fridayCloses2}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{strings.saturday}</b>
                      </td>
                      <td>
                        {selectedPharmacy.saturdayOpens} -{" "}
                        {selectedPharmacy.saturdayCloses}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>{strings.sunday}</b>
                      </td>
                      <td>
                        {selectedPharmacy.sundayOpens} -{" "}
                        {selectedPharmacy.sundayCloses}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span className="fa-stack ml-auto text-xs">
                  <i className="fas fa-circle fa-stack-2x color-input-bg" />
                  <i className="far fa-clock fa-stack-1x color-light-grey" />
                </span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PharmacySection;
