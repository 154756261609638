/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../../common/Strings/Strings";
import { Pharmacy } from "../../../../models/pharmacy/Pharmacy";

interface Props {
  pharmacy: Pharmacy;
}

const SelectedPharmacyDetails: React.FC<Props> = ({ pharmacy }: Props) => {
  return (
    <div className="py-1 pharmacy-details text-sm">
      <div className="hr-on-bottom py-2">
        <i className="fas fa-check-circle mr-1 color-green" />{" "}
        <b>{pharmacy.shortName}</b>
      </div>
      <div className="color-light-grey py-2">
        {pharmacy.officeAddress}
        <br />
        {pharmacy.zipCode} {pharmacy.city}
      </div>
      <div className="color-light-grey pt-2 d-flex">
        {pharmacy.generalPhone}
        <span className="fa-stack ml-auto align-self-center text-xs">
          <i className="fas fa-circle fa-stack-2x color-input-bg" />
          <i className="fas fa-phone fa-stack-1x color-light-grey" />
        </span>
      </div>
      <div className="color-light-grey pb-2 d-flex">
        {pharmacy.prescriptionPhone}
        <span className="fa-stack ml-auto align-self-center  text-xs">
          <i className="fas fa-circle fa-stack-2x color-input-bg" />
          <i className="fas fa-prescription fa-stack-1x color-light-grey" />
        </span>
      </div>
      <div className="d-flex">
        <table className="color-light-grey">
          <tbody>
            <tr>
              <td>
                <b>{strings.monday}</b>
              </td>
              <td>
                {pharmacy.mondayOpens1} - {pharmacy.mondayCloses1}
              </td>
              <td>&nbsp;</td>
              <td>
                {pharmacy.mondayOpens2} {pharmacy.mondayOpens2 ? "-" : ""}{" "}
                {pharmacy.mondayCloses2}
              </td>
            </tr>
            <tr>
              <td>
                <b>{strings.tuesday}</b>
              </td>
              <td>
                {pharmacy.tuesdayOpens1} - {pharmacy.tuesdayCloses1}
              </td>
              <td>&nbsp;</td>
              <td>
                {pharmacy.tuesdayOpens2} {pharmacy.tuesdayOpens2 ? "-" : ""}{" "}
                {pharmacy.tuesdayCloses2}
              </td>
            </tr>
            <tr>
              <td>
                <b>{strings.wednesday}</b>
              </td>
              <td>
                {pharmacy.wednesdayOpens1} - {pharmacy.wednesdayCloses1}
              </td>
              <td>&nbsp;</td>
              <td>
                {pharmacy.wednesdayOpens2} {pharmacy.wednesdayOpens2 ? "-" : ""}{" "}
                {pharmacy.wednesdayCloses2}
              </td>
            </tr>
            <tr>
              <td>
                <b>{strings.thursday}</b>
              </td>
              <td>
                {pharmacy.thursdayOpens1} - {pharmacy.thursdayCloses1}
              </td>
              <td>&nbsp;</td>
              <td>
                {pharmacy.thursdayOpens2} {pharmacy.thursdayOpens2 ? "-" : ""}{" "}
                {pharmacy.thursdayCloses2}
              </td>
            </tr>
            <tr>
              <td>
                <b>{strings.friday}</b>
              </td>
              <td>
                {pharmacy.fridayOpens1} - {pharmacy.fridayCloses1}
              </td>
              <td>&nbsp;</td>
              <td>
                {pharmacy.fridayOpens2} {pharmacy.fridayOpens2 ? "-" : ""}{" "}
                {pharmacy.fridayCloses2}
              </td>
            </tr>
            <tr>
              <td>
                <b>{strings.saturday}</b>
              </td>
              <td>
                {pharmacy.saturdayOpens} - {pharmacy.saturdayCloses}
              </td>
            </tr>
            <tr>
              <td>
                <b>{strings.sunday}</b>
              </td>
              <td>
                {pharmacy.sundayOpens} - {pharmacy.sundayCloses}
              </td>
            </tr>
          </tbody>
        </table>
        <span className="fa-stack ml-auto text-xs">
          <i className="fas fa-circle fa-stack-2x color-input-bg" />
          <i className="far fa-clock fa-stack-1x color-light-grey" />
        </span>
      </div>
    </div>
  );
};

export default SelectedPharmacyDetails;
