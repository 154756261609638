/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useLocation, useParams } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { getDefaultVat, getGeneralError } from "../../../util/helperFunctions";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { InventoryItemUnit } from "../../../models/inventory/item/InventoryItemUnit";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { NewInventoryItemRequest } from "../../../models/inventory/item/Request/NewInventoryItemRequest";
import { PetSpecies } from "../../../models/pet/PetSpeciesEnum";
import Button from "../../../components/Button";
import CopyItemToTreatmentModal from "../Modals/CopyItemToTreatmentModal";
import { InventoryItemResponse } from "../../../models/inventory/item/Response/InventoryItemResponse";
import InventoryItemForm, {
  CreateInventoryItemForm,
} from "./InventoryItemForm";
import CountryApi from "../../../api/CountryApi";
import LoaderInline from "../../../components/LoaderInline";
import VaraItemModal from "../Modals/VaraItemModal";
import { VaraItem } from "../../../models/inventory/item/VaraItem";
import { useClinic } from "../../../contexts/ClinicContext";
import UpdateStockModal from "../Stock/ItemStockModal";
import { calculateProfitMargin } from "../Details/InventoryItemDetails";

const defaultFormValues = (
  countryDetails?: CountryDetailsResponse
): CreateInventoryItemForm => ({
  acquisitionUnit: InventoryItemUnit.PIECES,
  accountingCode: null,
  applicableToSpecificSpecies: false,
  barcode: undefined,
  categoryId: undefined,
  lowLevel: 0,
  name: undefined,
  species: [],
  varaNplPackId: undefined,
  varaNvn: undefined,

  acquisitionPrice: undefined,
  acquisitionVat: getDefaultVat(countryDetails?.vatRates),
  expirationDate: undefined,
  quantity: 0,

  profitMargin: undefined,
  sellingPrice: 0,
  sellingVat: getDefaultVat(countryDetails?.vatRates) || 0,
});

const InventoryItemPage: React.FC = () => {
  const [editItem, setEditItem] = useState<InventoryItemResponse>();
  const [loadingEditItem, setLoadingEditItem] = useState<boolean>(false);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [loadingCountryDetails, setLoadingCountryDetails] =
    useState<boolean>(false);
  const [stepCount, setStepCount] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const [addToTreatmentModal, setAddToTreatmentModal] =
    useState<boolean>(false);
  const [selectedTreatmentItem, setSelectedTreatmentItem] =
    useState<InventoryItemResponse>();
  const [isStockStepSkipped, setIsStockStepSkipped] = useState<boolean>(false);
  const [useVara, setUseVara] = useState<boolean>(false);
  const [selectedVaraPackage, setSelectedVaraPackage] = useState<VaraItem>();

  const [openStockModal, setOpenStockModal] = useState<boolean>(false);

  const { itemId } = useParams<{
    itemId: string;
  }>();
  const methods = useForm<CreateInventoryItemForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(countryDetails),
  });
  const { handleSubmit, setValue, reset } = methods;
  const { clinic } = useClinic();
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.categoryId) {
      setValue("categoryId", location.state.categoryId);
    }
  }, [location?.state]);

  useEffect(() => {
    const getCountryDetails = async () => {
      if (clinic?.id) {
        setLoadingCountryDetails(true);
        try {
          const response = await CountryApi.getCountryDetailsOfClinic(
            clinic.id
          );
          setCountryDetails(response.data);
          setValue("sellingVat", getDefaultVat(response.data?.vatRates) || 0);
          setValue(
            "acquisitionVat",
            getDefaultVat(response.data?.vatRates) || 0
          );
        } catch (e) {
          setError(await getGeneralError(e));
        } finally {
          setLoadingCountryDetails(false);
        }
      }
    };

    void getCountryDetails();
  }, [clinic, setValue]);

  useEffect(() => {
    const fillForm = (data: InventoryItemResponse) => {
      if (data.accountingCode) {
        setValue(
          "accountingCode",
          countryDetails?.accountingCodes.filter(
            (a) => a.code === data.accountingCode
          ) || null
        );
      }
      if (data.acquisitionPrice) {
        setValue("acquisitionPrice", data.acquisitionPrice);
      }
      if (data.acquisitionUnit) {
        setValue("acquisitionUnit", data.acquisitionUnit);
      }
      if (data.acquisitionVat) {
        setValue("acquisitionVat", data.acquisitionVat || 0);
      }
      setValue("barcode", data.barcode);
      setValue("categoryId", data.categoryId);
      setValue("lowLevel", data.lowLevel);
      setValue("name", data.name);
      if (data.sellingPrice) {
        setValue("sellingPrice", data.sellingPrice);
      }
      if (data.sellingVat) {
        setValue("sellingVat", data.sellingVat || 0);
      }
      if (data.acquisitionPrice && data.sellingPrice) {
        setValue(
          "profitMargin",
          calculateProfitMargin(data.acquisitionPrice, data.sellingPrice)
        );
      }
      if (data.species && data.species.length > 0) {
        setValue("applicableToSpecificSpecies", true);
        setValue(
          "species",
          data.species?.map((s: PetSpecies) => ({
            value: s,
            title: PetSpecies[s],
          })) || []
        );
      }
      setValue("varaNplPackId", data.varaNplPackId);
      setValue("varaNvn", data.varaNvn);
    };

    const getItem = async (id: string) => {
      setLoadingEditItem(true);
      try {
        const response = await InventoryItemApi.getItem(id);
        setEditItem(response.data);
        fillForm(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        countryDetails && setLoadingEditItem(false);
      }
    };

    if (itemId && !loadingCountryDetails) {
      void getItem(itemId);
    }
  }, [itemId, countryDetails, loadingCountryDetails, setValue]);

  const submitForm = async (data: CreateInventoryItemForm) => {
    if (!data.categoryId || !data.name) return;

    const speciesValues = data.species.map((i) => i.value);
    const requestSpecies = Object.keys(PetSpecies)
      .filter((i) => speciesValues.includes(i))
      .map((i) => i as PetSpecies);

    const request: NewInventoryItemRequest = {
      acquisitionUnit: data.acquisitionUnit,
      accountingCode: data.accountingCode?.[0]?.code,
      barcode: data.barcode,
      lowLevel: data.lowLevel,
      name: data.name,
      species: requestSpecies,
      varaNplPackId: data.varaNplPackId,
      varaNvn: data.varaNvn,

      /* first stockrefill - optional */
      acquisitionPrice: data.acquisitionPrice,
      acquisitionVat: data.acquisitionVat,
      expirationDate: data.expirationDate,
      quantity: isStockStepSkipped ? undefined : data.quantity,

      sellingPrice: data.sellingPrice,
      sellingVat: data.sellingVat,
    };

    if (itemId && clinic?.id && editItem?.categoryId) {
      setLoading(true);
      try {
        const response = await InventoryItemApi.updateItemInfo(
          clinic?.id,
          editItem.categoryId,
          itemId,
          { ...request, categoryId: data.categoryId }
        );
        setSuccess(true);
        setSelectedTreatmentItem(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    } else if (clinic?.id) {
      setLoading(true);
      try {
        const response = await InventoryItemApi.createItem(
          clinic.id,
          data.categoryId,
          request
        );
        setSuccess(true);
        setSelectedTreatmentItem(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <form className="w-full md:max-w-lg md:tw-card space-y-6 md:p-8">
            <div className="flex justify-between space-x-4">
              <div>
                <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                  {itemId ? strings.editItem : strings.newItem}
                </h1>
                <div>
                  {editItem ? (
                    <Link
                      className="tw-link-tercier"
                      to={`/inventory/item/${editItem?.id}/details`}
                    >
                      {editItem?.name}
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <span
                className="text-gray-500 dark:text-gray-400"
                hidden={!!success}
              >
                {stepCount}/{itemId ? 2 : 3}
              </span>
            </div>
            {success ? (
              <div className="space-y-6">
                <AlertBox
                  closeAble={false}
                  message={
                    editItem
                      ? strings.formatString(
                          strings.itemUpdatedSuccessfully,
                          <Link to={`/inventory/item/${editItem?.id}/details`}>
                            {editItem?.name}
                          </Link>
                        )
                      : strings.formatString(
                          strings.itemCreatedSuccessfully,
                          <Link
                            to={`/inventory/item/${selectedTreatmentItem?.id}/details`}
                          >
                            {selectedTreatmentItem?.name}
                          </Link>
                        )
                  }
                  type={AlertType.SUCCESS}
                />
                <div className="space-y-4">
                  <Button
                    variant="primary"
                    onClick={() => {
                      setOpenStockModal(true);
                    }}
                  >
                    {strings.refillStock}
                  </Button>
                  <div>
                    <Link
                      to={`/inventory/item/${
                        selectedTreatmentItem?.id || editItem?.id
                      }/counting-stock`}
                    >
                      <Button>{strings.countInventory}</Button>
                    </Link>
                  </div>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setAddToTreatmentModal(true);
                    }}
                  >
                    {strings.addToTreatment}
                  </Button>
                </div>
                <div className="flex items-center justify-center">
                  <span className="w-full border border-gray-200 dark:border-gray-700" />
                  <span className="px-4 text-gray-500 dark:text-gray-400">
                    {strings.OR}
                  </span>
                  <span className="w-full border border-gray-200 dark:border-gray-700" />
                </div>
                <div>
                  <Link to="/inventory/create-item" replace>
                    <Button
                      fullWidth
                      variant="primary-outline"
                      onClick={() => {
                        setSuccess(false);
                        setError(null);
                        setEditItem(undefined);
                        setSelectedTreatmentItem(undefined);
                        reset(defaultFormValues(countryDetails));
                        setSelectedVaraPackage(undefined);
                        setStepCount(1);
                      }}
                    >
                      {strings.newItem}
                    </Button>
                  </Link>
                </div>
                <div>
                  <Link className="text-sm" to="/inventory">
                    {strings.inventory}
                  </Link>
                </div>
              </div>
            ) : (
              <>
                {loadingEditItem ? (
                  <LoaderInline />
                ) : (
                  <FormProvider {...methods}>
                    <InventoryItemForm
                      clinicId={clinic?.id}
                      countryDetails={countryDetails}
                      isEdit={!!itemId}
                      submitLoading={loading}
                      selectedVaraPackage={selectedVaraPackage}
                      setIsStockStepSkipped={setIsStockStepSkipped}
                      setStepCount={setStepCount}
                      submit={handleSubmit(submitForm)}
                      useVara={() => {
                        setUseVara(true);
                      }}
                    />
                  </FormProvider>
                )}
                <AlertBox message={error} />
              </>
            )}
          </form>
        </div>
        <CopyItemToTreatmentModal
          clinicId={clinic?.id}
          isOpen={addToTreatmentModal}
          item={selectedTreatmentItem}
          close={() => {
            setAddToTreatmentModal(false);
          }}
        />
        <VaraItemModal
          close={() => {
            setUseVara(false);
          }}
          isOpen={useVara}
          returnSelectedVaraPackage={(p) => {
            setSelectedVaraPackage(p);
            setUseVara(false);
          }}
        />
        <UpdateStockModal
          close={() => {
            setOpenStockModal(false);
          }}
          countryDetails={countryDetails}
          inventoryItem={selectedTreatmentItem}
          isOpen={openStockModal}
        />
      </section>
    </main>
  );
};

export default InventoryItemPage;
