/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import AlertBox from "../../../../components/AlertBox";
import DatePickerInput from "../../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import TimeSelect from "../../../../components/ReactHookFormFields/General/Select/TimeSelect";
import EmployeeSelect from "../../../../components/ReactHookFormFields/Specific/EmployeeSelect";
import { EmployeeResponse } from "../../../../models/user/EmployeeResponse";

export interface SubstitutionForm {
  absentEmployeeUser: EmployeeResponse[];
  startDate: Date;
  startTime: string;
  endTime: string;
  endDate: Date;
}

interface Props {
  error?: string | null;
  loading: boolean;
  onHide: () => void;
  submitTimeForm: (formData: SubstitutionForm) => void;
}

const SubstitutionTimeForm: React.FC<Props> = ({ error }: Props) => {
  const { control } = useFormContext<SubstitutionForm>();

  return (
    <>
      <EmployeeSelect
        allowNew={false}
        control={control}
        label={strings.deleteShiftsFor}
        name="absentEmployeeUser"
        placeholder={strings.searchEmployee}
        required
      />
      
        <div className="flex items-end space-x-4">
          <DatePickerInput
            name="startDate"
            label={strings.selectShiftsFrom}
            control={control}
            required
          />
          <TimeSelect
            name="startTime"
            labelOff
            control={control}
            required
          />
        </div>
        <div className="flex items-end space-x-4">
          <DatePickerInput
            name="endDate"
            label={strings.to}
            control={control}
            required
          />
          <TimeSelect
            name="endTime"
            labelOff
            control={control}
            required
          />
      </div>
      <AlertBox message={error} className="mt-4" />
    </>
  );
};

export default SubstitutionTimeForm;
