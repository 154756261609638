/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const statisticsItemsHu = {
  NUMBER_OF_APPOINTMENTS: "Időpontfoglalások száma",
  APPOINTMENT_USAGE: "Időpontfoglaló használat száma",
  NUMBER_OF_MEDICAL_RECORDS: "Kiállított kórlapok száma",
  MEDICAL_RECORD_USAGE: "Kórlap használat száma",
  NUMBER_OF_USED_ITEMS: "Felhasznált segédeszközök száma",
  PRICE_OF_USED_ITEMS: "Felhasznált segédeszközök összköltsége",
  MAX_NUMBER_OF_EMPLOYEES: "Alkalmazottak maximális száma a hónapban",
  MAX_NUMBER_OF_VETS: "Állatorvosok maximális száma a hónapban",
  MAX_NUMBER_OF_ASSISTANTS: "Asszisztensek maximális száma a hónapban",
  MAX_NUMBER_OF_RECEPTIONISTS: "Recepciósok maximális száma a hónapban",
  MAX_NUMBER_OF_ORGANIZATION_MANAGERS:
    "Szervezet menedzsereinek maximális száma a hónapban",
  INVENTORY_USAGE: "Raktárkészlet használat száma",
  SMS_USAGE: "Elküldött SMS-ek száma",
  NUMBER_OF_PRESCRIPTIONS: "Beküldött e-receptek száma a hónapban",
};
