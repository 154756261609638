/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Control, RegisterOptions, useController } from "react-hook-form";
import React, { ReactElement, useEffect, useState } from "react";
import { Tooltip } from "../../../Tooltip";
import { Colors } from "../../../../models/Colors";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import { Option } from "../../../../models/Option";
import { InfoFilled } from "../../../../common/Icons/InfoFilled";

interface FieldProps {
  control: Control<any>;
  name: string;
  options: Option[];
  autoComplete?: AutoCompleteOptions;
  classLabel?: string;
  className?: string;
  defaultValue?: any;
  disabledChooseOptionTitle?: string;
  fieldOptions?: RegisterOptions;
  firstOption?: Option;
  isChooseOption?: boolean;
  label?: string;
  labelIcon?: string;
  labelOff?: boolean;
  onChange?(e: any): void;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  separationEnabled?: boolean;
  showRequired?: boolean;
  toolTipText?: string;
  value?: any;
  width?: string;
}

const ControlledSelect = ({
  control,
  name,
  options,
  autoComplete = AutoCompleteOptions.on,
  classLabel,
  className,
  defaultValue,
  disabledChooseOptionTitle,
  fieldOptions,
  firstOption,
  isChooseOption = true,
  label,
  labelIcon,
  labelOff = false,
  onChange,
  optional = false,
  readOnly = false,
  required = false,
  separationEnabled = false,
  showRequired,
  toolTipText,
  value: v,
  width,
}: FieldProps): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});
  const [value, setValue] = useState<string>("");

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules: registerOptions, defaultValue });

  useEffect(() => {
    if (v) {
      setValue(v);
    } else {
      setValue("");
      field.onChange(v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [v]);

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  const labelClass = classLabel || "";

  const tooltipStyle = {
    borderBottomLeftRadius: "0",
    borderTopLeftRadius: "0",
    fontSize: "20px",
    borderLeft: "0",
    opacity: readOnly ? "0.5" : "1",
  };

  const optionList = options.map((option: Option) => {
    const title = separationEnabled
      ? option.title
          .split(" ")
          .map((str: string) => getStringFromEnumsOrReturn(str))
          .join(" ")
      : getStringFromEnumsOrReturn(option.title);

    return (
      <option value={option.value} key={option.value}>
        {title}
      </option>
    );
  });

  return (
    <div
      className={`${className} ${error ? "has-error" : ""}`}
      style={{ width }}
    >
      {labelOff ? (
        <></>
      ) : (
        <label className={labelClass} htmlFor={name}>
          {labelIcon ? <i className={labelIcon} /> : ""} {label}
          {showRequired ? " *" : ""}
          {optional ? (
            <span style={{ color: Colors.INPUTBORDER }}>
              {" "}
              ({strings.optional})
            </span>
          ) : (
            <></>
          )}
        </label>
      )}
      <div className="flex items-center">
        <select
          {...field}
          autoComplete={autoComplete || AutoCompleteOptions.on}
          className={`tw-input ${className}`}
          disabled={readOnly}
          name={name}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            field.onChange(e.target.value);
            if (onChange) {
              onChange(e.target.value);
            }

            setValue(e.target.value);
          }}
          value={value}
        >
          {isChooseOption ? (
            <option value="" disabled>
              {disabledChooseOptionTitle || strings.pleaseChoose}
            </option>
          ) : (
            <></>
          )}
          {firstOption ? (
            <option value={firstOption.value}>{firstOption.title}</option>
          ) : (
            <></>
          )}
          {optionList}
        </select>
        {toolTipText ? (
          <div style={tooltipStyle}>
            <Tooltip content={toolTipText} placement="top">
              <span>
                <InfoFilled className="text-sky-500" />
              </span>
            </Tooltip>
          </div>
        ) : (
          <></>
        )}
      </div>
      <p>{error && error.message?.toString()}</p>
    </div>
  );
};

export default ControlledSelect;
