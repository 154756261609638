/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import NumberFormatter from "./NumberFormatter";
import { CountryDetailsResponse } from "../models/management/CountryDetailsResponse";
import { ClinicResponse } from "../models/clinic/ClinicResponse";
import { RoundingStrategy } from "../models/clinic/RoundingStrategy";
import { VatCalculationStrategy } from "../models/clinic/VatCalculationStrategy";
import { groupBy } from "./helperFunctions";

export interface InvoiceItem {
  price?: number;
  quantity: number;
  vat?: number;
}

export default class CostCalculator {
  static getNetPriceOfItem(
    item: InvoiceItem,
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    const roundingStrategy = this.getRoundingStrategyOfItem(country, clinic);
    const unitPrice = NumberFormatter.roundToTwoDecimal(item.price ?? 0);
    const value = unitPrice * Number(item.quantity);
    return NumberFormatter.roundBy(value, roundingStrategy);
  }

  static getGrossPriceOfItem(
    item: InvoiceItem,
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    const roundingStrategy = this.getRoundingStrategyOfItem(country, clinic);
    const netPrice = this.getNetPriceOfItem(item);
    const grossPrice = netPrice * ((100 + Number(item.vat ?? 0)) / 100);
    return NumberFormatter.roundBy(grossPrice, roundingStrategy);
  }

  static getTotalGrossPriceOfInvoiceItems(
    items: InvoiceItem[],
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    let totalGrossPrice = 0;

    switch (this.getVatCalculationStrategy(country, clinic)) {
      case VatCalculationStrategy.ITEMIZED:
        totalGrossPrice = this.getGrossPriceWithItemizedVat(
          items,
          country,
          clinic
        );
        break;
      case VatCalculationStrategy.GROUPED:
        totalGrossPrice = this.getGrossPriceWithGroupedVat(
          items,
          country,
          clinic
        );
        break;
    }

    const roundingStrategy = this.getRoundingStrategyOfTotal(country, clinic);
    return NumberFormatter.roundBy(totalGrossPrice, roundingStrategy);
  }

  static getTotalNetPriceOfInvoiceItems(
    items: InvoiceItem[],
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    let result = 0;

    items.forEach((item: InvoiceItem) => {
      result += item.price ? this.getNetPriceOfItem(item, country, clinic) : 0;
    });

    const roundingStrategy = this.getRoundingStrategyOfTotal(country, clinic);
    return NumberFormatter.roundBy(result, roundingStrategy);
  }

  static getGrossPriceWithItemizedVat(
    items: InvoiceItem[],
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    let result = 0;

    items.forEach((item: InvoiceItem) => {
      result += item.price
        ? this.getGrossPriceOfItem(item, country, clinic)
        : 0;
    });

    return result;
  }

  static getGrossPriceWithGroupedVat(
    items: InvoiceItem[],
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number {
    let netPrice = 0;

    items.forEach((item: InvoiceItem) => {
      netPrice += item.price
        ? this.getNetPriceOfItem(item, country, clinic)
        : 0;
    });

    let vatPrice = 0;
    const roundingStrategyItem = this.getRoundingStrategyOfItem(
      country,
      clinic
    );
    groupBy(items, (item) => item.vat).forEach((i, vat) => {
      const itemsNetPrice = this.getTotalNetPriceOfInvoiceItems(
        i,
        country,
        clinic
      );
      const value = itemsNetPrice * (Number(vat ?? 0) / 100);
      vatPrice += NumberFormatter.roundBy(value, roundingStrategyItem);
    });

    return netPrice + vatPrice;
  }

  static getNetPrice = (gross: number, vat?: number): number => {
    const roundingStrategy = RoundingStrategy.TWO_DECIMAL; // Should we use clinic/country specific strategy here?
    const value =
      NumberFormatter.roundToTwoDecimal(gross) /
      ((100 + Number(vat ?? 0)) / 100);
    return NumberFormatter.roundBy(value, roundingStrategy);
  };

  static getGrossPrice = (
    net: number,
    vat?: number,
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ): number => {
    const roundingStrategy = this.getRoundingStrategyOfItem(country, clinic);
    const value =
      NumberFormatter.roundToTwoDecimal(net) * ((100 + Number(vat ?? 0)) / 100);
    return NumberFormatter.roundBy(value, roundingStrategy);
  };

  private static getRoundingStrategyOfItem = (
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ) => {
    return (
      clinic?.roundingStrategyItem ||
      country?.roundingStrategyItem ||
      RoundingStrategy.TWO_DECIMAL
    );
  };

  private static getRoundingStrategyOfTotal = (
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ) => {
    return (
      clinic?.roundingStrategyTotal ||
      country?.roundingStrategyTotal ||
      RoundingStrategy.TWO_DECIMAL
    );
  };

  private static getVatCalculationStrategy = (
    country?: CountryDetailsResponse,
    clinic?: ClinicResponse
  ) => {
    return (
      clinic?.vatCalculationStrategy ||
      country?.vatCalculationStrategy ||
      VatCalculationStrategy.ITEMIZED
    );
  };
}
