/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MedicalRecordStatus } from "../../../models/medical/MedicalRecordStatus";
import { MedicalRecordResponse } from "../../../models/medical/MedicalRecordResponse";
import { strings } from "../../../common/Strings/Strings";
import { downloadMedicalRecordPdfForReservation } from "../../../util/FileUtils";
import Button from "../../../components/Button";
import { DocumentText } from "../../../common/Icons/DocumentText";
import { ArrowDownTray } from "../../../common/Icons/ArrowDownTray";
import Tooltip from "../../../components/Tooltip";
import { Document } from "../../../common/Icons/Document";

interface Props {
  medicalRecord: MedicalRecordResponse | any;
  returnError(message: string | null): void;
}

const MedicalRecordActions: React.FC<Props> = ({
  medicalRecord,
  returnError,
}: Props) => {
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  return (
    <div className="flex justify-end items-center space-x-4">
      {medicalRecord.invoiceId && (
        <div>
          <Tooltip content={strings.invoice}>
            <Link to={`/invoices/clinic/${medicalRecord.invoiceId}`}>
              <Button
                disabled={!medicalRecord.invoiceId || pdfLoading}
                variant="icon"
              >
                <Document />
              </Button>
            </Link>
          </Tooltip>
        </div>
      )}
      {medicalRecord.status === MedicalRecordStatus.SIGNED && (
        <div>
          <Tooltip content={strings.downloadMedicalRecordPDF}>
            <Button
              disabled={
                medicalRecord.status !== MedicalRecordStatus.SIGNED ||
                pdfLoading
              }
              onClick={() => {
                setPdfLoading(true);
                downloadMedicalRecordPdfForReservation(
                  medicalRecord.referenceId,
                  medicalRecord.id
                )
                  .catch(() => {
                    returnError(strings.simpleError);
                  })
                  .finally(() => {
                    setPdfLoading(false);
                  });
              }}
              variant="icon"
            >
              <ArrowDownTray />
            </Button>
          </Tooltip>
        </div>
      )}
      <Link to={`/medical-record/${medicalRecord.id}`}>
        <Button variant="icon">
          <DocumentText />
        </Button>
      </Link>
    </div>
  );
};

export default MedicalRecordActions;
