/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import {
  prescriptionIntakeKeys,
  prescriptionIntakes,
  prescriptionMethodIds,
  prescriptionMethods,
  strings,
} from "../../../common/Strings/Strings";
import { Option } from "../../../models/Option";
import { prescriptionRegexp } from "../../../util/Validations";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Field from "../../../components/ReactHookFormFields/General/Field";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import sendingRequestProcesser from "../sections/finish/sendingRequestProcesser";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import Button from "../../../components/Button";
import { XMark } from "../../../common/Icons/XMark";

interface Props {
  deleteInterval(): void;
  isCommodity?: boolean;
  nestIndex: number;
}

const IntervalComponent: React.FC<Props> = ({
  deleteInterval,
  isCommodity,
  nestIndex,
}: Props) => {
  const {
    control,
    register,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();

  const daysType = watch(`intervals.${nestIndex}.daysType`);

  const generateMethods = (): Option[] => {
    const options: Option[] = [];
    prescriptionMethodIds.forEach((methodId: string) => {
      options.push({ title: prescriptionMethods[methodId], value: methodId });
    });

    return options;
  };

  const getPeriods = (): Option[] => {
    const options: Option[] = [];

    prescriptionIntakeKeys.forEach((method) => {
      const temp = { title: prescriptionIntakes[method], value: method };
      options.push(temp);
    });

    return options;
  };

  return (
    <>
      <div className="flex flex-wrap lg:flex-nowrap space-x-3 items-end">
        <Field
          className="mb-0"
          name={`intervals.${nestIndex}.dose`}
          register={register}
          autoComplete={AutoCompleteOptions.off}
          error={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            errors?.intervals?.[nestIndex]?.dose
          }
          label={strings.dose}
          fieldOptions={{
            pattern: {
              value: prescriptionRegexp,
              message: strings.notAllowedCharacter,
            },
            validate: {
              positive: (v: number) => v > 0 || Number.isNaN(v),
              isInstructionFilled: () =>
                !isCommodity
                  ? sendingRequestProcesser.generateTextFromIntervals(
                      getValues()
                    ).length > 0
                  : true,
            },
            deps: ["freeText", `intervals.${nestIndex}.unit`],
          }}
          placeholder={strings.dose}
          type="number"
          width="80px"
        />
        <Field
          className="mb-0"
          name={`intervals.${nestIndex}.unit`}
          register={register}
          autoComplete={AutoCompleteOptions.off}
          error={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            errors?.intervals?.[nestIndex]?.unit
          }
          label={strings.unit}
          fieldOptions={{
            pattern: {
              value: prescriptionRegexp,
              message: strings.notAllowedCharacter,
            },
            validate: {
              isUnitAndDoseFilled: (v: string) => {
                const dose = !Number.isNaN(
                  getValues(`intervals.${nestIndex}.dose`)
                );
                const unit = !!v;

                return !(dose && !unit);
              },
              isInstructionFilled: () =>
                !isCommodity
                  ? sendingRequestProcesser.generateTextFromIntervals(
                      getValues()
                    ).length > 0
                  : true,
            },
            deps: ["freeText", `intervals.${nestIndex}.dose`],
          }}
          placeholder={strings.unit}
          width="100px"
        />
        <Select
          className="mb-0"
          error={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            errors?.intervals?.[nestIndex]?.period
          }
          name={`intervals.${nestIndex}.period`}
          options={getPeriods()}
          register={register}
          autoComplete={AutoCompleteOptions.off}
          firstOption={{ value: "", title: strings.none }}
          label={strings.period}
        />
        <Select
          autoComplete={AutoCompleteOptions.off}
          className="mb-0"
          error={
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            errors?.intervals?.[nestIndex]?.daysType
          }
          isChooseOption={false}
          name={`intervals.${nestIndex}.daysType`}
          register={register}
          label={strings.day}
          options={[
            { value: "", title: "-" },
            { value: 1, title: "1" },
            { value: 2, title: "2" },
            { value: 3, title: "3" },
            { value: 4, title: "4" },
            { value: 5, title: "5" },
            { value: 6, title: "6" },
            { value: 7, title: "7" },
            { value: 8, title: "8" },
            { value: 9, title: "9" },
            { value: 10, title: "10" },
            { value: 14, title: "14" },
            { value: 21, title: "21" },
            { value: 28, title: "28" },
            { value: "everyOtherDay", title: strings.everyOtherDay },
            { value: "everyOtherWeek", title: strings.everyOtherWeek },
            { value: "long", title: strings.forFurther },
            { value: "custom", title: `${strings.other}...` },
          ]}
          width="120px"
        />
        <div hidden={daysType !== "custom"}>
          <Field
            className="mb-0"
            error={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */
              errors?.intervals?.[nestIndex]?.days
            }
            name={`intervals.${nestIndex}.days`}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            disableNotNumberCharacters
            label={strings.day}
            fieldOptions={{
              pattern: {
                value: prescriptionRegexp,
                message: strings.notAllowedCharacter,
              },
              validate: {
                positive: (v: number) => {
                  const dayType = getValues(`intervals.${nestIndex}.daysType`);
                  if (dayType === "custom") {
                    return v > 0 || Number.isNaN(v);
                  }
                  return true;
                },
              },
            }}
            placeholder={strings.day}
            type="number"
            width="80px"
          />
        </div>
        <CombinedSelect
          className="mb-0"
          control={control}
          fieldOptions={{
            validate: {
              isPattern: (value: [Option] | null) =>
                value?.[0]?.title
                  ? prescriptionRegexp.test(value?.[0]?.title) ||
                    strings.notAllowedCharacter
                  : true,
            },
          }}
          name={`intervals.${nestIndex}.method`}
          options={generateMethods()}
          allowNew
          label={strings.method}
          labelKey="title"
        />
        <Button className="mb-2" onClick={deleteInterval} variant="icon">
          <XMark className="w-5 h-5" />
        </Button>
      </div>
    </>
  );
};

export default IntervalComponent;
