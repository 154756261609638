/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import LoaderInline from "../../../../components/LoaderInline";
import { strings } from "../../../../common/Strings/Strings";
import {
  getGeneralError,
  localDateFormat,
} from "../../../../util/helperFunctions";
import { Substitution } from "../../../../models/substitution/Substitution";
import { SubstitutionRequest } from "../../../../models/substitution/SubstitutionRequest";
import SubstitutionApi from "../../../../api/SubstitutionApi";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import { SubstitutionCandidatesResponse } from "../../../../models/substitution/SubstitutionCandidatesResponse";
import { useClinic } from "../../../../contexts/ClinicContext";
import { ClinicEmploymentType } from "../../../../models/employment/ClinicEmploymentType";
import Select from "../../../../components/ReactHookFormFields/General/Select/Select";
import PetInfo from "../../../Todo/PetInfo";
import { CheckCircle } from "../../../../common/Icons/CheckCircle";
import { SubstitutionCandidatesRequest } from "../../../../models/substitution/SubstitutionCandidatesRequest";
import { StringMap } from "../../../../models/StringMap";

interface Props {
  deleteEmployee?: boolean;
  end?: string;
  employeeUserId?: string;
  employeeType?: ClinicEmploymentType;
  onHide: () => void;
  reload: () => void;
  setLoading: (value: boolean) => void;
  start?: string;
}

const ReservationsForm: React.FC<Props> = ({
  deleteEmployee = false,
  employeeType,
  onHide,
  reload,
  setLoading,
  employeeUserId,
  start,
  end,
}: Props) => {
  const { clinic } = useClinic();
  const [error, setError] = useState<string | null>(null);
  const [substitutionDetails, setSubstitutionDetails] =
    useState<SubstitutionCandidatesResponse | null>(null);

  useEffect(() => {
    const getSubstitutionDetails = async () => {
      if (clinic && employeeUserId && start && end) {
        const request: SubstitutionCandidatesRequest = {
          absentEmployeeUserId: employeeUserId,
          startTime: start,
          endTime: end,
        };

        try {
          const response = await SubstitutionApi.getSubstitutionCandidates(
            clinic.id,
            request
          );
          setSubstitutionDetails(response.data);
        } catch (err) {
          setError(await getGeneralError(err));
        } finally {
          setLoading(false);
        }
      }
    };

    void getSubstitutionDetails();
  }, [clinic, employeeUserId, start, end, setLoading]);

  const submitReservationsForm = async (data: StringMap) => {
    if (!substitutionDetails || !clinic) {
      return;
    }

    setLoading(true);
    setError(null);

    const substitutions: Substitution[] = [];

    Object.keys(data).forEach((reservationId) => {
      let substituteUserId: any | null = data[reservationId] ?? null;
      if (substituteUserId !== null && substituteUserId === "null") {
        substituteUserId = null;
      }
      substitutions.push({ reservationId, substituteUserId });
    });

    const currentStartTime = moment(
      substitutionDetails.startTime,
      localDateFormat()
    );

    const newStartTime = currentStartTime.format(localDateFormat());
    let newEndTime: string =
      moment(substitutionDetails.endTime, localDateFormat()).format(
        localDateFormat()
      ) ?? "";

    if (newEndTime === "") {
      newEndTime = moment(currentStartTime)
        .set({ year: currentStartTime.year() + 100 })
        .format(localDateFormat());
    }

    const request: SubstitutionRequest = {
      absentEmployeeUserId: substitutionDetails.absentEmployee.userId,
      startTime: newStartTime,
      endTime: newEndTime,
      substitutions,
      deleteEmployee: deleteEmployee ?? false,
      employeeType: employeeType ?? null,
    };

    try {
      await SubstitutionApi.addSubstitutions(clinic.id, request);
      onHide();
      reload();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  return (
    <>
      <LoaderInline hidden={substitutionDetails != null} className="p-3" />
      <form
        onSubmit={handleSubmit(submitReservationsForm)}
        id="reservationsForm"
      >
        <div hidden={substitutionDetails?.reservations.length !== 0}>
          <div className="flex">
            <div>
              <CheckCircle className="text-green-500 mr-3" />
            </div>
            <div>
              {strings.formatString(
                strings.noReservationsSaveSubstitution,
                <b>{substitutionDetails?.absentEmployee?.fullName}</b>,
                <b>{moment(substitutionDetails?.startTime).format("LLL")}</b>,
                <b>{moment(substitutionDetails?.endTime).format("LLL")}</b>
              )}
            </div>
          </div>
        </div>
        <AlertBox
          className="mb-3"
          hidden={
            !(
              substitutionDetails &&
              substitutionDetails?.reservations?.length > 0
            )
          }
          message={
            <>
              {strings.formatString(
                strings.userHasReservationsBetween,
                <b>{substitutionDetails?.absentEmployee?.fullName}</b>,
                <b>{moment(substitutionDetails?.startTime).format("LLL")}</b>,
                <b>{moment(substitutionDetails?.endTime).format("LLL")}</b>
              )}
            </>
          }
          type={AlertType.INFO}
        />
        <div className="divide-y dark:divide-gray-700">
          {substitutionDetails?.reservations.map((substitution) => {
            const substitutes = [
              { value: "null", title: strings.cancelAndNotifyPetOwner },
              ...substitution.candidates.map((emp) => ({
                value: emp.userId,
                title: emp.fullName,
              })),
            ];
            const { pet } = substitution.reservation;
            return (
              <div
                key={substitution.reservation.id}
                className="text-sm grid grid-cols-2 gap-4 py-3"
              >
                <div>
                  <div className="font-bold pb-2">
                    {substitution.reservation.reservationType?.name ?? null}
                  </div>
                  <div>
                    {pet ? (
                      <PetInfo
                        pet={{
                          clinicPetDetails: {
                            ...pet,
                            chronicDiseases: pet.chronicDiseases || [],
                          },
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div>
                  <div className="flex pb-2">
                    <div>
                      {moment(
                        substitution.reservation.startDateTime,
                        localDateFormat()
                      ).format("LL, HH:mm")}
                    </div>
                    <div className="px-2">-</div>
                    <div>
                      {moment(
                        substitution.reservation.endDateTime,
                        localDateFormat()
                      ).format("HH:mm")}
                    </div>
                  </div>
                  <Select
                    error={errors[substitution.reservation.id]}
                    name={substitution.reservation.id}
                    labelOff
                    register={register}
                    disabledChooseOptionTitle={strings.chooseSubstitute}
                    options={substitutes}
                    required
                  />
                </div>
              </div>
            );
          })}
        </div>
        <AlertBox message={error} className="mt-2" />
      </form>
    </>
  );
};

export default ReservationsForm;
