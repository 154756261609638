/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import SubsectionTemplate from "./Templates/SubsectionTemplate";
import { strings } from "../../../common/Strings/Strings";
import DiagnoseList from "../Diagnosis/DiagnoseList";
import { useMainSectionContext } from "./MainSectionContext";

const DiagnosisSection: React.FC = () => {
  const {
    medicalRecord,
    deleteDiagnose,
    deleteDifferentialDiagnoses,
    diagnoses,
    setDiagnoses,
    signed,
    accessScope,
    differentialDiagnoses,
    setDifferentialDiagnoses,
  } = useMainSectionContext();
  return (
    <>
      <SubsectionTemplate
        hidden={
          signed &&
          medicalRecord?.differentialDiagnoses.length === 0 &&
          medicalRecord?.diagnoses.length === 0
        }
        title={strings.diagnosis}
      >
        <DiagnoseList
          accessScope={accessScope}
          current={diagnoses}
          deleteCurrent={deleteDiagnose}
          name="diagnoseSelect"
          label={strings.diagnoses}
          signed={signed}
          submit={setDiagnoses}
        />
        <DiagnoseList
          accessScope={accessScope}
          current={differentialDiagnoses}
          deleteCurrent={deleteDifferentialDiagnoses}
          name="differentialDiagnoseSelect"
          label={strings.differentialDiagnoses}
          signed={signed}
          submit={setDifferentialDiagnoses}
        />
      </SubsectionTemplate>
    </>
  );
};

export default DiagnosisSection;
