/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import Paging from "../../../../components/Paging";
import PageSizeSelect from "../../../../components/PageSizeSelect";
import { useDebouncedState } from "../../../../hooks/hooks";
import { UserPetResponse } from "../../../../models/pet/UserPetResponse";
import PetListRow from "./PetListRow";
import EmptyListText from "../../../../components/EmptyListText";

interface Props {
  userPets: UserPetResponse[];
}

const UserPetList = ({ userPets }: Props): ReactElement => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filteredPets, setFilteredPets] = useState<UserPetResponse[]>(userPets);
  const petNameSearchQuery = useDebouncedState("", 400);

  useEffect(() => {
    setFilteredPets(userPets);
  }, [userPets]);

  useEffect(() => {
    const query = petNameSearchQuery.value.toLowerCase();
    const newPets = userPets.filter((p) =>
      p.userPetDetails.name.toLowerCase().includes(query)
    );
    setFilteredPets(newPets);
  }, [petNameSearchQuery.value, userPets]);

  return (
    <>
      <div className="tw-card divide-y dark:divide-gray-700">
        {filteredPets.length === 0 ? (
          <div className="p-4">
            <EmptyListText />
          </div>
        ) : (
          filteredPets
            .slice(page * pageSize, (page + 1) * pageSize)
            .map(({ userPetDetails: pet }: UserPetResponse) => (
              <PetListRow
                key={pet.id}
                isUserSide
                petDetails={pet}
                userPet={pet}
              />
            ))
        )}
      </div>
      <div className="flex">
        <div className="flex items-center">
          <PageSizeSelect
            pageSize={pageSize}
            setPageSize={(newPageSize: number) => {
              setPageSize(newPageSize);
            }}
            totalPages={Math.ceil(filteredPets.length / pageSize)}
            totalResults={filteredPets.length}
          />
        </div>
        <div className="ml-auto">
          <Paging
            totalPages={Math.ceil(filteredPets.length / pageSize)}
            currentPage={page}
            selectPage={(newPageNumber: number) => {
              setPage(newPageNumber);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default UserPetList;
