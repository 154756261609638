/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";
import { SetupIntent } from "@stripe/stripe-js";
import { strings } from "../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { SetupIntentResponse } from "../../../models/stripe/SetupIntentResponse";
import StripeApi from "../../../api/StripeApi";
import { getGeneralError } from "../../../util/helperFunctions";
import StripeSection from "../../Stripe/StripeSection";
import StripeSetupForm, {
  StripeSetupFormRef,
} from "../../Stripe/StripeSetupForm";
import LoaderInline from "../../../components/LoaderInline";
import Button from "../../../components/Button";
import { LocatedAddressRequest } from "../../../models/contact/LocatedAddressRequest";
import { insertReactElements } from "../../../util/HtmlUtils";
import SimpleCheckBox from "../../../components/InputFieldsSimple/SimpleCheckBox";

interface Props {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAlert: React.Dispatch<React.SetStateAction<string | null>>;
  addressData?: LocatedAddressRequest;
  countryCode?: string;
  onSubmit(data: SetupIntent): void;
}

const CreditCardTab: React.FC<Props> = ({
  loading,
  setLoading,
  setAlert,
  addressData,
  countryCode,
  onSubmit,
}: Props) => {
  const [isStripeFormValid, setStripeFormValid] = useState<boolean>(false);
  const stripeSetupRef = useRef<StripeSetupFormRef>(null);
  const [stripeSetupIntent, setStripeSetupIntent] =
    useState<SetupIntentResponse>();
  const [confirmedSetupIntent, setConfirmedSetupIntent] =
    useState<SetupIntent>();

  useEffect(() => {
    if (!countryCode) {
      return;
    }
    (async () => {
      setLoading(true);

      try {
        const response = await StripeApi.getSetupIntent(countryCode);
        setStripeSetupIntent(response.data);
      } catch (err) {
        setAlert(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmStripeSetup = async () => {
    if (confirmedSetupIntent) {
      onSubmit(confirmedSetupIntent);
      return;
    }

    if (!stripeSetupRef.current) return;
    setLoading(true);

    try {
      const response = await stripeSetupRef.current.confirmSetup();
      if (response?.setupIntent && !response?.error) {
        setConfirmedSetupIntent(response.setupIntent);
        onSubmit(response.setupIntent);
      } else {
        setAlert(response?.error?.message ?? strings.simpleError);
      }
    } catch (e) {
      const err: any = e;
      setAlert(err?.message ?? strings.simpleError);
    } finally {
      setLoading(false);
    }
  };

  const [policiesAccepted, setPoliciesAccepted] = useState<boolean>(false);

  const handleCheckBox = (checked: boolean) => {
    setPoliciesAccepted(checked);
  };

  const generalTermsLink = (
    <Button
      className="text-sm"
      onClick={() =>
        window.open(
          countryCode
            ? `/general-terms-and-conditions/${countryCode}`
            : "/general-terms-and-conditions",
          "_blank",
          "noreferrer"
        )
      }
      variant="link"
    >
      {strings.clinicCreationAgreementGeneral}
    </Button>
  );

  const dataProcessingTermsLink = (
    <Button
      className="text-sm"
      onClick={() =>
        window.open(
          countryCode
            ? `/data-processing-terms-and-conditions/${countryCode}`
            : "/data-processing-terms-and-conditions",
          "_blank",
          "noreferrer"
        )
      }
      variant="link"
    >
      {strings.clinicCreationAgreementDataProcessing}
    </Button>
  );

  return (
    <>
      <LoaderInline hidden={!loading} />
      <div hidden={loading} className="space-y-6">
        <AlertBox
          closeAble={false}
          type={AlertType.INFO}
          message={strings.chargeWarning}
        />
        <div>
          {stripeSetupIntent && (
            <StripeSection
              apiKey={stripeSetupIntent.publicKey}
              clientSecret={stripeSetupIntent.clientSecret}
            >
              <StripeSetupForm
                ref={stripeSetupRef}
                onValidation={(valid) => setStripeFormValid(valid)}
                address={addressData}
              />
            </StripeSection>
          )}
        </div>
        <div className="flex items-center text-sm">
          <SimpleCheckBox name="acceptPolicies" onChange={handleCheckBox} />
          {insertReactElements(strings.clinicCreationAgreement, [
            generalTermsLink,
            dataProcessingTermsLink,
          ])}
        </div>
        <Button
          disabled={
            (!isStripeFormValid && confirmedSetupIntent === undefined) ||
            !policiesAccepted
          }
          onClick={() => confirmStripeSetup()}
          type="button"
          variant="primary"
        >
          {strings.saveAndCreate}
        </Button>
      </div>
    </>
  );
};

export default CreditCardTab;
