/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import CustomHistoryList from "./CustomHistoryList";
import { strings } from "../../../common/Strings/Strings";
import { Lists } from "../MedicalRecordComponent";
import ChevronButtonUp from "../../../components/Buttons/ChevronButtonUp";
import ChevronButtonDown from "../../../components/Buttons/ChevronButtonDown";

interface Props {
  lists: Lists;
}

const MedicalHistorySection: React.FC<Props> = ({ lists }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="lg:tw-card p-4 lg:p-6">
      <button
        className="flex items-center justify-between w-full bg-transparent dark:bg-transparent bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
        type="button"
      >
        <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {strings.history}
        </h2>
        {isOpen ? <ChevronButtonUp /> : <ChevronButtonDown/>}
      </button>

      {isOpen && (
        <div className="flex flex-col space-y-5 pt-5">
          <CustomHistoryList
            history={lists.weights}
            listFor={strings.weight}
            icon="weight"
          />
          <CustomHistoryList
            history={lists.vaccines}
            listFor={strings.vaccines}
            icon="vaccines"
          />
          <CustomHistoryList
            history={lists.diagnoses}
            listFor={strings.diagnoses}
            icon="monitor_heart"
          />
          <CustomHistoryList
            history={lists.medicines}
            listFor={strings.medicines}
            icon="medication"
          />
          <CustomHistoryList
            history={lists.treatments}
            listFor={strings.treatments}
            icon="healing"
          />
        </div>
      )}
    </div>
  );
};

export default MedicalHistorySection;
