/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { UserResponse } from "../../../models/user/UserResponse";
import Button from "../../../components/Button";
import { ChevronRight } from "../../../common/Icons/ChevronRight";

interface Props {
  authorities: string[];
  user: UserResponse;
}

function Menu(props: Props): ReactElement {
  const { authorities, user } = props;

  const hasAuthority = (authority: string): boolean => {
    return (
      authorities.includes("MANAGER_FULL") || authorities.includes(authority)
    );
  };

  const hasAnySettingsAuthority = (): boolean => {
    return (
      hasAuthority("MANAGER_CONFIG") ||
      hasAuthority("MANAGER_FEES") ||
      hasAuthority("MANAGER_POLICIES") ||
      hasAuthority("MANAGER_MANAGERS")
    );
  };

  return (
    <>
      <div className="tw-card p-4">
        <div className="font-bold mb-4">{user.details?.fullName}</div>
        <div className="tw-table-container">
          <table className="tw-table">
            <tbody className="tw-tbody">
              <MenuItem
                hasAuthority={hasAnySettingsAuthority()}
                route="/management/settings"
                name={strings.SITE_MANAGER_SETTINGS}
              />
              <MenuItem
                hasAuthority={hasAuthority("MANAGER_USERS")}
                route="/management/users"
                name={strings.USERS}
              />
              <MenuItem
                hasAuthority={hasAuthority("MANAGER_CLINICS")}
                route="/management/clinics"
                name={strings.CLINICS}
              />
              <MenuItem
                hasAuthority={hasAuthority("MANAGER_BILLING")}
                route="/management/billing"
                name={strings.BILLING}
              />
              <MenuItem
                hasAuthority={hasAuthority("MANAGER_DOCUMENTS")}
                route="/management/vet-documents"
                name={strings.VET_DOCUMENTS}
              />
              <MenuItem
                hasAuthority={hasAuthority("MANAGER_INSURANCE")}
                route="/management/insurance"
                name={strings.INSURANCE}
              />
              <MenuItem
                hasAuthority={hasAuthority("MANAGER_FEEDBACKS")}
                route="/management/feedbacks"
                name={strings.FEEDBACKS}
              />
              <MenuItem
                hasAuthority={hasAuthority("MANAGER_CLINICS")}
                route="/management/analytics/events"
                name={strings.ANALYTICS}
              />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

interface ItemProps {
  hasAuthority: boolean;
  route: string;
  name: string;
}

export const MenuItem = (props: ItemProps): ReactElement => {
  const { hasAuthority, route, name } = props;

  if (!hasAuthority) {
    return <></>;
  }

  return (
    <tr key={name}>
      <td>
        <Link to={route} className="text-current font-medium">
          <div className="d-flex px-4 py-3">
            <div className="flex-grow-1 self-center">{name}</div>
            <div className="self-center align-items-center">
              <Button variant="icon" small className="m-0">
                <ChevronRight />
              </Button>
            </div>
          </div>
        </Link>
      </td>
    </tr>
  );
};

export default Menu;
