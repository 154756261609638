/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export enum SiteManagerPermission {
  INSURANCE = "INSURANCE",
  CONFIG = "CONFIG",
  DOCUMENTS = "DOCUMENTS",
  FEES = "FEES",
  POLICIES = "POLICIES",
  USERS = "USERS",
  FEEDBACKS = "FEEDBACKS",
  MANAGERS = "MANAGERS",
  BILLING = "BILLING",
  CLINICS = "CLINICS",
  CLINIC_REMOTE_ACCESS = "CLINIC_REMOTE_ACCESS",
}
