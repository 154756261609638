/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { createContext, useContext, useEffect, useState } from "react";
import logger from "../util/logger";
import PetSpeciesApi from "../api/PetSpeciesApi";
import { LocalizedPetSpeciesResponse } from "../models/pet/LocalizedPetSpeciesResponse";
import { ChildrenProps } from "../models/ChildrenProps";

interface ISpeciesContext {
  speciesCodesAndNames: LocalizedPetSpeciesResponse[]
}

const SpeciesContext = createContext<ISpeciesContext | null>(null);

export const useSpecies = () => useContext(SpeciesContext)!!;

export const SpeciesProvider: React.FC<ChildrenProps> = ({ children }: ChildrenProps) => {
  const [speciesCodesAndNames, setSpeciesCodesAndNames] = useState<
    LocalizedPetSpeciesResponse[]
    >([]);

  const getPetSpecies = async () => {
    try {
      const response = await PetSpeciesApi.getPetSpecies();
      setSpeciesCodesAndNames(response.data);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    void getPetSpecies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ctx = {
    speciesCodesAndNames
  };

  return (
    <SpeciesContext.Provider value={ctx}>{children}</SpeciesContext.Provider>
  );
};
