/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import moment from "moment";
import { strings } from "../../common/Strings/Strings";
import { calendarPlaceholder } from "../../assets/AssetConfig";
import Button from "../../components/Button";
import { PlusIcon } from "../../common/Icons/PlusIcon";
import CalendarWeekNewShiftModal from "./Week/CalendarWeekNewShiftModal";
import CalendarDayNewReservationModal from "./NewReservation/CalendarDayNewReservationModal";

interface Props {
  reloadReservations(): void;
  selectedDay: string;
}

const NoShiftYet: React.FC<Props> = ({ reloadReservations, selectedDay }) => {
  const [modalNewIsOpen, setModalNewIsOpen] = useState<boolean>(false);
  const [modalNewReservationIsOpen, setModalNewReservationIsOpen] =
    useState<boolean>(false);

  const openNewModal = (): void => {
    setModalNewIsOpen(true);
  };

  const closeNewModal = (): void => {
    setModalNewIsOpen(false);
  };

  const openNewReservationModal = (): void => {
    setModalNewReservationIsOpen(true);
  };

  const closeNewReservationModal = (): void => {
    setModalNewReservationIsOpen(false);
  };

  return (
    <section>
      <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
        <form className="w-full md:max-w-md md:tw-card space-y-6 md:p-8 text-center">
          <div>
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.newShift}
            </h1>
          </div>
          <div>
            <p>{strings.noShiftOnThisDay}</p>
          </div>
          <div className="flex items-center justify-center">
            <div className="relative">
              <img src={calendarPlaceholder} className="w-40" alt="Add pet" />
              <Button
                variant="circle-primary"
                className="absolute bottom-0 right-0 p-4"
                onClick={openNewModal}
              >
                <PlusIcon />
                <span className="sr-only">Plus</span>
              </Button>
            </div>
          </div>
          <div>
            <Button variant="primary" fullWidth onClick={openNewModal}>
              {strings.newShift}
            </Button>
          </div>
          <div className="flex items-center justify-center">
            <span className="w-full border border-gray-200 dark:border-gray-700" />
            <span className="px-4 text-gray-500 dark:text-gray-400">
              {strings.OR}
            </span>
            <span className="w-full border border-gray-200 dark:border-gray-700" />
          </div>
          <div>
            <Button
              variant="primary-outline"
              fullWidth
              onClick={openNewReservationModal}
            >
              {strings.createEmergencyAppointment}
            </Button>
          </div>
        </form>
      </div>
      <CalendarWeekNewShiftModal
        open={modalNewIsOpen}
        closeNewModal={closeNewModal}
        selectedTimeAndDate={{
          time: "07:00",
          date: selectedDay,
        }}
        reload={reloadReservations}
      />
      <CalendarDayNewReservationModal
        close={closeNewReservationModal}
        dateAndTime={moment(selectedDay)
          .set({ hour: moment().hours(), minute: moment().minutes() })
          .toDate()}
        isEmergency
        open={modalNewReservationIsOpen}
        reload={reloadReservations}
      />
    </section>
  );
};

export default NoShiftYet;
