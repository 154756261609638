/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { CombinedSelectComponentProps } from "../../../InputFieldsSimple/CombinedSelectComponent";
import DynamicSelectComponent, {
  DynamicSelectComponentProps,
} from "../../../InputFieldsSimple/DynamicSelectComponent";

export interface CombinedSelectFieldProps {
  control: Control<any>;
  name: string;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  onChange?(e: any): void;
}

const DynamicSelect = ({
  control,
  name: n,
  fieldOptions,
  onChange: customOnchange,
  required,
  ...rest
}: CombinedSelectFieldProps &
  CombinedSelectComponentProps &
  DynamicSelectComponentProps): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  return (
    <Controller
      name={n}
      control={control}
      rules={registerOptions}
      render={({
        field: { onChange, value },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        return (
          <DynamicSelectComponent
            name={n}
            error={error}
            onChange={e => {
              onChange(e);

              if (customOnchange) {
                customOnchange(e);
              }
            }}
            value={value}
            {...rest}
          />
        );
      }}
    />
  );
};

export default DynamicSelect;
