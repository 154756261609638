/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { getGeneralError } from "../../../util/helperFunctions";
import logger from "../../../util/logger";
import EmailConfirmationInfoBase from "../../../components/contact/confirmation/EmailConfirmationInfoBase";
import { ApiPromise } from "../../../models/ApiPromise";

interface Props {
  email: string;
  emailId: string;
  resend: (emailId: string) => ApiPromise<any>;
}

const ResendEmailConfirmation: React.FC<Props> = (props) => {
  const { email, emailId, resend } = props;
  const [resendSuccess, setResendSuccess] = useState<boolean>(false);
  const [resendError, setResendError] = useState<string | null>(null);
  const [resendLoading, setResendLoading] = useState<boolean>(false);

  const sendConfirmationMailAgain = async () => {
    setResendLoading(true);
    try {
      await resend(emailId);
      setResendSuccess(true);
    } catch (err) {
      logger.error(err);
      setResendError(await getGeneralError(err));
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <EmailConfirmationInfoBase
      email={email}
      error={resendError}
      loading={resendLoading}
      resend={sendConfirmationMailAgain}
      success={resendSuccess}
    />
  );
};

export default ResendEmailConfirmation;
