/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import emblemPrimary from "./Logos/globalvet_jel_0ca2fe.svg";
import textDark from "./Logos/globalvet_felirat_212529-01.svg";
import textWhite from "./Logos/globalvet_felirat_white-01.svg";
import smallEmblemWhite from "./Logos/globalvet_kisjel_white-01.svg";
import doctor from "./img/placeholders/onboarding-doctor-placeholder.png";
import clinic from "./img/placeholders/clinic-placeholder.png";
import dog from "./Placeholders/dog-placeholder.svg";
import dogMain from "./img/placeholders/onboarding-pet-owner-placeholder.png";
import alpaca from "./Placeholders/alpaca-placeholder.svg";
import camel from "./Placeholders/camel-placeholder.svg";
import cat from "./Placeholders/cat-placeholder.svg";
import chicken from "./Placeholders/chicken-placeholder.svg";
import duck from "./Placeholders/duck-placeholder.svg";
import fish from "./Placeholders/fish-placeholder.svg";
import insect from "./Placeholders/insect-placeholder.svg";
import moose from "./Placeholders/moose-placeholder.svg";
import mouse from "./Placeholders/mouse-placeholder.svg";
import ostrich from "./Placeholders/ostrich-placeholder.svg";
import pig from "./Placeholders/pig-placeholder.svg";
import rabbit from "./Placeholders/rabbit-placeholder.svg";
import sheep from "./Placeholders/sheep-placeholder.svg";
import pet from "./Placeholders/pet-placeholder.svg";
import medicalRecord from "./Placeholders/medical-record-icon.svg";
import user from "./Placeholders/profile-placeholder-v2.png";
import eHealth from "./Images/eHealthLogo.png";
import fass from "./Images/fass-se.jpg";
import arrowDown from "./img/icons/chevron-down.svg";
import codeInput from "./Icons/code.svg";
import medicinalCertificate from "./Icons/medicinal-certificate.svg";
import licence from "./Icons/licence.svg";
import facebook from "./Icons/facebook-icon.svg";
import google from "./Icons/google-icon.svg";
import building from "./Icons/building-icon.svg";
import roundPlus from "./Icons/round-plus-icon.svg";
import petDrawing from "./Icons/pet-icon.svg";
import petNotification from "./Icons/pet-notification-icon.svg";
import cookie from "./img/icons/cookie.svg";
import addNewPet from "./img/placeholders/add-new-pet-placeholder.png";
import userRegistration from "./img/placeholders/user-registration.png";
import calendar from "./img/placeholders/appointment-placeholder.png";
import notAvailable from "./img/placeholders/no-connection.png";
import videoConsultation from "./Images/video-call.png";
import callEnd from "./Images/call-end.png";
import permission from "./Images/permission.png";

/* Logos */
export const mainEmblem = emblemPrimary;
export const mainText = textDark;
export const simpleLightText = textWhite;
export const smallEmblemInDropdown = smallEmblemWhite;

/* Placeholders */
export const userProfilePlaceholder = user;

export const dogMainPlaceholder = dogMain;
export const dogPlaceholder = dog;
export const doctorPlaceholder = doctor;
export const clinicPlaceholder = clinic;
export const clinicPlaceholderColored = clinic;
export const addNewPetPlaceholder = addNewPet;

export const alpacaPlaceholder = alpaca;
export const camelPlaceholder = camel;
export const catPlaceholder = cat;
export const chickenPlaceholder = chicken;
export const duckPlaceholder = duck;
export const fishPlaceholder = fish;
export const insectPlaceholder = insect;
export const moosePlaceholder = moose;
export const mousePlaceholder = mouse;
export const ostrichPlaceholder = ostrich;
export const pigPlaceholder = pig;
export const rabbitPlaceholder = rabbit;
export const sheepPlaceholder = sheep;
export const petPlaceholder = pet;
export const medicalRecordPlaceholder = medicalRecord;
export const userRegistrationPlaceholder = userRegistration;
export const calendarPlaceholder = calendar;
export const notAvailablePlaceholder = notAvailable;

/* Images */
export const eHealthLogo = eHealth;
export const fassSePlaceholder = fass;

/* Icons */
export const arrowDownIcon = arrowDown;
export const codeIcon = codeInput;
export const vetDocumentIcon = medicinalCertificate;
export const vetLicence = licence;
export const facebookIcon = facebook;
export const googleIcon = google;
export const buildingIcon = building;
export const roundPlusIcon = roundPlus;
export const petIcon = petDrawing;
export const petNotificationIcon = petNotification;
export const cookieIcon = cookie;
export const videoConsultationIcon = videoConsultation;
export const callEndIcon = callEnd;
export const permissionImage = permission;
