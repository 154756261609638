/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { strings } from "../common/Strings/Strings";
import { cookieIcon } from "../assets/AssetConfig";
import Analytics from "../util/Analytics";

const CookieApproval: React.FC = () => {
  const handleAcceptCookie = () => {
    Analytics.setAnalyticsEnabled(true);
  };

  const handleDeclineCookie = () => {
    Analytics.setAnalyticsEnabled(false);
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent) {
      handleAcceptCookie();
    }
  }, []);

  return (
    <CookieConsent
      disableStyles
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
      containerClasses="fixed bottom-5 z-100 inset-x-5 drop-shadow-2xl rounded-lg mx-auto max-w-screen-xl bg-white p-4 flex flex-wrap lg:flex-nowrap space-x-4 gap-4 items-center justify-between lg:justify-between dark:bg-slate-700"
      location="bottom"
      buttonWrapperClasses="flex justify-center"
      declineButtonClasses="tw-btn-secondary mr-4"
      buttonClasses="tw-btn-tercier"
      buttonText={strings.acceptCookie}
      declineButtonText={strings.declineCookie}
    >
      <div className="flex items-center space-x-3 max-w-4xl">
        <img src={cookieIcon} className="w-6" alt="cookie" />
        <div>{strings.cookieConsentText}</div>
      </div>
    </CookieConsent>
  );
};

export default CookieApproval;
