/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect } from "react";

export interface Props {
  calculateModalHeights?(): void;
  className?: string;
  children?: string | ReactElement | ReactElement[];
  extraClassName?: string;
  hidden?: boolean;
}

const ModalFooter: React.FC<Props> = ({
  calculateModalHeights,
  className,
  children,
  extraClassName,
  hidden,
}: Props) => {
  // Recalculate the modal heights in every render
  calculateModalHeights && calculateModalHeights();

  // Recalculate the modal heights when the visibility changes
  useEffect(() => {
    calculateModalHeights && calculateModalHeights();
  }, [calculateModalHeights, hidden]);

  if (hidden) return null;

  return (
    <div
      className={className || `tw-modal-footer ${extraClassName || ""}`}
      id="modal-footer"
    >
      {children}
    </div>
  );
};

ModalFooter.displayName = "ModalFooter";

export default ModalFooter;
