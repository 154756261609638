/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { PageProps } from "../../models/PageProps";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../util/LocalStorageVariables";
import ChooseRole from "./Onboarding/ChooseRole";

const Main: React.FC<PageProps> = (props: PageProps) => {
  const role: UserRole | null = getValueOfActiveUser("role");
  const navigate = useNavigate();

  useEffect(() => {
    if (role === "doctor") {
      navigate("/vet", { replace: true });
    }
    if (role === "owner") {
      navigate("/pet-owner", { replace: true });
    }
    if (role === "manager") {
      navigate("/management", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  if (!role) {
    return <ChooseRole {...props} />;
  }

  return <></>;
};

export default Loader(Main);
