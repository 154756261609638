/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { TreatmentResponse } from "../models/treatment/TreatmentResponse";
import { NewTreatmentRequest } from "../models/treatment/NewTreatmentRequest";
import { UpdateTreatmentRequest } from "../models/treatment/UpdateTreatmentRequest";
import Params from "../common/Params";
import { PageResponse } from "../models/PageResponse";
import { SearchTreatments } from "../models/apiparams/SearchTreatments";

export default class TreatmentApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getTreatmentsOfClinic(
    clinicId: string,
    requestParams: SearchTreatments
  ): ApiPromise<PageResponse<TreatmentResponse>> {
    return axiosCommon.get(`${this.baseUrl}/clinics/${clinicId}/treatments`, {
      params: {
        ...(requestParams || {}),
        species: requestParams?.species?.[0]?.value,
      },
    });
  }

  static getTreatmentOfClinic(
    clinicId: string,
    treatmentId: string
  ): ApiPromise<TreatmentResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/treatments/${treatmentId}`;
    return axiosCommon.get(url);
  }

  static getTreatmentNameSuggestions(clinicId: string): ApiPromise<string[]> {
    const url = `${this.baseUrl}/clinics/${clinicId}/treatments/suggestions/names`;
    return axiosCommon.get(url);
  }

  static addTreatment(
    clinicId: string,
    treatment: NewTreatmentRequest
  ): ApiPromise<TreatmentResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/treatments`;
    return axiosCommon.post(url, treatment);
  }

  static updateTreatment(
    clinicId: string,
    treatmentId: string,
    treatment: UpdateTreatmentRequest
  ): ApiPromise<TreatmentResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/treatments/${treatmentId}`;
    return axiosCommon.put(url, treatment);
  }

  static updateTreatmentInfo(
    clinicId: string,
    treatmentId: string,
    request: UpdateTreatmentRequest
  ): ApiPromise<TreatmentResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/treatments/${treatmentId}`;
    return axiosCommon.put(url, request);
  }

  static removeTreatment(
    clinicId: string,
    treatmentId: string
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/treatments/${treatmentId}`;
    return axiosCommon.delete(url);
  }
}
