/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Link } from "react-router-dom";
import React from "react";

const UrlTab: React.FC<any> = <T,>({
  currentTabKey,
  tabKey,
  title,
  useBaseUrlOnly = false,
  visible = true,
}: {
  useBaseUrlOnly?: boolean;
  currentTabKey: T;
  tabKey: T;
  title: string;
  visible: boolean;
}) => {
  const current = currentTabKey === tabKey;

  if (!visible) {
    return <></>;
  }

  return (
    <li>
      <Link
        aria-current={current ? "page" : undefined}
        className={current ? "tw-tab-active" : "tw-tab-inactive"}
        style={{
          textDecoration: "none",
        }}
        to={`${useBaseUrlOnly ? "." : `${tabKey}`}`}
      >
        {title}
      </Link>
    </li>
  );
};

export default UrlTab;

UrlTab.defaultProps = {
  useBaseUrlOnly: false,
};
