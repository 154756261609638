/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { dateAndTime } from "../../../common/Strings/Strings";
import { localDateFormat } from "../../../util/helperFunctions";
import { Shift } from "../../../models/calendar/Shift";
import CalendarWeekNewShiftModal from "./CalendarWeekNewShiftModal";
import { getCalendarNavigationClass } from "../Day/MonthCalendarNavigation";
import ShiftModal from "./ShiftModal";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";

interface Props {
  colSpans: number[];
  reload(): void;
  schedule: any[];
  selectedDay: string;
  times: string[];
  weekEnd: boolean;
}

const CalendarWeek: React.FC<Props> = ({
  colSpans,
  reload,
  selectedDay,
  schedule,
  times,
  weekEnd,
}: Props) => {
  const [weekDaysDates, setWeekDaysDates] = useState<moment.Moment[]>([]);
  const [selectedShiftData, setSelectedShiftData] = useState<Shift>();
  const [selectedTimeAndDate, setSelectedTimeAndDate] = useState<{
    time: string;
    date: string;
  }>({
    time: "",
    date: "",
  });
  const [tdWidth, setTdWidth] = useState<number>(20);
  const [borderTemplate, setBorderTemplate] = useState<number[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalNewIsOpen, setModalNewIsOpen] = useState<boolean>(false);

  const openModal = (shiftData: Shift): void => {
    setModalIsOpen(true);
    setSelectedShiftData(shiftData);
  };

  const closeModal = (): void => {
    setModalIsOpen(false);
  };

  const openNewModal = (time: string, columnNumber?: number): void => {
    setModalNewIsOpen(true);

    if (columnNumber !== undefined) {
      const temp = colSpans.slice(1, colSpans.length);
      temp.push(colSpans[0]);
      let sum = temp[0];
      let day = 0;

      while (sum <= columnNumber) {
        day += 1;
        sum += temp[day];
      }
      const result = {
        time,
        date: moment(weekDaysDates[day]).format("YYYY-MM-DD"),
      };
      setSelectedTimeAndDate(result);
    } else {
      setSelectedTimeAndDate({
        time,
        date:
          weekDaysDates[0].format("YYYY-MM-DD") ||
          selectedDay ||
          moment().format("YYYY-MM-DD"),
      });
    }
  };

  const closeNewModal = (): void => {
    setModalNewIsOpen(false);
  };

  useEffect(() => {
    const weekArray = [];
    let date: moment.Moment = moment(selectedDay).weekday(0);
    const width = weekEnd ? 100 / 7 : 100 / 5;
    setTdWidth(width);

    for (let i = 1; i <= 7; i += 1) {
      if (!weekEnd && (date.day() === 0 || date.day() === 6)) {
        /* empty */
      } else {
        weekArray.push(date);
      }
      date = moment(date).add(1, "days");
    }
    setWeekDaysDates(weekArray);
  }, [weekEnd, selectedDay]);

  useEffect(() => {
    const template: number[] = [];
    let temp: number[];

    if (
      moment(selectedDay).weekday(0).week() !==
        moment(selectedDay).day(0).week() ||
      !weekEnd
    ) {
      temp = colSpans.slice(1, colSpans.length);
      temp.push(colSpans[0]);
    } else {
      temp = colSpans;
    }

    temp.forEach((i) => {
      for (let j = 0; j < i; j += 1) {
        if (j === i - 1) template.push(1);
        else template.push(0);
      }
    });

    setBorderTemplate(template);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colSpans, weekEnd]);

  const proxyReload = (): void => {
    reload();
  };

  return (
    <div>
      <div className="overflow-scroll max-h-screen">
        <table className="tw-table">
          <thead>
            <tr>
              <th className="" />
              {weekDaysDates.map((weekDay, index) => {
                const numberWeekContainerClass = getCalendarNavigationClass(
                  weekDay,
                  selectedDay
                ).numberContainerClass;
                const numberWeekClass = getCalendarNavigationClass(
                  weekDay,
                  selectedDay
                ).numberClass;

                return (
                  <th
                    key={`weekdays-${index}`}
                    className=""
                    style={{ width: `${tdWidth}%` }}
                    colSpan={colSpans[moment(weekDay).day()]}
                  >
                    <div className="flex flex-col items-center space-y-1">
                      <span className="uppercase text-xs font-semibold text-gray-500 dark:text-gray-400">
                        {moment(weekDay).format("dd")}
                      </span>
                      <div className={numberWeekContainerClass}>
                        <span className={numberWeekClass}>
                          {" "}
                          {moment(weekDay).format("D")}
                        </span>
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800">
            {schedule.map((row, index) => (
              <tr key={`schedule-${index}`}>
                <td
                  role="gridcell"
                  className="calendar-times mt-1 text-xs font-normal text-gray-500 dark:text-gray-400 py-1 pr-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    openNewModal(times[index]);
                  }}
                  onKeyDown={() => {
                    openNewModal(times[index]);
                  }}
                >
                  {times[index]}
                </td>
                {row.map(
                  (i: number | boolean | Shift | string, rIndex: number) => {
                    if (rIndex % 3 === 0) {
                      if (i === -2)
                        return (
                          <td
                            role="gridcell"
                            onClick={() => {
                              openNewModal(times[index], rIndex);
                            }}
                            key={`${rIndex}-${index}`}
                            className="borderCalendarSimple"
                            style={{ cursor: "pointer" }}
                          />
                        );
                      if (i === -1)
                        return <Fragment key={`${rIndex}-${index}`} />;
                      if (typeof i === "number" && i > -1)
                        return (
                          <td
                            role="gridcell"
                            key={`${rIndex}-${index}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              openModal(row[rIndex + 1]);
                            }}
                            className="borderCalendarSimple bg-sky-50 dark:bg-sky-800 rounded-lg text-center align-top"
                            width="1%"
                            rowSpan={i}
                          >
                            <div
                              className="my-2 sticky top-2"
                              style={{ width: "20px", height: "20px" }}
                            >
                              <UserProfilePicture
                                userId={row[rIndex + 1].collaborator.userId}
                              />
                            </div>
                            <span className="rotate sticky top-9">
                              {row[rIndex + 1] === undefined ? (
                                ""
                              ) : (
                                <div className="text-xs dark:text-gray-200">
                                  {row[rIndex + 1].collaborator.fullName} &nbsp;
                                  {`${moment(
                                    row[rIndex + 1].startDateTime,
                                    localDateFormat()
                                  ).format(
                                    moment(
                                      row[rIndex + 1].startDateTime
                                    ).isSame(row[rIndex + 1].endDateTime, "day")
                                      ? dateAndTime.timeFormat
                                      : dateAndTime.shortDateTimeFormat
                                  )}–${moment(
                                    row[rIndex + 1].endDateTime,
                                    localDateFormat()
                                  ).format(
                                    moment(
                                      row[rIndex + 1].startDateTime
                                    ).isSame(row[rIndex + 1].endDateTime, "day")
                                      ? dateAndTime.timeFormat
                                      : dateAndTime.shortDateTimeFormat
                                  )}`}
                                </div>
                              )}
                            </span>
                          </td>
                        );
                    }
                    if (rIndex % 3 === 1) {
                      let borderRadius = "0 0 0 0";
                      const isAboveCellDifferentColor: boolean =
                        schedule[index - 1] &&
                        schedule[index - 1][rIndex + 1] !== row[rIndex + 1];
                      const isUnderCellDifferentColor: boolean =
                        schedule[index + 1] &&
                        schedule[index + 1][rIndex + 1] !== row[rIndex + 1];

                      if (isAboveCellDifferentColor) {
                        borderRadius = "0 8px 0 0";
                      }

                      if (isUnderCellDifferentColor) {
                        borderRadius = "0 0 8px 0";
                      }

                      if (
                        isUnderCellDifferentColor &&
                        isAboveCellDifferentColor
                      ) {
                        borderRadius = "0 8px 8px 0";
                      }

                      const bgColor =
                        row[rIndex + 1] === undefined ? false : row[rIndex + 1];

                      return (
                        <td
                          style={{
                            width: ".7%",
                            cursor: "pointer",
                            borderRadius,
                            backgroundColor: bgColor === false ? "" : bgColor,
                          }}
                          onClick={() => {
                            openNewModal(times[index], rIndex);
                          }}
                          role="gridcell"
                          key={`${rIndex}-${index}`}
                          className="borderCalendarSimple"
                        />
                      );
                    }
                    let border = "";
                    if (borderTemplate[rIndex] === 1) {
                      border = "border-r border:gray-200 dark:border-gray-700";
                    }

                    const className = `borderCalendarSimple ${border}`;
                    return (
                      <td
                        role="gridcell"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          openNewModal(times[index], rIndex);
                        }}
                        key={`${rIndex}-${index}`}
                        className={className}
                      />
                    );
                  }
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedShiftData ? (
        <ShiftModal
          selectedShiftData={selectedShiftData}
          onHide={closeModal}
          reload={proxyReload}
          show={modalIsOpen}
        />
      ) : (
        <></>
      )}
      <CalendarWeekNewShiftModal
        open={modalNewIsOpen}
        closeNewModal={closeNewModal}
        selectedTimeAndDate={selectedTimeAndDate}
        reload={proxyReload}
      />
    </div>
  );
};

export default CalendarWeek;
