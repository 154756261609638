/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";

interface Props {
  title: string;
  hidden?: boolean;
  children: React.ReactNode;
}

export const SubsectionTemplate: React.FC<Props> = ({
  title,
  hidden = false,
  children,
}) => {
  return (
    <div hidden={hidden}>
      <div className="flex flex-col space-y-4">
        <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {title}
        </h2>
        {children}
      </div>
    </div>
  );
};

export default SubsectionTemplate;
