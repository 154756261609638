/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { useClinic } from "../../../contexts/ClinicContext";
import { SavedReservationProgress } from "../../../models/calendar/SavedReservationProgress";
import { NewReservationPetType } from "../../../models/calendar/NewReservationPetType";
import { saveReservationInProgress } from "../../../util/LocalStorageVariables";
import NewExternalMedicalRecordModal from "../../Pet/History/NewExternalMedicalRecordModal";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import TodoModal from "../../Todo/TodoModal";
import { ClinicPetResponse } from "../../../models/pet/ClinicPetResponse";
import { Feature } from "../../../models/clinic/Feature";
import { ClinicFeature } from "../../../models/clinic/ClinicFeature";

interface Props {
  pet: ClinicPetResponse;
  owner?: PetOwnerResponse;
}

const PetListRowActions = ({ pet, owner }: Props): ReactElement => {
  const [externalMedicalRecordModal, setExternalMedicalRecordModal] =
    useState<boolean>(false);
  const [newTodoModal, setNewTodoModal] = useState<boolean>(false);
  const { clinic } = useClinic();
  const navigate = useNavigate();

  const openExternalMedicalRecordModal = () => {
    setExternalMedicalRecordModal(true);
  };

  const closeExternalMedicalRecordModal = () => {
    setExternalMedicalRecordModal(false);
  };

  const openNewTodoModal = () => {
    setNewTodoModal(true);
  };

  const closeNewTodoModal = () => {
    setNewTodoModal(false);
  };

  const createNewAppointment = () => {
    if (pet && owner) {
      const temp: SavedReservationProgress = {
        petState: {
          state: NewReservationPetType.SELECTED,
          pet: {
            clinicPetDetails: {
              id: pet.clinicPetDetails.id,
              name: pet.clinicPetDetails.name,
              gender: pet.clinicPetDetails.gender,
              species: pet.clinicPetDetails.species,
              breed: pet.clinicPetDetails.breed,
              chronicDiseases: pet.clinicPetDetails.chronicDiseases || [],
              deceased: pet.clinicPetDetails.deceased,
            },
          },
          petOwner: owner,
        },
        symptoms: [],
      };

      saveReservationInProgress(temp);
      navigate("/");
    }
  };

  return (
    <>
      <Dropdown className="rounded-dropdown">
        <Dropdown.Toggle as="div" bsPrefix="toggle-add" id="dropdown-pets-news">
          <span className="material-icons">add</span>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end" className="mt-1 p-0">
          {clinic?.availableFeatures?.includes(ClinicFeature.PRESCRIPTION) ? (
            <Dropdown.Item
              bsPrefix="calendar-menu dropdown-item hr-on-bottom"
              href={`/prescription/${pet.clinicPetDetails?.id}`}
            >
              {strings.newPrescription}
            </Dropdown.Item>
          ) : (
            <></>
          )}
          <Dropdown.Item
            bsPrefix="calendar-menu dropdown-item hr-on-bottom"
            as="button"
            onClick={createNewAppointment}
          >
            {strings.newAppointment}
          </Dropdown.Item>
          <Dropdown.Item
            bsPrefix="calendar-menu dropdown-item hr-on-bottom"
            as="button"
            onClick={openNewTodoModal}
          >
            {strings.newTask}
          </Dropdown.Item>
          <Dropdown.Item
            bsPrefix="calendar-menu dropdown-item"
            as="button"
            onClick={() => {
              openExternalMedicalRecordModal();
            }}
          >
            {strings.addNewExternalMedicalRecord}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="rounded-dropdown">
        <Dropdown.Toggle
          as="div"
          bsPrefix="toggle-kebab"
          id="dropdown-pets-more"
        >
          <span className="material-icons">more_vert</span>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end" className="mt-1 p-0">
          <Dropdown.Item
            bsPrefix="calendar-menu dropdown-item hr-on-bottom"
            href={`/edit-clinic-pet/${pet.clinicPetDetails.id}`}
          >
            {strings.editPet}
          </Dropdown.Item>
          {clinic?.features.includes(Feature.RESERVATION) && (
            <Dropdown.Item
              bsPrefix="calendar-menu dropdown-item hr-on-bottom"
              href={`/last-medical-record/${pet.clinicPetDetails.id}`}
            >
              {strings.openLastMedicalRecord}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <NewExternalMedicalRecordModal
        close={closeExternalMedicalRecordModal}
        isOpen={externalMedicalRecordModal}
        petId={pet.clinicPetDetails.id}
      />
      <TodoModal
        onHide={closeNewTodoModal}
        show={newTodoModal}
        todoForPet={owner ? { pet, petOwner: owner } : undefined}
      />
    </>
  );
};

export default PetListRowActions;
