/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { ReservationTypeResponse } from "../models/reservationtype/ReservationTypeResponse";
import { ReservationTypeRequest } from "../models/reservationtype/ReservationTypeRequest";
import Params from "../common/Params";
import queryString from "query-string";

export default class ReservationTypeApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getReservationTypesOfClinic(
    clinicId: string,
    reservable?: boolean
  ): ApiPromise<ReservationTypeResponse[]> {
    const queryStr = queryString.stringify({
      reservable,
    });
    const url = `${this.baseUrl}/clinics/${clinicId}/reservations/types?${queryStr}`;
    return axiosCommon.get(url);
  }

  static addReservationType(
    clinicId: string,
    request: ReservationTypeRequest
  ): ApiPromise<ReservationTypeResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/reservations/types`;
    return axiosCommon.post(url, request);
  }

  static updateReservationType(
    clinicId: string,
    reservationTypeId: string,
    request: ReservationTypeRequest
  ): ApiPromise<ReservationTypeResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/reservations/types/${reservationTypeId}`;
    return axiosCommon.put(url, request);
  }

  static removeReservationType(
    clinicId: string,
    reservationTypeId: string
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/reservations/types/${reservationTypeId}`;
    return axiosCommon.delete(url);
  }
}
