/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import React, { Fragment, ReactElement } from "react";
import { ViewTypes } from "../MainCalendar";

interface Props {
  localSelectedDay?: string;
  realFirstDay: string;
  realLastDay: moment.Moment;
  setLocalSelectedDay(newDate: string): void;
  viewType?: ViewTypes;
}

export const getCalendarNavigationClass = (
  day: moment.Moment,
  selectedDay?: string | moment.Moment | Date
): { numberContainerClass: string; numberClass: string } => {
  let numberContainerClass =
    "rounded-full w-10 h-10 flex items-center justify-center";
  let numberClass =
    "text-center text-2xl font-light text-zinc-800 dark:text-zinc-300";

  if (day.isSame(moment(new Date()), "day")) {
    numberContainerClass =
      "bg-sky-500 rounded-full w-10 h-10 flex items-center justify-center";
    numberClass = "text-center text-2xl font-light text-white";
  } else if (day.isSame(moment(selectedDay), "day")) {
    numberContainerClass =
      "bg-gray-600 rounded-full w-10 h-10 flex items-center justify-center";
    numberClass = "text-center text-2xl font-light text-white";
  } else if (day.day() === 6 || day.day() === 0) {
    numberContainerClass =
      "bg-gray-100 dark:bg-gray-700 rounded-full w-10 h-10 flex items-center justify-center";
  }

  return { numberContainerClass, numberClass };
};

const MonthCalendarNavigation = ({
  localSelectedDay: selectedDay,
  realFirstDay,
  realLastDay,
  setLocalSelectedDay: setSelectedDay
}: Props): ReactElement => {
  const daysOfMonth = [];
  const realDaysInMonth: number = realLastDay.diff(realFirstDay, "day") + 1;

  for (let i = 0; i <= realDaysInMonth - 1; i += 1) {
    const day = moment(realFirstDay).add(i, "day");
    let numberContainerClass = getCalendarNavigationClass(
      day,
      selectedDay
    ).numberContainerClass;
    let numberClass = getCalendarNavigationClass(day, selectedDay).numberClass;

    const monthDay = { date: day, numberContainerClass, numberClass };
    daysOfMonth.push(monthDay);
  }

  return (
    <div className="hidden lg:flex justify-around">
      {daysOfMonth.map((day) => {
        return (
          <Fragment key={`${day.date}`}>
            <button
              className="flex flex-col items-center space-y-1"
              type="button"
              onClick={() => {
                setSelectedDay(moment(day.date).format("YYYY-MM-DD"));
              }}
            >
              <span className="uppercase text-xs font-semibold text-gray-500 dark:text-gray-400">
                {day.date.format("dd")}
              </span>
              <div
                className={day.numberContainerClass}
                id="calendarNumberContainer"
              >
                <span className={day.numberClass} id="calendarNumber">
                  {moment(day.date).format("D")}
                </span>
              </div>
            </button>
          </Fragment>
        );
      })}
    </div>
  );
};

export default MonthCalendarNavigation;

MonthCalendarNavigation.defaultProps = {
  localSelectedDay: undefined,
};
