/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { downloadInvoicePdf, printInvoicePdf } from "../../../util/FileUtils";
import { strings } from "../../../common/Strings/Strings";
import logger from "../../../util/logger";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import LoaderInline from "../../../components/LoaderInline";
import InvoiceState from "../InvoiceDetails/InvoiceState";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";

interface Props {
  isOpen: boolean;
  close: () => void;
  invoice?: InvoiceResponse;
}

const SuccessModal: React.FC<Props> = ({
  isOpen,
  close,
  invoice: invoiceProp,
}: Props) => {
  const navigate = useNavigate();

  const [invoice, setInvoice] = useState<InvoiceResponse>();
  const [isPrinting, setPrinting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setInvoice(invoiceProp);
  }, [invoiceProp]);

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header>
        <i
          className="fas fa-check-circle color-green mr-3"
          hidden={invoice?.valid === false}
          style={{ fontSize: "24px" }}
        />
        {strings.invoiceCreated}
      </Modal.Header>
      <Modal.Body>
        {invoice?.valid === false ? (
          <div className="m-3">
            {invoice && (
              <InvoiceState
                invoice={invoice}
                returnRefreshedInvoice={(i: InvoiceResponse) => {
                  setInvoice(i);
                }}
              />
            )}
          </div>
        ) : (
          <>
            {invoice?.customer?.email ? (
              <div className="hr-on-bottom p-3">
                {strings.invoiceHasBeenSentInEmail}{" "}
                <b>{invoice?.customer.email}</b>
              </div>
            ) : (
              <></>
            )}
            <div className="flex justify-center items-center px-5">
              <div className="mr-3">{strings.printOrDownloadInvoice}</div>
              <div className="ml-auto">
                {isDownloading ? (
                  <LoaderInline />
                ) : (
                  <Button
                    className="p-5"
                    disabled={isDownloading || !invoice}
                    onClick={async () => {
                      setIsDownloading(true);
                      try {
                        if (invoice) {
                          await downloadInvoicePdf(invoice);
                        }
                      } catch (err) {
                        logger.error(err);
                        setError(await getGeneralError(err));
                      } finally {
                        setIsDownloading(false);
                      }
                    }}
                    variant="icon"
                  >
                    <span className="material-icons">file_download</span>
                  </Button>
                )}
              </div>
              <div className="ml-5">
                {isPrinting ? (
                  <LoaderInline />
                ) : (
                  <Button
                    className="p-5"
                    disabled={isPrinting || !invoice}
                    onClick={async () => {
                      setPrinting(true);
                      try {
                        if (invoice) {
                          await printInvoicePdf(invoice, () =>
                            setPrinting(false)
                          );
                        }
                      } catch (err) {
                        logger.error(err);
                        setError(await getGeneralError(err));
                        setPrinting(false);
                      }
                    }}
                    variant="icon"
                  >
                    <span className="material-icons">print</span>
                  </Button>
                )}
              </div>
            </div>
            <AlertBox message={error} className="my-5" />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          onClick={() => {
            navigate(`/sales`);
          }}
        >
          {strings.proceedToInvoices}
        </Button>
        <Button
          className="modal-sub-button"
          onClick={() => {
            navigate(`/invoices/clinic/${invoice?.id}`);
          }}
          variant="link"
        >
          {strings.invoice}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;

SuccessModal.defaultProps = {
  invoice: undefined,
};
