/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../../common/Strings/Strings";
import { GiroAccountResponse } from "../../../../../models/clinic/GiroAccountResponse";
import { GiroAccountsRequest } from "../../../../../models/clinic/GiroAccountsRequest";
import { getGeneralError } from "../../../../../util/helperFunctions";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import AlertBox from "../../../../../components/AlertBox";
import ClinicApi from "../../../../../api/ClinicApi";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal/Modal";
import CloseButton from "../../../../../components/CloseButton";

interface Props {
  isOpen: boolean;
  close(): void;
  refresh: (data: GiroAccountResponse[]) => void;
  clinicId: string;
  bankgirotAccount?: string;
  plusgirotAccount?: string;
}

interface EditGiroAccountsForm {
  bankgirot?: string;
  plusgirot?: string;
}

const defaultFormValues = (bankgirot?: string, plusgirot?: string) => ({
  bankgirot: bankgirot ?? "",
  plusgirot: plusgirot ?? "",
});

const EditGiroAccountsModal: React.FC<Props> = ({
  isOpen,
  close,
  refresh,
  clinicId,
  bankgirotAccount,
  plusgirotAccount,
}: Props) => {
  const [externalError, setExternalError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const submit = async (values: EditGiroAccountsForm) => {
    setLoading(true);

    try {
      const request: GiroAccountsRequest = {
        bankgirot: values.bankgirot || null,
        plusgirot: values.plusgirot || null,
      };
      const response = await ClinicApi.updateGiroAccounts(clinicId, request);

      setLoading(false);
      setExternalError(null);
      refresh(response.data);
      close();
    } catch (err) {
      setLoading(false);
      setExternalError(await getGeneralError(err));
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EditGiroAccountsForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(bankgirotAccount, plusgirotAccount),
  });

  useEffect(() => {
    const resetFormAndErrors = () => {
      reset(defaultFormValues(bankgirotAccount, plusgirotAccount));
      setExternalError(null);
    };

    if (isOpen) {
      resetFormAndErrors();
    }
  }, [bankgirotAccount, plusgirotAccount, isOpen, reset]);

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.bankAndPlusGiroAccount} />
      <Modal.Body noSpacing>
        <form
          className="modal-body"
          id="giroAccountsForm"
          onSubmit={handleSubmit(submit)}
        >
          <Field
            name="bankgirot"
            label={strings.bankgirot}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            fieldOptions={{ required: true, maxLength: 10 }}
            error={errors.bankgirot}
          />
          <Field
            name="plusgirot"
            label={strings.plusgirot}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            fieldOptions={{ required: true, maxLength: 10 }}
            error={errors.plusgirot}
          />
          <AlertBox message={externalError} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={close} text={strings.cancel} />
        <Button
          className="modal-main-button"
          disabled={loading || !isValid}
          form="giroAccountsForm"
          loading={loading}
          type="submit"
        >
          {strings.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGiroAccountsModal;
