/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useClinic } from "../../../../contexts/ClinicContext";
import { useUser } from "../../../../contexts/UserContext";
import Button from "../../../../components/Button";
import { strings } from "../../../Strings/Strings";
import { useNavigate } from "react-router-dom";
import {
  LoggedInUser,
  setRoleOfActiveUser,
  updateLoggedInUser,
} from "../../../../util/LocalStorageVariables";

const ForeignClinicWarning: React.FC = () => {
  const { user } = useUser();
  const { clinic, clinics, clearClinic } = useClinic();
  const navigate = useNavigate();

  if (!user.isSiteManager) {
    return <></>;
  }

  if (!clinic || clinics.map((i) => i.clinic.id).includes(clinic.id)) {
    return <></>;
  }

  const leaveClinic = () => {
    clearClinic();
    setRoleOfActiveUser("manager");
    updateLoggedInUser((u: LoggedInUser) => ({
      ...u,
      activeClinicId: undefined,
    }));
    navigate(`/management/clinics/${clinic.id}`);
  };

  return (
    <>
      <div className="fixed z-20 top-14 xl:top-28 left-0 w-full px-4 lg:px-6 tw-alert-warning rounded-none">
        <div className="container flex flex-nowrap items-center justify-between px-0 xl:px-2">
          <div className="text-sm font-medium flex-grow-1 line-clamp-1">
            {strings.foreignClinicWarning}
          </div>
          <div>
            <Button
              variant="danger"
              className="whitespace-nowrap"
              small
              onClick={leaveClinic}
            >
              {strings.leaveClinic}
            </Button>
          </div>
        </div>
      </div>
      <div className="h-14"></div>
    </>
  );
};
export default ForeignClinicWarning;
