/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { Loader } from "../../../../components/Loader";
import Clinics from "./Clinics";
import { useClinic } from "../../../../contexts/ClinicContext";
import { PageProps } from "../../../../models/PageProps";

const ClinicsPage: React.FC<PageProps> = ({ setPageLoading }: PageProps) => {
  const { clinics, clinicsLoading } = useClinic();

  useEffect(() => {
    setPageLoading(clinicsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicsLoading]);

  return <Clinics myClinics={clinics} />;
};

export default Loader(ClinicsPage);
