/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { generateUUId } from "../pages/ePrescription/sections/finish/sendingRequestProcesser";
import { InvoiceItemRequest } from "../models/invoice/InvoiceItemRequest";

export type CartItem = InvoiceItemRequest & {
  localId?: string;
  disabled?: boolean;
  referenceId?: string;
  type?: string;
};

export type LocalInvoiceItemRequest = InvoiceItemRequest & { localId?: string };

type Actions =
  | { type: "fill"; itemsToAdd: CartItem[]; enableDisabled: boolean }
  | { type: "add"; itemToAdd: CartItem }
  | { type: "changeQuantity"; itemToChange: CartItem }
  | { type: "changePrice"; itemToChange: CartItem }
  | { type: "remove"; itemToRemove: CartItem };

export default class InvoiceCartUtils {
  static reducer = (
    state: Array<LocalInvoiceItemRequest>,
    action: Actions
  ): CartItem[] => {
    switch (action.type) {
      case "fill": {
        action.itemsToAdd.forEach((item: CartItem) => {
          item.disabled = action.enableDisabled;
          item.localId = generateUUId();
          if (item.type === "INVENTORY_ITEM") {
            item.inventoryItemId = item.referenceId;
          }
        });
        return action.itemsToAdd;
      }
      case "add": {
        const addActionId =
          action.itemToAdd.inventoryItemId || action.itemToAdd.localId;
        const alreadyAddedItemIndex = state.findIndex(
          (item: CartItem) =>
            item.inventoryItemId === addActionId || item.localId === addActionId
        );
        if (alreadyAddedItemIndex >= 0) {
          state[alreadyAddedItemIndex].quantity += action.itemToAdd.quantity;
          return [...state];
        }
        return [...state, action.itemToAdd];
      }
      case "changeQuantity": {
        const changeActionId =
          action.itemToChange.inventoryItemId || action.itemToChange.localId;
        const changeIndex = state.findIndex(
          (item: CartItem) =>
            item.inventoryItemId === changeActionId ||
            item.localId === changeActionId
        );

        if (changeIndex >= 0) {
          state[changeIndex].quantity = action.itemToChange.quantity;
        }
        return [...state];
      }
      case "changePrice": {
        const changePriceActionId =
          action.itemToChange.referenceId || action.itemToChange.localId;
        const changePriceIndex = state.findIndex(
          (item: CartItem) =>
            item.referenceId === changePriceActionId ||
            item.localId === changePriceActionId
        );

        if (changePriceIndex >= 0) {
          state[changePriceIndex].price = action.itemToChange.price;
        }
        return [...state];
      }
      case "remove": {
        const removeActionId =
          action.itemToRemove.inventoryItemId || action.itemToRemove.localId;

        return [
          ...state.filter(
            (item: CartItem) =>
              (item.inventoryItemId || item.localId) !== removeActionId
          ),
        ];
      }
      default: {
        return [];
      }
    }
  };
}
