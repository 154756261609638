/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import CountryApi from "../../../api/CountryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { ClinicCost } from "../../../models/cost/ClinicCost";
import FeeItemInputs from "./FeeItemInputs";
import {
  ClinicFeeDetailsKeys,
  UpdateFeesForm,
} from "../../../models/management/FeeTypes";
import { CountryPriceSettingsResponse } from "../../../models/management/CountryPriceSettingsResponse";
import { CountryPriceSettingsRequest } from "../../../models/management/CountryPriceSettingsRequest";
import CloseButton from "../../../components/CloseButton";
import { CountrySettingsResponse } from "../../../models/management/CountrySettingsResponse";
import Field from "../../../components/ReactHookFormFields/General/Field";
import moment from "moment";
import Modal from "../../../components/Modal/Modal";

interface Props {
  country: CountrySettingsResponse;
  countryDetails: CountryDetailsResponse;
  settings?: CountryPriceSettingsResponse;
  isOpen: boolean;
  close: () => void;
}

const defaultFormValues = (settings?: CountryPriceSettingsResponse) => {
  const defaultValues: UpdateFeesForm = {};
  defaultValues.validityStart = moment(
    settings?.validityStart ?? new Date()
  ).format("YYYY-MM-DD");

  Object.keys(ClinicCost).forEach((clinicCost) => {
    const feeItem = settings?.clinicFees?.find((i) => i.name === clinicCost);

    defaultValues[clinicCost.toLowerCase() as ClinicFeeDetailsKeys] = {
      price: feeItem?.price ?? "",
      vat: `${feeItem?.vat}` ?? "",
      accountingCode: feeItem?.accountingCode ?? "",
    };
  });

  return defaultValues;
};

const FeesModal = (props: Props): ReactElement => {
  const { country, countryDetails, settings, isOpen, close } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.ERROR);

  const submitFees = async (formData: UpdateFeesForm) => {
    const clinicFees = Object.keys(ClinicCost).map((cost) => {
      const clinicCostKey = cost.toLowerCase() as ClinicFeeDetailsKeys;
      const clinicCost = formData[clinicCostKey];
      return {
        name: cost,
        price: Number(clinicCost ? clinicCost.price : ""),
        vat: Number(clinicCost ? clinicCost.vat : ""),
        accountingCode: clinicCost ? clinicCost.accountingCode : "",
      };
    });

    const request: CountryPriceSettingsRequest = {
      validityStart: new Date(formData.validityStart ?? new Date()),
      clinicFees,
    };

    setAlertMessage(null);
    setLoading(true);

    try {
      await CountryApi.updateCountryPriceSettings(country.id, request);
      setAlertMessage(strings.savedSuccessfully);
      setAlertType(AlertType.SUCCESS);
    } catch (err) {
      setAlertMessage(await getGeneralError(err));
      setAlertType(AlertType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<UpdateFeesForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(settings),
  });

  useEffect(() => {
    reset(defaultFormValues(settings));
    setAlertMessage(null);
  }, [settings, reset]);

  return (
    <>
      <Modal handleClose={close} size="modal-lg" show={isOpen}>
        <Modal.Header title={strings.fees} />
        <Modal.Body>
          <form>
            <Field
              register={register}
              type="date"
              label={strings.startDate}
              name="validityStart"
            />

            {Object.keys(ClinicCost).map((clinicCost) => (
              <div key={clinicCost}>
                <FeeItemInputs
                  control={control}
                  cost={clinicCost as ClinicFeeDetailsKeys}
                  countryDetails={countryDetails}
                  feeItem={settings?.clinicFees?.find(
                    (i) => i.name === clinicCost
                  )}
                />
              </div>
            ))}

            <AlertBox
              className="my-3"
              message={alertMessage}
              type={alertType}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <CloseButton
            disabled={loading}
            onClick={() => {
              close();
            }}
          />
          <Button
            disabled={!isValid || !isDirty || loading}
            onClick={handleSubmit(submitFees)}
          >
            {strings.save}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeesModal;
