/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import { Pencil } from "../../../../../common/Icons/Pencil";
import Button from "../../../../../components/Button";
import { canManageClinic } from "../../../../../util/helperFunctions";
import SzamlazzIntegrationModal from "./SzamlazzIntegrationModal";

export type PrevStateSetter = (prevState: ClinicResponse) => ClinicResponse;

interface Props {
  clinic: ClinicResponse;
  setClinic: (clinic: PrevStateSetter) => void;
}

const SzamlazzIntegration: React.FC<Props> = ({ clinic, setClinic }: Props) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 flex items-center">
          <p className="uppercase">{strings.szamlazzIntegration}</p>
          {clinic.szamlazzEnabled && canManageClinic(clinic) && (
            <div className="ml-auto">
              <Button
                variant="icon"
                onClick={openModal}
                hidden={!canManageClinic(clinic)}
              >
                <Pencil />
              </Button>
            </div>
          )}
        </div>
        <div className="p-4 space-y-4">
          <div>{strings.szamlazzTooltip}</div>
          <AlertBox
            type={clinic.szamlazzEnabled ? AlertType.SUCCESS : AlertType.INFO}
            message={`${strings.state}: ${
              clinic.szamlazzEnabled
                ? strings.integrated
                : strings.notIntegrated
            }`}
            closeAble={false}
          />
        </div>
        {!clinic.szamlazzEnabled && canManageClinic(clinic) && (
          <div className="flex p-4">
            <Button variant="primary" minW onClick={openModal}>
              {strings.addIntegration}
            </Button>
          </div>
        )}
      </div>
      <SzamlazzIntegrationModal
        clinic={clinic}
        setClinic={(previousState) => setClinic(previousState)}
        show={isModalVisible}
        onHide={closeModal}
      />
    </>
  );
};

export default SzamlazzIntegration;
