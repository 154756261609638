/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  strings,
  prescriptionWaitingPeriodType,
} from "../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { MinusInverseIcon } from "../../../common/Icons/MinusInverseIcon";
import { PlusInverseIcon } from "../../../common/Icons/PlusInverseIcon";

const WaitingPeriodsBox: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    getValues,
    register,
    formState: { errors },
  } = useFormContext();

  const isInWaitingPeriodRange = (v: number) => {
    if (!v) {
      return true;
    }

    return (
      (v >= 0 && v < 366) || strings.formatString(strings.mustBeBetween, 0, 365)
    );
  };

  const isOnlyTwoFieldsFilled = (v: number | null) => {
    if (!v) {
      return true;
    }

    let cnt = 0;
    const waitingPeriods = getValues();

    if (waitingPeriods) {
      if (waitingPeriods.slaughter && waitingPeriods.slaughter !== "") {
        cnt += 1;
      }
      if (waitingPeriods.milk && waitingPeriods.milk !== "") {
        cnt += 1;
      }
      if (waitingPeriods.egg && waitingPeriods.egg !== "") {
        cnt += 1;
      }
      if (waitingPeriods.honey && waitingPeriods.honey !== "") {
        cnt += 1;
      }
    }

    return cnt < 3 || strings.onlyTwoFieldsAllowed;
  };

  return (
    <div className="tw-card">
      <div
        role="button"
        tabIndex={0}
        className="px-3 py-2 flex items-center"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyDown={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="font-semibold">{strings.waitingPeriods}</div>
        <div className="ml-auto">
          <button type="button">
            {isOpen ? (
              <MinusInverseIcon className="text-gray-500" />
            ) : (
              <PlusInverseIcon className="text-gray-500" />
            )}
          </button>
        </div>
      </div>
      {isOpen ? (
        <div className="grid grid-cols-12 gap-3 px-3 py-2">
          <div className="col-span-6 md:col-span-3">
            <Field
              autoComplete={AutoCompleteOptions.off}
              disableNotNumberCharacters
              error={errors.egg}
              fieldOptions={{
                validate: {
                  isInWaitingPeriodRange,
                  isOnlyTwoFieldsFilled,
                },
              }}
              label={`${prescriptionWaitingPeriodType.EGGS}`}
              name="egg"
              register={register}
              optional
              placeholder={`${prescriptionWaitingPeriodType.EGGS} (${strings.days})`}
              type="number"
            />
          </div>
          <div className="col-span-6 md:col-span-3">
            <Field
              autoComplete={AutoCompleteOptions.off}
              disableNotNumberCharacters
              error={errors.honey}
              fieldOptions={{
                validate: {
                  isInWaitingPeriodRange,
                  isOnlyTwoFieldsFilled,
                },
              }}
              label={`${prescriptionWaitingPeriodType.HONEY}`}
              name="honey"
              register={register}
              optional
              placeholder={`${prescriptionWaitingPeriodType.HONEY} (${strings.days})`}
              type="number"
            />
          </div>
          <div className="col-span-6 md:col-span-3">
            <Field
              autoComplete={AutoCompleteOptions.off}
              disableNotNumberCharacters
              error={errors.milk}
              fieldOptions={{
                validate: {
                  isInWaitingPeriodRange,
                  isOnlyTwoFieldsFilled,
                },
              }}
              label={`${prescriptionWaitingPeriodType.MILK}`}
              name="milk"
              register={register}
              optional
              placeholder={`${prescriptionWaitingPeriodType.MILK} (${strings.days})`}
              type="number"
            />
          </div>
          <div className="col-span-6 md:col-span-3">
            <Field
              autoComplete={AutoCompleteOptions.off}
              disableNotNumberCharacters
              error={errors.slaughter}
              fieldOptions={{
                validate: {
                  isInWaitingPeriodRange,
                  isOnlyTwoFieldsFilled,
                },
              }}
              label={`${prescriptionWaitingPeriodType.SLAUGHTER}`}
              name="slaughter"
              register={register}
              optional
              placeholder={`${prescriptionWaitingPeriodType.SLAUGHTER} (${strings.days})`}
              type="number"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default WaitingPeriodsBox;
