/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { MedicalRecordFileResponse } from "../../../models/medical/MedicalRecordFileResponse";
import MedicalRecordApi from "../../../api/MedicalRecordApi";
import { getGeneralError } from "../../../util/helperFunctions";
import LoaderInline from "../../../components/LoaderInline";
import Button from "../../../components/Button";
import { XMark } from "../../../common/Icons/XMark";
import FilePreviewModal from "./Preview/FilePreviewModal";
import { PaperClip } from "../../../common/Icons/PaperClip";

interface Props {
  canEdit: boolean;
  file: MedicalRecordFileResponse;
  resIdS?: string;
  setError(message: string | null): void;
}

const FileRow = ({ canEdit, file, resIdS, setError }: Props): ReactElement => {
  const [deleted, setDeleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFilePreviewModalOpen, setFilePreviewModalOpen] =
    useState<boolean>(false);

  const deleteFile = async (item: MedicalRecordFileResponse) => {
    if (resIdS) {
      setLoading(true);
      setError(null);

      try {
        await MedicalRecordApi.deleteFile(resIdS, item.fileId);
        setDeleted(true);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteButton = () => {
    if (loading) {
      return <LoaderInline className="ml-auto" />;
    }
    if (!canEdit) {
      return <></>;
    }
    return (
      <Button
        className="ml-auto"
        variant="basic"
        onClick={() => deleteFile(file)}
      >
        <XMark />
      </Button>
    );
  };

  return (
    <div hidden={deleted}>
      <div className="flex items-center tw-card rounded-full px-3 py-2">
        <Button
          className="text-gray-500"
          variant="basic"
          onClick={() => {
            setFilePreviewModalOpen(true);
          }}
        >
          <PaperClip />
        </Button>
        <Button
          variant="basic"
          className="min-w-0 mx-2"
          contentClassName="truncate"
          onClick={() => {
            setFilePreviewModalOpen(true);
          }}
        >
          {file.fileName}
        </Button>
        {deleteButton()}
      </div>
      <FilePreviewModal
        file={file}
        isOpen={isFilePreviewModalOpen}
        close={() => {
          setFilePreviewModalOpen(false);
        }}
        reservationId={resIdS}
      />
    </div>
  );
};

export default FileRow;
