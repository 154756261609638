/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../../../common/Strings/Strings";
import CloseButton from "../../../../../components/CloseButton";
import { EmployeeResponse } from "../../../../../models/user/EmployeeResponse";
import Modal from "../../../../../components/Modal/Modal";
import Button from "../../../../../components/Button";

interface Props {
  deleteRequest?: () => void;
  open: boolean;
  onHide: () => void;
  selectedEmployee?: EmployeeResponse;
}

export const DeleteRequestModal: React.FC<Props> = ({
  deleteRequest,
  onHide,
  open,
  selectedEmployee,
}) => (
  <Modal handleClose={onHide} show={open}>
    <Modal.Header title={strings.warning} />
    <Modal.Body>
      <p className="text-center p-3">
        {strings.formatString(
          strings.confirmEmployeeRequestDeletion,
          selectedEmployee?.fullName ||
            selectedEmployee?.request?.requestedEmail ||
            ""
        )}
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button
        className="modal-main-button"
        onClick={deleteRequest}
        variant="danger"
      >
        {strings.confirm}
      </Button>
      <CloseButton onClick={onHide} text={strings.cancel} />
    </Modal.Footer>
  </Modal>
);

export default DeleteRequestModal;
