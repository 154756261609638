/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const HematologyParams = [
  "WBC",
  "RBC",
  "HGB",
  "HCT",
  "MCV",
  "MCH",
  "MCHC",
  "RDW",
  "PLT",
  "MPV",
  "Lym #",
  "Mon #",
  "Gra #",
  "Eos #"
];

export const HematologyUnits = [
  "10^3/mm^3",
  "10^6/mm^3",
  "g/dl",
  "%",
  "µm3",
  "pg",
  "g/dl",
  "%",
  "10^3/mm^3",
  "µm3",
  "10^3/mm^3",
  "10^3/mm^3",
  "10^3/mm^3",
  "10^3/mm^3"
];

interface SpeciesRef {
  [key: string]: Array<{ start: number | null; end: number | null }>;
}

export const HematologyReferenceRanges: SpeciesRef = {
  DOG: [
    { start: 6, end: 12 },
    { start: 5.5, end: 8.5 },
    { start: 15, end: 20 },
    { start: 44, end: 57 },
    { start: 60, end: 70 },
    { start: 17, end: 26 },
    { start: 31, end: 38 },
    { start: 14, end: 17 },
    { start: 200, end: 460 },
    { start: 6.7, end: 11.1 },
    { start: 1, end: 3.6 },
    { start: 0, end: 0.5 },
    { start: 3, end: 10 },
    { start: 0, end: 0.6 }
  ],
  CAT: [
    { start: 5, end: 11 },
    { start: 5, end: 10 },
    { start: 8, end: 17 },
    { start: 27, end: 47 },
    { start: 40, end: 55 },
    { start: 13, end: 17 },
    { start: 31, end: 36 },
    { start: 17, end: 22 },
    { start: 180, end: 430 },
    { start: 6.5, end: 15 },
    { start: 1, end: 4 },
    { start: 0, end: 0.5 },
    { start: 3, end: 12 },
    { start: 0, end: 0.6 }
  ],
  HORSE: [
    { start: 5, end: 10 },
    { start: 5.5, end: 11 },
    { start: 9, end: 18 },
    { start: 30, end: 50 },
    { start: 37, end: 55 },
    { start: 13, end: 19 },
    { start: 31, end: 39 },
    { start: 14, end: 20 },
    { start: 100, end: 400 },
    { start: 6.7, end: 11.1 },
    { start: 1.5, end: 4 },
    { start: 0, end: 0.4 },
    { start: 3, end: 8 },
    { start: 0, end: 0.4 }
  ],
  RABBIT: [
    { start: 6.3, end: 10 },
    { start: 5.2, end: 6.8 },
    { start: 11.5, end: 15.1 },
    { start: 36, end: 47 },
    { start: 65, end: 76 },
    { start: 21.1, end: 24.5 },
    { start: 29.5, end: 33.9 },
    { start: null, end: null },
    { start: 250, end: 610 },
    { start: null, end: null },
    { start: null, end: null },
    { start: null, end: null },
    { start: null, end: null },
    { start: null, end: null }
  ]
};

export default {
  HematologyParams,
  HematologyUnits,
  ReferenceRanges: HematologyReferenceRanges
};
