/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import App from "./App";
import { initializeNetwork } from "./util/axiosSetup";
import Params from "./common/Params";
import logger from "./util/logger";
import reportWebVitals from "./reportWebVitals";
import { setupDarkMode } from "./util/DarkMode";
import Analytics from "./util/Analytics";
import setupProcess from "./pages/VideoConsultation/setupProcess";

// Set up the process that is needed by the simple-peer library in the newer React versions
setupProcess();

initializeNetwork();

try {
  Sentry.init({ dsn: Params.sentryDSN });
} catch (e) {
  logger.error(e);
}

try {
  Analytics.init();
} catch (e) {
  logger.error(e);
}

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

// Setup dark mode here initially to avoid FOUC (Flash of unstyled content)
setupDarkMode();

reportWebVitals();
