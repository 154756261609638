/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useUser } from "../../../../../../contexts/UserContext";
import HasCode from "./HasCode";
import SetNewCode from "./SetNewCode";
import ConfirmNewCode from "./ConfirmNewCode";

export enum PinState {
  HasCode,
  NewCode,
  ConfirmCode,
}

interface Props {
  asCard?: boolean;
  canCancel?: boolean;
}

const PinCodeSettings: React.FC<Props> = ({
  asCard = true,
  canCancel = true,
}: Props) => {
  const { user } = useUser();

  const [pinState, setPinState] = useState<PinState>(PinState.HasCode);

  useEffect(() => {
    if (user.hasConfirmedPinCode) {
      setPinState(PinState.HasCode);
    } else if (user.hasUnconfirmedPinCode) {
      setPinState(PinState.ConfirmCode);
    } else {
      setPinState(PinState.NewCode);
    }
  }, [user]);

  switch (pinState) {
    /* When the user has a pin code. */
    case PinState.HasCode:
      return <HasCode asCard={asCard} setPinState={setPinState} />;
    /* When the user wants to add a new pin code. */
    case PinState.NewCode:
      return (
        <SetNewCode
          asCard={asCard}
          canCancel={canCancel}
          setPinState={setPinState}
        />
      );
    /* When the user has to confirm the new pin code. */
    default:
      return <ConfirmNewCode asCard={asCard} setPinState={setPinState} />;
  }
};

export default PinCodeSettings;
