/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import StatisticsApi from "../../../api/StatisticsApi";
import { ClinicStatisticsResponse } from "../../../models/statistics/ClinicStatisticsResponse";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import { Option } from "../../../models/Option";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import MonthSelectOfStats from "./MonthSelectOfStats";
import { Card } from "../../../components/Cards/Card";

interface Props {
  clinic?: ClinicResponse;
}

const UsageStatisticsTab: React.FC<Props> = ({ clinic }: Props) => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [months, setMonths] = useState<Option[]>([]);
  const [statistics, setStatistics] =
    useState<Map<string, ClinicStatisticsResponse>>();
  const [currentMonth, setCurrentMonth] = useState<string | number>();
  const [currentStatistics, setCurrentStatistics] =
    useState<ClinicStatisticsResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!clinic) return;

    const getStatistics = async () => {
      setLoading(true);

      try {
        const response = await StatisticsApi.getStatisticsOfClinic(clinic.id);
        const monthList: Option[] = [];
        const indexByMonth: Map<string, ClinicStatisticsResponse> = new Map();

        response.data.forEach((item: ClinicStatisticsResponse) => {
          monthList.push({
            value: item.month,
            title: moment(item.month, undefined, strings.getLanguage()).format(
              "YYYY MMMM"
            ),
          });
          indexByMonth.set(item.month, item);
        });
        setStatistics(indexByMonth);
        setMonths(monthList);
        setCurrentMonth(monthList[0]?.value);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void getStatistics();
  }, [clinic]);

  useEffect(() => {
    if (!currentMonth) return;
    setCurrentStatistics(statistics?.get(currentMonth.toString()));
  }, [currentMonth, statistics]);

  return (
    <Card
      actionText={strings.clinics}
      loading={loading}
      onAction={() => navigate("/globalvet/clinics")}
      size="md"
      title={strings.clinicStatistics}
      type="simple"
    >
      <AlertBox
        type={AlertType.INFO}
        hidden={months.length > 0 || error !== null || loading}
        message={error || strings.statisticsErrorMsg}
        closeAble={false}
      />
      <AlertBox message={error} />
      <div hidden={months.length <= 0 || error != null}>
        <div className="flex">
          <MonthSelectOfStats
            months={months}
            setCurrentMonth={setCurrentMonth}
          />
        </div>
      </div>
      <div hidden={months.length === 0}>
        <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
          {currentStatistics?.statistics?.map((stat) => (
            <div className="p-4 flex justify-between items-center space-x-4">
              <div>{getStringFromEnumsOrReturn(stat.name)}</div>
              <div className="ml-3">{stat.value}</div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default UsageStatisticsTab;
