/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TreatmentResponse } from "../../../../../models/treatment/TreatmentResponse";
import { strings } from "../../../../../common/Strings/Strings";
import InventoryItemSection from "../../../../Treatments/CreateAndEditTreatment/InventoryItemSection";
import NumberFormatter from "../../../../../util/NumberFormatter";
import TreatmentCostCalculator from "../../../../../util/TreatmentCostCalculator";
import FeeSection from "../../../../Treatments/CreateAndEditTreatment/FeeSection";
import CheckBox from "../../../../../components/ReactHookFormFields/General/CheckBox";
import CostCalculator from "../../../../../util/CostCalculator";
import Modal from "../../../../../components/Modal/Modal";
import CloseButton from "../../../../../components/CloseButton";
import Button from "../../../../../components/Button";
import { CountryDetailsResponse } from "../../../../../models/management/CountryDetailsResponse";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";

export interface AddTreatmentForm {
  items: boolean;
  fees: boolean;
}

interface Props {
  close(): void;
  clinic?: ClinicResponse;
  countryDetails?: CountryDetailsResponse;
  treatment?: TreatmentResponse;
  response(values: AddTreatmentForm): void;
}

const AddTreatmentWithItemsModal = ({
  close,
  clinic,
  countryDetails,
  treatment,
  response,
}: Props): ReactElement => {
  const { register, handleSubmit } = useForm<AddTreatmentForm>({
    defaultValues: { items: true, fees: true },
  });
  const [expandItems, setExpandItems] = useState<boolean>(false);
  const [expandFees, setExpandFees] = useState<boolean>(false);

  useEffect(() => {
    setExpandFees(false);
    setExpandItems(false);
  }, [treatment]);

  return (
    <Modal handleClose={close} show={!!treatment} size="modal-lg">
      <Modal.Header title={`${strings.addTreatment} (${treatment?.name})`} />
      <Modal.Body>
        <form>
          {treatment ? (
            <div className="d-flex block-label-simple align-items-center py-3">
              <div className="">
                {strings.basePrice} ({strings.gross})
              </div>
              <div className="ml-auto">
                {NumberFormatter.formatPrice(
                  CostCalculator.getGrossPriceOfItem(
                    {
                      price: treatment.price || 0,
                      vat: treatment.vat || 0,
                      quantity: 1,
                    },
                    countryDetails,
                    clinic
                  ),
                  treatment.currency
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

          {treatment?.usedItems && treatment.usedItems.length > 0 ? (
            <div className="py-3 hr-on-top">
              <div className="d-flex block-label-simple align-items-center pb-3">
                <Button
                  onClick={() => {
                    setExpandItems(!expandItems);
                  }}
                  variant="basic"
                >
                  <div className="">
                    {expandItems ? (
                      <span className="material-icons">expand_more</span>
                    ) : (
                      <span className="material-icons">expand_less</span>
                    )}
                  </div>
                </Button>
                {strings.priceOfUsedItems} ({strings.gross})
                <div className="ml-auto">
                  {NumberFormatter.formatPrice(
                    TreatmentCostCalculator.getGrossPriceOfItems2(
                      treatment.usedItems,
                      countryDetails,
                      clinic
                    ),
                    treatment.currency
                  )}
                </div>
              </div>
              <div hidden={!expandItems}>
                <InventoryItemSection
                  countryDetails={countryDetails}
                  usedItems={treatment.usedItems}
                />
              </div>
              <CheckBox
                label={strings.addAllItemsToo}
                name="items"
                register={register}
              />
            </div>
          ) : (
            <></>
          )}
          {treatment?.fees && treatment.fees.length > 0 ? (
            <div className="py-3 hr-on-top">
              <div className="d-flex block-label-simple align-items-center pb-3">
                <Button
                  onClick={() => {
                    setExpandFees(!expandFees);
                  }}
                  variant="basic"
                >
                  <div className="">
                    {expandFees ? (
                      <span className="material-icons">expand_more</span>
                    ) : (
                      <span className="material-icons">expand_less</span>
                    )}
                  </div>
                </Button>
                {strings.priceOfAppliedFees} ({strings.gross})
                <div className="ml-auto">
                  {NumberFormatter.formatPrice(
                    TreatmentCostCalculator.getGrossPriceOfFees2(
                      treatment.fees,
                      countryDetails,
                      clinic
                    ),
                    treatment.currency
                  )}
                </div>
              </div>
              <div hidden={!expandFees}>
                <FeeSection
                  countryDetails={countryDetails}
                  fees={treatment.fees}
                />
              </div>
              <CheckBox
                label={strings.addAllFeesToo}
                name="fees"
                register={register}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="hr-on-top d-flex py-3">
            <div className="block-label-simple ">
              {strings.totalAmount} ({strings.gross})
            </div>
            <div className="block-label-simple ml-auto">
              {treatment &&
                NumberFormatter.formatPrice(
                  TreatmentCostCalculator.getGrossTotalPrice(
                    treatment,
                    countryDetails,
                    clinic
                  ),
                  treatment.currency
                )}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-main-button" onClick={handleSubmit(response)}>
          {strings.add}
        </Button>
        <CloseButton onClick={close} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default AddTreatmentWithItemsModal;
