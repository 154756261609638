/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useUser } from "../../../contexts/UserContext";
import PhoneSetup from "./ProfileSetupSteps/PhoneSetup";
import AddressSetup from "./ProfileSetupSteps/AddressSetup";
import NotificationsSetup from "./ProfileSetupSteps/NotificationsSetup";
import ProfileSetup from "./ProfileSetupSteps/ProfileSetup";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../../util/LocalStorageVariables";

type ProfileSetupStepName = "NOTIFICATIONS" | "PHONE" | "PROFILE" | "ADDRESS";

type ProfileSetupStep = {
  autoSkipCondition: boolean;
  name: ProfileSetupStepName;
  skippable: boolean;
};

const UserProfileSetup: React.FC = () => {
  const role: UserRole | null = getValueOfActiveUser("role");

  const { user, fetchUser } = useUser();
  const navigate = useNavigate();

  const [step, setStep] = useState<ProfileSetupStepName>();
  const [remainingSteps, setRemainingSteps] = useState<ProfileSetupStepName[]>(
    []
  );

  // Use local storage to remember already manually skipped steps after page refresh
  const [manuallySkippedSteps, setManuallySkippedSteps] = useLocalStorage<
    ProfileSetupStepName[]
  >("profileSetupManuallySkippedSteps", []);

  /* The profile setup steps in order
   * Setting the autoSkipCondition to false disables automatic skip
   * Changing the skippable property shows/hides the manual skip option
   * Note 1: The backend does not allow most functions with unfinished registration
   * Note 2: Phone and Address setup is required for finished registration currently */
  const ProfileSetupSteps = useMemo<ProfileSetupStep[]>(
    () => [
      {
        name: "NOTIFICATIONS",
        autoSkipCondition: user?.receiveSMSNotifications !== null,
        skippable: true,
      },
      {
        name: "PHONE",
        autoSkipCondition: !!user?.confirmedMobilePhone,
        skippable: false,
      },
      {
        name: "ADDRESS",
        autoSkipCondition: !!user?.primaryAddress,
        skippable: false,
      },
      {
        name: "PROFILE",
        autoSkipCondition:
          !!user.details?.firstName &&
          !!user.details?.lastName &&
          !!user.details?.dateOfBirth,
        skippable: false,
      },
    ],
    [user]
  );

  const getSkippableByName = (stepName: string): boolean | undefined => {
    const st = ProfileSetupSteps.find(
      (s: ProfileSetupStep) => s.name === stepName
    );
    return st ? st.skippable : undefined;
  };

  useEffect(() => {
    const autoSkipSteps = () => {
      const states: ProfileSetupStepName[] = [];
      ProfileSetupSteps.forEach((s: ProfileSetupStep) => {
        if (!s.autoSkipCondition && !manuallySkippedSteps.includes(s.name)) {
          states.push(s.name);
        }
      });
      setRemainingSteps(states);
    };

    autoSkipSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProfileSetupSteps]);

  const isInitialMount = useRef(true);

  // This doesn't run on initial mount
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (remainingSteps.length === 0) {
      // Finish profile setup
      fetchUser().then(() => navigate("/", { replace: true }));
    } else {
      setStep(remainingSteps[0]);
    }
  }, [fetchUser, navigate, remainingSteps]);

  const nextStep = (skip: boolean) => {
    if (skip && step && !manuallySkippedSteps.includes(step)) {
      setManuallySkippedSteps([...manuallySkippedSteps, step]);
    }
    setRemainingSteps([...remainingSteps.filter((s) => s !== step)]);
  };

  // An existing active role means that the user already finished the profile setup
  if (role) {
    return <Navigate to="/" />;
  }

  switch (step) {
    case "NOTIFICATIONS":
      return (
        <NotificationsSetup
          onNextStep={nextStep}
          skippable={getSkippableByName("NOTIFICATIONS")}
        />
      );
    case "PHONE":
      return (
        <PhoneSetup
          onNextStep={nextStep}
          skippable={getSkippableByName("PHONE")}
        />
      );
    case "PROFILE":
      return (
        <ProfileSetup
          onNextStep={nextStep}
          skippable={getSkippableByName("PROFILE")}
        />
      );
    case "ADDRESS":
      return (
        <AddressSetup
          onNextStep={nextStep}
          skippable={getSkippableByName("ADDRESS")}
        />
      );
    default:
      return <></>;
  }
};

export default UserProfileSetup;
