/*
 * Copyright © GlobalVET AB, 2019.
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  dateAndTime,
  getStringFromEnumsOrReturn,
  strings,
} from "../../common/Strings/Strings";
import { InvoiceResponse } from "../../models/invoice/InvoiceResponse";
import { getGeneralError } from "../../util/helperFunctions";
import { InvoiceStatus } from "../../models/invoice/InvoiceStatus";
import InvoiceApi from "../../api/InvoiceApi";
import AlertBox from "../../components/AlertBox";
import { downloadInvoicePdf } from "../../util/FileUtils";

interface Props {
  isClinicPage: boolean;
  close(): void;
  open: boolean;
  prescriptionId: string;
}

const PrescriptionBillModal: React.FC<Props> = ({
  isClinicPage,
  open,
  close,
  prescriptionId,
}: Props) => {
  const [invoice, setInvoice] = useState<InvoiceResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const getInvoice = async () => {
      try {
        const response = await InvoiceApi.getInvoiceOfPrescription(
          prescriptionId
        );
        if (isMounted) {
          setInvoice(response.data);
        }
      } catch (err) {
        if (isMounted) {
          setError(await getGeneralError(err));
        }
      }
    };

    setError(null);
    setInvoice(null);

    void getInvoice();

    return () => {
      isMounted = false;
    };
  }, [prescriptionId]);

  return (
    <Modal show={open} animation={false} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{strings.invoice}</Modal.Title>
      </Modal.Header>
      {invoice !== null ? (
        <Modal.Body bsPrefix="custom-modal-body">
          <div className="rounded-lg bg-sky-50 dark:bg-gray-900">
            <div className="d-flex">
              <div className="p-3">
                <div className="paragraph-small">
                  <b>{strings.invoice}:</b> {`Invoice-${invoice.id}`}
                </div>
                <div className="paragraph-small">
                  <b>{strings.date}:</b>{" "}
                  {moment(invoice.creationDate).format(
                    dateAndTime.momentDateTimeFormat
                  )}
                </div>
                <div className="paragraph-small">
                  <b>{strings.clinic}:</b> {invoice.clinicName}
                </div>
                <div className="paragraph-small">
                  <b>{strings.clinic}:</b> {invoice.clinicName}
                </div>
                <div className="paragraph-small">
                  <b>{strings.invoiceState}:</b>{" "}
                  {getStringFromEnumsOrReturn(invoice.status)}
                </div>
                <div className="paragraph-small">
                  <b>{strings.amount}:</b> {invoice.totalCost} SEK
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      ) : null}
      {error !== null ? (
        <Modal.Body bsPrefix="custom-modal-body">
          <AlertBox message={error} className="m-0" />
        </Modal.Body>
      ) : null}
      <Modal.Footer>
        <Button variant="secondary mr-auto" onClick={close}>
          {strings.close}
        </Button>
        {invoice && (
          <>
            <Button
              variant="primary"
              hidden={invoice.status === InvoiceStatus.IN_PROGRESS}
              onClick={() => {
                downloadInvoicePdf(invoice);
              }}
            >
              <i className="far fa-file-pdf" />
            </Button>
            <Link
              className="btn btn-primary"
              hidden={
                invoice.status !== InvoiceStatus.IN_PROGRESS || !isClinicPage
              }
              to={`/invoices/edit/${invoice.id}/finish`}
            >
              {strings.goToInvoices}
            </Link>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default PrescriptionBillModal;
