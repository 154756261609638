/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import IntervalComponent from "./Interval";
import sendingRequestProcesser from "../sections/finish/sendingRequestProcesser";
import { prescriptionRegexp } from "../../../util/Validations";
import hardcodedStrings from "../fixValues/hardcodedStrings";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Field from "../../../components/ReactHookFormFields/General/Field";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import Button from "../../../components/Button";

interface Props {
  defaultUnit?: string;
  isCommodity?: boolean /* Not required for commodity products */;
  isVaraLicensed?: boolean;
  productType?: string;
}

const Instructions: React.FC<Props> = ({
  defaultUnit,
  isCommodity,
  isVaraLicensed,
  productType,
}: Props) => {
  const { control, register, getValues, formState, watch } = useFormContext();
  const instructionLengthLimit = 254;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "intervals",
  });

  return (
    <div className="space-y-3">
      <div>{strings.dosageInfo}</div>
      {fields.map((item, index) => (
        <Fragment key={item.id}>
          <IntervalComponent
            deleteInterval={() => {
              remove(index);
            }}
            isCommodity={isCommodity}
            nestIndex={index}
          />
        </Fragment>
      ))}
      <Button
        className="tw-link-tercier "
        variant="link"
        onClick={() => {
          append({
            dose: undefined,
            unit:
              getValues(`intervals.${fields.length - 1}.unit`) ||
              defaultUnit?.toLowerCase(),
            period: undefined,
            days: undefined,
            method: getValues(`intervals.${fields.length - 1}.method`) || [],
          });
        }}
      >
        <div className="flex">
          <PlusIcon />
          <span className="ml-1">{strings.addNewInterval}</span>
        </div>
      </Button>
      <div
        hidden={
          !(
            isVaraLicensed &&
            productType === hardcodedStrings.licenseProductType
          )
        }
      >
        <Field
          name="totalAmount"
          register={register}
          autoComplete={AutoCompleteOptions.off}
          error={formState.errors.totalAmount}
          fieldOptions={{
            pattern: {
              value: prescriptionRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.totalAmount}
          placeholder={strings.totalAmount}
          required={
            isVaraLicensed &&
            productType === hardcodedStrings.licenseProductType
          }
        />
      </div>
      <Textarea
        className="mb-0"
        name="freeText"
        register={register}
        allowMoreCharactersToInput
        autoComplete={AutoCompleteOptions.off}
        error={formState.errors.freeText}
        fieldOptions={{
          pattern: {
            value: prescriptionRegexp,
            message: strings.notAllowedCharacter,
          },
          validate: {
            isInstructionFilled: () =>
              !isCommodity
                ? sendingRequestProcesser.generateTextFromIntervals(getValues())
                    .length > 0
                : true,
          },
          deps: ["intervals"],
        }}
        label={strings.instructions}
        maxLength={
          instructionLengthLimit -
          sendingRequestProcesser.generateTextFromIntervals({
            intervals: getValues().intervals,
            totalAmount: getValues().totalAmount,
          }).length -
          (sendingRequestProcesser.generateTextFromIntervals({
            intervals: getValues().intervals,
            totalAmount: getValues().totalAmount,
          }).length > 0
            ? 1
            : 0)
        }
        minRows={2}
        placeholder={strings.instructions}
        showCharacterCount
      />
      <p>
        {strings.preview}:{" "}
        <b className="ml-1" style={{ wordBreak: "break-word" }}>
          {sendingRequestProcesser.generateTextFromIntervals(watch())}
        </b>
      </p>
    </div>
  );
};

export default Instructions;
