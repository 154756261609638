/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { CustomFlowbiteTheme } from "flowbite-react";

const showBackground = false;
const showButtonHover = false;

export const dropdownTheme: CustomFlowbiteTheme = {
  dropdown: {
    arrowIcon: "hidden ml-2 h-4 w-4",
    content: "py-2 text-md text-gray-700 dark:text-gray-400 focus:outline-none",
    floating: {
      animation: "transition-opacity",
      arrow: {
        base: "absolute z-10 h-2 w-2 rotate-45",
        style: {
          dark: "bg-gray-900 dark:bg-gray-700",
          light: "bg-white",
          auto: "bg-white dark:bg-gray-700",
        },
        placement: "-4px",
      },
      base: "dropdownNavbarUser z-30 shadow-md rounded-lg w-screen lg:w-96 font-normal bg-white dark:bg-gray-700 divide-y divide-gray-100 dark:divide-gray-600 block",
      content: "py-1 text-sm text-gray-700 dark:text-gray-200",
      divider: "my-1 h-px bg-gray-100 dark:bg-gray-600",
      header: "block py-2 px-4 text-sm text-gray-700 dark:text-gray-200",
      hidden: "invisible opacity-0",
      item: {
        container:
          "hover:bg-gray-50 hover:text-gray-800 dark:hover:bg-gray-600 dark:hover:text-gray-300",
        base: "w-full flex items-center",
        icon: "mr-2 h-4 w-4",
      },
      style: {
        dark: "bg-gray-900 text-white dark:bg-gray-700",
        light: "border border-gray-200 bg-white text-gray-900",
        auto: "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white",
      },
      target: "w-fit",
    },
    inlineWrapper: "flex items-center",
  },
  button: {
    base: "group flex items-stretch items-center justify-center p-0.5 text-center font-medium relative focus:z-10 focus:outline-none",
    fullSized: "w-full",
    color: {
      light: `text-white bg-slate-500 ${
        !showBackground && "bg-opacity-0 enabled:hover:bg-opacity-0"
      } border border-transparent ${
        showButtonHover
          ? "enabled:hover:bg-slate-600"
          : "enabled:hover:bg-slate-500"
      } focus:ring-4 focus:ring-slate-500`,
      dark: `text-white bg-gray-800 ${
        !showBackground && "bg-opacity-0 enabled:hover:bg-opacity-0"
      } border border-transparent ${
        showButtonHover
          ? "enabled:hover:bg-gray-700"
          : "enabled:hover:bg-gray-800"
      } focus:ring-4 focus:ring-gray-800`,
    },
    disabled: "cursor-not-allowed opacity-50",
    isProcessing: "cursor-wait",
    spinnerSlot: "absolute h-full top-0 flex items-center animate-fade-in",
    inner: {
      base: "flex items-stretch items-center transition-all duration-200",
      position: {
        none: "",
        start: "rounded-r-none",
        middle: "rounded-none",
        end: "rounded-l-none",
      },
      outline: "border border-transparent",
      isProcessingPadding: {
        xs: "pl-8",
        sm: "pl-10",
        md: "pl-12",
        lg: "pl-16",
        xl: "pl-20",
      },
    },
    outline: {
      color: {
        gray: "border border-gray-900 dark:border-white",
        default: "border-0",
        light: "",
      },
      off: "",
      on: "flex justify-center bg-white text-gray-900 transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-inherit dark:bg-gray-900 dark:text-white w-full",
    },
    size: {
      md: "text-sm py-0",
      lg: "text-sm py-2",
    },
  },
};
