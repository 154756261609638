/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import CloseButton from "../../../../components/CloseButton";
import { MedicalRecordFileResponse } from "../../../../models/medical/MedicalRecordFileResponse";
import { strings } from "../../../../common/Strings/Strings";
import Modal from "../../../../components/Modal/Modal";
import Button from "../../../../components/Button";
import { downloadMedicalRecordFile } from "../../../../util/FileUtils";
import { ArrowDownTray } from "../../../../common/Icons/ArrowDownTray";
import FilePreview from "./FilePreview";

interface Props {
  close(): void;
  file: MedicalRecordFileResponse;
  isOpen: boolean;
  reservationId?: string;
}

const FilePreviewModal: React.FC<Props> = ({
  close,
  file,
  isOpen,
  reservationId,
}: Props) => (
  <Modal handleClose={close} show={isOpen} size="modal-lg">
    <Modal.Header title={strings.document} />
    <Modal.Body>
      <FilePreview file={file} reservationId={reservationId} size="full" />
      <p className="break-all">{file.fileName}</p>
    </Modal.Body>
    <Modal.Footer>
      <CloseButton onClick={close} />
      <Button
        minW
        onClick={() => {
          if (reservationId) {
            void downloadMedicalRecordFile(reservationId, file);
          }
        }}
      >
        <ArrowDownTray />
        <span className="pl-2">{strings.download}</span>
      </Button>
    </Modal.Footer>
  </Modal>
);

export default FilePreviewModal;
