/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { strings } from "../../../../common/Strings/Strings";
import { AnimalCarer } from "../../../../models/ePrescription/sending/AnimalCarer";
import { AnimalCarerTypeEnum } from "../../../../models/ePrescription/local/AnimalCarerTypeEnum";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import { useClinic } from "../../../../contexts/ClinicContext";
import { defaultCountryCodeOfClinic } from "../../../../common/DefaultCountry";
import PersonalIdOwnerForm, { PersonalIdForm } from "./PersonalIdOwnerForm";
import OrganizationIdOwnerForm, {
  OrganizationIdFormProps,
} from "./OrganizationIdOwnerForm";
import BirthDateOwnerForm, { BirthDateFormProps } from "./BirthDateOwnerForm";
import { animalCarerBirthDateFormat } from "../../fixValues/hardcodedStrings";
import StatusSignal from "../../StatusSignal";
import Button from "../../../../components/Button";
import PetOwnerInfo from "../../../Todo/PetOwnerInfo";
import { PetOwnerResponse } from "../../../../models/pet/PetOwnerResponse";
import { Pencil } from "../../../../common/Icons/Pencil";
import { isSwedishPersonalIdValid } from "../../../../util/Validations";
import isSwedishOrganizationNumberValid from "../../../../util/RegistrationNumberValidator";
import RadioButton from "../../../../components/ReactHookFormFields/General/RadioButton/RadioButton";
import { RadioButtonOption } from "../../../../common/Icons/RadioButtonOption";

interface Props {
  defaultInputType: AnimalCarerTypeEnum;
  isPhoneRequired: boolean /* unused, but leave it here */;
  isPharmacySelected: boolean;
  isValid(isValid: boolean): void;
  petOwner?: PetOwnerResponse;
  refresh: boolean;
  setAnimalCarer(a: AnimalCarer | undefined): void;
  setAnimalCarerType(t: AnimalCarerTypeEnum): void;
}

const OwnerSelection: React.FC<Props> = ({
  defaultInputType,
  isPhoneRequired,
  isPharmacySelected,
  isValid,
  petOwner,
  refresh,
  setAnimalCarer,
  setAnimalCarerType,
}: Props) => {
  const { clinic } = useClinic();

  const [selectedAnimalCarerType, setSelectedAnimalCarerType] =
    useState<AnimalCarerTypeEnum>(defaultInputType);
  const [editAnimalOwner, setEditAnimalOwner] = useState<boolean>(false);

  const personalIdOwnerMethods = useForm<PersonalIdForm>({ mode: "onChange" });
  const {
    formState: { isValid: animalCarerPersonalIdValid },
    getValues: getPersonalIdOwner,
  } = personalIdOwnerMethods;

  const orgIdOwnerMethods = useForm<OrganizationIdFormProps>({
    mode: "onChange",
  });
  const {
    formState: { isValid: animalCarerOrganizationIdValid },
    getValues: getOrgOwner,
  } = orgIdOwnerMethods;

  const birthDateOwnerMethods = useForm<BirthDateFormProps>({
    mode: "onChange",
  });
  const {
    formState: { isValid: animalCarerBirthDateValid },
    getValues: getBirthDateOwner,
  } = birthDateOwnerMethods;

  useEffect(() => {
    if (petOwner && animalCarerPersonalIdValid) {
      setEditAnimalOwner(false);
    } else {
      setEditAnimalOwner(true);
    }
  }, [petOwner, animalCarerPersonalIdValid]);

  useEffect(() => {
    switch (selectedAnimalCarerType) {
      case AnimalCarerTypeEnum.PERSONALID: {
        if (animalCarerPersonalIdValid) {
          const {
            givenName,
            familyName,
            idNumber,
            phoneNumber,
            city,
            zip,
            address,
          } = getPersonalIdOwner();
          setAnimalCarer({
            givenName,
            familyName,
            personalNumber: idNumber,
            postalCode: zip,
            streetAddress: address,
            city,
            phoneNumber,
          });
        }
        isValid(animalCarerPersonalIdValid);
        break;
      }
      case AnimalCarerTypeEnum.ORGANIZATION: {
        if (animalCarerOrganizationIdValid) {
          const {
            cityOrg,
            phoneNumberOrg,
            zipOrg,
            addressOrg,
            organizationName,
            organizationId,
          } = getOrgOwner();
          setAnimalCarer({
            organizationName,
            organizationId,
            postalCode: zipOrg,
            streetAddress: addressOrg,
            city: cityOrg,
            phoneNumber: phoneNumberOrg,
          });
        }
        isValid(animalCarerOrganizationIdValid);
        break;
      }
      case AnimalCarerTypeEnum.DATEOFBIRTH: {
        if (animalCarerBirthDateValid) {
          const {
            givenNameForeign,
            familyNameForeign,
            cityForeign,
            addressForeign,
            zipForeign,
            phoneNumberForeign,
            birthDateForeign,
          } = getBirthDateOwner();
          setAnimalCarer({
            givenName: givenNameForeign,
            familyName: familyNameForeign,
            birthDate: moment(birthDateForeign).format(
              animalCarerBirthDateFormat
            ),
            postalCode: zipForeign,
            streetAddress: addressForeign,
            city: cityForeign,
            phoneNumber: phoneNumberForeign,
          });
        }
        isValid(animalCarerBirthDateValid && isPharmacySelected);
        break;
      }
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    animalCarerPersonalIdValid,
    animalCarerOrganizationIdValid,
    animalCarerBirthDateValid,
    selectedAnimalCarerType,
    isPharmacySelected,
    refresh,
  ]);

  const animalCarerTypeChange = (value: AnimalCarerTypeEnum) => {
    setSelectedAnimalCarerType(value);
    setAnimalCarerType(value);
  };

  const defaultCountryCode = defaultCountryCodeOfClinic(clinic);

  return (
    <div className="tw-card space-y-4 p-4">
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
        {strings.owner}
      </h2>
      {!editAnimalOwner && petOwner ? (
        <div className="flex">
          <PetOwnerInfo petOwner={petOwner} />
          <Button
            variant="icon"
            onClick={() => {
              setEditAnimalOwner(true);
            }}
          >
            <Pencil />
          </Button>
        </div>
      ) : (
        <div className="divide-y dark:divide-gray-700">
          <div className="space-y-2 pb-1">
            <div>
              <Button
                onClick={() => {
                  animalCarerTypeChange(AnimalCarerTypeEnum.PERSONALID);
                }}
                variant="basic"
              >
                {selectedAnimalCarerType === AnimalCarerTypeEnum.PERSONALID ? (
                  <RadioButtonOption className="text-sky-500" checked />
                ) : (
                  <RadioButtonOption
                    className="text-gray-500"
                    checked={false}
                  />
                )}
                <div className="ml-2">{strings.idNumber}</div>
              </Button>
            </div>
            <div
              className="pb-2"
              hidden={
                selectedAnimalCarerType !== AnimalCarerTypeEnum.PERSONALID
              }
            >
              <FormProvider {...personalIdOwnerMethods}>
                <PersonalIdOwnerForm
                  defaultCountryCode={defaultCountryCode}
                  petOwner={petOwner?.petOwnerDetails}
                />
              </FormProvider>
            </div>
          </div>
          <div className="space-y-2 py-1">
            <div>
              <Button
                onClick={() => {
                  animalCarerTypeChange(AnimalCarerTypeEnum.ORGANIZATION);
                }}
                variant="basic"
              >
                {selectedAnimalCarerType ===
                AnimalCarerTypeEnum.ORGANIZATION ? (
                  <RadioButtonOption className="text-sky-500" checked />
                ) : (
                  <RadioButtonOption
                    className="text-gray-500"
                    checked={false}
                  />
                )}
                <div className="ml-2">{strings.organisationId}</div>
              </Button>
            </div>
            <div
              className="pb-2"
              hidden={
                selectedAnimalCarerType !== AnimalCarerTypeEnum.ORGANIZATION
              }
            >
              <FormProvider {...orgIdOwnerMethods}>
                <OrganizationIdOwnerForm
                  defaultCountryCode={defaultCountryCode}
                />
              </FormProvider>
            </div>
          </div>
          <div className="space-y-2 pt-1">
            <div>
              <Button
                onClick={() => {
                  animalCarerTypeChange(AnimalCarerTypeEnum.DATEOFBIRTH);
                }}
                variant="basic"
              >
                {selectedAnimalCarerType === AnimalCarerTypeEnum.DATEOFBIRTH ? (
                  <RadioButtonOption className="text-sky-500" checked />
                ) : (
                  <RadioButtonOption
                    className="text-gray-500"
                    checked={false}
                  />
                )}
                <div className="ml-2">{strings.dateOfBirth}</div>
              </Button>
            </div>
            <div
              className="space-y-2"
              hidden={
                selectedAnimalCarerType !== AnimalCarerTypeEnum.DATEOFBIRTH
              }
            >
              {!isPharmacySelected ? (
                <AlertBox
                  type={AlertType.INFO}
                  message={strings.chooseASpecificPharmacy}
                />
              ) : (
                <></>
              )}
              <FormProvider {...birthDateOwnerMethods}>
                <BirthDateOwnerForm
                  defaultCountryCode={defaultCountryCode}
                  petOwner={petOwner?.petOwnerDetails}
                />
              </FormProvider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OwnerSelection;

OwnerSelection.defaultProps = {
  petOwner: undefined,
};
