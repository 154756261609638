/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AxiosError, AxiosResponse } from "axios";
import {
  petGenders,
  petSpecies,
  strings,
} from "../../../common/Strings/Strings";
import { useDebouncedState } from "../../../hooks/hooks";
import { useClinic } from "../../../contexts/ClinicContext";
import { HighlightedText } from "../../../util/StringUtils";
import LoaderInline from "../../../components/LoaderInline";
import ClinicPetApi from "../../../api/ClinicPetApi";
import { ClinicPetAndOwnerResponse } from "../../../models/pet/ClinicPetAndOwnerResponse";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import EmptyListText from "../../../components/EmptyListText";
import { Colors } from "../../../models/Colors";
import ClickOutsideDetector from "../../../components/ClickOutsideDetector";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { MagnifyingGlass } from "../../../common/Icons/MagnifyingGlass";
import { Heart } from "../../../common/Icons/Heart";
import { Link } from "react-router-dom";

interface Props {
  onClickNewPetOwner?: () => void;
  onSelectPet: (petAndOwner: ClinicPetAndOwnerResponse) => void;
  onClickNewPet?: (petOwner: PetOwnerResponse) => void;
  onError?: (err: AxiosError) => void;
}

const PetSearchSection: React.FC<Props> = ({
  onClickNewPetOwner,
  onSelectPet,
  onClickNewPet,
  onError,
}: Props) => {
  const { clinic } = useClinic();
  const [isDown, setIsDown] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<ClinicPetAndOwnerResponse[]>(
    []
  );
  const { value: searchText, setValue: setSearchText } =
    useDebouncedState<string>("", 400);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);

  const searchChange = ({ target: { value } }: any) => {
    setSearchText(value);
  };

  useEffect(() => {
    let isMounted = true;
    if (searchText.length >= 3 && clinic?.id) {
      setIsDown(true);
      setIsSearchLoading(true);

      ClinicPetApi.searchClinicPet(clinic.id, searchText)
        .then((response: AxiosResponse<ClinicPetAndOwnerResponse[]>) => {
          if (isMounted) {
            setSearchResult(response.data);
          }
        })
        .catch((err: AxiosError) => {
          if (onError) {
            onError(err);
          }
        })
        .finally(() => {
          setIsSearchLoading(false);
        });
    } else if (isMounted) {
      setSearchResult([]);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <ClickOutsideDetector
      listen
      onClickOutside={() => {
        setIsDown(false);
      }}
    >
      <div className="dropdown">
        <div className="relative w-full">
          <label htmlFor="search">{strings.petOrOwner} *</label>
          <div
            role="button"
            tabIndex={0}
            hidden={!onClickNewPetOwner}
            className="absolute right-0 top-0 tw-link text-sm"
            onClick={onClickNewPetOwner}
            style={{ cursor: "pointer" }}
          >
            {strings.newPetAndOwner}
          </div>
          <div className="relative w-full">
            <button
              className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              style={{ zIndex: 0 }}
              type="button"
            >
              {isSearchLoading ? (
                <LoaderInline />
              ) : (
                <MagnifyingGlass className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              )}
            </button>
            <input
              autoComplete={AutoCompleteOptions.off}
              className="tw-input pl-10"
              name="search"
              onChange={searchChange}
              onClick={() => {
                if (searchResult.length > 0 && !isDown) {
                  setIsDown(true);
                }
              }}
              placeholder={strings.searchPetOwner}
              type="text"
            />
          </div>
        </div>
        <ul
          className={`dropdown-menu w-full ${isDown ? "show" : ""}`}
          style={{
            maxHeight: "300px",
            minWidth: 0,
            padding: 0,
            overflowY: "scroll",
          }}
        >
          {isSearchLoading ? (
            <div style={{ padding: "12px", color: Colors.BLOCKBORDER }}>
              <LoaderInline />
            </div>
          ) : (
            <div style={{ fontSize: "12px" }}>
              {searchResult.length > 0 ? (
                <>
                  {searchResult.map(
                    (
                      { pet, petOwner }: ClinicPetAndOwnerResponse,
                      index: number
                    ) => (
                      <div
                        className={
                          index === searchResult.length - 1
                            ? ""
                            : "hr-on-bottom"
                        }
                        key={index}
                        style={{ padding: "12px" }}
                      >
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            onSelectPet({ pet, petOwner });
                            setIsDown(false);
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="flex flex-wrap items-center space-x-2">
                            <HighlightedText
                              text={pet?.clinicPetDetails?.name}
                              highlights={searchText.split(" ")}
                            />{" "}
                            {pet?.clinicPetDetails?.deceased && (
                              <div>
                                <Heart className="h-5 w-5" variant="solid" />
                              </div>
                            )}
                            <div>
                              (
                              {pet?.clinicPetDetails?.species &&
                                petSpecies[pet?.clinicPetDetails?.species]}
                              ,{" "}
                              {pet?.clinicPetDetails?.gender &&
                                petGenders[pet?.clinicPetDetails?.gender]}
                              , {pet?.clinicPetDetails?.breed})
                            </div>
                          </div>
                        </div>
                        <div className="flex">
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              onSelectPet({ pet, petOwner });
                              setIsDown(false);
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <HighlightedText
                              text={petOwner.petOwnerDetails.fullName}
                              highlights={searchText.split(" ")}
                            />
                          </div>
                          {petOwner.petOwnerDetails.personalId ? (
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                onSelectPet({ pet, petOwner });
                                setIsDown(false);
                              }}
                              role="button"
                              tabIndex={0}
                            >
                              <span className="ml-2">
                                <HighlightedText
                                  text={petOwner.petOwnerDetails.personalId}
                                  highlights={searchText.split(" ")}
                                />
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="ml-2" hidden={!onClickNewPet}>
                            <Button
                              variant="link p-0"
                              onClick={() => {
                                if (onClickNewPet) {
                                  onClickNewPet(petOwner);
                                  setIsDown(false);
                                }
                              }}
                              style={{
                                fontSize: "12px",
                                lineHeight: 1,
                                verticalAlign: "unset",
                              }}
                            >
                              {strings.newPet}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              ) : (
                <div style={{ padding: "12px" }}>
                  <EmptyListText />
                  <Link to="/create-pet-owner">{strings.createPetOwner}</Link>
                </div>
              )}
            </div>
          )}
        </ul>
      </div>
    </ClickOutsideDetector>
  );
};

export default PetSearchSection;
