/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  RegisterOptions,
} from "react-hook-form";
import React, { ReactElement, useEffect, useState } from "react";

import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { Colors } from "../../../models/Colors";
import { strings } from "../../../common/Strings/Strings";
import { Tooltip } from "../../Tooltip";
import { RegisterReturnType } from "../../../models/RegisterReturnType";
import { InfoFilled } from "../../../common/Icons/InfoFilled";

interface FieldProps {
  name: string;
  register(ref: string, registerOptions?: RegisterOptions): RegisterReturnType;
  autoComplete?: AutoCompleteOptions;
  className?: string;
  defaultChecked?: boolean;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  fieldOptions?: RegisterOptions;
  id?: string;
  label?: ReactElement | string;
  onChange?(e: boolean): void;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  secondLabel?: string;
  toolTipText?: string | ReactElement;
}

const CheckBox = ({
  name,
  register,
  autoComplete = AutoCompleteOptions.on,
  className,
  defaultChecked = false,
  error,
  fieldOptions,
  id = name,
  label,
  onChange,
  optional = false,
  readOnly = false,
  required = false,
  secondLabel,
  toolTipText,
}: FieldProps): ReactElement => {
  const [options, setOptions] = useState<RegisterOptions>(fieldOptions || {});

  useEffect(() => {
    if (required) {
      setOptions({ ...options, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  return (
    <div className={`${className || ""} ${error ? "has-error" : ""}`}>
      <div className="flex items-center">
        <input
          {...register(name, options)}
          autoComplete={autoComplete || AutoCompleteOptions.on}
          className="cursor-pointer"
          disabled={readOnly}
          id={id}
          name={name}
          type="checkbox"
          readOnly={readOnly}
          defaultChecked={defaultChecked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            void register(name).onChange(e);
            if (onChange) {
              onChange(e.target.checked);
            }
          }}
        />
        <label htmlFor={id}>
          {label}
          {optional ? (
            <span style={{ color: Colors.INPUTBORDER }}>
              {" "}
              ({strings.optional})
            </span>
          ) : (
            <></>
          )}
          {secondLabel ? <div>{secondLabel}</div> : <></>}
        </label>
        {toolTipText ? (
          <div
            className="ml-2"
            style={{
              fontSize: "20px",
            }}
          >
            <Tooltip content={toolTipText} placement="top">
              <span>
                <InfoFilled className="text-sky-500" />
              </span>
            </Tooltip>
          </div>
        ) : (
          <></>
        )}
      </div>
      <p>{error && error.message?.toString()}</p>
    </div>
  );
};

export default CheckBox;
