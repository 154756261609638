/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { paymentMethod, strings } from "../../../../../common/Strings/Strings";
import {
  canManageClinic,
  getGeneralError,
} from "../../../../../util/helperFunctions";
import LoaderInline from "../../../../../components/LoaderInline";
import AlertBox from "../../../../../components/AlertBox";
import { PaymentMethodResponse } from "../../../../../models/payment/PaymentMethodResponse";
import PaymentMethodApi from "../../../../../api/PaymentMethodApi";
import PaymentMethodsModal from "./PaymentMethodsModal";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import Button from "../../../../../components/Button";
import { Trash } from "../../../../../common/Icons/Trash";
import { PlusIcon } from "../../../../../common/Icons/PlusIcon";
import EmptyListText from "../../../../../components/EmptyListText";

interface Props {
  clinic: ClinicResponse;
}

const PaymentMethods: React.FC<Props> = ({ clinic }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodResponse[]>(
    []
  );

  const [loaded, setLoaded] = useState<boolean>(true);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getPaymentMethods = async () => {
    setLoaded(false);

    try {
      const response = await PaymentMethodApi.getPaymentMethodsOfClinic(
        clinic.id
      );
      setPaymentMethods(response.data);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoaded(true);
    }
  };

  const triggerReload = () => {
    void getPaymentMethods();
  };

  useEffect(() => {
    setPaymentMethods(clinic.paymentMethods || []);
    /* eslint-disable-next-line */
  }, [clinic]);

  const deletePaymentMethod = async (method: PaymentMethodResponse) => {
    try {
      setLoaded(false);
      await PaymentMethodApi.removePaymentMethod(clinic.id, method.id);
      triggerReload();
    } catch (e) {
      setError(await getGeneralError(e));
      setLoaded(true);
    }
  };

  const readOnly = !canManageClinic(clinic);

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 flex items-center">
          <p className="uppercase">{strings.supportedPaymentMethods}</p>
          <div className="ml-auto">
            <LoaderInline className="m-5" hidden={loaded} />
          </div>
        </div>
        <div className="p-4 space-y-4">
          <AlertBox message={error} />
          <Button variant="link" onClick={openModal} hidden={readOnly}>
            <div className="flex">
              <PlusIcon /> <span>{strings.addPaymentMethod}</span>
            </div>
          </Button>
          {!loaded ? (
            <div className="tw-table-container py-3.5 px-4">
              <LoaderInline />
            </div>
          ) : (
            <>
              {paymentMethods.length === 0 ? (
                <div className="tw-table-container py-3.5 px-4">
                  <EmptyListText />
                </div>
              ) : (
                <div className="tw-table-container">
                  <table className="tw-table" id="paymentMethods">
                    <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
                      <tr>
                        <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          {strings.name}
                        </th>
                        <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          {strings.paymentType}
                        </th>
                        <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          {strings.code}
                        </th>
                        <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400" />
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                      {paymentMethods.map((method) => (
                        <tr key={method.id}>
                          <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                            {method.name}
                          </td>
                          <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                            {paymentMethod[method.type]}
                          </td>
                          <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                            {method.code}
                          </td>
                          <td className="px-4 py-3 text-sm font-medium whitespace-nowrap text-right">
                            <Button
                              type="button"
                              hidden={readOnly}
                              variant="icon"
                              onClick={() => {
                                void deletePaymentMethod(method);
                              }}
                            >
                              <Trash />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
          <Button
            variant="link"
            onClick={openModal}
            hidden={readOnly || paymentMethods.length < 10}
          >
            <div className="flex">
              <PlusIcon /> <span>{strings.addPaymentMethod}</span>
            </div>
          </Button>
        </div>
      </div>
      <PaymentMethodsModal
        clinicId={clinic.id}
        reload={triggerReload}
        isOpen={isModalOpen}
        close={closeModal}
      />
    </>
  );
};

export default PaymentMethods;
