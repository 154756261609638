/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import InventoryCategoryApi from "../../../api/InventoryCategoryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import CloseButton from "../../../components/CloseButton";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { NewInventoryCategoryRequest } from "../../../models/inventory/category/NewInventoryCategoryRequest";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";

interface Props {
  clinicId?: string;
  isOpen: boolean;
  close: () => void;
  reloadCategories: () => void;
}

const defaultFormValues = {
  name: "",
};

const CreateCategoryModal: React.FC<Props> = ({
  clinicId,
  isOpen,
  close,
  reloadCategories,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }, 
    reset
  } = useForm<NewInventoryCategoryRequest>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  const submitForm = async (formData: NewInventoryCategoryRequest) => {
    setLoading(true);
    if (clinicId) {
      try {
        await InventoryCategoryApi.createCategory(clinicId, formData);
        reset();
        close();
        reloadCategories();
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.createNewCat} />
      <Modal.Body noSpacing>
        <form className="modal-body" id="createNewInventoryCategoryForm">
          <Field
            name="name"
            label={strings.name}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            required
            error={errors.name}
          />
          <AlertBox message={error} type={AlertType.ERROR} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || loading}
          form="createNewInventoryCategoryForm"
          loading={loading}
          onClick={handleSubmit(submitForm)}
          type="submit"
        >
          {strings.save}
        </Button>
        <CloseButton onClick={close} />
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCategoryModal;
