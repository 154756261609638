/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import moment from "moment";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { strings } from "../../../common/Strings/Strings";
import InvoiceApi from "../../../api/InvoiceApi";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import CloseButton from "../../../components/CloseButton";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";

interface Props {
  allowSendNew: boolean;
  invoice: InvoiceResponse;
  setInvoice(invoice: InvoiceResponse): void;
}

const EmailReminders = ({
  allowSendNew,
  invoice,
  setInvoice,
}: Props): ReactElement => {
  const [showSendEmailModal, setShowSendEmailModal] = useState<boolean>(false);
  const [emailSendingLoading, setEmailSendingLoading] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const openEmailModal = () => {
    setShowSendEmailModal(true);
  };

  const closeEmailModal = () => {
    if (!emailSendingLoading) {
      setShowSendEmailModal(false);
    }
  };

  const sendEmailReminder = async () => {
    setEmailSendingLoading(true);

    try {
      const response = await InvoiceApi.sendPaymentReminder(invoice.id);
      setInvoice(response.data);
      setError(null);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setEmailSendingLoading(false);
      closeEmailModal();
    }
  };

  return (
    <>
      <div className="w-full md:tw-card space-y-6 md:p-8 my-6 space-y-6">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
          {strings.emailReminders}
        </h2>
        <AlertBox message={error} />
        <div
          hidden={
            invoice?.costAccounting?.status !== "UNRESOLVED" ||
            !invoice?.customer?.email ||
            !allowSendNew
          }
        >
          <Button minW onClick={openEmailModal}>
            {strings.sendReminderEmail}
          </Button>
        </div>
        {(invoice?.costAccounting?.historyOfEmailReminders?.length || 0) > 0 ? (
          <div className="tw-card divide-y dark:divide-gray-700">
            <div
              hidden={
                (invoice?.costAccounting?.historyOfEmailReminders?.length ||
                  0) > 0
              }
            >
              {strings.noEmailReminderSent}
            </div>
            {invoice?.costAccounting?.historyOfEmailReminders
              ?.reverse()
              ?.map((dateTime, idx) => (
                <div
                  className="flex p-4 items-center flex-wrap"
                  key={`${dateTime}-${idx}`}
                >
                  <div className="font-semibold">
                    {moment(dateTime).format("LL")}
                  </div>
                  <div className="ml-4">{invoice?.customer?.email}</div>
                </div>
              ))}
          </div>
        ) : (
          <AlertBox
            closeAble={false}
            message={strings.noEmailReminderSent}
            type={AlertType.INFO}
          />
        )}
      </div>
      <Modal handleClose={closeEmailModal} show={showSendEmailModal}>
        <Modal.Header title={strings.warning} />
        <Modal.Body>
          <div className="text-center m-5">
            {strings.warningYouAreAboutToSendEmail}
            <div>
              <b>{`(${invoice?.customer?.email})`}</b>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-main-button"
            disabled={emailSendingLoading}
            loading={emailSendingLoading}
            onClick={sendEmailReminder}
          >
            {strings.sendReminderEmail}
          </Button>
          <CloseButton onClick={closeEmailModal} />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmailReminders;
