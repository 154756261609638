/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import qs from "query-string";
import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { TicketResponse } from "../models/support/TicketResponse";
import { TicketType } from "../models/support/TicketType";
import { UnauthorizedTicketArticleRequest } from "../models/support/UnauthorizedTicketArticleRequest";
import { UnauthorizedTicketRequest } from "../models/support/UnauthorizedTicketRequest";
import { axiosCommon } from "../util/axiosSetup";

export default class TicketingApi {
  private static baseUrl = `${Params.clinicServiceBaseURL}/ticketing`;

  static createUnauthorizedTicket(
    request: UnauthorizedTicketRequest
  ): ApiPromise<TicketResponse> {
    const url = `${this.baseUrl}/unauthorized-tickets`;
    return axiosCommon.post(url, request);
  }

  static createUnauthorizedTicketArticle(
    id: number,
    request: UnauthorizedTicketArticleRequest
  ): ApiPromise<TicketResponse> {
    const url = `${this.baseUrl}/unauthorized-tickets/${id}/articles`;
    return axiosCommon.post(url, request);
  }

  static getUnauthorizedTicket(
    id: number,
    ticketKey: string,
    email: string
  ): ApiPromise<TicketResponse> {
    const query = qs.stringify({ ticketKey, email });
    const url = `${this.baseUrl}/unauthorized-tickets/${id}?${query}`;
    return axiosCommon.get(url);
  }

  static createAuthorizedTicket(request: {
    title: string;
    content: string;
    type: TicketType;
  }): ApiPromise<TicketResponse> {
    const url = `${this.baseUrl}/authorized-tickets`;
    return axiosCommon.post(url, request);
  }

  static createAuthorizedTicketArticle(
    id: number,
    request: { content: string }
  ): ApiPromise<TicketResponse> {
    const url = `${this.baseUrl}/authorized-tickets/${id}/articles`;
    return axiosCommon.post(url, request);
  }

  static getAuthorizedTicket(id: number): ApiPromise<TicketResponse> {
    const url = `${this.baseUrl}/authorized-tickets/${id}`;
    return axiosCommon.get(url);
  }

  static getAuthorizedTicketsOfUser(): ApiPromise<TicketResponse[]> {
    const url = `${this.baseUrl}/authorized-tickets/my`;
    return axiosCommon.get(url);
  }
}
