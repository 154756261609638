/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import { ApiPromise } from "../models/ApiPromise";
import { axiosCommon } from "../util/axiosSetup";
import Params from "../common/Params";
import { ClinicDiscountRequest } from "../models/discount/ClinicDiscountRequest";
import { ClinicDiscountResponse } from "../models/discount/ClinicDiscountResponse";
import { ClinicDiscountUpdateRequest } from "../models/discount/ClinicDiscountUpdateRequest";
import { PageResponse } from "../models/PageResponse";
import qs from "query-string";
import { DiscountsResponse } from "../models/discount/DiscountsResponse";
import { TrialDiscountResponse } from "../models/discount/TrialDiscountResponse";
import { TrialDiscountUpdateRequest } from "../models/discount/TrialDiscountUpdateRequest";

export default class ClinicDiscountApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getDiscounts(queryParams: {
    pageNumber?: number;
    pageSize?: number;
  }): ApiPromise<PageResponse<ClinicDiscountResponse>> {
    const query = qs.stringify(queryParams);
    let url = `${this.baseUrl}/managements/discounts?${query}`;
    return axiosCommon.get(url);
  }

  static addDiscount(
    request: ClinicDiscountRequest
  ): ApiPromise<ClinicDiscountResponse> {
    const url = `${this.baseUrl}/managements/discounts`;
    return axiosCommon.post(url, request);
  }

  static changeDiscount(
    discountId: string,
    request: ClinicDiscountUpdateRequest
  ): ApiPromise<ClinicDiscountResponse> {
    const url = `${this.baseUrl}/managements/discounts/${discountId}`;
    return axiosCommon.put(url, request);
  }

  static deleteDiscount(discountId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/managements/discounts/${discountId}`;
    return axiosCommon.delete(url);
  }

  static getDiscountsOfClinic(clinicId: string): ApiPromise<DiscountsResponse> {
    const url = `${this.baseUrl}/managements/clinic-discounts/${clinicId}`;
    return axiosCommon.get(url);
  }

  static changeTrialDiscount(
    trialDiscountId: string,
    request: TrialDiscountUpdateRequest
  ): ApiPromise<TrialDiscountResponse> {
    const url = `${this.baseUrl}/managements/trial-discounts/${trialDiscountId}`;
    return axiosCommon.put(url, request);
  }

  static deleteTrialDiscount(trialDiscountId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/managements/trial-discounts/${trialDiscountId}`;
    return axiosCommon.delete(url);
  }
}
