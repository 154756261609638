/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import AlertBox from "../../../../../../components/AlertBox";
import { strings } from "../../../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../../../util/helperFunctions";
import CloseButton from "../../../../../../components/CloseButton";
import logger from "../../../../../../util/logger";
import { PetTransferResponse } from "../../../../../../models/petTransfer/PetTransferResponse";
import PetTransferApi from "../../../../../../api/PetTransferApi";
import Modal from "../../../../../../components/Modal/Modal";
import Button from "../../../../../../components/Button";
import PetProfilePicture from "../../../../../../components/Pictures/Pet/PetProfilePicture";

interface Props {
  close(): void;
  isOpen: boolean;
  transfer?: PetTransferResponse;
  setSuccess(value: boolean): void;
}

const AcceptTransferOwnershipModal = ({
  close,
  isOpen,
  transfer,
  setSuccess,
}: Props): ReactElement => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();

  const submitAccept = async () => {
    if (transfer) {
      setSubmitting(true);
      try {
        await PetTransferApi.accept(transfer.id);
        setSuccess(true);
      } catch (e) {
        setError(await getGeneralError(e));
        logger.error(e);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.acceptOwnership} />
      <Modal.Body>
        <div className="flex items-center">
          <div className="w-[80px] h-[80px]">
            <PetProfilePicture species={transfer?.pet?.species} />
          </div>
          <b className="pl-5">{transfer?.pet?.name}</b>
        </div>
        <div className="mt-5" hidden={!transfer?.note}>
          <div className="flex items-center" style={{ wordBreak: "break-all" }}>
            <i>{`"${transfer?.note}"`}</i>
          </div>
        </div>
        <AlertBox className="mt-5" message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={submitting}
          onClick={submitAccept}
        >
          {strings.acceptOwnership}
        </Button>
        <CloseButton
          disabled={submitting}
          loading={submitting}
          onClick={close}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptTransferOwnershipModal;
