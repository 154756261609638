/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { dateAndTime, strings } from "../../common/Strings/Strings";
import { ClinicPetResponse } from "../../models/pet/ClinicPetResponse";
import { PetOwnerResponse } from "../../models/pet/PetOwnerResponse";
import PetInfo from "./PetInfo";
import PetOwnerInfo from "./PetOwnerInfo";
import { MedicalRecordTodoResponse } from "../../models/todo/MedicalRecordTodoResponse";
import { Cross } from "../../common/Icons/Cross";
import Button from "../../components/Button";
import { DocumentText } from "../../common/Icons/DocumentText";

interface Props {
  disableClose?: boolean;
  handleClose(): void;
  todoForMedicalRecord?: MedicalRecordTodoResponse;
  selectedPet?: ClinicPetResponse;
  selectedPetOwner?: PetOwnerResponse;
}

const SelectedPetOrOwnerCard = ({
  disableClose = false,
  handleClose,
  todoForMedicalRecord,
  selectedPet: pet,
  selectedPetOwner: petOwner,
}: Props): ReactElement => (
  <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
    <div className="p-3 flex justify-between">
      <div>{strings.petOrOwner}</div>
      <Button hidden={disableClose} onClick={handleClose} variant="icon">
        <Cross />
      </Button>
    </div>
    <div className="px-3 divide-y divide-gray-200 dark:divide-gray-700">
      {pet ? (
        <div className="py-3">
          <PetInfo pet={pet} />
        </div>
      ) : (
        <></>
      )}
      {petOwner ? (
        <div className="py-3">
          <PetOwnerInfo petOwner={petOwner} />
        </div>
      ) : (
        <></>
      )}
      {todoForMedicalRecord ? (
        <div className="py-3 flex items-center color-light-grey">
          <Link to={`/medical-record/${todoForMedicalRecord.medicalRecordId}`}>
            <Button
              variant="link-tertiary"
              className="flex space-x-2 items-center"
            >
              <DocumentText />
              <div>
                {todoForMedicalRecord.reservationType?.name}{" "}
                {todoForMedicalRecord.medicalRecordDateTime ? (
                  <span>
                    (
                    {moment(todoForMedicalRecord.medicalRecordDateTime).format(
                      dateAndTime.momentDateTimeFormat
                    )}
                    )
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Button>
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  </div>
);

export default SelectedPetOrOwnerCard;
