/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { GeoPharmacy } from "../../../../models/pharmacy/GeoPharmacy";
import GoogleMap, {
  MarkerMapLocation,
} from "../../../../components/map/GoogleMap";

interface Props {
  markers: GeoPharmacy[];
  returnPharmacy(p: GeoPharmacy): void;
}

const PharmacyMap: React.FC<Props> = ({
  returnPharmacy,
  markers: pharmacies,
}: Props) => {
  const [markers, setMarkers] = useState<MarkerMapLocation[]>([]);

  useEffect(() => {
    const list: MarkerMapLocation[] = [];
    pharmacies.forEach((pharmacy: GeoPharmacy) => {
      if (pharmacy.lat && pharmacy.lng) {
        list.push({
          lat: pharmacy.lat,
          lng: pharmacy.lng,
          id: pharmacy.glnCode,
        });
      }
    });
    setMarkers(list);
  }, [pharmacies]);

  const onMarkerClick = (key: string) => {
    const pharmacy = pharmacies.find((p) => p.glnCode === key);

    if (pharmacy) {
      returnPharmacy(pharmacy);
    }
  };

  return (
    <GoogleMap
      mapHeight="500px"
      mapWidth="500px"
      markers={markers}
      onMarkerClick={onMarkerClick}
      zoom={16}
    />
  );
};

export default PharmacyMap;
