/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import Field from "../../../components/ReactHookFormFields/General/Field";
import SelectCountry from "../../../components/ReactHookFormFields/Specific/SelectCountry";

const TestInvoiceBillingForm = (): ReactElement => {
  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <>
      <div className="tw-card">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
          {strings.billingInfo}
        </h2>
        <div className="p-4 grid grid-cols-2 gap-4">
          <Field
            register={register}
            name="name"
            error={errors.name}
            label={strings.name}
            required
          />
          <Field
            name="taxNumber"
            error={errors.taxNumber}
            register={register}
            label={strings.taxNumber}
          />
          <Field
            name="email"
            error={errors.email}
            register={register}
            label={strings.email}
            required
          />
          <Field
            name="street"
            error={errors.street}
            register={register}
            label={strings.address}
          />
          <Field
            name="houseDetails"
            error={errors.houseDetails}
            register={register}
            label={strings.addressLine2}
          />
          <Field
            name="city"
            error={errors.city}
            register={register}
            label={strings.city}
          />
          <Field
            name="zip"
            error={errors.zip}
            register={register}
            label={strings.zip}
          />
          <SelectCountry control={control} name="country" setValue={setValue} />
        </div>
      </div>
    </>
  );
};

export default TestInvoiceBillingForm;
