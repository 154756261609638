/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import { Tooltip } from "../Tooltip";
import { Colors } from "../../models/Colors";
import { strings } from "../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { InfoFilled } from "../../common/Icons/InfoFilled";

export type FieldInputType =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

interface FieldProps {
  name: string;
  autoComplete?: AutoCompleteOptions;
  classInput?: string;
  classLabel?: string;
  className?: string;
  disabled?: boolean;
  error?: string | FieldError;
  label?: string;
  labelIcon?: string;
  labelOff?: boolean;
  maxLength?: number;
  onChange?(e: string): void;
  optional?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  suffix?: string | null;
  toolTipText?: string;
  type?: FieldInputType;
  validate?(input: string): boolean;
  value?: any;
  width?: string;
}

const SimpleInput = ({
  name,
  autoComplete,
  classInput,
  classLabel,
  className,
  disabled = false,
  error: outsideError,
  label,
  labelIcon,
  labelOff,
  maxLength,
  onChange,
  optional,
  placeholder = label,
  readOnly,
  required,
  suffix,
  toolTipText,
  type = "text",
  validate,
  value: v,
  width,
}: FieldProps): ReactElement => {
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<string | null | undefined | FieldError>(
    outsideError
  );
  const [space, setSpace] = useState<number>(0);

  useEffect(() => {
    setError(outsideError);
  }, [outsideError]);

  useEffect(() => {
    if (suffix) {
      if (toolTipText) {
        setSpace(2);
      } else {
        setSpace(1);
      }
    } else if (toolTipText) {
      setSpace(1);
    }
  }, [suffix, toolTipText]);

  useEffect(() => {
    if (v !== undefined && v !== null) {
      setValue(v);
    } else {
      setValue("");
    }
  }, [v]);

  const inputClass =
    classInput ||
    `tw-input${space === 1 ? " pr-10" : ""}${space === 2 ? " pr-20" : ""}`;

  return (
    <div
      className={`${className || ""} ${error ? "has-error" : ""}`}
      style={{ width }}
    >
      {labelOff ? (
        <></>
      ) : (
        <label htmlFor={name} className={classLabel}>
          {labelIcon ? <i className={labelIcon} /> : ""} {label}
          {optional ? (
            <span style={{ color: Colors.INPUTBORDER }}>
              {" "}
              ({strings.optional})
            </span>
          ) : (
            <></>
          )}
        </label>
      )}
      <div className="relative w-full">
        <input
          autoComplete={autoComplete || AutoCompleteOptions.on}
          name={name}
          type={type}
          placeholder={placeholder}
          className={`${inputClass} text-ellipsis`}
          maxLength={maxLength}
          readOnly={readOnly}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
              setValue(e.target.value);
              onChange(e.target.value);
            }

            if (validate && !validate(e.target.value)) {
              setError(strings.notAllowedCharacter);
            } else if (validate) {
              setError(null);
            }
          }}
          value={value}
          required={required}
          disabled={disabled}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-2">
          {suffix ? (
            <div>
              <span>{suffix}</span>
            </div>
          ) : (
            <></>
          )}
          {toolTipText ? (
            <div>
              <Tooltip content={toolTipText} placement="top">
                <span>
                  <InfoFilled className="text-sky-500" />
                </span>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <p>{error ? (typeof error === "string" ? error : error?.message) : ""}</p>
    </div>
  );
};

export default SimpleInput;

SimpleInput.defaultProps = {
  autoComplete: AutoCompleteOptions.on,
  classInput: undefined,
  classLabel: undefined,
  className: undefined,
  error: undefined,
  label: undefined,
  labelIcon: undefined,
  labelOff: false,
  maxLength: undefined,
  onChange: undefined,
  optional: false,
  placeholder: undefined,
  readOnly: false,
  suffix: undefined,
  toolTipText: undefined,
  type: "text",
  width: undefined,
};
