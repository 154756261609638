/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../Strings/Strings";
import { ClipboardDocumentList } from "../../Icons/ClipboardDocumentList";
import { MenuItemPetOwner } from "./MenuItem";
import { Heart } from "../../Icons/Heart";
import { CalendarIcon } from "../../Icons/Calendar";
import { DocumentChartBar } from "../../Icons/DocumentCharBar";

const iconClassName = "w-6 h-6 flex-shrink-0";

const PetOwnerMenuItems: MenuItemPetOwner[] = [
  {
    type: "MY_PETS",
    name: strings.MY_PETS,
    href: "/",
    icon: <Heart className={iconClassName} />,
  },
  {
    type: "APPOINTMENTS",
    name: strings.APPOINTMENTS,
    href: "/appointments",
    icon: <CalendarIcon className={iconClassName} />,
  },
  {
    type: "PRESCRIPTIONS",
    name: strings.PRESCRIPTIONS,
    href: "/mypets/prescriptions",
    icon: <ClipboardDocumentList className={iconClassName} />,
  },
  {
    type: "INVOICES",
    name: strings.INVOICES,
    href: "/invoices",
    icon: <DocumentChartBar className={iconClassName} />,
  },
];

export default PetOwnerMenuItems;
