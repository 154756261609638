/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../common/Strings/Strings";
import { TodoResponse } from "../../models/todo/TodoResponse";
import TodoApi from "../../api/TodoApi";
import { getGeneralError } from "../../util/helperFunctions";
import TodoCard from "../Todo/TodoCard";
import TodoModal from "../Todo/TodoModal";
import { MedicalRecordTodoResponse } from "../../models/todo/MedicalRecordTodoResponse";
import LoaderInline from "../../components/LoaderInline";
import { useDebouncedState } from "../../hooks/hooks";
import { PetProfileTabs } from "../Pet/PetProfile/ClinicPetProfile";
import Button from "../../components/Button";
import { PlusIcon } from "../../common/Icons/PlusIcon";
import logger from "../../util/logger";
import ChevronButtonUp from "../../components/Buttons/ChevronButtonUp";
import ChevronButtonDown from "../../components/Buttons/ChevronButtonDown";

interface Props {
  medicalRecord: MedicalRecordTodoResponse;
}

const TodoSection = ({ medicalRecord }: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [todos, setTodos] = useState<TodoResponse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [newTodo, setNewTodo] = useState<boolean>(false);
  const reload = useDebouncedState<boolean>(true, 600);

  const triggerReload = () => {
    reload.setValue(!reload.value);
  };

  const getTodos = async (universalId: string) => {
    setLoading(true);

    try {
      const response = await TodoApi.getTodosForMedicalRecord(universalId);
      setTodos(response.data);
    } catch (e) {
      setError(await getGeneralError(e));
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getTodos(medicalRecord.universalMedicalRecordId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalRecord.universalMedicalRecordId, reload.value]);

  return (
    <div className="lg:tw-card p-4 lg:p-6">
      <button
        className="flex items-center justify-between w-full bg-transparent dark:bg-transparent bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        type="button"
      >
        <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {strings.tasks} ({todos.length})
        </h2>
        {isOpen ? <ChevronButtonUp /> : <ChevronButtonDown />}
      </button>

      {isOpen && (
        <div className="flex flex-col space-y-5 pt-5">
          {loading ? (
            <LoaderInline />
          ) : (
            todos.map((todo: TodoResponse) => (
              <Fragment key={todo.id}>
                <TodoCard
                  onError={setError}
                  reload={triggerReload}
                  sm
                  todo={todo}
                />
              </Fragment>
            ))
          )}

          <div className="flex justify-between items-center space-x-3">
            <Button
              variant="link"
              onClick={() => {
                setNewTodo(true);
              }}
            >
              <div className="flex items-center justify-center">
                <PlusIcon />
                <div>{strings.newTask}</div>
              </div>
            </Button>
            <Link
              to={`/clinic-pet-profile/${medicalRecord?.pet?.clinicPetDetails.id}/${PetProfileTabs.todos}`}
            >
              {strings.seeAllTasks}
            </Link>
          </div>
        </div>
      )}

      <TodoModal
        onHide={(isSuccess?: boolean) => {
          setNewTodo(false);
          if (isSuccess) {
            triggerReload();
          }
        }}
        show={newTodo}
        todoForMedicalRecord={medicalRecord}
      />
    </div>
  );
};

export default TodoSection;
