/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { strings } from "../../common/Strings/Strings";
import { doSecondFactor, setUpAuthToken } from "../../util/axiosSetup";
import { PageProps } from "../../models/PageProps";
import { Loader } from "../../components/Loader";
import { useUser } from "../../contexts/UserContext";
import { Card } from "../../components/Cards/Card";
import AlertBox from "../../components/AlertBox";
import { getGeneralError } from "../../util/helperFunctions";
import { getMfaToken } from "../../util/LocalStorageVariables";

function MfaConfirm({ setPageLoading }: PageProps): ReactElement {
  const { fetchUser } = useUser();
  const [error, setError] = useState<string | null>();
  const { code, email } = useParams<{ code: string; email: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    setPageLoading(true);
    if (email && code && getMfaToken()) {
      void doSecondFactor(
        email,
        code,
        (response) => {
          const { data } = response;

          setUpAuthToken(data);
          fetchUser().then(() => {
            navigate("/", { replace: true });
          });
        },
        async (err) => {
          setError(await getGeneralError(err));
          setPageLoading(false);
        }
      );
    } else {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, email]);

  return (
    <Card title={strings.mfaAuthentication}>
      <AlertBox message={error} />
    </Card>
  );
}

export default Loader(MfaConfirm);
