/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import {
  CombinedSelectComponentProps,
  GroupedOption,
} from "../../InputFieldsSimple/CombinedSelectComponent";
import CombinedSelect, {
  CombinedSelectFieldProps,
} from "../General/Select/CombinedSelect";

import { useClinic } from "../../../contexts/ClinicContext";
import { EmployeeResponse } from "../../../models/user/EmployeeResponse";
import { strings } from "../../../common/Strings/Strings";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";

interface Props
  extends CombinedSelectFieldProps,
    CombinedSelectComponentProps {}

export type SimpleEmployee = Omit<EmployeeResponse, "request">;

export const findEmployee = (
  clinic: ClinicResponse,
  userId: string
): SimpleEmployee | undefined => {
  let result: SimpleEmployee | undefined;

  result = clinic?.assistants.find((a) => a.userId === userId);

  if (!result) {
    result = clinic?.authorizedAssistants.find((a) => a.userId === userId);
  }
  if (!result) {
    result = clinic?.managers.find((a) => a.userId === userId);
  }
  if (!result) {
    result = clinic?.receptionists.find((a) => a.userId === userId);
  }
  if (!result) {
    result = clinic?.vets.find((a) => a.userId === userId);
  }

  return result;
};

function EmployeeSelect(props: Props): ReactElement {
  const [options, setOptions] = useState<GroupedOption<SimpleEmployee>[]>([]);
  const { clinic } = useClinic();

  const isConfirmedEmployee = (e: EmployeeResponse) => !e.request;

  useEffect(() => {
    if (clinic) {
      setOptions([
        {
          groupTitle: strings.assistants,
          groupOptions: [
            ...clinic.assistants
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName)),
          ],
        },
        {
          groupTitle: strings.authorizedAssistants,
          groupOptions: [
            ...clinic.authorizedAssistants
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName)),
          ],
        },
        {
          groupTitle: strings.managers,
          groupOptions: [
            ...clinic.managers
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName)),
          ],
        },
        {
          groupTitle: strings.receptionists,
          groupOptions: [
            ...clinic.receptionists
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName)),
          ],
        },
        {
          groupTitle: strings.vets,
          groupOptions: [
            ...clinic.vets
              .filter(isConfirmedEmployee)
              .sort((e1, e2) => e1.fullName.localeCompare(e2.fullName)),
          ],
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic]);

  return (
    <CombinedSelect labelKey="fullName" groupedByOptions={options} {...props} />
  );
}

export default EmployeeSelect;
