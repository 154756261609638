/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useImperativeHandle,
} from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import {
  SetupIntentResult,
  StripePaymentElementChangeEvent,
} from "@stripe/stripe-js";
import { Address } from "../../models/contact/Address";

interface Props {
  onValidation: (isValid: boolean) => void;
  address?: Address;
}

export interface StripeSetupFormRef {
  confirmSetup(): Promise<SetupIntentResult> | undefined;
}

const StripeSetupForm = forwardRef(
  (props: Props, ref: Ref<StripeSetupFormRef>): ReactElement => {
    const stripe = useStripe();
    const elements = useElements();

    const { onValidation, address } = props;

    useImperativeHandle(ref, () => ({
      confirmSetup(): Promise<SetupIntentResult> | undefined {
        if (stripe == null || elements == null) return undefined;
        let billingAddress;
        if (address) {
          billingAddress = {
            country: address.countryCode || "",
            city: address.city || "",
            line1: address.street || "",
            line2: address.houseDetails || "",
            postal_code: address.zip || "",
            state: "",
          };
        }
        return stripe.confirmSetup({
          elements,
          confirmParams: {
            payment_method_data: {
              billing_details: { address: billingAddress },
            },
          },
          redirect: "if_required",
        });
      },
    }));

    const onChange = (ev: StripePaymentElementChangeEvent) => {
      onValidation(ev.complete);
    };

    return (
      <PaymentElement
        onChange={onChange}
        options={{
          fields: { billingDetails: { address: address ? "never" : "auto" } },
        }}
      />
    );
  }
);

export default StripeSetupForm;

StripeSetupForm.defaultProps = {
  address: undefined,
};
