/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import moment from "moment";
import { generateOptions, isCancellable } from "../../../util/helperFunctions";
import { ReservationResponse } from "../../../models/reservation/ReservationResponse";
import {
  dateAndTime,
  getStringFromEnumsOrReturn,
  petGenders,
  petSpecies,
  strings,
} from "../../../common/Strings/Strings";
import { CancellableReservationResponse } from "../../../models/reservation/CancellableReservationResponse";
import ReservationTypeApi from "../../../api/ReservationTypeApi";
import { Option } from "../../../models/Option";
import { PublicUserResponse } from "../../../models/user/PublicUserResponse";
import MedicalRecordButtons from "../../../components/MedicalRecordActions/MedicalRecordButtons";
import { CalendarReservation } from "../../../models/calendar/CalendarReservation";
import { useClinic } from "../../../contexts/ClinicContext";
import CalendarDayReservationModal from "../Reservation/CalendarDayReservationModal";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../../util/LocalStorageVariables";
import { MedicalRecordStatus } from "../../../models/medical/MedicalRecordStatus";
import SimpleSelect from "../../../components/InputFieldsSimple/SimpleSelect";
import SimpleInput from "../../../components/InputFieldsSimple/SimpleInput";
import { ReservationState } from "../../../models/reservation/ReservationState";
import PetProfilePicture from "../../../components/Pictures/Pet/PetProfilePicture";
import logger from "../../../util/logger";
import EmptyListText from "../../../components/EmptyListText";
import { VideoCamera } from "../../../common/Icons/VideoCamera";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import Tooltip from "../../../components/Tooltip";
import ReservationStateIcons from "../Reservation/ReservationStateIcons";
import Tag, { TagType } from "../../../components/Tag";


interface ReservationCellProps {
  dayReservation: CancellableReservationResponse;
  onClick: () => void;
}

export const medicalRecordStatusTags = (reservation: CancellableReservationResponse | ReservationResponse) => {
  /* Created */
  if (
    !reservation?.lastMedicalRecordId ||
    (reservation?.lastMedicalRecordId &&
      !reservation?.lastSignedMedicalRecordId)
  ) {
    return (
      <></>
    );

    /* Signed */
  }
  if (
    reservation?.lastSignedMedicalRecordId ===
    reservation?.lastMedicalRecordId
  ) {
    return <Tag type={TagType.primary} text={strings.signed} />;

    /* Under correction */
  }
  return <Tag type={TagType.secondary} text={strings.underCorrection} />;
};

const ReservationCell = ({ dayReservation, onClick }: ReservationCellProps) => {
  const {
    collaborator,
    description,
    lastMedicalRecordId,
    pet,
    petOwner,
    startDateTime,
    endDateTime,
    reservationType,
    state,
    videoConsultationRoomId,
  } = dayReservation;

  return (
    <div className="p-4" onClick={onClick} role="button" tabIndex={0}>
      <div className="grid grid-cols-12 gap-4">
        <div className="cursor-pointer col-span-4 md:col-span-2 lg:col-span-1 flex items-center ">
          <div className="flex items-center space-x-2">
            <div
              className="w-2 self-stretch rounded-sm"
              style={{ backgroundColor: reservationType?.color }}
            />
            <strong>
              {startDateTime
                ? moment(startDateTime).format(dateAndTime.timeFormat)
                : ""}{" "}
              –<br />{" "}
              {endDateTime
                ? moment(endDateTime).format(dateAndTime.timeFormat)
                : ""}
            </strong>
            <div className="flex flex-grow justify-center">
              <ReservationStateIcons
                notArrivedVisible={false} 
                reservationState={state} 
                size="w-6 h-6" 
              />
            </div>
          </div>
        </div>
        <div className="cursor-pointer col-span-8 md:col-span-10 lg:col-span-2 flex items-center">
          <strong>{collaborator?.fullName}</strong>
        </div>
        <div className="cursor-pointer col-span-12 md:col-span-6 lg:col-span-3 flex items-center">
          <div className="flex items-center space-x-2">
            <div style={{ width: "35px", height: "35px" }}>
              <PetProfilePicture petId={pet?.id} />
            </div>
            <div>
              <strong>{pet?.name}</strong>
              <p className="text-sm">
                {[
                  petSpecies[pet?.species || ""],
                  pet?.breed,
                  petGenders[pet?.gender || ""],
                ]
                  .filter((e) => e)
                  .join(", ")}
              </p>
            </div>
          </div>
        </div>
        <div className="cursor-pointer col-span-12 md:col-span-6 lg:col-span-2 xl:col-span-3 flex items-center">
          <p>{description}</p>
        </div>
        <div className="cursor-pointer col-span-12 lg:col-span-2 xl:col-span-1">
          <strong>{petOwner?.fullName}</strong>
          <p className="text-sm">
            {[petOwner?.personalId, petOwner?.phones[0]?.value]
              .filter((e) => e)
              .join(", ")}
          </p>
        </div>
        <div className="cursor-pointer col-span-12 lg:col-span-2 flex items-center justify-end space-x-3">
          <div hidden={!videoConsultationRoomId}>
            <Link 
              to={`/meet/${videoConsultationRoomId}`} 
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip content={strings.connectToVideoConsultation}>
                <Button className="tw-link" variant="icon">
                  <VideoCamera variant="outline" />
                </Button>
              </Tooltip>
            </Link>
          </div>
          <div>{medicalRecordStatusTags(dayReservation)}</div>
          <MedicalRecordButtons lastMedicalRecordId={lastMedicalRecordId} />
        </div>
      </div>
    </div>
  );
};

interface Props {
  reloadReservations(): void;
  reservations: CalendarReservation[];
}

const CalendarListView: React.FC<Props> = ({ reloadReservations, reservations }: Props) => {
  const { clinic } = useClinic();
  const role: UserRole | null = getValueOfActiveUser("role");
  const [dayData, setDayData] = useState<CancellableReservationResponse[]>([]);
  const [filteredDayData, setFilteredDayData] = useState<
    CancellableReservationResponse[]
  >([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [activeReservation, setActiveReservation] =
    useState<CancellableReservationResponse>();
  const [reservationTypes, setReservationTypes] = useState<Option[]>([]);
  const [collaborators, setCollaborators] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [reservationTypeFilter, setReservationTypeFilter] =
    useState<string>("-");
  const [vetFilter, setVetFilter] = useState<string | null>(null);
  const [reservationStateFilter, setReservationStateFilter] =
    useState<string>("-");
  const [signatureFilter, setSignatureFilter] = useState<string>("-");

  const openModal = (reservationData: CancellableReservationResponse): void => {
    setActiveReservation(reservationData);
    setModalIsOpen(true);
  };

  const closeModal = (): void => {
    setModalIsOpen(false);
  };

  const sortCollaborators = (
    res: ReservationResponse[]
  ): PublicUserResponse[] => {
    const collabs: PublicUserResponse[] = res.map(
      (reservation) => reservation.collaborator
    );

    return (
      Array.from(new Set(collabs.map((c) => c.userId)))
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((userId) => collabs.find((c) => c.userId === userId)!)
        .filter((c) => c !== undefined)
    );
  };

  useEffect(() => {
    const dataArray: CancellableReservationResponse[] = [];
    if (reservations.length !== 0) {
      reservations.forEach((item: ReservationResponse) => {
        const newItem: CancellableReservationResponse = {
          ...item,
          cancellable: isCancellable(item, role || undefined),
        };

        dataArray.push(newItem);
      });
    }

    const options: Option[] = [];
    options.push({ title: strings.all, value: "-" });
    const collabs = sortCollaborators(reservations);

    setCollaborators(
      options.concat(generateOptions(collabs, "fullName", "userId"))
    );
    setDayData(dataArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservations]);

  useEffect(() => {
    const getReservationTypes = async () => {
      if (!clinic) {
        return;
      }
      try {
        const response = await ReservationTypeApi.getReservationTypesOfClinic(
          clinic.id
        );
        const options: Option[] = [];
        options.push({ title: strings.all, value: -1 });
        setReservationTypes(
          options.concat(generateOptions(response.data, "name", "id"))
        );
      } catch (err) {
        logger.error(err);
      }
    };

    void getReservationTypes();
  }, [clinic]);

  useEffect(() => {
    const filterBySignatureState = (
      dayReservation: CancellableReservationResponse
    ): boolean => {
      switch (signatureFilter) {
        case MedicalRecordStatus.IN_PROGRESS:
          return (
            dayReservation.lastMedicalRecordId !==
            dayReservation.lastSignedMedicalRecordId
          );
        case MedicalRecordStatus.SIGNED:
          return (
            dayReservation.lastMedicalRecordId ===
            dayReservation.lastSignedMedicalRecordId
          );
        case "-":
          return true;
        default:
          return false;
      }
    };

    setFilteredDayData(
      dayData
        .sort(
          (r1, r2) =>
            moment(r1.startDateTime).valueOf() -
            moment(r2.startDateTime).valueOf()
        )
        .filter((dayReservation: CancellableReservationResponse) => {
          const lowerSearchValue = searchValue.toLowerCase();
          const petName = dayReservation?.pet?.name?.toLowerCase();
          const ownerName = dayReservation?.petOwner?.fullName?.toLowerCase();
          const ownerId = dayReservation?.petOwner?.personalId?.toLowerCase();

          return (
            petName?.includes(lowerSearchValue) ||
            ownerName?.includes(lowerSearchValue) ||
            ownerId?.includes(lowerSearchValue) ||
            searchValue === ""
          );
        })
        .filter(
          (dayReservation: CancellableReservationResponse) =>
            dayReservation?.reservationType?.id === reservationTypeFilter ||
            reservationTypeFilter === "-"
        )
        .filter(
          (dayReservation: CancellableReservationResponse) =>
            dayReservation?.collaborator?.userId === vetFilter ||
            vetFilter === null
        )
        .filter(filterBySignatureState)
        .filter(
          (dayReservation: CancellableReservationResponse) =>
            dayReservation?.state === reservationStateFilter ||
            reservationStateFilter === "-"
        )
    );
  }, [
    dayData,
    searchValue,
    reservationTypeFilter,
    vetFilter,
    signatureFilter,
    reservationStateFilter,
  ]);

  return (
    <div className="bg-white dark:bg-gray-800 p-6">
      <div className="hidden md:flex flex-wrap pb-6">
        <div className="flex">
          <div className="pb-4 lg:pb-0 grow md:w-64 pr-4">
            <SimpleInput
              label={strings.search}
              name="search"
              onChange={(search: string) => {
                setSearchValue(search);
              }}
              placeholder={strings.searchPetOwner}
            />
          </div>
          <div className="pb-4 lg:pb-0 md:pr-4">
            <SimpleSelect
              isChooseOption={false}
              label={strings.reservationType}
              name="filterByReservationType"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setReservationTypeFilter(e.target.value);
              }}
              options={reservationTypes}
            />
          </div>
        </div>
        <div className="flex">
          <div className="pr-4">
            <SimpleSelect
              isChooseOption={false}
              label={strings.collaborator}
              name="filterByVet"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                const filterByVet = e.target.value;
                setVetFilter(filterByVet === "-" ? null : filterByVet);
              }}
              options={collaborators}
            />
          </div>
          <div className="pr-4">
            <SimpleSelect
              isChooseOption={false}
              label={strings.signature}
              name="filterBySignature"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setSignatureFilter(e.target.value);
              }}
              options={[
                { title: strings.all, value: "-" },
                {
                  value: MedicalRecordStatus.IN_PROGRESS,
                  title: strings.inProgress,
                },
                { value: MedicalRecordStatus.SIGNED, title: strings.signed },
              ]}
            />
          </div>
          <div>
            <SimpleSelect
              isChooseOption={false}
              label={strings.status}
              name="filterByReservationState"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setReservationStateFilter(e.target.value);
              }}
              options={[
                { title: strings.all, value: "-" },
                {
                  value: ReservationState.NOT_ARRIVED_YET,
                  title: getStringFromEnumsOrReturn(
                    ReservationState.NOT_ARRIVED_YET
                  ),
                },
                {
                  value: ReservationState.ARRIVED,
                  title: getStringFromEnumsOrReturn(ReservationState.ARRIVED),
                },
                {
                  value: ReservationState.UNDER_TREATMENT,
                  title: getStringFromEnumsOrReturn(
                    ReservationState.UNDER_TREATMENT
                  ),
                },
                {
                  value: ReservationState.READY_FOR_PAYMENT,
                  title: getStringFromEnumsOrReturn(
                    ReservationState.READY_FOR_PAYMENT
                  ),
                },
                {
                  value: ReservationState.PENDING_PAYMENT,
                  title: getStringFromEnumsOrReturn(
                    ReservationState.PENDING_PAYMENT
                  ),
                },
                {
                  value: ReservationState.PAID,
                  title: getStringFromEnumsOrReturn(ReservationState.PAID),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="w-full tw-card divide-y dark:divide-gray-700">
        {filteredDayData.length === 0 ? (
          <div className="p-4">
            <EmptyListText text={strings.noReservationsFound} />
          </div>
        ) : (
          filteredDayData.map((r) => (
            <Fragment key={r.id}>
              <ReservationCell
                dayReservation={r}
                onClick={() => openModal(r)}
              />
            </Fragment>
          ))
        )}
      </div>
      {activeReservation !== undefined ? (
        <CalendarDayReservationModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          activeReservation={activeReservation}
          reload={() => {
            reloadReservations();
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CalendarListView;
