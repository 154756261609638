/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { UserResponse } from "../models/user/UserResponse";
import { VetRegistrationNumberRequest } from "../models/vet/VetRegistrationNumberRequest";
import Params from "../common/Params";
import { VetProfessionWithUserResponse } from "../models/vet/VetProfessionWithUserResponse";

export default class VetApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static addVetRegistrationNumber(
    request: VetRegistrationNumberRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/vet-registration-numbers`;
    return axiosCommon.post(url, request);
  }

  static updateVetRegistrationNumber(
    regNumberId: string,
    request: VetRegistrationNumberRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/vet-registration-numbers/${regNumberId}`;
    return axiosCommon.patch(url, request);
  }

  static removeVetRegistrationNumber(
    regNumberId: string
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/vet-registration-numbers/${regNumberId}`;
    return axiosCommon.delete(url);
  }

  static uploadDocumentToProveVetProfession(
    file: File
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-vet-profession-documents?fileType=${file.type}`;
    const formData = new FormData();
    formData.append("file", file);
    return axiosCommon.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: console.log,
    });
  }

  static removeVetProfessionDocument(
    documentId: string
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-vet-profession-documents/${documentId}`;
    return axiosCommon.delete(url);
  }

  static getVetProfessionDocument(documentId: string): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/vet-profession-documents/${documentId}`;
    return axiosCommon.get(url, {
      headers: { Accept: "*/*" },
      responseType: "arraybuffer",
    });
  }

  static getAllVetProfessionDocuments(): ApiPromise<
    VetProfessionWithUserResponse[]
  > {
    const url = `${this.baseUrl}/vet-profession-documents`;
    return axiosCommon.get(url);
  }

  static approveUserAsVet(
    vetUserId: string
  ): ApiPromise<VetProfessionWithUserResponse> {
    const url = `${this.baseUrl}/users/${vetUserId}/approve-vet-profession`;
    return axiosCommon.put(url);
  }

  static rejectUserAsVet(
    vetUserId: string,
    message: string
  ): ApiPromise<VetProfessionWithUserResponse> {
    const url = `${this.baseUrl}/users/${vetUserId}/reject-vet-profession`;
    return axiosCommon.put(url, { message });
  }
}
