/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import React, { useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import ReservationsForm from "./ReservationsForm";
import Button from "../../../../components/Button";
import { ShiftResponse } from "../../../../models/shift/ShiftResponse";
import { CalendarDays } from "../../../../common/Icons/CalendarDays";
import Modal from "../../../../components/Modal/Modal";
import UserProfilePicture from "../../../../components/Pictures/User/UserProfilePicture";

interface Props {
  changeState(): void;
  onHide(): void;
  reload(): void;
  selectedShiftData?: ShiftResponse;
  show: boolean;
}

type DeleteState = "areYouSure" | "substitutions";

const DeleteShiftsModal: React.FC<Props> = ({
  changeState,
  onHide,
  selectedShiftData,
  reload,
  show,
}: Props) => {
  const [deleteState, setDeleteState] = useState<DeleteState>("areYouSure");
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.deleteShift} />
      <Modal.Body>
        {deleteState === "areYouSure" ? (
          <div className="space-y-4">
            <div className="">{strings.areYouSureToDeleteShift}</div>
            <div className="flex items-center space-x-4">
              <div style={{ width: "40px", height: "40px" }}>
                <UserProfilePicture
                  userId={selectedShiftData?.collaborator.userId}
                />
              </div>
              <div className="font-bold">
                {selectedShiftData?.collaborator.fullName}
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="relative rounded-full bg-sky-50 w-10 h-10 flex-shrink-0 flex justify-center items-center dark:bg-sky-50 dark:text-gray-700">
                <CalendarDays />
              </div>
              <div className="font-bold">
                {moment(selectedShiftData?.startDateTime).format("LLL")} -{" "}
                {moment(selectedShiftData?.endDateTime).format("LT")}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <ReservationsForm
              employeeUserId={selectedShiftData?.collaborator.userId}
              start={selectedShiftData?.startDateTime}
              end={selectedShiftData?.endDateTime}
              onHide={onHide}
              setLoading={setLoading}
              reload={reload}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {deleteState === "areYouSure" ? (
          <Button
            className="modal-main-button"
            onClick={() => {
              if (deleteState === "areYouSure") {
                setDeleteState("substitutions");
              }
            }}
          >
            {strings.next}
          </Button>
        ) : (
          <Button
            className="modal-main-button"
            disabled={loading}
            loading={loading}
            form="reservationsForm"
            type="submit"
          >
            {strings.finish}
          </Button>
        )}
        <Button
          className="modal-sub-button"
          onClick={() => {
            if (deleteState === "areYouSure") {
              changeState();
            } else {
              onHide();
            }
          }}
          variant="secondary"
        >
          {deleteState === "areYouSure" ? strings.back : strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteShiftsModal;
