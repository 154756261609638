/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Button } from "react-bootstrap";
import { strings } from "../../../common/Strings/Strings";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import NumberFormatter from "../../../util/NumberFormatter";
import CostCalculator from "../../../util/CostCalculator";
import { TreatmentFeeResponse } from "../../../models/treatment/TreatmentFeeResponse";
import SimpleInput from "../../../components/InputFieldsSimple/SimpleInput";
import { XMark } from "../../../common/Icons/XMark";
import TreatmentCostCalculator from "../../../util/TreatmentCostCalculator";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";

interface Props {
  clinic?: ClinicResponse;
  changeQuantityOfFee?(treatmentFee: TreatmentFeeResponse): void;
  countryDetails?: CountryDetailsResponse;
  deleteFee?(treatmentItem: TreatmentFeeResponse): void;
  fees: TreatmentFeeResponse[];
  showSum?: boolean;
}

const FeeSection: React.FC<Props> = ({
  clinic,
  changeQuantityOfFee,
  countryDetails,
  deleteFee,
  fees,
  showSum,
}: Props) => {
  const val = (input: string): boolean =>
    !Number.isNaN(Number(input)) && input.length > 0 && Number(input) > 0;

  return (
    <div className="tw-table-container my-3" hidden={fees.length === 0}>
      <table className="tw-table">
        <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
          <tr>
            <th className="py-2 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
              {strings.feeName}
            </th>
            <th className="py-2 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
              {strings.quantity}
            </th>
            <th className="py-2 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
              {strings.price} ({strings.gross})
            </th>
            <th />
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
          {fees.map((usedFee: TreatmentFeeResponse) => (
            <tr key={usedFee.fee.id}>
              <td className="px-4 py-2 text-sm font-medium">
                {usedFee.fee.name}
              </td>
              <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                {changeQuantityOfFee ? (
                  <SimpleInput
                    className="m-0"
                    name="quantity"
                    onChange={(newValue: string) => {
                      changeQuantityOfFee({
                        ...usedFee,
                        quantity: Number(newValue),
                      });
                    }}
                    type="number"
                    validate={val}
                    value={usedFee.quantity.toString()}
                    width="80px"
                  />
                ) : (
                  <>{usedFee.quantity}</>
                )}
              </td>
              <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                {NumberFormatter.formatPrice(
                  CostCalculator.getGrossPriceOfItem(
                    {
                      ...usedFee.fee,
                      vat: usedFee.fee.vat,
                      price: usedFee.fee.price || 0,
                      quantity: usedFee.quantity,
                    },
                    countryDetails,
                    clinic
                  ),
                  usedFee.fee.currency
                )}
              </td>
              <td className="px-4 py-2 text-sm font-medium whitespace-nowrap text-right">
                {deleteFee ? (
                  <Button
                    type="button"
                    variant="simple-dark"
                    onClick={() => deleteFee(usedFee)}
                  >
                    <XMark />
                  </Button>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          ))}
          <tr hidden={!showSum}>
            <td className="px-4 py-2 text-sm font-bold whitespace-nowrap">
              {strings.sum}
            </td>
            <td />
            <td className="px-4 py-2 text-sm font-bold whitespace-nowrap">
              {NumberFormatter.formatPrice(
                TreatmentCostCalculator.getGrossPriceOfFees2(
                  fees,
                  countryDetails,
                  clinic
                ),
                countryDetails?.currency
              )}
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FeeSection;

FeeSection.defaultProps = {
  changeQuantityOfFee: undefined,
  countryDetails: undefined,
  deleteFee: undefined,
};
