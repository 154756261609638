/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Dropdown, Flowbite, Navbar } from "flowbite-react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";
import { simpleLightText } from "../../assets/AssetConfig";
import { useMaintenance } from "../../contexts/MaintenanceContext";
import { Environments, getEnvironment } from "../../models/Environments";
import {
  getAccessToken,
  getSimpleLanguage,
  getValueOfActiveUser,
  setSimpleLanguage,
  UserRole,
} from "../../util/LocalStorageVariables";
import { strings, SupportedLanguages } from "../Strings/Strings";
import EnvironmentBadge from "./EnvironmentBadge";
import {
  DoctorMenuItem,
  PetOwnerMenuItem,
  SiteManagerMenuItem,
} from "./MenuItem/MenuItemTypes";
import NavDoctor from "./NavBarTypes/NavDoctor/NavDoctor";
import NavPetOwner from "./NavBarTypes/NavPetOwner/NavPetOwner";
import UserMenu from "./UserMenu/UserMenu";
import NavSiteManager from "./NavBarTypes/NavSiteManager/NavSiteManager";
import { Globe } from "../Icons/Globe";
import { CheckSimple } from "../Icons/CheckSimple";
import Button from "../../components/Button";
import { QuestionMarkCircle } from "../Icons/QuestionMarkCircle";
import { customTheme } from "./Themes/NavTheme";

interface Props {
  activeDoctorMenu?: DoctorMenuItem;
  activePetOwnerMenu?: PetOwnerMenuItem;
  activeSiteManagerMenu?: SiteManagerMenuItem;
  isSecondNavbarDisabled?: boolean;
}

const globalVetData = {
  startYear: 2019,
  currentYear: new Date().getFullYear(),
  fullName: "GlobalVET AB",
};

// International copyright statement
export const copyrightStatement = `${strings.copyright} ${globalVetData.startYear}-${globalVetData.currentYear} ${globalVetData.fullName}`;

const NavBar: React.FC<Props> = (props: Props) => {
  const [environment, setEnvironment] = useState<Environments>(
    Environments.Prod
  );

  const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");

  const maintenance = useMaintenance();

  useEffect(() => {
    setEnvironment(getEnvironment());
  }, []);

  const role: UserRole | null = getValueOfActiveUser("role");
  const authenticated = getAccessToken();

  if (maintenance?.maintenanceModeEnabled) {
    return null;
  }

  if (authenticated && role === "doctor") {
    return (
      <NavDoctor
        environment={environment}
        activeMenu={props.activeDoctorMenu}
        isSecondNavbarDisabled={props.isSecondNavbarDisabled}
      />
    );
  }
  if (authenticated && role === "owner") {
    return (
      <NavPetOwner
        environment={environment}
        activeMenu={props.activePetOwnerMenu}
      />
    );
  }
  if (authenticated && role === "manager") {
    return (
      <NavSiteManager
        environment={environment}
        activeMenu={props.activeSiteManagerMenu}
      />
    );
  }

  if (authenticated) {
    return (
      <Flowbite theme={{ theme: customTheme, dark: darkMode }}>
        <Navbar fluid>
          <Navbar.Brand href="/">
            <img alt="GlobalVet" className="w-24" src={simpleLightText} />
          </Navbar.Brand>
          <UserMenu />
        </Navbar>
        <EnvironmentBadge environment={environment} />
      </Flowbite>
    );
  }

  // Unauthenticated navbar
  return (
    <Flowbite theme={{ theme: customTheme, dark: darkMode }}>
      <Navbar fluid>
        <Navbar.Brand className="mr-auto" href="https://globalvet.com">
          <img alt="GlobalVet" className="w-24" src={simpleLightText} />
        </Navbar.Brand>
        <div className="flex flex-wrap items-center space-x-4 lg:space-x-5 lg:order-2">
          <Link to="/registration">
            <Button className="min-w-0 lg:mr-2">{strings.signUp}</Button>
          </Link>
          <Navbar.Collapse>
            <Navbar.Link href="/login">{strings.signIn}</Navbar.Link>
            <li>
              <Dropdown
                inline
                label={
                  <>
                    <Globe className="w-6 h-6 flex-shrink-0" />
                    <span>
                      {getSimpleLanguage()?.toUpperCase() || strings.language}
                    </span>
                  </>
                }
              >
                {SupportedLanguages.map((ln) => (
                  <Dropdown.Item
                    key={ln.code}
                    onClick={() => {
                      setSimpleLanguage(ln.code);
                      window.location.reload();
                    }}
                  >
                    {ln.title}
                    <CheckSimple
                      className={`ml-auto${
                        getSimpleLanguage() === ln.code ? "" : " hidden"
                      }`}
                    />
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </li>
            <Navbar.Link
              className="lg:hidden flex items-center space-x-2 w-full"
              href="/help"
            >
              <QuestionMarkCircle className="w-6 h-6 flex-shrink-0" />
              <span>{strings.help}</span>
            </Navbar.Link>
            <ul className="flex flex-col pb-4 mt-auto lg:hidden">
              <div className="text-slate-300 text-sm">{copyrightStatement}</div>
            </ul>
          </Navbar.Collapse>
        </div>
        <Navbar.Toggle className="ml-3" />
      </Navbar>
    </Flowbite>
  );
};

export default NavBar;
