/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment/moment";
import { PageProps } from "../../../../models/PageProps";
import { Loader } from "../../../../components/Loader";
import ReservationApi from "../../../../api/ReservationApi";
import { ReservationDeletionRequest } from "../../../../models/reservation/ReservationDeletionRequest";
import { getGeneralError } from "../../../../util/helperFunctions";
import { strings } from "../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import LoaderInline from "../../../../components/LoaderInline";
import { ReservationResponse } from "../../../../models/reservation/ReservationResponse";
import Button from "../../../../components/Button";
import AppointmentDetails from "./AppointmentDetails";

const ReservationDeletionPage = (props: PageProps) => {
  const { setPageLoading } = props;
  const { reservationId } = useParams<"reservationId">();

  const [reservation, setReservation] = useState<ReservationResponse>();
  const [isLoading, setLoading] = useState(false);
  const [isCancelled, setCancelled] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getReservation = async (rId: string) => {
      try {
        setPageLoading(true);
        const { data } = await ReservationApi.getReservation(rId);
        setReservation(data);
        if (
          data.lastMedicalRecordId !== null ||
          moment(data.startDateTime) <= moment()
        ) {
          setError(strings.cancelAppointmentError);
        }
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    };
    if (reservationId) {
      void getReservation(reservationId);
    }
  }, [reservationId]);

  const cancelReservation = async () => {
    setLoading(true);
    setError(null);
    setCancelled(false);

    if (reservationId) {
      try {
        const request: ReservationDeletionRequest = {
          employeeRequest: false,
        };

        await ReservationApi.deleteReservation(reservationId, request);
        setCancelled(true);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="mx-auto flex flex-col items-center justify-center space-y-6 px-4 py-10 lg:px-0 lg:py-14">
          <div className="w-full space-x-0 space-y-10 lg:w-auto lg:space-x-6 lg:space-y-0">
            <div className="w-full md:max-w-md md:tw-card md:p-8 space-y-6">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.cancelAppointment}
              </h1>
              <div hidden={!reservation}>
                {reservation && (
                  <AppointmentDetails
                    appointmentEnd={new Date(reservation.endDateTime)}
                    appointmentStart={new Date(reservation.startDateTime)}
                    clinicAddress={reservation.clinicAddresses[0]}
                    clinicName={reservation.clinicName}
                    description={reservation.description}
                    isVideoConsultation={!!reservation.videoConsultationRoomId}
                    resType={reservation.reservationType}
                  />
                )}
              </div>
              <AlertBox message={error} closeAble={false} />
              <AlertBox
                closeAble={false}
                hidden={!isCancelled}
                message={strings.cancelAppointmentSuccess}
                type={AlertType.SUCCESS}
              />
              <div hidden={!isLoading}>
                <div className="m-3 flex flex-col items-center">
                  <LoaderInline />
                </div>
              </div>
              <div hidden={isLoading || isCancelled || error != null}>
                <Button onClick={cancelReservation}>
                  {strings.cancelAppointment}
                </Button>
              </div>
              <div>
                <Link className="text-sm" to={`/appointments`}>
                  {strings.appointments}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(ReservationDeletionPage);
