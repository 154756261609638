/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CountrySettingsResponse } from "../../models/management/CountrySettingsResponse";
import CountryApi from "../../api/CountryApi";
import { getGeneralError } from "../../util/helperFunctions";
import LoaderInline from "../../components/LoaderInline";
import AlertBox from "../../components/AlertBox";
import { strings } from "../../common/Strings/Strings";

interface CountryMenuItemProps {
  countryName: string;
  countryCode: string;
}

export const CountryMenuItem = (props: CountryMenuItemProps): ReactElement => {
  const { countryName, countryCode } = props;

  return (
    <Link to={`/management/countries/${countryCode}`}>
      <div
        className="d-flex p-3 hr-on-top flex-fill align-items-center"
        key={countryCode}
      >
        <div className="paragraph">{countryName}</div>
        <div className="ml-auto">
          <Button variant="secondary">
            <i className="fas fa-chevron-right" />
          </Button>
        </div>
      </div>
    </Link>
  );
};

interface Props {
  isActiveTab: boolean;
}

export default function CountrySection({ isActiveTab }: Props): ReactElement {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<CountrySettingsResponse[]>([]);
  const [error, setError] = useState<string | null>(null);

  const getCountrySettings = async () => {
    try {
      const resp = await CountryApi.getCountrySettings();
      setCountries(resp.data);
      setLoading(false);
    } catch (err) {
      setError(await getGeneralError(err));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getCountrySettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab]);

  return (
    <>
      <LoaderInline className="my-3" hidden={!isLoading} />
      <div hidden={isLoading || error == null}>
        <AlertBox message={error} className="my-3" />
      </div>
      <div hidden={isLoading || error !== null}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3">
              <div className="card d-flex flex-column">
                <div className="d-flex p-3 flex-fill align-items-center">
                  <div className="paragraph bold">
                    {strings.activeCountries}
                  </div>
                </div>
                {countries
                  .filter((i) => i.globalvetEnabled)
                  .map((country) => (
                    <CountryMenuItem
                      countryName={country.countryName}
                      countryCode={country.countryCode}
                    />
                  ))}
              </div>
              <div className="card d-flex flex-column mt-4">
                <div className="d-flex p-3 flex-fill align-items-center">
                  <div className="paragraph bold">
                    {strings.inactiveCountries}
                  </div>
                </div>
                {countries
                  .filter((i) => !i.globalvetEnabled)
                  .map((country) => (
                    <CountryMenuItem
                      countryName={country.countryName}
                      countryCode={country.countryCode}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
