/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import AlertBox, { AlertType } from "../../components/AlertBox";
import Modal from "../../components/Modal/Modal";
import { strings } from "../../common/Strings/Strings";
import Button from "../../components/Button";
import { permissionImage } from "../../assets/AssetConfig";

interface Props {
  show: boolean;
  onHide: () => void;
}

const PermissionRequestModal: React.FC<Props> = ({ show, onHide }: Props) => (
  <Modal handleClose={onHide} show={show}>
    <Modal.Header title={strings.permissionRequestTitle} />
    <Modal.Body>
      <div className="flex justify-center">
        <img src={permissionImage} alt="permission" />
      </div>
      <AlertBox
        closeAble={false}
        message={strings.permissionRequestText}
        type={AlertType.INFO}
      />
    </Modal.Body>
    <Modal.Footer extraClassName="justify-end">
      <Button className="modal-main-button" onClick={onHide}>
        {strings.close}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default PermissionRequestModal;
