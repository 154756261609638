/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { strings } from "../../../common/Strings/Strings";
import { Option } from "../../../models/Option";
import SimpleSelect from "../../../components/InputFieldsSimple/SimpleSelect";

const sortMonth = (m1: Option, m2: Option) =>
  moment(m1.value).isBefore(m2.value) ? 1 : -1;

interface Props {
  setCurrentMonth(newValue: string): void;
  months: Option[];
}

const MonthSelectOfStats: React.FC<Props> = ({
  setCurrentMonth,
  months: values,
}: Props) => {
  const [months, setMonths] = useState<Option[]>([]);

  useEffect(() => {
    setMonths(values.sort(sortMonth));
  }, [values]);

  const onChangeMonth = (month: string) => {
    setCurrentMonth(month);
  };

  return (
    <SimpleSelect
      className="mb-0"
      name="month"
      isChooseOption={false}
      label={strings.month}
      onChange={(e) => onChangeMonth(e.target.value)}
      options={months}
      separationEnabled
      value={months[0]}
    />
  );
};

export default MonthSelectOfStats;
