/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { NewCostEntryRequest } from "../models/costaccounting/NewCostEntryRequest";
import { CostAccountingCorrectionRequest } from "../models/costaccounting/CostAccountingCorrectionRequest";
import { GroupPaymentRequest } from "../models/costaccounting/GroupPaymentRequest";
import Params from "../common/Params";
import { PaymentRequest } from "../models/costaccounting/PaymentRequest";
import { InvoiceResponse } from "../models/invoice/InvoiceResponse";

export default class CostAccountingApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static addPayment(
    invoiceId: string,
    request: NewCostEntryRequest
  ): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices/${invoiceId}/payments`;
    return axiosCommon.put(url, request);
  }

  static addInvoiceCorrection(
    invoiceId: string,
    request: CostAccountingCorrectionRequest
  ): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices/${invoiceId}/cost-accounting/correct`;
    return axiosCommon.put(url, request);
  }

  static payCostAccounting(
    invoiceId: string,
    request: PaymentRequest
  ): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices/${invoiceId}/pay`;
    return axiosCommon.post(url, request);
  }

  static quickPayCostAccounting(
    longInvoiceId: string,
    request: PaymentRequest
  ): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices/${longInvoiceId}/quick-pay`;
    return axiosCommon.post(url, request);
  }

  static payAllCostAccounting(request: GroupPaymentRequest): ApiPromise<void> {
    const url = `${this.baseUrl}/pay-invoices`;
    return axiosCommon.post(url, request);
  }

  static withdrawPayment(
    invoiceId: string,
    costEntryId: string
  ): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices/${invoiceId}/payments/${costEntryId}`;
    return axiosCommon.delete(url);
  }

  static syncPayment(
    invoiceId: string,
    costEntryId: string
  ): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices/${invoiceId}/payments/${costEntryId}/sync`;
    return axiosCommon.put(url);
  }
}
