/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { getGeneralError } from "../../util/helperFunctions";
import AlertBox from "../../components/AlertBox";
import PageSizeSelect from "../../components/PageSizeSelect";
import Paging from "../../components/Paging";
import { FeedbackResponse } from "../../models/feedback/FeedbackResponse";
import FeedbackApi from "../../api/FeedbackApi";
import {
  dateAndTime,
  getStringFromEnumsOrReturn,
  strings
} from "../../common/Strings/Strings";
import { PageProps } from "../../models/PageProps";
import { SiteManagerPage } from "./SiteManagerPage";

function FeedbacksPage(props: PageProps): ReactElement {
  const { setPageLoading } = props;
  const [error, setError] = useState<string | null>(null);

  const [feedbacks, setFeedbacks] = useState<FeedbackResponse[]>();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalElements, setTotalElements] = useState<number>(0);

  const getFeedbacks = async () => {
    try {
      setPageLoading(true);
      const response = await FeedbackApi.getSubmittedFeedbacks(
        pageSize,
        currentPage
      );
      setFeedbacks(response.data.elements);
      setTotalPages(response.data.totalPages);
      setTotalElements(response.data.totalElements);
      setError(null);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    void getFeedbacks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  return (
    <>
      <div className="container frame">
        <div className="row">
          <div className="col-sm-12 col-md-10 offset-md-1">
            <AlertBox message={error} className="my-3" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-10 offset-md-1">
            <div className="card">
              <div className="block-label p-3">
                <div className="flex-grow-1 h5 m-0">{strings.feedbacks}</div>
              </div>
              {feedbacks?.map(feedback => (
                <div className="hr-on-bottom p-3">
                  <div className="d-flex flex-wrap small">
                    <span className="material-icons md-18">schedule</span>
                    <div className="align-middle ml-2 mr-4">
                      {moment(feedback.submitDateTime).format(
                        dateAndTime.momentDateTimeFormat
                      )}
                    </div>
                    <span className="material-icons md-18">public</span>
                    <div className="align-middle ml-2 mr-4">
                      {feedback.countryCode}
                    </div>
                    <span className="material-icons md-18">person</span>
                    <div className="align-middle ml-2 mr-4">
                      {feedback.age} {strings.feedbackAge}
                    </div>
                    <span className="material-icons md-18">pets</span>
                    <div className="align-middle ml-2 mr-4">
                      {feedback.numberOfPets} {strings.feedbackPets}
                    </div>
                    {feedback.clinicRoles && (
                      <>
                        <span className="material-icons md-18">badge</span>
                        <div className="align-middle ml-2 mr-4">
                          {feedback.clinicRoles
                            .map(getStringFromEnumsOrReturn)
                            .join(", ")}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mt-3">{feedback.message}</div>
                </div>
              ))}
              <div className="d-flex px-3 align-items-center mt-3">
                <PageSizeSelect
                  pageSize={totalPages}
                  setPageSize={setPageSize}
                  totalPages={
                    (feedbacks?.length || 0) > 0
                      ? Math.ceil(totalElements / pageSize)
                      : 1
                  }
                  totalResults={totalElements}
                />
              </div>
              <div className="d-flex justify-content-center flex-fill p-3">
                <Paging
                  currentPage={currentPage}
                  totalPages={totalPages}
                  selectPage={page => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SiteManagerPage(FeedbacksPage, strings.FEEDBACKS);
