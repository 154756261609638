/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { strings } from "../../../common/Strings/Strings";
import { PaymentMethodResponse } from "../../../models/payment/PaymentMethodResponse";
import { useClinic } from "../../../contexts/ClinicContext";
import ClinicPetApi from "../../../api/ClinicPetApi";
import logger from "../../../util/logger";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import { CountryResponse } from "../../../models/management/CountryResponse";
import BillingForm from "./BillingForm";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { PaymentMethod } from "../../../models/costaccounting/PaymentMethod";
import { ClinicPetAndOwnerResponse } from "../../../models/pet/ClinicPetAndOwnerResponse";
import { PersonDetailsResponse } from "../../../models/contact/PersonDetailsResponse";
import DatePickerInput from "../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import Switch from "../../../components/ReactHookFormFields/General/Switch";
import { ClinicPetResponse } from "../../../models/pet/ClinicPetResponse";
import SelectedPetOrOwnerCard from "../../Todo/SelectedPetOrOwnerCard";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import MedicalRecordApi from "../../../api/MedicalRecordApi";
import { DetailedMedicalRecordResponse } from "../../../models/medical/DetailedMedicalRecordResponse";
import PetSearchSection from "../../Calendar/NewReservation/PetSearchSection";
import { InvoicingMethod } from "../../../models/invoice/InvoicingMethod";
import { getDefaultInvoicingMethod } from "../../../util/InvoiceSystemUtils";
import RadioButtonGroup from "../../../components/ReactHookFormFields/General/RadioButton/RadioButtonGroup";
import { Link } from "react-router-dom";

/*
Payment method type:
- ONLINE -> email is required

- ONLINE, TRANSFER -> not payed
 */

export interface PaymentForm {
  paymentMethod: PaymentMethodResponse[];
  invoicingMethod: InvoicingMethod;
  isBillingInfoTheSame: boolean;
  payLater: boolean;
  dueDate?: string;
}

export interface CustomerForm {
  customerUserId?: string;
  name: string;
  taxNumber?: string;
  email?: string;
  phone?: string;
  street?: string;
  houseDetails?: string;
  zip?: string;
  city?: string;
  country?: CountryResponse[];
}

export interface OtherCustomerForm {
  nameOther: string;
  taxNumberOther?: string;
  emailOther?: string;
  phoneOther?: string;
  streetOther?: string;
  houseDetailsOther?: string;
  zipOther?: string;
  cityOther?: string;
  countryOther?: CountryResponse[];
}

export interface InvoicingMethodOption {
  value: InvoicingMethod;
  label: string;
  sendLabel?: string;
}

const PaymentAndCustomerInfo = ({
  countries,
  invoice,
}: {
  countries: CountryResponse[];
  invoice?: InvoiceResponse;
}): ReactElement => {
  const [paymentMethods, setPaymentMethods] =
    useState<PaymentMethodResponse[]>();
  const [invoicingMethods, setInvoicingMethods] = useState<
    InvoicingMethodOption[]
  >([]);
  const [isBillingInfoTheSame, setIsBillingInfoTheSame] =
    useState<boolean>(true);
  const [paidImmediately, setPaidImmediately] = useState<boolean>(true);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodResponse>();
  const [invoicingMethod, setInvoicingMethod] = useState<InvoicingMethod>();
  const [selectedUser, setSelectedUser] = useState<PetOwnerResponse>();
  const [selectedPet, setSelectedPet] = useState<ClinicPetResponse>();
  const [selectedMedicalRecord, setSelectedMedicalRecord] =
    useState<DetailedMedicalRecordResponse>();
  const { register, control, setValue, clearErrors } = useFormContext();
  const { clinic } = useClinic();

  useEffect(() => {
    const getMedicalRecord = async () => {
      if (invoice?.medicalRecordId) {
        try {
          const response = await MedicalRecordApi.getMedicalRecord(
            invoice.medicalRecordId
          );
          setSelectedMedicalRecord(response.data);
        } catch (e) {
          logger.error(e);
        }
      }
    };

    const loadPet = async () => {
      if (invoice) {
        if (invoice.pet) {
          try {
            const response = await ClinicPetApi.getClinicPet(invoice.pet.id);
            setSelectedPet(response.data.pet);
            setSelectedUser(response.data.petOwner);
          } catch (e) {
            logger.error(e);
          }
        }
      }
    };

    void loadPet();
    void getMedicalRecord();
  }, [invoice]);

  useEffect(() => {
    setInvoicingMethods(getAvailableInvoicingMethods());
    setPaymentMethods(clinic?.paymentMethods);
    setInvoicingMethod(getDefaultInvoicingMethod(clinic, true));
  }, [clinic]);

  useEffect(() => {
    setValue("invoicingMethod", invoicingMethod);
  }, [invoicingMethod]);

  const selectUser = (owner: PersonDetailsResponse) => {
    const { fullName, addresses, emails, phones, id } = owner;
    const country: CountryResponse | undefined = countries.find(
      (c: CountryResponse) => c.countryCode === addresses[0]?.countryCode
    );

    setValue("name", fullName);
    setValue("customerUserId", id);

    if (phones[0]) {
      setValue("phone", phones[0].value);
    }
    if (emails[0]) {
      setValue("email", emails[0].value);
    }
    if (addresses[0]) {
      setValue("city", addresses[0].city);
      setValue("street", addresses[0].street);
      setValue("houseDetails", addresses[0].houseDetails);
      setValue("zip", addresses[0].zip);
      setValue("country", country ? [country] : []);
    }
    clearErrors();
  };

  const unSelectUser = () => {
    setSelectedUser(undefined);
    setSelectedPet(undefined);
    setValue("name", undefined);
    setValue("customerUserId", undefined);
    setValue("phone", undefined);
    setValue("email", undefined);
    setValue("city", undefined);
    setValue("street", undefined);
    setValue("houseDetails", undefined);
    setValue("zip", undefined);
    setValue("country", undefined);
    setValue("taxNumber", undefined);
  };

  useEffect(() => {
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBillingInfoTheSame]);

  const getAvailablePaymentMethods = (): PaymentMethodResponse[] => {
    const immediateTypes = [
      PaymentMethod.CASH,
      PaymentMethod.CARD,
      PaymentMethod.DIRECT_DEBIT,
    ];
    return (paymentMethods || []).filter(
      (i) => !paidImmediately || immediateTypes.includes(i.type)
    );
  };

  const getAvailableInvoicingMethods = (): InvoicingMethodOption[] => {
    const invoicingMethods: InvoicingMethodOption[] = [];
    if (clinic?.fortnoxEnabled) {
      invoicingMethods.push({
        value: InvoicingMethod.FORTNOX,
        label: strings.fortnox,
        sendLabel: strings.sendToFortnox,
      });
    }
    if (clinic?.szamlazzEnabled) {
      invoicingMethods.push({
        value: InvoicingMethod.SZAMLAZZ,
        label: strings.szamlazz,
        sendLabel: strings.sendToSzamlazz,
      });
    }
    invoicingMethods.push({
      value: InvoicingMethod.COST_CALCULATION,
      label: strings.invoicingMethodNone,
    });
    return invoicingMethods;
  };

  const chooseUser = (petAndOwner: ClinicPetAndOwnerResponse) => {
    selectUser(petAndOwner.petOwner.petOwnerDetails);
    setSelectedPet(petAndOwner.pet);
    setSelectedUser(petAndOwner.petOwner);

    if (petAndOwner.pet.clinicPetDetails.insuranceCompany) {
      setValue("company", [petAndOwner.pet.clinicPetDetails.insuranceCompany]);
    }

    setSelectedMedicalRecord(undefined);
  };

  return (
    <>
      <div className="flex">
        <div className="tw-card w-full">
          <div>
            <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
              {strings.customer}
            </h2>
          </div>
          <div className="p-4 space-y-4">
            <div className={selectedUser ? "hidden" : ""}>
              <PetSearchSection
                onSelectPet={(result) => {
                  chooseUser(result);
                }}
              />
            </div>
            <div className={selectedUser ? "" : "hidden"}>
              <SelectedPetOrOwnerCard
                disableClose={!!invoice?.medicalRecordId}
                handleClose={unSelectUser}
                selectedPet={selectedPet}
                selectedPetOwner={selectedUser}
                todoForMedicalRecord={
                  selectedMedicalRecord
                    ? {
                        medicalRecordDateTime:
                          selectedMedicalRecord.medicalRecord.creationDate,
                        medicalRecordId: selectedMedicalRecord.medicalRecord.id,
                        reservationType:
                          selectedMedicalRecord.reservation.reservationType,
                        universalMedicalRecordId:
                          selectedMedicalRecord.medicalRecord.universalId,
                      }
                    : undefined
                }
              />
            </div>
            <BillingForm
              readOnly={!isBillingInfoTheSame}
              paymentMethod={paymentMethod}
              invoicingMethod={invoicingMethod}
            />
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="tw-card mt-4 flex-fill">
          <div>
            <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
              {strings.billingInfo}
            </h2>
          </div>
          <div className="p-4 space-y-4">
            <CheckBox
              name="isBillingInfoTheSame"
              register={register}
              label={strings.sameAsCustomerData}
              defaultChecked
              onChange={() => {
                setIsBillingInfoTheSame(!isBillingInfoTheSame);
              }}
            />
            {!isBillingInfoTheSame ? (
              <BillingForm
                namePostFix="Other"
                paymentMethod={paymentMethod}
                invoicingMethod={invoicingMethod}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="tw-card mt-4 w-full">
        <div>
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
            {strings.payment}
          </h2>
        </div>
        <div className="p-4 space-y-4">
          <CheckBox
            name="payLater"
            register={register}
            label={strings.payLater}
            onChange={() => {
              setPaidImmediately(!paidImmediately);

              if (paymentMethod?.type === PaymentMethod.ONLINE) {
                setPaymentMethod(undefined);
                setValue("paymentMethod", undefined);
              }
            }}
          />
          <div className="w-50">
            <CombinedSelect
              allowNew={false}
              control={control}
              isTypeahead={false}
              label={strings.paymentMethod}
              labelKey="name"
              name="paymentMethod"
              onChange={(value: PaymentMethodResponse[] | null) => {
                setPaymentMethod(value ? value[0] : undefined);
              }}
              options={getAvailablePaymentMethods()}
              required
            />
          </div>
          {!paidImmediately && (
            <div className="w-50">
              <DatePickerInput
                className="mb-0"
                control={control}
                name="dueDate"
                defaultValue={moment().add(30, "day").toDate()}
                label={strings.dueDateForPayment}
              />
            </div>
          )}
        </div>
        <AlertBox
          hidden={!paymentMethods ? true : paymentMethods?.length !== 0}
          message={strings.noPaymentMethods}
          type={AlertType.WARNING}
          className="mx-3 mb-3"
          closeAble={false}
        />
      </div>
      <div className="tw-card mt-4 w-full">
        <div>
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
            {strings.invoice}
          </h2>
        </div>
        <div className="p-4">
          {invoicingMethods.length > 2 && (
            <RadioButtonGroup
              control={control}
              name="invoicingMethodOption"
              options={invoicingMethods}
              onChange={(value) => {
                setInvoicingMethod(value as InvoicingMethod);
              }}
              required
            />
          )}
          {invoicingMethods.length == 2 && (
            <Switch
              control={control}
              name="invoiceNeeded"
              label={invoicingMethods[0].sendLabel}
              order="IL"
              value={true}
              onChange={(checked) => {
                setInvoicingMethod(getDefaultInvoicingMethod(clinic, checked));
              }}
            />
          )}
          {invoicingMethods.length <= 1 && (
            <AlertBox
              message={
                <>
                  <div>{strings.invoicingSystemIsNotIntegrated}</div>
                  <div>
                    {strings.formatString(
                      strings.goTo,
                      <Link target="_blank" to="/clinic-settings/bank">{strings.settings}</Link>
                    )}
                  </div>
                </>
              }
              type={AlertType.INFO}
              closeAble={false}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentAndCustomerInfo;
