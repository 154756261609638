/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { Colors } from "../../models/Colors";
import { strings } from "../../common/Strings/Strings";
import Tooltip from "../Tooltip";
import { InfoFilled } from "../../common/Icons/InfoFilled";

interface FieldProps {
  name: string;
  autoComplete?: AutoCompleteOptions;
  checked?: boolean;
  className?: string;
  error?: string;
  id?: string;
  label?: string;
  onChange?(e: boolean): void;
  onClick?(): void;
  optional?: boolean;
  readOnly?: boolean;
  secondLabel?: string;
  toolTipText?: string | ReactElement;
}

const SimpleCheckBox = ({
  name,
  autoComplete = AutoCompleteOptions.on,
  checked,
  className,
  error,
  id = name,
  label,
  onChange,
  onClick,
  optional = false,
  readOnly = false,
  secondLabel,
  toolTipText,
}: FieldProps): ReactElement => (
  <div className={`${className || ""} ${error ? "has-error" : ""}`}>
    <div className="flex items-center">
      <input
        autoComplete={autoComplete || AutoCompleteOptions.on}
        className="cursor-pointer"
        disabled={readOnly}
        id={id}
        checked={checked}
        name={name}
        type="checkbox"
        readOnly={readOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange && !readOnly) {
            onChange(e.target.checked);
          }
        }}
        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
          if (onClick && !readOnly) {
            e.preventDefault();
            onClick();
          }
        }}
      />
      <label htmlFor={id}>
        {label}
        {optional ? (
          <span style={{ color: Colors.INPUTBORDER }}>
            {" "}
            ({strings.optional})
          </span>
        ) : (
          <></>
        )}

        {secondLabel ? <div>{secondLabel}</div> : <></>}
      </label>
      {toolTipText ? (
        <div
          className="ml-2"
          style={{
            fontSize: "20px",
          }}
        >
          <Tooltip content={toolTipText} placement="top">
            <span>
              <InfoFilled className="text-sky-500" />
            </span>
          </Tooltip>
        </div>
      ) : (
        <></>
      )}
    </div>
    <p>{error || ""}</p>
  </div>
);

export default SimpleCheckBox;
