/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import Calendar from "./OwnerCalendar";
import { strings } from "../../../common/Strings/Strings";
import {
  generateOptions,
  getGeneralError,
  getMonday,
  getSunday,
  localDateFormat,
} from "../../../util/helperFunctions";
import { ShiftForConsultationPeriodsResponse } from "../../../models/shift/ShiftForConsultationPeriodsResponse";
import logger from "../../../util/logger";
import { Option } from "../../../models/Option";
import { Appointment } from "../../../models/calendar/Appointment";
import { ReservationTypeResponse } from "../../../models/reservationtype/ReservationTypeResponse";
import { NewUserReservationRequest } from "../../../models/reservation/NewUserReservationRequest";
import LoaderInline from "../../../components/LoaderInline";
import CloseButton from "../../../components/CloseButton";
import AlertBox from "../../../components/AlertBox";
import UserPetApi from "../../../api/UserPetApi";
import { UserPetResponse } from "../../../models/pet/UserPetResponse";
import ReservationCreationApi from "../../../api/ReservationCreationApi";
import { PetDetailsResponse } from "../../../models/pet/PetDetailsResponse";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import { BasicClinicResponse } from "../../../models/clinic/BasicClinicResponse";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";
import AppointmentDetails from "./Appointments/AppointmentDetails";

interface CreateReservationForm {
  anyVet: boolean;
  collabId?: string;
  petId: string;
  description: string;
  userInformedAboutGdpr: boolean;
}

const defaultFormValues = (
  pet?: PetDetailsResponse
): CreateReservationForm => ({
  anyVet: false,
  collabId: undefined,
  petId: pet?.id.toString() ?? "",
  description: "",
  userInformedAboutGdpr: false,
});

const OwnerCalendarPage = (): ReactElement => {
  const location = useLocation();

  const [pet, setPet] = useState<PetDetailsResponse | undefined>(
    location?.state?.pet?.userPetDetails
  );
  const clinic: BasicClinicResponse = location?.state?.clinic;
  const resType: ReservationTypeResponse = location?.state?.resType;
  const videoConsultation: string = location?.state?.videoConsultation;
  const maxUpcomingWeekRange: number | undefined =
    location?.state?.maxUpcomingWeekRange;

  const navigate = useNavigate();

  const [date, setDate] = useState(new Date());
  const [needReload, setNeedReload] = useState<boolean>(false);

  const [pets, setPets] = useState<UserPetResponse[]>([]);
  const [shifts, setShifts] = useState<ShiftForConsultationPeriodsResponse[]>(
    []
  );
  const [collabs, setCollabs] = useState<Option[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [displayedShifts, setDisplayedShifts] = useState<
    ShiftForConsultationPeriodsResponse[]
  >([]);
  const [reservationPeriod, setReservationPeriod] = useState<
    Appointment | undefined
  >();
  const [availableCollabs, setAvailableCollabs] = useState<Option[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [modalIsOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateReservationForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(pet),
  });

  const [anyVet, collabId, description, userInformedAboutGdpr] = watch([
    "anyVet",
    "collabId",
    "description",
    "userInformedAboutGdpr",
  ]);

  useEffect(() => {
    const getMyPets = async () => {
      try {
        const response = await UserPetApi.getUserPets();
        setPets(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      }
    };

    void getMyPets();
  }, []);

  useEffect(() => {
    const getConsultationPeriods = async (clinicId: string) => {
      const startDate = moment(getMonday(date))
        .set({ hour: 0, minute: 0, second: 0 })
        .toDate();

      const endDate = moment(getSunday(date))
        .set({ hour: 23, minute: 59, second: 59 })
        .toDate();

      try {
        const response = await ReservationCreationApi.getConsultationPeriods(
          clinicId,
          startDate,
          endDate,
          resType.id
        );
        const resultShifts = response.data;
        setShifts(response.data);

        const collaborators = Object.values(
          Object.fromEntries(
            resultShifts.map((s) => s.collaborator).map((c) => [c.userId, c])
          )
        );

        const options: Option[] = generateOptions(
          collaborators,
          "fullName",
          "userId"
        );
        setCollabs(options);
        setIsLoaded(true);
      } catch (err) {
        setError(await getGeneralError(err));
        logger.error(err);
      }
    };

    if (clinic.id) {
      void getConsultationPeriods(clinic.id);
    }
  }, [clinic.id, date, resType.id]);

  useEffect(() => {
    setDisplayedShifts(
      shifts.filter((s) => anyVet || s.collaborator.userId === collabId)
    );
  }, [anyVet, collabId, shifts]);

  useEffect(() => {
    if (reservationPeriod !== undefined) {
      const list: Option[] = [];
      displayedShifts.forEach((s) => {
        if (
          s &&
          moment(s.startTime) <= moment(reservationPeriod.start) &&
          moment(s.endTime) >= moment(reservationPeriod.end)
        ) {
          list.push({ value: s.id, title: s.collaborator.fullName });
        }
      });
      setAvailableCollabs(list);
    }
  }, [displayedShifts, reservationPeriod]);

  const setActualWeek = (newDate: Date) => {
    setDate(newDate);
    setNeedReload(false);
  };

  const findPet = (petId: string): void => {
    pets.forEach(({ userPetDetails: p }) => {
      if (p.id === petId) {
        setPet(p);
      }
    });
  };
 
  const submit = async (formData: CreateReservationForm) => {
    setLoading(true);
    setError(null);

    if (reservationPeriod && reservationPeriod.shiftId && reservationPeriod) {
      const start = moment(reservationPeriod.start).format(localDateFormat());
      const end = moment(reservationPeriod.end).format(localDateFormat());

      const reservation: NewUserReservationRequest = {
        petId: pet?.id || formData.petId,
        timePeriod: { startTime: start, endTime: end },
        reservationTypeId: resType.id,
        description: formData.description,
        isVideoConsultation:
          videoConsultation === "video",
      };

      try {
        await ReservationCreationApi.createUserReservation(
          clinic.id,
          reservationPeriod.shiftId,
          reservation
        );
        navigate("/appointments");
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        closeModal();
        setLoading(false);
      }
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <div className="flex justify-between">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.addAppointment} {pet?.name ? `(${pet?.name})` : ""}
              </h1>
            </div>
            {isLoaded ? (
              <>
                <AlertBox message={error} />
                <form
                  className="w-full md:w-1/2 space-y-6"
                  onSubmit={handleSubmit(submit)}
                  id="createReservationForm"
                >
                  <CheckBox
                    name="anyVet"
                    label={strings.anyVet}
                    register={register}
                  />
                  {!anyVet && (
                    <Select
                      name="collabId"
                      label={strings.chooseADoctor}
                      disabledChooseOptionTitle={strings.chooseDoctor}
                      options={collabs}
                      register={register}
                      error={errors.collabId}
                      required
                    />
                  )}
                  <Select
                    name="petId"
                    label={pet?.name ? strings.pet : strings.chooseAPet}
                    disabledChooseOptionTitle={strings.choosePet}
                    options={pets.map((p) => ({
                      title: p.userPetDetails.name,
                      value: p.userPetDetails.id,
                    }))}
                    register={register}
                    onChange={(e) => findPet(e.target.value)}
                    error={errors.petId}
                    required
                  />
                  <Textarea
                    name="description"
                    label={strings.description}
                    placeholder={strings.writeSymptoms}
                    minRows={3}
                    register={register}
                    error={errors.description}
                    required
                  />
                  <CheckBox
                    name="userInformedAboutGdpr"
                    label={strings.iAcceptThatOrganizationWillReceiveMyData}
                    register={register}
                  />
                </form>
              </>
            ) : (
              <LoaderInline className="my-3" />
            )}
            {!needReload && pet && (anyVet || collabId) ? (
              <>
                <div className="d-none d-lg-block">
                  <Calendar
                    defaultView="timeGridWeek"
                    startDate={date}
                    actualWeekMonday={getMonday(date)}
                    actualWeekSunday={getSunday(date)}
                    shifts={displayedShifts}
                    minTime="07:00:00"
                    maxTime="18:00:00"
                    reservationLength={resType ? resType.minutes : 0}
                    reservationName={resType ? resType.name : ""}
                    maxUpcomingWeekRange={
                      maxUpcomingWeekRange !== undefined
                        ? maxUpcomingWeekRange
                        : 2
                    } // originally 2 was the default value
                    changeWeek={(newDate: Date) => {
                      setNeedReload(true);
                      setActualWeek(newDate);
                    }}
                    onAppointmentChange={setReservationPeriod}
                  />
                </div>
                <div className="d-lg-none">
                  <Calendar
                    defaultView="timeGridDay"
                    startDate={date}
                    actualWeekMonday={getMonday(date)}
                    actualWeekSunday={getSunday(date)}
                    shifts={displayedShifts}
                    minTime="07:00:00"
                    maxTime="18:00:00"
                    reservationLength={resType ? resType.minutes : 0}
                    reservationName={resType ? resType.name : ""}
                    maxUpcomingWeekRange={
                      maxUpcomingWeekRange !== undefined
                        ? maxUpcomingWeekRange
                        : 2
                    }
                    changeWeek={(newDate: Date) => {
                      setNeedReload(true);
                      setActualWeek(newDate);
                    }}
                    onAppointmentChange={setReservationPeriod}
                  />
                </div>
              </>
            ) : needReload ? (
              <LoaderInline className="my-3" />
            ) : null}

            <div className="ml-auto mt-3 mt-sm-0">
              <Button
                variant="primary"
                type="button"
                onClick={() => {
                  openModal();
                }}
                disabled={
                  !(
                    availableCollabs.length > 0 &&
                    userInformedAboutGdpr &&
                    isValid &&
                    reservationPeriod
                  )
                }
              >
                {strings.reserveButtonText}
              </Button>
              <Modal show={modalIsOpen} handleClose={closeModal}>
                <Modal.Header title={strings.addAppointment} />
                <Modal.Body>
                  {reservationPeriod ? (
                    <AppointmentDetails 
                      appointmentEnd={reservationPeriod.end}
                      appointmentStart={reservationPeriod.start}
                      clinicAddress={clinic.address} 
                      clinicName={clinic.name}
                      description={description}
                      isVideoConsultation={videoConsultation === "video"}
                      resType={resType} 
                    />
                  ) : (
                    <></>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <CloseButton onClick={closeModal} />
                  <Button
                    type="submit"
                    form="createReservationForm"
                    variant="primary"
                    disabled={loading}
                  >
                    {loading ? <LoaderInline /> : strings.reserve}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default OwnerCalendarPage;
