/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Link, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { dateAndTime, petTypes, strings } from "../../common/Strings/Strings";
import PrescriptionApi from "../../api/PrescriptionApi";
import { PageProps } from "../../models/PageProps";
import { Loader } from "../../components/Loader";
import ItemList from "./ItemList";
import { NewPrescriptionStatus } from "../../models/ePrescription/response/NewPrescriptionStatus";
import logger from "../../util/logger";
import SpeciesFunctions from "../../common/AnimalSpecies/SpeciesFunctions";
import { HistoryData } from "../../models/ePrescription/history/HistoryData";
import utils from "./utils";
import Paging from "../../components/Paging";
import { useUser } from "../../contexts/UserContext";
import { Tooltip } from "../../components/Tooltip";
import { getGeneralError } from "../../util/helperFunctions";
import AlertBox from "../../components/AlertBox";
import { useSpecies } from "../../contexts/SpeciesContext";
import PageSizeSelect from "../../components/PageSizeSelect";
import CheckBox from "../../components/ReactHookFormFields/General/CheckBox";
import Button from "../../components/Button";
import { ChevronUp } from "../../common/Icons/ChevronUp";
import { ChevronDown } from "../../common/Icons/ChevronDown";
import { CheckCircle } from "../../common/Icons/CheckCircle";
import { ExclamationMark } from "../../common/Icons/ExclamationMark";
import EmptyListText from "../../components/EmptyListText";
import { DocumentText } from "../../common/Icons/DocumentText";
import Tag from "../../components/Tag";
import { useClinic } from "../../contexts/ClinicContext";

interface StringMap {
  [key: string]: boolean;
}

interface FilterForm {
  accepted: boolean;
  warning: boolean;
  failed: boolean;
}

const PrescriptionHistoryMain: React.FC<PageProps> = (props: PageProps) => {
  const { setPageLoading } = props;

  const { user } = useUser();

  const [prescriptions, setPrescriptions] = useState<HistoryData[]>([]);
  const [totalPrescriptions, setTotalPrescriptions] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);
  const [isCollapseOpenList, setIsCollapseOpenList] = useState<StringMap>({});
  const [reload, setReload] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { register, getValues } = useForm<FilterForm>({
    defaultValues: { accepted: true, warning: true, failed: false },
  });

  const refIcon = useRef(null);

  const location = useLocation();

  const { speciesCodesAndNames } = useSpecies();

  const params = useParams<"petId" | "prescriptionId">();
  const { prescriptionId } = params;
  const isClinicPage = !location.pathname.includes("mypets");
  const petId = params.petId;
  const { clinic } = useClinic();

  useEffect(() => {
    setPageLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, page]);

  const getFilters = (): NewPrescriptionStatus[] | undefined => {
    const { accepted, failed, warning } = getValues();
    const result: NewPrescriptionStatus[] = [];

    if (accepted) {
      result.push(NewPrescriptionStatus.ACCEPTED);
    }
    if (failed) {
      result.push(NewPrescriptionStatus.FAILED);
    }
    if (warning) {
      result.push(NewPrescriptionStatus.WARNINGS);
    }

    return result.length === 0 ? undefined : result;
  };

  const getPrescriptionsOfClinic = async (clinicId: string) => {
    setLoading(true);
    const filters = getFilters();

    try {
      const response = await PrescriptionApi.getPrescriptionsOfClinic(
        clinicId,
        petId,
        page,
        pageSize,
        undefined,
        filters
      );
      setPrescriptions(response.data.elements);
      setTotalPrescriptions(response.data.totalElements);
    } catch (err) {
      setError(await getGeneralError(err));
      logger.error(err);
    } finally {
      setPageLoading(false);
      setLoading(false);
    }
  };

  const getPrescriptionById = async (id: string) => {
    try {
      const response = await PrescriptionApi.getPrescriptionByMongoId(id);
      setPrescriptions([response.data]);
      setTotalPrescriptions(1);
    } catch (err) {
      logger.error(err);
    } finally {
      setPageLoading(false);
    }
  };

  const getPrescriptionsOfMyPets = async () => {
    const filters = getFilters();

    try {
      const response = await PrescriptionApi.getMyPetsPrescriptions(
        page,
        pageSize,
        filters
      );
      setPrescriptions(response.data.elements);
      setTotalPrescriptions(response.data.totalElements);
    } catch (err) {
      logger.error(err);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (prescriptionId) {
      void getPrescriptionById(prescriptionId);
    } else if (isClinicPage && clinic?.id) {
      void getPrescriptionsOfClinic(clinic.id);
    } else {
      void getPrescriptionsOfMyPets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, reload, page, pageSize]);

  const statusIcon = (status: NewPrescriptionStatus) => {
    let icon = (
      <Tooltip content={strings.successfulPrescription} placement="right">
        <CheckCircle className="text-green-500" />
      </Tooltip>
    );

    switch (status) {
      case NewPrescriptionStatus.FAILED:
        icon = (
          <Tooltip content={strings.failedPrescription} placement="right">
            <ExclamationMark className="text-red-500" />
          </Tooltip>
        );
        break;
      case NewPrescriptionStatus.WARNINGS:
        icon = (
          <Tooltip content={strings.warningPrescription} placement="right">
            <CheckCircle className="text-orange-500" />
          </Tooltip>
        );
        break;
      default:
        break;
    }

    return icon;
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <div className="flex justify-between">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {`${strings.prescriptions}${
                  location?.state?.petName
                    ? ` (${location?.state?.petName})`
                    : ""
                }`}
              </h1>
              <div className="ml-auto">
                {isClinicPage ? (
                  <div className="ml-auto flex mb-3">
                    {petId ? (
                      <>
                        <div className="mr-3">
                          <Link to="/prescription">
                            <Button>{strings.newPrescription}</Button>
                          </Link>
                        </div>
                        <div>
                          <Link
                            to={`/prescription/${petId}`}
                            state={{
                              petId,
                            }}
                          >
                            <Button variant="primary">
                              {strings.newPrescription} (
                              {location?.state?.petName})
                            </Button>
                          </Link>
                        </div>
                      </>
                    ) : (
                      <div>
                        <Link to="/prescription">
                          <Button variant="primary">
                            {strings.newPrescription}
                          </Button>
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <form>
              <div className="flex flex-wrap items-center">
                <div className="mr-3">
                  <CheckBox
                    defaultChecked
                    label={strings.acceptedPrescriptions}
                    name="accepted"
                    onChange={() => {
                      setReload(!reload);
                    }}
                    register={register}
                  />
                </div>
                <div className="mr-3">
                  <CheckBox
                    defaultChecked
                    label={strings.prescriptionsWithWarnings}
                    name="warning"
                    onChange={() => {
                      setReload(!reload);
                    }}
                    register={register}
                  />
                </div>
                <div className="mr-3" hidden={!isClinicPage}>
                  <CheckBox
                    label={strings.failedPrescriptions}
                    name="failed"
                    onChange={() => {
                      setReload(!reload);
                    }}
                    register={register}
                  />
                </div>
              </div>
            </form>
            <AlertBox message={error} className="mb-3" />
            <div className="tw-table-container" ref={refIcon}>
              <table className="tw-table">
                <thead className="tw-thead">
                  <tr>
                    <th className="tw-th" />
                    <th className="tw-th">{strings.date}</th>
                    <th className="text-left tw-th">{strings.pet}</th>
                    <th className="text-left tw-th">{strings.owner}</th>
                    <th className="text-left tw-th">{strings.vet}</th>
                    <th className="text-left tw-th">
                      {strings.commentToPharmacist}
                    </th>
                    <th className="text-left tw-th">{strings.status}</th>
                    <th className="text-left tw-th">{strings.medicalRecord}</th>
                    <th className="text-right tw-th">&nbsp;</th>
                  </tr>
                </thead>
                <tbody className="tw-tbody">
                  {prescriptions.length === 0 ? (
                    <tr>
                      <td
                        className="px-4 py-4 text-sm whitespace-nowrap"
                        colSpan={9}
                      >
                        <EmptyListText />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {prescriptions
                        ?.sort(
                          (p2, p) =>
                            moment(p.creationTimestamp).valueOf() -
                            moment(p2.creationTimestamp).valueOf()
                        )
                        .map((p: HistoryData, index: number) => (
                          <Fragment key={index}>
                            <tr
                              onClick={() => {
                                setIsCollapseOpenList({
                                  ...isCollapseOpenList,
                                  [p.prescriptionSetUuid]:
                                    !isCollapseOpenList[p.prescriptionSetUuid],
                                });
                              }}
                            >
                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                <div className="flex">
                                  <Button variant="basic">
                                    {isCollapseOpenList[
                                      p.prescriptionSetUuid
                                    ] ? (
                                      <ChevronUp />
                                    ) : (
                                      <ChevronDown />
                                    )}
                                  </Button>
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                {moment
                                  .unix(p.creationTimestamp)
                                  .format(dateAndTime.momentDateTimeFormat)}
                              </td>
                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                <b>
                                  {p.model.animal
                                    ? utils.getAnimalIds(p.model.animal)
                                    : ""}
                                </b>
                                <div>
                                  {SpeciesFunctions.getAnimalStringFromCode(
                                    speciesCodesAndNames,
                                    p.model.animal.speciesCode
                                  )}
                                  , {petTypes[p.model.animal.animalIdType]}
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                <b>
                                  {p.model.animalCarer
                                    ? utils.getOwnerName(p.model.animalCarer)
                                    : ""}
                                </b>
                                <div>
                                  {p.model.animalCarer
                                    ? utils.getOwnerId(p.model.animalCarer)
                                    : ""}
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                <b>
                                  {p.model.veterinaryInformation
                                    ? `${p.model.veterinaryInformation.givenName} ${p.model.veterinaryInformation.familyName}`
                                    : ""}
                                </b>
                                <div>
                                  {p.model.veterinaryInformation
                                    ? `${strings.registrationNumber}: ${p.model.veterinaryInformation.veterinaryId}`
                                    : ""}
                                </div>
                              </td>
                              <td className="px-4 py-4 text-sm">
                                {p.model.prescription.comment}
                              </td>
                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                {p.cancellations.length > 0 ? (
                                  <Tag text={strings.withdrawn} />
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                {p.model.medicalRecordId ? (
                                  <Link
                                    to={`/medical-record/${p.model.medicalRecordId}/latest`}
                                  >
                                    <Button variant="icon">
                                      <DocumentText />
                                    </Button>
                                  </Link>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="px-4 py-4 text-sm whitespace-nowrap">
                                {statusIcon(
                                  p.creation.data.internalResponse.result
                                )}
                              </td>
                            </tr>
                            <tr
                              className="border-t-0"
                              hidden={
                                !isCollapseOpenList[p.prescriptionSetUuid]
                              }
                            >
                              <td />
                              <td
                                colSpan={7}
                                className="px-4 pb-4 text-sm whitespace-nowrap"
                              >
                                <ItemList
                                  activePrescription={p.model.prescription}
                                  historyData={p}
                                  triggerReload={() => {
                                    setReload(!reload);
                                  }}
                                  status={
                                    p.creation.data.internalResponse?.result !==
                                    NewPrescriptionStatus.ACCEPTED
                                      ? p.creation.data.internalResponse
                                      : undefined
                                  }
                                  withdrawalAllowed={
                                    isClinicPage &&
                                    user?.vetProfession?.state === "APPROVED"
                                  }
                                />
                              </td>
                              <td />
                            </tr>
                          </Fragment>
                        ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex items-center">
              {loading ? (
                <></>
              ) : (
                <PageSizeSelect
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  totalPages={
                    prescriptions.length > 0
                      ? Math.ceil(totalPrescriptions / pageSize)
                      : 1
                  }
                  totalResults={totalPrescriptions}
                />
              )}
              <div className="ml-auto">
                <Paging
                  totalPages={
                    totalPrescriptions > 0
                      ? Math.ceil(totalPrescriptions / pageSize)
                      : 0
                  }
                  selectPage={(newPage: number) => {
                    setPage(newPage);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(PrescriptionHistoryMain);
