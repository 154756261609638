/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { forwardRef, Fragment, ReactElement } from "react";
import { Link } from "react-router-dom";
import {
  petSpecies,
  quantityUnits,
  strings,
} from "../../common/Strings/Strings";
import CostCalculator from "../../util/CostCalculator";
import NumberFormatter from "../../util/NumberFormatter";
import { Tooltip } from "../../components/Tooltip";
import { InventoryItemResponse } from "../../models/inventory/item/Response/InventoryItemResponse";
import { PetSpecies } from "../../models/pet/PetSpeciesEnum";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import { ClinicResponse } from "../../models/clinic/ClinicResponse";
import Button from "../../components/Button";
import { CloudArrowUp } from "../../common/Icons/CloudArrowUp";
import { ClipboardDocumentCheck } from "../../common/Icons/ClipboardDocumentCheck";
import { calculateProfitMargin } from "./Details/InventoryItemDetails";

interface Props {
  clinic?: ClinicResponse;
  countryDetails?: CountryDetailsResponse;
  item: InventoryItemResponse;
  openRefillStock(item: InventoryItemResponse): void;
}

export interface InventoryItemRowRef {
  resetForm(): void;
}

const InventoryItemRow = forwardRef(
  ({ item, clinic, countryDetails, openRefillStock }: Props): ReactElement => {
    return (
      <>
        <td className="px-3 py-2 text-sm font-medium">{item.name}</td>
        <td className="px-3 py-2 text-sm font-medium">{item.barcode}</td>
        <td className="px-3 py-2 text-sm font-medium">
          {item.species?.map((s: PetSpecies, index: number) => (
            <Fragment key={index}>
              {petSpecies[s]}
              {item.species && item.species[index + 1] ? ", " : ""}
            </Fragment>
          ))}
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          <span
            className={
              item.stock !== undefined &&
              item.lowLevel !== undefined &&
              item.stock <= item.lowLevel
                ? "text-red-600"
                : ""
            }
          >
            {NumberFormatter.format(item.stock || 0)}{" "}
            {item?.acquisitionUnit && quantityUnits[item?.acquisitionUnit]}
          </span>
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          <div className="flex space-x-3 items-center">
            <Tooltip content={strings.refillStock}>
              <Button
                variant="icon"
                onClick={() => {
                  openRefillStock(item);
                }}
              >
                <CloudArrowUp />
              </Button>
            </Tooltip>
            <Link to={`/inventory/item/${item.id}/counting-stock`}>
              <Tooltip content={strings.countInventory}>
                <Button variant="icon">
                  <ClipboardDocumentCheck />
                </Button>
              </Tooltip>
            </Link>
          </div>
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          {NumberFormatter.formatPrice(
            CostCalculator.getGrossPriceOfItem(
              {
                ...item,
                quantity: 1,
                vat: item.acquisitionVat,
                price: item.acquisitionPrice || item.acquisitionPrice || 0,
              },
              countryDetails,
              clinic
            ),
            item.acquisitionCurrency
          )}
          <div className="text-xs text-gray-500">
            {NumberFormatter.formatPrice(
              item.acquisitionPrice || 0,
              item.acquisitionCurrency
            )}{" "}
            {strings.plusVat}
          </div>
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          {NumberFormatter.formatPrice(
            CostCalculator.getGrossPriceOfItem(
              {
                ...item,
                quantity: 1,
                vat: item.sellingVat,
                price: item.sellingPrice || item.sellingPrice || 0,
              },
              countryDetails,
              clinic
            ),
            item.sellingCurrency
          )}
          <div className="text-xs text-gray-500">
            {NumberFormatter.formatPrice(
              item.sellingPrice || 0,
              item.sellingCurrency
            )}{" "}
            {strings.plusVat}
          </div>
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          {NumberFormatter.formatPrice(calculateProfitMargin(item.acquisitionPrice, item.sellingPrice))} %
        </td>
        <td className="px-3 py-2 text-sm font-medium whitespace-nowrap">
          {item.accountingCode}
        </td>
      </>
    );
  }
);

export default InventoryItemRow;
