/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Route, Routes } from "react-router-dom";
import LocationIndicator from "../../../components/Tabs/LocationIndicator";
import NotFound from "../../NotFound";
import { SettingsTabs } from "../../../models/user/SettingsTabs";
import ChangePassword from "./ProfileSettingsTabs/SecurityTab/ChangePassword";
import BasicInfoTab from "./ProfileSettingsTabs/BasicInfoTab/BasicInfoTab";
import ContactsTab from "./ProfileSettingsTabs/ContactsTab";
import SecurityTab from "./ProfileSettingsTabs/SecurityTab/SecurityTab";
import NotificationsTab from "./ProfileSettingsTabs/NotificationsTab";
import VetTab from "./ProfileSettingsTabs/VetTab/VetTab";

interface Props {
  setCurrentTab: (tab: SettingsTabs) => void;
}

const ProfileSettingsTabs: React.FC<Props> = ({ setCurrentTab }: Props) => (
  <Routes>
    <Route
      path="/"
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.basicInfo}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <BasicInfoTab />
        </>
      }
    />
    <Route
      path={SettingsTabs.contacts}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.contacts}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <ContactsTab />
        </>
      }
    />
    <Route
      path={SettingsTabs.security}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.security}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <SecurityTab />
        </>
      }
    />
    <Route
      path={SettingsTabs.security}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.security}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <ChangePassword />
        </>
      }
    />
    <Route
      path={SettingsTabs.notifications}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.notifications}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <NotificationsTab />
        </>
      }
    />
    <Route
      path={SettingsTabs.vet}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.vet}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <VetTab />
        </>
      }
    />
    {/*
    <Route
      path={SettingsTabs.linkedAccounts}
      element={
        <>
          <LocationIndicator
            location={SettingsTabs.linkedAccounts}
            setLocation={(location: SettingsTabs) => {
              setCurrentTab(location);
            }}
          />
          <LinkedAccountsTab />
        </>
      }
    />
     */}
    <Route element={<NotFound />} />
  </Routes>
);

export default ProfileSettingsTabs;
