/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment/moment";
import { strings } from "../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../util/helperFunctions";
import AlertBox from "../../../../components/AlertBox";
import { Card } from "../../../../components/Cards/Card";
import Button from "../../../../components/Button";
import UserProfilePictureSettings from "../../../../components/Pictures/User/UserProfilePictureSettings";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { BasicInformationRequest } from "../../../../models/user/BasicInformationRequest";
import UserApi from "../../../../api/UserApi";
import { useUser } from "../../../../contexts/UserContext";
import DatePickerInput from "../../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import { fullYearSwedishPersonalId } from "../../../../util/Validations";
import { CountryResponse } from "../../../../models/management/CountryResponse";
import CountryApi from "../../../../api/CountryApi";
import logger from "../../../../util/logger";

interface Props {
  onNextStep(skip: boolean): void;
  skippable?: boolean;
}

interface ProfileSetupForm {
  prefix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  dateOfBirth?: Date;
  idNumber?: string;
}

const ProfileSetup = ({
  onNextStep,
  skippable = false,
}: Props): ReactElement => {
  const [userCountry, setUserCountry] = useState<CountryResponse>();
  const { user, setUser } = useUser();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileSetupForm>({
    mode: "onChange",
  });

  const submit = async (data: ProfileSetupForm) => {
    setLoading(true);
    setError(null);

    const request: BasicInformationRequest = {
      dateOfBirth: moment(data.dateOfBirth).format("YYYY-MM-DD"),
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: !data.middleName ? undefined : data.middleName,
      personalId: !data.idNumber ? undefined : data.idNumber,
      prefix: !data.prefix ? undefined : data.prefix,
    };

    try {
      const response = await UserApi.updateBasicInfo(request);
      setUser(response.data);
      onNextStep(false);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const resp = await CountryApi.getCountries();

        if (user.primaryAddress?.countryCode) {
          const userCountry = resp.data.filter(
            (c) => c.countryCode === user.primaryAddress?.countryCode
          )[0];
          setUserCountry(userCountry);
        }
      } catch (e) {
        logger.error(e);
      }
    };

    if (user.primaryAddress?.countryCode) {
      void getCountries();
    }
  }, [user.primaryAddress?.countryCode]);

  return (
    <Card
      footerText={strings.setupYourProfileLater}
      onAction={skippable ? () => onNextStep(true) : undefined}
      title={strings.yourProfile}
    >
      <UserProfilePictureSettings />
      <form className="space-y-6" onSubmit={handleSubmit(submit)}>
        <Field
          error={errors.prefix}
          name="prefix"
          label={strings.prefix}
          placeholder={strings.prefixPlaceholder}
          register={register}
        />
        <Field
          error={errors.firstName}
          name="firstName"
          label={strings.firstName}
          register={register}
          required
          showRequired
        />
        <Field
          error={errors.middleName}
          name="middleName"
          register={register}
          label={strings.middleName}
        />
        <Field
          error={errors.lastName}
          name="lastName"
          label={strings.lastName}
          register={register}
          required
          showRequired
        />
        <DatePickerInput
          control={control}
          label={strings.dateOfBirth}
          name="dateOfBirth"
        />
        <div hidden={!userCountry?.isPersonalIdUsed}>
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.idNumber}
            name="idNumber"
            fieldOptions={{
              pattern: {
                value: fullYearSwedishPersonalId.matchRegexp,
                message: strings.formatString(
                  strings.invalidFormat,
                  strings.personalIdFormatPlaceholder
                ),
              },
              validate: {
                req: (v: string) => {
                  if (userCountry?.isPersonalIdUsed) {
                    return v.length > 0;
                  }
                  return true;
                },
              },
            }}
            label={strings.personalId}
            maxLength={12}
            placeholder={strings.personalIdFormatPlaceholder}
            register={register}
          />
        </div>
        <AlertBox message={error} />
        <Button disabled={loading} loading={loading} type="submit">
          {strings.saveAndContinue}
        </Button>
      </form>
    </Card>
  );
};

export default ProfileSetup;
