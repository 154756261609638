/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import Field from "../../ReactHookFormFields/General/Field";
import { strings } from "../../../common/Strings/Strings";
import SelectCountry from "../../ReactHookFormFields/Specific/SelectCountry";
import { CountryResponse } from "../../../models/management/CountryResponse";
import { AddressRequest } from "../../../models/contact/AddressRequest";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";

export interface CreateSimpleAddressForm
  extends Pick<AddressRequest, "city" | "street" | "houseDetails" | "zip"> {
  countries: CountryResponse[];
}

const AddressForm = (): ReactElement => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<CreateSimpleAddressForm>();

  return (
    <form className="space-y-6">
      <Field
        name="street"
        register={register}
        autoComplete={AutoCompleteOptions.addressLine1}
        error={errors.street}
        label={strings.addressLine1}
        required
        showRequired
      />
      <Field
        name="houseDetails"
        register={register}
        autoComplete={AutoCompleteOptions.addressLine2}
        error={errors.houseDetails}
        label={strings.addressLine2}
        optional
      />
      <Field
        name="city"
        register={register}
        autoComplete={AutoCompleteOptions.off}
        error={errors.city}
        label={strings.city}
        required
        showRequired
      />
      <SelectCountry control={control} name="countries" required showRequired />
      <Field
        name="zip"
        register={register}
        autoComplete={AutoCompleteOptions.postalCode}
        error={errors.zip}
        label={strings.postalCode}
        required
        showRequired
        type="number"
      />
    </form>
  );
};

export default AddressForm;
