/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import {
  DoctorMenuItem,
  PetOwnerMenuItem,
  SiteManagerMenuItem,
} from "../MenuItem/MenuItemTypes";
import { NavMenuLinkClasses } from "../Themes/NavTheme";

interface Props {
  icon: ReactNode;
  menu: DoctorMenuItem | PetOwnerMenuItem | SiteManagerMenuItem | "MORE";
  name: string;
  activeMenu?: DoctorMenuItem | PetOwnerMenuItem | SiteManagerMenuItem;
  closeMoreMenu?(): void;
  href?: string;
  isInMore?: boolean;
  onClick?(): void;
  visible?: boolean;
}

const FooterMenuItem: React.FC<Props> = ({
  icon,
  menu,
  name,
  activeMenu,
  closeMoreMenu,
  href,
  isInMore = false,
  onClick,
  visible = true,
}: Props) => {
  const navigate = useNavigate();

  const activeMenuClass = isInMore
    ? NavMenuLinkClasses.mobileMore.active
    : NavMenuLinkClasses.mobile.active;
  const simpleMenuClass = isInMore
    ? NavMenuLinkClasses.mobileMore.simple
    : NavMenuLinkClasses.mobile.simple;

  const isActive = menu === activeMenu;
  const menuClass = isActive ? activeMenuClass : simpleMenuClass;

  const handleClick = () => {
    href && navigate(href);
    onClick && onClick();
    closeMoreMenu && closeMoreMenu();
  };

  if (!visible) {
    return null;
  }

  if (!isInMore) {
    return (
      <button
        className="relative flex flex-col items-center space-y-1.5"
        onClick={handleClick}
        type="button"
      >
        <div className={menuClass}>{icon}</div>
        <span className="absolute top-6 whitespace-nowrap text-xs text-gray-500 dark:text-gray-400">
          {name}
        </span>
      </button>
    );
  }

  return (
    <button
      className="py-3 flex items-center space-x-4"
      onClick={handleClick}
      type="button"
    >
      <div className={menuClass}>{icon}</div>
      <span className="text-gray-500 dark:text-gray-400">{name}</span>
    </button>
  );
};

export default FooterMenuItem;
