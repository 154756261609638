/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { Pencil } from "../../../common/Icons/Pencil";
import { InventoryItemResponse } from "../../../models/inventory/item/Response/InventoryItemResponse";
import {
  petSpecies,
  quantityUnits,
  strings,
} from "../../../common/Strings/Strings";
import IconBubble from "../../../components/IconBubble";
import { PetSpecies } from "../../../models/pet/PetSpeciesEnum";
import Tag from "../../../components/Tag";
import CostCalculator from "../../../util/CostCalculator";
import NumberFormatter from "../../../util/NumberFormatter";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { Trash } from "../../../common/Icons/Trash";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import { SearchInventoryItems } from "../../../models/apiparams/SearchInventoryItems";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { useClinic } from "../../../contexts/ClinicContext";

interface Props {
  countryDetails?: CountryDetailsResponse;
  inventoryItem: InventoryItemResponse;
}

export const calculateProfitMargin = (
  acquisition: number | undefined | null,
  sale: number | undefined | null
): number => {
  const a = acquisition || 0;
  const s = sale || 0;

  if (a && s) {
    return 100 - (a * 100) / s;
  } else if (a === 0 && s === 0) {
    return 0;
  } else if (a === 0) {
    return 100;
  } else return 0;
};

const InventoryItemDetails: React.FC<Props> = ({
  countryDetails,
  inventoryItem,
}) => {
  const [aNetPrice, setAcquisitionNetPrice] = useState<number>(0);
  const [aGrossPrice, setAcquisitionGrossPrice] = useState<number>(0);
  const [sNetPrice, setSellingNetPrice] = useState<number>(0);
  const [sGrossPrice, setSellingGrossPrice] = useState<number>(0);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [categoryUrl, setCategoryUrl] = useState<SearchInventoryItems>();

  const navigate = useNavigate();
  const { clinic } = useClinic();

  useEffect(() => {
    if (inventoryItem && inventoryItem?.categoryId) {
      setCategoryUrl({
        pageNumber: 0,
        pageSize: 50,
        query: "",
        stockFilter: "ALL",
        categoryId: inventoryItem?.categoryId,
      });
    }
  }, [inventoryItem]);

  useEffect(() => {
    setAcquisitionNetPrice(inventoryItem?.acquisitionPrice || 0);
    setAcquisitionGrossPrice(
      CostCalculator.getGrossPriceOfItem(
        {
          ...inventoryItem,
          quantity: 1,
          vat: inventoryItem?.acquisitionVat,
          price: inventoryItem?.acquisitionPrice || 0,
        },
        countryDetails,
        clinic || undefined
      )
    );
    setSellingNetPrice(inventoryItem?.sellingPrice || 0);
    setSellingGrossPrice(
      CostCalculator.getGrossPriceOfItem(
        {
          ...inventoryItem,
          quantity: 1,
          vat: inventoryItem?.sellingVat,
          price: inventoryItem?.sellingPrice || 0,
        },
        countryDetails,
        clinic || undefined
      )
    );
  }, [inventoryItem]);

  const deleteItem = async (id: string) => {
    setDeleteLoading(true);

    try {
      await InventoryItemApi.removeItem(id);
      navigate("/inventory");
    } catch (e) {
      setDeleteError(await getGeneralError(e));
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="w-full md:tw-card space-y-6 md:p-8 my-6">
      <div className="flex items-center flex-wrap md:flex-no-wrap">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {inventoryItem?.name}
        </h2>
        <div className="ml-3">
          <IconBubble>
            {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
          </IconBubble>
        </div>
        <div className="ml-3 md:ml-auto space-x-3">
          <Button
            askAreYouSure={{
              enable: true,
              content: strings.formatString(
                strings.areYouSureDeleteItem,
                <b>{inventoryItem?.name}</b>
              ),
              title: strings.deletingItem,
            }}
            disabled={deleteLoading}
            loading={deleteLoading}
            variant="icon"
            onClick={() => {
              void deleteItem(inventoryItem?.id);
            }}
          >
            <Trash />
          </Button>
          <Link to={`/inventory/item/${inventoryItem?.id}/edit`}>
            <Button variant="icon" className="justify-center items-center">
              <Pencil />
            </Button>
          </Link>
        </div>
      </div>
      <AlertBox message={deleteError} />
      <div>
        <div>
          {strings.category}:{" "}
          <Link
            to={`/inventory?${encodeURIComponent(JSON.stringify(categoryUrl))}`}
          >
            {inventoryItem?.categoryName}
          </Link>
        </div>
        <div>
          {strings.barcode}:{" "}
          <span className="font-bold">{inventoryItem?.barcode}</span>
        </div>
        <div>
          {strings.lowLevel}:{" "}
          <span className="font-bold">
            {NumberFormatter.format(inventoryItem?.lowLevel || 0)}{" "}
            {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
          </span>
        </div>
        <div>
          {strings.accountingCode}:{" "}
          <span className="font-bold">{inventoryItem?.accountingCode}</span>
        </div>
        <div className="space-x-1">
          {inventoryItem?.species?.map((s: PetSpecies, index: number) => (
            <span key={index}>
              <Tag text={petSpecies[s]} />
            </span>
          ))}
        </div>
      </div>
      <div>
        <div className="tw-table-container lg:w-1/2">
          <table className="tw-table">
            <thead className="tw-thead">
              <tr>
                <th className="tw-th" />
                <th className="tw-th">{strings.vatAsTax}</th>
                <th className="tw-th">{strings.net}</th>
                <th className="tw-th">{strings.gross}</th>
              </tr>
            </thead>
            <tbody className="tw-tbody">
              <tr>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {strings.acquisitionPrice}
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {inventoryItem?.acquisitionVat} %
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    aNetPrice,
                    inventoryItem?.acquisitionCurrency
                  )}{" "}
                  / {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    aGrossPrice,
                    inventoryItem?.acquisitionCurrency
                  )}{" "}
                  / {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                </td>
              </tr>
              <tr>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {strings.salePrice}
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {inventoryItem?.sellingVat} %
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    sNetPrice,
                    inventoryItem?.sellingCurrency
                  )}{" "}
                  / {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                </td>
                <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    sGrossPrice,
                    inventoryItem?.acquisitionCurrency
                  )}{" "}
                  / {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                </td>
              </tr>
              <tr className="border-t-2">
                <td className="py-3.5 px-4 text-sm font-bold whitespace-nowrap">
                  {strings.profit}
                </td>
                <td />
                <td className="py-3.5 px-4 text-sm font-bold whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    (inventoryItem?.sellingPrice || 0) -
                      (inventoryItem?.acquisitionPrice || 0),
                    inventoryItem?.sellingCurrency
                  )}{" "}
                  / {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                </td>
                <td className="py-3.5 px-4 text-sm font-bold whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    CostCalculator.getGrossPriceOfItem(
                      {
                        ...inventoryItem,
                        quantity: 1,
                        vat: inventoryItem?.sellingVat,
                        price:
                          (inventoryItem?.sellingPrice || 0) -
                          (inventoryItem?.acquisitionPrice || 0),
                      },
                      countryDetails,
                      clinic || undefined
                    ),
                    inventoryItem?.acquisitionCurrency
                  )}{" "}
                  / {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                </td>
              </tr>
              <tr>
                <td className="py-3.5 px-4 text-sm font-bold whitespace-nowrap">
                  {strings.profitMargin}
                </td>
                <td />
                <td className="py-3.5 px-4 text-sm font-bold whitespace-nowrap">
                  {NumberFormatter.formatPrice(
                    calculateProfitMargin(aNetPrice, sNetPrice)
                  )}{" "}
                  %
                </td>
                <td className="py-3.5 px-4 text-sm font-bold whitespace-nowrap" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InventoryItemDetails;
