/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import PrescriptionApi from "../../../../api/PrescriptionApi";
import UserApi from "../../../../api/UserApi";
import { strings } from "../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import LoaderInline from "../../../../components/LoaderInline";
import CodeInputsForm from "../../../../components/ReactHookFormFields/Specific/CodeInputsForm";
import { useUser } from "../../../../contexts/UserContext";
import { CommodityPrescriptionItemFrontend } from "../../../../models/ePrescription/local/CommodityPrescriptionItemFrontend";
import { FeeType } from "../../../../models/ePrescription/local/FeeType";
import { LicensedPrescriptionItemFrontend } from "../../../../models/ePrescription/local/LicensedPrescriptionItemFrontend";
import { PrescriptionItemFrontend } from "../../../../models/ePrescription/local/PrescriptionItemFrontend";
import { ExtendedDelivery } from "../../../../models/ePrescription/persistence/ExtendedDelivery";
import { PrescriptionStatus } from "../../../../models/ePrescription/response/PrescriptionStatus";
import { Animal } from "../../../../models/ePrescription/sending/Animal";
import { AnimalCarer } from "../../../../models/ePrescription/sending/AnimalCarer";
import { Pharmacy } from "../../../../models/pharmacy/Pharmacy";
import { getGeneralError } from "../../../../util/helperFunctions";
import PrescriptionSummary from "./PrescriptionSummary";
import requiredFieldChecks from "./sendingRequestProcesser";
import { useClinic } from "../../../../contexts/ClinicContext";
import { PrescriptionResponseModel } from "../../../../models/ePrescription/response/PrescriptionResponseModel";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal/Modal";
import CloseButton from "../../../../components/CloseButton";
import UserProfilePicture from "../../../../components/Pictures/User/UserProfilePicture";

interface Props {
  isOpen: boolean;
  close(): void;
  animal?: Animal;
  animalCarer?: AnimalCarer;
  prescription?: PrescriptionItemFrontend[];
  licensedPrescription?: LicensedPrescriptionItemFrontend[];
  commodityPrescription?: CommodityPrescriptionItemFrontend[];
  instructionLanguage?: string;
  delivery?: ExtendedDelivery;
  receiverPharmacy?: Pharmacy;
  comment?: string;
  medicalRecordId?: string;
  selectedFeeType: FeeType;
  feeAmount: number;
}

const FinishModal: React.FC<Props> = ({
  isOpen,
  close,
  animal,
  animalCarer,
  prescription,
  licensedPrescription,
  commodityPrescription,
  instructionLanguage,
  delivery,
  receiverPharmacy,
  comment,
  medicalRecordId,
  selectedFeeType,
  feeAmount,
}: Props) => {
  const { user: vet } = useUser();

  const [secondScreen, setSecondScreen] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined | null>();
  const [success, setSuccess] = useState<string | undefined | null>();
  const [statusList, setStatusList] = useState<
    PrescriptionStatus[] | undefined | null
  >([]);
  const [isSendingLoading, setIsSendingLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const { clinic } = useClinic();

  const sendPrescription = async () => {
    if (animalCarer && animal && vet && clinic) {
      const request = requiredFieldChecks.generatePrescriptionRequest(
        clinic.id,
        prescription,
        licensedPrescription,
        commodityPrescription,
        comment,
        instructionLanguage,
        animal,
        animalCarer,
        delivery,
        receiverPharmacy,
        vet,
        selectedFeeType,
        feeAmount,
        clinic,
        medicalRecordId
      );

      try {
        const response = await PrescriptionApi.sendPrescription(request);
        setSuccess(response.data.message);
        setStatusList(response.data.statusList);
        setSecondScreen(true);
      } catch (err) {
        const typedErr = err as AxiosError<PrescriptionResponseModel>;
        setError(
          typedErr?.response?.data?.message ||
            (await getGeneralError(typedErr)) ||
            strings.simpleError
        );
        setStatusList(typedErr?.response?.data?.statusList);
      } finally {
        setIsSendingLoading(false);
      }
    } else {
      setIsSendingLoading(false);
    }
  };

  const checkPin = async (pin: string) => {
    setError(null);
    setSuccess(null);
    setStatusList(null);

    if (!pin) {
      setError(strings.simpleError);
      return;
    }

    setIsSendingLoading(true);

    try {
      await UserApi.validatePinCode({ pinCode: pin });
      void sendPrescription();
    } catch (e) {
      setError(await getGeneralError(e));
      setIsSendingLoading(false);
    }
  };

  const closeModal = () => {
    setError(null);
    setSuccess(null);
    setStatusList(null);
    setIsSendingLoading(false);
    close();
  };

  const finish = () => {
    if (!medicalRecordId && feeAmount > 0) {
      navigate(`/sales`);
    } else {
      navigate(-1);
    }
  };

  return (
    <Modal handleClose={closeModal} show={isOpen} size="modal-lg">
      <Modal.Header title={strings.verification} />
      <Modal.Body>
        <PrescriptionSummary
          animal={animal}
          animalCarer={animalCarer}
          prescription={prescription}
          licensedPrescription={licensedPrescription}
          commodityPrescription={commodityPrescription}
          instructionLanguage={instructionLanguage}
          delivery={delivery}
          receiverPharmacy={receiverPharmacy}
          comment={comment}
          selectedFeeType={selectedFeeType}
          feeAmount={feeAmount}
        />
        <div className="flex flex-col items-center">
          <div className="w-20 h-20">
            <UserProfilePicture userId={vet.userId} />
          </div>
          <div className="text-center">{strings.signature}</div>
          <div className="text-center text-xl">{vet?.details.fullName}</div>
        </div>
        <div className="text-center">
          {isSendingLoading ? (
            <LoaderInline />
          ) : secondScreen ? (
            <></>
          ) : (
            <>
              <p>{strings.enterPin}</p>
              <CodeInputsForm submit={checkPin} hideCharacters />
            </>
          )}
        </div>
        <AlertBox
          hidden={!error}
          type={AlertType.ERROR}
          message={
            <>
              <b>{error}</b>
              {statusList?.map((status: PrescriptionStatus) => (
                <div className="text-sm">{status.description}</div>
              ))}
            </>
          }
        />
        <AlertBox
          hidden={!success}
          type={AlertType.SUCCESS}
          message={
            <>
              <b>{success}</b>
              {statusList?.map((status: PrescriptionStatus) => (
                <p className="text-sm">{status.description}</p>
              ))}
            </>
          }
        />
      </Modal.Body>
      <Modal.Footer extraClassName="justify-end">
        {!secondScreen ? (
          <CloseButton asMain onClick={closeModal} />
        ) : (
          <Button className="modal-main-button" onClick={finish}>
            {strings.finish}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default FinishModal;
