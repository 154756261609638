/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { VetProfessionWithUserResponse } from "../../../models/vet/VetProfessionWithUserResponse";
import VetProfessionDocument from "./VetProfessionDocument";
import RejectionModal from "./RejectionModal";
import ApprovalModal from "./ApprovalModal";
import UserManagementApi from "../../../api/UserManagementApi";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import { EmailResponse } from "../../../models/contact/EmailResponse";
import LoaderInline from "../../../components/LoaderInline";
import Button from "../../../components/Button";

interface Props {
  onError(e: any): void;
  onUpdated?: (vetProfWithUser: VetProfessionWithUserResponse) => any;
  vetProfessionWithUser: VetProfessionWithUserResponse;
}

const VetProfessionsItemRow: React.FC<Props> = ({
  onError,
  onUpdated,
  vetProfessionWithUser,
}: Props) => {
  const [{ user, vetProfession }, setVetProfWithUser] = useState(
    vetProfessionWithUser
  );

  const [approvalModalShow, setApprovalModalShow] = useState(false);
  const [rejectModalShown, setRejectModalShown] = useState(false);

  const [primaryEmail, setPrimaryEmail] = useState<EmailResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getUserDetails = async (userId: string) => {
      try {
        const response = await UserManagementApi.getUserDetails(userId);
        const userDetails = response.data;
        const primaryUserEmail = userDetails.emails.find(
          (e) => e.contactType === "PRIMARY"
        );
        setPrimaryEmail(primaryUserEmail);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void getUserDetails(user.userId);
  }, [user.userId]);

  return (
    <>
      <tr hidden={!loading}>
        <td className="p-4 text-sm whitespace-nowrap" colSpan={3}>
          <LoaderInline />
        </td>
      </tr>
      <tr hidden={loading}>
        <td className="p-4 text-sm whitespace-nowrap">
          <p className="mb-0">{user.fullName}</p>
          <small className="small text-muted">{primaryEmail?.value}</small>
        </td>
        <td className="p-4 text-sm whitespace-nowrap" >
          <VetProfessionDocument
            documents={vetProfession.proofDocuments}
            onError={onError}
            vetName={user.fullName}
            vetUserEmail={primaryEmail?.value}
            vetUserId={user.userId}
          />
        </td>
        <td className="p-4 text-sm whitespace-nowrap">
          <div className="flex justify-around">
            {vetProfession.state !== "APPROVED" && (
              <Button
                fullWidth={false}
                onClick={() => setApprovalModalShow(true)}
                type="button"
              >
                {strings.approve}
              </Button>
            )}
            {vetProfession.state === "PENDING" && (
              <Button
                type="button"
                variant="danger"
                onClick={() => setRejectModalShown(true)}
              >
                {strings.reject}
              </Button>
            )}
          </div>
        </td>
      </tr>
      <tr hidden={!error}>
        <td className="p-4 text-sm whitespace-nowrap" colSpan={3}>
          <AlertBox message={error} />
        </td>
      </tr>
      <ApprovalModal
        onHide={() => setApprovalModalShow(false)}
        onUpdated={onUpdated}
        setVetProfWithUser={(v: VetProfessionWithUserResponse) =>
          setVetProfWithUser(v)
        }
        show={approvalModalShow}
        user={user}
        userEmail={primaryEmail?.value}
      />
      <RejectionModal
        onHide={() => setRejectModalShown(false)}
        onUpdated={onUpdated}
        setVetProfWithUser={(v: VetProfessionWithUserResponse) =>
          setVetProfWithUser(v)
        }
        show={rejectModalShown}
        user={user}
        userEmail={primaryEmail?.value}
      />
    </>
  );
};
export default VetProfessionsItemRow;
