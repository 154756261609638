/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import {
  mobileValidation,
  prescriptionRegexp,
} from "../../../../util/Validations";
import { strings } from "../../../../common/Strings/Strings";
import PhoneNumber from "../../../../components/ReactHookFormFields/Specific/PhoneNumber";

export interface OrganizationIdFormProps {
  organizationName: string;
  organizationId: string;
  phoneNumberOrg: string;
  cityOrg: string;
  zipOrg: string;
  addressOrg: string;
}

interface Props {
  defaultCountryCode?: string;
}

const OrganizationIdOwnerForm: React.FC<Props> = ({
  defaultCountryCode,
}: Props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <form>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.organizationName}
          fieldOptions={{
            maxLength: 35,
            pattern: {
              value: prescriptionRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.organisationName}
          maxLength={35}
          name="organizationName"
          register={register}
          required
          showRequired
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.organizationId}
          fieldOptions={{
            maxLength: 10,
            pattern: {
              value: prescriptionRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.organisationId}
          name="organizationId"
          maxLength={10}
          register={register}
          required
          showRequired
        />
        <PhoneNumber
          control={control}
          country={defaultCountryCode}
          fieldOptions={{
            pattern: {
              value: mobileValidation.matchRegexp,
              message: strings.phoneNotValid,
            },
          }}
          id="phoneNumberForPersonalIdOwner"
          label={strings.phone}
          name="phoneNumberOrg"
          placeholder={strings.phone}
          required
          showRequired
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.addressOrg}
          fieldOptions={{
            maxLength: 35,
            pattern: {
              value: prescriptionRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.address}
          name="addressOrg"
          maxLength={35}
          register={register}
          required
          showRequired
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          error={errors.cityOrg}
          fieldOptions={{
            maxLength: 28,
            pattern: {
              value: prescriptionRegexp,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.city}
          name="cityOrg"
          maxLength={28}
          required
          register={register}
          showRequired
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          disableNotNumberCharacters
          error={errors.zipOrg}
          fieldOptions={{
            maxLength: 6,
            minLength: 1,
            pattern: {
              value: /[^\s]/,
              message: strings.notAllowedCharacter,
            },
          }}
          label={strings.zip}
          name="zipOrg"
          maxLength={6}
          required
          register={register}
          showRequired
        />
      </div>
    </form>
  );
};

export default OrganizationIdOwnerForm;

OrganizationIdOwnerForm.defaultProps = {
  defaultCountryCode: undefined,
};
