/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import { getGeneralError, getVatOptions } from "../../util/helperFunctions";
import AlertBox from "../../components/AlertBox";
import LoaderInline from "../../components/LoaderInline";
import { CountryDetailsResponse } from "../../models/management/CountryDetailsResponse";
import { InsuranceConfigResponse } from "../../models/management/InsuranceConfigResponse";
import CountryApi from "../../api/CountryApi";
import InsuranceConfigApi from "../../api/InsuranceConfigApi";
import Field from "../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import Select from "../../components/ReactHookFormFields/General/Select/Select";
import CombinedSelect from "../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { InsuranceConfigRequest } from "../../models/management/InsuranceConfigRequest";
import { AccountingCodeResponse } from "../../models/accounting/AccountingCodeResponse";

interface Props {
  isActiveTab: boolean;
  countryCode: string;
}

interface InsuranceConfigForm {
  defaultDescription: string;
  vat: number;
  accountingCode: AccountingCodeResponse[];
}

export default function InsuranceConfigSection({
  isActiveTab,
  countryCode,
}: Props): ReactElement {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();

  const insuranceForm = useForm<InsuranceConfigForm>();
  const {
    register,
    control,
    formState: { errors },
    setValue,
    reset,
  } = insuranceForm;

  const initForm = (
    config?: InsuranceConfigResponse,
    country?: CountryDetailsResponse
  ) => {
    reset();
    const vats = country?.vatRates || [];
    const accountingCodes = country?.accountingCodes || [];
    setValue("defaultDescription", config?.defaultDescription || "");
    setValue("vat", config ? config.vat : vats[0] || 0);
    setValue(
      "accountingCode",
      accountingCodes.filter((i) => i.code === config?.accountingCode)
    );
  };

  const getCountryDetails = async () => {
    setLoading(true);
    try {
      const resp = await CountryApi.getCountryDetails(countryCode);
      setCountryDetails(resp.data);
      initForm(resp.data.insurance, resp.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  const deleteConfig = async () => {
    setLoading(true);
    try {
      await InsuranceConfigApi.deleteConfig(countryCode);
      initForm(undefined, countryDetails);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getCountryDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab, countryCode]);

  const saveConfig = async (data: InsuranceConfigForm) => {
    setLoading(true);

    try {
      const request: InsuranceConfigRequest = {
        defaultDescription: data.defaultDescription,
        vat: data.vat,
        accountingCode: data.accountingCode[0]?.code,
      };
      const resp = await InsuranceConfigApi.updateConfig(countryCode, request);
      initForm(resp.data, countryDetails);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div hidden={!isLoading}>
        <LoaderInline className="my-3" />
      </div>
      <div hidden={isLoading || error == null}>
        <AlertBox message={error} className="my-3" />
      </div>
      <div hidden={isLoading || error != null} className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="card d-flex flex-column mb-4">
              <div className="d-flex block-label">
                <div className="p-3">{strings.insuranceInvoiceSettings}</div>
              </div>
              <FormProvider {...insuranceForm}>
                <form onSubmit={insuranceForm.handleSubmit(saveConfig)}>
                  <div className="pt-3 px-3">
                    <div className="d-flex" style={{ gap: "20px" }}>
                      <Field
                        error={errors.defaultDescription}
                        autoComplete={AutoCompleteOptions.off}
                        name="defaultDescription"
                        className="flex-grow-1"
                        register={register}
                        label={strings.name}
                        required
                      />
                      <Select
                        error={errors.vat}
                        isChooseOption={false}
                        fieldOptions={{
                          valueAsNumber: true,
                        }}
                        name="vat"
                        options={getVatOptions(countryDetails?.vatRates)}
                        register={register}
                        label={strings.vat}
                        width="80px"
                      />
                    </div>
                    <CombinedSelect
                      control={control}
                      label={strings.accountingCode}
                      labelKey="name"
                      name="accountingCode"
                      options={countryDetails?.accountingCodes || []}
                      required
                    />
                  </div>
                  <div className="d-flex p-3 hr-on-top">
                    <Button
                      className="mr-auto"
                      variant="secondary"
                      onClick={deleteConfig}
                    >
                      <i className="fas fa-trash" />
                    </Button>
                    <Button variant="primary " type="submit">
                      {strings.save}
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
