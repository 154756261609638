/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { ChevronRight } from "../../Icons/ChevronRight";
import { ItemType } from "./UserDropdownMenuItems";

interface Props {
  image: ReactElement;
  itemType: ItemType;
  onClick?(): void;
  text: string;
  visible?: boolean;
  withArrow?: boolean;
}

const UserDropdownMenuItem: React.FC<Props> = ({
  image,
  itemType,
  text,
  onClick,
  visible = true,
  withArrow,
}: Props) => {
  if (!visible) return null;

  if (itemType === "dropdown-item") {
    return (
      <li
        className="flex justify-between items-center px-4 pe-10 lg:pe-4 py-2.5 hover:bg-gray-50 hover:text-gray-800 dark:hover:bg-gray-600 dark:hover:text-gray-300"
        role="button"
        tabIndex={0}
        onClick={onClick}
      >
        <div className="w-full flex justify-between items-center space-x-3">
          <span className="flex justify-center items-center">
            {image}
            {text}
          </span>
          {withArrow && <ChevronRight className="mx-1 w-5 h-5 align-middle" />}
        </div>
      </li>
    );
  }

  // itemType === "modal"
  return (
    <div
      className="flex justify-between items-center py-3 text-gray-700 dark:text-gray-400"
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <div className="w-full flex items-center space-x-3">
        {image}
        <span className="flex items-center">{text}</span>
      </div>
      {withArrow && <ChevronRight className="mr-2.5 w-5 h-5 align-middle" />}
    </div>
  );
};

export default UserDropdownMenuItem;
