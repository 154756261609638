/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import ReservationApi from "../../../../api/ReservationApi";
import {
  getGeneralError,
  isCancellable,
} from "../../../../util/helperFunctions";
import { ReservationResponse } from "../../../../models/reservation/ReservationResponse";
import { TodayShift } from "../../../../models/calendar/TodayShift";
import LoaderInline from "../../../../components/LoaderInline";
import { CalendarReservation } from "../../../../models/calendar/CalendarReservation";
import AlertBox from "../../../../components/AlertBox";
import { ViewTypes } from "../../MainCalendar";
import DayScheduler from "./DayScheduler";
import CalendarListView from "../CalendarListView";
import { useClinic } from "../../../../contexts/ClinicContext";
import ReservationInProgress from "./ReservationInProgress";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../../../util/LocalStorageVariables";
import ReservationCreationApi from "../../../../api/ReservationCreationApi";
import NoShiftYet from "../../NoShiftYet";

interface Props {
  selectedDay: string;
  calendarStartTime: moment.Moment;
  calendarEndTime: moment.Moment;
  handleNewReservationModal(
    open: boolean,
    dateAndTime?: { time: string; date: string },
    selectedShift?: TodayShift
  ): void;
  refreshReservationInProgress: boolean;
  triggerReload?: boolean;
  viewType: ViewTypes;
}

const DayDataController: React.FC<Props> = ({
  selectedDay,
  calendarEndTime,
  calendarStartTime,
  handleNewReservationModal,
  refreshReservationInProgress: refresh,
  triggerReload: mainReload,
  viewType,
}: Props) => {
  const role: UserRole | null = getValueOfActiveUser("role");
  const { clinic } = useClinic();
  const [dayData, setDayData] = useState<CalendarReservation[]>([]);
  const [loadedReservations, setLoadedReservations] = useState<boolean>(false);
  const [loadedShifts, setLoadedShifts] = useState<boolean>(false);
  const [todayShifts, setTodayShifts] = useState<TodayShift[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [
    refreshReservationInProgress,
    setRefreshReservationInProgress,
  ] = useState<boolean>(false);

  useEffect(() => {
    setRefreshReservationInProgress(!refreshReservationInProgress);
  }, [refresh]);

  useEffect(() => {
    setReload(!reload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainReload]);

  const getReservations = async () => {
    if (!clinic) {
      return;
    }

    const startTime = moment(selectedDay)
      .set({ hour: 0, minute: 0, second: 0 })
      .toDate();

    const endTime = moment(selectedDay)
      .set({ hour: 23, minute: 59, second: 59 })
      .toDate();

    try {
      const response = await ReservationApi.getClinicReservations(
        clinic.id,
        startTime,
        endTime
      );
      const dataArray: CalendarReservation[] = [];
      if (response.data.length !== 0) {
        response.data.forEach((item: ReservationResponse) => {
          const newItem: CalendarReservation = {
            ...item,
            cancellable: isCancellable(item, role ? role : undefined),
          };

          dataArray.push(newItem);
        });
      }
      setDayData(dataArray);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoadedReservations(true);
    }
  };

  useEffect(() => {
    setLoadedReservations(false);
    void getReservations();

    /* eslint-disable-next-line */
  }, [selectedDay, clinic, reload]);

  const getConsultationPeriods = async () => {
    if (!clinic) {
      return;
    }

    const day = moment(selectedDay);
    const startTime = day.set({ hour: 0, minute: 0, second: 0 }).toDate();

    const endTime = moment(selectedDay)
      .set({ hour: 23, minute: 59, second: 59 })
      .toDate();

    try {
      const response = await ReservationCreationApi.getConsultationPeriods(
        clinic.id,
        startTime,
        endTime
      );
      const shifts = response.data;
      setTodayShifts(
        shifts.map(shift => ({
          id: shift.id,
          collaborator: shift.collaborator,
          employmentType: shift.employmentType,
          reservations: [],
          startTime: shift.startTime,
          endTime: shift.endTime,
        }))
      );
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoadedShifts(true);
    }
  };

  useEffect(() => {
    setLoadedShifts(false);
    void getConsultationPeriods();

    /* eslint-disable-next-line */
  }, [selectedDay, reload, clinic]);

  const triggerReload = (): void => {
    setReload(!reload);
  };

  if (!loadedReservations || !loadedShifts) {
    return <LoaderInline className="mt-3" />;
  }
  if (loadedShifts && todayShifts.length === 0) {
    return (
      <NoShiftYet reloadReservations={triggerReload} selectedDay={selectedDay}/>
    );
  }
  if (error) {
    return (
      <div className="container">
        <AlertBox message={error} className="mt-3" />
      </div>
    );
  }
  if (viewType === ViewTypes.LISTVIEW) {
    return (
      <CalendarListView
        reloadReservations={triggerReload}
        reservations={dayData}
      />
    );
  }
  return (
    <>
      <ReservationInProgress refresh={refreshReservationInProgress} />
      <DayScheduler
        selectedDay={selectedDay}
        calendarStartTime={calendarStartTime}
        calendarEndTime={calendarEndTime}
        handleNewReservationModal={handleNewReservationModal}
        refreshReservationInProgress={() => {
          setRefreshReservationInProgress(!refreshReservationInProgress);
        }}
        reloadReservations={triggerReload}
        reservations={dayData}
        todayShifts={todayShifts}
        viewType={viewType}
      />
    </>
  );
};

export default DayDataController;

DayDataController.defaultProps = {
  triggerReload: undefined,
};
