/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../common/Strings/Strings";
import { CommodityArticle } from "../../../models/ePrescription/VARA/CommodityArticle";

interface Props {
  selectedPackage?: CommodityArticle;
}

const PackageInfoCommodity: React.FC<Props> = ({ selectedPackage }: Props) => {
  return (
    <>
      {selectedPackage ? (
        <div>
          <div className="grid grid-cols-12 gap-4 pt-2">
            <div className="col-span-6 md:col-span-6 flex flex-col">
              <div className="italic">{strings.name}</div>
              {selectedPackage.title}
            </div>
            <div className="col-span-6 md:col-span-2 flex flex-col">
              <div className="italic">{strings.quantityLimit}</div>
              {selectedPackage.label}
            </div>
            <div className="col-span-6 md:col-span-2 flex flex-col">
              <div className="italic">{strings.nvn}</div>
              {selectedPackage.itemNumber}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PackageInfoCommodity;
