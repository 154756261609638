/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import { UserResponse } from "../../../../models/user/UserResponse";
import { getGeneralError } from "../../../../util/helperFunctions";
import { ContactType } from "../../../../models/contact/ContactType";
import BeforeDeleteModal from "../../../Modals/BeforeDeleteModal";
import AddPhoneModal, {
  NewPhoneForm,
} from "../../../Modals/Phone/AddPhoneModal";
import ConfirmPhoneModal from "../../../Modals/Phone/ConfirmPhoneModal";
import PromotePhoneModal from "../../../Modals/Phone/PromotePhoneModal";
import AlertBox, { AlertType } from "../../../AlertBox";
import { useUser } from "../../../../contexts/UserContext";
import { PhoneResponse } from "../../../../models/contact/PhoneResponse";
import UserContactsApi from "../../../../api/UserContactsApi";
import ResendPhoneConfirmationModal from "../../../Modals/Phone/ResendPhoneConfirmationModal";
import UnconfirmedPhoneTag from "./UnconfirmedPhoneTag";
import { PhoneType } from "../../../../models/contact/PhoneType";
import Button from "../../../Button";
import { Trash } from "../../../../common/Icons/Trash";
import { Pencil } from "../../../../common/Icons/Pencil";

interface Props {
  owner: UserResponse;
}

const UserPhoneOwnershipList: React.FC<Props> = ({ owner }: Props) => {
  const { user } = useUser();

  const [phones, setPhones] = useState<PhoneResponse[]>(
    owner.phones ? owner.phones : []
  );

  const [phoneToConfirm, setPhoneToConfirm] = useState<PhoneResponse | null>(
    null
  );
  const [phoneToPromote, setPhoneToPromote] = useState<PhoneResponse | null>(
    null
  );
  const [phoneToDelete, setPhoneToDelete] = useState<PhoneResponse | null>();

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showResendConfirmationModal, setShowResendConfirmationModal] =
    useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showPromoteModal, setShowPromoteModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [resendConfirmationLoading, setResendConfirmationLoading] =
    useState<boolean>(false);
  const [confirmationLoading, setConfirmationLoading] =
    useState<boolean>(false);
  const [additionLoading, setAdditionLoading] = useState<boolean>(false);
  const [promotionLoading, setPromotionLoading] = useState<boolean>(false);
  const [deletionLoading, setDeletionLoading] = useState<boolean>(false);

  const [confirmationError, setConfirmationError] = useState<string | null>(
    null
  );
  const [resendConfirmationError, setResendConfirmationError] = useState<
    string | null
  >(null);
  const [additionError, setAdditionError] = useState<string | null>(null);
  const [promotionError, setPromotionError] = useState<string | null>(null);
  const [deletionError, setDeletionError] = useState<string | null>(null);

  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
    setPhoneToConfirm(null);
    setConfirmationError(null);
  };

  const openResendConfirmationModal = (phone: PhoneResponse) => {
    setPhoneToConfirm(phone);
    setShowResendConfirmationModal(true);
  };

  const closeResendConfirmationModal = () => {
    setShowResendConfirmationModal(false);
    setResendConfirmationError(null);
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    setAdditionError(null);
  };

  const openPromoteModal = (phone: PhoneResponse) => {
    if (phone.contactType === ContactType.PRIMARY) {
      return;
    }

    setPhoneToPromote(phone);
    setShowPromoteModal(true);
  };

  const closePromoteModal = () => {
    setShowPromoteModal(false);
    setPromotionError(null);
  };

  const confirmPhone = async (smsCode: string) => {
    if (phoneToConfirm === null) {
      return;
    }

    setConfirmationLoading(true);
    setConfirmationError(null);

    try {
      await UserContactsApi.confirmUserPhoneInstantly(phoneToConfirm.id, {
        smsCode,
      });
      setPhones(
        phones.map((p) => {
          if (p.id === phoneToConfirm.id) {
            p.confirmed = true;
          }
          return p;
        })
      );
      closeConfirmationModal();
    } catch (err) {
      setConfirmationError(await getGeneralError(err));
    } finally {
      setConfirmationLoading(false);
    }
  };

  const resendPhoneConfirmationCode = async () => {
    if (!phoneToConfirm) {
      return;
    }

    setResendConfirmationLoading(true);
    setConfirmationError(null);

    try {
      await UserContactsApi.sendUserPhoneConfirmation(phoneToConfirm.id);
      closeResendConfirmationModal();
      openConfirmationModal();
    } catch (err) {
      setResendConfirmationError(await getGeneralError(err));
    } finally {
      setResendConfirmationLoading(false);
    }
  };

  const addNewPhoneNumber = async ({
    phone,
    phoneType,
    isPublic,
  }: NewPhoneForm) => {
    setAdditionLoading(true);

    const oldPhones: PhoneResponse[] = [...phones];

    try {
      const response = await UserContactsApi.addUserPhone({
        value: phone,
        phoneType,
        public: isPublic,
      });
      const newPhones = response.data.phones;

      const newPhone = newPhones.find(
        (p: PhoneResponse) =>
          oldPhones.find((old) => old.id === p.id) === undefined
      );

      if (newPhone !== undefined && newPhone.phoneType === PhoneType.MOBILE) {
        setPhoneToConfirm(newPhone);
        setShowConfirmationModal(true);
      }

      setPhones(newPhones);
      closeAddModal();
      setAdditionError(null);
    } catch (err) {
      setAdditionError(await getGeneralError(err));
    } finally {
      setAdditionLoading(false);
    }
  };

  const promotePhone = async () => {
    if (
      phoneToPromote === null ||
      phoneToPromote.contactType === ContactType.PRIMARY
    ) {
      return;
    }

    setPromotionLoading(true);
    setPromotionError(null);

    try {
      await UserContactsApi.setPrimaryUserPhone(phoneToPromote.id);
      setPhones((prevState) => {
        const prevPrimary = prevState.find(
          (o) => o.contactType === ContactType.PRIMARY
        );
        const newPrimary = prevState.find((o) => o.id === phoneToPromote.id);
        if (prevPrimary !== undefined) {
          prevPrimary.contactType = ContactType.PRIVATE;
        }
        if (newPrimary !== undefined) {
          newPrimary.contactType = ContactType.PRIMARY;
        }
        return prevState;
      });
      closePromoteModal();
    } catch (err) {
      setPromotionError(await getGeneralError(err));
    } finally {
      setPromotionLoading(false);
    }
  };

  const deletePhone = async (phone: PhoneResponse) => {
    setPhoneToDelete(phone);
    setDeletionLoading(true);

    try {
      const response = await UserContactsApi.removeUserPhone(phone.id);
      setPhones(response.data.phones);
      setDeletionError(null);
    } catch (err) {
      setDeletionError(await getGeneralError(err));
    } finally {
      setDeletionLoading(false);
    }
  };

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 font-bold">
          <p>{strings.phoneNumber.toUpperCase()}</p>
        </div>
        {phones.map((phone, index) => (
          <div
            className="p-4 flex justify-between items-center space-x-4"
            key={index}
          >
            <div>
              <div className="flex flex-wrap items-center">
                <p>{phone.value}</p>
                <UnconfirmedPhoneTag
                  phone={phone}
                  onClick={() => openResendConfirmationModal(phone)}
                />
                <button
                  className={`tw-btn-pill-tercier-small ${
                    phone.confirmed ? "mx-3" : ""
                  }`}
                  disabled
                  hidden={phone.contactType !== ContactType.PRIMARY}
                  type="button"
                >
                  {strings.primary}
                </button>
              </div>
            </div>
            <div className="flex">
              <Button
                aria-label={strings.edit}
                hidden={phone.contactType === ContactType.PRIMARY}
                onClick={() => {
                  openPromoteModal(phone);
                }}
                variant="icon"
              >
                <span className="sr-only">{strings.edit}</span>
                <Pencil />
              </Button>
              <Button
                aria-label={strings.delete}
                className="tw-link ml-3"
                loading={deletionLoading && phone === phoneToDelete}
                onClick={() => {
                  setShowDeleteModal(true);
                  setPhoneToDelete(phone);
                }}
                showTextWhileLoading={false}
                variant="icon"
              >
                <span className="sr-only">{strings.delete}</span>
                <Trash />
              </Button>
            </div>
          </div>
        ))}
        <div className="p-4">
          <Button
            aria-label={strings.addPhoneNumber}
            onClick={openAddModal}
            variant="link"
          >
            <span className="sr-only">{strings.addPhoneNumber}</span>+{" "}
            {strings.addPhoneNumber}
          </Button>
        </div>
        <AlertBox message={deletionError} className="m-3" />
      </div>
      <ConfirmPhoneModal
        close={closeConfirmationModal}
        error={confirmationError}
        isOpen={showConfirmationModal}
        loading={confirmationLoading}
        onSubmit={confirmPhone}
        onResendCode={resendPhoneConfirmationCode}
        phoneToConfirm={phoneToConfirm}
      />
      <ResendPhoneConfirmationModal
        close={closeResendConfirmationModal}
        error={resendConfirmationError}
        isOpen={showResendConfirmationModal}
        loading={resendConfirmationLoading}
        onSubmit={resendPhoneConfirmationCode}
      />
      <AddPhoneModal
        close={closeAddModal}
        defaultCountryCode={user.primaryAddress?.countryCode}
        error={additionError}
        isOpen={showAddModal}
        isUser
        loading={additionLoading}
        onSubmit={addNewPhoneNumber}
      />
      <PromotePhoneModal
        close={closePromoteModal}
        error={promotionError}
        isOpen={showPromoteModal}
        loading={promotionLoading}
        onSubmit={promotePhone}
        phoneNumber={phoneToPromote?.value}
      />
      <BeforeDeleteModal
        content={
          phoneToDelete?.contactType === ContactType.PRIMARY ? (
            <AlertBox
              closeAble={false}
              message={strings.primaryContactNotDeletable}
              type={AlertType.WARNING}
            />
          ) : (
            <p>
              {strings.deletePhoneNumberConfirmation}
              <br />
              <strong>{phoneToDelete?.value}</strong>
            </p>
          )
        }
        open={showDeleteModal}
        returnYes={
          phoneToDelete?.contactType === ContactType.PRIMARY
            ? undefined
            : () => {
                if (phoneToDelete) {
                  void deletePhone(phoneToDelete);
                }
                setShowDeleteModal(false);
              }
        }
        onHide={() => {
          setShowDeleteModal(false);
        }}
        title={strings.deletePhoneNumber}
      />
    </>
  );
};

export default UserPhoneOwnershipList;
