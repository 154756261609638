/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import {
  dateAndTime,
  petGenders,
  petSpecies,
  strings,
} from "../../../../common/Strings/Strings";
import {
  appointmentToRescheduleKey,
  deleteFromLocalStorage,
  getFromLocalStorage,
  newReservationFormDataKey,
} from "../../../../util/LocalStorageVariables";
import { CalendarReservation } from "../../../../models/calendar/CalendarReservation";
import { SavedReservationProgress } from "../../../../models/calendar/SavedReservationProgress";
import { XMark } from "../../../../common/Icons/XMark";
import Button from "../../../../components/Button";
import { CalendarDays } from "../../../../common/Icons/CalendarDays";

interface Props {
  refresh: boolean;
}

const ReservationInProgress: React.FC<Props> = ({ refresh }: Props) => {
  const [reservationInProgress, setReservationInProgress] =
    useState<SavedReservationProgress>();
  const [appointmentToReschedule, setAppointmentToReschedule] =
    useState<CalendarReservation>();

  useEffect(() => {
    const savedReservationString = getFromLocalStorage(
      newReservationFormDataKey
    );

    if (savedReservationString) {
      const savedData = JSON.parse(
        savedReservationString
      ) as SavedReservationProgress;
      setReservationInProgress(savedData);
    } else {
      setReservationInProgress(undefined);
    }
  }, [refresh]);

  useEffect(() => {
    const appointmentUnderRescheduling = getFromLocalStorage(
      appointmentToRescheduleKey
    );

    if (appointmentUnderRescheduling) {
      const savedData = JSON.parse(appointmentUnderRescheduling);
      setAppointmentToReschedule(savedData);
    } else {
      setAppointmentToReschedule(undefined);
    }
  }, [refresh]);

  const displayReservationInProgress = (): ReactElement | null => {
    if (!reservationInProgress) return null;

    const pet = reservationInProgress?.petState?.pet;

    return (
      <div className="fixed bottom-5 z-100 inset-x-5 rounded-lg mx-auto max-w-md bg-sky-500 p-4 flex flex-nowrap items-start">
        <div className="relative rounded-full bg-sky-50 w-10 h-10 flex-shrink-0 flex justify-center items-center dark:bg-sky-50 mr-3">
          <CalendarDays />
        </div>
        <div className="text-white mr-3">
          <b>{strings.browseNewTimeSlot}</b>
          <div hidden={!pet}>
            <b className="mr-1">{pet?.clinicPetDetails.name}</b> (
            {[
              pet?.clinicPetDetails.species &&
                petSpecies[pet.clinicPetDetails.species],
              pet?.clinicPetDetails.breed && pet.clinicPetDetails.breed,
              pet?.clinicPetDetails.gender &&
                petGenders[pet.clinicPetDetails.gender],
              pet?.clinicPetDetails.lastMedicalRecordWeight &&
                `${pet.clinicPetDetails.lastMedicalRecordWeight} kg`,
            ]
              .filter((s) => !!s)
              .join(", ")}
            )
          </div>
          <div hidden={!reservationInProgress.reservationType}>
            {reservationInProgress.reservationType?.name}
          </div>
          <div className="italic">{strings.reScheduleToolTip}</div>
        </div>
        <div className="ml-auto">
          <Button
            className="tw-link text-white"
            variant="basic"
            onClick={() => {
              deleteFromLocalStorage(newReservationFormDataKey);
              setReservationInProgress(undefined);
            }}
          >
            {" "}
            <XMark />
          </Button>
        </div>
      </div>
    );
  };

  const displayAppointmentsToReSchedule = (): ReactElement | null => {
    if (!appointmentToReschedule) return null;

    return (
      <div className="fixed bottom-5 z-100 inset-x-5 rounded-lg mx-auto max-w-md bg-sky-500 p-4 flex flex-nowrap items-start">
        <div className="relative rounded-full bg-sky-50 w-10 h-10 flex-shrink-0 flex justify-center items-center dark:bg-sky-50 mr-3">
          <CalendarDays />
        </div>
        <div className="text-white mr-3">
          <b>{strings.reScheduleAppointment}</b>
          <div>
            {moment(appointmentToReschedule.startDateTime).format(
              dateAndTime.momentDateFormat
            )}
            <br />
            {moment(appointmentToReschedule.startDateTime).format(
              dateAndTime.timeFormat
            )}
            –
            {moment(appointmentToReschedule.endDateTime).format(
              dateAndTime.timeFormat
            )}
          </div>
          <div hidden={!appointmentToReschedule.reservationType}>
            {appointmentToReschedule.reservationType?.name}
          </div>
          <div>
            <b className="mr-2">
              {appointmentToReschedule.pet?.name ||
                appointmentToReschedule.petOfNewOwner?.name}
            </b>
            (
            {[
              appointmentToReschedule.pet?.species &&
                petSpecies[appointmentToReschedule.pet?.species],
              appointmentToReschedule.pet?.breed,
              appointmentToReschedule.pet?.gender &&
                petGenders[appointmentToReschedule.pet?.gender],
            ]
              .filter((s) => !!s)
              .join(", ")}
            )
          </div>
          <div className="italic">{strings.reScheduleToolTip}</div>
        </div>
        <div className="ml-auto">
          <Button
            className="tw-link text-white"
            variant="basic"
            onClick={() => {
              deleteFromLocalStorage(appointmentToRescheduleKey);
              setAppointmentToReschedule(undefined);
            }}
          >
            {" "}
            <XMark />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {displayReservationInProgress()}
      {displayAppointmentsToReSchedule()}
    </div>
  );
};

export default ReservationInProgress;
