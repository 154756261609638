/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { RegisterOptions } from "react-hook-form";
import { RegisterReturnType } from "../../../models/RegisterReturnType";
import Field from "../General/Field";
import { useDebouncedEffect } from "../../../hooks/hooks";

interface Props {
  name: string;
  register(ref: string, registerOptions?: RegisterOptions): RegisterReturnType;
  onChange: () => void;
  delay?: number;
  label?: string;
  labelOff?: boolean;
  placeholder?: string;
  className?: string;
}

/**
 * Uncontrolled (no react state) input component that calls delayed onChange function.
 * Uses react-hook-form register function to track values
 */
const DelayedOnChangeInput: React.FC<Props> = ({ onChange, delay = 400, ...rest }) => {
  const [fieldValue, setFieldValue] = useState<string>("");
  const [debounced, setDebounced] = useState<boolean>(false);

  useDebouncedEffect(
    () => {
      if (debounced) {
        onChange();
      }
    },
    delay,
    [fieldValue]
  );

  return (
    <Field
      onChange={e => {
        setDebounced(true);
        setFieldValue(e.target.value);
      }}
      {...rest}
    />
  );
};

export default DelayedOnChangeInput;
