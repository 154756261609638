/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { PrescriptionModel } from "../models/ePrescription/sending/PrescriptionModel";
import { PrescriptionResponseModel } from "../models/ePrescription/response/PrescriptionResponseModel";
import { CancellationResponseModel } from "../models/ePrescription/cancellation/CancellationResponseModel";
import { CancellationRequest } from "../models/ePrescription/cancellation/CancellationRequest";
import { HistoryData } from "../models/ePrescription/history/HistoryData";
import Params from "../common/Params";
import { PrescriptionsPage } from "../models/ePrescription/history/PrescriptionsPage";
import { NewPrescriptionStatus } from "../models/ePrescription/response/NewPrescriptionStatus";

export default class PrescriptionApi {
  private static baseUrl = Params.prescriptionService;

  static sendPrescription(
    request: PrescriptionModel
  ): ApiPromise<PrescriptionResponseModel> {
    const url = `${this.baseUrl}/api/prescriptions`;
    return axiosCommon.post(url, request);
  }

  static getPrescriptionsOfClinic(
    clinicId: string,
    petId?: string,
    page?: number,
    size?: number,
    medicalRecordId?: string,
    result?: NewPrescriptionStatus[]
  ): ApiPromise<PrescriptionsPage> {
    let url = `${this.baseUrl}/api/clinics/${clinicId}/prescriptions?`;

    if (page) {
      url = `${url}page=${page}&`;
    }
    if (size) {
      url = `${url}size=${size}&`;
    }
    if (medicalRecordId) {
      url = `${url}medicalRecordId=${medicalRecordId}&`;
    }
    if (petId) {
      url = `${url}petId=${petId}&`;
    }
    if (result && result.length > 0) {
      let resultText = "";
      result.forEach((type: string) => {
        resultText = `${resultText + type},`;
      });
      const finalText = resultText.slice(0, -1);
      url = `${url}resultTypes=${finalText}&`;
    }

    const finalUrl = url.slice(0, -1);
    return axiosCommon.get(finalUrl);
  }

  static getPrescriptionsOfUser(
    clinicId: string,
    page?: number,
    size?: number,
    result?: NewPrescriptionStatus[]
  ): ApiPromise<PrescriptionsPage> {
    let url = `${this.baseUrl}/api/clinics/${clinicId}/prescriptions/my?`;

    if (page) {
      url = `${url}page=${page}&`;
    }
    if (size) {
      url = `${url}size=${size}&`;
    }
    if (result && result.length > 0) {
      let resultText = "";
      result.forEach((type: string) => {
        resultText = `${resultText + type},`;
      });
      const finalText = resultText.slice(0, -1);
      url = `${url}resultTypes=${finalText}&`;
    }

    const finalUrl = url.slice(0, -1);
    return axiosCommon.get(finalUrl);
  }

  static getPrescriptionByMongoId(id: string): ApiPromise<HistoryData> {
    const url = `${this.baseUrl}/api/prescriptions/${id}`;
    return axiosCommon.get(url);
  }

  static getMyPetsPrescriptions(
    page?: number,
    size?: number,
    result?: NewPrescriptionStatus[]
  ): ApiPromise<PrescriptionsPage> {
    let url = `${this.baseUrl}/api/my-pets/prescriptions?`;

    if (page) {
      url = `${url}page=${page}&`;
    }
    if (size) {
      url = `${url}size=${size}&`;
    }
    if (result && result.length > 0) {
      let resultText = "";
      result.forEach((type: string) => {
        resultText = `${resultText + type},`;
      });
      const finalText = resultText.slice(0, -1);
      url = `${url}resultTypes=${finalText}&`;
    }

    const finalUrl = url.slice(0, -1);
    return axiosCommon.get(finalUrl);
  }

  static getMyPetPrescriptionsById(
    petId: string,
    page?: number,
    size?: number,
    result?: NewPrescriptionStatus[]
  ): ApiPromise<PrescriptionsPage> {
    let url = `${this.baseUrl}/api/my-pets/${petId}/prescriptions?`;

    if (page) {
      url = `${url}page=${page}&`;
    }
    if (size) {
      url = `${url}size=${size}&`;
    }
    if (result && result.length > 0) {
      let resultText = "";
      result.forEach((type: string) => {
        resultText = `${resultText + type},`;
      });
      const finalText = resultText.slice(0, -1);
      url = `${url}resultTypes=${finalText}&`;
    }

    const finalUrl = url.slice(0, -1);
    return axiosCommon.get(finalUrl);
  }

  static cancelPrescriptionById(
    request: CancellationRequest,
    prescriptionMongoId: string
  ): ApiPromise<CancellationResponseModel> {
    const url = `${this.baseUrl}/api/prescriptions/${prescriptionMongoId}/cancel`;
    return axiosCommon.post(url, request);
  }
}
