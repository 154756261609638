/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import NumberFormatter from "../../util/NumberFormatter";
import SimpleInput from "./SimpleInput";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { strings } from "../../common/Strings/Strings";
import { useDebouncedState } from "../../hooks/hooks";
import { RoundingStrategy } from "../../models/clinic/RoundingStrategy";

export interface PriceInputProps {
  className?: string;
  currency: string | undefined | null;
  error?: FieldError;
  label?: string;
  onChange?: (price?: number) => void;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  roundingStrategy?: RoundingStrategy;
  vat?: number;
  width?: string;
}

const getGrossPrice = (
  net: number,
  vat: number | undefined,
  strategy?: RoundingStrategy
): number => {
  const vatToUse = vat === undefined ? 0 : vat;
  const value = net * ((100 + Number(vatToUse)) / 100);
  return NumberFormatter.roundBy(
    value,
    strategy || RoundingStrategy.TWO_DECIMAL
  );
};

const getNetPrice = (gross: number, vat: number | undefined): number =>
  NumberFormatter.roundToTwoDecimal(gross / ((100 + Number(vat || 0)) / 100));

const Prices = ({
  className,
  currency,
  label,
  error,
  onChange,
  optional = false,
  readOnly = false,
  required = false,
  roundingStrategy = RoundingStrategy.TWO_DECIMAL,
  value,
  vat: newVat,
  width,
}: PriceInputProps & { value: number }) => {
  const newNetPrice = useDebouncedState<number | "init" | undefined>("init", 300);
  const newGrossPrice = useDebouncedState<number | "init" | undefined>("init", 300);
  const [netPrice, setNetPrice] = useState<number>();
  const [grossPrice, setGrossPrice] = useState<number | undefined>();
  const [blockNetUpdate, setBlockNetUpdate] = useState<boolean>(false);
  const [blockGrossUpdate, setBlockGrossUpdate] = useState<boolean>(false);
  const [vat, setVat] = useState<number>();

  useEffect(() => {
    const price = Number(value);
    if (vat !== newVat) {
      setNetPrice(price);
      setGrossPrice(getGrossPrice(price, newVat, roundingStrategy));
    }
    setVat(newVat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVat, value]);

  useEffect(() => {
    const price = Number(value);

    if (value !== undefined && value !== null) {
      if (netPrice !== value && vat !== undefined) {
        setNetPrice(price);
        setGrossPrice(getGrossPrice(price, vat, roundingStrategy));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, vat]);

  useEffect(() => {
    if (newNetPrice.value !== "init") {
      setNetPrice(newNetPrice.value);

      if (onChange) {
        onChange(newNetPrice.value);
      }
      if (!blockGrossUpdate) {
        newGrossPrice.setValue(
          newNetPrice.value !== undefined ? getGrossPrice(newNetPrice.value, vat, roundingStrategy) : undefined
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNetPrice.value]);

  useEffect(() => {
    if (newGrossPrice.value !== "init") {
      setGrossPrice(newGrossPrice.value);
      if (!blockNetUpdate) {
        newNetPrice.setValue(newGrossPrice.value !== undefined ? getNetPrice(newGrossPrice.value, vat) : undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newGrossPrice.value]);

  const onNetPriceChange = (net: number | string) => {
    const price = net !== "" ? Number(net) : undefined;

    setBlockNetUpdate(true);
    setBlockGrossUpdate(false);

    newNetPrice.setValue(price);
  };

  const onGrossPriceChange = (gross: number | string) => {
    const price = gross !== "" ? Number(gross) : undefined;

    setBlockGrossUpdate(true);
    setBlockNetUpdate(false);

    newGrossPrice.setValue(price || 0);
  };

  return (
    <div className="flex w-full space-x-4">
      <div className="grow">
        <SimpleInput
          name="price"
          autoComplete={AutoCompleteOptions.off}
          className={className}
          error={error}
          label={label ? `${label} (${strings.net})` : ""}
          placeholder={strings.price}
          onChange={(d: string) => {
            onNetPriceChange(d);
          }}
          optional={optional}
          readOnly={readOnly}
          required={required}
          suffix={currency}
          type="number"
          value={netPrice?.toString()}
          width={width}
        />
      </div>
      <div className="grow">
        <SimpleInput
          name="price_gross"
          autoComplete={AutoCompleteOptions.off}
          className={className}
          error={error}
          label={label ? `${label} (${strings.gross})` : ""}
          placeholder={strings.price}
          onChange={(d: string) => {
            onGrossPriceChange(d);
          }}
          optional={optional}
          readOnly={readOnly}
          required={required}
          suffix={currency}
          type="number"
          value={grossPrice?.toString() || ""}
          width={width}
        />
      </div>
    </div>
  );
};

export default Prices;
