/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import CloseButton from "../../../components/CloseButton";
import { MedicalRecordResponse } from "../../../models/medical/MedicalRecordResponse";
import { pickUserFile } from "../../../util/FileUtils";
import MedicalRecordApi from "../../../api/MedicalRecordApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { MedicalRecordFileResponse } from "../../../models/medical/MedicalRecordFileResponse";
import { strings } from "../../../common/Strings/Strings";
import { MedicalRecordFileType } from "../../../models/medical/MedicalRecordFileType";
import SimpleSelect from "../../../components/InputFieldsSimple/SimpleSelect";
import LoaderInline from "../../../components/LoaderInline";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";
import { Card } from "../../../components/Cards/Card";
import HorizontalLine from "../../../components/HorizontalLine";
import { Cross } from "../../../common/Icons/Cross";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import { ExclamationMark } from "../../../common/Icons/ExclamationMark";
import { getSupportedMedicalRecordFileExtensions } from "./Preview/FilePreview";

interface Props {
  openType: MedicalRecordFileType | undefined;
  close(): void;
  medicalRecord: MedicalRecordResponse;
  files: MedicalRecordFileResponse[];
  onChange: () => void;
}

type UploadSate = "success" | "error" | "pending" | "loading" | "too_large";
const supportedTypes = getSupportedMedicalRecordFileExtensions();
const maxFileSize = 10 * 1024 * 1024; // 10MB

interface FileUpload {
  file: File;
  state: UploadSate;
  error?: string | null;
}

const FilesModal: React.FC<Props> = ({
  openType,
  close,
  medicalRecord,
  files,
  onChange,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] =
    useState<MedicalRecordFileResponse[]>(files);
  const [newFiles, setNewFiles] = useState<FileUpload[]>([]);
  const [type, setType] = useState<string>();

  useEffect(() => {
    setNewFiles([]);
    if (openType) {
      setType(openType);
    }
  }, [openType]);

  const onChoose = async () => {
    const pickedFiles = (await pickUserFile(supportedTypes, true)) || [];
    const newDocs: FileUpload[] = pickedFiles.map((f) => {
      if (f.size > maxFileSize) {
        return {
          file: f,
          state: "too_large",
          error: strings.fileTooLarge,
        };
      }

      return {
        file: f,
        state: "pending",
      };
    });
    setNewFiles((prev) => [...prev, ...newDocs]);
  };

  const getIcon = (state: UploadSate) => {
    if (state === "success" || state === "pending") {
      return <CheckCircle className="text-green-700 dark:text-green-400" />;
    }
    if (state === "error" || state === "too_large") {
      return <ExclamationMark className="text-red-700 dark:text-red-400" />;
    }
    if (state === "loading") {
      return <LoaderInline />;
    }
    return <></>;
  };

  const uploadFiles = async () => {
    setLoading(true);
    let updatedFiles = uploadedFiles;
    setNewFiles((prev) => {
      prev
        .filter((i) => i.state !== "success" && i.state !== "too_large")
        .forEach((i) => {
          i.state = "loading";
          i.error = undefined;
        });
      return prev;
    });
    for (let index = 0; index < newFiles.length; index += 1) {
      const doc = newFiles[index];
      try {
        if (doc.state !== "success" && doc.state !== "too_large") {
          // eslint-disable-next-line no-await-in-loop
          const response = await MedicalRecordApi.addFile(
            medicalRecord.reservationId,
            doc.file,
            type as MedicalRecordFileType
          );
          updatedFiles = response.data.files;
          setNewFiles((prev) => {
            prev[index].state = "success";
            prev[index].error = undefined;
            return prev;
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-await-in-loop
        const message = await getGeneralError(e);
        setNewFiles((prev) => {
          prev[index].state = "error";
          prev[index].error = message;
          return prev;
        });
      }
    }
    setUploadedFiles(updatedFiles);
    setNewFiles((prev) => {
      const failedUploads = prev.filter((i) => i.state !== "success");
      if (failedUploads.length <= 0) {
        close();
      }
      return failedUploads;
    });
    setLoading(false);
    onChange();
  };

  const deletePendingFile = (item: FileUpload) => {
    setNewFiles((prev) => prev.filter((i) => i !== item));
  };

  return (
    <Modal handleClose={close} show={!!openType}>
      <Modal.Header title={strings.documents} />
      <Modal.Body>
        <SimpleSelect
          className="mb-5"
          isChooseOption={false}
          name="type"
          onChange={(v) => {
            setType(v.target.value);
          }}
          options={[
            {
              value: MedicalRecordFileType.TREATMENT,
              title: strings.treatment,
            },
            {
              value: MedicalRecordFileType.DIAGNOSTICS,
              title: strings.diagnostics,
            },
            {
              value: MedicalRecordFileType.OTHER,
              title: strings.followUp,
            },
          ]}
          value={type}
        />
        <Card type="simple" paddingClass="md:p-4" spaceYClass="space-y-3">
          {newFiles.map((file, index) => (
            <>
              <div className="flex items-center" key={`new-${index}`}>
                <div className="mr-3">{getIcon(file.state)}</div>
                <div className="grow">
                  <div style={{ wordBreak: "break-word" }}>
                    {file.file.name}
                  </div>
                  <div
                    hidden={!file.error}
                    className="text-sm text-red-700 dark:text-red-400"
                  >
                    {file.error}
                  </div>
                </div>
                <Button onClick={() => deletePendingFile(file)} variant="icon">
                  <Cross />
                </Button>
              </div>
              <HorizontalLine />
            </>
          ))}
          <Button disabled={loading} onClick={onChoose} variant="link">
            <PlusIcon />
            {strings.addFiles}
          </Button>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading || newFiles.length === 0}
          loading={loading}
          onClick={uploadFiles}
          type="submit"
        >
          {strings.addDocuments}
        </Button>
        <CloseButton disabled={loading} loading={loading} onClick={close} />
      </Modal.Footer>
    </Modal>
  );
};

export default FilesModal;
