/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import logger from "../util/logger";
import { UserResponse } from "../models/user/UserResponse";
import UserApi from "../api/UserApi";
import { getGeneralError } from "../util/helperFunctions";
import { strings } from "../common/Strings/Strings";
import {
  addOrUpdateLoggedInUser,
  getActiveUserId,
  getLocaleForActiveUser,
} from "../util/LocalStorageVariables";
import { ChildrenProps } from "../models/ChildrenProps";
import { Spinner, SpinnerSize } from "../common/Icons/Spinner";
import AlertBox from "../components/AlertBox";
import FastLoginApi from "../api/FastLoginApi";

interface IUserContext {
  user: UserResponse & { userId: string };
  fetchUser: (id?: string) => Promise<void>;
  setUser: (user: UserResponse | undefined) => void;
}

const UserContext = createContext<IUserContext | null>(null);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const useUser = () => useContext(UserContext)!;

export const UserProvider: React.FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [user, setUser] = useState<UserResponse | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");

  const fetchUser = async () => {
    const userId = getActiveUserId();

    if (!userId) {
      setIsLoading(false);
      return;
    }

    try {
      const r = await UserApi.getMyUserData();
      setUser(r.data);
    } catch (err) {
      logger.error(err);
      setError(await getGeneralError(err));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchUser();
  }, []);

  useEffect(() => {
    const setupUser = async (withFastLogin = false) => {
      const userId = getActiveUserId();

      if (!user || !userId) {
        return;
      }

      try {
        let response;

        if (withFastLogin) {
          response = await FastLoginApi.getFastLoginToken();
        }

        addOrUpdateLoggedInUser({
          userId,
          fastLoginToken: response?.data.token,
          name: user.details.fullName,
          locale: user.locale ?? getLocaleForActiveUser(),
        });
      } catch (e) {
        logger.error(e);
      }
    };

    if (user) {
      // TODO: Call setupUser() with 'withFastLogin' parameter set to true (When the auth-service can provide it)
      void setupUser();
    }
  }, [user]);

  const ctx = useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      user: { ...user!, userId: getActiveUserId()! },
      fetchUser,
      setUser,
    }),
    [user]
  );

  if (isLoading) {
    return (
      <div
        className="flex justify-center items-center"
        style={{ height: "100vh" }}
      >
        <div className="text-center">
          <Spinner size={SpinnerSize.ExtraLarge} dark={!darkMode} />
          <div className="mt-2 dark:text-white">{strings.loading}</div>
        </div>
      </div>
    );
  }

  if (error) {
    return <AlertBox message={error} />;
  }

  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>;
};
