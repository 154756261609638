/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { CountryResponse } from "../../../models/management/CountryResponse";
import Button from "../../../components/Button";
import EmailAndSocials from "./RegistrationSteps/EmailAndSocials";
import ChoosePassword from "./RegistrationSteps/ChoosePassword";
import VerifyEmail from "./RegistrationSteps/VerifyEmail";
import SocialRegistration from "./RegistrationSteps/SocialRegistration";
import RegistrationSuccessful from "./RegistrationSteps/RegistrationSuccessful/RegistrationSuccessful";

export interface RegistrationForm {
  password?: string;
  email: string;
  prefix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  countries: CountryResponse[];
}

enum RegistrationSteps {
  EMAIL = strings.enterYourEmail,
  PASSWORD = strings.choosePassword,
  VERIFY_EMAIL = strings.verifyYourEmail,
  SUCCESS = strings.registrationSuccessful,
}

const stepsInOrder = [
  RegistrationSteps.EMAIL,
  RegistrationSteps.PASSWORD,
  RegistrationSteps.VERIFY_EMAIL,
  RegistrationSteps.SUCCESS,
];

export interface ReCaptcha {
  reset: () => void;
  execute: () => void;
}

const UserRegistration: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()

  const [stepIndex, setStepIndex] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<RegistrationSteps>(
    RegistrationSteps.EMAIL
  );

  const [socialRegistration, setSocialRegistration] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();

  const nextStep = () => {
    if (stepIndex === stepsInOrder.length - 1) {
      return;
    }
    setActiveTab(stepsInOrder[stepIndex + 1]);
    setStepIndex(stepIndex + 1);
  };

  const previousStep = () => {
    if (stepIndex === 0) {
      navigate(-1);
    }
    setActiveTab(stepsInOrder[stepIndex - 1]);
    setStepIndex(stepIndex - 1);
  };

  if (socialRegistration) {
    return <SocialRegistration />;
  }

  return (
    <main className="main-default">
      <section>
        <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <div className="md:tw-card w-full space-y-6 md:max-w-md md:p-8">
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 dark:text-white lg:text-2xl">
              {activeTab}
            </h1>
            {activeTab === RegistrationSteps.EMAIL && (
              <EmailAndSocials
                onNext={(e: string) => {
                  setEmail(e);
                  nextStep();
                }}
                onSocialRegistration={() => setSocialRegistration(true)}
                prefilledEmail={searchParams.get("prefilledEmail")}
              />
            )}
            {activeTab === RegistrationSteps.PASSWORD && (
              <ChoosePassword 
                email={email} 
                onNext={nextStep}
                prefilledEmail={searchParams.get("prefilledEmail")}
              />
            )}
            {activeTab === RegistrationSteps.VERIFY_EMAIL && (
              <VerifyEmail email={email ?? ""} onNext={nextStep} />
            )}
            {activeTab === RegistrationSteps.SUCCESS && (
              <RegistrationSuccessful />
            )}
            <div id="dev-only" hidden>
              <div className="flex justify-between">
                <Button
                  className="text-left text-sm p-0"
                  onClick={previousStep}
                  variant="link"
                >
                  {stepIndex === 0 ? strings.cancel : strings.back}
                </Button>
                <Button
                  className="text-right text-sm p-0"
                  onClick={nextStep}
                  variant="link"
                >
                  {strings.next}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default UserRegistration;
