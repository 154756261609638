/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useReducer, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getGeneralError } from "../../../util/helperFunctions";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import InvoiceApi from "../../../api/InvoiceApi";
import { strings } from "../../../common/Strings/Strings";
import { Colors } from "../../../models/Colors";
import AddProducts from "./Products/AddProducts";
import PaymentAndCustomerInfo, {
  CustomerForm,
  OtherCustomerForm,
  PaymentForm,
} from "./PaymentAndCustomerInfo";
import CostCalculator from "../../../util/CostCalculator";
import { generateUUId } from "../../ePrescription/sections/finish/sendingRequestProcesser";
import { InvoiceRequest } from "../../../models/invoice/InvoiceRequest";
import ClinicInvoiceApi from "../../../api/ClinicInvoiceApi";
import SuccessModal from "./SuccessModal";
import LoaderInline from "../../../components/LoaderInline";
import { InvoiceCustomerResponse } from "../../../models/invoice/InvoiceCustomerResponse";
import ErrorModal from "./ErrorModal";
import logger from "../../../util/logger";
import { CountryResponse } from "../../../models/management/CountryResponse";
import CountryApi from "../../../api/CountryApi";
import { useClinic } from "../../../contexts/ClinicContext";
import { defaultCountryCodeOfClinic } from "../../../common/DefaultCountry";
import InvoiceCartUtils, { CartItem } from "../../../util/InvoiceCartUtils";
import AddedItems from "./AddedItems";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import CreateInvoiceActions from "./CreateInvoiceActions";
import Insurance, { InsuranceForm } from "./Insurance";
import { InsuranceInvoiceRequest } from "../../../models/invoice/InsuranceInvoiceRequest";
import Button from "../../../components/Button";
import { ClinicFeature } from "../../../models/clinic/ClinicFeature";

interface Props {
  invoiceId?: string;
  clinicId?: string;
  type: "finish" | "copy";
  setPageLoading: (isLoading: boolean) => void;
}

enum PageState {
  AddItems,
  PaymentAndInfo,
  Loading,
}

const CreateInvoice: React.FC<Props> = (props: Props) => {
  const { invoiceId, clinicId, type, setPageLoading } = props;

  const { clinic, isFeatureAvailable } = useClinic();
  const navigate = useNavigate();

  const [countries, setCountries] = useState<CountryResponse[]>([]);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();

  const defaultCountryCode = defaultCountryCodeOfClinic(clinic);

  const defaultCountry = countries?.find(
    (c) => c.countryCode === defaultCountryCode
  );

  const [invoice, setInvoice] = useState<InvoiceResponse>();
  const [invoiceResponse, setInvoiceResponse] = useState<InvoiceResponse>();

  const [error, setError] = useState<string | null>(null);
  const [pageState, setPageState] = useState<PageState>(PageState.AddItems);
  const [sum, setSum] = useState<number>(0);
  const [cart, dispatch] = useReducer(InvoiceCartUtils.reducer, []);
  const [currency, setCurrency] = useState<string>();

  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);

  const methods = useForm<
    PaymentForm & CustomerForm & OtherCustomerForm & InsuranceForm
  >({
    defaultValues: {
      country: defaultCountry ? [defaultCountry] : [],
      countryOther: defaultCountry ? [defaultCountry] : [],
      description: strings.insuranceTitle,
      vat: 0,
    },
  });

  const {
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    const getCountryDetails = async () => {
      if (!clinicId) {
        return;
      }

      try {
        const response = await CountryApi.getCountryDetailsOfClinic(clinicId);
        setCountryDetails(response.data);
        if (response.data.insurance) {
          setValue("description", response.data.insurance.defaultDescription);
          setValue("vat", response.data.insurance.vat);
          if (response.data.insurance.accountingCode) {
            setValue(
              "accountingCode",
              response.data.accountingCodes.filter(
                (ac) => ac.code === response.data.insurance?.accountingCode
              ) || []
            );
          }
        }
      } catch (err) {
        logger.error(err);
      }
    };

    void getCountryDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic]);

  useEffect(() => {
    if (defaultCountry) {
      setValue("country", [defaultCountry]);
      setValue("countryOther", [defaultCountry]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, defaultCountry, defaultCountryCode, methods]);

  useEffect(() => {
    setCurrency(invoice?.currency || clinic?.currency);
  }, [clinic, invoice]);

  const loadCountries = async () => {
    try {
      const resp = await CountryApi.getCountries();
      setCountries(resp.data);
    } catch (e) {
      logger.error(e);
    }
  };

  useEffect(() => {
    void loadCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const costItems = cart.map((item) => ({
      price: item.price,
      quantity: item.quantity,
      vat: item.vat,
    }));

    setSum(
      CostCalculator.getTotalGrossPriceOfInvoiceItems(
        costItems,
        countryDetails,
        clinic || undefined
      )
    );
  }, [cart, countryDetails, clinic]);

  const fillCustomerFormFromInvoice = (customer: InvoiceCustomerResponse) => {
    const {
      fullName,
      taxNumber,
      phoneNumber,
      email,
      city,
      zip,
      street,
      countryCode,
      organizationName,
      houseDetails,
    } = customer;

    if (fullName) {
      setValue("name", fullName);
    }
    if (organizationName) {
      setValue("name", organizationName);
    }
    if (taxNumber) {
      setValue("taxNumber", taxNumber);
    }
    if (phoneNumber) {
      setValue("phone", phoneNumber);
    }
    if (email) {
      setValue("email", email);
    }
    if (city) {
      setValue("city", city);
    }
    if (street) {
      setValue("street", street);
    }
    if (houseDetails) {
      setValue("houseDetails", houseDetails);
    }
    if (zip) {
      setValue("zip", zip);
    }
    const country: CountryResponse | undefined = countries.find(
      (c: CountryResponse) => c.countryCode === countryCode
    );
    if (countryCode && country) {
      setValue("country", [country]);
    }
  };

  const fillInsuranceDetails = (inv: InvoiceResponse) => {
    setValue(
      "company",
      inv?.pet?.insuranceCompany ? [inv?.pet?.insuranceCompany] : []
    );
  };

  const getInvoice = async () => {
    if (invoiceId) {
      setPageLoading(true);
      try {
        const response = await InvoiceApi.getInvoice(invoiceId);

        setInvoice(response.data);
        dispatch({
          type: "fill",
          itemsToAdd: response.data.items,
          enableDisabled: type === "finish",
        });

        fillCustomerFormFromInvoice(response.data.customer);
        fillInsuranceDetails(response.data);
      } catch (err) {
        logger.error(err);
        setError(await getGeneralError(err));
        setErrorModal(true);
      } finally {
        setPageLoading(false);
      }
    }
  };

  useEffect(() => {
    if (invoiceId) {
      void getInvoice();
    } else {
      setPageLoading(false);
    }
    /* eslint-disable-next-line */
  }, [invoiceId]);

  const addToCart = (item: CartItem) => {
    dispatch({
      type: "add",
      itemToAdd: { ...item, localId: item.localId || generateUUId() },
    });
  };

  const changeQuantity = (item: CartItem) => {
    dispatch({ type: "changeQuantity", itemToChange: item });
  };

  const changePrice = (item: CartItem) => {
    dispatch({ type: "changePrice", itemToChange: item });
  };

  const removeItem = (item: CartItem) => {
    dispatch({ type: "remove", itemToRemove: item });
  };

  const stateNumberIcon = (stateNumber: number, active: boolean) => {
    if (active) {
      return (
        <div
          className="text-white mr-2"
          style={{
            backgroundColor: Colors.PRIMARY,
            borderRadius: "50%",
            padding: "9px 15px 9px 15px",
            border: "2px solid",
            borderColor: Colors.PRIMARY,
          }}
        >
          {stateNumber}
        </div>
      );
    }
    return (
      <div
        className="text-sky-500 mr-2"
        style={{
          borderRadius: "50%",
          padding: "9px 15px 9px 15px",
          border: "2px solid",
          borderColor: Colors.PRIMARY,
        }}
      >
        {stateNumber}
      </div>
    );
  };

  const sendInvoice = async (request: InvoiceRequest) => {
    setPageState(PageState.Loading);
    if (clinicId) {
      try {
        let response;
        if (invoice?.id && type === "finish") {
          response = await InvoiceApi.billInvoice(invoice.id, request);
          setInvoiceResponse(response.data[0]);
        } else {
          response = await ClinicInvoiceApi.createInvoice(clinicId, request);
          setInvoiceResponse(response.data[0]);
        }
        setSuccessModal(true);
      } catch (err) {
        logger.error(err);
        setError(await getGeneralError(err));
        setErrorModal(true);
      } finally {
        setPageState(PageState.PaymentAndInfo);
      }
    }
  };

  const deleteInvoice = async () => {
    if (!invoice) return;
    setPageLoading(false);

    try {
      await InvoiceApi.deletePendingInvoice(invoice.id);
      navigate(`/sales`);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setPageLoading(false);
    }
  };

  const submit = ({
    customerUserId,
    country,
    isBillingInfoTheSame,
    houseDetails,
    name,
    phone,
    email,
    city,
    cityOther,
    countryOther,
    emailOther,
    houseDetailsOther,
    nameOther,
    invoicingMethod,
    payLater,
    paymentMethod,
    dueDate,
    phoneOther,
    street,
    streetOther,
    taxNumber,
    taxNumberOther,
    zip,
    zipOther,
    insuredAmount,
    vat,
    description,
    accountingCode,
    on,
    company,
  }: PaymentForm & CustomerForm & OtherCustomerForm & InsuranceForm) => {
    const filteredCart = [...cart.filter((item: CartItem) => !item.disabled)];
    const earlierAddedItems = [
      ...cart.filter((item: CartItem) => item.disabled),
    ];

    const insuredNetAmount = CostCalculator.getNetPrice(insuredAmount, vat);
    const insurance: InsuranceInvoiceRequest | undefined = on
      ? {
          insuredAmount: insuredNetAmount,
          accountingCode: accountingCode[0].code,
          description,
          insuranceCompanyId: company[0].id,
          vat,
        }
      : undefined;

    if (isBillingInfoTheSame) {
      const request: InvoiceRequest = {
        customerUserId:
          customerUserId?.toString().length === 0 ? undefined : customerUserId,
        customer: {
          name,
          taxNumber,
          email,
          phone,
          street,
          houseDetails,
          zip,
          city,
          countryCode:
            country?.length === 1 ? country[0].countryCode : undefined,
        },
        dueDate: payLater ? dueDate : undefined,
        insurance,
        invoicingMethod: invoicingMethod,
        items: filteredCart,
        paidImmediately: !payLater,
        paymentMethodId: paymentMethod[0].id,
        updatedItems: earlierAddedItems,
      };

      void sendInvoice(request);
    } else {
      const request: InvoiceRequest = {
        customer: {
          name: nameOther,
          taxNumber: taxNumberOther,
          email: emailOther,
          phone: phoneOther,
          street: streetOther,
          houseDetails: houseDetailsOther,
          zip: zipOther,
          city: cityOther,
          countryCode:
            countryOther?.length === 1
              ? countryOther[0].countryCode
              : undefined,
        },
        dueDate: payLater ? dueDate : undefined,
        insurance,
        invoicingMethod: invoicingMethod,
        items: filteredCart,
        paidImmediately: !payLater,
        paymentMethodId: paymentMethod[0].id,
        updatedItems: earlierAddedItems,
      };
      void sendInvoice(request);
    }
  };

  const buttonStates = () => {
    switch (pageState) {
      case PageState.Loading:
        return <LoaderInline className="ml-auto" />;
      case PageState.AddItems:
        return (
          <Button
            className="w-100"
            onClick={() => {
              setPageState(PageState.PaymentAndInfo);
              window.scrollTo(0, 0);
            }}
            disabled={cart.length === 0}
          >
            {strings.continue}
          </Button>
        );
      default:
        return (
          <Button
            onClick={handleSubmit(submit)}
            className="w-100"
            disabled={!isDirty || cart.length === 0}
          >
            {strings.finish}
          </Button>
        );
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div className="flex justify-between">
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.createInvoice}
            </h1>
            <div className="flex space-x-4">
              {invoiceId && (
                <CreateInvoiceActions
                  deleteInvoice={() => void deleteInvoice()}
                />
              )}
            </div>
          </div>
          <div className="grid lg:grid-cols-2 gap-4">
            <div hidden={pageState !== PageState.AddItems}>
              <div className="tw-card">
                <AddProducts
                  clinic={clinic || undefined}
                  countryDetails={countryDetails}
                  addToCart={addToCart}
                />
              </div>
            </div>
            <div className="" hidden={pageState === PageState.AddItems}>
              <div hidden={pageState === PageState.AddItems}>
                <FormProvider {...methods}>
                  <form>
                    <PaymentAndCustomerInfo
                      countries={countries}
                      invoice={invoice}
                    />
                  </form>
                </FormProvider>
              </div>
            </div>
            <div>
              <AddedItems
                removeItem={removeItem}
                changeQuantity={changeQuantity}
                sum={sum}
                cart={cart}
                changePrice={changePrice}
                currency={currency}
                country={countryDetails}
                clinic={clinic || undefined}
              />
              {isFeatureAvailable(ClinicFeature.SPLIT_INVOICE) && (
                <FormProvider {...methods}>
                  <form>
                    <Insurance
                      countryDetails={countryDetails}
                      sum={sum}
                      cart={cart}
                      clinic={clinic || undefined}
                    />
                  </form>
                </FormProvider>
              )}
            </div>
          </div>
          <div className="my-5" />
        </div>
      </section>
      <section
        className="fixed-bottom bg-white dark:bg-gray-700"
        style={{
          paddingRight: "2%",
          paddingLeft: "2%",
          boxShadow: "0px 0px 12px rgba(48, 48, 48, 0.08)",
        }}
      >
        <div className="container">
          <div className="row p-0 py-3 m-0">
            <div className="col-8 m-0 p-0 pr-3 flex items-center">
              <div
                className="pr-3"
                style={{
                  borderRight: "2px solid",
                  borderColor: Colors.BLOCKBORDER,
                }}
              >
                <Button
                  variant="link"
                  onClick={() => {
                    setPageState(PageState.AddItems);
                  }}
                >
                  <div className="flex items-center text-sm md:text-normal">
                    <div className="hidden md:flex items-center">
                      {stateNumberIcon(1, pageState === PageState.AddItems)}
                    </div>
                    {strings.addProducts}
                  </div>
                </Button>
              </div>
              <div className="pl-3">
                <Button
                  variant="link"
                  onClick={() => {
                    setPageState(PageState.PaymentAndInfo);
                  }}
                >
                  <div className="flex items-center text-sm md:text-normal">
                    <div className="hidden md:flex items-center">
                      {stateNumberIcon(
                        2,
                        pageState === PageState.PaymentAndInfo
                      )}
                    </div>
                    {strings.paymentAndCustomerInfo}
                  </div>
                </Button>
              </div>
            </div>
            <div className="col-4 m-0 pr-0 flex items-center">
              {buttonStates()}
            </div>
          </div>
        </div>
      </section>
      <SuccessModal
        isOpen={successModal}
        close={() => {
          setSuccessModal(false);
        }}
        invoice={invoiceResponse}
      />
      <ErrorModal
        isOpen={errorModal}
        close={() => {
          setErrorModal(false);
        }}
        error={error}
      />
    </main>
  );
};

export default CreateInvoice;
