/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import logger from "../../../../util/logger";
import AlertBox from "../../../../components/AlertBox";
import LoaderInline from "../../../../components/LoaderInline";
import MedicalRecordApi from "../../../../api/MedicalRecordApi";
import { MedicalRecordResponse } from "../../../../models/medical/MedicalRecordResponse";
import { useClinic } from "../../../../contexts/ClinicContext";
import PrescriptionApi from "../../../../api/PrescriptionApi";
import { NewPrescriptionStatus } from "../../../../models/ePrescription/response/NewPrescriptionStatus";
import { HistoryData } from "../../../../models/ePrescription/history/HistoryData";
import { strings } from "../../../../common/Strings/Strings";
import { PetDetailsResponse } from "../../../../models/pet/PetDetailsResponse";
import UserMedicalRecordRow from "./Rows/UserMedicalRecordRow";
import Filters from "../../../../components/ReactHookFormFields/General/Filters";
import { MergedHistory, RowType } from "../PetHistory";
import EmptyListText from "../../../../components/EmptyListText";
import UserPrescriptionRow from "./Rows/UserPrescriptionRow";

interface Props {
  limitResults?: number;
  pet?: PetDetailsResponse;
}

const UserPetHistory: React.FC<Props> = ({
  limitResults = 100,
  pet,
}: Props) => {
  const [medicalRecords, setMedicalRecords] = useState<MedicalRecordResponse[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [prescriptions, setPrescriptions] = useState<HistoryData[]>([]);
  const [loadingPrescriptions, setLoadingPrescriptions] =
    useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [triggerReload] = useState<boolean>(false);
  const [mergedList, setMergedList] = useState<Array<MergedHistory>>([]);
  const { clinic } = useClinic();

  const { control, watch } = useForm({ defaultValues: { filters: ["all"] } });
  const filters = watch("filters");

  const getMedicalRecords = async () => {
    setMedicalRecords([]);

    if (
      pet?.id !== undefined &&
      !!filters.find((f) => f === "all" || f === RowType.MedicalRecord)
    ) {
      setLoading(true);
      try {
        const resp = await MedicalRecordApi.getMedicalRecordsOfUserPet(pet.id);
        setMedicalRecords(resp.data);
      } catch (err) {
        setError(strings.couldNotRetrieveMedicalRecords);
        logger.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const getPrescriptions = async () => {
    setPrescriptions([]);
    if (
      pet?.id &&
      clinic &&
      !!filters.find((f) => f === "all" || f === RowType.Prescription)
    ) {
      setLoadingPrescriptions(true);
      try {
        const response = await PrescriptionApi.getMyPetPrescriptionsById(
          pet.id,
          undefined,
          undefined,
          [NewPrescriptionStatus.ACCEPTED, NewPrescriptionStatus.WARNINGS]
        );
        setPrescriptions(response.data.elements);
      } catch (err) {
        setError(strings.couldNotRetrievePrescriptions);
        logger.error(err);
      } finally {
        setLoadingPrescriptions(false);
      }
    }
  };

  useEffect(() => {
    if (pet?.id !== undefined) {
      void getMedicalRecords();
      void getPrescriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pet, clinic, triggerReload, filters]);

  useEffect(() => {
    if (!loading && !loadingPrescriptions) {
      const newMerged: MergedHistory[] = [
        ...medicalRecords.map((mr) => ({
          type: RowType.MedicalRecord,
          value: mr,
          dateTime: mr.previousVersionId
            ? moment(mr.reservationDateTime).add(1, "minute").format()
            : mr.reservationDateTime,
        })),
        ...prescriptions.map((p) => ({
          type: RowType.Prescription,
          value: p,
          dateTime: moment.unix(p.creationTimestamp).format(),
        })),
      ];

      newMerged.sort(
        (a, b) => moment(b.dateTime).valueOf() - moment(a.dateTime).valueOf()
      );
      setMergedList(newMerged);
    }
  }, [medicalRecords, prescriptions, loading, loadingPrescriptions]);

  return (
    <>
      <AlertBox message={error} className="my-3" />
      {loading || loadingPrescriptions ? (
        <LoaderInline className="m-3" />
      ) : (
        <div className="space-y-4">
          <div className="flex flex-wrap">
            <form>
              <Filters
                control={control}
                defaultFilters={["all"]}
                name="filters"
                options={[
                  {
                    value: "all",
                    title: strings.all,
                  },
                  {
                    value: RowType.MedicalRecord,
                    title: strings.medicalRecords,
                  },
                  { value: RowType.Prescription, title: strings.prescriptions },
                ]}
              />
            </form>
          </div>
          <div className="tw-card divide-y dark:divide-gray-700">
            {mergedList.length === 0 ? (
              <div className="p-4">
                <EmptyListText text={strings.noResult} />
              </div>
            ) : (
              <></>
            )}
            {mergedList.slice(0, limitResults).map((item: MergedHistory) => {
              if (item.type === RowType.MedicalRecord) {
                return (
                  <Fragment key={item.value.id}>
                    <UserMedicalRecordRow medicalRecord={item.value} />
                  </Fragment>
                );
              }
              if (item.type === RowType.Prescription) {
                return (
                  <Fragment key={item.value.id}>
                    <UserPrescriptionRow prescription={item.value} />
                  </Fragment>
                );
              }
              return <></>;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default UserPetHistory;
