/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Flowbite, Navbar } from "flowbite-react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useClinic } from "../../../../contexts/ClinicContext";
import { ClinicFeature } from "../../../../models/clinic/ClinicFeature";
import { Environments } from "../../../../models/Environments";
import { DoctorMenuItem } from "../../MenuItem/MenuItemTypes";
import UserMenu from "../../UserMenu/UserMenu";
import ClinicDropdown from "./ClinicDropdown";
import MainPetSearchSection from "./MainPetSearchSection";
import { customTheme } from "../../Themes/NavTheme";
import DoctorNavItem from "./DoctorNavItem";
import DoctorMenuItems from "../../MenuItem/DoctorMenuItems";
import ForeignClinicWarning from "./ForeignClinicWarning";

interface Props {
  activeMenu?: DoctorMenuItem;
  environment: Environments;
  isSecondNavbarDisabled?: boolean;
}

const NavDoctor: React.FC<Props> = ({
  activeMenu,
  environment,
  isSecondNavbarDisabled = false,
}: Props) => {
  const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");

  const { clinic, isFeatureAvailable } = useClinic();

  const isMenuItemVisible = (cf: ClinicFeature | null) => {
    if (cf === null) {
      return true;
    }
    return isFeatureAvailable(cf);
  };

  return (
    <>
      <Flowbite theme={{ theme: customTheme, dark: darkMode }}>
        <Navbar
          className="fixed z-30 top-0 left-0 w-full h-14 px-4 xl:px-6 flex items-center bg-gray-600 dark:bg-gray-800 xl:bg-slate-500 dark:xl:bg-gray-800 dark:border-b dark:border-gray-700"
          fluid
          id="doctor-navbar"
        >
          <ClinicDropdown environment={environment} />
          <UserMenu />
        </Navbar>
      </Flowbite>
      {isSecondNavbarDisabled || !clinic ? (
        <></>
      ) : (
        <nav
          className="fixed z-20 top-14 left-0 shadow w-full h-14 px-4 lg:px-6 hidden xl:flex items-center bg-white dark:bg-gray-800 dark:border-b dark:border-gray-700"
          id="doctor-second-navbar"
        >
          <div className="container flex flex-wrap items-center justify-between xl:justify-start mx-auto px-0">
            <MainPetSearchSection />
            <div className="flex items-center justify-between hidden w-full xl:flex xl:w-auto xl:order-1 ml-8">
              {clinic && (
                <ul className="flex flex-row space-x-8 font-medium">
                  {DoctorMenuItems.map((item, index) => {
                    if (isMenuItemVisible(item.clinicFeature)) {
                      return (
                        <li key={index}>
                          <DoctorNavItem
                            menu={item.type}
                            activeMenu={activeMenu}
                            href={item.href}
                            visible={isMenuItemVisible(item.clinicFeature)}
                          />
                        </li>
                      );
                    }
                    return <></>;
                  })}
                </ul>
              )}
            </div>
          </div>
        </nav>
      )}
      <ForeignClinicWarning />
    </>
  );
};

export default NavDoctor;
