/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { LocationDTO } from "../models/geo/LocationDTO";
import Params from "../common/Params";
import { GMapGeoCodingResult } from "../models/geo/gmap/GMapGeoCodingResult";
import { Address } from "../models/contact/Address";

type GoogleResponse = any;

export default class GeoApi {
  private static baseUrl = Params.geoService;

  static async getGeoFromAddresses(
    adds: Address[]
  ): ApiPromise<GoogleResponse> {
    const body = adds.map((a) => ({
      address: `${a.countryCode}, ${a.city}, ${a.street}, ${a.zip}`,
    }));
    const url = `${this.baseUrl}/api/geo`;
    return axiosCommon.post(url, body);
  }

  static async reverseLookupLocations(
    locations: LocationDTO[]
  ): ApiPromise<GMapGeoCodingResult[][]> {
    const url = `${this.baseUrl}/api/reverse`;
    return axiosCommon.post(url, locations);
  }

  static getLocationFromResponse(response: GoogleResponse[]): LocationDTO {
    const { location } = response[0].geometry;
    return {
      lat: location.lat,
      lng: location.lng,
    };
  }

  static getAddressStringFromResponse(response: GoogleResponse[]): string {
    return response[0].formattedAddress;
  }
}
