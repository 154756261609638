import { AnimalCarer } from "../../models/ePrescription/sending/AnimalCarer";
import { strings } from "../../common/Strings/Strings";
import sendingRequestProcesser from "../ePrescription/sections/finish/sendingRequestProcesser";
import { Animal } from "../../models/ePrescription/sending/Animal";

export const getOwnerName = (a: AnimalCarer): string => {
  if (a.organizationName) {
    return a.organizationName;
  }
  return `${a.givenName} ${a.familyName}`;
};

export const getOwnerId = (a: AnimalCarer): string => {
  if (a.organizationId) {
    return `${strings.organisationId}: ${a.organizationId}`;
  }
  if (a.personalNumber) {
    return `${strings.personalId}: ${a.personalNumber}`;
  }
  if (a.birthDate) {
    return `${
      strings.dateOfBirth
    }: ${sendingRequestProcesser.reverseFormatBirthDate(a.birthDate)}`;
  }

  return "";
};

export const getAnimalIds = (a: Animal): string => {
  let ids = "";
  a.animalIds.forEach((animal: string) => {
    ids = `${ids + animal}, `;
  });

  return ids.slice(0, -2);
};

export default { getAnimalIds, getOwnerId, getOwnerName };
