/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import SimpleInput from "../../../components/InputFieldsSimple/SimpleInput";
import CostCalculator from "../../../util/CostCalculator";
import NumberFormatter from "../../../util/NumberFormatter";
import {
  CartItem,
  LocalInvoiceItemRequest,
} from "../../../util/InvoiceCartUtils";
import Prices from "../../../components/InputFieldsSimple/Prices";
import { Colors } from "../../../models/Colors";
import EmptyListText from "../../../components/EmptyListText";
import { XMark } from "../../../common/Icons/XMark";
import Button from "../../../components/Button";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";

interface Props {
  cart: LocalInvoiceItemRequest[];
  removeItem: (item: CartItem) => void;
  changePrice: (item: CartItem) => void;
  changeQuantity: (item: CartItem) => void;
  currency?: string;
  country?: CountryDetailsResponse;
  clinic?: ClinicResponse;
  sum: number;
}

const AddedItems = ({
  cart,
  removeItem,
  changePrice,
  changeQuantity,
  currency,
  country,
  clinic,
  sum,
}: Props): ReactElement => (
  <div className="tw-card mt-3 md:mt-0 mb-5 mb-md-0">
    <div>
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
        {strings.itemsAdded}
        <div className="ml-auto">
          <div
            hidden={cart.length === 0}
            style={{
              backgroundColor: Colors.NAVBARMEDIUM,
              width: "25px",
              height: "25px",
              fontSize: "14px",
              fontWeight: 700,
              borderRadius: "50%",
              color: "white",
              textAlign: "center",
              marginTop: "1px",
            }}
          >
            {cart.length}
          </div>
        </div>
      </h2>
    </div>
    <div>
      {cart.length === 0 ? (
        <div className="p-4">
          <EmptyListText text={strings.noItemsAdded} />
        </div>
      ) : (
        <>
          {cart.map((item: CartItem, index: number) => {
            const { name, price, quantity, vat } = item;
            return (
              <div
                className={
                  index % 2 === 0
                    ? "bg-gray-100 dark:bg-gray-900"
                    : "bg-transparent"
                }
                key={index}
              >
                <div className="flex flex-wrap lg:flex-nowrap items-center pt-3 px-4">
                  <div className="font-bold">{name}</div>
                  <div className="ml-auto">
                    <div className="flex">
                      <div className="pr-3">
                        <SimpleInput
                          className="m-0"
                          label={strings.quantity}
                          name="quantity"
                          onChange={(newValue: string) => {
                            changeQuantity({
                              ...item,
                              quantity: Number(newValue),
                            });
                          }}
                          suffix={item.unit && quantityUnits[item.unit]}
                          readOnly={item.disabled}
                          type="number"
                          value={quantity.toString()}
                          width="80px"
                        />
                      </div>
                      <Prices
                        className="m-0"
                        currency={currency}
                        label={strings.price}
                        onChange={(newValue: number | undefined) => {
                          changePrice({
                            ...item,
                            price: newValue || 0,
                          });
                        }}
                        roundingStrategy={
                          clinic?.roundingStrategyItem ||
                          country?.roundingStrategyItem
                        }
                        value={item.price}
                        vat={item.vat}
                        width="100px"
                      />
                      <div className="ml-3" style={{ marginTop: "22px" }}>
                        <Button
                          variant="basic"
                          disabled={item.disabled}
                          onClick={() => {
                            removeItem(item);
                          }}
                        >
                          <XMark />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-3 text-gray-500 pb-3 px-4">
                  <div>
                    {strings.subtotal} ({strings.gross})
                  </div>
                  <div className="ml-auto">
                    {NumberFormatter.formatPrice(
                      CostCalculator.getGrossPriceOfItem(
                        {
                          ...item,
                          quantity,
                          vat,
                          price,
                        },
                        country,
                        clinic
                      ),
                      currency
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="flex border-t p-4">
            <div>
              <div>
                {strings.total} ({strings.net})
              </div>
              <div className="font-bold">
                {strings.total} ({strings.gross})
              </div>
            </div>
            <div className="ml-auto text-right">
              <div>
                {NumberFormatter.formatPrice(
                  CostCalculator.getTotalNetPriceOfInvoiceItems(
                    cart,
                    country,
                    clinic
                  ),
                  currency
                )}
              </div>
              <div className="font-bold">
                {NumberFormatter.formatPrice(sum, currency)}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  </div>
);

export default AddedItems;

AddedItems.defaultProps = {
  currency: undefined,
};
