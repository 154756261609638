/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { BillingSystemConfigResponse } from "../../../models/management/BillingSystemConfigResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";

interface Props {
  countryCode: string;
  billingConfig?: BillingSystemConfigResponse;
}

const FortnoxIntegration: React.FC<Props> = (props: Props) => {
  const { countryCode, billingConfig } = props;

  return (
    <div className="tw-card">
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
        {strings.fortnoxInvoiceSystem}
      </h2>
      <div className="p-4 space-y-4">
        {billingConfig?.fortnoxIntegrationComplete ? (
          <AlertBox
            type={AlertType.SUCCESS}
            message={strings.integrated}
            closeAble={false}
          />
        ) : (
          <AlertBox
            type={AlertType.ERROR}
            message={strings.authenticationNeeded}
            closeAble={false}
          />
        )}
        <div className="">
          <Link to={`/management/countries/${countryCode}/fortnox-auth`}>
            {strings.authenticate}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FortnoxIntegration;
