/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import VetApi from "../../../api/VetApi";
import { strings } from "../../../common/Strings/Strings";
import logger from "../../../util/logger";
import { useUser } from "../../../contexts/UserContext";
import { pickUserFile } from "../../../util/FileUtils";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Button from "../../../components/Button";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import DocumentsUploadPageHeader from "./DocumentsUploadPageHeader";

interface Props {
  onSuccess(): void;
}

const AcceptedDocumentsTypes = [".jpg", ".png", ".pdf"];

export const DocumentsUploadPage = ({ onSuccess }: Props): ReactElement => {
  const { user, setUser } = useUser();

  const [documents, setDocuments] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [successShown, setSuccessShown] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const clearDocuments = () => {
    setDocuments([]);
  };

  const onSubmit = async () => {
    setUploading(true);

    try {
      const r = await VetApi.uploadDocumentToProveVetProfession(documents[0]);
      setUser(r.data);
      clearDocuments();
      setSuccessShown(true);
    } catch (e) {
      setError(await getGeneralError(e));
      logger.error(e);
    } finally {
      setUploading(false);
    }
  };

  const onChoose = async () => {
    const files = await pickUserFile(AcceptedDocumentsTypes.join(", "), false);
    setDocuments(files || []);
  };

  if (
    user.vetProfession === null ||
    (user?.vetProfession?.state === "PENDING" &&
      user?.vetProfession?.proofDocuments.length === 0)
  ) {
    return (
      <>
        <DocumentsUploadPageHeader
          documents={documents}
          onChoose={onChoose}
          setDocuments={setDocuments}
        />
        <AlertBox message={error} className="w-100 mt-3" />
        <div className="mt-4 w-100">
          <Button
            disabled={!documents[0]}
            loading={uploading}
            onClick={onSubmit}
          >
            {strings.submitDocument}
          </Button>
        </div>
      </>
    );
  }

  if (user?.vetProfession?.state === "PENDING" || successShown) {
    return (
      <>
        <AlertBox
          closeAble={false}
          message={strings.vetDocumentSubmitted}
          type={AlertType.SUCCESS}
        />
        <div className="mt-4 w-full">
          <Button type="button" className="w-full" onClick={onSuccess}>
            {strings.ok}
          </Button>
        </div>
      </>
    );
  }

  if (user?.vetProfession?.state === "REJECTED" && !successShown) {
    return (
      <>
        <DocumentsUploadPageHeader
          documents={documents}
          onChoose={onChoose}
          setDocuments={setDocuments}
        />
        <AlertBox
          className="mt-3 w-full"
          closeAble={false}
          message={
            <div>
              <div className="bold">{strings.vetDocumentsWereRejected}</div>
              <div>{user?.vetProfession?.rejectionMessage}</div>
            </div>
          }
        />
        <AlertBox message={error} className="w-full mt-3" />
        <div className="mt-4 w-full">
          <Button
            className="w-full"
            disabled={!documents[0]}
            loading={uploading}
            onClick={onSubmit}
          >
            {strings.submitDocument}
          </Button>
        </div>
      </>
    );
  }

  return (
    <div className="text-green-600 dark:text-green-600">
      <CheckCircle className="h-6 w-6 flex-shrink-0" />
    </div>
  );
};
