/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import StripeSection from "../../Stripe/StripeSection";
import OnlinePaymentForm, {
  OnlinePaymentFormRef,
} from "../../Stripe/OnlinePaymentForm";
import { getGeneralError } from "../../../util/helperFunctions";
import { PaymentRequest } from "../../../models/costaccounting/PaymentRequest";
import LoaderInline from "../../../components/LoaderInline";
import NumberFormatter from "../../../util/NumberFormatter";
import { ClinicChargeResponse } from "../../../models/charge/ClinicChargeResponse";
import ChargeApi from "../../../api/ChargeApi";
import StripeApi from "../../../api/StripeApi";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import ClinicApi from "../../../api/ClinicApi";
import Button from "../../../components/Button";
import AlertBox from "../../../components/AlertBox";
import Modal from "../../../components/Modal/Modal";
import CloseButton from "../../../components/CloseButton";
import HorizontalLine from "../../../components/HorizontalLine";

interface Props {
  isOpen: boolean;
  close: () => void;
  onChargeUpdated: (invoice: ClinicChargeResponse) => void;
  monthlyCharge: ClinicChargeResponse;
}

const MonthlyChargePaymentModal: React.FC<Props> = ({
  isOpen,
  close,
  onChargeUpdated,
  monthlyCharge,
}: Props) => {
  const [stripeApiKey, setStripeApiKey] = useState<string>();
  const [clinic, setClinic] = useState<ClinicResponse>();
  const [error, setError] = useState<string | null>(null);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<"registered" | "new">();

  const onlinePaymentRef = useRef<OnlinePaymentFormRef>(null);

  const closeModal = () => {
    if (!loading) {
      close();
    }
  };

  useEffect(() => {
    const getClinicAndStripeApiKey = async () => {
      try {
        setLoading(true);
        const response = await ClinicApi.getClinic(monthlyCharge.clinicId);

        setClinic(response.data);
        if (response.data.countryCode) {
          const apiKeyResponse = await StripeApi.getStripePublicKeyForGlobalVet(
            response.data.countryCode
          );
          setStripeApiKey(apiKeyResponse.data);
        }
        setLoading(false);
      } catch (err) {
        setError(await getGeneralError(err));
        setLoading(false);
      }
    };

    if (!isOpen) {
      return;
    }

    setSelectedCard(undefined);
    setError(null);

    void getClinicAndStripeApiKey();
  }, [isOpen, monthlyCharge]);

  const payMonthlyCharge = async (request?: PaymentRequest) => {
    setLoading(true);

    try {
      const response = await ChargeApi.payClinicCharge(
        monthlyCharge.clinicId,
        monthlyCharge.id,
        request
      );

      onChargeUpdated(response.data);
      setLoading(false);
      closeModal();
    } catch (e) {
      const err: any = e;
      if (
        err?.response?.data?.id === "ACTION_REQUIRED" &&
        err?.response?.data?.clientSecret
      ) {
        // eslint-disable-next-line no-use-before-define
        confirmPayment(err.response.data.clientSecret);
      } else {
        setError(await getGeneralError(err));
        setLoading(false);
      }
    }
  };

  const submitPayment = (request: PaymentRequest) => {
    void payMonthlyCharge(request);
  };

  const startPayment = (ev: any) => {
    ev.preventDefault();
    setLoading(true);

    const startPaymentCall = onlinePaymentRef?.current?.createPaymentMethod();

    if (!startPaymentCall) {
      return;
    }

    startPaymentCall
      .then((response) => {
        if (response.error) {
          setError(response?.error?.message ?? strings.simpleError);
          setLoading(false);
        } else {
          const request = {
            paymentMethodId: response?.paymentMethod?.id,
            cardHolder: onlinePaymentRef?.current?.getCardHolder() || "",
          };
          submitPayment(request);
        }
      })
      .catch((err) => {
        setError(err ?? strings.simpleError);
        setLoading(false);
      });
  };

  const confirmPayment = (clientSecret: string) => {
    setLoading(true);

    const confirmPaymentCall =
      onlinePaymentRef?.current?.confirmPayment(clientSecret);

    if (!confirmPaymentCall) {
      return;
    }

    confirmPaymentCall
      .then((response) => {
        if (response.error) {
          setError(response?.error?.message ?? strings.simpleError);
          setLoading(false);
        } else {
          const request = {
            paymentIntentId: response?.paymentIntent?.id,
            cardHolder: onlinePaymentRef?.current?.getCardHolder() || "",
          };
          submitPayment(request);
        }
      })
      .catch((err) => {
        setError(err ?? strings.simpleError);
        setLoading(false);
      });
  };

  return (
    <Modal handleClose={closeModal} show={isOpen}>
      <Modal.Header title={strings.onlinePayment} />
      <Modal.Body>
        <div hidden={!loading} className="p-3 align-middle">
          <LoaderInline />
        </div>
        <div hidden={loading}>
          <div className="flex p-3">
            <div className="font-weight-bold">{strings.amountToPay}:</div>
            <div className="ml-auto">
              {NumberFormatter.formatPrice(
                monthlyCharge.total || 0,
                monthlyCharge.currency
              )}
            </div>
          </div>
          <HorizontalLine />
          <div hidden={selectedCard !== undefined}>
            <div className="m-3 font-weight-bold">
              {strings.creditCardDetails}:
            </div>
            <div className="my-5 text-center">
              {clinic?.last4 && (
                <div>
                  <button
                    className="btn btn-link border text-decoration-none"
                    type="button"
                    onClick={() => {
                      setSelectedCard("registered");
                      void payMonthlyCharge();
                    }}
                  >
                    <div className="inline-flex">
                      <i className="fa fa-credit-card mr-5" />
                      <div className="text-dark text-right">
                        **** **** **** {clinic.last4}
                      </div>
                    </div>
                    <div className="text-muted text-right">
                      {clinic?.cardHolder}
                    </div>
                  </button>
                </div>
              )}
              <div className="mt-2">
                <Button
                  variant="link"
                  type="button"
                  onClick={() => setSelectedCard("new")}
                >
                  {strings.payWithDifferentCard}
                </Button>
              </div>
            </div>
          </div>

          <div hidden={selectedCard !== "new"}>
            <div>
              <div className="m-3 font-weight-bold">
                {strings.creditCardDetails}:
              </div>
            </div>
            <div className="mb-3">
              {stripeApiKey && (
                <StripeSection apiKey={stripeApiKey}>
                  <OnlinePaymentForm
                    ref={onlinePaymentRef}
                    onValidation={(isValid) => setFormValid(isValid)}
                  />
                </StripeSection>
              )}
            </div>
          </div>
          <AlertBox message={error} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!formValid || loading}
          hidden={selectedCard !== "new"}
          loading={loading}
          onClick={(e: any) => startPayment(e)}
        >
          {strings.pay}
        </Button>
        <CloseButton onClick={close} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default MonthlyChargePaymentModal;
