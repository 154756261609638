/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { PrescriptionItemFrontend } from "../../../models/ePrescription/local/PrescriptionItemFrontend";
import { LicensedPrescriptionItemFrontend } from "../../../models/ePrescription/local/LicensedPrescriptionItemFrontend";
import LicenseUpdateModal from "../modals/LicesnseUpdateModal";
import requiredFieldChecks from "../sections/finish/sendingRequestProcesser";
import { GeoPharmacy } from "../../../models/pharmacy/GeoPharmacy";
import { PackageType } from "../../../models/ePrescription/VARA/PackageType";
import { CommodityPrescriptionItemFrontend } from "../../../models/ePrescription/local/CommodityPrescriptionItemFrontend";
import UpdateModal from "../modals/UpdateModal";
import CommodityUpdateModal from "../modals/CommodityUpdateModal";
import { Pencil } from "../../../common/Icons/Pencil";
import Button from "../../../components/Button";
import { XMark } from "../../../common/Icons/XMark";
import EmptyListText from "../../../components/EmptyListText";

interface Props {
  geoPharmacies: GeoPharmacy[];
  packageTypes: PackageType[];
  prescription: PrescriptionItemFrontend[];
  licensedPrescription: LicensedPrescriptionItemFrontend[];
  commodityPrescription: CommodityPrescriptionItemFrontend[];
  updatePrescription(updateItem: PrescriptionItemFrontend): void;
  deletePrescriptionItem(id: number): void;
  updateLicensedPrescription(
    updateItem: LicensedPrescriptionItemFrontend
  ): void;
  deleteLicensedPrescriptionItem(id: number): void;
  updateCommodityPrescription(
    updateItem: CommodityPrescriptionItemFrontend
  ): void;
  deleteCommodityPrescriptionItem(id: number): void;
}

const PrescriptionItemList: React.FC<Props> = ({
  geoPharmacies,
  packageTypes,
  prescription,
  licensedPrescription,
  commodityPrescription,
  updatePrescription,
  deletePrescriptionItem,
  updateLicensedPrescription,
  deleteLicensedPrescriptionItem,
  updateCommodityPrescription,
  deleteCommodityPrescriptionItem,
}: Props) => {
  const [editPrescription, setEditPrescription] =
    useState<PrescriptionItemFrontend>();
  const [editLicensedPrescription, setEditLicensedPrescription] =
    useState<LicensedPrescriptionItemFrontend>();
  const [editCommodityPrescription, setEditCommodityPrescription] =
    useState<CommodityPrescriptionItemFrontend>();
  const [editModal, setEditModal] = useState<boolean>(false);
  const [editLicenseModal, setEditLicenseModal] = useState<boolean>(false);
  const [editCommodityModal, setEditCommodityModal] = useState<boolean>(false);

  const openEditModal = (p: PrescriptionItemFrontend, i: number): void => {
    p.id = i;
    setEditPrescription(p);
    setEditModal(true);
  };

  const openLicenseEditModal = (
    p: LicensedPrescriptionItemFrontend,
    i: number
  ): void => {
    p.id = i;
    setEditLicensedPrescription(p);
    setEditLicenseModal(true);
  };

  const openCommodityEditModal = (
    p: CommodityPrescriptionItemFrontend,
    i: number
  ): void => {
    p.id = i;
    setEditCommodityPrescription(p);
    setEditCommodityModal(true);
  };

  return (
    <>
      <div className="tw-card space-y-4 p-4">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
          {strings.prescriptionItem}
        </h2>
        <div className="divide-y dark:divide-gray-700">
          {prescription.length === 0 &&
          licensedPrescription.length === 0 &&
          commodityPrescription.length === 0 ? (
            <EmptyListText text={strings.notAddedYet} />
          ) : (
            <></>
          )}
          <div className="divide-y dark:divide-gray-700" hidden={prescription.length === 0}>
            {prescription.map(
              (presc: PrescriptionItemFrontend, index: number) => (
                <div className="py-2" key={index}>
                  <div className="flex">
                    <div className="font-bold">
                      {presc.product.name} ({presc.product.strength})
                    </div>
                    <div className="ml-auto space-x-3 flex flex-no-wrap">
                      <Button
                        onClick={() => {
                          openEditModal(presc, index);
                        }}
                        variant="icon"
                      >
                        <Pencil />
                      </Button>
                      <Button
                        variant="icon"
                        onClick={() => {
                          deletePrescriptionItem(index);
                        }}
                      >
                        <XMark />
                      </Button>
                    </div>
                  </div>
                  <div>
                    {requiredFieldChecks.generateTextFromIntervals(
                      presc.dosageText
                    )}
                  </div>
                </div>
              )
            )}
          </div>
          <div className="divide-y dark:divide-gray-700" hidden={licensedPrescription.length === 0}>
            {licensedPrescription.map(
              (presc: LicensedPrescriptionItemFrontend, index: number) => (
                <div className="py-2" key={index}>
                  <div className="flex flex-nowrap">
                    <div className="font-bold">
                      {presc?.freeTextLicense?.supplementaryInformation}
                    </div>
                    <div className="ml-auto space-x-3 flex flex-no-wrap">
                      <Button
                        onClick={() => {
                          openLicenseEditModal(presc, index);
                        }}
                        variant="icon"
                      >
                        <Pencil />
                      </Button>
                      <Button
                        onClick={() => {
                          deleteLicensedPrescriptionItem(index);
                        }}
                        variant="icon"
                      >
                        <XMark />
                      </Button>
                    </div>
                  </div>
                  <div>
                    {requiredFieldChecks.generateTextFromIntervals(
                      presc.dosageText
                    )}
                  </div>
                </div>
              )
            )}
          </div>
          <div className="divide-y dark:divide-gray-700" hidden={commodityPrescription.length === 0}>
            {commodityPrescription.map(
              (presc: CommodityPrescriptionItemFrontend, index: number) => (
                <div className="py-2" key={index}>
                  <div className="flex">
                    <div className="font-bold">{presc.product.name}</div>
                    <div className="ml-auto space-x-3 flex flex-no-wrap">
                      <Button
                        onClick={() => {
                          openCommodityEditModal(presc, index);
                        }}
                        variant="icon"
                      >
                        <Pencil />
                      </Button>
                      <Button
                        variant="icon"
                        onClick={() => {
                          deleteCommodityPrescriptionItem(index);
                        }}
                      >
                        <XMark />
                      </Button>
                    </div>
                  </div>
                  <div>
                    {requiredFieldChecks.generateTextFromIntervals(
                      presc.dosageText
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <UpdateModal
        geoPharmacies={geoPharmacies}
        open={editModal}
        forEdit={editPrescription}
        updatePrescription={(updateItem: PrescriptionItemFrontend) => {
          updatePrescription(updateItem);
          setEditModal(false);
          setEditPrescription(undefined);
        }}
        close={() => {
          setEditModal(false);
        }}
        packageTypes={packageTypes}
      />
      <LicenseUpdateModal
        geoPharmacies={geoPharmacies}
        open={editLicenseModal}
        forEdit={editLicensedPrescription}
        updateLicensedPrescription={(
          updateItem: LicensedPrescriptionItemFrontend
        ) => {
          updateLicensedPrescription(updateItem);
          setEditLicenseModal(false);
          setEditLicensedPrescription(undefined);
        }}
        close={() => {
          setEditLicenseModal(false);
        }}
      />
      <CommodityUpdateModal
        geoPharmacies={geoPharmacies}
        open={editCommodityModal}
        forEdit={editCommodityPrescription}
        updateCommodityPrescription={(
          updateItem: CommodityPrescriptionItemFrontend
        ) => {
          updateCommodityPrescription(updateItem);
          setEditCommodityModal(false);
          setEditCommodityPrescription(undefined);
        }}
        close={() => {
          setEditCommodityModal(false);
        }}
        packageTypes={packageTypes}
      />
    </>
  );
};

export default PrescriptionItemList;
