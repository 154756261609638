/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useParams } from "react-router-dom";
import ContactsConfirmationApi from "../../../api/ContactsConfirmationApi";
import UserApi from "../../../api/UserApi";
import EmailConfirmation from "./EmailConfirmation";
import AlertBox from "../../../components/AlertBox";
import { strings } from "../../../common/Strings/Strings";

const UserEmailConfirmation: React.FC = () => {
  const params = useParams<{ emailId: string; token: string }>();
  const emailId = params.emailId;
  const token = params.token;

  const getTokenAndConfirmEmail = async () => {
    if (emailId && token) {
      await ContactsConfirmationApi.confirmEmail(emailId, token);
    }
  };

  if (emailId && token) {
    return (
      <main className="main-default">
        <section>
          <EmailConfirmation
            confirm={getTokenAndConfirmEmail}
            emailId={emailId}
            resendMail={(id) =>
              UserApi.sendRegistrationConfirmationEmailById(id)
            }
            token={token}
          />
        </section>
      </main>
    );
  }

  return <AlertBox message={strings.errorDuringEmailVerification} />;
};

export default UserEmailConfirmation;
