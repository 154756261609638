/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { CalendarReservation } from "../../../../models/calendar/CalendarReservation";
import Column from "./Column";
import { CalendarRowsByColumn } from "../../../../models/calendar/Rendering/CalendarRowsByColumn";
import SimpleSelect from "../../../../components/InputFieldsSimple/SimpleSelect";
import MedicalRecordComponent from "../../../MedicalRecord/MedicalRecordComponent";
import { Option } from "../../../../models/Option";
import { useUser } from "../../../../contexts/UserContext";
import { dateAndTime, strings } from "../../../../common/Strings/Strings";
import { ViewTypes } from "../../MainCalendar";
import {
  momentDateFormat,
  unitCellHeight,
} from "../../calendarConfig";
import { TodayShift } from "../../../../models/calendar/TodayShift";
import { mergeTimeAndDate } from "../../../../util/helperFunctions";
import { ReservationResponse } from "../../../../models/reservation/ReservationResponse";
import { useDeepLinking } from "../../../../hooks/DeepLinking";

interface Props {
  day: string;
  handleNewReservationModal(
    open: boolean,
    dateAndTime?: { time: string; date: string },
    selectedShift?: TodayShift,
  ): void;
  refreshReservationInProgress(): void;
  reloadReservations(): void;
  schedule: CalendarRowsByColumn;
  times: string[];
}

const DoctorDashboard: React.FC<Props> = ({
  day,
  handleNewReservationModal,
  refreshReservationInProgress,
  reloadReservations,
  schedule,
  times,
}: Props) => {
  const [selectedShiftColumnKey, setSelectedShiftColumnKey] =
    useState<string>("column0");
  const [selectedReservation, setSelectedReservation] =
    useState<CalendarReservation>();
  const [collaborators, setCollaborators] = useState<Option[]>([]);
  const { user } = useUser();
  const [lastOpenedReservationId, setLastOpenedReservationId] = useDeepLinking<
    { id: string } | undefined
  >(undefined, false);
  const [actualTimeHeight, setActualTimeHeight] = useState<number>(0);
  const [calendarStartTime, setCalendarStartTime] = useState<moment.Moment>();
  const [calendarEndTime, setCalendarEndTime] = useState<moment.Moment>();
  const refCalendarColumn = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const firstStartTime =
      schedule[selectedShiftColumnKey]?.columnShift?.firstStartTime;

    const lastEndTime =
      schedule[selectedShiftColumnKey]?.columnShift?.lastEndTime;

    if (firstStartTime) {
      setCalendarStartTime(moment(firstStartTime));
    }

    if (lastEndTime) {
      setCalendarEndTime(moment(lastEndTime));
    }
  }, [selectedShiftColumnKey]);

  useEffect(() => {
    const options = Object.entries(schedule).map((row) => {
      if (row[1].columnShift?.collaboratorUserId === user.userId) {
        setSelectedShiftColumnKey(row[0]);
      }
      const name = row[1].columnShift?.collaboratorName;
      const startTime = row[1].columnShift?.firstStartTime;
      const endTime = row[1].columnShift?.lastEndTime;

      return {
        value: row[0],
        title: `${name || ""} (${
          startTime && moment(startTime).format(dateAndTime.timeFormat)
        } - ${endTime && moment(endTime).format(dateAndTime.timeFormat)})`,
      };
    });

    setCollaborators(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  useEffect(() => {
    if (calendarStartTime) {
      const height: number =
        Math.abs(calendarStartTime.diff(moment(), "minutes")) * unitCellHeight;
      setActualTimeHeight(height);

      if (moment().isSame(moment(day), "days")) {
        const calendarHeight: number =
          refCalendarColumn?.current?.clientHeight || 0;
        refCalendarColumn?.current?.scrollTo(0, height - calendarHeight / 2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarStartTime]);

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="shadow relative col-span-12 xl:col-span-4 bg-white dark:bg-gray-800 p-6">
        <div className="flex flex-col space-y-6">
          <SimpleSelect
            name="vets"
            label={strings.employee}
            options={collaborators}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSelectedShiftColumnKey(e.target.value);
            }}
            isChooseOption={false}
            value={selectedShiftColumnKey}
          />
          <div className="grid grid-cols-12 relative" ref={refCalendarColumn}>
            <div
              style={{
                display:
                  !moment().isSame(moment(day), "days") ||
                  moment().isAfter(calendarEndTime, "minutes") ||
                  moment().isBefore(calendarStartTime, "minutes")
                    ? "none"
                    : "initial",
                top: `${actualTimeHeight}px`,
              }}
              className="absolute z-10 w-full h-0.5 bg-red-500 dark:bg-red-400"
            >
              <div className="rounded-full -ml-2 -mt-1.5 w-3.5 h-3.5 bg-red-500 dark:bg-red-400"></div>
            </div>
            <Column
              clickEmptySlot={(time: string) => {
                let clickedShift: TodayShift | undefined;
                schedule[
                  selectedShiftColumnKey
                ]?.columnShift?.mergedShifts?.forEach((shift: TodayShift) => {
                  if (
                    moment(mergeTimeAndDate(time, new Date(day))).isBetween(
                      moment(shift.startTime),
                      moment(shift.endTime),
                      "minute",
                      "[]",
                    )
                  ) {
                    clickedShift = shift;
                  }
                });
                const dayAndTime = {
                  date: moment(day).format(momentDateFormat),
                  time,
                };
                handleNewReservationModal(true, dayAndTime, clickedShift);
              }}
              column={schedule[selectedShiftColumnKey]}
              day={day}
              disableHeader
              openMedicalRecord={(reservation: CalendarReservation) => {
                setSelectedReservation(reservation);
              }}
              times={times}
              viewType={ViewTypes.DOCTOR_DAY}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-0 xl:space-y-6 col-span-12 xl:col-span-8 pt-2 lg:pt-6">
        {selectedReservation || lastOpenedReservationId?.id ? (
          <MedicalRecordComponent
            isPage={false}
            isUserNotOwner
            setPageLoading={() => {}}
            recordId={selectedReservation?.lastMedicalRecordId}
            refreshReservationInProgress={refreshReservationInProgress}
            reloadReservations={() => {
              reloadReservations();
            }}
            returnReservation={(res: ReservationResponse) => {
              setLastOpenedReservationId({ id: res.id });
            }}
            resId={
              selectedReservation?.id ||
              lastOpenedReservationId?.id ||
              undefined
            }
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DoctorDashboard;
