/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import DiscountCard from "../Discounts/DiscountCard";
import { Card } from "../../../components/Cards/Card";
import EditDiscountModal from "../Discounts/EditDiscountModal";
import { ClinicDiscountResponse } from "../../../models/discount/ClinicDiscountResponse";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import ClinicDiscountApi from "../../../api/ClinicDiscountApi";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import BeforeDeleteModal from "../../../components/Modals/BeforeDeleteModal";
import { DiscountsResponse } from "../../../models/discount/DiscountsResponse";
import { TrialDiscountResponse } from "../../../models/discount/TrialDiscountResponse";
import EditTrialDiscountModal from "../Discounts/EditTrialDiscountModal";

interface Props {
  clinicId: string;
}

const ClinicDiscountsSection: React.FC<Props> = (props: Props) => {
  const { clinicId } = props;
  const [discounts, setDiscounts] = useState<DiscountsResponse>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [isDiscountModalVisible, setDiscountModalVisible] =
    useState<boolean>(false);
  const [isDiscountDeleteModalVisible, setDiscountDeleteModalVisible] =
    useState<boolean>(false);
  const [selectedDiscount, setSelectedDiscount] =
    useState<ClinicDiscountResponse>();

  const [isTrialDiscountModalVisible, setTrialDiscountModalVisible] =
    useState<boolean>(false);
  const [
    isTrialDiscountDeleteModalVisible,
    setTrialDiscountDeleteModalVisible,
  ] = useState<boolean>(false);
  const [selectedTrialDiscount, setSelectedTrialDiscount] =
    useState<TrialDiscountResponse>();

  const getDiscounts = async () => {
    setLoading(true);

    try {
      const response = await ClinicDiscountApi.getDiscountsOfClinic(clinicId);
      setDiscounts(response.data);
      setError(null);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getDiscounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicId]);

  const openDiscountModal = (discount?: ClinicDiscountResponse) => {
    setSelectedDiscount(discount);
    setDiscountModalVisible(true);
  };

  const closeDiscountModal = () => {
    setDiscountModalVisible(false);
    setSelectedDiscount(undefined);
  };

  const openDiscountDeleteModal = (discount: ClinicDiscountResponse) => {
    setSelectedDiscount(discount);
    setDiscountDeleteModalVisible(true);
  };

  const closeDiscountDeleteModal = () => {
    setDiscountDeleteModalVisible(false);
  };

  const deleteDiscount = async () => {
    closeDiscountDeleteModal();
    if (!selectedDiscount) {
      return;
    }

    setLoading(true);

    try {
      await ClinicDiscountApi.deleteDiscount(selectedDiscount.id);
      setError(null);
      await getDiscounts();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  const openTrialDiscountModal = (discount?: TrialDiscountResponse) => {
    setSelectedTrialDiscount(discount);
    setTrialDiscountModalVisible(true);
  };

  const closeTrialDiscountModal = () => {
    setTrialDiscountModalVisible(false);
    setSelectedTrialDiscount(undefined);
  };

  const openTrialDiscountDeleteModal = (discount: TrialDiscountResponse) => {
    setSelectedTrialDiscount(discount);
    setTrialDiscountDeleteModalVisible(true);
  };

  const closeTrialDiscountDeleteModal = () => {
    setTrialDiscountDeleteModalVisible(false);
  };

  const deleteTrialDiscount = async () => {
    closeTrialDiscountDeleteModal();
    if (!selectedTrialDiscount) {
      return;
    }

    setLoading(true);

    try {
      await ClinicDiscountApi.deleteTrialDiscount(selectedTrialDiscount.id);
      setError(null);
      await getDiscounts();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        size="max"
        title={strings.discounts}
        type="simple"
        loading={isLoading}
        titleButtonContent={<PlusIcon />}
        titleButtonOnClick={() => openDiscountModal(undefined)}
        titleButtonVariant="icon"
      >
        <AlertBox message={error} />
        <div className="grid grid-cols-12 gap-6 my-4">
          {discounts?.discounts.map((discount) => (
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
              key={discount.id}
            >
              <DiscountCard
                clinicDiscount={discount}
                clinicDiscountClicked={() => openDiscountModal(discount)}
                clinicDiscountDeleteClicked={() =>
                  openDiscountDeleteModal(discount)
                }
              />
            </div>
          ))}
          {discounts?.trialDiscounts.map((discount) => (
            <div
              className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3"
              key={discount.id}
            >
              <DiscountCard
                trialDiscount={discount}
                trialDiscountClicked={() => openTrialDiscountModal(discount)}
                trialDiscountDeleteClicked={() =>
                  openTrialDiscountDeleteModal(discount)
                }
              />
            </div>
          ))}
        </div>
      </Card>
      <EditDiscountModal
        getDiscounts={getDiscounts}
        onHide={closeDiscountModal}
        selectedDiscount={selectedDiscount}
        selectedClinicId={clinicId}
        show={isDiscountModalVisible}
      />
      <EditTrialDiscountModal
        getDiscounts={getDiscounts}
        onHide={closeTrialDiscountModal}
        selectedTrialDiscount={selectedTrialDiscount}
        show={isTrialDiscountModalVisible}
      />
      <BeforeDeleteModal
        open={isDiscountDeleteModalVisible}
        onHide={closeDiscountDeleteModal}
        returnYes={deleteDiscount}
      />
      <BeforeDeleteModal
        open={isTrialDiscountDeleteModalVisible}
        onHide={closeTrialDiscountDeleteModal}
        returnYes={deleteTrialDiscount}
      />
    </>
  );
};

export default ClinicDiscountsSection;
