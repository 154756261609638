/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import AddReservationTypeForm from "./AddReservationTypeForm";
import EditReservationTypeForm from "./EditReservationTypeForm";
import { strings } from "../../../../common/Strings/Strings";
import {
  canManageClinic,
  getGeneralError,
} from "../../../../util/helperFunctions";
import { ReservationTypeResponse } from "../../../../models/reservationtype/ReservationTypeResponse";
import ReservationTypeApi from "../../../../api/ReservationTypeApi";
import { ReservationTypeRequest } from "../../../../models/reservationtype/ReservationTypeRequest";
import LoaderInline from "../../../../components/LoaderInline";
import AlertBox from "../../../../components/AlertBox";
import { ClinicEmploymentType } from "../../../../models/employment/ClinicEmploymentType";
import BeforeDeleteModal from "../../../../components/Modals/BeforeDeleteModal";
import Button from "../../../../components/Button";
import { PlusIcon } from "../../../../common/Icons/PlusIcon";
import { Trash } from "../../../../common/Icons/Trash";
import { Pencil } from "../../../../common/Icons/Pencil";
import EmptyListText from "../../../../components/EmptyListText";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import { VideoCamera } from "../../../../common/Icons/VideoCamera";
import Tooltip from "../../../../components/Tooltip";
import { CalendarDays } from "../../../../common/Icons/CalendarDays";

interface Props {
  clinic: ClinicResponse;
  isActiveTab: boolean;
}

const ReservationTypes: React.FC<Props> = (props: Props) => {
  const { clinic, isActiveTab } = props;
  const [reservationTypes, setReservationTypes] = useState<
    ReservationTypeResponse[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const [modal, setModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [selectedReservationType, setSelectedReservationType] =
    useState<ReservationTypeResponse>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [areYouSureToDeleteModal, setAreYouSureToDeleteModal] =
    useState<ReservationTypeResponse>();

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const openEdit = (editReservation: ReservationTypeResponse) => {
    setSelectedReservationType(editReservation);
    setEdit(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  useEffect(() => {
    const getReservationTypes = async () => {
      try {
        const response = await ReservationTypeApi.getReservationTypesOfClinic(
          clinic.id
        );
        setReservationTypes(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setIsLoaded(true);
      }
    };

    if (!isActiveTab) {
      return;
    }

    setIsLoaded(false);

    void getReservationTypes();
  }, [clinic, isActiveTab]);

  const postReservationType = async (
    reservationType: ReservationTypeRequest,
    onSuccess: () => void,
    onFailure: (err: AxiosError) => void
  ) => {
    try {
      const resp = await ReservationTypeApi.addReservationType(
        clinic.id,
        reservationType
      );
      setReservationTypes([...(reservationTypes ?? []), resp.data]);
      onSuccess();
    } catch (e) {
      const err: any = e;
      onFailure(err);
    }
  };

  const deleteReservationType = async (
    reservationType: ReservationTypeResponse
  ) => {
    try {
      await ReservationTypeApi.removeReservationType(
        clinic.id,
        reservationType.id
      );
      const result = reservationTypes?.filter((it) => it !== reservationType);
      setReservationTypes(result);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  };

  const editReservationType = async (
    resTypeId: string,
    reservationType: ReservationTypeRequest,
    onSuccess: () => void,
    onFailure: (err: AxiosError) => void
  ) => {
    try {
      const resp = await ReservationTypeApi.updateReservationType(
        clinic.id,
        resTypeId,
        reservationType
      );
      const result = reservationTypes?.map((el) =>
        el.id === resTypeId ? resp.data : el
      );
      setReservationTypes(result);
      onSuccess();
    } catch (e) {
      const err: any = e;
      onFailure(err);
    }
  };

  const employmentTypeText = (type: ReservationTypeResponse): string => {
    const resultStrings: string[] = [];
    if (type.employmentTypes.includes(ClinicEmploymentType.VET))
      resultStrings.push(strings.vet);
    if (type.employmentTypes.includes(ClinicEmploymentType.ASSISTANT))
      resultStrings.push(strings.assistant);
    if (
      type.employmentTypes.includes(ClinicEmploymentType.AUTHORIZED_ASSISTANT)
    )
      resultStrings.push(strings.authorizedAssistant);
    return resultStrings.join(", ");
  };

  const readOnly = !canManageClinic(clinic);

  return (
    <div className="w-full md:tw-card md:max-w-2xl space-y-6 md:p-8">
      <div>
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
          {strings.reservationTypes}
          <div className="ml-auto">
            <LoaderInline hidden={isLoaded} />
          </div>
        </h2>
      </div>
      <div>{strings.reservationTypesToolTip}</div>
      <div>
        <Button variant="link" onClick={openModal} hidden={readOnly}>
          <div className="flex">
            <PlusIcon /> <span>{strings.addReservationType}</span>
          </div>
        </Button>
      </div>
      <AlertBox message={error} className="mb-3" />
      {!isLoaded ? (
        <div className="tw-table-container py-3.5 px-4">
          <LoaderInline />
        </div>
      ) : (
        <div className="tw-table-container">
          <table className="tw-table" id="accountingCodes">
            <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
              <tr>
                <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.name}
                </th>
                <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.time}
                </th>
                <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.color}
                </th>
                <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {strings.employmentType}
                </th>
                <th
                  hidden={readOnly}
                  className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                >
                  {strings.operations}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
              {reservationTypes?.length === 0 ? (
                <tr>
                  <td
                    className="px-4 py-3 text-sm font-medium whitespace-nowrap"
                    colSpan={5}
                  >
                    <EmptyListText text={strings.clinicHasNoReservationTypes} />
                  </td>
                </tr>
              ) : (
                <>
                  {reservationTypes?.map((reservationType, index) => (
                    <tr key={`email-${index}`}>
                      <td className="px-4 py-3 text-sm font-medium">
                        <div className="flex items-center space-x-2">
                          <div>{reservationType.name}</div>
                          <div hidden={!reservationType.isReservableByOwners}>
                            <Tooltip content={strings.isReservableByOwners}>
                              <CalendarDays className="w-5 h-5" />
                            </Tooltip>
                          </div>
                          <div
                            hidden={!reservationType.isVideoConsultationAllowed}
                          >
                            <Tooltip
                              content={strings.isVideoConsultationAllowed}
                            >
                              <VideoCamera
                                className="w-5 h-5"
                                variant="outline"
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </td>
                      <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                        {reservationType.minutes} min
                      </td>
                      <td className="px-4 py-3 text-sm font-medium">
                        <i
                          style={{ color: reservationType?.color }}
                          className="fas fa-circle"
                        />
                      </td>
                      <td className="px-4 py-3 text-sm font-medium">
                        {employmentTypeText(reservationType)}
                      </td>
                      <td
                        hidden={readOnly}
                        className="px-4 py-3 text-sm font-medium whitespace-nowrap text-right"
                      >
                        <Button
                          variant="icon"
                          onClick={() => {
                            openEdit(reservationType);
                          }}
                        >
                          <Pencil />
                        </Button>
                        <Button
                          className="tw-link ml-3"
                          variant="icon"
                          onClick={() => {
                            setAreYouSureToDeleteModal(reservationType);
                          }}
                        >
                          <Trash />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      )}
      <div>
        <Button variant="link" onClick={openModal} hidden={readOnly}>
          <div className="flex">
            <PlusIcon /> <span>{strings.addReservationType}</span>
          </div>
        </Button>
      </div>
      <AddReservationTypeForm
        onSubmit={postReservationType}
        close={closeModal}
        isOpen={modal}
      />
      <EditReservationTypeForm
        reservationType={selectedReservationType}
        onSubmit={editReservationType}
        close={closeEdit}
        isOpen={edit}
      />

      <BeforeDeleteModal
        content={strings.formatString(
          strings.areYouSureDeleteReservationType,
          <b>{areYouSureToDeleteModal?.name}</b>
        )}
        onHide={() => {
          setAreYouSureToDeleteModal(undefined);
        }}
        open={!!areYouSureToDeleteModal}
        returnYes={() => {
          if (areYouSureToDeleteModal) {
            void deleteReservationType(areYouSureToDeleteModal);
          }
          setAreYouSureToDeleteModal(undefined);
        }}
        title={strings.deleteReservationType}
      />
    </div>
  );
};

export default ReservationTypes;
