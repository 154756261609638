/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { ProductTypesObj } from "../fixValues/ProductTypes";
import Button from "../../../components/Button";
import { ChevronDown } from "../../../common/Icons/ChevronDown";
import { ChevronUp } from "../../../common/Icons/ChevronUp";
import { CommodityProduct } from "../../../models/ePrescription/VARA/CommodityProduct";

interface Props {
  medicine?: CommodityProduct;
}

const ProductInfoCommodity: React.FC<Props> = ({ medicine }: Props) => {
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  return (
    <div className="p-4 tw-card">
      <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
        {medicine?.name}
      </h2>
      <div>{medicine?.title}</div>
      <div hidden={!showMoreInfo}>
        <div className="grid grid-cols-12 gap-4 pt-2">
          <div className="col-span-6 md:col-span-2 flex flex-col">
            <div className="italic">{strings.marketed}</div>
            {medicine?.isOnTheMarket ? strings.yes : strings.no}
          </div>
          <div className="col-span-6 md:col-span-2 flex flex-col">
            <div className="italic"> {strings.prescriptionDrug}</div>
            {medicine?.prescriptionDrug}
          </div>
          <div className="col-span-6 md:col-span-6 flex flex-col">
            <div className="italic">{strings.productType}</div>
            {medicine?.productType
              ? ProductTypesObj[medicine?.productType]
              : ""}
          </div>
        </div>
      </div>
      <Button
        variant="link"
        onClick={() => {
          setShowMoreInfo((prev) => !prev);
        }}
      >
        {showMoreInfo ? (
          <div className="flex items-center">
            {strings.showLess}<ChevronUp className="h-5 w-5 ml-1" />
          </div>
        ) : (
          <div className="flex items-center">
            {strings.showMore}<ChevronDown className="h-5 w-5 ml-1" />
          </div>
        )}
      </Button>
    </div>
  );
};

export default ProductInfoCommodity;
