/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../../common/Strings/Strings";
import AlertBox from "../../../../../components/AlertBox";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { EmailRequest } from "../../../../../models/contact/EmailRequest";
import { ApiPromise } from "../../../../../models/ApiPromise";
import { EmailResponse } from "../../../../../models/contact/EmailResponse";
import { ContactOwner } from "./EmailOwnershipList";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal/Modal";

interface Props {
  addEmail: (ownerId: string, email: EmailRequest) => ApiPromise<ContactOwner>;
  onHide: () => void;
  owner: ContactOwner;
  setOwnerships: (emails: EmailResponse[]) => void;
  show: boolean;
}

const AddEmailModal: React.FC<Props> = ({
  addEmail,
  onHide,
  owner,
  setOwnerships,
  show,
}: Props) => {
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EmailRequest>({
    mode: "onChange",
  });

  useLayoutEffect(() => {
    if (show) {
      reset();
      setError(null);
    }
  }, [reset, show]);

  const submit = async (email: EmailRequest) => {
    setLoading(true);

    try {
      const response = await addEmail(owner.id, email);
      setOwnerships(response.data.emails);
      onHide();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.addEmail} />
      <Modal.Body>
        <form id="addEmailForm" onSubmit={handleSubmit(submit)}>
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.email}
            label={strings.yourEmail}
            name="email"
            placeholder={strings.emailAddress}
            register={register}
            required
            showRequired
            type="email"
          />
        </form>
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || loading}
          form="addEmailForm"
          fullWidth={false}
          loading={loading}
          type="submit"
        >
          {strings.addEmail}
        </Button>
        <Button
          className="modal-sub-button"
          disabled={loading}
          onClick={onHide}
          variant="secondary"
        >
          {strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEmailModal;
