/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import { PackageType } from "../../../models/ePrescription/VARA/PackageType";
import { StringMap } from "../../../models/StringMap";

export const ProductTypesArray: PackageType[] = [
  { label: "-", description: "Ospecificerad" },
  { label: "A58", description: "Article 58 WHO Läkemedel" },
  { label: "ALK", description: "Teknisk sprit" },
  { label: "ALL", description: "Allergen" },
  { label: "APL", description: "Extemporeläkemedel" },
  {
    label: "BER",
    description:
      "Extempore (enskilda extemporeberedningar, ej lagerberedningar och rikslicenser)"
  },
  {
    label: "FOR",
    description: "Förbrukningsartiklar som omfattas av förmånen"
  },
  { label: "HOM", description: "Homeopatiskt läkemedel" },
  { label: "IPL", description: "Intern produktlista för Apotekens Service AB" },
  { label: "LIC", description: "Licensläkemedel" },
  {
    label: "LLI",
    description:
      "Livsmedel f. ssk näringsändamål till vuxna över 16 år (momsplikt, landstingsbidrag)"
  },
  { label: "MEP", description: "Läkemedel" },
  {
    label: "MF",
    description: "Plasma Master File/Vaccine Antigen Master File"
  },
  { label: "MTK", description: "Medicintekniska produkter med läkemedel" },
  { label: "NIN", description: "Naturmedel för injektion" },
  { label: "NLM", description: "Naturläkemedel" },
  { label: "OVL", description: "Övriga landstingssubventionerade varor" },
  {
    label: "PLI",
    description: "Livsmedel f. ssk näringsändamål till barn under 16 år"
  },
  { label: "RAP", description: "Radiofarmaka" },
  { label: "TJA", description: "Tjänster" },
  { label: "TVB", description: "Traditionellt växtbaserade läkemedel" },
  { label: "VBL", description: "Växtbaserade läkemedel" },
  { label: "VUM", description: "Vissa utvärtes läkemedel" }
];

export const ProductTypesObj: StringMap = {
  "-": "Ospecificerad",
  A58: "Article 58 WHO Läkemedel",
  ALK: "Teknisk sprit",
  ALL: "Allergen",
  APL: "Extemporeläkemedel",
  BER:
    "Extempore (enskilda extemporeberedningar, ej lagerberedningar och rikslicenser)",
  FOR: "Förbrukningsartiklar som omfattas av förmånen",
  HOM: "Homeopatiskt läkemedel",
  IPL: "Intern produktlista för Apotekens Service AB",
  LIC: "Licensläkemedel",
  LLI:
    "Livsmedel f. ssk näringsändamål till vuxna över 16 år (momsplikt, landstingsbidrag)",
  MEP: "Läkemedel",
  MF: "Plasma Master File/Vaccine Antigen Master File",
  MTK: "Medicintekniska produkter med läkemedel",
  NIN: "Naturmedel för injektion",
  NLM: "Naturläkemedel",
  OVL: "Övriga landstingssubventionerade varor",
  PLI: "Livsmedel f. ssk näringsändamål till barn under 16 år",
  RAP: "Radiofarmaka",
  TJA: "Tjänster",
  TVB: "Traditionellt växtbaserade läkemedel",
  VBL: "Växtbaserade läkemedel",
  VUM: "Vissa utvärtes läkemedel"
};

export default { ProductTypesObj, ProductTypesArray };
