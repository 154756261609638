/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { Option } from "../../../models/Option";

const Tab = ({
  defaultValue = false,
  label,
  onChange,
  value = defaultValue,
}: {
  defaultValue?: boolean;
  label?: string;
  onChange(newValue: boolean): void;
  value: boolean;
}) => (
  <button
    onClick={() => {
      onChange(!value);
    }}
    type="button"
  >
    <li className={value ? "tw-tab-active" : "tw-tab-inactive"}>{label}</li>
  </button>
);

interface FieldProps {
  control: Control<any>;
  name?: string;
  defaultValue?: string | number;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  onChange?(newValue: string | number): void;
  options: Option[];
  required?: boolean;
}

const Tabs = ({
  control,
  name: n = "state",
  defaultValue,
  fieldOptions,
  onChange: customOnChange,
  options,
  required = false,
}: FieldProps): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  return (
    <div
      id={n}
      className="border border-gray-200 rounded-md bg-gray-50 !p-2 text-center text-md font-medium text-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300"
    >
      <ul className="flex flex-wrap -mb-px">
        <Controller
          name={n}
          control={control}
          rules={registerOptions}
          render={({ field: { onChange, value } }) => (
            <>
              {options.map((option: Option) => (
                <Fragment key={option.value}>
                  <Tab
                    defaultValue={defaultValue === option.value}
                    value={option.value === value}
                    label={option.title}
                    onChange={() => {
                      onChange(option.value);

                      if (customOnChange) {
                        customOnChange(option.value);
                      }
                    }}
                  />
                </Fragment>
              ))}
            </>
          )}
        />
      </ul>
    </div>
  );
};

export default Tabs;
