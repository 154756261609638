/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { PageProps } from "../../../models/PageProps";
import { Loader } from "../../../components/Loader";
import LoaderInline from "../../../components/LoaderInline";
import { useUser } from "../../../contexts/UserContext";
import ProfileSettingsMenu from "./ProfileSettingsMenu";
import ProfileSettingsTabs from "./ProfileSettingsTabs";
import { SettingsTabs } from "../../../models/user/SettingsTabs";

const ProfileSettings: React.FC<PageProps> = ({
  setPageLoading,
}: PageProps) => {
  const { user } = useUser();

  const [currentTab, setCurrentTab] = useState<SettingsTabs>(
    SettingsTabs.basicInfo
  );

  useEffect(() => {
    setPageLoading(false);
  }, [setPageLoading]);

  return !user ? (
    <LoaderInline />
  ) : (
    <main className="main-signed-in">
      <section>
        <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <div>
            <h1 className="flex items-center text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.profileSettings}
            </h1>
          </div>
          <div className="border border-gray-200 rounded-md bg-gray-50 !p-4 text-center text-md font-medium text-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300">
            <ProfileSettingsMenu currentTab={currentTab} />
          </div>
          <ProfileSettingsTabs setCurrentTab={setCurrentTab} />
        </div>
      </section>
    </main>
  );
};

export default Loader(ProfileSettings);
