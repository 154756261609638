/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
export enum Colors {
  DARK = "#2e2e2f",
  BLOCKBORDER ="#a7afba",
  CATEGORY_COLOR_2 = "#58CB7D",
  CATEGORY_COLOR_3 = "#F89454",
  CATEGORY_COLOR_4 = "#D6C954",
  INPUTBORDER = "#9c9ea0",
  LIGHTBLUE = "#e8f1ff",
  LIGHTGREY = "#fafafa",
  NAVBARMEDIUM = "#5f6f85",
  PRIMARY = "#0ca2fe",
  RED = "#f37676",
  TABLE_GROUP_BG = "#D0D4D8",
  TEXTLIGHT = "#797f83",
  WHITE = "#ffffff",
}
