/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import Moment from "react-moment";
import { getGeneralError } from "../../util/helperFunctions";
import { strings } from "../../common/Strings/Strings";
import { ReservationResponse } from "../../models/reservation/ReservationResponse";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../util/LocalStorageVariables";
import DeleteAppointmentModal from "./DeleteAppointmentModal";
import { ReservationDeletionRequest } from "../../models/reservation/ReservationDeletionRequest";
import ReservationApi from "../../api/ReservationApi";
import VersionSection from "./VersionHistory/VersionSection";
import CalendarDayReservationModal from "../Calendar/Reservation/CalendarDayReservationModal";
import { Pencil } from "../../common/Icons/Pencil";
import { Trash } from "../../common/Icons/Trash";
import Button from "../../components/Button";
import { Envelope } from "../../common/Icons/Envelope";
import { ArrowDownTray } from "../../common/Icons/ArrowDownTray";
import { PencilSquare } from "../../common/Icons/PencilSquare";
import SendInEmailModal from "../Pet/History/SendInEmailModal";
import { RowType } from "../Pet/History/PetHistory";
import { MedicalRecordsMailResponse } from "../../models/medical/mail/MedicalRecordsMailResponse";
import { useMainSectionContext } from "./MainSection/MainSectionContext";
import {
  downloadMedicalRecordPdfForReservation,
  printMedicalRecordPdf,
} from "../../util/FileUtils";
import Tooltip from "../../components/Tooltip";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import logger from "../../util/logger";
import { Printer } from "../../common/Icons/Printer";
import IconBubble from "../../components/IconBubble";
import { CalendarIcon } from "../../common/Icons/Calendar";
import { Link } from "react-router-dom";
import { VideoCamera } from "../../common/Icons/VideoCamera";
import moment from "moment";

interface Props {
  addCorrection(): void;
  cancellable: boolean;
  reload?(): void;
  reservationData?: ReservationResponse;
}

const ReservationSection = ({
  addCorrection,
  cancellable,
  reload,
  reservationData,
}: Props): ReactElement => {
  const role: UserRole | null = getValueOfActiveUser("role");
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [reservationInfoModalOpen, setReservationInfoModalOpen] =
    useState<boolean>(false);
  const [newEmail, setNewEmail] = useState<boolean>(false);
  const [isPrinting, setPrinting] = useState<boolean>(false);
  const {
    signed,
    isUserNotOwner,
    medicalRecord,
    medicalRecordVersions,
    setMedicalRecordVersions,
  } = useMainSectionContext();

  const openDeleteModal = () => {
    setModalDeleteIsOpen(true);
  };

  const closeDeleteModal = () => setModalDeleteIsOpen(false);

  const deleteReservation = async (values: { explanation: string }) => {
    const id = reservationData?.id;

    if (id) {
      const request: ReservationDeletionRequest = {
        explanation: values.explanation,
        employeeRequest: role === "doctor",
      };

      try {
        await ReservationApi.deleteReservation(id, request);
        window.location.href = "/";
      } catch (err) {
        setDeleteError(await getGeneralError(err));
      }
    }
  };

  return (
    <div className="lg:tw-card p-4 lg:p-6 flex flex-col space-y-4">
      <div className="flex justify-between">
        <div>
          <h1
            className={`text-xl font-semibold leading-tight ${
              reservationData?.isEmergency
                ? "text-red-600"
                : "text-zinc-800 dark:text-white"
            } lg:text-2xl`}
          >
            {reservationData?.isEmergency
              ? strings.emergencyAppointment
              : reservationData?.reservationType?.name || null}
            {reservationData?.pet?.name ? ` (${reservationData.pet.name})` : ""}
          </h1>
          {medicalRecord?.referenceId && (
            <span className="font-normal text-sm text-gray-500 dark:text-gray-400">
              {`${strings.medicalRecordId}: ${medicalRecord?.referenceId}`}
            </span>
          )}
        </div>
        <div className="flex">
          {!signed && cancellable ? (
            <>
              <div
                hidden={
                  !reservationData?.videoConsultationRoomId ||
                  (!!reservationData?.startDateTime &&
                    moment().isAfter(reservationData?.startDateTime, "days"))
                }
              >
                <Link
                  to={`/meet/${reservationData?.videoConsultationRoomId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="icon">
                    <VideoCamera variant="outline" />
                  </Button>
                </Link>
              </div>
              <Button
                className="tw-link ml-3"
                onClick={() => {
                  setReservationInfoModalOpen(true);
                }}
                variant="icon"
              >
                <Pencil />
              </Button>
              <Button
                className="ctw-link ml-3"
                onClick={() => {
                  openDeleteModal();
                }}
                variant="icon"
              >
                <Trash />
              </Button>
            </>
          ) : (
            <></>
          )}
          {signed ? (
            <>
              <Tooltip content={strings.downloadPDF}>
                <Button
                  className="tw-close tw-link ml-3"
                  variant="icon"
                  onClick={() => {
                    void downloadMedicalRecordPdfForReservation(
                      medicalRecord?.referenceId,
                      medicalRecord?.id
                    );
                  }}
                >
                  <ArrowDownTray />
                </Button>
              </Tooltip>
              <Tooltip content={strings.print}>
                <Button
                  className="tw-close tw-link ml-3"
                  disabled={isPrinting}
                  onClick={async () => {
                    setPrinting(true);
                    try {
                      if (medicalRecord) {
                        await printMedicalRecordPdf(medicalRecord?.id, () =>
                          setPrinting(false)
                        );
                      }
                    } catch (err) {
                      logger.error(err);
                      setPrinting(false);
                    }
                  }}
                  variant="icon"
                >
                  <Printer />
                </Button>
              </Tooltip>
              {
                isUserNotOwner &&
                <>
                  <Tooltip content={strings.attachToEmail}>
                    <Button
                      className="tw-close tw-link ml-3"
                      variant="icon"
                      onClick={() => {
                        setNewEmail(true);
                      }}
                    >
                      <Envelope variant="outline" />
                    </Button>
                  </Tooltip>
                  <Tooltip content={strings.openForCorrection}>
                    <Button
                      className="tw-close tw-link ml-3"
                      variant="icon"
                      onClick={addCorrection}
                    >
                      <PencilSquare />
                    </Button>
                  </Tooltip>
                </>
              }
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div hidden={!reservationData?.description}>
        <p>{reservationData?.description}</p>
      </div>
      <div className="flex flex-col space-y-6 xl:space-y-0 xl:flex-row xl:justify-between">
        <div className="flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:space-x-6 lg:items-center">
          <div className="flex space-x-3 items-center">
            <IconBubble>
              <CalendarIcon />
            </IconBubble>
            <div className="flex flex-col -space-y-1">
              <span className="font-semibold text-base text-zinc-800 dark:text-white">
                <Moment
                  format="LL"
                  locale={
                    strings.getLanguage() === "gb"
                      ? "en-gb"
                      : strings.getLanguage()
                  }
                >
                  {reservationData?.startDateTime}
                </Moment>
              </span>
              <span className="font-normal text-sm text-zinc-800 dark:text-white">
                <Moment format="LT">{reservationData?.startDateTime}</Moment>
                &nbsp;-&nbsp;
                <Moment format="LT">{reservationData?.endDateTime}</Moment>
              </span>
            </div>
          </div>
          <div className="flex space-x-3 items-center">
            <div className="relative rounded-full border-2 border-gray-100 bg-white w-10 h-10 flex-shrink-0 dark:border-gray-700">
              <UserProfilePicture
                userId={reservationData?.collaborator.userId}
              />
            </div>
            <div className="font-semibold text-base text-zinc-800 dark:text-white">
              {reservationData?.collaborator.fullName}
            </div>
          </div>
        </div>
        <VersionSection
          medicalRecordData={medicalRecord}
          medicalRecordVersions={medicalRecordVersions}
          reservationData={reservationData}
        />
      </div>
      <DeleteAppointmentModal
        cancellable={cancellable}
        closeDeleteModal={closeDeleteModal}
        deleteError={deleteError}
        deleteReservation={deleteReservation}
        modalDeleteIsOpen={modalDeleteIsOpen}
        reservation={reservationData}
      />
      {!medicalRecord?.signedBy && cancellable && reservationData ? (
        <CalendarDayReservationModal
          activeReservation={{ ...reservationData, cancellable }}
          closeModal={() => {
            setReservationInfoModalOpen(false);
          }}
          modalIsOpen={reservationInfoModalOpen}
          reload={() => {
            if (reload) {
              reload();
            }
          }}
        />
      ) : (
        <></>
      )}
      <SendInEmailModal
        close={() => {
          setNewEmail(false);
        }}
        isOpen={newEmail}
        pet={
          medicalRecord
            ? {
                clinicPetDetails: {
                  ...medicalRecord.pet,
                  chronicDiseases: medicalRecord.pet.chronicDiseases || [],
                },
              }
            : undefined
        }
        selectedDocuments={
          medicalRecord
            ? [
                {
                  dateTime: medicalRecord.creationDate,
                  value: medicalRecord,
                  type: RowType.MedicalRecord,
                },
              ]
            : []
        }
        updateMedicalRecordVersion={(newEmail: MedicalRecordsMailResponse) => {
          const list = [...medicalRecordVersions];
          list.forEach((v) => {
            if (v.id === medicalRecord?.id) {
              if (v.sentMails) {
                v.sentMails.push(newEmail);
              } else {
                v.sentMails = [newEmail];
              }
            }
          });
          setMedicalRecordVersions(list);
        }}
      />
    </div>
  );
};

export default ReservationSection;
