/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import CloseButton from "../../../components/CloseButton";
import { InsuranceCompanyResponse } from "../../../models/insurance/InsuranceCompanyResponse";
import { CountryResponse } from "../../../models/management/CountryResponse";
import AlertBox from "../../../components/AlertBox";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import PhoneNumber from "../../../components/ReactHookFormFields/Specific/PhoneNumber";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import InsuranceApi from "../../../api/InsuranceApi";
import { BackendFieldError } from "../../../models/BackendFieldError";
import Button from "../../../components/Button";

interface Props {
  show: boolean;
  onHide: () => void;
  refresh: () => void;
  countries: CountryResponse[];
  selectedCompany?: InsuranceCompanyResponse;
}

interface InsuranceCompanyForm {
  name: string;
  country?: CountryResponse[];
  city?: string;
  zip?: string;
  street?: string;
  phone?: string;
  fax?: string;
  email: string;
  paymentTerms?: string;
}

type InsuranceCompanyFormKeys = keyof InsuranceCompanyForm;

const defaultFormValues = (
  countries: CountryResponse[],
  company?: InsuranceCompanyResponse
) => {
  const country = countries.find((i) => i.countryCode === company?.countryCode);
  return {
    name: company?.name,
    country: company?.countryCode && country ? [country] : [],
    city: company?.city,
    zip: company?.zip,
    street: company?.street,
    phone: company?.phone,
    fax: company?.fax,
    email: company?.email,
    paymentTerms: company?.paymentTerms,
  };
};

const InsuranceCompanyEditorModal: React.FC<Props> = ({
  show,
  onHide,
  refresh,
  countries,
  selectedCompany,
}: Props) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<
    Array<BackendFieldError<InsuranceCompanyFormKeys>>
  >([]);

  const saveCompany = async (data: InsuranceCompanyForm) => {
    setLoading(true);
    try {
      if (selectedCompany) {
        await InsuranceApi.updateInsuranceCompany(selectedCompany.id, {
          countryCode: data.country && data.country[0].countryCode,
          ...data,
        });
      } else {
        await InsuranceApi.createInsuranceCompany({
          countryCode: data.country && data.country[0].countryCode,
          ...data,
        });
      }
      onHide();
      refresh();
    } catch (e) {
      const err: any = e;
      setErrorMessage(await getGeneralError(err));
      setFieldErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const {
    control,
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm<InsuranceCompanyForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(countries, selectedCompany),
  });

  useEffect(() => {
    if (show) {
      reset(defaultFormValues(countries, selectedCompany));
      setErrorMessage(null);
      setFieldErrors([]);
    }
  }, [countries, reset, selectedCompany, show]);

  useEffect(() => {
    fieldErrors.forEach(
      ({ field, message }: BackendFieldError<InsuranceCompanyFormKeys>) => {
        setError(field, { type: "manual", message });
      }
    );
  }, [fieldErrors, setError]);

  return (
    <Modal size="lg" show={show} onHide={onHide} backdrop="static">
      <form onSubmit={handleSubmit(saveCompany)}>
        <Modal.Header closeButton>
          <Modal.Title>{strings.insuranceCompany}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Field
              name="name"
              label={strings.name}
              register={register}
              autoComplete={AutoCompleteOptions.off}
              required
              error={errors.name}
            />
            <CombinedSelect
              allowNew={false}
              multiple={false}
              name="country"
              label={strings.country}
              labelKey="displayName"
              control={control}
              options={countries}
              placeholder={strings.chooseFromWithDots}
              optional
            />
            <Field
              name="city"
              label={strings.city}
              register={register}
              autoComplete={AutoCompleteOptions.addressLevel1}
              optional
              error={errors.name}
            />
            <Field
              name="zip"
              label={strings.zip}
              register={register}
              autoComplete={AutoCompleteOptions.postalCode}
              optional
              error={errors.zip}
            />
            <Field
              name="street"
              label={strings.addressLine1}
              register={register}
              autoComplete={AutoCompleteOptions.addressLine1}
              optional
              error={errors.street}
            />
            <PhoneNumber
              id="phone"
              name="phone"
              country={selectedCompany?.countryCode}
              label={strings.phone}
              control={control}
              autocomplete={AutoCompleteOptions.telNational}
              optional
            />
            <Field
              name="fax"
              label={strings.fax}
              register={register}
              fieldOptions={{
                pattern: {
                  value: /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/,
                  message: strings.phoneNotValid,
                },
              }}
              autoComplete={AutoCompleteOptions.off}
              optional
              error={errors.fax}
            />
            <Field
              name="email"
              type="email"
              label={strings.email}
              register={register}
              autoComplete={AutoCompleteOptions.email}
              required
              error={errors.email}
            />
            <Textarea
              name="paymentTerms"
              label={strings.paymentTerms}
              register={register}
              optional
              error={errors.paymentTerms}
            />
          </div>
          <AlertBox message={errorMessage} className="mb-2" />
        </Modal.Body>
        <Modal.Footer>
          <CloseButton onClick={onHide} />
          <Button
            className="ml-auto"
            disabled={!isValid || isLoading || (selectedCompany && !isDirty)}
            fullWidth={false}
            loading={isLoading}
            type="submit"
            variant="primary"
          >
            {strings.save}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default InsuranceCompanyEditorModal;
