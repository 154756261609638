/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment";
import { dateAndTime, strings } from "../../../../../common/Strings/Strings";
import { HistoryData } from "../../../../../models/ePrescription/history/HistoryData";
import { ExtendedPrescriptionItem } from "../../../../../models/ePrescription/persistence/ExtendedPrescriptionItem";
import Tag, { TagType } from "../../../../../components/Tag";

interface Props {
  prescription: HistoryData | any;
}

const UserPrescriptionRow: React.FC<Props> = ({ prescription }: Props) => (
    <div className="flex flex-wrap py-3" key={prescription.id}>
      <div className="col-12 col-lg-2">
        <div className="flex items-center">
          <div className="flex flex-col justify-center">
            <div className="uppercase text-sm">{strings.prescription}</div>
            <div className="font-bold">
              {moment
                .unix(prescription.creationTimestamp)
                .format(dateAndTime.momentDateFormat)}
            </div>
            <div hidden={prescription.cancellations.length === 0}>
              <Tag type={TagType.secondary} text={strings.withdrawn} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 flex flex-col justify-center">
        {prescription.model.prescription.items.map(
          (p: ExtendedPrescriptionItem) => (
              <div>
                <b>
                  {p.product
                    ? p.product.name
                    : p.licensed
                    ? p.licensed.supplementaryInformation
                    : strings.prescriptionItem}
                  {p.product?.strength || p.licensed?.strength
                    ? ` (${p.product?.strength || p.licensed?.strength})`
                    : ""}
                  :
                </b>
                &nbsp;{p.instructions.text}
              </div>
            )
        )}
      </div>
      <div className="col-12 col-lg-3 flex flex-col justify-center">
        {prescription.model.veterinaryInformation.givenName}&nbsp;
        {prescription.model.veterinaryInformation.familyName}
      </div>
    </div>
  );

export default UserPrescriptionRow;
