/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import Params from "../common/Params";

export default class PictureApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  /* User */
  static getUserProfilePictureByUserId(userId: string): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/users/${userId}/profile-picture`;
    return axiosCommon.get(url, {
      headers: {
        Accept: "*/*",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
      responseType: "arraybuffer",
    });
  }

  static setUserProfilePicture(file: File): ApiPromise<void> {
    const url = `${this.baseUrl}/user-profile-picture`;
    const formData = new FormData();
    formData.append("file", file);
    return axiosCommon.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: console.log,
    });
  }

  static deleteUserProfilePicture(): ApiPromise<any> {
    return axiosCommon.delete(`${this.baseUrl}/user-profile-picture`);
  }

  /* Clinic */
  static getClinicLogoPictureByClinicId(
    clinicId: string
  ): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/clinics/${clinicId}/logo-picture`;
    return axiosCommon.get(url, {
      headers: {
        Accept: "*/*",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
      responseType: "arraybuffer",
    });
  }

  static setClinicLogoPicture(clinicId: string, file: File): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/logo-picture?`;
    const formData = new FormData();
    formData.append("file", file);
    return axiosCommon.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: console.log,
    });
  }

  static deleteClinicLogoPicture(clinicId: string): ApiPromise<any> {
    return axiosCommon.delete(
      `${this.baseUrl}/clinics/${clinicId}/logo-picture`
    );
  }

  /* Pet */
  static getPetProfilePictureByPetId(petId: string): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/pets/${petId}/profile-picture`;
    return axiosCommon.get(url, {
      headers: {
        Accept: "*/*",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
      responseType: "arraybuffer",
    });
  }

  static setPetProfilePicture(petId: string, file: File): ApiPromise<void> {
    const url = `${this.baseUrl}/pets/${petId}/profile-picture`;
    const formData = new FormData();
    formData.append("file", file);
    return axiosCommon.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: console.log,
    });
  }

  static deletePetProfilePicture(petId: string): ApiPromise<any> {
    return axiosCommon.delete(`${this.baseUrl}/pets/${petId}/profile-picture`);
  }
}
