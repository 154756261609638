/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import {
  getAddressString,
  getGeneralError,
} from "../../../util/helperFunctions";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import PetOwnerContactsApi from "../../../api/PetOwnerContactsApi";
import AlertBox from "../../AlertBox";
import { ContactType } from "../../../models/contact/ContactType";
import BeforeDeleteModal from "../../Modals/BeforeDeleteModal";
import AddAddressModal from "../../Modals/Address/AddAddressModal";
import { AddressResponse } from "../../../models/contact/AddressResponse";
import { AddressRequest } from "../../../models/contact/AddressRequest";
import { Pencil } from "../../../common/Icons/Pencil";
import { Trash } from "../../../common/Icons/Trash";
import Button from "../../Button";
import PromoteAddressModal from "../../Modals/Address/PromoteAddressModal";

interface Props {
  owner?: PetOwnerResponse;
}

const PetOwnerAddresses: React.FC<Props> = ({ owner }: Props) => {
  const [addresses, setAddresses] = useState<AddressResponse[]>(
    owner?.addresses || []
  );

  const [addressToPromote, setAddressToPromote] =
    useState<AddressResponse | null>(null);
  const [addressToDelete, setAddressToDelete] = useState<AddressResponse>();

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showPromoteModal, setShowPromoteModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [additionLoading, setAdditionLoading] = useState<boolean>(false);
  const [deletionLoading, setDeletionLoading] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (owner) {
      setAddresses(owner.addresses);
    }
  }, [owner]);

  const openAddModal = (): void => {
    setShowAddModal(true);
  };

  const closeAddModal = (): void => {
    setShowAddModal(false);
  };

  const openPromoteModal = (address: AddressResponse): void => {
    if (address.contactType === ContactType.PRIMARY) {
      return;
    }

    setAddressToPromote(address);
    setShowPromoteModal(true);
  };

  const closePromoteModal = (): void => {
    setShowPromoteModal(false);
  };

  const addNewAddress = async (address: AddressRequest) => {
    if (!owner?.petOwnerDetails.id) {
      return;
    }

    setAdditionLoading(true);
    setError(null);

    try {
      const response = await PetOwnerContactsApi.addPetOwnerAddress(
        owner?.petOwnerDetails.id,
        address
      );

      setAddresses(response.data.addresses);
      closeAddModal();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setAdditionLoading(false);
    }
  };

  const deleteAddress = async (address: AddressResponse) => {
    if (!owner?.petOwnerDetails.id) {
      return;
    }

    setDeletionLoading(true);
    setError(null);

    try {
      const response = await PetOwnerContactsApi.removePetOwnerAddress(
        owner?.petOwnerDetails.id,
        address.id
      );

      setAddresses(response.data.addresses);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setDeletionLoading(false);
    }
  };

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 font-bold">
          <p>{strings.address.toUpperCase()}</p>
        </div>
        {addresses.map((address, index) => (
          <div
            className="p-4 flex justify-between items-center space-x-4"
            key={index}
          >
            <div>
              <div className="flex flex-wrap items-center">
                <p className="mr-3">{getAddressString(address)}</p>
                <button
                  className="tw-btn-pill-tercier-small"
                  disabled
                  hidden={address.contactType !== ContactType.PRIMARY}
                  type="button"
                >
                  {strings.primary}
                </button>
              </div>
            </div>
            <div className="flex">
              <Button
                aria-label={strings.edit}
                hidden={address.contactType === ContactType.PRIMARY}
                onClick={() => {
                  openPromoteModal(address);
                }}
                variant="icon"
              >
                <span className="sr-only">{strings.edit}</span>
                <Pencil />
              </Button>
              <Button
                aria-label={strings.delete}
                className="tw-link ml-3"
                disabled={deletionLoading}
                loading={deletionLoading && address === addressToDelete}
                onClick={() => {
                  setShowDeleteModal(true);
                  setAddressToDelete(address);
                }}
                showTextWhileLoading={false}
                variant="icon"
              >
                <span className="sr-only">{strings.delete}</span>
                <Trash />
              </Button>
            </div>
          </div>
        ))}

        <div className="p-4">
          <Button
            aria-label={strings.addAddress}
            onClick={openAddModal}
            variant="link"
          >
            <span className="sr-only">{strings.addAddress}</span>+{" "}
            {strings.addAddress}
          </Button>
        </div>
        <AlertBox message={error} className="m-3" />
      </div>
      <AddAddressModal
        close={closeAddModal}
        error={error}
        isOpen={showAddModal}
        loading={additionLoading}
        onSubmit={addNewAddress}
      />
      <PromoteAddressModal
        address={addressToPromote}
        onHide={closePromoteModal}
        petOwnerId={owner?.petOwnerDetails.id}
        setAddresses={(previousState) => setAddresses(previousState)}
        show={showPromoteModal}
      />
      <BeforeDeleteModal
        open={showDeleteModal}
        returnYes={() => {
          if (addressToDelete) {
            void deleteAddress(addressToDelete);
          }
          setShowDeleteModal(false);
        }}
        onHide={() => {
          setShowDeleteModal(false);
        }}
      />
    </>
  );
};

export default PetOwnerAddresses;
