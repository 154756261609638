export enum ClinicCost {
  BASE_CLINIC_FEE = "BASE_CLINIC_FEE",
  APPOINTMENT_FEE = "APPOINTMENT_FEE",
  MEDICAL_RECORD_FEE = "MEDICAL_RECORD_FEE",
  SMS_FEE = "SMS_FEE",
  INVENTORY_FEE = "INVENTORY_FEE",
  PRESCRIPTION_FEE = "PRESCRIPTION_FEE",
  EMPLOYEE_FEE = "EMPLOYEE_FEE",
  VET_FEE = "VET_FEE",
  ASSISTANT_FEE = "ASSISTANT_FEE",
  RECEPTIONIST_FEE = "RECEPTIONIST_FEE",
}
