/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { AddressRequest } from "../models/contact/AddressRequest";
import { EmailRequest } from "../models/contact/EmailRequest";
import { PhoneRequest } from "../models/contact/PhoneRequest";
import { PetOwnerResponse } from "../models/pet/PetOwnerResponse";
import { axiosCommon } from "../util/axiosSetup";

export default class PetOwnerContactsApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static addPetOwnerEmail(
    petOwnerId: string,
    emailRequest: EmailRequest
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/emails`;
    return axiosCommon.post(url, emailRequest);
  }

  static removePetOwnerEmail(
    petOwnerId: string,
    emailId: string
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/emails/${emailId}`;
    return axiosCommon.delete(url);
  }

  static addPetOwnerPhone(
    petOwnerId: string,
    phoneRequest: PhoneRequest
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/phones`;
    return axiosCommon.post(url, phoneRequest);
  }

  static removePetOwnerPhone(
    petOwnerId: string,
    phoneId: string
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/phones/${phoneId}`;
    return axiosCommon.delete(url);
  }

  static addPetOwnerAddress(
    petOwnerId: string,
    addressRequest: AddressRequest
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/addresses`;
    return axiosCommon.post(url, addressRequest);
  }

  static removePetOwnerAddress(
    petOwnerId: string,
    addressId: string
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/addresses/${addressId}`;
    return axiosCommon.delete(url);
  }

  static setPrimaryPetOwnerEmail(
    petOwnerId: string,
    emailId: string
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/primary-email`;
    return axiosCommon.put(url, { emailId });
  }

  static setPrimaryPetOwnerPhone(
    petOwnerId: string,
    phoneId: string
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/primary-phone`;
    return axiosCommon.put(url, { phoneId });
  }

  static setPrimaryPetOwnerAddress(
    petOwnerId: string,
    addressId: string
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/primary-address`;
    return axiosCommon.put(url, { addressId });
  }
}
