/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import {
  getActiveLoggedInUser,
  setRoleOfActiveUser,
  UserRole,
} from "../../../util/LocalStorageVariables";
import Button from "../../../components/Button";
import {
  doctorPlaceholder,
  dogMainPlaceholder,
} from "../../../assets/AssetConfig";

interface Props {
  setPageLoading: (isLoading: boolean) => void;
}

export const useChosenRole = (chosenRole: UserRole) => {
  useEffect(() => {
    if (!getActiveLoggedInUser()?.role) {
      setRoleOfActiveUser(chosenRole);
    }
  }, [chosenRole]);
};

const ChooseRole: React.FC<Props> = (props: Props) => {
  const { setPageLoading } = props;
  const navigate = useNavigate();

  useEffect(() => {
    setPageLoading(false);
  }, [setPageLoading]);

  const chooseOwner = () => {
    setRoleOfActiveUser("owner");
    navigate("/pet-owner");
  };

  const chooseDoctor = () => {
    setRoleOfActiveUser("doctor");
    navigate("/profile/doctor-setup");
  };

  return (
    <main className="lg:min-h-screen pb-0 lg:pb-14 pt-14">
      <section>
        <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 lg:px-0 py-10 lg:py-14">
          <div className="w-full lg:w-auto lg:grid lg:grid-cols-2 space-x-0 lg:space-x-6 space-y-10 lg:space-y-0">
            <div className="w-full lg:w-auto lg:tw-card space-y-6 lg:p-8 text-center">
              <div>
                <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                  {strings.employeeMode}
                </h2>
              </div>
              <div className="flex items-center justify-center">
                <img src={doctorPlaceholder} className="w-40" alt="Doctor" />
              </div>
              <div>
                <p>{strings.employeeSideInfo}</p>
              </div>
              <div>
                <Button
                  className="min-w-64"
                  fullWidth={false}
                  variant="primary"
                  onClick={chooseDoctor}
                >
                  {strings.continueAsEmployee}
                </Button>
              </div>
            </div>
            <div className="w-full lg:w-auto lg:tw-card space-y-6 lg:p-8 text-center">
              <div>
                <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                  {strings.petOwnerMode}
                </h2>
              </div>
              <div className="flex items-center justify-center">
                <img src={dogMainPlaceholder} className="w-40" alt="Doctor" />
              </div>
              <div>
                <p>{strings.ownerSideInfo}</p>
              </div>
              <div>
                <Button
                  className="min-w-64"
                  fullWidth={false}
                  variant="primary"
                  onClick={chooseOwner}
                >
                  {strings.continueAsOwner}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ChooseRole;
