/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../AlertBox";
import Field from "../../ReactHookFormFields/General/Field";
import { EmailRequest } from "../../../models/contact/EmailRequest";
import Button from "../../Button";
import { EmailResponse } from "../../../models/contact/EmailResponse";
import { getGeneralError } from "../../../util/helperFunctions";
import PetOwnerContactsApi from "../../../api/PetOwnerContactsApi";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import CloseButton from "../../CloseButton";
import Modal from "../../Modal/Modal";

interface Props {
  onHide: () => void;
  petOwnerId?: string;
  setEmails: (emails: EmailResponse[]) => void;
  show: boolean;
}

const AddEmailModal: React.FC<Props> = ({
  onHide,
  petOwnerId,
  setEmails,
  show,
}: Props) => {
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<EmailRequest>({
    mode: "onChange",
  });

  useLayoutEffect(() => {
    if (show) {
      reset();
      setError(null);
    }
  }, [reset, show]);

  const submit = async (email: EmailRequest) => {
    if (!petOwnerId) return;

    setLoading(true);

    try {
      const response = await PetOwnerContactsApi.addPetOwnerEmail(
        petOwnerId,
        email
      );
      setEmails(response.data.emails);
      onHide();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.addEmail} />
      <Modal.Body>
        <form onSubmit={handleSubmit(submit)} id="addEmailForm">
          <Field
            name="email"
            type="email"
            label={strings.email}
            placeholder={strings.emailAddress}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            required
            error={errors.email}
          />
        </form>
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || loading}
          form="addEmailForm"
          loading={loading}
          type="submit"
        >
          {strings.save}
        </Button>
        <CloseButton onClick={onHide} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default AddEmailModal;
