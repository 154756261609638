/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { ListGroup, Modal, ModalBody } from "react-bootstrap";
import { strings } from "../../../common/Strings/Strings";
import VetProfessionDocListItem from "./VetProfessionDocListItem";
import { VetProfessionDocumentResponse } from "../../../models/vet/VetProfessionResponse";
import { DocumentsDownloadButton } from "../../UserManagement/VetProfessionDocument/DocumentsDownloadButton";
import AlertBox from "../../../components/AlertBox";
import CloseButton from "../../../components/CloseButton";
import Button from "../../../components/Button";

interface Props {
  documents: VetProfessionDocumentResponse[];
  onError(e: any): void;
  vetName: string;
  vetUserEmail?: string;
  vetUserId: string;
}

const VetProfessionDocument: React.FC<Props> = ({
  documents,
  onError,
  vetName,
  vetUserEmail,
  vetUserId,
}: Props) => {
  const [filesModalShown, setFilesModalShown] = useState(false);
  const [modalError, setModalError] = useState<string | null>(null);

  if (documents.length === 0) {
    return <i>{strings.noFile}</i>;
  }

  if (documents.length === 1) {
    return (
      <div className="flex items-center">
        <DocumentsDownloadButton onError={onError} vetDocument={documents[0]} />
        {documents[0].fileName}
      </div>
    );
  }

  return (
    <>
      <Button
        onClick={() => setFilesModalShown(true)}
        type="button"
        variant="dark"
      >
        {strings.formatString(strings.viewFiles, documents.length)}
      </Button>
      <Modal
        centered
        show={filesModalShown}
        onHide={() => setFilesModalShown(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{strings.documents}</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <div className="mb-3">
            <div>
              <div>
                <strong>{`${strings.name}: `}</strong>
                {vetName}
              </div>
              <div>
                <strong>{`${strings.userId}: `}</strong>
                {vetUserId}
              </div>
              <div>
                <strong>{`${strings.email}: `}</strong>
                {vetUserEmail}
              </div>
            </div>
          </div>
          <ListGroup className="my-5">
            {documents.map((d) => (
              <VetProfessionDocListItem document={d} onError={setModalError} />
            ))}
          </ListGroup>
          <AlertBox message={modalError} className="mt-5" />
        </ModalBody>
        <Modal.Footer>
          <div className="flex justify-center">
            <CloseButton
              onClick={() => setFilesModalShown(false)}
              text={strings.close}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VetProfessionDocument;
