/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import qs from "query-string";
import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { PersonDetailsResponse } from "../models/contact/PersonDetailsResponse";
import { AddSocialAccountRequest } from "../models/user/AddSocialAccountRequest";
import { PinCodeConfirmationRequest } from "../models/user/PinCodeConfirmationRequest";
import { PinCodeValidationRequest } from "../models/user/PinCodeValidationRequest";
import { ProfileSetupAddressRequest } from "../models/user/ProfileSetupAddressRequest";
import { ProfileSetupPhoneRequest } from "../models/user/ProfileSetupPhoneRequest";
import { ProfileSetupPreferencesRequest } from "../models/user/ProfileSetupPreferencesRequest";
import { RegistrationRequest } from "../models/user/RegistrationRequest";
import { SocialLoginProvider } from "../models/user/SocialLoginProvider";
import { SocialUserResponse } from "../models/user/SocialUserResponse";
import { UpdatePasswordRequest } from "../models/user/UpdatePasswordRequest";
import { UpdatePinCodeRequest } from "../models/user/UpdatePinCodeRequest";
import { BasicInformationRequest } from "../models/user/BasicInformationRequest";
import { UserDeletionRequest } from "../models/user/UserDeletionRequest";
import { UserResponse } from "../models/user/UserResponse";
import { axiosCommon } from "../util/axiosSetup";
import { SendConfirmationEmailResponse } from "../models/user/SendConfirmationEmailResponse";
import { EmailConfirmedResponse } from "../models/user/EmailConfirmedResponse";
import { EmailConfirmedRequest } from "../models/user/EmailConfirmedRequest";
import { LocaleRequest } from "../models/user/LocaleRequest";
import { NotificationsRequest } from "../models/user/NotificationsRequest";

export default class UserApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getMyUserData(): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user`;
    return axiosCommon.get(url);
  }

  static getPetOwnerDataForRegistration(
    petOwnerId: string,
    registrationToken: string
  ): ApiPromise<PersonDetailsResponse> {
    const query = qs.stringify({ registrationToken });
    const url = `${this.baseUrl}/registration/pet-owners/${petOwnerId}?${query}`;
    return axiosCommon.get(url);
  }

  static registerUser(
    request: RegistrationRequest,
    recaptchaToken: string | null
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/registration`;
    const headers = recaptchaToken ? { "Captcha-Token": recaptchaToken } : {};
    return axiosCommon.post(url, request, { headers });
  }

  static setupUserProfileAddress(
    request: ProfileSetupAddressRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-setup/address`;
    return axiosCommon.post(url, request);
  }

  static setupUserProfilePhone(
    request: ProfileSetupPhoneRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-setup/phone`;
    return axiosCommon.post(url, request);
  }

  static setupUserProfilePreferences(
    request: ProfileSetupPreferencesRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-setup/preferences`;
    return axiosCommon.post(url, request);
  }

  static updateBasicInfo(
    request: BasicInformationRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-settings/basic-information`;
    return axiosCommon.put(url, request);
  }

  static updateLocale(request: LocaleRequest): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-settings/locale`;
    return axiosCommon.put(url, request);
  }

  static updateNotificationSettings(
    request: NotificationsRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-settings/notifications`;
    return axiosCommon.put(url, request);
  }

  static changePassword(
    request: UpdatePasswordRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-settings/password`;
    return axiosCommon.put(url, request);
  }

  static addSocialAccount(
    request: AddSocialAccountRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-settings/social-accounts`;
    return axiosCommon.put(url, request);
  }

  static getSocialUserData(
    provider: SocialLoginProvider,
    accessToken: string
  ): ApiPromise<SocialUserResponse> {
    const url = `${this.baseUrl}/registration/social?provider=${provider}&accessToken=${accessToken}`;
    return axiosCommon.get(url);
  }

  static setPinCode(request: UpdatePinCodeRequest): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-settings/pin`;
    return axiosCommon.put(url, request);
  }

  static confirmPinCode(
    request: PinCodeConfirmationRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-settings/pin/confirm`;
    return axiosCommon.post(url, request);
  }

  static validatePinCode(request: PinCodeValidationRequest): ApiPromise<void> {
    const url = `${this.baseUrl}/user-settings/pin/validate`;
    return axiosCommon.post(url, request);
  }

  static deleteUser(request: UserDeletionRequest): ApiPromise<void> {
    const url = `${this.baseUrl}/user`;
    return axiosCommon.delete(url, { data: request });
  }

  static checkUserEmailConfirmed(
    email: string
  ): ApiPromise<EmailConfirmedResponse> {
    const request: EmailConfirmedRequest = { email };
    const url = `${this.baseUrl}/registration/check-registration-email-confirmed`;
    return axiosCommon.post(url, request);
  }

  static sendRegistrationConfirmationEmail(email: string): ApiPromise<void> {
    const url = `${this.baseUrl}/send-registration-confirmation-mail`;
    return axiosCommon.post(url, { email });
  }

  static sendRegistrationConfirmationEmailById(
    emailId: string
  ): ApiPromise<SendConfirmationEmailResponse> {
    const url = `${this.baseUrl}/send-registration-confirmation-mail-by-id`;
    return axiosCommon.post(url, { emailId });
  }
}
