/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const clinicCostsEn = {
  BASE_CLINIC_FEE: "Clinic base fee",
  APPOINTMENT_FEE: "Appointment fee",
  MEDICAL_RECORD_FEE: "Medical record fee",
  SMS_FEE: "SMS fee",
  INVENTORY_FEE: "Inventory fee",
  PRESCRIPTION_FEE: "Prescription fee",
  EMPLOYEE_FEE: "Employee fee",
  VET_FEE: "Veterinarian fee",
  ASSISTANT_FEE: "Assistant fee",
  RECEPTIONIST_FEE: "Receptionist fee",
};
