/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const symptomsHu = {
  itching: "Viszketés",
  diarrhea: "Hasmenés",
  vomiting: "Hányás",
  inappetence: "Étvágytalanság",
  stranguria: "Vizelési nehézség",
  decreasedGeneralCondition: "Csökkent általános jólét",
  PDPU: "Sokat iszik, sokat pisil",
  bitewound: "Harapás",
  coughing: "Köhögés",
  lethargy: "Levertség",
  dewormingProfylaxis: "Férgesség",
  analSacsProblem: "Bűzmirigyprobéma",
  limbing: "Sántaság",
  discharge: "Váladékozás",
  sneezing: "Tüsszögés",
  seizures: "Rohamok",
  neurologicalSymtpoms: "Neurológiai tünetek",
  hotspot: "Hotspot",
  traumaticWounds: "Traumás sérülés",
  clawcapsuleFracture: "Körömágy sérülés",
  fever: "Láz",
  otitis: "Fülgyulladás",
  wounddamage: "Sebesülés",
  acuteCystitis: "Akut hólyaghurut",
};
