/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { Calculator } from "../../../common/Icons/Calculator";
import ChevronButtonDown from "../../../components/Buttons/ChevronButtonDown";
import Button from "../../../components/Button";
import ChevronButtonUp from "../../../components/Buttons/ChevronButtonUp";
import NumberFormatter from "../../../util/NumberFormatter";
import Prices from "../../../components/InputFieldsSimple/Prices";
import CostCalculator from "../../../util/CostCalculator";
import { InventoryItemUnit } from "../../../models/inventory/item/InventoryItemUnit";
import { InventoryItemStockRequest } from "../../../models/inventory/item/Request/InventoryItemStockRequest";
import MultiplicationFieldsComponent from "../../../components/InputFieldsSimple/MultiplicationFieldsComponent";
import { useClinic } from "../../../contexts/ClinicContext";

interface Props {
  countryDetails?: CountryDetailsResponse;
  totalQuantity?: number;
  totalQuantityEnabled?: boolean;
  unit: InventoryItemUnit;
}

const UnitPriceCalculator: React.FC<Props> = ({
  countryDetails,
  totalQuantity: tq,
  totalQuantityEnabled = false,
  unit,
}: Props) => {
  const { watch, setValue } = useFormContext<InventoryItemStockRequest>();
  const [isOpen, setIsOpen] = useState<boolean>(!false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalQuantity, setTotalQuantity] = useState<number>(1);
  const [result, setResult] = useState<number>(0);
  const { clinic } = useClinic();

  useEffect(() => {
    if (tq && totalQuantityEnabled) {
      setTotalQuantity(tq);
    }
  }, [tq, totalQuantityEnabled]);

  useEffect(() => {
    if (totalQuantity > 0 && totalPrice > 0) {
      setResult(totalPrice / totalQuantity);
      setValue("acquisitionPrice", totalPrice / totalQuantity);
    }
  }, [totalPrice, totalQuantity, setValue]);

  return (
    <div className="bg-sky-50 dark:bg-sky-900 p-4 rounded-lg">
      <div className="flex items-center">
        <Calculator />
        <div className="pl-2">
          {strings.unitPriceCalculator} ({countryDetails?.currency}/
          {quantityUnits[unit]})
        </div>
        <div className="ml-auto">
          <Button
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
            variant="basic"
          >
            {isOpen ? <ChevronButtonDown /> : <ChevronButtonUp />}
          </Button>
        </div>
      </div>
      <div hidden={isOpen} className="space-y-4 pt-4">
        <Prices
          currency={countryDetails?.currency}
          label={strings.totalPackagePrice}
          onChange={(v) => {
            setTotalPrice(Number(v));
          }}
          value={totalPrice}
          vat={watch("acquisitionVat")}
        />
        <div hidden={totalQuantityEnabled}>
          <label className="mb-0">
            {strings.totalQuantityInPackages} ({quantityUnits[unit]})
          </label>
          <div className="flex items-center space-x-2">
            <MultiplicationFieldsComponent
              onChange={(v) => {
                setTotalQuantity(v || 1);
              }}
              unit={unit}
              value={totalQuantity}
            />
          </div>
        </div>
        <div>
          <label className="mb-0">{strings.result}</label>
          <b>
            {NumberFormatter.formatPrice(
              CostCalculator.getGrossPrice(
                result, 
                watch("acquisitionVat"), 
                countryDetails, 
                clinic || undefined
              ),
              `${countryDetails?.currency}/
              ${quantityUnits[unit]}`
            )}
          </b>
          <div className="text-xs text-gray-500">
            {NumberFormatter.formatPrice(
              result,
              `${countryDetails?.currency}/
              ${quantityUnits[unit]}`
            )}{" "}
            {strings.plusVat}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitPriceCalculator;
