/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../../../util/helperFunctions";
import VetRegistrationNumberForm, {
  VetRegistrationNumberProps,
} from "./VetRegistrationNumberForm";
import VetApi from "../../../../../../api/VetApi";
import { vetLicence } from "../../../../../../assets/AssetConfig";
import AlertBox from "../../../../../../components/AlertBox";
import { UserResponse } from "../../../../../../models/user/UserResponse";
import Button from "../../../../../../components/Button";
import Modal from "../../../../../../components/Modal/Modal";
import CloseButton from "../../../../../../components/CloseButton";

interface Props {
  close(): void;
  isOpen: boolean;
  onSuccess?(response: UserResponse): void;
}

const VetRegistrationNumberModal: React.FC<Props> = ({
  close,
  isOpen,
  onSuccess,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const methods = useForm<VetRegistrationNumberProps>({ mode: "onChange" });

  useEffect(() => {
    setError(null);
  }, []);

  const onSubmit = async (data: VetRegistrationNumberProps) => {
    setLoading(true);
    setError(null);

    try {
      const response = await VetApi.addVetRegistrationNumber({
        ...data,
        countryCode: data.countries[0].countryCode,
      });

      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={close} show={isOpen} size="modal-sm">
      <Modal.Header title={strings.addRegistrationNumber} />
      <Modal.Body>
        <p>{strings.vetRegNumberInfo}</p>
        <div className="flex justify-center">
          <img src={vetLicence} alt="vetLicence" />
        </div>
        <FormProvider {...methods}>
          <VetRegistrationNumberForm />
        </FormProvider>
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!methods.formState.isValid}
          loading={loading}
          onClick={methods.handleSubmit(onSubmit)}
        >
          {strings.save}
        </Button>
        <CloseButton onClick={close} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default VetRegistrationNumberModal;
