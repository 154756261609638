/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import moment, { Moment } from "moment";
import { EventImpl } from "@fullcalendar/core/internal";
import { EventContentArg } from "@fullcalendar/core";
import {
  getCurrentDate,
  getSunday,
  mergeTimeAndDate,
} from "../../../util/helperFunctions";
import { strings } from "../../../common/Strings/Strings";
import { Colors } from "../../../models/Colors";
import { Appointment } from "../../../models/calendar/Appointment";
import { ShiftForConsultationPeriodsResponse } from "../../../models/shift/ShiftForConsultationPeriodsResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Tooltip from "../../../components/Tooltip";

const eventBGColor = Colors.NAVBARMEDIUM;

interface Props {
  defaultView: string;
  startDate: Date;
  actualWeekMonday: Date;
  actualWeekSunday: Date;
  shifts: ShiftForConsultationPeriodsResponse[];
  minTime: string;
  maxTime: string;
  reservationLength: number;
  reservationName: string;
  maxUpcomingWeekRange: number;
  changeWeek(newDate: Date): void;
  onAppointmentChange(actualPeriod?: Appointment): void;
}

interface FullCalendarEvent {
  start: Date;
  end: Date;
  display: string;
  color: string;
  title: string;
}

const maxOfMoments = (moments: Moment[]) => {
  let actual = moments[0];
  for (let i = 1; i < moments.length; i += 1)
    if (moments[i].isSameOrAfter(actual)) actual = moments[i];
  return actual;
};

const minOfMoments = (moments: Moment[]) => {
  let actual = moments[0];
  for (let i = 1; i < moments.length; i += 1)
    if (moments[i].isSameOrBefore(actual)) actual = moments[i];
  return actual;
};

const timeslotsIntersect = (
  [a0, a1]: [Moment, Moment],
  [b0, b1]: [Moment, Moment]
) => !(a1.isSameOrBefore(b0) || a0.isSameOrAfter(b1));

const momentBetween = (a: Moment, b: Moment, c: Moment) =>
  a.isSameOrBefore(b) && b.isSameOrBefore(c);

const timeSlotContained = (
  [a0, a1]: [Moment, Moment],
  [b0, b1]: [Moment, Moment]
) => momentBetween(b0, a0, b1) && momentBetween(b0, a1, b1);

const sumOfTimeslots = (slots: [Moment, Moment][]) => {
  let result: [Moment, Moment][] = [];
  slots.forEach((s) => {
    const intersected = [
      s,
      ...result.filter((s2) => timeslotsIntersect(s, s2)),
    ];
    const start = minOfMoments(intersected.map((min) => min[0]));
    const end = maxOfMoments(intersected.map((min) => min[1]));
    result = [
      ...result.filter((s2) => !timeslotsIntersect(s, s2)),
      [start, end],
    ];
  });
  return result.sort(([a], [b]) => (a.isSameOrBefore(b) ? -1 : 1));
};

const OwnerCalendar: React.FC<Props> = ({
  defaultView,
  startDate,
  actualWeekMonday,
  actualWeekSunday,
  shifts,
  maxTime,
  maxUpcomingWeekRange,
  minTime,
  changeWeek,
  reservationLength,
  reservationName,
  onAppointmentChange,
}: Props) => {
  const [calendarWeekends] = useState(true);
  const [reserved, setReserved] = useState<Appointment[]>([]);
  const [appointmentPeriodError, setAppointmentPeriodError] = useState<
    string | undefined
  >();
  const [appointment, setAppointment] = useState<Appointment | undefined>();
  const calendarRef = useRef<FullCalendar>(null);

  useEffect(() => {
    const createComplementaryBackgroundEvents = (
      day: Date,
      shiftsOfDay: ShiftForConsultationPeriodsResponse[]
    ): Appointment[] => {
      const cmpEvents: Appointment[] = [];
      const today = new Date(day);
      const timeOfOpening = mergeTimeAndDate(minTime, today);
      const timeOfClosing = mergeTimeAndDate(maxTime, today);

      const timeSlots = shiftsOfDay
        .flatMap((s) => s.freeTimeslots)
        .map((s) => [moment(s.startTime), moment(s.endTime)]) as [
        Moment,
        Moment,
      ][];

      const timeSlotsFilteredByDuration = timeSlots.filter(([a, b]) => {
        const timeSlotMillis = +b.toDate() - +a.toDate();
        const reservationMillis = reservationLength * 60 * 1000;
        return timeSlotMillis >= reservationMillis;
      }) as [Moment, Moment][];

      const sum = sumOfTimeslots(timeSlotsFilteredByDuration);

      const timePoints = [
        timeOfOpening,
        ...sum.flatMap((s) => s).map((s) => s.toDate()),
        timeOfClosing,
      ];

      for (let i = 0; i < timePoints.length; i += 2) {
        if (+timePoints[i] !== +timePoints[i + 1]) {
          cmpEvents.push({
            id: (Math.floor(Math.random() * 10000000) * -1).toString(),
            title: "",
            start: timePoints[i],
            end: timePoints[i + 1],
            color: eventBGColor,
            rendering: "background",
          });
        }
      }

      return cmpEvents;
    };

    const day = new Date(actualWeekMonday);

    const list: Appointment[] = [];
    let count = 0;

    while (day <= actualWeekSunday) {
      const shiftsOnDay = shifts
        .filter(
          (s) =>
            getCurrentDate(moment(s.startTime).toDate()) ===
            getCurrentDate(moment(day).toDate())
        )
        .sort(
          (first, second) =>
            moment(first.startTime).valueOf() -
            moment(second.startTime).valueOf()
        );
      const bgEvents = createComplementaryBackgroundEvents(day, shiftsOnDay);
      bgEvents.forEach((s) => list.push(s));
      day.setDate(day.getDate() + 1);
      count += 1;
    }

    setReserved(list);
  }, [
    actualWeekMonday,
    actualWeekSunday,
    shifts,
    minTime,
    maxTime,
    reservationLength,
  ]);

  useEffect(() => {
    onAppointmentChange(appointment?.shiftId ? appointment : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment]);

  const findShiftByAppointmentTime = (
    appointmentSlot: [Moment, Moment]
  ): ShiftForConsultationPeriodsResponse | undefined => {
    const i = shifts.findIndex(
      (s) =>
        s.freeTimeslots.findIndex((ts) =>
          timeSlotContained(appointmentSlot, [
            moment(ts.startTime),
            moment(ts.endTime),
          ])
        ) >= 0
    );

    return shifts[i];
  };

  const handleDateClick = (arg: { date: Date }) => {
    const dateStart = arg.date;
    const tempDateEnd = moment(dateStart)
      .add(reservationLength, "minutes")
      .format("YYYY-MM-DD kk:mm:ss");
    const dateEnd = new Date(tempDateEnd);

    const shiftId = findShiftByAppointmentTime([
      moment(dateStart),
      moment(dateEnd),
    ])?.id;

    setAppointmentPeriodError(
      shiftId ? undefined : strings.appointmentPeriodOnOverlappingFreeTimeSlots
    );

    setAppointment({
      id: (Math.floor(Math.random() * 10000000) * -1).toString(),
      title: reservationName,
      start: dateStart,
      end: dateEnd,
      allDay: false,
      color: shiftId ? Colors.PRIMARY : "##FD9B5B",
      shiftId,
    });
  };

  const changeAppointment = (info: any) => {
    const changedAppointment = info.event as Appointment;
    const shiftId = findShiftByAppointmentTime([
      moment(changedAppointment.start),
      moment(changedAppointment.end),
    ])?.id;

    setAppointmentPeriodError(
      shiftId ? undefined : strings.appointmentPeriodOnOverlappingFreeTimeSlots
    );

    setAppointment({
      id: changedAppointment.id,
      title: changedAppointment.title,
      start: changedAppointment.start,
      end: changedAppointment.end,
      allDay: false,
      color: shiftId ? Colors.PRIMARY : "#FD9B5B",
      shiftId,
    });
  };

  const isInValidRange = (newDate: Date) => {
    const sundayAfterTwoWeeks = new Date(getSunday(new Date()));
    sundayAfterTwoWeeks.setDate(
      sundayAfterTwoWeeks.getDate() + maxUpcomingWeekRange * 7
    );
    return newDate <= sundayAfterTwoWeeks;
  };

  const generateFullCalendarEvents = (
    events: Appointment[]
  ): FullCalendarEvent[] =>
    events.map((event: Appointment) => ({
      start: event.start,
      end: event.end,
      title: event.title,
      display: event.rendering || "list-item",
      color: event.color,
    }));

  const getEventContent = (event: EventImpl) => {
    if (!event.title) {
      return null;
    }

    const formattedStartTime = moment(event.start).format("h:mm");
    const formattedEndTime = moment(event.end).format("h:mm");
    const eventTime = `${formattedStartTime} - ${formattedEndTime}`;

    const startTime = moment(event.start);
    const endTime = moment(event.end);
    const durationInMinutes = endTime.diff(startTime, "minutes");

    const baseContent: ReactElement = (
      <div className="fc-event-main-frame">
        <div className="fc-event-time">{eventTime}</div>
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">{event.title}</div>
        </div>
      </div>
    );

    // Add Tooltip to short events to help readability
    return durationInMinutes <= 15 ? (
      <Tooltip content={`${event.title} (${eventTime})`}>{baseContent}</Tooltip>
    ) : (
      baseContent
    );
  };

  return (
    <div>
      {appointmentPeriodError && (
        <AlertBox
          className="mb-2"
          type={AlertType.WARNING}
          message={appointmentPeriodError}
        />
      )}
      <FullCalendar
        ref={calendarRef}
        firstDay={1}
        titleFormat={{ year: "numeric", month: "long", day: "numeric" }}
        headerToolbar={{
          start: "today,prev,next,title",
          center: "",
          end: "",
        }}
        customButtons={{
          next: {
            icon: "chevron-right",
            text: "",
            click: () => {
              const nextWeekDate = new Date(startDate);
              const add = defaultView === "timeGridDay" ? 1 : 7;

              nextWeekDate.setDate(nextWeekDate.getDate() + add);
              if (isInValidRange(nextWeekDate)) {
                changeWeek(nextWeekDate);
                const calendarApi = calendarRef?.current?.getApi();
                calendarApi?.next();
              }
            },
          },
          prev: {
            icon: "chevron-left",
            text: "",
            click: () => {
              const nextWeekDate = new Date(startDate);
              const sub = defaultView === "timeGridDay" ? 1 : 7;

              nextWeekDate.setDate(nextWeekDate.getDate() - sub);
              if (isInValidRange(nextWeekDate)) {
                changeWeek(nextWeekDate);
                const calendarApi = calendarRef?.current?.getApi();
                calendarApi?.prev();
              }
            },
          },
          today: {
            text: strings.today,
            click: () => {
              if (calendarRef && calendarRef.current) {
                const calendarApi = calendarRef?.current?.getApi();
                const currentRef = calendarApi.getDate();

                if (getCurrentDate(currentRef) !== getCurrentDate(new Date())) {
                  changeWeek(new Date());
                  calendarApi.today();
                }
              }
            },
          },
        }}
        eventContent={(info: EventContentArg) => getEventContent(info.event)}
        editable
        eventOverlap={false}
        eventDurationEditable={false}
        allDaySlot={false}
        slotMinTime={minTime}
        slotMaxTime={maxTime}
        slotDuration="00:10:00"
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: false,
        }}
        slotLabelInterval="00:10:00"
        nowIndicator
        contentHeight={600}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        weekends={calendarWeekends}
        events={generateFullCalendarEvents([
          ...reserved,
          ...(appointment ? [appointment] : []),
        ])}
        dateClick={handleDateClick}
        eventDrop={(info: any) => changeAppointment(info)}
        locale={strings.getLanguage() === "gb" ? "en" : strings.getLanguage()}
        initialView={defaultView}
      />
    </div>
  );
};

export default OwnerCalendar;
