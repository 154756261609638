/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ClinicResponse } from "../clinic/ClinicResponse";
import { PublicPet } from "../pet/PublicPet";
import { BasicUserResponse } from "../user/BasicUserResponse";

export enum PetTransferStatus {
  WAITING_FOR_NEW_OWNER = "WAITING_FOR_NEW_OWNER",
  WAITING_FOR_OLD_OWNER = "WAITING_FOR_OLD_OWNER",
  COMPLETED = "COMPLETED",
  REJECTED_BY_NEW_OWNER = "REJECTED_BY_NEW_OWNER",
  REJECTED_BY_OLD_OWNER = "REJECTED_BY_OLD_OWNER",
}

export interface PetTransferResponse {
  id: string;
  newOwner?: BasicUserResponse;
  note?: string;
  oldOwner: BasicUserResponse;
  pet: PublicPet;
  requestingClinic: ClinicResponse;
  status: PetTransferStatus;
}
