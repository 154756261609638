/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, ReactNode, useState } from "react";
import BeforeDeleteModal from "./Modals/BeforeDeleteModal";
import LoaderInline from "./LoaderInline";

type ButtonType = "button" | "submit" | "reset";
export type ButtonVariant =
  | "primary"
  | "primary-outline"
  | "secondary"
  | "tertiary"
  | "social"
  | "danger"
  | "dark"
  | "icon"
  | "basic"
  | "link"
  | "link-danger"
  | "link-tertiary"
  | "circle-primary"
  | "circle-tertiary"
  | "pill-secondary"
  | "pill-tertiary"
  | "pill-primary"
  | "pill-green"
  | "pill-red"
  | "pill-orange"
  | "video-consultation"
  | "video-consultation-gray"
  | "video-consultation-red";

// Button variants that have a small variant
const ButtonsVariantsSmall: ButtonVariant[] = [
  // Comments are needed in Tailwind for dynamic CSS class recognition
  "primary", // tw-btn-primary-small
  "primary-outline", // tw-btn-primary-outline-small
  "secondary", // tw-btn-secondary-small
  "tertiary", // tw-btn-tercier-small
  "danger", // tw-btn-red-small
  "dark", // tw-btn-dark-small
  "pill-secondary", // tw-btn-pill-secondary-small
  "pill-tertiary", // tw-btn-pill-tercier-small
];
const smallVariantExists = (v: ButtonVariant) =>
  ButtonsVariantsSmall.includes(v);

// Buttons variants that have a disabled variant
const ButtonsVariantsDisabled: ButtonVariant[] = [
  // Comments are needed in Tailwind for dynamic CSS class recognition
  "primary", // tw-btn-primary-disabled / tw-btn-primary-small-disabled
  "tertiary", // tw-btn-tercier-disabled / tw-btn-tercier-small-disabled
  "danger", // tw-btn-red-disabled / tw-btn-red-small-disabled
  "dark", // tw-btn-dark-disabled / tw-btn-dark-small-disabled
];
const disabledVariantExists = (v: ButtonVariant) =>
  ButtonsVariantsDisabled.includes(v);

interface Props {
  askAreYouSure?: { enable: boolean; title?: string; content?: string };
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  disabled?: boolean;
  form?: string;
  fullWidth?: boolean;
  hidden?: boolean;
  loading?: boolean;
  minW?: boolean;
  onClick?(e?: any): void;
  preventDefault?: boolean;
  showTextWhileLoading?: boolean;
  small?: boolean;
  type?: ButtonType;
  variant?: ButtonVariant;
}

function Button({
  askAreYouSure,
  children,
  className = "",
  contentClassName = "flex justify-center items-center",
  disabled,
  form,
  fullWidth,
  hidden = false,
  loading = false,
  minW,
  onClick,
  preventDefault = false,
  showTextWhileLoading = true,
  small = false,
  type = "button",
  variant = "primary",
}: Props): ReactElement {
  const [areYouSureModalOpen, setAreYouSureModalOpen] =
    useState<boolean>(false);

  const getVariant = (): string => {
    switch (variant) {
      case "primary":
        return "tw-btn-primary";
      case "primary-outline":
        return "tw-btn-primary-outline";
      case "secondary":
        return "tw-btn-secondary";
      case "tertiary":
        return "tw-btn-tercier";
      case "social":
        return "tw-btn-social";
      case "danger":
        return "tw-btn-red";
      case "dark":
        return "tw-btn-dark";
      case "icon":
        return "tw-icon";
      case "link":
        return "tw-link";
      case "link-danger":
        return "tw-link-red";
      case "link-tertiary":
        return "tw-link-tercier";
      case "circle-primary":
        return "tw-btn-circle-primary";
      case "circle-tertiary":
        return "tw-btn-circle-tercier";
      case "pill-secondary":
        return "tw-btn-pill-secondary";
      case "pill-tertiary":
        return "tw-btn-pill-tercier";
      case "pill-primary":
        return "tw-btn-pill-primary-small"; // only small variant exists so it is constructed here
      case "pill-green":
        return "tw-btn-pill-green-small"; // only small variant exists so it is constructed here
      case "pill-red":
        return "tw-btn-pill-red-small"; // only small variant exists so it is constructed here
      case "pill-orange":
        return "tw-btn-pill-orange-small"; // only small variant exists so it is constructed here
      case "video-consultation":
        return "video-consultation-btn";
      case "video-consultation-gray":
        return "video-consultation-btn-gray";
      case "video-consultation-red":
        return "video-consultation-btn-red";
      default:
        return "tw-btn-basic";
    }
  };

  const getWidth = (): string => {
    if (minW) {
      return "xl:min-w-48";
    }

    const isModalButton =
      className?.includes("modal-main-button") ||
      className?.includes("modal-sub-button");

    const isFullWidth =
      fullWidth ||
      (!isModalButton && variant === "primary" && fullWidth === undefined);

    return isFullWidth ? "w-full" : "";
  };

  return (
    <>
      <button
        className={`${getVariant()}${
          small && smallVariantExists(variant) ? "-small" : ""
        }${disabled && disabledVariantExists(variant) ? "-disabled" : ""}${
          className ? ` ${className}` : ""
        }${getWidth() ? ` ${getWidth()}` : ""}`}
        disabled={disabled || false}
        form={form}
        hidden={hidden}
        type={type === "submit" ? "submit" : "button"}
        onClick={(e) => {
          if (preventDefault) {
            e.preventDefault();
          }

          if (onClick && !askAreYouSure) {
            onClick(e);
          } else if (askAreYouSure?.enable) {
            setAreYouSureModalOpen(true);
          }
        }}
      >
        <div className={contentClassName}>
          {loading ? (
            <>
              <LoaderInline
                className={showTextWhileLoading ? "mr-2" : ""}
                dark={variant === "icon" || variant === "secondary"}
              />
              {showTextWhileLoading && children}
            </>
          ) : (
            <>{children}</>
          )}
        </div>
      </button>
      {askAreYouSure?.enable && (
        <BeforeDeleteModal
          content={askAreYouSure.content}
          loading={loading}
          open={areYouSureModalOpen}
          onHide={() => {
            setAreYouSureModalOpen(false);
          }}
          title={askAreYouSure?.title}
          returnYes={() => {
            if (onClick) {
              onClick();
            }
          }}
        />
      )}
    </>
  );
}

export default Button;
