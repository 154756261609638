/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import EditCardModal from "./EditCardModal";
import InfoTextWithLabel from "../../../../../components/InfoTextWithLabel";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import { Pencil } from "../../../../../common/Icons/Pencil";
import Button from "../../../../../components/Button";
import { canManageClinic } from "../../../../../util/helperFunctions";

interface Props {
  clinic: ClinicResponse;
  setClinic: (clinic: ClinicResponse) => void;
}

const CreditCardDetails: React.FC<Props> = ({ clinic, setClinic }: Props) => {
  const [showEditCardModal, setShowEditCardModal] = useState<boolean>(false);

  const closeEditCardModal = () => {
    setShowEditCardModal(false);
  };

  return (
    <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
      <div className="p-4 flex items-center">
        <p className="uppercase">{strings.creditCard}</p>
        <div className="ml-auto">
          <Button
            variant="icon"
            onClick={() => setShowEditCardModal(true)}
            hidden={!canManageClinic(clinic)}
          >
            <Pencil />
          </Button>
        </div>
      </div>
      <div className="p-4">
        <div>{strings.creditCardOrgToolTip}</div>
        <InfoTextWithLabel
          label={strings.name}
          text={clinic.cardHolder}
          inputLike
          widthPercent={100}
        />
        <InfoTextWithLabel
          label={strings.creditCardNumber}
          text={clinic.last4 ? `**** **** **** ${clinic.last4}` : ""}
          inputLike
          widthPercent={100}
        />
      </div>
      <EditCardModal
        clinic={clinic}
        setClinic={setClinic}
        show={showEditCardModal}
        close={closeEditCardModal}
      />
    </div>
  );
};

export default CreditCardDetails;
