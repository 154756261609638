/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import IconBubble from "../../../components/IconBubble";
import { InventoryItemDetailsResponse } from "../../../models/inventory/item/Response/InventoryItemDetailsResponse";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import EmptyListText from "../../../components/EmptyListText";

interface Props {
  countryDetails?: CountryDetailsResponse;
  inventoryItem: InventoryItemDetailsResponse;
}

const displayDateFormat = "L";

const CustomToolTip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="tw-card p-3">
        <p className="font-bold">{moment(label, displayDateFormat).format("LL")}</p>
        <p className="text-sky-500">{`${strings.acquisitionPrice}: ${payload[0].value}`}</p>
        <p className="text-green-500">{`${strings.salePrice}: ${payload[1].value}`}</p>
      </div>
    );
  }
  return <div className="tw-card p-3"/>
}

const InventoryItemAnalytics: React.FC<Props> = ({
  countryDetails,
  inventoryItem,
}) => {
  const [data, setData] = useState<
    Array<{ name: string; purchase: number; sale: number }>
  >([]);

  useEffect(() => {
    const tempData: Array<{
      price: number;
      date: string;
      type: "sale" | "purchase";
    }> = [];

    inventoryItem?.stocks?.forEach((stock) => {
      tempData.push({
        price: stock.acquisitionPrice || 0,
        date: stock.dateTime,
        type: "purchase",
      });
    });

    inventoryItem?.usages?.forEach((usage) => {
      tempData.push({
        price: usage.sellingPrice || 0,
        date: usage.dateTime,
        type: "sale",
      });
    });

    tempData.sort((a, b) => {
      if (moment(a.date).isAfter(b.date)) {
        return 1;
      } if (moment(b.date).isAfter(a.date)) {
        return -1;
      }
      return 0;
    });

    const tempPurchaseData: Array<number> = [];
    const tempSaleData: Array<number> = [];
    const allData: Array<any> = [];

    tempData.forEach((td, index) => {
      if (td.type === "purchase") {
        tempPurchaseData.push(td.price);
        tempSaleData.push(tempSaleData[index - 1] || 0);
        allData.push({
          name: moment(td.date).format(displayDateFormat),
          purchase: td.price,
          sale: tempSaleData[index - 1] || 0,
        });
      } else {
        tempSaleData.push(td.price);
        tempPurchaseData.push(tempPurchaseData[index - 1] || 0);
        allData.push({
          name: moment(td.date).format(displayDateFormat),
          purchase: tempPurchaseData[index - 1] || 0,
          sale: td.price,
        });
      }
    });

    if(inventoryItem.acquisitionPrice !== undefined || !!inventoryItem.sellingPrice !== undefined){
      allData.push({
        name: moment().format(displayDateFormat),
        purchase: inventoryItem.acquisitionPrice || 0,
        sale: inventoryItem.sellingPrice || 0,
      });
    }

    setData(allData);
  }, [inventoryItem]);

  return (
    <div className="w-full md:tw-card space-y-6 md:p-8 my-6">
      <div className="flex items-center flex-wrap md:flex-no-wrap">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {inventoryItem?.name}
        </h2>
        <div className="ml-3">
          <IconBubble>
            {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
          </IconBubble>
        </div>
      </div>
      <div className="space-y-4">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {strings.purchaseVsSalePriceTitle}
        </h2>
        {
          data.length === 0 ?
          <EmptyListText text={strings.notEnoughData}/>
          :
          <div>
              <ResponsiveContainer
              width="100%"
              height="100%"
              minHeight={250}
              minWidth={250}
            >
              <LineChart
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" label={undefined}/>
                <YAxis
                  label={{
                    value: `${strings.price} (${countryDetails?.currency}/${quantityUnits[inventoryItem?.acquisitionUnit || ""]})`,
                    style: { textAnchor: "middle" },
                    angle: -90,
                    position: "left",
                  }}
                />
                <Tooltip
                  content={<CustomToolTip />}
                />
                <Legend />
                <Line
                  activeDot={{ r: 8 }}
                  dataKey="purchase"
                  name={strings.acquisitionPrice}
                  stroke="#0ca2fe"
                  type="monotone"
                />
                <Line
                dataKey="sale"
                  name={strings.salePrice}
                  stroke="#58cb7d"
                  type="monotone"
                />
              </LineChart>
            </ResponsiveContainer>
        </div>
        }
      </div>
    </div>
  );
};

export default InventoryItemAnalytics;
