/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, useState } from "react";
import { MedicinalProduct } from "../../../../models/ePrescription/VARA/MedicinalProduct";
import { PharmaceuticalProduct } from "../../../../models/ePrescription/VARA/PharmaceuticalProduct";
import { Ingredient } from "../../../../models/ePrescription/VARA/Ingredient";
import { Package } from "../../../../models/ePrescription/VARA/Package";
import Button from "../../../../components/Button";
import { strings } from "../../../../common/Strings/Strings";

interface Props {
  index: number;
  isMobile?: boolean;
  medicine: MedicinalProduct;
  selectProduct(product: MedicinalProduct): void;
}

export const listSubstances = (med: MedicinalProduct): string[] => {
  const substances: string[] = [];

  if (med && med.pharmaceuticalProducts) {
    med.pharmaceuticalProducts.forEach((pp: PharmaceuticalProduct) => {
      if (pp.ingredients) {
        pp.ingredients.forEach((ingredient: Ingredient) => {
          if (ingredient && ingredient.substance) {
            substances.push(ingredient.substance.name);
            substances.push(", ");
          }
        });
      }
    });
    substances.pop();
  }

  return substances;
};

const ResultRow: React.FC<Props> = ({
  index,
  isMobile = false,
  medicine,
  selectProduct,
}: Props) => {
  const listStakeholders = (med: MedicinalProduct): string[] => {
    const stakeholders: string[] = [];
    if (med && med.packages) {
      med.packages.forEach((p: Package) => {
        if (
          p.displayStakeholder &&
          stakeholders.indexOf(p.displayStakeholder.name) === -1
        ) {
          stakeholders.push(p.displayStakeholder.name);
          stakeholders.push(", ");
        }
      });

      stakeholders.pop();
    }

    return stakeholders;
  };

  if (isMobile) {
    return (
      <>
        <tr>
          <td className="paragraph-small">
            <b>{medicine.name}</b> <br />
            {medicine.pharmaceuticalForm} <br />
            {medicine.strength} <br />
            {listStakeholders(medicine).map(
              (stakeholder: string, i: number) => {
                return <span key={i}>{stakeholder}</span>;
              }
            )}
            {medicine.containsLactose ? (
              <i
                className="fas fa-exclamation-circle mr-1"
                style={{ color: "#f37676" }}
              />
            ) : (
              <></>
            )}
            {listSubstances(medicine).map((substance: string, i: number) => {
              return (
                <span key={i} className="text-xs bold">
                  {substance}
                </span>
              );
            })}
          </td>
          <td className="text-right">
            <Button
              fullWidth={false}
              type="button"
              onClick={() => {
                selectProduct(medicine);
              }}
            >
              {strings.choose}
            </Button>
          </td>
        </tr>
      </>
    );
  }
  return (
    <Fragment key={index}>
      <tr>
        <td className="px-4 py-2 text-sm font-medium">
          <h2 className="font-semibold text-gray-800 dark:text-white">
            {" "}
            {medicine.name}
          </h2>
          {medicine.containsLactose ? (
            <i
              className="fas fa-exclamation-circle mr-1"
              style={{ color: "#f37676" }}
            />
          ) : (
            <></>
          )}
          {listSubstances(medicine).map((substance: string, i: number) => {
            return (
              <span
                key={i}
                className="text-sm font-normal text-gray-600 dark:text-gray-400"
              >
                {substance}
              </span>
            );
          })}
        </td>
        <td className="px-4 py-2 text-sm font-medium">
          {medicine.pharmaceuticalForm}
        </td>
        <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
          {medicine.strength}
        </td>
        <td className="px-4 py-2 text-sm font-medium">
          {listStakeholders(medicine).map((stakeholder: string, i: number) => {
            return <span key={i}>{stakeholder}</span>;
          })}
        </td>
        <td className="px-4 py-2 text-sm font-medium whitespace-nowrap text-right">
          <Button
            fullWidth={false}
            type="button"
            onClick={() => {
              selectProduct(medicine);
            }}
          >
            {strings.choose}
          </Button>
        </td>
      </tr>
    </Fragment>
  );
};

export default ResultRow;
