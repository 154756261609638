/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ApiPromise } from "../models/ApiPromise";
import { ClinicChargeResponse } from "../models/charge/ClinicChargeResponse";
import Params from "../common/Params";
import { axiosCommon } from "../util/axiosSetup";
import { PageResponse } from "../models/PageResponse";
import qs from "query-string";
import { ClinicChargeRequest } from "../models/management/ClinicChargeRequest";

export default class ChargeManagementApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getClinicCharges(queryParams: {
    startDate?: string;
    endDate?: string;
    clinicId?: string;
    pageNumber?: number;
    pageSize?: number;
  }): ApiPromise<PageResponse<ClinicChargeResponse>> {
    const query = qs.stringify(queryParams);
    const url = `${this.baseUrl}/management/charges?${query}`;
    return axiosCommon.get(url);
  }

  static createClinicChargePreview(
    request: ClinicChargeRequest
  ): ApiPromise<ClinicChargeResponse> {
    const url = `${this.baseUrl}/management/charges/preview`;
    return axiosCommon.post(url, request);
  }

  static createClinicCharge(
    request: ClinicChargeRequest
  ): ApiPromise<ClinicChargeResponse> {
    const url = `${this.baseUrl}/management/charges`;
    return axiosCommon.post(url, request);
  }
}
