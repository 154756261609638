/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import {
  getAccessToken,
  getValueOfActiveUser,
  UserRole,
} from "../../util/LocalStorageVariables";

interface Props {
  children: React.ReactNode;
  wrap?: boolean;
}

export const CardWrapper: React.FC<Props> = ({ children, wrap }) => {
  const role: UserRole | null = getValueOfActiveUser("role");
  const authenticated = getAccessToken();

  // TODO: New navbar is not yet implemented for site manager (so it's not sticky yet)
  // This is why it does not need more top padding yet
  // Top padding is required for sticky double top menus in cards
  const paddingClasses =
    (role === "owner" || role === "doctor") && authenticated
      ? "py-20 md:py-28"
      : "py-10 md:py-14";

  if (!wrap) {
    return <>{children}</>;
  }

  return (
    <main className="main-default">
      <section>
        <div
          className={`flex flex-col items-center justify-center space-y-6 mx-auto ${paddingClasses}`}
        >
          {children}
        </div>
      </section>
    </main>
  );
};
