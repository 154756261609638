/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import { GeoPharmacy } from "../../../../models/pharmacy/GeoPharmacy";
import PharmacyForm from "./PharmacyForm";
import HomeDeliveryAddress from "./HomeDeliveryAddress";
import { DeliverySelectionTypeEnum } from "../../../../models/ePrescription/local/DeliverySelectionTypeEnum";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import { PersonDetailsResponse } from "../../../../models/contact/PersonDetailsResponse";
import { Pharmacy } from "../../../../models/pharmacy/Pharmacy";
import { ExtendedPharmacy } from "../../../../models/ePrescription/persistence/ExtendedPharmacy";
import { RadioButtonOption } from "../../../../common/Icons/RadioButtonOption";
import Button from "../../../../components/Button";

interface Props {
  geoPharmacies: GeoPharmacy[];
  owner?: PersonDetailsResponse;
  deliveryTypeChanged(type: DeliverySelectionTypeEnum): void;
  isPharmacySelected: boolean;
}

const DeliveryHomeOrPharmacy: React.FC<Props> = ({
  geoPharmacies,
  owner,
  deliveryTypeChanged,
  isPharmacySelected,
}: Props) => {
  const [selectedDeliveryType, setSelectedDeliveryType] =
    useState<DeliverySelectionTypeEnum>(DeliverySelectionTypeEnum.NONE);
  const {
    control,
    getValues,
    formState: { isValid },
    setValue,
    trigger,
  } = useFormContext();

  useEffect(() => {
    void trigger();
    deliveryTypeChanged(selectedDeliveryType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeliveryType]);

  const pharmacyChange = (value: DeliverySelectionTypeEnum) => {
    setSelectedDeliveryType(value);
  };

  return (
    <div className="tw-card p-4">
      <form className="space-y-4">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
          {strings.delivery}
        </h2>
        <div className="divide-y dark:divide-gray-700">
          <div className="pb-1">
            <Button
              onClick={() => {
                pharmacyChange(DeliverySelectionTypeEnum.NONE);
                setValue("deliveryType", DeliverySelectionTypeEnum.NONE);
              }}
              variant="basic"
            >
              {selectedDeliveryType === DeliverySelectionTypeEnum.NONE ? (
                <RadioButtonOption className="text-sky-500" checked />
              ) : (
                <RadioButtonOption className="text-gray-500" checked={false} />
              )}
              <div className="ml-2">{strings.none}</div>
            </Button>
          </div>
          <div className="py-1">
            <Button
              onClick={() => {
                pharmacyChange(DeliverySelectionTypeEnum.PHARMACY);
                setValue("deliveryType", DeliverySelectionTypeEnum.PHARMACY);
              }}
              variant="basic"
            >
              {selectedDeliveryType === DeliverySelectionTypeEnum.PHARMACY ? (
                <RadioButtonOption className="text-sky-500" checked />
              ) : (
                <RadioButtonOption className="text-gray-500" checked={false} />
              )}
              <div className="ml-2">{strings.pharmacy}</div>
            </Button>
            <div
              className="py-2 space-y-4"
              hidden={
                selectedDeliveryType !== DeliverySelectionTypeEnum.PHARMACY
              }
            >
              {!isPharmacySelected ? (
                <AlertBox
                  type={AlertType.INFO}
                  message={strings.chooseASpecificPharmacy}
                />
              ) : (
                <></>
              )}
              <Controller
                control={control}
                name="pharmacy"
                render={({ field: { onChange } }) => (
                  <PharmacyForm
                    selectPharmacy={(p: Pharmacy | undefined) => {
                      if (p) {
                        const value: ExtendedPharmacy = {
                          shortName: p.shortName,
                          glnCode: p.glnCode,
                          longName: p.longName,
                          mailingAddress: p.mailingAddress,
                          officeAddress: p.officeAddress,
                          zipCode: p.zipCode,
                          city: p.city,
                          countyName: p.countyName,
                          municipalityName: p.municipalityName,
                          generalPhone: p.generalPhone,
                          prescriptionPhone: p.prescriptionPhone,
                        };
                        onChange(value);
                      } else {
                        onChange(undefined);
                      }
                    }}
                    geoPharmacies={geoPharmacies}
                  />
                )}
                rules={{
                  validate: {
                    ifPharmacyDelivery: (v) => {
                      const type = getValues();
                      if (
                        type.deliveryType === DeliverySelectionTypeEnum.PHARMACY
                      ) {
                        return !!v;
                      }
                      return true;
                    },
                  },
                }}
              />
            </div>
          </div>
          <div className="pt-1">
            <Button
              onClick={() => {
                pharmacyChange(DeliverySelectionTypeEnum.HOME);
                setValue("deliveryType", DeliverySelectionTypeEnum.HOME);
              }}
              variant="basic"
            >
              {selectedDeliveryType === DeliverySelectionTypeEnum.HOME ? (
                <RadioButtonOption className="text-sky-500" checked />
              ) : (
                <RadioButtonOption className="text-gray-500" checked={false} />
              )}
              <div className="ml-2">{strings.address}</div>
            </Button>
            <div
              className="py-2 space-y-4"
              hidden={selectedDeliveryType !== DeliverySelectionTypeEnum.HOME}
            >
              {!isPharmacySelected ? (
                <AlertBox
                  type={AlertType.INFO}
                  message={strings.chooseASpecificPharmacy}
                />
              ) : (
                <></>
              )}
              <HomeDeliveryAddress owner={owner} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DeliveryHomeOrPharmacy;
