/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Control, Controller } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import Button from "../../../components/Button";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import { VideoCamera } from "../../../common/Icons/VideoCamera";
import { XMark } from "../../../common/Icons/XMark";
import { PetState } from "../../../models/calendar/PetState";
import { NewReservationPetType } from "../../../models/calendar/NewReservationPetType";

const VideoConsultation = ({
  control,
  petState,
}: {
  control: Control<any>;
  petState?: PetState;
}): ReactElement => {
  return (
    <Controller
      control={control}
      name="isVideoConsultation"
      render={({ field: { onChange, value } }) => (
        <>
          {value ? (
            <div className="tw-card flex py-2 px-3 items-center">
              <VideoCamera
                className="w-6 h-6 text-gray-500 dark:text-gray-400 mr-3"
                variant="outline"
              />{" "}
              {strings.videoConsultation}
              <div className="ml-auto">
                <Button
                  onClick={() => {
                    onChange(false);
                  }}
                  variant="icon"
                >
                  <XMark />
                </Button>
              </div>
            </div>
          ) : (
            <Button
              className="tw-link-tercier "
              variant="link"
              onClick={() => {
                onChange(true);
              }}
            >
              <div className="flex">
                <PlusIcon />
                <span className="ml-1">{strings.addVideoConsultation}</span>
              </div>
            </Button>
          )}
        </>
      )}
      rules={{
        validate: {
          isEmailAvailable: (value) => {
            if (value !== undefined && value) {
              if (petState?.state === NewReservationPetType.SEARCH) {
                return true;
              } else if (
                petState?.state === NewReservationPetType.PET_OWNER_FORM
              ) {
                return strings.needPetOwnerEmailError;
              }

              if (petState?.petOwner) {
                if (petState.petOwner.emails.length > 0) {
                  return true;
                } else {
                  return strings.needPetOwnerEmailError;
                }
              }
            } else {
              return true;
            }
          },
        },
      }}
    />
  );
};

export default VideoConsultation;
