/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { Pharmacy } from "../models/pharmacy/Pharmacy";
import { County } from "../models/pharmacy/County";
import { Municipality } from "../models/pharmacy/Municipality";
import { PharmacyType } from "../models/pharmacy/PharmacyType";
import { City } from "../models/pharmacy/City";
import Params from "../common/Params";

export default class PharmacyApi {
  private static baseUrl = Params.pharmacyService;

  static getCities(): ApiPromise<City[]> {
    const url = `${this.baseUrl}/api/cities`;
    return axiosCommon.get(url);
  }

  static getCounties(keyWord?: string): ApiPromise<County[]> {
    let url = `${this.baseUrl}/api/counties`;

    if (keyWord) {
      url += `?keyword=${keyWord}`;
    }
    return axiosCommon.get(url);
  }

  static getCountiesById(id: string): ApiPromise<County> {
    const url = `${this.baseUrl}/api/counties/${id}`;

    return axiosCommon.get(url);
  }

  static getMunicipalities(keyWord?: string): ApiPromise<Municipality[]> {
    let url = `${this.baseUrl}/api/municipalities`;

    if (keyWord) {
      url += `?keyword=${keyWord}`;
    }
    return axiosCommon.get(url);
  }

  static getMunicipalitiesById(id: string): ApiPromise<Municipality> {
    const url = `${this.baseUrl}/api/municipalities/${id}`;

    return axiosCommon.get(url);
  }

  static getPharmacies(): ApiPromise<Pharmacy[]> {
    const url = `${this.baseUrl}/api/pharmacies`;
    return axiosCommon.get(url);
  }

  static getPharmacyById(id: string): ApiPromise<Pharmacy> {
    const url = `${this.baseUrl}/api/pharmacies/${id}`;
    return axiosCommon.get(url);
  }

  static getPharmacyByIdByMunicipalityId(
    pId: string,
    mId: string
  ): ApiPromise<Pharmacy> {
    const url = `${this.baseUrl}/api/pharmacies/${pId}/${mId}`;
    return axiosCommon.get(url);
  }

  static getPharmacyTypes(): ApiPromise<PharmacyType[]> {
    const url = `${this.baseUrl}/api/pharmacies/types`;
    return axiosCommon.get(url);
  }
}
