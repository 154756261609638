/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { createContext, useContext, useEffect, useState } from "react";
import { ChildrenProps } from "../models/ChildrenProps";
import { NotificationResponse } from "../models/notification/NotificationResponse";
import NotificationApi from "../api/NotificationApi";
import logger from "../util/logger";
import { useUser } from "./UserContext";

interface INotificationContext {
  notifications: NotificationResponse[];
  fetchNotifications: () => Promise<void>;
}

const NotificationContext = createContext<INotificationContext | null>(null);

export const useNotification = () => useContext(NotificationContext)!!;

export const NotificationProvider: React.FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [notifications, setNotifications] = useState<NotificationResponse[]>(
    []
  );

  const [isReloadNeeded, setReloadNeeded] = useState<boolean>(true);
  const reloadTimeMillis = 60 * 1000;

  const { user } = useUser();

  const getNotifications = async () => {
    try {
      const response = await NotificationApi.getNotifications();
      setNotifications(response.data);
    } catch (e) {
      logger.error(e);
    }
  };

  useEffect(() => {
    if (!isReloadNeeded) {
      const reloadCallback = () => {
        setReloadNeeded(true);
      };
      setTimeout(reloadCallback, reloadTimeMillis);
    }
  }, [isReloadNeeded]);

  useEffect(() => {
    if (user.userId != undefined && isReloadNeeded) {
      void getNotifications();
      setReloadNeeded(false);
    }
  }, [user, isReloadNeeded]);

  const ctx = {
    notifications,
    fetchNotifications: getNotifications,
  };

  return (
    <NotificationContext.Provider value={ctx}>
      {children}
    </NotificationContext.Provider>
  );
};
