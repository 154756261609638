/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { findLocale, strings } from "../../../common/Strings/Strings";
import Params from "../../../common/Params";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import RecoveryApi from "../../../api/RecoveryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";

interface Props {
  setCaptcha: (captcha: ReCAPTCHA | null) => void;
  resetCaptcha: () => void;
  captchaValid: boolean;
  recaptchaToken: string | null;
  onCaptchaChange: (captchaToken: string | null) => void;
}

interface EmailRecoveryForm {
  email: string;
}

const defaultFormValues = {
  email: "",
};

const EmailRecovery: React.FC<Props> = ({
  setCaptcha,
  resetCaptcha,
  captchaValid,
  recaptchaToken,
  onCaptchaChange,
}: Props) => {
  const [alertMessage, setAlertMessage] = useState<
    string | ReactElement | null
  >(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.INFO);
  const [loading, setLoading] = useState<boolean>(false);

  const submitForm = async (formData: EmailRecoveryForm) => {
    setLoading(true);
    try {
      await RecoveryApi.requireMailRecovery(formData, recaptchaToken);
      setAlertMessage(
        <>
          {strings.recoveryEmailOk} <strong>{formData.email}</strong>
        </>
      );
      setAlertType(AlertType.INFO);
    } catch (e) {
      setAlertMessage(await getGeneralError(e));
      setAlertType(AlertType.ERROR);
    } finally {
      setLoading(false);
      resetCaptcha();
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<EmailRecoveryForm>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  return (
    <form className="space-y-6" onSubmit={handleSubmit(submitForm)}>
      <Field
        name="email"
        label={strings.emailAddress}
        type="email"
        register={register}
        autoComplete={AutoCompleteOptions.email}
        required
        error={errors.email}
      />
      {Params.captcha === "on" && (
        <ReCAPTCHA
          ref={(e) => {
            setCaptcha(e);
          }}
          hl={findLocale()}
          sitekey={Params.recaptchaSiteKey}
          size="normal"
          onChange={onCaptchaChange}
        />
      )}
      <AlertBox closeAble={false} message={alertMessage} type={alertType} />
      <Button
        disabled={
          !isDirty ||
          !isValid ||
          loading ||
          (!captchaValid && Params.captcha === "on")
        }
        loading={loading}
        type="submit"
      >
        {strings.sendRecoveryEmail}
      </Button>
    </form>
  );
};

export default EmailRecovery;
