/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const petSpeciesSv = {
  ALPACA: "Alpacka",
  ASS: "Nöt",
  BIRD: "Fågel",
  CAMEL: "Kameldjur",
  CAT: "Katt",
  CHICKEN: "Höns, inkl slakt- kyckling",
  CHINCHILLA: "Chinchilla",
  CHINCHILLA_LIVESTOCK: "Chinchilla (boskap)",
  DEER: "Hjort",
  DEGU: "Degu",
  DOG: "Hund",
  DONKEY: "Åsna",
  DUCK: "Anka",
  FERRET: "Iller",
  FISH_FARMED: "Fisk, odlad",
  FISH_WILD: "Fisk, vildlevande",
  FUR_ANIMAL: "Pälsdjur",
  GOAT: "Get",
  GOOSE: "Gås",
  GUINEA_PIG: "Marsvin",
  HAMSTER: "Hamster",
  HORSE: "Häst",
  INSECT: "Insekter",
  MINK: "Mink",
  MOOSE: "Älg",
  MOUSE: "Mus",
  MUSSEL_FARMED: "Mussla, odlad",
  OSTRICH: "Strutsdjur",
  OTHER_LIVESTOCK: "Övriga djur",
  OTHER_PRODUCTION: "Övrigt animalie- produktion",
  OTHER_FENCED_GAME: "Övrigt hägnat vilt",
  OTHER_GAME: "Övrigt vilt",
  OTHER: "Övrigt sällskapsdjur",
  OTHER_POULTRY: "Övriga fjäderfä",
  PIG: "Gris",
  PIG_LIVESTOCK: "Gris (boskap)",
  RABBIT: "Kanin",
  RABBIT_LIVESTOCK: "Kanin (boskap)",
  RAT: "Råtta",
  REINDEER: "Ren",
  REPTILE: "Reptiler",
  SHEEP: "Får",
  TURKEY: "Kalkon",
  WILD_BOAR: "Vildsvin",
  ZOO_ANIMAL: "Zoodjur"
};