/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { PetOwnerMenuItem } from "../../MenuItem/MenuItemTypes";
import { useModal } from "../../../../contexts/ModalContext";
import FooterMenuItem from "../FooterMenuItem";
import PetOwnerMenuItems from "../../MenuItem/PetOwnerMenuItems";

interface Props {
  activeMenu?: PetOwnerMenuItem;
}

function PetOwnerMobileMenu(props: Props): ReactElement | null {
  const { openModalIds } = useModal();

  // Hide the menu when the user menu dropdown is open
  if (openModalIds.includes("userDropdownMenuMobile")) {
    return null;
  }

  return (
    <div
      className="fixed z-30 bottom-0 left-0 right-0 xl:hidden w-full pt-1.5 pb-1.5 bg-white border-t border-gray-200 items-center justify-between p-4 dark:bg-gray-800 dark:border-gray-700"
      id="owner-mobile-menu"
    >
      <div className="container mx-auto">
        <ul className="flex items-center justify-around text-sm font-medium">
          {PetOwnerMenuItems.map((item, index) => (
            <li key={index}>
              <FooterMenuItem
                menu={item.type}
                name={item.name}
                activeMenu={props.activeMenu}
                href={item.href}
                icon={item.icon}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PetOwnerMobileMenu;
