/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import {
  postReservationStates,
  preReservationStates,
  ReservationState,
} from "../../../models/reservation/ReservationState";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import { Colors } from "../../../models/Colors";
import LoaderInline from "../../../components/LoaderInline";
import ReservationApi from "../../../api/ReservationApi";
import { getGeneralError } from "../../../util/helperFunctions";
import ClickOutsideDetector from "../../../components/ClickOutsideDetector";
import { MedicalRecordResponse } from "../../../models/medical/MedicalRecordResponse";
import MedicalRecordApi from "../../../api/MedicalRecordApi";
import { ChevronDown } from "../../../common/Icons/ChevronDown";
import { CheckSimple } from "../../../common/Icons/CheckSimple";
import { CurrencyDollar } from "../../../common/Icons/CurrencyDollar";

interface Props {
  disabled?: boolean;
  dropUp?: boolean;
  medicalRecordId?: string;
  reservationId?: string;
  reservationStatus?: ReservationState;
  setError(message: string | null): void;
  updateMedicalRecord(
    reservationState: ReservationState,
    medicalRecord?: MedicalRecordResponse
  ): void;
}

enum InvoiceStatus {
  OPEN = "OPEN",
  PAID = "PAID",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  READY_FOR_PAYMENT = "READY_FOR_PAYMENT",
}

const invoiceStatusProps = {
  OPEN: {
    color: Colors.TABLE_GROUP_BG,
    icon: { name: "attach_money", type: "material-icons" },
    iconFilled: { name: "paid", type: "material-icons" },
  },
  PAID: {
    color: Colors.CATEGORY_COLOR_2,
    icon: { name: "attach_money", type: "material-icons" },
    iconFilled: { name: "paid", type: "material-icons" },
  },
  PENDING_PAYMENT: {
    color: Colors.RED,
    icon: { name: "attach_money", type: "material-icons" },
    iconFilled: { name: "paid", type: "material-icons" },
  },
  READY_FOR_PAYMENT: {
    color: Colors.NAVBARMEDIUM,
    icon: { name: "attach_money", type: "material-icons" },
    iconFilled: { name: "paid", type: "material-icons" },
  },
};

const invoiceToReservationState = (state: InvoiceStatus): ReservationState => {
  switch (state) {
    case InvoiceStatus.READY_FOR_PAYMENT:
      return ReservationState.READY_FOR_PAYMENT;
    case InvoiceStatus.PAID:
      return ReservationState.PAID;
    case InvoiceStatus.PENDING_PAYMENT:
      return ReservationState.PENDING_PAYMENT;
    default:
      return ReservationState.READY_FOR_PAYMENT;
  }
};

const reservationToInvoiceState = (state?: ReservationState): InvoiceStatus => {
  switch (state) {
    case ReservationState.READY_FOR_PAYMENT:
      return InvoiceStatus.READY_FOR_PAYMENT;
    case ReservationState.PAID:
      return InvoiceStatus.PAID;
    case ReservationState.PENDING_PAYMENT:
      return InvoiceStatus.PENDING_PAYMENT;
    default:
      return InvoiceStatus.READY_FOR_PAYMENT;
  }
};

const InvoiceStatusSelect = ({
  dropUp = false,
  medicalRecordId,
  reservationId,
  reservationStatus,
  setError,
  updateMedicalRecord,
}: Props): ReactElement => {
  const [isDown, setIsDown] = useState<boolean>(false);
  const [status, setStatus] = useState<InvoiceStatus>(InvoiceStatus.OPEN);
  const [statuses, setStatuses] = useState<Array<InvoiceStatus>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(false);

    if (reservationStatus) {
      if (postReservationStates.includes(reservationStatus)) {
        setStatus(reservationToInvoiceState(reservationStatus));
      } else if (preReservationStates.includes(reservationStatus)) {
        setStatus(InvoiceStatus.OPEN);
      }
    }
  }, [reservationStatus]);

  useEffect(() => {
    if (status === InvoiceStatus.OPEN) {
      setStatuses([InvoiceStatus.OPEN, InvoiceStatus.READY_FOR_PAYMENT]);
    } else {
      setStatuses([
        InvoiceStatus.READY_FOR_PAYMENT,
        InvoiceStatus.PENDING_PAYMENT,
        InvoiceStatus.PAID,
      ]);
    }
  }, [status]);

  const createInvoice = async () => {
    if (medicalRecordId) {
      setLoading(true);
      setIsDown(false);
      try {
        const response = await MedicalRecordApi.createInvoice(medicalRecordId);
        setStatus(InvoiceStatus.READY_FOR_PAYMENT);
        updateMedicalRecord(ReservationState.READY_FOR_PAYMENT, response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  const updateReservationState = async (state: ReservationState) => {
    if (reservationId) {
      setLoading(true);
      setIsDown(false);

      try {
        const response = await ReservationApi.updateReservationState(
          reservationId,
          { state }
        );
        setStatus(reservationToInvoiceState(response.data.state));
        updateMedicalRecord(state);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    }
  };

  const updateState = async (state: InvoiceStatus) => {
    if (
      state === InvoiceStatus.READY_FOR_PAYMENT &&
      reservationStatus &&
      preReservationStates.includes(reservationStatus)
    ) {
      void createInvoice();
    } else {
      void updateReservationState(invoiceToReservationState(state));
    }
  };

  return (
    <ClickOutsideDetector
      listen
      onClickOutside={() => {
        setIsDown(false);
      }}
    >
      <div className={`dropdown flex-fill${dropUp ? " dropup" : ""}`}>
        <div
          className="tw-card w-full flex justify-between items-center p-3 cursor-pointer"
          onClick={() => {
            setIsDown(!isDown);
          }}
          role="button"
          tabIndex={0}
        >
          <div className="flex items-center w-full space-x-2">
            <div style={{ color: invoiceStatusProps[status].color }}>
              <CurrencyDollar className="flex-shrink-0 w-6 h-6" />
            </div>
            <div className="font-bold grow">
              {loading ? (
                <LoaderInline />
              ) : (
                getStringFromEnumsOrReturn(status) || strings.notArrivedYet
              )}
            </div>
            <div className="ml-auto">
              <ChevronDown className="w-6 h-6 text-slate-500 dark:text-slate-400 shrink-0" />
            </div>
          </div>
        </div>
        <div
          className={`z-30 shadow-md rounded-lg w-full bg-white font-normal divide-y divide-gray-100 dark:bg-gray-700 dark:divide-gray-600 overflow-y-auto absolute ${
            isDown ? "block" : "hidden"
          }`}
        >
          <ul className="py-2 text-md text-gray-700 dark:text-gray-400">
            {statuses.map((st: InvoiceStatus, index) => (
              <li
                key={index}
                className="px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <div className="flex space-x-2 font-bold">
                  <div style={{ color: invoiceStatusProps[st].color }}>
                    <CurrencyDollar className="flex-shrink-0 w-6 h-6" />
                  </div>
                  {st === status ? (
                    <div className="flex w-full">
                      <div>{getStringFromEnumsOrReturn(st)}</div>
                      <div className="ml-auto">
                        <CheckSimple />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                          void updateState(st);
                        }}
                        type="button"
                      >
                        {getStringFromEnumsOrReturn(st)}
                      </button>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </ClickOutsideDetector>
  );
};

export default InvoiceStatusSelect;
