/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../../common/Strings/Strings";
import { UserClinicWithRolesResponse } from "../../../../models/clinic/UserClinicWithRolesResponse";
import Button from "../../../../components/Button";
import { useClinic } from "../../../../contexts/ClinicContext";
import { Cog } from "../../../../common/Icons/Cog";
import { ClinicRole } from "../../../../models/clinic/ClinicRole";
import PendingClinics from "./PendingClinics";
import { clinicPlaceholderColored } from "../../../../assets/AssetConfig";
import { PlusIcon } from "../../../../common/Icons/PlusIcon";
import ClinicLogo from "../../../../components/Pictures/Clinic/ClinicLogo";

interface Props {
  myClinics: UserClinicWithRolesResponse[];
}

const Clinics: React.FC<Props> = ({ myClinics }: Props) => {
  const navigate = useNavigate();
  const { fetchClinic } = useClinic();

  const chooseClinic = async (userClinic: UserClinicWithRolesResponse) => {
    await fetchClinic(userClinic.clinic.id);
    navigate("/");
  };

  return (
    <main className="main-signed-in-no-clinic">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <PendingClinics />
            {myClinics.length === 0 ? (
              <div className="flex justify-center px-4 py-10 md:px-0 md:py-14">
                <div className="md:tw-card w-full space-y-6 text-center md:max-w-md md:p-8">
                  <div>
                    <h1 className="text-xl font-semibold leading-tight text-zinc-800 dark:text-white lg:text-2xl">
                      {strings.createClinic}
                    </h1>
                  </div>
                  <div>
                    <p>{strings.noClinicYet}</p>
                  </div>
                  <div className="flex items-center justify-center">
                    <div className="relative">
                      <img
                        src={clinicPlaceholderColored}
                        className="w-40"
                        alt="Add company"
                      />
                      <Link to="/clinics/create">
                        <button
                          type="button"
                          className="tw-btn-circle-primary absolute bottom-0 right-0 p-4"
                        >
                          <PlusIcon />
                          <span className="sr-only">Plus</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div>
                    <Link to="/clinics/create">
                      <button type="button" className="tw-btn-primary w-full">
                        {strings.createNewClinic}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="flex">
                  <h1 className="text-xl font-semibold leading-tight text-zinc-800 dark:text-white lg:text-2xl">
                    {strings.clinics}
                  </h1>
                  <div className="ml-auto">
                    <Link to="/clinics/create">
                      <Button variant="primary">
                        {strings.createNewClinic}
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6">
                  {myClinics.map((ucwr: UserClinicWithRolesResponse) => (
                    <div
                      className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 flex flex-col justify-between tw-card shadow divide-y  hover:border-sky-500"
                      key={ucwr.clinic.id}
                    >
                      <div className="flex flex-col items-center p-4 space-y-4 border-gray-200 dark:border-gray-700">
                        <div className="ml-auto">
                          <Link
                            hidden={
                              !ucwr.roles.includes(ClinicRole.OWNER) &&
                              !ucwr.roles.includes(ClinicRole.MANAGER)
                            }
                            className="tw-icon tw-link"
                            to={`/globalvet/settings/${ucwr.clinic.id}`}
                          >
                            <span className="sr-only">{strings.settings}</span>
                            <Cog className="w-5 h-5" />
                          </Link>
                        </div>
                        <div
                          className="w-40 h-40"
                          onClick={() => {
                            void chooseClinic(ucwr);
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <ClinicLogo
                            clinicId={ucwr?.clinic.id}
                            logoPictureId={ucwr.clinic?.logoPictureId}
                          />
                        </div>
                        <div
                          className="text-center font-bold"
                          onClick={() => {
                            void chooseClinic(ucwr);
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          {ucwr.clinic.name}
                        </div>
                      </div>
                      <div
                        className="justify-center flex p-4 border-gray-200 dark:border-gray-700"
                        onClick={() => {
                          void chooseClinic(ucwr);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {ucwr.roles.map(getStringFromEnumsOrReturn).join(", ")}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Clinics;
