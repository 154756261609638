/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useRef, useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import StripeSetupForm, {
  StripeSetupFormRef,
} from "../../../../Stripe/StripeSetupForm";
import { SetupIntentResponse } from "../../../../../models/stripe/SetupIntentResponse";
import { getGeneralError } from "../../../../../util/helperFunctions";
import StripeApi from "../../../../../api/StripeApi";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal/Modal";
import { ContactType } from "../../../../../models/contact/ContactType";
import StripeSection from "../../../../Stripe/StripeSection";
import LoaderInline from "../../../../../components/LoaderInline";

interface Props {
  clinic: ClinicResponse;
  setClinic: (c: ClinicResponse) => void;
  show: boolean;
  close: () => void;
}

const EditCardModal: React.FC<Props> = ({
  clinic,
  setClinic,
  show,
  close,
}: Props) => {
  const [stripeSetupIntent, setStripeSetupIntent] =
    useState<SetupIntentResponse>();
  const [isStripeFormValid, setStripeFormValid] = useState<boolean>(false);

  const [closeable, setCloseable] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [alertClass, setAlertClass] = useState<AlertType>(AlertType.ERROR);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const stripeSetupRef = useRef<StripeSetupFormRef>(null);

  const getStripeSetupIntent = async () => {
    try {
      setLoading(true);
      if (clinic.countryCode) {
        const response = await StripeApi.getSetupIntent(clinic.countryCode);
        setStripeSetupIntent(response.data);
      }
    } catch (err) {
      setAlertMessage(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  const onShow = () => {
    void getStripeSetupIntent();
  };

  const onHide = () => {
    if (closeable) {
      close();
      setStripeSetupIntent(undefined);
      setAlertMessage(null);
    }
  };

  const saveCreditCard = async (stripeSetupIntentId: string) => {
    try {
      const response = await StripeApi.updateCreditCard(
        clinic.id,
        stripeSetupIntentId
      );
      const { data: clinicData } = response;
      setClinic(clinicData);
      setAlertMessage(strings.savedSuccessfully);
      setAlertClass(AlertType.SUCCESS);
      close();
    } catch (error) {
      setAlertMessage(await getGeneralError(error));
      setAlertClass(AlertType.ERROR);
    }
  };

  const confirmCreditCard = async () => {
    if (!stripeSetupRef.current) return;
    setCloseable(false);
    setLoading(true);

    try {
      const response = await stripeSetupRef.current.confirmSetup();
      if (response?.setupIntent && !response?.error) {
        await saveCreditCard(response.setupIntent.id);
      } else {
        setAlertMessage(response?.error?.message ?? strings.simpleError);
      }
    } catch (e) {
      const err: any = e;
      setAlertMessage(err?.message ?? strings.simpleError);
    } finally {
      setCloseable(true);
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={onHide} onShow={onShow} show={show}>
      <Modal.Header title={strings.creditCardModification} />
      <Modal.Body>
        <LoaderInline className="my-3" hidden={!loading} />
        <div hidden={loading}>
          <AlertBox
            type={AlertType.WARNING}
            message={strings.baseChargeWarning}
            closeAble={false}
          />
          <div className="mt-3">
            {stripeSetupIntent && (
              <StripeSection
                apiKey={stripeSetupIntent.publicKey}
                clientSecret={stripeSetupIntent.clientSecret}
              >
                <StripeSetupForm
                  ref={stripeSetupRef}
                  onValidation={(isValid) => setStripeFormValid(isValid)}
                  address={clinic.addresses.find(
                    (i) => i.contactType === ContactType.PRIMARY
                  )}
                />
              </StripeSection>
            )}
          </div>
          <AlertBox type={alertClass} message={alertMessage} className="mt-3" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading || !isStripeFormValid}
          loading={loading}
          onClick={confirmCreditCard}
        >
          {strings.save}
        </Button>
        <Button
          className="modal-sub-button"
          disabled={!closeable}
          onClick={onHide}
          variant="secondary"
        >
          {strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCardModal;
