/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Dropdown } from "flowbite-react";
import { NotificationResponse } from "../../../models/notification/NotificationResponse";
import { NotificationType } from "../../../models/notification/NotificationType";
import ClinicRequestNotification from "./NotificationTypes/ClinicRequestNotification";
import OwnerPosteriorRegistrationNotification from "./NotificationTypes/OwnerPosteriorRegistrationNotification";
import PetTransferNewOwnerNotification from "./NotificationTypes/PetTransferNewOwnerNotification";
import PetTransferOldOwnerNotification from "./NotificationTypes/PetTransferOldOwnerNotification";
import { strings } from "../../Strings/Strings";

interface Props {
  refresh: () => void;
  notifications: NotificationResponse[];
}

export const NotificationList: React.FC<Props> = ({
  notifications,
  refresh,
}: Props) => (
  <>
    {!notifications.length && (
      <Dropdown.Item className="cursor-default flex justify-center items-center">
        <div className="w-full text-center">
          <i>{strings.noUnreadNotifications}</i>
        </div>
      </Dropdown.Item>
    )}
    {notifications.map((n: NotificationResponse, i) => (
      <>
        <Dropdown.Item className="cursor-default flex justify-center items-center">
          <div key={i} className="w-full flex flex-col py-2 px-3">
            {n.type === NotificationType.CLINIC_EMPLOYMENT_REQUEST &&
              n.clinicEmploymentRequest && (
                <ClinicRequestNotification
                  refresh={refresh}
                  request={n.clinicEmploymentRequest}
                />
              )}
            {n.type === NotificationType.LINKED_UNREGISTERED_PETS &&
              n.clinicRegisteredPets && (
                <OwnerPosteriorRegistrationNotification refresh={refresh} />
              )}
            {n.type === NotificationType.PET_TRANSFER_NEW_OWNER &&
              n.petTransfer && (
                <PetTransferNewOwnerNotification
                  refresh={refresh}
                  request={n.petTransfer}
                />
              )}
            {n.type === NotificationType.PET_TRANSFER_OLD_OWNER &&
              n.petTransfer && (
                <PetTransferOldOwnerNotification
                  refresh={refresh}
                  request={n.petTransfer}
                />
              )}
          </div>
        </Dropdown.Item>
        {notifications[i + 1] ? <Dropdown.Divider className="h-0.5" /> : null}
      </>
    ))}
  </>
);

export default NotificationList;
