/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import PackageList from "./PackageList";
import { CancellationFlag } from "../../models/ePrescription/cancellation/CancellationFlag";
import {
  prescriptionTreatmentPurpose,
  prescriptionWaitingPeriodType,
  strings,
} from "../../common/Strings/Strings";
import WithdrawalModal from "./WithdrawalModal";
import { ExtendedPrescription } from "../../models/ePrescription/persistence/ExtendedPrescription";
import { ExtendedPrescriptionItem } from "../../models/ePrescription/persistence/ExtendedPrescriptionItem";
import { ExtendedPackage } from "../../models/ePrescription/persistence/ExtendedPackage";
import { CancellationItemExtended } from "../../models/ePrescription/local/history/CancellationItemExtended";
import { CancellationPackageExtended } from "../../models/ePrescription/local/history/CancellationPackageExtended";
import { WaitingPeriod } from "../../models/ePrescription/sending/WaitingPeriod";
import { PrescriptionResponseModel } from "../../models/ePrescription/response/PrescriptionResponseModel";
import { NewPrescriptionStatus } from "../../models/ePrescription/response/NewPrescriptionStatus";
import { HistoryData } from "../../models/ePrescription/history/HistoryData";
import AlertBox, { AlertType } from "../../components/AlertBox";
import { PrescriptionStatus } from "../../models/ePrescription/response/PrescriptionStatus";
import Button from "../../components/Button";

interface Props {
  activePrescription?: ExtendedPrescription;
  historyData: HistoryData;
  triggerReload(): void;
  status?: PrescriptionResponseModel;
  withdrawalAllowed: boolean;
}

const ItemList: React.FC<Props> = ({
  activePrescription,
  historyData,
  triggerReload,
  status,
  withdrawalAllowed,
}: Props) => {
  const [cancellationItems, setCancellationItems] =
    useState<CancellationItemExtended[]>();
  const [withdrawalModal, setWithdrawalModal] = useState<boolean>(false);

  const closeModal = () => {
    setWithdrawalModal(false);
  };

  const openModal = () => {
    setWithdrawalModal(true);
  };

  useEffect(() => {
    if (activePrescription) {
      const items: CancellationItemExtended[] = [];

      if (activePrescription.items) {
        activePrescription.items.forEach((item: ExtendedPrescriptionItem) => {
          const packages: CancellationPackageExtended[] = [];

          item.packages.forEach((pack: ExtendedPackage) => {
            const p: CancellationPackageExtended = {
              ...pack,
              isSelectedForCancellation: false,
              isCancelledBefore:
                !!pack.cancellationDetails &&
                pack.cancellationDetails.cancellationFlag !==
                  CancellationFlag.DO_NOT_CANCEL,
            };
            packages.push(p);
          });

          items.push({
            title: item.product
              ? item.product.name
              : item.licensed
              ? item.licensed.supplementaryInformation
              : strings.prescriptionItem,
            instruction: item.instructions.text,
            packages,
            waitingPeriods: item.waitingPeriods,
            repeatDispensing: item.repeatDispensing,
            latestRequestedTimeForDispensing:
              item.latestRequestedTimeForDispensing,
            antimicrobial: item.antimicrobial,
            validUntil: item.validUntil,
          });
        });

        setCancellationItems(items);
      }
    }
  }, [activePrescription]);

  const addCancellationItems = (
    ci: CancellationItemExtended,
    index: number
  ) => {
    if (cancellationItems) {
      const tempCancellationItems = [...cancellationItems];
      tempCancellationItems[index] = ci;
      setCancellationItems(tempCancellationItems);
    }
  };

  const isNonSelected = (): boolean => {
    let cnt = 0;

    if (cancellationItems) {
      const temp = [...cancellationItems];
      temp.forEach((ci: CancellationItemExtended) => {
        ci.packages.forEach((cip: CancellationPackageExtended) => {
          if (cip.isSelectedForCancellation) {
            cnt += 1;
          }
        });
      });
    }

    return cnt > 0;
  };

  return (
    <div>
      <div className="divide-y dark:divide-gray-700 space-y-4">
        {cancellationItems?.map(
          (i: CancellationItemExtended, index: number) => (
            <div className="pt-4" key={index}>
              <b>{i.title}</b>
              <div>{i.instruction}</div>
              {i.antimicrobial ? (
                <div>
                  {strings.treatmentPurpose}:{" "}
                  <b>{prescriptionTreatmentPurpose[i.antimicrobial]}</b>
                </div>
              ) : (
                <></>
              )}
              {i.validUntil ? (
                <div>
                  {strings.validUntil}: <b>{i.validUntil}</b>
                </div>
              ) : (
                <></>
              )}
              {i.repeatDispensing.numberOfTimes !== 1 ? (
                <div>
                  {strings.limit}: <b>{i.repeatDispensing.numberOfTimes}</b>
                </div>
              ) : (
                <></>
              )}
              {i.repeatDispensing.interval ? (
                <div>
                  {strings.expeditionInterval}:{" "}
                  <b>{`${i.repeatDispensing.interval} ${
                    i.repeatDispensing.intervalUnit
                      ? strings[i.repeatDispensing.intervalUnit.toLowerCase()]
                      : ""
                  }`}</b>
                </div>
              ) : (
                <></>
              )}
              {i.latestRequestedTimeForDispensing ? (
                <div>
                  {strings.latestRequestedTimeForDispensing}:{" "}
                  <b>{i.latestRequestedTimeForDispensing}</b>
                </div>
              ) : (
                <></>
              )}
              {i.waitingPeriods ? (
                <div>
                  <div>
                    {strings.waitingPeriods}:&nbsp;
                    <b>
                      {i.waitingPeriods.map(
                        (wp: WaitingPeriod, ind: number) => (
                          <span key={ind}>{`${
                            prescriptionWaitingPeriodType[wp.type]
                          }: ${wp.days} ${strings.days} `}</span>
                        )
                      )}
                    </b>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <PackageList
                activePrescriptionItem={i}
                index={index}
                returnCancelledItems={addCancellationItems}
                status={status}
                withdrawalAllowed={withdrawalAllowed}
                historyData={historyData}
              />
            </div>
          )
        )}
      </div>
      {withdrawalAllowed ? (
        <div className="text-right mb-3">
          <Button
            variant="danger"
            onClick={openModal}
            disabled={!isNonSelected()}
          >
            {strings.withdraw}
          </Button>
          <WithdrawalModal
            open={withdrawalModal}
            close={closeModal}
            cancellationItems={cancellationItems}
            prescription={activePrescription}
            historyData={historyData}
            triggerReload={triggerReload}
          />
        </div>
      ) : (
        <></>
      )}
      {status ? (
        <AlertBox
          className="mb-3"
          closeAble={false}
          type={
            status.result === NewPrescriptionStatus.WARNINGS
              ? AlertType.WARNING
              : AlertType.ERROR
          }
          message={
            <>
              <b className="mb-1">{status.message}</b>
              <div className="small">
                {status.statusList ? (
                  status.statusList.map((s: PrescriptionStatus, i: number) => (
                    <div className="my-1" key={i}>
                      {s.description}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </>
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ItemList;

ItemList.defaultProps = {
  activePrescription: undefined,
  status: undefined,
};
