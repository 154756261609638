/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useParams } from "react-router-dom";
import ClinicApi from "../../../../api/ClinicApi";
import { strings } from "../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../util/helperFunctions";
import { useSearchParams } from "../../../../hooks/hooks";
import LoaderInline from "../../../../components/LoaderInline";
import { useClinic } from "../../../../contexts/ClinicContext";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import Button from "../../../../components/Button";

export const ClinicDeletionConfirmation = (): ReactElement => {
  const { clinicId } = useParams() as any;
  const { clinicName, deletionToken } = useSearchParams();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const { fetchClinics, setClinic } = useClinic();

  const onFinalizeDelete = async () => {
    setIsDeleting(true);
    setErrorMessage(null);

    try {
      await ClinicApi.finalizeClinicDeletion(clinicId, deletionToken);
      setClinic(null);
      fetchClinics();
      setIsDeleted(true);
    } catch (err) {
      setErrorMessage(await getGeneralError(err));
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
          <div className="w-full md:max-w-lg md:tw-card space-y-6 md:p-8">
            <div className="flex justify-between space-x-4">
              <div>
                <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                  {strings.deleteClinic}
                </h1>
              </div>
              <LoaderInline hidden={!isDeleting} />
            </div>
            <AlertBox
              message={strings.clinicDeletionWarning}
              type={AlertType.WARNING}
            />
            <div>
              {`${strings.clinicName}: `}
              <b>{clinicName}</b>
            </div>
            <div hidden={!isDeleted}>
              <AlertBox
                type={AlertType.SUCCESS}
                closeAble={false}
                message={strings.clinicDeleted}
              />
            </div>
            <AlertBox message={errorMessage} />
            <Button
              minW
              variant="danger"
              onClick={onFinalizeDelete}
              hidden={isDeleted || errorMessage != null}
              disabled={isDeleting}
              loading={isDeleting}
            >
              {strings.deleteClinic}
            </Button>
          </div>
        </div>
      </section>
    </main>
  );
};
