/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import Tooltip from "../../../Tooltip";
import { strings } from "../../../../common/Strings/Strings";
import { PhoneResponse } from "../../../../models/contact/PhoneResponse";

interface Props {
  phone: PhoneResponse;
  onClick?: (phone: PhoneResponse) => void;
}

const UnconfirmedPhoneTag: React.FC<Props> = ({ phone, onClick }: Props) => {
  if (phone.confirmed) {
    return null;
  }

  return (
    <span className="mx-3">
      <Tooltip content={strings.phoneNotConfirmedYet} placement="right">
        <i
          className="fas fa-exclamation-triangle"
          role="button"
          tabIndex={0}
          style={{ cursor: "pointer" }}
          onClick={() => {
            onClick && onClick(phone);
          }}
        />
      </Tooltip>
    </span>
  );
};

export default UnconfirmedPhoneTag;
