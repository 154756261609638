/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import GDPRApi from "../../../api/GDPRApi";
import { LocalLanguageResponse } from "../../../models/language/LocalLanguageResponse";
import { PolicyResponse } from "../../../models/management/PolicyResponse";
import { getGeneralError } from "../../../util/helperFunctions";
import LanguageApi from "../../../api/LanguageApi";
import PolicyList from "./PolicyList";
import { PolicyType } from "../../../models/management/PolicyType";
import { policyType } from "../../../common/Strings/Strings";
import LoaderInline from "../../../components/LoaderInline";
import { CountrySettingsResponse } from "../../../models/management/CountrySettingsResponse";
import AlertBox from "../../../components/AlertBox";

interface Props {
  isActiveTab: boolean;
  country: CountrySettingsResponse;
}

export default function PolicySection({
  isActiveTab,
  country,
}: Props): ReactElement {
  const [languages, setLanguages] = useState<LocalLanguageResponse[]>([]);
  const [policies, setPolicies] = useState<PolicyResponse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoaded, setLoaded] = useState<boolean>(false);

  const getPolicies = async () => {
    setLoaded(false);

    try {
      const response = await GDPRApi.getPolicies();
      setPolicies(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoaded(true);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getPolicies();
  }, [isActiveTab]);

  useEffect(() => {
    const getLanguages = async () => {
      try {
        const response = await LanguageApi.getLanguages();
        setLanguages(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      }
    };

    void getLanguages();
  }, []);

  const primaryPolicyChanged = (primaryPolicy: PolicyResponse) => {
    const policyList: PolicyResponse[] = [];

    policies.forEach((policy) => {
      if (
        policy.type === primaryPolicy.type &&
        policy.countryCode === primaryPolicy.countryCode
      ) {
        policy.primaryForCountry =
          policy.languageCode === primaryPolicy.languageCode;
      }
      policyList.push(policy);
    });

    setPolicies(policyList);
  };

  return (
    <>
      <LoaderInline className="my-3" hidden={isLoaded} />

      <AlertBox message={error} className="mb-2" />

      <div hidden={!isLoaded} className="container frame">
        <div className="row">
          <div className="col-sm-2" />
          <div className="col-sm-8">
            {Object.values(PolicyType).map((type) => (
              <PolicyList
                policyType={type}
                policyName={policyType[type]}
                policies={policies.filter((i) => i.type === type)}
                languages={languages}
                country={country}
                onPolicyChanged={() => getPolicies()}
                onPrimaryPolicyChanged={(policy) =>
                  primaryPolicyChanged(policy)
                }
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
