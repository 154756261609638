/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import AddressMapCheck from "../../../components/contact/address/AddressMapCheck";
import { LocatedAddressRequest } from "../../../models/contact/LocatedAddressRequest";

interface Props {
  address?: LocatedAddressRequest;
  countryName?: string;
  onPrevious(): void;
  onSubmit(
    address: LocatedAddressRequest,
    onError: (error: any) => void,
    onFinally: () => void
  ): void;
}

const LocationTab: React.FC<Props> = ({
  address,
  countryName,
  onPrevious,
  onSubmit,
}: Props) => (
  <AddressMapCheck
    address={address}
    countryName={countryName}
    changeAddress={() => {
      onPrevious();
    }}
    isMapCenterToCurrentLocation={false}
    setLocalizedAddress={onSubmit}
  />
);

export default LocationTab;
