/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  getGeneralError,
  localDateFormat,
} from "../../../util/helperFunctions";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../common/Strings/Strings";
import { PageResponse } from "../../../models/PageResponse";
import { AnalyticsEventResponse } from "../../../models/analytics/AnalyticsEventResponse";
import { usePager } from "../../../hooks/hooks";
import AnalyticsApi from "../../../api/AnalyticsApi";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import { ChevronUp } from "../../../common/Icons/ChevronUp";
import { ChevronDown } from "../../../common/Icons/ChevronDown";
import Button from "../../../components/Button";
import DateFilters, { DateTemplate } from "../../../components/DateFilters";
import { FormProvider, useForm } from "react-hook-form";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { clinicAnalyticsEventTypeEn } from "../../../common/Strings/ClinicAnalyticsEventType/en";
import { userAnalyticsEventTypeEn } from "../../../common/Strings/UserAnalyticsEventType/en";

interface Props {
  clinicId?: string;
  userId?: string;
  setPageLoading?: (isLoading: boolean) => void;
}

interface FilterForm {
  dateTemplate: DateTemplate;
  startDate?: Date | null;
  endDate?: Date | null;
  type?: string;
}

const AnalyticsEventsTable: React.FC<Props> = (props: Props) => {
  const { clinicId, userId, setPageLoading } = props;

  const [events, setEvents] = useState<PageResponse<AnalyticsEventResponse>>();
  const [startDate, setStartDate] = useState<Date>(moment().toDate());
  const [endDate, setEndDate] = useState<Date>(moment().toDate());
  const [type, setType] = useState<string>();
  const [isCollapseOpenList, setIsCollapseOpenList] = useState<{
    [key: string]: boolean;
  }>({});

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const pager = usePager(50);

  const filterFormMethods = useForm<FilterForm>({
    mode: "onChange",
    defaultValues: {
      dateTemplate: DateTemplate.today,
      endDate: endDate,
      startDate: startDate,
      type: undefined,
    },
  });

  const { register } = filterFormMethods;

  const getEvents = async () => {
    setLoading(true);

    try {
      const response = await AnalyticsApi.getEvents({
        clinicId,
        userId,
        startDate: moment(startDate).startOf("day").format(localDateFormat()),
        endDate: moment(endDate).endOf("day").format(localDateFormat()),
        type,
        pageNumber: pager.pageNumber,
        pageSize: pager.pageSize,
      });
      setEvents(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
      if (setPageLoading) setPageLoading(false);
    }
  };

  useEffect(() => {
    void getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicId, pager.pageNumber, pager.pageSize, startDate, endDate, type]);

  const eventTypeOptions = [
    ...Object.keys(clinicAnalyticsEventTypeEn),
    ...Object.keys(userAnalyticsEventTypeEn),
  ].map((key) => ({
    title: getStringFromEnumsOrReturn(key),
    value: key,
  }));

  return (
    <div>
      <FormProvider {...filterFormMethods}>
        <div className="mb-3 flex space-x-4">
          <DateFilters
            onChange={(start, end) => {
              setStartDate(start);
              setEndDate(end);
              pager.setPageNumber(0);
            }}
          />
          <Select
            isChooseOption={false}
            firstOption={{ title: strings.all, value: "" }}
            label={strings.analyticsEventType}
            name="type"
            register={register}
            options={eventTypeOptions}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setType(e.target.value ? e.target.value : undefined);
            }}
          />
        </div>
      </FormProvider>
      <PageableTable
        pager={pager}
        isLoading={loading}
        content={events}
        error={error}
      >
        <PageableTableHead>
          <tr>
            <th className="tw-th" />
            <th className="tw-th">{strings.date}</th>
            <th className="text-left tw-th">{strings.analyticsEventType}</th>
            <th className="text-left tw-th" hidden={clinicId !== undefined}>
              {strings.clinic}
            </th>
            <th className="text-left tw-th" hidden={userId !== undefined}>
              {strings.user}
            </th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {events &&
            events.elements.map((item, index: number) => (
              <Fragment key={index}>
                <tr
                  onClick={() => {
                    setIsCollapseOpenList({
                      ...isCollapseOpenList,
                      [item.id]:
                        !isCollapseOpenList[item.id] &&
                        !!item.params &&
                        Object.keys(item.params).length > 0,
                    });
                  }}
                >
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <div className="flex">
                      <Button
                        variant="basic"
                        hidden={!item.params || !Object.keys(item.params)}
                      >
                        {isCollapseOpenList[item.id] ? (
                          <ChevronUp />
                        ) : (
                          <ChevronDown />
                        )}
                      </Button>
                    </div>
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    {moment(item.dateTime).format("LLL")}
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap">
                    <strong>{getStringFromEnumsOrReturn(item.type)}</strong>
                  </td>
                  <td
                    className="px-4 py-4 text-sm whitespace-nowrap"
                    hidden={clinicId !== undefined}
                  >
                    <Link to={`/management/clinics/${item.clinicId}`}>
                      {item.clinicName || item.clinicId}
                    </Link>
                  </td>
                  <td
                    className="px-4 py-4 text-sm whitespace-nowrap"
                    hidden={userId !== undefined}
                  >
                    <Link to={`/management/users/${item.userId}`}>
                      {item.userName || item.userId}
                    </Link>
                  </td>
                </tr>
                <tr
                  className="border-t-0"
                  hidden={!isCollapseOpenList[item.id]}
                >
                  <td />
                  <td
                    colSpan={4}
                    className="px-4 pb-4 text-sm whitespace-nowrap"
                  >
                    {item.params &&
                      Object.keys(item.params).map((key) => (
                        <div>
                          <strong>{key}: </strong>
                          {item.params && item.params[key]?.toString()}
                        </div>
                      ))}
                  </td>
                  <td />
                </tr>
              </Fragment>
            ))}
        </PageableTableBody>
      </PageableTable>
    </div>
  );
};

export default AnalyticsEventsTable;
