/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ExtraCost } from "../../../models/medical/ExtraCost";
import { ServiceFeeResponse } from "../../../models/servicefee/ServiceFeeResponse";
import { TreatmentFeeResponse } from "../../../models/treatment/TreatmentFeeResponse";

export type UsedFeeActions =
  | { type: "fill"; itemsToAdd: ExtraCost[] }
  | { type: "add"; itemToAdd: ServiceFeeResponse; quantity: number }
  | { type: "addFromTreatment"; itemToAdd: TreatmentFeeResponse }
  | { type: "changeQuantity"; itemToChange: ExtraCost; newQuantity: number }
  | { type: "reduceQuantity"; itemToChange: TreatmentFeeResponse }
  | { type: "changePrice"; itemToChange: ExtraCost; newPrice: number }
  | { type: "remove"; itemToRemove: ExtraCost };

export const feeReducer = (
  state: Array<ExtraCost>,
  action: UsedFeeActions
): ExtraCost[] => {
  switch (action.type) {
    case "fill": {
      return action.itemsToAdd;
    }
    case "add": {
      const addActionId = action.itemToAdd.id;
      const alreadyAddedItemIndex = state.findIndex(
        (item: ExtraCost) => item.referenceId === addActionId
      );
      if (alreadyAddedItemIndex >= 0) {
        state[alreadyAddedItemIndex].quantity += action.quantity;
        return [...state];
      }

      /* Convert data */
      const extraCost: ExtraCost = {
        ...action.itemToAdd,
        referenceId: addActionId,
        price: action.itemToAdd.price || 0,
        quantity: action.quantity,
      };

      return [...state, extraCost];
    }
    case "addFromTreatment": {
      const addActionId = action.itemToAdd.fee.id;
      const alreadyAddedItemIndex = state.findIndex(
        (item: ExtraCost) => item.referenceId === addActionId
      );
      if (alreadyAddedItemIndex >= 0) {
        state[alreadyAddedItemIndex].quantity += action.itemToAdd.quantity;
        return [...state];
      }

      /* Convert data */
      const extraCost: ExtraCost = {
        accountingCode: action.itemToAdd.fee.accountingCode,
        id: action.itemToAdd.fee.id,
        name: action.itemToAdd.fee.name,
        price: action.itemToAdd.fee.price || 0,
        referenceId: addActionId,
        quantity: action.itemToAdd.quantity,
        vat: action.itemToAdd.fee.vat,
      };

      return [...state, extraCost];
    }
    case "changeQuantity": {
      const changeActionId = action.itemToChange.referenceId;
      const changeIndex = state.findIndex(
        (item: ExtraCost) => item.referenceId === changeActionId
      );

      if (changeIndex >= 0) {
        state[changeIndex].quantity = action.newQuantity;
      }
      return [...state];
    }
    case "reduceQuantity": {
      const changeActionId = action.itemToChange.fee.id;
      const changeIndex = state.findIndex(
        (item: ExtraCost) => item.referenceId === changeActionId
      );

      if (changeIndex >= 0) {
        if (state[changeIndex].quantity > action.itemToChange.quantity) {
          state[changeIndex].quantity -= action.itemToChange.quantity;
        } else {
          return [
            ...state.filter(
              (item: ExtraCost) => item.referenceId !== changeActionId
            ),
          ];
        }
      }
      return [...state];
    }
    case "changePrice": {
      const changePriceActionId = action.itemToChange.referenceId;
      const changePriceIndex = state.findIndex(
        (item: ExtraCost) => item.referenceId === changePriceActionId
      );

      if (changePriceIndex >= 0) {
        state[changePriceIndex].price = action.newPrice;
      }
      return [...state];
    }
    case "remove": {
      const removeActionId = action.itemToRemove.referenceId;

      return [
        ...state.filter(
          (item: ExtraCost) => item.referenceId !== removeActionId
        ),
      ];
    }
    default: {
      return [];
    }
  }
};
