/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../../util/helperFunctions";
import AlertBox from "../../../../../components/AlertBox";
import ClinicApi from "../../../../../api/ClinicApi";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal/Modal";
import CloseButton from "../../../../../components/CloseButton";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import { RoundingStrategy } from "../../../../../models/clinic/RoundingStrategy";
import { VatCalculationStrategy } from "../../../../../models/clinic/VatCalculationStrategy";
import Select from "../../../../../components/ReactHookFormFields/General/Select/Select";
import { RoundingStrategyRequest } from "../../../../../models/clinic/RoundingStrategyRequest";

interface Props {
  isOpen: boolean;
  close(): void;
  refresh: (data: ClinicResponse) => void;
  clinic: ClinicResponse;
}

interface RoundingSettingsForm {
  roundingStrategyItem: RoundingStrategy;
  roundingStrategyTotal: RoundingStrategy;
  vatCalculationStrategy: VatCalculationStrategy;
}

const defaultFormValues = (clinic: ClinicResponse): RoundingSettingsForm => ({
  roundingStrategyItem:
    clinic.roundingStrategyItem || RoundingStrategy.TWO_DECIMAL,
  roundingStrategyTotal:
    clinic.roundingStrategyTotal || RoundingStrategy.TWO_DECIMAL,
  vatCalculationStrategy:
    clinic.vatCalculationStrategy || VatCalculationStrategy.ITEMIZED,
});

const RoundingSettingsModal: React.FC<Props> = (props: Props) => {
  const { isOpen, close, refresh, clinic } = props;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const submit = async (values: RoundingSettingsForm) => {
    setLoading(true);

    try {
      const request: RoundingStrategyRequest = values;
      const response = await ClinicApi.updateRoundingStrategy(
        clinic.id,
        request
      );

      setLoading(false);
      setError(null);
      refresh(response.data);
      close();
    } catch (err) {
      setLoading(false);
      setError(await getGeneralError(err));
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm<RoundingSettingsForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(clinic),
  });

  useEffect(() => {
    const resetFormAndErrors = () => {
      reset(defaultFormValues(clinic));
      setError(null);
    };

    if (isOpen) {
      resetFormAndErrors();
    }
  }, [clinic, isOpen, reset]);

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.roundingSettings} />
      <Modal.Body noSpacing>
        <form
          className="modal-body"
          id="roundingSettingsFrom"
          onSubmit={handleSubmit(submit)}
        >
          <Select
            name="roundingStrategyItem"
            options={Object.values(RoundingStrategy).map((i) => ({
              title: getStringFromEnumsOrReturn(i),
              value: i,
            }))}
            register={register}
            isChooseOption={false}
            label={strings.roundingStrategyItem}
          />
          <Select
            name="roundingStrategyTotal"
            options={Object.values(RoundingStrategy).map((i) => ({
              title: getStringFromEnumsOrReturn(i),
              value: i,
            }))}
            register={register}
            isChooseOption={false}
            label={strings.roundingStrategyTotal}
          />
          <Select
            name="vatCalculationStrategy"
            options={Object.values(VatCalculationStrategy).map((i) => ({
              title: getStringFromEnumsOrReturn(i),
              value: i,
            }))}
            register={register}
            isChooseOption={false}
            label={strings.vatCalculationStrategy}
          />
          <AlertBox message={error} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={close} text={strings.cancel} />
        <Button
          className="modal-main-button"
          disabled={loading || !isValid}
          form="roundingSettingsFrom"
          loading={loading}
          type="submit"
        >
          {strings.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RoundingSettingsModal;
