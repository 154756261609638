/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import punycode from "punycode";

export const mobileValidation = {
  matchRegexp: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/,
};
export const optionalMobileValidation = {
  matchRegexp: /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*)|\w*$/,
};

export const positiveOrZeroNumber = { matchRegexp: /^[+]?\d*$/ };
export const positiveOrZeroDouble = { matchRegexp: /^[+]?(\d+(?:[,.]\d+)?)$/ };
export const positiveOrZeroTwoDecimal = {
  matchRegexp: /^[+]?(\d+(?:[,.]\d{1,2})?)$/,
};

export const emailValidation = {
  matchRegexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
export const optionalEmailValidation = {
  matchRegexp: /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))|\w*$/,
};

export const passwordRegexp = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/;

export const prescriptionRegexp = /^[ -~¡-ÿ]*$/;
export const prescriptionNameRegexp = /^[ -"'-*,-/:;?A-~¡«́ ¶·»¿-öø-ÿ]*$/;

export const swedishPersonalId = {
  matchRegexp: /([0-9]{6}|[0-9]{8})-?([0-9]{4})/,
};

export const fullYearSwedishPersonalId = {
  matchRegexp: /([0-9]{8})-?([0-9]{4})/,
};

export const isSwedishPersonalIdValid = (value: string): boolean => {
  return fullYearSwedishPersonalId.matchRegexp.test(value);
};

export const validateInternationalEmail = (
  value: string | undefined
): boolean => {
  if (!value) return true;
  const parts = value.split("@");
  if (parts.length !== 2) return false;
  const asciiValue = `${parts[0]}@${punycode.toASCII(parts[1])}`;
  const match = asciiValue.match(emailValidation.matchRegexp);
  return match ? match.length > 0 : false;
};