/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import ClinicApi from "../../../../api/ClinicApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import { PageProps } from "../../../../models/PageProps";
import { InvoiceResponse } from "../../../../models/invoice/InvoiceResponse";
import UserInvoiceApi from "../../../../api/UserInvoiceApi";
import AlertBox from "../../../../components/AlertBox";
import { Loader } from "../../../../components/Loader";
import UnpaidInvoiceList from "./UnpaidInvoiceList";
import { BasicClinicResponse } from "../../../../models/clinic/BasicClinicResponse";

function OwnerInvoicePage({ setPageLoading }: PageProps) {
  const [invoices, setInvoices] = useState<InvoiceResponse[]>([]);
  const [clinics, setClinics] = useState<BasicClinicResponse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(true);
  const [clinicsLoading, setClinicsLoading] = useState<boolean>(true);

  const onlyUnique = (value: any, index: number, self: any) =>
    self.indexOf(value) === index;

  useEffect(() => {
    setPageLoading(clinicsLoading || invoiceLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceLoading, clinicsLoading]);

  const getInvoices = async () => {
    try {
      setInvoiceLoading(true);
      const response = await UserInvoiceApi.getInvoices();
      setInvoices(response.data);
      setError(null);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setInvoiceLoading(false);
    }
  };

  useEffect(() => {
    void getInvoices();
  }, []);

  useEffect(() => {
    const getClinicsByIds = async (clinicIds: string[]) => {
      try {
        setClinicsLoading(true);
        const response = await ClinicApi.getClinicsForPayment(clinicIds);
        setClinics(response.data);
        setError(null);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setClinicsLoading(false);
      }
    };

    if (invoices === undefined) return;

    const clinicIds = invoices
      .map((invoice) => invoice.clinicId)
      .filter(onlyUnique);

    void getClinicsByIds(clinicIds);
  }, [invoices]);

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div>
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.myBills}
            </h1>
          </div>
          <AlertBox message={error} />
          {clinics?.map((clinic) => (
            <div key={clinic.id}>
              <UnpaidInvoiceList
                clinic={clinic}
                invoices={invoices}
                setError={setError}
                refresh={() => void getInvoices()}
              />
            </div>
          ))}
        </div>
      </section>
    </main>
  );
}

export default Loader(OwnerInvoicePage);
