/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import React, { ReactElement } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { WeekDays } from "../../../models/calendar/WeekDays";
import Button from "../../../components/Button";

interface Props {
  inputDate: string;
  localSelectedDay?: string;
  setLocalSelectedDay(newDate: string): void;
}

function MonthCalendarNavigationMobile({
  inputDate,
  localSelectedDay,
  setLocalSelectedDay,
}: Props): ReactElement {
  const daysOfMonth = [];
  const today = new Date();
  const diff: number = moment(inputDate).date();
  const daysInMonth: number = moment(inputDate).daysInMonth();

  const firstDay: string = moment(inputDate)
    .subtract(diff - 1, "day")
    .format();
  const realFirstDay: string =
    moment(firstDay).day() === 1
      ? moment(firstDay).format()
      : moment(firstDay)
          .subtract(moment(firstDay).day() - 1, "day")
          .format();

  const lastDay: string = moment(firstDay)
    .add(daysInMonth - 1, "day")
    .format();
  const realLastDay: moment.Moment =
    moment(lastDay).day() === 0
      ? moment(lastDay)
      : moment(lastDay).add(7 - moment(lastDay).day(), "day");

  const realDaysInMonth: number = realLastDay.diff(realFirstDay, "day") + 1;

  for (let i = 0; i <= realDaysInMonth; i += 1) {
    const day: moment.Moment = moment(realFirstDay).add(i, "day");
    let numberContainerClass =
      "rounded-full w-5 h-5 flex items-center justify-center";
    let numberClass = "text-center font-light text-zinc-800 dark:text-zinc-300";

    if (
      day.isSame(moment(today), "day") &&
      moment(today).month() === moment(inputDate).month()
    ) {
      numberContainerClass =
        "bg-sky-500 rounded-full w-5 h-5 flex items-center justify-center jaj";
      numberClass = "text-center font-light text-white";
    } else if (
      day.isSame(moment(localSelectedDay), "day") &&
      day.month() === moment(inputDate).month()
    ) {
      numberContainerClass =
        "bg-gray-600 rounded-full w-5 h-5 flex items-center justify-center jaj1";
      numberClass = "text-center font-light text-white";
    } else if (day.month() !== moment(today).month()) {
      numberContainerClass =
        "rounded-full w-5 h-5 flex items-center justify-center";
      numberClass = "text-center font-light text-zinc-800 dark:text-zinc-300";
    }

    const monthDay = {
      weekDay: day.day(),
      date: day,
      numberContainerClass,
      numberClass,
    };
    daysOfMonth.push(monthDay);
  }

  const weeksOfMonth: Array<WeekDays[]> = [];
  let temp: WeekDays[] = [];

  daysOfMonth.forEach((item, index) => {
    if (index === daysOfMonth.length - 1) {
      weeksOfMonth.push(temp);
    } else if (item.weekDay === 1) {
      weeksOfMonth.push(temp);
      temp = [];
      temp.push(item);
    } else {
      temp.push(item);
    }
  });

  const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");
  const weekdayBgColor = "rgb(0,0,0,0)";
  const weekendBgColor = darkMode ? "rgb(255,255,255,0.05)" : "rgb(0,0,0,0.02)";

  return (
    <table>
      <thead>
        <tr>
          {[1, 2, 3, 4, 5, 6, 7].map((day, idx) => (
            <th className="p-1 text-center" key={idx}>
              {moment().day(day).format("dd")}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeksOfMonth.map((day, idx) => (
          <tr key={`week-${idx}`}>
            {day.map((date, index) => (
              <td
                className="p-1"
                key={index}
                style={{
                  backgroundColor:
                    date.weekDay === 6 || date.weekDay === 0
                      ? weekendBgColor
                      : weekdayBgColor,
                }}
                align="center"
              >
                <Button
                  variant="basic"
                  type="button"
                  onClick={() => {
                    setLocalSelectedDay(date.date.format());
                  }}
                >
                  <div className={date.numberContainerClass}>
                    <span className={date.numberClass}>
                      {moment(date.date).format("D")}
                    </span>
                  </div>
                </Button>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default MonthCalendarNavigationMobile;

MonthCalendarNavigationMobile.defaultProps = {
  localSelectedDay: undefined,
};
