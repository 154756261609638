/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const prescriptionIntakeSv = {
  QD: "1 gång/dag",
  BID: "2 gånger/dag",
  TID: "3 gånger/dag",
  QID: "4 gånger/dag",
  QOD: "5 gånger/dag",
  SD: "6 gånger/dag",
  SED: "7 gånger/dag",
  OD: "8 gånger/dag",
  NOD: "9 gånger/dag",
  DD: "10 gånger/dag",
  DQD: "11 gånger/dag",
  DBID: "12 gånger/dag",
  QW: "1 gånger/vecka",
  BIW: "2 gånger/vecka",
  q3D: "var 3 dygn",
  QM: "1 gång/månad",
  q3M: "var 3 månad"
};