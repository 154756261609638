/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { ReservationAccessScope } from "../../../models/reservation/ReservationAccessScope";

import CombinedSelectComponent from "../../../components/InputFieldsSimple/CombinedSelectComponent";
import Button from "../../../components/Button";
import { XMark } from "../../../common/Icons/XMark";
import { useClinic } from "../../../contexts/ClinicContext";
import { getClinicCountryDiagnosesOptions } from "./DiagnosesOptions/DiagnosisOptions";

interface Props {
  accessScope: ReservationAccessScope;
  current: string[];
  deleteCurrent: (what: string) => void;
  name: string;
  label: string;
  signed: boolean;
  submit: (input: string) => void;
}

const DiagnoseList: React.FC<Props> = (props: Props) => {
  const { deleteCurrent, current, accessScope, name, label, signed, submit } =
    props;
  const [v] = useState(null);
  const canEdit = signed || accessScope === ReservationAccessScope.READ;
  const { clinic } = useClinic();

  const onSubmit = (input: [{ title: string; value: string }] | null) => {
    if (input === null) {
      return;
    }
    if (input[0]?.title !== undefined) {
      const newValue = `${input[0].title} ${
        input[0].value ? `(${input[0].value})` : ""
      }`;
      if (current.filter((i: string) => i === newValue).length === 0) {
        submit(newValue);
      }
    }
  };

  return (
    <div
      className={current.length === 0 ? "" : "space-y-4"}
      hidden={signed && current.length === 0}
    >
      {canEdit ? (
        <label>{label}</label>
      ) : (
        <div className="md:w-64">
          <CombinedSelectComponent
            allowNew
            labelKey="title"
            label={label}
            name={name}
            onChange={onSubmit}
            options={getClinicCountryDiagnosesOptions(clinic?.countryCode)}
            value={v}
          />
        </div>
      )}
      <div className="space-y-2">
        {current?.map((record: string, idx: number) => (
          <div key={idx} className="tw-card px-3 py-2">
            <div className="flex items-center">
              <div className="font-bold text-base">{record}</div>
              <div className="ml-auto flex items-center">
                {canEdit ? null : (
                  <>
                    <Button
                      onClick={() => {
                        deleteCurrent(record);
                      }}
                      variant="basic"
                    >
                      <XMark className="w-5 h-5" />
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiagnoseList;
