/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import Tooltip from "../../components/Tooltip";
import { strings } from "../../common/Strings/Strings";
import { ExclamationMark } from "../../common/Icons/ExclamationMark";

interface Props {
  lowLevel?: number;
  stock?: number;
}

const LowLevelIndicator: React.FC<Props> = ({ lowLevel, stock }: Props) => (
    <div>
      {stock !== undefined && lowLevel !== undefined && stock <= lowLevel ? (
        <Tooltip content={strings.lowLevel} placement="top">
          <ExclamationMark className="text-red-500 w-5 h-5 mr-2" />
        </Tooltip>
      ) : (
        <></>
      )}
    </div>
  );

export default LowLevelIndicator;