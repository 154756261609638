/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { strings } from "../../../../common/Strings/Strings";
import { HistoryData } from "../../../../models/ePrescription/history/HistoryData";
import { ExtendedPrescriptionItem } from "../../../../models/ePrescription/persistence/ExtendedPrescriptionItem";
import PrescriptionBillModal from "../../../ePrescriptionHistory/PrescriptionBillModal";
import Tag, { TagType } from "../../../../components/Tag";
import Button from "../../../../components/Button";
import HorizontalLine from "../../../../components/HorizontalLine";
import { DocumentText } from "../../../../common/Icons/DocumentText";
import { Document } from "../../../../common/Icons/Document";
import Tooltip from "../../../../components/Tooltip";

interface Props {
  isNextExist: boolean;
  prescription: HistoryData | any;
}

const PrescriptionRow: React.FC<Props> = ({
  isNextExist,
  prescription,
}: Props) => {
  const [billModal, setBillModal] = useState<boolean>(false);

  return (
    <>
      <div
        className="flex flex-wrap py-3 space-y-3 lg:space-y-0"
        key={prescription.id}
      >
        <div className="col-12 col-lg-2 flex items-center">
          <div
            style={{
              marginRight: "8px",
              marginBottom: "11px",
              marginLeft: "30px",
            }}
          />
          <div className="flex flex-col justify-center">
            <div className="uppercase text-sm">{strings.prescription}</div>
            <div className="font-bold">
              {moment.unix(prescription.creationTimestamp).format("LL")}
            </div>
            <div className="mb-3 md:mb-0">
              <div hidden={prescription.cancellations.length === 0}>
                <Tag type={TagType.secondary} text={strings.withdrawn} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7 flex flex-col justify-center">
          {prescription.model.prescription.items.map(
            (p: ExtendedPrescriptionItem) => (
              <div>
                <b>
                  {p.product
                    ? p.product.name
                    : p.licensed
                    ? p.licensed.supplementaryInformation
                    : strings.prescriptionItem}
                  {p.product?.strength || p.licensed?.strength
                    ? ` (${p.product?.strength || p.licensed?.strength})`
                    : ""}
                  :
                </b>
                &nbsp;{p.instructions.text}
              </div>
            )
          )}
        </div>
        <div className="col-12 col-lg-2 flex flex-col justify-center">
          {prescription.model.veterinaryInformation.givenName}&nbsp;
          {prescription.model.veterinaryInformation.familyName}
        </div>
        <div className="col-12 col-lg-1 flex justify-end items-center space-x-3">
          <div>
            <Tooltip content={strings.invoice}>
              <Button
                variant="icon"
                onClick={() => {
                  setBillModal(true);
                }}
              >
                <Document />
              </Button>
            </Tooltip>
          </div>
          <Link to={`/prescriptions/${prescription.id}`}>
            <Button variant="icon">
              <DocumentText />
            </Button>
          </Link>
        </div>
        <PrescriptionBillModal
          isClinicPage
          close={() => {
            setBillModal(false);
          }}
          open={billModal}
          prescriptionId={prescription.id}
        />
      </div>
      {isNextExist && <HorizontalLine />}
    </>
  );
};

export default PrescriptionRow;
