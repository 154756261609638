/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { FormProvider } from "react-hook-form";
import { useMainSectionContext } from "./MainSectionContext";
import SubsectionTemplate from "./Templates/SubsectionTemplate";
import { strings } from "../../../common/Strings/Strings";
import TextAreaTemplate from "./Templates/TextAreaTemplate";
import AdditionalFields from "../AdditionalFields";
import { MedicalRecordsMailResponse } from "../../../models/medical/mail/MedicalRecordsMailResponse";
import { ReservationAccessScope } from "../../../models/reservation/ReservationAccessScope";
import Button from "../../../components/Button";
import { MedicalRecordFileType } from "../../../models/medical/MedicalRecordFileType";
import AddButtonIcon from "../../../components/AddButtonIcon";
import Files from "../Files/Files";

const FollowUpSection: React.FC = () => {
  const {
    signed,
    isUserNotOwner,
    recommendationText,
    setRecommendationText,
    accessScope,
    triggerChange,
    additionalFieldMethods,
    medicalRecord,
    refreshReservationInProgress,
    medicalRecordVersions,
    setMedicalRecordVersions,
    setFilesModalOpen,
    files,
    resIdS,
  } = useMainSectionContext();

  return (
    <SubsectionTemplate title={strings.followUp}>
      <TextAreaTemplate
        name="recommendation"
        label={strings.recommendation}
        accessScope={accessScope}
        signed={signed}
        value={recommendationText}
        setValue={setRecommendationText}
        triggerChange={triggerChange}
      />
      {
        isUserNotOwner &&
        <FormProvider {...additionalFieldMethods}>
          <AdditionalFields
            accessScope={accessScope}
            medicalRecord={medicalRecord}
            refreshReservationInProgress={refreshReservationInProgress}
            signed={signed}
            triggerChange={triggerChange}
            updateMedicalRecordVersion={(
              newEmail: MedicalRecordsMailResponse
            ) => {
              const list = [...medicalRecordVersions];
              list.forEach((v) => {
                if (v.id === medicalRecord?.id) {
                  if (v.sentMails) {
                    v.sentMails.push(newEmail);
                  } else {
                    v.sentMails = [newEmail];
                  }
                }
              });
              setMedicalRecordVersions(list);
            }}
          />
        </FormProvider>
      }
      {!signed && accessScope !== ReservationAccessScope.READ && (
        <div className="grid grid-cols-12 gap-4">
          <Button
            variant="primary-outline"
            className="col-span-12 lg:col-span-6"
            fullWidth
            onClick={() => setFilesModalOpen(MedicalRecordFileType.OTHER)}
          >
            <div className="flex items-center justify-center">
              <AddButtonIcon />
              {strings.documents}
            </div>
          </Button>
        </div>
      )}

      <Files
        canEdit={!(signed || accessScope === ReservationAccessScope.READ)}
        files={files}
        resIdS={resIdS}
        type={MedicalRecordFileType.OTHER}
      />
    </SubsectionTemplate>
  );
};

export default FollowUpSection;
