/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState, useEffect } from "react";
import VaraApi from "../../../../api/VaraApi";
import { PackageType } from "../../../../models/ePrescription/VARA/PackageType";
import logger from "../../../../util/logger";
import { VaraItem } from "../../../../models/inventory/item/VaraItem";
import ProductSelectSteps from "../../../ePrescription/findProduct/ProductSelectSteps";

interface Props {
  returnPackage(p: VaraItem): void;
}

const AddItemFromVara: React.FC<Props> = ({ returnPackage }: Props) => {
  const [packageTypes, setPackageTypes] = useState<PackageType[]>([]);

  useEffect(() => {
    const getPackageTypes = async () => {
      try {
        const response = await VaraApi.getPackageTypes();
        setPackageTypes(response.data);
      } catch (err) {
        logger.error(err);
      }
    };

    void getPackageTypes();
  }, []);

  return (
    <ProductSelectSteps
      addPackageToInventory={returnPackage}
      geoPharmacies={[]}
      packageTypes={packageTypes}
      inventory
    />
  );
};

export default AddItemFromVara;
