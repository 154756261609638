/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { EmailResponse } from "../../../models/contact/EmailResponse";
import { getGeneralError } from "../../../util/helperFunctions";
import { ContactType } from "../../../models/contact/ContactType";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../AlertBox";
import Button from "../../Button";
import PetOwnerContactsApi from "../../../api/PetOwnerContactsApi";
import CloseButton from "../../CloseButton";
import Modal from "../../Modal/Modal";

type PrevStateSetter = (prevState: EmailResponse[]) => EmailResponse[];

interface Props {
  email: EmailResponse | null;
  onHide: () => void;
  petOwnerId?: string;
  setEmails: (setter: PrevStateSetter) => void;
  show: boolean;
}

const PromoteEmailModal: React.FC<Props> = ({
  email,
  onHide,
  petOwnerId,
  setEmails,
  show,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const promoteOwnership = async () => {
    if (!petOwnerId) {
      return;
    }

    if (email === null || email.contactType === ContactType.PRIMARY) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await PetOwnerContactsApi.setPrimaryPetOwnerEmail(petOwnerId, email.id);

      setEmails((prevState) => {
        const prevPrimary = prevState.find(
          (o) => o.contactType === ContactType.PRIMARY
        );
        const newPrimary = prevState.find((e) => e.id === email.id);
        if (prevPrimary !== undefined) {
          prevPrimary.contactType = ContactType.PRIVATE;
        }
        if (newPrimary !== undefined) {
          newPrimary.contactType = ContactType.PRIMARY;
        }
        return prevState;
      });
      onHide();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.setPrimaryEmail} />
      <Modal.Body>
        {strings.primaryPhonePromotionTextPart1}
        <b>{email?.value}</b>
        {strings.primaryPhonePromotionTextPart2}
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading}
          form="addEmailForm"
          loading={loading}
          onClick={promoteOwnership}
          type="submit"
        >
          {strings.setAsPrimary}
        </Button>
        <CloseButton onClick={onHide} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default PromoteEmailModal;
