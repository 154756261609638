/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import {
  getGeneralError,
  localDateFormat,
  mergeTimeAndDate,
} from "../../../util/helperFunctions";
import ShiftApi from "../../../api/ShiftApi";
import { TimePeriod } from "../../../models/shift/TimePeriod";
import { Shift } from "../../../models/calendar/Shift";
import { useClinic } from "../../../contexts/ClinicContext";
import DatePickerInput from "../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import TimeSelect from "../../../components/ReactHookFormFields/General/Select/TimeSelect";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";

interface Props {
  changeState(): void;
  show: boolean;
  onHide(): void;
  reload(): void;
  selectedShiftData: Shift;
}

interface FormData {
  endDate: Date;
  endTime: string;
  startTime: string;
  startDate: Date;
}

const CalendarWeekEditShiftModal: React.FC<Props> = ({
  changeState,
  reload,
  show,
  selectedShiftData,
  onHide,
}: Props) => {
  const { clinic } = useClinic();
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { control, getValues, setValue, handleSubmit, reset } =
    useForm<FormData>({
      mode: "onChange",
    });

  useEffect(() => {
    const endDateTime = moment(selectedShiftData.endDateTime);
    const startDateTime = moment(selectedShiftData.startDateTime);
    setValue("endDate", endDateTime.toDate());
    setValue("endTime", endDateTime.format("HH:mm"));
    setValue("startDate", startDateTime.toDate());
    setValue("startTime", startDateTime.format("HH:mm"));
  }, [selectedShiftData, setValue, show]);

  const clearResponses = () => {
    if (error) {
      setError(null);
    }

    if (success) {
      setSuccess(null);
    }
  };

  const closeModal = (): void => {
    reset();
    clearResponses();
    onHide();
  };

  const submitEdit = async ({
    endDate,
    endTime,
    startDate,
    startTime,
  }: FormData) => {
    clearResponses();

    if (!clinic || !selectedShiftData) {
      return;
    }

    setLoading(true);

    const putData: TimePeriod = {
      startTime: moment(mergeTimeAndDate(startTime, startDate)).format(
        localDateFormat()
      ),
      endTime: moment(mergeTimeAndDate(endTime, endDate)).format(
        localDateFormat()
      ),
    };

    try {
      await ShiftApi.updateTimePeriod(clinic.id, selectedShiftData.id, putData);
      setSuccess(strings.shiftSuccessfullyModified);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      handleClose={() => {
        if (success) {
          reload();
        } else {
          closeModal();
        }
      }}
      show={show}
      size="modal-lg"
    >
      <Modal.Header
        title={strings.formatString(
          strings.modifyShiftFor,
          selectedShiftData.collaborator.fullName
        )}
      />
      <Modal.Body>
        <form className="space-y-4">
          <div className="flex flex-wrap items-center">
            <div className="flex items-center">
              <div className="mr-3">
                <DatePickerInput
                  control={control}
                  fieldOptions={{ deps: ["endDate"] }}
                  label={strings.startDate}
                  name="startDate"
                  required
                />
              </div>
              <div className="mr-3">
                <TimeSelect
                  control={control}
                  label={strings.startTime}
                  name="startTime"
                  required
                />
              </div>
            </div>
            <div className="flex items-center">
              <div className="mr-3">-</div>
              <div className="mr-3">
                <TimeSelect
                  control={control}
                  label={strings.endTime}
                  name="endTime"
                  required
                />
              </div>
              <DatePickerInput
                control={control}
                fieldOptions={{
                  validate: {
                    isSameOrAfter: (v) =>
                      moment(v).isSameOrAfter(getValues("startDate"), "days"),
                  },
                }}
                label={strings.endDate}
                name="endDate"
                required
              />
            </div>
          </div>
          <AlertBox message={error} />
          <AlertBox type={AlertType.SUCCESS} message={success} />
        </form>
      </Modal.Body>
      <Modal.Footer extraClassName="justify-end">
        {!success ? (
          <Button
            className="modal-main-button"
            disabled={loading}
            loading={loading}
            onClick={handleSubmit(submitEdit)}
          >
            {strings.modify}
          </Button>
        ) : (
          <Button className="modal-main-button" onClick={() => reload()}>
            {strings.ok}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarWeekEditShiftModal;
