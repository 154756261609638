/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { getStreetAndHouseDetails, howOld } from "../../util/helperFunctions";
import {
  getStringFromEnumsOrReturn,
  petGenders,
  petSpecies,
  strings,
} from "../../common/Strings/Strings";
import { PublicPet } from "../../models/pet/PublicPet";
import { PersonDetailsResponse } from "../../models/contact/PersonDetailsResponse";
import { PetProfileTabs } from "../Pet/PetProfile/ClinicPetProfile";
import Button from "../../components/Button";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import PetProfilePicture from "../../components/Pictures/Pet/PetProfilePicture";
import Tooltip from "../../components/Tooltip";
import { Heart } from "../../common/Icons/Heart";

interface Props {
  pet?: PublicPet;
  petOwner?: PersonDetailsResponse;
}

const PetAndOwnerSection = ({ pet, petOwner }: Props): ReactElement => (
  <div className="lg:tw-card grid grid-cols-12 lg:gap-6">
    <div className="col-span-12 lg:col-span-6 flex flex-col space-y-6 p-4 lg:p-6">
      <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
        {strings.pet}
      </h2>
      <div className="flex items-center space-x-3">
        <div className="relative rounded-full border-4 border-gray-100 bg-white w-20 h-20 flex-shrink-0 dark:border-gray-700">
          <PetProfilePicture petId={pet?.id} species={pet?.species} />
        </div>
        <div className="flex flex-col space-y-1">
          <div className="flex items-center">
            <Link to={`/clinic-pet-profile/${pet?.id}/${PetProfileTabs.basic}`}>
              {pet?.name}
            </Link>
            {pet?.deceased && (
              <div>
                <Tooltip content={strings.deceased}>
                  <Heart className="ml-2" variant="solid" />
                </Tooltip>
              </div>
            )}
          </div>
          <strong>
            {pet?.species && petSpecies[pet?.species]},{" "}
            {pet?.gender && petGenders[pet?.gender]}
            {pet?.color && `, ${pet?.color}`}, {pet?.breed}
          </strong>
        </div>
      </div>
      {pet?.chronicDiseases && pet?.chronicDiseases?.length > 0 && (
        <div className="flex flex-wrap">
          {pet?.chronicDiseases?.map((disease, index) => (
            <Button variant="pill-red" small className="mr-2 mb-2" key={index}>
              {getStringFromEnumsOrReturn(disease)}
            </Button>
          ))}
        </div>
      )}
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-6">
        <div>
          <label>{strings.dateOfBirth}</label>
          <strong>
            {pet?.dateOfBirth ? (
              <>
                {moment(pet?.dateOfBirth).format("LL")}{" "}
                <div>
                  ({howOld(moment(pet?.dateOfBirth), "year")} {strings.years}{" "}
                  {howOld(moment(pet?.dateOfBirth), "month")} {strings.monthCnt}
                  )
                </div>
              </>
            ) : (
              <>-</>
            )}
          </strong>
        </div>
        <div>
          <label>{strings.chipNumber}</label>
          <strong>{pet?.chipNumber || "-"}</strong>
        </div>
        <div hidden={!pet?.tattooNumber}>
          <label>{strings.tattooNumber}</label>
          <strong>{pet?.tattooNumber || "-"}</strong>
        </div>
        <div hidden={!pet?.passportNumber}>
          <label>{strings.passportNumber}</label>
          <strong>{pet?.passportNumber || "-"}</strong>
        </div>
        <div hidden={!pet?.insuranceCompany}>
          <label>{strings.insuranceCompany}</label>
          <strong>{pet?.insuranceCompany?.name || "-"}</strong>
        </div>
        <div hidden={!pet?.insuranceNumber}>
          <label>{strings.insuranceNumber}</label>
          <strong>{pet?.insuranceNumber || "-"}</strong>
        </div>
      </div>
      {pet?.comment && (
        <div>
          <label>{strings.comment}</label>
          <strong>{pet?.comment}</strong>
        </div>
      )}
    </div>
    <div className="col-span-12 lg:col-span-6 flex flex-col space-y-6 p-4 lg:p-6">
      <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
        {strings.owner}
      </h2>
      <div className="flex items-center space-x-3">
        <div className="relative rounded-full border-4 border-gray-100 bg-white w-20 h-20 flex-shrink-0 dark:border-gray-700">
          <UserProfilePicture userId={petOwner?.id} />
        </div>
        <Link to={`/pet-owner-profile/${petOwner?.id}`}>
          {petOwner?.fullName}
        </Link>
      </div>
      <div className="grid grid-cols-2 gap-6">
        <div>
          <div>{strings.phone}</div>
          <strong>{petOwner?.phones[0]?.value}</strong>
        </div>
        <div>
          <label>{strings.email}</label>
          <strong>{petOwner?.emails[0]?.value}</strong>
        </div>
        <div>
          <label>{strings.address}</label>
          {petOwner?.addresses[0] && (
            <strong>
              {getStreetAndHouseDetails(petOwner?.addresses[0])},{" "}
              {petOwner?.addresses[0]?.zip}, {petOwner?.addresses[0]?.city}
            </strong>
          )}
        </div>
        <div hidden={!petOwner?.personalId}>
          <label>{strings.personalId}</label>
          <strong>{petOwner?.personalId}</strong>
        </div>
      </div>
    </div>
  </div>
);

export default PetAndOwnerSection;
