/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { getStringFromEnumsOrReturn, strings } from "../../Strings/Strings";
import TooltipHint from "../../../components/TooltipHint";
import { ChevronDown } from "../../Icons/ChevronDown";
import { useClinic } from "../../../contexts/ClinicContext";
import { useUser } from "../../../contexts/UserContext";
import { getValueOfActiveUser } from "../../../util/LocalStorageVariables";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";

interface Props {
  onClick?(): void;
}

const UserDropdownMenuButton: React.FC<Props> = ({ onClick }: Props) => {
  const { clinic, clinics } = useClinic();
  const clinicRoles =
    clinics.find((c) => c.clinic.id === clinic?.id)?.roles || [];

  const { user } = useUser();
  const fullName = user?.details?.fullName;
  const email = user?.emails?.[0];
  const role = getValueOfActiveUser("role");

  return (
    <>
      <div
        className="flex items-center text-white"
        onClick={onClick}
        role="button"
        style={{ userSelect: "none" }}
        tabIndex={0}
      >
        <div className="hidden xl:block mr-3">
          {fullName || email?.value || ""}
          <div className="text-right font-light">
            {clinicRoles.map(getStringFromEnumsOrReturn).join(", ")}
          </div>
        </div>
        <div className="w-8 h-8">
          <UserProfilePicture border={false} borderWidth="2px" loggedIn />
        </div>
        <ChevronDown className="hidden xl:flex w-5 h-5 flex-shrink-0 ml-3" />
      </div>
      <TooltipHint
        id="tooltipSwitchMode"
        positionClassName="absolute top-16 xl:top-11 right-0"
        show={!!role}
        text={strings.changeRoleInfo}
      />
    </>
  );
};

export default UserDropdownMenuButton;
