/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

/* Default start and end times of the rendered calendar */
export const defaultCalendarStartTime = "08:00";
export const defaultCalendarEndTime = "18:00";

/* Date and time formats used in the calendar for moment.js */
export const momentTimeFormat = "HH:mm";
export const momentDateFormat = "YYYY-MM-DD";

/* Time between rows in the calendar (sec) */
export const calendarTimeGapsMin = 10;

/* The minimum supported length of the reservation (sec) */
export const minReservationLength = 1;

/* The maximum number of columns rendered in one page */
export const maxColumnsPerPage = 4;

/* Base height of a single cell in the calendar column (pixel) */
export const baseSizeInPixels = 45;

/* Minimum height of a single cell in the calendar column (pixel) */
export const minHeight = 22;

/* Height of a single cell in the calendar column (pixel) */
export const unitCellHeight = baseSizeInPixels / calendarTimeGapsMin;

/* Height of the header in the calendar column (pixel) */
export const headerHeight = baseSizeInPixels + 20;

/* Height of the calendar, optimalized to bigger screens (vh) */
export const calendarHeight = "70vh";

/* Minimum width of the column, calculated from the smallest screen size (pixel) */
export const columnMinWidth = "255px";

/* General width of the column, calculated from the maximum number of columns (%) */
export const columnWidth = `${100 / maxColumnsPerPage}%`;

/* Navigation period in days ( n * 7 ) for day view */
export const navigationPeriodInDays = 3 * 7;
