/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import {
  downloadTestInvoicePdf,
  printTestInvoicePdf,
} from "../../../util/FileUtils";
import { strings } from "../../../common/Strings/Strings";
import logger from "../../../util/logger";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import LoaderInline from "../../../components/LoaderInline";
import { GlobalVetInvoiceResponse } from "../../../models/management/GlobalVetInvoiceResponse";

interface Props {
  isOpen: boolean;
  close: () => void;
  invoice?: GlobalVetInvoiceResponse;
  countryCode: string;
}

const TestInvoiceSuccessModal: React.FC<Props> = (props: Props) => {
  const { isOpen, close, invoice, countryCode } = props;

  const [isPrinting, setPrinting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <>
      <Modal show={isOpen} onHide={close} size="sm" backdrop="static">
        <Modal.Header>
          <Modal.Title className="align-items-center">
            <i
              className="fas fa-check-circle color-green mr-3"
              hidden={invoice?.valid === false}
              style={{ fontSize: "24px" }}
            />
            {strings.testInvoice}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body bsPrefix="p-0">
          {invoice?.valid === false ? (
            <div className="m-3">
              <AlertBox message={invoice?.validationError} closeAble={false} />
            </div>
          ) : (
            <>
              {invoice?.customer?.email ? (
                <div className="hr-on-bottom p-3">
                  {strings.invoiceHasBeenSentInEmail}{" "}
                  <b>{invoice?.customer.email}</b>
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex justify-content-center align-items-center p-3 hr-on-bottom">
                <div className="mr-3">{strings.printOrDownloadInvoice}</div>
                <div className="ml-auto">
                  {isDownloading ? (
                    <LoaderInline />
                  ) : (
                    <Button
                      variant="terciery"
                      disabled={isDownloading || !invoice}
                      onClick={async () => {
                        setIsDownloading(true);
                        try {
                          if (invoice) {
                            await downloadTestInvoicePdf(countryCode, invoice);
                          }
                        } catch (err) {
                          logger.error(err);
                          setError(await getGeneralError(err));
                        } finally {
                          setIsDownloading(false);
                        }
                      }}
                    >
                      <i className="fas fa-download" />
                    </Button>
                  )}
                </div>
                <div className="ml-3">
                  {isPrinting ? (
                    <LoaderInline />
                  ) : (
                    <Button
                      variant="primary"
                      disabled={isPrinting || !invoice}
                      onClick={async () => {
                        setPrinting(true);
                        try {
                          if (invoice) {
                            await printTestInvoicePdf(
                              countryCode,
                              invoice,
                              () => setPrinting(false)
                            );
                          }
                        } catch (err) {
                          logger.error(err);
                          setError(await getGeneralError(err));
                          setPrinting(false);
                        }
                      }}
                    >
                      <i className="fas fa-print" />
                    </Button>
                  )}
                </div>
              </div>
              <AlertBox message={error} className="m-3" />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="ml-auto">
            <Link
              to={`/management/countries/${countryCode}/payment-and-billing`}
            >
              <button className="btn btn-primary" type="button">
                {strings.testInvoices}
              </button>
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestInvoiceSuccessModal;

TestInvoiceSuccessModal.defaultProps = {
  invoice: undefined,
};
