/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { FieldError } from "react-hook-form";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { InputModeEnum } from "../../models/InputModeEnum";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { strings } from "../../common/Strings/Strings";
import { Colors } from "../../models/Colors";
import Tooltip from "../Tooltip";
import { InfoFilled } from "../../common/Icons/InfoFilled";

export interface TextareaComponentProps {
  allowMoreCharactersToInput?: boolean;
  autoComplete?: AutoCompleteOptions;
  classInput?: string;
  classLabel?: string;
  className?: string;
  inputMode?: InputModeEnum;
  label?: string;
  labelIcon?: string;
  labelOff?: boolean;
  maxLength?: number;
  maxRows?: number;
  minRows?: number;
  onInit?(): void;
  onScrollToBottom?(isScrollable: boolean, isScrolledToBottom: boolean): void;
  optional?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  showCharacterCount?: boolean;
  toolTipText?: string;
  width?: string;
}

interface TextareaProps {
  error?: FieldError;
  name?: string;
  onChange?(e: string): void;
  value?: string | null;
}

const SimpleTextarea = ({
  name,
  allowMoreCharactersToInput,
  autoComplete,
  classInput,
  classLabel,
  className,
  error,
  inputMode,
  label,
  labelIcon,
  labelOff,
  maxLength,
  maxRows = 50,
  minRows = 1,
  onChange,
  onInit,
  onScrollToBottom,
  optional,
  placeholder = label,
  readOnly,
  showCharacterCount,
  toolTipText,
  value: v,
  width,
}: TextareaProps & TextareaComponentProps): ReactElement => {
  const [value, setValue] = useState<string>("");
  const [dataRowCount, setDataRowCount] = useState<number>(0);
  const [rows, setRows] = useState<number>(minRows);

  const ref = useRef<null | HTMLDivElement>(null);
  const refT = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (v) {
      setValue(v);

      const dataRows = v.split(/\r\n|\r|\n/).length;
      setDataRowCount(dataRows);

      const newRows = Math.min(Math.max(dataRows, minRows), maxRows);
      setRows(newRows);
    } else {
      setValue("");
      setRows(minRows);
    }
  }, [maxRows, minRows, v]);

  const checkScrollStatus = useCallback(() => {
    if (!onScrollToBottom) return;

    const textarea = refT?.current;
    if (!textarea) return;

    const isScrollable = textarea.clientHeight < textarea.scrollHeight;
    const isScrolledToBottom =
      textarea.clientHeight + textarea.scrollTop === textarea.scrollHeight;

    onScrollToBottom(isScrollable, isScrolledToBottom);
    // Please don't remove value from the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScrollToBottom, value]);

  useEffect(() => {
    onInit && onInit();
    checkScrollStatus();
  }, [checkScrollStatus, onInit]);

  const inputModeForMobile = inputMode || InputModeEnum.text;

  return (
    <div
      className={`${className || ""} ${error ? "has-error" : ""}`}
      style={{ width }}
    >
      {labelOff ? (
        <></>
      ) : (
        <label htmlFor={name} className={classLabel}>
          {labelIcon ? <i className={labelIcon} /> : ""} {label}
          {optional ? (
            <span style={{ color: Colors.INPUTBORDER }}>
              {" "}
              ({strings.optional})
            </span>
          ) : (
            <></>
          )}
        </label>
      )}
      <div
        className={`relative w-full${
          dataRowCount > maxRows ? " " : " auto-grow-wrap"
        }`}
        ref={ref}
      >
        <textarea
          autoComplete={autoComplete || AutoCompleteOptions.on}
          className={classInput || `tw-input ${!!toolTipText && "pr-10"}`}
          inputMode={inputModeForMobile}
          maxLength={allowMoreCharactersToInput ? undefined : maxLength}
          name={name}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            ref.current?.setAttribute("data-text", e.target.value);
            setValue(e.target.value);

            if (onChange) {
              onChange(e.target.value);
            }
          }}
          onScroll={checkScrollStatus}
          placeholder={placeholder}
          ref={refT}
          readOnly={readOnly}
          rows={rows}
          value={value}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-2">
          {toolTipText ? (
            <div>
              <Tooltip content={toolTipText} placement="top">
                <span>
                  <InfoFilled className="text-sky-500" />
                </span>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex w-full items-center">
        <p>{error && error.message}</p>
        {showCharacterCount && maxLength ? (
          <div className="ml-auto">
            {value && value.length > maxLength ? (
              <span className="text-red-500 text-xs">
                {`${strings.formatString(
                  strings.charactersAbove,
                  value ? Math.abs(maxLength - value.length) : 0
                )}`}
              </span>
            ) : (
              <span className="text-zinc-400 text-xs">
                {`${strings.formatString(
                  strings.charactersLeft,
                  value ? maxLength - value.length : maxLength
                )}`}
              </span>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SimpleTextarea;
