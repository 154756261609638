/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import LoaderInline from "../../../components/LoaderInline";
import CloseButton from "../../../components/CloseButton";
import { PolicyUpdateRequest } from "../../../models/management/PolicyUpdateRequest";
import GDPRApi from "../../../api/GDPRApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { CountrySettingsResponse } from "../../../models/management/CountrySettingsResponse";
import { PolicyType } from "../../../models/management/PolicyType";
import { PolicyResponse } from "../../../models/management/PolicyResponse";
import { LocalLanguageResponse } from "../../../models/language/LocalLanguageResponse";
import Tooltip from "../../../components/Tooltip";
import { formatVersion } from "./PolicyList";
import AlertBox from "../../../components/AlertBox";
import SimpleInput from "../../../components/InputFieldsSimple/SimpleInput";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import Button from "../../../components/Button";
import HtmlEditorTextarea from "../../../components/HtmlEditorTextarea";
import Modal from "../../../components/Modal/Modal";
import { InfoFilled } from "../../../common/Icons/InfoFilled";

interface Props {
  country: CountrySettingsResponse;
  language?: string;
  languages: LocalLanguageResponse[];
  onHide: () => void;
  onPolicyChanged: (policy: PolicyResponse) => void;
  policyName: string;
  policyType: PolicyType;
  setVersion: (version: string) => void;
  show: boolean;
  statement?: string;
  version?: string;
}

interface EditPolicyForm {
  statement: string;
  isGrammarChange: boolean;
}

const defaultFormValues = (statement: string) => ({
  statement,
  isGrammarChange: false,
});

const EditPolicyModal: React.FC<Props> = ({
  country,
  language,
  languages,
  onHide,
  onPolicyChanged,
  policyName,
  policyType,
  setVersion,
  show,
  statement,
  version,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const updatePolicy = async (formData: EditPolicyForm) => {
    if (!language) {
      return;
    }

    setLoading(true);

    const request: PolicyUpdateRequest = {
      languageCode: language,
      countryCode: country.countryCode,
      type: policyType,
      statement: formData.statement,
      isGrammarChange: formData.isGrammarChange,
    };

    try {
      const response = await GDPRApi.updatePolicy(request);
      setVersion(formatVersion(response.data.version));
      onPolicyChanged(response.data);
      onHide();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<EditPolicyForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(statement ?? ""),
  });

  useLayoutEffect(() => {
    if (show && statement) {
      reset(defaultFormValues(statement));
      setError(null);
    }
  }, [reset, show, statement]);

  return (
    <Modal handleClose={onHide} show={show} size="modal-lg">
      <Modal.Header title={`${strings.editPolicy}: ${policyName}`} />
      <Modal.Body>
        {language !== null ? (
          <>
            <SimpleInput
              name="country"
              label={`${strings.country}:`}
              readOnly
              value={country?.countryName}
            />
            <SimpleInput
              name="language"
              label={`${strings.language}:`}
              readOnly
              value={
                languages.find((i) => i.code === language)?.displayLanguage
              }
            />
            <SimpleInput
              name="version"
              label={`${strings.version}:`}
              readOnly
              value={formatVersion(version ?? "")}
            />
            <form
              className="space-y-6"
              onSubmit={handleSubmit(updatePolicy)}
              id="editPolicyForm"
            >
              <div className="flex space-x-2">
                <CheckBox
                  name="isGrammarChange"
                  label={strings.grammarChange}
                  register={register}
                />
                <Tooltip content={strings.infoNoNewVersion} placement="right">
                  <InfoFilled />
                </Tooltip>
              </div>
              <HtmlEditorTextarea
                setValue={(value) => setValue("statement", value)}
                value={getValues("statement")}
              />
            </form>
          </>
        ) : (
          <LoaderInline />
        )}
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || !isDirty || loading}
          form="editPolicyForm"
          loading={loading}
          type="submit"
          variant="primary"
        >
          {strings.save}
        </Button>
        <CloseButton onClick={onHide} />
      </Modal.Footer>
    </Modal>
  );
};

export default EditPolicyModal;
