/*
 * Copyright © GlobalVET AB, 2019.
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { CancellationFlag } from "../../models/ePrescription/cancellation/CancellationFlag";
import {
  dateAndTime,
  prescriptionCancellationReasons,
  strings,
} from "../../common/Strings/Strings";
import { CancellationItemExtended } from "../../models/ePrescription/local/history/CancellationItemExtended";
import { CancellationPackageExtended } from "../../models/ePrescription/local/history/CancellationPackageExtended";
import { PrescriptionResponseModel } from "../../models/ePrescription/response/PrescriptionResponseModel";
import { NewPrescriptionStatus } from "../../models/ePrescription/response/NewPrescriptionStatus";
import { ExtendedCancellation } from "../../models/ePrescription/persistence/ExtendedCancellation";
import { HistoryData } from "../../models/ePrescription/history/HistoryData";
import { CancellationEvent } from "../../models/ePrescription/history/CancellationEvent";
import { CancellationItemResult } from "../../models/ePrescription/cancellation/CancellationItemResult";
import { makeListSentenceFromWords } from "../../util/helperFunctions";
import { CancellationRequestedBy } from "../../models/ePrescription/cancellation/CancellationRequestedBy";
import CheckBox from "../../components/ReactHookFormFields/General/CheckBox";
import Tag from "../../components/Tag";

interface Props {
  activePrescriptionItem: CancellationItemExtended;
  index: number;
  returnCancelledItems(ci: CancellationItemExtended, index: number): void;
  status?: PrescriptionResponseModel;
  withdrawalAllowed: boolean;
  historyData: HistoryData;
}

const PackageList: React.FC<Props> = ({
  activePrescriptionItem,
  index,
  returnCancelledItems,
  status,
  withdrawalAllowed,
  historyData,
}: Props) => {
  const [cancellation, setCancellation] = useState<CancellationItemExtended>(
    activePrescriptionItem
  );

  const { register, watch } = useForm();

  useEffect(() => {
    const onChange = (values: { [key: string]: boolean }) => {
      if (cancellation) {
        const newCancellation: CancellationPackageExtended[] = [];

        Object.keys(values).forEach((key: string) => {
          const i = key.split("/")[1];
          if (cancellation.packages[parseInt(i, 10)]) {
            const newItem: CancellationPackageExtended =
              cancellation.packages[parseInt(i, 10)];
            if (values[key]) {
              newItem.isSelectedForCancellation = true;
            } else {
              if (newItem.cancellationDetails) {
                newItem.localCancellationFlag = CancellationFlag.DO_NOT_CANCEL;
              }
              newItem.isSelectedForCancellation = false;
            }
            newCancellation.push(newItem);
          }
        });

        setCancellation({ ...cancellation, packages: newCancellation });
      }
    };

    const subscription = watch((data) => {
      onChange(data);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (cancellation) {
      returnCancelledItems(cancellation, index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancellation]);

  const cancellationReason = (c: ExtendedCancellation): string => {
    if (c.cancellationFlag === CancellationFlag.OTHER && c.comment) {
      return c.comment;
    }
    return prescriptionCancellationReasons[c.cancellationFlag];
  };

  return (
    <form>
      <div className="font-bold pt-2 pb-1">{strings.packages}</div>
      {activePrescriptionItem?.packages.map((p, cipIndex: number) => {
        const cancellationResult =
          p.cancellationDetails?.cancellationResponse?.itemResults;

        let result: CancellationItemResult | undefined;

        if (cancellationResult) {
          result = cancellationResult.filter(
            (itemResult: CancellationItemResult) => itemResult.index === index
          )[0];
        }

        return (
          <div>
            <div>
              {result ? (
                <span className="mr-3">
                  <Tag text={strings.withdrawn} />
                </span>
              ) : (
                <></>
              )}
              {p.isCancelledBefore ||
              status?.result === NewPrescriptionStatus.FAILED ||
              !withdrawalAllowed ? (
                `${p.numberOfPackages} x ${p.label || strings.packages} ${
                  p.title || ""
                }`
              ) : (
                <CheckBox
                  label={`${p.numberOfPackages} x ${
                    p.label || strings.packages
                  } ${p.title || ""}`}
                  name={`item-${index}/${cipIndex}`}
                  defaultChecked={p.isSelectedForCancellation}
                  register={register}
                />
              )}
            </div>
            <div>
              {result ? (
                <div className="font-bold pt-2">
                  {strings.cancellationDetails}
                </div>
              ) : (
                <></>
              )}
              <div className="text-sm">
                <b>
                  {p.isCancelledBefore && p.cancellationDetails?.timestamp
                    ? `${strings.date}: `
                    : ""}
                </b>
                {p.isCancelledBefore && p.cancellationDetails?.timestamp
                  ? moment
                      .unix(p.cancellationDetails.timestamp)
                      .format(dateAndTime.momentDateTimeFormat)
                  : ""}
              </div>
              <div className="text-sm">
                <b>
                  {p.isCancelledBefore &&
                  p.cancellationDetails?.veterinaryInformation
                    ? `${strings.vet}: `
                    : ""}
                </b>
                {p.isCancelledBefore &&
                p.cancellationDetails?.veterinaryInformation
                  ? `${p.cancellationDetails?.veterinaryInformation.givenName} ${p.cancellationDetails?.veterinaryInformation.familyName} (${p.cancellationDetails?.veterinaryInformation.veterinaryId})`
                  : ""}
              </div>
              <div className="text-sm">
                <b>
                  {p.isCancelledBefore &&
                  p.cancellationDetails?.veterinaryInformation
                    ? `${strings.clinic}: `
                    : ""}
                </b>
                {p.isCancelledBefore &&
                p.cancellationDetails?.veterinaryInformation
                  ? `${p.cancellationDetails?.veterinaryInformation.organizationName} (${p.cancellationDetails?.veterinaryInformation.organizationId}) - `
                  : ""}
                {p.isCancelledBefore &&
                p.cancellationDetails?.veterinaryInformation
                  ? `${p.cancellationDetails?.veterinaryInformation.streetAddress}, ${p.cancellationDetails?.veterinaryInformation.postalCode}, ${p.cancellationDetails?.veterinaryInformation.city}`
                  : ""}
              </div>
              <div className="text-sm">
                <b>
                  {p.isCancelledBefore && p?.cancellationDetails
                    ? `${strings.causeOfCancellation}: `
                    : ""}
                </b>
                {p.isCancelledBefore && p?.cancellationDetails
                  ? cancellationReason(p.cancellationDetails)
                  : ""}
              </div>
              <div className="text-sm flex items-start">
                <b>
                  {historyData.cancellations.filter(
                    (h: CancellationEvent) =>
                      h.timestamp === p.cancellationDetails?.timestamp
                  )[0]?.data?.internalResponse?.message
                    ? `${strings.statusOfCancellation}: `
                    : ""}
                </b>
                <div className="ml-1" hidden={!result}>
                  {result ? result.message : ""}{" "}
                  {result ? `(${result.code})` : ""}
                  <br />
                  {result && result?.alreadyCancelled ? (
                    <>
                      {result?.alreadyCancelled?.cancelledBy ? (
                        <>
                          <b>{strings.cancelledBy}: </b>
                          {makeListSentenceFromWords([
                            result?.alreadyCancelled?.cancelledBy?.personName,
                            result?.alreadyCancelled?.cancelledBy?.id,
                            result?.alreadyCancelled?.cancelledBy?.phoneNumber,
                            result?.alreadyCancelled?.cancelledBy
                              ?.organizationName,
                            result?.alreadyCancelled?.cancelledBy?.city,
                          ])}
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {result?.alreadyCancelled?.requestedBy &&
                      result?.alreadyCancelled?.requestedBy !==
                        CancellationRequestedBy.UNKNOWN ? (
                        <>
                          <b>{strings.requestedBy}: </b>
                          {
                            prescriptionCancellationReasons[
                              result?.alreadyCancelled?.requestedBy
                            ]
                          }
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {result?.alreadyCancelled?.time ? (
                        <>
                          <b>{strings.time}: </b>
                          {moment(result?.alreadyCancelled?.time).format(
                            dateAndTime.momentDateTimeFormat
                          )}
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {result?.alreadyCancelled?.cancellationFlag ? (
                        <>
                          <b>{strings.causeOfCancellation}: </b>
                          {
                            prescriptionCancellationReasons[
                              result?.alreadyCancelled?.cancellationFlag
                            ]
                          }
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {result?.alreadyCancelled?.cause ? (
                        <>
                          <b>{strings.causeOfCancellation}: </b>
                          {result?.alreadyCancelled?.cause}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </form>
  );
};

export default PackageList;

PackageList.defaultProps = {
  status: undefined,
};
