/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  dateAndTime,
  prescriptionCancellationReasons,
  strings,
} from "../../common/Strings/Strings";
import { CancellationFlag } from "../../models/ePrescription/cancellation/CancellationFlag";
import { CancellationItem } from "../../models/ePrescription/cancellation/CancellationItem";
import PrescriptionApi from "../../api/PrescriptionApi";
import { ExtendedPrescription } from "../../models/ePrescription/persistence/ExtendedPrescription";
import { PrescriptionStatus } from "../../models/ePrescription/response/PrescriptionStatus";
import {
  getGeneralError,
  makeListSentenceFromWords,
} from "../../util/helperFunctions";
import WithdrawForm, { PrescriptionWithdrawalFormData } from "./WithdrawForm";
import { CancellationItemExtended } from "../../models/ePrescription/local/history/CancellationItemExtended";
import { CancellationPackageExtended } from "../../models/ePrescription/local/history/CancellationPackageExtended";
import PinCheckSection from "./PinCheckSection";
import { CancellationRequest } from "../../models/ePrescription/cancellation/CancellationRequest";
import { HistoryData } from "../../models/ePrescription/history/HistoryData";
import { CancellationPackage } from "../../models/ePrescription/cancellation/CancellationPackage";
import { CancellationItemResult } from "../../models/ePrescription/cancellation/CancellationItemResult";
import LoaderInline from "../../components/LoaderInline";
import AlertBox, { AlertType } from "../../components/AlertBox";
import { notAcceptedCancellationCodes } from "../ePrescription/fixValues/hardcodedStrings";
import { CancellationRequestedBy } from "../../models/ePrescription/cancellation/CancellationRequestedBy";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button";
import CloseButton from "../../components/CloseButton";

interface Props {
  open: boolean;
  close(): void;
  cancellationItems?: CancellationItemExtended[];
  prescription?: ExtendedPrescription;
  historyData: HistoryData;
  triggerReload(): void;
}

enum ContentStage {
  SELECTION,
  PINCHECK,
  SUCCESS,
}

const WithdrawalModal: React.FC<Props> = ({
  open,
  close,
  cancellationItems,
  prescription,
  historyData,
  triggerReload,
}: Props) => {
  const [cancellationItemsLocal, setCancellationItemsLocal] =
    useState<CancellationItemExtended[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined | null>();
  const [success, setSuccess] = useState<string | undefined | null>();
  const [itemResults, setItemResults] = useState<
    CancellationItemResult[] | undefined | null
  >();
  const [isFormValid, setIsFormValid] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [contentState, setContentStage] = useState<ContentStage>(
    ContentStage.SELECTION
  );

  const initValidList = () => {
    const tempValid: { [key: string]: boolean } = {};
    cancellationItems?.forEach((item: CancellationItemExtended, itemIndex) => {
      item.packages.forEach((p: CancellationPackageExtended, packageIndex) => {
        if (p.isSelectedForCancellation) {
          tempValid[`${itemIndex}r${packageIndex}`] = false;
        }
      });
    });
    setIsFormValid(tempValid);
  };

  useEffect(() => {
    if (cancellationItems) {
      setCancellationItemsLocal(cancellationItems);
      initValidList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancellationItems]);

  const simpleClose = () => {
    initValidList();
    setError(null);
    setSuccess(null);
    setItemResults(null);
    setContentStage(ContentStage.SELECTION);
    close();
  };

  const closeAndUpdate = () => {
    initValidList();
    setError(null);
    setSuccess(null);
    setItemResults(null);
    setContentStage(ContentStage.SELECTION);
    triggerReload();
    close();
  };

  const checkIfHasWithdrawalItem = (cps: CancellationPackageExtended[]) => {
    let result = false;

    cps.forEach((p) => {
      if (p.isSelectedForCancellation) {
        result = true;
      }
    });

    return result;
  };

  const setPinCheckScreen = () => {
    setContentStage(ContentStage.PINCHECK);
  };

  const startPinCheck = () => {
    setLoading(true);
    setError(null);
    setItemResults(null);
  };

  const updateCancellationItems = (values: PrescriptionWithdrawalFormData) => {
    if (cancellationItemsLocal) {
      const temp: CancellationItemExtended[] = [...cancellationItemsLocal];

      temp.forEach((cancelItem, cancelItemIndex: number) => {
        if (cancelItemIndex === parseInt(values.ciIndex, 10)) {
          cancelItem.packages.forEach(
            (_packageItem, packageItemIndex: number) => {
              if (packageItemIndex === parseInt(values.cipIndex, 10)) {
                temp[cancelItemIndex].packages[
                  packageItemIndex
                ].localCancellationFlag = values.cancellationFlag;

                temp[cancelItemIndex].packages[
                  packageItemIndex
                ].localCancellationComment = values.reason;
              }
            }
          );
        }
      });

      setCancellationItemsLocal(temp);
    }
  };

  const cancelPrescription = async () => {
    if (cancellationItemsLocal && historyData) {
      setLoading(true);

      const cancellationItemsTemp: CancellationItem[] =
        cancellationItemsLocal.map((cie: CancellationItemExtended) => {
          const packages = cie.packages.map(
            ({
              localCancellationFlag,
              localCancellationComment,
              isCancelledBefore,
            }) => {
              const cancellationPackage: CancellationPackage = {
                cancellationFlag: CancellationFlag.DO_NOT_CANCEL,
              };

              if (localCancellationFlag && !isCancelledBefore) {
                cancellationPackage.cancellationFlag = localCancellationFlag;
              }
              if (localCancellationComment && !isCancelledBefore) {
                cancellationPackage.comment = localCancellationComment;
              }

              return cancellationPackage;
            }
          );

          return {
            packages,
          };
        });

      const cancellationRequest: CancellationRequest = {
        items: cancellationItemsTemp,
      };

      try {
        const response = await PrescriptionApi.cancelPrescriptionById(
          cancellationRequest,
          historyData.id
        );
        setSuccess(response.data?.message);
        setItemResults(response.data?.itemResults || null);
        setContentStage(ContentStage.SUCCESS);
      } catch (e) {
        const err: any = e;
        setError(
          (err.response && err.response.data
            ? err.response.data.message
            : null) || getGeneralError(err)
        );
        setItemResults(
          err.response && err.response.data
            ? err.response.data.itemResults
            : null
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const isAllPackageValid = (): boolean => {
    if (Object.keys(isFormValid).length === 0) {
      return false;
    }

    let result = true;
    Object.keys(isFormValid).forEach((k) => {
      result = result && isFormValid[k];
    });

    return result;
  };

  const isCancellationRefused = (item: CancellationItemResult) => {
    let result = false;

    if (notAcceptedCancellationCodes.includes(item.code)) {
      result = true;
    }

    return result;
  };

  const body = () => {
    switch (contentState) {
      case ContentStage.PINCHECK:
        return (
          <>
            <PinCheckSection
              clear={startPinCheck}
              onSuccessFunction={cancelPrescription}
              returnErrorMessage={(m: string | null | undefined) => {
                setError(m);
                setLoading(false);
              }}
            />
            <AlertBox
              className="m-0"
              hidden={!error}
              message={
                <>
                  <b>{error?.toString()}</b>
                  {itemResults?.map((status: PrescriptionStatus) => (
                    <div className="text-sm mt-1">{status.description}</div>
                  ))}
                </>
              }
            />
          </>
        );
      case ContentStage.SUCCESS:
        return (
          <>
            <AlertBox
              className="m-0"
              hidden={!error}
              message={
                <>
                  <b>{error}</b>
                  {itemResults?.map((result: CancellationItemResult) => (
                    <div className="text-sm">
                      {result.message} ({result.code})
                    </div>
                  ))}
                </>
              }
            />
            {success ? (
              <>
                {itemResults?.map(
                  (result: CancellationItemResult, i: number) => (
                    <AlertBox
                      className={`${
                        i < itemResults.length - 1 ? "mb-3" : "m-0"
                      }`}
                      type={
                        isCancellationRefused(result)
                          ? AlertType.ERROR
                          : AlertType.SUCCESS
                      }
                      message={
                        <>
                          <b>
                            {isCancellationRefused(result)
                              ? strings.notAccepted
                              : strings.accepted}
                          </b>
                          <div className="text-sm" key={i}>
                            {cancellationItems ? (
                              <b>
                                {`${cancellationItems[result.index].title} - ${
                                  cancellationItems[result.index].packages[0]
                                    .label
                                }`}
                                <br />
                              </b>
                            ) : (
                              <></>
                            )}
                            {result.message} ({result.code})<br />
                            {result && result?.alreadyCancelled ? (
                              <>
                                {result?.alreadyCancelled?.cancelledBy ? (
                                  <>
                                    <b>{strings.cancelledBy}: </b>
                                    {makeListSentenceFromWords([
                                      result?.alreadyCancelled?.cancelledBy
                                        ?.personName,
                                      result?.alreadyCancelled?.cancelledBy?.id,
                                      result?.alreadyCancelled?.cancelledBy
                                        ?.phoneNumber,
                                      result?.alreadyCancelled?.cancelledBy
                                        ?.organizationName,
                                      result?.alreadyCancelled?.cancelledBy
                                        ?.city,
                                    ])}
                                    <br />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {result?.alreadyCancelled?.requestedBy &&
                                result?.alreadyCancelled?.requestedBy !==
                                  CancellationRequestedBy.UNKNOWN ? (
                                  <>
                                    <b>{strings.requestedBy}: </b>
                                    {
                                      prescriptionCancellationReasons[
                                        result?.alreadyCancelled?.requestedBy
                                      ]
                                    }
                                    <br />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {result?.alreadyCancelled?.time ? (
                                  <>
                                    <b>{strings.time}: </b>
                                    {moment(
                                      result?.alreadyCancelled?.time
                                    ).format(dateAndTime.momentDateTimeFormat)}
                                    <br />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {result?.alreadyCancelled?.cancellationFlag ? (
                                  <>
                                    <b>{strings.causeOfCancellation}: </b>
                                    {
                                      prescriptionCancellationReasons[
                                        result?.alreadyCancelled
                                          ?.cancellationFlag
                                      ]
                                    }
                                    <br />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {result?.alreadyCancelled?.cause ? (
                                  <>
                                    <b>{strings.causeOfCancellation}: </b>
                                    {result?.alreadyCancelled?.cause}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      }
                      closeAble={false}
                    />
                  )
                )}
              </>
            ) : (
              <></>
            )}
            {!error && !success ? (
              <AlertBox
                className="m-0"
                message={<b>{strings.simpleError}</b>}
                type={AlertType.WARNING}
              />
            ) : (
              <></>
            )}
          </>
        );
      default:
        return (
          <>
            {cancellationItems?.map(
              (ci: CancellationItemExtended, ciIndex: number) => (
                <div className="pb-4" key={ciIndex}>
                  {!checkIfHasWithdrawalItem(ci.packages) ? (
                    <></>
                  ) : (
                    <div>
                      <div className="flex text-lg mb-1">
                        <b>{ci.title}</b>
                      </div>
                      {ci.packages.map(
                        (
                          cip: CancellationPackageExtended,
                          cipIndex: number
                        ) => {
                          if (cip.isSelectedForCancellation) {
                            return (
                              <div key={cipIndex}>
                                <div className="text-left">{cip.label}</div>
                                <WithdrawForm
                                  ciIndex={ciIndex}
                                  cipIndex={cipIndex}
                                  updateCancellationItems={
                                    updateCancellationItems
                                  }
                                  validateList={(isValid: boolean) => {
                                    const validList = { ...isFormValid };
                                    validList[`${ciIndex}r${cipIndex}`] =
                                      isValid;

                                    if (Object.keys(isFormValid).length > 0) {
                                      setIsFormValid(validList);
                                    }
                                  }}
                                />
                              </div>
                            );
                          }
                          return <></>;
                        }
                      )}
                    </div>
                  )}
                </div>
              )
            )}
          </>
        );
    }
  };

  const footer = () => {
    switch (contentState) {
      case ContentStage.PINCHECK:
        return <CloseButton onClick={simpleClose} text={strings.cancel} />;
      case ContentStage.SUCCESS:
        return (
          <Button
            className="modal-main-button"
            disabled={loading || !isAllPackageValid()}
            loading={loading}
            onClick={closeAndUpdate}
          >
            {strings.finish}
          </Button>
        );
      default:
        return (
          <>
            <Button
              className="modal-main-button"
              disabled={loading || !isAllPackageValid()}
              loading={loading}
              onClick={setPinCheckScreen}
            >
              {strings.next}
            </Button>
            <CloseButton onClick={simpleClose} text={strings.cancel} />
          </>
        );
    }
  };

  return (
    <Modal handleClose={simpleClose} show={open}>
      <Modal.Header title={strings.withdraw} />
      <Modal.Body>
        {body()}
        {loading ? <LoaderInline /> : <></>}
      </Modal.Body>
      <Modal.Footer>{footer()}</Modal.Footer>
    </Modal>
  );
};

export default WithdrawalModal;
