/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState, Dispatch, useEffect } from "react";
import { strings } from "../../../common/Strings/Strings";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { getVatOptions } from "../../../util/helperFunctions";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { TreatmentItemResponse } from "../../../models/treatment/TreatmentItemResponse";
import InventoryItemSection from "./InventoryItemSection";
import { TreatmentFeeResponse } from "../../../models/treatment/TreatmentFeeResponse";
import FeeSection from "./FeeSection";
import TreatmentCostCalculator from "../../../util/TreatmentCostCalculator";
import NumberFormatter from "../../../util/NumberFormatter";
import CostCalculator from "../../../util/CostCalculator";
import { AccountingCodeResponse } from "../../../models/accounting/AccountingCodeResponse";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { useFormContext } from "react-hook-form";
import { TreatmentItemActions } from "./AddItemModal";
import { TreatmentFeeActions } from "./AddFeeModal";
import PriceInputs from "../../../components/ReactHookFormFields/Specific/PriceInputs";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";

interface Props {
  clinic?: ClinicResponse;
  countryDetails?: CountryDetailsResponse;
  dispatch: Dispatch<TreatmentItemActions>;
  dispatchFee: Dispatch<TreatmentFeeActions>;
  usedFees: Array<TreatmentFeeResponse>;
  usedItems: Array<TreatmentItemResponse>;
  vat?: number;
}

const TreatmentPrice: React.FC<Props> = ({
  clinic,
  countryDetails,
  dispatch,
  dispatchFee,
  usedFees,
  usedItems,
  vat,
}: Props) => {
  const [vatValue, setVatValue] = useState<number>();
  const { control, getValues, register, watch } = useFormContext();

  useEffect(() => {
    if (vat) {
      setVatValue(vat);
    }
  }, [vat]);

  useEffect(() => {
    if (countryDetails) {
      setVatValue(countryDetails.vatRates[0]);
    }
  }, [countryDetails]);

  const deleteItem = (treatmentItem: TreatmentItemResponse) => {
    dispatch({ type: "remove", itemToRemove: treatmentItem });
  };

  const changeQuantityOfItem = (treatmentItem: TreatmentItemResponse) => {
    dispatch({ type: "changeQuantity", itemToChange: treatmentItem });
  };

  const deleteFee = (feeItem: TreatmentFeeResponse) => {
    dispatchFee({ type: "remove", feeToRemove: feeItem });
  };

  const changeQuantityOfFee = (feeItem: TreatmentFeeResponse) => {
    dispatchFee({ type: "changeQuantity", feeToChange: feeItem });
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
          {strings.treatmentPrice}
        </h2>
      </div>
      <div className="flex flex-nowrap space-x-4">
        <Select
          className="min-w-24"
          name="vat"
          options={getVatOptions(countryDetails?.vatRates)}
          register={register}
          autoComplete={AutoCompleteOptions.off}
          isChooseOption={false}
          fieldOptions={{ valueAsNumber: true }}
          label={strings.vatAsTax}
          onChange={() => {
            setVatValue(getValues("vat"));
          }}
          required
        />
        <PriceInputs
          control={control}
          currency={countryDetails?.currency}
          label={strings.basePrice}
          name="price"
          roundingStrategy={
            clinic?.roundingStrategyItem || countryDetails?.roundingStrategyItem
          }
          vat={vatValue}
        />
      </div>
      <CombinedSelect
        allowNew={false}
        control={control}
        label={strings.accountingCode}
        name="accountingCode"
        options={countryDetails?.accountingCodes || []}
        labelKey="name"
        optional
        placeholder={strings.search}
        selectedKey={(a: AccountingCodeResponse | any) => a.code}
      />
      <div>
        <div className="flex items-center">
          <div>
            {strings.priceOfUsedItems} ({strings.gross})
          </div>
          <div className="ml-auto">
            {NumberFormatter.formatPrice(
              TreatmentCostCalculator.getGrossPriceOfItems2(
                usedItems,
                countryDetails,
                clinic
              ),
              countryDetails?.currency
            )}
          </div>
        </div>
        <InventoryItemSection
          changeQuantityOfItem={changeQuantityOfItem}
          countryDetails={countryDetails}
          deleteItem={deleteItem}
          usedItems={usedItems}
        />
      </div>
      <div>
        <div className="flex items-center">
          <div>
            {strings.priceOfAppliedFees} ({strings.gross})
          </div>
          <div className="ml-auto">
            {NumberFormatter.formatPrice(
              TreatmentCostCalculator.getGrossPriceOfFees2(
                usedFees,
                countryDetails,
                clinic
              ),
              countryDetails?.currency
            )}
          </div>
        </div>
        <FeeSection
          changeQuantityOfFee={changeQuantityOfFee}
          countryDetails={countryDetails}
          deleteFee={deleteFee}
          fees={usedFees}
        />
      </div>
      <div className="flex font-bold">
        <div>
          {strings.totalAmount} ({strings.gross})
        </div>
        <div className="ml-auto">
          {NumberFormatter.formatPrice(
            CostCalculator.getGrossPriceOfItem(
              {
                price: watch("price") || 0,
                vat: watch("vat") || 0,
                quantity: 1,
              },
              countryDetails,
              clinic
            ) +
              TreatmentCostCalculator.getGrossPriceOfItems2(
                usedItems,
                countryDetails,
                clinic
              ) +
              TreatmentCostCalculator.getGrossPriceOfFees2(
                usedFees,
                countryDetails,
                clinic
              ),
            countryDetails?.currency
          )}
        </div>
      </div>
    </div>
  );
};

export default TreatmentPrice;
