/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
export const AnimalSpecies = [
  {
    code: "11",
    species: "Nöt",
    type: "LIVESTOCK"
  },
  {
    code: "12",
    species: "Gris",
    type: "LIVESTOCK"
  },
  {
    code: "13",
    species: "Häst",
    type: "LIVESTOCK"
  },
  {
    code: "14",
    species: "Får",
    type: "LIVESTOCK"
  },
  {
    code: "15",
    species: "Get",
    type: "LIVESTOCK"
  },
  {
    code: "16",
    species: "Ren",
    type: "LIVESTOCK"
  },
  {
    code: "17",
    species: "Åsna",
    type: "LIVESTOCK"
  },
  {
    code: "18",
    species: "Kameldjur",
    type: "LIVESTOCK"
  },
  {
    code: "20",
    species: "Höns, inkl slakt- kyckling",
    type: "LIVESTOCK"
  },
  {
    code: "21",
    species: "Strutsdjur",
    type: "LIVESTOCK"
  },
  {
    code: "22",
    species: "Kalkon",
    type: "LIVESTOCK"
  },
  {
    code: "23",
    species: "Anka/gås",
    type: "LIVESTOCK"
  },
  {
    code: "24",
    species: "Övriga fjäderfä",
    type: "LIVESTOCK"
  },
  {
    code: "25",
    species: "Fisk, odlad",
    type: "LIVESTOCK"
  },
  {
    code: "26",
    species: "Mussla, odlad",
    type: "LIVESTOCK"
  },
  {
    code: "27",
    species: "Pälsdjur",
    type: "LIVESTOCK"
  },
  {
    code: "28",
    species: "Insekter",
    type: "LIVESTOCK"
  },
  {
    code: "29",
    species: "Övrigt animalie- produktion",
    type: "LIVESTOCK"
  },
  {
    code: "31",
    species: "Hjort",
    type: "GAME"
  },
  {
    code: "32",
    species: "Vildsvin",
    type: "GAME"
  },
  {
    code: "33",
    species: "Fisk, vildlevande",
    type: "GAME"
  },
  {
    code: "34",
    species: "Älg",
    type: "GAME"
  },
  {
    code: "41",
    species: "Kanin",
    type: "LIVESTOCK"
  },
  {
    code: "48",
    species: "Övrigt hägnat vilt",
    type: "GAME"
  },
  {
    code: "49",
    species: "Övrigt vilt",
    type: "GAME"
  },
  {
    code: "50",
    species: "Gris",
    type: "PET"
  },
  {
    code: "51",
    species: "Hund",
    type: "PET"
  },
  {
    code: "52",
    species: "Katt",
    type: "PET"
  },
  {
    code: "53",
    species: "Kanin",
    type: "PET"
  },
  {
    code: "54",
    species: "Marsvin",
    type: "PET"
  },
  {
    code: "55",
    species: "Hamster",
    type: "PET"
  },
  {
    code: "56",
    species: "Iller",
    type: "PET"
  },
  {
    code: "57",
    species: "Mus",
    type: "PET"
  },
  {
    code: "58",
    species: "Råtta",
    type: "PET"
  },
  {
    code: "59",
    species: "Chinchilla",
    type: "PET"
  },
  {
    code: "60",
    species: "Mink",
    type: "LIVESTOCK"
  },
  {
    code: "61",
    species: "Chincilla",
    type: "LIVESTOCK"
  },
  {
    code: "65",
    species: "Reptiler",
    type: "LIVESTOCK"
  },
  {
    code: "68",
    species: "Alpacka",
    type: "LIVESTOCK"
  },
  {
    code: "70",
    species: "Fågel",
    type: "PET"
  },
  {
    code: "71",
    species: "Degu",
    type: "PET"
  },
  {
    code: "79",
    species: "Övrigt sällskapsdjur",
    type: "PET"
  },
  {
    code: "80",
    species: "Övriga djur",
    type: "LIVESTOCK"
  },
  {
    code: "90",
    species: "Zoodjur",
    type: "ZOO"
  }
];
