/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  petTypes,
  strings,
  prescriptionWaitingPeriodType,
  prescriptionTreatmentPurpose,
  dateAndTime,
} from "../../../../common/Strings/Strings";
import logger from "../../../../util/logger";
import { Animal } from "../../../../models/ePrescription/sending/Animal";
import { AnimalCarer } from "../../../../models/ePrescription/sending/AnimalCarer";
import { PrescriptionItemFrontend } from "../../../../models/ePrescription/local/PrescriptionItemFrontend";
import { LicensedPrescriptionItemFrontend } from "../../../../models/ePrescription/local/LicensedPrescriptionItemFrontend";
import { CommodityPrescriptionItemFrontend } from "../../../../models/ePrescription/local/CommodityPrescriptionItemFrontend";
import { FeeType } from "../../../../models/ePrescription/local/FeeType";
import { LocalizedPetSpeciesResponse } from "../../../../models/pet/LocalizedPetSpeciesResponse";
import SpeciesFunctions from "../../../../common/AnimalSpecies/SpeciesFunctions";
import { Pharmacy } from "../../../../models/pharmacy/Pharmacy";
import sendingRequestProcesser, {
  generateTextFromIntervals,
} from "./sendingRequestProcesser";
import { WaitingPeriod } from "../../../../models/ePrescription/sending/WaitingPeriod";
import { ExtendedDelivery } from "../../../../models/ePrescription/persistence/ExtendedDelivery";
import { LocalLanguageResponse } from "../../../../models/language/LocalLanguageResponse";
import LanguageApi from "../../../../api/LanguageApi";
import { makeListSentenceFromWords } from "../../../../util/helperFunctions";
import { useSpecies } from "../../../../contexts/SpeciesContext";

interface Props {
  animal?: Animal;
  animalCarer?: AnimalCarer;
  prescription?: PrescriptionItemFrontend[];
  licensedPrescription?: LicensedPrescriptionItemFrontend[];
  commodityPrescription?: CommodityPrescriptionItemFrontend[];
  instructionLanguage?: string;
  delivery?: ExtendedDelivery;
  receiverPharmacy?: Pharmacy;
  comment?: string;
  selectedFeeType: FeeType;
  feeAmount: number;
}

const PrescriptionSummary: React.FC<Props> = ({
  animal,
  animalCarer,
  prescription,
  licensedPrescription,
  commodityPrescription,
  instructionLanguage,
  delivery,
  receiverPharmacy,
  comment,
  selectedFeeType,
  feeAmount,
}: Props) => {
  const [languages, setLanguages] = useState<LocalLanguageResponse[]>([]);

  const speciesCodesAndNames: LocalizedPetSpeciesResponse[] =
    useSpecies().speciesCodesAndNames;

  const getLanguages = async () => {
    try {
      const response = await LanguageApi.getLanguages();
      setLanguages(response.data);
    } catch (err) {
      logger.error(err);
    }
  };

  useEffect(() => {
    if (instructionLanguage) {
      void getLanguages();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionLanguage]);

  return (
    <div className="flex bg-sky-50 dark:bg-gray-900 rounded-lg p-4">
      <table className="tw-table">
        <tbody>
          <tr>
            <td className="text-sm">
              <b>{strings.pet}</b>
            </td>
            <td className="text-sm">
              {animal?.animalIds &&
                makeListSentenceFromWords(animal?.animalIds)}
              ,{" "}
              {SpeciesFunctions.getAnimalStringFromCode(
                speciesCodesAndNames,
                animal?.speciesCode
              )}
              , {animal?.animalIdType ? petTypes[animal?.animalIdType] : ""}
            </td>
          </tr>
          <tr>
            <td className="text-sm">
              <b>{strings.petOwner}</b>
            </td>
            <td className="text-sm">
              {animalCarer?.givenName ? animalCarer?.givenName : ""}{" "}
              {animalCarer?.familyName ? animalCarer?.familyName : ""}
              {animalCarer?.familyName || animalCarer?.givenName ? ", " : <></>}
              {animalCarer?.personalNumber}
              {sendingRequestProcesser.reverseFormatBirthDate(
                animalCarer?.birthDate
              )}
              {animalCarer?.personalNumber || animalCarer?.birthDate ? (
                ", "
              ) : (
                <></>
              )}
              {animalCarer?.organizationName
                ? animalCarer?.organizationName
                : ""}
              {animalCarer?.organizationName ? ", " : <></>}
              {animalCarer?.organizationId ? animalCarer?.organizationId : ""}
              {animalCarer?.organizationId ? ", " : <></>}
              {animalCarer?.streetAddress}
              {", "}
              {animalCarer?.postalCode} {animalCarer?.city}
              {", "}
              {animalCarer?.phoneNumber}
            </td>
          </tr>
          {instructionLanguage ? (
            <tr>
              <td className="text-sm">
                <b>{strings.instructionLanguage}</b>
              </td>
              <td className="text-sm">
                {
                  languages.filter(
                    (l: LocalLanguageResponse) => l.code === instructionLanguage
                  )[0]?.displayLanguage
                }
              </td>
            </tr>
          ) : (
            <></>
          )}
          {receiverPharmacy ? (
            <tr>
              <td className="text-sm">
                <b>{strings.receiverPharmacy}</b>
              </td>
              <td className="text-sm">
                {receiverPharmacy?.shortName}
                {", "}
                {receiverPharmacy?.officeAddress}
                {", "}
                {receiverPharmacy?.zipCode} {receiverPharmacy?.city}
              </td>
            </tr>
          ) : (
            <></>
          )}
          {delivery ? (
            <tr>
              <td className="text-sm">
                <b>{strings.delivery}</b>
              </td>
              <td className="text-sm">
                {delivery?.pharmacy ? (
                  <>
                    {delivery?.pharmacy.shortName}
                    {", "}
                    {delivery?.pharmacy.officeAddress}
                    {", "}
                    {delivery?.pharmacy.zipCode} {delivery?.pharmacy.city}
                  </>
                ) : (
                  <>
                    {delivery?.streetAddress}
                    {", "}
                    {delivery?.building}
                    {delivery?.building ? ", " : <></>}
                    {delivery?.postalCode} {delivery?.city}
                  </>
                )}
              </td>
            </tr>
          ) : (
            <></>
          )}
          <tr>
            <td className="text-sm">
              <b>{strings.fees}</b>
            </td>
            <td className="text-sm">{feeAmount} SEK</td>
          </tr>
          {comment && comment !== "" ? (
            <tr>
              <td className="text-sm">
                <b>{strings.commentToPharmacist}</b>
              </td>
              <td className="text-sm">{comment}</td>
            </tr>
          ) : (
            <></>
          )}
          <tr>
            <td className="text-sm">
              <b>{strings.prescriptionItem}</b>
            </td>
            <td className="text-sm">
              {prescription?.map((presc: PrescriptionItemFrontend, index) => {
                return (
                  <div className="mb-1" key={index}>
                    <b>
                      {presc.product.name} {presc.product.strength}
                    </b>{" "}
                    <br />
                    {generateTextFromIntervals(presc.dosageText)}
                    {presc.antimicrobial ? (
                      <div>
                        <i>{strings.treatmentPurpose}:</i>{" "}
                        {prescriptionTreatmentPurpose[presc.antimicrobial]}
                      </div>
                    ) : (
                      <></>
                    )}
                    {presc.validUntil ? (
                      <div>
                        <i>{strings.validUntil}:</i>{" "}
                        {moment(presc.validUntil).format(
                          dateAndTime.momentDateFormat
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {presc.repeatDispensing?.numberOfTimes ? (
                      <div>
                        <i>{strings.limit}:</i>{" "}
                        {presc.repeatDispensing?.numberOfTimes}
                      </div>
                    ) : (
                      <></>
                    )}
                    {presc.repeatDispensing?.interval ? (
                      <div>
                        <i>{strings.expeditionInterval}: </i>
                        {`${presc.repeatDispensing.interval} ${
                          presc.repeatDispensing.intervalUnit
                            ? strings[
                                presc.repeatDispensing.intervalUnit.toLowerCase()
                              ]
                            : ""
                        }`}
                      </div>
                    ) : (
                      <></>
                    )}
                    {presc.latestRequestedTimeForDispensing ? (
                      <div>
                        <i>{strings.latestRequestedTimeForDispensing}: </i>
                        {moment(presc.latestRequestedTimeForDispensing).format(
                          dateAndTime.momentDateFormat
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {presc.waitingPeriods ? (
                      <div>
                        <div>
                          <i>{strings.waitingPeriods}:&nbsp;</i>
                          {sendingRequestProcesser
                            .transformWaitingPeriods(presc.waitingPeriods)
                            ?.map((wp: WaitingPeriod) => {
                              return (
                                <span>{`${
                                  prescriptionWaitingPeriodType[wp.type]
                                }: ${wp.days} ${strings.days} `}</span>
                              );
                            })}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="text-sm">
                      {presc.package.numberOfPackages} x {presc.package.label}
                    </div>
                  </div>
                );
              })}
              {licensedPrescription?.map(
                (presc: LicensedPrescriptionItemFrontend) => {
                  return (
                    <div className="mb-1">
                      <b>
                        {presc.freeTextLicense.supplementaryInformation}{" "}
                        {presc.freeTextLicense.strength}
                      </b>{" "}
                      <br />
                      {generateTextFromIntervals(presc.dosageText)}
                      {presc.antimicrobial ? (
                        <div>
                          <i>{strings.treatmentPurpose}:</i>{" "}
                          {prescriptionTreatmentPurpose[presc.antimicrobial]}
                        </div>
                      ) : (
                        <></>
                      )}
                      {presc.validUntil ? (
                        <div>
                          <i>{strings.validUntil}:</i>{" "}
                          {moment(presc.validUntil).format(
                            dateAndTime.momentDateFormat
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {presc.repeatDispensing?.numberOfTimes ? (
                        <div>
                          <i>{strings.limit}:</i>{" "}
                          {presc.repeatDispensing?.numberOfTimes}
                        </div>
                      ) : (
                        <></>
                      )}
                      {presc.repeatDispensing?.interval ? (
                        <div>
                          <i>{strings.expeditionInterval}: </i>
                          {`${presc.repeatDispensing.interval} ${
                            presc.repeatDispensing.intervalUnit
                              ? strings[
                                  presc.repeatDispensing.intervalUnit.toLowerCase()
                                ]
                              : ""
                          }`}
                        </div>
                      ) : (
                        <></>
                      )}
                      {presc.latestRequestedTimeForDispensing ? (
                        <div>
                          <i>{strings.latestRequestedTimeForDispensing}: </i>
                          {moment(
                            presc.latestRequestedTimeForDispensing
                          ).format(dateAndTime.momentDateFormat)}
                        </div>
                      ) : (
                        <></>
                      )}
                      {presc.waitingPeriods ? (
                        <div>
                          <div>
                            <i>{strings.waitingPeriods}:&nbsp;</i>
                            {sendingRequestProcesser
                              .transformWaitingPeriods(presc.waitingPeriods)
                              ?.map((wp: WaitingPeriod) => {
                                return (
                                  <span>{`${
                                    prescriptionWaitingPeriodType[wp.type]
                                  }: ${wp.days} ${strings.days} `}</span>
                                );
                              })}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {presc.package.map((pack) => {
                        return (
                          <div className="text-sm">
                            {pack.numberOfPackages} x {strings.packages}
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              )}
              {commodityPrescription?.map(
                (presc: CommodityPrescriptionItemFrontend) => {
                  return (
                    <div className="mb-1">
                      <b>{presc.product.name}</b> <br />
                      {generateTextFromIntervals(presc.dosageText)}
                      {presc.repeatDispensing?.numberOfTimes ? (
                        <div>
                          <i>{strings.limit}:</i>{" "}
                          {presc.repeatDispensing?.numberOfTimes}
                        </div>
                      ) : (
                        <></>
                      )}
                      {presc.repeatDispensing?.interval ? (
                        <div>
                          <i>{strings.expeditionInterval}: </i>
                          {`${presc.repeatDispensing.interval} ${
                            presc.repeatDispensing.intervalUnit
                              ? strings[
                                  presc.repeatDispensing.intervalUnit.toLowerCase()
                                ]
                              : ""
                          }`}
                        </div>
                      ) : (
                        <></>
                      )}
                      {presc.latestRequestedTimeForDispensing ? (
                        <div>
                          <i>{strings.latestRequestedTimeForDispensing}: </i>
                          {moment(
                            presc.latestRequestedTimeForDispensing
                          ).format(dateAndTime.momentDateFormat)}
                        </div>
                      ) : (
                        <></>
                      )}
                      {presc.waitingPeriods ? (
                        <div>
                          <i>{strings.waitingPeriods}:&nbsp;</i>
                          {sendingRequestProcesser
                            .transformWaitingPeriods(presc.waitingPeriods)
                            ?.map((wp: WaitingPeriod) => {
                              return (
                                <span>{`${
                                  prescriptionWaitingPeriodType[wp.type]
                                }: ${wp.days} ${strings.days} `}</span>
                              );
                            })}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="text-sm">
                        {presc.package.numberOfPackages} x {presc.package.label}
                      </div>
                    </div>
                  );
                }
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PrescriptionSummary;

PrescriptionSummary.defaultProps = {
  animal: undefined,
  animalCarer: undefined,
  prescription: undefined,
  licensedPrescription: undefined,
  commodityPrescription: undefined,
  instructionLanguage: undefined,
  delivery: undefined,
  receiverPharmacy: undefined,
  comment: undefined,
};
