/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { RecoveryRequest } from "../models/recovery/RecoveryRequest";
import { MobileRecoveryRequest } from "../models/recovery/MobileRecoveryRequest";
import { MobileRecoveryResponse } from "../models/recovery/MobileRecoveryResponse";
import { RecoveryTokenResponse } from "../models/recovery/RecoveryTokenResponse";
import { SmsCodeRequest } from "../models/recovery/SmsCodeRequest";
import { UpdatePasswordRequest } from "../models/user/UpdatePasswordRequest";
import Params from "../common/Params";

export default class RecoveryApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static requireMailRecovery(
    recoveryRequest: RecoveryRequest,
    recaptchaToken: string | null
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/password-recovery/email`;
    return axiosCommon.post(url, recoveryRequest, {
      headers: { "Captcha-Token": recaptchaToken },
    });
  }

  static requireMobileRecovery(
    recoveryRequest: MobileRecoveryRequest,
    recaptchaToken: string | null
  ): ApiPromise<MobileRecoveryResponse> {
    const url = `${this.baseUrl}/password-recovery/mobile`;
    return axiosCommon.post(url, recoveryRequest, {
      headers: { "Captcha-Token": recaptchaToken },
    });
  }

  static getRecoveryTokenBySmsCode(
    codeId: string,
    smsCode: string
  ): ApiPromise<RecoveryTokenResponse> {
    const url = `${this.baseUrl}/password-recovery/mobile/${codeId}`;
    const request: SmsCodeRequest = { smsCode };
    return axiosCommon.post(url, request);
  }

  static checkTokenIsValid(tokenId: string, token: string): ApiPromise<void> {
    const url = `${this.baseUrl}/password-recovery/${tokenId}/${token}`;
    return axiosCommon.get(url);
  }

  static resetPassword(
    tokenId: string,
    token: string,
    password: UpdatePasswordRequest
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/password-recovery/${tokenId}/${token}`;
    return axiosCommon.post(url, password);
  }
}
