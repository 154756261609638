/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import MaintenanceApi from "../api/MaintenanceApi";
import logger from "../util/logger";
import { ChildrenProps } from "../models/ChildrenProps";

interface IMaintenanceContext {
  maintenanceModeEnabled: boolean;
  fetchMaintenanceModeEnabled: () => void;
}

const MaintenanceContext = createContext<IMaintenanceContext | null>(null);

export const useMaintenance = (): IMaintenanceContext | null =>
  useContext(MaintenanceContext);

export const MaintenanceProvider: React.FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [maintenanceModeEnabled, setMaintenanceModeEnabled] = useState(false);

  const fetchMaintenanceModeEnabled = useCallback(async () => {
    try {
      const response = await MaintenanceApi.getMaintenanceModeEnabled();
      const enabled = response.data.enabled;
      if (maintenanceModeEnabled !== enabled) {
        setMaintenanceModeEnabled(enabled);
      }
    } catch (e) {
      logger.error(e);
    }
  }, [maintenanceModeEnabled]);

  useEffect(() => {
    void fetchMaintenanceModeEnabled();
  }, [fetchMaintenanceModeEnabled]);

  const ctx = useMemo(
    () => ({
      fetchMaintenanceModeEnabled,
      maintenanceModeEnabled,
    }),
    [fetchMaintenanceModeEnabled, maintenanceModeEnabled]
  );

  return (
    <MaintenanceContext.Provider value={ctx}>
      {children}
    </MaintenanceContext.Provider>
  );
};
