/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useParams } from "react-router-dom";
import { PageProps } from "../../../models/PageProps";
import { Loader } from "../../../components/Loader";
import CreateInvoice from "./CreateInvoice";
import { useClinic } from "../../../contexts/ClinicContext";

type Params = {
  invoiceId?: string;
  clinicId?: string;
  type: "finish" | "copy";
};

const CreateInvoicePage = ({ setPageLoading }: PageProps) => {
  const { invoiceId, type } = useParams<Params>();
  const { clinic } = useClinic();

  return (
    <CreateInvoice
      invoiceId={invoiceId}
      clinicId={clinic?.id}
      type={type || "copy"}
      setPageLoading={setPageLoading}
    />
  );
};

export default Loader(CreateInvoicePage);
