/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { strings } from "../../../../common/Strings/Strings";
import {
  getAddressString,
  getGeneralError,
} from "../../../../util/helperFunctions";
import { AddressResponse } from "../../../../models/contact/AddressResponse";
import { ContactType } from "../../../../models/contact/ContactType";
import UserContactsApi from "../../../../api/UserContactsApi";
import AlertBox from "../../../AlertBox";
import Button from "../../../Button";

type PrevStateSetter = (prevState: AddressResponse[]) => AddressResponse[];

interface Props {
  address: AddressResponse | null;
  onHide: () => void;
  setAddresses: (setter: PrevStateSetter) => void;
  show: boolean;
}

const PromoteAddressModal: React.FC<Props> = ({
  address,
  onHide,
  setAddresses,
  show,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const promoteOwnership = async () => {
    if (address === null || address.contactType === ContactType.PRIMARY) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await UserContactsApi.setPrimaryUserAddress(address.id);
      setAddresses((prevState) => {
        const prevPrimary = prevState.find(
          (o) => o.contactType === ContactType.PRIMARY
        );
        const newPrimary = prevState.find((o) => o.id === address.id);
        if (prevPrimary !== undefined) {
          prevPrimary.contactType = ContactType.PRIVATE;
        }
        if (newPrimary !== undefined) {
          newPrimary.contactType = ContactType.PRIMARY;
        }
        return prevState;
      });
      onHide();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{strings.setPrimaryAddress}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex-row p-3 text-center">
          {strings.primaryAddressPromotionTextPart1}
          <b>{address && getAddressString(address)}</b>
          {strings.primaryAddressPromotionTextPart2}
        </div>
        <AlertBox message={error} className="m-3" />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={onHide} variant="secondary">
          {strings.cancel}
        </Button>
        <Button
          className="ml-auto"
          disabled={loading}
          fullWidth={false}
          loading={loading}
          onClick={promoteOwnership}
          variant="primary"
        >
          {strings.save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PromoteAddressModal;
