/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, ReactElement, useState } from "react";
import moment from "moment";
import {
  CalendarTableRowUnit,
  TupleType,
  ZipType,
} from "../../../../models/calendar/Rendering/CalendarTableRowUnit";
import { CalendarReservation } from "../../../../models/calendar/CalendarReservation";
import AppointmentCard from "./AppointmentCard";

import { ShiftToRender } from "../../../../models/calendar/Rendering/CalendarRowsByColumn";
import { ViewTypes } from "../../MainCalendar";
import {
  baseSizeInPixels,
  calendarTimeGapsMin,
  momentDateFormat,
  momentTimeFormat,
  unitCellHeight,
} from "../../calendarConfig";

interface Props {
  clickEmptySlot?(time: string): void;
  clickReservation?(
    reservation: CalendarReservation | number | boolean | undefined
  ): void;
  day: string;
  column: ShiftToRender;
  disableHeader?: boolean;
  openMedicalRecord?(reservation: CalendarReservation): void;
  times: string[];
  viewType?: ViewTypes;
}

export interface Header {
  collaboratorName: string;
  collaboratorUserId: string;
  firstStartTime: string;
  lastEndTime: string;
}

const Column = ({
  clickEmptySlot,
  clickReservation,
  column,
  day,
  disableHeader = false,
  openMedicalRecord,
  times,
  viewType = ViewTypes.DAY,
}: Props): ReactElement => {
  const [activeAppointment, setActiveAppointment] = useState<string>();
  let startIndex = -1;

  const getCellHeight = (
    cellType: TupleType,
    index: number,
    rowSpan?: number
  ): number => {
    switch (cellType) {
      case TupleType.RESERVATION_START:
        return unitCellHeight * (rowSpan || 1);
      case TupleType.RESERVATION:
        return 0;
      default:
        return unitCellHeight;
    }
  };

  return (
    <>
      <div id="display-time-column" className="col-span-2">
        <div className="flex flex-col">
          {column.rows.map((item: CalendarTableRowUnit, index: number) => {
            const cellType: TupleType = item[1];
            const time = times[index];
            const isBorder = index % calendarTimeGapsMin === 0;
            const isInside = moment(
              day + time,
              momentDateFormat + momentTimeFormat
            ).isBetween(
              column.columnShift.firstStartTime,
              column.columnShift.lastEndTime,
              "minutes",
              "[]"
            );
            const cellZip = item[2];
            const cellClass = isBorder
              ? "border-t border-gray-200 dark:border-gray-700"
              : "";

            /* Outside shift block - do not display in vet view */
            if (
              viewType === ViewTypes.DOCTOR_DAY &&
              cellType === TupleType.EMPTY &&
              !isInside
            ) {
              return <Fragment key={index} />;
            }

            /* Time starting block, based on calendarTimeGapsMin, which doesn't contain reservation */
            if (cellZip === ZipType.ZIP_NEXT_BLOCK && isBorder) {
              startIndex = index;
              return (
                <div
                  className={`${cellClass} ${cellType === TupleType.EMPTY ? "bg-gray-50 dark:bg-gray-900" : ""}`}
                  key={index}
                  onClick={() => {
                    if (clickEmptySlot) {
                      clickEmptySlot(time);
                    }
                  }}
                  role="button"
                  style={{
                    height: baseSizeInPixels,
                  }}
                  tabIndex={0}
                >
                  <span className="mt-1 text-xs font-normal text-gray-500 dark:text-gray-400">
                    {time}
                  </span>
                </div>
              );
            }

            /* Between time starting blocks; zipped empty blocks */
            if (
              cellZip === ZipType.ZIPPED &&
              !isBorder &&
              index - (index % calendarTimeGapsMin) === startIndex
            ) {
              return <Fragment key={index} />;
            }

            /* Default blocks */

            return (
              <div
                className={`${cellClass} ${cellType === TupleType.EMPTY ? "bg-gray-50 dark:bg-gray-900" : ""}`}
                // className="border-t border-gray-200 dark:border-gray-700 py-0.5"
                key={index}
                onClick={() => {
                  if (clickEmptySlot) {
                    clickEmptySlot(time);
                  }
                }}
                role="button"
                style={{
                  height: unitCellHeight,
                }}
                tabIndex={0}
              >
                <span className="mt-1 text-xs font-normal text-gray-500 dark:text-gray-400">
                  {isBorder ? time : ""}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div id="display-reservations-column" className="relative col-span-10">
        <div className="flex flex-col">
          {column.rows.map((item: CalendarTableRowUnit, index: number) => {
            const cellType: TupleType = item[1];
            const cellValues = item[0];
            const cellZip = item[2];
            const time = times[index];
            const isBorder = index % calendarTimeGapsMin === 0;
            const cellHeight: number = getCellHeight(
              cellType,
              index,
              cellValues?.reservation?.rowSpan
            );
            const cellClass = isBorder
              ? "border-t border-gray-200 dark:border-gray-700"
              : "";
            const isInside = moment(
              day + time,
              momentDateFormat + momentTimeFormat
            ).isBetween(
              column.columnShift.firstStartTime,
              column.columnShift.lastEndTime,
              "minutes",
              "[]"
            );

            /* Outside shift block - do not display in vet view */
            if (
              viewType === ViewTypes.DOCTOR_DAY &&
              cellType === TupleType.EMPTY &&
              !isInside
            ) {
              return <Fragment key={index} />;
            }

            /* Reservation start block */
            if (cellType === TupleType.RESERVATION_START) {
              return (
                <div
                  className={`${cellClass}`}
                  key={index}
                  onClick={() => {
                    if (openMedicalRecord && cellValues?.reservation) {
                      openMedicalRecord(cellValues.reservation);
                    } else if (clickReservation) {
                      clickReservation(cellValues?.reservation);
                    }

                    setActiveAppointment(cellValues?.reservation?.id);
                  }}
                  role="button"
                  style={{
                    height: `${cellHeight}px`,
                  }}
                  tabIndex={0}
                >
                  <AppointmentCard
                    height={cellHeight}
                    isItActive={
                      activeAppointment === cellValues?.reservation?.id
                    }
                    reservation={cellValues?.reservation}
                    viewType={viewType}
                  />
                </div>
              );
            }

            /* Inside reservation block */
            if (cellType === TupleType.RESERVATION) {
              return <></>;
            }

            /* Time starting block, based on calendarTimeGapsMin, which doesn't contain reservation */
            if (cellZip === ZipType.ZIP_NEXT_BLOCK && isBorder) {
              startIndex = index;
              return (
                <div
                  className={`${cellClass} ${cellType === TupleType.EMPTY ? "bg-gray-50 dark:bg-gray-900" : ""}`}
                  key={index}
                  onClick={() => {
                    if (clickEmptySlot) {
                      clickEmptySlot(time);
                    }
                  }}
                  role="button"
                  style={{
                    height: baseSizeInPixels,
                  }}
                  tabIndex={0}
                />
              );
            }

            /* Between time starting blocks; zipped empty blocks */
            if (
              cellZip === ZipType.ZIPPED &&
              !isBorder &&
              index - (index % calendarTimeGapsMin) === startIndex
            ) {
              return <Fragment key={index} />;
            }

            /* Default blocks */

            return (
              <div
                className={`${cellClass} ${cellType === TupleType.EMPTY ? "bg-gray-50 dark:bg-gray-900" : ""}`}
                key={index}
                onClick={() => {
                  if (clickEmptySlot) {
                    clickEmptySlot(time);
                  }
                }}
                role="button"
                style={{
                  height: cellHeight,
                }}
                tabIndex={0}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Column;

Column.defaultProps = {
  clickEmptySlot: undefined,
  clickReservation: undefined,
  disableHeader: false,
  openMedicalRecord: undefined,
  viewType: undefined,
};
