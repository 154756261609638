/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { getGeneralError } from "../../util/helperFunctions";
import { PageProps } from "../../models/PageProps";
import { Loader } from "../../components/Loader";
import FeedbackApi from "../../api/FeedbackApi";
import Textarea from "../../components/ReactHookFormFields/General/Textarea";
import AlertBox, { AlertType } from "../../components/AlertBox";
import { findLocale, strings } from "../../common/Strings/Strings";
import logger from "../../util/logger";
import Params from "../../common/Params";
import { useParams } from "react-router-dom";
import Button from "../../components/Button";

interface FormData {
  message: string;
}

interface ReCaptcha {
  reset: () => void;
  execute: () => void;
}

const FeedbackPage = (props: PageProps): ReactElement => {
  const { setPageLoading } = props;
  const { feedbackId } = useParams<"feedbackId">();

  const [alert, setAlert] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.ERROR);

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [captchaValid, setCaptchaValid] = useState(false);
  const captchaRef = useRef<ReCaptcha | null>(null);

  const { formState, handleSubmit, register } = useForm<FormData>({
    mode: "onChange",
  });

  const submitFeedback = async (data: FormData) => {
    if (feedbackId) {
      try {
        setPageLoading(true);
        await FeedbackApi.submitFeedback(feedbackId, data, recaptchaToken);
        setAlertType(AlertType.SUCCESS);
        setAlert(strings.feedbackSuccess);
        setPageLoading(false);
      } catch (e) {
        setAlertType(AlertType.ERROR);
        setAlert(await getGeneralError(e));
        setPageLoading(false);
      } finally {
        captchaRef.current?.reset();
      }
    }
  };

  useEffect(() => {
    const getFeedback = async (fbId: string) => {
      try {
        setPageLoading(true);
        const response = await FeedbackApi.getFeedback(fbId);

        if (response.data.submitted) {
          setAlertType(AlertType.INFO);
          setAlert(strings.feedbackAlreadySent);
        } else {
          setAlert(null);
        }

        setPageLoading(false);
      } catch (e) {
        setAlertType(AlertType.ERROR);
        setAlert(await getGeneralError(e));
        setPageLoading(false);
      }
    };
    if (feedbackId) {
      void getFeedback(feedbackId);
    }
  }, [feedbackId]);

  return (
    <main className="main-signed-in">
      <section>
        <div className="mx-auto flex flex-col items-center justify-center space-y-6 px-4 py-10 lg:px-0 lg:py-14">
          <div className="w-full space-x-0 space-y-10 lg:w-auto lg:space-x-6 lg:space-y-0">
            <div className="w-full md:max-w-md md:tw-card md:p-8 space-y-4">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.survey}
              </h1>
              <AlertBox message={alert} type={alertType} closeAble={false} />
              <div hidden={alert !== null}>
                <form onSubmit={handleSubmit(submitFeedback)}>
                  <div className="mb-2">{strings.feedbackQuestion}</div>
                  <Textarea
                    error={formState.errors.message}
                    name="message"
                    register={register}
                    placeholder={strings.feedbackAnswerPlaceholder}
                    minRows={10}
                    required
                  />
                  {Params.captcha === "on" && (
                    <div>
                      <ReCAPTCHA
                        ref={(e) => {
                          captchaRef.current = e;
                        }}
                        hl={findLocale()}
                        sitekey={Params.recaptchaSiteKey}
                        size="normal"
                        onChange={(t) => {
                          if (t === null) {
                            logger.debug("Captcha expired");
                            return;
                          }
                          setCaptchaValid(true);
                          setRecaptchaToken(t);
                        }}
                      />
                    </div>
                  )}
                  <Button
                    type="submit"
                    disabled={
                      !formState.isValid ||
                      (!captchaValid && Params.captcha === "on")
                    }
                  >
                    {strings.sendFeedback}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Loader(FeedbackPage);
