/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { InvoicingMethod } from "../models/invoice/InvoicingMethod";
import { strings } from "../common/Strings/Strings";
import { InvoiceResponse } from "../models/invoice/InvoiceResponse";
import { GlobalVetInvoiceResponse } from "../models/management/GlobalVetInvoiceResponse";
import { BillingSystem } from "../models/management/BillingSystem";
import { ClinicResponse } from "../models/clinic/ClinicResponse";

export const getInvoicingSuccessMessage = (invoice?: InvoiceResponse) => {
  switch (invoice?.invoicingMethod) {
    case InvoicingMethod.FORTNOX:
      return strings.sentToFortnox;
    case InvoicingMethod.SZAMLAZZ:
      return strings.sentToSzamlazz;
    default:
      return undefined;
  }
};

export const getInvoicingErrorMessage = (invoice?: InvoiceResponse) => {
  switch (invoice?.invoicingMethod) {
    case InvoicingMethod.FORTNOX:
      return strings.failedToSendToFortnox;
    case InvoicingMethod.SZAMLAZZ:
      return strings.failedToSendToSzamlazz;
    default:
      return undefined;
  }
};

export const getGVInvoicingSuccessMessage = (
  invoice: GlobalVetInvoiceResponse
) => {
  switch (invoice.billingSystem) {
    case BillingSystem.FORTNOX:
      return strings.sentToFortnox;
    case BillingSystem.SZAMLAZZ:
      return strings.sentToSzamlazz;
    default:
      return undefined;
  }
};

export const getGVInvoicingErrorMessage = (
  invoice: GlobalVetInvoiceResponse
) => {
  switch (invoice.billingSystem) {
    case BillingSystem.FORTNOX:
      return strings.failedToSendToFortnox;
    case BillingSystem.SZAMLAZZ:
      return strings.failedToSendToSzamlazz;
    default:
      return undefined;
  }
};

export const getDefaultInvoicingMethod = (
  clinic: ClinicResponse | undefined | null,
  invoiceNeeded: boolean | undefined
): InvoicingMethod => {
  if (!invoiceNeeded) return InvoicingMethod.COST_CALCULATION;
  if (clinic?.fortnoxEnabled) return InvoicingMethod.FORTNOX;
  if (clinic?.szamlazzEnabled) return InvoicingMethod.SZAMLAZZ;
  return InvoicingMethod.COST_CALCULATION;
};
