/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const statisticsItemsEn = {
  NUMBER_OF_APPOINTMENTS: "Appointments made",
  APPOINTMENT_USAGE: "Appointment usage",
  NUMBER_OF_MEDICAL_RECORDS: "Medical records created",
  MEDICAL_RECORD_USAGE: "Medical record usage",
  NUMBER_OF_USED_ITEMS: "Inventory items used",
  PRICE_OF_USED_ITEMS: "Cost of inventory items used",
  MAX_NUMBER_OF_EMPLOYEES: "Maximum number of employees during the month",
  MAX_NUMBER_OF_VETS: "Maximum number of vets during the month",
  MAX_NUMBER_OF_ASSISTANTS: "Maximum number of assistants during the month",
  MAX_NUMBER_OF_RECEPTIONISTS:
    "Maximum number of receptionists during the month",
  MAX_NUMBER_OF_ORGANIZATION_MANAGERS:
    "Maximum number of organization managers during the month",
  INVENTORY_USAGE: "Inventory usage",
  SMS_USAGE: "SMS usage",
  NUMBER_OF_PRESCRIPTIONS: "Prescriptions created",
};
