/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import Params from "../../../common/Params";
import { getGeneralError } from "../../../util/helperFunctions";
import { VersionInfo } from "../../../models/version/VersionInfo";
import VersionApi from "../../../api/VersionApi";
import moment from "moment";
import { strings } from "../../../common/Strings/Strings";

function AppVersions(): ReactElement {
  return (
    <>
      <div className="tw-card p-4">
        <div className="font-bold mb-4">{strings.gvAppVersions}</div>
        <div className="tw-table-container">
          <table className="tw-table">
            <thead>
              <tr>
                <th className="tw-th">{strings.gvServiceName}</th>
                <th className="tw-th">{strings.gvServiceVersion}</th>
                <th className="tw-th">{strings.gvLastCommit}</th>
              </tr>
            </thead>
            <tbody className="tw-tbody">
              <Service
                name="auth-service"
                baseUrl={`${Params.authBaseURL}${Params.authPrefix}`}
                repositoryUrl="https://gitlab.com/globalvet/mainservices/globalvet-aws/auth-service"
              />
              <Service
                name="clinic-service"
                baseUrl={Params.clinicServiceBaseURL}
                repositoryUrl="https://gitlab.com/globalvet/mainservices/globalvet-aws/clinic-service"
              />
              <Service
                name="geo-service"
                baseUrl={Params.geoService}
                repositoryUrl="https://gitlab.com/globalvet/mainservices/globalvet-aws/geo-service"
              />
              <Service
                name="pharmacy-service"
                baseUrl={Params.pharmacyService}
                repositoryUrl="https://gitlab.com/globalvet/mainservices/globalvet-aws/pharmacy-service"
              />
              <Service
                name="prescription-service"
                baseUrl={Params.prescriptionService}
                repositoryUrl="https://gitlab.com/globalvet/mainservices/globalvet-aws/swedish-prescription-service"
              />
              <Service
                name="szamlazzhu-service"
                baseUrl={Params.szamlazzhuServiceBaseURL}
                repositoryUrl="https://gitlab.com/globalvet/mainservices/globalvet-aws/szamlazzhu-service"
              />
              <Service
                name="vara-service"
                baseUrl={Params.varaService}
                repositoryUrl="https://gitlab.com/globalvet/mainservices/globalvet-aws/vara-service"
              />
              <Service
                name="frontend"
                baseUrl=""
                repositoryUrl="https://gitlab.com/globalvet/mainservices/globalvet-s3/app.globalvet.com"
              />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

interface ServiceProps {
  name: string;
  baseUrl: string;
  repositoryUrl: string;
}

export const Service = (props: ServiceProps): ReactElement => {
  const { name, baseUrl, repositoryUrl } = props;

  const [versionInfo, setVersionInfo] = useState<VersionInfo>();
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    const getVersionInfo = async () => {
      try {
        setError(null);
        const response = await VersionApi.getVersionInfo(baseUrl);
        setVersionInfo(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
      }
    };

    const getFrontendVersion = () => {
      const version: VersionInfo = {
        version: process.env.REACT_APP_VERSION || "",
        build: process.env.REACT_APP_LAST_COMMIT_HASH || "",
        lastModified: process.env.REACT_APP_LAST_COMMIT_TIME || "",
      };
      setVersionInfo(version);
    };

    if (name == "frontend") {
      getFrontendVersion();
    } else {
      void getVersionInfo();
    }
  }, []);

  return (
    <tr key={name}>
      <td className="px-4 py-3 text-sm font-bold whitespace-nowrap">
        <a href={repositoryUrl} target="_blank" className="text-current">
          {name}
        </a>
      </td>
      <td
        hidden={!!error}
        className="px-4 py-3 text-sm font-medium whitespace-nowrap"
      >
        {versionInfo?.version}
      </td>
      <td
        hidden={!!error}
        className="px-4 py-3 text-sm font-medium whitespace-nowrap"
      >
        <a
          href={`${repositoryUrl}/-/commits/${versionInfo?.build}`}
          target="_blank"
          className="text-current font-medium"
        >
          {versionInfo?.lastModified &&
            moment(versionInfo?.lastModified).format("LLL")}
        </a>
      </td>
      <td
        colSpan={2}
        hidden={!error}
        className="px-4 py-3 text-sm font-medium whitespace-nowrap text-danger"
      >
        {error}
      </td>
    </tr>
  );
};

export default AppVersions;
