/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ClinicResponse } from "../models/clinic/ClinicResponse";
import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { ClinicPharmacyOrdinalRequest } from "../models/clinic/ClinicPharmacyOrdinalRequest";
import { ClinicPharmacyRequest } from "../models/clinic/ClinicPharmacyRequest";
import Params from "../common/Params";

export default class ClinicPharmacyApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static addAffiliatedSwedishPharmacyToClinic(
    clinicId: string,
    request: ClinicPharmacyRequest
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/pharmacies`;
    return axiosCommon.post(url, request);
  }

  static removeAffiliatedSwedishPharmacyFromClinic(
    clinicId: string,
    pharmacyId: string
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/pharmacies/${pharmacyId}`;
    return axiosCommon.delete(url);
  }

  static setAffiliatedSwedishPharmacyOrdinalInClinic(
    clinicId: string,
    pharmacyId: string,
    request: ClinicPharmacyOrdinalRequest
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/pharmacies/${pharmacyId}/ordinal`;
    return axiosCommon.put(url, request);
  }
}
