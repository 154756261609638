/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { axiosCommon } from "../util/axiosSetup";
import { InvoiceResponse } from "../models/invoice/InvoiceResponse";
import { InvoiceRequest } from "../models/invoice/InvoiceRequest";

export default class InvoiceApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getInvoice(invoiceId: string): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices/${invoiceId}`;
    return axiosCommon.get(url);
  }

  static getInvoiceOfPrescription(
    prescriptionId: string
  ): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/prescriptions/${prescriptionId}/invoice`;
    return axiosCommon.get(url);
  }

  static getInvoiceOfMedicalRecord(
    medicalRecordId: string
  ): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/medical-records/${medicalRecordId}/invoice`;
    return axiosCommon.get(url);
  }

  static getInvoicePdf(invoiceId: string): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/invoices/${invoiceId}/pdf`;
    return axiosCommon.get(url, {
      headers: { Accept: "*/*" },
      responseType: "arraybuffer",
    });
  }

  static sendPaymentReminder(invoiceId: string): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices/${invoiceId}/reminders`;
    return axiosCommon.post(url);
  }

  static billInvoice(
    invoiceId: string,
    request: InvoiceRequest
  ): ApiPromise<InvoiceResponse[]> {
    const url = `${this.baseUrl}/invoices/${invoiceId}/bill`;
    return axiosCommon.post(url, request);
  }

  static cancelInvoice(invoiceId: string): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices/${invoiceId}/cancel`;
    return axiosCommon.post(url);
  }

  static getInvoiceByLongId(longId: string): ApiPromise<InvoiceResponse> {
    const url = `${this.baseUrl}/invoices/${longId}/quick-view`;
    return axiosCommon.get(url);
  }

  static deletePendingInvoice(invoiceId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/invoices/${invoiceId}`;
    return axiosCommon.delete(url);
  }
}
