/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageProps } from "../../../models/PageProps";
import { getGeneralError, hasAuthority } from "../../../util/helperFunctions";
import { Loader } from "../../../components/Loader";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../../components/AlertBox";
import Tabs from "../../../components/Tabs/Tabs";
import { ClinicDetailsResponse } from "../../../models/management/ClinicDetailsResponse";
import ClinicManagementApi from "../../../api/ClinicManagementApi";
import ClinicLogo from "../../../components/Pictures/Clinic/ClinicLogo";
import ClinicBasicInfoTab from "./ClinicBasicInfoTab";
import AnalyticsEventsTable from "../Analytics/AnalyticsEventsTable";
import ClinicBillingTab from "./ClinicBillingTab";
import Dropdown from "react-bootstrap/Dropdown";
import { useClinic } from "../../../contexts/ClinicContext";
import {
  LoggedInUser,
  setRoleOfActiveUser,
  updateLoggedInUser,
} from "../../../util/LocalStorageVariables";
import { SiteManagerAuthority } from "../../../models/management/SiteManagerAuthority";

enum ClinicDetailsTabs {
  basic = "basic",
  events = "events",
  billing = "billing",
}

type Params = {
  clinicId: string;
};

const ClinicDetailsPage: React.FC<PageProps> = (props: PageProps) => {
  const { setPageLoading } = props;
  const { clinicId } = useParams<Params>();
  const { clearClinic, fetchClinic } = useClinic();
  const navigate = useNavigate();

  const [clinic, setClinic] = useState<ClinicDetailsResponse>();
  const [key, setKey] = useState<ClinicDetailsTabs>(ClinicDetailsTabs.basic);
  const [error, setError] = useState<string | null>(null);

  const getClinicDetails = async (id: string) => {
    try {
      setPageLoading(true);
      const resp = await ClinicManagementApi.getClinicDetails(id);
      setClinic(resp.data);
      setError(null);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setPageLoading(false);
    }
  };

  const accessClinic = async () => {
    if (!clinicId) return;
    setPageLoading(true);
    clearClinic();
    setRoleOfActiveUser("doctor");
    updateLoggedInUser((u: LoggedInUser) => ({
      ...u,
      activeClinicId: undefined,
    }));
    await fetchClinic(clinicId);
    navigate("/");
  };

  useEffect(() => {
    if (clinicId) {
      void getClinicDetails(clinicId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicId]);

  if (!clinic) {
    return <></>;
  }

  if (error) {
    return <AlertBox message={error} />;
  }

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div className="flex flex-wrap items-center">
            <div style={{ width: "100px", height: "100px" }}>
              <ClinicLogo
                clinicId={clinic.id}
                logoPictureId={clinic.logoPictureId}
              />
            </div>
            <div className="text-xl font-bold lg:text-2xl text-zinc-800 dark:text-white ml-3">
              {clinic.name}
            </div>
            <div
              className="ml-auto"
              hidden={
                !hasAuthority(SiteManagerAuthority.MANAGER_CLINIC_REMOTE_ACCESS)
              }
            >
              <Dropdown className="rounded-dropdown">
                <Dropdown.Toggle
                  as="div"
                  bsPrefix="toggle-kebab"
                  id="dropdown-pets-more"
                >
                  <span className="material-icons">more_vert</span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="mt-1 p-0">
                  <Dropdown.Item
                    bsPrefix="calendar-menu dropdown-item"
                    as="button"
                    onClick={accessClinic}
                  >
                    {strings.remoteAccess}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <Tabs
            tabs={[
              { title: strings.mainPage, tab: ClinicDetailsTabs.basic },
              { title: strings.activityLogs, tab: ClinicDetailsTabs.events },
              { title: strings.billing, tab: ClinicDetailsTabs.billing },
            ]}
            currentTabKey={key}
            setTab={setKey}
          />

          <AlertBox message={error} />

          {key === ClinicDetailsTabs.basic && (
            <ClinicBasicInfoTab clinic={clinic} />
          )}

          {key === ClinicDetailsTabs.events && (
            <AnalyticsEventsTable clinicId={clinic.id} />
          )}

          {key === ClinicDetailsTabs.billing && (
            <ClinicBillingTab clinic={clinic} />
          )}
        </div>
      </section>
    </main>
  );
};

export default Loader(ClinicDetailsPage);
