/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import fortnoxLogo from "../../../../../assets/Images/fortnox_logo.png";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import Modal from "../../../../../components/Modal/Modal";
import Button from "../../../../../components/Button";
import CloseButton from "../../../../../components/CloseButton";

interface Props {
  clinic: ClinicResponse;
  show: boolean;
  onHide: () => void;
}

const FortnoxIntegrationModal: React.FC<Props> = ({
  clinic,
  show,
  onHide,
}: Props) => (
  <Modal handleClose={onHide} show={show}>
    <Modal.Header title={strings.fortnoxIntegration} />
    <Modal.Body>
      <img width="120px" alt="fortnoxLogo" src={fortnoxLogo} />
      <p>{strings.fortnoxRedirect}</p>
      <AlertBox
        closeAble={false}
        message={strings.fortnoxIntegrationCostWarning}
        type={AlertType.WARNING}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button
        className="modal-main-button"
        onClick={() =>
          window.open(
            `/clinics/${clinic.id}/fortnox-auth`,
            "_blank",
            "noreferrer"
          )
        }
      >
        {strings.continue}
      </Button>
      <CloseButton onClick={onHide} text={strings.cancel} />
    </Modal.Footer>
  </Modal>
);

export default FortnoxIntegrationModal;
