import { createContext, useContext } from "react";
import { UseFormReturn } from "react-hook-form";
import { ReservationAccessScope } from "../../../models/reservation/ReservationAccessScope";
import { MedicalRecordResponse } from "../../../models/medical/MedicalRecordResponse";
import { MedicalRecordFileType } from "../../../models/medical/MedicalRecordFileType";
import { MedicalRecordFileResponse } from "../../../models/medical/MedicalRecordFileResponse";
import { TreatmentResponse } from "../../../models/treatment/TreatmentResponse";
import { MedicalTreatmentResponse } from "../../../models/medical/MedicalTreatmentResponse";
import { NewlyAddedData } from "../MedicalRecordComponent";
import { UsedFeeActions } from "../Reducers/FeeReducer";
import { UsedOtherActions } from "../Reducers/OtherReducer";
import { UsedInventoryItemResponse } from "../../../models/medical/UsedInventoryItemResponse";
import { UsedInventoryItemType } from "../../../models/medical/local/UsedInventoryItemType";
import { AdditionalFieldsProps } from "../AdditionalFields";
import { MedicalRecordVersionResponse } from "../../../models/medical/MedicalRecordVersionResponse";
import { InventoryRelatedTypes } from "../../../models/medical/local/InventoryRelatedTypes";
import { ExtraCost } from "../../../models/medical/ExtraCost";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";

interface MainSectionContextProps {
  // Common
  additionalFieldMethods: UseFormReturn<AdditionalFieldsProps>;

  // State
  resIdS: string | undefined;
  signed: boolean;
  isUserNotOwner: boolean;
  medicalRecord: MedicalRecordResponse | undefined;
  accessScope: ReservationAccessScope;
  weight: number | undefined;
  setWeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  anamnesis: string;
  setAnamnesis: React.Dispatch<React.SetStateAction<string>>;
  physicalExamination: string;
  setPhysicalExamination: React.Dispatch<React.SetStateAction<string>>;
  diagnostics: string;
  setDiagnostics: React.Dispatch<React.SetStateAction<string>>;
  files: MedicalRecordFileResponse[];
  diagnoses: string[];
  deleteDiagnose(v: string): void;
  differentialDiagnoses: string[];
  deleteDifferentialDiagnoses(v: string): void;
  chipNumber?: string;
  setChipNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  tattooNumber?: string;
  setTattooNumber: React.Dispatch<React.SetStateAction<string | undefined>>;
  currentTreatments?: MedicalTreatmentResponse[] | undefined;
  countryDetails?: CountryDetailsResponse;
  error: string | null;
  treatmentText: string;
  setTreatmentText: React.Dispatch<React.SetStateAction<string>>;
  usedFees: Array<ExtraCost>;
  setUsedFees: (value: UsedFeeActions) => void;
  setUsedOthers: (value: UsedOtherActions) => void;
  newlyAdded: NewlyAddedData;
  clinicTreatmentsByPetSpecies: TreatmentResponse[];
  recommendationText: string;
  setRecommendationText: React.Dispatch<React.SetStateAction<string>>;
  medicalRecordVersions: MedicalRecordVersionResponse[];
  setMedicalRecordVersions: React.Dispatch<
    React.SetStateAction<MedicalRecordVersionResponse[]>
  >;

  // Functions
  setFilesModalOpen(t: MedicalRecordFileType): void;
  setDiagnoses(v: string): void;
  setDifferentialDiagnoses(v: string): void;
  triggerChange(value?: string): void;
  handleTreatmentsChange: (input: TreatmentResponse[], treatmentQuantity: number) => void;
  markTreatmentToDelete: (treatmentId: string) => void;
  handleInventoryItemChange: (
    newInventoryItem: UsedInventoryItemResponse,
    type: UsedInventoryItemType
  ) => void;
  refreshReservationInProgress?(): void;
  deleteCurrent(id: string, where: InventoryRelatedTypes): void;
}

export const MainSectionContext = createContext<MainSectionContextProps | null>(
  null
);

export const useMainSectionContext = () => {
  const ctx = useContext(MainSectionContext);

  if (ctx == null) {
    throw new Error("The provided MainSectionContext value is null");
  }

  return ctx;
};
