/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { setLocale } from "../common/Strings/Strings";
import {
  getLocaleForActiveUser,
  getSystemLanguageCode,
} from "../util/LocalStorageVariables";
import { ChildrenProps } from "../models/ChildrenProps";

interface LocaleContext {
  value: string;
  changeLocale: (newValue: string) => void;
  resetLocale: () => void;
}

const ActiveLocale = createContext<LocaleContext | null>(null);

export const useLocale = (): LocaleContext | null => useContext(ActiveLocale);

export const LocaleProvider: React.FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [value, setValue] = useState<string>(
    getLocaleForActiveUser() || getSystemLanguageCode()
  );

  const changeLocale = useCallback((newValue: string) => {
    const locale = getLocaleForActiveUser();

    if (`${locale}` !== newValue) {
      setLocale(newValue);
      setValue(newValue);
    }
  }, []);

  const resetLocale = useCallback(() => {
    const locale = getLocaleForActiveUser();

    if (locale !== null && value !== locale) {
      setLocale(`${locale}`);
      setValue(`${locale}`);
    }
  }, [value]);

  const ctx = useMemo(
    () => ({
      value,
      changeLocale,
      resetLocale,
    }),
    [value, changeLocale, resetLocale]
  );

  return <ActiveLocale.Provider value={ctx}>{children}</ActiveLocale.Provider>;
};
