/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { SocialLoginProvider } from "../../../../models/user/SocialLoginProvider";
import UserApi from "../../../../api/UserApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import { ReCaptcha, RegistrationForm } from "../UserRegistration";
import { PolicyResponse } from "../../../../models/management/PolicyResponse";
import { RegistrationRequest } from "../../../../models/user/RegistrationRequest";
import { findLocale, strings } from "../../../../common/Strings/Strings";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import Button from "../../../../components/Button";
import Params from "../../../../common/Params";
import logger from "../../../../util/logger";
import AlertBox from "../../../../components/AlertBox";
import { Card } from "../../../../components/Cards/Card";

/* Currently not available, codes were part of the legacy registration component */
/* TODO: Implement social registration & login */
const SocialRegistration: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    getValues,
    setValue,
    register,
    formState: { isValid },
  } = useForm<RegistrationForm>({
    mode: "onChange",
  });

  const [socialEmail, setSocialEmail] = useState<string>();
  const [socialProvider, setSocialProvider] = useState<
    SocialLoginProvider | undefined
  >();
  const [socialToken, setSocialToken] = useState<string | undefined>();

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [captchaValid, setCaptchaValid] = useState(false);
  const captchaRef = useRef<ReCaptcha | null>(null);

  /* Will not be needed - policy modals are not used anymore */
  const [submittedRegistrationForm, setSubmittedRegistrationForm] = useState<
    RegistrationForm | undefined
  >();
  const [policyModalShown, setPolicyModalShown] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>("TODO");

  const getSocialData = useCallback(
    async (provider: any, token: any) => {
      setError(null);
      setLoading(true);

      try {
        const response = await UserApi.getSocialUserData(provider, token);
        const { data } = response;
        setSocialToken(token);
        setSocialEmail(data.email);
        setSocialProvider(provider);
        setValue("firstName", data.firstName);
        setValue("middleName", data.middleName);
        setValue("lastName", data.lastName);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    },
    [setValue]
  );

  const submitRegistration = async (f: RegistrationForm, p: PolicyResponse) => {
    setError(null);
    setLoading(true);

    const { countries, prefix, ...restForm } = f;
    const { petOwnerId, petOwnerToken } = qs.parse(location.search);

    const userRequest: RegistrationRequest = {
      ...restForm,
      prefix: prefix || undefined,
      middleName: f.middleName || undefined,
      socialProvider,
      socialToken,
      countryCode: countries[0].countryCode,
      acceptedPolicyLangCode: p.languageCode,
      petOwnerId: petOwnerId as string,
      petOwnerToken: petOwnerToken as string,
    };

    try {
      await UserApi.registerUser(userRequest, recaptchaToken);

      setError(
        f.email === socialEmail
          ? strings.successfulRegistration
          : `${strings.confirmEmailOk + f.email}.`
      );

      if (socialProvider && socialToken && f.email === socialEmail) {
        navigate(`/social-registration-successful`, {
          state: {
            socialProvider,
            socialToken,
            email: restForm.email,
          },
        });
      } else {
        navigate(`/social-registration-successful`, {
          state: { email: restForm.email },
        });
      }
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
      captchaRef.current?.reset();
    }
  };

  const submit = () => {
    setSubmittedRegistrationForm(getValues());
    setPolicyModalShown(true);
  };

  useEffect(() => {
    if (location.state) {
      const { socialProvider: provider, socialToken: token } =
        location.state as any;
      if (provider && token) {
        void getSocialData(provider, token);
      }
    }
  }, [getSocialData, location.state]);

  return (
    <Card title="Social registration (TODO)">
      <form onSubmit={handleSubmit(submit)}>
        <Field
          name="firstName"
          register={register}
          label={strings.firstName}
          required
          showRequired
        />
        <Field
          name="middleName"
          register={register}
          label={strings.middleName}
          optional
        />
        <Field
          name="lastName"
          register={register}
          label={strings.lastName}
          required
          showRequired
        />
        {Params.captcha === "on" && (
          <div className="flex justify-center my-5">
            <ReCAPTCHA
              ref={(e) => {
                captchaRef.current = e;
              }}
              hl={findLocale()}
              sitekey={Params.recaptchaSiteKey}
              size="normal"
              onChange={(t) => {
                if (t === null) {
                  logger.debug("Captcha expired");
                  return;
                }
                setCaptchaValid(true);
                setRecaptchaToken(t);
              }}
            />
          </div>
        )}
        <AlertBox message={error} className="my-5" />
        <Button
          disabled={
            !isValid || (!captchaValid && Params.captcha === "on") || loading
          }
          loading={loading}
          type="submit"
          onClick={submit}
        >
          {strings.createAccount}
        </Button>
      </form>
      <div className="grid grid-cols-2 gap-4 my-5">
        <div className="flex justify-start">
          <span className="text-left text-sm font-medium text-gray-500 dark:text-gray-400">
            {strings.alreadyHaveAccount}
          </span>
        </div>
        <div className="flex justify-end">
          <Button
            className="text-sm"
            onClick={() => navigate("/login")}
            variant="link"
          >
            {strings.signInHere}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default SocialRegistration;
