/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { ListGroupItem } from "react-bootstrap";
import moment from "moment";
import { VetProfessionDocumentResponse } from "../../../models/vet/VetProfessionResponse";
import { DocumentsDownloadButton } from "../../UserManagement/VetProfessionDocument/DocumentsDownloadButton";
import { dateAndTime } from "../../../common/Strings/Strings";

interface Props {
  document: VetProfessionDocumentResponse;
  onError(e: any): void;
}

const VetProfessionDocListItem: React.FC<Props> = ({
  document,
  onError,
}: Props) => (
  <ListGroupItem className="flex justify-between items-center">
    <div>
      {document.fileName}
      <p className="small text-muted">
        {moment(document.dateTime).format(dateAndTime.momentDateTimeFormat)}
      </p>
    </div>
    <DocumentsDownloadButton vetDocument={document} onError={onError} />
  </ListGroupItem>
);

export default VetProfessionDocListItem;
