/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ClinicApi from "../api/ClinicApi";
import { ClinicResponse } from "../models/clinic/ClinicResponse";
import { canAccessClinic, getGeneralError } from "../util/helperFunctions";
import {
  getActiveUserId,
  getValueOfActiveUser,
  LoggedInUser,
  updateLoggedInUser,
  UserRole,
} from "../util/LocalStorageVariables";
import { UserClinicWithRolesResponse } from "../models/clinic/UserClinicWithRolesResponse";
import { ChildrenProps } from "../models/ChildrenProps";
import { ClinicFeature } from "../models/clinic/ClinicFeature";

interface IClinicContext {
  clearClinic: () => void;
  clinics: UserClinicWithRolesResponse[];
  clinic: ClinicResponse | null;
  setClinic: (c: ClinicResponse | null) => any;
  fetchClinic: (id: string) => any;
  fetchClinicError: string | null;
  fetchClinicsError: string | null;
  fetchClinics: () => any;
  clinicLoading: boolean;
  clinicsLoading: boolean;
  isFeatureAvailable: (feature: ClinicFeature) => boolean;
}

const ClinicContext = createContext<IClinicContext | null>(null);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const useClinic = (): IClinicContext => useContext(ClinicContext)!;

export const ClinicProvider: React.FC<ChildrenProps> = ({
  children,
}: ChildrenProps) => {
  const [clinic, setClinic] = useState<ClinicResponse | null>(null);
  const [clinics, setClinics] = useState<UserClinicWithRolesResponse[]>([]);
  const [clinicLoading, setClinicLoading] = useState<boolean>(false);
  const [clinicsLoading, setClinicsLoading] = useState<boolean>(false);
  const [errorFetchingClinic, setErrorFetchingClinic] = useState<string | null>(
    null
  );
  const [errorFetchingClinics, setErrorFetchingClinics] = useState<
    string | null
  >(null);

  const userId: string | null = getActiveUserId();
  const activeUserActiveClinicId = getValueOfActiveUser("activeClinicId");
  const role: UserRole | null = getValueOfActiveUser("role");

  const clearClinic = () => {
    setClinic(null);
    setClinics([]);
  };

  const updateClinic = useCallback((newClinic: ClinicResponse | null) => {
    setClinic(newClinic);
    updateLoggedInUser((u: LoggedInUser) => ({
      ...u,
      activeClinicId: newClinic ? newClinic.id : undefined,
    }));
  }, []);

  const fetchClinic = useCallback(
    async (id: string) => {
      try {
        setClinicLoading(true);
        const { data } = await ClinicApi.getClinic(id);
        updateClinic(canAccessClinic(data) ? data : null);
      } catch (e) {
        setErrorFetchingClinic(await getGeneralError(e));
        updateClinic(null);
      } finally {
        setClinicLoading(false);
      }
    },
    [updateClinic]
  );

  const fetchClinics = async () => {
    try {
      setClinicsLoading(true);
      const response = await ClinicApi.getEmployeeClinics();
      setClinics(response.data);
    } catch (err) {
      setErrorFetchingClinics(await getGeneralError(err));
    } finally {
      setClinicsLoading(false);
    }
  };

  useEffect(() => {
    if (activeUserActiveClinicId) {
      void fetchClinic(activeUserActiveClinicId);
    }
    if (role === "doctor") {
      void fetchClinics();
    }
  }, [activeUserActiveClinicId, fetchClinic, role, userId]);

  const isFeatureAvailable = useCallback(
    (feature: ClinicFeature): boolean => {
      const features = clinic?.availableFeatures || [];
      return features.includes(feature) || features.includes(ClinicFeature.ALL);
    },
    [clinic?.availableFeatures]
  );

  const ctx = useMemo(
    () => ({
      clinic,
      clinics,
      clearClinic,
      setClinic: updateClinic,
      fetchClinic,
      fetchClinics,
      fetchClinicError: errorFetchingClinic,
      fetchClinicsError: errorFetchingClinics,
      clinicLoading,
      clinicsLoading,
      isFeatureAvailable,
    }),
    [
      clinic,
      clinicLoading,
      clinics,
      clinicsLoading,
      errorFetchingClinic,
      errorFetchingClinics,
      fetchClinic,
      isFeatureAvailable,
      updateClinic,
    ]
  );

  return (
    <ClinicContext.Provider value={ctx}>{children}</ClinicContext.Provider>
  );
};
