/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export enum PetSpecies {
  ALPACA = "ALPACA",
  ASS = "ASS",
  BIRD = "BIRD",
  CAMEL = "CAMEL",
  CAT = "CAT",
  CHICKEN = "CHICKEN",
  CHINCHILLA = "CHINCHILLA",
  CHINCHILLA_LIVESTOCK = "CHINCHILLA_LIVESTOCK",
  DEER = "DEER",
  DEGU = "DEGU",
  DOG = "DOG",
  DONKEY = "DONKEY",
  DUCK = "DUCK",
  FERRET = "FERRET",
  FISH_FARMED = "FISH_FARMED",
  FISH_WILD = "FISH_WILD",
  FUR_ANIMAL = "FUR_ANIMAL",
  GOAT = "GOAT",
  GOOSE = "GOOSE",
  GUINEA_PIG = "GUINEA_PIG",
  HAMSTER = "HAMSTER",
  HORSE = "HORSE",
  INSECT = "INSECT",
  MINK = "MINK",
  MOOSE = "MOOSE",
  MOUSE = "MOUSE",
  MUSSEL_FARMED = "MUSSEL_FARMED",
  OSTRICH = "OSTRICH",
  OTHER_LIVESTOCK = "OTHER_LIVESTOCK",
  OTHER_PRODUCTION = "OTHER_PRODUCTION",
  OTHER_FENCED_GAME = "OTHER_FENCED_GAME",
  OTHER_GAME = "OTHER_GAME",
  OTHER = "OTHER",
  OTHER_POULTRY = "OTHER_POULTRY",
  PIG = "PIG",
  PIG_LIVESTOCK = "PIG_LIVESTOCK",
  RABBIT = "RABBIT",
  RABBIT_LIVESTOCK = "RABBIT_LIVESTOCK",
  RAT = "RAT",
  REINDEER = "REINDEER",
  REPTILE = "REPTILE",
  SHEEP = "SHEEP",
  TURKEY = "TURKEY",
  WILD_BOAR = "WILD_BOAR",
  ZOO_ANIMAL = "ZOO_ANIMAL",
}
