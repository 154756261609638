/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { VetProfessionDocumentResponse } from "../../../models/vet/VetProfessionResponse";
import { downloadVetProfessionDocument } from "../../../util/FileUtils";
import { getGeneralError } from "../../../util/helperFunctions";
import Button from "../../../components/Button";
import { ArrowDownTray } from "../../../common/Icons/ArrowDownTray";

interface Props {
  onError(e: any): void;
  vetDocument: VetProfessionDocumentResponse;
  disabled?: boolean;
}

export const DocumentsDownloadButton: React.FC<Props> = ({
  onError,
  vetDocument,
  disabled,
}) => {
  const [downloading, setDownloading] = useState(false);

  const onDownload = async () => {
    setDownloading(true);
    try {
      await downloadVetProfessionDocument(vetDocument);
    } catch (e) {
      const error = await getGeneralError(e);
      onError(error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Button
      className="mr-2"
      disabled={disabled}
      loading={downloading}
      onClick={onDownload}
      type="button"
      variant="dark"
    >
      <ArrowDownTray className="h-5 w-5" />
    </Button>
  );
};
