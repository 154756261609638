/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { dateAndTime } from "../../../common/Strings/Strings";
import { ClipboardDocumentList as ClipboardDocumentListIcon } from "../../../common/Icons/ClipboardDocumentList";

interface Props {
  history: any[];
  icon: string;
  listFor: string;
}

export default function CustomHistoryList({
  listFor,
  icon,
  history,
}: Props): ReactElement {
  const [historyList, setHistoryList] = useState(history);

  useEffect(() => setHistoryList(history), [history]);

  return (
    <div className="tw-card shadow divide-y divide-gray-200 dark:divide-gray-700">
      <div className="p-4 flex justify-between items-center space-x-3">
        <p className="font-semibold">{listFor}</p>
        <div className="relative rounded-full bg-sky-50 w-10 h-10 flex-shrink-0 flex justify-center items-center dark:bg-sky-50">
          <span className="material-symbols-outlined">{icon}</span>
        </div>
      </div>
      <div className="p-4 flex flex-col space-y-4">
        {historyList.map((event, index) => (
          <div className="flex items-center space-x-2" key={index}>
            <ClipboardDocumentListIcon />

            <Link
              to={`/medical-record/${event.medicalRecordId}/latest`}
              className="text-sm text-inherit"
              rel="noopener noreferrer"
              target="_blank"
            >
              <b>{event.name}</b>{" "}
              {moment(event.date).format(dateAndTime.momentDateFormat)}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
