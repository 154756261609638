/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useNavigate } from "react-router-dom";
import { strings } from "../../../Strings/Strings";
import { useUser } from "../../../../contexts/UserContext";
import Button from "../../../../components/Button";

const mutePosteriorRegistrationNotificationForUser = (userId: string) => {
  localStorage.setItem(
    `mutePosteriorRegistrationNotification:${userId}`,
    "true"
  );
};

export const isPosteriorRegistrationNotificationMutedForUser = (
  userId: string
) => !!localStorage.getItem(`mutePosteriorRegistrationNotification:${userId}`);

interface Props {
  refresh: () => void;
}

const OwnerPosteriorRegistrationNotification = ({ refresh }: Props) => {
  const { user } = useUser();
  const navigate = useNavigate();

  const mute = () => {
    mutePosteriorRegistrationNotificationForUser(user.userId);
    refresh();
  };

  const goToPosteriorRegistration = () => {
    navigate("/pending-pets");
  };

  return (
    <div className="flex flex-col">
      <p className="text-sm text-left">
        {strings.checkClinicRegistrationsNotificationMsg}
      </p>
      <div className="flex mt-5">
        <Button onClick={mute} variant="link">
          <p className="text-sm text-slate-500 dark:text-slate-300">
            {strings.mute}
          </p>
        </Button>
        <Button
          className="ml-auto text-sm"
          onClick={goToPosteriorRegistration}
          variant="link"
        >
          {strings.resolveClinicRegsBtnTitle}
        </Button>
      </div>
    </div>
  );
};

export default OwnerPosteriorRegistrationNotification;
