/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import UrlTab from "../../../components/Tabs/UrlTab";
import { strings } from "../../../common/Strings/Strings";
import { SettingsTabs } from "../../../models/user/SettingsTabs";

interface Props {
  currentTab: SettingsTabs;
}

const ProfileSettingsMenu: React.FC<Props> = ({ currentTab }: Props) => (
  <ul className="flex flex-wrap -mb-px">
    <UrlTab
      useBaseUrlOnly
      currentTabKey={currentTab}
      tabKey={SettingsTabs.basicInfo}
      title={strings.basicInfo}
    />
    <UrlTab
      currentTabKey={currentTab}
      tabKey={SettingsTabs.contacts}
      title={strings.contacts}
    />
    <UrlTab
      currentTabKey={currentTab}
      tabKey={SettingsTabs.security}
      title={strings.security}
    />
    <UrlTab
      currentTabKey={currentTab}
      tabKey={SettingsTabs.vet}
      title={strings.vet}
    />
    <UrlTab
      currentTabKey={currentTab}
      tabKey={SettingsTabs.notifications}
      title={strings.notifications}
    />
    <UrlTab
      currentTabKey={currentTab}
      tabKey={SettingsTabs.linkedAccounts}
      title={strings.linkedAccounts}
      visible={false}
    />
  </ul>
);

export default ProfileSettingsMenu;
