/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import UpdateStockForm from "../../Stock/UpdateStockForm";
import Button from "../../../../components/Button";
import { CreateInventoryItemForm } from "../InventoryItemForm";

interface Props {
  countryDetails?: CountryDetailsResponse;
  isEdit: boolean;
  next(isSkipped: boolean): void;
  prev(): void;
}

const InventoryItemFirstStockStep: React.FC<Props> = ({
  countryDetails,
  isEdit,
  next,
  prev,
}: Props) => {
  const { watch, trigger } =
    useFormContext<CreateInventoryItemForm>();

  const [name, acquisitionUnit] = watch(["name", "acquisitionUnit"]);

  return (
    <>
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
        {strings.firstStock}
      </h2>
      <UpdateStockForm
        countryDetails={countryDetails}
        inventoryItem={name ? { name, acquisitionUnit } : undefined}
        isPositive={false}
        isRequired={!isEdit}
      />
      <Button
        onClick={() => {
          const response = trigger([
            "expirationDate",
            "acquisitionVat",
            "acquisitionPrice",
            "quantity",
          ]);

          response.then((isValid: boolean) => {
            if (isValid) {
              next(false);
            }
          });
        }}
        variant="primary"
      >
        {strings.saveAndContinue}
      </Button>
      <div className="flex">
        <div>
          <Button className="text-sm" onClick={prev} variant="link">
            {strings.back}
          </Button>
        </div>
        <div className="ml-auto">
          <Button
            className="text-sm"
            onClick={() => {
              next(true);
            }}
            variant="link"
          >
            {strings.skip}
          </Button>
        </div>
      </div>
    </>
  );
};

export default InventoryItemFirstStockStep;
