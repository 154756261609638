/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import { SupportTopics } from "../Topics";
import AlertBox, { AlertType } from "../../../components/AlertBox";

interface Props {
  isPreview?: boolean;
}

const SzamlazzIntegrationSteps: React.FC<Props> = ({ isPreview }) => {
  return (
    <div className="col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3 flex flex-col justify-between tw-card p-4 space-y-4 ">
      {isPreview ? (
        <Link
          className="tw-link-tercier"
          to={`/help/topics/${SupportTopics.szamlazz}`}
        >
          {strings.szamlazzIntegration}
        </Link>
      ) : (
        <div className="font-bold">{strings.szamlazzIntegration}</div>
      )}
      <div className="space-y-4">
        <div className={isPreview ? "line-clamp-2" : ""}>
          {strings.formatString(
            strings.szamlazzIntegrationInfo1,
            <Link
              target="_blank"
              to="https://www.szamlazz.hu/egyedi-megoldasok/szamla-agent/"
            >
              {" "}
              {strings.here}
            </Link>
          )}
        </div>
        <div className="space-y-4" hidden={isPreview}>
          <AlertBox
            closeAble={false}
            type={AlertType.INFO}
            message={
              <>
                {strings.szamlazzIntegrationCostWarning}
                <div>
                  <Link
                    target="_blank"
                    to="https://tudastar.szamlazz.hu/gyik/szamla-agent-automatikus-szamlazas-koltsege"
                  >
                    {strings.costsOfSzamlazz}
                  </Link>
                </div>
              </>
            }
          />
          <p>{strings.szamlazzIntegrationSupportContent}</p>
          <ul>
            <li>
              1.{" "}
              <a
                href="https://www.szamlazz.hu/szamla/regisztracio"
                target="_blank"
                rel="noopener noreferrer"
              >
                {strings.szamlazzIntegrationSupportStep1}
              </a>
            </li>
            <li>2. {strings.szamlazzIntegrationSupportStep2}</li>
            <li>
              3.{" "}
              <a
                href="/clinic-settings/bank "
                target="_blank"
                rel="noopener noreferrer"
              >
                {strings.szamlazzIntegrationSupportStep3}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SzamlazzIntegrationSteps;
