/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import CombinedSelectComponent, {
  CombinedSelectComponentProps,
} from "../../../InputFieldsSimple/CombinedSelectComponent";

export interface CombinedSelectFieldProps {
  control: Control<any>;
  name: string;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  onChange?(e: any): void;
}

const CombinedSelect = ({
  control,
  name: n,
  options,
  fieldOptions,
  onChange: customOnChange,
  required,
  ...rest
}: CombinedSelectFieldProps & CombinedSelectComponentProps): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  return (
    <Controller
      name={n}
      control={control}
      rules={registerOptions}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <CombinedSelectComponent
          name={n}
          error={error}
          onChange={(e) => {
            onChange(e);

            if (customOnChange) {
              customOnChange(e);
            }
          }}
          value={value}
          options={options}
          {...rest}
          required={required}
        />
      )}
    />
  );
};

export default CombinedSelect;
