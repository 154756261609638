/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import ReCAPTCHA from "react-google-recaptcha";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import { findLocale, strings } from "../../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import SelectCountry from "../../../../components/ReactHookFormFields/Specific/SelectCountry";
import { CountryResponse } from "../../../../models/management/CountryResponse";
import CountryApi from "../../../../api/CountryApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import { PersonDetailsResponse } from "../../../../models/contact/PersonDetailsResponse";
import UserApi from "../../../../api/UserApi";
import logger from "../../../../util/logger";
import Button from "../../../../components/Button";
import { RegistrationRequest } from "../../../../models/user/RegistrationRequest";
import Params from "../../../../common/Params";
import { PolicyType } from "../../../../models/management/PolicyType";
import { ReCaptcha } from "../UserRegistration";
import AlertBox from "../../../../components/AlertBox";
import CheckBox from "../../../../components/ReactHookFormFields/General/CheckBox";
import { insertReactElement } from "../../../../util/HtmlUtils";
import { PolicyResponse } from "../../../../models/management/PolicyResponse";
import GDPRApi from "../../../../api/GDPRApi";

interface Props {
  email?: string;
  onNext(): void;
  prefilledEmail?: string | null;
}

interface RegistrationForm {
  email: string;
  password?: string;
  confirmPassword?: string;
  countries: CountryResponse[];
  /* The names are optional at the registration process
   * Can be prefilled by invitation or given later at the profile settings while the user onboarding process */
  prefix?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  GDPRConsentAccepted?: boolean;
}

const ChoosePassword: React.FC<Props> = ({ email, onNext, prefilledEmail }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [prefilledRegistration, setPrefilledRegistration] =
    useState<PersonDetailsResponse>();

  const [countryOptions, setCountryOptions] = useState<CountryResponse[]>([]);
  const defaultCountryCode = prefilledRegistration?.addresses[0]?.countryCode;
  const defaultCountry = countryOptions.find(
    (c) => c.countryCode === defaultCountryCode
  );

  const [policy, setPolicy] = useState<PolicyResponse>();

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [captchaValid, setCaptchaValid] = useState(false);
  const captchaRef = useRef<ReCaptcha | null>(null);

  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState(false);

  const {
    control,
    setValue,
    register,
    getValues,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid },
  } = useForm<RegistrationForm>({ mode: "onChange" });

  useEffect(() => {
    setValue("email", email ?? "");
  }, [email, setValue]);

  const getCountries = useCallback(async () => {
    try {
      const resp = await CountryApi.getCountries();
      const countries = resp.data.filter((i) =>
        i.availablePolicies.includes(PolicyType.GDPR_CONSENT)
      );
      setCountryOptions(countries);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  }, [setCountryOptions, setError]);

  const countries = watch("countries");
  const countryCode = countries ? countries[0]?.countryCode : undefined;

  useEffect(() => {
    if (!countryCode) {
      return;
    }

    const getPolicy = async () => {
      setLoading(true);

      try {
        const response = await GDPRApi.getPolicy(
          PolicyType.GDPR_CONSENT,
          countryCode
        );
        setPolicy(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void getPolicy();
  }, [countryCode]);

  const fetchPrefilledRegistration = useCallback(
    async (petOwnerId: string, petOwnerToken: string) => {
      try {
        const resp = await UserApi.getPetOwnerDataForRegistration(
          petOwnerId,
          petOwnerToken
        );
        setPrefilledRegistration(resp.data);
        setValue("prefix", resp.data.prefix);
        setValue("firstName", resp.data.firstName);
        setValue("middleName", resp.data.middleName);
        setValue("lastName", resp.data.lastName);
        const firstEmail = resp.data.emails[0]?.value;
        if (firstEmail) {
          setValue("email", firstEmail);
        }
      } catch (e) {
        // If the token is invalid or expired, nothing to do.
        logger.error(e);
      }
    },
    [setPrefilledRegistration, setValue]
  );

  useEffect(() => {
    void getCountries();
    const { petOwnerId, petOwnerToken } = qs.parse(location.search);
    if (petOwnerId && petOwnerToken) {
      void fetchPrefilledRegistration(
        petOwnerId as string,
        petOwnerToken as string
      );
    }
  }, [fetchPrefilledRegistration, getCountries, location.search]);

  const submit = async (formValues: RegistrationForm) => {
    setLoading(true);
    setError(null);

    const { countries, prefix, ...restForm } = formValues;
    const { petOwnerId, petOwnerToken } = qs.parse(location.search);
    const userRequest: RegistrationRequest = {
      ...restForm,
      prefix: prefix || undefined,
      middleName: formValues.middleName || undefined,
      countryCode: countries[0].countryCode,
      acceptedPolicyLangCode: policy?.languageCode || "",
      petOwnerId: petOwnerId as string,
      petOwnerToken: petOwnerToken as string,
    };

    try {
      await UserApi.registerUser(userRequest, recaptchaToken);
      onNext();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
      captchaRef.current?.reset();
    }
  };

  const gdprConsentLink = (
    <Button
      className="text-sm"
      onClick={() =>
        window.open(
          countryCode ? `/gdpr-consent/${countryCode}` : "/gdpr-consent/",
          "_blank",
          "noreferrer"
        )
      }
      variant="link"
    >
      {strings.gdprAgreementGdpr}
    </Button>
  );

  return (
    <div className="my-5">
      <form onSubmit={handleSubmit(submit)} className="space-y-6">
        <Field
          autoComplete={AutoCompleteOptions.email}
          error={errors.email}
          label={strings.yourEmail}
          name="email"
          placeholder={strings.emailExampleName}
          readOnly={!!prefilledEmail}
          register={register}
          required
          showRequired
          type="email"
        />
        <Field
          autoComplete={AutoCompleteOptions.newPassword}
          error={errors.password}
          fieldOptions={{
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
              message: strings.passwordValid,
            },
          }}
          label={strings.password}
          name="password"
          placeholder={strings.password}
          register={register}
          required
          showRequired
          type="password"
        />
        <Field
          autoComplete={AutoCompleteOptions.newPassword}
          error={errors.confirmPassword}
          fieldOptions={{
            validate: {
              confirmPasswordIsSameAsPassword: (v) =>
                v.toString() === getValues("password") || strings.confirmValid,
            },
          }}
          label={strings.confirmPassword}
          name="confirmPassword"
          placeholder={strings.confirmPassword}
          register={register}
          required
          showRequired
          type="password"
        />
        <SelectCountry
          control={control}
          defaultCountry={defaultCountry}
          label={strings.countryOfResidence}
          name="countries"
          options={countryOptions}
          required
          showRequired
        />
        {Params.captcha === "on" && (
          <div className="flex justify-center my-5">
            <ReCAPTCHA
              ref={(e) => {
                captchaRef.current = e;
              }}
              hl={findLocale()}
              sitekey={Params.recaptchaSiteKey}
              size="normal"
              onChange={(t) => {
                if (t === null) {
                  logger.debug("Captcha expired");
                  return;
                }
                void trigger();
                setCaptchaValid(true);
                setRecaptchaToken(t);
              }}
            />
          </div>
        )}
        <div className="flex items-center text-sm">
          <CheckBox
            fieldOptions={{
              validate: { isChecked: (v) => !!v },
            }}
            name="GDPRConsentAccepted"
            register={register}
            required
          />
          {insertReactElement(strings.gdprAgreement, gdprConsentLink)}
        </div>
        <AlertBox message={error} />
        <Button
          disabled={
            !isValid || (!captchaValid && Params.captcha === "on") || loading
          }
          loading={loading}
          type="submit"
        >
          {strings.createAccount}
        </Button>
      </form>
      <div className="grid grid-cols-2 gap-4 my-5">
        <div className="flex justify-start">
          <span className="text-left text-sm font-medium text-gray-500 dark:text-gray-400">
            {strings.alreadyHaveAccount}
          </span>
        </div>
        <div className="flex justify-end">
          <Button
            className="text-sm"
            onClick={() => navigate("/login")}
            variant="link"
          >
            {strings.signInHere}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChoosePassword;
