/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { PhoneResponse } from "../../../../../models/contact/PhoneResponse";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { strings } from "../../../../../common/Strings/Strings";
import { ContactOwner } from "../Emails/EmailOwnershipList";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import { ApiPromise } from "../../../../../models/ApiPromise";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal/Modal";
import { ContactType } from "../../../../../models/contact/ContactType";

interface Props {
  phone?: PhoneResponse;
  onHide: () => void;
  owner: ContactOwner;
  removePhone: (
    ownerId: string,
    ownershipId: string
  ) => ApiPromise<ContactOwner>;
  setPhones: (phones: PhoneResponse[]) => void;
  show: boolean;
}

const DeletePhoneModal: React.FC<Props> = ({
  phone,
  onHide,
  owner,
  removePhone,
  setPhones,
  show,
}: Props) => {
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect((): void => {
    setError(null);
  }, [show]);

  const deletePhone = async (phone: PhoneResponse) => {
    setLoading(true);

    try {
      const response = await removePhone(owner.id, phone.id);
      setPhones(response.data.phones);
      setError(null);
      setLoading(false);
      onHide();
    } catch (err) {
      setError(await getGeneralError(err));
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.deletePhoneNumber} />
      <Modal.Body>
        {phone?.contactType === ContactType.PRIMARY ? (
          <AlertBox
            closeAble={false}
            message={strings.primaryContactNotDeletable}
            type={AlertType.WARNING}
          />
        ) : (
          <p>
            {strings.deletePhoneNumberConfirmation}
            <br />
            <strong>{phone?.value}</strong>
          </p>
        )}
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading}
          fullWidth={false}
          hidden={phone?.contactType === ContactType.PRIMARY}
          loading={loading}
          onClick={() => {
            if (phone) {
              void deletePhone(phone);
            }
          }}
          variant="danger"
        >
          {strings.deletePhoneNumber}
        </Button>
        <Button
          className="modal-sub-button"
          disabled={loading}
          onClick={onHide}
          variant="secondary"
        >
          {strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePhoneModal;
