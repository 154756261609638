/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../util/helperFunctions";
import UserApi from "../../../../api/UserApi";
import { useUser } from "../../../../contexts/UserContext";
import { UserResponse } from "../../../../models/user/UserResponse";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import Switch from "../../../../components/ReactHookFormFields/General/Switch";
import Button from "../../../../components/Button";
import { Card } from "../../../../components/Cards/Card";

interface NotificationSettingsForm {
  receiveSMSNotifications: boolean;
}

const defaultFormValues = (user: UserResponse) => ({
  receiveSMSNotifications: user.receiveSMSNotifications ?? false,
});

const NotificationsTab: React.FC = () => {
  const { user } = useUser();

  const [alertMessage, setAlertMessage] = useState<string | null>();
  const [alertType, setAlertType] = useState<AlertType>(AlertType.ERROR);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<NotificationSettingsForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(user),
  });

  const updateNotificationSettings = async (
    formData: NotificationSettingsForm
  ) => {
    setLoading(true);
    try {
      const request = {
        receiveSMSNotifications: formData.receiveSMSNotifications,
      };
      const response = await UserApi.updateNotificationSettings(request);
      reset(defaultFormValues(response.data));
      setAlertMessage(strings.savedSuccessfully);
      setAlertType(AlertType.SUCCESS);
    } catch (e) {
      setAlertMessage(await getGeneralError(e));
      setAlertType(AlertType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card size="md" type="simple" title={strings.notifications}>
      <form
        className="space-y-6"
        onSubmit={handleSubmit(updateNotificationSettings)}
      >
        <div className="flex">
          <div>{strings.receiveSMSNotifications}</div>
          <div className="ml-auto">
            <Switch
              control={control}
              name="receiveSMSNotifications"
              value={user.receiveSMSNotifications}
              error={errors.receiveSMSNotifications}
            />
          </div>
        </div>
        <AlertBox message={alertMessage} type={alertType} />
        <div>
          <Button
            disabled={!isValid || !isDirty}
            loading={loading}
            type="submit"
          >
            {strings.save}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default NotificationsTab;
