/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const prescriptionMethodsHu = {
  AURI: "Fülön",
  BIDI: "Csőrön",
  BU: "Fogra",
  CUTN: "Bőrön",
  DENT: "Fogászati használatra",
  DIPP: "Fürdetéssel",
  DW: "Ivóvízben",
  GAEN: "Gasztroenterálisan",
  GING: "Ínyen",
  IH: "Inhalálással",
  INAC: "Intraartikulárisan",
  INBU: "Intraburzálisan",
  INCD: "Intrakardiálisan",
  INDE: "Intradermálisan",
  INFE: "Takarmányban",
  MOUTH: "Szájon át",
  NASA: "Nazálisan",
  OCUL: "Szemészeti használatra",
  ORAL: "Orális használatra",
  ORMU: "Szájüregben",
  PDON: "Parodontális használatra",
  PO: "Szájonként",
  POON: "Felöntéssel",
  PR: "Végbélbe",
  RECT: "Rektálisan",
  SL: "Nyelv alatt",
  SMUC: "Nyálkahártya alatt",
  SPON: "Spot-on",
  SUCO: "Kötőhártya-zsákban",
  SUCU: "Szubkután használatra",
  TEAT: "Emlőkön",
  TO: "Helyileg",
  TODR: "Takarmányba szórva",
  VAGI: "Vaginálisan",
  WABO: "Vízzel történő kezeléshez"
};