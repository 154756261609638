/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../../../../common/Strings/Strings";
import { CountryResponse } from "../../../../../../models/management/CountryResponse";
import SelectCountry from "../../../../../../components/ReactHookFormFields/Specific/SelectCountry";
import Field from "../../../../../../components/ReactHookFormFields/General/Field";

export interface VetRegistrationNumberProps {
  vetRegistrationNumber: string;
  countries: CountryResponse[];
}

const VetRegistrationNumberForm = (): ReactElement => {
  const {
    control,
    register,
    getValues,
    formState: { errors },
    setValue,
  } = useFormContext<VetRegistrationNumberProps>();

  return (
    <form className="space-y-4">
      <SelectCountry
        control={control}
        name="countries"
        required
        setValue={setValue}
      />
      <Field
        error={errors.vetRegistrationNumber}
        fieldOptions={{
          validate: {
            swedishPattern: (v: string) => {
              const country: CountryResponse[] | undefined =
                getValues("countries");

              if (country?.length > 0 && country[0].countryCode === "SE") {
                return /^\d{4}$/.test(v);
              }
              return true;
            },
          },
        }}
        name="vetRegistrationNumber"
        label={strings.registrationNumber}
        register={register}
        required
      />
    </form>
  );
};

export default VetRegistrationNumberForm;
