/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { petTypes, strings } from "../../../common/Strings/Strings";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { generateOptions } from "../../../util/helperFunctions";
import { AnimalType } from "../../../models/ePrescription/sending/AnimalType";
import {
  animalSpeciesConverterToCode,
  animalSpeciesOptions,
} from "../../../common/AnimalSpecies/SpeciesFunctions";
import { AnimalSpecies } from "../../../common/AnimalSpecies/AnimalSpecies";
import StatusSignal from "../StatusSignal";
import { prescriptionRegexp } from "../../../util/Validations";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { useSpecies } from "../../../contexts/SpeciesContext";
import Field from "../../../components/ReactHookFormFields/General/Field";
import Button from "../../../components/Button";
import { XMark } from "../../../common/Icons/XMark";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import PetInfo from "../../Todo/PetInfo";
import { ClinicPetResponse } from "../../../models/pet/ClinicPetResponse";

interface Props {
  pet?: ClinicPetResponse;
  petId?: string;
  petWeight?: number;
}

export interface AnimalForm {
  additionalAnimalIds: Array<{ value: string }> /* required "format" */;
  animalId: string;
  animalIdType: AnimalType;
  internalAnimalId: string;
  speciesCode: string;
}

const AnimalSection: React.FC<Props> = ({ pet, petId, petWeight }: Props) => {
  const {
    control,
    register,
    setValue,
    formState: { errors, isValid },
    trigger,
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "additionalAnimalIds",
  });

  const { speciesCodesAndNames } = useSpecies();

  useEffect(() => {
    if (pet) {
      setValue("animalId", pet.clinicPetDetails.name, { shouldValidate: true });
    }

    if (speciesCodesAndNames && pet) {
      setValue(
        "speciesCode",
        animalSpeciesConverterToCode(
          speciesCodesAndNames,
          pet.clinicPetDetails.species
        )
      );
    } else if (speciesCodesAndNames) {
      setValue("speciesCode", "51");
    }

    void trigger();
  }, [speciesCodesAndNames, pet, setValue, trigger]);

  return (
    <div className="tw-card mr-0 lg:mr-3 space-y-4 p-4">
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
        {strings.pet}
      </h2>
      <form className={pet ? "hidden" : ""}>
        <div className="flex flex-wrap sm:flex-nowrap space-y-4 md:space-y-0">
          <div className="space-y-4 min-w-48 mr-4">
            <Field
              autoComplete={AutoCompleteOptions.off}
              error={errors.animalId}
              fieldOptions={{
                pattern: {
                  value: prescriptionRegexp,
                  message: strings.notAllowedCharacter,
                },
              }}
              label={strings.name}
              name="animalId"
              placeholder={strings.name}
              readOnly={!!petId}
              register={register}
              required
              showRequired
            />
            {fields.map((item, index) => (
              <Fragment key={item.id}>
                <div className="flex items-center space-x-3">
                  <Field
                    autoComplete={AutoCompleteOptions.off}
                    error={
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      /* @ts-ignore */
                      errors?.additionalAnimalIds?.[index]?.value
                    }
                    fieldOptions={{
                      pattern: {
                        value: prescriptionRegexp,
                        message: strings.notAllowedCharacter,
                      },
                    }}
                    label={strings.name}
                    name={`additionalAnimalIds.${index}.value`}
                    placeholder={strings.name}
                    readOnly={!!petId}
                    register={register}
                  />
                  <Button
                    variant="icon"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <XMark />
                  </Button>
                </div>
              </Fragment>
            ))}
            <div hidden={!!petId}>
              <div className="flex">
                <Button variant="link" onClick={() => append({ value: "" })}>
                  <PlusIcon /> {strings.addNewName}
                </Button>
              </div>
            </div>
          </div>
          <div className="flex items-start flex-nowrap md:flex-no-wrap space-x-4">
            <Select
              autoComplete={AutoCompleteOptions.off}
              isChooseOption={false}
              label={strings.species}
              name="speciesCode"
              options={
                animalSpeciesOptions(speciesCodesAndNames) ||
                generateOptions(AnimalSpecies, "species", "code")
              }
              readOnly={!!petId}
              register={register}
              required
              showRequired
            />
            <Select
              autoComplete={AutoCompleteOptions.off}
              isChooseOption={false}
              label={strings.animalType}
              name="animalIdType"
              options={[
                {
                  title: petTypes[AnimalType.GROUP],
                  value: AnimalType.GROUP,
                },
                {
                  title: petTypes[AnimalType.INDIVIDUAL],
                  value: AnimalType.INDIVIDUAL,
                },
                {
                  title: petTypes[AnimalType.STOCK],
                  value: AnimalType.STOCK,
                },
              ]}
              readOnly={!!petId}
              register={register}
              required
              showRequired
            />
          </div>
        </div>
      </form>
      {pet ? <PetInfo pet={pet} weight={petWeight} /> : <></>}
    </div>
  );
};

export default AnimalSection;

AnimalSection.defaultProps = {
  pet: undefined,
  petId: undefined,
  petWeight: undefined,
};
