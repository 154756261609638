/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import jwtDecode from "jwt-decode";
import axios, { AxiosResponse } from "axios";
import formUrlEncoded from "form-urlencoded";
import ITokenResponse from "js-pkce/dist/ITokenResponse";
import Urls from "./Urls";
import { setLocale, strings } from "../common/Strings/Strings";
import Params from "../common/Params";
import logger from "./logger";
import {
  clearStorages,
  getAccessToken,
  getMfaRefreshToken,
  getMfaToken,
  getRefreshToken,
  removeMfaToken,
  setAccessToken,
  setActiveUserId,
  setMfaToken,
  setRefreshToken,
  setSkipMfaTokenForEmail,
} from "./LocalStorageVariables";
import { OAuth2AccessToken } from "../models/login/OAuth2AccessToken";
import AuthApi from "../api/AuthApi";
import Analytics from "./Analytics";

const axiosCommon = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": strings.getLanguage(),
  },
});

const axiosRefresh = axios.create({
  headers: {
    Accept: "application/json",
  },
});

const mfaBody = (code: string): any => ({
  grant_type: "mfa",
  mfa_token: getMfaToken(),
  mfa_code: code,
});

let tokenRefreshPromise: Promise<void> | undefined;

const setUpAuthToken = (
  body: ITokenResponse | OAuth2AccessToken,
  ignoreLocale = false
): any => {
  setAccessToken(body.access_token);
  setRefreshToken(body.refresh_token);

  const token = jwtDecode(body.access_token);
  if (token.sub) {
    setActiveUserId(token.sub);
    Analytics.setUser(token.sub);
  }

  if (token.locale && !ignoreLocale) {
    setLocale(token.locale);
  }

  return token;
};

const refreshAccessToken = (): Promise<void> => {
  if (!tokenRefreshPromise) {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      throw new Error("Missing refresh token from local storage!");
    }
    tokenRefreshPromise = AuthApi.refreshAccessToken(refreshToken).then(
      (resp) => {
        setUpAuthToken(resp.data, true);
        setTimeout(() => {
          tokenRefreshPromise = undefined;
        }, 5000);
      }
    );
  }
  return tokenRefreshPromise;
};

const refreshMfaToken = async (): Promise<void> => {
  const refreshToken = getMfaRefreshToken();
  if (!refreshToken) {
    throw new Error("Missing refresh token from local storage!");
  }
  const resp = await AuthApi.refreshAccessToken(refreshToken);
  setMfaToken(resp.data.access_token);
};

const doSecondFactor = async (
  email: string,
  code: string,
  onSuccess: (result: AxiosResponse) => any,
  onError: (error: any) => any
): Promise<any> => {
  const url = `${Params.authBaseURL}${Params.tokenEndpoint}`;
  const data = formUrlEncoded(mfaBody(code), { ignorenull: true });

  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      auth: {
        username: Urls.CLIENT_NAME,
        password: Urls.CLIENT_SECRET,
      },
      maxRedirects: 0,
    });
    removeMfaToken();
    setSkipMfaTokenForEmail(email, response.data.skipMfaToken);
    onSuccess(response);
  } catch (e) {
    const error: any = e;
    if (error?.response?.status === 401) {
      logger.info("Token expired");
      try {
        await refreshMfaToken();
        await doSecondFactor(email, code, onSuccess, onError);
      } catch (e1) {
        onError(e1);
      }
    } else {
      onError(e);
    }
  }
};

const doLogoutOnAuthServer = async () => {
  try {
    await AuthApi.logoutOnServer();
  } catch (e) {
    const err: any = e;
    logger.error(
      `${err?.response?.status} (${err?.response?.statusText}): ${err.response?.data?.message}`,
      err?.response
    );
  }
};

// Clears tokens in local and session storages
// Set the 'invalidateSession' parameter to true to also clear the user session
const doLogout = async (invalidateSession = false) => {
  // Clear the local and session storage while preserving some variables
  clearStorages();
  Analytics.setUser(undefined);

  // Clears session on the back-end (thus invalidating the JSESSIONID cookie)
  // Navigates to the auth server login page
  if (invalidateSession) {
    await doLogoutOnAuthServer();
  }
};

const initializeNetwork = (): void => {
  axiosCommon.interceptors.request.use(
    (request) => {
      const accessToken = getAccessToken();
      if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`;
      logger.info(`Starting request: ${request?.method} ${request?.url}`);
      logger.info(request);
      logger.debug(request);
      return request;
    },
    (error) => {
      logger.error("Request error:", error?.response);
      return Promise.reject(error);
    }
  );

  axiosCommon.interceptors.response.use(
    (response) => {
      logger.info("Response:", response);
      return response;
    },
    async (error) => {
      if (error?.response?.status === 401) {
        logger.info("Token expired");
        try {
          await refreshAccessToken();
          return axiosCommon.request(error.config);
        } catch (e) {
          logger.error("Token refresh failed");
          const err: any = e;
          logger.error(
            `${err?.response?.status} (${err?.response?.statusText}): ${err.response?.data?.message}`,
            err?.response
          );
          if (err && !err?.response?.status) {
            logger.error(err);
          }
          await doLogout();
          window.location.replace(`${Params.frontendBaseURL}/login`);
        }
      }
      logger.error(
        `${error?.response?.status} (${error?.response?.statusText}): ${error.response?.data?.message}`,
        error?.response
      );
      return Promise.reject(error);
    }
  );

  axiosRefresh.interceptors.request.use(
    (request) => {
      logger.debug("Refreshing access token", request);
      return request;
    },
    (error) => {
      logger.error(
        "Request error when refreshing access token:",
        error?.response
      );
      return Promise.reject(error);
    }
  );

  axiosRefresh.interceptors.response.use(
    (response) => {
      logger.debug("Access token refreshed:", response);
      return response;
    },
    (error) => {
      logger.error(
        "Response error when refreshing access token:",
        error?.response
      );
      return Promise.reject(error);
    }
  );
};

export {
  axiosCommon,
  axiosRefresh,
  doLogout,
  doSecondFactor,
  initializeNetwork,
  refreshAccessToken,
  setUpAuthToken,
};
