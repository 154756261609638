/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import SimpleInput from "../../../../components/InputFieldsSimple/SimpleInput";

interface Props {
  name: string;
  label: string;
  signed: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  triggerChange(v: string): void;
}

const SimpleInputTemplate: React.FC<Props> = ({
  name,
  label,
  signed,
  value,
  setValue,
  triggerChange,
}) => {
  return (
    <>
      {signed && value && (
        <div className="flex flex-col">
          <label>{label}</label>
          <p className="whitespace-pre-line">
            <strong>{value}</strong>
          </p>
        </div>
      )}
      {!signed && (
        <SimpleInput
          name={name}
          autoComplete={AutoCompleteOptions.off}
          label={label}
          onChange={(v: string) => {
            if(v.length === 0){
              setValue(undefined);
            } else {
              setValue(v);
            }
            triggerChange(v);
          }}
          readOnly={signed}
          value={value}
        />
      )}
    </>
  );
};

export default SimpleInputTemplate;
