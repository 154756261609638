/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import AlertBox from "../../../components/AlertBox";
import { getGeneralError } from "../../../util/helperFunctions";
import ClinicDiscountApi from "../../../api/ClinicDiscountApi";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";
import Field from "../../../components/ReactHookFormFields/General/Field";
import moment from "moment/moment";
import { TrialDiscountResponse } from "../../../models/discount/TrialDiscountResponse";
import { TrialDiscountUpdateRequest } from "../../../models/discount/TrialDiscountUpdateRequest";

interface Props {
  getDiscounts: () => void;
  onHide: () => void;
  selectedTrialDiscount?: TrialDiscountResponse;
  show: boolean;
}

interface EditTrialDiscountForm {
  trialDiscountPercent: number;
  validityStart: string;
  validityEnd: string;
}

const defaultFormValues = (
  selectedTrialDiscount?: TrialDiscountResponse
): EditTrialDiscountForm => ({
  trialDiscountPercent: selectedTrialDiscount?.trialDiscountPercent || 0,
  validityStart: moment(
    selectedTrialDiscount?.validityStart ?? new Date()
  ).format("YYYY-MM-DD"),
  validityEnd: moment(selectedTrialDiscount?.validityEnd ?? new Date()).format(
    "YYYY-MM-DD"
  ),
});

const EditTrialDiscountModal: React.FC<Props> = ({
  getDiscounts,
  onHide,
  selectedTrialDiscount,
  show,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const saveDiscount = async (formData: EditTrialDiscountForm) => {
    if (formData === undefined || selectedTrialDiscount === undefined) return;

    setLoading(true);

    const request: TrialDiscountUpdateRequest = {
      trialDiscountPercent: formData.trialDiscountPercent,
      validityStart: formData.validityStart,
      validityEnd: formData.validityEnd,
    };

    try {
      await ClinicDiscountApi.changeTrialDiscount(
        selectedTrialDiscount.id,
        request
      );
      onHide();
      getDiscounts();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<EditTrialDiscountForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(selectedTrialDiscount),
  });

  useLayoutEffect(() => {
    reset(defaultFormValues(selectedTrialDiscount));
    setError(null);
  }, [reset, selectedTrialDiscount, show]);

  return (
    <>
      <Modal show={show} handleClose={onHide}>
        <Modal.Header closeButton title={strings.discounts}></Modal.Header>
        <Modal.Body>
          <form>
            <div className="space-y-5">
              <Field
                register={register}
                type="date"
                label={strings.startDate}
                name="validityStart"
                error={errors.validityStart}
              />
              <Field
                register={register}
                type="date"
                label={strings.endDate}
                name="validityEnd"
                error={errors.validityEnd}
              />
              <Field
                register={register}
                type="number"
                label={strings.trialDiscountPercent}
                name="trialDiscountPercent"
                error={errors.trialDiscountPercent}
              />
            </div>
            <AlertBox message={error} className="my-3" />
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={!isValid || loading || !isDirty}
            fullWidth={false}
            loading={loading}
            onClick={handleSubmit(saveDiscount)}
            variant="primary"
          >
            {strings.save}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditTrialDiscountModal;
