/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";
import { autoUpdate, useFloating } from "@floating-ui/react";
import UserDropdownMenuButton from "./UserDropdownMenuButton";
import ChangeUserModal from "./Modals/ChangeUserModal/ChangeUserModal";
import ChooseLanguageModal from "./Modals/ChooseLanguageModal";
import UserDropdownMenuMobile from "./UserDropdownMenuMobile";
import UserDropdownMenuItems from "./UserDropdownMenuItems";
import { useModal } from "../../../contexts/ModalContext";
import ClickOutsideDetector from "../../../components/ClickOutsideDetector";
import { TailwindResponsiveBreakpoints } from "../../../util/TailwindResponsiveBreakpoints";

const UserDropdownMenu: React.FC = () => {
  const { openModalIds } = useModal();

  const buttonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const userDropdownButton = document.getElementById(
      "user-dropdown-menu-button"
    ) as HTMLDivElement;
    if (userDropdownButton) {
      buttonRef.current = userDropdownButton;
    }
  }, []);

  const [isDropdownMenuOpen, setDropdownMenuOpen] = useState<boolean>(false);

  const [showSwitchAccountModal, setShowSwitchAccountModal] =
    useState<boolean>(false);
  const handleShowSwitchAccountModal = () => setShowSwitchAccountModal(true);
  const handleCloseSwitchAccountModal = () => setShowSwitchAccountModal(false);

  const [showChooseLanguageModal, setShowChooseLanguageModal] =
    useState<boolean>(false);
  const handleShowChooseLanguageModal = () => setShowChooseLanguageModal(true);
  const handleCloseChooseLanguageModal = () =>
    setShowChooseLanguageModal(false);

  const handleClickOutside = () => {
    if (!isDropdownMenuOpen) return;

    const isMobile = window.innerWidth < TailwindResponsiveBreakpoints.xl;
    if (isMobile) return;

    if (openModalIds.length !== 0) {
      if (
        openModalIds.length === 1 &&
        openModalIds[0] === "userDropdownMenuMobile"
      ) {
        setDropdownMenuOpen(false);
      }
    } else {
      setDropdownMenuOpen(false);
    }
  };

  const handleMobileMenuOnHide = () => {
    // Prevent the click outside event (close menu) to occur when the mobile menu is hidden
    const isMobile = window.innerWidth < TailwindResponsiveBreakpoints.xl;
    if (!isMobile) return;

    // Prevent the click outside event (close menu) to occur when of the secondary modals is open on top of the primary modal
    if (
      openModalIds.includes("changeUserModal") ||
      openModalIds.includes("chooseLanguageModal")
    ) {
      return;
    }

    setDropdownMenuOpen(false);
  };

  const { refs, floatingStyles } = useFloating({
    placement: "bottom-end",
    whileElementsMounted: autoUpdate,
  });

  return (
    <>
      {/* Menu button */}
      <div id="user-dropdown-menu-button" ref={refs.setReference}>
        <UserDropdownMenuButton
          onClick={() => setDropdownMenuOpen((prevState) => !prevState)}
        />
      </div>
      {/* Desktop menu */}
      {isDropdownMenuOpen && (
        <div className="hidden xl:flex" id="user-dropdown-menu-desktop">
          <ClickOutsideDetector
            listen
            ignore={buttonRef.current}
            onClickOutside={handleClickOutside}
          >
            <div
              className="dropdownNavbarUser z-30 shadow-md rounded-lg w-screen lg:w-96 font-normal bg-white dark:bg-gray-700 divide-y divide-gray-100 dark:divide-gray-600 top-2"
              id="user-dropdown"
              ref={refs.setFloating}
              style={floatingStyles}
            >
              <UserDropdownMenuItems
                itemType="dropdown-item"
                onMenuItemClick={() => setDropdownMenuOpen(false)}
                showChooseLanguageModal={handleShowChooseLanguageModal}
                showSwitchAccountModal={handleShowSwitchAccountModal}
              />
            </div>
          </ClickOutsideDetector>
        </div>
      )}
      {/* Mobile menu */}
      <div className="flex xl:hidden" id="user-dropdown-menu-mobile">
        <UserDropdownMenuMobile
          open={isDropdownMenuOpen}
          onHide={handleMobileMenuOnHide}
          onMenuItemClick={() => setDropdownMenuOpen(false)}
          showChooseLanguageModal={handleShowChooseLanguageModal}
          showSwitchAccountModal={handleShowSwitchAccountModal}
        />
      </div>
      <ChangeUserModal
        showModal={showSwitchAccountModal}
        closeModal={handleCloseSwitchAccountModal}
      />
      <ChooseLanguageModal
        showModal={showChooseLanguageModal}
        closeModal={handleCloseChooseLanguageModal}
      />
    </>
  );
};

export default UserDropdownMenu;
