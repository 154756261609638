/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { InvoiceResponse } from "../../../models/invoice/InvoiceResponse";
import { strings } from "../../../common/Strings/Strings";
import BeforeDeleteModal from "../../../components/Modals/BeforeDeleteModal";
import InvoiceApi from "../../../api/InvoiceApi";
import { getGeneralError } from "../../../util/helperFunctions";

interface Props {
  close(): void;
  invoice: InvoiceResponse;
  isOpen: boolean;
  reload(): void;
}

const WithdrawInvoice = ({
  invoice,
  isOpen,
  close,
  reload,
}: Props): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const cancelInvoice = async () => {
    setLoading(true);
    setError(null);

    try {
      await InvoiceApi.cancelInvoice(invoice.id);
      reload();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <BeforeDeleteModal
      content={
        error ||
        `${strings.warningYouAreAboutToWithdrawAnInvoice} ${strings.areYouSure}`
      }
      loading={loading}
      onHide={() => {
        close();
      }}
      open={isOpen}
      returnYes={cancelInvoice}
      title={strings.withdrawInvoice}
    />
  );
};

export default WithdrawInvoice;
