/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import moment from "moment";
import { strings } from "../../../../common/Strings/Strings";
import { ClinicEmploymentType } from "../../../../models/employment/ClinicEmploymentType";
import { BasicUserResponse } from "../../../../models/user/BasicUserResponse";
import ReservationsForm from "./ReservationsForm";
import Button from "../../../../components/Button";
import { localDateFormat } from "../../../../util/helperFunctions";
import Modal from "../../../../components/Modal/Modal";

interface Props {
  close(): void;
  deleteEmployee?: boolean;
  employee?: BasicUserResponse;
  employeeType?: ClinicEmploymentType;
  end?: string;
  open: boolean;
  reload(): void;
  start?: string;
}

const SubstitutionModal: React.FC<Props> = ({
  open,
  reload,
  close,
  deleteEmployee,
  employee,
  employeeType,
  start = moment().format(localDateFormat()),
  end = moment().add(100, "years").format(localDateFormat()),
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Modal handleClose={close} show={open}>
      <Modal.Header title={strings.substitutionTitle} />
      <Modal.Body>
        <ReservationsForm
          deleteEmployee={deleteEmployee}
          employeeType={employeeType}
          end={end}
          onHide={close}
          reload={reload}
          setLoading={setLoading}
          employeeUserId={employee?.userId}
          start={start}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading}
          form="reservationsForm"
          loading={loading}
          type="submit"
        >
          {strings.finish}
        </Button>
        <Button
          className="modal-sub-button"
          onClick={close}
          variant="secondary"
        >
          {strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubstitutionModal;
