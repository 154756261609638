/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { PageProps } from "../../../models/PageProps";
import { Loader } from "../../../components/Loader";
import { useClinic } from "../../../contexts/ClinicContext";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { strings } from "../../../common/Strings/Strings";
import UrlTab from "../../../components/Tabs/UrlTab";
import InventoryItemDetails from "./InventoryItemDetails";
import LocationIndicator from "../../../components/Tabs/LocationIndicator";
import StockHistory from "./StockHistory";
import { InventoryItemDetailsResponse } from "../../../models/inventory/item/Response/InventoryItemDetailsResponse";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import CountryApi from "../../../api/CountryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import UsageHistory from "./UsageHistory";
import CountingHistory from "./CountingHistory";
import ItemQuantityHistory from "./ItemQuantityHistory";
import InventoryItemAnalytics from "./InventoryItemAnalytics";

export type InventoryItemDetailsKeys =
  | "info"
  | "stock-history"
  | "usage-history"
  | "counting-history";

const InventoryItemDetailsPage: React.FC<PageProps> = ({setPageLoading}: PageProps) => {
  const [inventoryItem, setInventoryItem] =
    useState<InventoryItemDetailsResponse>();
  const [key, setKey] = useState<InventoryItemDetailsKeys>("info");
  const [error, setError] = useState<string | null>(null);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [reloadItem, setReloadItem] = useState<boolean>(false);

  const { clinic } = useClinic();
  const { itemId } = useParams();

  useEffect(() => {
    const getCountryDetails = async () => {
      if (clinic?.id) {
        try {
          const response = await CountryApi.getCountryDetailsOfClinic(
            clinic.id
          );
          setCountryDetails(response.data);
        } catch (e) {
          setError(await getGeneralError(e));
        }
      }
    };

    void getCountryDetails();
  }, [clinic]);

  useEffect(() => {
    const getInventoryItemDetails = async () => {
      if (!clinic?.id || !itemId) return;
      try {
        const response = await InventoryItemApi.getItem(itemId);
        setInventoryItem(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    };

    void getInventoryItemDetails();
  }, [clinic, itemId, reloadItem, setPageLoading]);

  const triggerReload = () => {
    setPageLoading(true);
    setReloadItem((prev) => !prev);
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div>
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.inventoryItemDetails}
            </h1>
          </div>
          <div className="border border-gray-200 rounded-md bg-gray-50 !p-4 text-center text-md font-medium text-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300">
            <ul className="flex flex-wrap -mb-px">
              <UrlTab
                useBaseUrlOnly
                currentTabKey={key}
                tabKey="info"
                title={strings.details}
              />
              <div className="xl:hidden">
                <UrlTab
                  currentTabKey={key}
                  tabKey="history"
                  title={strings.history}
                />
              </div>
              <div className="hidden xl:block">
                <UrlTab
                  currentTabKey={key}
                  tabKey="stock-history"
                  title={strings.orderHistory}
                />
              </div>
              <div className="hidden xl:block">
                <UrlTab
                  currentTabKey={key}
                  tabKey="usage-history"
                  title={strings.usageHistory}
                />
              </div>
              <div className="hidden xl:block">
                <UrlTab
                  currentTabKey={key}
                  tabKey="counting-history"
                  title={strings.countingHistory}
                />
              </div>
              <UrlTab
                currentTabKey={key}
                tabKey="analytics"
                title={strings.analytics}
              />
            </ul>
          </div>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <LocationIndicator
                    location="info"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <InventoryItemDetails countryDetails={countryDetails} inventoryItem={inventoryItem} />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="history"
              element={
                <>
                  <LocationIndicator
                    location="history"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <ItemQuantityHistory
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                        reloadItem={triggerReload}
                      />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="stock-history"
              element={
                <>
                  <LocationIndicator
                    location="stock-history"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <StockHistory
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                        reloadItem={triggerReload}
                      />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="usage-history"
              element={
                <>
                  <LocationIndicator
                    location="usage-history"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <UsageHistory
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                      />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="counting-history"
              element={
                <>
                  <LocationIndicator
                    location="counting-history"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    <AlertBox message={error} />
                    {inventoryItem && (
                      <CountingHistory
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                        reloadItem={triggerReload}
                      />
                    )}
                  </div>
                </>
              }
            />
            <Route
              path="analytics"
              element={
                <>
                  <LocationIndicator
                    location="analytics"
                    setLocation={(location: InventoryItemDetailsKeys) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    {inventoryItem && (
                      <InventoryItemAnalytics
                        countryDetails={countryDetails}
                        inventoryItem={inventoryItem}
                      />
                    )}
                  </div>
                </>
              }
            />
          </Routes>
        </div>
      </section>
    </main>
  );
};

export default Loader(InventoryItemDetailsPage);
