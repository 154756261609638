/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../common/Strings/Strings";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";

interface Props {
  isOpen: boolean;
  close(): void;
}

const NegativeStockModal: React.FC<Props> = ({ isOpen, close }: Props) => (
  <Modal handleClose={close} show={isOpen}>
    <Modal.Header title={strings.warning} />
    <Modal.Body>
      <p className="text-center p-3">{strings.itemsAtNegativeStock}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button className="modal-main-button" onClick={close}>
        {strings.ok}
      </Button>
      <Button className="modal-sub-button" onClick={close} variant="secondary">
        {strings.close}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default NegativeStockModal;
