/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { MedicinalProduct } from "../../../models/ePrescription/VARA/MedicinalProduct";
import RedemptionInputs from "./RepeatDispensingBox";
import { PrescriptionItemFrontend } from "../../../models/ePrescription/local/PrescriptionItemFrontend";
import WaitingPeriodsBox from "./WaitingPeriodsBox";
import { WaitingPeriods } from "../../../models/ePrescription/local/WaitingPeriods";
import LatestDispensingTime from "./LatestDispensingTime";
import { RepeatDispensing } from "../../../models/ePrescription/sending/RepeatDispensing";
import { PackageType } from "../../../models/ePrescription/VARA/PackageType";
import Instructions from "./Instructions";
import PackageSelection from "./PackageSelection";
import hardcodedStrings from "../fixValues/hardcodedStrings";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import OtherSettings, { OtherForm } from "./OtherSettings";
import { Antimicrobial } from "../../../models/ePrescription/sending/Antimicrobial";
import { DosageTexts } from "../../../models/ePrescription/local/DosageTexts";
import { LatestRequestedTimeForDispensing } from "../../../models/ePrescription/local/LatestRequestedTimeForDispensing";
import { IntervalUnitEnum } from "../../../models/ePrescription/sending/IntervalUnitEnum";
import AddToPrescriptionButtonContent from "./AddToPrescriptionButtonContent";
import { Package } from "../../../models/ePrescription/VARA/Package";
import Button from "../../../components/Button";
import ProductInfo from "./ProductInfo";

interface Props {
  medicalProduct?: MedicinalProduct;
  addPrescription(newItem: PrescriptionItemFrontend): void;
  forEdit?: PrescriptionItemFrontend;
  packageTypes: PackageType[];
  productType?: string;
  isVaraLicensed?: boolean;
}

const ProductDetailSection: React.FC<Props> = ({
  medicalProduct,
  addPrescription,
  forEdit,
  packageTypes,
  productType,
  isVaraLicensed,
}: Props) => {
  const [medicine, setMedicine] = useState<MedicinalProduct>();
  const packagesMethods = useForm<{
    selectedPackage: Package[];
    numberOfPackages: number;
  }>({
    mode: "onChange",
    defaultValues: {
      numberOfPackages: 1,
      selectedPackage: [],
    },
  });

  const {
    formState: { isValid: isPackageValid, isDirty: isPackageDirty },
    getValues: getPackage,
    reset: setPackage,
  } = packagesMethods;

  const otherBoxSettingsMethods = useForm<
    LatestRequestedTimeForDispensing & RepeatDispensing & WaitingPeriods
  >({
    mode: "onChange",
    defaultValues: {
      numberOfTimes: 1,
      interval: undefined,
      intervalUnit: IntervalUnitEnum.DAYS,
    },
  });

  const {
    formState: { isValid: isOtherFormValid },
    getValues: getOthers,
    setValue: setOther,
  } = otherBoxSettingsMethods;

  const [otherSettings, setOtherSettings] = useState<OtherForm>();
  const [isOtherSettingsValid, setIsOtherSettingsValid] =
    useState<boolean>(true);

  const defaultUnit =
    medicine &&
    medicine.packages &&
    medicine.packages[0] &&
    medicine.packages[0].quantityNumericUnit
      ? medicine.packages[0].quantityNumericUnit
      : "";

  const instructionMethods = useForm<DosageTexts>({
    mode: "onChange",
    defaultValues: {
      intervals: forEdit?.dosageText?.intervals || [
        {
          dose: undefined,
          unit: defaultUnit.toLowerCase(),
          period: undefined,
          days: undefined,
          daysType: "",
          method: [],
        },
      ],
      freeText: forEdit?.dosageText?.freeText,
      totalAmount: forEdit?.dosageText?.totalAmount,
    },
  });

  const {
    getValues,
    formState: { isValid },
    setValue, 
  } = instructionMethods;

  useEffect(() => {
    if (defaultUnit && !forEdit) {
      setValue(`intervals.${0}.unit`, defaultUnit.toLowerCase());
    }
  }, [defaultUnit, setValue, forEdit]);

  useEffect(() => {
    if (forEdit) {
      setMedicine(forEdit.product);
      setOtherSettings({
        antimicrobial: forEdit.antimicrobial,
        validUntil: forEdit.validUntil,
      });

      setOther("numberOfTimes", forEdit.repeatDispensing?.numberOfTimes || 1);
      setOther("interval", forEdit.repeatDispensing?.interval);
      setOther("intervalUnit", forEdit.repeatDispensing?.intervalUnit);

      setOther("egg", forEdit.waitingPeriods?.egg);
      setOther("honey", forEdit.waitingPeriods?.honey);
      setOther("milk", forEdit.waitingPeriods?.milk);
      setOther("slaughter", forEdit.waitingPeriods?.slaughter);

      setOther(
        "latestRequestedTimeForDispensing",
        forEdit.latestRequestedTimeForDispensing
      );

      setPackage({
        numberOfPackages: forEdit.package.numberOfPackages,
        selectedPackage:
          forEdit.product?.packages?.filter(
            (p: Package) => p.nplPackId === forEdit.package.nplPackId
          ) || [],
      });
    } else if (medicalProduct) {
      setMedicine(medicalProduct);
    }
  }, [forEdit, medicalProduct, setOther, setPackage]);

  const mapPackingType = (packageType?: string): string => {
    if (packageTypes) {
      packageTypes.forEach((pt: PackageType) => {
        if (pt.label === packageType) {
          packageType = pt.description;
        }
      });
    }

    return packageType || "";
  };

  const addToPrescription = () => {
    if (medicine && isPackageValid) {
      const { numberOfPackages, selectedPackage } = getPackage();
      const p: Package = selectedPackage[0];

      const egg = getOthers("egg");
      const honey = getOthers("honey");
      const milk = getOthers("milk");
      const slaughter = getOthers("slaughter");

      const interval = getOthers("interval");

      const newPrescriptionItem: PrescriptionItemFrontend = {
        product: medicine,
        package: {
          nplPackId: p.nplPackId,
          numberOfPackages,
          title: p.title,
          label: p.label,
          manufacturer:
            p && p.displayStakeholder ? p.displayStakeholder.name : "",
          innerPackageType: mapPackingType(p.innerPackageType),
        },
        id: -1,
        dosageText: getValues(),
        repeatDispensing: {
          interval,
          intervalUnit: interval ? getOthers("intervalUnit") : undefined,
          numberOfTimes: getOthers("numberOfTimes"),
        },
        latestRequestedTimeForDispensing: getOthers(
          "latestRequestedTimeForDispensing"
        ),
        waitingPeriods:
          egg || honey || milk || slaughter
            ? {
                egg,
                honey,
                milk,
                slaughter,
              }
            : undefined,
        isVaraLicensed,
      };

      if (forEdit) {
        newPrescriptionItem.id = forEdit.id;
      }

      newPrescriptionItem.antimicrobial =
        otherSettings?.antimicrobial === Antimicrobial.NOTANTIMICROBIAL
          ? undefined
          : otherSettings?.antimicrobial;
      newPrescriptionItem.validUntil = otherSettings?.validUntil;

      addPrescription(newPrescriptionItem);
    }
  };

  const isPrescriptionItemDisabled = () => {
    if(!!forEdit){
      return (
        !isPackageValid || !isValid || !isOtherFormValid || !isOtherSettingsValid
      );
    }
    return (
      !isPackageValid || !isPackageDirty || !isValid || !isOtherFormValid || !isOtherSettingsValid
    );
  };

  return (
    <div>
      {medicine?.nplId === hardcodedStrings.licensedNplId ||
      (medicine?.withdrawalDate && isVaraLicensed) ? (
        <AlertBox
          type={AlertType.WARNING}
          message={`${strings.notPrescribable} ${
            medicine?.withdrawalDate
              ? `${strings.withdrawalDate}: ${moment(
                  medicine?.withdrawalDate
                ).format(dateAndTime.momentDateFormat)}.`
              : ""
          }`}
          className="m-3"
        />
      ) : (
        <div className="space-y-4">
          <ProductInfo medicine={medicine} isVaraLicensed={isVaraLicensed} />
          <div className="space-y-4 divide-y dark:divide-gray-700">
            <div className="space-y-3">
              <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {strings.packages}
              </h2>
              <FormProvider {...packagesMethods}>
                <form>
                  <PackageSelection
                    mapPackingType={mapPackingType}
                    medicalProduct={medicine}
                  />
                </form>
              </FormProvider>
            </div>
            <div className="space-y-3 pt-3">
              <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {strings.dosage}
              </h2>
              <div>
                <FormProvider {...instructionMethods}>
                  <form>
                    <Instructions
                      defaultUnit={defaultUnit}
                      isVaraLicensed={isVaraLicensed}
                      productType={productType}
                    />
                  </form>
                </FormProvider>
              </div>
            </div>
            <div className="py-4 space-y-3">
              <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
                {strings.otherSettings}
              </h2>
              <OtherSettings
                forEdit={{
                  antimicrobial: forEdit?.antimicrobial,
                  validUntil: forEdit?.validUntil,
                }}
                returnIsValid={(valid: boolean) => {
                  setIsOtherSettingsValid(valid);
                }}
                returnValues={(values: OtherForm) => {
                  setOtherSettings(values);
                }}
              />

              <FormProvider {...otherBoxSettingsMethods}>
                <form className="space-y-3">
                  <RedemptionInputs />
                  <LatestDispensingTime />
                  <WaitingPeriodsBox />
                </form>
              </FormProvider>
            </div>
            <Button
              onClick={() => {
                addToPrescription();
              }}
              disabled={isPrescriptionItemDisabled()}
              minW
            >
              {forEdit ? strings.save : <AddToPrescriptionButtonContent />}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetailSection;

ProductDetailSection.defaultProps = {
  medicalProduct: undefined,
  forEdit: undefined,
  productType: undefined,
  isVaraLicensed: undefined,
};
