/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import Button from "../../Button";
import Tag, { TagType } from "../../Tag";

interface Props<T> {
  control: Control<any>;
  defaultFilters: Array<T | "all">;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  multiple?: boolean;
  name: string;
  options: { value: T | "all"; title: string }[];
  title?: string;
}

function Filters<T>({
  control,
  fieldOptions,
  multiple = true,
  name: n,
  options,
  title,
}: Props<T>): ReactElement {
  return (
    <>
      <div className="mb-2">{title}</div>
      <div className="flex flex-wrap gap-3">
        <Controller
          name={n}
          control={control}
          rules={fieldOptions}
          render={({ field: { onChange, value } }) => (
            <>
              {options.map(
                (option: { value: T | "all"; title: string }, index) => (
                  <div key={index}>
                    <Button
                      variant="basic"
                      onClick={() => {
                        if (multiple) {
                          if (option.value === "all") {
                            onChange([option.value]);
                          } else if (!value?.includes(option.value)) {
                            onChange([
                              ...value.filter((f: T | "all") => f !== "all"),
                              option.value,
                            ]);
                          } else {
                            const otherFilters = value?.filter(
                              (f: T | "all") => f !== option.value
                            );

                            onChange(otherFilters);
                          }
                        } else {
                          onChange([option.value]);
                        }
                      }}
                    >
                      <Tag 
                        size="lg" 
                        type={value?.includes(option.value) ? TagType.secondary : TagType.secondaryOutlined} 
                        text={option.title} 
                      />
                    </Button>
                  </div>
                )
              )}
            </>
          )}
        />
      </div>
    </>
  );
}

export default Filters;
