/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../../../common/Strings/Strings";
import InfoTextWithLabel from "../../../../../components/InfoTextWithLabel";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import { Pencil } from "../../../../../common/Icons/Pencil";
import Button from "../../../../../components/Button";
import { canManageClinic } from "../../../../../util/helperFunctions";
import RoundingSettingsModal from "./RoundingSettingsModal";

interface Props {
  clinic: ClinicResponse;
  setClinic: (org: ClinicResponse) => void;
}

const RoundingSettings: React.FC<Props> = ({ clinic, setClinic }: Props) => {
  const [isRoundingSettingsModalVisible, setRoundingSettingsModalVisible] =
    useState<boolean>(false);

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 flex items-center">
          <p className="uppercase">{strings.roundingSettings}</p>
          <div className="ml-auto">
            <Button
              variant="icon"
              onClick={() => setRoundingSettingsModalVisible(true)}
              hidden={!canManageClinic(clinic)}
            >
              <Pencil />
            </Button>
          </div>
        </div>
        <div className="p-4 space-y-4">
          <div>{strings.roundingSettingsTooltip}</div>
          <InfoTextWithLabel
            label={strings.roundingStrategyItem}
            text={getStringFromEnumsOrReturn(clinic.roundingStrategyItem)}
            inputLike
            widthPercent={100}
          />
          <InfoTextWithLabel
            label={strings.roundingStrategyTotal}
            text={getStringFromEnumsOrReturn(clinic.roundingStrategyTotal)}
            inputLike
            widthPercent={100}
          />
          <InfoTextWithLabel
            label={strings.vatCalculationStrategy}
            text={getStringFromEnumsOrReturn(clinic.vatCalculationStrategy)}
            inputLike
            widthPercent={100}
          />
        </div>
        <RoundingSettingsModal
          isOpen={isRoundingSettingsModalVisible}
          close={() => {
            setRoundingSettingsModalVisible(false);
          }}
          refresh={(data: ClinicResponse) => setClinic(data)}
          clinic={clinic}
        />
      </div>
    </>
  );
};

export default RoundingSettings;
