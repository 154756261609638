/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import Params from "../common/Params";
import { VideoChatRoomUsers } from "../models/videoConsultation/VideoChatRoomUsers";
import { VideoChatRooms } from "../models/videoConsultation/VideoChatRooms";
import { ReservationResponse } from "../models/reservation/ReservationResponse";

interface VideoConsultationEndRequest {
  startTime: Date;
  endTime: Date;
}

export default class VideoConsultationApi {
  private static clinicServiceBaseURL = Params.clinicServiceBaseURL;

  private static videoChatSignalServiceBaseURL =
    Params.videoChatSignalServiceBaseURL;

  private static path = Params.videoChatPrefix;

  static getRooms(): ApiPromise<VideoChatRooms> {
    const url = `${this.videoChatSignalServiceBaseURL}${this.path}/rooms`;
    return axiosCommon.get(url);
  }

  static getUsersInRoom(roomId: string): ApiPromise<VideoChatRoomUsers> {
    const url = `${this.videoChatSignalServiceBaseURL}${this.path}/rooms/${roomId}/users`;
    return axiosCommon.get(url);
  }

  static getVideoConsultation(
    consultationId: string
  ): ApiPromise<ReservationResponse> {
    const url = `${this.clinicServiceBaseURL}/video-consultations/${consultationId}`;
    return axiosCommon.get(url);
  }

  static endCallTest(
    durationInMinutes: number,
    consultationId: string
  ): ApiPromise<void> {
    const url = `${this.clinicServiceBaseURL}/video-consultations/${consultationId}/end`;

    const request: VideoConsultationEndRequest = {
      startTime: moment().subtract(durationInMinutes, "minutes").toDate(),
      endTime: moment().toDate(),
    };

    return axiosCommon.post(url, request);
  }
}
