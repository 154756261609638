/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { strings } from "../../common/Strings/Strings";
import { getGeneralError } from "../../util/helperFunctions";
import ClinicEmploymentApi from "../../api/ClinicEmploymentApi";
import { useClinic } from "../../contexts/ClinicContext";
import { useNotification } from "../../contexts/NotificationContext";
import { Card } from "../../components/Cards/Card";
import AlertBox, { AlertType } from "../../components/AlertBox";

const EmploymentConfirmation: React.FC = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { fetchClinics } = useClinic();
  const { fetchNotifications } = useNotification();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [redirectSeconds, setRedirectSeconds] = useState<number>();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

  const location = useLocation();

  const startRedirect = (seconds: number) => {
    setRedirectSeconds(seconds);
    const interval = setInterval(() => {
      setRedirectSeconds((prev) => (prev ? prev - 1 : undefined));
    }, 1000);
    setIntervalId(interval);
  };

  useEffect(() => {
    if (redirectSeconds !== undefined && redirectSeconds <= 0) {
      if (intervalId) {
        setRedirectSeconds(undefined);
        clearInterval(intervalId);
      }
      navigate("/", { replace: true });
    }
    /* eslint-disable-next-line */
  }, [redirectSeconds]);

  const acceptEmployment = async () => {
    try {
      if (location.pathname.includes("clinic-employment") && !!code) {
        await ClinicEmploymentApi.acceptClinicEmploymentRequestByCode(code);
        void fetchClinics();
        void fetchNotifications();
      } else {
        return;
      }

      setError(null);
      startRedirect(5);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void acceptEmployment();
    /* eslint-disable-next-line */
  }, [code, location.pathname]);

  return (
    <Card loading={loading} title={strings.pendingRequest}>
      <AlertBox
        closeAble={false}
        hidden={error !== null}
        message={strings.employmentRequestAccepted}
        type={AlertType.SUCCESS}
      />
      <AlertBox closeAble={false} message={error} />
      <p hidden={!redirectSeconds}>
        {strings.redirectInSeconds?.replace("{1}", redirectSeconds)}
      </p>
    </Card>
  );
};

export default EmploymentConfirmation;
