/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import StripeIntegrationModal from "./StripeIntegrationModal";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import { Pencil } from "../../../../../common/Icons/Pencil";
import Button from "../../../../../components/Button";
import { canManageClinic } from "../../../../../util/helperFunctions";

export type PrevStateSetter = (prevState: ClinicResponse) => ClinicResponse;

interface Props {
  clinic: ClinicResponse;
  setClinic: (clinic: PrevStateSetter) => void;
}

const StripeIntegration: React.FC<Props> = ({ clinic, setClinic }: Props) => {
  const [showStripeModal, setShowStripeModal] = useState<boolean>(false);

  const openModal = () => setShowStripeModal(true);
  const closeModal = () => setShowStripeModal(false);

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 flex items-center">
          <p className="uppercase">{strings.stripeIntegration}</p>
          <div className="ml-auto">
            <Button
              variant="icon"
              onClick={openModal}
              hidden={!canManageClinic(clinic)}
            >
              <Pencil />
            </Button>
          </div>
        </div>
        <div className="p-4 space-y-4">
          <div>{strings.stripeOrgToolTip}</div>
          <AlertBox
            type={clinic.stripeEnabled ? AlertType.SUCCESS : AlertType.INFO}
            message={`${strings.state}: ${
              clinic.stripeEnabled ? strings.integrated : strings.notIntegrated
            }`}
            closeAble={false}
          />
        </div>
        {!clinic.stripeEnabled && canManageClinic(clinic) && (
          <div className="hr-on-top d-flex p-3">
            <Button variant="primary" className="ml-auto" onClick={openModal}>
              {strings.addIntegration}
            </Button>
          </div>
        )}
      </div>
      <StripeIntegrationModal
        clinic={clinic}
        setClinic={(previousState) => setClinic(previousState)}
        show={showStripeModal}
        onHide={closeModal}
      />
    </>
  );
};

export default StripeIntegration;
