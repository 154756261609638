/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { EmployeeResponse } from "../../../../../models/user/EmployeeResponse";
import SubstitutionModal from "../../../../Calendar/Modals/SubstitutionModal/SubstitutionModal";
import { ClinicEmploymentType } from "../../../../../models/employment/ClinicEmploymentType";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../../../common/Strings/Strings";
import { User } from "../../../../../common/Icons/User";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal/Modal";
import UserProfilePicture from "../../../../../components/Pictures/User/UserProfilePicture";

interface Props {
  employee?: EmployeeResponse;
  employeeType?: ClinicEmploymentType;
  onHide: (isSuccess?: boolean) => void;
  reload(): void;
  show: boolean;
}

type ModalState = "areYouSure" | "resolveReservations";

const DeleteCollaboratorModal = ({
  employee,
  employeeType,
  onHide,
  reload,
  show,
}: Props): ReactElement => {
  const [modalState, setModalState] = useState<ModalState>("areYouSure");

  const onClose = () => {
    setModalState("areYouSure");
    onHide();
  };

  if (modalState === "areYouSure") {
    return (
      <Modal handleClose={onClose} show={show}>
        <Modal.Header title={strings.removeEmployee} />
        <Modal.Body>
          <div className="space-y-4">
            <p>{strings.areYouSureToRemoveEmployee}</p>
            <div className="flex items-center space-x-4">
              <div style={{ width: "40px", height: "40px" }}>
                <UserProfilePicture userId={employee?.userId} />
              </div>
              <div className="font-bold">{employee?.fullName}</div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="relative rounded-full bg-sky-50 w-10 h-10 flex-shrink-0 flex justify-center items-center dark:bg-sky-50 dark:text-gray-700">
                <User />
              </div>
              <div className="font-bold">
                {getStringFromEnumsOrReturn(employeeType)}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="modal-main-button"
            onClick={() => {
              setModalState("resolveReservations");
            }}
          >
            {strings.next}
          </Button>
          <Button
            className="modal-sub-button"
            onClick={onClose}
            variant="secondary"
          >
            {strings.cancel}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <SubstitutionModal
      close={onClose}
      deleteEmployee
      employee={employee}
      employeeType={employeeType}
      open={show}
      reload={reload}
    />
  );
};

export default DeleteCollaboratorModal;
