/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import VaraApi from "../../../../api/VaraApi";
import { SearchParams } from "../../../../models/ePrescription/local/SearchParams";
import Paging from "../../../../components/Paging";
import { CommodityProduct } from "../../../../models/ePrescription/VARA/CommodityProduct";
import LoaderInline from "../../../../components/LoaderInline";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import { useDebouncedState } from "../../../../hooks/hooks";
import PageSizeSelect from "../../../../components/PageSizeSelect";
import CommodityResultRow from "./CommodityResultRow";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import logger from "../../../../util/logger";
import { isEnough } from "../SimpleProduct/SearchProduct";
import EmptyListText from "../../../../components/EmptyListText";

interface FormData {
  product: string;
  barcode: string;
}

interface SearchSize {
  count: number;
  pages: number;
  pageNum: number;
  totalPages: number;
}

interface Props {
  selectProduct(c: CommodityProduct): void;
  resetSearch: boolean;
}

const SearchCommodityProduct: React.FC<Props> = ({
  selectProduct,
  resetSearch,
}: Props) => {
  const [searchResult, setSearchResult] = useState<CommodityProduct[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSize, setDataSize] = useState<SearchSize>({
    count: 0,
    pages: 0,
    pageNum: 0,
    totalPages: 1,
  });
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const productSearchParams = useDebouncedState<SearchParams>({}, 600);
  const {
    getValues,
    formState: { errors, isValid },
    register,
    reset,
  } = useForm<FormData>({ mode: "onChange" });

  useEffect(() => {
    setPage(0);
    setDataSize({
      count: 0,
      pages: 0,
      pageNum: 0,
      totalPages: 1,
    });
    productSearchParams.setValue({});
    setSearchResult([]);
    reset();
  }, [resetSearch]);

  useEffect(() => {
    let isMounted = true;

    const search = async ({
      pageNumber,
      pageSize: ps,
      barcodeKeyword,
      productNameKeyword,
    }: SearchParams) => {
      setLoading(true);

      try {
        const response = await VaraApi.searchCommodityProducts({
          pageNumber,
          pageSize: ps,
          productNameKeyword,
          barcodeKeyword,
        });

        if (isMounted) {
          setSearchResult(response.data.items);
          setDataSize({
            count: response.data.count,
            pages: response.data.pageSize,
            pageNum: response.data.pageNum,
            totalPages: response.data.totalPages,
          });
        }
      } catch (err) {
        logger.error(err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (
      (productSearchParams.value.productNameKeyword ||
        productSearchParams.value.barcodeKeyword) &&
      isValid
    ) {
      const request = {
        productNameKeyword: productSearchParams.value.productNameKeyword,
        substanceKeyword: productSearchParams.value.substanceKeyword,
        pageNumber: page,
        pageSize,
      };
      void search(request);
    }

    return () => {
      isMounted = false;
    };
  }, [productSearchParams.value, page, pageSize, isValid]);

  const searchInput = (): void => {
    const { product, barcode } = getValues();
    setSearchResult([]);
    setDataSize({
      count: 0,
      pages: 0,
      pageNum: 0,
      totalPages: 1,
    });

    const searchInputs: SearchParams = {
      productNameKeyword: product,
      barcodeKeyword: barcode,
    };

    productSearchParams.setValue(searchInputs);

    if (page !== 0) {
      setPage(0);
    }
  };

  return (
    <div className="space-y-4 pt-4">
      <form className="space-y-4">
        <div className="flex space-x-4">
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.product}
            fieldOptions={{
              deps: ["barcode"],
              validate: {
                isEnough: (v: string | undefined) =>
                  isEnough(getValues("barcode"), v),
              },
            }}
            label={strings.product}
            name="product"
            onChange={searchInput}
            register={register}
          />
          <Field
            autoComplete={AutoCompleteOptions.off}
            error={errors.barcode}
            fieldOptions={{
              deps: ["product"],
              validate: {
                isEnough: (v: string | undefined) =>
                  isEnough(v, getValues("product")),
              },
            }}
            label={strings.barcode}
            name="barcode"
            onChange={searchInput}
            register={register}
          />
        </div>
      </form>
      {/* MOBILE VIEW */}
      <div className="md:hidden">
        <table className="tw-table">
          <thead>
            <tr>
              <th colSpan={2}>{strings.product}</th>
            </tr>
          </thead>
          {loading ? (
            <></>
          ) : (
            <tbody className="tw-tbody">
              {searchResult.map((medicine: CommodityProduct, index: number) => {
                return (
                  <CommodityResultRow
                    index={index}
                    isMobile
                    key={index}
                    medicine={medicine}
                    selectProduct={selectProduct}
                  />
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      {/* NORMAL VIEW */}
      <div className="hidden md:block">
        <div className="tw-table-container">
          <table className="tw-table">
            <thead className="tw-thead">
              <tr>
                <th className="tw-th">{strings.product}</th>
                <th className="tw-th">{strings.businessCompany}</th>
                <th className="tw-th">{strings.groupCode}</th>
                <th className="tw-th" />
              </tr>
            </thead>
            <tbody className="tw-tbody">
              {loading ? (
                <tr>
                  <td colSpan={5} className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                    <LoaderInline />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {!loading && searchResult.length === 0 ? (
                <tr>
                  <td colSpan={5} className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                    <EmptyListText />
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {searchResult.map((medicine: CommodityProduct, index: number) => {
                return (
                  <CommodityResultRow
                    index={index}
                    key={index}
                    medicine={medicine}
                    selectProduct={selectProduct}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center">
        {loading ? (
          <></>
        ) : (
          <PageSizeSelect
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalPages={dataSize.totalPages}
            totalResults={dataSize.count}
          />
        )}
        <div className="ml-auto">
          <Paging
            currentPage={page}
            totalPages={dataSize.totalPages}
            selectPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchCommodityProduct;
