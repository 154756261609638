/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../../../util/helperFunctions";
import { UserResponse } from "../../../../../../models/user/UserResponse";
import { VetRegistrationNumberResponse } from "../../../../../../models/vet/VetRegistrationNumberResponse";
import VetApi from "../../../../../../api/VetApi";
import BeforeDeleteModal from "../../../../../../components/Modals/BeforeDeleteModal";
import VetRegistrationNumberModal from "./VetRegistrationNumberModal";
import AlertBox from "../../../../../../components/AlertBox";
import { PlusIcon } from "../../../../../../common/Icons/PlusIcon";
import Button from "../../../../../../components/Button";
import EmptyListText from "../../../../../../components/EmptyListText";
import { Trash } from "../../../../../../common/Icons/Trash";

interface Props {
  user: UserResponse;
}

const VetRegistrationNumberList: React.FC<Props> = ({ user }: Props) => {
  const [vetRegistrationNumbers, setVetRegistrationNumbers] = useState<
    VetRegistrationNumberResponse[]
  >(user.vetRegistrationNumbers);

  const [deletionError, setDeletionError] = useState<string | null>(null);
  const [deletionLoading, setDeletionLoading] = useState<boolean>(false);

  const [registrationNumberToDelete, setRegistrationNumberToDelete] =
    useState<VetRegistrationNumberResponse>();

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const openAddModal = (): void => {
    setShowAddModal(true);
    setDeletionError(null);
  };

  const closeAddModal = (): void => {
    setShowAddModal(false);
  };

  const deleteRegistrationNumber = async (
    vetRegNumber: VetRegistrationNumberResponse
  ) => {
    setDeletionLoading(true);
    setDeletionError(null);

    try {
      const response = await VetApi.removeVetRegistrationNumber(
        vetRegNumber.id
      );
      setVetRegistrationNumbers(response.data.vetRegistrationNumbers);
    } catch (err) {
      setDeletionError(await getGeneralError(err));
    } finally {
      setDeletionLoading(false);
    }
  };

  return (
    <>
      <Button variant="link" onClick={openAddModal}>
        <div className="flex">
          <PlusIcon /> <span>{strings.addRegistrationNumber}</span>
        </div>
      </Button>
      <AlertBox message={deletionError} />
      <div className="tw-table-container">
        <table className="tw-table" id="vetRegistrationNumbers">
          <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
            <tr>
              <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                {strings.registrationNumber}
              </th>
              <th className="py-3.5 px-4 text-sm font-normal text-right rtl:text-right text-gray-500 dark:text-gray-400">
                {strings.operations}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
            {vetRegistrationNumbers.length === 0 ? (
              <tr>
                <td
                  className="px-4 py-3 text-sm font-medium whitespace-nowrap"
                  colSpan={2}
                >
                  <EmptyListText text={strings.vetRegNumbersEmpty} />
                </td>
              </tr>
            ) : (
              <>
                {vetRegistrationNumbers?.map((vetRegistrationNumber) => (
                  <tr key={vetRegistrationNumber.id}>
                    <td className="px-4 py-3 text-sm font-medium">
                      {`${vetRegistrationNumber.registrationNumber} (${vetRegistrationNumber.countryCode}) `}
                    </td>
                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap text-right">
                      <Button
                        aria-label="Remove"
                        disabled={deletionLoading}
                        loading={
                          deletionLoading &&
                          registrationNumberToDelete?.id ===
                            vetRegistrationNumber.id
                        }
                        showTextWhileLoading={false}
                        onClick={() => {
                          setShowDeleteModal(true);
                          setRegistrationNumberToDelete(vetRegistrationNumber);
                        }}
                        variant="icon"
                      >
                        <Trash />
                      </Button>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      <Button variant="link" onClick={openAddModal}>
        <div className="flex">
          <PlusIcon /> <span>{strings.addRegistrationNumber}</span>
        </div>
      </Button>
      <VetRegistrationNumberModal
        close={closeAddModal}
        isOpen={showAddModal}
        onSuccess={(response: UserResponse) => {
          setVetRegistrationNumbers(response.vetRegistrationNumbers);
          closeAddModal();
        }}
      />
      <BeforeDeleteModal
        open={showDeleteModal}
        returnYes={() => {
          if (registrationNumberToDelete) {
            void deleteRegistrationNumber(registrationNumberToDelete);
          }
          setShowDeleteModal(false);
        }}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        content={strings.vetRegNumberDeleteConfirmation}
      />
    </>
  );
};

export default VetRegistrationNumberList;
