/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const petSpeciesHu = {
  ALPACA: "Alpaka",
  ASS: "Szamár",
  BIRD: "Madár",
  CAMEL: "Teve",
  CAT: "Macska",
  CHICKEN: "Csirke",
  CHINCHILLA: "Csincsilla",
  CHINCHILLA_LIVESTOCK: "Csincsilla (állomány)",
  DEER: "Szarvas",
  DEGU: "Degu",
  DOG: "Kutya",
  DONKEY: "Szamár",
  DUCK: "Kacsa",
  FERRET: "Görény",
  FISH_FARMED: "Hal (tenyésztett)",
  FISH_WILD: "Hal (vad)",
  FUR_ANIMAL: "Szőréért tartott állat",
  GOAT: "Kecske",
  GOOSE: "Liba",
  GUINEA_PIG: "Tengeri malac",
  HAMSTER: "Hörcsög",
  HORSE: "Ló",
  INSECT: "Rovar",
  MINK: "Nyérc",
  MOOSE: "Jávorszarvas",
  MOUSE: "Egér",
  MUSSEL_FARMED: "Éti kagyló",
  OSTRICH: "Strucc",
  OTHER_LIVESTOCK: "Egyéb állatállomány",
  OTHER_PRODUCTION: "Egyéb haszonállat",
  OTHER_FENCED_GAME: "Bekerített vad",
  OTHER_GAME: "Egyéb vad",
  OTHER: "Egyéb háziállat",
  OTHER_POULTRY: "Szárnyas",
  PIG: "Disznó",
  PIG_LIVESTOCK: "Disznó állomány",
  POLECAT: "Görény",
  RABBIT: "Nyúl",
  RABBIT_LIVESTOCK: "Nyúl állomány",
  RAT: "Patkány",
  REINDEER: "Rénszarvas",
  REPTILE: "Hüllő",
  SHEEP: "Juh",
  TURKEY: "Pulyka",
  WILD_BOAR: "Vaddisznó",
  ZOO_ANIMAL: "Állatkerti állat"
};