/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import { ClinicResponse } from "../../../../../models/clinic/ClinicResponse";
import { ApiPromise } from "../../../../../models/ApiPromise";
import { EmailRequest } from "../../../../../models/contact/EmailRequest";
import { ContactType } from "../../../../../models/contact/ContactType";
import { EmailResponse } from "../../../../../models/contact/EmailResponse";
import ResendEmailConfirmationModal from "./ResendEmailConfirmationModal";
import AddEmailModal from "./AddEmailModal";
import PromoteEmailModal from "./PromoteEmailModal";
import { Pencil } from "../../../../../common/Icons/Pencil";
import { Trash } from "../../../../../common/Icons/Trash";
import Button from "../../../../../components/Button";
import UnconfirmedEmailTag from "./UnconfirmedEmailTag";
import DeleteEmailModal from "./DeleteEmailModal";

export type ContactOwner = ClinicResponse;

interface Props {
  owner: ContactOwner;
  addEmail: (ownerId: string, email: EmailRequest) => ApiPromise<ContactOwner>;
  removeEmail: (
    ownerId: string,
    ownershipId: string
  ) => ApiPromise<ContactOwner>;
  promoteEmail: (
    ownerId: string,
    emailOwnershipId: string
  ) => ApiPromise<ClinicResponse>;
  resendConfirmation: (ownershipId: string) => ApiPromise<any>;
  readOnly: boolean;
}

const EmailOwnershipList: React.FC<Props> = ({
  owner,
  addEmail,
  removeEmail,
  promoteEmail,
  resendConfirmation,
  readOnly,
}: Props) => {
  const [emails, setEmails] = useState<EmailResponse[]>(
    owner.emails ? owner.emails : []
  );

  const [emailToConfirm, setEmailToConfirm] = useState<EmailResponse | null>(
    null
  );
  const [emailToPromote, setEmailToPromote] = useState<EmailResponse | null>(
    null
  );
  const [emailToDelete, setEmailToDelete] = useState<EmailResponse | null>(
    null
  );

  const [showResendConfirmationModal, setShowResendConfirmationModal] =
    useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showPromoteModal, setShowPromoteModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const openResendConfirmationModal = (email: EmailResponse) => {
    if (readOnly) return;
    setEmailToConfirm(email);
    setShowResendConfirmationModal(true);
  };

  const closeResendConfirmationModal = () => {
    setShowResendConfirmationModal(false);
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const openPromoteModal = (email: EmailResponse) => {
    if (email.contactType === ContactType.PRIMARY) {
      return;
    }
    setEmailToPromote(email);
    setShowPromoteModal(true);
  };

  const closePromoteModal = () => {
    setShowPromoteModal(false);
  };

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4 font-bold">
          <p className="uppercase">{strings.email.toUpperCase()}</p>
        </div>
        {emails.map((email, index) => (
          <div
            className="p-4 flex justify-between items-center space-x-4"
            key={index}
          >
            <div>
              <div className="flex flex-wrap items-center">
                <p>{email.value}</p>
                <UnconfirmedEmailTag
                  email={email}
                  onClick={() => openResendConfirmationModal(email)}
                />
                <button
                  className={`tw-btn-pill-tercier-small ${
                    email.confirmed ? "mx-3" : ""
                  }`}
                  disabled
                  hidden={email.contactType !== ContactType.PRIMARY}
                  type="button"
                >
                  {strings.primary}
                </button>
              </div>
            </div>
            <div className="flex">
              <Button
                aria-label={strings.edit}
                hidden={email.contactType === ContactType.PRIMARY || readOnly}
                onClick={() => {
                  openPromoteModal(email);
                }}
                variant="icon"
              >
                <span className="sr-only">{strings.edit}</span>
                <Pencil />
              </Button>
              <Button
                aria-label={strings.delete}
                hidden={readOnly}
                className="tw-link ml-3"
                onClick={() => {
                  setShowDeleteModal(true);
                  setEmailToDelete(email);
                }}
                showTextWhileLoading={false}
                variant="icon"
              >
                <span className="sr-only">{strings.delete}</span>
                <Trash />
              </Button>
            </div>
          </div>
        ))}
        <div className="p-4" hidden={readOnly}>
          <Button
            aria-label={strings.addEmail}
            onClick={openAddModal}
            variant="link"
          >
            <span className="sr-only">{strings.addEmail}</span>+{" "}
            {strings.addEmail}
          </Button>
        </div>
      </div>
      <ResendEmailConfirmationModal
        email={emailToConfirm}
        onHide={closeResendConfirmationModal}
        resendConfirmation={resendConfirmation}
        show={showResendConfirmationModal}
      />
      <AddEmailModal
        addEmail={addEmail}
        onHide={closeAddModal}
        owner={owner}
        setOwnerships={setEmails}
        show={showAddModal}
      />
      <PromoteEmailModal
        email={emailToPromote}
        onHide={closePromoteModal}
        owner={owner}
        promoteEmail={promoteEmail}
        setEmails={(previousState) => setEmails(previousState)}
        show={showPromoteModal}
      />
      <DeleteEmailModal
        email={emailToDelete}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        owner={owner}
        removeEmail={removeEmail}
        setEmails={(previousState) => setEmails(previousState)}
        show={showDeleteModal}
      />
    </>
  );
};

export default EmailOwnershipList;
