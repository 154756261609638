/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import UrlTab from "../../../components/Tabs/UrlTab";
import { TicketResponse } from "../../../models/support/TicketResponse";
import TicketList from "./TicketList";
import TicketingApi from "../../../api/TicketingApi";
import { getGeneralError } from "../../../util/helperFunctions";
import LoaderInline from "../../../components/LoaderInline";
import { notAvailablePlaceholder } from "../../../assets/AssetConfig";

type TicketsTabKey = "open" | "closed";

export const UserTicketsPage: React.FC = () => {
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialTabKey = searchParams.get("tab") as TicketsTabKey;
  const tabKey = initialTabKey !== null ? initialTabKey : "open";

  const [tickets, setTickets] = useState<TicketResponse[]>();

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const resp = await TicketingApi.getAuthorizedTicketsOfUser();
        setTickets(resp.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setPageLoaded(true);
      }
    };

    void fetchTickets();
  }, []);

  return (
    <main className="main-signed-in">
      {error ? (
        <section>
          <div className="my-10 flex flex-col justify-center items-center space-y-6">
            <div className="text-3xl font-bold">{strings.weAreSorry}</div>
            <div className="w-48">
              <img alt="Not available" src={notAvailablePlaceholder} />
            </div>
            <div>{error}</div>
          </div>
        </section>
      ) : (
        <section>
          <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
            <div>
              <h1 className="flex items-center text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.myTickets}
              </h1>
            </div>
            {!pageLoaded ? (
              <LoaderInline />
            ) : (
              <>
                {!error && (
                  <>
                    <ul className="flex flex-wrap -mb-px">
                      <UrlTab
                        baseUrl={`${location.pathname}?tab=open`}
                        useBaseUrlOnly
                        currentTabKey={tabKey}
                        tabKey="open"
                        title={strings.active}
                      />
                      <UrlTab
                        baseUrl={`${location.pathname}?tab=closed`}
                        useBaseUrlOnly
                        currentTabKey={tabKey}
                        tabKey="closed"
                        title={strings.closed}
                      />
                    </ul>
                    <TicketList
                      tickets={
                        tabKey === "open"
                          ? tickets?.filter((t) => !t.closeAt) || []
                          : tickets?.filter((t) => !!t.closeAt) || []
                      }
                      loading={!tickets}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </section>
      )}
    </main>
  );
};
