/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { dateAndTime, strings } from "../../../../../common/Strings/Strings";
import { MedicalRecordResponse } from "../../../../../models/medical/MedicalRecordResponse";
import { medicalRecordStatus } from "../../Rows/MedicalRecordRow";
import { MedicalTreatmentResponse } from "../../../../../models/medical/MedicalTreatmentResponse";
import Button from "../../../../../components/Button";
import { DocumentText } from "../../../../../common/Icons/DocumentText";

interface Props {
  medicalRecord: MedicalRecordResponse | any;
}

const UserMedicalRecordRow: React.FC<Props> = ({ medicalRecord }: Props) => (
  <div className="flex flex-wrap py-3" key={medicalRecord.id}>
    <div className="col-12 col-lg-2">
      <div className="flex items-center">
        <div className="flex flex-col justify-center">
          <div className="uppercase text-sm">{strings.medicalRecord}</div>
          <div className="font-bold">
            {moment(medicalRecord.reservationDateTime).format(
              dateAndTime.momentDateFormat
            )}
          </div>
          <div className="mb-3 md:mb-0">
            {medicalRecordStatus(medicalRecord)}
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-lg-6 flex flex-col justify-center">
      <div hidden={!medicalRecord.weight}>
        <div className="flex">
          <b>{strings.weight}:&nbsp;</b>
          {`${medicalRecord.weight} kg`}
        </div>
      </div>
      <div hidden={medicalRecord.diagnoses.length === 0}>
        <div className="flex">
          <b>{strings.diagnoses}:&nbsp;</b>
          {medicalRecord.diagnoses.map((d: string, index: number) => (
            <div key={index}>{`${d}${
              medicalRecord.diagnoses[index + 1] ? ", " : ""
            }`}</div>
          ))}
        </div>
      </div>
      <div hidden={medicalRecord.treatments.length === 0}>
        <div className="flex">
          <b>{strings.treatments}:&nbsp;</b>
          {medicalRecord.treatments.map(
            (t: MedicalTreatmentResponse, index: number) => (
              <div>{`${t.name}${
                medicalRecord.treatments[index + 1] ? ", " : ""
              }`}</div>
            )
          )}
        </div>
      </div>
    </div>
    <div className="col-12 col-lg-3 flex flex-col justify-center">
      {`${medicalRecord.vet.fullName}`}
    </div>
    <div className="col-12 col-lg-1 flex justify-end items-center">
      {medicalRecord?.signedBy ? (
        <div className="flex items-center space-x-3">
          <Link to={`/medical-record/${medicalRecord.id}`}>
            <Button className="tw-link" variant="icon">
              <DocumentText />
            </Button>
          </Link>
        </div>
      ) : (
        <></>
      )}
    </div>
  </div>
);

export default UserMedicalRecordRow;
