/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import CheckBox from "../../../../components/ReactHookFormFields/General/CheckBox";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import { useClinic } from "../../../../contexts/ClinicContext";
import { ClinicFeature } from "../../../../models/clinic/ClinicFeature";

export interface ReservationTypeDataForm {
  allowedForAssistants: boolean;
  allowedForVets: boolean;
  allowedForAuthorizedAssistants: boolean;
  color: string;
  isReservableByOwners: boolean;
  isVideoConsultationAllowed: boolean;
  minutes: number;
  name: string;
}

interface Props {
  onEmploymentTypeChange(): void;
}

const ReservationTypeForm: React.FC<Props> = ({
  onEmploymentTypeChange,
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { isFeatureAvailable } = useClinic();

  return (
    <div className="space-y-3">
      <Field
        name="name"
        register={register}
        autoComplete={AutoCompleteOptions.off}
        label={strings.reservationType}
        fieldOptions={{ required: true }}
        error={errors.name}
      />
      <Field
        name="minutes"
        register={register}
        autoComplete={AutoCompleteOptions.off}
        label={strings.duration}
        fieldOptions={{
          required: true,
          validate: {
            positive: (v) =>
              parseInt(v, 10) > 0 || strings.durationMustBePositive,
          },
        }}
        error={errors.minutes}
        type="number"
      />
      <Field
        name="color"
        register={register}
        label={strings.color}
        fieldOptions={{ required: true }}
        error={errors.color}
        type="color"
      />
      <div>{strings.allowReservationFor}</div>
      <div className="flex flex-col gap-2">
        <CheckBox
          name="allowedForVets"
          id="allowedForVets"
          label={strings.vet}
          register={register}
          error={errors.allowedForVets}
          onChange={onEmploymentTypeChange}
          defaultChecked
        />
        <CheckBox
          name="allowedForAuthorizedAssistants"
          id="allowedForAuthorizedAssistants"
          label={strings.authorizedAssistant}
          register={register}
          error={errors.allowedForAuthorizedAssistants}
          onChange={onEmploymentTypeChange}
        />
        <CheckBox
          name="allowedForAssistants"
          id="allowedForAssistants"
          label={strings.assistant}
          register={register}
          error={errors.allowedForAssistants}
          onChange={onEmploymentTypeChange}
        />
      </div>
      <div>{strings.isReservableByOwnersQuestion}</div>
      <div className="flex flex-col gap-2">
        <CheckBox
          name="isReservableByOwners"
          id="isReservableByOwners"
          label={strings.isReservableByOwners}
          register={register}
          error={errors.isReservableByOwners}
        />
      </div>
      <div
        className="space-y-3"
        hidden={!isFeatureAvailable(ClinicFeature.VIDEO_CONSULTATION)}
      >
        <div>{strings.isVideoConsultationAllowedQuestion}</div>
        <div className="flex flex-col gap-2">
          <CheckBox
            name="isVideoConsultationAllowed"
            id="isVideoConsultationAllowed"
            label={strings.isVideoConsultationAllowed}
            register={register}
            error={errors.isVideoConsultationAllowed}
          />
        </div>
      </div>
    </div>
  );
};

export default ReservationTypeForm;
