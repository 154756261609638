/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { strings } from "../../../../common/Strings/Strings";
import { Loader } from "../../../../components/Loader";
import ReservationApi from "../../../../api/ReservationApi";
import { ReservationResponse } from "../../../../models/reservation/ReservationResponse";
import AppointmentList from "./AppointmentList";
import Button from "../../../../components/Button";

interface Props {
  setPageLoading: (isLoading: boolean) => void;
}

const Appointments: React.FC<Props> = (props: Props) => {
  const { setPageLoading } = props;
  const [filteredAppointments, setFilteredAppointments] = useState<
    ReservationResponse[]
  >([]);

  useEffect(() => {
    const getAppointments = async () => {
      try {
        const response = await ReservationApi.getUserReservations();
        setFilteredAppointments(
          response.data.sort((a, b) =>
            moment(a.startDateTime).valueOf() >
            moment(b.startDateTime).valueOf()
              ? -1
              : 1
          )
        );
        setPageLoading(false);
      } catch (e) {
        setPageLoading(false);
      }
    };

    void getAppointments();
  }, [setPageLoading]);

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div className="md:flex justify-between items-center">
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.appointments}
            </h1>
            <div className="mt-3 md:ml-auto">
              <Link to="/reservation">
                <Button minW variant="primary">{strings.addAppointment}</Button>
              </Link>
            </div>
          </div>
          <AppointmentList reservations={filteredAppointments} />
        </div>
      </section>
    </main>
  );
};

export default Loader(Appointments);
