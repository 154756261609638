/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import qs from "query-string";
import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { NewInventoryItemRequest } from "../models/inventory/item/Request/NewInventoryItemRequest";
import { InventoryItemResponse } from "../models/inventory/item/Response/InventoryItemResponse";
import { PetSpecies } from "../models/pet/PetSpeciesEnum";
import { MedicationsResponse } from "../models/inventory/item/Response/MedicationsResponse";
import { InventoryItemsWithValidationResponse } from "../models/inventory/item/Response/InventoryItemsWithValidationResponse";
import { UpdateInventoryItemStockRequest } from "../models/inventory/item/Request/UpdateInventoryItemStockRequest";
import { SearchInventoryItems } from "../models/apiparams/SearchInventoryItems";
import { PageResponse } from "../models/PageResponse";
import Params from "../common/Params";
import { UpdateInventoryItemRequest } from "../models/inventory/item/Request/UpdateInventoryItemRequest";
import { InventoryItemStockResponse } from "../models/inventory/item/Response/InventoryItemStockResponse";
import { InventoryItemUsageRequest } from "../models/inventory/item/Request/InventoryItemUsageRequest";
import { InventoryItemUsageResponse } from "../models/inventory/item/Response/InventoryItemUsageResponse";
import { InventoryItemStockRequest } from "../models/inventory/item/Request/InventoryItemStockRequest";
import { InventoryItemDetailsResponse } from "../models/inventory/item/Response/InventoryItemDetailsResponse";
import { InventoryItemStockItemResponse } from "../models/inventory/item/Response/InventoryItemStockItemResponse";
import { InventoryItemStockCountingResponse } from "../models/inventory/item/Response/InventoryItemStockCountingResponse";
import { InventoryItemStockCountingRequest } from "../models/inventory/item/Request/InventoryItemStockCountingRequest";

export default class InventoryItemApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static createItem(
    clinicId: string,
    categoryId: string,
    item: NewInventoryItemRequest
  ): ApiPromise<InventoryItemResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/inventories/categories/${categoryId}/items`;
    return axiosCommon.post(url, item);
  }

  static getItems(
    clinicId: string,
    categoryId: string
  ): ApiPromise<InventoryItemResponse[]> {
    const url = `${this.baseUrl}/clinics/${clinicId}/inventories/categories/${categoryId}/items`;
    return axiosCommon.get(url);
  }

  static searchItemsOfClinic(
    clinicId: string,
    {
      query,
      categoryId,
      pageNumber = 0,
      pageSize = 50,
      stockFilter,
    }: SearchInventoryItems
  ): ApiPromise<PageResponse<InventoryItemResponse>> {
    const queryStr = qs.stringify({
      query,
      stockFilter,
      categoryId,
      pageSize,
      pageNumber,
    });
    const url = `${this.baseUrl}/clinics/${clinicId}/inventories/search?${queryStr}`;
    return axiosCommon.get(url);
  }

  static getMedicationItems(
    clinicId: string,
    species: PetSpecies
  ): ApiPromise<MedicationsResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/inventories/categories/medications?species=${species}`;
    return axiosCommon.get(url);
  }

  static getItemSuggestions(
    clinicId: string,
    categoryId: string
  ): ApiPromise<string[]> {
    const url = `${this.baseUrl}/clinics/${clinicId}/inventories/categories/${categoryId}/suggestions`;
    return axiosCommon.get(url);
  }

  static updateItemInfo(
    clinicId: string,
    categoryId: string,
    itemId: string,
    item: UpdateInventoryItemRequest
  ): ApiPromise<InventoryItemResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/inventories/categories/${categoryId}/items/${itemId}`;
    return axiosCommon.put(url, item);
  }

  static updateStockWithMedicalRecord(
    clinicId: string,
    medicalRecordId: string,
    force: boolean
  ): ApiPromise<InventoryItemsWithValidationResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/inventories/use/medical-record/${medicalRecordId}?force=${force}`;
    return axiosCommon.put(url);
  }

  static updateStock(
    clinicId: string,
    items: UpdateInventoryItemStockRequest[]
  ): ApiPromise<InventoryItemsWithValidationResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/inventories/items/refill`;
    return axiosCommon.put(url, items);
  }

  static removeItem(itemId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/items/${itemId}`;
    return axiosCommon.delete(url);
  }

  static getItem(itemId: string): ApiPromise<InventoryItemDetailsResponse> {
    const url = `${this.baseUrl}/items/${itemId}`;
    return axiosCommon.get(url);
  }

  static addItemStock(
    itemId: string,
    request: InventoryItemStockRequest
  ): ApiPromise<InventoryItemStockResponse> {
    const url = `${this.baseUrl}/items/${itemId}/stocks`;
    return axiosCommon.post(url, request);
  }

  static updateItemStock(
    itemId: string,
    stockId: string,
    request: InventoryItemStockRequest
  ): ApiPromise<InventoryItemStockResponse> {
    const url = `${this.baseUrl}/items/${itemId}/stocks/${stockId}`;
    return axiosCommon.put(url, request);
  }

  static deleteItemStock(
    itemId: string,
    stockId: string
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/items/${itemId}/stocks/${stockId}`;
    return axiosCommon.delete(url);
  }

/*
  static addItemUsage(
    itemId: string,
    request: InventoryItemUsageRequest
  ): ApiPromise<InventoryItemUsageResponse> {
    const url = `${this.baseUrl}/items/${itemId}/usages`;
    return axiosCommon.post(url, request);
  }

  static updateItemUsage(
    itemId: string,
    usageId: string,
    request: InventoryItemUsageRequest
  ): ApiPromise<InventoryItemUsageResponse> {
    const url = `${this.baseUrl}/items/${itemId}/usages/${usageId}`;
    return axiosCommon.put(url, request);
  }

  static deleteItemUsage(
    itemId: string,
    usageId: string
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/items/${itemId}/usages/${usageId}`;
    return axiosCommon.delete(url);
  }
*/

  static getAvailableStocks(itemId: string): ApiPromise<InventoryItemStockItemResponse[]> {
    const url = `${this.baseUrl}/items/${itemId}/available-stocks`;
    return axiosCommon.get(url);
  }

  static addItemCounting(
    itemId: string,
    request: InventoryItemStockCountingRequest
  ): ApiPromise<InventoryItemStockCountingResponse> {
    const url = `${this.baseUrl}/items/${itemId}/stock-countings`;
    return axiosCommon.post(url, request);
  }

  static deleteItemCounting(
    itemId: string,
    stockCountingId: string
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/items/${itemId}/stock-countings/${stockCountingId}`;
    return axiosCommon.delete(url);
  }
}
