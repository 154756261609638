/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { strings } from "../../common/Strings/Strings";
import { ReservationAccessScope } from "../../models/reservation/ReservationAccessScope";
import { AutoCompleteOptions } from "../../models/AutoCompleteOptions";
import { MedicalRecordResponse } from "../../models/medical/MedicalRecordResponse";
import AddButtonIcon from "../../components/AddButtonIcon";
import { SavedReservationProgress } from "../../models/calendar/SavedReservationProgress";
import { NewReservationPetType } from "../../models/calendar/NewReservationPetType";
import {
  appointmentToRescheduleKey,
  deleteFromLocalStorage,
  saveReservationInProgress,
} from "../../util/LocalStorageVariables";
import SendInEmailModal from "../Pet/History/SendInEmailModal";
import { RowType } from "../Pet/History/PetHistory";
import { MedicalRecordsMailResponse } from "../../models/medical/mail/MedicalRecordsMailResponse";
import { personDetailsToPetOwner } from "../../models/pet/PetOwnerResponse";
import ControlledTextarea from "../../components/ReactHookFormFields/General/Controlled/ControlledTextarea";
import Button from "../../components/Button";
import { SignedTextAreaDisplay } from "./MainSection/Templates/TextAreaTemplate";

interface Props {
  accessScope: ReservationAccessScope;
  medicalRecord?: MedicalRecordResponse;
  refreshReservationInProgress?(): void;
  signed: boolean;
  triggerChange(): void;
  updateMedicalRecordVersion(newEmail: MedicalRecordsMailResponse): void;
}

export interface AdditionalFieldsProps {
  prognosis: string;
  certificate: string;
  referral: string;
  comment: string;
}

const AdditionalFields = ({
  accessScope,
  medicalRecord,
  refreshReservationInProgress,
  signed,
  triggerChange,
  updateMedicalRecordVersion,
}: Props): ReactElement => {
  const { control } = useFormContext();
  const [isPrognosisOpen, setIsPrognosisOpen] = useState<boolean>(
    !medicalRecord?.prognosis
  );
  const [isReferralOpen, setIsReferralOpen] = useState<boolean>(
    !medicalRecord?.referral
  );
  const [isCertificateOpen, setIsCertificateOpen] = useState<boolean>(
    !medicalRecord?.certificate
  );
  const [newEmail, setNewEmail] = useState<boolean>(false);

  const navigate = useNavigate();

  const startNewReservation = () => {
    const reservation: SavedReservationProgress = {
      petState: {
        state: NewReservationPetType.SELECTED,
        pet: medicalRecord
          ? { clinicPetDetails: { ...medicalRecord?.pet, chronicDiseases: [] } }
          : undefined,
        petOwner: medicalRecord
          ? personDetailsToPetOwner(medicalRecord.petOwner)
          : undefined,
      },
      symptoms: [],
    };

    saveReservationInProgress(reservation);
    deleteFromLocalStorage(appointmentToRescheduleKey);

    if (refreshReservationInProgress) {
      refreshReservationInProgress();
    } else {
      navigate("/");
    }
  };

  return (
    <>
      {signed ? (
        <>
          <SignedTextAreaDisplay
            label={strings.prognosis}
            value={medicalRecord?.prognosis}
          />
          <SignedTextAreaDisplay
            label={strings.referral}
            value={medicalRecord?.referral}
          />
          <SignedTextAreaDisplay
            label={strings.certificate}
            value={medicalRecord?.certificate}
          />

          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-6">
              <Button
                variant="primary-outline"
                fullWidth
                onClick={() => {
                  startNewReservation();
                }}
              >
                <div className="flex items-center justify-center">
                  <AddButtonIcon />
                  {strings.appointment}
                </div>
              </Button>
            </div>
            <div className="col-span-12 lg:col-span-6">
              <Button
                variant="primary-outline"
                fullWidth
                onClick={() => {
                  setNewEmail(true);
                }}
              >
                <div className="flex items-center justify-center">
                  <AddButtonIcon />
                  {strings.attachToEmail}
                </div>
              </Button>
            </div>
          </div>
        </>
      ) : (
        <form className="space-y-4">
          <div hidden={isPrognosisOpen}>
            <ControlledTextarea
              control={control}
              name="prognosis"
              autoComplete={AutoCompleteOptions.off}
              label={strings.prognosis}
              minRows={2}
              onChange={triggerChange}
              placeholder={strings.prognosis}
              readOnly={signed || accessScope === ReservationAccessScope.READ}
            />
          </div>
          <div hidden={isReferralOpen}>
            <ControlledTextarea
              control={control}
              name="referral"
              autoComplete={AutoCompleteOptions.off}
              label={strings.referral}
              minRows={2}
              onChange={triggerChange}
              placeholder={strings.referral}
              readOnly={signed || accessScope === ReservationAccessScope.READ}
            />
          </div>
          <div hidden={isCertificateOpen}>
            <ControlledTextarea
              control={control}
              name="certificate"
              autoComplete={AutoCompleteOptions.off}
              label={strings.certificate}
              minRows={2}
              onChange={triggerChange}
              placeholder={strings.certificate}
              readOnly={signed || accessScope === ReservationAccessScope.READ}
            />
          </div>
          <div className="grid grid-cols-12 gap-4">
            <div
              className="col-span-12 lg:col-span-6"
              hidden={!isPrognosisOpen}
            >
              <Button
                variant="primary-outline"
                fullWidth
                onClick={() => {
                  setIsPrognosisOpen(false);
                }}
              >
                <div className="flex items-center justify-center">
                  <AddButtonIcon />
                  {strings.prognosis}
                </div>
              </Button>
            </div>
            <div className="col-span-12 lg:col-span-6" hidden={!isReferralOpen}>
              <Button
                variant="primary-outline"
                fullWidth
                onClick={() => {
                  setIsReferralOpen(false);
                }}
              >
                <div className="flex items-center justify-center">
                  <AddButtonIcon />
                  {strings.referral}
                </div>
              </Button>
            </div>
            <div
              className="col-span-12 lg:col-span-6"
              hidden={!isCertificateOpen}
            >
              <Button
                variant="primary-outline"
                fullWidth
                onClick={() => {
                  setIsCertificateOpen(false);
                }}
              >
                <div className="flex items-center justify-center">
                  <AddButtonIcon />
                  {strings.certificate}
                </div>
              </Button>
            </div>
          </div>
        </form>
      )}
      <SendInEmailModal
        close={() => {
          setNewEmail(false);
        }}
        isOpen={newEmail}
        pet={
          medicalRecord
            ? {
                clinicPetDetails: {
                  ...medicalRecord.pet,
                  chronicDiseases: medicalRecord.pet.chronicDiseases || [],
                },
              }
            : undefined
        }
        selectedDocuments={
          medicalRecord
            ? [
                {
                  dateTime: medicalRecord.creationDate,
                  value: medicalRecord,
                  type: RowType.MedicalRecord,
                },
              ]
            : []
        }
        updateMedicalRecordVersion={updateMedicalRecordVersion}
      />
    </>
  );
};

export default AdditionalFields;
