/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import Moment from "react-moment";
import IconBubble from "../../../../components/IconBubble";
import { VideoCamera } from "../../../../common/Icons/VideoCamera";
import { strings } from "../../../../common/Strings/Strings";
import { CalendarIcon } from "../../../../common/Icons/Calendar";
import { Clock } from "../../../../common/Icons/Clock";
import { Pencil } from "../../../../common/Icons/Pencil";
import { Circle } from "../../../../common/Circle";
import { ReservationTypeResponse } from "../../../../models/reservationtype/ReservationTypeResponse";
import { MapPin } from "../../../../common/Icons/MapPin";
import { getAddressString } from "../../../../util/helperFunctions";
import { AddressResponse } from "../../../../models/contact/AddressResponse";
import { PublicAddressResponse } from "../../../../models/contact/PublicAddressResponse";

interface Props {
  appointmentEnd: Date;
  appointmentStart: Date;
  clinicAddress?: AddressResponse | PublicAddressResponse;
  clinicName: string;
  description: string;
  isVideoConsultation: boolean;
  resType?: ReservationTypeResponse;
}

const AppointmentDetails = ({ appointmentEnd, appointmentStart, clinicAddress, clinicName, description, resType, isVideoConsultation }: Props): ReactElement => {
  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-3">
        <div
          style={{
            color: resType?.color || "#asdasd",
          }}
        >
          <Circle height="40px" width="40px" />
        </div>
        <div className="font-bold">{resType?.name}</div>
      </div>
      {isVideoConsultation ? (
        <div className="flex items-center space-x-3">
          <IconBubble>
            <VideoCamera variant="outline" />
          </IconBubble>
          <div className="font-bold">{strings.onlineVideoConsultation}</div>
        </div>
      ) : (
        <></>
      )}
      <div className="flex items-center space-x-3">
        <IconBubble>
          <CalendarIcon />
        </IconBubble>
        <div className="font-bold">
          <Moment
            format="LL"
            locale={
              strings.getLanguage() === "gb" ? "en-gb" : strings.getLanguage()
            }
          >
            {appointmentStart}
          </Moment>
        </div>
      </div>
      <div className="flex items-center space-x-3">
        <IconBubble>
          <Clock variant="outline" />
        </IconBubble>
        <div className="font-bold">
          <Moment format="LT">
            {appointmentStart}
          </Moment>
          &nbsp;-&nbsp;
          <Moment format="LT">
            {appointmentEnd}
          </Moment>
        </div>
      </div>
      <div className="flex items-center space-x-3">
        <IconBubble>
          <MapPin variant="outline" />
        </IconBubble>
        <div>
          <div className="font-bold">{clinicName}</div>
          <div className="text-sm">
            {clinicAddress ? getAddressString(clinicAddress) : ""}
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-3">
        <IconBubble>
          <Pencil />
        </IconBubble>
        <div className="line-clamp-1 font-bold">{description}</div>
      </div>
    </div>
  );
};

export default AppointmentDetails;
