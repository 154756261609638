/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { EmailRequest } from "../models/contact/EmailRequest";
import { PhoneConfirmationRequest } from "../models/contact/PhoneConfirmationRequest";
import { PhoneRequest } from "../models/contact/PhoneRequest";
import { PhoneUpdateRequest } from "../models/contact/PhoneUpdateRequest";
import { UserResponse } from "../models/user/UserResponse";
import { axiosCommon } from "../util/axiosSetup";
import { UserAddressRequest } from "../models/contact/UserAddressRequest";

export default class UserContactsApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static addUserEmail(emailRequest: EmailRequest): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-emails`;
    return axiosCommon.post(url, emailRequest);
  }

  static removeUserEmail(emailId: string): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-emails/${emailId}`;
    return axiosCommon.delete(url);
  }

  static addUserPhone(phoneRequest: PhoneRequest): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-phones`;
    return axiosCommon.post(url, phoneRequest);
  }

  static confirmUserPhoneInstantly(
    phoneId: string,
    confirmationRequest: PhoneConfirmationRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-phones/${phoneId}/confirm-instantly`;
    return axiosCommon.post(url, confirmationRequest);
  }

  static removeUserPhone(phoneId: string): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-phones/${phoneId}`;
    return axiosCommon.delete(url);
  }

  static addUserAddress(
    addressRequest: UserAddressRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-addresses`;
    return axiosCommon.post(url, addressRequest);
  }

  static removeUserAddress(addressId: string): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-addresses/${addressId}`;
    return axiosCommon.delete(url);
  }

  static setPrimaryUserEmail(emailId: string): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/primary-user-email`;
    return axiosCommon.put(url, { emailId });
  }

  static setPrimaryUserPhone(phoneId: string): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/primary-user-phone`;
    return axiosCommon.put(url, { phoneId });
  }

  static setPrimaryUserAddress(addressId: string): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/primary-user-address`;
    return axiosCommon.put(url, { addressId });
  }

  static sendUserEmailConfirmation(emailId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/user-emails/${emailId}/send-confirmation-mail`;
    return axiosCommon.post(url);
  }

  static sendUserPhoneConfirmation(phoneId: string): ApiPromise<void> {
    const url = `${this.baseUrl}/user-phones/${phoneId}/send-confirmation-sms`;
    return axiosCommon.post(url);
  }

  static updateUserPhone(
    phoneId: string,
    updateRequest: PhoneUpdateRequest
  ): ApiPromise<UserResponse> {
    const url = `${this.baseUrl}/user-phones/${phoneId}`;
    return axiosCommon.put(url, updateRequest);
  }
}
