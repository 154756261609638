/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import qs from "query-string";
import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { PageResponse } from "../models/PageResponse";
import { ClinicAndClinicPetsResponse } from "../models/pet/ClinicAndClinicPetsResponse";
import { ClinicPetAndOwnerResponse } from "../models/pet/ClinicPetAndOwnerResponse";
import { ClinicPetToUserPetRequest } from "../models/pet/ClinicPetToUserPetRequest";
import { NewPetAndOwnerRequest } from "../models/pet/NewPetAndOwnerRequest";
import { NewPetOwnerRequest } from "../models/pet/NewPetOwnerRequest";
import { PetOwnerAndClinicPetsResponse } from "../models/pet/PetOwnerAndClinicPetsResponse";
import { PetOwnerResponse } from "../models/pet/PetOwnerResponse";
import { UpdatePetOwnerRequest } from "../models/pet/UpdatePetOwnerRequest";
import { UserPetsAndLinkedClinicPetOwnersResponse } from "../models/pet/UserPetsAndLinkedClinicPetOwnersResponse";
import { axiosCommon } from "../util/axiosSetup";
import { PendingPetsResponse } from "../models/pet/PendingPetsResponse";

export default class PetOwnerApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getPetOwner(
    petOwnerId: string
  ): ApiPromise<PetOwnerAndClinicPetsResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}`;
    return axiosCommon.get(url);
  }

  static getPetOwners(
    clinicId: string,
    queryParams: {
      query?: string;
      pageNumber?: number;
      pageSize?: number;
    }
  ): ApiPromise<PageResponse<PetOwnerResponse>> {
    const query = qs.stringify(queryParams);
    const url = `${this.baseUrl}/clinics/${clinicId}/pet-owners?${query}`;
    return axiosCommon.get(url);
  }

  static createPetOwner(
    clinicId: string,
    creationRequest: NewPetOwnerRequest
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/pet-owners`;
    return axiosCommon.post(url, creationRequest);
  }

  static createPetOwnerForReservation(
    reservationId: string,
    creationRequest: NewPetAndOwnerRequest
  ): ApiPromise<ClinicPetAndOwnerResponse> {
    const url = `${this.baseUrl}/reservations/${reservationId}/new-pet-owner`;
    return axiosCommon.post(url, creationRequest);
  }

  static updatePetOwner(
    petOwnerId: string,
    updateRequest: UpdatePetOwnerRequest
  ): ApiPromise<PetOwnerResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}`;
    return axiosCommon.put(url, updateRequest);
  }

  static getLinkedPetOwners(): ApiPromise<ClinicAndClinicPetsResponse[]> {
    return axiosCommon.get(`${this.baseUrl}/linked-pet-owners`);
  }

  static linkPetOwnersToUser(): ApiPromise<ClinicAndClinicPetsResponse> {
    return axiosCommon.get(`${this.baseUrl}/link-pet-owners`);
  }

  static assignPetOwnerToUser(
    petOwnerId: string,
    clinicPetsToUserPets: ClinicPetToUserPetRequest[]
  ): ApiPromise<UserPetsAndLinkedClinicPetOwnersResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/assign-to-user`;
    return axiosCommon.post(url, clinicPetsToUserPets);
  }

  static unlinkPetOwnerFromUser(
    petOwnerId: string
  ): ApiPromise<UserPetsAndLinkedClinicPetOwnersResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/unlink`;
    return axiosCommon.post(url);
  }

  static getPendingPets(): ApiPromise<PendingPetsResponse> {
    const url = `${this.baseUrl}/pending-pets`;
    return axiosCommon.get(url);
  }
}
