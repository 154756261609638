/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import LoaderInline from "../../../components/LoaderInline";
import TicketListHeader from "./TicketListHeader";
import TicketListCell from "./TicketListCell";
import { TicketResponse } from "../../../models/support/TicketResponse";

interface Props {
  tickets: TicketResponse[];
  loading: boolean;
}

const TicketList: React.FC<Props> = ({ tickets, loading }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="w-100" style={{ maxWidth: "80rem" }}>
      <div className="d-flex justify-content-end py-3 w-100">
        <Button
          variant="primary"
          onClick={() => navigate("/support/new-ticket")}
        >
          {strings.createTicket}
        </Button>
      </div>
      {loading && (
        <div className="card w-100 d-flex justify-content-center p-3">
          <LoaderInline />
        </div>
      )}
      {tickets.length > 0 && (
        <div
          className="card"
          style={{ display: "grid", gridTemplateColumns: "60% 20% 20%" }}
        >
          <TicketListHeader title={strings.subject} />
          <TicketListHeader title={strings.date} />
          <TicketListHeader title={strings.status} />
          <div
            style={{
              gridColumn: "1/4",
              height: "1px",
              backgroundColor: "#a7afba",
            }}
          />
          {tickets.map(t => (
            <Fragment key={t.id}>
              <TicketListCell content={t.title} ticketId={t.id} bold />
              <TicketListCell
                content={moment(t.createdAt).format(
                  dateAndTime.momentDateTimeFormat
                )}
                ticketId={t.id}
              />
              <TicketListCell
                content={
                  t.closeAt
                    ? strings.closed
                    : t.articles.find(a => !a.isOwn)
                    ? strings.inProgress
                    : strings.new
                }
                ticketId={t.id}
              />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default TicketList;
