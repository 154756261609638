/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { ClinicPetTransferRequest } from "../models/petTransfer/ClinicPetTransferRequest";
import { PetTransferResponse } from "../models/petTransfer/PetTransferResponse";
import { UserPetTransferRequest } from "../models/petTransfer/UserPetTransferRequest";
import { axiosCommon } from "../util/axiosSetup";

export default class PetTransferApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static clinicTransfer(
    petId: string,
    transferRequest: ClinicPetTransferRequest
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/clinic-pets/${petId}/clinic-transfer`;
    return axiosCommon.post(url, transferRequest);
  }

  static userTransfer(
    petId: string,
    transferRequest: UserPetTransferRequest
  ): ApiPromise<PetTransferResponse> {
    const url = `${this.baseUrl}/clinic-pets/${petId}/user-transfer`;
    return axiosCommon.post(url, transferRequest);
  }

  static transfer(
    petId: string,
    transferRequest: UserPetTransferRequest
  ): ApiPromise<PetTransferResponse> {
    const url = `${this.baseUrl}/user-pets/${petId}/transfer`;
    return axiosCommon.post(url, transferRequest);
  }

  static accept(transferId: string): ApiPromise<PetTransferResponse> {
    const url = `${this.baseUrl}/pet-transfers/${transferId}/accept`;
    return axiosCommon.post(url);
  }

  static reject(transferId: string): ApiPromise<PetTransferResponse> {
    const url = `${this.baseUrl}/pet-transfers/${transferId}/reject`;
    return axiosCommon.post(url);
  }
}
