/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactNode, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import MapMarker from "./MapMarker";
import { MapPinSolid } from "../../common/Icons/MapPinSolid";

export interface MapLocation {
  lat: number;
  lng: number;
}

export interface MarkerMapLocation {
  lat: number;
  lng: number;
  id: string;
  content?: ReactNode;
}

export interface GoogleMapClickEvent {
  x: number;
  y: number;
  lat: number;
  lng: number;
  event: Event;
}

interface Props {
  center?: MapLocation;
  defaultCenter?: MapLocation;
  defaultZoom?: number;
  location?: MapLocation;
  mapHeight?: string;
  mapWidth?: string;
  markers?: MarkerMapLocation[];
  onClick?({ x, y, lat, lng, event }: GoogleMapClickEvent): void;
  onMarkerClick?(key: string): void;
  zoom?: number;
}

const defaultProps = {
  center: {
    lat: 59.3293 /* Stockholm */,
    lng: 18.0686,
  },
  zoom: 11,
};

const GoogleMap = ({
  center,
  defaultCenter,
  defaultZoom,
  location,
  mapHeight = "100%",
  mapWidth = "200px",
  markers,
  onClick: customOnClick,
  onMarkerClick,
  zoom,
}: Props) => {
  const [loc, setLoc] = useState<MapLocation>();
  const [visibleDetailsKey, setVisibleDetailsKey] = useState<string>();

  useEffect(() => {
    setLoc(location);
  }, [location]);

  const onClick = (click: GoogleMapClickEvent) => {
    if (location) {
      setLoc({ lat: click.lat, lng: click.lng });
    }
    if (customOnClick) {
      customOnClick(click);
    }
  };

  const onChildClick = (id: string) => {
    if (onMarkerClick) {
      onMarkerClick(id);
    }

    setVisibleDetailsKey(id.toString());
  };

  return (
    <div style={{ height: mapHeight, width: mapWidth }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC-UrOVnMhVObBn6fYjE6lsKb_DolOQMCk" }}
        center={center}
        defaultCenter={defaultCenter || defaultProps.center}
        defaultZoom={defaultZoom || defaultProps.zoom}
        hoverDistance={20}
        onChildClick={onChildClick}
        onClick={onClick}
        zoom={zoom}
      >
        {loc ? (
          <MapMarker lat={loc.lat} lng={loc.lng}>
            <MapPinSolid height="40px" width="40px" className="text-red-500" />
          </MapMarker>
        ) : (
          <></>
        )}
        {markers?.map((location: MarkerMapLocation) => (
          <MapMarker lat={location.lat} lng={location.lng} key={location.id}>
            {location.content ? (
              <div className="history-card p-1">{location.content}</div>
            ) : (
              <></>
            )}
            <MapPinSolid
              height="40px"
              width="40px"
              key={location.id}
              className={
                visibleDetailsKey === location.id
                  ? "text-sky-500"
                  : "color-dark"
              }
            />
          </MapMarker>
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
