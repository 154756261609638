/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { ReservationAccessScope } from "../../../../models/reservation/ReservationAccessScope";
import SimpleTextarea from "../../../../components/InputFieldsSimple/SimpleTextarea";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";

interface Props {
  name: string;
  label: string;
  accessScope: ReservationAccessScope;
  signed: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  triggerChange(v: string): void;
}

export const SignedTextAreaDisplay: React.FC<{
  label: string;
  value: string | undefined;
}> = ({ label, value }) => {
  if (!value) return null;

  return (
    <>
      <label>{label}</label>
      <p className="whitespace-pre-line">
        <strong>{value}</strong>
      </p>
    </>
  );
};

const TextAreaTemplate: React.FC<Props> = ({
  name,
  label,
  accessScope,
  signed,
  value,
  setValue,
  triggerChange,
  ...rest
}) => (
  <>
    {signed && <SignedTextAreaDisplay label={label} value={value} />}
    {!signed && (
      <SimpleTextarea
        name={name}
        autoComplete={AutoCompleteOptions.off}
        label={label}
        minRows={2}
        onChange={(v: string) => {
          setValue(v);
          triggerChange(v);
        }}
        placeholder={label}
        readOnly={signed || accessScope === ReservationAccessScope.READ}
        value={value}
        {...rest}
      />
    )}
  </>
);

export default TextAreaTemplate;
