/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AxiosError } from "axios";
import { strings } from "../../../../common/Strings/Strings";
import { ReservationTypeRequest } from "../../../../models/reservationtype/ReservationTypeRequest";
import { ReservationTypeResponse } from "../../../../models/reservationtype/ReservationTypeResponse";
import CloseButton from "../../../../components/CloseButton";
import AlertBox from "../../../../components/AlertBox";
import { BackendFieldError } from "../../../../models/BackendFieldError";
import { getGeneralError } from "../../../../util/helperFunctions";
import { ClinicEmploymentType } from "../../../../models/employment/ClinicEmploymentType";
import Button from "../../../../components/Button";
import ReservationTypeForm, {
  ReservationTypeDataForm,
} from "./ReservationTypeForm";
import Modal from "../../../../components/Modal/Modal";

interface Props {
  reservationType: ReservationTypeResponse | undefined;
  onSubmit: (
    resTypeId: string,
    reservationType: ReservationTypeRequest,
    onSuccess: () => void,
    onFailure: (err: AxiosError<any>) => void
  ) => void;
  close: () => void;
  isOpen: boolean;
}

type AddReservationTypeFormKeys = keyof ReservationTypeDataForm;

const EditReservationTypeForm: React.FC<Props> = ({
  reservationType,
  onSubmit,
  close,
  isOpen,
}: Props) => {
  const [isBeingAdded, setIsBeingAdded] = useState<boolean>(false);
  const [employmentTypes, setEmploymentTypes] = useState<
    ClinicEmploymentType[]
  >([ClinicEmploymentType.VET]);

  const [fieldErrors, setFieldErrors] = useState<
    Array<BackendFieldError<AddReservationTypeFormKeys>>
  >([]);
  const [externalError, setExternalError] = useState<string | null>(null);
  const formMethods = useForm<ReservationTypeDataForm>({
    mode: "onChange",
    defaultValues: {
      color: "#bbb8c5",
    },
  });

  const {
    reset,
    handleSubmit,
    setError,
    getValues,
    formState: { isValid },
  } = formMethods;

  useEffect(() => {
    if (fieldErrors && fieldErrors.forEach) {
      fieldErrors.forEach(
        ({ field, message }: BackendFieldError<AddReservationTypeFormKeys>) => {
          setError(field, { type: "manual", message });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldErrors]);

  const onCancel = () => {
    close();
  };

  const onSuccess = () => {
    setIsBeingAdded(false);
    onCancel();
  };

  const onFailure = async (err: AxiosError<any>) => {
    setIsBeingAdded(false);
    setExternalError(await getGeneralError(err));
    setFieldErrors(err.response?.data || []);
  };

  const submit = (f: ReservationTypeDataForm) => {
    const request: ReservationTypeRequest = {
      name: f.name,
      minutes: f.minutes,
      color: f.color,
      employmentTypes,
      isReservableByOwners: f.isReservableByOwners,
      isVideoConsultationAllowed: f.isVideoConsultationAllowed,
    };
    if (reservationType) {
      onSubmit(reservationType.id, request, onSuccess, onFailure);
      setIsBeingAdded(true);
    }
  };

  useEffect(() => {
    if (reservationType) {
      setEmploymentTypes(reservationType.employmentTypes);
      reset({
        ...reservationType,
        allowedForVets: reservationType.employmentTypes.includes(
          ClinicEmploymentType.VET
        ),
        allowedForAssistants: reservationType.employmentTypes.includes(
          ClinicEmploymentType.ASSISTANT
        ),
        allowedForAuthorizedAssistants:
          reservationType.employmentTypes.includes(
            ClinicEmploymentType.AUTHORIZED_ASSISTANT
          ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationType]);

  const onEmploymentTypeChange = () => {
    const formValues = getValues();
    setEmploymentTypes([
      ...(formValues.allowedForVets ? [ClinicEmploymentType.VET] : []),
      ...(formValues.allowedForAssistants
        ? [ClinicEmploymentType.ASSISTANT]
        : []),
      ...(formValues.allowedForAuthorizedAssistants
        ? [ClinicEmploymentType.AUTHORIZED_ASSISTANT]
        : []),
    ]);
  };

  return (
    <Modal handleClose={onCancel} show={isOpen}>
      <Modal.Header title={strings.editReservationType} />
      <Modal.Body>
        <FormProvider {...formMethods}>
          <form id="editReservationTypeForm">
            <ReservationTypeForm
              onEmploymentTypeChange={onEmploymentTypeChange}
            />
          </form>
        </FormProvider>
        <AlertBox
          message={
            employmentTypes.length === 0 ? strings.chooseAnEmploymentType : ""
          }
          className="m-3"
        />
        <AlertBox message={externalError} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || employmentTypes.length === 0}
          fullWidth={false}
          loading={isBeingAdded}
          type="submit"
          variant="primary"
          form="editReservationTypeForm"
          onClick={handleSubmit(submit)}
        >
          {strings.save}
        </Button>
        <CloseButton onClick={onCancel} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default EditReservationTypeForm;
