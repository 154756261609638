/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import GeoApi from "../../api/GeoApi";
import PharmacyApi from "../../api/PharmacyApi";
import VaraApi from "../../api/VaraApi";
import { strings } from "../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../components/AlertBox";
import { Loader } from "../../components/Loader";
import { useUser } from "../../contexts/UserContext";
import { AnimalCarerTypeEnum } from "../../models/ePrescription/local/AnimalCarerTypeEnum";
import { CommodityPrescriptionItemFrontend } from "../../models/ePrescription/local/CommodityPrescriptionItemFrontend";
import { DeliverySelectionTypeEnum } from "../../models/ePrescription/local/DeliverySelectionTypeEnum";
import { FeeType } from "../../models/ePrescription/local/FeeType";
import { LicensedPrescriptionItemFrontend } from "../../models/ePrescription/local/LicensedPrescriptionItemFrontend";
import { PharmacySelectionTypeEnum } from "../../models/ePrescription/local/PharmacySelectionTypeEnum";
import { PrescriptionItemFrontend } from "../../models/ePrescription/local/PrescriptionItemFrontend";
import { ExtendedDelivery } from "../../models/ePrescription/persistence/ExtendedDelivery";
import { Animal } from "../../models/ePrescription/sending/Animal";
import { AnimalCarer } from "../../models/ePrescription/sending/AnimalCarer";
import { AnimalType } from "../../models/ePrescription/sending/AnimalType";
import { PackageType } from "../../models/ePrescription/VARA/PackageType";
import { PageProps } from "../../models/PageProps";
import { GeoPharmacy } from "../../models/pharmacy/GeoPharmacy";
import { Pharmacy } from "../../models/pharmacy/Pharmacy";
import logger from "../../util/logger";
import hardcodedStrings from "./fixValues/hardcodedStrings";
import AnimalSection, { AnimalForm } from "./sections/AnimalSection";
import CommentToThePharmacistSection from "./sections/CommentToThePharmacistSection";
import FinishModal from "./sections/finish/FinishModal";
import InstructionLanguage from "./sections/InstructionLanguageSection";
import OwnerSelection from "./sections/Owner/OwnerSelection";
import PaymentSection from "./sections/PaymentSection";
import DeliveryHomeOrPharmacy from "./sections/pharmacy/DeliveryHomeOrPharmacy";
import ReceiverPharmacy from "./sections/pharmacy/ReceiverPharmacy";
import PrescriptionItemList from "./sections/PrescriptionItemList";
import ClinicPetApi from "../../api/ClinicPetApi";
import { Address } from "../../models/contact/Address";
import SettingsReminders from "./SettingsReminders";
import ProductSelectSteps from "./findProduct/ProductSelectSteps";
import { ClinicPetResponse } from "../../models/pet/ClinicPetResponse";
import Button from "../../components/Button";
import { PetOwnerResponse } from "../../models/pet/PetOwnerResponse";

const MainEPrescription: React.FC<PageProps> = (props: PageProps) => {
  const { setPageLoading } = props;
  const params = useParams<"clinicPetId">();
  const petId = params.clinicPetId;

  const location = useLocation();
  const { user } = useUser();

  const [pet, setPet] = useState<ClinicPetResponse>();
  const [weight, setWeight] = useState<number>();
  const [petOwner, setPetOwner] = useState<PetOwnerResponse>();
  const [medicalRecordId, setMedicalRecordId] = useState<string>();

  const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);
  const [packageTypes, setPackageTypes] = useState<PackageType[]>([]);
  const [geoPharmacies, setGeoPharmacies] = useState<GeoPharmacy[]>([]);
  const [finishModal, setFinishModal] = useState<boolean>(false);

  const [animalCarerType, setAnimalCarerType] = useState<AnimalCarerTypeEnum>(
    AnimalCarerTypeEnum.PERSONALID
  );
  const [pharmacyType, setPharmacyType] = useState<PharmacySelectionTypeEnum>(
    PharmacySelectionTypeEnum.ALL
  );
  const [deliveryType, setDeliveryType] = useState<DeliverySelectionTypeEnum>(
    DeliverySelectionTypeEnum.NONE
  );
  const [prescription, setPrescription] = useState<PrescriptionItemFrontend[]>(
    []
  );
  const [licensedPrescription, setLicensedPrescription] = useState<
    LicensedPrescriptionItemFrontend[]
  >([]);
  const [commodityPrescription, setCommodityPrescription] = useState<
    CommodityPrescriptionItemFrontend[]
  >([]);
  const [selectedFeeType, setSelectedFeeType] = useState<FeeType>(
    FeeType.AUTOMATIC
  );
  const [feeAmount, setFeeAmount] = useState<number>(0);

  const commentMethods = useForm<{ prescriptionComment: string }>({
    mode: "onChange",
  });
  const {
    getValues: getCommentValue,
    formState: { isValid: isCommentValid },
  } = commentMethods;

  const homeDeliveryFormMethods = useForm<
    ExtendedDelivery & { deliveryType: DeliverySelectionTypeEnum }
  >({
    mode: "onChange",
    defaultValues: {
      deliveryType: DeliverySelectionTypeEnum.NONE,
    },
  });

  const [receiverPharmacySpecific, setReceiverPharmacySpecific] = useState<
    Pharmacy | undefined
  >();
  const [receiverPharmacyValid, setReceiverPharmacyValid] =
    useState<boolean>(true);
  const [instructionLanguage, setInstructionLanguage] = useState<string>();

  /* Animal carer */
  const [animalCarer, setAnimalCarer] = useState<AnimalCarer | undefined>();
  const [isAnimalCarerFilled, setIsAnimalCarerFilled] =
    useState<boolean>(false);
  const [refreshAnimalCarer, setRefreshAnimalCarer] = useState<boolean>(false);

  /* Animal */
  const [animal, setAnimal] = useState<Animal | undefined>();

  const animalFormMethods = useForm<AnimalForm>({
    mode: "onChange",
    defaultValues: {
      animalId: "",
      animalIdType: AnimalType.INDIVIDUAL,
      speciesCode: "51",
    },
  });
  const {
    getValues: getAnimalValues,
    formState: { isValid: isAnimalValid },
  } = animalFormMethods;

  useEffect(() => {
    const getPetById = async (pId: string) => {
      try {
        const response = await ClinicPetApi.getClinicPet(pId);
        setPet(response.data.pet);
        setPetOwner(response.data.petOwner);
      } catch (err) {
        logger.error(err);
      } finally {
        setPageLoading(false);
      }
    };
    if (petId) {
      void getPetById(petId);
    } else {
      setPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petId]);

  useEffect(() => {
    setWeight(location.state?.weight);
    setMedicalRecordId(location.state?.medicalRecordId);
  }, [location]);

  const refUnapproved = useRef(null);
  const refPrescriptionList = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const getPharmacies = async () => {
      try {
        const response = await PharmacyApi.getPharmacies();
        setPharmacies(response.data);
      } catch (err) {
        logger.error(err);
      }
    };
    void getPharmacies();
  }, []);

  useEffect(() => {
    const getPackageTypes = async () => {
      try {
        const response = await VaraApi.getPackageTypes();
        setPackageTypes(response.data);
      } catch (err) {
        logger.error(err);
      }
    };

    void getPackageTypes();
  }, []);

  useEffect(() => {
    const getGeoFromAddresses = async (addresses: Address[]) => {
      try {
        const response = await GeoApi.getGeoFromAddresses(addresses);
        const resultPharmacies: GeoPharmacy[] = [];
        response.data.forEach((googlePharmacy: any, index: number) => {
          const temp: GeoPharmacy = pharmacies[index];

          if (
            googlePharmacy[0] &&
            googlePharmacy[0].geometry &&
            googlePharmacy[0].geometry.location
          ) {
            temp.lat = googlePharmacy[0].geometry?.location.lat;
            temp.lng = googlePharmacy[0].geometry?.location.lng;
            resultPharmacies.push(temp);
          }
        });
        setGeoPharmacies(resultPharmacies);
      } catch (err) {
        logger.error(err);
      }
    };

    if (pharmacies.length > 0) {
      const addresses: Address[] = [];

      pharmacies.forEach((p: Pharmacy) => {
        const address: Address = {
          zip: p.zipCode || "",
          street: p.officeAddress || "",
          city: p.city || "",
          countryCode: hardcodedStrings.swedishCountryCode,
        };
        addresses.push(address);
      });

      void getGeoFromAddresses(addresses);
    }
  }, [pharmacies]);

  const addToPrescription = (newItem: PrescriptionItemFrontend) => {
    newItem.id = prescription.length;
    const temp: PrescriptionItemFrontend[] = [...prescription];

    temp.push(newItem);
    setPrescription(temp);

    refPrescriptionList?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const updatePrescription = (updateItem: PrescriptionItemFrontend): void => {
    const temp: PrescriptionItemFrontend[] = [...prescription];
    temp[updateItem.id] = updateItem;
    setPrescription(temp);
  };

  const deletePrescriptionItem = (id: number): void => {
    const temp: PrescriptionItemFrontend[] = [...prescription];
    temp.splice(id, 1);
    setPrescription(temp);
  };

  const addToLicensedPrescription = (
    newItem: LicensedPrescriptionItemFrontend
  ) => {
    newItem.id = licensedPrescription.length;
    const temp: LicensedPrescriptionItemFrontend[] = [...licensedPrescription];
    temp.push(newItem);
    setLicensedPrescription(temp);

    refPrescriptionList?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const updateLicensedPrescription = (
    updateItem: LicensedPrescriptionItemFrontend
  ): void => {
    const temp: LicensedPrescriptionItemFrontend[] = [...licensedPrescription];
    temp[updateItem.id] = updateItem;
    setLicensedPrescription(temp);
  };

  const deleteLicensedPrescriptionItem = (id: number): void => {
    const temp: LicensedPrescriptionItemFrontend[] = [...licensedPrescription];
    temp.splice(id, 1);
    setLicensedPrescription(temp);
  };

  const addToCommodityPrescription = (
    newItem: CommodityPrescriptionItemFrontend
  ) => {
    newItem.id = prescription.length;
    const temp: CommodityPrescriptionItemFrontend[] = [
      ...commodityPrescription,
    ];
    temp.push(newItem);
    setCommodityPrescription(temp);

    refPrescriptionList?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const updateCommodityPrescription = (
    updateItem: CommodityPrescriptionItemFrontend
  ): void => {
    const temp: CommodityPrescriptionItemFrontend[] = [
      ...commodityPrescription,
    ];
    temp[updateItem.id] = updateItem;
    setCommodityPrescription(temp);
  };

  const deleteCommodityPrescriptionItem = (id: number): void => {
    const temp: CommodityPrescriptionItemFrontend[] = [
      ...commodityPrescription,
    ];
    temp.splice(id, 1);
    setCommodityPrescription(temp);
  };

  const pharmacyTypeChanged = (type: PharmacySelectionTypeEnum) => {
    setPharmacyType(type);
  };

  const deliveryTypeChanged = (type: DeliverySelectionTypeEnum) => {
    setDeliveryType(type);
  };

  const specificPharmacyResult = (p: Pharmacy | undefined) => {
    setReceiverPharmacySpecific(p);
  };

  const selectReceiverPharmacy = (): Pharmacy | undefined => {
    let receiverPharmacy;

    if (
      pharmacyType === PharmacySelectionTypeEnum.SPECIFIC ||
      pharmacyType === PharmacySelectionTypeEnum.PARTNER
    ) {
      receiverPharmacy = receiverPharmacySpecific;
    } else {
      receiverPharmacy = undefined;
    }
    return receiverPharmacy;
  };

  const selectDelivery = (): ExtendedDelivery | undefined => {
    let deliveryResponse: ExtendedDelivery | undefined;

    if (deliveryType === DeliverySelectionTypeEnum.PHARMACY) {
      deliveryResponse = {
        pharmacy: homeDeliveryFormMethods.getValues().pharmacy,
      };
    } else if (deliveryType === DeliverySelectionTypeEnum.HOME) {
      const { streetAddress, city, building, postalCode } =
        homeDeliveryFormMethods.getValues();

      deliveryResponse = {
        streetAddress,
        city,
        building,
        postalCode,
        pharmacy: undefined,
      };
    } else {
      deliveryResponse = undefined;
    }

    return deliveryResponse;
  };

  const finishButtonDisabled = () => {
    let result = true;

    result =
      result &&
      (prescription.length > 0 ||
        licensedPrescription.length > 0 ||
        commodityPrescription.length > 0);

    result = result && isAnimalValid;
    result = result && isAnimalCarerFilled;
    result = result && homeDeliveryFormMethods.formState.isValid;
    result = result && receiverPharmacyValid;
    result = result && isCommentValid;

    return !result;
  };

  const getAnimal = () => {
    setAnimal({
      animalIds: [
        ...[getAnimalValues("animalId")],
        ...getAnimalValues("additionalAnimalIds").map(
          (a: { value: string }) => a.value
        ),
      ],
      animalIdType: getAnimalValues("animalIdType"),
      internalAnimalIds: petId
        ? [petId.toString()]
        : [hardcodedStrings.defaultPetId],
      speciesCode: getAnimalValues("speciesCode"),
    });
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.prescription}
            </h1>
            <AlertBox
              type={AlertType.WARNING}
              hidden={!user?.hasUnconfirmedPinCode}
              message={strings.pinWarning}
              className="mt-3"
            />
            <div
              className="grid gap-4 lg:grid-cols-2"
              ref={refPrescriptionList}
            >
              <FormProvider {...animalFormMethods}>
                <AnimalSection pet={pet} petId={petId} petWeight={weight} />
              </FormProvider>
              <OwnerSelection
                defaultInputType={animalCarerType}
                isPhoneRequired={
                  pharmacyType === PharmacySelectionTypeEnum.SPECIFIC ||
                  deliveryType === DeliverySelectionTypeEnum.HOME
                }
                isPharmacySelected={!!receiverPharmacySpecific}
                isValid={(isValid: boolean) => {
                  setIsAnimalCarerFilled(isValid);
                }}
                petOwner={petOwner}
                refresh={refreshAnimalCarer}
                setAnimalCarer={(a: AnimalCarer | undefined) => {
                  setAnimalCarer(a);
                }}
                setAnimalCarerType={(t: AnimalCarerTypeEnum) => {
                  setAnimalCarerType(t);
                }}
              />
            </div>
            <div className="grid md:grid-cols-4 gap-4">
              <div className="md:col-span-3" ref={refUnapproved}>
                <div className="w-full tw-card space-y-6 p-4">
                  <ProductSelectSteps
                    addPrescriptionItem={addToPrescription}
                    addCommodityPrescriptionPrescriptionItem={
                      addToCommodityPrescription
                    }
                    addLicensedPrescriptionItem={addToLicensedPrescription}
                    geoPharmacies={geoPharmacies}
                    packageTypes={packageTypes}
                  />
                </div>
              </div>
              <div>
                <div className="grid gap-4 grid-rows-7 md:col-span-1">
                  <PrescriptionItemList
                    geoPharmacies={geoPharmacies}
                    packageTypes={packageTypes}
                    prescription={prescription}
                    licensedPrescription={licensedPrescription}
                    commodityPrescription={commodityPrescription}
                    updatePrescription={updatePrescription}
                    deletePrescriptionItem={deletePrescriptionItem}
                    updateLicensedPrescription={updateLicensedPrescription}
                    deleteLicensedPrescriptionItem={
                      deleteLicensedPrescriptionItem
                    }
                    updateCommodityPrescription={updateCommodityPrescription}
                    deleteCommodityPrescriptionItem={
                      deleteCommodityPrescriptionItem
                    }
                  />
                  <InstructionLanguage
                    setLanguage={(lan: string) => {
                      setInstructionLanguage(lan);
                    }}
                  />
                  <ReceiverPharmacy
                    geoPharmacies={geoPharmacies}
                    animalCarerType={animalCarerType}
                    specificPharmacyResult={specificPharmacyResult}
                    pharmacyTypeChanged={pharmacyTypeChanged}
                    deliveryType={deliveryType}
                    isValid={(isValid: boolean) => {
                      setReceiverPharmacyValid(isValid);
                    }}
                  />
                  <FormProvider {...homeDeliveryFormMethods}>
                    <DeliveryHomeOrPharmacy
                      geoPharmacies={geoPharmacies}
                      owner={petOwner?.petOwnerDetails}
                      deliveryTypeChanged={deliveryTypeChanged}
                      isPharmacySelected={!!receiverPharmacySpecific}
                    />
                  </FormProvider>
                  <PaymentSection
                    defaultPaymentType={selectedFeeType}
                    paymentResult={setSelectedFeeType}
                    amountResult={setFeeAmount}
                  />
                  <FormProvider {...commentMethods}>
                    <CommentToThePharmacistSection />
                  </FormProvider>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setRefreshAnimalCarer((prevState) => !prevState);
                      getAnimal();
                      setFinishModal(true);
                    }}
                    disabled={finishButtonDisabled()}
                  >
                    {strings.finishPrescription}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FinishModal
        isOpen={finishModal}
        close={() => {
          setFinishModal(false);
        }}
        animal={animal}
        animalCarer={animalCarer}
        prescription={prescription}
        licensedPrescription={licensedPrescription}
        commodityPrescription={commodityPrescription}
        instructionLanguage={instructionLanguage}
        delivery={selectDelivery()}
        receiverPharmacy={selectReceiverPharmacy()}
        comment={getCommentValue("prescriptionComment")}
        medicalRecordId={medicalRecordId}
        selectedFeeType={selectedFeeType}
        feeAmount={feeAmount}
      />
      <SettingsReminders />
    </main>
  );
};

export default Loader(MainEPrescription);
