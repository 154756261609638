/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  forwardRef,
  ReactElement,
  Ref,
  useImperativeHandle,
  useState,
} from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  PaymentIntentResult,
  PaymentMethodResult,
  StripeCardElementChangeEvent,
} from "@stripe/stripe-js";
import { strings } from "../../common/Strings/Strings";
import SimpleInput from "../../components/InputFieldsSimple/SimpleInput";
import AlertBox from "../../components/AlertBox";

interface Props {
  cardHolderName?: string;
  onValidation: (isValid: boolean) => void;
}

export interface OnlinePaymentFormRef {
  createPaymentMethod(): Promise<PaymentMethodResult> | undefined;
  confirmPayment(
    clientSecret: string
  ): Promise<PaymentIntentResult> | undefined;
  getCardHolder(): string | undefined;
}

const OnlinePaymentForm = forwardRef(
  (
    { cardHolderName, onValidation }: Props,
    ref: Ref<OnlinePaymentFormRef>
  ): ReactElement => {
    const stripe = useStripe();
    const elements = useElements();

    const [alert, setAlert] = useState<string | null>(null);
    const [validCard, setValidCard] = useState<boolean>(false);
    const [cardHolder, setCardHolder] = useState<string | undefined>(
      cardHolderName
    );

    useImperativeHandle(ref, () => ({
      createPaymentMethod(): Promise<PaymentMethodResult> | undefined {
        if (stripe == null || elements == null) return undefined;

        const cardElement = elements.getElement(CardElement);

        if (cardElement == null) return undefined;

        return stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: cardHolder,
          },
        });
      },

      confirmPayment(
        clientSecret: string
      ): Promise<PaymentIntentResult> | undefined {
        if (stripe == null || elements == null) return undefined;

        const cardElement = elements.getElement(CardElement);

        if (cardElement == null) return undefined;

        return stripe.handleCardAction(clientSecret);
      },

      getCardHolder(): string | undefined {
        return cardHolder;
      },
    }));

    const onChange = (ev: StripeCardElementChangeEvent) => {
      if (ev.error) {
        setAlert(ev.error.message);
      } else {
        setAlert(null);
        setValidCard(ev.complete);
        onValidation(ev.complete && cardHolder !== undefined);
      }
    };

    const changeCardHolderName = (name: string) => {
      setCardHolder(name);
      onValidation(validCard && name !== undefined);
    };

    return (
      <div className="d-flex flex-column">
        <div className="px-3 pt-1">
          <SimpleInput
            name="cardHolder"
            label={strings.name}
            onChange={value => changeCardHolderName(value)}
          />
        </div>
        <div className="m-3 p-3 tw-card">
          <CardElement onChange={onChange} />
        </div>
        <AlertBox message={alert} className="m-3" />
        <div className="col-sm-1 justify-content-center align-self-center">
          <i className="fas fa-check tick small" hidden={!validCard} />
        </div>
      </div>
    );
  }
);

export default OnlinePaymentForm;

OnlinePaymentForm.defaultProps = {
  cardHolderName: undefined,
};
