/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const breedsEn = {
  DOG: [
    "Affenpinscher",
    "Afghan Hound",
    "Aidi",
    "Airedale Terrier",
    "Akbash",
    "Akita",
    "Aksaray Malaklisi",
    "Alano Español",
    "Alapaha Blue Blood Bulldog",
    "Alaskan Klee Kai",
    "Alaskan Malamute",
    "Alaskan husky",
    "Alaunt",
    "Alopekis",
    "Alpine Dachsbracke",
    "Alpine Mastiff",
    "American Bulldog",
    "American Bully",
    "American Cocker Spaniel",
    "American English Coonhound",
    "American Eskimo Dog",
    "American Foxhound",
    "American Hairless Terrier",
    "American Pit Bull Terrier",
    "American Staffordshire Terrier",
    "American Water Spaniel",
    "Andalusian Hound",
    "Anglo-Français de Petite Vénerie",
    "Appenzeller Sennenhund",
    "Argentine Polar Dog",
    "Ariegeois",
    "Armant",
    "Armenian Gampr dog",
    "Artois Hound",
    "Australian Cattle Dog",
    "Australian Kelpie",
    "Australian Shepherd",
    "Australian Stumpy Tail Cattle Dog",
    "Australian Terrier",
    "Austrian Black and Tan Hound",
    "Austrian Pinscher",
    "Azawakh",
    "Bakharwal dog",
    "Banjara Hound",
    "Barbado da Terceira",
    "Barbet",
    "Basenji",
    "Basque Shepherd Dog",
    "Basset Artésien Normand",
    "Basset Bleu de Gascogne",
    "Basset Fauve de Bretagne",
    "Basset Hound",
    "Bavarian Mountain Hound",
    "Beagle",
    "Beagle-Harrier",
    "Bearded Collie",
    "Beauceron",
    "Bedlington Terrier",
    "Belgian Mastiff",
    "Belgian Shepherd",
    "Bergamasco Shepherd",
    "Berger Picard",
    "Bernese Mountain Dog",
    "Bichon Frisé",
    "Billy",
    "Black Mouth Cur",
    "Black Norwegian Elkhound",
    "Black Russian Terrier",
    "Black and Tan Coonhound",
    "Black and Tan Terrier",
    "Bloodhound",
    "Blue Lacy",
    "Blue Paul Terrier",
    "Blue Picardy Spaniel",
    "Bluetick Coonhound",
    "Boerboel",
    "Bohemian Shepherd",
    "Bolognese",
    "Border Collie",
    "Border Terrier",
    "Borzoi",
    "Bosnian Coarse-haired Hound",
    "Boston Terrier",
    "Bouvier des Ardennes",
    "Bouvier des Flandres",
    "Boxer",
    "Boykin Spaniel",
    "Bracco Italiano",
    "Braque Dupuy",
    "Braque Francais",
    "Braque Saint-Germain",
    "Braque d'Auvergne",
    "Braque de l'Ariège",
    "Braque du Bourbonnais",
    "Briard",
    "Briquet Griffon Vendéen",
    "Brittany",
    "Broholmer",
    "Bruno Jura Hound",
    "Brussels Griffon",
    "Bucovina Shepherd Dog",
    "Bull Arab",
    "Bull Terrier",
    "Bull and terrier",
    "Bulldog",
    "Bullenbeisser",
    "Bullmastiff",
    "Bully Kutta",
    "Burgos Pointer",
    "Ca Mè Mallorquí",
    "Cairn Terrier",
    "Campeiro Bulldog",
    "Can de Chira",
    "Can de Palleiro",
    "Canaan Dog",
    "Canadian Eskimo Dog",
    "Cane Corso",
    "Cane Paratore",
    "Cane di Oropa",
    "Cantabrian Water Dog",
    "Cardigan Welsh Corgi",
    "Carea Castellano Manchego",
    "Carea Leonés",
    "Carolina Dog",
    "Carpathian Shepherd Dog",
    "Catahoula Leopard Dog",
    "Catalan Sheepdog",
    "Caucasian Shepherd Dog",
    "Cavalier King Charles Spaniel",
    "Central Asian Shepherd Dog",
    "Cesky Fousek",
    "Cesky Terrier",
    "Chesapeake Bay Retriever",
    "Chien Français Blanc et Noir",
    "Chien Français Blanc et Orange",
    "Chien Français Tricolore",
    "Chien-gris",
    "Chihuahua",
    "Chilean Terrier",
    "Chinese Chongqing Dog",
    "Chinese Crested Dog",
    "Chinook",
    "Chippiparai",
    "Chiribaya Dog",
    "Chongqing dog",
    "Chortai",
    "Chow Chow",
    "Cimarrón Uruguayo",
    "Cirneco dell'Etna",
    "Clumber Spaniel",
    "Colombian fino hound",
    "Cordoba Fighting Dog",
    "Coton de Tulear",
    "Cretan Hound",
    "Croatian Sheepdog",
    "Cumberland Sheepdog",
    "Cur",
    "Curly-Coated Retriever",
    "Cursinu",
    "Czechoslovakian Wolfdog",
    "Cão Fila de São Miguel",
    "Cão da Serra de Aires",
    "Cão de Castro Laboreiro",
    "Cão de Gado Transmontano",
    "Dachshund",
    "Dalbo dog",
    "Dalmatian",
    "Dandie Dinmont Terrier",
    "Danish Spitz",
    "Danish-Swedish Farmdog",
    "Denmark Feist",
    "Dingo",
    "Dobermann",
    "Dogo Argentino",
    "Dogo Cubano",
    "Dogo Guatemalteco",
    "Dogo Sardesco",
    "Dogue Brasileiro",
    "Dogue de Bordeaux",
    "Drentse Patrijshond",
    "Drever",
    "Dumfriesshire Black and Tan Foxhound",
    "Dunker",
    "Dutch Shepherd",
    "Dutch Smoushond",
    "East European Shepherd",
    "East Siberian Laika",
    "Ecuadorian Hairless Dog",
    "English Cocker Spaniel",
    "English Foxhound",
    "English Mastiff",
    "English Setter",
    "English Shepherd",
    "English Springer Spaniel",
    "English Toy Terrier",
    "English Water Spaniel",
    "English White Terrier",
    "Entlebucher Mountain Dog",
    "Estonian Hound",
    "Estrela Mountain Dog",
    "Eurasier",
    "Field Spaniel",
    "Fila Brasileiro",
    "Fila da Terceira",
    "Finnish Hound",
    "Finnish Lapphund",
    "Finnish Spitz",
    "Flat-Coated Retriever",
    "French Bulldog",
    "French Spaniel",
    "Galgo Español",
    "Garafian Shepherd",
    "Gascon Saintongeois",
    "Georgian Shepherd",
    "German Hound",
    "German Longhaired Pointer",
    "German Pinscher",
    "German Roughhaired Pointer",
    "German Shepherd Dog",
    "German Shorthaired Pointer",
    "German Spaniel",
    "German Spitz",
    "German Wirehaired Pointer",
    "Giant Schnauzer",
    "Glen of Imaal Terrier",
    "Golden Retriever",
    "Gordon Setter",
    "Gończy Polski",
    "Grand Anglo-Français Blanc et Noir",
    "Grand Anglo-Français Blanc et Orange",
    "Grand Anglo-Français Tricolore",
    "Grand Basset Griffon Vendéen",
    "Grand Bleu de Gascogne",
    "Grand Fauve de Bretagne",
    "Grand Griffon Vendéen",
    "Great Dane",
    "Greater Swiss Mountain Dog",
    "Greek Harehound",
    "Greek Shepherd",
    "Greenland Dog",
    "Greyhound",
    "Griffon Bleu de Gascogne",
    "Griffon Fauve de Bretagne",
    "Griffon Nivernais",
    "Gull Dong",
    "Gull Terrier",
    "Halden Hound",
    "Halls Heeler",
    "Hamiltonstövare",
    "Hanover Hound",
    "Hare Indian Dog",
    "Harrier",
    "Havanese",
    "Hawaiian Poi Dog",
    "Hierran Wolfdog",
    "Himalayan Sheepdog",
    "Hokkaido",
    "Hovawart",
    "Huntaway",
    "Hygen Hound",
    "Hällefors Elkhound",
    "Ibizan Hound",
    "Icelandic Sheepdog",
    "Indian Spitz",
    "Indian pariah dog",
    "Irish Red and White Setter",
    "Irish Setter",
    "Irish Terrier",
    "Irish Water Spaniel",
    "Irish Wolfhound",
    "Istrian Coarse-haired Hound",
    "Istrian Shorthaired Hound",
    "Italian Greyhound",
    "Jack Russell Terrier",
    "Jagdterrier",
    "Japanese Chin",
    "Japanese Spitz",
    "Japanese Terrier",
    "Jindo",
    "Jonangi",
    "Kai Ken",
    "Kaikadi",
    "Kangal Shepherd Dog",
    "Kanni",
    "Karakachan dog",
    "Karelian Bear Dog",
    "Kars",
    "Karst Shepherd",
    "Keeshond",
    "Kerry Beagle",
    "Kerry Blue Terrier",
    "Khala",
    "King Charles Spaniel",
    "King Shepherd",
    "Kintamani",
    "Kishu",
    "Kokoni",
    "Kombai",
    "Komondor",
    "Kooikerhondje",
    "Koolie",
    "Koyun dog",
    "Kromfohrländer",
    "Kuchi",
    "Kurī",
    "Kuvasz",
    "Labrador Retriever",
    "Lagotto Romagnolo",
    "Lakeland Terrier",
    "Lancashire Heeler",
    "Landseer",
    "Lapponian Herder",
    "Lapponian Shepherd",
    "Large Münsterländer",
    "Leonberger",
    "Levriero Sardo",
    "Lhasa Apso",
    "Limer",
    "Lithuanian Hound",
    "Lupo Italiano",
    "Löwchen",
    "Mackenzie River husky",
    "Magyar agár",
    "Mahratta Greyhound",
    "Maltese",
    "Manchester Terrier",
    "Maremmano-Abruzzese Sheepdog",
    "Marquesan Dog",
    "McNab dog",
    "Miniature American Shepherd",
    "Miniature Bull Terrier",
    "Miniature Fox Terrier",
    "Miniature Pinscher",
    "Miniature Schnauzer",
    "Molossus",
    "Molossus of Epirus",
    "Montenegrin Mountain Hound",
    "Moscow Water Dog",
    "Mountain Cur",
    "Mountain Feist",
    "Mucuchies",
    "Mudhol Hound",
    "Mudi",
    "Neapolitan Mastiff",
    "New Guinea singing dog",
    "New Zealand Heading Dog",
    "Newfoundland",
    "Norfolk Spaniel",
    "Norfolk Terrier",
    "Norrbottenspets",
    "North Country Beagle",
    "Northern Inuit Dog",
    "Norwegian Buhund",
    "Norwegian Elkhound",
    "Norwegian Lundehund",
    "Norwich Terrier",
    "Nova Scotia Duck Tolling Retriever",
    "Old Croatian Sighthound",
    "Old Danish Pointer",
    "Old English Bulldog",
    "Old English Sheepdog",
    "Old English Terrier",
    "Old Spanish Pointer",
    "Olde English Bulldogge",
    "Otterhound",
    "Pachon Navarro",
    "Paisley Terrier",
    "Pampas Deerhound",
    "Papillon",
    "Parson Russell Terrier",
    "Pastore della Lessinia e del Lagorai",
    "Patagonian Sheepdog",
    "Patterdale Terrier",
    "Pekingese",
    "Pembroke Welsh Corgi",
    "Perro Majorero",
    "Perro de Pastor Mallorquin",
    "Perro de Presa Canario",
    "Perro de Presa Mallorquin",
    "Peruvian Inca Orchid",
    "Petit Basset Griffon Vendéen",
    "Petit Bleu de Gascogne",
    "Phalène",
    "Pharaoh Hound",
    "Phu Quoc Ridgeback",
    "Picardy Spaniel",
    "Plott Hound",
    "Plummer Terrier",
    "Podenco Canario",
    "Podenco Valenciano",
    "Pointer",
    "Poitevin",
    "Polish Greyhound",
    "Polish Hound",
    "Polish Lowland Sheepdog",
    "Polish Tatra Sheepdog",
    "Polynesian Dog",
    "Pomeranian",
    "Pont-Audemer Spaniel",
    "Poodle",
    "Porcelaine",
    "Portuguese Podengo",
    "Portuguese Pointer",
    "Portuguese Water Dog",
    "Posavac Hound",
    "Pražský Krysařík",
    "Pshdar dog",
    "Pudelpointer",
    "Pug",
    "Puli",
    "Pumi",
    "Pungsan dog",
    "Pyrenean Mastiff",
    "Pyrenean Mountain Dog",
    "Pyrenean Sheepdog",
    "Rache",
    "Rafeiro do Alentejo",
    "Rajapalayam",
    "Rampur Greyhound",
    "Rastreador Brasileiro",
    "Rat Terrier",
    "Ratonero Bodeguero Andaluz",
    "Ratonero Mallorquin",
    "Ratonero Murciano",
    "Ratonero Valenciano",
    "Redbone Coonhound",
    "Rhodesian Ridgeback",
    "Romanian Mioritic Shepherd Dog",
    "Romanian Raven Shepherd Dog",
    "Rottweiler",
    "Rough Collie",
    "Russian Spaniel",
    "Russian Toy",
    "Russian Tracker",
    "Russo-European Laika",
    "Ryukyu Inu",
    "Saarloos Wolfdog",
    "Sabueso Español",
    "Saint Bernard",
    "Saint Hubert Jura Hound",
    "Saint John's water dog",
    "Saint-Usuge Spaniel",
    "Sakhalin Husky",
    "Salish Wool Dog",
    "Saluki",
    "Samoyed",
    "Sapsali",
    "Sarabi dog",
    "Sardinian Shepherd Dog",
    "Schapendoes",
    "Schillerstövare",
    "Schipperke",
    "Schweizer Laufhund",
    "Schweizerischer Niederlaufhund",
    "Scottish Deerhound",
    "Scottish Terrier",
    "Sealyham Terrier",
    "Segugio Italiano",
    "Segugio Maremmano",
    "Segugio dell'Appennino",
    "Serbian Hound",
    "Serbian Tricolour Hound",
    "Serrano Bulldog",
    "Shar Pei",
    "Shetland Sheepdog",
    "Shiba Inu",
    "Shih Tzu",
    "Shikoku",
    "Shiloh Shepherd",
    "Siberian Husky",
    "Silken Windhound",
    "Silky Terrier",
    "Sinhala Hound",
    "Skye Terrier",
    "Sleuth hound",
    "Sloughi",
    "Slovakian Wirehaired Pointer",
    "Slovenský Cuvac",
    "Slovenský Kopov",
    "Smalandstövare",
    "Small Greek domestic dog",
    "Small Münsterländer",
    "Smooth Collie",
    "Smooth Fox Terrier",
    "Soft-Coated Wheaten Terrier",
    "South Russian Ovcharka",
    "Southern Hound",
    "Spanish Mastiff",
    "Spanish Water Dog",
    "Spinone Italiano",
    "Sporting Lucas Terrier",
    "Stabyhoun",
    "Staffordshire Bull Terrier",
    "Standard Schnauzer",
    "Stephens Stock",
    "Styrian Coarse-haired Hound",
    "Sussex Spaniel",
    "Swedish Elkhound",
    "Swedish Lapphund",
    "Swedish Vallhund",
    "Swedish White Elkhound",
    "Tahitian Dog",
    "Tahltan Bear Dog",
    "Taigan",
    "Taiwan Dog",
    "Talbot Hound",
    "Tamaskan Dog",
    "Tazy",
    "Teddy Roosevelt Terrier",
    "Telomian",
    "Tenterfield Terrier",
    "Terrier Brasileiro",
    "Tesem",
    "Thai Bangkaew Dog",
    "Thai Ridgeback",
    "Tibetan Mastiff",
    "Tibetan Spaniel",
    "Tibetan Terrier",
    "Tornjak",
    "Tosa",
    "Toy Bulldog",
    "Toy Fox Terrier",
    "Toy Manchester Terrier",
    "Toy Trawler Spaniel",
    "Transylvanian Hound",
    "Treeing Cur",
    "Treeing Feist",
    "Treeing Tennessee Brindle",
    "Treeing Walker Coonhound",
    "Trigg Hound",
    "Turnspit dog",
    "Tweed Water Spaniel",
    "Tyrolean Hound",
    "Vikhan",
    "Villano de Las Encartaciones",
    "Villanuco de Las Encartaciones",
    "Vizsla",
    "Volpino Italiano",
    "Weimaraner",
    "Welsh Hillman",
    "Welsh Sheepdog",
    "Welsh Springer Spaniel",
    "Welsh Terrier",
    "West Highland White Terrier",
    "West Siberian Laika",
    "Westphalian Dachsbracke",
    "Wetterhoun",
    "Whippet",
    "White Shepherd",
    "White Swiss Shepherd Dog",
    "Wire Fox Terrier",
    "Wirehaired Pointing Griffon",
    "Wirehaired Vizsla",
    "Xiasi Dog",
    "Xoloitzcuintle",
    "Yakutian Laika",
    "Yorkshire Terrier",
    "Šarplaninac"
  ],
  CAT: [
    "Abyssinian",
    "Aegean",
    "American Bobtail",
    "American Curl",
    "American Ringtail",
    "American Shorthair",
    "American Wirehair",
    "Aphrodite Giant",
    "Arabian Mau",
    "Asian",
    "Asian Semi-longhair",
    "Australian Mist",
    "Balinese",
    "Bambino",
    "Bengal",
    "Birman",
    "Bombay",
    "Brazilian Shorthair",
    "Breeds",
    "British Longhair",
    "British Shorthair",
    "Burmese",
    "Burmilla",
    "California Spangled",
    "Chantilly-Tiffany",
    "Chartreux",
    "Chausie",
    "Colorpoint Shorthair",
    "Cornish Rex",
    "Cymric",
    "Cyprus",
    "Devon Rex",
    "Donskoy",
    "Dragon Li",
    "Dwelf",
    "Egyptian Mau",
    "European Shorthair",
    "Exotic Shorthair",
    "Foldex",
    "German Rex",
    "Havana Brown",
    "Highlander",
    "Himalayan",
    "Japanese Bobtail",
    "Javanese",
    "Kanaani",
    "Khao Manee",
    "Kinkalow",
    "Korat",
    "Korean Bobtail",
    "Korn Ja",
    "Kurilian Bobtail",
    "LaPerm",
    "Lambkin",
    "Lykoi",
    "Maine Coon",
    "Manx",
    "Mekong Bobtail",
    "Minskin",
    "Munchkin",
    "Napoleon",
    "Nebelung",
    "Norwegian Forest Cat",
    "Ocicat",
    "Ojos Azules",
    "Oregon Rex",
    "Oriental Bicolor",
    "Oriental Longhair",
    "Oriental Shorthair",
    "Persian",
    "Peterbald",
    "Pixie-bob",
    "Raas",
    "Ragamuffin",
    "Ragdoll",
    "Russian Blue",
    "Russian White",
    "Sam Sawet",
    "Savannah",
    "Scottish Fold",
    "Selkirk Rex",
    "Serengeti",
    "Serrade Petit",
    "Siamese",
    "Siberian",
    "Singapura",
    "Snowshoe",
    "Sokoke",
    "Somali",
    "Sphynx",
    "Suphalak",
    "Thai",
    "Thai Lilac",
    "Tonkinese",
    "Toyger",
    "Turkish Angora",
    "Turkish Van",
    "Turkish Vankedisi",
    "Ukrainian Levkoy",
    "York Chocolate"
  ],
  HORSE: [
    "Abaco Barb",
    "Abtenauer",
    "Abyssinian",
    "Aegidienberger",
    "Akhal-Teke",
    "Albanian horse",
    "Altai horse",
    "Altèr Real",
    "American Cream Draft",
    "American Indian Horse",
    "American Paint Horse",
    "American Quarter Horse",
    "American Saddlebred",
    "American Shetland Pony",
    "American Walking Pony",
    "American Warmblood",
    "Anadolu pony",
    "Andalusian horse",
    "Andravida horse",
    "Angevin horse",
    "Anglo-Arabian",
    "Anglo-Arabo Sardo",
    "Anglo-Kabarda",
    "Anglo-Norman horse",
    "Appaloosa",
    "Arabian horse",
    "Ardennes horse",
    "Arenberg-Nordkirchen",
    "Argentine Criollo",
    "Asian wild horse",
    "Assateague Pony",
    "Assateague horse",
    "Asturcón",
    "Augeron horse",
    "Australian Brumby",
    "Australian Draught Horse",
    "Australian Pony",
    "Australian Riding Pony",
    "Australian Stock Horse",
    "Austrian Warmblood",
    "Auvergne horse",
    "Auxois",
    "Azer At",
    "Azerbaijan horse",
    "Azteca horse",
    "Baise horse",
    "Bale",
    "Balearic horse",
    "Bali Pony",
    "Balikun horse",
    "Baluchi horse",
    "Banker horse",
    "Barb horse",
    "Bardigiano",
    "Bashkir Curly",
    "Bashkir horse",
    "Basque Mountain Horse",
    "Basuto pony",
    "Batak Pony",
    "Bavarian Warmblood",
    "Belgian Sport Horse",
    "Belgian Trotter",
    "Belgian Warmblood",
    "Belgian horse or Belgian Draught",
    "Berrichon horse",
    "Bhutia Horse",
    "Bidet horse",
    "Black Forest Horse or Black Forest Coldblood",
    "Blazer horse",
    "Boerperd",
    "Borana",
    "Bosnian Mountain Horse",
    "Bosnian Pony",
    "Boulonnais horse",
    "Brabant",
    "Brandenburger",
    "Brazilian Sport Horse",
    "Breton horse",
    "British Riding Pony",
    "British Spotted Pony",
    "Brumby",
    "Budyonny horse or Budenny",
    "Burguete horse",
    "Burmese Horse",
    "Burmese Pony",
    "Byelorussian Harness Horse",
    "Calabrese horse",
    "Camargue horse",
    "Camarillo White Horse",
    "Campeiro",
    "Campolina",
    "Canadian Pacer",
    "Canadian horse",
    "Canadian rustic pony",
    "Cape Horse",
    "Carolina Marsh Tacky",
    "Carpathian Pony",
    "Carthusian",
    "Caspian horse",
    "Castilian",
    "Castillonnais",
    "Catria horse",
    "Cavallo Romano della Maremma Laziale",
    "Cerbat Mustang",
    "Chapman horse",
    "Charentais horse",
    "Charolais horse",
    "Cheval du Morvan",
    "Chickasaw Horse",
    "Chilean horse or Chilean Corralero",
    "Chincoteague Pony",
    "Chinese Guoxia",
    "Choctaw horse",
    "Cleveland Bay",
    "Clydesdale horse",
    "Cob",
    "Coffin Bay Pony",
    "Coldblood trotter",
    "Colonial Spanish Horse",
    "Colorado Ranger",
    "Comtois horse",
    "Connemara pony",
    "Corsican horse",
    "Costa Rican Saddle Horse",
    "Cretan horse",
    "Criollo horse",
    "Croatian Coldblood",
    "Cuban Criollo",
    "Cumberland Island horse",
    "Curly Horse",
    "Czech Warmblood",
    "Czechoslovakian Small Riding Pony",
    "Dales Pony",
    "Daliboz",
    "Danish Sport Pony",
    "Danish Warmblood",
    "Danube Delta horse",
    "Dartmoor pony",
    "Deli pony",
    "Deutsches Reitpony",
    "Dole Gudbrandsdal",
    "Don",
    "Dongola horse",
    "Draft Trotter",
    "Dutch Harness Horse",
    "Dutch Heavy Draft",
    "Dutch Warmblood",
    "Dzungarian horse",
    "Dülmen Pony",
    "East Bulgarian",
    "East Friesian horse",
    "Eriskay pony",
    "Esperia Pony",
    "Estonian Draft",
    "Estonian Native",
    "Ethiopian horses",
    "Exmoor pony",
    "Falabella",
    "Faroe pony",
    "Faroese or Faroe horse",
    "Fell Pony",
    "Ferghana horse",
    "Finnhorse",
    "Fjord horse",
    "Flemish Horse",
    "Fleuve",
    "Flores pony",
    "Florida Cracker Horse",
    "Foutanké or Fouta",
    "Frederiksborger",
    "Freiberger",
    "French Saddle Pony",
    "French Trotter",
    "Friesian Sporthorse",
    "Friesian cross",
    "Friesian horse",
    "Furioso-North Star",
    "Galiceno or Galiceño",
    "Galician Pony",
    "Galloway pony",
    "Garrano",
    "Gayoe",
    "Gelderland horse",
    "Georgian Grande Horse",
    "German Classic Pony",
    "German Riding Pony",
    "German Warmblood or ZfDP",
    "Giara Horse",
    "Gidran",
    "Gotland Pony",
    "Groningen Horse",
    "Guangxi",
    "Guizhou pony",
    "Gypsy horse",
    "Gǔo-xìa pony",
    "Hackney horse",
    "Hackney pony",
    "Haflinger",
    "Hanoverian horse",
    "Heck horse",
    "Heihe horse",
    "Henson horse",
    "Hequ horse",
    "Highland Pony",
    "Hirzai",
    "Hispano or Spanish Anglo-Arab",
    "Hispano-Bretón",
    "Hispano-Árabe",
    "Hokkaido Pony",
    "Holsteiner",
    "Horro",
    "Hucul Pony",
    "Hungarian Warmblood",
    "Hunter Pony",
    "Icelandic horse",
    "Indian Country-bred",
    "Iomud",
    "Irish Draught",
    "Irish Hobby",
    "Irish Sport Horse or Irish Hunter",
    "Italian Heavy Draft",
    "Italian Trotter",
    "Jaca Navarra",
    "Java Pony",
    "Jeju horse",
    "Jennet",
    "Jutland horse",
    "Kabarda horse",
    "Kafa",
    "Kaimanawa horses",
    "Kalmyk horse",
    "Karabair",
    "Karabakh horse",
    "Karacabey horse",
    "Karachai horse",
    "Karossier see Ostfriesen and Alt-Oldenburger",
    "Kathiawari horse",
    "Kazakh Horse",
    "Kentucky Mountain Saddle Horse",
    "Kerry bog pony",
    "Kiger Mustang",
    "Kinsky horse",
    "Kisber Felver",
    "Kiso Horse",
    "Kladruber",
    "Knabstrupper",
    "Konik",
    "Kundudo",
    "Kustanair",
    "Kyrgyz Horse",
    "Lac La Croix Indian Pony",
    "Landais Pony",
    "Latvian horse",
    "Lijiang pony",
    "Lipizzan or Lipizzaner",
    "Lithuanian Heavy Draught",
    "Lokai",
    "Losino horse",
    "Lundy Pony",
    "Lusitano",
    "Luxembourg Warmblood",
    "Lyngshest",
    "M'Bayar",
    "M'Par",
    "Mallorquín",
    "Malopolski",
    "Mangalarga",
    "Mangalarga Marchador",
    "Manipuri Pony",
    "Maremmano",
    "Marismeño",
    "Marsh Tacky",
    "Marwari horse",
    "Mazury horse",
    "Mecklenburger",
    "Menorquín horse",
    "Merens Pony",
    "Messara horse",
    "Metis Trotter",
    "Mezőhegyesi sport-horse",
    "Međimurje horse",
    "Miniature horse",
    "Misaki horse",
    "Missouri Fox Trotter",
    "Miyako Pony",
    "Monchino",
    "Mongolian Horse",
    "Mongolian Wild Horse",
    "Monterufolino",
    "Morab",
    "Morgan horse",
    "Mountain Pleasure Horse",
    "Moyle horse",
    "Mulassier",
    "Muraközi",
    "Murgese",
    "Mustang",
    "Mérens horse",
    "Namaqua Pony",
    "Namib Desert Horse",
    "Nangchen horse",
    "Narragansett Pacer",
    "Narym Pony",
    "National Show Horse",
    "Navarrin horse",
    "Neapolitan horse",
    "New Forest pony",
    "Newfoundland pony",
    "Nez Perce Horse",
    "Nisean horse",
    "Nivernais horse",
    "Nokota horse",
    "Noma horse",
    "Nonius horse",
    "Nooitgedachter",
    "Nordlandshest/ Lyngshest",
    "Norfolk Trotter",
    "Noriker horse or Pinzgauer",
    "Norman Cob",
    "Norsk Kaldblodstraver",
    "North American Single-footing Horse",
    "North Swedish Horse",
    "Norwegian Fjord",
    "Norwegian Fjord Horse",
    "Novokirghiz",
    "Novoolexandrian Draught",
    "Oberlander Horse",
    "Ogaden",
    "Old English Black horse",
    "Oldenburger",
    "Orlov Trotter",
    "Ostfriesen and Alt-Oldenburger",
    "Paint",
    "Pampa horse",
    "Paso Fino",
    "Peneia Pony",
    "Pentro horse",
    "Percheron",
    "Persano horse",
    "Peruvian Paso",
    "Petiso Argentino",
    "Pindos Pony",
    "Pintabian",
    "Pleven horse",
    "Poitevin horse",
    "Poney Mousseye",
    "Pony of the Americas",
    "Posavac",
    "Pottok",
    "Pozan",
    "Priob",
    "Pryor Mountain Mustang",
    "Przewalski's horse",
    "Pura Raza Española or PRE",
    "Purosangue Orientale",
    "Qatgani",
    "Quarab",
    "Quarter Horse",
    "Quarter pony",
    "Racking horse",
    "Retuerta horse",
    "Rhenish German Coldblood",
    "Rhineland Heavy Draft",
    "Rhinelander horse",
    "Riding Pony",
    "Riwoche horse",
    "Rocky Mountain Horse",
    "Romanian Sporthorse",
    "Rottaler",
    "Russian Don",
    "Russian Heavy Draft",
    "Russian Trotter",
    "Sable Island Pony",
    "Saddlebred",
    "Salernitano",
    "Samolaco horse",
    "Sandalwood Pony",
    "Sanfratellano",
    "Santa Cruz Island horse",
    "Sarcidano horse",
    "Sardinian Anglo-Arab",
    "Schleswig Coldblood",
    "Schwarzwälder Kaltblut",
    "Selale",
    "Sella Italiano",
    "Selle Français",
    "Senner",
    "Shagya Arabian",
    "Shan Horse or Shan Myinn",
    "Shetland pony",
    "Shire horse",
    "Siciliano indigeno",
    "Silesian horse",
    "Sindhi horse",
    "Skogsruss",
    "Skyros Pony",
    "Sokolsky horse",
    "Sorraia",
    "South German Coldblood or Süddeutsches Kaltblut",
    "Soviet Heavy Draft",
    "Spanish Barb",
    "Spanish Jennet Horse",
    "Spanish Mustang",
    "Spanish Tarpan",
    "Spanish Trotter",
    "Spanish-Norman horse",
    "Spiti Horse",
    "Spotted Saddle Horse",
    "Standardbred",
    "Suffolk Punch",
    "Sumba and Sumbawa Pony",
    "Svensk Kallblodstravare",
    "Swedish Ardennes",
    "Swedish Warmblood",
    "Swiss Warmblood",
    "Taishū horse",
    "Takhi",
    "Tawleed",
    "Tchernomor",
    "Tennessee Walking Horse",
    "Tersk horse",
    "Thoroughbred",
    "Tibetan Pony",
    "Tiger Horse",
    "Timor Pony",
    "Tinker horse",
    "Tokara horse",
    "Tolfetano",
    "Tori horse",
    "Trait Du Nord",
    "Trakehner",
    "Tsushima",
    "Tuigpaard",
    "Turkoman Horse",
    "Ukrainian Riding Horse",
    "Unmol Horse",
    "Uzunyayla",
    "Ventasso horse",
    "Virginia highlander",
    "Vlaamperd",
    "Vladimir Heavy Draft",
    "Vyatka horse",
    "Waler or Australian Waler",
    "Walkaloosa",
    "Warlander",
    "Warmblood",
    "Welara",
    "Welsh Cob",
    "Welsh Pony",
    "Western Sudan pony",
    "Westphalian horse",
    "Wielkopolski",
    "Württemberger or Württemberg",
    "Xilingol horse",
    "Yakutian horse",
    "Yili horse",
    "Yonaguni horse",
    "Yorkshire Coach Horse",
    "Zangersheide",
    "Zaniskari",
    "Zweibrücker",
    "Öland horse",
    "Žemaitukas"
  ],
  RABBIT: [
    "Alaska",
    "Altex",
    "American",
    "American Chinchilla",
    "American Sable",
    "American Fuzzy Lop",
    "Angora",
    "Argente Bleu",
    "Argente Brun",
    "Argente Clair",
    "Argente Crème",
    "Argente Noir",
    "Argente St Hubert",
    "Argente de Champagne",
    "Armenian Marder",
    "Astrex",
    "Aurora Negro",
    "Baladi Black",
    "Baladi Red",
    "Baladi White",
    "Bauscat",
    "Beaver",
    "Beige",
    "Belgian Hare",
    "Beveren",
    "Beveren",
    "Big Silver Marten",
    "Blanc de Bouscat",
    "Blanc de Chauny",
    "Blanc de Hotot",
    "Blanc de L'Oural",
    "Blanc de Popielno",
    "Blanc de Termonde",
    "Blue of Ham",
    "Blue of Sint-Niklaas",
    "Bourbonnais Grey",
    "Brazilian",
    "Britannia Petite",
    "British Giant",
    "Brown Chestnut of Lorraine",
    "Caldes",
    "Californian",
    "Canadian Plush Lop",
    "Carmagnola Grey",
    "Cashmere Lop",
    "Champagne d'Argent",
    "Chaudry",
    "Checkered Giant",
    "Chinchilla",
    "Chinchilla Giganta",
    "Cinnamon",
    "Continental Giant Coloured",
    "Continental Giant White",
    "Creme d'Argent",
    "Criollo",
    "Cuban Brown",
    "Czech Red",
    "Czech Solver",
    "Czech Spot",
    "Czech White",
    "Dalmatian",
    "Deilenaar",
    "Dutch",
    "Dutch",
    "Dwarf Hotot",
    "Dwarf Lop",
    "Elfin",
    "Enderby Island",
    "English Angora",
    "English Lop",
    "English Spot",
    "Fauve de Bourgogne",
    "Fee de Marbourg",
    "Feh",
    "Feu Noir",
    "Flemish Giant",
    "Florida White",
    "Fox",
    "Fox–Silver",
    "French Angora",
    "French Lop",
    "Gabali",
    "German Angora",
    "German Lop",
    "Giant Angora",
    "Giant Chinchilla",
    "Giant Havana",
    "Giant Marburger",
    "Giant Papillon",
    "Giant Silver",
    "Giant Smoke Pearl",
    "Giza White",
    "Golden Fawn",
    "Golden Glavcot",
    "Gotland",
    "Gouwenaar",
    "Grey Pearl of Halle",
    "Güzelçamlı",
    "Harlequin",
    "Harlequin Dutch",
    "Harlequin Rex",
    "Havana",
    "Himalayan",
    "Holland Lop",
    "Hulstlander",
    "Hungarian Giant",
    "Isabella",
    "Jamora ",
    "Japanese Dutch",
    "Japanese White",
    "Jersey Wooly",
    "Kabyle",
    "Lilac",
    "Lionhead",
    "Liptov Baldspotted Rabbit",
    "Lynx",
    "Marburger Feh",
    "Mecklenburger Piebald",
    "Meissner Lop",
    "Mellerud rabbit",
    "Mini Cashmere Lop",
    "Mini Lion Lop",
    "Mini Lop",
    "Mini Rex",
    "Mini Satin",
    "Miniature Cashmere Lop",
    "Miniature Lion Lop",
    "Miniature Lop",
    "Miniature Papillon",
    "Miniature Rex",
    "Miniature Satin",
    "Moshtohor",
    "Netherland Dwarf",
    "New Zealand",
    "New Zealand red",
    "New Zealand white",
    "Nitransky",
    "Old English Red",
    "Opossum",
    "Orange",
    "Orylag ",
    "Palomino",
    "Pannon White",
    "Patagonian",
    "Perlfee",
    "Petite Brabancon",
    "Plush Lop",
    "Pointed Beveren",
    "Polish",
    "Rex",
    "Rex du Poitou",
    "Rhinelander",
    "Rhone",
    "Rouennais",
    "Russian",
    "Sachsengold ",
    "Sallander",
    "San Juan",
    "Satin Angora",
    "Satin",
    "Schwarzgrannen",
    "Separator",
    "Siamese Sable",
    "Siberian",
    "Silver",
    "Silver Fox",
    "Silver Fox",
    "Silver Marten",
    "Simenwar",
    "Sitka",
    "Slovenian Rabbit",
    "Smoke Pearl",
    "Spanish Giant",
    "Squirrel",
    "Standard Chinchilla",
    "Standard Rex",
    "Stone rabbit",
    "Sussex",
    "Swan",
    "Swedish Fur",
    "Swedish Hare",
    "Swiss Fox",
    "Tadla",
    "Tan",
    "Teddy Dwarf",
    "Teddy Lop",
    "Thrianta",
    "Thuringer",
    "Tri-Colour Dutch",
    "Tri-Colour English",
    "Trønder",
    "V-line",
    "Velveteen Lop",
    "Vienna Coloured",
    "Vienna White",
    "Wheaten",
    "Wheaten Lynx",
    "White Country",
    "Zemmouri",
    "Zika",
    "Ørestad"
  ]
};