/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { strings } from "../../../Strings/Strings";
import LoaderInline from "../../../../components/LoaderInline";
import { PetTransferResponse } from "../../../../models/petTransfer/PetTransferResponse";
import PetTransferApi from "../../../../api/PetTransferApi";
import logger from "../../../../util/logger";
import { getGeneralError } from "../../../../util/helperFunctions";
import AlertBox from "../../../../components/AlertBox";
import Button from "../../../../components/Button";

interface Props {
  refresh: () => void;
  request?: PetTransferResponse;
}

const PetTransferOldOwnerNotification: React.FC<Props> = ({
  refresh,
  request,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const accept = async () => {
    if (request) {
      setLoading(true);
      try {
        await PetTransferApi.accept(request.id);
        refresh();
      } catch (e) {
        logger.error(e);
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const reject = async () => {
    if (request) {
      setLoading(true);
      try {
        await PetTransferApi.reject(request.id);
        refresh();
      } catch (e) {
        logger.error(e);
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <LoaderInline className="p-3" hidden={!loading} />
      <div hidden={loading}>
        <div className="flex flex-col">
          <p className="text-sm text-left">
            {strings.formatString(
              strings.acceptAsOldOwner,
              request?.newOwner?.fullName,
              request?.pet?.name
            )}
          </p>
          <AlertBox className="mt-3 text-left" message={error} />
          <div className="flex mt-5">
            <Button onClick={reject} variant="link">
              <p className="text-sm text-slate-500 dark:text-slate-300">
                {strings.no}
              </p>
            </Button>
            <Button className="ml-auto text-sm" onClick={accept} variant="link">
              {strings.yes}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PetTransferOldOwnerNotification;
