/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Button } from "react-bootstrap";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import { CountryResponse } from "../../../models/management/CountryResponse";
import CountryApi from "../../../api/CountryApi";
import { InsuranceCompanyResponse } from "../../../models/insurance/InsuranceCompanyResponse";
import InsuranceApi from "../../../api/InsuranceApi";
import LoaderInline from "../../../components/LoaderInline";
import { SiteManagerPage } from "../SiteManagerPage";
import { PageProps } from "../../../models/PageProps";
import InsuranceCompanyDeletionModal from "./InsuranceCompanyDeletionModal";
import InsuranceCompanyEditorModal from "./InsuranceCompanyEditorModal";
import AlertBox from "../../../components/AlertBox";

function InsurancePage({ setPageLoading }: PageProps): ReactElement {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [isEditorModalVisible, setEditorModalVisible] =
    useState<boolean>(false);
  const [isDeletionModalVisible, setDeletionModalVisible] =
    useState<boolean>(false);

  const [companies, setCompanies] = useState<InsuranceCompanyResponse[]>([]);
  const [countries, setCountries] = useState<CountryResponse[]>([]);

  const [selectedCompany, setSelectedCompany] =
    useState<InsuranceCompanyResponse>();

  const getInsuranceCompanies = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await InsuranceApi.getInsuranceCompanies();
      setCompanies(resp.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
      setPageLoading(false);
    }
  }, [setPageLoading]);

  const getCountries = async () => {
    try {
      const response = await CountryApi.getCountries();
      setCountries(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  };

  useEffect(() => {
    void getInsuranceCompanies();
    void getCountries();
  }, [getInsuranceCompanies]);

  const openAddModal = () => {
    setEditorModalVisible(true);
    setSelectedCompany(undefined);
    setError(null);
  };

  const closeEditorModal = () => {
    setEditorModalVisible(false);
    setSelectedCompany(undefined);
    setError(null);
  };

  const openEditModal = (company: InsuranceCompanyResponse) => {
    setEditorModalVisible(true);
    setSelectedCompany(company);
    setError(null);
  };

  const openDeletionModal = (company: InsuranceCompanyResponse) => {
    setDeletionModalVisible(true);
    setSelectedCompany(company);
    setError(null);
  };

  const closeDeletionModal = () => {
    setDeletionModalVisible(false);
    setSelectedCompany(undefined);
    setError(null);
  };

  return (
    <>
      <LoaderInline className="my-3" hidden={!isLoading} />

      <div hidden={isLoading} className="container frame">
        <div className="row">
          <div className="offset-sm-2 col-sm-8">
            <AlertBox message={error} className="mb-2" />
            <div className="card">
              <div className="block-label p-3 d-flex border-bottom-0">
                <div className="flex-grow-1 h5 m-0">
                  {strings.insuranceCompanies}
                </div>
                <div>
                  <Button variant="primary" onClick={openAddModal}>
                    {strings.addNew}
                  </Button>
                </div>
              </div>
              <div>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>{strings.businessName}</th>
                      <th>{strings.country}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {companies.map((company) => (
                      <tr>
                        <td className="align-middle">{company.name}</td>
                        <td className="align-middle">
                          {
                            countries?.find(
                              (i) => i.countryCode === company.countryCode
                            )?.displayName
                          }
                        </td>
                        <td className="text-right">
                          <Button
                            className="mr-2"
                            variant="secondary"
                            onClick={() => openEditModal(company)}
                          >
                            <i className="fas fa-pencil-alt" />
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => openDeletionModal(company)}
                          >
                            <i className="fas fa-trash" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InsuranceCompanyEditorModal
        show={isEditorModalVisible}
        onHide={closeEditorModal}
        refresh={getInsuranceCompanies}
        countries={countries}
        selectedCompany={selectedCompany}
      />
      <InsuranceCompanyDeletionModal
        show={isDeletionModalVisible}
        onHide={closeDeletionModal}
        refresh={getInsuranceCompanies}
        selectedCompany={selectedCompany}
      />
    </>
  );
}

export default SiteManagerPage(InsurancePage, strings.INSURANCE);
