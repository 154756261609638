/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { paymentMethod, strings } from "../../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../../util/helperFunctions";
import CloseButton from "../../../../../components/CloseButton";
import AlertBox from "../../../../../components/AlertBox";
import { PaymentMethodRequest } from "../../../../../models/payment/PaymentMethodRequest";
import PaymentMethodApi from "../../../../../api/PaymentMethodApi";
import { PaymentMethod } from "../../../../../models/costaccounting/PaymentMethod";
import Select from "../../../../../components/ReactHookFormFields/General/Select/Select";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { BackendFieldError } from "../../../../../models/BackendFieldError";
import Button from "../../../../../components/Button";
import CombinedSelect from "../../../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import CountryApi from "../../../../../api/CountryApi";
import logger from "../../../../../util/logger";
import { CountryDetailsResponse } from "../../../../../models/management/CountryDetailsResponse";
import { AccountingCodeResponse } from "../../../../../models/accounting/AccountingCodeResponse";
import Modal from "../../../../../components/Modal/Modal";

interface Props {
  clinicId: string;
  reload: () => void;
  isOpen: boolean;
  close: () => void;
}

interface AddPaymentMethodForm {
  name: string;
  type: string;
  accountingCode?: AccountingCodeResponse[];
}

type AddPaymentMethodFormKeys = keyof AddPaymentMethodForm;

const PaymentMethodsModal: React.FC<Props> = ({
  clinicId,
  isOpen,
  reload,
  close,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<
    Array<BackendFieldError<AddPaymentMethodFormKeys>>
  >([]);
  const [countryDetails, setCountryDetails] =
    useState<CountryDetailsResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getCountryDetails = async (clinicId: string) => {
      try {
        const response = await CountryApi.getCountryDetailsOfClinic(clinicId);
        setCountryDetails(response.data);
      } catch (err) {
        logger.error(err);
      }
    };

    void getCountryDetails(clinicId);
  }, [clinicId]);

  const submitForm = async ({
    accountingCode,
    name,
    type,
  }: AddPaymentMethodForm) => {
    setLoading(true);

    try {
      const request: PaymentMethodRequest = {
        code: accountingCode?.[0]?.code || accountingCode?.[0]?.name,
        name,
        type: type as PaymentMethod,
      };
      await PaymentMethodApi.addPaymentMethod(clinicId, request);

      reload();
      close();
    } catch (err) {
      const error: any = err;
      setErrorMessage(await getGeneralError(error));
      setFieldErrors(error.response?.data || []);
    } finally {
      setLoading(false);
    }
  };

  const {
    control,
    register,
    reset,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<AddPaymentMethodForm>({
    mode: "onChange",
    defaultValues: {
      accountingCode: [],
      name: "",
      type: "",
    },
  });

  useEffect(() => {
    const resetFormAndErrors = () => {
      reset();
      setErrorMessage(null);
      setFieldErrors([]);
    };

    if (isOpen) {
      resetFormAndErrors();
    }
  }, [isOpen, reset]);

  useEffect(() => {
    fieldErrors.forEach(
      ({ field, message }: BackendFieldError<AddPaymentMethodFormKeys>) => {
        setError(field, { type: "manual", message });
      }
    );
  }, [fieldErrors, setError]);

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.addPaymentMethod} />
      <Modal.Body noSpacing>
        <form
          className="modal-body"
          onSubmit={handleSubmit(submitForm)}
          id="addPaymentMethodForm"
        >
          <Field
            name="name"
            label={strings.name}
            register={register}
            required
            error={errors.name}
          />
          <Select
            name="type"
            label={strings.paymentType}
            register={register}
            options={Object.keys(PaymentMethod).map((type) => ({
              title: paymentMethod[type],
              value: type,
            }))}
            isChooseOption
            required
          />
          <CombinedSelect
            allowNew
            control={control}
            name="accountingCode"
            label={strings.accountingCode}
            labelKey="name"
            optional
            options={countryDetails?.accountingCodes || []}
            placeholder={strings.accountingCode}
          />
          <AlertBox message={errorMessage} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid || loading}
          form="addPaymentMethodForm"
          fullWidth={false}
          loading={loading}
          type="submit"
        >
          {strings.save}
        </Button>
        <CloseButton onClick={close} />
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentMethodsModal;
