/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { strings } from "../../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../../models/AutoCompleteOptions";
import Field from "../../../../components/ReactHookFormFields/General/Field";
import BirthDatePicker from "../../../../components/ReactHookFormFields/Specific/BirthDatePicker";
import { fullYearSwedishPersonalId } from "../../../../util/Validations";
import { PetOwnerFullForm } from "./CreatePetOwner";
import CountryApi from "../../../../api/CountryApi";
import logger from "../../../../util/logger";
import { CountryResponse } from "../../../../models/management/CountryResponse";

export interface GeneralUserForm {
  comment?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  ownerDateOfBirth?: Date;
  personalId?: string;
  prefix?: string;
}

interface Props {
  primaryAddressCountryCode?: string;
}

const GeneralUserInfoForm: React.FC<Props> = ({
  primaryAddressCountryCode,
}: Props) => {
  const [countries, setCountries] = useState<CountryResponse[]>();

  useEffect(() => {
    const getCountries = async () => {
      try {
        const resp = await CountryApi.getCountries();
        setCountries(resp.data);
      } catch (e) {
        logger.error(e);
      }
    };

    if (primaryAddressCountryCode) {
      void getCountries();
    }
  }, [primaryAddressCountryCode]);

  const {
    control,
    register,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext<PetOwnerFullForm>();

  useEffect(() => {
    if (!countries) return;

    setValue(
      "country",
      countries.filter((c) => c.countryCode === primaryAddressCountryCode)
    );
  }, [countries, primaryAddressCountryCode, setValue]);

  const country = watch("country");

  const onPersonalIdChange = (value: string) => {
    const isLengthValid = value && value.length >= 8;

    if (isLengthValid && country) {
      const countryCode = country[0]?.countryCode;
      if (countryCode === "SE") {
        const dateOfBirth = moment(value, "YYYYMMDD");
        if (dateOfBirth.isValid()) {
          setValue("ownerDateOfBirth", dateOfBirth.toDate(), {
            shouldValidate: true,
          });
        }
      }
    }
  };

  const isPersonalIdUsed = () => {
    const formCountry = getValues("country")?.[0];
    return formCountry?.isPersonalIdUsed ?? false;
  };

  return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4">
        <Field
          autoComplete={AutoCompleteOptions.off}
          name="prefix"
          label={strings.prefix}
          error={errors.prefix}
          register={register}
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          name="firstName"
          register={register}
          label={strings.firstName}
          error={errors.firstName}
          required
          showRequired
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          name="middleName"
          register={register}
          label={strings.middleName}
          error={errors.middleName}
        />
        <Field
          autoComplete={AutoCompleteOptions.off}
          name="lastName"
          register={register}
          label={strings.lastName}
          error={errors.lastName}
          required
          showRequired
        />
        <div hidden={!isPersonalIdUsed()}>
          <Field
            autoComplete={AutoCompleteOptions.off}
            name="personalId"
            error={errors.personalId}
            fieldOptions={{
              pattern: {
                value: fullYearSwedishPersonalId.matchRegexp,
                message: strings.formatString(
                  strings.invalidFormat,
                  strings.personalIdFormatPlaceholder
                ),
              },
              validate: {
                req: (v: string) => {
                  if (isPersonalIdUsed()) {
                    return v?.length > 0;
                  }
                  return true;
                },
              },
            }}
            label={strings.personalId}
            maxLength={12}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onPersonalIdChange(e.target.value);
            }}
            placeholder={strings.personalIdFormatPlaceholder}
            register={register}
            showRequired={country?.[0]?.isPersonalIdUsed}
          />
        </div>
        <BirthDatePicker
          control={control}
          name="ownerDateOfBirth"
        />
      </div>
  );
};

export default GeneralUserInfoForm;
