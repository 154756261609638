/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { SiteManagerPage } from "../SiteManagerPage";
import { PageProps } from "../../../models/PageProps";
import {
  getAddressString,
  getGeneralError,
} from "../../../util/helperFunctions";
import logger from "../../../util/logger";
import { strings } from "../../../common/Strings/Strings";
import { PageResponse } from "../../../models/PageResponse";
import { Link } from "react-router-dom";
import { BasicClinicResponse } from "../../../models/clinic/BasicClinicResponse";
import ClinicManagementApi from "../../../api/ClinicManagementApi";
import { useDebouncedState, usePager } from "../../../hooks/hooks";
import SearchInput from "../../../components/InputFieldsSimple/SearchInput";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";

const ClinicListPage: React.FC<PageProps> = (props: PageProps) => {
  const { setPageLoading } = props;

  const [clinics, setClinics] = useState<PageResponse<BasicClinicResponse>>();

  const pager = usePager(50);
  const searchQuery = useDebouncedState("", 400);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getClinics = async () => {
    setLoading(true);

    try {
      const response = await ClinicManagementApi.getClinics({
        pageNumber: pager.pageNumber,
        pageSize: pager.pageSize,
        query: searchQuery.value,
      });
      setClinics(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
      logger.error(err);
    } finally {
      setPageLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    void getClinics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pager.pageNumber, pager.pageSize, searchQuery.value]);

  return (
    <div className="px-4 lg:px-6 py-6">
      <div className="w-80 mb-3">
        <SearchInput
          clear={() => {
            searchQuery.setValue("");
            pager.setPageNumber(0);
          }}
          enableCreateButton={false}
          loading={loading}
          onChange={(value) => {
            searchQuery.setValue(value);
            pager.setPageNumber(0);
          }}
          placeholder={`${strings.search}...`}
          label={strings.search}
          value={searchQuery.value}
        />
      </div>
      <PageableTable
        pager={pager}
        isLoading={loading}
        content={clinics}
        error={error}
      >
        <PageableTableHead>
          <tr>
            <th className="text-left tw-th">{strings.clinic}</th>
            <th className="text-center tw-th">{strings.country}</th>
            <th className="text-left tw-th">{strings.address}</th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {clinics &&
            clinics.elements.map((clinic, index: number) => (
              <tr key={index}>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <Link to={`/management/clinics/${clinic.id}`}>
                    {clinic.name}
                  </Link>
                </td>
                <td className="px-4 py-4 text-center text-sm whitespace-nowrap">
                  {clinic.address?.countryCode}
                </td>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  {clinic.address && getAddressString(clinic.address)}
                </td>
              </tr>
            ))}
        </PageableTableBody>
      </PageableTable>
    </div>
  );
};

export default SiteManagerPage(ClinicListPage, strings.CLINICS);
