/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Link } from "react-router-dom";
import Modal from "../../../components/Modal/Modal";
import UserDropdownMenuItems from "./UserDropdownMenuItems";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import { useUser } from "../../../contexts/UserContext";
import { copyrightStatement } from "../NavBar";
import { strings } from "../../Strings/Strings";
import { TailwindResponsiveBreakpoints } from "../../../util/TailwindResponsiveBreakpoints";

interface Props {
  open: boolean;
  onHide(): void;
  onMenuItemClick?(): void;
  showChooseLanguageModal(): void;
  showSwitchAccountModal(): void;
}

const UserDropdownMenuMobile: React.FC<Props> = ({
  open,
  onHide,
  onMenuItemClick,
  showChooseLanguageModal,
  showSwitchAccountModal,
}: Props) => {
  const { user } = useUser();
  const isMobile = window.innerWidth < TailwindResponsiveBreakpoints.xl;

  return (
    <Modal
      handleClose={onHide}
      show={open}
      id="userDropdownMenuMobile"
      ignoreScrollbar={!isMobile}
      hideOnDesktop
    >
      <Modal.Header>
        <div className="flex items-center space-x-3">
          <div className="w-8 h-8">
            <UserProfilePicture loggedIn />
          </div>
          <h3 className="truncate overflow-hidden xl:text-xl font-bold leading-tight text-zinc-800 dark:text-white">
            {user?.details?.fullName}
          </h3>
        </div>
      </Modal.Header>
      <Modal.Body className="px-4" noSpacing>
        <div className="divide-y divide-gray-200 dark:divide-gray-600 text-decoration-none">
          <UserDropdownMenuItems
            onMenuItemClick={onMenuItemClick}
            itemType="modal-item"
            showChooseLanguageModal={showChooseLanguageModal}
            showSwitchAccountModal={showSwitchAccountModal}
          />
          <ul className="py-4 font-normal text-gray-700 dark:text-gray-400">
            {/* TODO: Terms of Service */}
            <li hidden>
              <Link
                className="flex justify-between items-center text-white dark:text-gray-400 font-normal py-3 no-underline"
                to="/"
              >
                <span className="text-sm">Terms of Service</span>
              </Link>
            </li>
            <li>
              <Link
                className="flex justify-between items-center text-white dark:text-gray-400 font-normal py-3 no-underline"
                onClick={onHide}
                to="/privacy-policy"
              >
                <span className="text-sm">{strings.privacyPolicy}</span>
              </Link>
            </li>
            <li>
              <Link
                className="flex justify-between items-center text-white dark:text-gray-400 font-normal py-3 no-underline"
                onClick={onHide}
                to="/cookies"
              >
                <span className="text-sm">{strings.manageCookies}</span>
              </Link>
            </li>
            <li>
              <div className="py-3">
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {copyrightStatement}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserDropdownMenuMobile;
