/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useUser } from "../../../../../../contexts/UserContext";
import UserApi from "../../../../../../api/UserApi";
import AlertBox from "../../../../../../components/AlertBox";
import { getGeneralError } from "../../../../../../util/helperFunctions";
import { PinState } from "./PinCodeSettings";
import { Card } from "../../../../../../components/Cards/Card";
import CodeInputsForm from "../../../../../../components/ReactHookFormFields/Specific/CodeInputsForm";
import { strings } from "../../../../../../common/Strings/Strings";
import Button from "../../../../../../components/Button";
import { Phone as PhoneIcon } from "../../../../../../common/Icons/Phone";
import { codeIcon } from "../../../../../../assets/AssetConfig";

interface Props {
  canCancel: boolean;
  setPinState: (pinState: PinState) => void;
  asCard?: boolean;
}

const SetNewCode: React.FC<Props> = ({
  canCancel,
  setPinState,
  asCard = true,
}: Props) => {
  const { user, setUser } = useUser();

  const [pinCode, setPinCode] = useState<string>();

  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const updatePinCode = async (pin?: string) => {
    if (!pin) return;

    setLoading(true);
    setError(null);

    try {
      const response = await UserApi.setPinCode({ pinCode: pin });
      setPinCode(undefined);
      setUser(response.data);
      setError(null);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  const content = (
    <>
      <div className="flex items-center space-x-2 text-zinc-800 dark:text-white">
        <PhoneIcon />
        <div className="font-semibold leading-tight">
          {strings.usePinInfoTitle}
        </div>
      </div>
      <div>{strings.usePinInfo}</div>
      <div className="flex items-center justify-center">
        <img src={codeIcon} alt="password" />
      </div>
      <CodeInputsForm
        focusFirstInput={false}
        label={strings.pin}
        size={4}
        submit={(result: string) => {
          setPinCode(result);
        }}
      />
      <AlertBox className="my-5" message={error} />
      <div>
        <Button
          className="w-full mb-5"
          disabled={!pinCode}
          loading={loading}
          onClick={() => {
            void updatePinCode(pinCode);
          }}
          variant="primary"
        >
          {strings.savePin}
        </Button>
        <div>
          {user.hasConfirmedPinCode &&
            !user.hasUnconfirmedPinCode &&
            canCancel && (
              <Button
                variant="secondary"
                className="w-full"
                onClick={() => {
                  setPinState(PinState.HasCode);
                }}
              >
                {strings.cancel}
              </Button>
            )}
          {user.hasUnconfirmedPinCode && (
            <Button
              className="w-full"
              onClick={() => {
                setPinState(PinState.ConfirmCode);
              }}
              variant="secondary"
            >
              {strings.cancel}
            </Button>
          )}
        </div>
      </div>
    </>
  );

  if (!asCard) {
    return content;
  }

  return (
    <Card size="md" type="simple" title={strings.pin}>
      {content}
    </Card>
  );
};

export default SetNewCode;
