/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import NumberFormatter from "../../../util/NumberFormatter";
import CostCalculator from "../../../util/CostCalculator";
import RadioButtonGroup from "../../../components/ReactHookFormFields/General/RadioButton/RadioButtonGroup";
import { Check } from "../../../common/Icons/Check";
import { Clock } from "../../../common/Icons/Clock";
import { InventoryItemUnit } from "../../../models/inventory/item/InventoryItemUnit";
import { InventoryItemStockItemResponse } from "../../../models/inventory/item/Response/InventoryItemStockItemResponse";
import MultiplicationFields from "../../../components/ReactHookFormFields/Specific/MultiplicationFields";
import { InventoryCountingFormProps } from "./ItemCountingPage";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import Tooltip from "../../../components/Tooltip";
import { useClinic } from "../../../contexts/ClinicContext";

export interface CountingFormRowProps {
  acquisitionPrice?: number;
  acquisitionVat?: number;
  comment?: string;
  expirationDate?: string;
  isTheSame: string;
  newQuantity: number;
  oldQuantity: number;
}

interface Props {
  acquisitionUnit?: InventoryItemUnit;
  countryDetails?: CountryDetailsResponse;
  index: number;
  stock: InventoryItemStockItemResponse;
}

const CountingRow: React.FC<Props> = ({
  acquisitionUnit,
  countryDetails,
  index,
  stock,
}: Props) => {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<InventoryCountingFormProps>();
  const { clinic } = useClinic();

  useEffect(() => {
    setValue(`stocks.${index}.acquisitionPrice`, stock.acquisitionPrice);
    setValue(`stocks.${index}.acquisitionVat`, stock.acquisitionVat);
    setValue(`stocks.${index}.expirationDate`, stock.expirationDate);
    setValue(`stocks.${index}.oldQuantity`, stock.quantity);
    setValue(`stocks.${index}.newQuantity`, 1);
  }, [stock, setValue, index]);

  const isTheSame = watch("stocks")?.[index]?.isTheSame;

  return (
    <div className="tw-card flex p-4" key={stock.dateTime}>
      <div className="grow grid xl:grid-cols-12 gap-4">
        <div className="xl:col-span-1">{index + 1}</div>
        <div className="xl:col-span-2">
          {moment(stock.dateTime).format("LL")}
          {stock.expirationDate ? (
            <Tooltip content={strings.expirationDate}>
              <div className="flex items-center space-x-1.5">
                <Clock className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  {moment(stock.expirationDate).format("LL")}
                </div>
              </div>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
        <div className="xl:col-span-2">
          {NumberFormatter.formatPrice(
            CostCalculator.getGrossPrice(
              stock.acquisitionPrice || 0,
              stock.acquisitionVat,
              countryDetails,
              clinic || undefined
            ),
            stock.acquisitionCurrency
          )}
          <div className="text-xs text-gray-500">
            {NumberFormatter.formatPrice(
              stock.acquisitionPrice || 0,
              stock.acquisitionCurrency
            )}{" "}
            {strings.plusVat}
          </div>
        </div>
        <div className="xl:col-span-3">
          {strings.quantity}: {NumberFormatter.format(stock.quantity)}{" "}
          {quantityUnits[acquisitionUnit || ""]}
        </div>
        <div className="xl:col-span-4">
          <RadioButtonGroup
            control={control}
            name={`stocks.${index}.isTheSame`}
            options={[
              {
                value: "same",
                label: `${strings.stockIsTheSame} (${NumberFormatter.format(
                  stock.quantity
                )} ${quantityUnits[acquisitionUnit || ""]})`,
              },
              {
                value: "diff",
                label: strings.stockIsDifferent,
                content: (
                  <div className="space-y-2">
                    <MultiplicationFields
                      control={control}
                      disabled={isTheSame !== "diff"}
                      fieldOptions={{
                        validate: {
                          isRequired: (v, values) => {
                            if (values.stocks?.[index]?.isTheSame === "diff") {
                              if (Number.isNaN(v)) {
                                return false;
                              } 
                                return true;
                              
                            } 
                              return true;
                            
                          },
                          isNegative: (v) => v >= 0,
                        },
                      }}
                      name={`stocks.${index}.newQuantity`}
                      readOnly={isTheSame !== "diff"}
                      required
                      unit={acquisitionUnit}
                    />
                    <Textarea
                      label={strings.comment}
                      maxLength={100}
                      name={`stocks.${index}.comment`}
                      readOnly={isTheSame !== "diff"}
                      register={register}
                      optional
                    />
                  </div>
                ),
              },
            ]}
            required
          />
        </div>
      </div>
      <div className="ml-auto">
        {isTheSame === undefined ||
        (isTheSame === "diff" && !!errors?.stocks?.[index]?.newQuantity) ? (
          <Check className="text-gray-300 dark:text-gray-700" />
        ) : (
          <Check />
        )}
      </div>
    </div>
  );
};

export default CountingRow;
