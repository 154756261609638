/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";
import Field from "../../../components/ReactHookFormFields/General/Field";
import PhoneNumber from "../../../components/ReactHookFormFields/Specific/PhoneNumber";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { phoneTypeOptions } from "../../../models/contact/PhoneType";
import { CreateClinicBasicInfoForm } from "../CreateClinic";
import AlertBox from "../../../components/AlertBox";

interface Props {
  defaultCountryCode?: string;
  onNext(): void;
}

const ContactsTab: React.FC<Props> = ({
  defaultCountryCode,
  onNext,
}: Props) => {
  const {
    control,
    register,
    formState: { errors },
    trigger,
  } = useFormContext<CreateClinicBasicInfoForm>();

  return (
    <div className="space-y-6">
      <Field
        autoComplete={AutoCompleteOptions.email}
        error={errors.email}
        label={strings.email}
        name="email"
        placeholder={strings.emailExample}
        register={register}
        required
        showRequired
        type="email"
      />
      <PhoneNumber
        autocomplete={AutoCompleteOptions.telNational}
        control={control}
        country={defaultCountryCode}
        id="phoneNumber"
        label={strings.phone}
        name="phoneNumber"
        required
        showRequired
      />
      <Select
        error={errors.phoneType}
        isChooseOption={false}
        label={strings.phoneType}
        name="phoneType"
        options={phoneTypeOptions}
        register={register}
        required
        showRequired
      />
      <AlertBox
        hidden={
          errors.email?.type !== "required" &&
          errors.phoneNumber?.type !== "required"
        }
        message={strings.pleaseFillRequiredFields}
      />
      <Button
        onClick={() => {
          const response = trigger(["email", "phoneNumber"]);

          response.then((isValid: boolean) => {
            if (isValid) {
              onNext();
            }
          });
        }}
      >
        {strings.saveAndContinue}
      </Button>
    </div>
  );
};

export default ContactsTab;
