/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import {
  petGenders,
  petSpecies,
  strings,
} from "../../../common/Strings/Strings";
import Field from "../../../components/ReactHookFormFields/General/Field";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { ClinicPetResponse } from "../../../models/pet/ClinicPetResponse";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { InsuranceCompanyResponse } from "../../../models/insurance/InsuranceCompanyResponse";
import { PetInsuranceCompanyResponse } from "../../../models/pet/PetInsuranceCompany";
import InsuranceApi from "../../../api/InsuranceApi";
import logger from "../../../util/logger";
import { MergedHistory, RowType } from "./PetHistory";
import { validateInternationalEmail } from "../../../util/Validations";
import { Colors } from "../../../models/Colors";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import { SendMedicalRecordsMailRequest } from "../../../models/pet/SendMedicalRecordsMailRequest";
import ClinicPetApi from "../../../api/ClinicPetApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { RecipientRequest } from "../../../models/pet/RecipientRequest";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import { EmailResponse } from "../../../models/contact/EmailResponse";
import { MedicalRecordsMailResponse } from "../../../models/medical/mail/MedicalRecordsMailResponse";
import Modal from "../../../components/Modal/Modal";
import CloseButton from "../../../components/CloseButton";
import Button from "../../../components/Button";
import PetProfilePicture from "../../../components/Pictures/Pet/PetProfilePicture";
import PetInfo from "../../Todo/PetInfo";

interface Props {
  close(): void;
  isOpen: boolean;
  owner?: PetOwnerResponse;
  pet?: ClinicPetResponse;
  selectedDocuments: MergedHistory[];
  updateMedicalRecordVersion?(newEmail: MedicalRecordsMailResponse): void;
}

interface EmailForm {
  mergeFiles: boolean;
  message?: string;
  to: Array<
    | InsuranceCompanyResponse
    | PetInsuranceCompanyResponse
    | { email: string; id?: string }
  >;
  subject: string;
  zipFiles: boolean;
}

const SendInEmailModal = ({
  close,
  isOpen,
  owner,
  pet,
  selectedDocuments,
  updateMedicalRecordVersion,
}: Props): ReactElement => {
  const [petOwnerEmails, setPetOwnerEmails] = useState<EmailResponse[]>();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    register,
    reset,
  } = useForm<EmailForm>();
  const [insuranceCompanies, setInsuranceCompanies] = useState<
    InsuranceCompanyResponse[] | PetInsuranceCompanyResponse[]
  >([]);

  const getPetOwner = async () => {
    if (pet) {
      try {
        const response = await ClinicPetApi.getClinicPet(
          pet.clinicPetDetails.id
        );
        setPetOwnerEmails(response.data.petOwner.emails);
      } catch (e) {
        logger.error(e);
      }
    }
  };

  useEffect(() => {
    if (pet && isOpen) {
      if (!owner) {
        void getPetOwner();
      } else {
        setPetOwnerEmails(owner.emails);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner, pet, isOpen]);

  const loadInsuranceCompanies = async () => {
    try {
      const result = await InsuranceApi.getInsuranceCompanies();
      setInsuranceCompanies(result.data);
    } catch (e) {
      logger.error(e);
    }
  };

  useEffect(() => {
    void loadInsuranceCompanies();
  }, []);

  useEffect(() => {
    if (isOpen) {
      if (insuranceCompanies.length > 0 && pet) {
        if (pet.clinicPetDetails.insuranceCompany) {
          setValue("to", [pet.clinicPetDetails.insuranceCompany]);
          setValue(
            "subject",
            strings.formatString(
              strings.defaultInsuranceEmailTitle,
              pet?.clinicPetDetails?.name,
              petSpecies[pet?.clinicPetDetails?.species]
            )
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceCompanies, pet, isOpen]);

  const handleClose = () => {
    setError(null);
    setIsSuccess(false);
    reset();
    close();
  };

  const sendEmail = async (request: SendMedicalRecordsMailRequest) => {
    setError(null);
    setIsSuccess(false);
    setSubmitting(true);

    if (pet?.clinicPetDetails) {
      try {
        await ClinicPetApi.emailPetDocuments(pet.clinicPetDetails.id, request);
        setIsSuccess(true);

        if (updateMedicalRecordVersion) {
          updateMedicalRecordVersion({
            dateTime: moment().format(),
            recipients: request.recipients,
          });
        }
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setSubmitting(false);
      }
    }
  };

  const submit = ({
    mergeFiles,
    message,
    subject,
    to,
    zipFiles,
  }: EmailForm) => {
    const request: SendMedicalRecordsMailRequest = {
      bodyText: message,
      invoiceIds: [],
      medicalRecordIds: selectedDocuments.map((doc): string => doc.value.id),
      mergeMedicalRecords: mergeFiles,
      recipients: to.map(
        (m): RecipientRequest => ({ email: m.email, insuranceCompanyId: m.id })
      ),
      subject,
      zipFiles,
    };

    void sendEmail(request);
  };

  const printAttachment = (doc: MergedHistory | any) => {
    if (doc.type === RowType.MedicalRecord) {
      return (
        <div className="d-flex align-items-center color-light-grey">
          <span className="material-icons" style={{ marginRight: "8px" }}>
            assignment
          </span>
          <a
            href={`/medical-record/${doc.value.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="color-light-grey line-clamp-1 d-flex paragraph-small"
              style={{ textDecoration: "underline" }}
            >
              {`${moment(doc.dateTime).format("L")} ${
                doc.value.diagnoses[0] || ""
              }`}
            </div>
          </a>
        </div>
      );
    }
    return <></>;
  };

  return (
    <Modal handleClose={handleClose} show={isOpen}>
      <Modal.Header title={strings.newEmail} />
      <Modal.Body noSpacing>
        <form className="modal-body">
          { pet && <PetInfo pet={pet} /> }
          <CombinedSelect
            allowNewPropName="email"
            control={control}
            fieldOptions={{
              validate: {
                isEmail: (v) =>
                  v.filter((e: InsuranceCompanyResponse | any) =>
                    validateInternationalEmail(e.email)
                  ).length === v.length,
              },
            }}
            label={strings.emailTo}
            labelKey={(insuranceCompany: InsuranceCompanyResponse | any) =>
              `${insuranceCompany.email}${
                insuranceCompany.name ? ` (${insuranceCompany.name})` : ""
              }`
            }
            multiple
            name="to"
            groupedByOptions={[
              {
                groupOptions: petOwnerEmails
                  ? petOwnerEmails.map((e) => ({ email: e.value }))
                  : [],
                groupTitle: strings.petOwner,
              },
              {
                groupOptions: insuranceCompanies,
                groupTitle: strings.insuranceCompanies,
              },
            ]}
            required
          />
          <Field
            error={errors.subject}
            label={strings.emailSubject}
            name="subject"
            register={register}
            required
          />
          <Textarea
            label={strings.emailMessage}
            error={errors.message}
            name="message"
            minRows={3}
            register={register}
          />
          <div className="flex">
            <div>
              {selectedDocuments
                .slice(0, 5)
                .map((doc: MergedHistory, index) => (
                  <div key={index}>{printAttachment(doc)}</div>
                ))}
              {selectedDocuments.length > 5 ? (
                <span className="material-symbols-outlined color-light-grey">
                  more_horiz
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="ml-auto" hidden={selectedDocuments.length < 6}>
              <div
                style={{
                  backgroundColor: Colors.PRIMARY,
                  width: "25px",
                  height: "25px",
                  fontSize: "14px",
                  fontWeight: 700,
                  borderRadius: "50%",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {selectedDocuments.length}
              </div>
            </div>
          </div>
          <div hidden={selectedDocuments.length < 2}>
            <CheckBox
              label="Merge documents into one PDF"
              name="mergeFiles"
              register={register}
            />
            <CheckBox
              label="ZIP documents"
              name="zipFiles"
              register={register}
            />
          </div>
          <AlertBox closeAble={false} className="my-5" message={error} />
          <AlertBox
            className="my-5"
            closeAble={false}
            hidden={!isSuccess}
            message={strings.emailSentSuccessfully}
            type={AlertType.SUCCESS}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={submitting}
          loading={submitting}
          onClick={handleSubmit(submit)}
        >
          {strings.emailSend}
        </Button>
        <CloseButton onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default SendInEmailModal;
