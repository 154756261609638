/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import Modal from "../../../components/Modal/Modal";
import { InventoryItemStockItemResponse } from "../../../models/inventory/item/Response/InventoryItemStockItemResponse";
import NumberFormatter from "../../../util/NumberFormatter";
import CostCalculator from "../../../util/CostCalculator";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { InventoryItemUnit } from "../../../models/inventory/item/InventoryItemUnit";
import { getSumOfInventoryCounting } from "../Details/CountingHistory";
import { InventoryItemStockCountingResponse } from "../../../models/inventory/item/Response/InventoryItemStockCountingResponse";
import { useClinic } from "../../../contexts/ClinicContext";

interface Props {
  acquisitionUnit?: InventoryItemUnit;
  close: () => void;
  counting?: InventoryItemStockCountingResponse;
  countryDetails?: CountryDetailsResponse;
  isOpen: boolean;
  stocks?: Array<InventoryItemStockItemResponse>;
}

const CountingViewModal: React.FC<Props> = ({
  acquisitionUnit,
  counting,
  countryDetails,
  isOpen,
  close,
  stocks,
}: Props) => { 
  const { clinic } = useClinic();
  
  return (
    <Modal handleClose={close} show={isOpen} size="modal-lg">
      <Modal.Header title={strings.countingDetails} />
      <Modal.Body>
        <div>{strings.date}: <b>{moment(counting?.dateTime).format("LL")}</b></div>
        <div hidden={!counting?.comment}>{strings.comment}: <b>{counting?.comment}</b></div>
        <div className="tw-table-container">
          <table className="tw-table">
            <thead className="tw-thead">
              <th />
              <th className="tw-th">{strings.dateOfOrder}</th>
              <th className="tw-th">{strings.acquisitionPrice}</th>
              <th className="tw-th">{strings.expirationDate}</th>
              <th className="tw-th">{strings.comment}</th>
              <th className="tw-th">{strings.countedQuantity}</th>
            </thead>
            <tbody className="tw-tbody">
              {stocks?.map((stock, index) => (
                <tr key={index}>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    {stock.dateTime && moment(stock.dateTime).format("LL")}
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    {NumberFormatter.formatPrice(
                      CostCalculator.getGrossPrice(
                        stock.acquisitionPrice || 0,
                        stock.acquisitionVat,
                        countryDetails,
                        clinic || undefined
                      ),
                      countryDetails?.currency
                    )}
                    <div className="text-xs text-gray-500">
                      {NumberFormatter.formatPrice(
                        stock.acquisitionPrice || 0,
                        stock.acquisitionCurrency
                      )}{" "}
                      {strings.plusVat}
                    </div>
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    {!!stock.expirationDate &&
                      moment(stock.expirationDate).format("LL")}
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium">
                    {stock.comment}
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    {NumberFormatter.format(stock.quantity || 0)}{" "}
                    {quantityUnits[acquisitionUnit || ""]}
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  className="py-3.5 px-4 text-sm font-bold whitespace-nowrap"
                  colSpan={5}
                >
                  {strings.total}
                </td>
                <td className="py-3.5 px-4 text-sm font-bold whitespace-nowrap">
                  {NumberFormatter.format(
                    getSumOfInventoryCounting(stocks || [])
                  )}{" "}
                  {quantityUnits[acquisitionUnit || ""]}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  )};

export default CountingViewModal;
