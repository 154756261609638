/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import {
  AcceptedImageTypes,
  ImageResizeDimensions,
  isSupportedImageFileType,
  resizeImage,
  unsupportedFileTypeMessage,
} from "../../util/ImageUtils";
import logger from "../../util/logger";
import LoaderInline from "../LoaderInline";
import { Pencil } from "../../common/Icons/Pencil";
import UserProfilePicture from "./User/UserProfilePicture";
import AlertBox from "../AlertBox";
import { Trash } from "../../common/Icons/Trash";
import { pickUserFile } from "../../util/FileUtils";
import { getGeneralError } from "../../util/helperFunctions";
import PictureApi from "../../api/PictureApi";
import { strings } from "../../common/Strings/Strings";
import { Camera } from "../../common/Icons/Camera";
import { OwnerType } from "./Picture";
import { PetSpecies } from "../../models/pet/PetSpeciesEnum";
import { ClinicResponse } from "../../models/clinic/ClinicResponse";
import { PetDetailsResponse } from "../../models/pet/PetDetailsResponse";
import ClinicLogo from "./Clinic/ClinicLogo";
import PetProfilePicture from "./Pet/PetProfilePicture";

interface Props {
  ownerType: OwnerType;
  logoText?: boolean;
  onRefresh?(): void;
  owner?:
    | ClinicResponse
    | PetDetailsResponse
    | null /* Only for clinic and pet */;
  ownerId?: string;
  petSpecies?: PetSpecies /* Only for pet */;
  pictureId?: string;
  readOnly?: boolean;
}

const setPicture = (type: OwnerType, file: File, ownerId = "") => {
  switch (type) {
    case "user":
      return PictureApi.setUserProfilePicture(file);
    case "clinic":
      return PictureApi.setClinicLogoPicture(ownerId, file);
    case "pet":
      return PictureApi.setPetProfilePicture(ownerId, file);
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};

const deletePicture = (type: OwnerType, ownerId = "") => {
  switch (type) {
    case "user":
      return PictureApi.deleteUserProfilePicture();
    case "clinic":
      return PictureApi.deleteClinicLogoPicture(ownerId);
    case "pet":
      return PictureApi.deletePetProfilePicture(ownerId);
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};

const getPictureComponent = (
  ownerType: OwnerType,
  ownerId = "",
  pictureId = "",
  species: PetSpecies = PetSpecies.OTHER
) => {
  switch (ownerType) {
    case "user":
      return <UserProfilePicture border={false} loggedIn />;
    case "clinic":
      return (
        <ClinicLogo
          border={false}
          clinicId={ownerId}
          logoPictureId={pictureId}
        />
      );
    case "pet":
      return (
        <PetProfilePicture
          border={false}
          petId={ownerId}
          profilePictureId={pictureId}
          species={species}
        />
      );
    default:
      return "";
  }
};

/* Common picture settings component for users, clinics and pets */
const PictureSettings: React.FC<Props> = ({
  ownerType,
  logoText,
  onRefresh,
  owner,
  ownerId,
  petSpecies,
  pictureId,
  readOnly = false,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);

  const choosePicture = async () => {
    if (ownerType !== "user" && !owner) return;
    setError(null);

    const files = await pickUserFile(AcceptedImageTypes.join(", "), false);
    if (!files) return;

    let file = files[0];
    if (!file) return;

    if (!isSupportedImageFileType(file)) {
      setError(unsupportedFileTypeMessage);
      return;
    }

    setLoading(true);

    try {
      const isSVG = file.type === "image/svg+xml";

      if (!isSVG) {
        file = await resizeImage({
          file,
          width: ImageResizeDimensions.width,
          height: ImageResizeDimensions.height,
        });
      }

      await setPicture(ownerType, file, ownerId);

      onRefresh && onRefresh();
    } catch (e) {
      setError(await getGeneralError(e));
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  const removePicture = async () => {
    if (ownerType !== "user" && !owner) return;

    setError(null);
    setLoading(true);

    try {
      await deletePicture(ownerType, ownerId);
      onRefresh && onRefresh();
    } catch (e) {
      setError(await getGeneralError(e));
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-between space-y-2">
        <div>
          <div className="relative rounded-full border-4 border-gray-100 bg-white w-32 h-32 bg-no-repeat bg-cover bg-center dark:border-gray-700">
            <div className="absolute w-full h-full" hidden={isLoading}>
              {getPictureComponent(ownerType, ownerId, pictureId, petSpecies)}
            </div>

            {isLoading && (
              <LoaderInline className="text-slate-500 dark:text-slate-300 w-full h-full flex flex-wrap justify-center content-center" />
            )}
            <div
              className="opacity-0 hover:opacity-100 absolute -top-1 -right-1 rounded-full w-32 h-32 bg-gray-100 text-slate-500 dark:border-gray-800 dark:bg-gray-700 dark:text-slate-300 bg-opacity-90"
              hidden={!pictureId || readOnly}
              onClick={choosePicture}
              role="button"
              tabIndex={0}
            >
              <div className="absolute w-20 h-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <div className="text-slate-500 dark:text-slate-300 bg-gray-100 dark:bg-gray-700 dark:border-gray-800 bg-opacity-0">
                  <Pencil
                    className="w-full h-full opacity-10"
                    variant="outline"
                  />
                </div>
              </div>
              <div className="absolute flex justify-center items-center w-32 h-32 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <p className="text-slate-500 dark:text-slate-300">
                  {strings.edit}
                </p>
              </div>
            </div>
            {!readOnly && (
              <div
                className="absolute top-0 -right-2.5 rounded-full border-4 border-white p-1 bg-gray-100 flex items-center justify-between text-slate-500 hover:text-sky-500 dark:border-gray-800 dark:bg-gray-700 dark:text-slate-300 dark:hover:text-sky-500 transition-all duration-300"
                onClick={!pictureId ? choosePicture : removePicture}
                role="button"
                tabIndex={0}
              >
                {!pictureId ? (
                  <Camera variant="outline" />
                ) : (
                  <Trash variant="outline" />
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <p>
            <strong>{logoText}</strong>
          </p>
        </div>
      </div>
      <AlertBox message={error} className="my-5" />
    </>
  );
};

export default PictureSettings;
