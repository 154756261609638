/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { TodoResponse } from "../../models/todo/TodoResponse";
import DeleteTodoModal from "./DeleteTodoModal";
import NewTodoModal from "./NewTodoModal";
import { MedicalRecordTodoResponse } from "../../models/todo/MedicalRecordTodoResponse";
import { PetOwnerResponse } from "../../models/pet/PetOwnerResponse";
import { ClinicPetAndOwnerResponse } from "../../models/pet/ClinicPetAndOwnerResponse";

interface Props {
  modifyInput?: TodoResponse;
  onHide: (isSuccess?: boolean) => void;
  show: boolean;
  todoForMedicalRecord?: MedicalRecordTodoResponse;
  todoForPet?: ClinicPetAndOwnerResponse;
  todoForPetOwner?: PetOwnerResponse;
}

type ModalState = "delete" | "modify";

const TodoModal = ({
  todoForMedicalRecord,
  todoForPet,
  todoForPetOwner,
  ...rest
}: Props): ReactElement => {
  const [modalState, setModalState] = useState<ModalState>("modify");

  if (modalState === "delete") {
    return (
      <DeleteTodoModal
        changeState={() => {
          setModalState("modify");
        }}
        {...rest}
      />
    );
  }
  return (
    <NewTodoModal
      changeState={() => {
        setModalState("delete");
      }}
      todoForMedicalRecord={todoForMedicalRecord}
      todoForPet={todoForPet}
      todoForPetOwner={todoForPetOwner}
      {...rest}
    />
  );
};

export default TodoModal;
