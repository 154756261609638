/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import Button from "./Button";
import { XMark } from "../common/Icons/XMark";
import { ExclamationMark } from "../common/Icons/ExclamationMark";
import { CheckCircle } from "../common/Icons/CheckCircle";
import { strings } from "../common/Strings/Strings";

export enum AlertType {
  ERROR,
  WARNING,
  INFO,
  SUCCESS,
}

interface Props {
  className?: string;
  message?: string | null | ReactElement;
  type?: AlertType;
  closeAble?: boolean;
  hidden?: boolean;
}

const AlertBox: React.FC<Props> = ({
  className,
  message,
  type = AlertType.ERROR,
  closeAble = true,
  hidden = false,
}: Props) => {
  const [cssClass, setCssClass] = useState<string>("tw-alert-danger");
  const [open, setOpen] = useState<boolean>(true);

  const closeAlert = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, [message]);

  const getIcon = (): ReactElement => {
    switch (type) {
      case AlertType.SUCCESS:
        return <CheckCircle />;
      case AlertType.WARNING:
        return <ExclamationMark />;
      case AlertType.INFO:
        return <ExclamationMark />;
      default:
        return <ExclamationMark />;
    }
  };

  useEffect(() => {
    switch (type) {
      case AlertType.SUCCESS:
        setCssClass("tw-alert-success");
        break;
      case AlertType.WARNING:
        setCssClass("tw-alert-warning");
        break;
      case AlertType.INFO:
        setCssClass("tw-alert-info");
        break;
      default:
        setCssClass("tw-alert-danger");
        break;
    }
  }, [type]);

  if (!message || !open || hidden) return null;

  return (
    <div className={`${cssClass} ${className || ""}`} role="alert">
      {getIcon()}
      <div className="ml-3 mt-0.5 text-sm font-medium w-full">{message}</div>
      <div className="ml-auto" hidden={!closeAble}>
        <Button variant="icon" onClick={closeAlert}>
          <span className="sr-only">{strings.close}</span>
          <XMark />
        </Button>
      </div>
    </div>
  );
};

export default AlertBox;
