/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import Params from "../common/Params";
import { SetupIntentResponse } from "../models/stripe/SetupIntentResponse";
import { SetupIntentRequest } from "../models/stripe/SetupIntentRequest";
import { ClinicResponse } from "../models/clinic/ClinicResponse";
import { StripeIntegrationRequest } from "../models/clinic/StripeIntegrationRequest";

export default class StripeApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getStripePublicKeyOfClinic(clinicId: string): ApiPromise<string> {
    const url = `${this.baseUrl}/clinics/${clinicId}/stripe/public-key`;
    return axiosCommon.get(url);
  }

  static getStripePublicKeyForGlobalVet(
    countryCode: string
  ): ApiPromise<string> {
    const url = `${this.baseUrl}/stripe/public-key?countryCode=${countryCode}`;
    return axiosCommon.get(url);
  }

  static getSetupIntent(countryCode: string): ApiPromise<SetupIntentResponse> {
    const request: SetupIntentRequest = { countryCode };
    const url = `${this.baseUrl}/stripe/setup-intent`;
    return axiosCommon.post(url, request);
  }

  static enableStripe(
    clinicId: string,
    stripe: StripeIntegrationRequest
  ): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/stripe-integration`;
    return axiosCommon.put(url, stripe);
  }

  static updateCreditCard(
    clinicId: string,
    stripeSetupIntentId: string
  ): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/credit-card`;
    return axiosCommon.put(url, stripeSetupIntentId);
  }
}
