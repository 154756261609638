/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { strings } from "../../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { ApiPromise } from "../../../../../models/ApiPromise";
import { EmailResponse } from "../../../../../models/contact/EmailResponse";
import { ContactOwner } from "./EmailOwnershipList";
import Button from "../../../../../components/Button";
import AlertBox, { AlertType } from "../../../../../components/AlertBox";
import Modal from "../../../../../components/Modal/Modal";
import { ContactType } from "../../../../../models/contact/ContactType";

interface Props {
  email: EmailResponse | null;
  onHide: () => void;
  owner: ContactOwner;
  removeEmail: (
    ownerId: string,
    ownershipId: string
  ) => ApiPromise<ContactOwner>;
  setEmails: (emails: EmailResponse[]) => void;
  show: boolean;
}

const DeleteEmailModal: React.FC<Props> = ({
  email,
  onHide,
  owner,
  removeEmail,
  setEmails,
  show,
}: Props) => {
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect((): void => {
    setError(null);
  }, [show]);

  const deleteEmail = async (email: EmailResponse) => {
    setLoading(true);

    try {
      const response = await removeEmail(owner.id, email.id);
      setEmails(response.data.emails);
      setError(null);
      onHide();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.deleteEmail} />
      <Modal.Body>
        {email?.contactType === ContactType.PRIMARY ? (
          <AlertBox
            closeAble={false}
            message={strings.primaryContactNotDeletable}
            type={AlertType.WARNING}
          />
        ) : (
          <p>
            {strings.deleteEmailConfirmation}
            <br />
            <strong>{email?.value}</strong>
          </p>
        )}
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading}
          fullWidth={false}
          hidden={email?.contactType === ContactType.PRIMARY}
          loading={loading}
          onClick={() => {
            if (email) {
              void deleteEmail(email);
            }
          }}
          variant="danger"
        >
          {strings.deleteEmail}
        </Button>
        <Button
          className="modal-sub-button"
          disabled={loading}
          onClick={onHide}
          variant="secondary"
        >
          {strings.cancel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteEmailModal;
