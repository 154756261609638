/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, {
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import Field from "../General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";

interface Props {
  disableNotNumberCharacters?: boolean;
  fill?: boolean;
  focusFirstInput?: boolean;
  hideCharacters?: boolean;
  label?: string;
  optional?: boolean;
  readOnly?: boolean;
  resetForm?: boolean;
  size?: number;
  sm?: boolean;
  submit?(result: string): void;
}

export interface CodeInputsFormRef {
  resetForm(): void;
}

interface CodeForm {
  [key: string]: number | string;
}

type Direction = "left" | "right";

// Currently focused element
const getActiveElement = () => document.activeElement as HTMLElement | null;

// Information about element
const getFieldDetailsFromElement = () => {
  const focusedElement = getActiveElement();
  if (!focusedElement) return null;

  const name = focusedElement.getAttribute("name");
  if (!name) return null;

  const fieldIndex = parseInt(name.slice(-1), 10);

  return { name, fieldIndex: fieldIndex || 0 };
};

// Set the cursor position to the end of the text
const setInputCursorToEnd = (inputElement: HTMLInputElement | null) => {
  if (inputElement && inputElement.value) {
    const inputValueLength = inputElement.value.length;
    inputElement.setSelectionRange(inputValueLength, inputValueLength);
  }
};

const CodeInputsForm = forwardRef(
  (
    {
      disableNotNumberCharacters = true,
      fill = false,
      focusFirstInput = true,
      hideCharacters = false,
      label,
      optional = false,
      readOnly = false,
      resetForm,
      size = 4,
      sm = false,
      submit,
    }: Props,
    ref: Ref<CodeInputsFormRef>
  ): ReactElement => {
    // Starting from 0 (First field name: code0)
    const [fieldIndices, setFieldIndices] = useState<number[]>([]);

    const { register, getValues, setValue, setFocus, trigger } =
      useForm<CodeForm>({ mode: "onChange" });

    /* Use this instead of the of built-in react-hook-form reset.
    /* (It can't detect changes effectively after reset without default values,
    /* eg. writing the same character would not trigger change in the form.) */
    const reset = () => {
      fieldIndices.forEach((index) => {
        const fieldName = `code${index}`;
        setValue(fieldName, "");
      });
    };

    useEffect(() => {
      const idx = Array.from({ length: size }, (_, index) => index);
      setFieldIndices(idx);
    }, [size]);

    const handleFocusFirstInput = useCallback(() => {
      if (fieldIndices.length > 0) {
        setFocus("code0");
      }
    }, [fieldIndices.length, setFocus]);

    useEffect(() => {
      handleFocusFirstInput();
    }, [handleFocusFirstInput]);

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        handleFocusFirstInput(); // Focus first input
        reset(); // Reset the form
      },
    }));

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = e.target;
      const fieldIndex = parseInt(name.slice(-1), 10);

      if (value.length === 1 && fieldIndex + 1 < size) {
        const nextField = `code${fieldIndex + 1}`;
        setFocus(nextField);
      }
    };

    // Pastes the text on the clipboard (Ctrl + V), starting from the current field
    // Sets the focus on the next empty field or the last field
    const handlePaste = useCallback(
      (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();

        const clipboardData = event.clipboardData || "";
        const pastedText = clipboardData.getData("Text");

        const fieldDetails = getFieldDetailsFromElement();
        if (!fieldDetails) return;

        const { fieldIndex } = fieldDetails;

        for (let i = fieldIndex; i < size; i += 1) {
          const nextChar = pastedText[i - fieldIndex];

          if (nextChar) {
            setValue(`code${i}`, nextChar, { shouldDirty: true });
          }

          if (!nextChar || i === size - 1) {
            setFocus(`code${i}`);
            break;
          }
        }
      },
      [setFocus, setValue, size]
    );

    // Moves focus to te right or left field and set cursor to the end of the text
    const handleMoveFocus = useCallback(
      (event: KeyboardEvent, dir: Direction) => {
        event.preventDefault();

        const fieldDetails = getFieldDetailsFromElement();
        if (!fieldDetails) return;

        const { fieldIndex } = fieldDetails;
        let fieldToFocusIndex = fieldIndex;

        if (dir === "right" && fieldIndex < size - 1) {
          fieldToFocusIndex = fieldIndex + 1;
        }

        if (dir === "left" && fieldIndex > 0) {
          fieldToFocusIndex = fieldIndex - 1;
        }

        setFocus(`code${fieldToFocusIndex}`);
        setInputCursorToEnd(getActiveElement() as HTMLInputElement);
      },
      [setFocus, size]
    );

    // If current input is not empty, deletes current input character
    // If current input is empty, deletes previous input character and moves focus to that
    const handleBackspace = useCallback(() => {
      const fieldDetails = getFieldDetailsFromElement();
      if (!fieldDetails) return;

      const { name, fieldIndex } = fieldDetails;
      const value = getValues(name);

      if (value !== "") {
        setValue(name, "");
      } else if (fieldIndex !== 0) {
        const previousField = `code${fieldIndex - 1}`;
        setFocus(previousField);
      }
    }, [getValues, setFocus, setValue]);

    const handleKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (e.key === "Backspace") {
          handleBackspace();
        } else if (e.key === "ArrowLeft") {
          handleMoveFocus(e, "left");
        } else if (e.key === "ArrowRight") {
          handleMoveFocus(e, "right");
        }
      },
      [handleBackspace, handleMoveFocus]
    );

    useEffect(() => {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleKeyDown]);

    const autoSubmitForm = () => {
      trigger().then((formValid: boolean) => {
        if (formValid) {
          const result = Object.values(getValues()).join("");
          if (submit !== undefined) {
            submit(result);
          }
        }
      });
    };

    return (
      <form className="flex flex-col items-center justify-center text-center">
        {label ? <label>{label}</label> : <></>}
        <div className="flex space-x-3">
          {fieldIndices.map((index: number) => (
            <div key={`code${index}`}>
              <Field
                name={`code${index}`}
                register={register}
                autoComplete={AutoCompleteOptions.off}
                classInput="tw-input text-center"
                className="p-0 m-0"
                disableNotNumberCharacters={disableNotNumberCharacters}
                maxLength={1}
                onChange={(e) => {
                  handleTextChange(e);
                  if (index === size - 1) {
                    autoSubmitForm();
                  }
                }}
                onPaste={(e) => {
                  handlePaste(e);
                  autoSubmitForm();
                }}
                optional={optional}
                placeholder={fill ? "*" : ""}
                placeholderHighlight={fill}
                readOnly={readOnly}
                required
                sm={sm}
                type={hideCharacters ? "password" : "text"}
                width="2.5rem"
              />
            </div>
          ))}
        </div>
      </form>
    );
  }
);

export default CodeInputsForm;
