/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getGeneralError } from "../../util/helperFunctions";
import { PageProps } from "../../models/PageProps";
import { Loader } from "../../components/Loader";
import { InvoiceResponse } from "../../models/invoice/InvoiceResponse";
import InvoiceApi from "../../api/InvoiceApi";
import { InvoiceStatus } from "../../models/invoice/InvoiceStatus";
import InvoiceDetails from "./InvoiceDetails/InvoiceDetails";
import CreateInvoice from "./CreateInvoice/CreateInvoice";
import AlertBox from "../../components/AlertBox";

type Params = {
  invoiceId: string;
};

const InvoicePage = (props: PageProps): ReactElement => {
  const { setPageLoading } = props;
  const { invoiceId } = useParams<Params>();

  const [invoice, setInvoice] = useState<InvoiceResponse>();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getInvoice = async (id: string) => {
      try {
        setPageLoading(true);
        const response = await InvoiceApi.getInvoice(id);
        setInvoice(response.data);
        setError(null);
        setPageLoading(false);
      } catch (e) {
        setError(await getGeneralError(e));
        setPageLoading(false);
      }
    };

    if (invoiceId) {
      void getInvoice(invoiceId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  if (error) {
    return <AlertBox message={error} closeAble={false} className="m-3" />;
  }

  if (invoice?.status === InvoiceStatus.IN_PROGRESS) {
    return (
      <CreateInvoice
        type="finish"
        setPageLoading={setPageLoading}
        invoiceId={invoiceId}
        clinicId={invoice.clinicId}
      />
    );
  }

  if (invoice && invoiceId) {
    return (
      <InvoiceDetails invoiceId={invoiceId} setPageLoading={setPageLoading} />
    );
  }

  return <></>;
};

export default Loader(InvoicePage);
