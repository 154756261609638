/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../common/Strings/Strings";
import AddItemFromVara from "../CreateItem/VARA/AddItemFromVara";
import { VaraItem } from "../../../models/inventory/item/VaraItem";
import Modal from "../../../components/Modal/Modal";

interface Props {
  isOpen: boolean;
  close(): void;
  returnSelectedVaraPackage: (p: VaraItem) => void;
}

const VaraItemModal: React.FC<Props> = ({
  isOpen,
  close,
  returnSelectedVaraPackage,
}: Props) => (
  <Modal handleClose={close} show={isOpen} size="modal-lg">
    <Modal.Header title={strings.addItemFromVara} />
    <Modal.Body>
      <AddItemFromVara returnPackage={returnSelectedVaraPackage} />
    </Modal.Body>
  </Modal>
);

export default VaraItemModal;
