/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TicketingApi from "../../../api/TicketingApi";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import AlertBox from "../../../components/AlertBox";
import ChatInput from "../../../components/chat/ChatInput";
import { TicketMessages } from "../../../components/chat/Messages";
import { useUser } from "../../../contexts/UserContext";
import { TicketResponse } from "../../../models/support/TicketResponse";
import { TICKET_TYPES } from "../../../models/support/TicketType";
import { getGeneralError } from "../../../util/helperFunctions";
import StaticStatus from "./StaticStatus";

type MatchParams = {
  ticketId: string;
};

export const TicketPage: React.FC = () => {
  const navigate = useNavigate();

  const matchParams = useParams<MatchParams>();
  const ticketId = Number(matchParams.ticketId);

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const uuid = searchParams.get("uuid");
  const isTicketAuthorized = !(email && uuid);
  const { user } = useUser();

  const [urlIsInvalid, setUrlIsInvalid] = useState<boolean>(false);
  const [notFoundOrNotAuthorized, setNotFoundOrNotAuthorized] =
    useState<boolean>(false);
  const [ticketData, setTicketData] = useState<TicketResponse | undefined>();

  const [generalError, setGeneralError] = useState<string | null>(null);

  useEffect(() => {
    if (!ticketId || (!user.userId && isTicketAuthorized)) {
      setUrlIsInvalid(true);
      return undefined;
    }

    const fetchAuthorizedTicket = async () => {
      try {
        const resp = await TicketingApi.getAuthorizedTicket(ticketId);
        setTicketData(resp.data);
      } catch (e) {
        const err = e as any;
        const status = err.response.status;
        if (status === 404 || status === 403) {
          setNotFoundOrNotAuthorized(true);
        } else {
          setGeneralError(await getGeneralError(err));
        }
      }
    };

    const fetchUnauthorizedTicket = async () => {
      if (!uuid || !email) return;

      try {
        const resp = await TicketingApi.getUnauthorizedTicket(
          ticketId,
          uuid,
          email
        );
        setTicketData(resp.data);
      } catch (e) {
        const err = e as any;
        const status = err.response.status;
        if (status === 404 || status === 403) {
          setNotFoundOrNotAuthorized(true);
        } else {
          setGeneralError(await getGeneralError(err));
        }
      }
    };

    const fetchTicket = async () => {
      if (isTicketAuthorized) {
        await fetchAuthorizedTicket();
      } else {
        await fetchUnauthorizedTicket();
      }
    };

    const interval = setInterval(async () => {
      try {
        await fetchTicket();
      } catch (err) {
        setGeneralError(await getGeneralError(err));
        clearInterval(interval);
      }
    }, 10000);

    void fetchTicket();

    return () => {
      clearInterval(interval);
    };
  }, [
    isTicketAuthorized,
    matchParams,
    searchParams,
    ticketId,
    user.userId,
    email,
    uuid,
  ]);

  const addMessage = async (message: string) => {
    if (!uuid || !email) return;

    try {
      if (!isTicketAuthorized) {
        const resp = await TicketingApi.createUnauthorizedTicketArticle(
          ticketId,
          {
            content: message,
            ticketKey: uuid,
            userEmail: email,
          }
        );
        setTicketData(resp.data);
      } else {
        const resp = await TicketingApi.createAuthorizedTicketArticle(
          ticketId,
          {
            content: message,
          }
        );
        setTicketData(resp.data);
      }
    } catch (err) {
      setGeneralError(await getGeneralError(err));
    }
  };

  const goToUserTickets = () => {
    navigate("/support/tickets");
  };

  const getErrorMessage = () => {
    if (urlIsInvalid) return strings.invalidTicketUrl;
    if (notFoundOrNotAuthorized) return strings.ticketNotFoundOrNotAuthorized;
    if (!ticketData) return generalError;
    return null;
  };

  if (urlIsInvalid || notFoundOrNotAuthorized || !ticketData)
    return (
      <main className="main-default">
        <section>
          <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
            <AlertBox className="my-3" message={getErrorMessage()} />
          </div>
        </section>
      </main>
    );

  return (
    <main className="main-default">
      <section>
        <div className="space-y-6 mx-40 px-4 md:px-0 py-10 md:py-14">
          {isTicketAuthorized && (
            <div
              className="mt-1 paragraph-small btn-link"
              style={{ cursor: "pointer" }}
              role="button"
              tabIndex={0}
              onClick={goToUserTickets}
            >
              <i className="mr-2 fas fa-arrow-left" />
              {strings.backToTicketsPage}
            </div>
          )}
          <div className="flex justify-between">
            <div>
              <h3 style={{ width: "100%" }}>{ticketData?.title}</h3>
              <div className="font-weight-bold">{email}</div>
              <div>
                {moment(ticketData?.createdAt).format(
                  dateAndTime.momentDateTimeFormat
                )}
              </div>
            </div>
            <div className="flex">
              <StaticStatus
                label={strings.supportTicketState}
                content={
                  ticketData?.closeAt
                    ? strings.supportTicketClosed
                    : strings.supportTicketOpen
                }
              />
              <StaticStatus
                label={strings.supportTicketType}
                content={
                  ticketData?.type &&
                  (
                    TICKET_TYPES.find(
                      (type) => type.key === ticketData?.type
                    ) || { value: "" }
                  ).value
                }
              />
            </div>
          </div>

          <div className="card p-1 pt-4 pb-2 pl-4">
            <TicketMessages
              articles={ticketData?.articles || []}
              userEmail={email || user.details?.fullName || ""}
            />
            <div className="hr-on-top mt-3 pt-2 pr-4">
              <ChatInput
                submitAnswer={addMessage}
                titleText={strings.supportTicketChatAnswerTitle}
                buttonText={strings.supportTicketChatAnswerButton}
                isDisabled={Boolean(ticketData?.closeAt)}
              />
              <div>
                {ticketData?.closeAt &&
                  strings.supportTicketIsClosed(
                    moment(ticketData?.closeAt).format(
                      `${dateAndTime.momentDateFormat} ${dateAndTime.timeFormat}`
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TicketPage;
