/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { strings } from "../../common/Strings/Strings";
import LoaderInline from "../LoaderInline";
import Button from "../Button";
import Modal from "../Modal/Modal";

interface Props {
  content?: string | ReactElement;
  loading?: boolean;
  open: boolean;
  onHide(): void;
  returnYes?(): void;
  title?: string;
}

export const BeforeDeleteModal: React.FC<Props> = ({
  open,
  onHide,
  returnYes,
  title = strings.deleteConfirmationTitle,
  content = strings.deleteConfirmation,
  loading = false,
}) => (
  <Modal handleClose={onHide} show={open}>
    <Modal.Header title={title} />
    <Modal.Body>
      <p className="break-words text-left">{content}</p>
    </Modal.Body>
    <Modal.Footer>
      <LoaderInline hidden={!loading} />
      <Button
        className="modal-main-button"
        hidden={!returnYes || loading}
        onClick={returnYes}
        variant="danger"
      >
        {strings.yes}
      </Button>
      <Button
        className="modal-sub-button"
        hidden={loading}
        onClick={onHide}
        variant="secondary"
      >
        {!returnYes ? strings.cancel : strings.no}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default BeforeDeleteModal;
