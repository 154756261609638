/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, useEffect, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import { VaraItem } from "../../../../models/inventory/item/VaraItem";
import { CommodityProduct } from "../../../../models/ePrescription/VARA/CommodityProduct";
import { CommodityArticle } from "../../../../models/ePrescription/VARA/CommodityArticle";
import SimpleSelect from "../../../../components/InputFieldsSimple/SimpleSelect";
import { generateOptions } from "../../../../util/helperFunctions";
import PackageInfoCommodity from "../../../ePrescription/productDetails/PackageInfoCommodity";
import Button from "../../../../components/Button";
import { Option } from "../../../../models/Option";

interface Props {
  commodityProduct?: CommodityProduct;
  returnPackages?(p: VaraItem): void;
}

const VaraCommodityPackageSelection: React.FC<Props> = ({
  commodityProduct,
  returnPackages,
}: Props) => {
  const [selectedPackageId, setSelectedPackageId] = useState<string>();
  const [packageOptions, setPackageOptions] = useState<Option[]>([]);

  useEffect(() => {
    setPackageOptions(
      generateOptions(commodityProduct?.articles || [], "title", "itemNumber")
    );
  }, [commodityProduct]);

  const selectPackage = (value: string) => {
    setSelectedPackageId(value);
  };

  return (
    <>
      <SimpleSelect
        name="packageSelect"
        onChange={(e) => selectPackage(e?.target?.value)}
        options={packageOptions}
      />
      {commodityProduct && commodityProduct.articles ? (
        commodityProduct.articles
          .filter((a: CommodityArticle) => a.itemNumber === selectedPackageId)
          .map((a: CommodityArticle, index: number) => (
              <Fragment key={a.itemNumber}>
                <PackageInfoCommodity selectedPackage={a} />
              </Fragment>
            ))
      ) : (
        <></>
      )}
      <div className="d-flex">
        <Button
          minW
          onClick={() => {
            const resultPackage = commodityProduct?.articles?.filter(
              (a: CommodityArticle) => a.itemNumber === selectedPackageId
            )[0];

            if (resultPackage && returnPackages) {
              const newVaraItem: VaraItem = {
                name: `${resultPackage.title || resultPackage.label}`,
                barcode: resultPackage?.barcode,
                unit: resultPackage?.quantityNumericUnit,
                packageContent: resultPackage?.quantityNumeric,
                varaNvn: resultPackage.itemNumber,
              };
              returnPackages(newVaraItem);
            }
          }}
        >
          {strings.use}
        </Button>
      </div>
    </>
  );
};

export default VaraCommodityPackageSelection;

VaraCommodityPackageSelection.defaultProps = {
  commodityProduct: undefined,
};
