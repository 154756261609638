/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { VersionInfo } from "../models/version/VersionInfo";

export default class VersionApi {
  static getVersionInfo(baseUrl: string): ApiPromise<VersionInfo> {
    const url = `${baseUrl}/api/versions`;
    return axiosCommon.get(url);
  }
}
