/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { NewPetRequest } from "../models/pet/NewPetRequest";
import { UpdatePetRequest } from "../models/pet/UpdatePetRequest";
import { UserPetResponse } from "../models/pet/UserPetResponse";
import { axiosCommon } from "../util/axiosSetup";

export default class UserPetApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getUserPets(): ApiPromise<UserPetResponse[]> {
    return axiosCommon.get(`${this.baseUrl}/user-pets`);
  }

  static getUserPet(userPetId: string): ApiPromise<UserPetResponse> {
    return axiosCommon.get(`${this.baseUrl}/user-pets/${userPetId}`);
  }

  static createUserPet(
    creationRequest: NewPetRequest
  ): ApiPromise<UserPetResponse> {
    return axiosCommon.post(`${this.baseUrl}/user-pets`, creationRequest);
  }

  static updateUserPet(
    petId: string,
    updateRequest: UpdatePetRequest
  ): ApiPromise<UserPetResponse> {
    const url = `${this.baseUrl}/user-pets/${petId}`;
    return axiosCommon.put(url, updateRequest);
  }
}
