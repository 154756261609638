/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ChangeEvent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import SelectInsuranceCompany from "../../../components/ReactHookFormFields/Specific/SelectInsuranceCompany";
import Field from "../../../components/ReactHookFormFields/General/Field";
import Switch from "../../../components/ReactHookFormFields/General/Switch";
import { strings } from "../../../common/Strings/Strings";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { getVatOptions } from "../../../util/helperFunctions";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import NumberFormatter from "../../../util/NumberFormatter";
import { InsuranceCompanyResponse } from "../../../models/insurance/InsuranceCompanyResponse";
import { AccountingCodeResponse } from "../../../models/accounting/AccountingCodeResponse";
import { CartItem } from "../../../util/InvoiceCartUtils";
import CostCalculator from "../../../util/CostCalculator";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";

export interface InsuranceForm {
  accountingCode: AccountingCodeResponse[];
  description: string;
  insuredAmount: number;
  company: InsuranceCompanyResponse[];
  on: boolean;
  vat: number;
}

interface Props {
  clinic?: ClinicResponse;
  countryDetails?: CountryDetailsResponse;
  sum: number;
  cart: CartItem[];
}

const Insurance: React.FC<Props> = ({
  clinic,
  countryDetails,
  sum,
  cart,
}: Props) => {
  const [isOn, setIsOn] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [vat, setVat] = useState<number>(0);
  const [netAmount, setNetAmount] = useState<number>(0);

  const {
    register,
    control,
    formState: { errors },
    getValues,
  } = useFormContext<InsuranceForm>();

  const required = (v: string | undefined | []) => {
    const on = getValues("on");

    if (!on) {
      return true;
    }
    return !!v && v.length > 0;
  };

  const requiredNumber = (v: number | undefined) => {
    const on = getValues("on");

    if (!on) {
      return true;
    }

    return !!v && v > 0;
  };

  useEffect(() => {
    setNetAmount(CostCalculator.getNetPrice(amount, vat));
  }, [amount, vat]);

  const getTotalValue = (): number => {
    const costItems = cart.map((item) => ({
      price: item.price,
      quantity: item.quantity,
      vat: item.vat,
    }));

    costItems.push({
      price: -netAmount,
      quantity: 1,
      vat,
    });

    return CostCalculator.getTotalGrossPriceOfInvoiceItems(
      costItems,
      countryDetails,
      clinic
    );
  };

  return (
    <>
      <div className="tw-card mt-4">
        <div>
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
            {strings.insuranceTitle}
            <div className="ml-auto">
              <Switch
                control={control}
                name="on"
                onChange={() => {
                  setIsOn(!isOn);
                }}
              />
            </div>
          </h2>
        </div>
        <div hidden={!isOn}>
          <div className="space-y-4 p-4">
            <div className="flex space-x-4">
              <div className="flex-fill">
                <SelectInsuranceCompany
                  allowNew
                  fieldOptions={{
                    validate: {
                      isRequired: required,
                    },
                  }}
                  label={strings.payer}
                  name="company"
                  control={control}
                />
              </div>
              <Field
                className="mb-0"
                error={errors.insuredAmount}
                fieldOptions={{
                  validate: {
                    isRequired: requiredNumber,
                  },
                }}
                label={`${strings.amount} (${strings.gross})`}
                name="insuredAmount"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setAmount(e.target.value ? Number(e.target.value) : 0);
                }}
                register={register}
                suffix={countryDetails?.currency}
                type="number"
                width="140px"
              />
            </div>
            <div className="flex space-x-4">
              <Field
                autoComplete={AutoCompleteOptions.off}
                className="mb-0"
                error={errors.description}
                fieldOptions={{
                  validate: {
                    isRequired: required,
                  },
                }}
                name="description"
                register={register}
                label={strings.name}
              />
              <Select
                className="mb-0"
                error={errors.vat}
                isChooseOption={false}
                fieldOptions={{
                  valueAsNumber: true,
                }}
                name="vat"
                options={getVatOptions(countryDetails?.vatRates)}
                register={register}
                label={strings.vatAsTax}
                width="80px"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setVat(e.target.value ? Number(e.target.value) : 0);
                }}
              />
            </div>
            <CombinedSelect
              className="mb-0"
              control={control}
              fieldOptions={{
                validate: {
                  isRequired: required,
                },
              }}
              label={strings.accountingCode}
              labelKey="name"
              name="accountingCode"
              options={countryDetails?.accountingCodes}
            />
          </div>
          <div className="d-flex border-t p-4">
            <div>
              <div className="text-gray-500">
                {strings.total} ({strings.gross})
              </div>
              <div className="text-gray-500">{strings.insuranceTitle}</div>
              <div className="bold">
                {strings.total} ({strings.gross})
              </div>
            </div>
            <div className="ml-auto text-right">
              <div className="text-gray-500">
                {NumberFormatter.formatPrice(sum, countryDetails?.currency)}
              </div>
              <div className="text-gray-500">
                {NumberFormatter.formatPrice(-amount, countryDetails?.currency)}
              </div>
              <div className="bold">
                {NumberFormatter.formatPrice(
                  getTotalValue(),
                  countryDetails?.currency
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Insurance;
