/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../common/Strings/Strings";
import { ReservationAccessScope } from "../../../models/reservation/ReservationAccessScope";
import { TreatmentResponse } from "../../../models/treatment/TreatmentResponse";
import { MedicalTreatmentResponse } from "../../../models/medical/MedicalTreatmentResponse";
import TreatmentInfoBlock from "./TreatmentInfoBlock";
import AlertBox from "../../../components/AlertBox";
import { Link } from "react-router-dom";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { useForm } from "react-hook-form";
import Button from "../../../components/Button";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";

interface Props {
  accessScope: ReservationAccessScope;
  currentTreatments?: MedicalTreatmentResponse[];
  countryDetails?: CountryDetailsResponse;
  error: string | null;
  handleTreatmentsChange: (
    input: TreatmentResponse[],
    treatmentQuantity: number
  ) => void;
  markTreatmentToDelete: (treatmentId: string) => void;
  signed: boolean;
  treatments: TreatmentResponse[];
}

interface FormData {
  treatment: TreatmentResponse[] | null;
  quantity: number;
}

const TreatmentSection: React.FC<Props> = ({
  accessScope,
  currentTreatments,
  countryDetails,
  error,
  handleTreatmentsChange,
  markTreatmentToDelete,
  signed,
  treatments,
}: Props) => {
  const canEdit = signed || accessScope === ReservationAccessScope.READ;
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      quantity: 1,
    },
  });

  const selectedTreatment = watch("treatment");

  const submit = ({ treatment, quantity }: FormData) => {
    if (treatment !== null) {
      handleTreatmentsChange(treatment, quantity);
      reset();
    }
  };

  return (
    <>
      <AlertBox message={error} />
      <form onSubmit={handleSubmit(submit)}>
        <div className="relative flex-fill">
          <div className="flex flex-wrap gap-4 items-end">
            <div className="relative grow">
              <label htmlFor="inventoryItem">{strings.treatments}</label>
              {canEdit ? (
                <></>
              ) : (
                <>
                  <div className="absolute right-0 top-0">
                    <Link className="text-sm" to="/treatments">
                      {strings.createNewTreatment}
                    </Link>
                  </div>
                  <CombinedSelect
                    allowNew={false}
                    control={control}
                    clearButton
                    labelOff
                    labelKey="name"
                    name="treatment"
                    options={treatments}
                  />
                </>
              )}
            </div>
            {selectedTreatment && (
              <>
                <Field
                  autoComplete={AutoCompleteOptions.off}
                  error={errors.quantity}
                  fieldOptions={{
                    validate: {
                      positive: (v) => v > 0,
                    },
                  }}
                  label={strings.quantity}
                  name="quantity"
                  register={register}
                  required
                  step="1"
                  type="number"
                  width="100px"
                />
                <div>
                  <Button
                    hidden={!selectedTreatment}
                    variant="primary"
                    className="text-sm"
                    type="submit"
                  >
                    {strings.add}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
      {currentTreatments?.map((record: MedicalTreatmentResponse) => (
        <TreatmentInfoBlock
          canEdit={canEdit}
          countryDetails={countryDetails}
          markTreatmentToDelete={() => {
            markTreatmentToDelete(record.id);
          }}
          medicalTreatment={record}
          key={record.id}
          treatment={treatments.find(
            (t: TreatmentResponse) => record.id === t.id
          )}
          quantity={record.quantity}
        />
      ))}
    </>
  );
};

export default TreatmentSection;
