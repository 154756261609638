/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Control, Controller, RegisterOptions } from "react-hook-form";
import React, { ReactElement, useEffect, useState } from "react";
import { strings } from "../../../../common/Strings/Strings";
import SimpleTextarea, {
  TextareaComponentProps,
} from "../../../InputFieldsSimple/SimpleTextarea";

interface FieldProps {
  control: Control<any>;
  name: string;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  onChange?(e: any): void;
  required?: boolean;
}

const ControlledTextarea = ({
  control,
  name: n,
  fieldOptions,
  maxLength,
  onChange: customOnchange,
  required,
  ...rest
}: FieldProps & TextareaComponentProps): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});

  useEffect(() => {
    if (required) {
      setRegisterOptions((prevState) => ({ ...prevState, required: true }));
    }
  }, [required]);

  useEffect(() => {
    if (maxLength) {
      setRegisterOptions((prevState) => ({
        ...prevState,
        maxLength: {
          value: maxLength,
          message: strings.tooManyCharactersEntered,
        },
      }));
    }
  }, [maxLength]);

  return (
    <Controller
      name={n}
      control={control}
      rules={registerOptions}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <SimpleTextarea
          name={n}
          error={error}
          onChange={(v: string) => {
            onChange(v);

            if (customOnchange) {
              customOnchange(v);
            }
          }}
          value={value}
          {...rest}
        />
      )}
    />
  );
};

export default ControlledTextarea;
