/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const defaultPharmacyGLN = "7350045511119";
export const defaultPharmacyGLNTest = "7350045514448";
export const specificPharmacyTest1 = "7350045514400";
export const specificPharmacyTest2 = "7350045514417";

export const varaVersion = "5";

export const defaultPetId = "-1";

export const licensedNplId = "SB060301000001";
export const licensedNplPackId = "SB060301100001";
export const packageArticleIdTypeNVN = "NORDISKT_VARUNR";
export const packageArticleIdIdNVN = "640000";
export const licenseProductType = "LIC";
export const technicalSpiritType = "ALK";
export const notApprovedProducts = ["LIC", "APL", "BER"];
export const approvedProducts = [
  "ALK",
  "ALL",
  "A_58",
  "FOR",
  "HOM",
  "IPL",
  "LLI",
  "MEP",
  "MF",
  "MTK",
  "NIN",
  "NLM",
  "NOT_SPECIFIED",
  "OVL",
  "PLI",
  "RAP",
  "TJA",
  "TVB",
  "VBL",
  "VUM",
];

/* M-KK8.7: Result codes 1, 2 and 8 should not be returned to prescribers, but handled by the care system */
export const acceptedCancellationCodes = ["3", "9"];
export const notAcceptedCancellationCodes = ["4", "5", "6", "7", "8"];

export const swedishCountryCode = "SE";
export const dateFormat = "YYYY-MM-DD";
export const animalCarerBirthDateFormat = "YYYYMMDD";

export default {
  defaultPharmacyGLN,
  licensedNplId,
  licensedNplPackId,
  packageArticleIdTypeNVN,
  packageArticleIdIdNVN,
  specificPharmacyTest1,
  specificPharmacyTest2,
  varaVersion,
  defaultPetId,
  defaultPharmacyGLNTest,
  technicalSpiritType,
  notApprovedProducts,
  approvedProducts,
  licenseProductType,
  acceptedCancellationCodes,
  notAcceptedCancellationCodes,
  swedishCountryCode,
  dateFormat,
  animalCarerBirthDateFormat,
};
