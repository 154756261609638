/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import MainFormSectionTemplate from "./Templates/SubsectionTemplate";
import { strings } from "../../../common/Strings/Strings";
import { MedicalRecordResponse } from "../../../models/medical/MedicalRecordResponse";
import SimpleTextarea from "../../../components/InputFieldsSimple/SimpleTextarea";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { ReservationAccessScope } from "../../../models/reservation/ReservationAccessScope";
import TextAreaTemplate from "./Templates/TextAreaTemplate";
import Diagnostics from "../Diagnostics/Diagnostics";
import Button from "../../../components/Button";
import { MedicalRecordFileType } from "../../../models/medical/MedicalRecordFileType";
import AddButtonIcon from "../../../components/AddButtonIcon";
import Files from "../Files/Files";
import { useMainSectionContext } from "./MainSectionContext";

const ExaminationSection: React.FC = () => {
  const {
    medicalRecord,
    signed,
    accessScope,
    physicalExamination,
    setPhysicalExamination,
    diagnostics,
    setDiagnostics,
    triggerChange,
    setFilesModalOpen,
    files,
    resIdS,
  } = useMainSectionContext();

  return (
    <MainFormSectionTemplate title={strings.examination}>
      <TextAreaTemplate
        name={"physicalExamination"}
        label={strings.physicalExamination}
        accessScope={accessScope}
        signed={signed}
        value={physicalExamination}
        setValue={setPhysicalExamination}
        triggerChange={triggerChange}
      />

      <TextAreaTemplate
        name={"diagnostics"}
        label={strings.diagnostics}
        accessScope={accessScope}
        signed={signed}
        value={diagnostics}
        setValue={setDiagnostics}
        triggerChange={triggerChange}
      />

      {!signed && medicalRecord && (
        <div className="grid grid-cols-12 gap-4">
          <Diagnostics
            species={medicalRecord.pet.species}
            readOnly={signed}
            returnDiagnosticsText={(result: string) => {
              setDiagnostics((prevState) => `${prevState}\n${result}`);
            }}
          />
          <Button
            variant="primary-outline"
            fullWidth
            className="col-span-12 lg:col-span-6"
            disabled={signed || accessScope === ReservationAccessScope.READ}
            onClick={() => setFilesModalOpen(MedicalRecordFileType.DIAGNOSTICS)}
          >
            <div className="flex items-center justify-center">
              <AddButtonIcon />
              {strings.documents}
            </div>
          </Button>
        </div>
      )}

      <Files
        canEdit={!(signed || accessScope === ReservationAccessScope.READ)}
        files={files}
        resIdS={resIdS}
        type={MedicalRecordFileType.DIAGNOSTICS}
      />
    </MainFormSectionTemplate>
  );
};

export default ExaminationSection;
