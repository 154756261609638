/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { TodayShift } from "../../../../models/calendar/TodayShift";
import { CalendarReservation } from "../../../../models/calendar/CalendarReservation";
import Column from "./Column";
import { CalendarRowsByColumn } from "../../../../models/calendar/Rendering/CalendarRowsByColumn";
import CalendarDayReservationModal from "../../Reservation/CalendarDayReservationModal";
import {
  headerHeight,
  maxColumnsPerPage,
  momentDateFormat,
  unitCellHeight,
} from "../../calendarConfig";
import Paging from "../../../../components/Paging";
import { mergeTimeAndDate } from "../../../../util/helperFunctions";
import { dateAndTime } from "../../../../common/Strings/Strings";

interface Props {
  calendarStartTime: moment.Moment;
  calendarEndTime: moment.Moment;
  day: string;
  handleNewReservationModal(
    open: boolean,
    dateAndTime?: { time: string; date: string },
    selectedShift?: TodayShift,
  ): void;
  reloadReservations(): void;
  refreshReservationInProgress(): void;
  schedule: CalendarRowsByColumn;
  times: string[];
}

const NewCalendarDay: React.FC<Props> = ({
  calendarEndTime,
  calendarStartTime,
  day,
  handleNewReservationModal,
  refreshReservationInProgress,
  reloadReservations,
  schedule,
  times,
}: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [activeReservation, setActiveReservation] =
    useState<CalendarReservation>();
  const [page, setPage] = useState<number>(1);
  const [actualTimeHeight, setActualTimeHeight] = useState<number>(0);

  const refCalendar = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const height: number =
      Math.abs(calendarStartTime.diff(moment(), "minutes")) * unitCellHeight +
      headerHeight;
    setActualTimeHeight(height);

    if (moment().isSame(moment(day), "days")) {
      const calendarHeight: number = refCalendar?.current?.clientHeight || 0;
      refCalendar?.current?.scrollTo(
        0,
        height - calendarHeight / 2 + headerHeight,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (
    reservationData: CalendarReservation | number | boolean | undefined,
  ): void => {
    if (
      typeof reservationData !== "number" &&
      typeof reservationData !== "boolean" &&
      reservationData
    ) {
      setActiveReservation(reservationData);
      setModalIsOpen(true);
    }
  };

  const closeModal = (): void => {
    setModalIsOpen(false);
  };

  const openNewModal = (time: string, shift?: TodayShift): void => {
    refreshReservationInProgress();
    const dayAndTime = { date: moment(day).format(momentDateFormat), time };
    handleNewReservationModal(true, dayAndTime, shift);
  };

  const proxyReload = (): void => {
    reloadReservations();
  };

  return (
    <div className="grid grid-cols-12 gap-6 calendar-scroll">
      <div className="shadow relative col-span-12 bg-white dark:bg-gray-800 p-6">
        <div hidden={Object.entries(schedule).length <= maxColumnsPerPage}>
          <div className="flex items-center justify-center">
            <Paging
              totalPages={
                Math.ceil(
                  Object.entries(schedule).length / maxColumnsPerPage,
                ) || 1
              }
              currentPage={page - 1}
              selectPage={(newPage: number) => {
                setPage(newPage + 1);
              }}
            />
          </div>
        </div>

        <div
          className={`relative grid grid-cols-1 xl:grid-cols-4 gap-6`}
          ref={refCalendar}
        >
          {schedule &&
            Object.entries(schedule).map((row, index: number) => {
              if (
                index >= (page - 1) * maxColumnsPerPage &&
                index < page * maxColumnsPerPage
              ) {
                return (
                  <div className="flex flex-col space-y-2 relative" key={index}>
                    <div
                      style={{
                        display:
                          !moment().isSame(moment(day), "days") ||
                          moment().isAfter(calendarEndTime, "minutes") ||
                          moment().isBefore(calendarStartTime, "minutes")
                            ? "none"
                            : "initial",
                        top: `${actualTimeHeight}px`,
                      }}
                      className="absolute z-10 w-full h-0.5 bg-red-500 dark:bg-red-400"
                    >
                      <div className="rounded-full -ml-2 -mt-1.5 w-3.5 h-3.5 bg-red-500 dark:bg-red-400"></div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                      <p>
                        <strong>{row[1].columnShift.collaboratorName}</strong>
                      </p>
                      <p>
                        {row[1].columnShift?.firstStartTime &&
                          moment(row[1].columnShift.firstStartTime).format(
                            moment(row[1].columnShift.firstStartTime).isSame(
                              moment(day),
                              "days",
                            )
                              ? dateAndTime.timeFormat
                              : dateAndTime.momentDateTimeFormat,
                          )}
                        –
                        {row[1].columnShift?.lastEndTime &&
                          moment(row[1].columnShift.lastEndTime).format(
                            moment(row[1].columnShift.lastEndTime).isSame(
                              moment(day),
                              "days",
                            )
                              ? dateAndTime.timeFormat
                              : dateAndTime.momentDateTimeFormat,
                          )}
                      </p>
                    </div>
                    <div className="grid grid-cols-12">
                      <Column
                        clickEmptySlot={(time: string) => {
                          let clickedShift: TodayShift | undefined;
                          row[1]?.columnShift?.mergedShifts?.forEach(
                            (shift: TodayShift) => {
                              if (
                                moment(
                                  mergeTimeAndDate(time, new Date(day)),
                                ).isBetween(
                                  moment(shift.startTime),
                                  moment(shift.endTime),
                                  "minute",
                                  "[]",
                                )
                              ) {
                                clickedShift = shift;
                              }
                            },
                          );
                          openNewModal(time, clickedShift);
                        }}
                        clickReservation={(
                          reservation:
                            | CalendarReservation
                            | number
                            | boolean
                            | undefined,
                        ) => {
                          openModal(reservation);
                        }}
                        column={row[1]}
                        day={day}
                        times={times}
                      />
                    </div>
                  </div>
                );
              }
              return <></>;
            })}
        </div>
        {activeReservation !== undefined ? (
          <CalendarDayReservationModal
            activeReservation={activeReservation}
            closeModal={closeModal}
            modalIsOpen={modalIsOpen}
            navigateForEdit={false}
            refreshReservationInProgress={refreshReservationInProgress}
            reload={proxyReload}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default NewCalendarDay;
