/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Control, FieldErrors, RegisterOptions } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { RegisterReturnType } from "../../../models/RegisterReturnType";
import PhoneNumber from "../../../components/ReactHookFormFields/Specific/PhoneNumber";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import { useClinic } from "../../../contexts/ClinicContext";
import { defaultCountryCodeOfClinic } from "../../../common/DefaultCountry";
import Button from "../../../components/Button";
import { Cross } from "../../../common/Icons/Cross";

const NewPetOwnerForm = ({
  closable,
  onCancel,
  errors,
  register,
  control,
}: {
  closable?: boolean;
  control: Control<any>;
  errors: FieldErrors;
  onCancel: () => any;
  register(ref: string, registerOptions?: RegisterOptions): RegisterReturnType;
}): ReactElement => {
  const { clinic } = useClinic();

  const defaultCountryCode = defaultCountryCodeOfClinic(clinic);

  return (
    <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
      <div className="p-3 flex items-center">
        <span>{strings.newPetAndOwner}</span>
        <Button variant="icon" hidden={!closable} onClick={onCancel}>
          <Cross />
        </Button>
      </div>
      <div className="p-3 space-y-3">
        <PhoneNumber
          control={control}
          id="mobilePhoneId"
          name="mobilePhone"
          country={defaultCountryCode}
          label={strings.mobilePhoneNumber}
          required
          showRequired
        />
        <Field
          name="name"
          register={register}
          error={errors.name}
          label={strings.petName}
          autoComplete={AutoCompleteOptions.off}
        />
        <CheckBox
          name="userInformedAboutGdpr"
          register={register}
          error={errors.userInformedAboutGdpr}
          autoComplete={AutoCompleteOptions.off}
          label={strings.iHaveInformedOwnerAboutPolicy}
          required
        />
      </div>
    </div>
  );
};

export default NewPetOwnerForm;
