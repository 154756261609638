/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { PaymentSystem } from "../../../models/management/PaymentSystem";
import { PaymentSystemConfigResponse } from "../../../models/management/PaymentSystemConfigResponse";
import PaymentSystemConfigApi from "../../../api/PaymentSystemConfigApi";
import { Trash } from "../../../common/Icons/Trash";
import Button from "../../../components/Button";

interface Props {
  isActiveTab: boolean;
  countryCode: string;
  onLoadingChange: (isLoading: boolean) => void;
  onError: (error: string | null) => void;
}

interface PaymentConfigForm {
  system?: PaymentSystem;
  publicKey?: string;
  secretKey?: string;
}

const PaymentSystemConfig: React.FC<Props> = (props: Props) => {
  const { isActiveTab, countryCode, onLoadingChange, onError } = props;

  const [selectedPaymentSystem, setSelectedPaymentSystem] =
    useState<PaymentSystem>();

  const paymentConfigForm = useForm<PaymentConfigForm>({
    mode: "onChange",
  });

  const initPaymentConfigForm = (
    config: PaymentSystemConfigResponse | undefined
  ) => {
    paymentConfigForm.reset();
    paymentConfigForm.setValue("system", config?.system);
    paymentConfigForm.setValue("publicKey", config?.publicKey);
    paymentConfigForm.setValue("secretKey", config?.secretKey);
    setSelectedPaymentSystem(config?.system);
  };

  const getPaymentSystemConfig = async () => {
    onLoadingChange(true);
    try {
      const resp = await PaymentSystemConfigApi.getConfig(countryCode);
      initPaymentConfigForm(resp.data);
    } catch (err) {
      onError(await getGeneralError(err));
    } finally {
      onLoadingChange(false);
    }
  };

  const deletePaymentSystemConfig = async () => {
    onLoadingChange(true);
    try {
      await PaymentSystemConfigApi.deleteConfig(countryCode);
      initPaymentConfigForm(undefined);
    } catch (err) {
      onError(await getGeneralError(err));
    } finally {
      onLoadingChange(false);
    }
  };

  useEffect(() => {
    if (!isActiveTab) return;
    void getPaymentSystemConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab, countryCode]);

  const submitPaymentConfig = async (f: PaymentConfigForm) => {
    onLoadingChange(true);

    try {
      const resp = await PaymentSystemConfigApi.updateConfig(countryCode, f);
      initPaymentConfigForm(resp.data);
    } catch (err) {
      onError(await getGeneralError(err));
    } finally {
      onLoadingChange(false);
    }
  };

  return (
    <div className="tw-card">
      <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
        {strings.paymentSystem}
      </h2>
      <FormProvider {...paymentConfigForm}>
        <form
          className="p-4 space-y-4"
          onSubmit={paymentConfigForm.handleSubmit(submitPaymentConfig)}
        >
          <Select
            name="system"
            label={strings.paymentSystem}
            options={Object.keys(PaymentSystem).map((i) => ({
              value: i,
              title: i,
            }))}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSelectedPaymentSystem(e.target.value as PaymentSystem);
            }}
            register={paymentConfigForm.register}
            required
          />
          {selectedPaymentSystem === PaymentSystem.STRIPE && (
            <Field
              autoComplete={AutoCompleteOptions.off}
              label={strings.publicKey}
              name="publicKey"
              register={paymentConfigForm.register}
              required
            />
          )}
          {selectedPaymentSystem === PaymentSystem.STRIPE && (
            <Field
              autoComplete={AutoCompleteOptions.off}
              label={strings.secretKey}
              name="secretKey"
              register={paymentConfigForm.register}
              required
            />
          )}
          <div className="pt-4 flex items-center">
            <div>
              <Button variant="icon" onClick={deletePaymentSystemConfig}>
                <Trash />
              </Button>
            </div>
            <div className="ml-auto">
              <Button
                type="submit"
                disabled={!paymentConfigForm.formState.isValid}
                minW
              >
                {strings.save}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default PaymentSystemConfig;
