/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { UserDeletionRequest } from "../../../../../models/user/UserDeletionRequest";
import UserApi from "../../../../../api/UserApi";
import CloseButton from "../../../../../components/CloseButton";
import {
  getValueOfActiveUser,
  UserRole,
} from "../../../../../util/LocalStorageVariables";
import AlertBox from "../../../../../components/AlertBox";
import Field from "../../../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal/Modal";

interface Props {
  open: boolean;
  logout(): void;
  close(): void;
}

interface DeleteUserForm {
  password: string;
}

const defaultFormValues = {
  password: "",
};

const UserDeletionModal: React.FC<Props> = ({ open, logout, close }: Props) => {
  const role: UserRole | null = getValueOfActiveUser("role");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<DeleteUserForm>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  const closeModal = (): void => {
    close();
    setError(null);
    reset(defaultFormValues);
  };

  const submitDeleteForm = async (data: UserDeletionRequest) => {
    setLoading(true);

    try {
      await UserApi.deleteUser(data);
      setLoading(false);
      logout();
      close();
    } catch (err) {
      setLoading(false);
      setError(await getGeneralError(err));
    }
  };

  return (
    <Modal handleClose={closeModal} show={open}>
      <Modal.Header title={strings.deleteProfile} />
      <Modal.Body>
        <AlertBox
          closeAble={false}
          message={
            role === "doctor"
              ? `${strings.deleteProfileWarning} ${strings.deleteProfileWarningWorker}`
              : strings.deleteProfileWarning
          }
        />
        <div className="mt-10 mb-5">{strings.deleteProfileCredentials}</div>
        <form onSubmit={handleSubmit(submitDeleteForm)} id="deleteUserForm">
          <Field
            name="password"
            placeholder={strings.password}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            required
            error={errors.password}
          />
        </form>
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={!isValid}
          form="deleteUserForm"
          loading={loading}
          type="submit"
          variant="danger"
        >
          {strings.delete}
        </Button>
        <CloseButton onClick={closeModal} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default UserDeletionModal;
