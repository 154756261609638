/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  RegisterOptions,
  useController,
} from "react-hook-form";
import React, { ReactElement, useEffect, useState } from "react";
import { InputModeEnum } from "../../../models/InputModeEnum";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { Colors } from "../../../models/Colors";
import { strings } from "../../../common/Strings/Strings";
import { combineClassNames } from "../../../util/HtmlUtils";

interface FieldProps {
  control: Control<any>;
  name: string;
  autoComplete?: AutoCompleteOptions;
  classInput?: string;
  className?: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  fieldOptions?: Exclude<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  inputMode?: InputModeEnum;
  label?: string;
  labelClass?: string;
  labelIcon?: string;
  labelOff?: boolean;
  onChange?(e: boolean): void;
  optional?: boolean;
  order?: "LI" | "IL";
  readOnly?: boolean;
  required?: boolean;
  value?: any;
  warningLabel?: string;
}

const Switch = ({
  control,
  name,
  autoComplete,
  classInput,
  className,
  error,
  fieldOptions,
  inputMode,
  label,
  labelClass,
  labelIcon,
  labelOff,
  onChange,
  optional,
  order = "LI",
  readOnly,
  required,
  value: v,
  warningLabel,
}: FieldProps): ReactElement => {
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});
  const [value, setValue] = useState<boolean>(false);
  const { field } = useController({ name, control, rules: registerOptions });

  useEffect(() => {
    if (typeof v === "boolean") {
      setValue(v);
      field.onChange(v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [v]);

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  const getLabel = () => (
    <label>
      {labelIcon ? <i className={labelIcon} /> : ""} {label}
      {optional ? (
        <span style={{ color: Colors.INPUTBORDER }}> ({strings.optional})</span>
      ) : (
        <></>
      )}
    </label>
  );

  const getInput = () => (
    <label
      className={combineClassNames(
        "relative inline-flex items-center",
        readOnly ? "cursor-not-allowed" : "cursor-pointer"
      )}
    >
      <input
        {...field}
        autoComplete={autoComplete || AutoCompleteOptions.on}
        checked={value}
        type="checkbox"
        className={classInput || "sr-only peer"}
        disabled={readOnly}
        readOnly={readOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          field.onChange(e.target.checked);
          setValue(e.target.checked);

          if (onChange) {
            onChange(e.target.checked);
          }
        }}
      />
      <div className="rounded-full bg-gray-200 w-11 h-6 peer peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-sky-100 dark:peer-focus:ring-sky-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:bg-gray-700 dark:border-gray-600 peer-checked:bg-sky-500" />
      {warningLabel ? <label className="ml-2">{warningLabel}</label> : null}
    </label>
  );

  return (
    <>
      <div className={`flex items-center w-full ${className}`}>
        {order === "IL" ? (
          <>
            <div className={labelClass || "mr-3"}>{getInput()}</div>
            <div className={`${labelOff ? "" : "mb-1"}`}>{getLabel()}</div>
          </>
        ) : (
          <>
            <div className={`${labelOff ? "" : "mr-3"}`}>{getLabel()}</div>
            <div className={labelClass || "ml-auto"}>{getInput()}</div>
          </>
        )}
      </div>
      <div className="flex w-100">
        <div className="ml-auto validation-error">
          {error && error.message?.toString()}
        </div>
      </div>
    </>
  );
};

export default Switch;

Switch.defaultProps = {
  autoComplete: AutoCompleteOptions.on,
  classInput: undefined,
  className: undefined,
  error: undefined,
  fieldOptions: undefined,
  inputMode: undefined,
  label: undefined,
  labelClass: undefined,
  labelIcon: undefined,
  labelOff: false,
  onChange: undefined,
  optional: false,
  order: "LI",
  readOnly: false,
  required: false,
  value: undefined,
  warningLabel: undefined,
};
