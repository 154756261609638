/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { ApiPromise } from "../models/ApiPromise";
import { ClinicChargeResponse } from "../models/charge/ClinicChargeResponse";
import Params from "../common/Params";
import { axiosCommon } from "../util/axiosSetup";
import { PageResponse } from "../models/PageResponse";
import { PaymentRequest } from "../models/costaccounting/PaymentRequest";

export default class ChargeApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getClinicCharges(
    clinicId: string
  ): ApiPromise<ClinicChargeResponse[]> {
    const url = `${this.baseUrl}/clinics/${clinicId}/charges`;
    return axiosCommon.get(url);
  }

  static payClinicCharge(
    clinicId: string,
    chargeId: string,
    request?: PaymentRequest
  ): ApiPromise<ClinicChargeResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/charges/${chargeId}/pay`;
    return axiosCommon.post(url, request);
  }

  static getInvoicePdfForClinicCharge(chargeId: string): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/charges/${chargeId}/pdf`;
    return axiosCommon.get(url, {
      headers: { Accept: "*/*" },
      responseType: "arraybuffer",
    });
  }

  static getMonthlyCharges(
    month?: string,
    clinicId?: string,
    pageSize?: number,
    pageNumber?: number
  ): ApiPromise<PageResponse<ClinicChargeResponse>> {
    let url = `${this.baseUrl}/managements/charges`;

    if (month !== undefined) {
      url += `?month=${month}`;
    }

    if (clinicId !== undefined) {
      url += month ? "&" : "?";
      url += `clinicId=${clinicId}`;
    }

    if (pageSize) {
      url += month !== undefined || clinicId !== undefined ? "&" : "?";
      url += `pageSize=${pageSize}&pageNumber=${pageNumber || 0}`;
    }
    return axiosCommon.get(url);
  }
}
