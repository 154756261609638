/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Control } from "react-hook-form";
import { petSpecies, strings } from "../../../common/Strings/Strings";
import { PetSpecies } from "../../../models/pet/PetSpeciesEnum";
import CombinedSelect from "../General/Select/CombinedSelect";

interface FieldProps {
  className?: string;
  control: Control<any>;
  onChange?(value: PetSpeciesOption[] | null): void;
  optional?: boolean;
  required?: boolean;
}

export interface PetSpeciesOption {
  value: PetSpecies;
  name: string;
}

const commonPetSpecies = ["DOG", "CAT", "FERRET", "HORSE"];

const otherPetSpecies = [
  "BIRD",
  "HAMSTER",
  "CHINCHILLA",
  "RABBIT",
  "FUR_ANIMAL",
  "MOUSE",
  "RAT",
  "OTHER",
];

const SelectSpecies = ({
  className,
  control,
  onChange,
  optional,
  required,
}: FieldProps): ReactElement => {
  const options = useMemo<PetSpeciesOption[]>(() => {
    const result: PetSpeciesOption[] = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const value in PetSpecies) {
      // noinspection JSUnfilteredForInLoop
      result.push({ value: value as PetSpecies, name: petSpecies[value] });
    }
    return result;
  }, []);
  const [commonPets, setCommonPets] = useState<PetSpeciesOption[]>([]);
  const [otherPets, setOtherPets] = useState<PetSpeciesOption[]>([]);
  const [livestockPets, setLivestockPets] = useState<PetSpeciesOption[]>([]);

  useEffect(() =>{
    setCommonPets(options.filter(o =>
      commonPetSpecies.includes(o.value)
    ));

    setOtherPets(options.filter(o =>
      otherPetSpecies.includes(o.value)
    ));

    setLivestockPets(options.filter(
      o => !commonPets.includes(o) && !otherPets.includes(o)
    ));
  }, [options]);

  return (
    <CombinedSelect
      clearButton={true}
      control={control}
      className={className}
      groupedByOptions={[
        {
          groupOptions: commonPets,
          groupTitle: strings.pets
        },
        {
          groupOptions: otherPets
        },
        {
          groupOptions: livestockPets,
          groupTitle: strings.farmAnimals
        }
      ]}
      isTypeahead={true}
      label={strings.species}
      labelKey="name"
      maxResults={100}
      name="species"
      onChange={onChange}
      optional={optional}
      options={options}
      required={required}
    />
  );
};

export default SelectSpecies;

SelectSpecies.defaultProps = {
  optional: false,
  required: false,
};
