/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UpdatePasswordRequest } from "../../../models/user/UpdatePasswordRequest";
import UserManagementApi from "../../../api/UserManagementApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { strings } from "../../../common/Strings/Strings";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import AlertBox from "../../../components/AlertBox";
import { passwordRegexp } from "../../../util/Validations";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal/Modal";
import CloseButton from "../../../components/CloseButton";

interface Props {
  userId: string;
  close(): void;
  isOpen: boolean;
}

const ChangePasswordModal: React.FC<Props> = ({
  userId,
  close,
  isOpen,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<UpdatePasswordRequest>({
    mode: "onChange",
  });

  useEffect(() => {
    if (isOpen) {
      setError(null);
      reset();
    }
  }, [isOpen, userId, reset]);

  const submit = async (request: UpdatePasswordRequest) => {
    setLoading(true);

    try {
      await UserManagementApi.changeUserPassword(userId, request);
      close();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={strings.changePassword} />
      <Modal.Body>
        <form id="changePasswordForm" onSubmit={handleSubmit(submit)}>
          <Field
            name="password"
            label={strings.newPassword}
            register={register}
            autoComplete={AutoCompleteOptions.off}
            error={errors.password}
            type="password"
            fieldOptions={{
              required: true,
              pattern: {
                value: passwordRegexp,
                message: strings.passwordValid,
              },
            }}
          />
        </form>
        <AlertBox className="mt-5" message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          disabled={loading || !isValid}
          form="changePasswordForm"
          loading={loading}
          type="submit"
          variant="primary"
        >
          {strings.save}
        </Button>
        <CloseButton onClick={close} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
