/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { strings } from "../../../Strings/Strings";
import { DoctorMenuItem } from "../../MenuItem/MenuItemTypes";
import { useClinic } from "../../../../contexts/ClinicContext";
import { ClinicFeature } from "../../../../models/clinic/ClinicFeature";
import { More } from "../../../Icons/More";
import { useModal } from "../../../../contexts/ModalContext";
import FooterMenuItem from "../FooterMenuItem";
import DoctorMenuItems from "../../MenuItem/DoctorMenuItems";
import { getMenuItemsVisible } from "../../MenuItem/MenuItem";

interface Props {
  activeMenu?: DoctorMenuItem;
}

function DoctorMobileMenu(props: Props): ReactElement | null {
  const { clinic, isFeatureAvailable } = useClinic();
  const { openModalIds } = useModal();

  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const [menuItemsVisible, setMenuItemsVisible] = useState(
    getMenuItemsVisible()
  );

  // Recalculate the number of visible items when the screen is resized
  useEffect(() => {
    const handleResize = () => {
      setMenuItemsVisible(getMenuItemsVisible());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Hide the menu when the user menu dropdown is open
  if (openModalIds.includes("userDropdownMenuMobile")) {
    return null;
  }

  const isMenuItemVisible = (cf: ClinicFeature | null) => {
    if (cf === null) {
      return true;
    }
    return isFeatureAvailable(cf);
  };

  const menuItemsDefault = DoctorMenuItems.slice(0, menuItemsVisible);
  const menuItemsMore = DoctorMenuItems.slice(menuItemsVisible);
  const menuItemsMoreVisible: boolean =
    menuItemsVisible < DoctorMenuItems.length;

  const activeMenuIndex = DoctorMenuItems.findIndex(
    (item) => item.type === props.activeMenu
  );

  // Make the currently selected menu always visible
  if (menuItemsMoreVisible && activeMenuIndex > menuItemsDefault.length - 1) {
    const lastItemInDefault = menuItemsDefault[menuItemsDefault.length - 1];

    const activeMenuIndexInMore = menuItemsMore.findIndex(
      (item) => item.type === props.activeMenu
    );

    // Switch place with the last element of the more list
    menuItemsDefault[menuItemsDefault.length - 1] =
      menuItemsMore[activeMenuIndexInMore];
    menuItemsMore[activeMenuIndexInMore] = lastItemInDefault;
  }

  if (!clinic || clinic?.availableFeatures.length === 0) {
    return null;
  }

  return (
    <>
      <div
        className="fixed z-30 bottom-0 left-0 right-0 xl:hidden"
        id="doctor-mobile-menu"
      >
        <div
          className="dropdown-mobile-footer-more z-30 rounded-lg border-t border-l border-r border-gray-200 w-full bg-white divide-y divide-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:divide-gray-600"
          hidden={!isMoreOpen || !menuItemsMoreVisible}
          id="dropdownMobileFooterMore"
        >
          <ul className="px-6 py-3 flex flex-col items-start text-base font-medium">
            <>
              {/* Menu items that can be seen by clicking the 'More' icon */}
              {menuItemsMore.map((item, index) => {
                if (isMenuItemVisible(item.clinicFeature)) {
                  return (
                    <li key={index}>
                      <FooterMenuItem
                        menu={item.type}
                        activeMenu={props.activeMenu}
                        closeMoreMenu={() => setIsMoreOpen(false)}
                        isInMore
                        href={item.href}
                        visible={isMenuItemVisible(item.clinicFeature)}
                        name={item.name}
                        icon={item.icon}
                      />
                    </li>
                  );
                }
                return <></>;
              })}
            </>
          </ul>
        </div>
        <div
          className={`w-full pt-1.5 pb-1.5 bg-white items-center justify-between p-4 dark:bg-gray-800 dark:border-gray-700 ${
            isMoreOpen ? "" : "border-t border-gray-200 dark:border-gray-700"
          }`}
        >
          <div className="container mx-auto ">
            <ul className="flex items-center justify-around text-sm font-medium">
              <>
                {/* Menu items that can be seen by default */}
                {menuItemsDefault.map((item, index) => {
                  if (isMenuItemVisible(item.clinicFeature)) {
                    return (
                      <li key={index}>
                        <FooterMenuItem
                          menu={item.type}
                          activeMenu={props.activeMenu}
                          closeMoreMenu={() => setIsMoreOpen(false)}
                          isInMore={false}
                          href={item.href}
                          visible={isMenuItemVisible(item.clinicFeature)}
                          name={item.name}
                          icon={item.icon}
                        />
                      </li>
                    );
                  }
                  return <></>;
                })}
              </>
              <FooterMenuItem
                menu="MORE"
                onClick={() => {
                  setIsMoreOpen((prev) => !prev);
                }}
                visible={menuItemsMoreVisible}
                name={strings.more}
                icon={<More className="w-6 h-6 flex-shrink-0" />}
              />
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorMobileMenu;
